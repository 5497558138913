import Render from '@Views/root/patients/merge/item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {IMergeData, INotification, IPatientMerge, IPopin, IPopinAction} from '@Store/types'
import {Gender, PopinType} from '@Enums/index'
import {superPanel, patient, notif} from '@Store/modules'
import {Dictionary} from "vue-router/types/router"

@Render
@Component({})
export class MergeItem extends Vue {

	@Prop() patient: IPatientMerge
	@Prop() readOnly: boolean
	@Prop() query: string
	@Prop({default: false}) popinToUpdate: boolean
	@Prop() pathToOpen: {name: string, params: any}
	@Prop() unselect: () => void
	@Prop({default: false}) withoutOpen: boolean
	@Prop({default: false}) withoutUnselect: boolean

	//#region Getters
	get last_name(): string {
		return (this.patient && this.patient.last_name) || ''
	}
	get first_name(): string {
		return (this.patient && this.patient.first_name) || ''
	}
	get gender(): Gender {
		return (this.patient && this.patient.gender) || Gender.Man
	}
	get phone(): string {
		return (this.patient && this.patient.phone) || ''
	}
	get email(): string {
		return (this.patient && this.patient.email) || ''
	}
	get blacklisted(): boolean {
		return this.patient && this.patient.blacklisted
	}
	get deceased(): boolean {
		return this.patient && this.patient.deceased
	}
	get count_duplicate(): number {
		return (this.patient && this.patient.count) || 0
	}
	//#endregion

	mounted() {
		this.patient.visible = true
		this.$forceUpdate()
	}

	merge(): void {
		let merge: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.merge.item.label').toString(),
			title: this.$i18n.t('vm.root.patients.merge.item.title').toString(),
			callback: (data: {idList: string[], keys:Dictionary<IMergeData>}, updateLoading: any) => {
				let message: string = this.$i18n.t('vm.root.patients.merge.item.notif', {count: data.idList.length}).toString()
				let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
				updateLoading(true)
				return patient.mergePatient({merge: data.keys, patients: data.idList})
					.then(() => {
						this.patient.visible = false
						this.$forceUpdate()
						patient.clear()
						updateLoading(false)
						notif.success(notification)
						return Promise.resolve()
					})
					.catch(() => {
						updateLoading(false)
						return Promise.reject()
					})
			},
			condition: (data: {keys:Dictionary<IMergeData>, loaded: boolean, allSelected: boolean}) => {
				return data.loaded && data.allSelected
			}
		}

		let changeLogPopin: IPopin = {
			title: this.$i18n.t('vm.root.patients.merge.item.title').toString(),
			content: {component: 'super-panel-merge'},
			options: {idList: this.patient.id_list, keys: {}, loaded: false, allSelected: false},
			isWide: true,
			action: merge
		}

		superPanel.show({popin: changeLogPopin, type: PopinType.Info})
	}
}
