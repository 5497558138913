<context>

	<div>
		<section class="wrapper marg-v">
			<h2>{{ $tc('vm.root.user.context-referral.title', godchildList.length) }}</h2>

			<div v-if="isLoading" class="text-lg text-primary">
				<spinner/>
			</div>
			<p v-else-if="!godchildList || godchildList.length === 0">{{ $t('vm.root.user.context-referral.no-godchild') }}</p>
			<section v-else class="semi-marg-v person-item" v-for="godchild in godchildList" :key="godchild.id">
				<span class="person-item-header text-capitalize text-inherit">
					<figure class="person-item-avatar">
						<avatar :first-name="godchild.coordinates.first_name" :last-name="godchild.coordinates.last_name" :email="godchild.email" :gender="godchild.coordinates.gender"/>
					</figure>
					<h3 class="h-reset person-item-name">{{ godchild.coordinates.first_name }}<br><b>{{ godchild.coordinates.last_name }}</b></h3>
				</span>
				<div class="person-item-border">
					<div class="marquee">
						<span class="marquee-inner">
							<span class="marquee-content">{{ godchild.email }}</span>
						</span>
					</div>
				</div>
			</section>
		</section>
	</div>

</context>
