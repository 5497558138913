export const followUp = {
    title: "Paramètres du suivi de consultation",
    type: {
        title: "Sélectionnez le type d'envoi de vos suivis de consultation",
        label: "Type d'envoi, par défaut, du suivi de consultation"
    },
    details: {
        title: "Détails du SMS de suivi de consultation",
        text1: {
            content: "Vous pouvez modifier le texte ci-dessous, 160 caractères maximum. C'est celui-ci que vos patients recevront, par SMS, après le délai indiqué (ici {0}).",
            strong: "{count} jour|{count} jour|{count} jours"
        },
        text2: {
            content: "{0} si le message venait à dépasser les 160 caractères, il ne serait pas envoyé.",
            strong: "Attention :"
        },
        text3: {
            content: "{0} La mention {1} sera remplacée par le lien vers la page de suivi, ce lien fait {2} et est obligatoire. Veillez à bien la saisir sous ce format.",
            em: "N.B. :",
            strong1: "LIEN",
            strong2: "une quinzaine de caractères"
        }
    },
    sms: {
        balance: {
            content: "Votre solde actuel : {0}",
            strong: "{sms-count} SMS"
        },
        title: "Acquérir des SMS",
        label: "acquérir des SMS"
    },
    text: {
        label: "Texte du SMS de suivi de consultation ({length})",
        placeholder: "ex : Votre ostéopathe souhaite prendre de vos nouvelles…",
        title: "Veuillez renseigner le texte de votre SMS de suivi"
    },
    delay: {
        label: "Délai, par défaut, pour relancer vos patients après une consultation (jours)",
        title: "Veuillez indiquer le délai après lequel le suivi sera envoyé",
        placeholder: "ex : dans 15 jours"
    },
    'page-presentation': {
        title: "Page de suivi de consultation personnalisable",
        help: "Vous avez la possibilité de vous présenter par le biais du texte suivant. Si vous ne renseignez pas ce champ, une présentation par défaut sera alors affichée sur la page de suivi de consultation. Cliquez sur le lien suivant pour avoir {0}.",
        'link-title': "Aperçu de votre page de suivi de consultation",
        'link-label': "un aperçu de cette page"
    },
    presentation: {
        label: "Présentation personnalisée",
        placeholder: "ex : Pour toute question, vous pouvez me joindre…",
        title: "Veuillez renseigner votre présentation personnalisée"
    },
    'remaining-characters': "{count} caractère restant|{count} caractère restant|{count} caractères restants",
    error: {
        'no-stop': 'La mention "STOP au XXXXX" est obligatoire.',
        'no-link': 'La mention "LIEN" doit être présente pour pouvoir indiquer le lien votre page de suivi.',
        'too-long': "Votre texte de suivi de consultation par SMS est trop long."
    }
}
