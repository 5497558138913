<div v-if="!!formations">
	<div class="childs-space-between marg-v">
		<h1 class="text-uppercase-first-letter no-marg">{{ $tc('vm.root.user.formations.title', formations.length) }}</h1>
		<button type="button" class="btn--link-like is-pushed-right" @click="addFormation" :title="$t('vm.root.user.formations.new-formation.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.user.formations.new-formation.label') }}</span>
		</button>
	</div>

	<div v-if="formations.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 640 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#graduation-cap"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.user.formations.none.label') }}</span><br>
			<i18n path="vm.root.user.formations.none.text" tag="span">
				<button type="button" class="btn--link-like" @click="addFormation" :title="$t('vm.root.user.formations.new-formation.title')">
					{{ $t('vm.root.user.formations.none.button-label') }}
				</button>
			</i18n>
		</p>
	</div>

	<draggable :forceFallback="true" tag="ul" :list="formations" class="ul-reset" v-else handle=".draggable-handler">
		<li v-for="formation in formations" class="draggable" :ref="getElementId(formation)" :key="getElementId(formation)">

			<div class="collapsible-panel bordered-panel marg-v draggable-frame is-with-border">
				<button type="button" class="btn--reset draggable-handler" :title="$t('vm.root.user.formations.move.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.user.formations.move.label') }}</span>
				</button>
				<button type="button" class="btn--link-like collapsible-panel-trigger text-bold"
					:title="$t('vm.root.user.formations.details')"
					@click="toggle(formation.id, formation.create_id)"
					:class="{'is-active': isToggle(formation.id, formation.create_id)}">
					<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
					</svg>
					<span class="text-uppercase-first-letter">{{ formation.name || $t('vm.root.user.formations.default-name') }}</span>
				</button>
				<div class="collapsible-panel-container"  :class="{'is-active': isToggle(formation.id, formation.create_id)}">
					<div class="collapsible-panel-content">
						<formation :formation="formation" :submitted="submitted"/>
					</div>
				</div>
			</div>

		</li>
	</draggable>


</div>
