<context>
	<infinite-scroll :initialized="initialized" tag="hr" class-tag="wrapper" :load-more="loadNextPurchases" :is-loading="isLoading" :can-load-more="hasMorePurchases">
		<section class="wrapper marg-v">
			<h2>
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
				</svg>
				{{ $t('vm.root.shop.context.title') }}
			</h2>

			<p v-if="!purchaseList || purchaseList.length === 0">{{ $t('vm.root.shop.context.none') }}</p>

			<div v-else v-for="(purchase, index) in purchaseList" :key="purchase.id">
				<purchase-item :purchase="purchase"/>
				<hr v-if="index !== purchaseList.length - 1">
			</div>

		</section>

		<div slot="loading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</div>
	</infinite-scroll>

</context>
