export const asideSpend = {
    back: {
        title: "Retourner au tableau des finances",
        label: "Retour au tableau"
    },
    details: {
        title: "Modifiez les informations de votre dépense",
        label: "Détails"
    },
    recurrency: {
        title: "Créez une récurrence pour votre dépense",
        label: "Récurrence"
    },
    delete: {
        title: "Suppression de cette dépense",
        'delete-msg': "Voulez-vous vraiment supprimer cette dépense ?",
        label: "Supprimer cette dépense"
    }
}
