<context v-if="!!purchase">

	<div>
		<section class="wrapper marg-v">
			<h2>
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
				</svg>
				{{ $tc('vm.root.shop.purchase.context.title', purchase.billings.length) }}
			</h2>

			<template v-if="purchase.billings && purchase.billings.length > 0">
				<div v-for="billing in purchase.billings" :key="billing.id" class="p-like childs-space-between">
					<span>{{ $tc('vm.root.shop.purchase.context.title', 1) }} {{ billing.display_number }}</span>
					<a class="is-pushed-right" :href="billingPath(billing.id)" target="_blank">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#print"/>
						</svg>
						{{ $t('general.print.text') }}
					</a>
				</div>
			</template>

			<p v-else>{{ $t('vm.root.shop.purchase.context.not-payed') }}</p>

			<p v-if="payed" class="text-bold">{{ $t('vm.root.shop.purchase.context.paying') }}</p>

		</section>
	</div>

</context>
