export const formation = {
    name: {
        label:"Nom de la formation",
        placeholder:"ex : Ostéopathie pédiatrique",
        'error-msg':"Merci de renseigner le nom de la formation",
        title:"Veuillez renseigner le nom de la formation",
    },
    period: {
        label:"Période liée à la formation",
        placeholder:"ex : 2014-2016",
        title:"Veuillez renseigner la période pendant laquelle vous avez suivi cette formation",
    },
    description: {
		label: "Description de la formation ({count} caractère restant) | Description de la formation ({count} caractère restant) | Description de la formation ({count} caractères restants)",
        placeholder:"ex : Formation suivi auprès de…",
        title:"Veuillez renseigner la description de cette formation",
		'msg-error': "Votre description ne doit pas dépasser 250 caractères"
    },
    delete: {
        msg: "Voulez-vous vraiment supprimer cette formation ?",
        title: "Supprimer cette formation"
    }
}
