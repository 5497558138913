import Render from '@Views/components/popin/layout/popin-change-log.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IChangeLog } from '@Store/types'

@Render
@Component({})
export class PopinChangeLog extends Vue {
    
    @Prop() options: {changeLog: IChangeLog}
	@Prop({default: false}) submitted: boolean
	
	get changeLog(): IChangeLog {
		return this.options.changeLog
	}
}