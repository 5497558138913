import { i18n } from '@I18n/index'
import { StorageHelper } from "@Helpers/index"
import { INotification, IPatient } from "@Store/types"
import { PatientService } from "@Services/index"
import { NotificationType } from "@Enums/index"
import { Backup } from "./backup"

export class Queue {

    private static QUEUE_KEY: string = 'queue'
    //private static QUEUE_DELAY: number = 10000  // 10 secondes
    private static QUEUE_DELAY: number = 300000 // 5 minutes

    private queue: string[]
    private listeners: ((notification: INotification, type: NotificationType) => any)[] = []
    private started: boolean = false
    private service: PatientService = null

    private static instance: Queue = null

    get count(): number {
        return this.queue.length
    }

    public static getInstance(): Queue {
        if (!this.instance) {
            let storedQueue: string[] = StorageHelper.get(Queue.QUEUE_KEY, [])
            this.instance = new Queue(storedQueue)
        }
        return this.instance
    }

    private constructor(queue?: string[]) {
        this.listeners = []
        this.service = new PatientService()
        this.queue = queue || []
    }

    public enqueue(patient: IPatient): void {
        Backup.getInstance().add(patient)

        if (!this.queue.find(id => id === patient.id)) {
            this.queue.push(patient.id)
            StorageHelper.set(Queue.QUEUE_KEY, this.queue)
        }

        this.start()
    }

    public clear(): void {
        this.queue = []
        StorageHelper.set(Queue.QUEUE_KEY, this.queue)
    }

    public addListener(callback: (notification: INotification, type: NotificationType) => any): void {
        this.listeners.push(callback)
    }

    public removeListener(callback: (notification: INotification, type: NotificationType) => any): void {
        this.listeners.splice(this.listeners.indexOf(callback), 1)
    }

    private start(): void {
        if (this.count === 0) return
        if (this.started) return

        this.started = true
        setTimeout(() => this.tryToSave(), Queue.QUEUE_DELAY)
    }

    private dequeue(): IPatient {
        let pat_id = this.queue.shift()
        let patient: IPatient = Backup.getInstance().get(pat_id)

        StorageHelper.set(Queue.QUEUE_KEY, this.queue)
        return patient
    }

    private tryToSave(): void {
        let patient: IPatient = this.dequeue()
        if (!patient) return
        if (!patient.is_new && parseInt(patient.id) === -1) return

        this.service.save(patient)
        .then(newPatient => this.continueToSave(newPatient), () => this.saveFailed(patient))
        .catch(() => this.saveFailed(patient))
    }

    private continueToSave(patient: IPatient): void {
        Backup.getInstance().add(patient)

        this.started = false
        if (this.count > 0) {
            this.start()
        } else {
            let notification:INotification = { message: i18n.t("models.queue.data-saved").toString(), actions: [], delay: 5000 }
            for(let callback of this.listeners) {
                callback(notification, NotificationType.Success)
            }
        }
    }

    private saveFailed(patient:IPatient): void {
        this.started = false
        this.enqueue(patient)
        let notification:INotification = { message: i18n.t("models.queue.save-failed").toString(), actions: [], delay: 5000 }
        for(let callback of this.listeners) {
            callback(notification, NotificationType.Warning)
        }
    }
}
