export const patientsMain = {
    legends: {
        first: "homme",
        second: "femme",
        third: "autre",
    },
    'age-chart': {
        category1: "0 - 9 ans",
        category2: "10 - 19 ans",
        category3: "20 - 29 ans",
        category4: "30 - 39 ans",
        category5: "40 - 49 ans",
        category6: "50 - 59 ans",
        category7: "60 - 69 ans",
        category8: "70 - 79 ans",
        category9: "80 - 89 ans",
        category10: "90 - 99 ans",
        category11: "+100 ans"
    },
    'pediatry-chart': {
        category1: "0 - 6 mois",
        category2: "6 - 12 mois",
        category3: "12 - 18 mois",
        category4: "18 - 24 mois",
        category5: "24 - 30 mois",
        category6: "30 - 36 mois"
    },
    title: "Statistiques patients",
    loading: "Chargement en cours…",
    'ratio-title': "Répartition Homme / Femme / Autre ({total})",
    'evolution-title': "Évolution patientèle (en {year})",
    'chart1-title': "Répartition par tranche d'âges",
    'chart2-title': "Répartition par tranche d'âges pédiatrique"
}
