import { StorageHelper, DateHelper } from "@Helpers/index"
import { Dictionary } from "vue-router/types/router"
import { Patient } from "./patient"
import { ErrorService } from "@Services/index"
import { IPatientFilter, IPatientSearch, IPatient } from "@Store/types"
import * as LZString from 'lz-string'

export class Backup {
    private static BACKUP_KEY: string = 'patient_list'
    private static MAX_SIZE: number = 4096
    private static MAX_COUNT: number = 100

    private backup: Dictionary<IPatient> = {}
    private static instance: Backup = null

    get count(): number {
        let list: string[] = []
        Object.keys(this.backup).map(id => {
            if (!this.backup[id]) return
            list.push(id)
        })

        return list.length
    }
    get size(): number {
        return StorageHelper.getKeySize(Backup.BACKUP_KEY)
    }
    get patientList(): IPatient[] {
        let list: IPatient[] = []
        Object.keys(this.backup).map(id => {
            if (!this.backup[id]) return
            list.push(this.backup[id])
        })
        list.sort((a: IPatient, b: IPatient) => { return a.read_date > b.read_date ? -1 : a.read_date < b.read_date ? 1 : 0 })

        return list
    }

    public static getInstance(): Backup {
        this.instance = !this.instance ? new Backup() : this.instance
        return this.instance
    }

    private constructor() {
        this.backup = {}
        let backup: Dictionary<string> = StorageHelper.get(Backup.BACKUP_KEY, {})
        Object.keys(backup).map(id => {
            let value: any = backup[id]
            if (!value) return

            try {
                let patient: any = JSON.parse(LZString.decompress(value))
                DateHelper.convertStringToDate(patient)

                this.backup[id] = patient as IPatient
            } catch(e) {
                // let service: ErrorService = new ErrorService()
                // let error: string = `last step : ${(Backup.MAX_SIZE - this.getListSize(this.getList())).toFixed(2)} KB available after free up`
                // error =`${error}\nlast step : ${Backup.MAX_COUNT - this.count} slot available after free up`
                // error = `${error}\nBack up constructor : ${e}<br>${id} :: ${LZString.decompress(value)}`
                // service.error(error)
                this.delete(id)
            }
        })
    }

    public load(dictionary: Dictionary<IPatient>) {
        let ids: string[] = Object.keys(dictionary)
        if (ids.length === 0) return

        for(let patId of ids) {
            let patient:IPatient = dictionary[patId]
            this.backup[patient.id] = patient
        }

        this.free()
    }

    public save(): void {
        StorageHelper.set(Backup.BACKUP_KEY, this.getList())
    }

    public add(patient: IPatient): void {
        if (!patient) return

        let dictionary: Dictionary<IPatient> = {}
        dictionary[patient.id] = {...patient}
        this.load(dictionary)
    }

    public delete(patId: string): void {
        if (!patId) return

        this.backup[patId] = null
        this.save()
    }

    public blacklist(patId: string, blacklisted: boolean) {
        let patient: IPatient = this.get(patId)
        if (!patient) return

        patient.blacklisted = blacklisted
        this.save()
	}

    public dead(patId: string, deceased: boolean) {
        let patient: IPatient = this.get(patId)
        if (!patient) return

        patient.deceased = deceased
        this.save()
	}

    public get(id: string): IPatient {
        if (!id) return

        let patient: IPatient = this.backup[id]
        return patient
    }

    public clear(): void {
        this.backup = {}
        this.save()
    }

    public search(query: string, filter: IPatientFilter): IPatientSearch[] {
        if (!query || !filter) return []

        let list: {score: number, patient: IPatientSearch}[] = []
        Object.keys(this.backup).map(id => {
            let patient: IPatientSearch = Patient.toPatientSearch(this.backup[id])
            let score: number = this.scorePatient(query, filter, patient)
            if (score === 0) return

            list.push({score: score, patient: patient})
        })

        list.sort((a: {score: number, patient: IPatientSearch}, b: {score: number, patient: IPatientSearch}) => { return a.score > b.score ? -1 : a.score < b.score ? 1 : 0 })

        return list.map(i => { return i.patient })
    }

    private free() {
        let oldSize: number = this.getListSize(this.getList())
        let size: number = oldSize
        let list: IPatient[] = this.patientList

        console.debug(`step 1 : ${(Backup.MAX_SIZE - size).toFixed(2)} KB available`)
        console.debug(`step 1 : ${Backup.MAX_COUNT - this.count} slot available`)
        while(size > Backup.MAX_SIZE || this.count > Backup.MAX_COUNT) {
            let patient: IPatient = list.shift()
            if (!patient) {
                this.clear()
            } else {
                this.remove(patient.id)
            }
            size = this.getListSize(this.getList())
            console.debug(`space free up  ${(oldSize - size).toFixed(2)} KB`)
        }
        console.debug(`last step : ${(Backup.MAX_SIZE - size).toFixed(2)} KB available after free up`)
        console.debug(`last step : ${Backup.MAX_COUNT - this.count} slot available after free up`)
        this.save()
    }

    private remove(id: string): void {
        if (!id) return

        this.backup[id] = undefined
    }

    private getList(): Dictionary<string> {
        let list: Dictionary<string> = {}
        Object.keys(this.backup).map(id => {
            if (!this.backup[id]) return
            list[id] = LZString.compress(JSON.stringify(this.backup[id]))
        })

        return list
    }

    private scorePatient(query: string, filter2: IPatientFilter, patient: IPatientSearch): number {
		if (!filter2 || !patient) return 0

		let countResult:number = 0
		let tags: string[] = query.split(' ')
		for(let tag of tags) {
			tag = tag.trim()
			if (tag === '') continue
			if (filter2.by_first_name && !!patient.first_name && patient.first_name.startsWith(tag)) {
				countResult += 2
            } else if (filter2.by_last_name && !!patient.last_name && patient.last_name.startsWith(tag)) {
				countResult += 3
            } else if (filter2.by_profession && !!patient.profession && patient.profession.startsWith(tag)) {
				countResult++
            } else if (filter2.by_phone && !!patient.phone && patient.phone.startsWith(tag)) {
				countResult += 4
            } else if (filter2.by_email && !!patient.email && patient.email.startsWith(tag)) {
				countResult += 4
            // } else if (filter2.by_address && !!patient.address && patient.address.startsWith(tag)) {
			// 	countResult++
			}
		}
		return countResult
    }

    private getSize(patient: IPatient): number {
        let compress: string = LZString.compress(JSON.stringify(patient))
        return StorageHelper.getStringSize(compress)
    }

    private getListSize(list: Dictionary<string>): number {
        let str: string = JSON.stringify(list)
        return StorageHelper.getStringSize(str)
    }
}
