import Render from '@Views/root/user/account/organization/main.html'
import { isEqual, cloneDeep } from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IUser, ICurriculum } from '@Store/types'
import { user } from '@Store/modules'
import {User} from "@Models/user"

@Render
@Component({})
export class Main extends Vue {

	messageLength: number = User.maxCurriculumLength
	private messageWarning: boolean = false
	private oldOrganization: ICurriculum = null

	@Prop() submitted: boolean
	@Prop() organization: ICurriculum

	//#region getter
	get hasNameError(): boolean {
		return this.submitted && !this.organization.name
	}
	get elementId(): string {
		return parseInt(this.organization.id) === -1 ? this.organization.create_id : this.organization.id
	}
	private get user(): IUser {
		return user.user
	}
	//#endregion

	mounted() {
		this.oldOrganization = cloneDeep(this.organization)
		this.updateMessage()
	}

	deleteOrganization(): void {
		this.user.organizations.deleted.push(this.organization)
		this.user.organizations.currents = this.user.organizations.currents.filter(o => { return o.id !== this.organization.id || o.create_id !== this.organization.create_id } )
	}

	@Watch('organization', {deep: true})
	updateOrganization() {
		// On retire la session en cours pour ne pas l'écrire 2 fois
		if (this.oldOrganization.id !== this.organization.id || isEqual(this.oldOrganization, this.organization)) return

		this.organization['update'] = true
	}

	@Watch('organization.description')
	private updateMessage() {
		let result = User.maxCurriculumLength
		if (!!this.organization && !!this.organization.description) {
			let text: string = this.organization.description.trim()
			result = User.maxCurriculumLength - text.length
		}
		this.messageWarning = result < 0
		this.messageLength = Math.max(0, result)
	}
}
