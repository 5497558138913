export const referral = {
    title: "Parrainez vos collègues",
    none: {
        text: "Pour parrainer vos collègues, vous devez au préalable posséder {0}.",
        'link-label': "un pack tranquillité",
        'link-title': "Acquérir un pack tranquillité"
    },
    help1: {
        text: "Vous pouvez parrainer un utilisateur d'Osteo2ls en lui communiquant votre code parrain, ci-dessous. Il bénéficiera de {0}.",
        strong: "10 % de réduction sur l'achat d'un pack tranquillité"
    },
    help2: "Dès lors que votre filleul finalise l'achat d'un pack tranquillité, vous obtenez un point. Collectez autant de point que vous le pouvez et obtenez le bonus de votre choix. Communiquez votre code de parrainge ou votre lien de parrainage à vos collègues (s'ils ne sont pas encore inscrits sur Osteo2ls).",
    'default-share-text': "Bénéficiez de 10 % de réduction sur l'achat d'un pack tranquillité Osteo2ls grâce au code : {code}. Inscrivez-vous directement via ce lien https://app.osteo2ls.com/login/register/{code} ou renseignez ce code lors de votre achat.",
    'share-by': "partager par",
    'share-by-email': {
        label: "email",
        title: "Partager par email",
        'action-title': "Parrainez un collègue",
        'action-label': "parrainer"
    },
    'share-by-facebook': {
        label: "facebook",
        title: "Partager par facebook"
    },
    'share-by-twitter': {
        label: "twitter",
        title: "Partager par twitter"
    },
    'referral-code': "Code parrain",
    'referral-url': "Url de parrainage",
    point: "Aucun point | {count} point | {count} points",
    boni: "Boni disponibles",
    header: {
        bonus: "Bonus",
        get: "Obtenir"
    },
    get: "Dépenser {point} pour obtenir {gift}",
    'current-total': "Vous totalisez actuellement",
	'send-referral': {
        title: "Envoyez la facture par email",
        label: "Envoyer la facture",
        succeeded: "Votre parrainage a bien été envoyé à l'adresse <strong>{email}</strong>.",
        failed: "Votre parrainage n'a pu être envoyé à l'adresse <strong>{email}</strong>."
    },
    'apply-gift': {
        additional: "supplémentaire|supplémentaire|supplémentaires",
        success: "Félicitations ! Votre compte vient d'être mis à jour : {gift} {additional}.",
        'default-error': "Une erreur s'est produite, veuillez réessayer plus tard.",
        'not-enough-error': "Vous ne totalisez pas un nombre suffisant de point pour ce cadeau.",
        'no-gift-error': "Aucun cadeau ne semble correspondre à votre demande."
    }
}