import Render from '@Views/root/user/password.html'
import { Component } from 'vue-property-decorator'
import { IBreadcrumbItem, PasswordPayload } from '@Store/types'
import { AutoSaver } from '@Components/auto-saver'
import { breadcrumb, notif, user } from '@Store/modules'

@Render
@Component({
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Password extends AutoSaver {
	
	submitted: boolean = false
	password: {currentPassword?: string, newPassword?: string, confirmPassword?: string} = {}

	constructor() {
		super('password', false)
	}

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('notification/success') success:(message: string | INotification) => void
	// @Action('user/changePassword') changePassword:(payload: PasswordPayload) => any

	get hasCurrentPasswordError(): boolean {
		return this.submitted && !this.password.currentPassword
	}
	get hasNewPasswordError(): boolean {
		return this.submitted && !this.password.newPassword
	}
	get hasConfirmPasswordError(): boolean {
		return this.submitted && this.password.confirmPassword !== this.password.newPassword
	}
	get isLoading(): boolean {
		return user.isPasswordLoading
	}

    canSubmit(): boolean {
		return !!this.password.currentPassword && !!this.password.newPassword && !!this.password.confirmPassword
	}

	submit(event: any) {
		this.submitted = true
		this.save()
	}

	//#region IAutoSaver implementation
	restoreValue(value: any): void {
		this.password = {}
	}
	save(): void {
		if (!this.canSubmit()) return

        const { currentPassword, newPassword, confirmPassword } = this.password
		
		let payload: PasswordPayload = { currentPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword }
		user.changePassword(payload)
		.then(() => { notif.success(this.$i18n.t('vm.root.user.password.notification').toString()) })
		.then(() => { super.initializeValue({}) })
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'password')
		this.initializeBreadcrumb()
		super.initializeValue({})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.password').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}