import Render from '@Views/root/patients/patient/main.html'
import { cloneDeep } from 'lodash'
import { ASidePatient } from './aside-patient'
import { ASideConsultation } from './consultation/aside-consultation'
import { ASidePediatrics } from './pediatrics/aside-pediatrics'
import { ContextMain } from './context-main'
import { Component } from 'vue-property-decorator'
import { AutoSaver } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { Backup, Debouncer, Patient } from '@Models/index'
import { PatientService } from '@Services/index'
import { AgeBracket, AutoSaverState, Level, MemberType, PackType } from '@Enums/index'
import config from "@Config/index"
import {
	IUser,
	INotification,
	INotificationAction,
	IPatient,
	IConsultation,
	IPopinAction,
	IPopin,
	IPrintList,
	IMember,
	IAttachment
} from '@Store/types'
import { breadcrumb, notif, patient, popIn, user } from '@Store/modules'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'aside-patient': ASidePatient,
		'aside-consultation': ASideConsultation,
		'aside-pediatrics': ASidePediatrics
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
		notif.clear()
	},
	beforeRouteUpdate(to, from, next) {
		this.scrollToTop()
		next()
		if (to.params.pat_id === from.params.pat_id) return

		this.initializePatient()
	}
})
export class Main extends AutoSaver {

	readonly: boolean = true
	submitted: boolean = false
	activeNotes: boolean = false
	item: string = ''
	private printList: IPrintList = null
	private centralElement: Element = null
	private patientHeader: Element = null
	private debouncerScroll: Debouncer = null

	constructor() {
		super('patient')
	}

	//#region Getters
	get patient(): IPatient {
		return patient.patient
	}
	get blacklisted(): boolean {
		return patient.blacklisted
	}
	get deceased(): boolean {
		return patient.deceased
	}
	get age(): string {
		let age: string = this.$options.filters.age(this.patient.coordinates.birth_date)
		if (!age) return this.$i18n.t('vm.root.patients.patient.main.no-date').toString()
		return age
	}
	get isNoneBracket(): boolean {
		return this.ageBracket === AgeBracket.None
	}
	get gender(): string {
		if (this.ageBracket === AgeBracket.Child) return EnumsHelper.genderChildToString(this.patient.coordinates.gender)
		if (this.ageBracket === AgeBracket.Teenager) return EnumsHelper.genderTeenagerToString(this.patient.coordinates.gender)
		if (this.ageBracket === AgeBracket.Adult) return EnumsHelper.genderToString(this.patient.coordinates.gender)
		return ''
	}
	get patientName() {
		return !!this.patient && `${this.patient.coordinates.first_name} ${this.patient.coordinates.last_name}`.trim()
	}
	get isSaving(): boolean {
		return patient.isSaving
	}
	get displayAsidePatient(): boolean {
		return this.item !== 'schema' && this.item !== 'consultation' && this.item !== 'pediatrics'
	}
	get displayAsideConsultation(): boolean {
		return this.item === 'consultation' || this.item === 'schema'
	}
	get hasWrapperFull(): boolean {
		return this.item === 'schema'
	}
	get displayAsidePediatrics(): boolean {
		return this.item === 'pediatrics'
	}
	get hasPhone(): boolean {
		return !!this.patient.coordinates.phone1.number
	}
	get phone(): string {
		return this.patient.coordinates.phone1.number
	}
	get hasProfession(): boolean {
		return !!this.patient.coordinates.professions && this.patient.coordinates.professions.length > 0
	}
	get profession(): string {
		return this.patient.coordinates.professions.join(', ')
	}
	get isLowLevel(): boolean {
		return this.patient.coordinates.level === Level.Low
	}
	get isMediumLevel(): boolean {
		return this.patient.coordinates.level === Level.Medium
	}
	get isHighLevel(): boolean {
		return this.patient.coordinates.level === Level.High
	}
	private get ageBracket() :AgeBracket {
		if (!this.patient.coordinates.birth_date) return AgeBracket.None
		let ageNumber: number = (new Date().getTime() - this.patient.coordinates.birth_date.getTime()) / 31557600000

		if (ageNumber < 10) return AgeBracket.Child
		if (ageNumber < 18) return AgeBracket.Teenager
		return AgeBracket.Adult
	}
	private get user(): IUser {
		return user.user
	}
	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	//#endregion

	beforeUpdate() {
		let button = this.$refs['active-notes'] as HTMLButtonElement
		if (!button) return
		this.activeNotes = button.classList.contains('is-active')
	}

	updated() {
		let button = this.$refs['active-notes'] as HTMLButtonElement
		let notes = this.$refs['notes'] as HTMLElement
		if (!button || !notes) return
		if (this.activeNotes) {
			button.classList.add('is-active')
			notes.classList.add('is-active')
		} else {
			button.classList.remove('is-active')
			notes.classList.remove('is-active')
		}
	}

	//#region IAutoSaver implementation
	restoreValue(value: IPatient): void {
		if (!value) return

		value.deceased = !value.is_new && this.deceased
		value.blacklisted = !value.is_new && this.blacklisted

		patient.patientSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		let _patient:IPatient = Patient.getPatient(this.patient, this.oldValue)
		_patient.blacklisted = this.blacklisted
		let notification:INotification = Patient.getNotification(_patient)
		const { is_new } = _patient
		patient.savePatient({patient: _patient, callbackProgress: notif.progress})
		.then((newPatient: IPatient) => {
			super.initializeValue()
			notif.success(notification)
			this.submitted = false

			let currentItemId = this.$route.params.item_id
			let params = {pat_id: newPatient.id}
			let createArray: any = newPatient['create_array'][this.item]
			if (!!createArray && !!createArray[currentItemId]) {
				params['item_id'] = createArray[currentItemId]
			}
			if (newPatient.attachments.currents.length > 0) user.loadStorage()

			if (!is_new && !params['item_id']) return

			let route = {name: this.$router.currentRoute.name, params: params}
			this.$router.replace(route)
		})
		.catch(() => {
			super.initializeValue()
			let notification:INotification = { message: this.$t('vm.root.patients.patient.main.save.failed').toString(), actions: [], delay: 5000 }
			notif.warning(notification)
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		this.debouncerScroll = new Debouncer(this.handleScroll, 100)
		breadcrumb.updateLinked(true)
		this.initializePatient()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerScroll)
	}

	scrollToTop(): void {
		let element: HTMLElement = document.getElementById('target-to-top')
		if (!element) return

		// element.scrollTo(0, 0)
		element.scrollTop = 0
	}

	onDelete() {
		// Appelé quand on efface le dossier depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	triggerHandleScroll() {
		this.debouncerScroll.start()
	}

	private handleScroll(e:any) {
		if (!this.centralElement || !this.patientHeader) {
			this.centralElement = document.getElementsByClassName('l-central')[0]
			this.patientHeader = document.getElementsByClassName('patient-header')[0]
		}

		if (!this.centralElement || !this.patientHeader) return

		if (this.centralElement.scrollTop > this.patientHeader.scrollHeight) {
			this.patientHeader.classList.add("is-narrow")
		} else {
			this.patientHeader.classList.remove("is-narrow")
		}
	}

	onNavigate(item: string): void {
		this.item = item
	}

	onEdit(item: string): void {
		this.readonly = false
	}

	submitCancel(): void {
		if (this.disabled) {
			this.readonly = true
		} else {
			let cancelAction: IPopinAction = {
				label: this.$i18n.t('vm.root.patients.patient.main.cancel.action').toString(),
				title: this.$i18n.t('vm.root.patients.patient.main.cancel.title-button').toString(),
				callback: (data: any) => {
					this.cancelAction()
					return Promise.resolve()
				}
			}
			let patient:IPatient = Patient.getPatient(this.patient, this.oldValue)
			let messages: string[] = Patient.getUpdateList(patient)
			let updates: string = messages.join("</li><li>")
			let cancelPopin: IPopin = {
				title: this.$i18n.t('vm.root.patients.patient.main.cancel.title').toString(),
				content: this.$i18n.t('vm.root.patients.patient.main.cancel.content', {updates: updates}).toString(),
				action: cancelAction
			}
			this.warning(cancelPopin)
		}
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	duplicate() {
		let failedNotification: INotification = { message: this.$i18n.t('vm.root.patients.patient.main.duplicate.failed').toString(), actions: [], canClose: true, delay: 5000 }

		let duplicateAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.main.duplicate.label').toString(),
			title: this.$i18n.t('vm.root.patients.patient.main.duplicate.title').toString(),
			callback: (data: {patient: IPatient, member: MemberType}, updateLoading: any) => {
				let validData: boolean = !!data.patient.coordinates.last_name && !!data.patient.coordinates.first_name
				if (!validData) return Promise.reject()

				if (data.member !== MemberType.None) {
					data.patient.family.currents.push({
						id: '-1',
						link_id: this.patient.id,
						type: EnumsHelper.getRevertMember(data.member),
						last_name: '',
						first_name: '',
						deceased: false,
						birth_date: null,
						gender: null,
						email: ''
					})

				}
				updateLoading(true)

				return patient.duplicatePatient({patient: data.patient})
				.then(newPatient => {
					if (data.member !== MemberType.None) {
						let memberTemp: IMember = {
							id: 'none', //pour ne pas le sauvegarder
							link_id: newPatient.id,
							type: data.member,
							last_name: newPatient.coordinates.last_name,
							first_name: newPatient.coordinates.first_name,
							deceased: newPatient.coordinates.deceased,
							birth_date: newPatient.coordinates.birth_date,
							gender: newPatient.coordinates.gender,
							email: newPatient.coordinates.email
						}
						patient.addMemberTemp(memberTemp)
					}

					updateLoading(false)
					let goToDuplicateAction: INotificationAction = {
						label: this.$i18n.t('vm.root.patients.patient.main.duplicate.action-label').toString(),
						callback: () => { this.$router.push({name: 'patient-coordinates', params: {pat_id: newPatient.id}}) }
					}
					let notification: INotification = {
						message: this.$i18n.t('vm.root.patients.patient.main.duplicate.succeeded').toString(),
						actions: [goToDuplicateAction],
						canClose: true,
						delay: 10000
					}

					notif.success(notification)
					return Promise.resolve()
				}, () => {
					updateLoading(false)
					return Promise.reject()
				})
				.catch(() => {
					updateLoading(false)
					notif.error(failedNotification)
					return Promise.reject()
				})
			}
		}
		let duplicatePatient: IPatient = Patient.createNewPatient(this.configuration)
		duplicatePatient.coordinates.last_name = this.patient.coordinates.last_name
		duplicatePatient.coordinates.address = cloneDeep(this.patient.coordinates.address)
		duplicatePatient.coordinates.address.id = '-1'
		duplicatePatient.coordinates.phone1 = cloneDeep(this.patient.coordinates.phone1)
		duplicatePatient.coordinates.phone1.id = '-1'
		duplicatePatient.coordinates.email = this.patient.coordinates.email
		duplicatePatient.coordinates['update'] = true

		let duplicate: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.main.duplicate.title-popin').toString(),
			content: {component: 'popin-create-patient'},
			action: duplicateAction,
			options: {patient: duplicatePatient, member: MemberType.None},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(duplicate)
	}

	print() {
		let printAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.main.print.label').toString(),
			title: this.$i18n.t('vm.root.patients.patient.main.print.title').toString(),
			callback: (data: {printList: any}, updateLoading: any) => {
				if (!data.printList) return Promise.reject()

				updateLoading(true)
				let service = new PatientService()
				return service.print({pat_id: this.patient.id, params: this.printList})
				.then((result: any) => {
					updateLoading(false)

					let param: string = this.isDemo ? "?demo=true" : ""
					let label: string = `${this.patient.coordinates.first_name}-${this.patient.coordinates.last_name}`.replace(/\s+|[,\/]/g, "-")
					let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/print/patient/${result.print}/${label}.pdf${param}`

					window.open(url, '_blank').focus()
					return Promise.resolve()
				})
			}
		}

		let print: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.main.print.title-popin').toString(),
			content: {component: 'popin-print-patient'},
			action: printAction,
			options: {printList: this.printList, patient: this.patient}
		}

		popIn.info(print)
	}

	//#region Private methods
	private cancelAction(): void {
		this.oldValue.blacklisted = this.blacklisted
		this.readonly = true
		this.redirectBeforeCancel()
		super.cancel()
		this.submitted = false
	}

	private canSave(): boolean {
		if (!this.patient) return false

		let notification: INotification | false = Patient.getErrorNotification(this.patient)
		if (!notification) return true

		notif.error(notification)
		return false
	}

	private redirectBeforeCancel() {
		this.redirectConsultationBeforeCancel()
		this.redirectAttachmentBeforeCancel()
	}

	private redirectConsultationBeforeCancel() {
		if (!this.$router.currentRoute.name.startsWith('patient-consultation')) return

		let consultation: IConsultation = patient.consultation(this.$route.params.item_id)
		if (!!consultation && parseInt(consultation.id) !== -1) return

		this.$router.push({name: 'patient-consultations', params: this.$route.params})
	}

	private redirectAttachmentBeforeCancel() {
		if (!this.$router.currentRoute.name.startsWith('patient-attachment')) return

		let attachment: IAttachment = patient.attachment(this.$route.params.item_id)
		if (!!attachment && parseInt(attachment.id) !== -1) return

		this.$router.push({name: 'patient-attachments', params: this.$route.params})
	}

	private initializePatient(): void {
		this.printList = {
			consultations:[],
			antecedents:[],
			spheres:[],
			reasons: [],
			attachments:[],
			pediatry: false,
		}

		let payload = {pat_id: this.$route.params.pat_id}
		if (!payload.pat_id) {
			this.restoreValue(Patient.createNewPatient(super.configuration))
			this.initializeValue()
			this.readonly = false
		} else if (!patient.isLoaded || !patient.patient || patient.patient.id !== payload.pat_id) {
			patient.loadPatient(payload)
			.then(() => {
				this.initializeValue()
			})
			.catch((error) => {
				if (error.status === 406)
					this.askLicense()
			})
		} else {
			this.initializeValue()
		}
	}

	protected initializeValue() {
		super.initializeValue()
		if (this.patient.allow_sharing) return

		this.rgpdConfirm()
	}

	private rgpdConfirm(): void {
		let rgpdAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.main.rgpd.label').toString(),
			title: this.$i18n.t('vm.root.patients.patient.main.rgpd.title').toString(),
			callback: (data: {pat_id: string, date: Date}, updateLoading: any) => {
				if (!data.date) return Promise.reject()

				updateLoading(true)
				return patient.confirmPatient(data)
				.then(() => {
					updateLoading(false)
					this.patient.allow_sharing = true
					this.initializeValue()
					Backup.getInstance().add(this.patient)
					return Promise.resolve()
				}, () => {
					updateLoading(false)
					return Promise.reject()
				})
				.catch(() => {
					updateLoading(false)
					return Promise.reject()
				})
			}
		}
		let rgpd: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.main.rgpd.title').toString(),
			content: {component: 'popin-rgpd-patient'},
			action: rgpdAction,
			cancelAction: () => {
				this.$router.push({name: 'patients'})
			},
			options: {pat_id: this.patient.id, date: null}
		 }

		this.warning(rgpd)
	}

	private askLicense(): void {
		let licenseAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.main.buy-license.label').toString(),
			title: this.$i18n.t('vm.root.patients.patient.main.buy-license.title').toString(),
			callback: (data: any) => {
				this.$router.push({name: 'purchase-pack', params:{pack: (PackType.Unlimited as number).toString()}})
				return Promise.resolve()
			}
		}

		let license: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.main.buy-license.title').toString(),
			content: this.$i18n.t('vm.root.patients.patient.main.buy-license.content', {license: this.user.license}).toString(),
			action: licenseAction,
			cancelAction: () => {
				this.$router.push({name: 'patients'})
			},
			options: {}
		 }

		popIn.error(license)
	}
	//#endregion
}
