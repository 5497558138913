import { Vue, Watch } from 'vue-property-decorator'
import { isEqual, cloneDeep } from 'lodash'
import { AutoSaverState } from '@Enums/index'
import { Debouncer } from '@Models/index'
import { IPopin, IPopinAction, IConfiguration } from '@Store/types'
import { configuration, popIn } from '@Store/modules'

export abstract class AutoSaver extends Vue {

	state: AutoSaverState
	autoSave: boolean
	oldValue: any
	private readonly property: string
	private readonly debouncer: Debouncer = null

	constructor(property?: string, autosave: boolean = true) {
		super()
		this.autoSave = autosave
		this.property = property
		this.state = AutoSaverState.None
		this.debouncer = new Debouncer(this.save)
	}

	get disabled(): boolean {
		return this.state !== AutoSaverState.Updated
	}
	get propertyValue(): any {
		return !!this ? this[this.property] || {} : {}
	}
	get configuration(): IConfiguration {
		return configuration.configuration
	}

	abstract restoreValue(value: any): void
	abstract save(): void

	warning(popin: IPopin): void {
		popIn.warning(popin)
	}

	protected beforeRouteLeave(to: any, from: any, next: any, forceRefresh: boolean = false) {
		Debouncer.clear(this.debouncer)

		if (this.state === AutoSaverState.Updated) {
			this.leavePage(next, forceRefresh)
		} else {
			this.move(next, forceRefresh)
		}
	}

	protected cancel(): void {
		this.restoreValue(this.oldValue)
		this.initializeValue(this.oldValue)
	}

	protected initializeValue(value?: any): void {
		Debouncer.clear(this.debouncer)
		value = value === undefined ? this.propertyValue : value
		this.oldValue = null
		this.setOldValue(value)
		this.state = AutoSaverState.None
	}

	@Watch('propertyValue', {deep: true})
	protected updateSaver() {
		let value = this.propertyValue
        Debouncer.clear(this.debouncer)

        this.setOldValue(value)

        this.state = isEqual(this.oldValue, value) ? AutoSaverState.None : AutoSaverState.Updated
        if (this.state !== AutoSaverState.Updated) return

        this.startDebounce()
    }

    private setOldValue(value: any) {
        if (value === null)
            return

		if (!!this.oldValue) {
			this.oldValue = cloneDeep(this.oldValue)
			return
		}

		this.oldValue = cloneDeep(value)
	}

	private move(next: any, forceRefresh: boolean): void {
		this.restoreValue(this.oldValue)
		if (forceRefresh) {
			this.oldValue = null
		}
		next()
	}

	private leavePage(next: any, forceRefresh: boolean): void {
		let logoutAction: IPopinAction = {
			label: this.$i18n.t('vm.components.auto-saver.leave.label').toString(),
			title: this.$i18n.t('vm.components.auto-saver.leave.title').toString(),
			callback: (data: any) => {
				this.move(next, forceRefresh)
				return Promise.resolve()
			}
		}
		let logout: IPopin = {
			title: this.$i18n.t('vm.components.auto-saver.leave.title').toString(),
			content: this.$i18n.t('vm.components.auto-saver.leave.content').toString(),
			action: logoutAction,
			cancelAction: () => {
				next(false)
				this.startDebounce()
			}
		}

		// Pour laisser le temps à une autre popin (logout par exemple) de se fermer
		setTimeout(() => { this.warning(logout) }, 100);
	}

	// private isNew(): boolean {
    //     return !this.oldValue || parseInt(this.oldValue.id) === -1
    // }

	private startDebounce(): void {
		this.debouncer.delay = this.configuration.auto_save === -1 ? undefined : this.configuration.auto_save
		this.debouncer.start(this.configuration.auto_save !== -1 && this.autoSave)
    }
}
