export const context = {
    title: "Remplissage du cabinet",
    complete: "compléter",
    'page-enabled': {
        title: "Page activée",
        description: "Activez cette page pour permettre la prise de rdv en ligne",
        'link-title': "Activez votre page de planning en ligne"
    },
    url: {
        title: "Url de la page",
        description: "L'url doit contenir votre nom et celui de la ville d'exercice",
        'link-title': "Générez l'url de votre page de planning en ligne"
    },
    address: {
        title: "Adresse postale",
        description: "Renseignez au mieux l'adresse postale de votre cabinet",
        'link-title': "Renseignez au mieux les différents éléments de votre adresse postale de votre cabinet"
    },
    phone: {
        title: "Téléphone",
        description: "Indiquez votre téléphone pour d'éventuelles urgences",
        'link-title': "Renseignez le numéro de téléphone lié à ce cabinet"
    },
    presentation: {
        title: "Message de présentation",
        description: "Présentez vous ainsi que le déroulement d'une consultation par exemple",
        'link-title': "Présentez vous à vos patients sur votre page de planning en ligne"
    },
    rejection: {
        title: "Message de refus",
        description: "Gagnez du temps avec un message de refus par défaut",
        'link-title': "Indiquez un message de refus par défaut pour votre planning en ligne"
    },
    schedule: {
        title: "Horaires d'ouverture",
        description: "Évitez les erreurs et les surprises en indiquant vos horaires",
        'link-title': "Saisissez vos horaires d'ouverture de votre cabinet"
    },
    access: {
        title: "Accès à votre cabinet",
        description: "Facilitez la vie de vos patients en indiquant les accès à votre cabinet",
        'link-title': "Indiquez les différents moyens d'accès à votre cabinet"
    }
}