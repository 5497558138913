import Render from '@Views/components/super-panel/super-panel.html'
import { Vue, Component } from 'vue-property-decorator'
import {IPopin, IPopinAction } from '@Store/types'
import { PopinType } from '@Enums/index'
import * as SuperPanelLayout from './layout/index'
import { superPanel } from '@Store/modules'

@Render
@Component({
    components: {
        'super-panel-quick-event': SuperPanelLayout.SuperPanelQuickEvent,
        'super-panel-merge': SuperPanelLayout.SuperPanelMerge
    }
})
export class SuperPanel extends Vue {

    loading: boolean = false
    submitted: boolean = false
    title: string = null
    component: string = null

	//#region Getters
    get type(): PopinType {
		return superPanel.type
    }
    get popin(): IPopin {
		return superPanel.popin
    }
    get options(): any {
		return superPanel.options
    }
    get action(): IPopinAction {
		return superPanel.popin.action
    }
    get hasComponent(): boolean {
        return typeof this.popin.content !== 'string'
    }
    private get cancelAction(): () => any {
		return superPanel.popin.cancelAction
    }
    //#endregion

    display(): boolean {
		return this.type !== PopinType.None && !!this.popin
	}

	close(): void {
        if (!!this.cancelAction) {
            this.cancelAction()
        }
        this.submitted = false
		superPanel.clear()
    }

    callAction(): void {
        this.submitted = true
        this.action.callback(superPanel.options, this.updateLoading)
        .then(() => {
            this.submitted = false
            superPanel.clear()
        })
    }

    canCallAction(): boolean {
        if (!this.action.condition) return true
        return this.action.condition(superPanel.options)
    }

    buttonClass(): any {
        switch(this.type) {
            case PopinType.Error:
                return 'btn-danger'
            case PopinType.Warning:
                return 'btn-warning'
            case PopinType.Success:
                return 'btn-success'
            case PopinType.Info:
                return 'btn-info'
            default:
                return 'btn-default'
        }
    }

    popinClass(): any {
        let popinClass:string = this.popin.isWide ? 'is-wide ' : ''
        switch(this.type) {
            case PopinType.Error:
                return `${popinClass}is-danger`
            case PopinType.Warning:
                return `${popinClass}is-warning`
            case PopinType.Success:
                return `${popinClass}is-success`
            case PopinType.Info:
                return `${popinClass}is-info`
            default:
                return `${popinClass}is-default`
        }
    }

    private updateLoading(loading: boolean): void {
        this.loading = loading
    }
}
