import Render from '@Views/root/user/office/parameters.html'
import { isEqual } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IOffice, ISettings, IBreadcrumbItem } from '@Store/types'
import { InputRangeType, IDropdownItem, InputDocument } from '@Components/index'
import {DropdownHelper, EnumsHelper} from '@Helpers/index'
import { PackType, Gender } from '@Enums/index'
import * as Filter from '@Filters/index'
import { breadcrumb, office, planning, user } from '@Store/modules'
import {FreeSpaceMode} from "@Enums/free-space-mode"

@Render
@Component({})
export class Parameters extends Vue {

	suggestion: string = null

	@Prop() office: IOffice

	get isGenerating(): boolean {
		return office.isGenerating
	}
	get settings(): ISettings {
		return office.setting(this.office.id)
	}
	get oldSettings(): ISettings {
		return office.oldSettings(this.office.id)
	}
	get suggestionItems() : IDropdownItem[] {

		let result: IDropdownItem[] = []
		for (let i = 1; i <= 5; i++) {
			result.push({
				value: this.getSuggest(this.$i18n.t(`vm.root.user.office.parameters.suggest${i}`).toString()),
				label: this.$i18n.t('vm.root.user.office.parameters.suggestion.item', {number: i}).toString()
			})
		}
		return result
	}
	get planningItems() : IDropdownItem[] {
		return DropdownHelper.toDropdownItems(planning.plannings)
	}
	get freeSpaceModeItems() : IDropdownItem[] {
		return [
			{value: FreeSpaceMode.Classic, label: EnumsHelper.freeSpaceModeToString(FreeSpaceMode.Classic)},
			{value: FreeSpaceMode.Optimized, label: EnumsHelper.freeSpaceModeToString(FreeSpaceMode.Optimized)},
			{value: FreeSpaceMode.SemiOptimized, label: EnumsHelper.freeSpaceModeToString(FreeSpaceMode.SemiOptimized)}
		];
	}
	get rangeType(): InputRangeType {
		return InputRangeType.RangeDateTime
	}
	get to(): {name: string, params: {pack: PackType}} {
        return {name: 'purchase-pack', params: {pack: PackType.Sms}}
	}
	get lightOptions(): any {
        return InputDocument.lightOptions()
    }

	mounted() {
		this.$emit('navigate', 'settings-office')
		this.initializeBreadcrumb()
	}

	generate(): void {
		office.generateUrl({ofiId: this.office.id, sttId: this.settings.id})
		.then(result => {
			this.settings.url = result.url
		})
	}

	@Watch('suggestion')
	updateMessage() {
		if (!this.suggestion) return

		this.settings.message = this.suggestion
	}

	@Watch('settings', {deep: true})
	updateSettings() {
		if (!this.settings) return

		// On retire les horaires en cours si finalement ils sont restaurés
		this.office.settings.currents = []

		if (this.oldSettings.id !== this.settings.id || isEqual(this.oldSettings, this.settings)) return

		this.settings['update'] = true
		this.office.settings.currents.push(this.settings)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.web-page', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}

	private getSuggest(suggest: string): string {
		let session = office.sessions(this.office.id)[0]
		suggest = suggest.replace(/--GENDER--/g, user.currentUser.coordinates.gender === Gender.Man ? this.$i18n.t('general.mister.short').toString() : this.$i18n.t('general.madam.short').toString())
		suggest = suggest.replace(/--FIRST_NAME--/g, user.currentUser.coordinates.first_name)
		suggest = suggest.replace(/--LAST_NAME--/g, user.currentUser.coordinates.last_name)
		suggest = suggest.replace(/--CITY--/g, this.office.coordinates.address.city)
		suggest = suggest.replace(/--ZIPCODE--/g, this.office.coordinates.address.zipcode)
		suggest = suggest.replace(/--TITLE--/g, user.currentUser.coordinates.title)
		suggest = suggest.replace(/--PRONOUN--/g, user.currentUser.coordinates.gender === Gender.Man ? this.$i18n.t('general.mister.pronoun').toString() : this.$i18n.t('general.madam.pronoun').toString())
		suggest = suggest.replace(/--DURATION--/g, `${!!session ? session.duration : 45}`)
		suggest = suggest.replace(/--PHONE--/g, `<a href='tel:${this.office.coordinates.phone1.number}'>${Filter.formatPhone(this.office.coordinates.phone1.number)}</a>`)

		return suggest
	}
}
