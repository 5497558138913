import Render from '@Views/components/layout/infinite-scroll.html'
import { Vue, Watch, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class InfiniteScroll extends Vue {

    private listEndObserver: IntersectionObserver

	@Prop() loadMore: () => any
	@Prop({default: "div"}) tag: string
	@Prop({default: ""}) classTag: string
	@Prop({default: true}) initialized: boolean
	@Prop({default: true}) canLoadMore: boolean
	@Prop({default: false}) isLoading: boolean

	mounted() {}

    beforeDestroy() {
        if (!this.listEndObserver) return

        this.listEndObserver.disconnect()
	}

    @Watch('initialized')
    initializeObserver() {
        if (this.initialized) return
        if (this.listEndObserver) return

        this.$nextTick().then(() => {
            this.setUpInterSectionObserver()
        })
    }

    private setUpInterSectionObserver(): void {
        let options: IntersectionObserverInit = {
            root: this.$refs["scrollContainer"] as Element,
            // rootMargin: "20px"
        }
        this.listEndObserver = new IntersectionObserver(
            (x: any) => { this.handleIntersection(x, this) },
            options
        )

		let sentinel: Element = this.$refs['sentinel'] as Element
        this.listEndObserver.observe(sentinel)
    }

    private handleIntersection([entry], vm) {
        if (!entry.isIntersecting) return
		if (!this.canLoadMore || this.isLoading) return

		this.loadMore()
    }
}
