export const context = {
    search: {
        title: "Votre recherche ne donne rien ?",
        paragraph1: {
            text: "Vous pouvez ajuster les {0} pour rechercher votre patient selon d'autres critères.",
            strong: "filtres"
        },
        paragraph2: {
            text: "Si votre patient a un nom ou un prénom composé, saisissez cette information entre guillemet, comme ceci : {0} ou {1}.",
            sample1: '"jean claude" durand',
            sample2: 'josé "dos santos"'
        },
        paragraph3: {
            text: "Si votre recherche ne donne toujours rien, peut-être est-il temps de créer un {0} ?",
            link: "nouveau dossier",
            title: "Créer un nouveau dossier patient"
        }
    },
    export: {
        title: "Vous souhaitez communiquer avec vos patients ?",
        'more-filter': "Afficher également les patients…",
        blacklisted: "…blacklistés",
        deceased: "…décédés",
        paragraph: {
            text: "Vous pouvez {0} les coordonnées de vos patients. Le fichier {1} ainsi obtenu vous permettra une communication groupée, par mail, avec votre client mail habituel.",
            link: "exporter",
            title: "Exportez les coordonnées de vos patients",
            abbr: "CSV"
        }
    },
    campaign: {
        title: "Soyez plus efficace avec une campagne SMS.",
        paragraph: {
            text: "L'envoi de mail n'est pas forcément la meilleure façon de communiquer. Des études montrent que le taux d'ouverture d'un {0} est de 95 %, là où celui d'un mail est de 30 %. Vous vous assurez donc la lecture de votre message {1}.",
            abbr: "SMS",
            link: "en l'envoyant par SMS",
            title: "Créez une nouvelle campagne SMS"
        }
    }
}