export const main = {
    'co-working': 'Co-travail',
    home: 'Dashboard',
    patients: 'Patients',
    export: 'Export / Listing coordonnées',
	merge: 'Fusion des doublons',
    campaign: 'Campagne SMS',
    patient: 'Dossier patient',
    attachment: 'Pièce jointe | Pièce jointe | Pièces jointes',
    consultation: 'Consultation | Consultation | Consultations',
    antecedent: 'Antécédent | Antécédent | Antécédents',
    sphere: 'Sphère | Sphère | Sphères',
    cranio: 'Cranio-sacré',
    'exclusion-test': "Test d'exclusion | Test d'exclusion | Tests d'exclusion",
    'follow-up': 'Suivi',
    forms: "Questionnaire personnalisé | Questionnaire personnalisé | Questionnaires personnalisés",
    schema: "Schéma corporel",
    walton: "Diagramme de Walton",
    reason: "Historique",
    pediatrics: "Périnatalité",
    neonatal: "Néonatal",
    documents: "Facture et attestation",
    relaunch: "Relance",
    family: "Famille",
    "no-user": "utilisateur à sélectionner",
    "plannings-secretary": "Plannings ({user})",
    "plannings-main": "Plannings",
    planning: "Planning",
    'valid-event': "Rendez-vous à valider",
    event: "Rendez-vous",
    'event-relaunch': "Relance",
    recurrency: "Récurrence",
    help: "Aide",
    'closed-tickets': "Tickets fermés",
    'new-ticket': "Nouveau ticket",
    ticket: "Ticket",
    user: "Compte utilisateur",
    email: "Email",
    password: "Mot de passe",
    offices: "Cabinet | Cabinet | Cabinets",
    formations: "Formation | Formation | Formations",
    organizations: "Association | Association | Associations",
    absence: "Absence / Congés",
    accesses: "Accès | Accès | Accès",
    'web-page': "Page web",
    schedule: "Horaires",
    'specific-schedule': "Horaires spécifiques",
    sessions: "Type de consultation | Type de consultation | Types de consultation",
    statistics: "Statistiques",
    accounting: "Finances",
    reference: "Référence",
    top10: "Top 10",
    colleagues: "Collègue | Collègue | Collègues",
    immobilization: "Immobilisation",
    receipt: "Recette",
    spend: "Dépense",
    books: "Journal",
    remittances: "Remise | Remise | Remises",
    parameters: "Paramètres",
    notifications: "Notifications",
    reminder: "Rappel de rdv",
    confirmation: "Confirmation de rdv",
    'follow-up-parameters': 'Suivi de consultation',
    template: 'Modèles de courrier',
    letters: 'Courriers',
    shop: 'Boutique',
    purchase: "Achat",
    bulk: "Achat groupé",
    referral: "Parrainage",
    gift: "Planning en ligne offert",
    gift1: "Premier mois offert",
    gift2: "Deuxième mois offert",
    gift3: "Dix mois offerts",
}
