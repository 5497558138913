import { IPatient, ICustomer } from '@Store/types'
import { Patient } from "./patient"

export class Customer {
	public static toPatient(customer: ICustomer): IPatient {
		let result: IPatient = Patient.createNewPatient(null)
		result.is_new = true
		result.id = '-1'
		result.blacklisted = false
		result.deceased = false

		if (!customer) return result
		
		result.coordinates.last_name = customer.last_name
		result.coordinates.first_name = customer.first_name
		result.coordinates.gender = customer.gender
		result.coordinates.email = customer.email
		result.coordinates.phone1.number = customer.phone

		return result
	}
}