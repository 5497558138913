<div class="input-frame">
	<label class="label" :for="id" :data-text="label">
		<span>{{label}}</span>
	</label>
	<slider :value="value" :min="min" :max="max" :disabled="disabled"
			:interval="step" :included="true" :marks="marks" :data="data"
			:tooltip-formatter="tooltipFormatter" :hide-label="hideLabel"
			:height="8" @change="handleInput" :process-style="processStyle">
		<template v-slot:dot="{ value, focus }">
			<div class="vue-slider-dot-handle">
				<svg width="1em" class="svg-middle" viewBox="0 0 640 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#chevrons-left-right"/>
				</svg>
			</div>
		</template>
	</slider>
</div>
