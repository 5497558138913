import Render from '@Views/root/user/office/access/main.html'
import { isEqual, cloneDeep } from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IOffice, IAccess } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { AccessType } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import {Office} from "@Models/office"

@Render
@Component({})
export class Main extends Vue {

	messageLength: number = Office.maxAccessLength
	private messageWarning: boolean = false
	private oldAccess: IAccess = null

	@Prop() submitted: boolean
	@Prop() access: IAccess
	@Prop() office: IOffice

	//#region getter
	get hasNameError(): boolean {
		return this.submitted && !this.access.name
	}
	get typeItems(): IDropdownItem[] {
		return [
			{value: AccessType.Other, label: EnumsHelper.accessToString(AccessType.Other)},
			{value: AccessType.Metro, label: EnumsHelper.accessToString(AccessType.Metro)},
			{value: AccessType.Rer, label: EnumsHelper.accessToString(AccessType.Rer)},
			{value: AccessType.Tramway, label: EnumsHelper.accessToString(AccessType.Tramway)}
		]
	}
	get hasLine(): boolean {
		return this.access.type !== AccessType.Other
	}
	get lineItems(): IDropdownItem[] {
		switch(this.access.type) {
			case AccessType.Rer:
				return [
					{value:'A', label: 'Ligne A'},
					{value:'B', label: 'Ligne B'},
					{value:'C', label: 'Ligne C'},
					{value:'D', label: 'Ligne D'},
					{value:'E', label: 'Ligne E'}
				]
			case AccessType.Metro:
				return [
					{value:'1', label:'Ligne 1'},
					{value:'2', label:'Ligne 2'},
					{value:'3', label:'Ligne 3'},
					{value:'3b', label:'Ligne 3b'},
					{value:'4', label:'Ligne 4'},
					{value:'5', label:'Ligne 5'},
					{value:'6', label:'Ligne 6'},
					{value:'7', label:'Ligne 7'},
					{value:'7b', label:'Ligne 7b'},
					{value:'8', label:'Ligne 8'},
					{value:'9', label:'Ligne 9'},
					{value:'10', label:'Ligne 10'},
					{value:'11', label:'Ligne 11'},
					{value:'12', label:'Ligne 12'},
					{value:'13', label:'Ligne 13'},
					{value:'14', label:'Ligne 14'},
					{value:'15', label:'Ligne 15'},
					{value:'16', label:'Ligne 16'},
					{value:'17', label:'Ligne 17'},
					{value:'18', label:'Ligne 18'}
				]
			case AccessType.Tramway:
				return [
					{value:'1', label:'Ligne 1'},
					{value:'2', label:'Ligne 2'},
					{value:'3', label:'Ligne 3'},
					{value:'3a', label:'Ligne 3a'},
					{value:'4', label:'Ligne 4'},
					{value:'5', label:'Ligne 5'},
					{value:'6', label:'Ligne 6'},
					{value:'7', label:'Ligne 7'},
					{value:'8', label:'Ligne 8'},
					{value:'9', label:'Ligne 9'},
					{value:'10', label:'Ligne 10'}
				]
			default:
				return []
		}
	}
	get elementId(): string {
		return parseInt(this.access.id) === -1 ? this.access.create_id : this.access.id
	}
	//#endregion

	mounted() {
		this.oldAccess = cloneDeep(this.access)
		this.updateMessage()
	}

	deleteAccess(): void {
		this.office.accesses.deleted.push(this.access)
		this.office.accesses.currents = this.office.accesses.currents.filter(a => { return a.id !== this.access.id || a.create_id !== this.access.create_id } )
	}

	@Watch('access.type')
	updateType() {
		if (this.oldAccess.id !== this.access.id) return
		this.access.line = undefined
	}

	@Watch('access', {deep: true})
	updateAccess() {
		// On retire la session en cours pour ne pas l'écrire 2 fois
		if (this.oldAccess.id !== this.access.id || isEqual(this.oldAccess, this.access)) return

		this.access['update'] = true
	}

	@Watch('access.description')
	private updateMessage() {
		let result = Office.maxAccessLength
		if (!!this.access && !!this.access.description) {
			let text: string = this.access.description.trim()
			result = Office.maxAccessLength - text.length
		}
		this.messageWarning = result < 0
		this.messageLength = Math.max(0, result)
	}
}
