import { getModule, VuexModule, Module, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { PopinType } from "@Enums/popin"
import { IPopin } from "@Store/types"

@Module({
	name: 'super-panel',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class SuperPanelModule extends VuexModule {
	popin: IPopin = null
    type: PopinType = PopinType.None

	get isOpen(): boolean {
		return this.type !== PopinType.None
	}
	get options(): any {
		return (this.popin && this.popin.options) || {}
	}
	
	@Mutation
	clear() {
		this.type = PopinType.None
		this.popin = null
	}

	@Mutation
	show(context: {popin: IPopin, type: PopinType}) {
		this.type = context.type
		this.popin = context.popin
	}
}

export const superPanel = getModule(SuperPanelModule)
// export const superPanel = new SuperPanelModule({ store, name: "superPanel" })