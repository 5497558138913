import { BaseService } from '@Services/index'
import { IPlanning, IEvent, IEventFilter, FreeSlotPayload } from '@Store/types'
import { DateHelper } from '@Helpers/index'

export class EventService extends BaseService {
	
	public getNotificationEvent(): Promise<any> {
		return super.get(`/notification/event/`)
	}

	public getEventList(start: Date, end: Date, calendar: IPlanning): Promise<any> {
		return super.get(`/planning/${calendar.id}/events`, { start_date: DateHelper.getDateString(start), end_date: DateHelper.getDateString(end) })
	}

	public getEvent(evt_id: string): Promise<any> {
		return super.get(`/event/${evt_id}`)
	}

	public searchEvent(query: string, filter: IEventFilter): Promise<any> {
		return super.post(`/search/events/${query}`, {filter: filter})
	}
	
	public getFreeSlot(payload: FreeSlotPayload): Promise<any> {
		return super.post(`/free-slot/`, payload)
	}
	
	public getNextFreeSlot(payload: FreeSlotPayload): Promise<any> {
		return super.put(`/free-slot/`, payload)
	}

	public sendGoogleList(eventList: any[]): Promise<any> {
		if (eventList === undefined || eventList.length === 0) return;
		return super.put("/google/event/", eventList)
	}
	
	public deleteGoogle(event: any): Promise<any> {
		return super.delete(`/google/event/${event.id}`)
	}

	public save(event: IEvent): Promise<any> {
		if (parseInt(event.id) === -1) 
			return super.post('/event', event)

		return super.put(`/event/${event.id}`, event)
	}

	public delete(id: string): Promise<any> {
		return super.delete(`/event/${id}`)
	}

	public cancel(event: IEvent): Promise<any> {
		return super.put(`/cancel/event/${event.id}`, event)
	}

	public confirm(event: IEvent): Promise<any> {
		return super.put(`/valid-event/${event.id}`, event)
	}

	public refuse(evt_id: string, refuse: {cst_id: string, text: string}): Promise<any> {
		return super.delete(`/valid-event/${evt_id}`, {params: refuse})
	}

}