<div class="l-loading" v-if="isLoading">
	<spinner class="l-loading-spinner svg-block svg-center text-primary"/>
	<p>{{ $t('vm.root.help.ticket.loading') }}</p>
</div>
<div v-else-if="ticket" class="wrapper ticket ticket-frame">

	<h1 class="ticket-header ticket-title">
		<div>
			<div class="ticket-title-small">{{ $t('vm.root.help.ticket.title') }} &bull; {{ $tc('vm.root.help.ticket.message-count', ticket.comments.length) }}</div>
			<div>{{ticket.title}}</div>
		</div>
	</h1>

	<template v-for="(comments, day, mainIndex) in commentsGrouped">

		<div class="ticket-separator p-like">
			<time :datetime="mainDate(comments[0].date_create)" class="ticket-separator-text text-sm">{{day}}</time>
		</div>

		<section v-for="(comment, index) in comments" :key="comment.id" class="ticket-comment p-like" :id="comment.isLast ? 'last' : `comment_${mainIndex}_${index}`">

			<figure class="ticket-comment-avatar">
				<svg v-if="comment.admin" width="1em" class="text-primary" viewBox="0 0 90 90" role="img" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#logo-min"/>
				</svg>
				<avatar v-else :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
				<figcaption v-if="comment.admin" class="sr-only">{{ $t('vm.root.help.ticket.from-osteo2ls') }}</figcaption>
				<figcaption v-else class="sr-only">{{ $t('vm.root.help.ticket.from-you') }}</figcaption>
			</figure>

			<div class="ticket-comment-content">
				<h2 class="ticket-comment-title h-reset" :class="{'text-primary text-bold': comment.unread}">
					<strong v-if="comment.admin">{{ $t('vm.root.help.ticket.from-osteo2ls') }}</strong>
					<strong v-else>{{ $t('vm.root.help.ticket.from-you') }}</strong>
					<span class="text-xs">&bull; <time :datetime="comment.date_create.toISOString()">{{$d(comment.date_create, 'LT')}}</time></span>
					<span class="text-xs" v-if="comment.unread">&bull; {{ $t('vm.root.help.ticket.new-message') }}</span>
				</h2>
				<p class="ticket-comment-text" v-html="comment.content"/>
			</div>

		</section>

	</template>

	<control-bar v-if="!isClosed" id="control-bar">
		<form class="ticket-form" @submit.prevent="submitPost">

			<textarea-frame id="comment" rows="1" v-model="comment" :auto-focus="true"
				:placeholder="$t('vm.root.help.new.comment.placeholder')"
				:error-test="hasCommentError"
				:error-msg="$t('vm.root.help.new.comment.error-msg')"
				:title="$t('vm.root.help.new.comment.title')"/>

			<button-action type="submit" btn-class="btn-primary btn--input-shape text-md ticket-form-submit"
				:loading="isPosting" :title="$t('vm.root.help.new.send.title')">
				<span class="sr-only">{{ $t('vm.root.help.new.send.label') }}</span>
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
				</svg>
			</button-action>

		</form>
	</control-bar>

</div>
