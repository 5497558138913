<div v-if="options" class="patient-popin-print">
	<p class="text-bold">{{ $t('vm.components.popin.print-patient.text') }}</p>

	<checkbox-frame id="all-elements" :value="isFull" @input="selectAll">
		<span>{{ $tc('vm.components.popin.print-patient.all-elements', consultations.length + antecedents.length + spheres.length + attachments.length + 1) }}</span>
	</checkbox-frame>

	<hr>

	<template v-if="consultations.length > 0">
		<h2 class="text-rg">{{ $tc('vm.components.popin.print-patient.consultations-title', options.printList.consultations.length) }}</h2>
		<checkbox-frame v-if="consultations.length > 1" id="consultations" :value="isConsultationsFull" @input="selectAllConsultations">
			<strong>{{ $tc('vm.components.popin.print-patient.all-consultations', consultations.length) }}</strong>
		</checkbox-frame>
		<checkbox-frame v-for="consultation in consultations" :key="`consultation-${consultation.id}`" :id="`consultation-${consultation.id}`" :value="isConsultationSelected(consultation.id)" @input="selectConsultation(consultation.id)">
			<span>{{ $t('vm.components.popin.print-patient.consultation-title', {date: $d(consultation.act_date, 'L'), reason: consultation.reason.join(' ')}) }}</span>
		</checkbox-frame>
		<hr>
	</template>

	<template v-if="antecedents.length > 0">
		<h2 class="text-rg">{{ $tc('vm.components.popin.print-patient.antecedents-title', options.printList.antecedents.length) }}</h2>
		<checkbox-frame v-if="antecedents.length > 1" id="antecedents" :value="isAntecedentsFull" @input="selectAllAntecedents">
			<strong>{{ $tc('vm.components.popin.print-patient.all-antecedents', antecedents.length) }}</strong>
		</checkbox-frame>
		<checkbox-frame v-for="antecedent in antecedents" :key="`antecedent-${antecedent.id}`" :id="`antecedent-${antecedent.id}`" :value="isAntecedentSelected(antecedent.id)" @input="selectAntecedent(antecedent.id)">
			<span>{{ $t('vm.components.popin.print-patient.antecedent-title', {date: $d(antecedent.act_date, 'L'), type: antecedent.type, title: antecedent.title}) }}</span>
		</checkbox-frame>
		<hr>
	</template>

	<template v-if="spheres.length > 0">
		<h2 class="text-rg">{{ $tc('vm.components.popin.print-patient.spheres-title', options.printList.spheres.length) }}</h2>
		<checkbox-frame v-if="spheres.length > 1" id="spheres" :value="isSpheresFull" @input="selectAllSpheres">
			<strong>{{ $tc('vm.components.popin.print-patient.all-spheres', spheres.length) }}</strong>
		</checkbox-frame>
		<checkbox-frame v-for="sphere in spheres" :key="`sphere-${sphere.id}`" :id="`sphere-${sphere.id}`" :value="isSphereSelected(sphere.id)" @input="selectSphere(sphere.id)">
			<span>{{ $t('vm.components.popin.print-patient.sphere-title', {date: $d(sphere.act_date, 'L'), type: sphere.type, title: sphere.title}) }}</span>
		</checkbox-frame>
		<hr>
	</template>

	<template v-if="reasons.length > 0">
		<h2 class="text-rg">{{ $tc('vm.components.popin.print-patient.reasons-title', options.printList.reasons.length) }}</h2>
		<checkbox-frame v-if="reasons.length > 1" id="reasons" :value="isReasonsFull" @input="selectAllReasons">
			<strong>{{ $tc('vm.components.popin.print-patient.all-reasons', reasons.length) }}</strong>
		</checkbox-frame>
		<checkbox-frame v-for="reason in reasons" :key="`reason-${reason.id}`" :id="`reason-${reason.id}`" :value="isReasonSelected(reason.id)" @input="selectReason(reason.id)">
			<span>{{ $t('vm.components.popin.print-patient.reason-title', {title: reason.title}) }}</span>
		</checkbox-frame>
		<hr>
	</template>

	<template v-if="attachments.length > 0">
		<h2 class="text-rg">{{ $tc('vm.components.popin.print-patient.attachments-title', options.printList.attachments.length) }}</h2>
		<checkbox-frame v-if="attachments.length > 1" id="attachments" :value="isAttachmentsFull" @input="selectAllAttachments">
			<strong>{{ $tc('vm.components.popin.print-patient.all-attachments', attachments.length) }}</strong>
		</checkbox-frame>
		<checkbox-frame v-for="attachment in attachments" :key="`attachments-${attachment.id}`" :id="`attachments-${attachment.id}`" :value="isAttachmentSelected(attachment.id)" @input="selectAttachment(attachment.id)">
			<span>{{ $t('vm.components.popin.print-patient.attachment-title', {date: $d(attachment.act_date, 'L'), name: attachment.name}) }}</span>
		</checkbox-frame>
		<hr>
	</template>

	<checkbox-frame id="pediatry" v-model="options.printList.pediatry">
		<span>{{ $t('vm.components.popin.print-patient.pediatry') }}</span>
	</checkbox-frame>

</div>
