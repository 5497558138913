import Vue from 'vue'
import { store } from '@Store'
import { router } from '@Router'
import { i18n } from '@I18n/index'
import * as Component from '@Components/index'
import * as Directive from '@Directives/index'
import * as Filter from '@Filters/index'
import App from './app'
import '@Assets/scss/skins/default/skin.scss'
import '@Assets/scss/skins/light/skin.scss'
import '@Assets/scss/skins/dark/skin.scss'
import '@Assets/scss/main.scss'
import '@Assets/svg/flag-defs.svg'
import VueApexCharts from 'vue-apexcharts'


Vue.use(require('vue-cookies'))

Vue.component('apexchart', VueApexCharts)
Vue.component('avatar', Component.Avatar)
Vue.component('anonymous', Component.Anonymous)
Vue.component('dropdown', Component.Dropdown)
Vue.component('dropdown-actions', Component.DropdownActions)
Vue.component('dropdown-editable', Component.DropdownEditable)
Vue.component('dropdown-frame', Component.DropdownFrame)
Vue.component('dropdown-editable-frame', Component.DropdownEditableFrame)
Vue.component('misc-item', Component.MiscItem)
Vue.component('input-range', Component.InputRange)
Vue.component('input-date-frame', Component.InputDateFrame)
Vue.component('input-phone-frame', Component.InputPhoneFrame)
Vue.component('input-nir-frame', Component.InputNirFrame)
Vue.component('input-address-frame', Component.InputAddressFrame)
Vue.component('input-password-frame', Component.InputPasswordFrame)
Vue.component('input-password', Component.InputPassword)
Vue.component('input-color-frame', Component.InputColorFrame)
Vue.component('input-color', Component.InputColor)
Vue.component('input-frame', Component.InputFrame)
Vue.component('slider-frame', Component.SliderFrame)
Vue.component('input-document', Component.InputDocument)
Vue.component('input-document-frame', Component.InputDocumentFrame)
Vue.component('textarea-frame', Component.TextareaFrame)
Vue.component('checkbox-frame', Component.CheckboxFrame)
Vue.component('switch-frame', Component.SwitchFrame)
Vue.component('togglebox', Component.ToggleBox)
Vue.component('togglebox-frame', Component.ToggleBoxFrame)
Vue.component('toggledate-frame', Component.ToggleDateFrame)
Vue.component('radio-frame', Component.RadioFrame)
Vue.component('button-action', Component.ButtonAction)
Vue.component('button-to-top', Component.ButtonToTop)
Vue.component('count-up', Component.CountUp)
Vue.component('delete-item', Component.DeleteItem)
Vue.component('spinner', Component.Spinner)
Vue.component('container', Component.Container)
Vue.component('central', Component.Central)
Vue.component('master', Component.Master)
Vue.component('a-side', Component.Aside)
Vue.component('breadcrumb', Component.Breadcrumb)
Vue.component('context', Component.Context)
Vue.component('control-bar', Component.ControlBar)
Vue.component('collapsible-help', Component.CollapsibleHelp)
Vue.component('collapsible-panel', Component.CollapsiblePanel)
Vue.component('misc-collapsible-panel', Component.MiscCollapsiblePanel)

Vue.directive('focus', Directive.focus)
Vue.directive('toggle', Directive.toggle)
Vue.directive('click-outside', Directive.clickOutside)

Vue.filter('age', Filter.age)
Vue.filter('smsClass', Filter.smsClass)
Vue.filter('timeAgo', Filter.timeAgo)
Vue.filter('since', Filter.since)
Vue.filter('inTime', Filter.inTime)
Vue.filter('formatPhone', Filter.formatPhone)
Vue.filter('formatNir', Filter.formatNir)
Vue.filter('member', Filter.member)
Vue.filter('members', Filter.members)
Vue.filter('currency', Filter.currency)
Vue.filter('situation', Filter.situation)
Vue.filter('gender', Filter.gender)
Vue.filter('profile', Filter.profile)
Vue.filter('profileIcon', Filter.profileIcon)
Vue.filter('level', Filter.level)
Vue.filter('followUpLevel', Filter.followUpLevel)
Vue.filter('mark', Filter.mark)
Vue.filter('eva', Filter.eva)
Vue.filter('walton', Filter.walton)
Vue.filter('question', Filter.question)
Vue.filter('form', Filter.form)
Vue.filter('foregroundClass', Filter.foregroundClass)
Vue.filter('uppercase', Filter.uppercase)
Vue.filter('capitalize', Filter.capitalize)
Vue.filter('articulationMove', Filter.articulationMove)
Vue.filter('horizontalDirection', Filter.horizontalDirection)
Vue.filter('verticalDirection', Filter.verticalDirection)
Vue.filter('strainMove', Filter.strainMove)
Vue.filter('alimentation', Filter.alimentation)
Vue.filter('widget', Filter.widget)
Vue.filter('news', Filter.news)
Vue.filter('pack', Filter.pack)
Vue.filter('giftPack', Filter.giftPack)

new Vue({
   i18n,
   router,
   store,
   render: h => h(App)
}).$mount('#app');
