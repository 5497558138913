<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<button type="button" class="btn--link-like" @click="newTicket" :title="$t('vm.root.help.aside.new-ticket.title')">
				{{ $t('vm.root.help.aside.new-ticket.label') }}
			</button>
		</li>
		<li v-if="hasValidSupport" class="hr" aria-hidden="true"/>
		<li v-if="isLoading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</li>
		<li v-else v-for="ticket in tickets" :key="ticket.id">
			<router-link class="with-badge" :to="{name: 'ticket', params:{sup_id: ticket.id}}" :title="$t('vm.root.help.aside.ticket', {title: ticket.title})">
				<span class="with-badge-label">{{ ticket.title }}</span>
				<span v-if="ticket.unread_count > 0" class="with-badge-badge">{{ ticket.unread_count }}</span>
			</router-link>
		</li>
	</ul>
	<ul class="aside-navigation is-pushed-down" v-if="closedCount > 0">
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link class="with-badge" :to="{name: 'closed-help'}" :title="$t('vm.root.help.aside.closed-ticket.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<span class="with-badge-label">{{ $t('vm.root.help.aside.closed-ticket.label') }}</span>
				<span v-if="unreadClosedCommentCount > 0" class="with-badge-badge">{{ unreadClosedCommentCount }}</span>
			</router-link>
		</li>
	</ul>
</a-side>
