<div class="grid-2" v-if="!!test">
	<input-frame :id="`exclusion-test-name-${id}`" v-model="test.name"
		:label="$t('vm.root.patients.patient.test-item.name.label')" 
		:placeholder="$t('vm.root.patients.patient.test-item.name.placeholder')"
		:error-test="hasExclusionTestNameError" :error-msg="$t('vm.root.patients.patient.test-item.name.error')"
		:title="$t('vm.root.patients.patient.test-item.name.title')"/>

	<!-- <switch-frame :id="`exclusion-test-value-${id}`" v-model="test.positive">Test positif</switch-frame> -->
	<togglebox-frame :id="`exclusion-test-value-${id}`" :items="items" v-model="test.positive"
		:label="$t('vm.root.patients.patient.test-item.value.label')" 
		:title="$t('vm.root.patients.patient.test-item.value.title')" />
	<textarea-frame :id="`exclusion-test-comment-${id}`" v-model="test.comment" class="grid-item-full"
		:label="$t('vm.root.patients.patient.test-item.comment.label')" 
		:placeholder="$t('vm.root.patients.patient.test-item.comment.placeholder')" 
		:title="$t('vm.root.patients.patient.test-item.comment.title')"/>

	<div v-if="isMainUser" class="grid-item-full text-right">
		<delete-item class="btn btn--link-like text-danger" :can-delete="true"
			:delete-action="submitDelete">
			{{ $t('general.delete.text') }}
		</delete-item>
	</div>

	<hr class="grid-item-full">
</div>