import { i18n } from "@I18n/index"
import { Timezone } from "@Enums/timezone"

export class DateHelper {
    private static REGEX_ISO_8601: any = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/
	private static REGEX_DATE: any =  /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(\.\d{3}[Zz])/

    public static getTimezone(date: Date): string {
        let tz: number = date.getTimezoneOffset()
		let hourTz: string = `${Math.round((Math.abs(tz) / 60))}`.padStart(2, '0')
		let minuteTz: string = `${Math.round((Math.abs(tz) % 60))}`.padStart(2, '0')
        let timezone: string = `${(tz < 0 ? '+' : '-')}${hourTz}:${minuteTz}`
        return timezone
    }

    public static timezoneToUtc(timezone: Timezone): string {
        switch(timezone) {
          case Timezone.UtcMinus3:
            return ' GMT-03:00'
          case Timezone.UtcMinus4:
            return ' GMT-04:00'
          case Timezone.UtcMinus5:
            return ' GMT-05:00'
          case Timezone.UtcPlus4:
            return ' GMT+04:00'
          default:
            return ' GMT+01:00'
        }
    }

    public static getMonday(date: Date): Date {
        date = new Date(date)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
        let day: number = date.getDay()
        let diff: number = date.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
        return new Date(date.setDate(diff))
    }

    public static getSunday(date: Date): Date {
        date = DateHelper.getMonday(date)
        return new Date(date.setDate(date.getDate() + 6))
    }

    public static sameDay(first: Date, second: Date): boolean {
        return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    }

    public static getDaysName(): {index: number, min: string, normal: string}[] {
		let weekdays: {index: number, min: string, normal: string}[] = []
		let date = new Date('2019-02-18T00:00:00') // un lundi, comme tant d'autres
		for(let i = 0; i < 7; i++) {
			let index: number = i === 6 ? 0 : i+1
			weekdays.push({
				index: index,
				min: i18n.d(date, 'weekdayMin'),
				normal: i18n.d(date, 'weekday'),
			})
			date.setDate(date.getDate() + 1)
		}
		return weekdays
	}

    public static getMonthsName(format: string = 'MM'): string[] {
        let months: string[] = []
		let date: Date = new Date('2020-01-01')
		for(let i = 0; i < 12; i++) {
			date.setMonth(i)
			months.push(i18n.d(date, format))
        }
        return months
    }

    public static convertStringToDate(input: any): void {
		// Ignore things that aren't objects.
		if (typeof input !== "object") return input

		for (let key in input) {
			if (!input.hasOwnProperty(key)) continue

			let value: any = input[key]
			let match: RegExpMatchArray
			// Check for string properties which look like dates.
			if (typeof value === "string" && (match = value.match(DateHelper.REGEX_ISO_8601))) {
				let milliseconds: number = Date.parse(match[0])
				if (isNaN(milliseconds)) continue
				let date = new Date(milliseconds)
				if (isNaN(date.getTime())) continue
				input[key] = date
			} else if (typeof value === "string" && (match = value.match(DateHelper.REGEX_DATE))) {
				let date = new Date(value)
				input[key] = date
			} else if (typeof value === "object") {
				// Recurse into object
				DateHelper.convertStringToDate(value)
			}
		}
    }

    public static isValidDate(date: Date) {
        return date !== null && date instanceof Date && !isNaN(date.getTime())
    }

    public static getDateString(date: Date): string {
        let currentDate = date || new Date()

        return `${i18n.d(currentDate, 'yyyy')}-${i18n.d(currentDate, 'mm')}-${i18n.d(currentDate, 'dd')}`
    }

    public static getDayName(index: number): string {
        let date = new Date('2019-02-18T00:00:00') // un lundi, comme tant d'autres
        index = index -1
        date.setDate(date.getDate() + index)

        return `${i18n.d(date, 'weekday')}`
    }

    public static getHourString(date: Date): string {
        let currentDate = date || new Date()

        return currentDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute:'2-digit' })
    }

    public static calculateAge(birth_date: Date, date?: Date): string {
		if (!birth_date) return ''

		birth_date.setHours(0, 0, 0, 0)
		let event_date: Date = !!date ? date : new Date()
        let ageNumber: number = (event_date.getTime() - birth_date.getTime()) / 31557600000
        // let age: string = `${~~ageNumber}`
		let age: string = `${i18n.tc('units.year', ~~ageNumber)}`
		if (ageNumber < 2) {
            // age = `${~~(ageNumber * 12)}`
			age = `${i18n.tc('units.month', ~~(ageNumber * 12))}`

			if (ageNumber * 12 <= 1) {
                // age = `${~~(ageNumber * 365)}`
                age = `${i18n.tc('units.day', ~~(ageNumber * 365))}`
				// unit = 'jours'
			}

			if (ageNumber === 0) {
                age = `${i18n.t('units.once-upon-a-time')}`
				// age = ''
				// unit = 'il était une fois'
			}
		}
		if (ageNumber < 0) return `${i18n.t('units.not-born')}`

		return age //`${age} ${unit}`.trim()
    }

    public static timeAgo(from: Date, to?: Date): string {
        let diff: ITime = DateHelper.timeBetweenDate(from, to)

        if (!diff) return ''

        return `il y a ${i18n.tc(diff.unit, diff.time)}`.trim()
    }

    public static since(from: Date, to?: Date): string {
        let diff: ITime = DateHelper.timeBetweenDate(from, to)

        if (!diff) return ''

        return `depuis ${i18n.tc(diff.unit, diff.time)}`.trim()
    }

    public static inTime(to: Date, from?: Date): string {
        let diff: ITime = DateHelper.timeBetweenDate(to, from)

        if (!diff) return ''

        return `dans ${i18n.tc(diff.unit, diff.time)}`.trim()
    }

    public static timeBetweenDate(from: Date, to?: Date): ITime {
		if (!from) return null

        let date: Date = !!to ? to : new Date()
        let timeNumber: number = Math.abs((date.getTime() - from.getTime()) / 31536000000)

        let time: number = ~~timeNumber
        if (timeNumber > 1) return {time: time, unit: 'units.year', unitValue: 5}

        time = ~~(timeNumber * 12)
        if (timeNumber * 12 > 1) return {time: time, unit: 'units.month', unitValue: 4}

        time = ~~(timeNumber * 365)
        if (timeNumber * 365 > 1) return {time: time, unit: 'units.day', unitValue: 3}

        time = ~~(timeNumber * 8760)
        if (timeNumber * 8760 > 1) return {time: time, unit: 'units.hour', unitValue: 2}

        time = ~~(timeNumber * 525600)
        if (timeNumber * 525600 > 1) return {time: time, unit: 'units.minute', unitValue: 1}

        return {time: 0, unit: 'units.few-moments', unitValue: 0}
	}
}

export interface ITime {
    time: number,
    unit: string,
    unitValue: number
}
