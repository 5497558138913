import Render from '@Views/root/accounting/remittances/remittance/main.html'
import { Component, Watch } from 'vue-property-decorator'
import { AutoSaver, IToggleBoxItem } from '@Components/index'
import { ASideRemittance } from './aside-remittance'
import { ContextRemittance } from './context-remittance'
import { IBreadcrumbItem, IAccountingFilter, IAccountingSubFilter, IInvoice, IRemittance, IRemittanceLine, AccountingActionPayload } from '@Store/types'
import { Debouncer } from '@Models/index'
import { EnumsHelper } from '@Helpers/index'
import { AutoSaverState, InvoiceType, PaymentMode } from '@Enums/index'
import { accounting, breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-remittance': ASideRemittance,
		'context-remittance': ContextRemittance
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	searched: boolean = false
	searching: boolean = false
	selectAll: boolean = false
	lines: IRemittanceLine[] = []
	private submitted: boolean = false
	private allLines: IRemittanceLine[] = []
	private debouncerFilter: Debouncer = null

	constructor() {
		super('remittance', false)
	}

	//#region Getter
	get remittance(): IRemittance {
		return accounting.remittance
	}
	get filter(): IAccountingFilter {
		return accounting.filter
	}
	get subFilter(): IAccountingSubFilter {
		return accounting.subFilter
	}
	get defaultCurrency(): string {
		return !!this.lines && this.lines.length > 0 ? this.lines[0].currency_i18n : 'fr'
	}
	get total(): number {
		let total: number = this.selectedLines.length === 0 ? 0 : this.selectedLines.map(l => l.ttc).reduce((prev, next) => prev + next)

		this.remittance.count = total
		return total
	}
	get isSaving(): boolean {
		return accounting.isSaving
	}
	get isNewRemittance(): boolean {
		return !this.$route.params.rem_id
	}
	get saveButtonLabel(): string {
		return this.isNewRemittance
			? this.$i18n.t('vm.root.accounting.remittance.main.save').toString()
			: this.$i18n.t('vm.root.accounting.remittance.main.update').toString()
	}
	get hasLabelError(): boolean {
		return this.submitted && !this.remittance.label
	}
	get hasNumberError(): boolean {
		return this.submitted && !this.remittance.number
	}
	get isLoading(): boolean {
		return accounting.isLoading
	}
	get isDeleting(): boolean {
		return accounting.isDeleting
	}
	get countSelectedLines(): number {
		return this.selectedLines.length
	}
	get depositItems() : IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('vm.root.accounting.remittance.main.banked.not-banked').toString()},
			{value: true, label: this.$i18n.t('vm.root.accounting.remittance.main.banked.banked').toString(), icon: "piggy-bank"}
		]
	}
	private get remittanceLines(): IRemittanceLine[] {
		return !!this.remittance && !!this.remittance.list ? this.remittance.list : []
	}
	private get selectedLines(): IRemittanceLine[] {
		return [...this.allLines, ...this.remittanceLines].filter(l => { return l.selected })
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IRemittance): void {
		accounting.remittanceSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		let list: IRemittanceLine[] = [...this.allLines, ...this.remittanceLines]
			.filter(l => { return l.selected })
			.map(l => { return {id: l.id} })

		let remittance: IRemittance = {
			id: this.remittance.id,
			label: this.remittance.label,
			deposit_date: this.remittance.deposit_date,
			number: this.remittance.number,
			closed: this.remittance.closed,
			list: list
		}
		accounting.saveRemittance(remittance)
		.then(newRemittance => {
			let filter: AccountingActionPayload = {
				usr_id: this.filter.usr_id,
				ofi_id: this.filter.ofi_id,
				start: this.filter.start,
				end: this.filter.end,
				without_remittance: true
			}
			super.initializeValue(newRemittance)
			this.state = AutoSaverState.None

			accounting.loadInvoices(filter)
			.then((invoices: IInvoice[]) => {
				this.allLines = invoices.filter(this.filterInvoice).map(i => { return {selected: false, ...i} })

				this.filterLines()

				super.initializeValue(newRemittance)
				this.state = AutoSaverState.None
				this.submitted = false

				if (parseInt(remittance.id) !== -1) return

				let route = {name: this.$router.currentRoute.name, params: {rem_id: newRemittance.id}}
				this.$router.replace(route)
			})
		})
		.catch(() => {
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		this.debouncerFilter = new Debouncer(this.applySubFilter, 333)
		this.initializeBreadcrumb()
		this.initializeInvoices()
		this.initializeRemittance()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerFilter)
	}

	onDelete() {
		// Appelé quand on efface le dossier depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	getIcon(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode, true)
	}

	getModeLabel(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode)
	}

	@Watch('selectAll')
	updateSelectAll(): void {
		this.lines.forEach(l => { l.selected = this.selectAll })
		this.remittance.count = this.selectAll ? this.lines.length : 0
	}

	//#region private methods
	private canSave(): boolean {
		const { remittance } = this
		return !!remittance && !!remittance.label && !!remittance.number
	}

	@Watch('filter', {deep: true})
	private initializeInvoices() {
		if (!this.filter) return

		this.searching = true
		this.searched = false
		this.lines = []

		let filter: AccountingActionPayload = {
			usr_id: '-1',
			ofi_id: this.filter.ofi_id,
			start: this.filter.start,
			end: this.filter.end,
			without_remittance: true
		}

		accounting.loadInvoices(filter)
		.then((invoices: IInvoice[]) => {
			this.searching = false
			this.allLines = invoices.filter(this.filterInvoice).map(i => { return {selected: false, ...i} })

			this.filterLines()
		})
		.catch(() => {
			this.searching = false
			this.searched = true
		})
	}

	@Watch('remittance.closed')
	private applySubFilter(): void {
		if (!!this.allLines) {
			accounting.subFilterRequest()
			this.lines = [...this.allLines, ...this.remittanceLines]
				.filter(this.filterLine)
				.sort((a, b) => b.number.localeCompare(a.number))
				accounting.subFilterSuccess()
		}
		this.searched = true
	}

	@Watch('subFilter', {deep: true})
	private filterLines(force: boolean = false) {
		if (!force && (!this.allLines || this.allLines.length === 0)) return

		this.debouncerFilter.start()
	}

	private filterInvoice(invoice: IInvoice): boolean {
		let result: boolean = true

		result = result && this.filterByMode(invoice)
		result = result && this.filterByType(invoice)
		result = result && this.filterByReceiptType(invoice)

		return result
	}

	private filterLine(line: IRemittanceLine): boolean {
		let result: boolean = true

		result = result && (!this.remittance.closed || line.selected)
		result = result && this.filterByBank(line)
		result = result && this.filterByNumber(line)
		result = result && this.filterByLabel(line)

		return result
	}

	// private filterDuplicate(line: IRemittanceLine): boolean {
	// 	if (!this.remittance || this.remittance.list.length === 0) return true
	// 	return !this.remittance.list.find(l => { return l.id === line.id })
	// }

	private filterByNumber(line: IRemittanceLine): boolean {
		let filter: string = this.subFilter.number.trim().toLowerCase()
		if (!filter) return true

		return !!line.number && line.number.toLowerCase().includes(filter)
	}

	private filterByBank(line: IRemittanceLine): boolean {
		let filter: string = this.subFilter.bank.trim().toLowerCase()
		if (!filter) return true

		return !!line.bank && line.bank.toLowerCase().includes(filter)
	}

	private filterByLabel(line: IRemittanceLine): boolean {
		let filter: string = this.subFilter.label.trim().toLowerCase()
		if (!filter) return true

		return !!line.label && line.label.toLowerCase().includes(filter)
	}

	private filterByMode(line: IInvoice): boolean {
		return line.mode === PaymentMode.Check
	}

	private filterByType(line: IInvoice): boolean {
		return line.type === InvoiceType.Receipt
	}

	private filterByReceiptType(line: IInvoice): boolean {
		return line.type === InvoiceType.Receipt
	}

	private createNewRemittance(): IRemittance {
		return {
			id: '-1',
			label: '',
			deposit_date: new Date(),
			number:  !!accounting.lastRemittance ? accounting.lastRemittance.number : '',
			closed: false,
			list: [],
			count: 0
		}
	}

	private initializeRemittance(): void {
		let payload = {rem_id: this.$route.params.rem_id}
		if (payload.rem_id === undefined) {
			this.restoreValue(this.createNewRemittance())
			super.initializeValue()
		} else if (!accounting.isLoaded || !accounting.remittance || accounting.remittance.id !== payload.rem_id) {
			accounting.loadRemittance(payload)
			.then(() => {
				super.initializeValue()
				this.filterLines(true)
			})
		} else {
			super.initializeValue()
			this.filterLines(true)
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.remittances', 2).toString(), link: "remittances" }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.remittances', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
	//#endregion
}
