export const mainRemittance = {
    title: "Nouvelle remise de chèques",
    loading: "Chargement de la remise de chèques",

    save: "créer la remise",
    update: "mettre à jour la remise",
    help: {
        content: "Pensez à indiquer le numéro du chèque et éventuellement la banque émettrice de ce chèque dans le champ {0} de vos recettes. C'est cette information qui apparaîtra dans vos remises de chèques.",
        strong: "Information bancaire"
    },
    label: {
        label: "Libellé de la remise de chèques",
        placeholder: "ex : R-20/07",
        'error-msg': "Merci de renseigner un libellé à votre remise de chèques",
        title: "Indiquez un libellé pour identifier cette remise de chèques"
    },
    number: {
        label: "Numéro du compte en banque",
        placeholder: "ex : 70045678908",
        'error-msg': "Merci de renseigner un numéro de compte en banque à votre remise de chèqes",
        title: "Indiquez un numéro de compte en banque pour cette remise de chèques"
    },
    banked: {
        'not-banked': "non déposée",
        banked: "déposée",
        title: "Indiquez sur la remise de chèques a été déposée en banque",
        label: "Déposée en banque ?"
    },
    date: {
        title: "Indiquez la date de dépôt de votre remise de chèques",
        label: "Date de dépôt"
    },
    'closed-warning': "Une fois déposée, il ne vous sera plus possible de rajouter d'autres chèques à cette remise.",
    none: "Aucun chèque à déposer.",
    total: "Total",
    check: "{count} chèque | {count} chèque | {count} chèques"
}
