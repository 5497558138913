import { AsYouType, CountryCode } from 'libphonenumber-js'

export class PhoneHelper {

    public static selectCountryWithDialCode(number: string): CountryCode {
        if (!number || !number.startsWith('+')) return 'FR'

        const formatter = new AsYouType()
        formatter.input(number)
        if (!formatter.country) return 'FR'

        return formatter.country
    }
}
