<div>
	<h1>{{ $t('vm.root.user.gift.home.title') }}</h1>

	<i18n path="vm.root.user.gift.home.help.text1" tag="p">
		<strong>{{ $t('vm.root.user.gift.home.help.strong1') }}</strong>
	</i18n>

	<i18n path="vm.root.user.gift.home.help.text2" tag="p">
		<strong>{{ $t('vm.root.user.gift.home.help.strong2') }}</strong>
	</i18n>

	<section>
		<h2>
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
			</svg>
			{{ $t('vm.root.user.gift.home.steps.title') }}
		</h2>
		<table class="data-table">
			<thead>
				<tr>
					<th class="w70 text-left" scope="col">{{ $t('vm.root.user.gift.home.header.step') }}</th>
					<th class="w30 text-center" scope="col">{{ $t('vm.root.user.gift.home.header.get') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th class="text-left text-normal" scope="row">{{ $t('vm.root.user.gift.home.steps.step1') }}</th>
					<td>
						<router-link :to="{name: 'user-gift1'}" class="btn btn-primary is-full-width" :disabled="isFirstStateApplied" @click="apply()"
							:title="$t('vm.root.user.referral.get', {point: $tc('vm.root.user.referral.point', 1), gift: ''})">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
							</svg>
							{{ $tc('vm.root.user.gift.home.free-month', 1, {total: isFirstStateApplied ? 1 : 0}) }}
						</router-link>
					</td>
				</tr>
				<tr>
					<th class="text-left text-normal" scope="row">{{ $t('vm.root.user.gift.home.steps.step2') }}</th>
					<td>
						<router-link :to="{name: 'user-gift2'}" class="btn btn-primary is-full-width" :disabled="isSecondStateApplied" @click="apply()"
							:title="$t('vm.root.user.referral.get', {point: $tc('vm.root.user.referral.point', 1), gift: ''})">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
							</svg>
							{{ $tc('vm.root.user.gift.home.free-month', 1, {total: isSecondStateApplied ? 1 : 0}) }}
						</router-link>
					</td>
				</tr>
				<tr>
					<th class="text-left text-normal" scope="row">{{ $t('vm.root.user.gift.home.steps.step3') }}</th>
					<td>
						<router-link :to="{name: 'user-gift3'}" class="btn btn-primary is-full-width" :disabled="isThirdStateApplied" @click="apply()"
							:title="$t('vm.root.user.referral.get', {point: $tc('vm.root.user.referral.point', 1), gift: ''})">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
							</svg>
							{{ $tc('vm.root.user.gift.home.free-month', 10, {total: thirdStateAppliedCount}) }}
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</section>

</div>
