import Render from '@Views/components/collapsible-help.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StorageHelper } from '@Helpers/index'
import {i18n} from '@I18n/index'

@Render
@Component({})
export class CollapsibleHelp extends Vue {

    hide: boolean = true
    @Prop({default: i18n.t('vm.components.collapsible-help.title').toString()}) title: string
    @Prop({default: "h3"}) tag: string
    @Prop({default: "no-marg"}) tagClass: string
    @Prop({default: ""}) tagFor: string
    @Prop({default: i18n.t('vm.components.collapsible-help.hide-label').toString()}) hideLabel: string
    @Prop({default: i18n.t('vm.components.collapsible-help.show-label').toString()}) showLabel: string
    @Prop() id: string

    mounted() {
        let state = StorageHelper.get('collapsible-help', {})
        this.hide = !!state[this.id]
    }
    toggle(): void {
        this.hide = !this.hide
        let state = StorageHelper.get('collapsible-help', {})
        state[this.id] = this.hide

        StorageHelper.set('collapsible-help', state)
    }
}
