export const consultationsMain = {
    legends: {
        first: "1<sup>ère</sup> consultation",
        second: "suivi",
        third: "total"
    },
    'week-legends': {
        first: "1ère consultation ({year})",
        second: "suivi ({year})",
        third: "total ({year})",
        quarter: "rendez-vous manqués ({year})"
    },
    'legend-chart': {
        category: "{count} consultation | {count} consultation | {count} consultations",
        tooltip: "{count} patient | {count} patient | {count} patients",
        name: "Nombre",
        title: "patients"
    },
    title: "Statistiques consultations",
    loading: "Chargement en cours…",
    'week-title': "Détails de la semaine {week}",
    'ratio-title': "Fidélisation du patient ({total})",
    'ratio-follow-up-title': "Suivi des consultations ({total})",
    'evolution-title': "Évolution des consultations (en {year})"
}
