export const asideColleague = {
    back: {
        title: "Retour aux statistiques des praticiens",
        label: "Retour aux praticiens",
    },
    delete: {
        title: "Suppression des données de ce praticien",
        'delete-msg': "Vous êtes sur sur le point de supprimer les données de ce praticien. Souhaitez-vous continuer ?",
        label: "Supprimer ce praticien"
    }
}