export const aside = {
    cancel: {
        action: "perdre vos modifications",
        'message-details': "Vous êtes sur le point d'annuler l'ensemble de vos modifications :<ul><li>{details}</li></ul>Vous confirmez ?",
        message: "Vous êtes sur le point d'annuler l'ensemble de vos modifications. Vous confirmez ?",
        title: "Annulation",
    },
    back: {
        title: "Retour à votre compte utilisateur",
        label: "Retour",
    },
    home: {
        title: "Présentation des mois offerts pour le planning en ligne",
        label: "Présentation",
    },
    gift1: {
        title: "Validez la première étape pour obtenir un mois gratuit de planning en ligne",
        label: "1. Profil et cabinet|1. Profil et cabinet|1. Profil et cabinets",
    },
    gift2: {
        title: "Validez la deuxième étape pour obtenir un mois gratuit supplémentaire",
        label: "2. Zone de chalandise",
    },
    gift3: {
        title: "Validez la dernière étape pour obtenir jusqu'à 10 mois gratuit de planning en ligne",
        label: "3. Profession",
    },
    'gift-bonus': {
        title: "Utilisez le planning en ligne gratuitement à vie",
        label: "Bonus",
    }
}
