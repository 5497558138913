export const aside = {
    general: {
        title: "Gérez les paramètres généraux de l'application",
        label: "Généraux"
    },
    patient: {
        title: "Gérez l'affichage de vos dossiers patients",
        label: "Patient"
    },
    planning: {
        title: "Gérez l'affichage de vos plannings",
        label: "Planning"
    },
    accounting: {
        title: "Gérez l'affichage de vos finances",
        label: "Finances"
    },
    confirmation: {
        title: "Configurez vos confirmations de rdv",
        label: "Confirmation de rdv"
    },
    reminder: {
        title: "Configurez vos rappels de rdv",
        label: "Rappel de rdv"
    },
    'follow-up': {
        title: "Configurez vos suivis de consultation",
        label: "Suivi de consultation"
    },
    relaunch: {
        title: "Configurez vos relances préventives",
        label: "Relance préventive"
    },
    forms: {
        title: "Paramétrez vos questionnaires personnalisés",
        label: "Questionnaire personnalisé | Questionnaire personnalisé | Questionnaires personnalisés"
    },
    letters: {
        title: "Paramétrez vos modèles de courrier",
        label: "Modèle de courrier | Modèle de courrier | Modèles de courrier"
    },
    notifications: {
        title: "Gérez les notifications",
        label: "Notifications"
    }
}
