<div class="wrapper">

	<section class="marg-v" v-if="officesByUser.length !== 1">
		<collapsible-help id="context-event-information" :title="$tc('vm.root.calendar.context-main.office.main-title', offices.length)" tag="h2" tag-class="h-reset text-bold">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.calendar.context-main.office.help.text" tag="span">
						<router-link :to="{name: 'office-schedule', params: {ofi_id: selectedOffice}}"
							:title="$t('vm.root.calendar.context-main.office.help.link.title')">
							{{ $t('vm.root.calendar.context-main.office.help.link.text') }}
						</router-link>
					</i18n>
				</p>
			</div>
		</collapsible-help>

		<div v-if="hasOffice">
			<radio-frame :id="`office-${office.id}`" :key="`office-${office.id}`" v-model="selectedOffice" v-for="office in offices"
				default-class="p-like" :name="`office-${office.id}`" :title="office.coordinates.name"
				:radio-value="office.id">{{office.coordinates.name}}</radio-frame>
		</div>
		<div class="notice-warning" v-else>
			<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
			</svg>
			{{ $t('vm.root.calendar.context-main.office.none') }}
		</div>
	</section>

	<hr v-if="offices.length !== 1">

	<section class="marg-v">
		<h2 class="h-reset text-bold">{{ $tc('vm.root.calendar.context-main.planning.main-title', plannings.length) }}</h2>

		<planning-item v-for="planning in plannings" :key="planning.id" :planning="planning"></planning-item>

		<div class="notice-warning" v-if="!hasPlanning">
			<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
			</svg>
			{{ $t('vm.root.calendar.context-main.planning.none') }}
		</div>

		<div class="p-like text-right">
			<router-link :to="{name:'planning'}" :title="$t('vm.root.calendar.context-main.planning.create.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				<span>{{ $t('vm.root.calendar.context-main.planning.create.label') }}</span>
			</router-link>
		</div>
	</section>

	<hr>

	<section class="marg-v">
		<h2 class="h-reset text-bold">{{ $tc('vm.root.calendar.context-main.planning-google.main-title', googlePlannings.length) }}</h2>

		<div class="p-like text-center">
			<div v-if="initializing" class="text-center">
				<spinner class="svg-xl text-primary"/>
			</div>

			<button v-if="!initializing && !authorized" type="button" @click="authenticate" class="google"
					:title="$t('vm.root.calendar.context-main.planning-google.connect.title')">
				<img src="@Assets/img/illustrations/google-connect.png" :alt="$t('vm.root.calendar.context-main.planning-google.connect.label')">
<!--				<svg width="1em" class="svg-middle" viewBox="0 0 46 46" aria-hidden="true" focusable="false">-->
<!--				<svg width="1em" class="svg-middle" viewBox="0 0 46 46" >-->
<!--					&lt;!&ndash; Generator: Sketch 3.3.3 (12081) - http://www.bohemiancoding.com/sketch &ndash;&gt;-->
<!--					<title>btn_google_dark_normal_ios</title>-->
<!--					<desc>Created with Sketch.</desc>-->
<!--					<defs>-->
<!--						<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">-->
<!--							<feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>-->
<!--							<feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>-->
<!--							<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.168 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1"></feColorMatrix>-->
<!--							<feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>-->
<!--							<feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>-->
<!--							<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.084 0" in="shadowBlurOuter2" type="matrix" result="shadowMatrixOuter2"></feColorMatrix>-->
<!--							<feMerge>-->
<!--								<feMergeNode in="shadowMatrixOuter1"></feMergeNode>-->
<!--								<feMergeNode in="shadowMatrixOuter2"></feMergeNode>-->
<!--								<feMergeNode in="SourceGraphic"></feMergeNode>-->
<!--							</feMerge>-->
<!--						</filter>-->
<!--						<rect id="path-2" x="0" y="0" width="40" height="40" rx="2"></rect>-->
<!--						<rect id="path-3" x="5" y="5" width="38" height="38" rx="1"></rect>-->
<!--					</defs>-->
<!--					<g id="Google-Button" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">-->
<!--						<g id="9-PATCH" sketch:type="MSArtboardGroup" transform="translate(-608.000000, -219.000000)"></g>-->
<!--						<g id="btn_google_dark_normal" sketch:type="MSArtboardGroup" transform="translate(-1.000000, -1.000000)">-->
<!--							<g id="button" sketch:type="MSLayerGroup" transform="translate(4.000000, 4.000000)" filter="url(#filter-1)">-->
<!--								<g id="button-bg">-->
<!--									<use fill="#4285F4" fill-rule="evenodd" sketch:type="MSShapeGroup" xlink:href="#path-2"></use>-->
<!--									<use fill="none" xlink:href="#path-2"></use>-->
<!--									<use fill="none" xlink:href="#path-2"></use>-->
<!--									<use fill="none" xlink:href="#path-2"></use>-->
<!--								</g>-->
<!--							</g>-->
<!--							<g id="button-bg-copy">-->
<!--								<use fill="#FFFFFF" fill-rule="evenodd" sketch:type="MSShapeGroup" xlink:href="#path-3"></use>-->
<!--								<use fill="none" xlink:href="#path-3"></use>-->
<!--								<use fill="none" xlink:href="#path-3"></use>-->
<!--								<use fill="none" xlink:href="#path-3"></use>-->
<!--							</g>-->
<!--							<g id="logo_googleg_48dp" sketch:type="MSLayerGroup" transform="translate(15.000000, 15.000000)">-->
<!--								<path d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z" id="Shape" fill="#4285F4" sketch:type="MSShapeGroup"></path>-->
<!--								<path d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z" id="Shape" fill="#34A853" sketch:type="MSShapeGroup"></path>-->
<!--								<path d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z" id="Shape" fill="#FBBC05" sketch:type="MSShapeGroup"></path>-->
<!--								<path d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z" id="Shape" fill="#EA4335" sketch:type="MSShapeGroup"></path>-->
<!--								<path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z" id="Shape" sketch:type="MSShapeGroup"></path>-->
<!--							</g>-->
<!--							<g id="handles_square" sketch:type="MSLayerGroup"></g>-->
<!--						</g>-->
<!--					</g>-->
<!--				</svg>-->
<!--				Sign in with Goggle-->
<!--				{{ $t('vm.root.calendar.context-main.planning-google.connect.label') }}-->
			</button>
<!--			<hr>-->
<!--			<button type="button" @click="test" class="btn btn-primary"-->
<!--					:title="$t('vm.root.calendar.context-main.planning-google.connect.title')">-->
<!--				<svg width="1em" class="svg-middle" viewBox="0 0 488 512" aria-hidden="true" focusable="false">-->
<!--					<use xlink:href="@Assets/svg/symbol-defs.svg#google"/>-->
<!--				</svg>-->
<!--				TEST-->
<!--			</button>-->

<!--			<button type="button" @click="loadCalendar" class="btn btn-primary"-->
<!--					:title="$t('vm.root.calendar.context-main.planning-google.connect.title')">-->
<!--				<svg width="1em" class="svg-middle" viewBox="0 0 488 512" aria-hidden="true" focusable="false">-->
<!--					<use xlink:href="@Assets/svg/symbol-defs.svg#google"/>-->
<!--				</svg>-->
<!--				{{ $t('vm.root.calendar.context-main.planning-google.connect.label') }} 2-->
<!--			</button>-->
<!--			<hr>-->
<!--			<button v-if='!isAuthorized()' type="button" @click="handleAuthClick" class="btn btn-primary"-->
<!--				:title="$t('vm.root.calendar.context-main.planning-google.connect.title')">-->
<!--				<svg width="1em" class="svg-middle" viewBox="0 0 488 512" aria-hidden="true" focusable="false">-->
<!--					<use xlink:href="@Assets/svg/symbol-defs.svg#google"/>-->
<!--				</svg>-->
<!--				{{ $t('vm.root.calendar.context-main.planning-google.connect.label') }}-->
<!--			</button>-->
		</div>

		<div v-if="isGoogleLoading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</div>
		<section v-if='!isGoogleLoading && authorized' v-for="planning in googlePlannings" :key="planning.id" class="planning-list-item p-like is-readonly">
			<header class="planning-list-item-header">
				<div class="planning-list-item-legend" :style="`--color:${planning.color}`"></div>
				<checkbox-frame default-class="planning-list-item-title" :id="planning.id" v-model="planning.visible"
					@input="updateGooglePlanning(planning)" :title="$t('vm.root.calendar.context-main.display-planning', {name: planning.name})">
					<span class="text-bold text-capitalize">{{planning.name}}</span>
				</checkbox-frame>
			</header>
		</section>

		<div v-if='authorized' class="p-like childs-space-between">
			<button type="button" @click="refreshToken" class="btn--link-like"
					:title="$t('vm.root.calendar.context-main.planning-google.refresh.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#sync-alt"/>
				</svg>
				{{ $t('vm.root.calendar.context-main.planning-google.refresh.label') }}
			</button>
			<button type="button" @click="disconnect" class="btn--link-like"
				:title="$t('vm.root.calendar.context-main.planning-google.disconnect.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 488 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#google"/>
				</svg>
				{{ $t('vm.root.calendar.context-main.planning-google.disconnect.label') }}
			</button>
		</div>
	</section>

</div>
