export const detailsImmobilization = {
    label: {
        label:"Libellé de l'immobilisation",
        placeholder:"ex : Table électrique",
        'error-msg': "Merci de renseigner un libellé à votre immobilisation",
        title:"Indiquez un libellé pour identifier cette immobilisation"
    },
    office: {
        label:"Cabinet lié à l'immobilisation",
        title:"Sélectionnez le cabinet associé à cette immobilisation"
    },
    number: {
        label:"Numéro de l'immobilisation",
        placeholder:"ex : 01000123",
        title:"Indiquez un numéro unique, idéalement consécutif au précédent"
    },
    'modify-number': {
        title:"Activez ce switch si vous souhaitez modifier manuellement le numéro de l'immobilisation",
        label: "Modifier le numéro de l'immobilisation"
    },
    date: {
        label:"Date d'émission de l'immobilisation",
        title:"Indiquez la date de création de cette immobilisation",
        placeholder:"ex: 30 Février 2016"
    },
    type: {
        label:"Type d'amortissement",
        title:"Sélectionnez le type d'amortissement"
    },
    mode: {
        label:"Mode de paiement pour cette immobilisation",
        title:"Sélectionnez le mode de paiement utilisé pour régler cette immobilisation"
    },
    amount: {
        label:"Montant de l'immobilisation (en euros)",
        placeholder:"ex : 55 €",
        title:"Indiquez le montant de l'immobilisation"
    },
    rate: {
        label:"Amortie sur (en années)",
        placeholder:"ex : 3 ans",
        title:"Indiquez le nombre d'année sur lequel se passe cette immobilisation"
    }, 
    tva: {
        label:"Taux de TVA associé à l'immobilisation (en %)",
        placeholder:"ex : 20 %",
        title:"Indiquez le taux de TVA associé à l'immobilisation"
    }
}