import Render from '@Views/root/patients/patient/consultation/read-only.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConsultation, ITimelineItem, IExamen, ICranioSacral, IEva } from '@Store/types'
import { FormSummary } from '@Components/forms/form-summary'
import { FormHelper } from '@Helpers/index'
import { Patient } from '@Models/index'

@Render
@Component({
	components: {
		'form-summary': FormSummary
	}
})
export class ReadOnly extends Vue {

	@Prop() consultation: IConsultation | ITimelineItem
	@Prop({default: false}) timeline: boolean
	@Prop({default: true}) withExamens: boolean
	@Prop({default: true}) withTests: boolean
	@Prop({default: true}) withForms: boolean
	@Prop({default: false}) withCranio: boolean

	//#region Getters
	get examen(): IExamen {
		return this.consultation.examen
	}
	get eva(): IEva {
		return this.consultation.eva
	}
	get cranio(): ICranioSacral {
		return this.consultation.cranio
	}
	get isEmptyEva(): boolean {
		return Patient.isEmptyEva(this.eva)
	}
	get isEmptyExamen(): boolean {
		return Patient.isEmptyExamen(this.examen)
	}
	get isEmptyCranio(): boolean {
		return Patient.isEmptyCranio(this.cranio)
	}
	get tests(): string {
		if (!this.consultation.exclusion_tests) return ''
		if (!this.consultation.exclusion_tests.currents) return ''

		let result: string = ""
		for(let test of this.consultation.exclusion_tests.currents) {
			if (!test.positive) continue

			result += `${test.name}, `
		}
		return !!result ? result.trim().slice(0, -1) : ""
	}
	get isEmpty(): boolean {
		let emptyEva: boolean = !this.withExamens || this.isEmptyEva
		let emptyExamens: boolean = !this.withExamens || this.isEmptyExamen
		let emptyForms: boolean = !this.withForms || this.isEmptyForms
		let emptyCranio: boolean = !this.withCranio || this.isEmptyCranio

		return emptyEva && emptyExamens && emptyForms && emptyCranio && (!this.withTests || !this.tests)
	}
	private get isEmptyForms(): boolean {
		for(let form of this.consultation.forms) {
			if (!FormHelper.isEmpty(form)) return false
		}
		return true
	}
	//#endregion
}
