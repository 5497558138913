<section>
	<h2 class="text-rg no-marg text-right">{{ title }}</h2>
	<div class="is-overflow scrollbar">
		<table class="data-table">
			<thead>
				<tr>
					<th scope="col" class="sr-only"><!-- Place --></th>
					<th scope="col" class="sr-only"><!-- Avatar --></th>
					<th scope="col" class="sr-only text-left">{{ $t('vm.root.statistics.top10.main.patient-column') }}</th>
					<th scope="col" class="sr-only text-right">{{ columnName }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(patient, index) in data" :key="index">
					<td class="text-center">
						<svg v-if="hasIcon(index)" width="1em" class="svg-block svg-center" viewBox="0 0 576 512" aria-hidden="true" focusable="false"
							:class="getRankClass(index)"
							:style="getRankStyle(index)">
							<title>{{ getRank(index) }}</title>
							<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getRankIcon(index)}`"/>
						</svg>
						<span v-else class="text-sm">
							{{ index + 1 }}<sup>e</sup>
						</span>
					</td>
					<td>
						<avatar class="text-md" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
					</td>
					<td class="w70 text-ellipsis">
						<router-link :to="{name:'patient-coordinates', params: {pat_id: patient.id}}"
							:title="$t('vm.root.patients.patient-item.title', {first_name: patient.first_name, last_name: patient.last_name})">
							{{ patient.first_name }} {{ patient.last_name }}
						</router-link>
					</td>
					<td class="w30 text-center">
						{{ patient[property] }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</section>
