import Render from '@Views/root/statistics/top10/top10.html'
import { Table } from './table'
import { Component, Vue } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({
	components: {
		'top10-table': Table
	}
})
export class Top10 extends Vue {

	stats: any = {}

	// @State('statistics') stateStatistics: StatisticsState
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('statistics/loadTop10Statistics') loadTop10Statistics: (ofi_id: string) => Promise<any>

	get isLoading(): boolean {
        return statistics.isLoading
	}
	get missed(): any[] {
		return this.stats.missed
	}
	get cancelled(): any[] {
		return this.stats.cancelled
	}
	get consultation(): any[] {
		return this.stats.consultation
	}

	mounted() {
		this.$emit('navigate', 'top10')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()

		statistics.loadTop10Statistics('-1')
		.then(stats => { this.stats = stats })
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.top10').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
