export const main = {
    saved: "Paramètres sauvegardés",
    cancel: {
        label: "annuler",
        long: "les modifications",
        'title-button': "Annulez les modifications de vos paramètres",
        action: "perdre vos modifications",
        title: "Annulation",
        content: "Vous êtes sur le point d'annuler l'ensemble de vos modifications :<ul><li>{updates}</li></ul>Vous confirmez ?"
    },
    save: {
        title: "Sauvegardez les modifications de vos paramètres",
        label: "sauvegarder"
    }
}