<div class="l-container">
	<div class="l-aside l-aside-nav"></div>
	<div class="l-loading text-center">
		<div>
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#radiation-alt"/>
			</svg>
			<p><span class="text-lg">{{ $t('vm.root.restricted.title') }}</span></p>
			<p>{{ $t('vm.root.restricted.text1') }}<br>{{ $t('vm.root.restricted.text2') }}</p>
		</div>
	</div>
</div>
