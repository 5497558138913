import Render from '@Views/root/co-working/steps/step-1.html'
import { Component, Vue } from 'vue-property-decorator'
import { Profile } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { IDropdownItem } from '@Components/index'
import { account, coworking } from '@Store/modules'

@Render
@Component({})
export class StepOne extends Vue {

    submitted: boolean = false

    // @State('coworking') stateCoWorking: CoWorkingState
    // @State('account') stateAccount: AccountState
    // @Mutation('coworking/updateProfile') updateProfile: (profile: Profile) => void
    // @Mutation('coworking/updateStep') updateStep: (step: number) => void
    // @Action('coworking/nextStep') nextStep:() => void

    get profile(): Profile {
        return coworking.profile
    }
    set profile(value: Profile) {
        coworking.updateProfile(value)
    }
    get hasProfileError(): boolean {
        return this.submitted && this.profile === Profile.None
    }
    get disabled(): boolean {
        return this.profile === Profile.None
    }
    get isVerifying(): boolean {
		return account.isVerifying
	}
	get profileItems() : IDropdownItem[] {
		return [
			{value: Profile.Main, label: EnumsHelper.profileToString(Profile.Main), icon:EnumsHelper.profileToIcon(Profile.Main)},
			{value: Profile.Substitute, label: EnumsHelper.profileToString(Profile.Substitute), icon:EnumsHelper.profileToIcon(Profile.Substitute)},
			{value: Profile.Secretary, label: EnumsHelper.profileToString(Profile.Secretary), icon:EnumsHelper.profileToIcon(Profile.Secretary)},
			{value: Profile.Accounting, label: EnumsHelper.profileToString(Profile.Accounting), icon:EnumsHelper.profileToIcon(Profile.Accounting)},
		]
    }

    mounted() {
        coworking.updateStep(0)
    }
    
    submitNext(): void {
        this.submitted = true
        this.next()
    }
    
    private next(): void {
        if (!this.canNext()) return

        coworking.nextStep()
        this.$router.push({name: 'share-step-two'})
    }

    private canNext(): boolean {
        return !this.hasProfileError
    }
}