export const forms = {
    title: "Questionnaire personnalisé | Questionnaire personnalisé | Questionnaires personnalisés",
    none: {
        title: "Vous n'avez pas de questionnaire personnalisé pour cette partie du dossier patient.",
        description: {
            'coordinates-text': "Vous pouvez, si vous le souhaitez, {0} pour vos coordonnées.",
            'consultation-text': "Vous pouvez, si vous le souhaitez, {0} pour vos consultations.",
            'reason-text': "Vous pouvez, si vous le souhaitez, {0} pour vos historiques.",
            'pediatrics-text': "Vous pouvez, si vous le souhaitez, {0} pour votre périnatalité.",
            title: "Ajouter un questionnaire personnalisé",
            link: "ajouter un questionnaire personnalisé"
        }
    }
}