import Render from '@Views/root/accounting/receipt/main.html'
import { Component } from 'vue-property-decorator'
import { AutoSaver } from '@Components/index'
import { Accounting } from '@Models/index'
import { RequestStatus, AutoSaverState, PaymentMode } from '@Enums/index'
import { ASideReceipt } from './aside-receipt'
import { IBreadcrumbItem, INotification, IPopinAction, IPopin, IOffice, ISession, IInvoice, IAccountingFilter } from '@Store/types'
import { accounting, breadcrumb, notif, office, user } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-receipt': ASideReceipt
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	submitted: boolean = false

	constructor() {
		super('receipt', false)
	}

	//#region Getters
	get receipt(): IInvoice {
		return accounting.invoice
	}
	get isLoading(): boolean {
		return accounting.status === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return accounting.isSaving
	}
	get isDeleting(): boolean {
		return accounting.isDeleting
	}
	private get selectedOfficeId(): string {
		return !!this.filter ? this.filter.ofi_id : '-1'
	}
	private get filter(): IAccountingFilter {
		return accounting.filter
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IInvoice): void {
		accounting.invoiceSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		const { receipt } = this
		accounting.saveInvoice({invoice: receipt})
		.then((newReceipt: IInvoice) => {
			super.initializeValue()
			let message: string = this.$i18n.t('vm.root.accounting.receipt.main.create', {number: newReceipt.number}).toString()
			let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
			notif.success(notification)

			this.submitted = false

			this.$router.push({name: 'accounting'})
		})
		.catch(() => {
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
		this.initializeReceipt()
	}

	beforeDestroy() {
		accounting.clearInvoice()
	}

	onDelete() {
		// Appelé quand on efface le rdv depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.accounting.receipt.main.cancel.label').toString(),
			title: this.$i18n.t('vm.root.accounting.receipt.main.cancel.title').toString(),
			callback: (data: any) => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.accounting.receipt.main.cancel.title').toString(),
			content: this.$i18n.t('vm.root.accounting.receipt.main.cancel.content').toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	cancelAction(): void {
		super.cancel()
		this.submitted = false
		this.$router.push({name: 'accounting'})
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	//#region private methods
	private canSave(): boolean {
		const { receipt } = this
		return !!receipt && !!receipt.label
	}

	private createNewReceipt(): IInvoice {
		let ofiId: string = null
		let defaultMode: PaymentMode = PaymentMode.Check
		if (!!this.selectedOfficeId && parseInt(this.selectedOfficeId) !== -1) {
			ofiId = this.selectedOfficeId
		} else {
			let offices: IOffice[] = office.offices.filter(o => { return office.isActive(o) })
			let _office = (!!offices && offices.length > 0) ? offices[0] : null
			ofiId = !!_office ? _office.id : null
			defaultMode = !!_office ? _office.coordinates.mode : PaymentMode.Check
		}

		let receipt: IInvoice = Accounting.createNewReceipt(user.currentUser.id, ofiId)
		receipt.ttc = this.getTtc(ofiId)
		receipt.tva = this.getTva(ofiId)
		receipt.mode = defaultMode

		return receipt
	}

	private getTtc(ofiId: string): number {
		if (!ofiId) return 0

		let _office: IOffice = office.offices.find(o => { return o.id === ofiId})
		let sessions: ISession[] = office.sessions(ofiId, user.currentUser.id)

		return (!sessions || sessions.length === 0) ? (!!_office ? _office.coordinates.ttc : 0) : sessions[0].ttc
	}

	private getTva(ofiId: string): number {
		if (!ofiId) return 0

		let _office: IOffice = office.offices.find(o => { return o.id === ofiId})

		return !!_office ? _office.coordinates.tva : 0
	}

	private initializeReceipt(): void {
		let payload = {inv_id: this.$route.params.inv_id}
		if (payload.inv_id === undefined) {
			this.restoreValue(this.createNewReceipt())
			super.initializeValue()
		} else {
			accounting.loadInvoice(payload)
			.then(() => { super.initializeValue() })
			.catch(error => {
				if (error.status !== 406) return

				this.$router.push({ name: 'accounting' })
			})
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.receipt').toString() }
		breadcrumb.updateItems([item1, item2])
	}
	//#endregion
}
