import { EnumsHelper } from "@Helpers/enums"
import { MemberType } from "@Enums/member"
import { Profile } from "@Enums/profile"
import { Level } from "@Enums/level"
import { Situation } from "@Enums/situation"
import { QuestionType, FormType } from "@Enums/form"
import { ArticulationMove, StrainMove } from "@Enums/move"
import { DirectionHorizontal, DirectionVertical } from "@Enums/direction"
import { FollowUpLevel } from "@Enums/reminder"
import { Gender } from "@Enums/gender"
import { Alimentation } from "@Enums/alimentation"
import { Currency } from "@Enums/currency"
import { WidgetType } from "@Enums/widget"
import { NewsType } from "@Enums/news"
import { PackType } from "@Enums/purchase"

export const news = function (type: NewsType) {
  return EnumsHelper.newsTypeToString(type)
}

export const pack = function (type: PackType) {
  return EnumsHelper.packTypeToString(type)
}

export const giftPack = function (type: PackType, value: number = 1) {
  return EnumsHelper.giftPackTypeToString(type, value)
}

export const widget = function (widget: WidgetType, count: number = 1) {
  return EnumsHelper.widgetToString(widget, count)
}

export const currency = function (currency: Currency, short: boolean = true) {
  return EnumsHelper.currencyToString(currency, short)
}

export const member = function (type: MemberType, gender: Gender) {
  return EnumsHelper.memberToString(type, gender)
}

export const members = function (type: MemberType, count: number = 1) {
  return EnumsHelper.membersToString(type, count)
}

export const profile = function (profile: Profile) {
  return EnumsHelper.profileToString(profile)
}

export const profileIcon = function (profile: Profile) {
  return EnumsHelper.profileToIcon(profile)
}

export const level = function (level: Level) {
  return EnumsHelper.levelToString(level)
}

export const followUpLevel = function (level: FollowUpLevel) {
  return EnumsHelper.followUpLevelToString(level)
}

export const situation = function (situation: Situation, gender?: Gender) {
  return EnumsHelper.situationToString(situation, gender)
}

export const gender = function (gender: Gender) {
  return EnumsHelper.genderToString(gender)
}

export const question = function (type: QuestionType) {
  return EnumsHelper.questionTypeToString(type)
}

export const form = function (type: FormType) {
  return EnumsHelper.formTypeToString(type)
}

export const articulationMove = function (move: ArticulationMove) {
  return EnumsHelper.articulationMoveToString(move)
}

export const horizontalDirection = function (direction: DirectionHorizontal) {
  return EnumsHelper.horizontalDirectionToString(direction)
}

export const verticalDirection = function (direction: DirectionVertical) {
  return EnumsHelper.verticalDirectionToString(direction)
}

export const strainMove = function (move: StrainMove) {
  return EnumsHelper.strainMoveToString(move)
}

export const alimentation = function (alimentation: Alimentation) {
  return EnumsHelper.alimentationToString(alimentation)
}
