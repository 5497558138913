import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { INews, IStickyNote, StickyNoteSharePayload } from "@Store/types"
import {RequestStatus, WidgetType} from "@Enums/index"
import {BlogService, StickyNoteService, WidgetService} from "@Services/index"

@Module({
	name: 'home',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class HomeModule extends VuexModule {
	_unreadFollowUp: number =  0
	status: RequestStatus = RequestStatus.None
    statusWidget: RequestStatus = RequestStatus.None
    statusBirthday: RequestStatus = RequestStatus.None
    statusStickyNote: RequestStatus = RequestStatus.None
    statusDeleting: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusSharing: RequestStatus = RequestStatus.None
    news: INews[] = []

	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isWidgetLoading() : boolean {
		return this.statusWidget === RequestStatus.Loading
	}
	get isStickyNoteLoading(): boolean {
		return this.statusStickyNote === RequestStatus.Loading
	}
	get isSharing(): boolean {
		return this.statusSharing === RequestStatus.Loading
	}
	get isLoadingBirthday(): boolean {
		return this.statusBirthday === RequestStatus.Loading
	}
	get hasUnreadFollowUp(): boolean {
		return this._unreadFollowUp > 0
	}
	get unreadFollowUpCount(): number {
		return this._unreadFollowUp
	}

	@Mutation
	clear() {
		this._unreadFollowUp = 0
		this.statusWidget = RequestStatus.None
		this.statusBirthday = RequestStatus.None
		this.statusStickyNote = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusSharing = RequestStatus.None
		this.status = RequestStatus.None
		this.news = []
	}

	@Mutation
	clearNews() {
		this.status = RequestStatus.None
		this.news = []
	}

	@Mutation
	newsRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	newsSuccess(news: INews[]) {
		this.status = RequestStatus.Success
		this.news = [...this.news, ...news]
	}

	@Mutation
	newsFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	widgetsRequest() {
		this.statusWidget = RequestStatus.Loading
	}

	@Mutation
	widgetsSuccess() {
		this.statusWidget = RequestStatus.Success
	}

	@Mutation
	widgetsFailure() {
		this.statusWidget = RequestStatus.Failed
	}

	@Mutation
	birthdayRequest() {
		this.statusBirthday = RequestStatus.Loading
	}

	@Mutation
	birthdaySuccess() {
		this.statusBirthday = RequestStatus.Success
	}

	@Mutation
	birthdayFailure() {
		this.statusBirthday = RequestStatus.Failed
	}

	@Mutation
	stickyNoteRequest() {
		this.statusStickyNote = RequestStatus.Loading
	}

	@Mutation
	stickyNoteSuccess() {
		this.statusStickyNote = RequestStatus.Success
	}

	@Mutation
	stickyNoteFailure() {
		this.statusStickyNote = RequestStatus.Failed
	}

	@Mutation
	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess() {
		this.statusSaving = RequestStatus.Success
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	shareRequest() {
		this.statusSharing = RequestStatus.Loading
	}

	@Mutation
	shareSuccess() {
		this.statusSharing = RequestStatus.Success
	}

	@Mutation
	shareFailure() {
		this.statusSharing = RequestStatus.Failed
	}

	@Mutation
	unreadFollowUp(count: number) {
		this._unreadFollowUp = count
	}

	@Action({rawError: true})
	async checkUnreadFollowUp(): Promise<any> {
		let service = new WidgetService()
		return service.getNotificationFollowUp()
			.then(result => { return Promise.resolve(result.count) })
			.catch(error => { return Promise.reject(error) })
	}

	@Action({rawError: true})
	async loadNews(page: number): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.newsRequest()

		let service = new BlogService()
		return service.getNewsList(page)
		.then(
			news => {
				this.newsSuccess(news)
				return Promise.resolve(news)
			}
		)
		.catch(error => {
			this.newsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadWidget(type: WidgetType): Promise<any> {
		let service = new WidgetService()
		return service.getWidget(type)
		.then(
			widget => {
				return Promise.resolve(widget)
			}
		)
		.catch(error => {
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadStickyNotes(): Promise<any> {
		if (this.statusStickyNote === RequestStatus.Loading) return

		this.stickyNoteRequest()

		let service = new StickyNoteService()
		return service.getStickyNoteList()
		.then(
			postIts => {
				this.stickyNoteSuccess()
				return Promise.resolve(postIts)
			}
		)
		.catch(error => {
			this.stickyNoteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteStickyNote(stickyNote: IStickyNote): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		const {id} = stickyNote
		this.deleteRequest()

		let service = new StickyNoteService()
		return service.deleteStickyNote(id)
		.then(() => {
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveStickyNote(stickyNote: IStickyNote): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new StickyNoteService()
		return service.save(stickyNote)
		.then(newPostIt => {
			this.saveSuccess()
			return Promise.resolve(newPostIt)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async shareStickyNote(payload: StickyNoteSharePayload): Promise<any> {
		if (this.statusSharing === RequestStatus.Loading) return

		this.shareRequest()

		let service = new StickyNoteService()
		return service.share(payload.id, payload.shareList)
		.then(newStickyNote => {
			this.shareSuccess()
			return Promise.resolve(newStickyNote)
		})
		.catch(error => {
			this.shareFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async birthdays(date: Date): Promise<any> {
		if (this.statusBirthday === RequestStatus.Loading) return

		this.birthdayRequest()

		let service = new WidgetService()
		return service.getBirthdayList(date)
		.then(
			widget => {
				this.birthdaySuccess()
				return Promise.resolve(widget)
			}
		)
		.catch(error => {
			this.birthdayFailure()
			return Promise.reject(error)
		})
	}
}

export const home = getModule(HomeModule)
// export const home = new HomeModule({ store, name: "home" })
