import Render from '@Views/root/help/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { ASideSupport } from './aside-support'
import { ContextMain } from './context-main'
import { Debouncer } from '@Models/index'
import { breadcrumb, support } from '@Store/modules'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'aside-support': ASideSupport
	}
})
export class Main extends Vue {

	absentPeriod: string = ""
	private centralElement: Element = null
	private ticketHeader: Element = null
	private debouncerScroll: Debouncer = null

	get hasHolidays(): boolean {
		if (!support.holidays) return false

		let start: Date = new Date(support.holidays.start)
		start.setDate(start.getDate() - 7)
		let end: Date = new Date(support.holidays.end)
		let now = new Date()

		let startString: string = this.$i18n.d(support.holidays.start, 'll').toString()
		let endString: string = this.$i18n.d(end, 'll').toString()

		this.absentPeriod = now >= support.holidays.start ?
			this.$i18n.t('vm.root.help.main.current-holidays', {end: endString}).toString() :
			this.$i18n.t('vm.root.help.main.holidays', {start: startString, end: endString}).toString()

		return start <= now && now <= end
	}

	mounted() {
		this.debouncerScroll = new Debouncer(this.handleScroll, 100)
		support.loadCount()
		.then(result => { support.unreadTickets(result) })

		breadcrumb.updateLinked(true)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerScroll)
	}

	triggerHandleScroll() {
		this.debouncerScroll.start()
	}

	private handleScroll() {
		if (!this.centralElement || !this.ticketHeader) {
			this.centralElement = document.getElementsByClassName('l-central')[0]
			this.ticketHeader = document.getElementsByClassName('ticket-header')[0]
		}

		if (!this.centralElement || !this.ticketHeader) return

		if (this.centralElement.scrollTop > this.ticketHeader.scrollHeight) {
			this.ticketHeader.classList.add("is-narrow");
		} else {
			this.ticketHeader.classList.remove("is-narrow");
		}
	}
}
