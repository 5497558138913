import Render from '@Views/components/input/dropdown.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Render
@Component({})
export class Dropdown extends Vue {

    open: boolean = false
    selectedItem: IDropdownItem = {value: null, label: ''}
    private selectedIndex: number = null

    @Prop({default: 'select-like'}) trigger: string
	@Prop({default: 'sélectionnez une valeur'}) defaultLabel: string
	@Prop() caret: string
    @Prop({default: ''}) defaultValue: any
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) manageMissing: boolean
    @Prop({default: true}) displaySelectedItem: boolean
    @Prop({default: 'both'}) menuStuck: string // both | left | right
    @Prop({default: false}) menuOnTop: boolean
    @Prop() title: string
    @Prop() items: IDropdownItem[]
    @Prop() id: string
    @Prop() value: any

    mounted() {
        this.updateValue()
    }

    @Watch('value')
    updateValue(){
        let item:IDropdownItem = undefined
        let index: number | false = false
        if (this.value !== undefined && !!this.items && this.items.length > 0) {
            item = this.items.find(item => item.value === this.value || item.value === this.value && this.value.toString())
            index = this.items.indexOf(item)
        }
        if (this.manageMissing && !item && !!this.value) {
            item = {value: this.value, label: this.value}
            index = this.items.length
            this.items.push(item)
        }

        this.selectedItem = !!item ? item : {value: this.defaultValue, label: this.defaultLabel, default: true}
        this.selectedIndex = index === false ? null : index
    }

    toggleButton(): void {
        this.open = !this.open
    }

    selectValue(item: IDropdownItem): void {
        this.selectedItem = item
        this.handleInput(item.value)
    }

    keyboardNav(e: any): void {
        if (!this.items || this.items.length === 0) return

        let list: HTMLElement = this.$refs.list as HTMLElement
        if (list === undefined) return

        switch(e.key) {
            case "Down":
            case "ArrowDown":
                e.preventDefault()
                this.keyDown(list)
                break
            case "Up":
            case "ArrowUp":
                e.preventDefault()
                this.keyUp(list)
                break
            case "Enter":
                e.preventDefault()
                this.keyEnter()
                break
            case "End":
                e.preventDefault()
                this.keyEnd(list, this.items.length -1)
                break
            case "Home":
                e.preventDefault()
                this.keyHome(list, 0)
                break
            default:
                break
        }
    }

    reset(): void {
        let index = this.items.indexOf(this.selectedItem)
        this.selectedIndex = index === -1 ? null : index
        this.open = false
    }

    isSelected(item: IDropdownItem): boolean {
        return item.value === this.selectedItem.value
    }

    getColor(color: string): string {
        return !!color ? `color:${color}` : ''
    }
    
    getTriggerClass(suffix: string): string {
        return this.trigger === 'select-like' ? `${this.trigger}${suffix}` : `dropdown${suffix}`
    }

    private keyEnter() {
        if (this.selectedIndex === null || this.selectedIndex < 0) return
        this.selectValue(this.items[this.selectedIndex])
    }

    private keyUp(list: HTMLElement) {
        let index = !!this.selectedIndex ? Math.max(0, this.selectedIndex - 1) : this.items.length - 1

        this.keyEnd(list, index)
    }

    private keyDown(list: HTMLElement) {
        let index = this.selectedIndex === null ? -1 : this.selectedIndex
        index = index === this.items.length - 1 ? 0 : Math.min(this.items.length - 1, index + 1)

        this.keyHome(list, index)
    }

    private keyHome(list: HTMLElement, index: number) {
        this.open = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.focus(selectedElement)
        if (selectedElement.offsetTop + selectedElement.clientHeight <= list.scrollTop + list.clientHeight) return

        list.scrollTop = selectedElement.offsetTop - list.clientHeight + selectedElement.clientHeight
    }

    private keyEnd(list: HTMLElement, index: number) {
        this.open = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.focus(selectedElement)
        if (selectedElement.offsetTop >+ list.scrollTop) return

        list.scrollTop = selectedElement.offsetTop
    }

    private focus(element: HTMLElement) {
        if (!element.children || element.children.length === 0) return
        (element.children[0] as HTMLElement).focus()
    }

    private handleInput(e: any) {
        this.$emit('input', e)
        this.toggleButton()
    }
}

export interface IDropdownItem {
    value: any,
    label: string,
    icon?:string,
    color?:string,
    default?: boolean
    separator?: boolean
    disabled?: boolean
}
