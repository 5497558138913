import { Dictionary } from "vue-router/types/router"
import { IDocument, ILetterFilter } from '@Store/types'

export class Document {
	public static createLetterFilter(): ILetterFilter {
		return {
			doc_id: '-1',
			pat_id: '-1',
			con_id: '-1',
			ofi_id: '-1',
			inv_id: '-1',
			patient: null,
			invoices: []
		}
	}

    public static createNewDocument(createId: string): IDocument {
        return {
            id: '-1',
            createId: createId,
            active: true,
            title: '',
            render: '',
            default: false,
            single: false,
            need_consultation: false,
            need_invoice: false,
            need_office: false,
            need_patient: false,
            reset: false
        }
	}

    public static getKeywords(): Dictionary<string[]> {
		return {
			'general': [
				'{date.jour}'
			],
			'user': [
				'{mon.nom}',
				'{mon.prenom}',
				'{mon.siren}',
				'{mon.logo}',
				'{mon.adeli}',
				'{mon.titre}'
			],
			'office': [
				'{cabinet.telephone}',
				'{cabinet.adresse}',
				'{cabinet.cp}',
				'{cabinet.ville}'
			],
			'patient': [
				'{patient.nom}',
				'{patient.prenom}',
				'{patient.adresse}',
				'{patient.cp}',
				'{patient.ville}',
				'{patient.naissance}',
				'{patient.secu}'
			],
			'consultation': [
				'{consultation.date}',
				'{consultation.debut}',
				'{consultation.fin}',
				'{consultation.motif}',
				'{consultation.evolution}',
				'{consultation.observation}',
				'{consultation.diagnostic}',
				'{consultation.tests}',
				'{consultation.traitement}',
				'{consultation.eva1}',
				'{consultation.eva2}',
				'{consultation.poids}',
				'{consultation.taille}'
			],
			'invoice': [
				'{facture.numero}',
				'{facture.date}',
				'{facture.montant}',
				'{facture.reglee}',
				'{facture.taux_tva}',
				'{facture.montant_tva}',
				'{facture.ht}'
			]
		}
	}
}
