import Render from '@Views/root/user/account/formations.html'
import draggable from 'vuedraggable'
import { Dictionary } from 'vue-router/types/router'
import * as Formation from './formation/index'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'
import { User, Common } from '@Models/index'
import { ICurriculum, IUser } from '@Store/types'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'draggable': draggable,
		'formation': Formation.Main
	}
})
export class Formations extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() user: IUser

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get formations(): ICurriculum[] {
		return this.user.formations.currents
	}

	mounted() {
		this.$emit('navigate', 'formation-user')
		this.initializeBreadcrumb()

		this.moveToFormation()
	}

	toggle(id: string, createId?: string): void {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	addFormation(): void {
		let createId: string = Common.generateId()
		let formation: ICurriculum = User.createNewCurriculum(createId)
		this.toggle(formation.id, formation.create_id)
		this.user.formations.currents.push(formation)
		setTimeout(() => { this.moveToFormation(formation) }, 100)
	}

	getElementId(formation: ICurriculum): string {
		return parseInt(formation.id) === -1 ? formation.create_id : formation.id
	}

	@Watch('submitted')
	private moveToFormation(formation?: ICurriculum) {
		formation = !formation ? this.formations.find(a => a.error) : formation
		if (!formation) return

		if (!this.isToggle(formation.id, formation.create_id)) {
			this.toggle(formation.id, formation.create_id)
		}
		let ses_id: string = this.getElementId(formation)
		let element = this.$refs[ses_id] as any
		if (!element || element.length === 0) return

		let centralElement: Element = document.getElementsByClassName('l-central')[0]
		centralElement.scrollTo(0, element[0].offsetTop)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.formations', this.formations.length).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}