import Render from '@Views/root/parameters/accounting.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { AccountingPeriod } from '@Enums/index'
import { breadcrumb } from '@Store/modules'

@Render
@Component({})
export class Accounting extends Vue {

	@Prop() configuration: IConfiguration

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void

	//#region getters
	get periods(): IDropdownItem[] {
		return [
			{label:EnumsHelper.periodToString(AccountingPeriod.Monthly), value:AccountingPeriod.Monthly}, 
			{label:EnumsHelper.periodToString(AccountingPeriod.Yearly), value:AccountingPeriod.Yearly}
		]
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'accounting')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}