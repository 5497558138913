import Render from '@Views/root/patients/patient/attachment/attachment-item.html'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {IAttachment} from '@Store/types'
import {AttachmentType, MimeType} from '@Enums/index'
import {patient, user} from '@Store/modules'

@Render
@Component({})
export class AttachmentItem extends Vue {

	@Prop() readOnly: boolean
	@Prop() attachment: IAttachment

	//#region Getter
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get isFile(): boolean {
		return this.attachment.type === AttachmentType.File
	}
	get isPdf(): boolean {
		return this.attachment.mime === MimeType.Pdf
	}
	get fileUrl(): string {
		if (!!this.attachment.url) return this.attachment.url
		return this.attachment.file
	}
	get owner(): string {
		return patient.owner(this.attachment)
	}
	private get routeId(): string {
		return parseInt(this.attachment.id) === -1 ? this.attachment.create_id : this.attachment.id
	}
	//#endregion

	submitDelete(): void {
		patient.deleteAttachment(this.attachment)
	}

	updateAttachment(): void {
		this.$emit('edit')
		this.goTo()
	}

	goTo(): void {
		this.$router.push({name:'patient-attachment', params:{pat_id:this.$route.params.pat_id, item_id: this.routeId}})
	}
}
