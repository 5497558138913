<div class="l-container">

	<template v-if="loading">
		<div class="l-aside l-aside-nav"></div>
		<div class="l-loading">
			<spinner class="l-loading-spinner svg-block svg-center text-primary"/>
			<p>{{loadingTitle}}&hellip;</p>
		</div>
	</template>

	<template v-else>
		<breadcrumb />
		<slot/>
	</template>

</div>
