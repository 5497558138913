export const asideSchedule = {
    back: {
        title: "Retournez à la liste de vos cabinets",
        label: "Retour aux cabinets"
    },
    'back-office': {
        title: "Retournez au cabinet",
        label: "Retour au cabinet"
    },
    schedule: {
        title: "Mettez à jour les horaires de votre cabinet",
        label: "Horaires simples"
    },
    'new-schedule': {
        title: "Créez un nouvel horaire spécifique à une période",
        label: "Créer un horaire spécifique"
    },
    'specific-schedule': {
        title: "Modifiez cet horaire spécifique",
        'simple-label': "Horaires semaine {start}",
        'multi-label': "Horaires semaines {start} à {end}",
        sublabel: "du {start} au {end}"
    },
}
