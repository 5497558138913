import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { ILinkUser, IUser, IAccount, ILink } from '@Store/types'
import { Profile, Provider, RequestStatus } from "@Enums/index"
import { AccountService } from "@Services/index"
import { office } from "./office"
import { user } from "./user"

@Module({
	name: 'account',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class AccountModule extends VuexModule {
    statusVerifying: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusDeleting: RequestStatus = RequestStatus.None
    status: RequestStatus = RequestStatus.None
    profile: Profile = Profile.None
    accounts: IAccount[] = []
    account: IAccount = null
    verifyLink: ILink = null

	get selectedProfile(): Profile {
        return this.profile
	}
	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isVerifying(): boolean {
		return this.statusVerifying === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
    get existsAccount(): boolean {
        return !!this.account && parseInt(this.account.id) !== -1
	}
	get activeAccount() : (ofi_id: string) => ILinkUser[] {
		return (ofi_id: string) => {
			let links: ILinkUser[] = office.links(ofi_id)
			let _user: IUser = user.currentUser
			let result: ILinkUser[] = [{
				id: _user.id,
				activate: [],
				coordinates: {
					last_name: _user.coordinates.last_name,
					first_name: _user.coordinates.first_name
				},
				last_name: _user.coordinates.last_name,
				first_name: _user.coordinates.first_name,
				email: _user.email,
				profile: _user.profile
			}]
			links.forEach(user => {
				if (this.accounts.filter(account => { return account.id === user.id }).length > 0)
					result.push(user)
			})
			return result
		}
    }

	@Mutation
	clear() {
		this.statusVerifying = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.status = RequestStatus.None
		this.profile = Profile.None
		this.accounts = []
		this.account = null
		this.verify = null
	}

	@Mutation
	selectProfile(profile: Profile) {
		this.statusVerifying = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.profile = profile
	}

    @Mutation
    accountsRequest() {
		this.status = RequestStatus.Loading
		this.accounts = []
    }

    @Mutation
	accountsSuccess(accounts: IAccount[]) {
		this.status = RequestStatus.Success
		this.accounts = accounts
    }
    @Mutation
	accountsFailure() {
		this.status = RequestStatus.Success
		this.accounts = []
    }

    @Mutation
    verifyRequest(link:ILink) {
		this.statusVerifying = RequestStatus.Loading
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.account = null
		this.verifyLink = link
    }

    @Mutation
	verifySuccess(account: IAccount) {
		this.statusVerifying = RequestStatus.Success
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.account = account
		this.verifyLink = null
    }

    @Mutation
	verifyFailure() {
		this.statusVerifying = RequestStatus.Failed
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.verifyLink= null
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(account: IAccount) {
		this.statusSaving = RequestStatus.Success
		this.account = account
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
		this.profile = Profile.None
		this.account = null
	}

	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

    @Action({rawError: true})
    async loadAccounts(): Promise<any> {
		if (this.isLoading) return

        this.accountsRequest()

		let service = new AccountService()
		return service.getAccountList()
		.then(accounts => {
            this.accountsSuccess(accounts)
			return Promise.resolve(accounts)
		})
		.catch(error => {
            this.accountsFailure()
			return Promise.reject(error)
		})
    }

    @Action({rawError: true})
    async verify(payload: {email: string, profile: Profile, provider: Provider}): Promise<any> {
		if (this.isVerifying) return

		this.verifyRequest({email: payload.email, profile: payload.profile})

		let service = new AccountService()
		return service.verify(payload.email, payload.profile, payload.provider)
		.then(account => {
			this.verifySuccess(account)
			return Promise.resolve(account)
		})
		.catch(error => {
			this.verifyFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async save(account: IAccount): Promise<any> {
		if (this.isSaving) return

		this.saveRequest()

		let service = new AccountService()
		return service.save(account)
		.then(
			newAccount => {
				this.saveSuccess(newAccount)
				if (account.provider === Provider.None) {
					office.updateLinks(newAccount)
				} else {
					office.loadOffices()
				}
				this.loadAccounts()
				return Promise.resolve(newAccount)
			}
		)
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async delete(payload:{ id: string, provider: Provider }): Promise<any> {
		if (this.isDeleting) return

		this.deleteRequest()

		let service = new AccountService()
		return service.delete(payload.id, payload.provider)
		.then(
			() => {
				this.deleteSuccess()
				if (payload.provider === Provider.None) {
					office.removeLinks(payload.id)
				} else {
					office.loadOffices()
				}
				return Promise.resolve(payload.id)
			}
		)
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}
}

export const account = getModule(AccountModule)
// export const account = new AccountModule({ store, name: "account" })
