import Render from '@Views/root/user/gift/gift2.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IBreadcrumbItem, IGift } from '@Store/types'
import { GiftState } from '@Enums/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class Gift2 extends Vue {

	submitted: boolean = false

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('user/submitGift2') submitGift2:(payload: Gift2Payload) => Promise<any>
	
	@Prop() gift: IGift
	
	get isSaving(): boolean {
		return user.isGiftSaving
	}
	get cities(): string[] {
		return this.gift.cities
	}
	get targets(): string[] {
		return this.gift.targets
	}
	get hasCityError(): boolean {
		return this.submitted && this.missingCities
	}
	get canSubmit(): boolean {
		return this.gift.second_state === GiftState.Running
	}
	private get missingCities(): boolean {
		return this.cities.filter(city => { return !!city && !!city.trim() }).length < 3
	}

	mounted() {
		// this.$emit('navigate', 'referral')
		this.initializeBreadcrumb()
	}

	previous(): void {
        this.$router.push({name: 'user-gift1'})
	}
	
	next(): void {
        this.$router.push({name: 'user-gift3'})
	}

	submitGift(): void {
		this.submitted = true

		if (!this.canSubmit || this.missingCities) return

		user.submitGift2({cities: this.cities, targets: this.targets})
		.then((gift: IGift) => {
			this.submitted = false
			this.$emit('restore', gift)
		})
		.catch((error) => { this.submitted = false })
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift').toString(), link: 'user-gift' }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift2').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}