<div class="input-frame">
	<label :for="id" :data-text="label" class="label">
		<span>{{label}}</span>
	</label>
	<div class="toggledate">
		<input type="number" :id="id" min="0" v-model="index"
			:placeholder="placeholder" :title="title" :disabled="disabled" :readonly="readonly">
		<togglebox :id="`${id}-toggledate`" v-model="type" :items="items" :disabled="disabled" :readonly="readonly"/>
	</div>
</div>
