<container>
	<aside-document v-if="isDocumentView" :configuration="configuration"/>
	<aside-main v-else/>

	<master>

		<central>
			<div class="wrapper">

				<form @submit.prevent="submitSave">

					<router-view ref="mainView" :configuration="configuration" :submitted="submitted" @navigate="onNavigate"/>

					<control-bar class="childs-space-between">
						<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || disabled"
							:title="$t('vm.root.parameters.main.cancel.title-button')">
							<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
							</svg>
							{{ $t('vm.root.parameters.main.cancel.label') }}
							<span class="rwd-over-xlarge">{{ $t('vm.root.parameters.main.cancel.long') }}</span>
						</button-action>
						<button-action type="submit" btn-class="btn-primary is-pushed-right" :loading="isSaving" :disabled="disabled"
								:title="$t('vm.root.parameters.main.save.title')">
							{{ $t('vm.root.parameters.main.save.label') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							</svg>
						</button-action>
					</control-bar>
				</form>

			</div>
		</central>

		<context-document v-if="isDocumentView" :configuration="configuration" @insert="onInsert"/>
	</master>

</container>
