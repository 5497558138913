import Render from '@Views/components/super-panel/layout/super-panel-quick-event.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDropdownItem } from '@Components/input/dropdown'
import { ReminderType, PlanningType } from '@Enums/index'
import { SearchPatient, PatientItem } from '@ViewModels/root/search/patient'
import { IOffice, IScheduleUser, ISession, IPlanning, IConfiguration, IUser, IPopin, IPopinAction, IPatient, IEvent, IColumnSlot } from '@Store/types'
import { DropdownHelper, DateHelper, EnumsHelper } from '@Helpers/index'
import { configuration, event, office, planning, popIn, user } from '@Store/modules'

@Render
@Component({
	components: {
		'patient-item': PatientItem,
		'search-patient': SearchPatient
	}
})
export class SuperPanelQuickEvent extends Vue {

	private static MaxRow:number = 5

	weekdays: {index: number, number: number, month: string, label: string, today: boolean}[]
	isEmpty: boolean = false
	hideSeeMoreButton: boolean = false
	skeleton: IColumnSlot[][] = []
	noMoreSlot: boolean = false
	private date: Date = null
	private reminders: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]
	private _substitutes: IScheduleUser[] = null

    @Prop() options: {event: IEvent}
	@Prop({default: false}) submitted: boolean

	//#region Getters
	get event(): IEvent {
		return this.options.event
	}
	get isLoadingSlot(): boolean {
		return event.isLoadingSlot
	}
	get freeSlots(): IColumnSlot[][] {
		return event.slots
	}
	get isClassic(): boolean {
		return this.event.type === PlanningType.Classic
	}
	get officeItems(): IDropdownItem[] {
		return office.offices.filter(o => { return office.isActive(o) }).map(o => { return {value: o.id, label: o.coordinates.name} })
	}
	get planningItems(): IDropdownItem[] {
		return DropdownHelper.toDropdownItems(planning.editablePlannings)
	}
	get remindersItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.reminders.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
	    return result
	}
	get confirmationsItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		this.reminders.forEach (type => result.push({value: type, label: EnumsHelper.confirmationToString(type)}))
		return result
	}
	get countDay(): number {
		return window.matchMedia("(min-width: 600px)").matches ? 7 : 4
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get user(): IUser {
		return user.user
	}
	private get selectedOffice(): IOffice {
		return office.selectedPlanningOffice
	}
	private get selectedUser(): IUser {
		return user.selectedPlanningUser
	}
	//#endregion

	mounted() {
		this.loadSkeleton()
		this.date = new Date()
		this.loadDays()

		this.options.event = this.createNewEvent()
		this.loadFreeSlot()
		this.updateSubstitutes()
	}

	next() {
		this.date.setDate(this.date.getDate() + this.countDay)
		this.loadDays()
		this.updateSubstitutes()

		this.loadFreeSlot()
	}

	previous() {
		this.date.setDate(this.date.getDate() - this.countDay)
		this.loadDays()
		this.updateSubstitutes()

		this.loadFreeSlot()
	}

	hasSessions(id: string): boolean {
		return office.hasSessions(id)
	}

	canPrevious(): boolean {
		let today: Date = new Date()
		today.setHours(0,0,0,0)

		let date: Date = new Date(this.date)
		date.setDate(date.getDate() - this.countDay)
		date.setHours(0,0,0,0)

		return  date >= today
	}

	withUser(id: string): boolean {
		let usrId: string = user.isSecretaryUser ? (!!this.selectedUser ? this.selectedUser.id : undefined) : this.user.id
		return !!id && parseInt(id) !== -1 && id !== usrId
	}

	getSubstitutes(): IScheduleUser[] {
		if (!this._substitutes) this.updateSubstitutes()

		return this._substitutes
	}

	getSubstituteColor(id: string): string {
		let substitute: IScheduleUser = this._substitutes.find(s => { return s.id === id})
		return !!substitute ? substitute.color : ''
	}

	@Watch('event.ofi_id')
	@Watch('event.ses_id')
	loadFreeSlot(): void {
		event.loadFreeSlot({usr_id: this.event.usr_id, ofi_id: this.event.ofi_id, ses_id: this.event.ses_id, date: this.date, count_day: this.countDay})
		.then(() => { this.analyze() })
		.catch(error => console.error(error))
	}

	nextFreeSlot(): void {
		this.date.setDate(this.date.getDate() + this.countDay)
		event.nextFreeSlot({usr_id: this.event.usr_id, ofi_id: this.event.ofi_id, ses_id: this.event.ses_id, date: this.date, count_day: this.countDay})
		.then((results) => {
			this.date = results.date
			this.loadDays()
			this.analyze(true)
		})
		.catch(error => { console.error(error) })
	}

	sessionItems(ofi_id: string): IDropdownItem[] {
		return office.sessions(ofi_id)
		.map(s => {
			let item: IDropdownItem = { value: s.id, label: s.name }
			if (s.with_color && !!s.color) {
				item.icon = 'circle'
				item.color = s.color
			}
			return item
		})
	}

	addPatient(patient: IPatient): void {
		if (!patient) return

		patient.coordinates.without_birth_date = !patient.coordinates.birth_date
		this.event.patient = patient
	}

	unselect(): void {
		let unselectAction: IPopinAction = {
			label: this.$i18n.t('vm.components.super-panel.quick-event.unselect.label').toString(),
			title: this.$i18n.t('vm.components.super-panel.quick-event.unselect.title').toString(),
			callback: (data: any) => {
				this.unselectPatient()
				return Promise.resolve()
			}
		}
		let unselectPopin: IPopin = {
			title: this.$i18n.t('vm.components.super-panel.quick-event.unselect.title').toString(),
			content: this.$i18n.t('vm.components.super-panel.quick-event.unselect.content').toString(),
			action: unselectAction
		}

		popIn.warning(unselectPopin)
	}

	getColumn(index: number): IColumnSlot[] {
		return this.freeSlots[index] || []
	}

	isSelectedDay(day: number): boolean {
		return !!this.getColumn(day).find(s => { return s.selected })
	}

	selectHour(slot: IColumnSlot) {
		this.unselectAll()
		if (!slot || !slot.value || parseInt(slot.value) === -1) return

		slot.selected = true
		let session: ISession = office.sessions(this.event.ofi_id).find(s => s.id === this.event.ses_id)

		let start: Date = new Date(slot.value)
		let end: Date = new Date(start.getTime() + session.duration * 60000)
		this.event.start_date = start
		this.event.end_date = end

		this.$forceUpdate()
	}

	seeMore(): void {
		this.hideSeeMoreButton = true
		for(let slots of this.freeSlots) {
			for(let slot of slots) {
				slot.hidden = false
			}
		}
		this.$forceUpdate()
	}

	private unselectAll(): void {
		for(let slots of this.freeSlots) {
			for(let slot of slots) {
				slot.selected = false
			}
		}
	}

	private analyze(next: boolean = false): void {
		this.noMoreSlot = false
		this.hideSeeMoreButton = false
		this.isEmpty = true
		for(let slots of this.freeSlots) {
			let index: number = 0
			for(let slot of slots) {
				slot.with_value = parseInt(slot.value) !== -1
				slot.hidden = index >= 5
				index++
				this.isEmpty = this.isEmpty && !slot.with_value
			}
		}
		this.noMoreSlot = next && this.isEmpty
	}

	private loadSkeleton(): void {
		this.skeleton = []
		for(let column: number = 0; column < this.countDay; column++) {
			let rows: IColumnSlot[] = []
			for(let row: number = 0; row < SuperPanelQuickEvent.MaxRow; row++) {
				rows.push({value: "-1", with_value: false, hidden: false})
			}
			this.skeleton.push(rows)
		}
	}

	private loadDays(): void {
		this.weekdays = []
		let today: Date = new Date()
		let date: Date = new Date(this.date)
		for(let i = 0; i < this.countDay; i++) {
            this.weekdays.push({
				index: i,
				number: date.getDate(),
                month: this.$d(date, 'MMM'),
				label: this.$d(date, 'weekday'),
				today: DateHelper.sameDay(today, date)
            })
            date.setDate(date.getDate() + 1)
		}
	}

	private unselectPatient(): void {
		this.event.patient = undefined
	}

	private getPlannings(): IPlanning[] {
		if (!user.isSecretaryUser)
		return planning.editablePlannings

		if (!this.selectedUser) return []
		return planning.editablePlannings.filter(p => { return p.usr_id === this.selectedUser.id})
	}

	private updateSubstitutes(): void {
		let usrId: string = user.isSecretaryUser ? (!!this.selectedUser ? this.selectedUser.id : undefined) : this.user.id
		let end = new Date(this.date)
		end.setDate(end.getDate() + this.countDay - 1)
		this._substitutes = office.scheduleUsers(this.event.ofi_id, usrId, this.date, end)
	}

	private createNewEvent(): IEvent {
		let reminderType: ReminderType = !!this.configuration.reminder_type ? this.configuration.reminder_type : ReminderType.Both
		let confirmationType: ReminderType = !!this.configuration.confirmation_type ? this.configuration.confirmation_type : ReminderType.Both
		let ofiId: string = null
		if (!!this.selectedOffice && parseInt(this.selectedOffice.id) !== -1) {
			ofiId = this.selectedOffice.id
		} else {
			let offices: IOffice[] = office.offices.filter(o => { return office.isActive(o) })
			let _office = (!!offices && offices.length > 0) ? offices[0] : null
			ofiId = !!_office ? _office.id : null
		}

		let sessions:ISession[] = office.sessions(ofiId)
		let session: ISession = (!!sessions && sessions.length > 0) ? sessions[0] : null

		let plannings: IPlanning[] = this.getPlannings()

		let planning = plannings.length > 0 ? plannings[0] : null
		let plaId = planning === null ? null : planning.id

		let event: IEvent = {
			id: '-1',
			label: "",
			missed: false,
			unvalid: false,
			color: null,
			with_color: null,
			start_date: null,
			end_date: null,
			all_day: false,
			ofi_id: !!ofiId ? ofiId : '-1',
			ses_id: !!session ? session.id : '-1',
			pla_id: plaId,
			old_pla_id: plaId,
			from_rec_id: null,
			confirmation_type: confirmationType,
			reminder_type: reminderType,
			reminder_mail: false,
			reminder_sms: false,
			relaunch: null,
			recurring: null,
			recurring_update: false,
			type: PlanningType.Classic,
			old_type: PlanningType.Classic,
			patient: null,
			usr_id: user.isSecretaryUser ? (!!this.selectedUser ? this.selectedUser.id : '-1') : this.user.id
		}

		return event
	}
}
