export const main = {
    'new-sticky-note': {
        title: "Ajoutez une nouvelle note",
        label: "nouvelle note"
    },
    loading: "Chargement en cours…",
    'change-log': "Change log",
    welcome: {
        'text-1': "Bonjour Maître de ces lieux",
        'text-2': "Paix et félicité",
        'text-3': "Respect et robustesse",
        'text-4': "Longue vie et prospérité",
        'text-5': "Que la Force soit avec vous",
        'text-6': "Salut, et encore merci pour le poisson",
        'text-7': "Force et honneur",
        'text-8': "La bonne journée, telle est la voie",
        'text-9': "Vous avez l'étoffe d'une mère de famille",
        'text-10': "Bonjour ! Ainsi ai-je parlé",
    }
}
