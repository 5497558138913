export const superPanelQuickEvent = {
    unselect: {
        label: "dissocier",
        title: "Dissociation",
        content: "Êtes-vous sûr de vouloir dissocier ce dossier patient de ce rendez-vous ?"
    },
    'reminder-type': "Type de rappel du rendez-vous",
    'confirmation-type': "Type de confirmation du rendez-vous",
    label: {
        label: "Intitulé du rdv",
        placeholder: "ex : Mal au dos",
        title: "Veuillez renseigner l'intitulé du rdv"
    },
    office: {
        label: "Cabinet lié à ce rdv",
        title: "Indiquez le cabinet lié à ce rdv",
        none: "Aucun cabinet"
    },
    session: {
        label: "Type de consultation",
        title: "Indiquez le type de consultation de la future consultation"
    },
    planning: {
        label: "Planning lié à ce rdv",
        title: "Sélectionnez le planning lié à ce rdv"
    },
    previous: {
        title: "Aller aux créneaux précédents",
        label: "précédent"
    },
    substitute: "Consultations assurées par un collaborateur :",
    'choose-hour': "Choisissez un horaire",
    next: {
        title: "Aller aux créneaux suivants",
        label: "suivant"
    },
    'select-hour': "Choisir le {day-label} {day-number} {day-month} à {hour}",
    'no-hour': "Aucun créneau disponible",
    'next-hour': {
        title: "Aller à la période contenant les prochaines disponibilités",
        label: "Prochaine disponibilité"
    },
    'more-hour': {
        title: "Afficher tous les horaires disponibles sur cette période",
        label: "Voir plus d'horaires"
    }
}
