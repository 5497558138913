<div v-if="!!configuration">
	<collapsible-help id="notification-information" :title="$t('vm.root.parameters.notifications.title')" tag="h1">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.parameters.notifications.informations') }}
			</p>
		</div>
	</collapsible-help>

	<div class="marg-v">
		<switch-frame id="notif-follow-up" :title="$t('vm.root.parameters.notifications.follow-up.title')" v-model="configuration.notification_follow_up">
			{{ $t('vm.root.parameters.notifications.follow-up.label') }}
		</switch-frame>
	</div>
	<div class="marg-v">
		<switch-frame id="notif-event" :title="$t('vm.root.parameters.notifications.event.title')" v-model="configuration.notification_event">
			{{ $t('vm.root.parameters.notifications.event.label') }}
		</switch-frame>
	</div>
	<div class="marg-v">
		<switch-frame v-if="isMainUser" id="notif-help" :title="$t('vm.root.parameters.notifications.help.title')" v-model="configuration.notification_help">
			{{ $t('vm.root.parameters.notifications.help.label') }}
		</switch-frame>
	</div>
</div>
