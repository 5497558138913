import Render from '@Views/root/patients/patient/antecedents.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { groupBy } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import * as Antecedent from './antecedent/index'
import { Patient, Common } from '@Models/index'
import { IAntecedent, ICoordinates, IBreadcrumbItem, IConfiguration } from '@Store/types'
import { FormSummary } from '@Components/forms/form-summary'
import { breadcrumb, configuration, patient, user } from '@Store/modules'

@Render
@Component({
	components: {
		'antecedent': Antecedent.Main,
		'family-antecedent': Antecedent.FamilyAntecedent,
		'form-summary': FormSummary
	}
})
export class Antecedents extends Vue {

	filter: string = ''
	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @State('configuration') stateConfiguration: ConfigurationState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/antecedents') antecedents: IAntecedent[]
	// @Getter('patient/coordinates') coordinates: ICoordinates
	// @Getter('user/isMainUser') isMainUser: boolean
	// @Getter('patient/owner') owner: (item: {id: string, creator_id: string}) => string
	// @Action('patient/deleteAntecedent') deleteAntecedent: (antecedent: IAntecedent) => void

	get coordinates(): ICoordinates {
		return patient.coordinates
	}
	get antecedents(): IAntecedent[] {
		return patient.antecedents
	}
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get antecedentsGrouped(): Dictionary<IAntecedent[]> {
		let result:Dictionary<IAntecedent[]> = groupBy(this.antecedents.filter(a => this.filterAntecedent(a) && !a.family), a => a.old_type)
		const ordered:Dictionary<IAntecedent[]> = {}
		Object.keys(result).sort().forEach(key => {
			ordered[key] = result[key].sort((a: IAntecedent, b: IAntecedent) => {
				if (parseInt(a.id) === -1) return -1
				if (parseInt(b.id) === -1) return 1
				return b.old_event_date.getTime() - a.old_event_date.getTime()
			}) 
		})
		return ordered
	}
	get familyAntecedents(): IAntecedent[] {
		let result:IAntecedent[] = this.antecedents.filter(a => this.filterAntecedent(a) && !!a.family)

		return result
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}

	mounted() {
		this.$emit('navigate', 'antecedents')
		this.initializeBreadcrumb()

		this.moveToAntecedent()
		this.updateFilter()
	}

	owner(item: {id: string, creator_id: string}): string {
		return patient.owner(item)
	}

	deleteAntecedent(antecedent: IAntecedent): void {
		patient.deleteAntecedent(antecedent)
	}

	toggle(id: string, createId?: string): any {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	editAntecedent(antecedent: IAntecedent): void {
		for (let a of this.antecedents) {
			if (!a.edit) continue
			a.edit = false
		}
		
		antecedent.edit = true
		this.$emit('edit')
		this.$forceUpdate()
	}

	addAntecedent($event, type?: string): void {
		for (let a of this.antecedents) {
			if (!a.edit) continue
			a.edit = false
		}

		this.$emit('edit')
		this.filter = ''
		let createId: string = Common.generateId()
		let antecedent: IAntecedent = Patient.createNewAntecedent(createId, this.configuration, type)
		antecedent.edit = true
		this.antecedents.push(antecedent)
	}

	getElementId(antecedent: IAntecedent): string {
		return parseInt(antecedent.id) === -1 ? antecedent.create_id : antecedent.id
	}
	
	@Watch('$route.query.filter')
	private updateFilter() {
		this.filter = this.$route.query.filter as string
		let element = this.$refs['filter'] as any
		if (!element || !element.$el) return

		element.$el.scrollIntoView(true)
	}

	@Watch('submitted')
	private moveToAntecedent() {
		let antecedent: IAntecedent = this.antecedents.find(a => a.error)
		if (!antecedent) return

		antecedent.edit = true
		setTimeout(() => {
			let atc_id: string = this.getElementId(antecedent)
			let element = this.$refs[atc_id] as any
			if (!element || element.length === 0) return
	
			let centralElement: Element = document.getElementsByClassName('l-central')[0]
			let header:HTMLElement = document.getElementById("header")
	
			centralElement.scrollTo(0, element[0].$el.offsetTop - header.offsetHeight)
		}, 100)
	}

	private filterAntecedent(antecedent: IAntecedent): boolean {
		if (!this.filter || this.filter.trim() === '') return true

		let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.filter.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!antecedent.type && antecedent.type.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!antecedent.title && antecedent.title.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!antecedent.description && antecedent.description.toLowerCase().includes(filter)) {
				countResult++
			}
		}
		return countFilter === countResult
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.antecedent', this.antecedents.length).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}