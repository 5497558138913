<div v-if="consultation">
	<h3>{{ $t('vm.root.patients.patient.examens.title') }}</h3>

	<read-only v-if="readOnly" :with-tests="false" :with-forms="false" :consultation="consultation"/>
	<div v-else>

		<dropdown-editable-frame id="reason" :searcher="getReasonList" :multiple="true" :taggable="true" v-model="consultation.reason"
			:label="$t('vm.root.patients.patient.examens.reason.label')"
			:error-test="hasReasonError" :error-msg="reasonError"
			:no-options-label="$t('vm.root.patients.patient.examens.reason.no-options-label')"
			:placeholder="$t('vm.root.patients.patient.examens.reason.placeholder')"
			:title="$t('vm.root.patients.patient.examens.reason.title')"/>

		<div :class="`${isMainUser ? 'grid-2' : 'stat-list'}`">
			<dropdown-frame id="office" v-model="consultation.ofi_id" :items="officeItems"
				:label="$t('vm.root.patients.patient.examens.office.label')"
				:error-test="hasOfficeError" :error-msg="$t('vm.root.patients.patient.examens.office.error')"
				:title="$t('vm.root.patients.patient.examens.office.title')"/>

			<dropdown-frame v-if="!isMainUser" id="user" v-model="consultation.usr_id" :items="userItems"
				:label="$t('vm.root.patients.patient.examens.user.label')"
				:error-test="hasUserError" :error-msg="$t('vm.root.patients.patient.examens.user.error')"
				:title="$t('vm.root.patients.patient.examens.user.title')"/>

			<input-date-frame v-model='consultation.act_date' id="act-date" :type="actDateType"
				:label="$t('vm.root.patients.patient.examens.act-date.label')"
				:title="$t('vm.root.patients.patient.examens.act-date.title')"
				:placeholder="$t('vm.root.patients.patient.examens.act-date.placeholder')"
				:error-test="hasActDateError" :error-msg="$t('vm.root.patients.patient.examens.act-date.error')"/>
		</div>

		<!-- <span class="text-xs">Lien pour une popup avec la courbe de poids et taille</span> -->
		<div class="stat-list" v-if="display.with_measurements">
			<input-frame type="number" id="size" v-model="measurement.size" step="1" min="0" max="250"
				:label="$t('vm.root.patients.patient.examens.size.label')"
				:placeholder="$t('vm.root.patients.patient.examens.size.placeholder')"
				:title="$t('vm.root.patients.patient.examens.size.title')"/>

			<input-frame type="number" id="weight" v-model="measurement.weight" step="0.01" min="0" max="200"
				:label="$t('vm.root.patients.patient.examens.weight.label')"
				:placeholder="$t('vm.root.patients.patient.examens.weight.placeholder')"
				:title="$t('vm.root.patients.patient.examens.weight.title')"/>

			<div class="input-frame">
				<div class="childs-space-between is-nowrap">
					<label class="label" for="imc-value" :data-text="$t('vm.root.patients.patient.examens.imc.label')">
						<span>{{ $t('vm.root.patients.patient.examens.imc.label') }}</span>
					</label>
					<span class="imc-indicator" :style="`--color: var(--imc-level-${imcLevel});`" :title="`Ce patient est en ${imcTitle}`"/>
				</div>
				<input type="text" id="imc-value" v-model="imc" readonly
					:placeholder="$t('vm.root.patients.patient.examens.imc.placeholder')"
					:title="$t('vm.root.patients.patient.examens.imc.title')" />
			</div>
		</div>

		<div :class="firstGrid" class="twin-textarea">
			<textarea-frame v-if="display.with_evolution_state" id="evolution-state" v-model="examen.evolution"
				:label="$t('vm.root.patients.patient.examens.evolution-state.label')"
				:placeholder="$t('vm.root.patients.patient.examens.evolution-state.placeholder')"
				:title="$t('vm.root.patients.patient.examens.evolution-state.title')"/>

			<textarea-frame v-if="display.with_observations" id="observations" v-model="examen.observation"
				:label="$t('vm.root.patients.patient.examens.observations.label')"
				:placeholder="$t('vm.root.patients.patient.examens.observations.placeholder')"
				:title="$t('vm.root.patients.patient.examens.observations.title')"/>
		</div>
		<div :class="secondGrid">
			<slider-frame v-if="display.with_eva_before" :label="$t('vm.root.patients.patient.examens.eva.before')" v-model="eva.before" :min="0" :max="10" :step="0.1" :process-style="eva.before | eva"/>

			<slider-frame v-if="display.with_eva_after" :label="$t('vm.root.patients.patient.examens.eva.after')" v-model="eva.after" :min="0" :max="10" :step="0.1" :process-style="eva.after | eva"/>
		</div>

		<div :class="thirdGrid" class="twin-textarea">
			<textarea-frame v-if="display.with_diagnostic" id="diagnostic" v-model="examen.diagnostic"
				:label="$t('vm.root.patients.patient.examens.diagnostic.label')"
				:placeholder="$t('vm.root.patients.patient.examens.diagnostic.placeholder')"
				:title="$t('vm.root.patients.patient.examens.diagnostic.title')"/>

			<textarea-frame v-if="display.with_tests" id="tests" v-model="examen.tests"
				:label="$t('vm.root.patients.patient.examens.tests.label')"
				:placeholder="$t('vm.root.patients.patient.examens.tests.placeholder')"
				:title="$t('vm.root.patients.patient.examens.tests.title')"/>

			<textarea-frame v-if="display.with_treatment" id="treatment" v-model="examen.treatment"
				:label="$t('vm.root.patients.patient.examens.treatment.label')"
				:placeholder="$t('vm.root.patients.patient.examens.treatment.placeholder')"
				:title="$t('vm.root.patients.patient.examens.treatment.title')"/>
		</div>
	</div>
</div>
