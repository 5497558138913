<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'accounting'}" :title="$t('vm.root.accounting.remittances.aside.back.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.accounting.remittances.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'remittance'}" :title="$t('vm.root.accounting.remittances.aside.new.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ $t('vm.root.accounting.remittances.aside.new.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li v-if="isLoading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</li>
		<li v-else v-for="remittance in remittances" :key="remittance.id">
			<router-link :to="{name: 'remittance', params: {rem_id: remittance.id}}" :title="$t('vm.root.accounting.remittances.aside.old.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<div>{{ remittance.label }}</div>
				<span v-if="remittance.closed" class="text-xs">{{ $t('vm.root.accounting.remittances.aside.old.date', {date: $d(remittance.deposit_date, 'l')}) }}</span>
			</router-link>
		</li>
	</ul>
</a-side>
