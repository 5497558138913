export const antecedents = {
    title: "Antécédents pédiatriques",
    subtitle1: "Antécédents des parents",
    'antecedents-parents': {
        label:"Antécédents des parents",
        placeholder:"ex : Asthme mère; Diabète père",
        title:"Veuillez renseigner les antécédents des parents"
    },
    'number-of-children': {
        label: "Nombre d'enfants",
        placeholder: "ex : 2", 
        title: "Veuillez renseigner le nombre d'enfants total"
    },
    remarks1: {
        label: "Remarques complémentaires",
        placeholder: "ex : beau bébé ", 
        title: "Veuillez renseigner des remarques complémentaires que vous ne pourriez saisir ailleurs"
    },
    subtitle2: "Antécédents grossesse | Antécédents grossesse | Antécédents grossesses",
    contraction: {
        'with-label': "Contractions",
        label: "Description des contractions",
        placeholder: "ex : Violentes, + fortes que pour le 1er enfant",
        title: "Veuillez décrire les contractions"
    },
    immobilisation: {
        'with-label': "Immobilisation au lit",
        label: "Causes de l'immobilisation",
        placeholder: "ex : Une semaine avant le terme à cause d'une sciatique",
        title: "Veuillez décrire les causes de l'immobilisation au lit"
    },
    strapping: {
        'with-label': "Stripping",
        label: "Raisons du stripping du col",
        placeholder: "ex : Pour risque accouchement prématurée",
        title: "Veuillez décrire les raisons du stripping du col"
    },
    accident: {
        'with-label': "Accident pendant la grossesse",
        label: "Description de l'accident",
        placeholder: "ex : Chute sur les genoux",
        title: "Veuillez décrire le ou les accidents"
    },
    disease: {
        'with-label': "Maladie pendant la grossesse",
        label:"Description de la maladie",
        placeholder: "ex : Vomissements",
        title: "Veuillez décrire la ou les maladies"
    },
    'gained-weight': {
        'with-label': "Prise de poids",
        label: "Variation du poids",
        placeholder: "ex : +12 Kg",
        title: "Veuillez décrire la prise ou la perte de poids"
    },
    tranquilizer: {
        'with-label': "Prise de tranquillisant",
        label: "Nom du tranquillisant",
        placeholder: "ex : Xanax",
        title: "Veuillez préciser le nom du tranquillisant"
    },
    remarks2: {
        label: "Remarques complémentaires",
        placeholder: "ex : beau bébé ", 
        title: "Veuillez renseigner des remarques complémentaires que vous ne pourriez saisir ailleurs"
    },
    subtitle3: "Antécédents accouchement | Antécédents accouchement | Antécédents accouchements",
    anesthesia: {
        'with-label': "Anesthésie",
        label: "Description l'anesthésie",
        placeholder: "ex : Péridurale non efficace",
        title: "Veuillez décrire l'anesthésie"
    },
    duration: {
        label: "Durée du travail (en heure)", 
        placeholder: "ex : 4 heures",
        title: "Veuillez renseigner la durée du travail"
    },
    eutocic: "Accouchement eutocique",
    obstructed: "Accouchement dystocique",
    episiotomy: "Épisiotomie",
    forceps: "Forceps",
    fundal: "Expression abdominale",
    'suction-cup': "Ventouse",
    finger: "Doigt dans la bouche",
    accelerators: {
        'with-label': "Prise d'accélérateurs",
        label: "Description de l'accélérateur",
        placeholder: "ex : Zynto",
        title: "Veuillez indiquer l'accélérateur"
    }, 
    caesarean: {
        'with-label': "Césarienne",
        label: "Description la césarienne",
        placeholder: "ex : Utérus cicatriciel",
        title: "Veuillez décrire la césarienne"
    },
    presentation: {
        label: "Présentation du bébé",
        placeholder: "ex : Sommet",
        title: "Veuillez décrire la présentation du bébé"
    },
    remarks3: {
        label: "Remarques complémentaires",
        placeholder: "ex : beau bébé ", 
        title: "Veuillez renseigner des remarques complémentaires que vous ne pourriez saisir ailleurs"
    }
}