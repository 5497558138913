<context>
	<div class="wrapper">
		<section class="marg-v">
			<h2 class="h-reset">{{ $t('vm.root.patients.campaigns.context.title') }}</h2>
			<i18n path="vm.root.patients.campaigns.context.text1" tag="p">
				<abbr :title="$t('vm.root.patients.campaigns.context.abbr-title')">{{ $t('vm.root.patients.campaigns.context.abbr-text') }}</abbr>
			</i18n>

			<p>{{ $t('vm.root.patients.campaigns.context.text2') }}</p>

			<strong>{{ $t('vm.root.patients.campaigns.context.strong') }}</strong>
		</section>
	</div>
</context>
