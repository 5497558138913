<container class="is-without-aside">

	<master>

			<central>
				<div class="wrapper">
					<h1>{{ !!document ? document.title : $t('vm.root.letters.main.title') }}</h1>

					<template v-if="!!document">

						<div class="letter-scroll scrollbar">
							<div class="letter-container">
								<div class="letter-frame paper-page" v-html="getContent()" />
							</div>
						</div>

						<control-bar class="childs-space-between">
							<router-link :to="{name: 'parameters-document', params: {doc_id: document.id}}" class="btn btn-default"
								:title="$t('vm.root.letters.main.update.title', {title: document.title})">
								<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
								</svg>
								{{ $t('vm.root.letters.main.update.label') }}
							</router-link>

							<button-action type="button" btn-class="btn-primary is-pushed-right" @click.native="printDocument"
								:title="$t('vm.root.letters.main.print.title')">
								<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#print"/>
								</svg>
								{{ $t('vm.root.letters.main.print.label') }}
							</button-action>
						</control-bar>

					</template>

					<div v-else class="marg-20 text-center">
						<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#envelope"/>
						</svg>
						<p>
							{{ $t('vm.root.letters.main.none.text') }}<br>
							<button type="button" class="btn--link-like accounting-filter-button is-pushed-right is-mobile-closed"
								data-toggle-context v-toggle="{className: 'is-open is-mobile-closed', selector: '[data-toggle-context]', state:'context'}"
								:title="$t('vm.root.letters.main.none.link-title')">
								<span>{{ $t('vm.root.letters.main.none.link-label') }}</span>
							</button>
							{{ $t('vm.root.letters.main.none.or') }}
							<router-link :to="{name: 'parameters-document'}"
										 :title="$t('vm.root.letters.main.none.new-title')">{{ $t("vm.root.letters.main.none.new-label") }}</router-link>
						</p>
					</div>

				</div>
			</central>

			<context-main @filter-update="updateContent"/>

	</master>

</container>
