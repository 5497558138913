export const merge = {
	context: {
		title: "Filtrer",
		'last-name': {
			label: "Filtre par nom",
			placeholder: "ex : Fleck",
			title: "Filtrer votre recherche par le nom",
		},
		'first-name': {
			label: "Filtre par prénom",
			placeholder: "ex : Arthur",
			title: "Filtrer votre recherche par le prénom",
		},
		duplicates: {
			label: "Doublons avec…",
			'same-date': "… la même date de naissance",
			'same-email': "… le même email",
			'same-phone': "… le même téléphone"
		}
	},
    title: "Fusionner les doublons ({value})",
	help: "Les patients listés ci-dessous ont exactement la même orthographe pour leur nom et prénom. Si vous pensez avoir des dossiers doublons que vous ne voyez pas dans cette liste, vérifiez vos filtres ci-contre. Si cela ne change rien, vérifiez ces dossiers en question pour vous assurer que l'orthographe des noms et prénoms est bien la même.",
    searching: "Chargement en cours…",
    'no-result': "Aucun résultat.",
    'adjust-filter': "Vous devriez peut-être modifier vos filtres.",
	item: {
		label: "fusionner",
		notif: "Vos {count} dossiers ont bien été fusionnés en un seul.",
		title: "Fusionner des dossiers"
	}
}
