import Render from '@Views/root/home/context-news.html'
import { Component, Vue } from 'vue-property-decorator'
import { INews } from '@Store/types'
import { home } from '@Store/modules'
import { InfiniteScroll } from "@Components/index"

@Render
@Component({
	components: {
		'infinite-scroll': InfiniteScroll
	}
})
export class ContextNews extends Vue {

	static newsPerPage: number = 5

	hasMoreNews: boolean = true
	initialized: boolean = true
	private page: number

	get isLoading(): boolean {
		return home.isLoading
	}
	get newsList(): INews[] {
		return home.news
	}

	mounted() {
		this.page = 0
		home.clearNews()
		this.loadNextNews()
	}

	share(link: string): void {
		window.open(link, '', `height=450, width=550, top=${window.outerHeight / 2 - 275}, left=${window.outerWidth / 2 - 225}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`)
	}

	summary(description: string): string {
		let div:HTMLElement = document.createElement("div")
		div.innerHTML = description.substring(0, 200)
		description = div.innerText.substring(0, 100)
		return `${description}&hellip;`
	}

	loadNextNews(): void {
		home.loadNews(this.page)
		.then((news: INews[]) => {
			this.hasMoreNews = news.length >= ContextNews.newsPerPage
			this.page++
			this.initialized = false
		})
	}
}
