export const main = {
    loading: "chargement du rendez-vous",
	by: 'par {owner}',
	yourself: "vous-même",
    'rdv-with': "Rendez-vous, créé {owner} le {date} à {hour}, avec",
    'rdv-online-with': "Rendez-vous en ligne, validé {owner} le {date} à {hour}, avec",
    'link-title': "Ouvrir le dossier de {first_name} {last_name}",
    'new-event': "Nouveau rendez-vous",
    cancel: {
        label:"annuler",
        long: "les modifications",
        title: "Annulez vos modifications et retourner à vote calendrier",
        'action-label': "perdre vos modifications",
        'action-title': "Annulation",
		content: "Vous êtes sur le point d'annuler l'ensemble de vos modifications. Vous confirmez ?"
    },
    reminder: {
        text: "Rappel envoyé",
        sms: "par sms",
        mail: "par email",
        and: " et ",
    },
    create: "Votre rendez-vous, le {day} à {hour}, a bien été ajouté.",
    update: "Votre rendez-vous, le {day} à {hour}, a bien été modifié."
}
