<transition name="fade">
	<div v-if="display()" :class="classObject" :style="progressStyle">
		<button v-if="canClose" type="button" class="btn--reset btn-close text-lg" @click="close()" :title="$t('vm.components.layout.notification.close.title')">
			<svg width="1em" class="svg-block svg-center" viewBox="0 0 320 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<span class="sr-only">{{$t('vm.components.layout.notification.close.label')}}</span>
		</button>
		<span v-html="message"/>
		<template v-for="(action, key) in actions">
			&bull;&nbsp;<button type="button" class="btn--link-like" @click="callback(action.callback)">{{action.label}}</button>
		</template>
	</div>
</transition>
