<div class="wrapper marg-v ticket">

	<collapsible-help id="new-ticket-information" tag="h1" :title="title || $t('vm.root.help.new.new-title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<i18n path="vm.root.help.new.help.text" tag="span">
					<abbr :title="$t('vm.root.help.new.help.abbr-title')">{{ $t('vm.root.help.new.help.abbr-label') }}</abbr>
					<strong>{{ $t('vm.root.help.new.help.strong') }}</strong>
				</i18n>
			</p>
		</div>
	</collapsible-help>

	<form v-if="canCreateTicket" @submit.prevent="submitPost">

		<input-frame :auto-focus="true" type="text" id="title" v-model="title"
			:label="$t('vm.root.help.new.title.label')"
			:placeholder="$t('vm.root.help.new.title.placeholder')"
			:error-test="hasTitleError"
			:error-msg="$t('vm.root.help.new.title.error-msg')"
			:title="$t('vm.root.help.new.title.title')"/>

		<textarea-frame id="comment" rows="5" v-model="comment"
			:label="$t('vm.root.help.new.comment.label')"
			:placeholder="$t('vm.root.help.new.comment.placeholder')"
			:error-test="hasCommentError"
			:error-msg="$t('vm.root.help.new.comment.error-msg')"
			:title="$t('vm.root.help.new.comment.title')"/>

		<div class="p-like text-right">
			<button-action type="submit" btn-class="btn btn-primary" :loading="isPosting" :title="$t('vm.root.help.new.send.title')">
				{{ $t('vm.root.help.new.send.label') }}
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
				</svg>
			</button-action>
		</div>

	</form>

	<div v-else class="notice-danger marg-v">
		<p class="text-left">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
			</svg>
			<i18n path="vm.root.help.new.support-disabled.paragraph1.text" tag="span">
				<strong>{{ $d(user.support_date, 'L') }}</strong>
				<router-link :to="{name:'shop', params:{pck_id: 4}}" :title="$t('vm.root.help.new.support-disabled.paragraph1.link-title')">
					{{ $t('vm.root.help.new.support-disabled.paragraph1.link-label') }}
				</router-link>
			</i18n>
		</p>
		<i18n path="vm.root.help.new.support-disabled.paragraph2.text" tag="p" class="text-left">
			<a href="//facebook.com/groups/198195551042934/" target="_blank" :title="$t('vm.root.help.new.support-disabled.paragraph2.link-title')">
				{{ $t('vm.root.help.new.support-disabled.paragraph2.link-label') }}
			</a>
		</i18n>
	</div>
</div>
