import config from '@Config/index'
import {Watch} from 'vue-property-decorator'
import {IBulk, INotification} from '@Store/types'
import {bulk, notif, shop} from '@Store/modules'
import {AutoSaver} from '@Components/auto-saver'
import {IToggleBoxItem} from '@ViewModels/components'
import {AutoSaverState, PackType, PurchaseMode} from '@Enums/index'
import {EnumsHelper} from '@Helpers/enums'

export abstract class PayStep  extends AutoSaver {

	private submitted: boolean = false
	paying: boolean = false
	mode: PurchaseMode = PurchaseMode.Paypal

	//#region Getters
	get isLoaded(): boolean {
		return bulk.isLoaded
	}
	get isSaving(): boolean {
		return bulk.isSaving
	}
	get paymentItems(): IToggleBoxItem[] {
		return [
			{value: PurchaseMode.Paypal, label: EnumsHelper.purchaseModeToString(PurchaseMode.Paypal), icon:"credit-card"},
			{value: PurchaseMode.Transfer, label: EnumsHelper.purchaseModeToString(PurchaseMode.Transfer), icon: 'share-square'},
			{value: PurchaseMode.Check, label: EnumsHelper.purchaseModeToString(PurchaseMode.Check), icon: 'money-check'}
		]
	}
	get hasAddressError(): boolean {
		return this.submitted && (!this.bulk.address || !this.bulk.address.formatted)
	}
	get constants(): any {
		return shop.shop.constants
	}
	get iban(): string[] {
		return this.constants.IBAN.split(" ")
	}
	get bulk(): IBulk {
		return !!bulk.bulk ? bulk.bulk.bulk : undefined
	}
	get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	//#endregion

	abstract getType(): PackType.Bulk1 | PackType.Bulk2

	//#region IAutoSaver implementation
	restoreValue(value: IBulk): void {
	}

	save(): void {
		if (!this.canSave()) return

		let b: IBulk = {...this.bulk}

		b.type = this.getType()
		bulk.saveBulk(b)
			.then((newBulk: IBulk) => {
				super.initializeValue()
				this.submitted = false

				if (b.mode === PurchaseMode.Paypal) {
					window.location.href = `${config.api_url}/paypal/send-bulk/${newBulk.id}`
					return
				}
				this.paying = true
			})
			.catch(() => {
				super.initializeValue()
				this.submitted = false
			})
	}
	//#endregion

	submit() {
		this.submitted = true
		this.save()
	}

	@Watch('bulk', {deep: true})
	updateBulk() {
		this.state = !!this.bulk && parseInt(this.bulk.id) !== -1 ? AutoSaverState.Updated : this.state
	}

	private canSave(): boolean {
		if (this.isDemo) {
			this.cantPayOnDemo()
			return false
		}

		return !!this.bulk.address && !!this.bulk.address.formatted
	}

	private cantPayOnDemo(): void {
		let message: string = this.$i18n.t('vm.root.shop.purchase.main.cant-payed-on-demo').toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
		notif.info(notification)
	}
}
