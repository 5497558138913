<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.patient.title') }}</h1>

	<hr>

	<section>
		<collapsible-help id="anonymous-information" :title="$t('vm.root.parameters.patient.anonymous.title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.parameters.patient.anonymous.help1.content" tag="span">
						<strong>{{ $t('vm.root.parameters.patient.anonymous.help1.strong1') }}</strong>
						<strong>{{ $t('vm.root.parameters.patient.anonymous.help1.strong2') }}</strong>
						<strong>{{ $t('vm.root.parameters.patient.anonymous.help1.strong3') }}</strong>
					</i18n>
				</p>
				<i18n path="vm.root.parameters.patient.anonymous.help2.content" tag="p" class="text-left">
					<strong>{{ $t('vm.root.parameters.patient.anonymous.help2.strong') }}</strong>
				</i18n>
			</div>
		</collapsible-help>

		<div class="marg-v">
			<switch-frame id="anonymous" :title="$t('vm.root.parameters.patient.anonymous.switch.title')" v-model="configuration.anonymous" checked-icon="user-secret">
				{{ $t('vm.root.parameters.patient.anonymous.switch.label') }}
			</switch-frame>
		</div>

	</section>

	<hr>

	<section>
		<h2>{{ $t('vm.root.parameters.patient.color.title') }}</h2>
		<div class="grid-2">
			<input-color-frame id="consultation-color" v-model="colors.badge_consultation"
				:title="$t('vm.root.parameters.patient.color.consultation.title')"
				:label="$t('vm.root.parameters.patient.color.consultation.label')"/>

			<input-color-frame id="antecedent-color" v-model="colors.badge_antecedent"
				:title="$t('vm.root.parameters.patient.color.antecedent.title')"
				:label="$t('vm.root.parameters.patient.color.antecedent.label')"/>

			<input-color-frame id="sphere-color" v-model="colors.badge_sphere"
				:title="$t('vm.root.parameters.patient.color.sphere.title')"
				:label="$t('vm.root.parameters.patient.color.sphere.label')"/>

			<input-color-frame id="attachment-color" v-model="colors.badge_attachment"
				:title="$t('vm.root.parameters.patient.color.attachment.title')"
				:label="$t('vm.root.parameters.patient.color.attachment.label')"/>
		</div>
	</section>

	<hr>

	<section>
		<collapsible-help id="display-information" :title="$t('vm.root.parameters.patient.display.title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.parameters.patient.display.help.content" tag="span">
						<strong>{{ $t('vm.root.parameters.patient.display.help.strong1') }}</strong>
						<strong>{{ $t('vm.root.parameters.patient.display.help.strong2') }}</strong>
						<strong>{{ $t('vm.root.parameters.patient.display.help.strong3') }}</strong>
						<strong>{{ $t('vm.root.parameters.patient.display.help.strong4') }}</strong>
					</i18n>
				</p>
			</div>
		</collapsible-help>

		<section>
			<h3>{{ $t('vm.root.parameters.patient.display.examens-title') }}</h3>

			<checkbox-frame id="size-weight" v-model="display.with_measurements">
				{{ $t('vm.root.parameters.patient.display.measurements.label') }}
			</checkbox-frame>
			<div v-if="display.with_measurements" class="grid-3">
				<input-frame type="number" id="size" :readonly="true"
					:label="$t('vm.root.parameters.patient.display.measurements.size.label')"
					:placeholder="$t('vm.root.parameters.patient.display.measurements.size.placeholder')"
					:title="$t('vm.root.parameters.patient.display.measurements.size.title')"/>
				<input-frame type="number" id="weight" :readonly="true"
					:label="$t('vm.root.parameters.patient.display.measurements.weight.label')"
					:placeholder="$t('vm.root.parameters.patient.display.measurements.weight.placeholder')"
					:title="$t('vm.root.parameters.patient.display.measurements.weight.title')"/>
					<input-frame id="imc-value" :readonly="true"
					:label="$t('vm.root.parameters.patient.display.measurements.imc.label')"
					:placeholder="$t('vm.root.parameters.patient.display.measurements.imc.placeholder')"
					:title="$t('vm.root.parameters.patient.display.measurements.imc.title')"/>
			</div>

			<hr>

			<div class="grid-2">
				<div>
					<checkbox-frame id="evolution-state" v-model="display.with_evolution_state"
						:title="$t('vm.root.parameters.patient.display.evolution-state.title')">
						{{ $t('vm.root.parameters.patient.display.evolution-state.label') }}
					</checkbox-frame>
					<textarea v-if="display.with_evolution_state" rows="3" readonly
						:placeholder="$t('vm.root.parameters.patient.display.evolution-state.placeholder')"/>
				</div>
				<div>
					<checkbox-frame id="observations" v-model="display.with_observations"
						:title="$t('vm.root.parameters.patient.display.observations.title')">
						{{ $t('vm.root.parameters.patient.display.observations.label') }}
					</checkbox-frame>
					<textarea v-if="display.with_observations" rows="3" readonly
						:placeholder="$t('vm.root.parameters.patient.display.observations.placeholder')"/>
				</div>
				<div>
					<checkbox-frame id="eva-before" v-model="display.with_eva_before"
						:title="$t('vm.root.parameters.patient.display.eva-before.title')">
						{{ $t('vm.root.parameters.patient.display.eva-before.label') }}
					</checkbox-frame>
					<slider-frame v-if="display.with_eva_before" label="E.V.A. avant" v-model="eva_before" :min="0" :max="10" :step="0.1" :process-style="eva_before | eva"/>
				</div>
				<div>
					<checkbox-frame id="eva-after" v-model="display.with_eva_after"
						:title="$t('vm.root.parameters.patient.display.eva-after.title')">
						{{ $t('vm.root.parameters.patient.display.eva-after.label') }}
					</checkbox-frame>
					<slider-frame v-if="display.with_eva_after" label="E.V.A. après" v-model="eva_after" :min="0" :max="10" :step="0.1" :process-style="eva_after | eva"/>
				</div>
			</div>

			<hr>

			<div class="grid-3">
				<div>
					<checkbox-frame id="diagnostic" v-model="display.with_diagnostic"
						:title="$t('vm.root.parameters.patient.display.diagnostic.title')">
						{{ $t('vm.root.parameters.patient.display.diagnostic.label') }}
					</checkbox-frame>
					<textarea v-if="display.with_diagnostic" rows="3" readonly
						:placeholder="$t('vm.root.parameters.patient.display.diagnostic.placeholder')"/>
				</div>
				<div>
					<checkbox-frame id="tests" v-model="display.with_tests"
						:title="$t('vm.root.parameters.patient.display.tests.title')">
						{{ $t('vm.root.parameters.patient.display.tests.label') }}
					</checkbox-frame>
					<textarea v-if="display.with_tests" rows="3" readonly
						:placeholder="$t('vm.root.parameters.patient.display.tests.placeholder')"/>
				</div>
				<div>
					<checkbox-frame id="treatment" v-model="display.with_treatment"
						:title="$t('vm.root.parameters.patient.display.treatment.title')">
						{{ $t('vm.root.parameters.patient.display.treatment.label') }}
					</checkbox-frame>
					<textarea v-if="display.with_treatment" rows="3" readonly
						:placeholder="$t('vm.root.parameters.patient.display.treatment.placeholder')"/>
				</div>
			</div>

			<hr>

			<div class="grid-2">
				<checkbox-frame id="exclusion-tests" v-model="display.with_exclusion_tests"
					:title="$t('vm.root.parameters.patient.display.exclusion-tests.title')">
					{{ $t('vm.root.parameters.patient.display.exclusion-tests.label') }}
				</checkbox-frame>
				<checkbox-frame id="cranio-sacral" v-model="display.with_cranio_sacral"
					:title="$t('vm.root.parameters.patient.display.cranio-sacral.title')">
					{{ $t('vm.root.parameters.patient.display.cranio-sacral.label') }}
				</checkbox-frame>
				<checkbox-frame id="walton" v-model="display.with_walton"
					:title="$t('vm.root.parameters.patient.display.walton.title')">
					{{ $t('vm.root.parameters.patient.display.walton.label') }}
				</checkbox-frame>
				<checkbox-frame id="schema" v-model="display.with_schema"
					:title="$t('vm.root.parameters.patient.display.schema.title')">
					{{ $t('vm.root.parameters.patient.display.schema.label') }}
				</checkbox-frame>
			</div>
		</section>

	</section>
</div>
