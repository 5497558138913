import Render from '@Views/components/dropdown-actions.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IDropdownItem } from './input/dropdown'

@Render
@Component({})
export class DropdownActions extends Vue {
    name: string = 'dropdown-actions'

    currentAction: any = null

    @Prop({default: []}) actions: IDropdownActionItem[]
    @Prop({default: false}) isLoading: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) menuOnTop: boolean
    @Prop({default: ''}) dropdownId: string
    @Prop({default: ''}) svgClass: string
    @Prop({default: 'btn btn-primary form-group-input is-last is-flex-fixed'}) triggerClass: string
    @Prop({default: 'btn btn-primary form-group-input'}) btnClass: string

    get actionLabel(): string {
        this.initializeCurrentAction()
		return this.actions.find(a => { return a.value === this.currentAction }).label
	}
    get actionTitle(): string {
        this.initializeCurrentAction()
		return this.actions.find(a => { return a.value === this.currentAction }).title
	}
	get actionIcon(): string {
        this.initializeCurrentAction()
		return this.actions.find(a => { return a.value === this.currentAction }).icon
	}

    private initializeCurrentAction() {
        if (!!this.currentAction) return

        this.currentAction = !!this.actions && this.actions.length > 0 ? this.actions[0].value : null
    }

    callAction(): void {
        this.actions.find(a => { return a.value === this.currentAction }).action()
    }
}

export interface IDropdownActionItem extends IDropdownItem {
    action: () => void,
    title: string
}
