import Render from '@Views/root/parameters/document/aside-document.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConfiguration, IDocument } from '@Store/types'
import { Document, Common } from '@Models/index'

@Render
@Component({})
export class ASideDocument extends Vue {

	@Prop() configuration: IConfiguration

	get documents(): IDocument[] {
		return this.configuration.documents.currents
	}

	getDocumentId(document: IDocument): string {
		return parseInt(document.id) === -1 ? document.createId : document.id
	}
	
	addDocument(): void {
		let createId: string = Common.generateId()
		let document: IDocument = Document.createNewDocument(createId)

		this.configuration.documents.currents.push(document)

		this.$router.push({name: 'parameters-document', params: {doc_id: document.createId}})
	}
}