<div class="wrapper">

	<section class="marg-v" v-if="!!filter">
		<h2>{{ $t('vm.root.accounting.context.title') }}</h2>

		<div class="input-frame">
			<label class="label" for="startRange" data-text="Période">
				<span>{{ $t('vm.root.accounting.context.period.title') }}</span>
			</label>
			<input-range class="accounting-search" v-model="range" :type="rangeType">
				<input type="text" class="accounting-search-input" id="startRange" :title="$t('vm.root.accounting.context.period.start-date')">
				<input type="text" class="accounting-search-input" id="endRange" :title="$t('vm.root.accounting.context.period.end-date')">
			</input-range>
		</div>

		<dropdown-frame id="user" v-if="userItems.length > 0" v-model="filter.usr_id" :items="userItems"
			:label="$t('vm.root.accounting.context.user.label')"
			:title="$t('vm.root.accounting.context.user.title')"/>

		<dropdown-frame id="office" v-model="filter.ofi_id" :items="officeItems"
			:label="$t('vm.root.accounting.context.office.label')"
			:title="$t('vm.root.accounting.context.office.title')"/>
	</section>

	<hr>

	<section class="marg-v" v-if="!!subFilter">
		<div class="childs-space-between p-like">
			<h2 class="no-marg">{{ $t('vm.root.accounting.context.filter.title') }}</h2>
			<div class="text-right">
				<spinner v-if="isFiltering" class="svg-middle"/>
				<button v-else type="button" class="btn--link-like" @click="reset" :title="$t('vm.root.accounting.context.filter.reset.title')">{{ $t('vm.root.accounting.context.filter.reset.label') }}</button>
			</div>
		</div>

		<input-frame id="filter-label" v-model="subFilter.label"
			:label="$t('vm.root.accounting.context.filter.label.label')" 
			:placeholder="$t('vm.root.accounting.context.filter.label.placeholder')"
			:title="$t('vm.root.accounting.context.filter.label.title')"/>

		<input-frame id="filter-number" v-model="subFilter.number"
			:label="$t('vm.root.accounting.context.filter.number.label')" 
			:placeholder="$t('vm.root.accounting.context.filter.number.placeholder')"
			:title="$t('vm.root.accounting.context.filter.number.title')"/>

		<input-frame id="filter-bank" v-model="subFilter.bank"
			:label="$t('vm.root.accounting.remittance.context.filter.bank.label')" 
			:placeholder="$t('vm.root.accounting.remittance.context.filter.bank.placeholder')"
			:title="$t('vm.root.accounting.remittance.context.filter.bank.title')"/>

	</section>

</div>
