<div v-if="!!bulk">
	<paying v-if="paying" :price="150" :payer="bulk" :is-bulk="true"></paying>
	<form v-else @submit.prevent="submitPay">
		<p>{{ $t('vm.root.shop.bulk.main.step1.how-to-validate', {price: $n(150, 'currency', 'fr')}) }}</p>
		<input-address-frame id="address" :address="bulk.address"
							 :error-test="hasAddressError" :error-msg="$t('vm.root.shop.bulk.main.address.error-msg')"
							 :label="$t('vm.root.shop.bulk.main.address.label')"
							 :title="$t('vm.root.shop.bulk.main.address.title')"></input-address-frame>

		<togglebox-frame id="payment" :items="paymentItems" v-model="bulk.mode"
						 :label="$t('vm.root.shop.bulk.main.mode.label')"
						 :title="$t('vm.root.shop.bulk.main.mode.title')"></togglebox-frame>

		<div class="p-like text-lg text-center">
			{{ $t('vm.root.shop.bulk.main.step1.down-payment') }}
			<span class="text-primary text-bold">
				<span>{{ $n(150, 'currency', 'fr') }}</span>
				<abbr :title="$t('vm.root.shop.purchase.main.total.ttc-title')">{{ $t('vm.root.shop.purchase.main.total.ttc-label') }}</abbr>
			</span>
		</div>

		<control-bar class="text-right">
			<button-action type="submit" btn-class="btn-success" :loading="isSaving" :disabled="disabled"
						   :title="$t('vm.root.shop.bulk.main.step1.reserve.title')">
				{{ $t('vm.root.shop.bulk.main.step1.reserve.label') }}
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
				</svg>
			</button-action>
		</control-bar>
	</form>
</div>
