export class ColorHelper {
	public static getTextColor(hex: any) : string {
		return ColorHelper.isDark(hex) ? "#dddddd" : "#555555"
	}

	public static isDark(hex: any) : boolean {
		if (hex === undefined) return false
		if (!(hex instanceof String || typeof hex === 'string') || !hex.startsWith('#')) return false

		if (hex.length ===  4) {
			hex = `#${hex.charAt(1)}${hex.charAt(1)}${hex.charAt(2)}${hex.charAt(2)}${hex.charAt(3)}${hex.charAt(3)}`
		}

		let r: number = parseInt(hex.substr(1, 2), 16)
		let g: number = parseInt(hex.substr(3, 2), 16)
		let b: number = parseInt(hex.substr(5, 2), 16)

		let lumen: number = r * 0.299 + g * 0.587 + b * 0.114
		return lumen / 256 <= 0.8
	}

	public static HSLToHex(hsl: any) {
		let sep = hsl.indexOf(",") > -1 ? "," : " "
		hsl = hsl.substr(4).split(")")[0].split(sep)

		let h = hsl[0],
			s = hsl[1].substr(0,hsl[1].length - 1) / 100,
			l = hsl[2].substr(0,hsl[2].length - 1) / 100

		// Strip label and convert to degrees (if necessary)
		if (h.indexOf("deg") > -1)
			h = h.substr(0,h.length - 3)
		else if (h.indexOf("rad") > -1)
			h = Math.round(h.substr(0,h.length - 3) * (180 / Math.PI))
		else if (h.indexOf("turn") > -1)
			h = Math.round(h.substr(0,h.length - 4) * 360)
		if (h >= 360)
			h %= 360

		let c = (1 - Math.abs(2 * l - 1)) * s,
			x = c * (1 - Math.abs((h / 60) % 2 - 1)),
			m = l - c/2,
			r = 0,
			g = 0,
			b = 0

		if (0 <= h && h < 60) {
			r = c
			g = x
			b = 0
		} else if (60 <= h && h < 120) {
			r = x
			g = c
			b = 0
		} else if (120 <= h && h < 180) {
			r = 0
			g = c
			b = x
		} else if (180 <= h && h < 240) {
			r = 0
			g = x
			b = c
		} else if (240 <= h && h < 300) {
			r = x
			g = 0
			 b = c
		} else if (300 <= h && h < 360) {
			r = c
			g = 0
			b = x
		}

		// Having obtained RGB, convert channels to hex
		let rr = Math.round((r + m) * 255).toString(16),
			gg = Math.round((g + m) * 255).toString(16),
			bb = Math.round((b + m) * 255).toString(16)

		// Prepend 0s, if necessary
		if (rr.length == 1)
			rr = `0${rr}`
		if (gg.length == 1)
			gg = `0${gg}`
		if (bb.length == 1)
			bb = `0${bb}`

		return `#${rr}${gg}${bb}`
	}
}
