<a-side :sub-menu="true" v-if="office">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'offices'}" :title="$t('vm.root.user.office.aside-schedule.back.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.office.aside-schedule.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'office-coordinates', params: {ofi_id: office.id}}" :title="$t('vm.root.user.office.aside-schedule.back-office.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.office.aside-schedule.back-office.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'office-schedule', params: $route.params}" :title="$t('vm.root.user.office.aside-schedule.schedule.title')">
				{{ $t('vm.root.user.office.aside-schedule.schedule.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<button type="button" class="btn--link-like" @click="addSchedule" :title="$t('vm.root.user.office.aside-schedule.new-schedule.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.user.office.aside-schedule.new-schedule.label') }}
			</button>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li v-for="schedule in schedules" :key="getScheduleId(schedule)">
            <router-link :to="{name: 'office-schedule-plus', params: {ofi_id: office.id, sch_id: getScheduleId(schedule)}}" :title="$t('vm.root.user.office.aside-schedule.specific-schedule.title')">
				{{ getScheduleName(schedule) }}<br>
				<span class="text-xs">
					{{ $t('vm.root.user.office.aside-schedule.specific-schedule.sublabel', {start: `${$d(schedule.start, 'weekdayMin')} ${$d(schedule.start, 'l')}`, end: `${$d(schedule.end, 'weekdayMin')} ${$d(schedule.end, 'l')}`}) }}
				</span>
			</router-link>
        </li>
	</ul>

</a-side>
