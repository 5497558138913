<form @submit.prevent="submit" v-if="!!user">
    <h1>{{ $t('vm.root.user.email.title')}}</h1>
    <i18n path="vm.root.user.email.current-email" tag="p">
        <strong class="text-primary">{{ user.email }}</strong>
    </i18n>
    <i18n v-if="user.email_tmp" path="vm.root.user.email.waiting-email" tag="p">
        <strong class="text-primary">{{ user.email_tmp }}</strong>
    </i18n>
    <div class="grid-2">
        <input-frame type="email" ref="new-email" id="new-email" v-model="email.newEmail"
            :label="$t('vm.root.user.email.new-email.label')" 
            :error-test="hasNewEmailError"
            :error-msg="$t('vm.root.user.email.new-email.error-msg')"
            :placeholder="$t('vm.root.user.email.new-email.placeholder')"
            :title="$t('vm.root.user.email.new-email.title')"/>

        <input-frame type="email" ref="confirm-email" id="confirm-email" v-model="email.confirmEmail"
            :label="$t('vm.root.user.email.confirm-email.label')" 
            :error-test="hasConfirmEmailError"
            :error-msg="$t('vm.root.user.email.confirm-email.error-msg')"
            :placeholder="$t('vm.root.user.email.confirm-email.placeholder')"
            :title="$t('vm.root.user.email.confirm-email.title')"/>
    </div>

    <control-bar>
        <button-action type="submit" btn-class="btn-primary" :loading="isLoading" :disabled="disabled" :title="$t('vm.root.user.email.update.title')">
            {{ $t('vm.root.user.email.update.short-label') }}
            <span class="rwd-over-xlarge">{{ $t('vm.root.user.email.update.long-label') }}</span>
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
            </svg>
        </button-action>
    </control-bar>
</form>
