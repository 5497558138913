import Render from '@Views/root/search/patient/search.html'
import { i18n } from '@I18n/index'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
	IPopinAction,
	IPopin,
	ICustomer,
	IConfiguration,
	IPatient,
	IPatientSearch,
	INotification
} from '@Store/types'
import { Patient, Customer } from '@Models/index'
import {configuration, notif, patient, popIn} from '@Store/modules'
import {MemberType} from "@Enums/member"
import {EnumsHelper} from "@Helpers/enums"

@Render
@Component({})
export class SearchPatient extends Vue {

	@Prop({default: null}) customer: ICustomer
	@Prop({default: true}) canCreate: boolean
	@Prop({default: SearchPatient.defaultLabel}) label: string
	@Prop({default: null}) action: (patient: IPatient) => void
	@Prop({default: null}) list: IPatientSearch[]
	@Prop() notification: INotification

	get configuration(): IConfiguration {
		return configuration.configuration
	}

	searchPatient(): void {
		if (!!this.notification) {
			notif.warning(this.notification)
		}
		let selectAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.patient.search.select.label').toString(),
			title: this.$i18n.t('vm.root.search.patient.search.select.title').toString(),
			icon: 'check',
			callback: (data: {selectedId: string, patient: IPatientSearch}) => {
				let patient: IPatientSearch = data.patient
				let validData: boolean = !!patient.last_name && !!patient.first_name
				if (!validData) return Promise.reject()

				if (!!this.action) this.action(Patient.toPatient(patient))
				notif.clear()
				return Promise.resolve()
			},
			condition: (data: {selectedId: string}) => {
				return !!data.selectedId
			}
		}
		let duplicateAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.patient.search.duplicate.label').toString(),
			title: this.$i18n.t('vm.root.search.patient.search.duplicate.title').toString(),
			icon: 'clone',
			stayOpen: true,
			callback: (data: {selectedId: string, patient: IPatientSearch}) => {
				this.duplicate(data.patient)
				notif.clear()
				return Promise.resolve()
			},
			condition: (data: {selectedId: string}) => {
				return !!data.selectedId
			}
		}

		let search: IPopin = {
			title: this.$i18n.t('vm.root.search.patient.search.select.title').toString(),
			content: {component: 'popin-search-patient'},
			actions: [selectAction, duplicateAction],
			cancelAction: notif.clear,
			isWide: !!this.list,
			options: {
				selectedId: null,
				patient: Patient.toPatientSearch(Customer.toPatient(this.customer)),
				customer: this.customer,
				list: this.list
			}
		}

		popIn.info(search)
	}

	createPatient(): void {
		if (!!this.notification) {
			notif.warning(this.notification)
		}

		let addAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.patient.search.create-popin.label').toString(),
			title: this.$i18n.t('vm.root.search.patient.search.create-popin.title').toString(),
			callback: (data: {patient: IPatient}) => {
				let validData: boolean = !!data.patient.coordinates.last_name && !!data.patient.coordinates.first_name
				if (!validData) return Promise.reject()

				if (!!this.action) this.action(data.patient)
				notif.clear()
				return Promise.resolve()
			}
		}
		let patient: IPatient = !!this.customer
			? Customer.toPatient(this.customer)
			: Patient.createNewPatient(this.configuration)

		patient.coordinates['update'] = true
		let add: IPopin = {
			title: this.$i18n.t('vm.root.search.patient.search.create-popin.title').toString(),
			content: {component: 'popin-create-patient'},
			action: addAction,
			options: {patient: patient},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(add)
	}

	duplicate(patientToDuplicate: IPatientSearch) {
		let failedNotification: INotification = { message: this.$i18n.t('vm.root.patients.patient.main.duplicate.failed').toString(), actions: [], canClose: true, delay: 5000 }

		let duplicateAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.main.duplicate.label').toString(),
			title: this.$i18n.t('vm.root.patients.patient.main.duplicate.title').toString(),
			callback: (data: {patient: IPatient, member: MemberType}, updateLoading: any) => {
				let validData: boolean = !!data.patient.coordinates.last_name && !!data.patient.coordinates.first_name
				if (!validData) return Promise.reject()

				if (data.member !== MemberType.None) {
					data.patient.family.currents.push({
						id: '-1',
						link_id: patientToDuplicate.id,
						type: EnumsHelper.getRevertMember(data.member),
						last_name: '',
						first_name: '',
						deceased: false,
						birth_date: null,
						gender: null,
						email: ''
					})
				}
				updateLoading(true)

				return patient.duplicatePatient({patient: data.patient})
					.then(newPatient => {
						updateLoading(false)
						let validData: boolean = !!newPatient.coordinates.last_name && !!newPatient.coordinates.first_name
						if (!validData) return Promise.reject()

						if (!!this.action) this.action(newPatient)
						notif.clear()
						return Promise.resolve()
					}, () => {
						updateLoading(false)
						return Promise.reject()
					})
					.catch(() => {
						updateLoading(false)
						notif.error(failedNotification)
						return Promise.reject()
					})
			}
		}
		let duplicatePatient: IPatient = Patient.toPatient(patientToDuplicate)
		duplicatePatient.is_new = true
		duplicatePatient.id = '-1'
		duplicatePatient.blacklisted = patient.blacklisted
		duplicatePatient.deceased = patient.deceased
		duplicatePatient.coordinates.first_name = ''
		duplicatePatient.coordinates.birth_date = null

		let duplicate: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.main.duplicate.title-popin').toString(),
			content: {component: 'popin-create-patient'},
			action: duplicateAction,
			options: {patient: duplicatePatient, member: MemberType.None},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(duplicate)
	}

	private static defaultLabel(): string {
		return i18n.t('vm.root.search.patient.search.label').toString()
	}
}
