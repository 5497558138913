import Render from '@Views/root/calendar/planning/aside-planning.html'
import { Component, Vue } from 'vue-property-decorator'
import { IPlanning } from '@Store/types'
import { planning } from '@Store/modules'

@Render
@Component({})
export class ASidePlanning extends Vue {

    // @State('planning') statePlanning: PlanningState
    // @Action('planning/deletePlanning') deletePlanning: (payload: PlanningActionPayload) => any

    get isDeleting(): boolean {
      return planning.isDeleting
    }
    get planning(): IPlanning {
      return planning.selectedPlanning
    }

    submitDelete(): void {
      let route = {name: 'plannings'}

      if (!!this.planning && !!this.planning.id && parseInt(this.planning.id) !== -1) {
        planning.deletePlanning({planning: this.planning}).then(() => {
          this.$router.push(route)
        })
      } else {
        this.$router.push(route)
      }
    }
}