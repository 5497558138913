export const widgetReferral = {
    title: "Parrainage",
    code: "Votre code :",
    godchild: "Parrainé|Parrainé|Parrainés",
    points: "Vos points",
    'more-informations': {
        label: "plus d'informations…",
        title: "Plus d'informations sur le parrainage"
    }
}
