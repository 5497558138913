import Render from '@Views/root/accounting/remittances/main.html'
import { Component, Vue } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'
import { ASideRemittances } from './aside-remittances'
import { ContextRemittances } from './context-remittances'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-remittances': ASideRemittances,
		'context-remittances': ContextRemittances
	}
})
export class Main extends Vue {

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	
	mounted() {
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}
	
	//#region private methods
	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.remittances', 2).toString() }
		breadcrumb.updateItems([item1, item2])
	}
	//#endregion
}