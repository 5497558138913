import Render from '@Views/components/popin/layout/popin-search-patient.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {Patient, Debouncer, Common, Customer} from '@Models/index'
import {
	IPatient,
	IUser,
	IConfiguration,
	IPopin,
	IPopinAction,
	IPatientSearch,
	IPatientFilter,
	ICustomer
} from '@Store/types'
import { configuration, event, notif, patients, popIn, user } from '@Store/modules'

@Render
@Component({})
export class PopinSearchPatient extends Vue {

	searched: boolean = false
	searching: boolean = false
	resultSearch: IPatientSearch[] = []
	random: string = null
	query: string = ''
	private debouncer: Debouncer = null

    @Prop() options: {selectedId: string, patient: IPatientSearch, list: IPatientSearch[], customer?: ICustomer}
    @Prop({default: false}) submitted: boolean

	//#region Getter
	get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get selectedUser(): IUser {
		return user.selectedPlanningUser
	}
	private get byUsrId(): string {
		if (!user.isSecretaryUser) return
		if (!this.selectedUser) return

		return this.selectedUser.id
	}
	//#endregion

	mounted() {
		this.random = Common.generateId()
		this.debouncer = new Debouncer(this.search, 500)

		let input: HTMLElement = this.$refs['popin-search-patient'] as HTMLElement
		input.focus()

		if (!!this.options.patient) {
			let patient: IPatientSearch = this.options.patient
			let query: string = `${Patient.queryToSearch(patient.first_name)} ${Patient.queryToSearch(patient.last_name)} ${patient.phone} ${patient.email}`
			this.query = query.trim()
		}
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	addPatient(patient: IPatient): void {
		event.addPatient({patient: patient})
	}

	createPatient(): void {
		let addAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.patient.search.create-popin.label').toString(),
			title: this.$i18n.t('vm.root.search.patient.search.create-popin.title').toString(),
			callback: (data: {patient: IPatient}) => {
				let validData: boolean = !!data.patient.coordinates.last_name && !!data.patient.coordinates.first_name
				if (!validData) return Promise.reject()

				event.addPatient({patient: data.patient})
				notif.clear()
				return Promise.resolve()
			}
		}
		let patient: IPatient = !!this.options.customer
			? Customer.toPatient(this.options.customer)
			: Patient.createNewPatient(this.configuration)

		patient.coordinates['update'] = true
		let add: IPopin = {
			title: this.$i18n.t('vm.root.search.patient.search.create-popin.title').toString(),
			content: {component: 'popin-create-patient'},
			action: addAction,
			options: {patient: patient},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(add)
	}

	@Watch('options.selectedId')
	udpateSelectedPatient(): void {
		if (!this.options.selectedId) {
			this.options.patient = null
			return
		}

		let patient: IPatientSearch = this.resultSearch.find(patient => { return patient.id === this.options.selectedId })
		if (!patient) patient = this.options.list.find(patient => { return patient.id === this.options.selectedId })

		this.options.patient = patient

	}

	search(): void {
		this.searching = true
		this.resultSearch = []
		let filter: IPatientFilter = {
			by_first_name: true,
			by_last_name: true,
			by_profession: false,
			by_email: true,
			by_phone: true,
			by_address: false,
			by_usr_id: this.byUsrId
		}
		patients.searchPatients({query: this.query, filter: filter})
		.then(results => {
			this.searched = true
			this.searching = false
			this.resultSearch = results
		})
		.catch(error => {
			this.searched = true
			this.searching = false
			console.error(error)
		})
	}

    @Watch('query')
	createDebounce(): void {
		if (!this.query) {
			this.searched = false
			this.resultSearch = []
		}

		this.debouncer.start(!!this.query)
    }
}
