import Render from '@Views/root/user/office/session/main.html'
import { isEqual, cloneDeep } from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ISession, IOffice } from '@Store/types'

@Render
@Component({})
export class Main extends Vue {

	private oldSession: ISession = null
	@Prop() submitted: boolean
	@Prop() session: ISession
	@Prop() office: IOffice

	get hasNameError(): boolean {
		return this.submitted && !this.session.name
	}
	get hasDurationError(): boolean {
		return this.submitted && !this.session.duration
	}
	get hasTtcError(): boolean {
		return this.submitted && !this.session.ttc
	}
	get elementId(): string {
		return parseInt(this.session.id) === -1 ? this.session.create_id : this.session.id
	}
	get isDefaultSession(): boolean {
		return parseInt(this.session.id) === -1 && !this.session.create_id
	}

	mounted() {
		this.oldSession = cloneDeep(this.session)
	}

	submitDelete(): void {
		this.office.sessions.deleted.push(this.session)
		this.office.sessions.currents = this.office.sessions.currents.filter(s => { return s.id !== this.session.id || s.create_id !== this.session.create_id } )
	}

	@Watch('session', {deep: true})
	updateSession() {
		// On retire la session en cours pour ne pas l'écrire 2 fois
		// this.office.sessions.currents = this.office.sessions.currents.filter(s => { return s.id !== this.session.id || s.create_id !== this.session.create_id } )

		if (this.oldSession.id !== this.session.id || isEqual(this.oldSession, this.session)) return

		this.session['update'] = true
	}
}