export const accesses = {
    title: "Accès | Accès | Accès",
    'new-access': {
        title: "Créer un nouvel accès à ce cabinet",
        label: "Nouvel accès"
    },
    'none': {
        label: "Pas d'accès",
        text: "Indiquez les différents {0} à votre cabinet.",
        'button-label': "modes d'accès"
    },
    move: {
        title: "Déplacer cet accès",
        label: "Déplacer"
    },
    details: "Détails de cet accès",
    'default-name': "nouvel accès"
}