import Render from '@Views/root/co-working/main.html'
import { Component, Vue } from 'vue-property-decorator'
import { ASideShare } from './aside-share'
import { ContextShare } from './context-share'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
    components: {
        'aside-share': ASideShare,
        'context-share': ContextShare
    }
})
export class Main extends Vue {

    // @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void

    mounted() {
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
    }

    private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.co-working').toString() }
		breadcrumb.updateItems([item])
	}
}