<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.accounting.title') }}</h1>
	<section>
		<dropdown-frame id="default-period" v-model="configuration.default_period" :items="periods" :label="$t('vm.root.parameters.accounting.period')"/>

		<div class="marg-v">
			<switch-frame id="cc-invoice" :title="$t('vm.root.parameters.accounting.cc-invoice.title')" v-model="configuration.cc_invoice">
				{{ $t('vm.root.parameters.accounting.cc-invoice.label') }}
			</switch-frame>
		</div>
	
	</section>
</div>
