import * as Help from '@ViewModels/root/help'
import { Profile } from '@Enums/profile'
import { TicketRoot } from './ticket'

export const HelpRoot = [
	{
		path: 'help', 
		component: Help.Main,
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: [
			...TicketRoot(),
			{
				path:'new-ticket', 
				name:'new-ticket',
				component: Help.NewTicket,
				meta: { permission: [Profile.Main, Profile.Demo] },
			}
		]
	},
	{
		path:'closed', 
		component: Help.ClosedTickets,
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: TicketRoot('closed-')
	}
]