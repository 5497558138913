import { i18n } from '@I18n/index'
import { AccessType, AccountingPeriod, Alimentation, ArticulationMove, AttachmentType, AutoSaveDelay, Currency, DampingType, DateState, DefaultPage, DirectionHorizontal, DirectionVertical, FreeSpaceMode, FollowUpLevel, FontSize, FormType, Gender, InvoiceType, Lang, Level, MemberType, NewsType, PackType, PaymentMode, PhoneType, Profile, Provider, PurchaseMode, QuestionType, ReceiptFilterType, ReceiptType, RecurringType, ReminderType, Situation, Skin, SpendType, StrainMove, Timezone, WidgetType } from '@Enums/index'

export class EnumsHelper {
	static fontSizeToClass(size: FontSize): string {
		switch (size) {
			case FontSize.Tiny:
				return 'zoom-tiny'
			case FontSize.Small:
				return 'zoom-small'
			case FontSize.Normal:
				return 'zoom-normal'
			case FontSize.Large:
				return 'zoom-large'
			case FontSize.Huge:
				return 'zoom-huge'
		}
	}
	static fontSizeToString(size: FontSize): string {
		switch (size) {
			case FontSize.Tiny:
				return i18n.t('enums.font-size.tiny').toString()
			case FontSize.Small:
				return i18n.t('enums.font-size.small').toString()
			case FontSize.Normal:
				return i18n.t('enums.font-size.normal').toString()
			case FontSize.Large:
				return i18n.t('enums.font-size.large').toString()
			case FontSize.Huge:
				return i18n.t('enums.font-size.huge').toString()
		}
	}
	static defaultPageToString(page: DefaultPage): string {
		switch (page) {
			case DefaultPage.None:
				return i18n.t('enums.default-page.none').toString()
			case DefaultPage.Dashboard:
				return i18n.t('enums.default-page.dashboard').toString()
			case DefaultPage.Patients:
				return i18n.t('enums.default-page.patients').toString()
			case DefaultPage.Planning:
				return i18n.t('enums.default-page.planning').toString()
			case DefaultPage.Accounting:
				return i18n.t('enums.default-page.accounting').toString()
			case DefaultPage.Letters:
				return i18n.t('enums.default-page.letters').toString()
			case DefaultPage.CoWorking:
				return i18n.t('enums.default-page.coworking').toString()
			case DefaultPage.Shop:
				return i18n.t('enums.default-page.shop').toString()
			case DefaultPage.Help:
				return i18n.t('enums.default-page.help').toString()
			case DefaultPage.Statistics:
				return i18n.t('enums.default-page.statistics').toString()
			case DefaultPage.Parameters:
				return i18n.t('enums.default-page.parameters').toString()
			case DefaultPage.User:
				return i18n.t('enums.default-page.user').toString()
			default:
				return ''
		}
	}
	static skinToString(skin: Skin): string {
		switch (skin) {
			case Skin.Dark:
				return i18n.t('enums.skin.dark').toString()
			case Skin.Default:
				return i18n.t('enums.skin.default').toString()
			case Skin.Light:
				return i18n.t('enums.skin.light').toString()
			default:
			return ''
		}
	}
	public static purchaseModeToString(mode: PurchaseMode): string {
		switch (mode) {
			case PurchaseMode.Paypal:
				return i18n.t('enums.purchase-mode.paypal').toString()
			case PurchaseMode.Check:
				return i18n.t('enums.purchase-mode.check').toString()
			case PurchaseMode.Transfer:
				return i18n.t('enums.purchase-mode.transfer').toString()
			default:
				return ''
		}
	}
	public static timezoneToString(timezone: Timezone): string {
		switch (timezone) {
			case Timezone.UtcPlus1:
				return i18n.t('enums.timezone.utc+1').toString()
			case Timezone.UtcMinus3:
				return i18n.t('enums.timezone.utc-3').toString()
			case Timezone.UtcMinus4:
				return i18n.t('enums.timezone.utc-4').toString()
			case Timezone.UtcMinus5:
				return i18n.t('enums.timezone.utc-5').toString()
			case Timezone.UtcPlus4:
				return i18n.t('enums.timezone.utc+4').toString()
			case Timezone.UtcPlus11:
				return i18n.t('enums.timezone.utc+11').toString()
			default:
				return ''
		}
	}
	public static langToString(lang: Lang): string {
		switch (lang) {
			case Lang.French:
				return i18n.t('enums.lang.french').toString()
			case Lang.English:
				return i18n.t('enums.lang.english').toString()
			default:
				return ""
		}
	}
	public static autoSaveDelayToString(delay: AutoSaveDelay): string {
		switch (delay) {
			case AutoSaveDelay.FiveMinutes:
				return i18n.t('enums.auto-save-delay.five-minutes').toString()
			case AutoSaveDelay.TenMinutes:
				return i18n.t('enums.auto-save-delay.ten-minutes').toString()
			case AutoSaveDelay.ThirtyMinutes:
				return i18n.t('enums.auto-save-delay.thirty-minutes').toString()
			case AutoSaveDelay.Never:
				return i18n.t('enums.auto-save-delay.never').toString()
			default:
				return ""
		}
	}
	public static periodToString(period: AccountingPeriod): string {
		switch (period) {
			case AccountingPeriod.Monthly:
				return i18n.t('enums.accounting-period.monthly').toString()
			case AccountingPeriod.Yearly:
				return i18n.t('enums.accounting-period.yearly').toString()
			default:
				return ""
		}
	}
	public static widgetToString(widget: WidgetType, count: number = 1): string {
		switch (widget) {
			case WidgetType.BirthDays:
				return i18n.tc('enums.widget.birth-days', count).toString()
			case WidgetType.Unpaid:
				return i18n.tc('enums.widget.unpaid', count).toString()
			case WidgetType.Sms:
				return i18n.tc('enums.widget.sms', count).toString()
			case WidgetType.FollowUp:
				return i18n.tc('enums.widget.follow-up', count).toString()
			case WidgetType.ValidEvent:
				return i18n.tc('enums.widget.valid-event', count).toString()
			default:
				return ""
		}
	}
	public static providerToString(provider: Provider): string {
		switch (provider) {
			case Provider.None:
				return i18n.t('enums.provider.none').toString()
			case Provider.Permatel:
				return i18n.t('enums.provider.permatel').toString()
			default:
				return ""
		}
	}
	public static spendTypeToString(type: SpendType): string {
		switch (type) {
			case SpendType.SpendType0:
				return i18n.t('enums.spend-type.spend0').toString()
			case SpendType.SpendType1:
				return i18n.t('enums.spend-type.spend1').toString()
			case SpendType.SpendType2:
				return i18n.t('enums.spend-type.spend2').toString()
			case SpendType.SpendType3:
				return i18n.t('enums.spend-type.spend3').toString()
			case SpendType.SpendType4:
				return i18n.t('enums.spend-type.spend4').toString()
			case SpendType.SpendType5:
				return i18n.t('enums.spend-type.spend5').toString()
			case SpendType.SpendType6:
				return i18n.t('enums.spend-type.spend6').toString()
			case SpendType.SpendType7:
				return i18n.t('enums.spend-type.spend7').toString()
			case SpendType.SpendType8:
				return i18n.t('enums.spend-type.spend8').toString()
			case SpendType.SpendType9:
				return i18n.t('enums.spend-type.spend9').toString()
			case SpendType.SpendType10:
				return i18n.t('enums.spend-type.spend10').toString()
			case SpendType.SpendType11:
				return i18n.t('enums.spend-type.spend11').toString()
			case SpendType.SpendType12:
				return i18n.t('enums.spend-type.spend12').toString()
			case SpendType.SpendType13:
				return i18n.t('enums.spend-type.spend13').toString()
			case SpendType.SpendType14:
				return i18n.t('enums.spend-type.spend14').toString()
			case SpendType.SpendType15:
				return i18n.t('enums.spend-type.spend15').toString()
			case SpendType.SpendType16:
				return i18n.t('enums.spend-type.spend16').toString()
			case SpendType.SpendType17:
				return i18n.t('enums.spend-type.spend17').toString()
			case SpendType.SpendType18:
				return i18n.t('enums.spend-type.spend18').toString()
			case SpendType.SpendType19:
				return i18n.t('enums.spend-type.spend19').toString()
			case SpendType.SpendType20:
				return i18n.t('enums.spend-type.spend20').toString()
			case SpendType.SpendType21:
				return i18n.t('enums.spend-type.spend21').toString()
			case SpendType.SpendType22:
				return i18n.t('enums.spend-type.spend22').toString()
			case SpendType.SpendType23:
				return i18n.t('enums.spend-type.spend23').toString()
			case SpendType.SpendType24:
				return i18n.t('enums.spend-type.spend24').toString()
			case SpendType.SpendType25:
				return i18n.t('enums.spend-type.spend25').toString()
			case SpendType.SpendType26:
				return i18n.t('enums.spend-type.spend26').toString()
			default:
				return ''
		}
	}
	public static dampingTypeToString(type: DampingType): string {
		switch (type) {
			case DampingType.Linear:
				return i18n.t('enums.damping-type.linear').toString()
			case DampingType.Degressive:
				return i18n.t('enums.damping-type.degressive').toString()
			case DampingType.Exceptional:
				return i18n.t('enums.damping-type.exceptional').toString()
			default:
				return ''
		}
	}
	public static receiptFilterTypeToString(type: ReceiptFilterType): string {
		switch (type) {
			case ReceiptFilterType.Checked:
				return i18n.t('enums.receipt-filter-type.checked').toString()
			case ReceiptFilterType.NotChecked:
				return i18n.t('enums.receipt-filter-type.not-checked').toString()
			case ReceiptFilterType.Banked:
				return i18n.t('enums.receipt-filter-type.banked').toString()
			case ReceiptFilterType.NotBanked:
				return i18n.t('enums.receipt-filter-type.not-banked').toString()
			case ReceiptFilterType.Retrocession:
				return i18n.t('enums.receipt-filter-type.retrocession').toString()
			case ReceiptFilterType.NotRetrocession:
				return i18n.t('enums.receipt-filter-type.not-retrocession').toString()
			default:
				return ''
		}
	}
	public static receiptTypeToString(type: ReceiptType): string {
		switch (type) {
			case ReceiptType.Receipt1:
				return i18n.t('enums.receipt-type.receipt1').toString()
			case ReceiptType.Receipt2:
				return i18n.t('enums.receipt-type.receipt2').toString()
			case ReceiptType.Receipt3:
				return i18n.t('enums.receipt-type.receipt3').toString()
			case ReceiptType.Receipt5:
				return i18n.t('enums.receipt-type.receipt5').toString()
			case ReceiptType.Receipt6:
				return i18n.t('enums.receipt-type.receipt6').toString()
			case ReceiptType.ReceiptOther:
				return i18n.t('enums.receipt-type.receipt-other').toString()
			default:
				return ''
		}
	}
	public static accessToString(access: AccessType): string {
		switch (access) {
			case AccessType.Other:
				return i18n.t('enums.access.other').toString()
			case AccessType.Rer:
				return i18n.t('enums.access.rer').toString()
			case AccessType.Tramway:
				return i18n.t('enums.access.tramway').toString()
			case AccessType.Metro:
				return i18n.t('enums.access.metro').toString()
			default:
				return ''
		}
	}
	public static currencyToString(currency: Currency, short: boolean = true): string {
		switch (currency) {
			case Currency.Dollar:
				return i18n.tc('enums.currency.dollar', short ? 0 : 1).toString()
			case Currency.Euro:
				return i18n.tc('enums.currency.euro', short ? 0 : 1).toString()
			default:
				return ''
		}
	}
	public static genderToString(gender: Gender): string {
		switch (gender) {
			case Gender.Man:
				return i18n.t('enums.gender.man').toString()
			case Gender.Woman:
				return i18n.t('enums.gender.woman').toString()
			case Gender.Other:
				return i18n.t('enums.gender.other').toString()
			default:
				return ''
		}
	}
	public static genderChildToString(gender: Gender): string {
		switch (gender) {
			case Gender.Man:
				return i18n.t('enums.gender-child.man').toString()
			case Gender.Woman:
				return i18n.t('enums.gender-child.woman').toString()
			case Gender.Other:
				return i18n.t('enums.gender-child.other').toString()
			default:
				return ''
		}
	}
	public static genderTeenagerToString(gender: Gender): string {
		switch (gender) {
			case Gender.Man:
				return i18n.t('enums.gender-teenager.man').toString()
			case Gender.Woman:
				return i18n.t('enums.gender-teenager.woman').toString()
			case Gender.Other:
				return i18n.t('enums.gender-teenager.other').toString()
			default:
				return ''
		}
	}
	public static genderNoneToString(gender: Gender): string {
		switch (gender) {
			case Gender.Man:
				return i18n.t('enums.gender-none.man').toString()
			case Gender.Woman:
				return i18n.t('enums.gender-none.woman').toString()
			case Gender.Other:
				return i18n.t('enums.gender-none.other').toString()
			default:
				return ''
		}
	}
	public static alimentationToString(alimentation: Alimentation): string {
		switch (alimentation) {
			case Alimentation.Both:
				return i18n.t('enums.alimentation.both').toString()
			case Alimentation.Breast:
				return i18n.t('enums.alimentation.breast').toString()
			case Alimentation.Milk:
				return i18n.t('enums.alimentation.milk').toString()
			default:
				return ''
		}
	}
	public static dateStateToString(state: DateState): string {
		switch (state) {
			case DateState.ThereIs:
				return i18n.t('enums.date_state.there_is').toString()
			case DateState.AtTheAge:
				return i18n.t('enums.date_state.at_the_age').toString()
			case DateState.Date:
				return i18n.t('enums.date_state.date').toString()
			case DateState.AtYear:
				return i18n.t('enums.date_state.at_year').toString()
			default:
				return ''
		}
	}
	public static formTypeToString(type: FormType): string {
		switch (type) {
			case FormType.Antecedent:
				return i18n.t('enums.form.antecedent').toString()
			case FormType.Consultation:
				return i18n.t('enums.form.consultation').toString()
			case FormType.Coordinates:
				return i18n.t('enums.form.coordinates').toString()
			case FormType.Pediatrics:
				return i18n.t('enums.form.pediatrics').toString()
			case FormType.Reason:
				return i18n.t('enums.form.reason').toString()
			case FormType.Sphere:
				return i18n.t('enums.form.sphere').toString()
			default:
				return ''
		}
	}
	public static questionTypeToString(type: QuestionType): string {
		switch (type) {
			case QuestionType.Long_Text:
				return i18n.t('enums.question.long_text').toString()
			case QuestionType.Multiple_Choice:
				return i18n.t('enums.question.multiple_choice').toString()
			case QuestionType.Short_Text:
				return i18n.t('enums.question.short_text').toString()
			case QuestionType.Single_Choice:
				return i18n.t('enums.question.single_choice').toString()
			default:
				return ''
		}
	}
	public static situationToString(situation: Situation, gender: Gender): string {
		switch (situation) {
			case Situation.None:
				return i18n.tc('enums.situation.none', gender).toString()
			case Situation.Single:
				return i18n.tc('enums.situation.single', gender).toString()
			case Situation.Married:
				return i18n.tc('enums.situation.married', gender).toString()
			case Situation.Divorced:
				return i18n.tc('enums.situation.divorced', gender).toString()
			case Situation.Widowed:
				return i18n.tc('enums.situation.widowed', gender).toString()
			case Situation.FreeUnion:
				return i18n.tc('enums.situation.freeUnion', gender).toString()
			case Situation.Pacs:
				return i18n.tc('enums.situation.pacs', gender).toString()
			default:
				return ''
		}
	}
  	public static levelToString(level: Level) {
    	switch (level) {
			case Level.Low:
				return i18n.t('enums.level.low').toString()
			case Level.High:
				return i18n.t('enums.level.high').toString()
			default:
				return i18n.t('enums.level.medium').toString()
		}
	}
	public static followUpLevelToString(level: FollowUpLevel) {
    	switch (level) {
			case FollowUpLevel.NotSatisfied:
				return i18n.tc('enums.follow-up-level.bad').toString()
			case FollowUpLevel.Satisfied:
				return i18n.tc('enums.follow-up-level.good').toString()
			default:
				return i18n.tc('enums.follow-up-level.none').toString()
		}
	}
	public static getRevertMember(member: MemberType): MemberType {
		switch (member) {
			case MemberType.Father_Mother:
				return MemberType.Son_Daughter
			case MemberType.Brother_Sister:
				return MemberType.Brother_Sister
			case MemberType.Son_Daughter:
				return MemberType.Father_Mother
			case MemberType.Nephew_Niece:
				return MemberType.Uncle_Aunt
			case MemberType.Uncle_Aunt:
				return MemberType.Nephew_Niece
			case MemberType.Husband_Wife:
				return MemberType.Husband_Wife
			case MemberType.GrandFather_GrandMother:
				return MemberType.GrandSon_GrandDaughter
			case MemberType.GrandSon_GrandDaughter:
				return MemberType.GrandFather_GrandMother
			default:
				return MemberType.None
		}
	}
	public static membersToString(type: MemberType, count: number = 1): string {
		switch (type) {
			case MemberType.None:
				return i18n.tc('enums.members.none', count).toString()
			case MemberType.Father_Mother:
				return i18n.tc('enums.members.father_mother', count).toString()
			case MemberType.Brother_Sister:
				return i18n.tc('enums.members.brother_sister', count).toString()
			case MemberType.Son_Daughter:
				return i18n.tc('enums.members.son_daughter', count).toString()
			case MemberType.Nephew_Niece:
				return i18n.tc('enums.members.nephew_niece', count).toString()
			case MemberType.Uncle_Aunt:
				return i18n.tc('enums.members.uncle_aunt', count).toString()
			case MemberType.Husband_Wife:
				return i18n.tc('enums.members.husband_wife', count).toString()
			case MemberType.GrandFather_GrandMother:
				return i18n.tc('enums.members.grand_father_grand_mother', count).toString()
			case MemberType.GrandSon_GrandDaughter:
				return i18n.tc('enums.members.grand_son_grand_daughter', count).toString()
			case MemberType.GreatGrandFather_GreatGrandMother:
				return i18n.tc('enums.members.great_grand_father_great_grand_mother', count).toString()
			case MemberType.GreatGrandSon_GreatGrandDaughter:
				return i18n.tc('enums.members.great_grand_son_great_grand_daughter', count).toString()
			default:
				return ""
		}
	}
	public static memberToString(type: MemberType, gender: Gender): string {
		switch (type) {
			case MemberType.None:
				return i18n.tc('enums.member.none', gender).toString()
			case MemberType.Father_Mother:
				return i18n.tc('enums.member.father_mother', gender).toString()
			case MemberType.Brother_Sister:
				return i18n.tc('enums.member.brother_sister', gender).toString()
			case MemberType.Son_Daughter:
				return i18n.tc('enums.member.son_daughter', gender).toString()
			case MemberType.Nephew_Niece:
				return i18n.tc('enums.member.nephew_niece', gender).toString()
			case MemberType.Uncle_Aunt:
				return i18n.tc('enums.member.uncle_aunt', gender).toString()
			case MemberType.Husband_Wife:
				return i18n.tc('enums.member.husband_wife', gender).toString()
			case MemberType.GrandFather_GrandMother:
				return i18n.tc('enums.member.grand_father_grand_mother', gender).toString()
			case MemberType.GrandSon_GrandDaughter:
				return i18n.tc('enums.member.grand_son_grand_daughter', gender).toString()
			case MemberType.GreatGrandFather_GreatGrandMother:
				return i18n.tc('enums.member.great_grand_father_great_grand_mother', gender).toString()
			case MemberType.GreatGrandSon_GreatGrandDaughter:
				return i18n.tc('enums.member.great_grand_son_great_grand_daughter', gender).toString()
			default:
				return ""
		}
	}
	public static verticalDirectionToString(direction: DirectionVertical): string {
		switch (direction) {
			case DirectionVertical.Top:
				return i18n.t('enums.verticalDirection.top').toString()
			case DirectionVertical.Bottom:
				return i18n.t('enums.verticalDirection.bottom').toString()
			default:
				return "-"
		}
	}
	public static strainMoveToString(move: StrainMove): string {
		switch (move) {
			case StrainMove.Rolling:
				return i18n.t('enums.strainMove.rolling').toString()
			case StrainMove.Sliding:
				return i18n.t('enums.strainMove.sliding').toString()
			default:
				return "-"
		}
	}
	public static articulationMoveToString(move: ArticulationMove): string {
		switch (move) {
			case ArticulationMove.Flexion:
				return i18n.t('enums.articulationMove.flexion').toString()
			case ArticulationMove.Extension:
				return i18n.t('enums.articulationMove.extension').toString()
			default:
				return "-"
		}
	}
	public static horizontalDirectionToString(direction: DirectionHorizontal): string {
		switch (direction) {
			case DirectionHorizontal.Left:
				return i18n.t('enums.horizontalDirection.left').toString()
			case DirectionHorizontal.Right:
				return i18n.t('enums.horizontalDirection.right').toString()
			default:
				return "-"
		}
	}
	public static recurringToString(type: RecurringType, cycle: string = ''): string {
		switch (type) {
			case RecurringType.None:
				return i18n.t('enums.recurring.none').toString()
			case RecurringType.Day:
				return `${i18n.t('enums.recurring.day').toString()} ${cycle}${EnumsHelper.recurringUnitToString(type)}`
			case RecurringType.Week:
				return `${i18n.t('enums.recurring.week').toString()} ${cycle}${EnumsHelper.recurringUnitToString(type)}`
			case RecurringType.Month:
				return `${i18n.t('enums.recurring.month').toString()} ${cycle}${EnumsHelper.recurringUnitToString(type)}`
			case RecurringType.Year:
				return `${i18n.t('enums.recurring.year').toString()} ${cycle}${EnumsHelper.recurringUnitToString(type)}`
			default:
				return ""
		}
	}
	public static recurringUnitToString(type: RecurringType): string {
		switch (type) {
			case RecurringType.Day:
				return i18n.t('enums.recurringUnit.day').toString()
			case RecurringType.Week:
				return i18n.t('enums.recurringUnit.week').toString()
			case RecurringType.Month:
				return i18n.t('enums.recurringUnit.month').toString()
			case RecurringType.Year:
				return i18n.t('enums.recurringUnit.year').toString()
			default:
				return ""
		}
	}
    public static profileToString(type: Profile, short:boolean = false): string {
		switch (type) {
			case Profile.Main:
				return i18n.t(`enums.profile.main${short ? '-short': ''}`).toString()
			case Profile.Substitute:
				return i18n.t(`enums.profile.substitute${short ? '-short': ''}`).toString()
			case Profile.Accounting:
				return i18n.t(`enums.profile.accounting${short ? '-short': ''}`).toString()
			case Profile.Secretary:
				return i18n.t(`enums.profile.secretary${short ? '-short': ''}`).toString()
			case Profile.Demo:
				return i18n.t(`enums.profile.demo${short ? '-short': ''}`).toString()
			default:
				return ""
		}
	}
    public static profileToIcon(type: Profile): string {
		switch (type) {
			case Profile.Substitute:
				return "retweet"
			case Profile.Secretary:
				return "calendar-check"
			case Profile.Accounting:
				return "calculator-alt"
			default:
				return "stars"
		}
	}
	public static reminderToString(type: ReminderType): string {
		switch (type) {
			case ReminderType.None:
				return i18n.t('enums.reminder.none').toString()
			case ReminderType.Both:
				return i18n.t('enums.reminder.both').toString()
			case ReminderType.OnlySms:
				return i18n.t('enums.reminder.onlySms').toString()
			case ReminderType.OnlyMail:
				return i18n.t('enums.reminder.onlyMail').toString()
			default:
				return ""
		}
	}
	public static confirmationToString(type: ReminderType): string {
		switch (type) {
			case ReminderType.None:
				return i18n.t('enums.confirmation.none').toString()
			case ReminderType.Both:
				return i18n.t('enums.confirmation.both').toString()
			case ReminderType.OnlySms:
				return i18n.t('enums.confirmation.onlySms').toString()
			case ReminderType.OnlyMail:
				return i18n.t('enums.confirmation.onlyMail').toString()
			default:
				return ""
		}
	}
	public static attachmentToString(type: AttachmentType): string {
		switch (type) {
			case AttachmentType.File:
				return i18n.t('enums.attachment.file').toString()
			case AttachmentType.Link:
				return i18n.t('enums.attachment.link').toString()
			default:
				return ""
		}
	}
	public static invoiceTypeToString(type: InvoiceType): string {
		switch (type) {
			case InvoiceType.Receipt:
				return i18n.t('enums.invoice-type.receipt').toString()
			case InvoiceType.Spend:
				return i18n.t('enums.invoice-type.spend').toString()
			case InvoiceType.Immobilization:
				return i18n.t('enums.invoice-type.immobilization').toString()
			default:
				return ""
		}
	}
	public static paymentToString(mode: PaymentMode, icon?: boolean): string {
		switch (mode) {
			case PaymentMode.Check:
				return !!icon ? 'money-check' : i18n.t('enums.payment.check').toString()
			case PaymentMode.CreditCard:
				return !!icon ? 'credit-card' : i18n.t('enums.payment.credit-card').toString()
			case PaymentMode.FreeAct:
				return !!icon ? 'thumbs-up' : i18n.t('enums.payment.free-act').toString()
			case PaymentMode.Moneo:
				return !!icon ? 'moneo' : i18n.t('enums.payment.moneo').toString()
			case PaymentMode.Cash:
				return !!icon ? 'coins' : i18n.t('enums.payment.cash').toString()
			case PaymentMode.Transfer:
				return !!icon ? 'share-square' : i18n.t('enums.payment.transfer').toString()
			case PaymentMode.Other:
				return !!icon ? 'question-square' : i18n.t('enums.payment.other').toString()
			case PaymentMode.Waiting:
				return !!icon ? 'hourglass-half' : i18n.t('enums.payment.waiting').toString()
			default:
				return ""
		}
	}
	public static newsTypeToString(type: NewsType): string {
		switch (type) {
			case NewsType.Health:
				return i18n.t('enums.news.health').toString()
			case NewsType.Osteopathy:
				return i18n.t('enums.news.osteopathy').toString()
			case NewsType.Software:
				return i18n.t('enums.news.software').toString()
			default:
				return ""
		}
	}
	public static packTypeToString(type: PackType): string {
		switch (type) {
			case PackType.Sms:
				return i18n.t('enums.pack.sms').toString()
			case PackType.Subscription:
				return i18n.t('enums.pack.subscription').toString()
			case PackType.Support:
				return i18n.t('enums.pack.support').toString()
			case PackType.Unlimited:
				return i18n.t('enums.pack.unlimited').toString()
			case PackType.Import:
				return i18n.t('enums.pack.import').toString()
			case PackType.Planning:
				return i18n.t('enums.pack.planning').toString()
			case PackType.Card:
				return i18n.t('enums.pack.card').toString()
			case PackType.Bulk1:
				return i18n.t('enums.pack.bulk1').toString()
			case PackType.Bulk2:
				return i18n.t('enums.pack.bulk2').toString()
			case PackType.Site:
				return i18n.t('enums.pack.site').toString()
			default:
				return ""
		}
	}
	public static giftPackTypeToString(type: PackType, value: number): string {
		switch (type) {
			case PackType.Sms:
				return i18n.tc('enums.gift-pack.sms', value).toString()
			case PackType.Subscription:
				return i18n.tc('enums.gift-pack.subscription', value).toString()
			case PackType.Support:
				return i18n.tc('enums.gift-pack.support', value).toString()
			case PackType.Unlimited:
				return i18n.tc('enums.gift-pack.unlimited', value).toString()
			case PackType.Import:
				return i18n.tc('enums.gift-pack.import', value).toString()
			case PackType.Planning:
				return i18n.tc('enums.gift-pack.planning', value).toString()
			case PackType.Card:
				return i18n.tc('enums.gift-pack.card', value).toString()
			case PackType.Site:
				return i18n.tc('enums.gift-pack.site', value).toString()
			default:
				return ""
		}
	}
	public static phoneTypeToString(type: PhoneType): string {
		switch (type) {
			case PhoneType.Fix:
				return i18n.t('enums.phone.fix').toString()
			case PhoneType.Mobil:
				return i18n.t('enums.phone.mobil').toString()
			case PhoneType.Professional:
				return i18n.t('enums.phone.professional').toString()
			case PhoneType.Fax:
				return i18n.t('enums.phone.fax').toString()
			default:
				return ""
		}
	}
	public static freeSpaceModeToString(mode: FreeSpaceMode): string {
		switch (mode) {
			case FreeSpaceMode.Classic:
				return i18n.t('enums.free-space-mode.classic').toString()
			case FreeSpaceMode.Optimized:
				return i18n.t('enums.free-space-mode.optimized').toString()
			case FreeSpaceMode.SemiOptimized:
				return i18n.t('enums.free-space-mode.semi-optimized').toString()
			default:
				return ""
		}
	}
}
