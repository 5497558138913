<div class="input-frame" :class="{ 'is-alert': errorTest }">
	<label v-if="label" class="label" :for="id" :data-text="label">
		<span>{{label}}</span>
	</label>
	<div class="textarea-container">
		<button type="button" class="btn--link-like expand-btn" @click="resized = !resized" 
			:title="resized ? $t('vm.components.frame.textarea-frame.title.collapse') : $t('vm.components.frame.textarea-frame.title.expand')">
			<svg v-if="resized" width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-from-bottom"/>
			</svg>
			<svg v-else width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-bottom"/>
			</svg>
			<span class="sr-only">{{resized ? $t('vm.components.frame.textarea-frame.label.collapse') : $t('vm.components.frame.textarea-frame.label.expand')}}</span>
		</button>
		<textarea :id="id" :ref="id" v-focus="autoFocus" :rows="rows" :value="value" @input="handleInput($event.target.value)" 
			@keyup="resize" :placeholder="placeholder" :title="title" :readonly="readonly" :disabled="disabled"/>
	</div>
	<div class="msg-alert" v-if="displayError" v-show="errorTest">
		<div class="msg-alert-inner">{{errorMsg}}</div>
	</div>
</div>
