export enum SpendType {
    SpendType0 = 0,
    SpendType1 = 1,
    SpendType2 = 2,
    SpendType3 = 3,
    SpendType4 = 4,
    SpendType5 = 5,
    SpendType6 = 6,
    SpendType7 = 7,
    SpendType8 = 8,
    SpendType9 = 9,
    SpendType10 = 10,
    SpendType11 = 11,
    SpendType12 = 12,
    SpendType13 = 13,
    SpendType14 = 14,
    SpendType15 = 15,
    SpendType16 = 16,
    SpendType17 = 17,
    SpendType18 = 18,
    SpendType19 = 19,
    SpendType20 = 20,
    SpendType21 = 21,
    SpendType22 = 22,
    SpendType23 = 23,
    SpendType24 = 24,
    SpendType25 = 25,
    SpendType26 = 26
}