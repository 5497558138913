<div v-if="options">
	<p>{{ $t('vm.components.popin.share-referral.text1') }}</p>

	<input-frame type="email" id="email" :auto-focus="true" v-model="options.email"
		:label="$t('vm.components.popin.share-referral.email.label')"
		:placeholder="$t('vm.components.popin.share-referral.email.placeholder')"
		:error-test="hasEmailError"
		:error-msg="$t('vm.components.popin.share-referral.email.error')"
		:title="$t('vm.components.popin.share-referral.email.title')"/>

	<textarea-frame id="share-text" rows="5" v-model="options.text"
		:placeholder="$t('vm.root.help.new.comment.placeholder')"
		:error-test="hasShareTextError"
		:error-msg="$t('vm.root.help.new.comment.error-msg')"
		:title="$t('vm.root.help.new.comment.title')"/>

</div>
