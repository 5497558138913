<container :loading="!purchase" loading-title="ouverture de l'achat">

	<aside-main @delete="onDelete"></aside-main>

	<master v-if="!!purchase">

		<central>
			<paying v-if="payed" :price="newPrice === -1 ? price : newPrice" :payer="purchase"></paying>

			<div v-else-if="isCompleted" class="wrapper marg-v text-center">
				<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
				</svg>
				<p><strong class="text-xl text-primary">{{ $t('vm.root.shop.purchase.main.payed.thanks') }}</strong></p>
				<p>{{ $t('vm.root.shop.purchase.main.payed.text1') }}</p>
				<p>{{ $tc('vm.root.shop.purchase.main.payed.text2', purchase.billings.length) }}</p>
				<control-bar class="text-right" v-if="canUnsubscribe">
					<delete-item class="btn btn-danger" :can-delete="true"
						:title="$t('vm.root.shop.purchase.main.unsubscribe.title')"
						:delete-label="$t('vm.root.shop.purchase.main.unsubscribe.label')"
						:delete-msg="$t('vm.root.shop.purchase.main.unsubscribe.delete-msg')"
						:delete-action="submitUnsubscribe">
						{{ $t('vm.root.shop.purchase.main.unsubscribe.label') }}
						<spinner v-if="isUnsubscribing"></spinner>
						<svg v-else width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
						</svg>
					</delete-item>
				</control-bar>
			</div>
			<div v-else class="wrapper marg-v">
				<h1>{{ $t('vm.root.shop.purchase.main.title') }} {{ type | pack }}</h1>

				<p v-if="isSupport">
					<i18n path="vm.root.shop.packs.support.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.support.text1.strong') }}</strong>
					</i18n>
					<i18n path="vm.root.shop.packs.support.text2.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.support.text2.strong') }}</strong>
					</i18n>
				</p>
				<p v-else-if="isUnlimited">
					<i18n path="vm.root.shop.packs.unlimited.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.unlimited.text1.strong') }}</strong>
					</i18n>
					<span>{{ $t('vm.root.shop.packs.unlimited.text2') }}</span>
					<br>
					<span>{{ $t('vm.root.shop.packs.unlimited.text3') }}</span>
				</p>
				<p v-else-if="isSubscription">
					<i18n path="vm.root.shop.packs.subscription.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.subscription.text1.strong') }}</strong>
					</i18n>
					<span>{{ $t('vm.root.shop.packs.subscription.text2') }}</span>
				</p>
				<p v-else-if="isPlanning">
					<span>{{ $t('vm.root.shop.packs.planning.text1') }}</span>
					<span>{{ $t('vm.root.shop.packs.planning.text2') }}</span>
					<i18n path="vm.root.shop.packs.planning.text3.content" tag="span">
						<span class='text-xs'>{{ $t('vm.root.shop.packs.planning.text3.span') }}</span>
					</i18n>
					<a href="https://osteo2ls.com/rdv/vous-etes-osteo.html" :title="$t('vm.root.shop.packs.planning.link-title')" target="_blank">
						{{ $t('vm.root.shop.packs.planning.link-label') }}
					</a>
				</p>
				<p v-else-if="isCard">
					<span>{{ $t('vm.root.shop.packs.card.text1') }}</span>
					<span>{{ $t('vm.root.shop.packs.card.text2') }}</span>
				</p>
				<p v-else-if="isSms">
					<i18n path="vm.root.shop.packs.sms.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.sms.text1.strong') }}</strong>
					</i18n>
					<i18n path="vm.root.shop.packs.sms.text2.content" tag="span">
						<router-link :to="{name: 'patients-campaigns'}" :title="$t('vm.root.shop.packs.sms.text2.link-title')">
							{{ $t('vm.root.shop.packs.sms.text2.link-label') }}
						</router-link>
					</i18n>
					<i18n path="vm.root.shop.packs.sms.text3.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.sms.text3.strong') }}</strong>
					</i18n>
				</p>
				<p v-else-if="isImport">
					<i18n path="vm.root.shop.packs.import.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.import.text1.strong') }}</strong>
					</i18n>
					<span>{{ $t('vm.root.shop.packs.import.text2') }}</span>
				</p>
				<p v-else-if="isSite">
					<i18n path="vm.root.shop.packs.site.text1.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.site.text1.strong') }}</strong>
					</i18n>
					<i18n path="vm.root.shop.packs.site.text2.content" tag="span">
						<strong>{{ $t('vm.root.shop.packs.site.text2.strong') }}</strong>
						<span class='text-xs'>{{ $t('vm.root.shop.packs.site.text2.span') }}</span>
					</i18n>
				</p>

				<div class="grid-3 vertical-gap rwd-over-large" v-if="isCard">
					<div>
						<figure class="illustration">
							<img v-if="purchase.model === 1" class="illustration-pic text-center" src="@Assets/img/illustrations/affiche-1.jpg" :alt="$t('vm.root.shop.purchase.main.poster.alt')">
							<img v-else-if="purchase.model === 2" class="illustration-pic text-center" src="@Assets/img/illustrations/affiche-2.jpg" :alt="$t('vm.root.shop.purchase.main.poster.alt')">
							<figcaption class="text-center">{{ $t('vm.root.shop.purchase.main.poster.title') }}</figcaption>
						</figure>
					</div>
					<div>
						<figure class="illustration">
							<img v-if="purchase.model === 1" class="illustration-pic text-center" src="@Assets/img/illustrations/carte-recto-1.jpg" :alt="$t('vm.root.shop.purchase.main.card.alt-recto')">
							<img v-else-if="purchase.model === 2" class="illustration-pic text-center" src="@Assets/img/illustrations/carte-recto-2.jpg" :alt="$t('vm.root.shop.purchase.main.card.alt-recto')">
							<figcaption class="text-center">{{ $t('vm.root.shop.purchase.main.card.title-recto') }}</figcaption>
						</figure>
					</div>
					<div>
						<figure class="illustration">
							<img class="illustration-pic text-center" src="@Assets/img/illustrations/carte-verso.jpg" :alt="$t('vm.root.shop.purchase.main.card.alt-verso')">
							<figcaption class="text-center">{{ $t('vm.root.shop.purchase.main.card.title-verso') }}</figcaption>
						</figure>
					</div>
				</div>

				<form @submit.prevent="submitPay">
					<input-address-frame id="address" :address="purchase.address" :readonly="isCompleted"
						:error-test="hasAddressError" :error-msg="$t('vm.root.shop.purchase.main.address.error-msg')"
						:label="$t('vm.root.shop.purchase.main.address.label')"
						:title="$t('vm.root.shop.purchase.main.address.title')"></input-address-frame>

					<div v-if="displayCode" class="notice-success">
						<p class="text-left">{{ $t('vm.root.shop.purchase.main.code.help') }}
						</p>
					</div>

					<div class="input-frame" v-if="!isCompleted">
						<label class="label" for="code" :data-text="$t('vm.root.shop.purchase.main.code.label')">
							<span>{{ $t('vm.root.shop.purchase.main.code.label') }}</span>
						</label>
						<div class="form-group">
							<input class="form-group-input" v-model="code" id="code"
								:title="$t('vm.root.shop.purchase.main.code.title')"
								:placeholder="$t('vm.root.shop.purchase.main.code.placeholder')">

							<button-action default-class="" btn-class="btn-default input-like form-group-input is-flex-fixed" :loading="isApplying"
								@click.native="submitCode" :disabled="!code" :title="$t('vm.root.shop.purchase.main.code.apply-title')">
								<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
								</svg>
								{{ $t('vm.root.shop.purchase.main.code.apply') }}
							</button-action>
						</div>
					</div>

					<div :class="{'grid-2': isCard}">
						<dropdown-frame v-if="isCard" id="model" v-model="purchase.model" :items="modelItems"
							:label="$t('vm.root.shop.purchase.main.model.label')"
							:title="$t('vm.root.shop.purchase.main.model.title')"></dropdown-frame>

						<dropdown-frame v-if="valueItems.length > 1" id="value" v-model="purchase.value" :items="valueItems"
							:label="$t('vm.root.shop.purchase.main.quantity.label')"
							:title="$t('vm.root.shop.purchase.main.quantity.title')"></dropdown-frame>
					</div>

					<togglebox-frame v-if="!isRecurrent" id="payment" :items="paymentItems" v-model="purchase.mode"
						:label="$t('vm.root.shop.purchase.main.mode.label')"
						:title="$t('vm.root.shop.purchase.main.mode.title')"></togglebox-frame>

					<div class="p-like text-lg text-center">
						{{ $t('vm.root.shop.purchase.main.total.text') }}
						<span class="text-primary text-bold">
							<span :class="{'text-strikethrough': newPrice !== -1}">{{ $n(price, 'currency', 'fr') }}</span>
							<span v-if="newPrice !== -1">{{ $n(newPrice, 'currency', 'fr') }}</span>
							<abbr :title="$t('vm.root.shop.purchase.main.total.ttc-title')">{{ $t('vm.root.shop.purchase.main.total.ttc-label') }}</abbr>
							<span v-if="isSubscription">{{ $t('vm.root.shop.purchase.main.total.subscription') }}</span>
							<span v-if="isPlanning"> / {{ purchase.value === 1 ? $t('vm.root.shop.purchase.main.total.month') : $t('vm.root.shop.purchase.main.total.year') }}</span>
						</span>
					</div>

					<control-bar class="childs-space-between">
						<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || isDeleting || disabled" :title="$t('general.cancel.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
							</svg>
							{{ $t('general.cancel.text') }}
						</button-action>

						<button-action type="submit" btn-class="btn-success" :loading="isSaving" :disabled="disabled || isDeleting"
							:title="isRecurrent ? $t('vm.root.shop.purchase.main.subscribe.title') : $t('vm.root.shop.purchase.main.pay.title')">
							<span v-if="isRecurrent">{{ $t('vm.root.shop.purchase.main.subscribe.label') }}</span>
							<span v-else>{{ $t('vm.root.shop.purchase.main.pay.label') }}</span>
							<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
								<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
							</svg>
						</button-action>
					</control-bar>
				</form>
			</div>
		</central>

		<context-main></context-main>

	</master>

</container>
