import Render from '@Views/root/accounting/spend/recurrency.html'
import { isEqual, cloneDeep } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { INotification, INotificationAction, IBreadcrumbItem, IInvoice, IRecurring } from '@Store/types'
import { Recurring } from '@Components/index'
import { breadcrumb, notif } from '@Store/modules'

@Render
@Component({
    components: {
        'recurring': Recurring
    }
})
export class Recurrency extends Vue {

    private readonly: boolean = true
    private oldRecurring: IRecurring

    @Prop() spend: IInvoice
    @Prop() prefix: string

	// @State('user') stateUser: UserState
    // @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
    // @Mutation('notification/warning') warning: (message: string | INotification) => void
    // @Mutation('notification/clear') clear: () => void

    get recurring(): IRecurring {
        return this.spend.recurring
    }
    get disabled(): boolean {
        return this.readonly
    }

    mounted() {
        this.initializeBreadcrumb()
        this.updateFromRecId()
    }

    beforeDestroy() {
        notif.clear()
    }

    private setOldRecurring(recurring: IRecurring): any {
        if (!recurring) return

        if (!!this.oldRecurring) return
        
        this.oldRecurring = cloneDeep(recurring)
    }

    @Watch('recurring', {deep: true})
    updateRecurring(value: IRecurring) {
        this.spend.recurring = value
        if (isEqual(this.spend.recurring, this.oldRecurring)) return

        this.spend.recurring_update = true
    }

    @Watch('spend.from_rec_id')
    updateFromRecId() {
        this.readonly = !!this.spend.from_rec_id && parseInt(this.spend.from_rec_id) !== -1
        if (!this.readonly) return

        this.displayWarning()
    }

    private displayWarning() {
        let continueAction: INotificationAction = {
            label: this.$i18n.t('general.recurrency-warning.continue').toString(),
            callback: () => {
                this.setOldRecurring(this.recurring)
                this.readonly = false
            }
        }
        let cancelAction: INotificationAction = {
            label: this.$i18n.t('general.recurrency-warning.cancel').toString(),
            callback: () => { this.$router.back() }
        }
        let notification: INotification = {
            message: this.$i18n.t('general.recurrency-warning.message').toString(),
            actions: [continueAction, cancelAction],
            canClose: false,
            delay: false
        }
        notif.warning(notification)
    }

    private initializeBreadcrumb(): void {
        let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.spend').toString(), link: {name: 'spend', params: this.$route.params} }
        let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.recurrency').toString() }
        breadcrumb.updateItems([item1, item2, item3])
	}
}