<div>
	<component :is="tag" class="marg-v">
		<button type="button" @click="toggle()" class="misc-trigger btn--link-like" :class="{'is-active': isToggle()}">
			<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
			</svg>
			<slot name="title"/>
		</button>
	</component>
	<div class="misc-collapsible" :class="{'is-active': isToggle()}">
		<div class="misc-collapsible-content">
			<slot />
		</div>
	</div>
</div>
