import Render from '@Views/root/parameters/aside-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { configuration, user } from '@Store/modules'

@Render
@Component({})
export class ASideMain extends Vue {

	get isMainUser(): boolean {
		return user.isMainUser
	}
	get isSubstituteUser(): boolean {
		return user.isSubstituteUser
	}
	get isSecretaryUser(): boolean {
		return user.isSecretaryUser
	}
	get isAccountingUser(): boolean {
		return user.isAccountingUser
	}
  	get formsCount(): number {
		return configuration.configuration.forms.currents.length
  	}
  	get documentsCount(): number {
		return configuration.configuration.documents.currents.length
	}
	get isLocalhost(): boolean {
		return !!location.hostname && location.hostname.includes('localhost')
	}
}