<div>
	<slot/>

	<div :class="classTag">
		<component :is="tag" ref="sentinel"/>
	</div>

	<slot name="loading" v-if="isLoading"/>
	<slot name="footer"/>
</div>
