import Render from '@Views/components/breadcrumb/more-info.html'
import { Vue, Component } from 'vue-property-decorator'
import { StorageHelper } from '@Helpers/index'
import { user } from '@Store/modules'
import { IUser } from '@Store/types'

@Render
@Component({})
export default class MoreInfo extends Vue {
	name:string = 'more-info'

	private reduce: boolean = false

	get user(): IUser | {} {
		return user.user || {}
	}
	get classes(): any {
		return {'is-open': !this.reduce}
	}

	mounted() {
		this.reduce = StorageHelper.get('menu_reduce', false)
	}
}
