import Render from '@Views/root/patients/patient/relaunch.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IRelaunch, IBreadcrumbItem, INotification, INotificationAction, IDisplay, IConfiguration } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { PackType, ReminderType } from '@Enums/index'
import { breadcrumb, configuration, notif, patient, user } from '@Store/modules'

@Render
@Component({})
export class Relaunch extends Vue {

	disabled: boolean = false
	private relaunchs: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]

	@Prop() saved: boolean
	@Prop() submitted: boolean
	@Prop() readOnly: boolean

    // @State('configuration') stateConfiguration: ConfigurationState
    // @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('notification/info') info: (message: string | INotification) => void
    // @Mutation('notification/clear') clear: () => void
    // @Getter('patient/relaunch') relaunch: IRelaunch

	get configuration(): IConfiguration {
		return configuration.configuration
	}
	get relaunch(): IRelaunch {
		return patient.relaunch
	}
	get display(): IDisplay {
		return this.configuration && this.configuration.display
	}
    get isRelaunchActive(): boolean {
        return this.relaunch.type !== ReminderType.None
    }
    get relaunchByEmail(): boolean {
        return this.relaunch.type === ReminderType.OnlyMail || this.relaunch.type === ReminderType.Both
    }
    get relaunchBySms(): boolean {
        return this.relaunch.type === ReminderType.OnlySms || this.relaunch.type === ReminderType.Both
    }
	get relaunchItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.relaunchs.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
	    return result
	}
	get hasDelayError(): boolean {
        return !!this.saved && !this.isValidDelay
    }
    get useActivePlanning(): boolean {
        return !user.user.no_planning && !!user.user.planning_date && user.user.planning_date > new Date()
    }
	private get isValidDelay(): boolean {
        return !this.isRelaunchActive || (!!this.relaunch.delay && parseInt(this.relaunch.delay.toString()) > 0)
    }

	mounted() {
		this.$emit('navigate', 'relaunch')
		this.initializeBreadcrumb()
		this.notifications()
    }
    
    beforeDestroy() {
        notif.clear()
    }

	private notifications() {
        if (!this.useActivePlanning) {
            this.purchaseNotification()
            return
        }

        this.disabled = false
    }

    private purchaseNotification() {
        this.disabled = true
        let purchaseAction: INotificationAction = {
            label: this.$i18n.t('vm.root.patients.patient.relaunch.purchase.label').toString(),
            callback: () => { this.$router.push({name: 'purchase-pack', params: {pack: PackType.Planning.toString()}}) }
        }
        let notification: INotification = {
            message: this.$i18n.t('vm.root.patients.patient.relaunch.purchase.message').toString(),
            actions: [purchaseAction],
            canClose: true,
            delay: false
        }
        notif.info(notification)
    }

	private initializeBreadcrumb(): void {
        let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.relaunch').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}