export const searchPatient = {
    label: "Sélectionnez un patient ou créez un nouveau dossier",
    title: "Recherchez un patient",
    placeholder: "ex : Émile Letueur",
    create: {
        title: "Créer un nouveau dossier patient",
        text: "Créer un dossier"
    },
    select: {
        label: "sélectionner",
        title: "Sélectionner ce dossier patient"
    },
    'create-popin': {
        label: "ajouter",
        title: "Ajouter un nouveau dossier patient"
    },
    duplicate: {
        label: "dupliquer",
        title: "Dupliquer ce dossier patient"
    },
    'update-popin': {
        label: "modifier",
        title: "Modifier les coordonnées du patient"
    }
}
