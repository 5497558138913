<nav class="l-navigation" data-toggle-navigation>
	<button type="button" v-toggle.main="{className: 'is-expanded', selector: '[data-toggle-navigation]', state:'menu'}" class="btn--reset nav-item nav-item-switch rwd-over-small" :title="$t('vm.components.layout.navigation.reduce.title')">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-right"/>
			</svg>
		</span>
		<span class="nav-item-label text-sm">{{$t('vm.components.layout.navigation.reduce.label')}}</span>
	</button>
	<router-link :to="{name: 'home'}" class="nav-item" :title="$t('vm.components.layout.navigation.dashboard.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<span v-if="hasUnreadFollowUp" class="nav-item-badge" :title="$tc('vm.components.layout.navigation.dashboard.tooltip', unreadFollowUpCount)">{{unreadFollowUpCount}}</span>
			<svg width="1em" class="svg-middle" viewBox="0 0 90 90" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#logo-min"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.dashboard.label')}}</span>
	</router-link>
	<router-link v-if="isSubstituteUser || isMainUser" :to="{name: 'patients'}" class="nav-item" :class="{'router-link-active': isPatientView}" :title="$t('vm.components.layout.navigation.patients.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use v-if="isAnonymous" xlink:href="@Assets/svg/symbol-defs.svg#user-secret"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.patients.label')}}</span>
	</router-link>
	<router-link v-if="isSecretaryUser || isMainUser" :to="{name: 'plannings'}" class="nav-item" :title="$t('vm.components.layout.navigation.planning.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<span v-if="hasUnvalidEvent" class="nav-item-badge" :title="$tc('vm.components.layout.navigation.planning.tooltip', unvalidEventCount)">{{unvalidEventCount}}</span>
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#calendar"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.planning.label')}}</span>
	</router-link>
	<router-link v-if="isSubstituteUser || isAccountingUser || isMainUser" :to="{name: 'accounting'}" class="nav-item" :title="$t('vm.components.layout.navigation.accounting.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#calculator"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.accounting.label')}}</span>
	</router-link>
	<router-link v-if="isMainUser" :to="{name: 'letters'}" class="nav-item" :title="$t('vm.components.layout.navigation.letters.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#envelope"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.letters.label')}}</span>
	</router-link>
	<router-link v-if="isMainUser" :to="{name: 'co-working'}" class="nav-item" :title="$t('vm.components.layout.navigation.co-working.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.co-working.label')}}</span>
	</router-link>
	<hr v-if="isMainUser">
	<router-link v-if="isMainUser" :to="{name: 'shop'}" class="nav-item" :title="$t('vm.components.layout.navigation.shop.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.shop.label')}}</span>
	</router-link>
	<router-link v-if="isMainUser" :to="{name: 'help'}" class="nav-item" :title="$t('vm.components.layout.navigation.help.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<span v-if="hasUnreadTicket" class="nav-item-badge">{{ unreadCommentCount + unreadClosedCommentCount }}</span>
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.help.label')}}</span>
	</router-link>
	<hr>
	<router-link v-if="isMainUser" :to="{name: 'statistics-patients'}" class="nav-item" :title="$t('vm.components.layout.navigation.statistics.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 544 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#chart-pie"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.statistics.label')}}</span>
	</router-link>
	<router-link :to="{name: 'parameters-main'}" class="nav-item" :title="$t('vm.components.layout.navigation.parameters.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#cog"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.parameters.label')}}</span>
	</router-link>
	<hr>

	<button v-if="isMainUser" type="button" class="nav-item-rdv" @click="createNewEvent" :title="$t('vm.components.layout.navigation.new-event.title')">
		<svg width="1em" class="nav-item-rdv-icon svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-plus"/>
		</svg>
		<span class="nav-item-rdv-text text-sm text-bold">{{$t('vm.components.layout.navigation.new-event.label')}}</span>
	</button>

	<div class="app-version text-xs text-center">
		<button type="button" class="btn--reset app-version-btn" @click="displayChangeLog" title="Changelogs">
			<span class="app-version-text">version</span>
			{{version}}
		</button>
	</div>
	<router-link :to="{name: 'user-account'}" class="nav-item nav-item-account marquee" :title="$t('vm.components.layout.navigation.account.title')"
		v-toggle.main="{className: 'is-open', selector: '[data-toggle-navigation].is-open', reducer: '[data-toggle-aside]', state:'menu'}">
		<span class="nav-item-avatar" :class="{'is-anonymous': isAnonymous}">
			<svg v-if="isAnonymous" width="1em" class="svg-block" viewBox="0 0 448 512" role="img" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#user-secret"/>
			</svg>
			<avatar v-else :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
		</span>
		<span class="nav-item-label text-sm">
			<span class="nav-item-account-name text-capitalize marquee-inner">
				<span class="marquee-content">{{user.coordinates.first_name}} {{user.coordinates.last_name}}</span>
			</span><br>
			<span class="nav-item-account-profile text-sm marquee-inner">
				<span v-if="isAnonymous" class="marquee-content">{{ $t('vm.components.layout.navigation.account.anonymous') }}</span>
				<span v-else class="marquee-content">{{user.profile | profile}}</span>
			</span>
		</span>
	</router-link>
	<button type="button" @click="logout()" class="btn--reset nav-item nav-item-logout" :title="$t('vm.components.layout.navigation.logout.title')">
		<span class="nav-item-icon">
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#power-off"/>
			</svg>
		</span>
		<span class="nav-item-label">{{$t('vm.components.layout.navigation.logout.label')}}</span>
	</button>
</nav>
