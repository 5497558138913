<container>

	<aside-campaigns/>

	<master>

		<central>
			<div class="l-loading text-center">
				<div>
					<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#comment-alt-lines"/>
					</svg>
					<p><strong class="text-lg text-primary">{{ $t('vm.root.patients.campaigns.white-space.strong') }}</strong></p>
					<p>{{ $t('vm.root.patients.campaigns.white-space.text') }}</p>
				</div>
			</div>
			
		</central>

		<context-campaigns/>

	</master>

</container>
