import Render from '@Views/root/calendar/context-main.html'
import {PlanningItem} from './planning-item'
import {Component, Vue, Watch} from 'vue-property-decorator'
import {GoogleService} from "@Services/index"
import {IOffice, IPlanning} from '@Store/types'
import {StorageHelper} from '@Helpers/index'
import {breadcrumb, office, planning, user} from '@Store/modules'
import {OfficeHelper} from "@Helpers/office"
import {clone} from "lodash"
@Render
@Component({
	components: {
		'planning-item': PlanningItem
	}
})
export class ContextMain extends Vue {

	authorized:boolean = false
	initializing:boolean = false
	private service: GoogleService = undefined

	//#region Getters
	get offices(): IOffice[] {
		let offices = clone(this.officesByUser)
		offices.unshift(OfficeHelper.createAllOfficeItem())

		return offices
	}
	get plannings(): IPlanning[] {
		return planning.plannings
	}
	get googlePlannings(): IPlanning[] {
		return planning.googlePlannings
	}
	get hasPlanning(): boolean {
		return planning.hasPlanning
	}
	get isGoogleLoading(): boolean {
		return planning.isGoogleLoading
	}
	get selectedOffice(): string {
		return !!office.selectedPlanningOffice ? office.selectedPlanningOffice.id : null
	}
	set selectedOffice(id: string) {
		let _office = this.offices.find(office => office.id === id)
		office.selectedPlanningLinkOffice(_office)
	}
	get hasOffice() {
		return this.officesByUser.length > 0
	}
	get officesByUser(): IOffice[] {
		if (!user.user) return []

		return office.officesByUser(user.user.id)
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		this.service = GoogleService.getInstance()
		this.authorized = this.service.authorized
		this.service.$on('initializing-updated', () => {
			// planning.authorizedGoogle(this.service.authorized)
			this.initializing = this.service.initializing
		})
		this.service.$on('authorized-updated', () => {
			this.authorized = this.service.authorized
		})
		this.service.$on('token-refreshed', () => {
			this.initializeGooglePlannings()
		})
		if (this.hasOffice && !this.selectedOffice) {
			this.selectedOffice = this.officesByUser.length > 1 ? this.offices[0].id : this.officesByUser[0].id
		}
	}

	beforeDestroy() {
		this.service.$off('authorized-updated')
		this.service.$off('initializing-updated')
		this.service.$off('token-refreshed')
	}

	authenticate() {
		this.service.authenticate()
	}

	refreshToken() {
		this.service.refreshToken()
	}

	disconnect() {
		this.service.revokeToken()
	}

	@Watch('authorized')
	initializeGooglePlannings() {
		planning.initializeGooglePlannings(this.authorized)
	}

	updateGooglePlanning(planning: IPlanning): void {
		StorageHelper.set(`planning-${planning.id}-visible`, planning.visible)
	}
}
