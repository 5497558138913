import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { ITag, ITagItem } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { TagCategory, TagService } from "@Services/index"
import { groupBy } from "lodash"

@Module({
	name: 'tag',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class TagModule extends VuexModule {
    statusSphere: RequestStatus = RequestStatus.None
    statusAntecedent: RequestStatus = RequestStatus.None
    private _sphereTags: ITag[] = []
    private _antecedentTags: ITag[] = []

	get antecedentTags(): ITagItem[] {
        return this._antecedentTags.map(tag => { return {group: tag.group, text: tag.label} })
    }
    get sphereTags(): ITagItem[] {
        return Object.keys(groupBy(this._sphereTags, 'group')).map(tag => { return {group: "sphere", text: tag} })
	}
	get pathologyTags(): (sphere: string) => ITagItem[] {
		return (sphere: string) => {
			return this._sphereTags.filter(tag => tag.group === sphere).map(tag => { return {group: sphere, text: tag.label} })
		}
    }
	
	@Mutation
	clear() {
		this.statusAntecedent = RequestStatus.None
		this.statusSphere = RequestStatus.None
		this._sphereTags = []
		this._antecedentTags = []
	}

	@Mutation
	sphereTagsRequest() {
		this.statusSphere = RequestStatus.Loading
		this._sphereTags = []
	}

	@Mutation
	sphereTagsSuccess(tags: ITag[]) {
		this.statusSphere = RequestStatus.Success
		this._sphereTags = tags
	}

	@Mutation
	sphereTagsFailure() {
		this.statusSphere = RequestStatus.Success
		this._sphereTags = []
	}

	@Mutation
	antecedentTagsRequest() {
		this.statusAntecedent = RequestStatus.Loading
		this._antecedentTags = []
	}

	@Mutation
	antecedentTagsSuccess(tags: ITag[]) {
		this.statusAntecedent = RequestStatus.Success
		this._antecedentTags = tags
	}

	@Mutation
	antecedentTagsFailure() {
		this.statusAntecedent = RequestStatus.Success
		this._antecedentTags = []
	}

	@Action({rawError: true})
	async loadSphereTags(): Promise<any> {
		if (this.statusSphere === RequestStatus.Loading) return

		this.sphereTagsRequest()

		let service = new TagService()
		return service.getAllTags(TagCategory.Sphere)
		.then(tags => {
			this.sphereTagsSuccess(tags)
			return Promise.resolve(tags)
		})
		.catch(error => {
			this.sphereTagsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadAntecedentTags(): Promise<any> {
		if (this.statusSphere === RequestStatus.Loading) return

		this.antecedentTagsRequest()

		let service = new TagService()
		return service.getAllTags(TagCategory.Antecedent)
		.then(tags => {
			this.antecedentTagsSuccess(tags)
			return Promise.resolve(tags)
		})
		.catch(error => {
			this.antecedentTagsFailure()
			return Promise.reject(error)
		})
	}
}

export const tag = getModule(TagModule)
// export const tag = new TagModule({ store, name: "tag" })