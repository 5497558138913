import Render from '@Views/root/user/office/absence.html'
import { isEqual } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IOffice, ISettings, IBreadcrumbItem } from '@Store/types'
import { InputRangeType } from '@Components/index'
import { breadcrumb, office } from '@Store/modules'

@Render
@Component({})
export class Absence extends Vue {

	suggestion: string = null
	
	@Prop() office: IOffice

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('office/settings') getSettings: (ofiId: string) => ISettings
	// @Getter('office/oldSettings') getOldSettings: (ofiId: string) => ISettings

	get settings(): ISettings {
		return office.setting(this.office.id)
	}
	get oldSettings(): ISettings {
		return office.oldSettings(this.office.id)
	}
	get rangeType(): InputRangeType {
		return InputRangeType.RangeDateTime
	}

	mounted() {
		this.$emit('navigate', 'absence-office')
		this.initializeBreadcrumb()
	}
	
	@Watch('settings', {deep: true})
	updateSettings() {
		if (!this.settings) return

		// On retire les horaires en cours si finalement ils sont restaurés
		this.office.settings.currents = []

		if (this.oldSettings.id !== this.settings.id || isEqual(this.oldSettings, this.settings)) return

		this.settings['update'] = true
		this.office.settings.currents.push(this.settings)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.absence').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}