export const reminder = {
    title: "Paramètres du rappel de rendez-vous",
    'remaining-characters': "{count} caractère restant|{count} caractère restant|{count} caractères restants",
    error: {
        // 'no-stop': 'La mention "STOP au XXXXX" est obligatoire.',
        'too-long': "Votre texte de rappel de rendez-vous par SMS est trop long."
    },
    type: {
        title: "Sélectionnez le type d'envoi de vos rappels de rendez-vous",
        label: "Type d'envoi du rappel de rdv"
    },
    'details-mail': {
        title: "Détails du mail de rappel de rendez-vous",
        text: "Un mail de rappel sera envoyé à votre patient {0}. Vous avez la possibilité d'ajouter un texte complémentaire à votre mail. Vous pouvez y indiquer : des consignes spécifiques, un code d'accès, etc. Notez que dans le mail de base, l'heure et le lieu du rdv seront déjà indiqués.",
        strong: "24 heures avant son rendez-vous"
    },
    'details-sms': {
        title: "Détails du SMS de rappel de rendez-vous",
        text1: {
            content: "Vous pouvez modifier le texte ci-dessous, 160 caractères maximum, que vos patients recevront {0} avant leur rendez-vous, par SMS.",
            strong: "1 jour"
        },
        text2: {
            content: "Les mentions {0} et {1} seront remplacées respectivement par la date et l'heure du rendez-vous. Veillez à bien les saisir sous ce format.",
            strong1: "jj/mm/aaaa",
            strong2: "hh:mm"
        },
        text3: "Vous pouvez également saisir des mots clés qui seront automatiquement remplacés par la valeur associée en fonction du cabinet :",
        list: {
            item1: {
                content: "{0} : l'adresse complète du cabinet.",
                strong: "{cabinet.adresse}"
            },
            item2: {
                content: "{0} : le code postal de la ville du cabinet.",
                strong: "{cabinet.code_postal}"
            },
            item3: {
                content: "{0} : la ville du cabinet.",
                strong: "{cabinet.ville}"
            }
        },
        text4: {
            content: "{0} si le message venait à dépasser les 160 caractères, il ne serait pas envoyé. Vérifiez bien le nombre de caractères nécessaire pour ces mots clés.",
            strong: "Attention :"
        }
    },
    sms: {
        balance: {
            content: "Votre solde actuel : {0}",
            strong: "{sms-count} SMS"
        },
        title: "Acquérir des SMS",
        label: "acquérir des SMS"
    },
    'text-sms': {
        label: "Texte du SMS de rappel de rdv ({length})",
        placeholder: "ex : Votre ostéopathe vous rappelle votre rdv le…",
        title: "Veuillez renseigner le texte de votre SMS de rappel"
    },
    'text-mail': {
        label: "Texte complémentaire du mail de rappel de rdv",
        placeholder: "ex : Venez avec votre masque et votre cape…",
        title: "Vous pouvez renseigner un texte complémentaire pour votre mail de rappel"
    },
}