import { IAccountingFilter, IAccountingSubFilter, IInvoice, IBookFilter } from '@Store/types'
import { SpendType, InvoiceType, DampingType, ReceiptType, PaymentMode } from '@Enums/index'
import { Recurring } from './recurring'

export class Accounting {

  public static createNewBookFilter(): IBookFilter {
		return {day_date: new Date(), ofi_id: '-1'}
	}

	public static createNewFilter(monthly: boolean): IAccountingFilter {
		let start: Date = new Date()
		let end: Date = new Date()
		if (monthly) {
			start.setDate(1)
			end.setDate(1)
			end.setMonth(end.getMonth() + 1)
			end.setDate(end.getDate() - 1)
		} else {
			start.setDate(1)
			start.setMonth(0)
			end.setDate(1)
			end.setMonth(0)
			end.setFullYear(end.getFullYear() + 1)
			end.setDate(end.getDate() - 1)
		}

		return {start: start, end: end, ofi_id: '-1', usr_id: '-1'}
	}

	public static createNewSubFilter(): IAccountingSubFilter {
		return {
			number: '',
			label: '',
			bank: '',
			modes: [],
			types: [],
			receiptTypes: []
		}
	}

	public static createNewReceipt(usrId: string, ofiId: string, conId?: string, patId?: string): IInvoice {
		return {
			id: '-1',
			mode: PaymentMode.Check,
			type: InvoiceType.Receipt,
			receipt_type: ReceiptType.Receipt1,
			number: "",
			label: "",
			settlement_date: new Date(),
			ofi_id: !!ofiId ? ofiId : '-1',
			con_id: !!conId ? conId : '-1',
			pat_id: !!patId ? patId : '-1',
			usr_id: usrId,
			checked: true, 
			banked: false,
			ttc: 0,
			tva: 0,
			retrocession: 0,
			mode_retrocession: PaymentMode.Check,
			bank: ''
		}
	}

	public static createNewSpend(usrId: string, ofiId: string): IInvoice {
		let settlementDate: Date = new Date()
		return {
			id: '-1',
			mode: PaymentMode.Check,
			type: InvoiceType.Spend,
			spend_type: SpendType.SpendType0,
			number: "",
			label: "",
			settlement_date: settlementDate,
			ofi_id: !!ofiId ? ofiId : '-1',
			usr_id: usrId,
			ttc: 0,
			tva: 0,
			from_rec_id: null,
			recurring: Recurring.createNewRecurring(settlementDate),
			recurring_update: false
		}
	}

	public static createNewImmobilization(usrId: string, ofiId: string): IInvoice {
		return {
			id: '-1',
			mode: PaymentMode.Check,
			type: InvoiceType.Immobilization,
			damping_type: DampingType.Linear,
			damping_rate: 1,
			number: "",
			label: "",
			settlement_date: new Date(),
			ofi_id: !!ofiId ? ofiId : '-1',
			usr_id: usrId,
			ttc: 0,
			tva: 0
		}
	}
}