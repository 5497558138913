<div class="grid-2" v-if="!!coordinates">
    <input-frame type="text" ref="title" id="title" v-model="coordinates.name" class="grid-item-full"
        :label="$t('vm.root.user.office.coordinates.title.label')"
        :error-test="hasNameError" :error-msg="$t('vm.root.user.office.coordinates.title.error-msg')"
        :placeholder="$t('vm.root.user.office.coordinates.title.placeholder')"
        :title="$t('vm.root.user.office.coordinates.title.title')"/>

    <input-address-frame id="address" :label="$t('vm.root.user.office.coordinates.address')" :address="coordinates.address" class="grid-item-full"/>
    
    <input-phone-frame id="phone-1" :phone="coordinates.phone1"
        :label="$t('vm.root.user.office.coordinates.phone-1.label')" 
        :error-test="coordinates.phone1.error" :error-msg="$t('vm.root.user.office.coordinates.phone-1.error-msg')"
        :title="$t('vm.root.user.office.coordinates.phone-1.title')"/>

    <input-phone-frame id="phone-2" :phone="coordinates.phone2"
        :label="$t('vm.root.user.office.coordinates.phone-2.label')" 
        :error-test="coordinates.phone2.error" :error-msg="$t('vm.root.user.office.coordinates.phone-2.error-msg')"
        :title="$t('vm.root.user.office.coordinates.phone-2.title')"/>

    <dropdown-frame id="currency" v-model="coordinates.currency" :items="currencyItems"
        :label="$t('vm.root.user.office.coordinates.currency.label')"
        :title="$t('vm.root.user.office.coordinates.currency.title')"/>
    <input-frame type="number" id="tva" v-model="coordinates.tva" step="0.1" min="0" max="100"
        :label="$t('vm.root.user.office.coordinates.tva.label')" 
        :placeholder="$t('vm.root.user.office.coordinates.tva.placeholder')" 
        :title="$t('vm.root.user.office.coordinates.tva.title')"/>

    <dropdown-frame id="mode" v-model="coordinates.mode" :items="modeItems"
        :label="$t('vm.root.user.office.coordinates.mode.label')"
        :title="$t('vm.root.user.office.coordinates.mode.title')"/>
    <dropdown-frame id="timezone" v-model="coordinates.timezone" :items="timezoneItems"
        :label="$t('vm.root.user.office.coordinates.timezone.label')"
        :title="$t('vm.root.user.office.coordinates.timezone.title')"/>

</div>