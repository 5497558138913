import { BaseService } from '../base.service'
import { Profile } from '@Enums/profile'
import { Provider } from '@Enums/provider'
import { StorageHelper } from '@Helpers/storage'

export class AccountService extends BaseService {
	
	public getAccountList(): Promise<any> {
		return super.get('/accounts')
	}

	public verify(email: string, profile: Profile, provider: Provider): Promise<any> {
		return super.get(`/account/verify/${email}`, {profile: profile, provider: provider})
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur pendant la vérification de l'adresse mail."
			if (!error) return Promise.reject(message)

			switch(error.status) {
				case 412:
					message = "L'adresse email est invalide !"
					break
				case 409:
					message = "Vous ne pouvez co-travailler avec vous-même. Voyons !"
					break
				case 401:
					message = "Vous avez été déconnecté !"
					StorageHelper.set('error', message)
					break
			} 
			return Promise.reject(message)
		})
	}

	public save(account: any): Promise<any> {
		if (parseInt(account.id) === -1) 
			return super.post('/account', account)

		return super.put(`/account/${account.id}`, account)
	}

	public delete(id: string, provider: Provider): Promise<any> {
		if (provider === Provider.None) return super.delete(`/account/${id}`)
		
		return super.delete(`/account/${id}/provider/${provider}`)
	}
}
