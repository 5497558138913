<div v-if="!!configuration">

	<div class="childs-space-between marg-v">
		<h1 class="no-marg">{{ $tc('vm.root.parameters.forms.title', forms.currents.length) }}</h1>
		<button type="button" @click="addForm()" class="btn--link-like is-pushed-right"
			:title="$t('vm.root.parameters.forms.create-form.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			{{ $t('vm.root.parameters.forms.create-form.label') }}
		</button>
	</div>

	<draggable :forceFallback="true" v-if="forms.currents && forms.currents.length > 0" tag="div" :list="forms.currents" @change="onChangedForm($event)" handle=".is-handle-form">
		<section v-for="form in forms.currents" :key="getKey(form)" class="custom-form-item draggable draggable-frame is-with-border pad-v">
			<div class="childs-space-between">
				<h2 class="text-md no-marg">
					<button type="button" class="btn--reset text-default draggable-handler is-handle-form"
						:title="$t('vm.root.parameters.forms.move-form.title')">
						<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
						</svg>
						<span class="sr-only">{{ $t('vm.root.parameters.forms.move-form.label') }}</span>
					</button>

					<button type="button" @click="toggle(form.id, form.createId)" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(form.id, form.createId)}"
						:title="isToggle(form.id, form.createId) ? $t('vm.root.parameters.forms.move-form.less') : $t('vm.root.parameters.forms.move-form.more')">
						<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
						</svg>
						{{form.name}}
					</button>
				</h2>
				<div class="is-pushed-right">
					<span class="text-italic">{{ $t('general.for') }} {{ form.type | form }}</span> |
					<button type="button" class="btn--link-like text-danger" @click="confirmDeleteForm(form)"
						:title="$t('vm.root.parameters.forms.delete-form.title')">
						{{ $t('vm.root.parameters.forms.delete-form.label') }}
					</button>
				</div>
			</div>

			<div v-if="isToggle(form.id, form.createId)">
				<div :class="{'grid-2': isNewForm(form)}">
					<input-frame :id="`name-${form.id}`" v-model="form.name" @input="updateForm(form)"
						:label="$t('vm.root.parameters.forms.form-name.label')"
						:title="$t('vm.root.parameters.forms.form-name.title')"/>
					<dropdown-frame :id="`type-${form.id}`" v-if="isNewForm(form)" v-model="form.type" :items="formTypes" @input="updateForm(form)"
						:label="$t('vm.root.parameters.forms.form-target.label')"
						:title="$t('vm.root.parameters.forms.form-target.title')"/>
				</div>

				<div class="childs-space-between marg-v">
					<h3 class="text-rg no-marg">{{ $t('vm.root.parameters.forms.question-list') }}</h3>
					<button type="button" @click="addQuestion(form)" class="btn--link-like is-pushed-right"
						:title="$t('vm.root.parameters.forms.create-question.title')">
						<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
						</svg>
						{{ $t('vm.root.parameters.forms.create-question.label') }}
					</button>
				</div>

				<draggable :forceFallback="true" v-if="form.questions.currents && form.questions.currents.length > 0" tag="div" :list="form.questions.currents" @change="onChangedQuestion($event, form)" handle=".is-handle-question">
					<section v-for="(question, idx) in form.questions.currents" class="collapsible-panel bordered-panel marg-v draggable draggable-frame is-with-border" :key="getKey(question)">

						<div :id="`question-${question.id}`" class="childs-space-between">
							<h4 class="no-marg">
								<button type="button" class="btn--reset text-default draggable-handler is-handle-question" :title="$t('vm.root.parameters.forms.move-question.title')">
									<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
										<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
									</svg>
									<span class="sr-only">{{ $t('vm.root.parameters.forms.move-question.label') }}</span>
								</button>
								<button type="button" @click="toggle(question.id, question.createId)"
									class="collapsible-panel-trigger btn--link-like" :class="{'is-active': isToggle(question.id, question.createId)}"
									:title="isToggle(question.id, question.createId) ? $t('vm.root.parameters.forms.move-question.less') : $t('vm.root.parameters.forms.move-question.more')">
									<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
										<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
									</svg>
									{{idx + 1}}. {{question.title}}
								</button>
							</h4>
							<div class="is-pushed-right">
								<span class="text-italic">{{question.type | question}}</span> |
								<button type="button" @click="confirmDeleteQuestion(form, question)" class="btn--link-like text-danger is-pushed-right"
									:title="$t('vm.root.parameters.forms.delete-question.title')">
									{{ $t('vm.root.parameters.forms.delete-question.label') }}
								</button>
							</div>
						</div>

						<template v-if="isToggle(question.id, question.createId)">

							<div class="grid-2">
								<input-frame :id="`title-${question.id}`" v-model="question.title" @input="updateQuestion(form, question)"
									:label="$t('vm.root.parameters.forms.question-title.label')"
									:title="$t('vm.root.parameters.forms.question-title.title')"/>
								<dropdown-frame :id="`type-${question.id}`" v-model="question.type" :items="questionTypes" @input="updateQuestion(form, question)"
									:label="$t('vm.root.parameters.forms.question-type.label')"
									:title="$t('vm.root.parameters.forms.question-type.title')"/>
							</div>

							<template v-if="hasChoice(question)">
								<hr>

								<div class="childs-space-between marg-v">
									<h5 class="text-rg no-marg">{{ $t('vm.root.parameters.forms.choice-list') }}</h5>
									<button type="button" @click="addChoice(question)" class="btn--link-like is-pushed-right"
										:title="$t('vm.root.parameters.forms.create-choice.title')">
										<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
										</svg>
										{{ $t('vm.root.parameters.forms.create-choice.label') }}
									</button>
								</div>

								<draggable :forceFallback="true" v-if="question.choices.currents.length > 0" tag="div" class="ul-reset grid-3" :list="question.choices.currents" @change="onChangedChoice($event, question)" handle=".is-handle-choice">
									<li v-for="(choice, idx) in question.choices.currents" class="draggable" :key="getKey(choice)">

										<div class="input-frame">
											<label class="label" :for="`label-${choice.id}`" :data-text="$t('vm.root.parameters.forms.choice-label.label', {index: choice.order + 1})">
												<span>{{ $t('vm.root.parameters.forms.choice-label.label', {index: choice.order + 1}) }}</span>
											</label>

											<div class="form-group draggable-frame">
												<button type="button" class="input-like is-narrow-padding-h form-group-input is-flex-fixed draggable-handler is-handle-choice"
													:title="$t('vm.root.parameters.forms.move-choice.title')">
													<svg width="1em" class="svg-block" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
														<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
													</svg>
													<span class="sr-only">{{ $t('vm.root.parameters.forms.move-choice.label') }}</span>
												</button>

												<input type="text" class="form-group-input" :id="`label-${choice.id}`" :ref="`label-${choice.id}`"
													v-model="choice.label" @input="updateChoice(form, question, choice)"
													:placeholder="$t('vm.root.parameters.forms.choice-label.placeholder')"
													:title="$t('vm.root.parameters.forms.choice-label.title')"/>

												<button type="button" class="input-like text-danger form-group-input is-flex-fixed" @click="confirmDeleteChoice(question, choice, idx)"
													:title="$t('vm.root.parameters.forms.delete-choice.title')">
													<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
														<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"></use>
													</svg>

													<span class="sr-only">{{ $t('vm.root.parameters.forms.delete-choice.label') }}</span>
												</button>
											</div>

										</div>
									</li>
								</draggable>

								<i18n v-else path="vm.root.parameters.forms.no-choice.content" tag="p">
									<button type="button" @click="addChoice(question)" class="btn--link-like" :title="$t('vm.root.parameters.forms.no-choice.button-title')">
										{{ $t('vm.root.parameters.forms.no-choice.button-label') }}
									</button>
								</i18n>
							</template>

						</template>

					</section>
				</draggable>

				<i18n v-else path="vm.root.parameters.forms.no-question.content" tag="p">
					<button type="button" @click="addQuestion(form)" class="btn--link-like" :title="$t('vm.root.parameters.forms.no-question.button-title')">
						{{ $t('vm.root.parameters.forms.no-question.button-label') }}
					</button>
				</i18n>

			</div>
		</section>
	</draggable>

	<div v-else class="marg-20 text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#cog"/>
		</svg>
		<p>
			{{ $t('vm.root.parameters.forms.no-form.content') }}<br>
			<button type="button" @click="addForm()" class="btn--link-like" :title="$t('vm.root.parameters.forms.no-form.button-title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.parameters.forms.no-form.button-label') }}
			</button>
		</p>
	</div>
</div>
