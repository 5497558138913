export const recurring = {
    label: "Récurrence",
    cycleLabel: "Répéter toutes les|Répéter tous les|Répéter tous les",
    repeatEach: "Répéter chaque",
    dayOfMonth: "jour du mois",
    dayOfWeek: "jour de la semaine",
    lastDayOfMonth: "dernier jour du mois",
    until: "jusqu'au",
    everyday: "tous les jours",
    the: "le | les",
    rank: "er | ème",
}