import Render from '@Views/root/patients/patient/timeline/timeline-item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ITimelineItem, IColor } from '@Store/types'
import { FormSummary } from '@Components/forms/form-summary'
import { ReadOnly } from '../consultation/read-only'
import { TimelineItemType, FollowUpLevel, Gender } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { configuration, patient } from '@Store/modules'

@Render
@Component({
	components: {
		'form-summary': FormSummary,
		'read-only': ReadOnly
	}
})
export class TimelineItem extends Vue {

	@Prop() item: ITimelineItem
	@Prop() gender: Gender
	@Prop() query: string

	//#region Getters
	get style(): string {
		if (!this.color) return ''

		return `--color:${this.color};`
	}
	get color(): string {
		switch (this.item.type) {
			case TimelineItemType.Antecedent:
				return this.colorManager && this.colorManager.badge_antecedent
			case TimelineItemType.Attachment:
				return this.colorManager && this.colorManager.badge_attachment
			case TimelineItemType.Sphere:
				return this.colorManager && this.colorManager.badge_sphere
			default:
				return this.colorManager && this.colorManager.badge_consultation
		}
	}
	get colorManager(): IColor {
		return configuration.configuration && configuration.configuration.colors
	}
	get isAntecedentItem(): boolean {
		return this.item.type === TimelineItemType.Antecedent
	}
	get isAttachmentItem(): boolean {
		return this.item.type === TimelineItemType.Attachment
	}
	get isConsultationItem(): boolean {
		return this.item.type === TimelineItemType.Consultation
	}
	get isSphereItem(): boolean {
		return this.item.type === TimelineItemType.Sphere
	}
	get hasFollowUp(): boolean {
		return !!this.item.follow_up && this.item.follow_up.read
	}
	get isNotSatisfiedLevel(): boolean {
		return !!this.item.follow_up && this.item.follow_up.level === FollowUpLevel.NotSatisfied
	}
	get isSatisfiedLevel(): boolean {
		return !!this.item.follow_up && this.item.follow_up.level === FollowUpLevel.Satisfied
	}
	get isNoneLevel(): boolean {
		return !!this.item.follow_up && this.item.follow_up.level === FollowUpLevel.None
	}
	get classLevel(): any {
		return {'text-danger': this.isNotSatisfiedLevel, 'text-success': this.isSatisfiedLevel}
	}
	get iconLevel(): string {
		return this.isNotSatisfiedLevel ? 'frown' : (this.isSatisfiedLevel ? 'smile-beam' : 'meh')
	}
	get owner(): string {
		return patient.owner(this.item)
	}
	//#endregion

	getMember(): string {
		return EnumsHelper.memberToString(this.item.member.type, this.item.member.gender)
	}

	toggle() {
		this.item.active = !this.item.active
		this.$forceUpdate()
	}
}
