import Render from '@Views/components/frame/input-color-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InputColor } from '@Components/input/input-color'

@Render
@Component({
    components: {
        'input-color': InputColor
    }
})
export class InputColorFrame extends Vue {

    @Prop() id: string
    @Prop() title: string
    @Prop() label: string
    @Prop() placeholder: string
    @Prop() value: string

    handleInput(e: any) {
        this.$emit('input', e)
    }
}