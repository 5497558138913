import { IStatisticsFilter } from '@Store/types'

export class Statistics {

	public static createNewFilter(): IStatisticsFilter {
		let date: Date = new Date()
		let year: number = date.getFullYear()

		return {
			usr_id: '-1',
			ofi_id: '-1',
			pla_id: '-1',
			year1: year - 1,
			year2: year,
			week: 1,
			query: '',
			months: [true, true, true, true, true, true, true, true, true, true, true, true]
		}
	}

	public static getTotalSeries(legend: string, element: any): number {
		if (!element) return 0
		let result: number = 0
		for(let i: number = 0; i < element.data.length; i++) {
			result += element.data[i]
		}
		return result
	}

}
