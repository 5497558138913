import Render from '@Views/components/forms/form-summary.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IForm, IQuestion } from '@Store/types'
import { FormHelper } from '@Helpers/index'
import { QuestionType } from '@Enums/index'

@Render
@Component({})
export class FormSummary extends Vue {

    @Prop() form: IForm

    get isEmpty(): boolean {
        return FormHelper.isEmpty(this.form)
    }

    isEmptyQuestion(question: IQuestion): boolean {
        return FormHelper.isEmptyQuestion(question)
    }

    isChoiceQuestion(question: IQuestion): boolean {
        return question.type === QuestionType.Multiple_Choice || question.type === QuestionType.Single_Choice
    }
}