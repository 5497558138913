import Render from '@Views/app.html'
import { VueCookies } from 'vue-cookies'
import { Vue, Component } from 'vue-property-decorator'
import { Notification, Popin, SuperPanel } from '@Components/index'
import config from "@Config/index"
import { Lang } from '@Enums/lang'
import { LangHelper } from '@Helpers/lang'
import { StorageHelper } from '@Helpers/storage'
import { login, notif, user } from '@Store/modules'
import { INotification, IUser } from '@Store/types'

@Render
@Component({
	components: {
		'notification': Notification,
		'popin': Popin,
		'super-panel': SuperPanel
	}
})
export default class App extends Vue {
	name:string = 'app'

	created() {
		// this.manageKonamiCode()
		this.updateLocale()

		let session_id: string | false = this.$cookies.get('session_id_osteo2ls')
		if (!session_id && config.use_storage) {
			session_id =  StorageHelper.get('session_id_osteo2ls', false)
		}
		if (session_id) {
			login.isLoggedIn()
			.then((_user: IUser) => {
				user.initialize(_user)
				this.$router.push({name: 'loader'})
			})
			.catch(() => {
				let notification:INotification = { message: this.$t('vm.root.main.logout').toString(), actions: [], delay: 5000 }
				notif.error(notification)
				this.$router.push({name: 'login'})
			})
		} else if (this.$router.currentRoute.name !== 'login' && this.$router.currentRoute.name !== 'register') {
			this.$router.push({name: 'login'})
		}
	}

	// private manageKonamiCode() {
	// 	const code:number[] = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]
  	// 	const length: number = code.length
	// 	let index: number = 0
	// 	document.addEventListener('keydown', function (event) {
	// 		if (event.keyCode !== code[index++]) {
	// 			index = 0
	// 			return
	// 		}
	// 		if (length === index) {
	// 			//options.callback()
	// 			console.log('konamiiiiiii')
	// 			index = 0 // ability to start over
	// 			return
	// 		}
	// 	}, false)
	// }

	private updateLocale() {
		let lang:Lang = StorageHelper.get('lang', Lang.French)
		
		LangHelper.updateLang(lang)
	}
}