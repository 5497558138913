import * as CoWorking from '@ViewModels/root/co-working'
import * as CoWorkingSteps from '@ViewModels/root/co-working/steps'
import { Profile } from '@Enums/profile'

export const CoWorkingRoot = {
	path: 'co-working', 
	name: 'co-working',
	component: CoWorking.Main,
	redirect: { name: 'share-step-one' },
	meta: { permission: [Profile.Main, Profile.Demo] },
	children: [
		{
			path:'',
			name:'share-step-one',
			component: CoWorkingSteps.StepOne,
			meta: { permission: [Profile.Main, Profile.Demo] }
		},
		{
			path:'step-2',
			name:'share-step-two',
			component: CoWorkingSteps.StepTwo,
			meta: { permission: [Profile.Main, Profile.Demo] }
		},
		{
			path:'step-3',
			name:'share-step-three',
			component: CoWorkingSteps.StepThree,
			meta: { permission: [Profile.Main, Profile.Demo] }
		}
	]
}