<container>
	<aside-planning/>

	<master>

		<central>
			<div class="wrapper marg-v">
				<form @submit.prevent="submitSave">
					<h2 class="text-uppercase-first-letter">{{ !!planning.name ? planning.name : $t('vm.root.calendar.planning.main.default-name') }}</h2>

					<div class="grid-2">

						<input-frame :auto-focus="true" type="text" id="label" v-model="planning.name"
							:label="$t('vm.root.calendar.planning.main.name.label')"
							:error-test="hasNameError" :error-msg="$t('vm.root.calendar.planning.main.name.error')"
							:placeholder="$t('vm.root.calendar.planning.main.name.placeholder')"
							:title="$t('vm.root.calendar.planning.main.name.title')"/>

						<input-color-frame v-model="planning.color"
							:title="$t('vm.root.calendar.planning.main.color.title')"
							:label="$t('vm.root.calendar.planning.main.color.label')"/>

						<div class="input-frame grid-item-full">
							<label class="label" for="url" :data-text="$t('vm.root.calendar.planning.main.url.label')">
								{{ $t('vm.root.calendar.planning.main.url.label') }}
							</label>
							<div>
								<div class="input-address-field">
									<div class="form-group">
										<div>
											<input class="form-group-input" type="text" v-model="planning.url" id="url"
												:title="$t('vm.root.calendar.planning.main.url.title')" :readonly="true">
										</div>
										<button type="button" id="copy-url" class="input-like form-group-input is-flex-fixed misc-trigger"
											:disabled="!planning.url"
											:title="$t('vm.root.calendar.planning.main.copy-url.title')" @click="copy()">
											<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#copy"/>
											</svg>
											<span class="rwd-over-medium">{{ $t('vm.root.calendar.planning.main.copy-url.label') }}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<control-bar :class="[isNewPlanning ? 'text-right' : 'childs-space-between']">
						<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || isDeleting || disabled"
							:title="$t('general.cancel.title')" v-if="!isNewPlanning">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
							</svg>
							{{ $t('general.cancel.text') }}
						</button-action>

						<button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled || isDeleting"
							:title="$t('general.save.title')">
							{{ $t('general.save.text') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							</svg>
						</button-action>
					</control-bar>
				</form>
			</div>
		</central>

		<context-main/>

	</master>

</container>
