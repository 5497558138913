import { StorageHelper } from "@Helpers/storage"

interface IToggle {
    className: string,
    selector?: string,
    reducer?: string,
    state?: string
}

let getState = function(state: string) {
    if (!state) return true

    return StorageHelper.get(`${state}_reduce`, false)
}

let setState = function(state: string) {
    if (!state) return

    let deactivate = getState(state)
    StorageHelper.set(`${state}_reduce`, !deactivate)
}

let toggleElement = function(element: any, className: string, withTransition: boolean) {
    if (!withTransition) {
        element.classList.add('is-no-transition')
    }

    let classes: string[] = (className || '').split(' ')
    classes.forEach(singleClass => { element.classList.toggle(singleClass) })

    if (!withTransition) {
        setTimeout(() => { element.classList.remove('is-no-transition') }, 500)
    }
}

let deactivateElement = function(element: any, className: string) {
    element.classList.add('is-no-transition')

    let classes: string[] = (className || '').split(' ')
    classes.forEach(singleClass => {
        if (element.classList.contains(singleClass)) {
            element.classList.remove(singleClass)
        }
    })

    setTimeout(() => { element.classList.remove('is-no-transition') }, 500)
}

let activateElement = function(element: any, className: string) {
    element.classList.add('is-no-transition')

    let classes: string[] = (className || '').split(' ')
    classes.forEach(singleClass => {
        if (!element.classList.contains(singleClass)) {
            element.classList.add(singleClass)
        }
    })

    setTimeout(() => { element.classList.remove('is-no-transition') }, 500)
}

let initialize = function(el: any, binding: any) {
    if (!binding.modifiers.main) return
    
    let value: IToggle = binding.value
    let deactivate = getState(value.state)

    initalizeAction(el, binding, deactivate)
}

let toggleAction = function(el: any, binding: any, saveState: boolean = true) {
    let value: IToggle = binding.value
    if (!!value.selector) {
        document.querySelectorAll(value.selector)
        .forEach( element => { 
            toggleElement(element, value.className, saveState)
        })
    }
    if (!!value.reducer) {
        document.querySelectorAll(value.reducer)
        .forEach( element => { 
            deactivateElement(element, value.className)
        })
    }

    if (!!binding.modifiers.self) {
        toggleElement(el, value.className, saveState)
    }

    if (!saveState) return

    setState(value.state)
}

let initalizeAction = function(el: any, binding: any, deactivate: boolean) {
    let value: IToggle = binding.value
    if (!!value.selector) {
        document.querySelectorAll(value.selector)
        .forEach( element => { 
            if (deactivate) deactivateElement(element, value.className)
            else activateElement(element, value.className)
        })
    }

    if (!!binding.modifiers.self) {
        if (deactivate) deactivateElement(el, value.className)
        else activateElement(el, value.className)
    }
}

export const toggle = {
    unbind(el: any, binding: any): void {
        el.removeEventListener('click', () => { toggleAction(el, binding) })
    },
    inserted(el: any, binding: any): void {
        initialize(el, binding)
        el.addEventListener('click', (event: any) => {
            event.preventDefault()
            toggleAction(el, binding)
        })
    }
}
