import Render from '@Views/root/user/referral.html'
import { Vue, Component } from 'vue-property-decorator'
import {IReferralGift, IPopinAction, IPopin, IBreadcrumbItem, INotification, IUser} from '@Store/types'
import { PackType } from '@Enums/index'
import { breadcrumb, notif, popIn, referral, user } from '@Store/modules'

@Render
@Component({})
export class Referral extends Vue {

	private gift: IReferralGift = null

	//#region Getters
	get gifts(): IReferralGift[] {
		return referral.gifts
	}
	get code(): string {
		return user.currentUser.referral_code
	}
	get point(): number {
		return user.currentUser.point_count || 0
	}
	get isApplying(): boolean {
		return referral.isApplying
	}
	get isGodfather(): boolean {
		return user.currentUser.license === -1 && !!this.code
	}
	get to(): {name: string, params: {pack: string}} {
		return {name: 'purchase-pack', params: {pack: PackType.Unlimited.toString()}}
	}
	private get shareText(): string {
		return this.$i18n.t('vm.root.user.referral.default-share-text', {code: this.code}).toString()
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'referral')
		this.initializeBreadcrumb()
	}

	shareBySocialNetwork(href: string, text: string): void {
		let link: string = `${href}https://app.osteo2ls.com/login/register/${this.code}&${text}=${this.shareText}`
		let top: number = window.outerHeight / 2 - 275
		let left: number = window.outerWidth / 2 - 225
		window.open(link, '', `height=450, width=550, top=${top}, left=${left}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`)
	}

	shareByEmail() {
		let shareAction: IPopinAction = {
			label: this.$i18n.t('vm.root.user.referral.share-by-email.action-label').toString(),
			title: this.$i18n.t('vm.root.user.referral.share-by-email.action-title').toString(),
			callback: (data: {text: string, email: string}, updateLoading: any) => {
				if (!data.text || !data.email) return Promise.reject()

				updateLoading(true)
				return referral.sendReferral({email: data.email, text: data.text})
				.then(() => {
					let notification: INotification = {message: this.$i18n.t('vm.root.user.referral.send-referral.succeeded', {email: data.email}).toString(), actions:[], delay: 5000, canClose: true}
					notif.success(notification)
					updateLoading(false)
					return Promise.resolve()
				})
				.catch(() => {
					let notification: INotification = {message: this.$i18n.t('vm.root.user.referral.send-referral.failed', {email: data.email}).toString(), actions:[], delay: 5000, canClose: true}
					notif.error(notification)
					updateLoading(false)
					return Promise.reject()
				})
			},
			condition: (data: {text: string, email: string}) => {
				return !!data.text && !!data.email
			}

		}

		let share: IPopin = {
			title: this.$i18n.t('vm.root.user.referral.share-by-email.action-title').toString(),
			content: {component: 'popin-share-referral'},
			action: shareAction,
			options: {text: this.shareText, email: ''}
		}

		popIn.info(share)
	}

	isApplyingGift(gift: IReferralGift): boolean {
		if (!this.gift) return false
		if (!this.isApplying) return false

		return this.gift.type === gift.type && this.gift.value === gift.value
	}

	apply(gift: IReferralGift): void {
		this.gift = gift
		referral.applyGift(gift)
		.then(() => {
			let additional: string = this.$i18n.tc('vm.root.user.referral.apply-gift.additional', gift.value).toString()
			let notification: INotification = {message: this.$i18n.t('vm.root.user.referral.apply-gift.success', {gift: this.$options.filters.giftPack(gift.type, gift.value), additional: additional}).toString(), actions:[], delay: 5000, canClose: true}
			notif.success(notification)
		})
		.catch((error) => {
			let notification: INotification = {message: this.$i18n.t('vm.root.user.referral.apply-gift.default-error').toString(), actions:[], delay: 5000, canClose: true}
			if (!!error && error.status === 406) {
				notification = {message: this.$i18n.t('vm.root.user.referral.apply-gift.not-enough-error').toString(), actions:[], delay: 5000, canClose: true}
			} else if (!!error && error.status === 412) {
				notification = {message: this.$i18n.t('vm.root.user.referral.apply-gift.no-gift-error').toString(), actions:[], delay: 5000, canClose: true}
			}
			notif.error(notification)
		})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.referral').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
