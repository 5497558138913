<context>
	<div class="wrapper marg-v">

		<collapsible-help id="context-faq-information" title="Aide" tag="h2" tag-class="h-reset text-bold">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.help.context.faq.text" tag="span">
						<abbr :title="$t('vm.root.help.context.faq.abbr-title')">{{ $t('vm.root.help.context.faq.abbr-label') }}</abbr>
					</i18n>
				</p>
			</div>
		</collapsible-help>

		<div class="notice-info marg-v" v-if="hasValidSupport">
			<p v-if="hasUnlimitedSupport" class="text-left">{{ $t('vm.root.help.context.support-unlimited') }}</p>
			<i18n v-else path="vm.root.help.context.support-enabled" tag="p" class="text-left">
				<strong>{{ $d(supportDate, 'LL') }}</strong>
			</i18n>
		</div>

		<div v-else class="notice-danger marg-v">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				<i18n path="vm.root.help.context.support-disabled.text" tag="span">
					<strong>{{ $d(supportDate, 'LL') }}</strong>
					<router-link :to="to" :title="$t('vm.root.help.context.support-disabled.link-title')">
						{{ $t('vm.root.help.context.support-disabled.link-label') }}
					</router-link>
				</i18n>
			</p>
		</div>

		<div class="p-like">
			<div class="context-search">
				<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" class="context-search-input" v-model="query"
					:placeholder="$t('vm.root.help.context.filter.placeholder')"
					:title="$t('vm.root.help.context.filter.title')">
			</div>
		</div>

		<div v-for="item in items" :key="item.id" class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(item.id)}"
				@click="toggle(item.id)" :title="$t('vm.root.help.context.open-question')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				<span :inner-html.prop="item.title | mark(query)"/>
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(item.id)}">
				<p class="misc-collapsible-content" :inner-html.prop="item.content | mark(query)"/>
			</div>
		</div>
	</div>
</context>
