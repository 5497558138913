import axios from 'axios'
import config from "@Config/index"
import { router } from '@Router/index'
import { DateHelper, StorageHelper } from '@Helpers/index'
import { Connection } from '@Models/connection'
import { INotification } from '@Store/types'

export abstract class BaseService {
	
	private get errorConnection(): any { return {response: {status: 1000}} }

	private options: any = {
		baseURL: `${config.api_url}/${config.api_version}/${config.api_key}/json`,
		withCredentials: true,
		headers: { 'Content-Type': 'application/json' },
		transformResponse: [
			(responseData: any, headers: any) => {
				if (headers['content-type'] !== 'application/json; charset=UTF-8' || responseData === '') {
					return responseData
				}
				try {
					return this.parseJsonResponse(responseData)
				} catch (e) {
					let responseWithoutError = this.getResponseWithoutError(responseData)
					let result = {}
					try {
						result = this.parseJsonResponse(responseWithoutError)
					} catch (ex) {
						result = {}
					}

					console.error(responseData)
					this.sendError(responseData)
					return result
				}
			}
		]
	}

	private specificOptions: any = {
		baseURL: `${config.api_url}/${config.api_version}/${config.api_key}`,
		withCredentials: true,
		headers: { 'Content-Type': 'application/json' }
	}

	private provider: any = axios.create(this.options)
	private specificProvider: any = axios.create(this.specificOptions)

	protected async specificGet(url: string, params?: any): Promise<any> {
		let vm = this
		return this.specificProvider.get(url, {params: params})
		.then((response: any) => {
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error)
		})
	}

	protected async specificPost(url: string, params?: any): Promise<any> {
		let vm = this
		return this.specificProvider.post(url, params)
		.then((response: any) => {
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error)
		})
	}

	protected async get(url: string, params?: any, callback?: (response: any) => void): Promise<any> {
		let vm = this
		return this.provider.get(url, {params: params})
		.then((response: any) => {
			if (callback != null) {
				callback(response)
			}
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error)
		})
	}

	protected async post(url: string, params?: any, callback?: (response: any) => void, without401?: boolean, callbackProgress?: (progress: number) => void): Promise<any> {
		if (!Connection.getInstance().state) return this.manageError(this.errorConnection)

		let vm = this
		return this.provider.post(url, params, {onUploadProgress: uploadEvent => {
			if (!callbackProgress) return
			callbackProgress(Math.round(uploadEvent.loaded / uploadEvent.total * 100))
		}})
		.then((response: any) => {
			if (!!callback) {
				callback(response.data)
			}
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error, without401)
		})
	}

	protected async put(url: string, params?: any, callback?: (response: any) => void, callbackProgress?: (progress: number) => void): Promise<any> {
		if (!Connection.getInstance().state) return this.manageError(this.errorConnection)

		let vm = this
		return this.provider.put(url, params, {onUploadProgress: uploadEvent => {
			if (!callbackProgress) return
			callbackProgress(Math.round(uploadEvent.loaded / uploadEvent.total * 100))
		}})
		.then((response: any) => {
			if (!!callback) {
				callback(response.data)
			}
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error)
		})
	}

	protected delete(url: string, params?: any): Promise<any> {
		if (!Connection.getInstance().state) return this.manageError(this.errorConnection)

		return this.provider.delete(url, params)
	}

	private manageError(error: any, without401?: boolean): Promise<any> {
		// console.error('manageError', error)
		if (error && !!error.response) {
			// if (!!error.response.data) {
			// 	message = error.response.data.error_description
			// }
			if (error.response.status === 405) {
				let notification:INotification = {message: "Le serveur semble indisponible pour le moment. Réessayez plus tard.", actions: [], delay: false, canClose: true}
				return Promise.reject(notification)
			}
			if (error.response.status === 1000) {
				let notification:INotification = {message: "ZzzzZzzz, votre connexion internet semble s'être endormie. Réessayez plus tard.", actions: [], delay: false, canClose: true}
				return Promise.reject(notification)
			}
			if (!without401 && error.response.status === 401) {
				StorageHelper.set('error', "Vous avez été déconnecté !")
				router.push({ name: 'login' })
				return Promise.reject(error.response)
			}
			return Promise.reject(error.response)
		} else if (error && !!error.message) {
			if (error.message.toLowerCase() === "Network Error".toLowerCase()) {
				let notification:INotification = {message: "ZzzzZzzz, votre connexion internet semble s'être endormie. Réessayez plus tard.", actions: [], delay: false, canClose: true}
				return Promise.reject(notification)
			}
		}

		// if (!message) 
		// 	message = "erreur d'authentification"

		return Promise.reject()
	}

	private parseJsonResponse(data: any): any {
		let input = JSON.parse(data)
		DateHelper.convertStringToDate(input)
		return input
	}

	private getResponseWithoutError(responseData: string): string {
		let lastCharacter = responseData.slice(-1)
		let firstCharacter = ''
		if (lastCharacter === ']') {
			firstCharacter = '['
		} else if (lastCharacter === '}') {
			firstCharacter = '{'
		} else {
			return responseData
		}

		let index = responseData.indexOf(firstCharacter)

		return responseData.substring(index)
	}

	sendError(responseData: any): Promise<any> {
		return this.post('/error', {error: responseData})
	}
}