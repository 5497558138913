export class StorageHelper {

    public static getStringSize(str: string): number {
        if (!str) return 0

        let length: number = str.length
        let nonAscii: number = length - str.replace(/[\u0100-\uFFFF]/g, '').length
        
        return StorageHelper.getSize(length + nonAscii)
    }
    public static getKeySize(key: string): number {
        let data = localStorage[key]
        return StorageHelper.getStringSize(data)
    }

    public static get(key: string, defaultValue: any): any {
        let value = localStorage.getItem(key)

        let parseValue = null
        try {
			parseValue = JSON.parse(value)
		} catch(SyntaxError) {
            return defaultValue
        }
		return value === undefined || value === null ? defaultValue : parseValue
    }

    public static set(key: string, value: any): void {
        let compressedValue = JSON.stringify(value)

        localStorage.setItem(key, compressedValue)
    }

    public static remove(key: string) {
        localStorage.removeItem(key)
    }

    public static getSize(length: number): number {
        return parseFloat((length / 1024).toFixed(2))
    }
}
