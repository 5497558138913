<container :loading="!colleague" :loading-title="$t('vm.root.statistics.colleagues.main-colleague.loading-title')">
	<aside-colleague :colleague="colleague" @delete="onDelete"/>

	<master>

		<central v-if="!!colleague">
            <div class="wrapper marg-v">
				<h2><strong>{{ colleague.last_name }}</strong> {{ colleague.first_name }}</h2>
			</div>

			<div class="wrapper marg-v">

                <form @submit.prevent="submitSave">
                    <details-colleague :colleague="colleague" :submitted="submitted"/>

                    <control-bar class="childs-space-between">
                        <button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || isDeleting || disabled" :title="$t('general.cancel.title')">
                            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                            </svg>
                            {{ $t('general.cancel.text') }}
                        </button-action>

                        <button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled || isDeleting" :title="$t('general.save.title')">
                            {{ $t('general.save.text') }}
                            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                                <use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
                            </svg>
                        </button-action>
                    </control-bar>
                </form>

            </div>
		</central>

        <context>
			<context-colleague/>
		</context>

	</master>

</container>
