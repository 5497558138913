import { BaseService } from '@Services/index'
import { DateHelper } from '@Helpers/index'
import { IInvoice, IRemittance } from '@Store/types'

export class AccountingService extends BaseService {

	public getInvoice(invId: string): Promise<any> {
		return super.get(`/invoice/${invId}`)
	}

	public deleteInvoice(invId: string): Promise<any> {
		return super.delete(`/invoice/${invId}`)
	}

	public checkInvoices(list: string[], checked: boolean): Promise<any> {
		return checked ? super.post(`/check/invoices/`, {list: list}) : super.put(`/check/invoices/`, {list: list})
	}

	public bankInvoices(list: string[], banked: boolean): Promise<any> {
		return banked ? super.post(`/bank/invoices/`, {list: list}) : super.put(`/bank/invoices/`, {list: list})
	}

	public deleteInvoices(list: string[]): Promise<any> {
		return super.post(`/delete/invoices/`, {list: list})
	}

	public getInvoiceList(start: Date, end: Date, ofiId: string, usrId: string, withoutRemittance: boolean): Promise<any> {
		let filter = {
			start_date: DateHelper.getDateString(start),
			end_date: DateHelper.getDateString(end),
			usr_id: usrId,
			without_remittance: !!withoutRemittance
		}
		return super.get(`/accounting/${ofiId}/invoices`, filter)
	}

	public getInvoiceListByPatient(patId: string): Promise<any> {
		return super.get(`/accounting/patient/invoices/${patId}`)
	}

	public getRemittance(remId: string): Promise<any> {
		return super.get(`/remittance/${remId}`)
	}

	public deleteRemittance(remId: string): Promise<any> {
		return super.delete(`/remittance/${remId}`)
	}

	public getRemittanceList(): Promise<any> {
		return super.get(`/remittances/`)
	}

	public sendInvoice(invId: string): Promise<any> {
		return super.post(`/send/invoice/${invId}`)
	}

	public sendDocuments(filter: Record<string, string>): Promise<any> {
		return super.post(`/send/documents/`, filter)
	}

	public save(invoice: IInvoice): Promise<any> {
		if (parseInt(invoice.id) === -1)
			return super.post('/invoice', invoice)

		return super.put(`/invoice/${invoice.id}`, invoice)
	}

	public saveRemittance(remittance: IRemittance): Promise<any> {
		if (parseInt(remittance.id) === -1)
			return super.post('/remittance', remittance)

		return super.put(`/remittance/${remittance.id}`, remittance)
	}
}
