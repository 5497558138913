import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { fr } from './fr/index'

Vue.use(VueI18n)

const messages = {
    fr: fr.messages,
    en: {
       message: {
          hello: 'hello world'
       }
    }
}

const dateTimeFormats = {
    fr: fr.dateTimeFormats
}

const numberFormats = {
    fr: {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
        }
    },
    us: {
        currency: {
            style: 'currency', currency: 'USD', currencyDisplay: 'symbol'
        }
    }
}
 
export const i18n = new VueI18n({
    locale: 'fr', // set locale
    numberFormats: numberFormats,
    dateTimeFormats: dateTimeFormats,
    messages: messages, // set locale messages
})
 