import Render from '@Views/root/calendar/valid-event/main.html'
import { Component } from 'vue-property-decorator'
import { ASideValidEvent } from './aside-valid-event'
import { AutoSaverState, Profile, Currency, PhoneType } from '@Enums/index'
import { DateHelper } from '@Helpers/index'
import { Office, User } from '@Models/index'
import { AutoSaver } from '@Components/index'
import { ContextEvent } from '@ViewModels/root/calendar/event/context-event'
import { PatientItem, SearchPatient } from '@ViewModels/root/search/patient'
import { breadcrumb, event, office, planning, popIn, user } from '@Store/modules'
import {
	IBreadcrumbItem,
	IEvent,
	ICustomer,
	EventRefusePayload,
	IOffice,
	ISession,
	ISettings,
	IPatient,
	IPopin,
	IPopinAction,
	ILinkUser,
	INotification
} from '@Store/types'

@Render
@Component({
	components: {
		'aside-valid-event': ASideValidEvent,
		'context-event': ContextEvent,
		'patient-item': PatientItem,
		'search-patient': SearchPatient
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	constructor() {
		super('event', false)
	}

	phoneUpdate: boolean = true
	emailUpdate: boolean = true

	//#region Getters
	get event(): IEvent {
		return event.event
	}
	get notification(): INotification {
		if (!this.event.description) return undefined
		return {
			canClose: true,
			delay: false,
			message: this.$i18n.t('vm.root.calendar.valid-event.main.notification', {patient: this.event.description}).toString(),
			actions: []
		}
	}
	get customer(): ICustomer {
		return !!this.event && this.event.customer
	}
	get patient(): IPatient {
		return !!this.event && this.event.patient
	}
	get office(): IOffice {
		return !!this.event && office.office(this.event.ofi_id)
	}
	get session(): ISession {
		return !!this.event && office.sessions(this.event.ofi_id).find(session => session.id === this.event.ses_id)
	}
	get currency(): string {
		return !!this.office && this.office.coordinates.currency === Currency.Dollar ? '$' : '€'
	}
	get date(): string {
		return DateHelper.getDateString(!this.event ? undefined : new Date(this.event.start_date))
	}
	get isRefusing(): boolean {
		return event.isRefusing
	}
	get isConfirming(): boolean {
		return event.isConfirming
	}
	get isPhoneUpdated(): boolean {
		return (this.event?.patient?.coordinates?.phone1?.number !== this.customer?.phone)
	}
	get isEmailUpdated(): boolean {
		return (this.event?.patient?.coordinates?.email !== this.customer?.email)
	}
	get isUpdated(): boolean {
		return !!this.event.patient && (this.isPhoneUpdated || this.isEmailUpdated)
	}
	private get settings(): ISettings {
		return office.settings.find(s => s.ofi_id === this.event.ofi_id) || Office.createNewSettings(this.event.ofi_id)
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IEvent): void {
		event.eventSuccess(value)
	}

	save(): void {
		const { date } = this
		if (!!this.event.patient && this.isPhoneUpdated && this.phoneUpdate) {
			this.event.patient.coordinates.phone1.number = this.customer.phone
			this.event.patient.coordinates.phone1.type = PhoneType.Mobil
			this.event.patient.coordinates['update'] = true
		}
		if (!!this.event.patient && this.isEmailUpdated && this.emailUpdate) {
			this.event.patient.coordinates.email = this.customer.email
			this.event.patient.coordinates['update'] = true
		}

		event.confirmEvent({event: this.event})
		.then(() => {
			super.initializeValue()
			planning.checkUnvalidEvents()
			.then(count => { planning.unvalidEvents(count) })
			this.$router.push({name: 'plannings', params: {date: date}})
		})
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
		this.initializeEvent()
	}

	select(patient: IPatient) {
		event.addPatient({patient: patient})
	}

	submitConfirm(): void {
		let confirmAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.valid-event.main.confirm.label-popin').toString(),
			title: this.$i18n.t('vm.root.calendar.valid-event.main.confirm.title').toString(),
			callback: () => {
				this.save()
				return Promise.resolve()
			}
		}
		let confirm: IPopin = {
			title: this.$i18n.t('vm.root.calendar.valid-event.main.confirm.title-popin').toString(),
			content: this.$i18n.t('vm.root.calendar.valid-event.main.confirm.content-popin').toString(),
			action: confirmAction
		}

		popIn.success(confirm)
	}

	submitRefuse(): void {
		let refuseAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.valid-event.main.refuse.label-popin').toString(),
			title: this.$i18n.t('vm.root.calendar.valid-event.main.refuse.title').toString(),
			callback: (data: any) => {
				this.refuse({evt_id: this.event.id, refuse: data})
				return Promise.resolve()
			}
		}
		let refuse: IPopin = {
			title: this.$i18n.t('vm.root.calendar.valid-event.main.refuse.title-popin').toString(),
			content: {component: 'popin-refuse-event'},
			options: {cst_id: this.customer.id, text: this.settings.default_rejection},
			action: refuseAction
		}

		popIn.error(refuse)
	}

	unselect(): void {
		let unselectAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.label').toString(),
			title: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.title').toString(),
			callback: () => {
				event.unselectPatient()
				return Promise.resolve()
			}
		}
		let unselectPopin: IPopin = {
			title: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.title').toString(),
			content: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.content').toString(),
			action: unselectAction
		}

		this.warning(unselectPopin)
	}

	private initializeEvent(): void {
		let payload = {evt_id: this.$route.params.evt_id}
		if (payload.evt_id === undefined) {
			this.$router.push({name: 'plannings'})
		} else if (!event.isLoaded || !event.event || event.event.id !== payload.evt_id) {
			event.loadEvent(payload)
			.then(() => {
				this.initializeValue()
			})
		} else {
			this.initializeValue()
		}
	}

	protected initializeValue() {
		super.initializeValue()
		this.checkSelectedUser()
	}

	private refuse(payload: EventRefusePayload) {
		const { date } = this
		event.refuseEvent(payload).then(() => {
			this.state = AutoSaverState.None
			planning.checkUnvalidEvents()
			.then(count => { planning.unvalidEvents(count) })
			this.$router.push({name: 'plannings', params: {date: date}})
		})
	}

	private checkSelectedUser(): void {
		if (!user.isSecretaryUser || !!user.selectedPlanningUser) return

		if (!!this.event.usr_id) {
			let users = user.linkedUsers(Profile.Main)
			let _user: ILinkUser = users.find(user => user.id === this.event.usr_id)
			if (!!_user) {
				planning.toggleVisibilityByUser(_user.id)
				user.selectLinkPlanningUser(User.linkUserToUser(_user))
				return
			}
		}

		// On force la redirection vers le planning pour éviter de ne pas avoir de session quand on est connecté avec un compte secrétaire
		this.$router.push({name: 'plannings', params: {date: this.date}})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.plannings-main').toString(), link: "plannings" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.valid-event').toString() }
		breadcrumb.updateItems([item1, item2])
	}

	//#endregion
}
