import Render from '@Views/components/frame/radio-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class RadioFrame extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: "input-frame"}) defaultClass: string
    @Prop() name: string
    @Prop() title: string
    @Prop() id: string
    @Prop() label: string
    @Prop() radioValue: any
    @Prop() value: any

    get checked(): boolean {
        return this.value === this.radioValue
    }

    update(): void {
        this.$emit('input', this.radioValue)
    }
}