export const planningMain = {
    legends: {
        first: "rendez-vous effectués",
        second: "rendez-vous manqués",
        third: "rendez-vous pris"
    },
    title: "Statistiques planning",
    loading: "Chargement en cours…",
    'ratio-title': "Rdv effectués / manqués (en {year})",
    'evolution-title': "Évolution des rdv (en {year})"
}