import Render from '@Views/root/patients/patient/aside-patient.html'
import { i18n } from '@I18n/index'
import { Component, Vue } from 'vue-property-decorator'
import { IColor, INotification, INotificationAction, IConsultation, IAttachment, IAntecedent, ISphere, IMember, IPatient, PatientLoadPayload } from '@Store/types'
import { Player } from '@Models/index'
import { Sound } from '@Enums/index'
import { configuration, notif, patient, user } from '@Store/modules'

@Render
@Component({})
export class ASidePatient extends Vue {

  //#region Getters
  get patient(): IPatient {
		return patient.patient
	}
  get spheres(): ISphere[] {
		return patient.spheres
	}
  get antecedents(): IAntecedent[] {
		return patient.antecedents
	}
  get attachments(): IAttachment[] {
		return patient.attachments
	}
  get consultations(): IConsultation[] {
		return patient.consultations
  }
  get family(): IMember[] {
    return patient.family
  }
  get isMainUser(): boolean {
    return user.isMainUser
  }
  get sphereRunningCount(): number {
    return this.spheres.filter(sphere => sphere.running).length
  }
  get antecedentRunningCount(): number {
    return this.antecedents.filter(antecedent => antecedent.running).length
  }
  get antecedentFamiliyCount(): number {
    return this.antecedents.filter(antecedent => antecedent.family).length
  }
  get colorManager(): IColor {
		return configuration.configuration && configuration.configuration.colors
  }
  get isDeleting(): boolean {
    return patient.isDeleting
  }
  get isBlacklisting(): boolean {
    return patient.isBlacklisting
  }
  get isDeceasing(): boolean {
    return patient.isDeceasing
  }
  get blacklisted(): boolean {
		return patient.blacklisted
	}
  get deceased(): boolean {
		return patient.deceased
	}
  //#endregion

  dead(deceased: boolean) {
    if (this.patient.is_new) return

    let message: string = deceased ?
      this.$i18n.t('vm.root.patients.patient.aside.deceased.text').toString() :
      this.$i18n.t('vm.root.patients.patient.aside.resurrected.text').toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }

		patient.deadPatient({pat_id: this.patient.id, deceased: deceased})
		.then(() => { notif.info(notification) })
  }

  blacklist(blacklisted: boolean) {
		if (this.patient.is_new) return

    let message: string = blacklisted ?
      this.$i18n.t('vm.root.patients.patient.aside.blacklist.text').toString() :
      this.$i18n.t('vm.root.patients.patient.aside.whitelist.text').toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }

		patient.blacklistPatient({pat_id: this.patient.id, blacklisted: blacklisted})
		.then(() => { notif.info(notification) })
  }

  submitDelete() {
    let payload: PatientLoadPayload = {pat_id: this.patient.id}

    let notification: INotification = this.getNotification(payload)
    notif.warning(notification)
  }

  private confirmDelete(payload: PatientLoadPayload, timer: NodeJS.Timeout, player: Player) {
    notif.clear()
    clearInterval(timer)

    this.$emit('delete')
    player.stop()
    patient.deletePatient(payload)
    .then(() => {
      notif.success(this.getResultNotification(true))
      let route = this.$router.currentRoute
		  if (!!route && route.name.startsWith('patient-')) {
        this.$router.push({ name: 'patients' })
      }
    }, () => { notif.error(this.getResultNotification(false)) })
		.catch(() => { notif.error(this.getResultNotification(false)) })
  }

  private getResultNotification(success: boolean): INotification {
    let message: string = success ?
      i18n.t('vm.root.patients.patient.aside.deleted.succeeded').toString() :
      i18n.t('vm.root.patients.patient.aside.deleted.failed').toString()
    return { message: message, actions: [], delay: 5000, canClose: true }
  }

  private getNotification(payload: PatientLoadPayload): INotification {
    let count: number = 10
    let player: Player = new Player()
    player.play(Sound.Stress)

    let timer: NodeJS.Timeout = setInterval(() => {
      count--
      if (count <= 0) {
        this.confirmDelete(payload, timer, player)
      } else {
        notif.updateMessage(i18n.tc('vm.root.patients.patient.aside.deleting', count).toString())
      }
    }, 1000)


    let cancelAction: INotificationAction = {
      label: this.$i18n.t('general.cancel.text').toString(),
      callback: (data: any) => {
        player.stop()

        clearInterval(timer)
        notif.clear()
        return true
      }
    }

    let deleteAction: INotificationAction = {
      label: this.$i18n.t('general.delete.text').toString(),
      callback: (data: any) => {
        this.confirmDelete(payload, timer, player)
        return true
      }
    }

    let message: string = this.$i18n.tc('vm.root.patients.patient.aside.deleting', count).toString()

    return { message: message, delay: false, actions: [cancelAction, deleteAction], canClose: false }
  }
}
