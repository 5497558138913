<div v-if="options">
	<p v-if="options.is_new">{{ $t('vm.components.popin.create-patient.text1') }}</p>

	<div :class="{'grid-3': isDuplicate, 'grid-2': !isDuplicate}">
		<input-frame :auto-focus="true" id="last-name" v-model="coordinates.last_name"
			:label="$t('vm.root.search.patient.create.last-name.label')"
			:placeholder="$t('vm.root.search.patient.create.last-name.placeholder')"
			:error-test="hasLastNameError"
			:error-msg="$t('vm.root.search.patient.create.last-name.error')"
			:title="$t('vm.root.search.patient.create.last-name.title')"/>
		
		<input-frame id="first-name" v-model="coordinates.first_name"
			:label="$t('vm.root.search.patient.create.first-name.label')"
			:placeholder="$t('vm.root.search.patient.create.first-name.placeholder')"
			:error-test="hasFirstNameError"
			:error-msg="$t('vm.root.search.patient.create.first-name.error')"
			:title="$t('vm.root.search.patient.create.first-name.title')"/>
		
		<dropdown-frame v-if="isDuplicate" :label="$t('vm.components.popin.create-patient.member.label')" :items="memberItems" id="member" 
			v-model="options.member" :title="$t('vm.components.popin.create-patient.member.title')"/>
	</div>
		
	<div class="grid-2">
		<input-date-frame v-model='coordinates.birth_date' id="birth-date" :type="birthDateType"
			:label="$t('vm.root.search.patient.create.birth-date.label')"
			:title="$t('vm.root.search.patient.create.birth-date.title')"
			:placeholder="$t('vm.root.search.patient.create.birth-date.placeholder')"/>

		<togglebox-frame id="gender" :items="genderItems" v-model="coordinates.gender"
			:label="$t('vm.root.search.patient.create.gender.label')"
			:title="$t('vm.root.search.patient.create.gender.title')"/>

		<input-address-frame id="address" class="grid-item-full" :address="coordinates.address"
			:label="$t('vm.root.search.patient.create.address.label')"
			:placeholder="$t('vm.root.search.patient.create.address.placeholder')"/>

		<input-phone-frame id="phone" :phone="coordinates.phone1"
			:label="$t('vm.root.search.patient.create.phone.label')"
			:error-test="coordinates.phone1.error"
			:error-msg="$t('vm.root.search.patient.create.phone.error')"
			:title="$t('vm.root.search.patient.create.phone.title')"/>

		<input-frame type="email" id="email" v-model="coordinates.email"
			:label="$t('vm.root.search.patient.create.email.label')"
			:placeholder="$t('vm.root.search.patient.create.email.placeholder')"
			:title="$t('vm.root.search.patient.create.email.title')"/>

	</div>
</div>
