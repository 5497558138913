<div class="attachment">
    <section>
        <h3 class="h-reset text-bold">
            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                <use v-if="!isFile" xlink:href="@Assets/svg/symbol-defs.svg#link"/>
                <use v-else-if="isPdf" xlink:href="@Assets/svg/symbol-defs.svg#file-pdf"/>
                <use v-else xlink:href="@Assets/svg/symbol-defs.svg#image"/>
            </svg>
            <button type="button" class="btn--link-like" @click="goTo" :title="$t('vm.root.patients.patient.attachment-item.title')">{{attachment.name}}</button>
            <div v-if="owner" class="text-italic text-sm">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
                </svg>
                {{ $t('vm.root.patients.patient.attachment-item.create-by', {owner: owner}) }}
            </div>
        </h3>
        <figure class="attachment-pic text-center">
            <button type="button" class="btn--link-like" @click="goTo" :title="$t('vm.root.patients.patient.attachment-item.title')">
				<img v-if="!isFile" :src="attachment.description" :alt="attachment.title">
				<svg v-else-if="isPdf" width="1em" class="svg-xl marg-v" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-pdf"/>
				</svg>
                <img v-else :src="fileUrl" loading="lazy"/>
            </button>
        </figure>
        <div class="childs-space-between">
            <div>
                <button type="button" class="btn btn--link-like" @click="updateAttachment" :title="$t('general.edit.title')">{{ $t('general.edit.text') }}</button>
            </div>
            <div class="is-pushed-right" v-if="!readOnly && isMainUser">
                <delete-item class="btn btn--link-like text-danger" :can-delete="true"
                    :delete-action="submitDelete">
                    {{ $t('general.delete.text') }}
                </delete-item>
            </div>
        </div>
    </section>
</div>
