import Render from '@Views/components/schedule.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InputRangeType, IDropdownItem } from '@Components/index'
import { ISchedule } from '@Store/types'
import { DateHelper } from '@Helpers/index'
import { Profile } from '@Enums/index'

@Render
@Component({})
export class ScheduleElement extends Vue {

	userItems: IDropdownItem[] = []
	
	@Prop() schedule: ISchedule
	@Prop() links: any[]

	get rangeType(): InputRangeType {
		return InputRangeType.RangeTime
	}

	created() {
		this.userItems = this.links
		.filter(user => { return user.profile === Profile.Main || user.profile === Profile.Demo || user.profile === Profile.Substitute })
		.map(user => { return {value: user.id, label: `${user.coordinates.first_name} ${user.coordinates.last_name}`} })
	}

	getDayName(index: number): string {
		return DateHelper.getDayName(index)
	}

	getDefaultDate(hour: number): Date {
		let date: Date = new Date()
		date.setHours(hour)
		date.setMinutes(0)
		date.setSeconds(0)
		date.setMilliseconds(0)
		return date
	}
}