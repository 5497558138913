<a-side>
	<div class="wrapper">
		<div class="aside-search-sticky-container">
			<div class="aside-search">
				<svg width="1em" class="aside-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" class="aside-search-input" v-model="query"
					:placeholder="$t('vm.root.calendar.aside.search.placeholder')"
					:title="$t('vm.root.calendar.aside.search.title')">
			</div>
		</div>

		<div v-if="resultSearch.length <= 0" class="patients-search-no-result" :class="{'is-white-space': !searched || !query || searching}">
			<label for="patients-search">
				<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
			</label>
			<div class="text-md text-bold text-center marg-v">
				<span v-if="searching">{{ $t("vm.root.calendar.aside.searching") }}</span>
				<span v-else-if="searched && query">{{ $tc("vm.root.calendar.aside.result", 0) }}</span>
			</div>
		</div>

		<div v-else>
			<div class="text-md text-bold">{{ $tc("vm.root.calendar.aside.result", resultSearch.length) }}</div>
			<section class="patients-search-item text-sm" :id="`search-${patient.id}`" :key="`search-${patient.id}`" v-for="patient in resultSearch">
				<router-link class="patients-search-item-header text-capitalize text-inherit" :to="link(patient)"
					:title="$t('vm.root.calendar.aside.patient-title', {first_name: patient.first_name, last_name: patient.last_name})">
					<figure class="patients-search-item-avatar">
						<avatar :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
					</figure>
					<h3 class="h-reset patients-search-item-name">
						<span :inner-html.prop="patient.first_name | mark(query)"/><br>
						<b :inner-html.prop="patient.last_name | mark(query)"/>
					</h3>
				</router-link>
				<div class="patients-search-item-border">
					<div>{{ $t('general.the') }} {{$d(patient.date, 'long')}}</div>
				</div>
			</section>
		</div>

	</div>
</a-side>
