export const parameters = {
    title: "Paramètres du planning en ligne",
    help: {
        text: "Ces paramètres seront destinés à la page de prise de rdv en ligne. Même {0} et renseigner ces paramètres. Cela permettra à d'éventuels nouveaux patients de vous connaître et de prendre rendez-vous avec vous, par téléphone, si ce dernier est renseigné dans les coordonnées de ce cabinet.",
        strong: "si vous n'avez pas souscrit à cette option, vous pouvez activer votre page"
    },
    activate: {
        title: "Activez votre page de prise de rendez-vous en ligne",
        label: "Page de prise de rendez-vous en ligne activée"
    },
    url: {
        title: "Url de votre page de cabinet",
        help: {
            text: "L'adresse postale de votre cabinet a changé et vous souhaitez modifier l'url associée ? Cliquez sur le bouton ci-dessous. {0}, vos patients ne pourront plus accéder à l'ancienne url. Veillez à bien les prévenir de ce changement.",
            strong: "Attention"
        },
        'button-title': "Re-générer l'url de votre page de prise de rdv",
        'button-label': "re-générer l'url"
    },
    planning: {
        title: "Sélectionnez le planning dans lequel les rdv seront insérés",
        label: "Planning dans lequel les rdv seront insérés"
    },
    suggestion: {
        label: "Suggestion de message de présentation",
        help: "Nous vous proposons quelques suggestions pour votre texte de présentation. Si vous optez pour l'une de ces suggestions, vérifiez bien le contenu du texte et son rendu, qui peuvent avoir certaines spécificités. N'hésitez pas à les compléter.",
        item: "Suggestion #{number}"
    },
    message: {
        label: "Message de présentation à destination de vos patients",
        placeholder: "ex : Je m'présente, je m'appelle Henri…",
        title: "Message pour vous présenter à vos patients sur votre page de prise de rdv en ligne"
    },
    'default-rejection': {
        label: "Message par défaut de refus d'un rendez-vous",
        placeholder: "ex : Impossibilité pour moi, j'ai aqua-poney…",
        title: "Message que vous êtes susceptible de saisir fréquemment en cas de refus de rdv"
    },
    'alert-message': {
		'main-title': "Message d'alerte",
        label: "Message d'alerte sur votre page de prise de rendez-vous",
        placeholder: "ex : C'est la crise, mais on gère…",
        title: "Message qui apparaîtra via une fenêtre sur votre page de prise de rendez-vous",
		help: {
			text: "Vous pouvez renseigner {0} lorsqu'un visiteur se rendra sur votre page de prise de rendez-vous. Impossible pour vos patients de ne pas le voir 😊.",
			strong: "un message qui apparaîtra au premier plan"
		}
	},
    'free-space-mode': {
		'main-title': "Mode de calcul des créneaux disponibles",
        label: "Mode de calcul des créneaux disponibles",
        title: "Sélection du mode de calcul pour proposer vos créneaux disponibles",
		help: {
			text: "Vous avez la possibilité de choisir comment les créneaux disponibles seront déterminés. Ce mode s'appliquera aussi bien sur votre page de prise de rdv que la prise de rdv rapide.",
			li1: {
				text : "{0} : Propose l'{1} de votre planning",
				strong1 : "Mode classique",
				strong2 : "ensemble des créneaux libres"
			},
			li2: {
				text : "{0} : Ne proposera {1}. Si aucun rdv n'est pris sur une journée, tous les créneaux libres seront proposés. Ce mode de calcul permet d'éviter l'effet {2} dans votre planning et regroupe l'ensemble des rdv.",
				strong1 : "Mode optimisé",
				strong2: "que les créneaux disponibles et adjascents (avant et / ou après) aux rdv associés à un patient",
				em: "gruyère",
			},
			li3: {
				text : "{0} : Comme le mode {1}, ce mode ne proposera {2}. En revanche, si votre journée est coupée par une pause déjeuner, il appliquera le même principe sur {3} et pas uniquement sur la journée entière.",
				strong1 : "Mode semi-optimisé",
				strong2 : "optimisé",
				strong3: "que les créneaux disponibles et adjascents (avant et / ou après) aux rdv associés à un patient",
				strong4: "chaque demi-journée",
			}
		}
	},
    'max-week': {
        label: "Nombre de semaines visibles",
        title: "Nombre de semaine après lequel la prise de rdv est désactivée"
    },
    'min-hour': {
        label: "Nombre d'heure minimum avant le prochain rendez-vous",
        title: "Indiquez le nombre d'heure minimum avant le prochain rendez-vous",
        help1: "Si vous le souhaitez, vous pouvez indiquer un délai horaire en dessous duquel les créneaux seront indisponibles.",
        help2: {
            text: "Exemple 1 : Il est 18:00, le créneau de 18:30 est disponible et votre cabinet ferme à 19:00. Si vous indiquez {0}, alors les créneaux entre 18:00 et 19:00 seront rendus inacessibles à vos patients, vous pourrez partir serein et écourter votre journée sans devoir revenir au cabinet en urgence.",
            strong: "1 heure minimum avant le prochain rdv"
        },
        help3: {
            text: "Exemple 2 : Vous ne souhaitez pas d'un rdv pris à la dernière minute, que vous n'aurez peut-être pas le temps de valider. Si vous indiquez {0}, alors les créneaux de l'heure courante seront rendus inacessibles à vos patients.",
            strong: "1 heure minimum avant le prochain rdv"
        }
    },
    validation: {
        'main-title': "Validation automatique",
        help: "Validez automatiquement les rendez-vous pris par des patients qui en ont déjà pris via le planning en ligne. Dès lors que le rdv est pris pour un patient associé à un et un seul dossier dans votre base de données, la validation sera automatique. Le patient en sera averti immédiatement.",
        title: "Activez la validation automatique des rdv pris en ligne",
        label: "Validation automatique des rendez-vous"
    },
    registration: {
        'main-title': "Inscription des patients",
        help: {
            text: "Certains patients peuvent ne pas recevoir le mail contenant leur mot de passe, lors de leur inscription. Pour éviter de perdre ces personnes, vous pouvez activer cette option leur permettant de recevoir leur mot de passe également par SMS. Cette option nécessite l'{0}.",
            'link-title': "Acquérir des SMS",
            'link-label': "acquisition d'un pack SMS"
        },
        title: "Envoyez un SMS à votre patient pour qu'il puisse recevoir son mot de passe par SMS",
        label: "Envoyer le mot de passe par SMS à l'inscription"
    },
    suggest1: "<p><strong class='u-text-capitalize'>--GENDER-- --FIRST_NAME-- --LAST_NAME--</strong> a le plaisir de vous recevoir au sein "+
		"de son cabinet de <strong class='u-text-capitalize'>--CITY-- (--ZIPCODE--)</strong>.</p>"+
		"<p>C'est <strong>avec le sourire et professionnalisme</strong> que votre ostéopathe vous y accueillera que "+
		"vous soyez un adulte, une femme enceinte, un nourrisson, un sportif, un senior ou un enfant / adolescent.</p>"+
		"<p>Une consultation se déroule généralement en trois parties :"+
		"<ol><li>l'<strong>interrogatoire complet</strong> comprenant l'état civil du patient, les modalités de la douleur "+
		"et les antécédents médicaux, chirurgicaux et traumatiques</li>"+
		"<li>les <strong>tests</strong> en vue d'établir <strong>le diagnostic ostéopathique</strong></li>"+
		"<li>le <strong>traitement</strong> adapté</li></ol></p>"+
		"<p><strong>Attention :</strong> la Sécurité Sociale ne rembourse pas les consultations auprès d'un ostéopathe. "+
		"Cependant, certaines mutuelles peuvent assurer une prise en charge partielle ou complète des consultations. "+
		"Pour connaître la part de celle-ci, merci de vous rapprocher de votre mutuelle.</p>"+
        "<p>Vous pouvez <strong>réserver votre consultation</strong>, 24h/24, 7j/7.</p>",
    suggest2: "<p><strong class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</strong>, --TITLE--, vous accueille au sein de son "+
		"cabinet de <strong class='u-text-capitalize'>--CITY-- (--ZIPCODE--)</strong>. <span class='u-text-capitalize'>--PRONOUN--</span> "+
		"pratique l'ostéopathie sur les nourrissons, les sportifs, les femmes enceintes et les seniors.</p>"+
		"<p>L'<strong>ostéopathe</strong> détecte les causes des tensions causant les douleurs par palpation "+
		"puis <strong>pratique des manipulations</strong> afin de rééquilibrer le corps en vue de soulager les différentes douleurs. "+
		"La durée de la consultation est d'environ --DURATION-- minutes.</p>"+
		"<p><strong>Attention :</strong> la Sécurité Sociale ne rembourse pas les consultations auprès d'un ostéopathe. Cependant, certaines "+
		"mutuelles peuvent assurer une prise en charge partielle ou complète des consultations. Pour connaître la part de celle-ci, "+
		"merci de vous rapprocher de votre mutuelle.</p>"+
        "<p>Prenez rendez-vous <strong>dès maintenant</strong>, 24h/24, 7j/7.</p>",
    suggest3: "<p><strong class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</strong>, --TITLE--, vous reçoit "+
		"au sein de son cabinet de <strong class='u-text-capitalize'>--CITY-- (--ZIPCODE--)</strong>.</p>"+
		"<p><span class='u-text-capitalize'>--PRONOUN--</span> prend en charge tout type de patient pour des consultations "+
		"d'ostéopathie classiques :<ul><li>Nourrissons</li><li>Enfants</li><li>Adultes</li><li>Femmes enceintes</li></ul></p>"+
		"<p><strong>Attention :</strong> la Sécurité Sociale ne rembourse pas les consultations auprès d'un ostéopathe. "+
		"Cependant, certaines mutuelles peuvent assurer une prise en charge partielle ou complète des consultations. "+
		"Pour connaître la part de celle-ci, merci de vous rapprocher de votre mutuelle.</p>"+
		"<p>Pour prendre rendez-vous avec <span class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</span>, "+
        "veuillez vous référer aux types de consultation ci-dessous.</p>",
    suggest4: "<p><strong class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</strong>, --TITLE--, a le plaisir d'accueillir sa patientèle, "+
		"du nouveau né à la personne agée, au sein de son cabinet de <strong class='u-text-capitalize'>--CITY-- (--ZIPCODE--)</strong>.</p>"+
		"<p><strong class='u-text-capitalize'>--GENDER-- --LAST_NAME--</strong> vous reçoit tous les jours de la semaine pour "+
		"des consultations d'ostéopathieet de suivi. Sa formation lui permet de prendre en charge les douleurs aiguës de "+
		"type lumbago, torticolis, mais également les douleurs aiguës ou chroniques de type sciatique, cruralgie, "+
		"névralgie cervico-brachiale, névralgie d'Arnold, etc.</p>"+
		"<p><strong>Attention :</strong> la Sécurité Sociale ne rembourse pas les consultations auprès d'un ostéopathe. "+
		"Cependant, certaines mutuelles peuvent assurer une prise en charge partielle ou complète des consultations. "+
		"Pour connaître la part de celle-ci, merci de vous rapprocher de votre mutuelle.</p>"+
        "<p>Pour tout rendez-vous en dehors des plages horaires disponibles, merci d'appeler directement l'ostéopathe au : --PHONE--</p>",
    suggest5: "<p><strong class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</strong>, --TITLE--, vous accueille dans son cabinet "+
		"de <strong class='u-text-capitalize'>--CITY-- (--ZIPCODE--)</strong>. Le cabinet reçoit tout type de patient : "+
		"<ul><li>nourissons</li><li>adultes</li><li>femmes enceintes</li><li>seniors</li><li>sportifs</li></ul></p>"+
		"<p><span class='u-text-capitalize'>--FIRST_NAME-- --LAST_NAME--</span> travaille avec une prise en charge globale, "+
		"basée sur des techniques non douloureuses et toujours adaptées aux besoins et pathologies du patient.</p>"+
		"<p><span class='u-text-capitalize'>--PRONOUN--</span> intervient sur différentes structures : "+
		"<ul><li>Le système articulaire et musculaire.</li><li>Le système neurologique.</li><li>Le système vasculaire.</li>"+
		"<li>Le système digestif et viscéral.</li><li>Troubles digestifs.</li><li>Troubles génito-urinaires.</li></ul></p>"+
		"<p>Si aucun créneau ne vous convient ou si c'est une urgence, vous pouvez appeler directement "+
		"le numéro de téléphone du cabinet : --PHONE--; nous pourrons vous proposer d'autres créneaux.</p>"+
		"<p><strong>Attention :</strong> la Sécurité Sociale ne rembourse pas les consultations auprès d'un ostéopathe. "+
		"Cependant, certaines mutuelles peuvent assurer une prise en charge partielle ou complète des consultations. "+
		"Pour connaître la part de celle-ci, merci de vous rapprocher de votre mutuelle.</p>",
    cancel: {
        action: "perdre vos modifications",
        'message-details': "Vous êtes sur le point d'annuler l'ensemble de vos modifications :<ul><li>{details}</li></ul>Vous confirmez ?",
        message: "Vous êtes sur le point d'annuler l'ensemble de vos modifications. Vous confirmez ?",
        title: "Annulation",
    },
    'create-by': "Créé par",
}
