<div>
	<collapsible-help id="patient-documents" :title="$t('vm.root.patients.patient.documents.help1.title')" tag="h2">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.patients.patient.documents.help1.text') }}
			</p>
		</div>
	</collapsible-help>

	<div v-if="!disabled" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.documents.disabled.title') }}</span><br>
			{{ $t('vm.root.patients.patient.documents.disabled.description') }}
		</p>
	</div>
	<template v-else>
		<div v-if="consultationItems.length === 0" class="marg-v text-center">
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
			</svg>
			<p>
				<span class="text-lg">{{ $t('vm.root.patients.patient.documents.none.title') }}</span><br>
				<i18n path="vm.root.patients.patient.documents.none.description.text" tag="span">
					<button type="button" class="btn--link-like" @click="addConsultation" :title="$t('vm.root.patients.patient.documents.none.description.title')">{{ $t("vm.root.patients.patient.consultations.none.description.link") }}</button>
				</i18n>
			</p>
		</div>
		<div v-else>
			<dropdown-frame id="consultation" v-model="con_id" :items="consultationItems"
				:label="$t('vm.root.patients.patient.documents.consultation.label')"
				:default-label="$t('vm.root.patients.patient.documents.consultation.default-label')"/>
			<div v-if="hasSelectedConsultation">
				<collapsible-help id="patient-documents-invoice" :title="$t('vm.root.patients.patient.documents.help2.title')">
					<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<div class="notice-info">
						<p class="text-left">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
							</svg>
							{{ $t('vm.root.patients.patient.documents.help2.text1') }}
						</p>
						<p class="text-left">{{ $t('vm.root.patients.patient.documents.help2.text2') }}</p>
						<p class="text-left">{{ $t('vm.root.patients.patient.documents.help2.text3') }}</p>
					</div>
				</collapsible-help>

				<div class="marg-v">
					<span v-if="hasInvoice"><strong class="text-numeric">#<span class="select">{{ invoiceNumber }}</span></strong> &bull; </span>
					<button type="button" class="btn--link-like" @click="createAndPrint" :title="$t('managers.invoice-manager.print-invoice.title')">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': invoicePrinted()}">
							<use v-if="invoicePrinted()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							<use v-else xlink:href="@Assets/svg/symbol-defs.svg#print"/>
						</svg>
						{{ $t('general.print.text') }}
					</button> &bull;
					<button type="button" class="btn--link-like" @click="createAndSend" :disabled="isInvoiceSending" :title="$t('managers.invoice-manager.send-invoice.title')">
						<spinner v-if="isInvoiceSending"/>
						<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': invoiceSended()}">
							<use v-if="invoiceSended()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							<use v-else xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
						</svg>
						{{ $t('general.send') }}
					</button> &bull;
					<button type="button" class="btn--link-like text-lowercase" @click="updateReceipt" :title="hasInvoice ? $t('managers.invoice-manager.update-invoice.title') : $t('managers.invoice-manager.generate-invoice.title')">
						<spinner v-if="isInvoiceLoading"/>
						<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
						</svg>
						{{ hasInvoice ? $t('general.edit.text') : $t('general.generate') }}
					</button>
				</div>

				<collapsible-help id="patient-documents-attestation" :title="$t('vm.root.patients.patient.documents.help3.title')">
					<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<div class="notice-info">
						<p class="text-left">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
							</svg>
							{{ $t('vm.root.patients.patient.documents.help3.text') }}
						</p>
					</div>
				</collapsible-help>

				<input-range v-model='range' :type="rangeType" class="grid-item-full grid-2">
					<input-frame id="startRange" :random-id="false"
						:label="$t('vm.root.patients.patient.documents.range.start.label')"
						:title="$t('vm.root.patients.patient.documents.range.start.title')"/>
					<input-frame id="endRange" :random-id="false"
						:label="$t('vm.root.patients.patient.documents.range.end.label')"
						:title="$t('vm.root.patients.patient.documents.range.end.title')"/>
				</input-range>

				<div class="marg-v">

					<button type="button" class="btn--link-like" :title="$t('vm.root.patients.patient.documents.print-attestation')" @click="printAttestation">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#print"/>
						</svg>
						{{ $t('general.print.text') }}
					</button>
				</div>
			</div>
			<div v-else class="marg-v text-center">
				<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-invoice"/>
				</svg>
				<p>
					<span class="text-lg">{{ $t('vm.root.patients.patient.documents.none-selected.title') }}</span><br>
					{{ $t('vm.root.patients.patient.documents.none-selected.description') }}
				</p>
			</div>
		</div>
	</template>
</div>
