import Render from '@Views/root/patients/campaigns/campaign/steps/step-3.html'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { ICampaign } from '@Store/types'
import { campaign } from '@Store/modules'

@Render
@Component({})
export class StepThree extends Vue {

	@Prop() campaign: ICampaign

	get isSaving(): boolean {
		return campaign.isSaving
	}
	get isSended(): boolean {
		return campaign.isSended()
	}

	mounted() {
		if (campaign.step === 2) return

		this.$router.push({name: 'campaign-step-one'})
	}

	previous(): void {
        campaign.previousStep()
        this.$router.push({name: 'campaign-step-two'})
	}
}
