<div class="input-frame" :class="{ 'is-alert': errorTest }">
    <label class="label" :for="id" :data-text="label">
        <span>{{label}}</span>
    </label>
    <input  :id="id" :ref="id" v-focus="autoFocus" :placeholder="placeholder" :title="title" :readonly="readonly" :disabled="disabled">
    <div class="msg-alert" v-if="displayError" v-show="errorTest">
        <div class="msg-alert-inner">{{errorMsg}}</div>
    </div>
</div>
    
