export const antecedents = {
    title: "Antécédents",
    help1: "Vous avez la possibilité de saisir les antécédents familiaux en vrac dans le champ ci-dessous.",
    help2: {
        text: "Notez que les antécédents indiqués comme {0}, auront un affichage spécifique mais seront surtout intégrés dans les dossiers des autres membres de la famille en tant qu'{1}.",
        strong1: "importants",
        strong2: "antécédents familiaux"
    },
    'create-by': "créé par {owner}",
    'not-specified': "Non renseignés",
    family: {
        label: "Antécédents familiaux",
        placeholder: "ex : Diabète de son père",
        title: "Veuillez renseigner les antécédents des membres de la famille de ce patient"
    },
    filter: {
        label: "Filtrer les antécédents",
        placeholder:"ex : chir lig",
        title:"Veuillez renseigner un filtre pour rechercher un élément précis"
    },
    'new-antecedent': {
        title: "Créer un nouvel antécédent {type}",
        text: "nouvel antécédent"
    },
    none: {
        title: "Pas d'antécédent.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer un nouvel antécédent",
            link: "un nouvel antécédent"
        }
    }
}