export const organization = {
    name: {
        label:"Nom de l'association",
        placeholder:"ex : Club sportif",
        'error-msg':"Merci de renseigner le nom de l'association",
        title:"Veuillez renseigner le nom de l'association"
    },
    period: {
        label:"Période liée à l'association",
        placeholder:"ex : 2014-2016",
        title:"Veuillez renseigner la période pendant laquelle vous avez œuvré pour cette association"
    },
    description: {
		label: "Description de l'association ({count} caractère restant) | Description de l'association ({count} caractère restant) | Description de l'association ({count} caractères restants)",
        placeholder:"ex : Participation à la préparation…",
        title:"Veuillez renseigner la description de cette association",
		'msg-error': "Votre description ne doit pas dépasser 250 caractères"
    },
    delete: {
        msg: "Voulez-vous vraiment supprimer cette association ?",
        title: "Supprimer cette association"
    }
}
