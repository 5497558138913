import { BaseService } from '@Services/index'
import { IColleague } from '@Store/types'

export class ColleagueService extends BaseService {
	
	public searchColleague(query: string): Promise<any> {
		return super.post(`/search/colleagues/${query}`, {filter: {}})
	}

	public getColleague(col_id: string): Promise<any> {
		return super.get(`/colleague/${col_id}`)
	}

	public save(colleague: IColleague, callbackProgress?: (progress: number) => void): Promise<any> {
		if (colleague.is_new) 
			return super.post('/colleague', colleague, undefined, undefined, callbackProgress)

		return super.put(`/colleague/${colleague.id}`, colleague, undefined, callbackProgress)
	}

	public delete(colId: string): Promise<any> {
		return super.delete(`/colleague/${colId}`)
	}
}