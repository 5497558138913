import Render from '@Views/components/frame/dropdown-editable-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITagItem } from '@Store/types'

@Render
@Component({})
export class DropdownEditableFrame extends Vue {

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) manageMissing: boolean
    @Prop({default: false}) multiple: boolean
    @Prop({default: false}) taggable: boolean
    @Prop({default: 'sélectionnez une valeur'}) defaultLabel: string
    @Prop({default: 'tapez pour rechercher…'}) noOptionsLabel: string
    @Prop({default: ''}) defaultValue: any
    @Prop() searcher: (search: string) => any
    @Prop() title: string
    @Prop() placeholder: string
    @Prop() errorMsg: string
    @Prop() label: string
    @Prop() items: ITagItem[]
    @Prop() id: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }

    handleInput(e: any) {
        this.$emit('input', e)
    }
}
