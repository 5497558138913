<div v-if="!!settings">
  <collapsible-help id="settings-absence-information" :title="$t('vm.root.user.office.absence.title')" tag="h2">
    <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
      <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
    </svg>
    <div class="notice-info">
      <p class="text-left">
        <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
          <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        {{ $t('vm.root.user.office.absence.help') }}
      </p>
    </div>
  </collapsible-help>

  <div>
    <switch-frame id="is-absent" :title="$t('vm.root.user.office.absence.is-absent.title')" v-model="settings.absence" checked-icon="tree-palm">
      {{ $t('vm.root.user.office.absence.is-absent.label') }}
    </switch-frame>

    <div class="input-frame" v-if="settings.absence">
      <label class="label" for="absence-date" :data-text="$t('vm.root.user.office.absence.period.label')">
        <span>{{ $t('vm.root.user.office.absence.period.label') }}</span>
      </label>

      <input-range id="absence-date" v-model="settings.absence_period" :type="rangeType" :default-start="settings.absence_period[0]"
        :disabled="!settings.absence" :default-end="settings.absence_period[1]" :single-input="true"
        :title="$t('vm.root.user.office.absence.period.title')"
        :placeholder="$t('vm.root.user.office.absence.period.placeholder')"/>
    </div>

    <textarea-frame v-if="settings.absence" id="absence-message" v-model="settings.absence_message"
      :label="$t('vm.root.user.office.absence.message.label')"
      :title="$t('vm.root.user.office.absence.message.title')"
      :placeholder="$t('vm.root.user.office.absence.message.placeholder')"/>
  </div>

</div>
