export const unitsValues = {
    minute: '1 minute | {n} minutes',
    hour: '1 heure | {n} heures',
    day: '1 jour | {n} jours',
    month: '{n} mois',
    year: '1 an | {n} ans',
    'few-moments': 'quelques instants',
    'once-upon-a-time': 'Il était une fois',
    'not-born': 'pas né'
}
