export const campaign = {
    'default-text': "[Votre message ici]\nSTOP au XXXXX",
    loading: "chargement de la campagne",
    aside: {
        back: {
            title: "Retournez à votre liste de campagne",
            label: "Retour aux campagnes SMS"
        },
        'step-1': {
            title: "Retournez à la première étape",
            label: "Texte & date"
        },
        'step-2': {
            title: "Retournez à la deuxième étape",
            label: "Patients"
        },
        'step-3': {
            title: "Retournez à la dernière étape",
            label: "Résumé"
        },
        delete: {
            text: "Supprimer cette campagne",
            title: "Supprimer cette campagne SMS",
            message: "Voulez-vous vraiment supprimer cette campagne SMS ?"
        }
    },
    summary: {
        title: "Résumé de la campagne",
        'sms-count': "Cette campagne a nécessité",
        sms: "{count} SMS|{count} SMS|{count} SMS",
        sended: "Le texte suivant a été envoyé le {date} à {hour}",
        'stop-sms': {
            title: "Liste des numéros désinscris",
            help: "Les numéros listés ci-dessous ne seront plus jamais concernés par vos campagnes SMS. Vous n'avez rien à faire, mais si vous gérez vos listes de numéros manuellement, vous pouvez les retirer de vos listes.",
            'none-title': "Aucun désinscrit",
            'none-label': "Serait-ce une campagne efficace ?"
        },
        header: {
            numbers: "Numéros",
            action: "Rechercher"
        },
        search: {
            label: "rechercher",
            title: "Recherchez les patients liés à ce numéro de téléphone",
            'popin-title': "Recherchez des patients",
            'popin-action': "ouvrir ce dossier",
        }
    },
    'step-1': {
        title: "Texte et détails de la campagne",
        help: "Vous pouvez modifier le texte ci-dessous, jusqu'à 480 caractères maximum. Vos patients le recevront {0}, par SMS.",
        strong: "au jour et à l'heure de la date d'envoi",
        warning: {
            text1: "{0} : si le message venait à dépasser les 480 caractères, il ne serait pas envoyé.",
            strong: "Attention",
            text2: "De plus, si vous envoyez un SMS à but lucratif, ou pour promouvoir un évènement, un produit, alors votre envoi sera Marketing. Ceci implique le respect de certaines règles définies par l'{0}, comme l'ajout de la mention STOP (écrivez {1} à la fin de votre message, avec les XXXXX), ainsi que le respect de certains horaires (uniquement de 8h à 20h les jours ouvrables). Chaque transgression est passible de {2} atteint.",
            'abbr-label': "ARCEP",
            'abbr-title': "Autorité de régulation des communications électroniques, des postes et de la distribution de la presse",
            strong1: "STOP au XXXXX",
            strong2: "1000€ d'amende par numéro",
        },
        input: {
            title: {
                label: "Nom de la campagne SMS",
                placeholder: "ex : Meilleurs voeux pour 2021",
                'error-msg': "Merci de saisir le nom de votre campagne SMS",
                title: "Veuillez saisir le nom de votre campagne SMS"
            },
            date: {
                label: "Date d'envoi de la campagne SMS",
                title: "Veuillez indiquer la date d'envoi à laquelle vous souhaitez envoyer cette campagne SMS",
                'error-msg-1': "Votre message doit être envoyé entre 8:00 et 20:00",
                'error-msg-2': "Merci d'indiquer une date future"
            },
            'sms-count': {
                label: "Nombre de SMS par personne",
                title: "Indique le nombre de SMS nécessaire pour un message par personne"
            },
            message: {
                label: "Message pour votre campagne SMS ({count} caractère restant) | Message pour votre campagne SMS ({count} caractère restant) | Message pour votre campagne SMS ({count} caractères restants)",
                placeholder: "ex : Je vous souhaite une excellente année…",
                'error-msg-1': "Merci de saisir un texte respectant les règles énoncées",
                'error-msg-2': "Votre message ne doit pas dépasser 480 caractères",
                title: "Veuillez saisir un texte respectant les règles énoncées"
            }
        },
        next: {
            title: "Aller à l'étape suivante",
            label: "étape 2"
        }
    },
    'step-2': {
        title: "Liste de patients",
        help1: "Vous pouvez fournir une liste de numéros de téléphone, au format international (ex : +33611223344), séparés par des virgules « , » ou des points-virgules « ; ».",
        help2: "Ou alors, vous pouvez filtrer les patients que vous souhaitez contacter. Le filtre se veut simple pour ne couvrir que les cas généraux, seuls les patients avec un numéro de téléphone apparaîtront. Si des patients, correspondant à votre filtre, venaient à se rajouter à votre listing avant l'envoi de cette campagne, ils seraient également contactés.",
        strong: "au jour et à l'heure de la date d'envoi",
        loading: "Chargement en cours…",
        'no-result': {
            title: "Aucun résultat.",
            text: "Vous devriez peut-être modifier vos filtres."
        },
        'patient-list': "Cette campagne ne concerne, actuellement, aucun patient | Cette campagne concerne, actuellement, un seul patient | Cette campagne concerne, actuellement, {count} patients",
        previous: {
            title: "Retourner à l'étape précédente",
            label: "étape 1"
        },
        next: {
            title: "Aller à l'étape suivante",
            label: "étape 3"
        },
        'choice-label': "Comment souhaitez-vous fournir les numéros à contacter ?",
        list: {
            result: "Cette campagne ne concerne aucun numéro de téléphone | Cette campagne concerne un seul numéro de téléphone | Cette campagne concerne {count} numéros de téléphone",
            label: "Liste des numéros de téléphone séparés par des , ou des ;",
            placeholder: "ex : +33611223344;+33644332211;",
            title: "Indiquez les numéros de téléphone au format international séparés par une , ou un ;"
        }
    },
    'step-3': {
        title: "Résumé",
        help: "L'estimation du nombre de SMS utilisé est calculée en fonction du nombre de patients impactés par cette campagne. Le nombre de SMS réellement envoyé sera calculé une fois la campagne envoyée.",
        warning: {
            text: "{0} : Assurez-vous que votre solde de SMS est au moins aussi élevé que notre estimation, sans quoi le message ne sera pas envoyé.",
            strong: "Attention"
        },
        'estimation-count': {
            label: "Estimation du nombre de SMS nécessaire",
            title: "Cela vous indique l'estimation du nombre de SMS nécessaire à votre campagne"
        },
        'total-sms': {
            label: "Nombre de SMS réellement utilisé",
            title: "Cela vous indique le nombre de SMS réellement utilisé après l'envoi de votre campagne"
        },
		notification: {
            label: "Recevoir un court résumé de la campagne par mail, une fois envoyée",
            title: "Permet de recevoir, par mail, un court résumé de la campagne SMS"
		},
        previous: {
            title: "Retourner à l'étape précédente",
            label: "étape 2"
        },
        save: {
            title: "Sauvegarder la campagne",
            label: "sauvegarder"
        }
    }
}
