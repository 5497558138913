import Render from '@Views/root/accounting/book/context-book.html'
import { Component, Vue } from 'vue-property-decorator'
import { InputDateType, IDropdownItem } from '@Components/index'
import { IOffice, IBookFilter } from '@Store/types'
import { accounting, office, user } from '@Store/modules'

@Render
@Component({})
export class ContextBook extends Vue {

    // @State('user') stateUser: UserState
    // @State('accounting') stateAccounting: AccountingState 
    // @Getter('office/offices') offices: () => IOffice[]
    // @Getter('office/officesByUser') officesByUser: (usr_id: string) => IOffice[]
    // @Getter('user/isSubstituteUser') isSubstituteUser: boolean

    get filter(): IBookFilter {
		return accounting.bookFilter
	}
    get dateType(): InputDateType.Calendar {
		return InputDateType.Calendar
    }
    get officeItems(): IDropdownItem[] {
		let allOfficesItem: IDropdownItem = {value: '-1', label: this.$i18n.t('general.all-offices').toString()}
		if (user.isSubstituteUser) 
			return [allOfficesItem, ...office.offices.map(o => { return {value: o.id, label: o.coordinates.name}})]

		let usr_id: string = parseInt(user.user.id) === -1 ? undefined : user.user.id
		let offices: IOffice[] = office.officesByUser(usr_id)
	
		return [allOfficesItem, ...offices.map(o => { return {value: o.id, label: o.coordinates.name}})]
	}

	next(): void {
		let date = new Date(this.filter.day_date)
		date.setDate(date.getDate() + 1)

		this.filter.day_date = date
	}

	previous(): void {
		let date = new Date(this.filter.day_date)
		date.setDate(date.getDate() - 1)

		this.filter.day_date = date
	}
}