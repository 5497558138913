import Render from '@Views/root/user/gift/main.html'
import { Component } from 'vue-property-decorator'
import { AutoSaver } from '@Components/index'
import { ASideGift } from './aside-gift'
import { IGift } from '@Store/types'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-gift': ASideGift
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	submitted: boolean = false

	constructor() {
		super('gift', false)
	}

	// @State('user') stateUser: UserState
	// @Mutation('user/giftSuccess') giftSuccess: (gift: IGift) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Action('user/loadGift') loadGift: () => any

	get gift(): IGift {
		return user.gift
	}

	//#region IAutoSaver implementation
	restoreValue(value: IGift): void {
		user.giftSuccess(value)
	}

	save(): void {
	}
	//#endregion

	mounted() {
		this.canAccessOnGift()
		breadcrumb.updateLinked(false)
		this.initializeGift()
	}

	restoreGift(gift: IGift) {
		this.restoreValue(gift)
		this.initializeValue(gift)
	}

	private initializeGift(): void {
		user.loadGift()
		.then(() => { super.initializeValue() })
	}

   // Pour empecher l'acces au gift
	private canAccessOnGift(){
		if (!this.$route.path.startsWith('/gift')) return
		this.$router.replace('/user/informations');
	}
}
