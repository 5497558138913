<div v-if="!!settings">
	<collapsible-help id="settings-parameters-information" :title="$t('vm.root.user.office.parameters.title')" tag="h2">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<i18n path="vm.root.user.office.parameters.help.text" tag="span">
					<strong>{{ $t('vm.root.user.office.parameters.help.strong') }}</strong>
				</i18n>
			</p>
		</div>
	</collapsible-help>


	<switch-frame id="activate" class="marg-v" :title="$t('vm.root.user.office.parameters.activate.title')" v-model="settings.activate">
		{{ $t('vm.root.user.office.parameters.activate.label') }}
	</switch-frame>

	<div v-if="settings.activate">
		<div class="input-frame">
			<collapsible-help id="help-url-information" :title="$t('vm.root.user.office.parameters.url.title')"
				tag="label" tag-class="label" tag-for="url">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info marg-b">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.user.office.parameters.url.help.text" tag="span">
							<strong>{{ $t('vm.root.user.office.parameters.url.help.strong') }}</strong>
						</i18n>
					</p>
				</div>
			</collapsible-help>

			<div class="input-address-field">
				<div class="form-group">
					<div>
						<input class="form-group-input" type="text" :value="`https://osteo2ls.com/rdv/${settings.url}`" id="url"
							:title="$t('vm.root.user.office.parameters.url.title')" :readonly="true">
					</div>
					<button-action :loading="isGenerating" default-class="" class="input-like form-group-input is-flex-fixed misc-trigger"
						:title="$t('vm.root.user.office.parameters.url.button-title')" @click.native="generate">
						<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#sync"/>
						</svg>
						<span class="rwd-over-medium">{{ $t('vm.root.user.office.parameters.url.button-label') }}</span>
					</button-action>
				</div>
			</div>
		</div>

		<dropdown-frame id="planning" v-model="settings.pla_id" :items="planningItems"
			:title="$t('vm.root.user.office.parameters.planning.title')"
			:label="$t('vm.root.user.office.parameters.planning.label')" />

		<dropdown-frame :with-help="true" id="suggestion" v-model="suggestion" :items="suggestionItems"
			:label="$t('vm.root.user.office.parameters.suggestion.label')">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info marg-b">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					{{ $t('vm.root.user.office.parameters.suggestion.help') }}
				</p>
			</div>
		</dropdown-frame>

		<input-document-frame :is-light="true" id="message" v-model="settings.message"
			:options="lightOptions"
			:label="$t('vm.root.user.office.parameters.message.label')"
			:placeholder="$t('vm.root.user.office.parameters.message.placeholder')"
			:title="$t('vm.root.user.office.parameters.message.title')"/>

		<textarea-frame :is-light="true" id="default-rejection" v-model="settings.default_rejection"
			:label="$t('vm.root.user.office.parameters.default-rejection.label')"
			:placeholder="$t('vm.root.user.office.parameters.default-rejection.placeholder')"
			:title="$t('vm.root.user.office.parameters.default-rejection.title')"/>

		<input-frame type="number" id="max-week" min="1" max="52" step="1" v-model="settings.max_week"
			:label="$t('vm.root.user.office.parameters.max-week.label')"
			:title="$t('vm.root.user.office.parameters.max-week.title')"/>

		<input-frame type="number" :with-help="true" id="min-hour" min="0" max="24" step="1" v-model="settings.min_hour"
			:label="$t('vm.root.user.office.parameters.min-hour.label')"
			:title="$t('vm.root.user.office.parameters.min-hour.title')">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info marg-b">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					{{ $t('vm.root.user.office.parameters.min-hour.help1') }}
				</p>
				<i18n path="vm.root.user.office.parameters.min-hour.help2.text" tag="p" class="text-left">
					<strong>{{ $t('vm.root.user.office.parameters.min-hour.help2.strong') }}</strong>
				</i18n>
				<i18n path="vm.root.user.office.parameters.min-hour.help3.text" tag="p" class="text-left">
					<strong>{{ $t('vm.root.user.office.parameters.min-hour.help3.strong') }}</strong>
				</i18n>
			</div>
		</input-frame>

		<collapsible-help id="settings-parameters-validation" :title="$t('vm.root.user.office.parameters.validation.main-title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					{{ $t('vm.root.user.office.parameters.validation.help') }}
				</p>
			</div>
		</collapsible-help>

		<switch-frame id="auto-validate" v-model="settings.auto_validate" :title="$t('vm.root.user.office.parameters.validation.title')">
			{{ $t('vm.root.user.office.parameters.validation.label') }}
		</switch-frame>

		<collapsible-help id="settings-parameters-registration" :title="$t('vm.root.user.office.parameters.registration.main-title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.user.office.parameters.registration.help.text" tag="span">
						<router-link :to="to" class="text-bold" :title="$t('vm.root.user.office.parameters.registration.help.link-title')">{{ $t('vm.root.user.office.parameters.registration.help.link-label') }}</router-link>
					</i18n>
				</p>
			</div>
		</collapsible-help>

		<switch-frame id="sms-registration" :title="$t('vm.root.user.office.parameters.registration.title')" v-model="settings.register_sms">
			{{ $t('vm.root.user.office.parameters.registration.label') }}
		</switch-frame>

		<collapsible-help id="settings-parameters-alert" :title="$t('vm.root.user.office.parameters.alert-message.main-title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<i18n path="vm.root.user.office.parameters.alert-message.help.text" tag="span">
						<strong>{{ $t('vm.root.user.office.parameters.alert-message.help.strong') }}</strong>
					</i18n>
				</p>
			</div>
		</collapsible-help>

		<textarea-frame :is-light="true" id="alert-message" v-model="settings.alert_message"
						:label="$t('vm.root.user.office.parameters.alert-message.label')"
						:placeholder="$t('vm.root.user.office.parameters.alert-message.placeholder')"
						:title="$t('vm.root.user.office.parameters.alert-message.title')"/>

		<collapsible-help id="settings-parameters-free-space-mode" :title="$t('vm.root.user.office.parameters.free-space-mode.main-title')" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					{{ $t('vm.root.user.office.parameters.free-space-mode.help.text') }}
					<ul>
						<i18n path="vm.root.user.office.parameters.free-space-mode.help.li1.text" tag="li">
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li1.strong1') }}</strong>
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li1.strong2') }}</strong>
						</i18n>
						<i18n path="vm.root.user.office.parameters.free-space-mode.help.li2.text" tag="li">
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li2.strong1') }}</strong>
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li2.strong2') }}</strong>
							<em>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li2.em') }}</em>
						</i18n>
						<i18n path="vm.root.user.office.parameters.free-space-mode.help.li3.text" tag="li">
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li3.strong1') }}</strong>
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li3.strong2') }}</strong>
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li3.strong3') }}</strong>
							<strong>{{ $t('vm.root.user.office.parameters.free-space-mode.help.li3.strong4') }}</strong>
						</i18n>
					</ul>
				</p>
			</div>
		</collapsible-help>

		<dropdown-frame id="planning" v-model="settings.free_space_mode" :items="freeSpaceModeItems"
						:title="$t('vm.root.user.office.parameters.free-space-mode.title')"
						:label="$t('vm.root.user.office.parameters.free-space-mode.label')" />

	</div>

</div>
