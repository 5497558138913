import Render from '@Views/root/patients/patient/pediatrics/aside-pediatrics.html'
import { Component, Vue } from 'vue-property-decorator'
import { IPediatrics } from '@Store/types'
import { patient } from '@Store/modules'

@Render
@Component({})
export class ASidePediatrics extends Vue {

    // @Getter('patient/pediatrics') pediatrics: IPediatrics

    get pediatrics(): IPediatrics {
		return patient.pediatrics
	}
}