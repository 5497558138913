import {Emit, Vue} from 'vue-property-decorator'
import { VueCookies } from 'vue-cookies'
import config from "@Config/index"
import {GoogleEventHelper} from '@Helpers/google-event'
import {IEvent, IPlanning} from '@Store/types'
import {PlanningType} from "@Enums/planning"

const googleConfig = {
	'apiKey': config.googleApiKey,
	'clientId': config.googleClientId,
	'scope': config.googleScope,
	'script': "https://accounts.google.com/gsi/client"
}

const googleApiConfig = {
	'apiKey': config.googleApiKey,
	'discoveryDocs': config.googleDiscoveryUrls,
	'clientId': config.googleClientId,
	'scope': config.googleScope,
	'script': "https://apis.google.com/js/api.js"
}

export class GoogleService extends Vue {

	private client: any = undefined
	private accessToken: any = undefined
    private scriptLoaded:boolean = false
    private scriptApiLoaded:boolean = false
    private _authorized:boolean = false
    private _initializing:boolean = false
    private static _instance: GoogleService = null

    private constructor() {
        super()

		this.initializing = true
		this.loadGoogleScript(() => this.googleScriptLoaded())
		this.loadGoogleApiScript(() => this.googleApiScriptLoaded())
    }

	//#region Getters
	get authorized(): boolean {
		return this._authorized
	}
	set authorized(value: boolean) {
		if (value === this._authorized) return

		this.setAuthorized(value)
	}
	get initializing(): boolean {
		return this._initializing
	}
	set initializing(value: boolean) {
		if (value === this._initializing) return

		this.setInitializing(value)
	}
	get access_token(): any {
		return this.accessToken
	}
	//#endregion

	public static getInstance(): GoogleService {
		if (GoogleService._instance === null) {
			GoogleService._instance = new GoogleService()
		}
		return GoogleService._instance
	}

	authenticate() {
		if (!this.client) return

		this.client.requestAccessToken({prompt: 'consent'})
	}

	refreshToken() {
		if (!this.client) return

		this.client.requestAccessToken({prompt: ''})
	}

	revokeToken() {
		// const self = this
		// const access_token = this.accessToken
		const g = (window as any).google
		g.accounts.oauth2.revoke()
		// .then(() => {
		this.authorized = false
		this.initializing = false
		this.$cookies.set('google_token', '', -10000)
		// })

		const gapi = (window as any).gapi
		gapi.client.setToken('')
	}

	getPlanningList(): Promise<any> {
		const bearer = `Bearer ${this.accessToken}`
		return new Promise(function (resolve, reject) {
			const params = new URLSearchParams()
			params.set("showDeleted", "false")
			params.set("showHidden", "false")

			const xhr = new XMLHttpRequest()
			xhr.open('GET', `https://www.googleapis.com/calendar/v3/users/me/calendarList?${params.toString()}`)
			xhr.setRequestHeader('Authorization', bearer)
			xhr.onload = function () {
				if (this.status === 200) {
					let response = JSON.parse(this.responseText)
					resolve(response)
				} else {
					reject({
						status: this.status,
						statusText: xhr.statusText
					})
				}
			}
			xhr.onerror = function () {
				reject({
					status: xhr.status,
					statusText: xhr.statusText
				});
			}
			xhr.send()
		})
	}

	getEventList(start: Date, end: Date, calendar: IPlanning): Promise<any> {
		const params = new URLSearchParams()
		params.set("timeMin", (new Date(start)).toISOString())
		params.set("timeMax", (new Date(end)).toISOString())
		params.set("singleEvents", "true")
		params.set("showDeleted", "true")

		return this.getEventListByParams(params, calendar)
    }

    getEvent(evt_id: string, pla_id: string): Promise<any> {
		const bearer = `Bearer ${this.accessToken}`
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', `https://www.googleapis.com/calendar/v3/calendars/${pla_id}/events/${evt_id}`)
			xhr.setRequestHeader('Authorization', bearer)
			xhr.onload = function () {
				if (this.status === 200) {
					let response = JSON.parse(this.responseText)
					return resolve(response)
				} else {
					reject({
						status: this.status,
						statusText: xhr.statusText
					})
				}
			}
			xhr.onerror = function () {
				reject({
					status: xhr.status,
					statusText: xhr.statusText
				});
			}
			xhr.send()
		})
    }

    searchEvent(query: string, pla_id: string) {
		const params = new URLSearchParams()
		params.set("q", query)

		return this.getEventListByParams(params, {id: pla_id, type: PlanningType.Google})
    }

    delete(event: IEvent) {
		const gapi = (window as any).gapi
		return gapi.client.calendar.events.delete({
            'calendarId': event.old_pla_id,
            'eventId': event.id
        })
    }

    save(event: IEvent) {
        let resource = GoogleEventHelper.convertEventToGoogleItem(event)
		if (parseInt(event.id) === -1) {
            resource.id = undefined
            return GoogleService.create(resource, event.pla_id)
        }

		return GoogleService.update(resource, event.pla_id)
    }

    private static create(resource: any, calendarId: string) {
		const gapi = (window as any).gapi
		return gapi.client.calendar.events.insert({
            'calendarId': calendarId,
            'resource': resource
        })
    }

    private static update(resource: any, calendarId: string) {
		const gapi = (window as any).gapi
		return gapi.client.calendar.events.patch({
            'calendarId': calendarId,
            'eventId': resource.id,
            'resource': resource
        })
    }

    @Emit('authorized-updated')
    private setAuthorized(value: boolean) {
        this._authorized = value
    }

    @Emit('initializing-updated')
    private setInitializing(value: boolean) {
        this._initializing = value
    }

	private getEventListByParams(params: URLSearchParams, calendar: IPlanning): Promise<any> {
		const bearer = `Bearer ${this.accessToken}`
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendar.id)}/events?${params.toString()}`)
			xhr.setRequestHeader('Authorization', bearer)
			xhr.onload = function () {
				if (this.status === 200) {
					let response = JSON.parse(this.responseText)
					let items = response.items
					for (let element of items) {
						element.calendar = calendar
					}
					return resolve(items)
				} else {
					reject({
						status: this.status,
						statusText: xhr.statusText
					})
				}
			}
			xhr.onerror = function () {
				reject({
					status: xhr.status,
					statusText: xhr.statusText
				});
			}
			xhr.send()
		})
	}

	private loadGoogleScript(fn: any): void {
		if (this.scriptLoaded) {
			this.loadToken()
		} else {
			GoogleService.loadScript(googleConfig.script, fn)
		}
	}

	private loadGoogleApiScript(fn: any): void {
		if (this.scriptApiLoaded) {
			this.initializeGapiClient()
		} else {
			GoogleService.loadScript(googleApiConfig.script, fn)
		}
	}

	private static loadScript(src: string, fn: any): void {
		if ((window as any).google !== undefined) {
			fn()
			return
		}
		const script = document.createElement("script")
		script.src = src
		script.onload = fn
		document.head.appendChild(script)
	}

	private googleScriptLoaded(): void {
		this.scriptLoaded = true
		let self = this
		const g = (window as any).google
		self.client = g.accounts.oauth2.initTokenClient({
			client_id: googleConfig.clientId,
			scope: googleConfig.scope,
			callback: (tokenResponse) => {
				self.$cookies.set('google_token', tokenResponse.access_token, parseInt(tokenResponse.expires_in))
				self.loadToken()
			}
		})

		this.loadToken()
	}

	private loadToken() {
		let token = this.$cookies.get('google_token') //GoogleService.getCookie('google_token')
		if (!token) return

		this.accessToken = token
		this.setAuthorized(true)
		this.initializing = false

		this.initializeGapiClient()
	}

	private googleApiScriptLoaded(): void {
		let self = this
		const gapi = (window as any).gapi
		gapi.load('client', function() {
			self.scriptApiLoaded = true
			self.initializeGapiClient()
		})
	}

	@Emit('token-refreshed')
	private initializeGapiClient() {
		if (!this.authorized) return
		if (!this.scriptApiLoaded) return

		const gapi = (window as any).gapi
		gapi.client.init({
			apiKey: googleApiConfig.apiKey,
			discoveryDocs: googleApiConfig.discoveryDocs,
		})
		.then(() => {
			gapi.client.setToken({access_token: this.accessToken})
		})
	}
}
