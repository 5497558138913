import Render from '@Views/components/avatar.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ColorHelper } from '@Helpers/index'
import { GravatarService } from '@Services/index'
import { Debouncer, Gravatar } from '@Models/index'
import { Gender } from '@Enums/index'
import { configuration } from '@Store/modules'

@Render
@Component({})
export class Avatar extends Vue {

	private service: GravatarService = null
	private hasAvatar: boolean = false
	private alphabetFirstLetter: number
	private alphabetCount: number
	private debouncer: Debouncer

	@Prop() email: string
	@Prop() firstName: string
	@Prop() lastName: string
	@Prop({default: Gender.Man}) gender: Gender
	@Prop({default: false}) disableAnonymous: boolean

	get img(): string {
		return `https://www.gravatar.com/avatar/${this.gravatarId}?s=144&d=${this.defaultAvatar}`
	}
	get gravatarExist(): boolean {
		return (!configuration.isAnonymous || this.disableAnonymous) && this.hasAvatar
	}
	get color(): string {
		let a: number = this.firstLetterFirstName.toLowerCase().charCodeAt(0)
		let b: number = this.lastLetterLastName.charCodeAt(0)
		let c: number = this.firstLetterLastName.toLowerCase().charCodeAt(0)
		let d: number = this.lastLetterFirstName.charCodeAt(0)

		// With latin chars, we will have an alphabet from 0 to 25
		// Conditions for keeping possibilities for other chars
		if (a >= this.alphabetFirstLetter ) a -= this.alphabetFirstLetter
		if (c >= this.alphabetFirstLetter ) c -= this.alphabetFirstLetter

		// Magic!
		let h: number = Math.round(((360 * a) / this.alphabetCount) + (b % 2 ? b : -b))
		let s: number = 85 + (b % 2 ? c / 2 : -c / 2)
		let l: number = 70 + (d % 2 ? c : -c)

		// Boundaries
		s = Math.min(Math.max(s, 0), 100)
		l = Math.min(Math.max(l, 50), 90)

		return `hsl(${h},${s}%,${l}%)`
	}
	get initials(): string {
		return `${this.firstLetterFirstName}${this.firstLetterLastName}`
	}
	get isDark(): string {
		return ColorHelper.isDark(ColorHelper.HSLToHex(this.color)) ? 'is-light' : 'is-dark'
	}
	private get defaultAvatar(): string {
		return `https://osteo2ls.com/assets/img/${this.gender === Gender.Woman ? 'female' : (this.gender === Gender.Man ? 'male' : 'other')}.png`
	}
	private get gravatarId(): string {
		return Gravatar.getGravatarId(this.email)
	}
	private get firstLetterFirstName(): string {
		if (!this.firstName && !this.lastName) return ';'
		if (!this.firstName) return '…'
		return this.firstName.substring(0, 1).toUpperCase()
	}
	private get firstLetterLastName(): string {
		if (!this.firstName && !this.lastName) return ')'
		if (!this.lastName) return '…'
		return this.lastName.substring(0, 1).toUpperCase()
	}
	private get lastLetterFirstName(): string {
		if (!this.firstName) return ' '
		return this.firstName.slice(-1).toLowerCase()
	}
	private get lastLetterLastName(): string {
		if (!this.lastName) return ' '
		return this.lastName.slice(-1).toLowerCase()
	}

	created() {
		this.alphabetFirstLetter = "a".charCodeAt(0),
		this.alphabetCount = "z".charCodeAt(0) - this.alphabetFirstLetter
		this.service = new GravatarService()
		this.debouncer = new Debouncer(this.checkAvatar, 350)
		this.checkAvatar()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	@Watch('email')
	updateEmail() {
		this.debouncer.start()
	}

	private checkAvatar(): void {
		this.hasAvatar = false
		if (Gravatar.defaultGravatarId === this.gravatarId)
			return

		try {
			this.service.exists(this.gravatarId)
			.then(response => {
				if (response.status !== 200) return

				this.hasAvatar = response.data.entry[0].requestHash === this.gravatarId
			}, error => {})
			.catch(error => {})
		} catch(err) {}
	}
}
