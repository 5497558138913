<div v-if="pediatrics">
    <h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.neonatal.title') }}</h2>

    <misc-collapsible-panel>
        <span slot="title">{{ $t('vm.root.patients.patient.neonatal.subtitle1') }}</span>
        <div class="no-marg">
            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.premature.label') }}</strong> : {{ pediatrics.with_premature ? $t('general.yes') : $t('general.no') }}<br>
                <span v-if="pediatrics.with_premature && !!pediatrics.premature" v-html="pediatrics.premature" />
            </p>

            <div class="grid-2">
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.weight.label') }}</strong> : {{ pediatrics.weight }}</p>
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.size.label') }}</strong> : {{ pediatrics.size }}</p>
                
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.apgar1.label') }}</strong> : {{ pediatrics.with_apgar1 ? pediatrics.apgar1 : '-' }}</p>
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.apgar3.label') }}</strong> : {{ pediatrics.with_apgar3 ? pediatrics.apgar3 : '-' }}</p>
                
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.apgar5.label') }}</strong> : {{ pediatrics.with_apgar5 ? pediatrics.apgar5 : '-' }}</p>
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.apgar10.label') }}</strong> : {{ pediatrics.with_apgar10 ? pediatrics.apgar10 : '-' }}</p>
                
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.alimentation') }}</strong> : {{ pediatrics.alimentation | alimentation }}</p>
                <p><strong>{{ $t('vm.root.patients.patient.neonatal.cyanotic') }}</strong> : {{ pediatrics.cyanotic ? $t('general.yes') : $t('general.no') }}</p>
            </div>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.reanimation.label') }}</strong> : {{ pediatrics.with_reanimation ? $t('general.with') : $t('general.without') }}<br>
                <span v-if="pediatrics.with_reanimation && !!pediatrics.reanimation" v-html="pediatrics.reanimation" />
            </p>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.scream.label') }}</strong> : {{ pediatrics.with_scream ? $t('general.yes') : $t('general.no') }}<br>
                <span v-if="pediatrics.with_scream && !!pediatrics.scream" v-html="pediatrics.scream" />
            </p>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.deformed-skull.label') }}</strong> : {{ pediatrics.with_deformed_skull ? $t('general.yes') : $t('general.no') }}<br>
                <span v-if="pediatrics.with_deformed_skull && !!pediatrics.deformed_skull" v-html="pediatrics.deformed_skull" />
            </p>
            
            <p class="text-pre-line" v-if="pediatrics.remarks4"><strong>{{ $t('vm.root.patients.patient.neonatal.remarks4.label') }}</strong> :<br><span v-html="pediatrics.remarks4"></span></p>
        </div>
        <hr>
    </misc-collapsible-panel>

    <misc-collapsible-panel>
        <span slot="title">{{ $t('vm.root.patients.patient.neonatal.subtitle2') }}</span>
        <div class="no-marg">
            <strong v-if="pediatrics.suckle">{{ $t('vm.root.patients.patient.neonatal.suckle') }}</strong>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.reflux.label') }}</strong> : {{ pediatrics.with_reflux ? $t('general.yes') : $t('general.no') }}<br>
                <span v-if="pediatrics.with_reflux && !!pediatrics.reflux" v-html="pediatrics.reflux" />
            </p>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.allergies.label') }}</strong> : {{ pediatrics.with_allergies ? $t('general.yes') : $t('general.no') }}<br>
                <span v-if="pediatrics.with_allergies && !!pediatrics.allergies" v-html="pediatrics.allergies" />
            </p>

            <p v-if="!!pediatrics.sleep" class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.sleep.label') }}</strong> :<br>
                <span v-html="pediatrics.sleep" />
            </p>

            <p v-if="!!pediatrics.state" class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.neonatal.state.label') }}</strong> :<br>
                <span v-html="pediatrics.state" />
            </p>

            <p class="text-pre-line" v-if="pediatrics.remarks5"><strong>{{ $t('vm.root.patients.patient.neonatal.remarks5.label') }}</strong> :<br><span v-html="pediatrics.remarks5"></span></p>
        </div>
        <hr>
    </misc-collapsible-panel>
</div>
