import { IGoogleEvent } from './google-event'
import base64 from 'base-64'
import utf8 from 'utf8'
import { PlanningType, ReminderType } from '@Enums/index'
import {IConfiguration, IEvent} from '@Store/types'
import { DateHelper } from './date'
import { Recurring, Relaunch } from '@Models/index'

export class EventHelper {

	public static getEventUrl(event:{id?: string, pla_id?: string, type: PlanningType, unvalid?: boolean}): {name: string, params?: any} {
		if (event.unvalid)
			return {name: 'valid-event', params: {'evt_id': event.id}}

		if (event.type === PlanningType.Google)
			return {name: 'google-event', params: {'evt_id': event.id, 'pla_id': event.pla_id}}

		return {name: 'event', params: {'evt_id': event.id}}
	}

	public static initializeEvent(event: IEvent, configuration: IConfiguration):IEvent {
		event.type = PlanningType.Classic
		event.old_type = PlanningType.Classic
		let { pla_id } = event
		event.old_pla_id = pla_id
		event.patient = !event.patient ? null : event.patient
		event.relaunch = !event.relaunch ? Relaunch.createNewRelaunch(configuration) : event.relaunch
		event.recurring = !event.recurring ? Recurring.createNewRecurring(event.start_date) : event.recurring

		return event
	}

	public static convertGoogleItemToEvent(entry: IGoogleEvent, pla_id: string):IEvent {
		if (entry === undefined) return undefined;
		if (entry.status === 'cancelled') return undefined;

		let event = {
			id: entry.id,
			label: entry.summary,
			recurring_update: false,
			reminder_mail: EventHelper.getReminderMail(entry),
			reminder_sms: EventHelper.getReminderSms(entry),
			reminder_type: EventHelper.getReminderType(entry),
			confirmation_type: EventHelper.getConfirmationType(entry),
			missed: EventHelper.isMissed(entry),
			unvalid: false,
			value: true,
			pla_id: pla_id,
			old_pla_id: pla_id,
			ofi_id: EventHelper.getOfiId(entry),
			ses_id: EventHelper.getSesId(entry),
			type: PlanningType.Google,
			old_type: PlanningType.Google,
			start_date: new Date(),
			end_date: new Date(),
			all_day: false,
			pat_id: EventHelper.getPatId(entry),
			patient: null
		}

		if (!EventHelper.updateDateFields(event, entry, 'start_date', 'end_date', 'all_day')) return event

		let patient = EventHelper.getPatient(entry)
		if (patient !== undefined) {
			event.patient = patient
			event.pat_id = patient.id
		}

		return event;
	}

	public static isMissed(entry: IGoogleEvent): boolean {
		return EventHelper.decodeDescription(entry.description, 'missed', false)
	}

	public static getOfiId(entry: IGoogleEvent): string {
		return EventHelper.decodeDescription(entry.description, 'ofi_id', null)
	}

	public static getPatId(entry: IGoogleEvent): string {
		return EventHelper.decodeDescription(entry.description, 'pat_id', null)
	}

	public static getReminderType(entry: IGoogleEvent): ReminderType {
		return EventHelper.decodeDescription(entry.description, 'reminder_type', ReminderType.Both)
	}

	public static getConfirmationType(entry: IGoogleEvent): ReminderType {
		return EventHelper.decodeDescription(entry.description, 'confirmation_type', ReminderType.Both)
	}

	public static getSesId(entry: IGoogleEvent): string {
		return EventHelper.decodeDescription(entry.description, 'ses_id', null)
	}

	public static getPatient(entry: IGoogleEvent): any {
		if (entry === undefined) return
		if (entry.attendees === undefined) return

		for(let attendee of entry.attendees) {
			if (attendee.comment === undefined) continue

			let comment = attendee.comment.split(':')
			if (comment === undefined || comment.length <= 1 || comment[0] !== 'osteo2ls') continue

			let patient = EventHelper.base64Decode(comment[1])
			// patient.enabled = $rootScope.user.can_add_patient

			return patient
		}

		return EventHelper.getPatientByDescription(entry)
	}

	public static getPatientByDescription(entry: IGoogleEvent): any {
		if (entry === undefined || entry.description === undefined) return

		let description = entry.description.split(';')
		if (description === undefined || description.length < 2) return

		let comment = description[1].split(':')
		if (comment === undefined || comment.length <= 1 || comment[0] !== 'patient_osteo2ls') return

		let patient = EventHelper.base64Decode(comment[1])
		// patient.enabled = $rootScope.user.can_add_patient

		return patient
	}

	public static updateDateFields(event: any, entry: IGoogleEvent, field1: string, field2: string, field3: string): boolean {
		if (entry.start === undefined || entry.end === undefined) return false

		let all_day:boolean = !entry.start.dateTime && !entry.end.dateTime
		let start:Date = new Date(entry.start.dateTime || entry.start.date)
		let end:Date = new Date(entry.end.dateTime || entry.end.date)
		if (all_day) end.setDate(end.getDate() - 1)

		event[field1] = all_day ? DateHelper.getDateString(start) : start.toISOString().split('.')[0] + 'Z'
		event[field2] = all_day ? DateHelper.getDateString(end) : end.toISOString().split('.')[0] + 'Z'
		event[field3] = all_day

		return true
	}

	public static isGoogleEvent(object: any): object is IGoogleEvent {
		return 'etag' in object
	}

	public static getReminderMail(entry: IGoogleEvent): false {
		return EventHelper.decodeDescription(entry.description, 'reminder_mail', false)
	}

	public static getReminderSms(entry: IGoogleEvent): false {
		return EventHelper.decodeDescription(entry.description, 'reminder_sms', false)
	}

	private static base64Decode(encoded: string): string {
		let bytes = base64.decode(encoded)
		let text = utf8.decode(bytes)
		return JSON.parse(text)
	}

	private static decodeDescription(description: string, key: string, default_value: any): any {
		let description_array_tmp = description === undefined ? undefined : description.split(';')
		if (description_array_tmp === undefined || description_array_tmp.length === 0) return default_value

		let description_array = description_array_tmp[0].split(':')
		let decoded_description = undefined
		if (description_array !== undefined && description_array.length > 1 && description_array[0] === 'osteo2ls') {
			decoded_description = EventHelper.base64Decode(description_array[1])
		}
		if (decoded_description === undefined || decoded_description[key] === undefined) return default_value
		if (parseInt(decoded_description[key]) === -1) return '-1'
		return decoded_description[key]
	}
}

