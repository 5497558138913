import Render from '@Views/root/patients/campaigns/aside-campaigns.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ICampaign } from '@Store/types'
import { CampaignStatus } from '@Enums/index'
import { campaign } from '@Store/modules'

@Render
@Component({})
export class ASideCampaigns extends Vue {

	campaigns: ICampaign[] = []

	// @State('campaign') campaignState: CampaignState
	// @Action('campaign/loadCampaigns') loadCampaigns: () => Promise<any>

	get isLoading(): boolean {
		return campaign.isLoading
	}

	mounted() {
		campaign.loadCampaigns()
		.then((campaigns: ICampaign[]) => { this.campaigns = campaigns})
	}

	isSended(_campaign: ICampaign): boolean {
		return campaign.isSended(_campaign)
	}
}
