<div>
	<h1>{{ $t('vm.root.statistics.top10.main.title') }}</h1>

	<div v-if="isLoading" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.statistics.top10.main.loading') }}
		</div>
	</div>

	<div v-else class="grid-2 vertical-gap">
		<top10-table :data="missed" property="missed_count"
			:title="$t('vm.root.statistics.top10.main.missed-title')" 
			:column-name="$t('vm.root.statistics.top10.main.missed-column')" />
		<top10-table :data="cancelled" property="cancelled_count"
			:title="$t('vm.root.statistics.top10.main.cancelled-title')" 
			:column-name="$t('vm.root.statistics.top10.main.cancelled-column')" />
		<top10-table :data="consultation" property="consultation_count"
			:title="$t('vm.root.statistics.top10.main.consultation-title')" 
			:column-name="$t('vm.root.statistics.top10.main.consultation-column')" />
	</div>
</div>
