import * as Letters from '@ViewModels/root/letters'
import { Profile } from '@Enums/profile'

export const LettersRoot = [
	{
		path: 'letters', 
		name:'letters', 
		component: Letters.Main,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
]
