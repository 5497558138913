<context>

	<div>
		<section class="wrapper marg-v">
			<h2>{{ $t('vm.root.letters.context.title') }}</h2>

			<dropdown-frame id="model" v-model="filter.doc_id" :items="models"
				:default-label="$t('vm.root.letters.context.model.default-label')"
				:label="$t('vm.root.letters.context.model.label')"
				:title="$t('vm.root.letters.context.model.title')"/>

			<div v-if="needPatient">
				<search-patient :can-create="false" :action="setPatient" :list="lastReadPatientList" :label="$t('vm.root.letters.context.patient')"/>
				<div v-if="!!patient" class="vertical-gap marg-v">
					<patient-item :patient="patient" :without-open="true" :without-unselect="true"/>
				</div>
			</div>

		</section>

		<section class="wrapper marg-v" :class="{'text-center': isConsultationLoading}" v-if="needConsultation && !!patient">
			<spinner class="svg-xl text-primary" v-if="isConsultationLoading"/>
			<dropdown-frame v-else-if="!!consultationItems && consultationItems.length > 0" id="consultation" v-model="filter.con_id" :items="consultationItems"
				:default-label="$t('vm.root.letters.context.consultation.default-label')"
				:label="$t('vm.root.letters.context.consultation.label')"
				:title="$t('vm.root.letters.context.consultation.title')"/>
			<span v-else>{{ $t('vm.root.letters.context.consultation.empty') }}</span>
			</section>

		<section class="wrapper marg-v" :class="{'text-center': isInvoiceLoading}" v-if="needInvoice && !!patient">
			<spinner class="svg-xl text-primary" v-if="isInvoiceLoading"/>
			<dropdown-frame v-else-if="!!invoiceItems && invoiceItems.length > 0" id="invoice" v-model="filter.inv_id" :items="invoiceItems"
				:default-label="$t('vm.root.letters.context.invoice.default-label')"
				:label="$t('vm.root.letters.context.invoice.label')"
				:title="$t('vm.root.letters.context.invoice.title')"/>
			<span v-else>{{ $t('vm.root.letters.context.invoice.empty') }}</span>
		</section>

		<section class="wrapper marg-v" v-if="needOffice">
			<dropdown-frame v-if="!!officeItems && officeItems.length > 0" id="office" v-model="filter.ofi_id" :items="officeItems"
				:default-label="$t('vm.root.letters.context.office.default-label')"
				:label="$t('vm.root.letters.context.office.label')"
				:title="$t('vm.root.letters.context.office.title')"/>
			<span v-else>{{ $t('vm.root.letters.context.office.empty') }}</span>
		</section>

	</div>

</context>
