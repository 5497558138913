export const offices = {
    title: "Cabinet | Cabinet | Cabinets",
    'new-office': {
        title: "Créer un nouveau cabinet",
        label: "Nouveau cabinet"
    },
    'no-active': {
        label: "Pas de cabinet actif",
        text: "Ajoutez un {0}.",
        'button-title': "Créez un nouveau cabinet",
        'button-label': "nouveau cabinet"
    },
    enabled: "activé",
    disabled: "désactivé",
    'details-enabled': {
        owner: "Ce cabinet a été créé par {0} {1}",
        'no-owner': "Le créateur de ce cabinet n'y travaille plus",
        update: {
            title: "Modifiez les informations de ce cabinet",
            label: "modifier"
        },
        delete: {
            msg: "Voulez-vous vraiment désactiver ce cabinet ?",
            title: "Désactivation",
            label: "désactiver"
        }
    },
    'details-disabled': {
        title: "Cabinet inactif | Cabinet inactif | Cabinets inactifs",
        warning: "Les cabinets sont désactivés (et non supprimés) car ils sont potentiellement liés à des consultations, des rdv, des factures. Pour ne pas créer d'erreur, le cabinet n'est donc pas supprimé, mais n'est plus listé dans vos cabinets actifs.",
        'allow-access': "Seul le créateur de ce cabinet peut réactiver cet accès.",
        enabled: {
            title: "Activer l'accès à ce cabinet",
            label: "activer"
        }
    }
}
