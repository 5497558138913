<div v-if="options" class="popin-person-search">

	<div class="with-icon">
		<svg width="1em" class="with-icon-icon" :class="{'is-shown': !searching}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
		</svg>
		<span class="with-icon-icon" :class="{'is-shown': searching}">
			<spinner class="text-primary"/>
		</span>
		<input :type="isAnonymous ? 'password' : 'search'" v-model="query" class="form-group-input" ref="popin-search-member"
			:id="`${random}-popin-search-member`"
			:title="$t('vm.root.search.member.search.title')"
			:placeholder="$t('vm.root.search.member.search.placeholder')">
	</div>

	<div v-if="resultSearch.length <= 0" class="popin-person-search-no-result" :class="{'is-white-space': !searched || !query || searching}">
		<div>
			<label for="popin-search-member">
				<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
			</label>
			<div class="text-md text-bold text-center marg-v">
				<span v-if="!!searching">{{ $t('vm.root.search.member.search.searching') }}</span>
				<span v-else>{{ $t('vm.root.search.member.search.no-result') }}</span>
			</div>
		</div>
	</div>

	<div v-else>
		<div class="grid-2 vertical-gap marg-v" v-for="member in resultSearch" :key="member.link_id">
			<div class="misc-item">
				<label class="misc-item-content">
					<span class="misc-item-icon">
						<avatar :first-name="member.first_name" :last-name="member.last_name" :email="member.email" :gender="member.gender"/>
					</span>
					<span class="misc-item-label">
						<span class="text-capitalize"><anonymous :text="member.first_name"/> <anonymous tag="b" :text="member.last_name"/></span>
					</span>
				</label>
			</div>
			<dropdown :items="memberItems(member.gender)" :value="member.type" @input="updateType($event, member)"
				:title="$t('vm.root.search.member.search.type.title')"/>
		</div>
	</div>

</div>
