export const aside = {
    search: {
        placeholder:"ex : Largo Winch",
        title:"Veuillez renseigner le nom d'un patient"
    },
	back: {
		text: "Retour aux patients",
		title: "Retournez à votre liste de patient"
	},
	searching: 'recherche en cours…',
    'activated-filter': 'aucun filtre | {count} filtre activé | {count} filtres activés',
    filter: {
        'last-name': 'nom',
        'first-name': 'prénom',
        profession: 'profession',
        email: 'adresse email',
        phone: 'téléphone (+33…)',
        address: 'code postal',
        'birth-date': 'date de naissance (jj/mm/aaaa)'
    },
	reinitialize: {
    	label: "réinitialiser",
    	title: "Réinitialiser l'index",
    	'button-label': "réinitialiser l'index",
    	content: "Par défaut, l'index du moteur de recherche se réinitialise automatiquement chaque nuit. En le réinitialisant manuellement, vous permettez à ce dernier de se mettre à jour lors de votre prochaine recherche. Celle-ci sera donc légèrement plus longue, le temps de reconstruire cet index.",
    	success: "Votre index a bien été réinitialisé.",
    	failed: "La réinitialisation de votre index a échouée. Veuillez réessayer plus tard.",
	},
    result: 'aucun résultat | {count} résultat | {count} résultats'
}
