import Render from '@Views/root/calendar/event/time-panel.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPlanning, IOffice, IEvent } from '@Store/types'
import { InputRangeType, IDropdownItem, InputDateType } from '@Components/index'
import { DropdownHelper } from '@Helpers/index'
import { PlanningType } from '@Enums/index'
import { office, planning, user } from '@Store/modules'

@Render
@Component({})
export class TimePanel extends Vue {

	duration: number = 45
	private lock: boolean = false

	@Prop() event: IEvent

	//#region Getters
	get rangeType(): InputRangeType.RangeDate {
		return InputRangeType.RangeDate
	}
	get isClassic(): boolean {
		return this.event.type === PlanningType.Classic
	}
	get dateType(): InputDateType.CalendarTime {
		return InputDateType.CalendarTime
	}
	get range(): Date[] {
		return [this.event.start_date as Date, this.event.end_date as Date]
	}
	set range(range: Date[]) {
		this.event.start_date = range[0]
		range[1].setHours(2)
		this.event.end_date = range[1]
	}
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = [{value: '-1', label: this.$i18n.t('vm.root.calendar.event.rendez-vous.office.none').toString()}]
		let ofi_id: string = parseInt(this.event.ofi_id) === -1 ? undefined : this.event.ofi_id
		let offices: IOffice[] = office.offices
		for (let _office of offices) {
			if (!office.isActive(_office) && _office.id !== ofi_id) continue

			result.push({value: _office.id, label: _office.coordinates.name})
		}

		return result
	}
	get planningItems(): IDropdownItem[] {
		if (!user.isSecretaryUser)
			return DropdownHelper.toDropdownItems(planning.editablePlannings)

		if (!this.selectedUser) return []
		return DropdownHelper.toDropdownItems(planning.editablePlannings.filter(p => { return p.usr_id === this.selectedUser.id}))
	}
	private get selectedUser(): any {
		return user.selectedPlanningUser || {}
	}
	//#endregion

	mounted() {
		this.updateDuration()
	}

	hasSessions(id: string): boolean {
		return office.hasSessions(id)
	}

	@Watch('event.pla_id')
	updatePanning(): void {
		let _planning: IPlanning = planning.editablePlannings.find(p => { return p.id === this.event.pla_id })
		if (!_planning || !_planning.type) return

		this.event.type = _planning.type
	}

	@Watch('event.ofi_id')
	updateOffice(): void {
		let _office = office.offices.find(office => office.id === this.event.ofi_id)
		let sessions = office.sessions(this.event.ofi_id)
		this.event.ses_id = sessions.length > 0 ? sessions[0].id : "-1"
		let session = office.sessions(this.event.ofi_id).find(session => session.id === this.event.ses_id)

		this.updateDefaultDuration(_office, session)
	}

	@Watch('event.ses_id')
	updateSession(): void {
		let _office = office.offices.find(office => office.id === this.event.ofi_id)
		let session = office.sessions(this.event.ofi_id).find(session => session.id === this.event.ses_id)

		this.updateDefaultDuration(_office, session)
		if (!session || !session.with_color || !session.color) return
		this.event.with_color = true
		this.event.color = session.color
	}

	@Watch('duration')
	@Watch('event.start_date')
	updateEndDate(): void {
		if (this.lock) return
		if (this.duration === undefined) return
		if (this.event.all_day) return

		this.lock = true
		let date = new Date(this.event.start_date);
		date.setTime(date.getTime() + this.duration * 60000)
		this.event.end_date = date
		this.lock = false
	}

	private updateDuration(): void {
		if (this.lock) return
		if (this.event.all_day) return

		this.lock = true
		let start = new Date(this.event.start_date)
		let end = new Date(this.event.end_date)
		let duration = end.getTime() - start.getTime()
		this.duration = Math.floor(duration / 60000)
		this.lock = false
	}

	sessionItems(ofi_id: string): IDropdownItem[] {
		return office.sessions(ofi_id)
		.map(s => {
			let item: IDropdownItem = { value: s.id, label: s.name }
			if (s.with_color && !!s.color) {
				item.icon = 'circle'
				item.color = s.color
			}
			return item
		})
	}

	private updateDefaultDuration(office: any, session: any): void {
		this.duration = !!session ? session.duration : !!office ? office.duration : this.duration
	}
}
