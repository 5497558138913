import Render from '@Views/components/delete-item.html'
import { i18n } from '@I18n/index'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPopin, IPopinAction } from '@Store/types'
import { popIn } from '@Store/modules'

@Render
@Component({})
export class DeleteItem extends Vue {

	private deleted: boolean = false

	@Prop({default: false}) disabled: boolean
	@Prop({default: i18n.t('general.delete.title').toString()}) title: string
	@Prop({default: "Voulez-vous vraiment supprimer cet élément ?"}) deleteMsg: string
	@Prop({default: i18n.t('general.suppress.title').toString()}) deleteLabel: string
	@Prop({default: "is-delete"}) btnClass: string
	@Prop() deleteAction: () => void
	@Prop() isDeleting: boolean
	@Prop() canDelete: boolean

	submitDelete(): void {
		this.deleted = true
		if (!this.canDelete) return

		this.delete()
	}

	private delete(): void {
		let deleteAction: IPopinAction = {
			label: this.deleteLabel,
			title: this.title,
			callback: (data: any) => {
				this.deleteAction()
				return Promise.resolve()
			}
		}
		let deletePopin: IPopin = {
			title: this.title,
			content: this.deleteMsg,
			action: deleteAction
		}

		popIn.warning(deletePopin)
	}
}
