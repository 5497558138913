import Render from '@Views/components/popin/layout/popin-sticky-note-share.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ILinkUser } from '@Store/types'
import { user } from '@Store/modules'

@Render
@Component({})
export class PopinStickyNoteShare extends Vue {
	
	@Prop() options: {shareList: any}
    @Prop({default: false}) submitted: boolean

	get users(): ILinkUser[] {
		return user.user.links
	}
}