import {IUser} from '@Store/types'
import Render from '@Views/components/popin/layout/popin-subscription.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {IDropdownItem} from "@ViewModels/components";

@Render
@Component({})
export class PopinSubscription extends Vue {

    @Prop() options: {user: IUser, choice: string, subscription: string}
	@Prop({default: false}) submitted: boolean

	checked:boolean = false;

	get isUserSubscribed(): boolean {
		return this.options.user.license === 99999;
	}
	get choice(): string {
		return this.options.choice;
	}
	set choice(value: string) {
		this.options.choice = value;
	}
	get subscription(): string {
		return this.options.subscription;
	}
	set subscription(value: string) {
		this.options.subscription = value;
	}
	get subscriptionItems(): IDropdownItem[] {
		return [
			{value: '12', label: this.$t('vm.components.popin.subscription.subscription-items.annually').toString()},
			{value: '1', label: this.$t('vm.components.popin.subscription.subscription-items.monthly').toString()}
		]
	}
	get expirationDate(): string {
		const expiration = new Date('2026-01-01')
		expiration.setMonth(expiration.getMonth() + this.options.user.trial_period || 0)
		return expiration.toLocaleDateString()
	}
	get paymentDate(): string {
		const expiration = new Date('2025-01-01')
		expiration.setMonth(expiration.getMonth() + this.options.user.trial_period || 0)
		return expiration.toLocaleDateString()
	}

    @Watch('checked')
    updateStorage() {
        // StorageHelper.set(this.options.storageKey.toString(), this.checked)
        // if (!this.checked) return
        // if (!this.isUserSubscribed) return
		//
        // const email = this.options.user.email
        // const baseURL = 'https://confirm.matiscode.fr'
        // const provider: any = axios.create({baseURL})
		//
        // provider.get('/client/confirm-read', {params: {email, emailModel: 'client'}})
    }

}
