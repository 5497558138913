<div v-if="pediatrics">
	<read-only v-if="readOnly" :pediatrics="pediatrics"/>
	<div v-else>

		<h2>{{ $t('vm.root.patients.patient.neonatal.title') }}</h2>

		<misc-collapsible-panel>
			<span slot="title">{{ $t('vm.root.patients.patient.neonatal.subtitle1') }}</span>
			<div class="grid-2">
				<togglebox-frame id="with-premature" v-model="pediatrics.with_premature" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.premature.with-label')"/>
				<textarea-frame id="premature" v-show="pediatrics.with_premature" v-model="pediatrics.premature" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.premature.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.premature.label')"
					:title="$t('vm.root.patients.patient.neonatal.premature.label')"/>
			</div>

			<div class="grid-2">
				<input-frame type="number" id="weight" v-model="pediatrics.weight" step="0.01" min="0" max="200"
					:label="$t('vm.root.patients.patient.neonatal.weight.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.weight.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.weight.title')"/>
				<input-frame type="number" id="size" v-model="pediatrics.size" step="1" min="0" max="100"
					:label="$t('vm.root.patients.patient.neonatal.size.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.size.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.size.title')"/>

				<div class="input-frame">
					<label class="label" for="apgar1" :data-text="$t('vm.root.patients.patient.neonatal.apgar1.label')">
						<span>{{$t('vm.root.patients.patient.neonatal.apgar1.label')}}</span>
                    </label>
					<div class="form-group">
                        <checkbox-frame id="with-apgar1" tag="label" default-class="input-like form-group-input is-flex-fixed" 
                            v-model="pediatrics.with_apgar1"
                            :title="$t('vm.root.patients.patient.neonatal.apgar1.title')"/>
						<input class="form-group-input" v-model="pediatrics.apgar1" id="apgar1" :disabled="!pediatrics.with_apgar1"
							:title="$t('vm.root.patients.patient.neonatal.apgar1.title')"
							:placeholder="$t('vm.root.patients.patient.neonatal.apgar1.placeholder')">
					</div>
				</div>

                <div class="input-frame">
					<label class="label" for="apgar3" :data-text="$t('vm.root.patients.patient.neonatal.apgar3.label')">
						<span>{{$t('vm.root.patients.patient.neonatal.apgar3.label')}}</span>
                    </label>
					<div class="form-group">
                        <checkbox-frame id="with-apgar3" tag="label" default-class="input-like form-group-input is-flex-fixed" 
                            v-model="pediatrics.with_apgar3"
                            :title="$t('vm.root.patients.patient.neonatal.apgar3.title')"/>
						<input class="form-group-input" v-model="pediatrics.apgar3" id="apgar3" :disabled="!pediatrics.with_apgar3"
							:title="$t('vm.root.patients.patient.neonatal.apgar3.title')"
							:placeholder="$t('vm.root.patients.patient.neonatal.apgar3.placeholder')">
					</div>
                </div>
                
                <div class="input-frame">
					<label class="label" for="apgar5" :data-text="$t('vm.root.patients.patient.neonatal.apgar5.label')">
						<span>{{$t('vm.root.patients.patient.neonatal.apgar5.label')}}</span>
                    </label>
					<div class="form-group">
                        <checkbox-frame id="with-apgar5" tag="label" default-class="input-like form-group-input is-flex-fixed" 
                            v-model="pediatrics.with_apgar5"
                            :title="$t('vm.root.patients.patient.neonatal.apgar5.title')"/>
						<input class="form-group-input" v-model="pediatrics.apgar5" id="apgar5" :disabled="!pediatrics.with_apgar5"
							:title="$t('vm.root.patients.patient.neonatal.apgar5.title')"
							:placeholder="$t('vm.root.patients.patient.neonatal.apgar5.placeholder')">
					</div>
                </div>
                
                <div class="input-frame">
					<label class="label" for="apgar10" :data-text="$t('vm.root.patients.patient.neonatal.apgar10.label')">
						<span>{{$t('vm.root.patients.patient.neonatal.apgar10.label')}}</span>
                    </label>
					<div class="form-group">
                        <checkbox-frame id="with-apgar10" tag="label" default-class="input-like form-group-input is-flex-fixed" 
                            v-model="pediatrics.with_apgar10"
                            :title="$t('vm.root.patients.patient.neonatal.apgar10.title')"/>
						<input class="form-group-input" v-model="pediatrics.apgar10" id="apgar10" :disabled="!pediatrics.with_apgar10"
							:title="$t('vm.root.patients.patient.neonatal.apgar10.title')"
							:placeholder="$t('vm.root.patients.patient.neonatal.apgar10.placeholder')">
					</div>
				</div>

                <!-- <div>
					<checkbox-frame id="with-apgar3" v-model="pediatrics.with_premature">{{$t('vm.root.patients.patient.neonatal.apgar3.label')}}</checkbox-frame>
					<input type="number" id="apgar3" step="1" min="0" max="10" v-model="pediatrics.apgar3"
						:placeholder="$t('vm.root.patients.patient.neonatal.apgar3.placeholder')"
						:title="$t('vm.root.patients.patient.neonatal.apgar3.title')"/>
				</div>

				<div>
					<checkbox-frame id="with-apgar5" v-model="pediatrics.with_premature">{{$t('vm.root.patients.patient.neonatal.apgar5.label')}}</checkbox-frame>
					<input type="number" id="apgar5" step="1" min="0" max="10" v-model="pediatrics.apgar5"
						:placeholder="$t('vm.root.patients.patient.neonatal.apgar5.placeholder')"
						:title="$t('vm.root.patients.patient.neonatal.apgar5.title')"/>
				</div>
				<div>
					<checkbox-frame id="with-apgar10" v-model="pediatrics.with_premature">{{$t('vm.root.patients.patient.neonatal.apgar10.label')}}</checkbox-frame>
					<input type="number" id="apgar10" step="1" min="0" max="10" v-model="pediatrics.apgar10"
						:placeholder="$t('vm.root.patients.patient.neonatal.apgar10.placeholder')"
						:title="$t('vm.root.patients.patient.neonatal.apgar10.title')"/>
				</div> -->

				<!-- <input-frame type="number" id="apgar3" step="1" min="0" max="10" v-model="pediatrics.apgar3"
					:label="$t('vm.root.patients.patient.neonatal.apgar3.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.apgar3.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.apgar3.title')"/>

				<input-frame type="number" id="apgar5" step="1" min="0" max="10" v-model="pediatrics.apgar5"
					:label="$t('vm.root.patients.patient.neonatal.apgar5.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.apgar5.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.apgar5.title')"/>
				<input-frame type="number" id="apgar10" step="1" min="0" max="10" v-model="pediatrics.apgar10"
					:label="$t('vm.root.patients.patient.neonatal.apgar10.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.apgar10.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.apgar10.title')"/> -->

				<togglebox-frame id="alimentation" v-model="pediatrics.alimentation" :items="alimentationItems"
					:label="$t('vm.root.patients.patient.neonatal.alimentation')"/>
				<togglebox-frame id="cyanotic" v-model="pediatrics.cyanotic" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.cyanotic')"/>

			</div>

			<div class="grid-2">
				<togglebox-frame id="with-reanimation" v-model="pediatrics.with_reanimation" :items="withItems"
					:label="$t('vm.root.patients.patient.neonatal.reanimation.with-label')"/>
				<textarea-frame id="reanimation" v-show="pediatrics.with_reanimation" v-model="pediatrics.reanimation" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.reanimation.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.reanimation.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.reanimation.title')"/>
			</div>

			<div class="grid-2">
				<togglebox-frame id="with-scream" v-model="pediatrics.with_scream" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.scream.with-label')"/>
				<textarea-frame id="scream" v-show="!pediatrics.with_scream" v-model="pediatrics.scream" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.scream.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.scream.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.scream.title')"/>
			</div>

			<div class="grid-2">
				<togglebox-frame id="with-deformed-skull" v-model="pediatrics.with_deformed_skull" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.deformed-skull.with-label')"/>
				<textarea-frame id="deformed-skull" v-show="pediatrics.with_deformed_skull" v-model="pediatrics.deformed_skull" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.deformed-skull.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.deformed-skull.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.deformed-skull.title')"/>
			</div>

			<textarea-frame id="remarks4" v-model="pediatrics.remarks4" rows="2"
				:label="$t('vm.root.patients.patient.neonatal.remarks4.label')"
				:placeholder="$t('vm.root.patients.patient.neonatal.remarks4.placeholder')"
				:title="$t('vm.root.patients.patient.neonatal.remarks4.title')"/>

			<hr>
		</misc-collapsible-panel>

		<misc-collapsible-panel>
			<span slot="title">{{ $t('vm.root.patients.patient.neonatal.subtitle2') }}</span>
			<checkbox-frame id="suckle" v-model="pediatrics.suckle">{{ $t('vm.root.patients.patient.neonatal.suckle') }}</checkbox-frame>

			<div class="grid-2">
				<togglebox-frame id="with-reflux" v-model="pediatrics.with_reflux" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.reflux.with-label')"/>
				<textarea-frame id="reflux" v-show="pediatrics.with_reflux" v-model="pediatrics.reflux" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.reflux.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.reflux.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.reflux.title')"/>
			</div>

			<div class="grid-2">
				<togglebox-frame id="with-allergies" v-model="pediatrics.with_allergies" :items="yesNoItems"
					:label="$t('vm.root.patients.patient.neonatal.allergies.with-label')"/>
				<textarea-frame id="allergies" v-show="pediatrics.with_allergies" v-model="pediatrics.allergies" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.allergies.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.allergies.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.allergies.title')"/>
			</div>

			<div class="grid-2">
				<textarea-frame id="sleep" v-model="pediatrics.sleep" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.sleep.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.sleep.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.sleep.title')"/>

				<textarea-frame id="state" v-model="pediatrics.state" rows="1"
					:label="$t('vm.root.patients.patient.neonatal.state.label')"
					:placeholder="$t('vm.root.patients.patient.neonatal.state.placeholder')"
					:title="$t('vm.root.patients.patient.neonatal.state.title')"/>
			</div>

			<textarea-frame id="remarks5" v-model="pediatrics.remarks5" rows="2"
				:label="$t('vm.root.patients.patient.neonatal.remarks5.label')"
				:placeholder="$t('vm.root.patients.patient.neonatal.remarks5.placeholder')"
				:title="$t('vm.root.patients.patient.neonatal.remarks5.title')"/>

			<hr>
		</misc-collapsible-panel>
	</div>
</div>
