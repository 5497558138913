export const readOnly = {
    eva: {
        text: "EVA : {0} {1} {2}",
        before: "Avant {eva}",
        after: "Après {eva}"
    },
    examen: {
        evolution: "Anamnèse du jour",
        observation: "Observations",
        diagnostic: "Diagnostic",
        tests: "Tests ostéopathiques",
        treatment: "Traitement"
    },
    tests: "Liste des tests d'exclusion positif",
    none: "Aucune information"
}
