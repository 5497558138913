import Render from '@Views/components/popin/layout/popin-print-patient.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient, IConsultation, IAntecedent, ISphere, IAttachment, IPrintList, IReason } from '@Store/types'

@Render
@Component({})
export class PopinPrintPatient extends Vue {
	
	@Prop() options: {printList: IPrintList, patient: IPatient}
    @Prop({default: false}) submitted: boolean

    get consultations(): IConsultation[] {
        return this.options.patient.consultations.currents
    }
    get antecedents(): IAntecedent[] {
        return this.options.patient.antecedents.currents
    }
    get spheres(): ISphere[] {
        return this.options.patient.spheres.currents
    }
    get attachments(): IAttachment[] {
        return this.options.patient.attachments.currents
    }
    get reasons(): IReason[] {
        return this.options.patient.reasons.currents
    }
    get isConsultationsFull(): boolean {
        return this.options.printList.consultations.length === this.consultations.length
    }
    get isAntecedentsFull(): boolean {
        return this.options.printList.antecedents.length === this.antecedents.length
    }
    get isSpheresFull(): boolean {
        return this.options.printList.spheres.length === this.spheres.length
    }
    get isReasonsFull(): boolean {
        return this.options.printList.reasons.length === this.reasons.length
    }
    get isAttachmentsFull(): boolean {
        return this.options.printList.attachments.length === this.attachments.length
    }
    get isFull(): boolean {
        return this.isConsultationsFull
        && this.isAntecedentsFull
        && this.isSpheresFull
        && this.isReasonsFull
        && this.isAttachmentsFull
        && this.options.printList.pediatry
    }
    get total(): number {
        return this.options.printList.consultations.length +
        this.options.printList.attachments.length +
        this.options.printList.spheres.length +
        this.options.printList.antecedents.length + (this.options.printList.pediatry ? 1 : 0)
    }

    selectAll(): void {
        let isFull: boolean = this.isFull

        this.clear(this.options.printList.consultations)
        this.clear(this.options.printList.attachments)
        this.clear(this.options.printList.spheres)
        this.clear(this.options.printList.reasons)
        this.clear(this.options.printList.antecedents)
        this.options.printList.pediatry = false
        if (isFull) return

        this.consultations.forEach(consultation => { this.options.printList.consultations.push(consultation.id) })
        this.antecedents.forEach(antecedent => { this.options.printList.antecedents.push(antecedent.id) })
        this.spheres.forEach(sphere => { this.options.printList.spheres.push(sphere.id) })
        this.reasons.forEach(reason => { this.options.printList.reasons.push(reason.id) })
        this.attachments.forEach(attachment => { this.options.printList.attachments.push(attachment.id) })
        this.options.printList.pediatry = true
    }
    selectAllConsultations(): void {
        let isFull: boolean = this.isConsultationsFull
        this.clear(this.options.printList.consultations)
        if (isFull) return

        this.consultations.forEach(consultation => { this.options.printList.consultations.push(consultation.id) })
    }
    selectAllAntecedents(): void {
        let isFull: boolean = this.isAntecedentsFull
        this.clear(this.options.printList.antecedents)
        if (isFull) return

        this.antecedents.forEach(antecedent => { this.options.printList.antecedents.push(antecedent.id) })
    }
    selectAllSpheres(): void {
        let isFull: boolean = this.isSpheresFull
        this.clear(this.options.printList.spheres)
        if (isFull) return

        this.spheres.forEach(sphere => { this.options.printList.spheres.push(sphere.id) })
    }
    selectAllReasons(): void {
        let isFull: boolean = this.isReasonsFull
        this.clear(this.options.printList.reasons)
        if (isFull) return

        this.reasons.forEach(reason => { this.options.printList.reasons.push(reason.id) })
    }
    selectAllAttachments(): void {
        let isFull: boolean = this.isAttachmentsFull
        this.clear(this.options.printList.attachments)
        if (isFull) return

        this.attachments.forEach(attachment => { this.options.printList.attachments.push(attachment.id) })
    }
    isConsultationSelected(id: string): boolean {
        return this.options.printList.consultations.includes(id)
    }
    isAntecedentSelected(id: string): boolean {
        return this.options.printList.antecedents.includes(id)
    }
    isSphereSelected(id: string): boolean {
        return this.options.printList.spheres.includes(id)
    }
    isReasonSelected(id: string): boolean {
        return this.options.printList.reasons.includes(id)
    }
    isAttachmentSelected(id: string): boolean {
        return this.options.printList.attachments.includes(id)
    }
    selectConsultation(id: string): void {
        if (this.isConsultationSelected(id)) {
            this.options.printList.consultations = this.options.printList.consultations.filter(item => {return item !== id})
        } else {
            this.options.printList.consultations.push(id)
        }
    }
    selectAntecedent(id: string): void {
        if (this.isAntecedentSelected(id)) {
            this.options.printList.antecedents = this.options.printList.antecedents.filter(item => {return item !== id})
        } else {
            this.options.printList.antecedents.push(id)
        }
    }
    selectSphere(id: string): void {
        if (this.isSphereSelected(id)) {
            this.options.printList.spheres = this.options.printList.spheres.filter(item => {return item !== id})
        } else {
            this.options.printList.spheres.push(id)
        }
    }
    selectReason(id: string): void {
        if (this.isReasonSelected(id)) {
            this.options.printList.reasons = this.options.printList.reasons.filter(item => {return item !== id})
        } else {
            this.options.printList.reasons.push(id)
        }
    }
    selectAttachment(id: string): void {
        if (this.isAttachmentSelected(id)) {
            this.options.printList.attachments = this.options.printList.attachments.filter(item => {return item !== id})
        } else {
            this.options.printList.attachments.push(id)
        }
    }
    private clear(array: string[]): void {
        array.splice(0, array.length)
    }
}