import Render from '@Views/root/patients/patient/consultation/follow-up.html'
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import { ReadOnlyFollowUp } from './read-only-follow-up'
import { IConsultation, IFollowUp, IBreadcrumbItem, IDisplay, IConfiguration } from '@Store/types'
import { IDropdownItem, IToggleBoxItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { Gender, ReminderType, FollowUpLevel } from '@Enums/index'
import {breadcrumb, configuration, home, patient, user} from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnlyFollowUp
	}
})
export class FollowUp extends Vue {

	disabled: boolean = false

	@Prop() saved: boolean
	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	//#region Getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get configuration(): IConfiguration {
		return configuration.configuration
	}
	get display(): IDisplay {
		return this.configuration && this.configuration.display
	}
	get followUp(): IFollowUp {
        return this.consultation.follow_up
	}
	get levelItems(): IToggleBoxItem[] {
		return[
			{value: FollowUpLevel.NotSatisfied, label: EnumsHelper.followUpLevelToString(FollowUpLevel.NotSatisfied), class: 'is-danger', icon:"frown"},
			{value: FollowUpLevel.None, label: EnumsHelper.followUpLevelToString(FollowUpLevel.None), icon:"meh"},
			{value: FollowUpLevel.Satisfied, label: EnumsHelper.followUpLevelToString(FollowUpLevel.Satisfied), class: 'is-success', icon:"smile-beam"}
		]
	}
    get isFollowUpActive(): boolean {
        return this.followUp.type !== ReminderType.None
    }
	get followUpItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.followUps.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
	    return result
	}
	get hasDelayError(): boolean {
        return !!this.saved && !this.isValidDelay
	}
	get isSended(): boolean {
		if (!this.isFollowUpActive) return false
		if (!this.isValidDelay) return false
		if (!this.followUp.top_sup) return false

		let sendDate: Date = new Date(this.consultation.act_date)
		sendDate.setDate(sendDate.getDate() + this.followUp.delay)

		return sendDate < new Date()
	}
	private get isValidDelay(): boolean {
        return !this.isFollowUpActive || (!!this.followUp.delay && this.followUp.delay > 0)
	}
	private get gender(): Gender {
		return patient.patient.coordinates.gender
	}
	private get followUps(): ReminderType[] {
		if (this.isMainUser) return [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]
		return [ReminderType.None, ReminderType.OnlyMail]
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
	}

	@Watch("followUp.read")
	updateNotification(): void {
		if (!this.followUp.back || this.followUp.back === '') return

		let oldNotification:boolean = configuration.configuration.notification_follow_up
		// to avoid notification at each click
		configuration.configuration.notification_follow_up = false
		if (this.followUp.read) home.unreadFollowUp(home.unreadFollowUpCount - 1)
		else home.unreadFollowUp(home.unreadFollowUpCount + 1)
		// to restore notification parameter
		setTimeout(() => configuration.configuration.notification_follow_up = oldNotification, 200)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.follow-up').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}
