import { IPlanning } from "@Store/types"
import { PlanningType } from "@Enums/index"

export class Planning {
    public static createNewPlanning(): IPlanning {
        return {
            id: '-1',
            name: '',
            type: PlanningType.Classic,
            color: '#ffffff',
            visible: true
        }
    }
}