import Render from '@Views/components/layout/header.html'
import { Vue, Component } from 'vue-property-decorator'
import { FullScreen } from '@Components/breadcrumb/fullscreen'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
    components: {
        'fullscreen': FullScreen
    }
})
export class Header extends Vue {
	name:string = 'header'

    get linkedToContext(): boolean {
        return breadcrumb.linkedToContext
    }

}