export class AnonymousHelper {
	public static getAnonymousText(text: string, isEmail: boolean = false) : string {
        if (!text) return ''
		if (text.trim() === '') return ''
		
		let result: string = ''
		let texts: string[] = text.split(isEmail ? '@' : ' ')
		if (isEmail && texts.length === 2)
			return `${AnonymousHelper.generateAnonymousText(texts[0], 0)}@${texts[1]}`

		for(let index: number = 0; index < texts.length; index++) {
			result = `${result}${AnonymousHelper.generateAnonymousText(texts[index], index)} `
		}
		return result.trim()
    }
    
    private static generateAnonymousText(text: string, index: number): string {
		let length: number = text.length
		if (index === 0) return text.charAt(0).padEnd(length, '*')

		return ''.padEnd(length, "*")
	}
}