import Render from '@Views/root/patients/campaigns/campaigns.html'
import { Component, Vue } from 'vue-property-decorator'
import { ASideCampaigns } from './aside-campaigns'
import { ContextCampaigns } from './context-campaigns'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-campaigns': ASideCampaigns,
		'context-campaigns': ContextCampaigns
	}
})
export class Campaigns extends Vue {
	mounted() {
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.campaign').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
