import md5 from 'md5'

export class Gravatar {

	public static defaultGravatarId = "00000000000000000000000000000000"

	public static getGravatarId(email: string): string {
		if (!email) return Gravatar.defaultGravatarId
		return md5(email)
	}
}