import { BaseService } from '@Services/index'
import { ITicket } from '@Store/types';

export class SupportService extends BaseService {
	
	public getNotificationSupport(): Promise<any> {
		return super.get(`/notification/support/`)
	}

	public getChangeLog(): Promise<any> {
		return super.get(`/support/change-log`)
	}
	
	public getFaqList(): Promise<any> {
		return super.get(`/support/faq/list`)
	}

	public getOpenedTicketList(): Promise<any> {
		return super.get(`/support/opened/list`)
	}

	public getClosedTicketList(): Promise<any> {
		return super.get(`/support/closed/list`)
	}

	public getTicket(sup_id: string): Promise<any> {
		return super.get(`/support/ticket/${sup_id}`)
	}

	public sendComment(sup_id: string, comment:string): Promise<any> {
		return super.put(`/support/ticket/${sup_id}`, {comment: comment})
	}

	public createTicket(ticket:ITicket): Promise<any> {
		return super.post(`/support/ticket/`, ticket)
	}
}