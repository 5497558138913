import Render from '@Views/root/statistics/top10/table.html'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class Table extends Vue {

	stats: any = {}
	@Prop() title: string
	@Prop() property: string
	@Prop() columnName: string
	@Prop() data: any

	hasIcon(index: number): boolean {
		return index < 3
	}
	getRank(index: number): string {
		return index === 0 ? this.$i18n.t('vm.root.statistics.top10.main.first').toString() : 
			index === 1 ? this.$i18n.t('vm.root.statistics.top10.main.second').toString() : this.$i18n.t('vm.root.statistics.top10.main.third').toString()
	}
	getRankClass(index: number): string {
		return index === 0 ? 'text-lg' : 'text-md'
	}
	getRankStyle(index: number): string {
		return `color:${index === 0 ? 'gold' : (index === 1 ? 'silver' : '#Ad8a56' )}`
	}
	getRankIcon(index: number): string {
		return index === 0 ? 'trophy-alt' : 'trophy'
	}
 }
