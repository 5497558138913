<form @submit.prevent="submit">
    <h1>{{ $t('vm.root.user.password.title') }}</h1>
    <div class="grid-2">
        <input-password-frame class="grid-item-full" ref="current-password" id="current-password" v-model="password.currentPassword"
            :label="$t('vm.root.user.password.current-password.label')"
            :error-test="hasCurrentPasswordError" :error-msg="$t('vm.root.user.password.current-password.error-msg')"
            :placeholder="$t('vm.root.user.password.current-password.placeholder')" 
            :title="$t('vm.root.user.password.current-password.title')"/>

        <input-password-frame ref="new-password" id="new-password" v-model="password.newPassword"
            :label="$t('vm.root.user.password.new-password.label')"
            :error-test="hasNewPasswordError" :error-msg="$t('vm.root.user.password.new-password.error-msg')"
            :placeholder="$t('vm.root.user.password.new-password.placeholder')" 
            :title="$t('vm.root.user.password.new-password.title')"/>

        <input-password-frame ref="confirm-password" id="confirm-password" v-model="password.confirmPassword"
            :label="$t('vm.root.user.password.confirm-password.label')"
            :error-test="hasConfirmPasswordError" :error-msg="$t('vm.root.user.password.confirm-password.error-msg')"
            :placeholder="$t('vm.root.user.password.confirm-password.placeholder')" 
            :title="$t('vm.root.user.password.confirm-password.title')"/>
    </div>

    <control-bar>
        <button-action type="submit" btn-class="btn-primary" :loading="isLoading" :disabled="disabled" :title="$t('vm.root.user.password.update.title')">
            {{ $t('vm.root.user.password.update.short-label') }}
            <span class="rwd-over-xlarge">{{ $t('vm.root.user.password.update.long-label') }}</span>
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
            </svg>
        </button-action>
    </control-bar>
</form>
