import Render from '@Views/root/accounting/context-main.html'
import { Component } from 'vue-property-decorator'
import { Profile } from '@Enums/index'
import { AbstractContext } from './context-base'
import { ILinkUser } from '@Store/types'
import { user } from '@Store/modules'
import { User } from '@Models/index'

@Render
@Component({})
export class ContextMain extends AbstractContext {

	mounted() {
		super.reset()
	}

	reset(): void {
		super.reset()
	}

	getUserList(): ILinkUser[] {
		let allUsers: ILinkUser = {
			id: '-1',
			coordinates: {
				last_name: this.$i18n.t('general.all-users').toString(),
				first_name: "",
			},
			last_name: this.$i18n.t('general.all-users').toString(),
			first_name: "",
			email: "",
			activate: [],
			profile: Profile.Main
		}

		return [allUsers, User.userToLinkUser(user.user), ...user.linkedUsers(Profile.Substitute)]
	}
}
