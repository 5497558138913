<context>
	<infinite-scroll :initialized="initialized" tag="hr" class-tag="wrapper" :load-more="loadNextNews" :is-loading="isLoading" :can-load-more="hasMoreNews">
		<section class="wrapper marg-v">
			<h2>
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#rss"/>
				</svg>
				{{ $t('vm.root.home.context-news.title') }}
			</h2>

			<div v-for="(news, index) in newsList" :key="news.id">
				<section class="news">
					<h3 class="text-rg">
						<a :href="`//osteo2ls.com/blog/${news.url}`" target="_blank" :title="$t('vm.root.home.context-news.news-title')">
							<span v-html="news.title"/>
						</a>
					</h3>
					<a :href="`//osteo2ls.com/blog/${news.url}`" target="_blank" :title="news.title">
						<figure class="news-figure" role="group">
							<img class="news-image" loading="lazy" :src="news.small_header_image" :alt="news.title">
							<figcaption class="news-date childs-space-between">
								<span class="sr-only">{{news.title}}</span>
								<span class="text-xs">{{ $d(news.publish_date, 'll') }}</span>
								<span class="text-xs is-pushed-right">{{ news.type | news }}</span>
							</figcaption>
						</figure>
					</a>
					<p class="text-sm" v-html="summary(news.description)"></p>
					<div class="text-right text-xs">
						{{ $t('vm.root.home.context-news.share.on') }}
						<button class="btn btn--link-like" @click="share(`https://facebook.com/sharer/sharer.php?u=https://osteo2ls.com/blog/${news.url}`)"
							:title="$t('vm.root.home.context-news.share.facebook-title')">{{ $t('vm.root.home.context-news.share.facebook') }}</button>,
						<button class="btn btn--link-like" @click="share(`https://twitter.com/intent/tweet/?url=https://osteo2ls.com/blog/${news.url}&via=osteo2ls`)"
							:title="$t('vm.root.home.context-news.share.twitter-title')">{{ $t('vm.root.home.context-news.share.twitter') }}</button>
					</div>
				</section>
				<hr v-if="index !== newsList.length - 1">
			</div>

		</section>

		<div slot="loading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</div>
	</infinite-scroll>

</context>
