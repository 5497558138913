import * as Patients from '@ViewModels/root/patients'
import * as Patient from '@ViewModels/root/patients/patient'
import * as Consultation from '@ViewModels/root/patients/patient/consultation'
import * as Pediatrics from '@ViewModels/root/patients/patient/pediatrics'
import * as Attachment from '@ViewModels/root/patients/patient/attachment'
import { Profile } from '@Enums/profile'

export const PatientsRoot = [
	{
		path: 'patients',
		name:'patients',
		component: Patients.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
	},
	{
		path: 'campaigns',
		name:'patients-campaigns',
		component: Patients.Campaigns,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'merge',
		name:'patients-merge',
		component: Patients.Merge,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'campaign/:cmp_id',
		name: 'patients-campaign',
		component: Patients.Campaign,
		redirect: { name: 'campaign-step-one' },
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: [
			{
				path:'summary',
				name:'campaign-summary',
				component: Patients.Summary,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path:'step-1',
				name:'campaign-step-one',
				component: Patients.StepOne,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path:'step-2',
				name:'campaign-step-two',
				component: Patients.StepTwo,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path:'step-3',
				name:'campaign-step-three',
				component: Patients.StepThree,
				meta: { permission: [Profile.Main, Profile.Demo] }
			}
		]
	},
	{
		path: 'export',
		name:'patients-export',
		component: Patients.Export,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'patient/:pat_id?',
		component: Patient.Main,
		redirect: 'patient-coordinates',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
		children: [
			{
				path: '',
				name: 'patient-coordinates',
				component: Patient.Coordinates,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'antecedents',
				name: 'patient-antecedents',
				component: Patient.Antecedents,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'spheres',
				name: 'patient-spheres',
				component: Patient.Spheres,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'consultations',
				name: 'patient-consultations',
				component: Patient.Consultations,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'consultation/:item_id?',
				component: Consultation.Main,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
				children: [
					{
						path: '',
						name: 'patient-consultation',
						component: Consultation.Examens,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'cranio',
						name: 'patient-consultation-cranio',
						component: Consultation.Cranio,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'forms',
						name: 'patient-consultation-forms',
						component: Consultation.Forms,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'exclusion',
						name: 'patient-consultation-exclusion',
						component: Consultation.ExclusionTests,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'follow-up',
						name: 'patient-consultation-follow-up',
						component: Consultation.FollowUp,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'reason',
						name: 'patient-consultation-reason',
						component: Consultation.Reason,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'schema',
						name: 'patient-consultation-schema',
						component: Consultation.Schema,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'walton',
						name: 'patient-consultation-walton',
						component: Consultation.Walton,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					}
				]
			},
			{
				path: 'attachments',
				name: 'patient-attachments',
				component: Patient.Attachments,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'attachment/:item_id?',
				component: Attachment.Main,
				name: 'patient-attachment',
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'pediatrics/',
				component: Pediatrics.Main,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
				children: [
					{
						path: '',
						name: 'patient-pediatrics',
						component: Pediatrics.Antecedents,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'neonatal',
						name: 'patient-pediatrics-neonatal',
						component: Pediatrics.Neonatal,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					},
					{
						path: 'forms',
						name: 'patient-pediatrics-forms',
						component: Pediatrics.Forms,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
					}
				]
			},
			{
				path: 'relaunch',
				name: 'patient-relaunch',
				component: Patient.Relaunch,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'family',
				name: 'patient-family',
				component: Patient.Family,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'documents/:con_id?',
				name: 'patient-documents',
				component: Patient.Documents,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			}
		]
	}
]
