import Render from '@Views/root/parameters/document/context-document.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConfiguration, IDocument } from '@Store/types'
import { Document } from '@Models/index'
import { Dictionary } from "vue-router/types/router"

@Render
@Component({})
export class ContextDocument extends Vue {

	@Prop() configuration: IConfiguration

	//#region Getter
	get doc_id(): string {
		return this.$route.params.doc_id
	}
	get document(): IDocument {
		return this.configuration.documents.currents.find(d => { return d.id === this.doc_id || (!!d.createId && d.createId === this.doc_id) })
	}
	get hasDocumentSelected() : boolean {
		return !!this.document
	}
	get keywords(): Dictionary<string[]> {
		return Document.getKeywords()
	}
	//#endregion

	insertText(text: string) {
		this.$emit('insert', text)
	}
}
