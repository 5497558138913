export const createPatient = {
    text1: "Veuillez indiquer quelques informations sur ce nouveau patient.",
    gender: {
        man: "homme",
        woman: "femme"
    },
    member: {
        label: "Lien avec le patient dupliqué",
        title: "Indiquez le lien de parenté avec le patient que vous souhaitez dupliquer"
    },
    duplicate: {
        label: 'sélectionner cet autre dossier',
        message: "Il existe déjà un dossier pour ce patient. Souhaitez-vous le sélectionner ?"
    }
}
