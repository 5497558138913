export const mark = function (value: string, query: string) {
  if (!value) return ""
  if (!query) return value

  let punctuationless:string = query.replace(/[\-]/g, " ").replace(/[.,\/#!$%\^&\*\+;:{}=_`~()]/g, "")

  let filters: string[] = punctuationless.split(' ')
  for(let filter of filters) {
    filter = filter.trim()
    if (filter === '') continue
    
    let pattern = new RegExp(`(${filter})`, 'gi')
    value = value.replace(pattern, `[[@($£%[[$1]]%£$)@]]`)
  }
  return value.split('[[@($£%[[').join('<mark>').split(']]%£$)@]]').join('</mark>')
}