export const relaunch = {
    title: "Relance à titre préventif",
    help1: "La relance permet de responsabiliser votre patient en lui suggérant de prendre un nouveau rendez-vous.",
    help2: "Il n'y a aucune obligation pour le patient, cela est fait à titre préventif.",
    help3: {
        text: "Il recevra, après un nombre de mois suivant sa dernière consultation, un mail/SMS contenant un lien unique qui le redirigera vers la page de vos cabinets sur {0}.",
        link: "le planning en ligne",
        title: "Le planning en ligne d'Osteo2ls"
    },
    help4: {
        text: "Vous devez {0} dans le dossier du patient {1}, pour que la relance lui soit envoyée.",
        strong: "nécessairement saisir au moins un email ou un numéro de téléphone portable",
        span: "(et avoir le nombre de crédit nécessaire pour les SMS)"
    },
    purchase: {
        label: 'acquérir',
        message: "Pour relancer vos patients, vous devez posséder l'option <strong>planning en ligne</strong>. Voulez-vous acquérir cette option ?"
    },
    type: "Type d'envoi de la relance",
    delay: {
        label: "Relancez votre patient dans {delay} mois",
        placeholder: "ex : 6 mois",
        error: "Merci de renseigner un nombre de mois",
        title: "Veuillez renseigner le nombre de mois avant l'envoi de la relance"
    },
    none: "Pas de relance.",
    'read-only': {
        summary: "Le relance sera envoyée, {delay} mois après la dernière consultation :",
        'by-email': "par email",
        'by-sms': "par SMS",
    }
}