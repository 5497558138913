import Render from '@Views/root/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { Navigation, Header } from '@Components/index'
import { Queue, Connection } from '@Models/index'
import { NotificationType, Profile } from '@Enums/index'
import { notif, breadcrumb, user, patient, popIn } from '@Store/modules'
import { INotification, IPopin, IUser } from '@Store/types'
import { StorageHelper } from '@Helpers/storage'

@Render
@Component({
	components: {
		'navigation': Navigation,
		'headeur': Header
	}
})
export class Main extends Vue {

	get hasAside(): boolean {
		return breadcrumb.hasAside
	}
	get user(): IUser {
		return user.user
	}
	get communicationKey(): string {
		return `communication_key_${user.currentUser.id}`
	}
	get canShowCommunication(): boolean {
		let isCommunicationHidden = !!StorageHelper.get(this.communicationKey, false);
		return !isCommunicationHidden;
	}

	mounted() {
		patient.loadBackup()
		.then(result => {
			if (Object.keys(result).length === 0) return

			let message:string = this.$t('vm.root.main.load-backup').toString()
			let notification:INotification = { message: message, actions: [], delay: 5000, canClose: true }
			notif.info(notification)
		})
		Queue.getInstance().addListener(this.callback)
		Connection.getInstance().addListener(this.callback)

		window.addEventListener('online',  this.updateOnlineStatus)
		window.addEventListener('offline', this.updateOnlineStatus)

		if (this.user.profile === Profile.Main && this.canShowCommunication) {
				this.communication()
		}
	}

	beforeDestroy() {
		Queue.getInstance().removeListener(this.callback)
		Connection.getInstance().removeListener(this.callback)

		window.removeEventListener('online',  this.updateOnlineStatus)
		window.removeEventListener('offline', this.updateOnlineStatus)
	}

	private updateOnlineStatus() {
		Connection.getInstance().state = window.navigator.onLine
	}

	private callback(notification: INotification, type: NotificationType): void {
		notif.notif({notification: notification, type: type})
	}

	 // la function  pour lancer le PopIn
	 private communication(): void {

		 let communication: IPopin = {
			 title: this.$i18n.t('vm.root.home.communication.title').toString(),
			 content: {component: 'popin-communication'},
			 action: null,
			 isWide: true,
			 options: {user : this.user ,storageKey : this.communicationKey},
			 cancelLabel: this.$i18n.t('general.close.text').toString()
		 }

		 popIn.info(communication)
	 }
}
