import {IOffice} from "@Store/types/office"
import {PaymentMode, Timezone, Currency} from "@Enums/index"
import { i18n } from '@I18n/index'

export class OfficeHelper {

    public static createAllOfficeItem(): IOffice {
		let allOfficeItem: IOffice = {
			id: '-1',
			usr_id: '',
			creator_usr_id: '',
			coordinates: {
				name: i18n.t('models.office.all-office').toString(),
				mode: PaymentMode.Check,
				timezone: Timezone.UtcPlus1,
				currency: Currency.Euro,
				address: undefined,
				phone1: undefined,
				phone2: undefined,
				latitude: 0,
				longitude: 0
			},
			links: [],
			sessions: {currents: [], deleted: []},
			accesses: {currents: [], deleted: []},
			schedules: {currents: [], deleted: []},
			specific_schedules: {currents: [], deleted: []},
			settings: {currents: [], deleted: []}
		}
		return allOfficeItem
    }
}
