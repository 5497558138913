<div class="wrapper marg-v">

    <collapsible-help id="share-office-information" tag="h1" :title="$t('vm.root.co-working.steps.step1.title')">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step1.help') }}
            </p>
        </div>
    </collapsible-help>

    <div v-if="isVerifying" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.co-working.steps.step1.verifying') }}
		</div>
	</div>
    <form v-else @submit.prevent="submitNext">
        <dropdown-frame id="profile" :items="profileItems" v-model="profile"
            :default-label="$t('vm.root.co-working.steps.step1.profile.default-label')"
            :error-test="hasProfileError" :error-msg="$t('vm.root.co-working.steps.step1.profile.error-msg')"
            :label="$t('vm.root.co-working.steps.step1.profile.label')"/>

        <control-bar class="text-right">
            <button-action type="submit" btn-class="btn btn-primary" :disabled="disabled" :title="$t('vm.root.co-working.steps.step1.next-step.title')">
                {{ $t('vm.root.co-working.steps.step1.next-step.text') }}
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
                </svg>
            </button-action>
        </control-bar>
    </form>

</div>
