export const main = {
    type: {
        label: "Type d'antécédent",
        error: "Merci de renseigner le type d'antécédent",
        'no-options-label': "tapez pour rechercher un type d'antécédent…",
        placeholder: "ex : Chirurgical",
        title: "Veuillez renseigner le type d'antécédent"
    },
    title: {
        label: "Titre de l'antécédent",
        placeholder: "ex : Ligaments croisés antérieurs droits",
        error: "Merci de renseigner le titre de cet antécédent",
        title: "Veuillez renseigner le titre de cet antécédent"
    },
    state_date: {
        label: "Date de l'antécédent"
    },
    there_is_placeholder: "ex : 15 jours",
    at_the_age_placeholder: "ex : 23 ans",
    date: {
        title: "Veuillez indiquer la date à laquelle s'est produit l'antécédent",
        placeholder: "ex : Le 16 juin 1995",
        error: "Merci d'indiquer la date à laquelle s'est produit l'antécédent"
    },
    at_year: {
        title: "Veuillez indiquer l'année à laquelle s'est produit l'antécédent",
        placeholder: "ex : 1995",
        error: "Merci d'indiquer l'année à laquelle s'est produit l'antécédent"
    },
    description: {
        label: "Description de l'antécédent",
        placeholder: "ex : Suite à un contact appuyé lors d'un entraînement",
        title: "Veuillez renseigner la description de cet antécédent"
    },
    important_label: "Antécédent important",
    periodic_label: "Antécédent en cours",
    periodicity: {
        label: "Périodicité",
        no: "ponctuel",
        yes: "périodique"
    },
    end_date: {
        label: "pendant…",
        placeholder: "ex : 6 mois"
    }
}
