declare const VERSION: string
declare const API_URL: string
declare const API_VERSION: string
declare const API_KEY: string
declare const USE_STORAGE: boolean

const config: any = {
    api_url: API_URL,
    api_version: API_VERSION,
    api_key: API_KEY,
    use_storage: USE_STORAGE,
    version: VERSION,
    googleClientId: '291087743368-dlnljc3php4bf0bcabjnetcgqg8p0am1.apps.googleusercontent.com',
    googleApiKey: 'AIzaSyAllcrMUumE88UVIyFZuqnUQQNi-6Ugnq4',
    googleDiscoveryUrls: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    googleScope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
}

export default config
