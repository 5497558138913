<context>

	<section class="wrapper">

		<collapsible-help id="remittance-information" tag="h2"
			:title="$t('vm.root.parameters.document.context.title')"
			:show-label="$t('vm.root.parameters.document.context.help-label')"
			:hide-label="$t('vm.root.parameters.document.context.help-label')">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">{{ $t('vm.root.parameters.document.context.text1') }}</p>
				<p class="text-left">{{ $t('vm.root.parameters.document.context.text2') }}</p>
			</div>
		</collapsible-help>

		<div class="notice-success">
			<p class="text-left"><strong>{{ $t('vm.root.parameters.document.context.tips.title') }}</strong></p>
			<i18n class="text-left" path="vm.root.parameters.document.context.tips.tips1.text" tag="p">
				<kbd title="$t('vm.root.parameters.document.context.tips.tips1.kbd1')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#keyboard-enter"/>
					</svg> {{ $t('vm.root.parameters.document.context.tips.tips1.kbd1') }}
				</kbd>
				<kbd title="$t('vm.root.parameters.document.context.tips.tips1.kbd2')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-alt-up"/>
					</svg> {{ $t('vm.root.parameters.document.context.tips.tips1.kbd2') }}
				</kbd>
			</i18n>
			<i18n class="text-left" path="vm.root.parameters.document.context.tips.tips2" tag="p">
				<svg width="1em" class="svg-middle text-md" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#edit-image"/>
				</svg>
			</i18n>
		</div>

		<section v-if="hasDocumentSelected" v-for="(list, value) in keywords" class="marg-v" :key="value">
			<h3 class="h-reset text-uppercase-first-letter"><b>{{ $t(`models.document.${value}`) }}</b></h3>
			<ul class="ul-reset">
				<li v-for="keyword in list" :key="keyword">
					<button type="button" class="btn--link-like" @click="insertText(keyword)"
						:title="$t('vm.root.parameters.document.context.button-title', {keyword: keyword})">{{ keyword }}</button>
				</li>
			</ul>
		</section>
	</section>

</context>
