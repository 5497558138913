import Render from '@Views/components/frame/input-date-frame.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import mobiscroll, { MbscCalendarOptions, MbscNumpadDateOptions } from '@mobiscroll/javascript'
import '@mobiscroll/javascript/dist/css/mobiscroll.javascript.min.css'
import { DateHelper } from '@Helpers/index'
import { InputRange } from '@Components/input/input-range'
import { configuration } from '@Store/modules'

export enum InputDateType {
    Calendar,
    CalendarTime,
    Numpad
}

@Render
@Component({})
export class InputDateFrame extends Vue {

    private instance: any = null

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) autoFocus: boolean
    @Prop({default: null}) invalid: any[]
    @Prop() errorMsg: string
    @Prop() id: string
    @Prop() placeholder: string
    @Prop() label: string
    @Prop() title: string
    @Prop() value: Date
    @Prop({default: InputDateType.Calendar}) type: InputDateType
    @Prop({default: InputRange.dateFormat}) dateFormat: string
    @Prop({default: InputRange.timeFormat}) timeFormat: string

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    
    // handleInput(e: any) {
    //     this.$emit('input', e)
    // }

    @Watch('disabled')
    updateDisabled() {
        this.instance.settings.disabled = this.disabled
    }

    @Watch('value', {deep: true})
    updateValue() {
        if (!DateHelper.isValidDate(this.value))
            return

        if (this.instance === null)
            return

        this.instance.setVal(this.value, true)
        // this.$emit('input', this.value)
    }

    @Watch('type')
    updateType() {
        let element: HTMLElement = this.$refs[this.id] as HTMLElement

        if (this.type === InputDateType.Calendar) {
            this.instance = mobiscroll.calendar(element, this.getConfigCalendar())
        } else if (this.type === InputDateType.CalendarTime) {
            this.instance = mobiscroll.calendar(element, this.getConfigCalendar(true))
        } else {
            this.instance = mobiscroll.numpad(element, this.getConfigNumpad())
        }
        this.instance.setVal(this.value, true)
    }

    mounted() {
        this.updateType()
    }

    private getConfigCalendar(withTime?: boolean): MbscCalendarOptions {
        let self = this

        let minTime: string = !!configuration.configuration.min_time ? configuration.configuration.min_time.toLocaleTimeString('fr-FR') : '06:00:00'
		let maxTime: string = !!configuration.configuration.max_time ? configuration.configuration.max_time.toLocaleTimeString('fr-FR') : '21:00:00'

        let config:MbscCalendarOptions = {
            lang: configuration.configuration.lang,
            theme: 'ios',
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat,
            disabled: self.disabled,
            showOnFocus: true, 
            steps: { minute: 5 },
            responsive: {
                small: { display: 'center' },
                medium: { display: 'center' },
                large: { display: 'center' }
            },
            // dateOrder: 'd MM yy',

            // max: new Date(),
            // min: new Date(1960, 0, 1),
            onSet: function (event, inst) {
                self.$emit('input', inst.getVal())
            },
            calendarScroll: 'vertical',
            controls: withTime ? ['calendar', 'time'] : ['calendar'],
            months: 'auto',
            invalid: !this.invalid ? [
                { start: '00:00', end: minTime },
                { start: maxTime, end: '23:55' }
            ] : this.invalid,
            onPageLoaded: function(event: {firstDay: Date, lastDay?: Date}, inst: any): void {
                let date = inst.getVal(true)
                let tempVal: Date = new Date(date)
                tempVal.setFullYear(event.firstDay.getFullYear())
                tempVal.setMonth(event.firstDay.getMonth())
                if (tempVal.getFullYear() === date.getFullYear() && tempVal.getMonth() === date.getMonth())
                    return

                inst.setDate(tempVal, false, false, true)
            }
        }

        // if (!withTime) return config

        // config['invalid'] = [
        //     { start: '00:00', end: minTime },
        //     { start: maxTime, end: '23:55' }
        // ]

        return config
    }

    private getConfigNumpad(): MbscNumpadDateOptions {
        let self = this
        
        return {
            lang: configuration.configuration.lang,
            theme: 'ios',
            dateFormat: this.dateFormat,
            display: 'center',
            showOnFocus: true,
            dateOrder: 'dmy',
            preset: 'date',
            max: new Date(),
            onSet: function (event, inst) {
                self.$emit('input', inst.getVal())
            }
        }

        // if (for_range) {
        //     options.onMonthLoaded = function(year, month, inst) {	};
        //     options.onSetDate = function (day, inst) {
        //         inst._isValid = true;
        //         $('.dwb-s .dwb', inst._markup).removeClass('dwb-d');
        //     };
        // }

        // // if (light) return options;

        // return options
    }
}