import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { RequestStatus } from "@Enums/index"
import { IColleague, ColleagueLoadPayload, ColleagueSavePayload, ColleagueSearchPayload } from "@Store/types"
import { ColleagueService } from "@Services/index"

@Module({
	name: 'colleague',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class ColleagueModule extends VuexModule {
    status: RequestStatus = RequestStatus.None
    statusSearch: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusDeleting: RequestStatus = RequestStatus.None
    colleague: IColleague = null

	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	
	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSearch = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.colleague = null
	}

	@Mutation
  	colleagueRequest() {
		this.status = RequestStatus.Loading
		this.colleague = null
	}

	@Mutation
  	colleagueSuccess(colleague: IColleague) {
		this.status = RequestStatus.Success
		this.colleague = colleague
	}

	@Mutation
  	colleagueFailure() {
		this.status = RequestStatus.Success
		this.colleague = null
	}

	@Mutation
  	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
  	saveSuccess(colleague: IColleague) {
		this.statusSaving = RequestStatus.Success
		this.colleague = colleague
	}

	@Mutation
  	saveFailure(colleague: IColleague) {
		this.statusSaving = RequestStatus.Success
		this.colleague = colleague
	}

	@Mutation
  	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
  	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
  	deleteFailure() {
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
  	searchRequest() {
		this.statusSearch = RequestStatus.Loading
	}

	@Mutation
  	searchSuccess() {
		this.statusSearch = RequestStatus.Success
	}

	@Mutation
  	searchFailure() {
		this.statusSearch = RequestStatus.Success
	}

	@Action({rawError: true})
	async loadColleague(payload: ColleagueLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.colleagueRequest()

		let service = new ColleagueService()
		return service.getColleague(payload.col_id)
		.then(colleague => {
			this.colleagueSuccess(colleague)
			return Promise.resolve(colleague)
		})
		.catch(error => {
			this.colleagueFailure()
			return Promise.reject(error)
		})
	}

	@Action
	async saveColleague(payload: ColleagueSavePayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new ColleagueService()
		return service.save(payload.colleague, payload.callbackProgress)
		.then(newColleague => {
			this.saveSuccess(newColleague)
			this.colleagueSuccess(newColleague)
			return Promise.resolve(newColleague)
		})
		.catch(error => {
			this.saveFailure(payload.colleague)
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteColleague(col_id: string): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		this.deleteRequest()

		let service = new ColleagueService()
		return service.delete(col_id)
		.then(() => {
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async searchColleagues(payload: ColleagueSearchPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		const {query} = payload
		this.searchRequest()

		let service = new ColleagueService()
		return service.searchColleague(query)
		.then(colleagues => {
			this.searchSuccess()
			return Promise.resolve(colleagues)
		})
		.catch(error => {
			this.searchFailure()
			return Promise.reject(error)
		})
	}
}

export const colleague = getModule(ColleagueModule)
// export const colleague = new ColleagueModule({ store, name: "colleague" })