<div v-if="!!user">
    <h1>{{ $t('vm.root.user.informations.main-title') }}</h1>
    <div class="grid-2">
        <input-frame type="text" ref="title" id="title" v-model="user.coordinates.title"
            :label="$t('vm.root.user.informations.title.label')"
            :placeholder="$t('vm.root.user.informations.title.placeholder')"
            :title="$t('vm.root.user.informations.title.title')"/>

        <togglebox-frame id="gender" :label="$t('vm.root.user.informations.gender.label')" :items="genderItems" v-model="user.coordinates.gender"/>

        <input-frame type="text" ref="last_name" id="last-name" v-model="user.coordinates.last_name"
            :label="$t('vm.root.user.informations.last-name.label')"
            :error-test="hasLastNameError"
            :error-msg="$t('vm.root.user.informations.last-name.error-msg')"
            :placeholder="$t('vm.root.user.informations.last-name.placeholder')"
            :title="$t('vm.root.user.informations.last-name.title')"/>

        <input-frame type="text" ref="first_name" id="first-name" v-model="user.coordinates.first_name"
            :label="$t('vm.root.user.informations.first-name.label')"
            :error-test="hasFirstNameError"
            :error-msg="$t('vm.root.user.informations.first-name.error-msg')"
            :placeholder="$t('vm.root.user.informations.first-name.placeholder')"
            :title="$t('vm.root.user.informations.first-name.title')"/>

        <input-frame type="text" ref="siren" id="siren" v-model="user.coordinates.siren"
            :label="$t('vm.root.user.informations.siren.label')"
            :error-test="hasSirenError"
            :error-msg="$t('vm.root.user.informations.siren.error-msg')"
            :placeholder="$t('vm.root.user.informations.siren.placeholder')"
            :title="$t('vm.root.user.informations.siren.title')"/>

        <input-frame type="text" ref="adeli" id="adeli" v-model="user.coordinates.adeli"
            :label="$t('vm.root.user.informations.adeli.label')"
            :placeholder="$t('vm.root.user.informations.adeli.placeholder')"
            :title="$t('vm.root.user.informations.adeli.title')"/>

        <!-- <div class="input-frame grid-item-full">
            <label class="label" for="notes" data-text="Notes">
                <span>Notes</span>
            </label>
            <textarea id="notes" v-model="user.notes"></textarea>
        </div> -->
    </div>

    <hr>

    <section>
        <h2>{{ $t('vm.root.user.informations.your-avatar') }}</h2>
        <div class="p-like text-center">
            <div class="account-avatar">
                <avatar class="svg-block" :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
                <a class="text-sm" href="https://fr.gravatar.com/" :title="$t('vm.root.user.informations.avatar.title')" target="_blank">
                    {{ $t('vm.root.user.informations.avatar.label') }}
                </a>
            </div>
        </div>
    </section>
</div>