import { DayOfMonthType, RecurringType } from "@Enums/index"
import { IRecurring } from "@Store/types"

export class Recurring {
    public static createNewRecurring(date: Date): IRecurring {
        return {
            id: '-1',
            type: RecurringType.None,
            cycle: 1,
            day_of_month: DayOfMonthType.DayOfWeek,
            with_end_date: false,
            start_date: new Date(date),
            end_date: new Date(date),
            selected_days: [
                {id: 1, checked: !!date && date.getDay() === 1},
                {id: 2, checked: !!date && date.getDay() === 2},
                {id: 3, checked: !!date && date.getDay() === 3},
                {id: 4, checked: !!date && date.getDay() === 4},
                {id: 5, checked: !!date && date.getDay() === 5},
                {id: 6, checked: !!date && date.getDay() === 6},
                {id: 0, checked: !!date && date.getDay() === 0},
            ]
        }
    }
}