import Render from '@Views/root/accounting/remittances/remittance/aside-remittance.html'
import config from "@Config/index"
import { Component, Vue } from 'vue-property-decorator'
import { IAccountingFilter, IRemittance } from '@Store/types'
import { accounting } from '@Store/modules'

@Render
@Component({})
export class ASideRemittance extends Vue {

	remittances: IRemittance[] = []

	// @State('accounting') stateAccounting: AccountingState
	// @Action('accounting/loadRemittances') loadRemittances: () => Promise<any>
	// @Action('accounting/deleteRemittance') deleteRemittance: (rem_id: string) => Promise<any>

	get filter(): IAccountingFilter {
		return accounting.filter
	}
	get remittance(): IRemittance {
		return accounting.remittance
	}
	get isDeleting(): boolean {
		return accounting.isDeleting
	}
	get isNewRemittance(): boolean {
		return !this.$route.params.rem_id
	}
	get remittancePath(): string {
		let param: string = this.isDemo ? "?demo=true" : ""
		let label: string = (this.remittance.label || "remise").replace(/\s+|[,\/]/g, "-")
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/remittances/${this.remittance.id}/${label}.pdf${param}`
	}
	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}

	mounted() {
		accounting.loadRemittances()
		.then(remittances => {
			this.remittances = remittances
		})
	}

	submitDelete(): void {
		const { remittance } = this
		let route = {name: 'remittances'}
	
		this.$emit('delete')
		if (!!remittance && !!remittance.id && parseInt(remittance.id) !== -1) {
			accounting.deleteRemittance(remittance.id).then(() => {
			this.$router.push(route)
		  })
		} else {
		  this.$router.push(route)
		}
	}
}