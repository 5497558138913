import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import {store} from '@Store/index'
import {
	ExclusionTestDeletePayload,
	IAntecedent,
	IAttachment,
	IColleague,
	IConsultation,
	ICoordinates,
	IMember,
	IOffice,
	IPatient,
	IPediatrics,
	IReason,
	IRelaunch,
	ISphere,
	ITodayInfos,
	PatientBlacklistPayload,
	PatientConfirmPayload,
	PatientDeadPayload,
	PatientDuplicatePayload,
	PatientLoadPayload, PatientMergePayload,
	PatientSavePayload
} from "@Store/types"
import {
	Gender,
	MemberException,
	MemberType,
	RequestStatus
} from "@Enums/index"
import {PatientService} from "@Services/index"
import {configuration, notif, office, user} from "@Store/modules"
import {Backup, Patient, Queue} from "@Models/index"
import {StorageHelper} from "@Helpers/index"

@Module({
	name: 'patient',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class PatientModule extends VuexModule {
	private status: RequestStatus = RequestStatus.None
    private statusSaving: RequestStatus = RequestStatus.None
    private statusDeleting: RequestStatus = RequestStatus.None
    private statusBlacklisting: RequestStatus = RequestStatus.None
    private statusDeceasing: RequestStatus = RequestStatus.None
    private statusDuplicating: RequestStatus = RequestStatus.None
    private statusSharing: RequestStatus = RequestStatus.None
	private familyTemp: IMember[] = []
    patient: IPatient = null
    blacklisted: boolean = false
    deceased: boolean = false

	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isBlacklisting(): boolean {
		return this.statusBlacklisting === RequestStatus.Loading
	}
	get isDeceasing(): boolean {
		return this.statusDeceasing === RequestStatus.Loading
	}
	get isShareFailed(): boolean {
		return this.statusSharing === RequestStatus.Failed
	}
    get coordinates(): ICoordinates {
        return this.patient && this.patient.coordinates
    }
    get pediatrics(): IPediatrics {
        return this.patient && this.patient.pediatrics
    }
    get relaunch(): IRelaunch {
        return this.patient && this.patient.relaunch
    }
    get family(): IMember[] {
        let members: IMember[] = (this.patient && this.patient.family && this.patient.family.currents) || []
        let temp: IMember[] = this.familyTemp || []
        return [...members, ...temp]
	}
	get member(): (id: string) => IMember {
		return (id: string) => {
			let members: IMember[] = this.family || []
			let _member: IMember = members.find(m => { return m.link_id === id })
			if (!!_member) return _member

			const newMember: IMember = {
				id: '-1',
				link_id: id,
				gender: Gender.Man,
				deceased: false,
				type: MemberType.None,
				last_name: '',
				first_name: '',
				birth_date: new Date(),
				email: ''
			}
			return newMember
		}
	}
    get todayInfos(): ITodayInfos {
        return this.patient && this.patient.today_infos
    }
    get antecedents(): IAntecedent[] {
        return this.patient && this.patient.antecedents && this.patient.antecedents.currents
    }
    get spheres(): ISphere[] {
        return this.patient && this.patient.spheres && this.patient.spheres.currents
    }
    get consultations(): IConsultation[] {
        let consultations: IConsultation[] = this.patient && this.patient.consultations && this.patient.consultations.currents
        return !!consultations && consultations.sort((a: IConsultation, b: IConsultation) => { return new Date(b.act_date).getTime() - new Date(a.act_date).getTime() })
	}
    get reasons(): IReason[] {
        return this.patient && this.patient.reasons && this.patient.reasons.currents
	}
    get attachments(): IAttachment[] {
		let attachments: IAttachment[] = this.patient && this.patient.attachments && this.patient.attachments.currents
		return !!attachments && attachments.sort((a: IAttachment, b: IAttachment) => { return new Date(b.act_date).getTime() - new Date(a.act_date).getTime() })
	}
	get consultation(): (id: string) => IConsultation {
		return (id: string) => {
			return this.consultations && this.consultations.find(consultation => {
				return consultation.id === id || consultation.create_id === id || consultation.id === this.createId('consultation', id)
			})
		}
	}
	get reasonTitle(): (consultation: IConsultation) => string {
		return (consultation: IConsultation) => {
			if (!consultation || !consultation.reason) return ''

			let result: string = ''
			let index: number = 0
			for(let tag of consultation.reason) {
				result += `${tag}${index === consultation.reason.length - 1 ? '': ', '}`
				index++
			}
			return result.trim()
		}
	}
	get reason(): (id: string) => IReason {
		return (id: string) => {
			let reasons: IReason[] = this.reasons
			let result:IReason = reasons && reasons.find(reason => {
				return reason.id === id || reason.create_id === id || reason.id === this.createId('reason', id)
			})
			if (!result) {
				result = Patient.createNewReason(id, configuration.configuration)
				this.reasons.push(result)
			}
			return result
		}
	}
	get attachment(): (id: string) => IAttachment {
		return (id: string) => {
			let attachments: IAttachment[] = this.attachments
			return attachments && attachments.find(attachment => {
				return attachment.id === id || attachment.create_id === id || attachment.id === this.createId('attachment', id)
			})
		}
	}
	get owner(): (item: {id?: string, creator_id?: string}) => string {
		return (item: {id: string, creator_id: string}) => {
			if (!item || !item.id || parseInt(item.id) === -1) return ''
			if (!item.creator_id) return ''

			if (!user.user || user.user.id === item.creator_id) return ''

			let offices: IOffice[] = office.officesByUser(item.creator_id)
			for (let office of offices) {
				let userLinked = office.links.find(u => { return u.id === item.creator_id})
				if (!userLinked) continue

				return `${userLinked.coordinates.first_name} ${userLinked.coordinates.last_name}`
			}
			return "un collègue"
		}
	}
	get createId(): (target: string, createId: string) => string {
		return (target: string, createId: string) => {
			let createArray = this.patient['create_array']
			if (!createArray || !createArray[target]) return

			return createArray[target][createId]
		}
    }


	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusBlacklisting = RequestStatus.None
		this.statusDeceasing = RequestStatus.None
		this.statusDuplicating = RequestStatus.None
		this.statusSharing = RequestStatus.None
		this.patient = null
		this.blacklisted = false
		this.deceased = false
		this.familyTemp = []
	}

	@Mutation
	patientRequest() {
		this.status = RequestStatus.Loading
		this.patient = null
		this.blacklisted = false
		this.deceased = false
	}

	@Mutation
	patientSuccess(patient: IPatient) {
		this.status = RequestStatus.Success
		this.patient = patient
		this.blacklisted = patient.blacklisted
		this.deceased = patient.deceased
		this.familyTemp = []
	}

	@Mutation
	patientFailure() {
		this.status = RequestStatus.Success
		this.patient = null
		this.blacklisted = false
		this.deceased = false
	}

	@Mutation
	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
		this.familyTemp = []
	}

	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(patient: IPatient) {
		this.statusSaving = RequestStatus.Success
		this.patient = patient
		this.blacklisted = patient.blacklisted
		this.deceased = patient.deceased
		this.familyTemp = []
	}

	@Mutation
	saveFailure(patient: IPatient) {
		this.statusSaving = RequestStatus.Success
		this.patient = patient
		this.blacklisted = patient.blacklisted
		this.deceased = patient.deceased
	}

	@Mutation
	blacklistRequest() {
		this.statusBlacklisting = RequestStatus.Loading
	}

	@Mutation
	blacklistSuccess(blacklisted: boolean) {
		this.statusBlacklisting = RequestStatus.Success
		this.blacklisted = blacklisted
	}

	@Mutation
	blacklistFailure() {
		this.statusBlacklisting = RequestStatus.Success
		this.blacklisted = false
	}

	@Mutation
	deadRequest() {
		this.statusDeceasing = RequestStatus.Loading
	}

	@Mutation
	deadSuccess(deceased: boolean) {
		this.statusDeceasing = RequestStatus.Success
		this.deceased = deceased
	}

	@Mutation
	deadFailure() {
		this.statusDeceasing = RequestStatus.Success
		this.deceased = false
	}

	@Mutation
	duplicateRequest() {
		this.statusDuplicating = RequestStatus.Loading
	}

	@Mutation
	duplicateSuccess() {
		this.statusDuplicating = RequestStatus.Success
	}

	@Mutation
	duplicateFailure() {
		this.statusDuplicating = RequestStatus.Success
	}

	@Mutation
	shareRequest() {
		this.statusSharing = RequestStatus.Loading
	}

	@Mutation
	shareSuccess() {
		this.statusSharing = RequestStatus.Success
	}

	@Mutation
	shareFailure() {
		this.statusSharing = RequestStatus.Failed
	}

	@Action
	addMemberTemp(member: IMember): void {
		if (!member) return

		this.familyTemp.push(member)
	}

	@Action
	addMember(member: IMember): MemberException {
		if (!member) return MemberException.None

		if (!!this.patient.family.currents.find(m => m.link_id === member.link_id))
			return MemberException.Already
		else if (this.patient.id === member.link_id)
			return MemberException.Same

		this.patient.family.currents.unshift(member)
		return MemberException.None
	}

	@Action
	deleteMember(member: IMember): void {
		if (!member) return
		if (parseInt(member.id) === -1) {
			this.patient.family.currents = this.patient.family.currents.filter(m => m !== member)
			return
		}

		this.patient.family.deleted.push(member)
		this.patient.family.currents = this.patient.family.currents.filter(m => m.id !== member.id)
	}

	@Action
	addColleague(colleague: IColleague): void {
		if (!colleague) return
		this.patient.coordinates.colleagues.currents.unshift(colleague)
	}

	@Action
	deleteColleague(colleague: IColleague): void {
		if (!colleague) return
		if (parseInt(colleague.id) === -1) {
			this.patient.coordinates.colleagues.currents = this.patient.coordinates.colleagues.currents.filter(c => c !== colleague)
			return
		}

		this.patient.coordinates.colleagues.deleted.push(colleague)
		this.patient.coordinates.colleagues.currents = this.patient.coordinates.colleagues.currents.filter(c => c.id !== colleague.id)
	}

	@Action
	deleteAntecedent(antecedent: IAntecedent): void {
		if (!antecedent) return
		if (parseInt(antecedent.id) === -1) {
			this.patient.antecedents.currents = this.patient.antecedents.currents.filter(a => a !== antecedent)
			return
		}

		this.patient.antecedents.deleted.push(antecedent)
		this.patient.antecedents.currents = this.patient.antecedents.currents.filter(a => a.id !== antecedent.id)
	}

	@Action
	deleteSphere(sphere: ISphere): void {
		if (!sphere) return
		if (parseInt(sphere.id) === -1) {
			this.patient.spheres.currents = this.patient.spheres.currents.filter(s => s !== sphere)
			return
		}

		this.patient.spheres.deleted.push(sphere)
		this.patient.spheres.currents = this.patient.spheres.currents.filter(s => s.id !== sphere.id)
	}

	@Action
	deleteConsultation(consultation: IConsultation): void {
		if (!consultation) return
		if (parseInt(consultation.id) === -1) {
			this.patient.consultations.currents = this.patient.consultations.currents.filter(c => c.create_id !== consultation.create_id)
			return
		}

		this.patient.consultations.deleted.push(consultation)
		this.patient.consultations.currents = this.patient.consultations.currents.filter(c => c.id !== consultation.id)
	}

	@Action
	deleteExclusionTest(payload: ExclusionTestDeletePayload): void {
		if (!payload.test || !payload.consultation) return

		if (parseInt(payload.test.id) === -1 || parseInt(payload.consultation.id) === -1) {
			payload.consultation.exclusion_tests.currents = payload.consultation.exclusion_tests.currents.filter(t => t !== payload.test)
			return
		}

		let consultation = this.patient.consultations.currents.find(consultation => consultation.id === payload.consultation.id)
		if (!consultation) return

		let test = consultation.exclusion_tests.currents.find(test => test.id === payload.test.id)
		if (!test) return

		consultation.exclusion_tests.deleted.push(test)
		consultation.exclusion_tests.currents = consultation.exclusion_tests.currents.filter(test => test.id !== payload.test.id)
	}

	@Action
	deleteReason(reason: IReason): void {
		if (!reason) return
		if (parseInt(reason.id) === -1) {
			this.patient.reasons.currents = this.patient.reasons.currents.filter(r => r !== reason)
			return
		}

		this.patient.reasons.deleted.push(reason)
		this.patient.reasons.currents = this.patient.reasons.currents.filter(r => r.id !== reason.id)
	}

	@Action
	deleteAttachment(attachment: IAttachment): void {
		if (!attachment) return
		if (parseInt(attachment.id) === -1) {
			this.patient.attachments.currents = this.patient.attachments.currents.filter(a => a !== attachment)
			return
		}

		this.patient.attachments.deleted.push(attachment)
		this.patient.attachments.currents = this.patient.attachments.currents.filter(a => a.id !== attachment.id)
	}

	@Action({rawError: true})
	async deletePatient(payload: PatientLoadPayload): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		const {pat_id} = payload
		this.deleteRequest()

		let service = new PatientService()
		return service.deletePatient(pat_id)
		.then(() => {
			Backup.getInstance().delete(pat_id)
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			notif.warning(error)
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadPatient(payload: PatientLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		const {pat_id} = payload
		this.patientRequest()

		let service = new PatientService()
		return service.getPatient(pat_id)
		.then(patient => {
			Backup.getInstance().add(patient)
			this.patientSuccess(patient)
			StorageHelper.remove('osteo2ls-lic')
			return Promise.resolve(patient)
		}, (error) => {
			return Promise.reject(error)
		})
		.catch(error => {
			notif.warning(error)
			let patient:IPatient = Backup.getInstance().get(pat_id)
			if (!!patient && error.status !== 406) {
				this.patientSuccess(patient)
				return Promise.resolve(patient)
			} else {
				this.patientFailure()
				return Promise.reject(error)
			}
		})
	}

	@Action
	async duplicatePatient(payload: PatientSavePayload): Promise<any> {
		if (this.statusDuplicating === RequestStatus.Loading) return

		this.duplicateRequest()

		payload.patient.coordinates.without_birth_date = !payload.patient.coordinates.birth_date

		let service = new PatientService()
		return service.save(payload.patient, payload.callbackProgress)
		.then(newPatient => {
			this.duplicateSuccess()
			return Promise.resolve(newPatient)
		})
		.catch(() => { this.duplicateFailure() })
	}

	@Action({rawError: true})
	async savePatient(payload: PatientSavePayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		payload.patient.coordinates.without_birth_date = !payload.patient.coordinates.birth_date

		let service = new PatientService()
		return service.save(payload.patient, payload.callbackProgress)
		.then(newPatient => {
			Backup.getInstance().add(newPatient)
			this.saveSuccess(newPatient)
			this.patientSuccess(newPatient)
			return Promise.resolve(newPatient)
		})
		.catch(error => {
			Queue.getInstance().enqueue(payload.patient)
			this.saveFailure(payload.patient)
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async blacklistPatient(payload: PatientBlacklistPayload): Promise<any> {
		if (this.statusBlacklisting === RequestStatus.Loading) return

		this.blacklistRequest()

		let service = new PatientService()
		return service.blacklist(payload.pat_id, payload.blacklisted)
		.then(() => {
			Backup.getInstance().blacklist(payload.pat_id, payload.blacklisted)
			this.blacklistSuccess(payload.blacklisted)
			return Promise.resolve(payload.blacklisted)
		})
		.catch(error => {
			this.blacklistFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deadPatient(payload: PatientDeadPayload): Promise<any> {
		if (this.statusDeceasing === RequestStatus.Loading) return

		this.deadRequest()

		let service = new PatientService()
		return service.dead(payload.pat_id, payload.deceased)
		.then(() => {
			Backup.getInstance().dead(payload.pat_id, payload.deceased)
			this.deadSuccess(payload.deceased)
			return Promise.resolve(payload.deceased)
		})
		.catch(error => {
			this.deadFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadBackup(): Promise<any> {
		let service = new PatientService()
		return service.getBackup()
		.then(result => {
			Backup.getInstance().load(result)
			return Promise.resolve(result)
		})
		.catch(error => {
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async checkDuplicate(payload: PatientDuplicatePayload): Promise<any> {
		if (this.statusDuplicating === RequestStatus.Loading) return

		this.duplicateRequest()

		let service = new PatientService()
		return service.checkDuplicate(payload)
		.then((result) => {
			this.duplicateSuccess()
			return Promise.resolve(result)
		})
		.catch(error => {
			this.duplicateFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async getDuplicate(payload: PatientLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		const {pat_id} = payload
		this.patientRequest()

		let service = new PatientService()
		return service.getDuplicate(pat_id)
		.then(patient => {
			Backup.getInstance().add(patient)
			this.patientSuccess(patient)
			return Promise.resolve(patient)
		})
		.catch(error => {
			notif.warning(error)
			let patient:IPatient = Backup.getInstance().get(pat_id)
			if (!!patient) {
				this.patientSuccess(patient)
				return Promise.resolve(patient)
			} else {
				this.patientFailure()
				return Promise.reject(error)
			}
		})
	}

	@Action({rawError: true})
	async confirmPatient(payload: PatientConfirmPayload): Promise<any> {
		const {pat_id, date} = payload

		this.shareRequest()
		let service = new PatientService()
		return service.confirm(pat_id, date)
		.then(() => {
			this.shareSuccess()
			return Promise.resolve()
		}, (error) => {
			this.shareFailure()
			return Promise.reject(error)
		})
		.catch(error => {
			this.shareFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async mergePatient(payload: PatientMergePayload): Promise<any> {
		let service = new PatientService()
		return service.merge(payload)
		.then(() => {
			return Promise.resolve()
		}, (error) => {
			return Promise.reject(error)
		})
		.catch(error => {
			return Promise.reject(error)
		})
	}
}

export const patient = getModule(PatientModule)
