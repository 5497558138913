import Render from '@Views/root/calendar/aside-calendar.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { EventHelper } from '@Helpers/index'
import { PlanningType } from '@Enums/index'
import { Debouncer } from '@Models/index'
import { IUser, IEventFilter, IEvent } from '@Store/types'
import { event, user } from '@Store/modules'

@Render
@Component({})
export class ASideCalendar extends Vue {

	private debouncer: Debouncer = null
	searched: boolean = false
	searching: boolean = false
	resultSearch: IEvent[] = []
	query: string = ''

	// @State('user') stateUser: UserState
	// @Getter('user/isSecretaryUser') isSecretaryUser: boolean
	// @Action('event/searchEvents') searchEvents: (payload: EventSearchPayload) => any

	private get selectedUser(): IUser {
		return user.selectedPlanningUser
	}
	private get byUsrId(): string {
		if (!user.isSecretaryUser) return
		if (!this.selectedUser) return

		return this.selectedUser.id
	}

	mounted() {
		this.debouncer = new Debouncer(this.search, 500)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	link(event:{id: string, pla_id: string, type: PlanningType}): {name: string, params?: any} {
		return EventHelper.getEventUrl(event)
	}

	search(): void {
		this.searching = true
		this.resultSearch = []
		let filter: IEventFilter = {by_first_name: true, by_last_name: true, by_phone: true, by_usr_id: this.byUsrId }
	
		event.searchEvents({query: this.query, filter: filter})
		.then(results => {
			this.searched = true
			this.searching = false
			this.resultSearch = results
		})
		.catch(error => {
			this.searched = true
			this.searching = false
		})
	}

	@Watch('query')
	createDebounce(): void {
		if (!this.query) {
			this.searched = false
			this.resultSearch = []
		}

		this.debouncer.start(!!this.query)
  }
}