<div>
	<collapsible-help id="reason-information" :title="$t('vm.root.patients.patient.reason.title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.patients.patient.reason.help') }}
			</p>
		</div>
	</collapsible-help>
	<read-only :reason="reason" v-if="readOnly"/>
	<div v-else>
		<div :class="{'grid-2': reasonItems.length > 0}">
			<dropdown-frame v-if="reasonItems.length > 0" id="reason" v-model="rea_id" :items="reasonItems"
				:label="$t('vm.root.patients.patient.reason.reason.label')"
				:title="$t('vm.root.patients.patient.reason.reason.title')"
				:default-label="$t('vm.root.patients.patient.reason.reason.default-label')"/>
			<div class="input-frame-like">
				<button type="button" class="btn btn-primary btn--input-shape" @click="addReason" :title="$t('vm.root.patients.patient.reason.new-reason.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
					</svg>
					{{ $t('vm.root.patients.patient.reason.new-reason.label') }}
				</button>
			</div>
		</div>

		<div v-if="reason" class="grid-2 twin-textarea">
			<input-frame id="title" v-model="reason.title" class="grid-item-full"
				:label="$t('vm.root.patients.patient.reason.name.label')"
				:placeholder="$t('vm.root.patients.patient.reason.name.placeholder')"
				:error-test="hasTitleError" :error-msg="$t('vm.root.patients.patient.reason.name.error')"
				:title="$t('vm.root.patients.patient.reason.name.title')"/>

			<textarea-frame id="topography" v-model="reason.topography"
				:label="$t('vm.root.patients.patient.reason.topography.label')"
				:placeholder="$t('vm.root.patients.patient.reason.topography.placeholder')"
				:title="$t('vm.root.patients.patient.reason.topography.title')"/>

			<textarea-frame id="chronology" v-model="reason.chronology"
				:label="$t('vm.root.patients.patient.reason.chronology.label')"
				:placeholder="$t('vm.root.patients.patient.reason.chronology.placeholder')"
				:title="$t('vm.root.patients.patient.reason.chronology.title')"/>

			<textarea-frame id="factors" v-model="reason.factors"
				:label="$t('vm.root.patients.patient.reason.factors.label')"
				:placeholder="$t('vm.root.patients.patient.reason.factors.placeholder')"
				:title="$t('vm.root.patients.patient.reason.factors.title')"/>

			<textarea-frame id="signs" v-model="reason.signs"
				:label="$t('vm.root.patients.patient.reason.signs.label')"
				:placeholder="$t('vm.root.patients.patient.reason.signs.placeholder')"
				:title="$t('vm.root.patients.patient.reason.signs.title')"/>

			<textarea-frame id="description" v-model="reason.description" class="grid-item-full"
				:label="$t('vm.root.patients.patient.reason.description.label')"
				:placeholder="$t('vm.root.patients.patient.reason.description.placeholder')"
				:title="$t('vm.root.patients.patient.reason.description.title')"/>

			<h3 class="grid-item-full">{{ $tc('vm.root.patients.patient.forms.title', reason.forms.length) }}</h3>

			<div class="notice-info grid-item-full" v-if="reason.forms.length === 0">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
					</svg>
					{{ $t('vm.root.patients.patient.forms.none.title') }}
					<i18n path="vm.root.patients.patient.forms.none.description.reason-text" tag="span">
						<router-link :to="{name: 'parameters-forms'}" :title="$t('vm.root.patients.patient.forms.none.description.title')">{{ $t("vm.root.patients.patient.forms.none.description.link") }}</router-link>
					</i18n>
				</p>
			</div>
			<custom-form v-else class="grid-item-full" v-for="form in reason.forms" :key="form.id" :form="form"/>

			<div v-if="isMainUser" class="text-right grid-item-full">
				<delete-item v-if="parseInt(reason.id) !== -1" class="btn btn--link-like text-danger" :can-delete="true" :delete-action="unselectReason"
					:title="$t('vm.root.patients.patient.reason.dissociate.title')"
					:delete-msg="$t('vm.root.patients.patient.reason.dissociate.dissociate-msg')"
					:delete-label="$t('vm.root.patients.patient.reason.dissociate.label')">
					{{ $t('vm.root.patients.patient.reason.dissociate.label') }}
				</delete-item>
				<span v-if="parseInt(reason.id) !== -1">&bull;</span>
				<delete-item class="btn btn--link-like text-danger" :can-delete="true" :delete-action="() => { deleteSelectedReason(reason) }"
					:title="$t('vm.root.patients.patient.reason.delete.title')"
					:delete-msg="$t('vm.root.patients.patient.reason.delete.delete-msg')">
					<span>{{ $t('general.delete.text') }}</span>
				</delete-item>
			</div>
		</div>

	</div>

</div>
