<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.reminder.title') }}</h1>
	<section>
		<dropdown-frame id="reminder-type" v-model="configuration.reminder_type" :items="types"
			:title="$t('vm.root.parameters.reminder.type.title')"
			:label="$t('vm.root.parameters.reminder.type.label')"/>

		<div v-if="canSendMail">
			<collapsible-help id="reminder-information" :title="$t('vm.root.parameters.reminder.details-mail.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.parameters.reminder.details-mail.text" tag="span">
							<strong>{{ $t('vm.root.parameters.reminder.details-mail.strong') }}</strong>
						</i18n>
					</p>
				</div>
			</collapsible-help>

			<input-document-frame id="reminder-mail-text" ref="inputDocument" v-model="configuration.reminder_mail_text"
				:options="lightOptions"
				:label="$t('vm.root.parameters.reminder.text-mail.label')"
				:placeholder="$t('vm.root.parameters.reminder.text-mail.placeholder')"
				:title="$t('vm.root.parameters.reminder.text-mail.title')"/>

		</div>
	
		<div v-if="canSendSms">
			<collapsible-help id="reminder-information" :title="$t('vm.root.parameters.reminder.details-sms.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.parameters.reminder.details-sms.text1.content" tag="span">
							<strong>{{ $t('vm.root.parameters.reminder.details-sms.text1.strong') }}</strong>
						</i18n>
					</p>
					<i18n path="vm.root.parameters.reminder.details-sms.text2.content" tag="p" class="text-left">
						<strong>{{ $t('vm.root.parameters.reminder.details-sms.text2.strong1') }}</strong>
						<strong>{{ $t('vm.root.parameters.reminder.details-sms.text2.strong2') }}</strong>
					</i18n>
					<p class="text-left">
						{{ $t('vm.root.parameters.reminder.details-sms.text3') }}
						<ul>
							<i18n path="vm.root.parameters.reminder.details-sms.list.item1.content" tag="li">
								<strong>{{ $t('vm.root.parameters.reminder.details-sms.list.item1.strong') }}</strong>
							</i18n>
							<i18n path="vm.root.parameters.reminder.details-sms.list.item2.content" tag="li">
								<strong>{{ $t('vm.root.parameters.reminder.details-sms.list.item2.strong') }}</strong>
							</i18n>
							<i18n path="vm.root.parameters.reminder.details-sms.list.item3.content" tag="li">
								<strong>{{ $t('vm.root.parameters.reminder.details-sms.list.item3.strong') }}</strong>
							</i18n>
						</ul>
						<i18n path="vm.root.parameters.reminder.details-sms.text4.content" tag="span">
							<strong>{{ $t('vm.root.parameters.reminder.details-sms.text4.strong') }}</strong>
						</i18n>
					</p>
				</div>
			</collapsible-help>

			<div class="childs-space-between" :class="user.sms_count | smsClass">
				<i18n path="vm.root.parameters.reminder.sms.balance.content" tag="span">
					<strong>{{ $t('vm.root.parameters.reminder.sms.balance.strong', {'sms-count': user.sms_count}) }}</strong>
				</i18n>
				<router-link class="text-strong" :to="to" :title="$t('vm.root.parameters.reminder.sms.title')">
					{{ $t('vm.root.parameters.reminder.sms.label') }}
				</router-link>
			</div>

			<textarea-frame id="reminder-text" v-model="configuration.reminder_text"
				:label="$t('vm.root.parameters.reminder.text-sms.label', {length: reminderTextLength})"
				:error-test="reminderError" :error-msg="reminderErrorMessage"
				:placeholder="$t('vm.root.parameters.reminder.text-sms.placeholder')"
				:title="$t('vm.root.parameters.reminder.text-sms.title')"/>

		</div>
	</section>
</div>
