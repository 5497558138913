<context>
	<div class="wrapper accounting-context">

		<section class="marg-v" v-if="!!filter">
			<h2>{{ $t('vm.root.accounting.book.context.title') }}</h2>

			<input-date-frame v-model='filter.day_date' id="day-date" :type="dateType"
				:label="$t('vm.root.accounting.book.context.day.label')"
				:placeholder="$t('vm.root.accounting.book.context.day.placeholder')"
				:title="$t('vm.root.accounting.book.context.day.title')"/>

			<div class="form-group">
				<button type="button" @click="previous" class="input-like form-group-input is-narrow-padding-v text-center" :title="$t('vm.root.accounting.book.context.previous.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.accounting.book.context.previous.label') }}</span>
				</button>
				<button type="button" @click="next" class="input-like form-group-input is-narrow-padding-v text-center" :title="$t('vm.root.accounting.book.context.next.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.accounting.book.context.next.label') }}</span>
				</button>
			</div>

			<dropdown-frame id="office" v-model="filter.ofi_id" :items="officeItems"
				:label="$t('vm.root.accounting.context.office.label')"
				:title="$t('vm.root.accounting.context.office.title')"/>
		</section>

	</div>
</context>
