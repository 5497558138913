<div class="wrapper-full marg-v calendar">
	<h2 class="h-reset text-md text-primary text-bold text-center">{{ title }}</h2>
	<calendar	ref="calendar"
				:config="config"
				:scroll-time="scrollTime"
				:events="allEvents"
				:event-duration-editable="false"
				:event-start-editable="false"
				default-view="timeGridDay"/>
</div>
