import Render from '@Views/root/statistics/colleagues/colleague/details.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPhone } from '@Components/index'
import { IColleague } from '@Store/types'
import { Tag } from '@Models/tag'

@Render
@Component({})
export class DetailsColleague extends Vue {
    
    phone: IPhone
    @Prop() colleague: IColleague
    @Prop({default: false}) submitted: boolean

    get hasLastNameError(): boolean {
		return this.submitted && !this.colleague.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.colleague.first_name
	}

    getProfessionList(tag: string): any {
		return Tag.getProfession(tag)
	}
}