import Render from '@Views/components/frame/dropdown-frame.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDropdownItem } from '@Components/input/dropdown'

@Render
@Component({})
export class DropdownFrame extends Vue {

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) manageMissing: boolean
    @Prop({default: false}) withHelp: boolean
    @Prop({default: 'sélectionnez une valeur'}) defaultLabel: string
    @Prop({default: ''}) defaultValue: any
    @Prop() title: string
    @Prop() errorMsg: string
    @Prop() label: string
    @Prop() items: IDropdownItem[]
    @Prop() id: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get selectedValue(): any {
        return this.value
    }
    set selectedValue(value: any) {
        this.$emit('input', value)
    }
}
