import Render from '@Views/root/parameters/test.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PhoneType, Skin } from '@Enums/index'
import { IDropdownItem, IPhone, IToggleBoxItem } from '@Components/index'
import { IConfiguration } from '@Store/types'
import { Tag } from '@Models/index'

@Render
@Component({})
export class Test extends Vue {

	@Prop() configuration: IConfiguration

	///////// TO BE DELETED
	with_color: boolean = false
	color: string = "#ffeedd"
	profession: any = []
	yesNo: boolean = false
	radioTest: any = "rouge"
	test: any = {test:undefined}
	testItems: IDropdownItem[] = [
		{value: 3, label: "Valeur 3", icon:"circle", color: "#ffddaa"}, 
		{value: "abcd", label: "Valeur abcd", icon:"circle", color: "#112233"}, 
		{value: "x", label: "Valeur x", icon:"circle", color: "#FF0000"}
	]
	toogleboxValue: any = "abcd"
	testToggleBoxItems: IToggleBoxItem[] = [{value: 3, label: "Valeur 3"}, {value: "lorem", label: "lorem ipsum dolor sit amet"}, {value: "x", label: "Valeur x"}]
	currentSkin: Skin = Skin.Default
	currentsPhone: IPhone[] = [{id: '-1', number: '+41775565700', iso: 'ch', type: PhoneType.Fix, error: false}]
	phones: {currents: IPhone[], deleted: IPhone[]} = {currents: this.currentsPhone, deleted: []}
	defaultPhone: IPhone = {id: '-1', number: '', iso: 'fr', type: PhoneType.Mobil}
	phone: IPhone = {id: '-1', number: '', iso: 'fr', type: PhoneType.Mobil}
	myDate: Date = new Date()
	myDate2: Date = new Date()
	hasPhoneError(phone: IPhone): boolean {
        return phone.error
	}
	updateMyDate(date: Date) {
		this.myDate2 = new Date(date)
	}

	editorData: string = "<table><tbody><tr width='100%'><td width='50%'><strong><capitalize>{mon.prenom} {mon.nom}</capitalize></strong><br><strong>{mon.titre}</strong><br>{cabinet.adresse}{cabinet.cp} {cabinet.ville}Tél : {cabinet.telephone}N° SIREN : {mon.siren}N° ADELI : {mon.adeli}</td><td width='50%'><right>Facture : {facture.numero}Le {facture.date}</right></td></tr><tr><td></td><td></td></tr><tr><td></td><td><strong>À l'attention de :</strong><br><strong><capitalize>{patient.prenom} {patient.nom}</capitalize></strong><br>{patient.adresse}{patient.cp} {patient.ville}</td></tr></tbody></table>"
	///////// TO BE DELETED

	//#region getters
	get hasErrorTest(): boolean {
		return !this.test.test
	}
	//#endregion

	mounted() {
	}

	search(tag: string): any {
		return Tag.getProfession(tag)
	}
}
