import Render from '@Views/root/search/member/item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IMember, IPopinAction, IPopin } from '@Store/types'
import { patient, popIn, user } from '@Store/modules'

@Render
@Component({})
export class MemberItem extends Vue {

	@Prop() member: IMember
	@Prop() readOnly: boolean

	get isMainUser(): boolean {
		return user.isMainUser
	}

	unselect(): void {
        let unselectAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.member.item.unselect.label').toString(),
			title: this.$i18n.t('vm.root.search.member.item.unselect.title').toString(),
			callback: (data: any) => {
				patient.deleteMember(this.member)
				return Promise.resolve()
			}
		}
		let unselectPopin: IPopin = {
			title: this.$i18n.t('vm.root.search.member.item.unselect.title').toString(),
			content: this.$i18n.t('vm.root.search.member.item.unselect.content').toString(),
			action: unselectAction
		}

        popIn.warning(unselectPopin)
	}
}
