import Render from '@Views/root/shop/context-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { IPurchase } from '@Store/types'
import { PurchaseItem } from './purchase-item'
import { shop } from '@Store/modules'
import { InfiniteScroll } from "@ViewModels/components"

@Render
@Component({
	components: {
		'infinite-scroll': InfiniteScroll,
		'purchase-item': PurchaseItem
	}
})
export class ContextMain extends Vue {

	static purchasePerPage: number = 5

	initialized: boolean = true
	hasMorePurchases: boolean = true
	private page: number

	//#region Getters
	get isLoading(): boolean {
		return shop.isPurchasesLoading
	}
	get purchaseList(): IPurchase[] {
		return shop.purchases
	}
	//#endregion

	mounted() {
		this.page = 0
		shop.clearPurchases()
		this.loadNextPurchases()
	}

	loadNextPurchases(): void {
		shop.loadPurchases(this.page)
		.then((purchases: IPurchase[]) => {
			this.hasMorePurchases = purchases.length >= ContextMain.purchasePerPage
			this.page++
			this.initialized = false
		})
	}
}
