import { PlanningType } from "@Enums/planning"

export class PlanningHelper {

    public static convertGoogleCalendarToCalendar(calendar: any, display?: {id: string, visible: boolean}[]) {
        if (calendar === undefined) return

        let planning = {
            id: calendar.id,
            name: calendar.summary,
            color: calendar.backgroundColor,
            textColor: calendar.foregroundColor,
            display: calendar.selected !== undefined && calendar.selected,
            editable: calendar.accessRole === 'owner',
            events: [],
            type: PlanningType.Google,
            timeout: null,
            visible: true
        }

        if (display != null) {
            let value = display.filter((element: { id: string }) => {return element.id === planning.id})
            planning.visible = value.length === 0 || value[0].visible
        }

        return planning
    }
}
