<div v-if="!isEmpty || withWhiteSpace">
	<h3>{{ $tc('vm.root.patients.patient.forms.title', forms.length) }}</h3>
	<div v-if="isEmpty" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p class="text-lg">{{ $t('vm.root.patients.patient.read-only.none') }}</p>
	</div>

	<form-summary v-else v-for="form in forms" :key="form.id" :form="form"/>
</div>
