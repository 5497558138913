export const gift3 = {
    title: "Vos 10 mois offerts",
    subtitle: "Votre profession et ses bienfaits en 10 questions",
    help: {
        text1: "Si vous arrivez à ce niveau, vous avez déjà peut-être 2 mois offerts sur votre planning en ligne. Félicitations !",
        text2: "Mais vous pouvez obtenir jusqu'à 10 mois supplémentaires très simplement. Il vous suffit de répondre à 10 questions parmi celles proposées ci-dessous. Le but de vos réponses sera d'aider vos futurs patients lors de leur recherche.",
        text3: "En effet, un patient ne recherche pas forcément un ostéopathe quand il a mal au ventre. Peut-être que, comme beaucoup, il effectuera une recherche sur un moteur de recherche, si ce dernier lui propose une page qui répond à sa question, il y a de grandes chances qu'il clique sur celle-ci. Nous cherchons donc à aider vos patients, avec vos mots, à trouver une solution à leur problème.",
        text4: "Vous pouvez donc faire le choix de répondre à 10 de ces questions ou à une seule, voire aucune, c'est vous qui choisissez. Seules contraintes, vous devez apporter des réponses personnelles (ne pas chercher une réponse toute faite, cela pénaliserait plus qu'autre chose) et votre réponse doit contenir au moins 150 mots. Si vous avez peur pour l'orthographe, pas de crainte, nous effectuerons une relecture systématique avant de mettre vos réponses en ligne."
    },
    valided: {
        title: "Félicitations, tout simplement !",
        text: "Vos 10 mois vous ont été offerts"
    },
    waiting: {
        title: "Vous avez répondu à 10 questions !",
        text: "Nous reviendrons vers vous très rapidement pour valider celles en attente"
    },
    question: {
        none: "Sélectionnez une question",
        label: "Choix de la question",
        title: "Choisissez la question à laquelle vous souhaitez répondre",
        help1: "Suggestion de plan pour votre réponse :",
        li1: "Présentez le trouble en question. Comment peut-on être confronté à celui-ci ?",
        li2: "Quelles peuvent être les problématiques, difficultés ou douleurs engendrées par ce trouble ?",
        li3: "Comment l'ostéopathie peut aider à traiter ce problème ?",
        li4: "Et enfin, peut-être, évoquer ce qu'un traitement ostéopathique apportera comme bénéfice pour le patient.",
        help2: "Libre à vous de suivre ce plan ou pas.",
        validated: {
            title: "Le mois pour cette question vous a été offert",
            text: "Félicitations, votre présence sur la toile n'en sera que meilleure."
        },
        waiting: {
            title: "Votre réponse est à l'étude",
            text: "Nous reviendront très rapidement vers vous."
        }
    },
    answer: {
        'error-msg': "Votre réponse doit contenir au moins 150 mots",
        title: "Veuillez répondre à la question suivante en au moins 150 mots",
        placeholder: "Indiquez votre approche lorsqu'un patient vous pose cette question. Soyez pragmatique avec des explications les plus claires possibles."
    },
    counter: {
        label: "Nombre de mot : {0} ({1}).",
        span: "{counter}",
        strong: "150 mots minimum"
    },
    back: {
        label: "étape 2",
        title: "Retourner à l'étape précédente"
    },
    action: {
        label: "soumettre",
        'short-label': "votre réponse",
        title: "Soumettre vos informations pour bénéficier d'un mois offert"
    },
    next: {
        label: "bonus",
        title: "Découvrez les bonus supplémentaires"
    }
}