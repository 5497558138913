<div class="l-breadcrumb">

	<div class="top-ctrl rwd-over-small rwd-under-medium">
		<button type="button"
			class="btn-in-breadcrumb is-for-aside" v-toggle="{className: 'is-open', selector: '[data-toggle-aside]'}" data-toggle-aside
			:title="$t('vm.components.breadcrumb.breadcrumb.subtitle')">
			<span class="btn-in-breadcrumb-icon">
				<svg width="1em" class="btn-in-breadcrumb-icon-search" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<svg width="1em" class="btn-in-breadcrumb-icon-nav" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#bars"/>
				</svg>
			</span>
			<span class="btn-in-breadcrumb-text sr-only">{{$t('vm.components.breadcrumb.breadcrumb.text')}}</span>
		</button>
	</div>

	<nav class="breadcrumb" aria-label="Breadcrumb">
		<router-link :to="{name: 'home'}" class="breadcrumb-icon text-lg"
			:title="$t('vm.components.breadcrumb.breadcrumb.title')">
			<svg width="1em" class="svg-middle svg-block" viewBox="0 0 90 90" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#logo-min"/>
			</svg>
			<span class="sr-only">{{$t('vm.components.breadcrumb.breadcrumb.dashboard')}}</span>
		</router-link>
		<template v-for='item in items'>
			<span class="breadcrumb-icon" aria-hidden="true" focusable="false">
				<svg width="1em" class="svg-middle svg-block text-md" viewBox="0 0 192 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#angle-right"/>
				</svg>
			</span>
			<breadcrumb-item :item="item"/>
		</template>
	</nav>

	<div class="top-ctrl is-pushed-right rwd-over-small">
		<fullscreen class="btn-in-breadcrumb"/>
		<more-info v-if="linkedToContext"/>
	</div>

</div>
