import { IDropdownItem } from "@Components/index"

export class DropdownHelper {
    public static toDropdownItems(list: any[], valueProperty: string = 'id', labelProperty: string = 'name'): IDropdownItem[] {
        let result:IDropdownItem[] = []
	    list.forEach (item => result.push({value: item[valueProperty], label: item[labelProperty]}))
	    return result
    }

    public static toSimpleDropdownItems(list: string[]): IDropdownItem[] {
        let result:IDropdownItem[] = []
	    list.forEach (item => result.push({value: item, label: item}))
	    return result
    }
}