import Render from '@Views/root/patients/patient/consultation/read-only-reason.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IReason } from '@Store/types'
import { ReadOnlyForms } from '@Components/forms/read-only-forms'

@Render
@Component({
	components: {
		'read-only-forms': ReadOnlyForms
	}
})
export class ReadOnlyReason extends Vue {

	@Prop() reason: IReason
}
