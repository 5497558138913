import Render from '@Views/root/user/offices.html'
import { Vue, Component } from 'vue-property-decorator'
import { IOffice, IUser, IBreadcrumbItem } from '@Store/types'
import { Office, Common } from '@Models/index'
import { Dictionary } from 'vue-router/types/router'
import { breadcrumb, office, user } from '@Store/modules'

@Render
@Component({})
export class Offices extends Vue {
	
	private deploy: Dictionary<boolean> = {}
	
	// @State('user') stateUser: UserState
	// @Getter('office/offices') allOffices: () => IOffice[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean
	// @Getter('user/currentUser') currentUser: IUser
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('office/addOffice') addNewOffice: (office: IOffice) => void
	// @Action('office/deleteOffice') deleteOffice:(ofiId: string) => Promise<any>
	// @Action('office/activateOffice') activateOffice:(ofiId: string) => Promise<any>

	mounted() {
		this.$emit('navigate', 'offices')
		this.initializeBreadcrumb()
	}

	get offices() : IOffice[] {
		return office.offices.filter(o => { return office.isActive(o) })
	}
	get disableOffices() : IOffice[] {
		return office.offices.filter(o => { return !office.isActive(o) })
	}

	isOwner(office: IOffice): boolean {
		return !!office && office.creator_usr_id === user.currentUser.id
	}

	owner(office: IOffice): IUser {
		return office.links.find(u => { return u.id === office.creator_usr_id })
	}

	toggle(id: string, createId?: string): any {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	addOffice(): void {
		let createId: string = Common.generateId()
		let _office: IOffice = Office.createNewOffice(createId, user.user.id)
		_office.links.push(user.currentUser)
		office.addOffice(_office)

		this.$router.push({name:'office-coordinates', params:{ofi_id: createId}})
	}

	submitDeleteOffice(_office: IOffice): void {
		office.deleteOffice(_office.id)
	}
	
	submitActivateOffice(_office: IOffice): void {
		office.activateOffice(_office.id)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', this.offices.length).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}