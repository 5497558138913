import Render from '@Views/root/patients/patient/antecedent/main.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IAntecedent, IForm, ITagItem } from '@Store/types'
import { InputDateType, IToggleBoxItem } from '@Components/index'
import { DateState } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { CustomForm } from '@Components/forms/custom-form'
import { patient, tag, user } from '@Store/modules'

@Render
@Component({
	components: {
		'custom-form': CustomForm
	}
})
export class Main extends Vue {

	fromDate: Date = null
	toDate: Date = null
	preciseDate: Date = null
	endDate: Date = null
	eventYear: number = null
	state: DateState = DateState.ThereIs
	private updating: boolean = false

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop({default: true}) canDelete: boolean
	@Prop() antecedent: IAntecedent

	//#region Getters
	get birthDate(): Date {
		return patient.coordinates.birth_date
	}
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get antecedentTags(): ITagItem[] {
		return tag.antecedentTags
	}
	get thereIsLabel(): string {
		return `${EnumsHelper.dateStateToString(DateState.ThereIs)}…`
	}
	get atTheAgeLabel(): string {
		return `${EnumsHelper.dateStateToString(DateState.AtTheAge)}…`
	}
	get dateLabel(): string {
		return `${EnumsHelper.dateStateToString(DateState.Date)}…`
	}
	get atYearLabel(): string {
		return `${EnumsHelper.dateStateToString(DateState.AtYear)}…`
	}
	get isThereIs(): boolean {
		return this.state === DateState.ThereIs
	}
	get isAtTheAge(): boolean {
		return this.state === DateState.AtTheAge
	}
	get isDate(): boolean {
		return this.state === DateState.Date
	}
	get eventDateType(): InputDateType {
		return InputDateType.Numpad
	}
	get importantItems() : IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.no').toString()},
			{value: true, label: this.$i18n.t('general.yes').toString(), icon:"exclamation-triangle"}
		]
	}
	get periodictyItems(): IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('vm.root.patients.patient.antecedent.periodicity.no').toString()},
			{value: true, label: this.$i18n.t('vm.root.patients.patient.antecedent.periodicity.yes').toString()}
		]
	}
	get runningItems(): IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.no').toString()},
			{value: true, label: this.$i18n.t('general.yes').toString(), icon:"hourglass-half"}
		]
	}
	get stateItems(): IToggleBoxItem[] {
		return [
			{value: DateState.ThereIs, label: EnumsHelper.dateStateToString(DateState.ThereIs)},
			{value: DateState.AtTheAge, label: EnumsHelper.dateStateToString(DateState.AtTheAge)},
			{value: DateState.Date, label: EnumsHelper.dateStateToString(DateState.Date)},
			{value: DateState.AtYear, label: EnumsHelper.dateStateToString(DateState.AtYear)}
		]
	}
	get hasTypeError(): boolean {
		return this.submitted && !this.antecedent.type
	}
	get hasTitleError(): boolean {
		return this.submitted && !this.antecedent.title
	}
	get hasEventDateError(): boolean {
		return this.submitted && !this.antecedent.event_date
	}
	get hasEndDateError(): boolean {
		return this.submitted && !this.antecedent.running && !this.antecedent.end_date
	}
	get elementId(): string {
		return parseInt(this.antecedent.id) === -1 ? this.antecedent.create_id : this.antecedent.id
	}
	get eventDate(): Date {
		return this.preciseDate
	}
	set eventDate(date: Date) {
		date = this.getEventDate(date)
		this.antecedent.event_date = date
		this.preciseDate = date
		this.toDate = date
		this.fromDate = date
	}
	get forms(): IForm[] {
		return this.antecedent.forms
	}
	//#endregion

	created() {
		this.updateAntecedent()
	}

	submitDelete(): void {
		patient.deleteAntecedent(this.antecedent)
	}

	@Watch('antecedent')
	private updateAntecedent() {
		if (parseInt(this.antecedent.id) === -1) {
			this.state = DateState.ThereIs
			this.fromDate = null
			this.toDate = null
			this.preciseDate = null
			this.endDate = null
		} else {
			this.state = this.getState()
			this.fromDate = new Date(this.antecedent.event_date)
			this.toDate = new Date(this.antecedent.event_date)
			this.preciseDate = new Date(this.antecedent.event_date)
			this.endDate = new Date(this.antecedent.end_date)
		}
	}

	@Watch('state')
	updateState() {
		let date: Date = this.preciseDate
		if (!date) return

		this.fromDate = new Date(date)
		this.toDate = new Date(date)
		this.preciseDate = new Date(date)
		this.eventYear = date.getFullYear()
	}

	updateYear(year: number) {
		if (this.updating) return

		this.updating = true
		let date: Date = new Date()
		date.setFullYear(year)
		this.antecedent.event_date = date
		this.fromDate = date
		this.toDate = date
		this.preciseDate = date
		this.eventYear = year
		this.updating = false
	}

	updateTo(date: Date) {
		if (this.updating) return

		this.updating = true
		date = this.getEventDate(date)
		this.antecedent.event_date = date
		this.fromDate = date
		this.preciseDate = date
		this.eventYear = date.getFullYear()
		this.updating = false
	}

	updateFrom(date: Date) {
		if (this.updating) return

		this.updating = true
		date = this.getEventDate(date)
		this.antecedent.event_date = date
		this.toDate = date
		this.preciseDate = date
		this.eventYear = date.getFullYear()
		this.updating = false
	}

	updateEnd(date: Date) {
		if (this.updating) return

		this.updating = true
		this.antecedent.end_date = date
		this.updating = false
	}

	private getEventDate(date: Date): Date {
		let value: number = Math.min(date.getTime(), new Date().getTime())

		return new Date(value)
	}

	private getState(): DateState {
		let eventDate: Date = this.antecedent.event_date
		let createDate: Date = this.antecedent.date_modif
		if (eventDate.getDate() === createDate.getDate() && eventDate.getMonth() === createDate.getMonth())
			return DateState.ThereIs

		if (eventDate.getDate() === this.birthDate.getDate() && eventDate.getMonth() === this.birthDate.getMonth())
			return DateState.AtTheAge

		return DateState.Date
	}
}
