import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { patient, user } from "@Store/modules"
import { cloneDeep } from "lodash"
import { ConfigurationService } from "@Services/index"
import { AutoSaveDelay, RequestStatus } from "@Enums/index"
import { IConfiguration, IConfigForm, IDocument } from "@Store/types"

@Module({
	name: 'configuration',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class ConfigurationModule extends VuexModule {
    configuration: IConfiguration = null
    forms: IConfigForm[] = []
    documents: IDocument[] = []
    status: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusDocuments: RequestStatus = RequestStatus.None

	get isAnonymous(): boolean {
        return (user.isMainUser || user.isSubstituteUser) && !!this.configuration && this.configuration.anonymous
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}

	@Mutation
	configurationRequest() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDocuments = RequestStatus.None
		this.configuration = null
		this.forms = []
		this.documents = []
	}

	@Mutation
	configurationSuccess(configuration: IConfiguration) {
		this.status = RequestStatus.Success
		this.configuration = configuration
		this.forms = cloneDeep(configuration.forms.currents)
		this.documents = cloneDeep(configuration.documents.currents)
	}

	@Mutation
	configurationFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(configuration: IConfiguration) {
		this.statusSaving = RequestStatus.Success
		this.configuration = configuration
		this.forms = cloneDeep(configuration.forms.currents)
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.configuration = null
		this.forms = null
	}

	@Action({rawError: true})
	async loadConfiguration(): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.configurationRequest()

		let service = new ConfigurationService()
		return service.getConfiguration()
		.then(configuration => {
			configuration.auto_save = configuration.auto_save !== AutoSaveDelay.Never && configuration.auto_save < AutoSaveDelay.FiveMinutes
				? AutoSaveDelay.Never
				: configuration.auto_save

			this.configurationSuccess(configuration)
			return Promise.resolve(configuration)
		})
		.catch(error => {
			this.configurationFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveConfiguration(configuration: IConfiguration): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new ConfigurationService()
		return service.save(configuration)
		.then(newConfiguration => {
			this.saveSuccess(newConfiguration)
			this.configurationSuccess(newConfiguration)
			patient.clear()
			return Promise.resolve(newConfiguration)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}
}

export const configuration = getModule(ConfigurationModule)
// export const configuration = new ConfigurationModule({ store, name: "configuration" })
