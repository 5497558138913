<div v-if="consultation">
	<h3>{{ $t('vm.root.patients.patient.cranio.title') }}</h3>

	<read-only v-if="readOnly" :with-cranio="true" :with-tests="false" :with-forms="false" :with-examens="false" :consultation="consultation"/>
	<div v-else>
		<h4>{{ $t('vm.root.patients.patient.cranio.sub-title-1') }}</h4>
		<div class="grid-3">
			<input-frame type="number" id="rhythm-mrp-cranial" :label="$t('vm.root.patients.patient.cranio.mrp-cranial.rhythm.label')"
				v-model="cranio.rhythm_mrp_cranial" step="1" min="0" max="9" :placeholder="$t('vm.root.patients.patient.cranio.mrp-cranial.rhythm.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-cranial.rhythm.title')"/>
			<input-frame id="amplitude-mrp-cranial" :label="$t('vm.root.patients.patient.cranio.mrp-cranial.amplitude.label')"
				v-model="cranio.amplitude_mrp_cranial" :placeholder="$t('vm.root.patients.patient.cranio.mrp-cranial.amplitude.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-cranial.amplitude.title')"/>
			<input-frame id="force-mrp-cranial" :label="$t('vm.root.patients.patient.cranio.mrp-cranial.force.label')"
				v-model="cranio.force_mrp_cranial" :placeholder="$t('vm.root.patients.patient.cranio.mrp-cranial.force.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-cranial.force.title')"/>
		</div>

		<h4>{{ $t('vm.root.patients.patient.cranio.sub-title-2') }}</h4>
		<div class="grid-3">
			<input-frame type="number" id="rhythm-mrp-sacred" :label="$t('vm.root.patients.patient.cranio.mrp-sacred.rhythm.label')"
				v-model="cranio.rhythm_mrp_sacred" step="1" min="0" max="9" :placeholder="$t('vm.root.patients.patient.cranio.mrp-sacred.rhythm.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-sacred.rhythm.title')"/>
			<input-frame id="amplitude-mrp-sacred" :label="$t('vm.root.patients.patient.cranio.mrp-sacred.amplitude.label')"
				v-model="cranio.amplitude_mrp_sacred" :placeholder="$t('vm.root.patients.patient.cranio.mrp-sacred.amplitude.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-sacred.amplitude.title')"/>
			<input-frame id="force-mrp-sacred" :label="$t('vm.root.patients.patient.cranio.mrp-sacred.force.label')"
				v-model="cranio.force_mrp_sacred" :placeholder="$t('vm.root.patients.patient.cranio.mrp-sacred.force.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mrp-sacred.force.title')"/>
		</div>

		<div class="grid-3">
			<dropdown-frame id="ssb" :label="$t('vm.root.patients.patient.cranio.ssb-label')" v-model="cranio.ssb" :items="articulationMoveItems" />
			<dropdown-frame id="torsion" :label="$t('vm.root.patients.patient.cranio.torsion-label')" v-model="cranio.torsion" :items="horizontalDirectionItems" />
			<dropdown-frame id="sbr" :label="$t('vm.root.patients.patient.cranio.sbr-label')" v-model="cranio.sbr" :items="horizontalDirectionItems" />
		</div>

		<div class="grid-2" >
			<dropdown-frame :label="$t('vm.root.patients.patient.cranio.vertical-strain.label')" id="vertical-strain" v-model="cranio.vertical_strain" :items="verticalDirectionItems" />
			<dropdown-frame :label="$t('vm.root.patients.patient.cranio.vertical-strain.cause')" id="cause-vertical-strain" v-model="cranio.cause_vertical_strain" :items="strainMoveItems" />
		</div>

		<div class="grid-2">
			<dropdown-frame :label="$t('vm.root.patients.patient.cranio.side-strain.label')" id="side-strain" v-model="cranio.side_strain" :items="horizontalDirectionItems" />
			<dropdown-frame :label="$t('vm.root.patients.patient.cranio.side-strain.cause')" id="cause-side-strain" v-model="cranio.cause_side_strain" :items="strainMoveItems" />
		</div>

		<switch-frame id="compression" v-model="cranio.compression">{{ $t('vm.root.patients.patient.cranio.compression-label') }}</switch-frame>

		<div class="grid-2 twin-textarea">
			<textarea-frame id="condylar-shares" :label="$t('vm.root.patients.patient.cranio.condylar-shares.label')" v-model="cranio.condylar_shares"
				:placeholder="$t('vm.root.patients.patient.cranio.condylar-shares.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.condylar-shares.title')"/>

			<textarea-frame id="c0c1" :label="$t('vm.root.patients.patient.cranio.c0c1.label')" v-model="cranio.c0c1"
				:placeholder="$t('vm.root.patients.patient.cranio.c0c1.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.c0c1.title')"/>
		</div>

		<div class="grid-3 twin-textarea">
			<textarea-frame id="eyes" :label="$t('vm.root.patients.patient.cranio.eyes.label')" v-model="cranio.eyes"
				:placeholder="$t('vm.root.patients.patient.cranio.eyes.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.eyes.title')"/>

			<textarea-frame id="roof" :label="$t('vm.root.patients.patient.cranio.roof.label')" v-model="cranio.roof"
				:placeholder="$t('vm.root.patients.patient.cranio.roof.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.roof.title')"/>

			<textarea-frame id="mandible" :label="$t('vm.root.patients.patient.cranio.mandible.label')" v-model="cranio.mandible"
				:placeholder="$t('vm.root.patients.patient.cranio.mandible.placeholder')"
				:title="$t('vm.root.patients.patient.cranio.mandible.title')"/>
		</div>
	</div>
</div>
