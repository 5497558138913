export const tests = {
    title: "Tests d'exclusion",
    'new-test': {
        text: "Nouveau test d'exclusion",
        title: "Créer un nouveau test d'exclusion"
    },
    'detail-test': {
        title: "Voir le détail du test d'exclusion"
    },
    none: {
        title: "Pas de test d'exclusion.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer un nouveau test d'exclusion",
            link: "un nouveau test"
        }
    }
}