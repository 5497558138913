export const aside = {
    patients: {
        title: "Affichez les statistiques liées aux patients",
        label: "Patients"
    },
    consultations: {
        title: "Affichez les statistiques liées aux consultations",
        label: "Consultations"
    },
    accounting: {
        title: "Affichez les statistiques liées aux finances",
        label: "Finances"
    },
    planning: {
        title: "Affichez les statistiques liées à votre planning",
        label: "Planning"
    },
    'planning-online': {
        title: "Affichez les statistiques liées à votre planning en ligne",
        label: "Planning en ligne"
    },
    references: {
        title: "Affichez les statistiques liées aux références",
        label: "Références"
    },
    colleague: {
        title: "Affichez les statistiques liées aux praticiens",
        label: "Praticiens"
    },
    top10: {
        title: "Affichez les statistiques liées aux top 10",
        label: "Top 10"
    }
}
