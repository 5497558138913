<div v-if="!!schedule">
	<collapsible-help id="schedule-plus-information" :title="$t('vm.root.user.office.schedule.title')" tag="h2">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<i18n path="vm.root.user.office.schedule.help1.text" tag="span">
					<strong>{{ $t('vm.root.user.office.schedule.help1.strong') }}</strong>
				</i18n>
			</p>
			<i18n path="vm.root.user.office.schedule.help2.text" class="text-left" tag="p">
				<strong>{{ $t('vm.root.user.office.schedule.help2.strong') }}</strong>
			</i18n>
			<i18n path="vm.root.user.office.schedule.help3.text" class="text-left" tag="p">
				<strong>{{ $t('vm.root.user.office.schedule.help3.strong') }}</strong>
			</i18n>
			<i18n path="vm.root.user.office.schedule.help4.text" class="text-left" tag="p">
				<strong>{{ $t('vm.root.user.office.schedule.help4.strong') }}</strong>
				<router-link :to="{name: 'office-absence', params: $route.params}" :title="$t('vm.root.user.office.schedule.help4.link.title')">
					{{ $t('vm.root.user.office.schedule.help4.link.label') }}
				</router-link>
			</i18n>
		</div>
	</collapsible-help>

	<div class="childs-space-between">
		<h3 class="no-marg">{{ $t('vm.root.user.office.schedule.subtitle', {start: `${$d(schedule.start, 'weekdayMin')} ${$d(schedule.start, 'LL')}`, end: `${$d(schedule.end, 'weekdayMin')} ${$d(schedule.end, 'LL')}`}) }}</h3>
		<div>
			<button type="button" @click="duplicate" class="btn--link-like"
					:title="$t('vm.root.user.office.schedule.duplicate.title')">
				<span>{{ $t('vm.root.user.office.schedule.duplicate.label') }}</span>
			</button>
			•
			<delete-item class="btn btn--link-like text-danger" :can-delete="true" :delete-action="submitDelete"
				:title="$t('vm.root.user.office.schedule.delete.title')"
				:delete-msg="$t('vm.root.user.office.schedule.delete.delete-msg')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				<span>{{ $t('vm.root.user.office.schedule.delete.label') }}</span>
			</delete-item>
		</div>
	</div>

	<div class="grid-3">
		<div v-if="samePeriod" class="grid-item-full semi-marg-v notice-warning">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				{{ $t('vm.root.user.office.schedule.warning') }}
			</p>
		</div>

		<div class="input-frame">
			<label for="start-period" class="label">{{ $t('vm.root.user.office.schedule.start.label') }}</label>
			<div class="input-like childs-space-between">
				<button id="start-period" type="button" class="btn--link-like" @click="previous" :disabled="!canPrevious"
					:title="$t('vm.root.user.office.schedule.start.previous.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.user.office.schedule.start.previous.label') }}</span>
				</button>
				<span class="text-bold select">{{ $d(schedule.start, 'weekdayMin') }} {{ $d(schedule.start, 'LL') }}</span>
				<button id="start-period" type="button" class="btn--link-like" @click="next" :disabled="false"
					:title="$t('vm.root.user.office.schedule.start.next.title')">
					<span class="sr-only">{{ $t('vm.root.user.office.schedule.start.next.label') }}</span>
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</button>
			</div>
		</div>
		<input-frame type="number" min="1" v-model="week"
			:label="$tc('vm.root.user.office.schedule.duration.label', week)"
			:placeholder="$t('vm.root.user.office.schedule.duration.placeholder')"
			:title="$t('vm.root.user.office.schedule.duration.title')"/>
		<input-frame type="number" v-model="schedule.cycle" min="1"
			:label="$tc('vm.root.user.office.schedule.cycle.label', schedule.cycle)"
			:placeholder="$t('vm.root.user.office.schedule.cycle.placeholder')"
			:title="$t('vm.root.user.office.schedule.cycle.title')"/>

		<textarea-frame id="message" class="grid-item-full" v-model="schedule.message"
			:label="$t('vm.root.user.office.schedule.message.label')"
			:title="$t('vm.root.user.office.schedule.message.title')"
			:placeholder="$t('vm.root.user.office.schedule.message.placeholder')"/>
	</div>

	<hr>

	<schedule :schedule="schedule" :links="links"/>
</div>
