import Render from '@Views/root/patients/campaigns/campaign/campaign.html'
import { Component } from 'vue-property-decorator'
import { ASideCampaign } from './aside-campaign'
import { AutoSaver } from '@Components/index'
import { IBreadcrumbItem, ICampaign } from '@Store/types'
import { breadcrumb, campaign } from '@Store/modules'
import { CampaignStatus, CampaignChoice } from '@Enums/index'

@Render
@Component({
	components: {
		'aside-campaign': ASideCampaign
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Campaign extends AutoSaver {

	constructor() {
		super('campaign', false)
	}

	//#region Getters
	get campaign(): ICampaign {
		return campaign.campaign
	}
	get isSended(): boolean {
		return campaign.isSended()
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(false)
		this.initializeCampaign()
		this.initializeBreadcrumb()
	}

	//#region IAutoSaver implementation
	restoreValue(value: ICampaign): void {
		campaign.campaignSuccess(value)
	}

	save(): void {
		campaign.saveCampaign(this.campaign)
		.then(() => {
			super.initializeValue()

			this.$router.push({name: 'patients-campaigns'})
		})
		.catch(() => {})
	}
	//#endregion

	submitSave() {
		if (this.isSended) return;

		this.save()
	}

	private createNewCampaign(): ICampaign {
		let date: Date = new Date()
		date.setHours(12)
		date.setMinutes(0)
		date.setSeconds(0)
		date.setDate(date.getDate() + (8 - date.getDay()))

		return {
			id: '-1',
			title: '',
			list: '',
			notification: false,
			choice: CampaignChoice.List,
			message: this.$i18n.t('vm.root.patients.campaign.default-text').toString(),
			status_message: "",
			status: CampaignStatus.Pending,
			sending_date: date,
			filter: {
				ofi_id: '-1',
				last_name: '',
				first_name: '',
			},
			mode: 0,
			sender: '',
			total_sms: 0,
			estimation_count: 0,
			sms_count: 1,
		}
	}

	private initializeCampaign(): void {
		let payload = {cmp_id: this.$route.params.cmp_id}

		if (payload.cmp_id === undefined || parseInt(payload.cmp_id) === -1) {
			this.restoreValue(this.createNewCampaign())
			super.initializeValue()
		} else {
			campaign.loadCampaign(payload.cmp_id)
			.then(() => { super.initializeValue() })
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.campaign').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
