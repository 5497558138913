export const gift2 = {
    title: "Votre deuxième mois offert",
    subtitle: "Votre zone de chalandise",
    help: {
        text1: "Maintenant que votre page est bien détaillée et que vos jauges sont à 100 %, il ne vous reste plus qu'à activer votre deuxième mois offert. Notez que vous pouvez {0}, en revanche {1} et ce cadeau risque de ne pas servir au maximum de son potentiel.",
        strong1: "l'activer sans avoir suivi la première étape",
        strong2: "l'efficacité de votre page ne sera alors pas optimale",
        text2: "Pour activer ce deuxième mois offert, vous devez simplement renseigner les quelques informations ci-dessous. En effet, pour être sûr de bien cibler des patients susceptibles de venir dans votre cabinet, et parce que nous ne connaissons pas toutes les régions de France, nous préférons vous demander les villes autour de vous pour lesquelles il peut y avoir un intérêt de parler de vous. Nous aimerions également savoir, si cela est possible, les domaines d'activité que vous avez en préférence. Si vous n'avez aucune préférence, laissez ces champs vides."
    },
    valided: {
        title: "Félicitations",
        text: "Vous avez obtenu votre deuxième mois !"
    },
    cities: {
        'main-title': "Les villes autour de votre cabinet",
        'error-msg': "Vous devez indiquer au moins 3 noms de ville",
        placeholder: "ex : Mâcon, Charnay-lès-mâcon, Sancé, etc.",
        label: "rien|1ère ville autour de votre activité|{count}ème ville autour de votre activité",
        title: "rien|Indiquez la 1ère ville autour de votre activité|Indiquez la {count}ème ville autour de votre activité"
    },
    domains: {
        'main-title': "Vos domaines de prédilection",
        placeholder: "ex : Sportif, Nourrisson, etc.",
        label: "rien|1er domaine de prédilection|{count}ème domaine de prédilection",
        title: "rien|Indiquez votre 1er domaine de prédilection|Indiquez votre {count}ème domaine de prédilection"
    },
    back: {
        label: "étape 1",
        title: "Retourner à l'étape précédente"
    },
    action: {
        label: "soumettre",
        title: "Soumettre vos informations pour bénéficier d'un mois offert"
    },
    next: {
        label: "étape 3",
        title: "Aller à la prochaine étape"
    }
}