<div class="dropdown" :class="{'is-active': open}" v-click-outside="reset">

	<slot name="inner-element"/>

	<button type="button" :class="[{ 'dropdown-toggle': trigger !== 'select-like', 'is-active': open }, trigger]"
		:title="title" :id="id" :readonly="readonly" :disabled="disabled"
		@keydown="keyboardNav" @keydown.esc="reset" @click="toggleButton"
		aria-haspopup="listbox" :aria-controls="`${id}Menu`" :aria-expanded="open">
		<svg v-if="displaySelectedItem && selectedItem.icon" width="1em" :class="getTriggerClass('-icon')" viewBox="0 0 512 512" aria-hidden="true" focusable="false"
			:style="getColor(selectedItem.color)">
			<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${selectedItem.icon}`"/>
		</svg>
		<span v-if="displaySelectedItem" class="dropdown-label" :class="[getTriggerClass('-label'), selectedItem.default ? 'text-placeholder' : '']">{{selectedItem.label}}</span>
		<svg width="1em" :class="[getTriggerClass('-caret'), caret]" viewBox="0 0 448 512" aria-hidden="true">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
		</svg>
	</button>

	<div class="dropdown-menu" :class="[`is-stuck-${menuStuck}`, {'is-on-top': menuOnTop}]" :id="`${id}Menu`" :aria-labelledby="id" :aria-hidden="!open"
		@keydown="keyboardNav" @keydown.esc="reset">

		<button type="button" @click="toggleButton" class="dropdown-menu-backdrop" :title="$t('general.close.title')">
			<span class="sr-only">{{$t('general.close.text')}}</span>
		</button>

		<ul class="dropdown-menu-list" ref="list">
			<li v-for="(item, index) in items" :key="item.value" @mouseclick="selectedIndex = index">
				<div v-if="item.separator" class="dropdown-menu-hr"></div>
				<button v-else type="button" class="dropdown-menu-item" :class="{'is-selected': isSelected(item), 'is-disabled': !!item.disabled}" :disabled="!!item.disabled" :title="item.label" @click="selectValue(item)">
					<span v-if="item.icon" class="dropdown-menu-item-icon">
						<svg width="1em" class="svg-block" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :style="getColor(item.color)">
							<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${item.icon}`"/>
						</svg>
					</span>
					<span class="dropdown-menu-item-label">{{item.label}}</span>
				</button>
			</li>
		</ul>
	</div>

</div>
