<div v-if="spheres">
	<h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.spheres.title') }}</h2>

	<div class="grid-2">
		<div class="input-frame">
			<label class="label" for="filter" :data-text="$t('vm.root.patients.patient.spheres.filter.label')">
				<span>{{ $t('vm.root.patients.patient.spheres.filter.label') }}</span>
			</label>
			<div class="with-icon">
				<svg width="1em" class="with-icon-icon is-shown" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" id="filter" v-model="filter"
					:placeholder="$t('vm.root.patients.patient.spheres.filter.placeholder')"
					:title="$t('vm.root.patients.patient.spheres.filter.title')">
			</div>
		</div>
		<div class="input-frame-like">
			<button type="button" class="btn btn-primary btn--input-shape" @click="addSphere($event)"
				:title="$t('vm.root.patients.patient.spheres.new-sphere.title', {type: ``})">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.patients.patient.spheres.new-sphere.text') }}
			</button>
		</div>
	</div>

	<hr>

	<div v-for="(spheres, type) in spheresGrouped" :key="type">
		<div class="childs-space-between marg-v">
			<h3 class="text-uppercase-first-letter no-marg" :inner-html.prop="type | mark(filter)"/>
			<button type="button" class="btn--link-like is-pushed-right" @click="addSphere($event, type)"
			:title="$t('vm.root.patients.patient.spheres.new-sphere.title', {type: type})">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.patients.patient.spheres.new-sphere.text') | capitalize }}
			</button>
		</div>

		<ul class="ul-reset">
			<li v-for="sphere in spheres" :key="getElementId(sphere)">

				<sphere v-if="sphere.edit && !readOnly" :ref="getElementId(sphere)" :sphere="sphere" :read-only="readOnly" :submitted="submitted"/>

				<collapsible-panel v-else collapsible-class="marg-v">
					<template slot="title">
						<svg v-if="sphere.important" width="1em" class="svg-middle text-md" viewBox="0 0 576 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle" />
						</svg>
						<span class="text-uppercase-first-letter" :inner-html.prop="sphere.title | mark(filter)"/>
					</template>
					<div v-if="owner(sphere)" slot="subtitle" class="text-italic text-sm">
						<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
						</svg>
						{{ $t('vm.root.patients.patient.spheres.create-by', {owner: owner(sphere)}) }}
					</div>
					<span slot="right" :class="{'text-danger': sphere.important}">
						{{ $t('general.at') }} {{coordinates.birth_date | age(sphere.event_date)}},

						<em v-if="sphere.periodic && sphere.running">
							<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
							</svg> {{ $t('general.running') }}, {{sphere.event_date | since}}
						</em>
						<span v-else-if="sphere.periodic">{{sphere.event_date | timeAgo}}, ({{ $t('general.during') }} {{sphere.event_date | age(sphere.end_date)}})</span>
						<span v-else>{{sphere.event_date | timeAgo}}</span>
					</span>

					<p class="no-marg" :inner-html.prop="sphere.description | mark(filter)"/>

					<form-summary v-for="form in sphere.forms" :key="form.id" :form="form"/>

					<div class="text-right">
						<button type="button" @click="editSphere(sphere)"class="btn--link-like" :title="$t('general.edit.title')">{{ $t('general.edit.text') }}</button>
						<span v-if="!readOnly && isMainUser">&bull;
							<delete-item class="btn btn--link-like text-danger" :can-delete="true"
								:delete-action="() => deleteSphere(sphere)">
								{{ $t('general.delete.text') }}
							</delete-item>
						</span>
					</div>
				</collapsible-panel>
			</li>
		</ul>
	</div>
	<div v-if="Object.keys(spheresGrouped).length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.spheres.none.title') }}</span><br>
			<i18n path="vm.root.patients.patient.spheres.none.description.text" tag="span">
				<button type="button" class="btn--link-like" @click="addSphere($event)" :title="$t('vm.root.patients.patient.spheres.none.description.title')">{{ $t("vm.root.patients.patient.spheres.none.description.link") }}</button>
			</i18n>
		</p>
	</div>
</div>
