import Render from '@Views/components/popin/layout/popin-search-colleague.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Debouncer, Common, Colleague } from '@Models/index'
import { IColleague, IPopin, IPopinAction } from '@Store/types'
import { colleague, patient, popIn } from '@Store/modules'

@Render
@Component({})
export class PopinSearchColleague extends Vue {

	searched: boolean = false
	searching: boolean = false
	random: string = null
    resultSearch: IColleague[] = []
	query: string = ''
	private debouncer: Debouncer = null

    @Prop() options: {selectedId: string, colleague: IColleague}
    @Prop({default: false}) submitted: boolean

    mounted() {
		this.random = Common.generateId()
        this.debouncer = new Debouncer(this.search, 500)
        let input: HTMLElement = this.$refs['popin-search-colleague'] as HTMLElement
        input.focus()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	createColleague(): void {
		let addAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.colleague.search.create-popin.label').toString(),
			title: this.$i18n.t('vm.root.search.colleague.search.create-popin.title').toString(),
			callback: (data: IColleague) => {
				let validData: boolean = !!data.last_name && !!data.first_name
				if (!validData) return Promise.reject()

				patient.addColleague(data)
				return Promise.resolve()
			}
		}
		let colleague: IColleague = Colleague.createNewColleague()
		let add: IPopin = {
			title: this.$i18n.t('vm.root.search.colleague.search.create-popin.title').toString(),
			content: {component: 'popin-create-colleague'},
			action: addAction,
			options: colleague,
			isWide: true
		}

		popIn.info(add)
	}

	@Watch('options.selectedId')
	udpateSelectedColleague(): void {
		if (!this.options.selectedId) {
			this.options.colleague = null
			return
		}

		this.options.colleague = this.resultSearch.find(colleague => { return colleague.id === this.options.selectedId })
	}

    search(): void {
		this.searching = true
		this.resultSearch = []
		colleague.searchColleagues({query: this.query})
		.then(results => {
			this.searched = true
			this.searching = false
			this.resultSearch = results
		})
		.catch(error => {
			this.searched = true
			this.searching = false
		})
    }

    @Watch('query')
	createDebounce(): void {
		if (!this.query) {
			this.searched = false
			this.resultSearch = []
		}

		this.debouncer.start(!!this.query)
    }
}
