export const loader = {
    title: "Chargement des données",
    description: {
        text: "Veuillez patienter quelques instants pendant que l'application {0} charge vos données.",
        strong: "Osteo2ls"
    },
    loading: {
        configuration: "chargement de la configuration…",
        offices: "chargement des cabinets…",
        accounts: "chargement des comptes associés…",
        schedules: "chargement des horaires…",
        sessions: "chargement des types de consultation…",
        settings: "chargement des paramètres…",
        plannings: "chargement des plannings…",
        'sphere-tags': "chargement des types de sphère…",
        'antecedent-tags': "chargement des types d'antécédent…",
        faq: "chargement de la faq…",
        'change-log': "chargement du change log…",
        'check-version': "vérification de la version…",
        shop: "chargement de la boutique…",
        'plannings-google': "chargement des plannings google…",
    },
    failed: {
        retry: "Réessayer",
        cancel: "Annuler",
        message: "Le chargement de vos données à échoué."
    }
}
