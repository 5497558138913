import { BaseService } from '@Services/index'
import {IBulk} from '@Store/types/shop'

export class BulkService extends BaseService {
	public getInfos(key: string): Promise<any> {
		return super.get(`/bulk/${key}/`)
	}

	public save(bulk: IBulk): Promise<any> {
		return super.post('/bulk', bulk)
	}
}
