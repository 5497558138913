export const createPatient = {
    label: "Veuillez indiquer quelques informations sur ce nouveau patient.",
    'first-name': {
        label: "Prénom du patient",
        placeholder: "ex : Émile",
        error: "Merci de renseigner le prénom du patient",
        title:"Veuillez renseigner le prénom du patient"
    },
    'last-name': {
        label: "Nom du patient",
        placeholder: "ex : Letueur",
        error: "Merci de renseigner le nom du patient",
        title:"Veuillez renseigner le nom du patient"
    },
    'birth-date': {
        label: "Date de naissance",
        title: "Veuillez renseigner la date de naissance de votre patient",
        placeholder: "ex : Le 30 Février 2015"
    },
    phone: {
        label: "Numéro de téléphone" ,
        error: "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le numéro de téléphone du patient"
    },
    gender: {
        label: "Sexe du patient",
        title: "Veuillez renseigner le genre du patient"
    },
    email: {
        label: "Adresse email" ,
        placeholder: "ex : emile@killer.com",
        title: "Veuillez renseigner l'adresse email du patient"
    },
    address: {
        label: "Adresse du patient",
        placeholder: "ex : 1 Boulevard de la Croisette, 06400 Cannes",
        title: "Veuillez renseigner l'adresse postale du patient"
    },
}