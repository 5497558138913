<transition name="appear">
	<div class="super-panel" v-if="display()" aria-labelledby="dialogTitle" aria-describedby="dialogDescription" role="dialog">

		<section class="super-panel-frame" :class="popinClass()" @keydown.esc="close">
			<div :class="{'wrapper': !popin.isWide, 'wrapper-full': popin.isWide}">

				<h1 id="dialogTitle">{{popin.title}}</h1>

				<div id="dialogDescription">
					<component v-if="hasComponent" :is="popin.content.component" :options="options" :submitted="submitted"></component>
					<p v-else v-html="popin.content"></p>
				</div>

				<footer class="control-bar childs-space-between">
					<button-action @click.native="close" :title="$t('general.cancel.title')" :disabled="loading">
						<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
						</svg>
						{{ $t('general.cancel.text') }}
					</button-action>
					<button-action v-if="!!action" :btn-class="buttonClass()" :loading="loading" :disabled="!canCallAction()"
								   :title="action.title" @click.native="callAction">
						{{action.label}}
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
						</svg>
					</button-action>
				</footer>

			</div>

		</section>

	</div>
</transition>
