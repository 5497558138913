<div>
		<a href="https://codepen.io/Lewitje/pen/MVommB">https://codepen.io/Lewitje/pen/MVommB</a>

	<div class="text-xl">
		<avatar first-name="Antoine" last-name="Mentré" email="piolet91@gmail.com"/>
		<avatar first-name="Antoine" last-name="Mentré" email="piolet@piggums.fr"/>
		<avatar first-name="Matthieu" last-name="Bué" email="matthieu.bue@gmail.com"/>
		<avatar first-name="Clark" last-name="Kent" email="superm@n.com"/>
		<avatar first-name="Kung" last-name="Fury" email="kung@fury.com"/>
		<avatar first-name="Serge" last-name="Karamazov" email=""/>
		<avatar first-name="Hubert" last-name="Bonisseur de la Bath"/>
		<avatar first-name="Hubert" last-name=""/>
		<avatar first-name="Hubert" />
		<avatar last-name="Hubert" first-name=""/>
		<avatar last-name="Hubert" />
		<avatar/>
	</div>
	<hr>
	<p class="text-xl">
		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#ban"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-alt-up"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 192 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#angle-right"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-from-bottom"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-bottom"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-left"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-right"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 192 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#caret-right"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#expand"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#expand-arrows"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 320 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#birthday-cake"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calculator"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 480 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calculator-alt"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#bars"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-check"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-day"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-plus"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-times"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#chair-office"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 544 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#chart-pie"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#clipboard-list-check"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#cloud-download"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#cog"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#coins"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#comment-alt-lines"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#copy"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#credit-card"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 496 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#dizzy"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#envelope"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle-strong"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#eye"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#eye-slash"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#file-exclamation"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#file-invoice"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#file-plus"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#file-times"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#filter"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#folder-open"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 496 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 488 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#google"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#graduation-cap"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 320 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#image"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#inbox-in"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#key"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#link"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#magic"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#map-marker-alt"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#mars"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 496 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#meh"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#moneo"/>
		</svg>

		<svg  width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#money-check"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#money-bill-wave"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#parking"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#paint-roller"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 320 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#phone-type-1"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#phone-type-2"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#piggy-bank"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#power-off"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#print"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 384 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-square"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#rabbit"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 496 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#radiation-alt"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#retweet"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#rss"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#share"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#share-square"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 496 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#smile-beam"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#stars"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#sticky-note"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#sync"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#thumbs-up"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#tree-palm"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#trophy"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 576 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#trophy-alt"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 512 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#university"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 448 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 640 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#users-class"/>
		</svg>

		<svg width="1em" class="svg-middle" viewBox="0 0 288 512">
			<use xlink:href="@Assets/svg/symbol-defs.svg#venus"/>
		</svg>
	</p>
	<hr>
	<div class="input-frame">
		<label class="label" for="color" data-text="Choix de la couleur">
			<span>Choix de la couleur</span>
		</label>
		<div class="form-group">
			<checkbox-frame id="with-color" tag="label" default-class="input-like form-group-input is-flex-fixed"
				v-model="with_color"
				title="Indiquez si la couleur est prise en compte"/>
			<input-color class="form-group-input" v-model="color" title="Sélectionnez la couleur désirée" :disabled="!with_color"/>
		</div>
	</div>
	<hr>
	<dropdown-editable-frame id="profession3" v-model="profession" :multiple="true" :taggable="true" :searcher="search"
		label="Indiquer la profession"
		placeholder="ex : Ministre"
		title="Veuillez indiquer la ou les professions"/>

	<div class="input-frame">
		<label class="label" for="profession2" data-text="Indiquer la profession">Indiquer la profession</label>

		<dropdown-editable id="profession2" v-model="profession" :multiple="true" :taggable="true"
			placeholder="ex : Ministre"
			:searcher="search"
			title="Veuillez indiquer la ou les professions"/>
	</div>
	<br>
	profession : {{profession}}
	<hr>

	Un champ tout seul<br>
	{{phone}}
	<input-phone-frame label="Numéro de téléphone seul" id="plopy" :phone="phone"
		:error-test="hasPhoneError(phone)"
		error-msg="Merci de renseigner un numéro de téléphone valide"
		title="Veuillez renseigner le numéro de téléphone du patient"/>

	<hr>
	items : {{testItems}}<br>
	value : {{test.test}}<br>
	toogle value : {{toogleboxValue}}
	<div class="grid-2">
		<dropdown-frame id="test2" v-model="test.test" :items="testItems" label="Test 2" error-msg="ouh pas bien" :error-test="hasErrorTest"/>
		<div class="input-frame">
			<label for="test" data-text="Test" class="label">
				<span>Test</span>
			</label>
			<dropdown id="test" v-model="test.test" :items="testItems" />
		</div>

		<toggledate-frame id="toggledate" label="choix d'une date"
			placeholder="ex : 6 mois" title="Veuillez renseigner une date" :date='myDate' @input="updateMyDate"/>
		{{myDate2}}

		<togglebox-frame id="togglebox" label="toggle box test" :items="testToggleBoxItems" v-model="toogleboxValue"/>

		<div class="input-frame">
			<label for="test3" data-text="Test" class="label">
				<span>Test</span>
			</label>
			<div class="togglebox">
				<input type="radio" name="popol" id="test3">
				<label for="test3" class="togglebox-item" title="oui">
					<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
					</svg>oui
				</label>
				<input type="radio" name="popol" id="test33">
				<label for="test33" class="togglebox-item" title="non">non</label>
			</div>
		</div>


	</div>

	{{yesNo}}
	<checkbox-frame id="coucou" v-model="yesNo">Lorem ipsum dolor sit amet</checkbox-frame>

	{{radioTest}}
	<radio-frame id="testBleu" title="bleu" name="color" radio-value="bleu" v-model="radioTest">bleu</radio-frame>
	<radio-frame id="testRaouge" title="rouge" name="color" radio-value="rouge" v-model="radioTest">rouge</radio-frame>

	<hr>

	<switch-frame id="coucou23" v-model="yesNo">Lorem ipsum dolor sit amet</switch-frame>

	<div class="input-frame">
		<div class="switchbox">
			<input type="checkbox" id="yoyo" title="TODO">
			<label for="yoyo">
				<svg width="1em" class="switch-yes" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				</svg>
				<svg width="1em" class="switch-no" viewBox="0 0 320 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
				</svg>
				<span class="sr-only">TODO</span>
			</label>
		</div>
		<label for="yoyo">Lorem ipsum dolor sit amet</label>
	</div>

	<div class="input-frame">
		<div class="checkbox">
			<input type="checkbox" id="plop" title="TODO">
			<label for="plop">
				<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
				<span class="sr-only">TODO</span>
			</label>
		</div>
		<label for="plop">Lorem ipsum dolor sit amet</label>
	</div>

	<div class="input-frame">
		<div class="radio">
			<input type="radio" id="plopi" name="plopo" title="TODO">
			<label for="plopi">
				<span class="sr-only">TODO</span>
			</label>
		</div>
		<label for="plopi">Lorem ipsum dolor sit amet</label>
	</div>

	<div class="input-frame">
		<div class="radio">
			<input type="radio" id="plopu" name="plopo" title="TODO">
			<label for="plopu">
				<span class="sr-only">TODO</span>
			</label>
		</div>
		<label for="plopu">Lorem ipsum dolor sit amet</label>
	</div>

	<hr>
	<!-- {{editorData}} -->
	<input-document v-model="editorData"/>
</div>
