import Render from '@Views/root/shop/purchase/main.html'
import config from "@Config/index"
import { Component, Watch } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { ASideMain } from './aside-main'
import { AutoSaver, IToggleBoxItem, IDropdownItem } from '@Components/index'
import { Address } from '@Models/index'
import { AutoSaverState, PurchaseState, PackType, PurchaseMode } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { IPopinAction, IPopin, IBreadcrumbItem, INotification, IPurchase, IPackValue, IPack } from '@Store/types'
import { breadcrumb, notif, purchase, shop, user } from '@Store/modules'
import {Paying} from "@ViewModels/root/shop/paying"

@Render
@Component({
	components: {
		'paying': Paying,
		'aside-main': ASideMain,
		'context-main': ContextMain
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	code: string = ""
	payed: boolean = false
	newPrice: number = -1
	displayCode: boolean = false
	private submitted: boolean = false
	private static allPackUnauthorized: number[] = [PackType.Subscription, PackType.Support, PackType.Bulk1, PackType.Bulk2, PackType.Planning,PackType.Unlimited]

	constructor() {
		super('purchase', false)
	}

	//#region Getter
	get purchase(): IPurchase {
		return purchase.purchase
	}
	get isLoading(): boolean {
		return purchase.isLoading
	}
	get isSaving(): boolean {
		return purchase.isSaving
	}
	get isDeleting(): boolean {
		return purchase.isDeleting()
	}
	get isUnsubscribing(): boolean {
		return purchase.isUnsubscribing()
	}
	get isApplying(): boolean {
		return purchase.isApplying
	}
	get isCompleted(): boolean {
		return purchase.isCompleted(this.purchase.state)
	}
	get hasAddressError(): boolean {
		return this.submitted && (!this.purchase.address || !this.purchase.address.formatted)
	}
	get paymentItems(): IToggleBoxItem[] {
		return [
			{value: PurchaseMode.Paypal, label: EnumsHelper.purchaseModeToString(PurchaseMode.Paypal), icon:"credit-card"},
			{value: PurchaseMode.Transfer, label: EnumsHelper.purchaseModeToString(PurchaseMode.Transfer), icon: 'share-square'},
			{value: PurchaseMode.Check, label: EnumsHelper.purchaseModeToString(PurchaseMode.Check), icon: 'money-check'}
		]
	}
	get valueItems(): IDropdownItem[] {
		return this.values.map(v => { return {value: v.value, label: v.name} })
	}
	get modelItems(): IDropdownItem[] {
		return [
			{value: 1, label: this.$i18n.t('vm.root.shop.purchase.main.card.model1').toString()},
			{value: 2, label: this.$i18n.t('vm.root.shop.purchase.main.card.model2').toString()}
		]
	}
	get price(): number {
		if (!this.values || this.values.length === 0) return 0

		let value: IPackValue = this.values.find(v => { return v.value === this.purchase.value})
		return !!value ? value.price : 0
	}
	get hasValues(): boolean {
		return !!this.values && this.values.length > 1
	}
	get isSupport(): boolean {
		return this.type === PackType.Support
	}
	get isUnlimited(): boolean {
		return this.type === PackType.Unlimited
	}
	get isSubscription(): boolean {
		return this.type === PackType.Subscription
	}
	get isPlanning(): boolean {
		return this.type === PackType.Planning
	}
	get isCard(): boolean {
		return this.type === PackType.Card
	}
	get isSms(): boolean {
		return this.type === PackType.Sms
	}
	get isImport(): boolean {
		return this.type === PackType.Import
	}
	get isSite(): boolean {
		return this.type === PackType.Site
	}
	get isRecurrent(): boolean {
		return this.pack.recurrent
	}
	get constants(): any {
		return shop.shop.constants
	}
	get iban(): string[] {
		return this.constants.IBAN.split(" ")
	}
	get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	get type(): PackType {
		return !!this.$route.params.pack ? parseInt(this.$route.params.pack) as PackType : (!!this.purchase ? this.purchase.type : PackType.Unlimited)
	}
	get canUnsubscribe(): boolean {
		return this.isRecurrent && (!!user.currentUser.license_date || !!user.currentUser.planning_date)
	}
	private get values(): IPackValue[] {
		return this.pack.values
	}
	private get pack(): IPack {
		return purchase.pack(this.type)
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IPurchase): void {
		purchase.purchaseSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		let isNew: boolean = parseInt(this.purchase.id) === -1

		let p: IPurchase = {...this.purchase}
		p.code = this.code

		purchase.savePurchase(p)
		.then((newPurchase: IPurchase) => {
			super.initializeValue()
			this.payed = true
			this.submitted = false

			if (p.mode === PurchaseMode.Paypal) {
				let url: string = `${config.api_url}/paypal/send/${newPurchase.id}`
				window.location.href = url
				return
			}
			if (!isNew) return

			let route = {name: 'purchase', params: {pur_id: newPurchase.id }}
			this.$router.replace(route)
		})
		.catch(() => {
			super.initializeValue()
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		this.canAccessOnPack();
		this.initializePurchase()
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	onDelete() {
		// Appelé quand on efface le rdv depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	@Watch('code')
	updateCode() {
		this.newPrice = -1
	}

	submitCode() {
		purchase.applyCode({code: this.code, purchase: this.purchase})
		.then(result => {
			this.newPrice = result.new_price
			if (result.new_price > 0) return

			let message: string = this.$i18n.t('vm.root.shop.purchase.main.unvalid-code').toString()
			let notification: INotification = { message: message, actions: [], delay: 5000, canClose: true }
			notif.error(notification)
		})
		.catch(error => { console.error(error) })
	}

	submitPay() {
		this.submitted = true
		this.save()
	}

	submitCancel() {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.shop.purchase.main.cancel.label').toString(),
			title: this.$i18n.t('vm.root.shop.purchase.main.cancel.title').toString(),
			callback: () => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.shop.purchase.main.cancel.title').toString(),
			content: this.$i18n.t('vm.root.shop.purchase.main.cancel.content').toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	submitUnsubscribe(): void {
		let route = {name: 'shop'}

		if (!!this.purchase && !!this.purchase.id && parseInt(this.purchase.id) !== -1) {
			purchase.unsubscribePurchase(this.purchase.id).then(() => {
				this.$router.push(route)
			})
		} else {
		  this.$router.push(route)
		}
	}

	cancelAction(): void {
		super.cancel()
		this.$router.push({name: 'shop'})
	}

	private canSave(): boolean {
		if (this.isDemo) {
			this.cantPayOnDemo()
			return false
		}

		return !!this.purchase.address && !!this.purchase.address.formatted
	}

	private cantPayOnDemo(): void {
		let message: string = this.$i18n.t('vm.root.shop.purchase.main.cant-payed-on-demo').toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
		notif.info(notification)
	}

	private createNewPurchase(packType: PackType): IPurchase {
		return {
			id: '-1',
			value: this.values[0].value,
			type: packType,
			mode: PurchaseMode.Paypal,
			model: 1,
			code: '',
			profile_id: '-1',
			state: PurchaseState.Running,
			address: Address.createDefaultAddress(),
			billings: []
		}
	}

	private initializePurchase(): void {
		this.payed = false
		let pur_id: string = this.$route.params.pur_id
		if (pur_id === undefined) {
			this.restoreValue(this.createNewPurchase(!!this.$route.params.pack ? parseInt(this.$route.params.pack) as PackType : PackType.Unlimited))
			this.initializeValue()
		} else {
			purchase.loadPurchase(pur_id)
			.then(() => {
				this.initializeValue()
				if (!this.canSave()) return
				if (this.isCompleted) return
				this.state = AutoSaverState.Updated
			})
		}
	}

	initializeValue() {
		super.initializeValue()
		this.initializeReferralCode()
	}

	private initializeReferralCode() {
		if (!user.currentUser.godchild_code) return
		if (!this.isUnlimited && !this.isSubscription) return
		if (this.purchase.state === PurchaseState.Completed) return

		this.code = user.currentUser.godchild_code
		this.displayCode = !!this.code && this.code.trim() !== ''
		this.submitCode()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.shop').toString(), link: "shop" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.purchase').toString() }
		breadcrumb.updateItems([item1, item2])
	}

	// Pour ne pas afficher des pack non autoriser
	private canAccessOnPack(){	
			if (!this.$route.path.startsWith('/purchase/pack/')) return
				const packId = parseInt(this.$route.params.pack);
				if (!Main.allPackUnauthorized.find(id => id === packId)) return
					this.$router.replace('/shop');	
	}
}
