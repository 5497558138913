<div class="input-frame">
    <label class="label" for="search-colleague" :data-text="$t('vm.root.search.colleague.search.label')">{{ $t('vm.root.search.colleague.search.label') }}</label>

    <div class="form-group">
        <div class="with-icon">
            <svg width="1em" class="with-icon-icon" :class="{'is-shown': !false}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
            </svg>
            <span class="with-icon-icon" :class="{'is-shown': false}">
                <spinner class="text-primary"/>
            </span>
            <input class="form-group-input" id="search-colleague" @click="searchColleague"
                :title="$t('vm.root.search.colleague.search.title')"
                :placeholder="$t('vm.root.search.colleague.search.placeholder')">
        </div>
        <button type="button" class="input-like form-group-input is-flex-fixed" @click="createColleague"
            :title="$t('vm.root.search.colleague.search.create.title')">
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
            </svg>
            <span class="rwd-over-xlarge">{{ $t('vm.root.search.colleague.search.create.text') }}</span>
        </button>
    </div>
</div>