import { isEqual, cloneDeep } from 'lodash'
import { Common } from './common'
import { Phone } from './phone'
import { Address } from './address'
import { i18n } from '@I18n/index'
import { router } from '@Router/index'
import { IUser, INotification, INotificationAction, IOffice, ISchedule, ISession, ISettings, IAccess, IScheduleUser } from '@Store/types'
import { Currency, PaymentMode, PlanningType, AccessType, PhoneType, Timezone } from '@Enums/index'
import { IBusinessHour, IFullCalendarEvent, IPhone } from '@Components/index'
import { DateHelper } from '@Helpers/index'
import {Main} from "@ViewModels/root/user/office/access";

export class Office {

	public static maxAccessLength: number = 250

	public static getState(office: IOffice, settings: ISettings, schedule: ISchedule, user: IUser): number {
			if (!office || !office.coordinates) return 0

			let count: number = 0
			let result: number = 0

			count++
			result += Office.checkUrl(office, settings, user) ? 1 : 0
			count++
			result += Office.checkAddress(office) ? 1 : 0
			count++
			result += Office.checkPhones(office) ? 1 : 0
			count++
			result += Office.checkActivate(settings) ? 1 : 0
			count++
			result += Office.checkDefaultRejection(settings) ? 1 : 0
			count++
			result += Office.checkSchedule(schedule) ? 1 : 0
			count++
			result += Office.checkAccesses(office) ? 1 : 0
			count++
			result += Office.checkMessage(settings) ? 1 : 0

			return Math.round(count === 0 ? 100 : (result * 100 / count))
	}

	public static getBusinessHours(schedule: ISchedule, settings: ISettings, start: Date, getDate:(start:Date, index: number) => Date): IBusinessHour[] | false {
		if (!schedule || parseInt(schedule.id) === -1) return Office.getHolidays(settings, start, getDate)

		let businessHours: any[] = []
		for (let day of schedule.days) {
			if (!day.opened_afternoon && !day.opened_morning) continue

			let date: Date = getDate(start, day.index)
			if (!date) continue

			if (day.opened_morning && !!day.morning) {
				let start = new Date(day.morning[0])
				start.setDate(date.getDate())
				start.setMonth(date.getMonth())
				start.setFullYear(date.getFullYear())

				let end = new Date(day.morning[1])
				end.setDate(date.getDate())
				end.setMonth(date.getMonth())
				end.setFullYear(date.getFullYear())

				let dayBusinessHours: any = Office.getBusinessHour(start, end, settings, day.index)
				businessHours.push(dayBusinessHours)
			}
			if (day.opened_afternoon && !!day.afternoon) {
				let start = new Date(day.afternoon[0])
				start.setDate(date.getDate())
				start.setMonth(date.getMonth())
				start.setFullYear(date.getFullYear())

				let end = new Date(day.afternoon[1])
				end.setDate(date.getDate())
				end.setMonth(date.getMonth())
				end.setFullYear(date.getFullYear())

				let dayBusinessHours: any = Office.getBusinessHour(start, end, settings, day.index)
				businessHours.push(dayBusinessHours)
			}
		}

		return businessHours.length === 0 ? false : businessHours
	}

	private static getHolidays(settings: ISettings, start: Date, getDate:(start:Date, index: number) => Date): IBusinessHour[] | false {
		if (!settings || !settings.absence || !settings.absence_period[1] || !settings.absence_period[0]) return false

		let result: IBusinessHour[] = []
		let startHolidays = new Date(settings.absence_period[0])
		let endHolidays = new Date(settings.absence_period[1])
		for(let index: number = 0; index < 7; index++) {
			let date: Date = getDate(start, index)
			if (!date) continue

			date.setSeconds(0)
			date.setMinutes(0)
			date.setHours(0)
			let restricted: boolean = false

			if (DateHelper.sameDay(date, startHolidays)) {
				let businessHour: IBusinessHour = {
					daysOfWeek: [index],
					startTime: '00:00',
					endTime: DateHelper.getHourString(startHolidays),
				}
				restricted = true
				result.push(businessHour)
			}

			if (DateHelper.sameDay(date, endHolidays)) {
				let businessHour: IBusinessHour = {
					daysOfWeek: [index],
					startTime: DateHelper.getHourString(endHolidays),
					endTime: '24:00',
				}
				restricted = true
				result.push(businessHour)
			}

			if (restricted || (date > startHolidays && date < endHolidays)) continue

			let businessHour: IBusinessHour = {
				daysOfWeek: [index],
				startTime: '00:00',
				endTime: '24:00',
			}
			result.push(businessHour)
		}

		return result.length === 0 ? false : result
	}

	public static getScheduleUsers(schedule: ISchedule, links: any[], usr_id: string, userIds: IScheduleUser[] = []): IScheduleUser[] {
		if (!schedule) return userIds
        if (!links || links.length === 0) return userIds

        let colors: string[] = ['salmon', 'pink', 'red', 'gold', 'lavender', 'white', 'orange']
        schedule.days.forEach(day => {
            if (day.user_morning !== usr_id && !userIds.find(u => { return u.id === day.user_morning})) {
                let linkMorning: any = links.find(link => { return Office.isScheduleUser(link, usr_id, day.user_morning) })
                if (!!linkMorning) {
                    userIds.push({
                        id: linkMorning.id,
                        name: `${linkMorning.coordinates.first_name} ${linkMorning.coordinates.last_name}`.trim(),
                        color: colors[userIds.length % colors.length]
                    })
                }
            }

            if (day.user_afternoon !== usr_id && !userIds.find(u => { return u.id === day.user_afternoon})) {
                let linkAfternoon: any = links.find(link => { return Office.isScheduleUser(link, usr_id, day.user_afternoon) })
                if (!!linkAfternoon) {
                    userIds.push({
                        id: linkAfternoon.id,
                        name: `${linkAfternoon.coordinates.first_name} ${linkAfternoon.coordinates.last_name}`.trim(),
                        color: colors[userIds.length % colors.length]
                    })
                }
            }
        })
        return userIds
	}

	public static getScheduleEventList(schedule: ISchedule, links: any[], usrId: string, start: Date, getDate:(start:Date, index: number) => Date): IFullCalendarEvent[] {

		if (!schedule || parseInt(schedule.id) === -1) return []

		let scheduleEvents: IFullCalendarEvent[] = []
		for (let day of schedule.days) {
			let date: Date = getDate(start, day.index)
			if (!date) continue

			if (day.opened_morning && day.user_morning !== usrId) {
				let start = new Date(day.morning[0])
				start.setDate(date.getDate())
				start.setMonth(date.getMonth())
				start.setFullYear(date.getFullYear())

				let end = new Date(day.morning[1])
				end.setDate(date.getDate())
				end.setMonth(date.getMonth())
				end.setFullYear(date.getFullYear())

				let event: IFullCalendarEvent = Office.getSubstituteEvent(start, end, day.user_morning, links, usrId)
				if (!!event) scheduleEvents.push(event)
			}
			if (day.opened_afternoon && day.user_afternoon !== usrId) {
				let start = new Date(day.afternoon[0])
				start.setDate(date.getDate())
				start.setMonth(date.getMonth())
				start.setFullYear(date.getFullYear())

				let end = new Date(day.afternoon[1])
				end.setDate(date.getDate())
				end.setMonth(date.getMonth())
				end.setFullYear(date.getFullYear())

				let event: IFullCalendarEvent = Office.getSubstituteEvent(start, end, day.user_afternoon, links, usrId)
				if (!!event) scheduleEvents.push(event)
			}
		}
		return scheduleEvents
	}

	private static getSubstituteEvent(start: Date, end: Date, substituteId: string, links: any[], usrId: string): IFullCalendarEvent {
		let user: any = links.find(u => { return Office.isScheduleUser(u, usrId, substituteId) })
		if (!user) return

		let title: string = `${user.coordinates.first_name} ${user.coordinates.last_name}`.trim()
		let event: IFullCalendarEvent = {
			groupId: 'schedule',
			start: start,
			end: end,
			rendering: 'background',
			title: title,
			type: PlanningType.Classic,
			//On récupère cette variable dans le css, pour afficher le titre dans la cellule, car pas disponible en version 4
			color: `;--title:'${title}'`,
			className: 'substitute'
		}
		return event
	}

	private static isScheduleUser(user: any, usrId: string, substituteId: string): boolean {
		return user.id === substituteId && (!user.activate || (user.activate.length === 0 || !!user.activate.find(id => id === usrId)))
	}

	private static getBusinessHour(start: Date, end: Date, settings: ISettings, index: number): IBusinessHour {
		let dayBusinessHours: IBusinessHour = {
			daysOfWeek: [index],
			startTime: DateHelper.getHourString(start),
			endTime: DateHelper.getHourString(end),
		}

		if (!settings || !settings.absence || end <= settings.absence_period[0] || start >= settings.absence_period[1]) return dayBusinessHours
		if (!!settings && start >= settings.absence_period[0] && end <= settings.absence_period[1]) {
			start.setSeconds(0)
			start.setMinutes(0)
			start.setHours(0)
			end.setSeconds(0)
			end.setMinutes(0)
			end.setHours(0)

			dayBusinessHours.startTime = DateHelper.getHourString(start)
			dayBusinessHours.endTime = DateHelper.getHourString(end)
			return dayBusinessHours
		}

		if (!!settings && start < settings.absence_period[0] && end > settings.absence_period[0]) {
			dayBusinessHours.endTime = DateHelper.getHourString(settings.absence_period[0])
		}
		if (!!settings && end > settings.absence_period[1] && start < settings.absence_period[1]) {
			dayBusinessHours.startTime = DateHelper.getHourString(settings.absence_period[1])
		}

		return dayBusinessHours
	}

	public static createNewOffice(createId: string, userId: string): IOffice {
		let defaultSession: ISession = Office.createNewSession(undefined, createId)
		defaultSession.name = i18n.t("models.office.default-session.name").toString()
		defaultSession.description = i18n.t("models.office.default-session.description").toString()
		return {
			id: '-1',
			create_id: createId,
			usr_id: userId,
			creator_usr_id: userId,
			coordinates: {
				name: '',
				timezone: Timezone.UtcPlus1,
				currency: Currency.Euro,
				mode: PaymentMode.Check,
				longitude: -1,
				latitude: -1,
				address: Address.createDefaultAddress(),
				phone1: Phone.createDefaultPhone(),
				phone2: Phone.createDefaultPhone(PhoneType.Fix)
			},
			links: [],
			accesses: {currents: [], deleted: []},
			sessions: {currents: [defaultSession], deleted: []},
			schedules: {currents: [Office.createNewSchedule(Common.generateId(), userId)], deleted: []},
			specific_schedules: {currents: [], deleted: []},
			settings: {currents: [Office.createNewSettings(userId)], deleted: []},
		}
	}

	public static createNewSchedule(createId: string, userId: string, ofiId: string = '-1'): ISchedule {
		return {
			id: '-1',
			create_id: createId,
			usr_id: userId,
			ofi_id: ofiId,
			cycle: 1,
			days: [
				{
					index: 1, // Lundi
					opened_morning: true,
					opened_afternoon: true,
					morning: [new Date(2000, 1, 1, 8, 30, 0, 0), new Date(2000, 1, 1, 12, 0, 0, 0)],
					afternoon: [new Date(2000, 1, 1, 13, 30, 0, 0), new Date(2000, 1, 1, 19, 0, 0, 0)],
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 2,
					opened_morning: true,
					opened_afternoon: true,
					morning: [new Date(2000, 1, 1, 8, 30, 0, 0), new Date(2000, 1, 1, 12, 0, 0, 0)],
					afternoon: [new Date(2000, 1, 1, 13, 30, 0, 0), new Date(2000, 1, 1, 19, 0, 0, 0)],
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 3,
					opened_morning: true,
					opened_afternoon: true,
					morning: [new Date(2000, 1, 1, 8, 30, 0, 0), new Date(2000, 1, 1, 12, 0, 0, 0)],
					afternoon: [new Date(2000, 1, 1, 13, 30, 0, 0), new Date(2000, 1, 1, 19, 0, 0, 0)],
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 4,
					opened_morning: true,
					opened_afternoon: true,
					morning: [new Date(2000, 1, 1, 8, 30, 0, 0), new Date(2000, 1, 1, 12, 0, 0, 0)],
					afternoon: [new Date(2000, 1, 1, 13, 30, 0, 0), new Date(2000, 1, 1, 19, 0, 0, 0)],
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 5,
					opened_morning: true,
					opened_afternoon: true,
					morning: [new Date(2000, 1, 1, 8, 30, 0, 0), new Date(2000, 1, 1, 12, 0, 0, 0)],
					afternoon: [new Date(2000, 1, 1, 13, 30, 0, 0), new Date(2000, 1, 1, 19, 0, 0, 0)],
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 6,
					opened_morning: false,
					opened_afternoon: false,
					user_morning: userId,
					user_afternoon: userId
				},
				{
					index: 0, // Dimanche
					opened_morning: false,
					opened_afternoon: false,
					user_morning: userId,
					user_afternoon: userId
				}
			]
		}
	}

	public static createNewSettings(userId: string, ofiId: string = '-1'): ISettings {
		return {
			id: '-1',
			ofi_id: ofiId,
			usr_id: userId,
			pla_id: '-1',
			default_rejection: '',
			message: '',
			url: '',
			activate: false,
			absence_message: '',
			absence_period: [null, null],
			absence: false,
			max_week: 5,
			min_hour: 0,
		}
	}

	public static createNewAccess(createId: string): IAccess {
		return {
			id: '-1',
			create_id: createId,
			is_new: true,
			name: '',
			description: '',
			order: 999,
			line: undefined,
			type: AccessType.Other
		}
	}

	public static createNewSession(createId: string, ofiId: string): ISession {
		return {
			id: '-1',
			create_id: createId,
			is_new: true,
			ofi_id: ofiId,
			visible: true,
			color: '',
			with_color: false,
			duration: 45,
			ttc: 50,
			name: '',
			description: '',
			order: 999
		}
	}

	public static getOffice(office: IOffice, oldOffice: IOffice): IOffice {
		let newOffice = cloneDeep(office)
		newOffice.coordinates['update'] = !isEqual(newOffice.coordinates, oldOffice.coordinates)
		Common.updateItemList(newOffice.accesses.currents, oldOffice.accesses.currents)
		Common.updateItemList(newOffice.sessions.currents, oldOffice.sessions.currents)
		Common.updateItemList(newOffice.settings.currents, oldOffice.settings.currents)

		return newOffice
	}

	public static getUpdateList(office:IOffice): string[] {
		let result: string[] = []
		if (!!office.create_id) {
			result.push(i18n.t("models.office.coordinates-created").toString())
		} else {
			if (office.coordinates['update']) {
				result.push(i18n.t("models.office.coordinates-updated").toString())
			}
		}

		if (office.schedules.currents.length > 0) {
			result.push(i18n.t("models.office.schedule-updated").toString())
		}

		Common.addMessagesNotification(result, Office.getNotificationList(office, 'sessions', "session", true, (item: ISession) => { return !!item.create_id}))
		Common.addMessagesNotification(result, Office.getNotificationList(office, 'accesses', "access"))

		if (!!office.settings.currents && office.settings.currents.length > 0 && office.settings.currents[0]['update']) {
			result.push(i18n.t("models.office.settings-updated").toString())
		}

		return result
	}

	public static getNotification(office:IOffice): INotification {
		let message: string = i18n.t("models.office.office-saved").toString()
		let messages: string[] = Office.getUpdateList(office)
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }

		return notification
	}

	public static getErrorNotification(office:IOffice): INotification | false {
		let messages: string[] = []
		let action: INotificationAction = undefined
		if (Office.hasErrorCoordinates(office)) {
			messages.push(i18n.t("models.office.coordinates-incomplete").toString())
			action = {label: i18n.t("models.office.complete").toString(), callback: () => router.push({name: 'office-coordinates', params: {ofi_id: office.id}})}
		}
		let sessionCount: number = Office.hasErrorSessions(office)
		if (sessionCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('office', 'incomplete', 'session', sessionCount, false))
			if (!action)
				action = {label: i18n.t("models.office.complete").toString(), callback: () => router.push({name: 'office-sessions', params: {ofi_id: office.id}})}
		}
		let accessCount: number = Office.hasErrorAccesses(office)
		if (accessCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('office', 'incomplete', 'access', accessCount, false))
			if (!action)
				action = {label: i18n.t("models.office.complete").toString(), callback: () => router.push({name: 'office-accesses', params: {ofi_id: office.id}})}
		}

		if (messages.length === 0) return false

		let message: string = i18n.t("models.office.office-incomplete").toString()
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		let actions: INotificationAction[] = !!action ? [action] : []
		let notification: INotification = { message: message, delay: false, actions: actions, canClose: true }
		return notification
	}

	private static hasErrorCoordinates(office: IOffice): boolean {
		return !office.coordinates.name
	}

	private static hasErrorSessions(office: IOffice): number {
		let count: number = 0
		for(let session of office.sessions.currents) {
			if (!session.name || !session.name.trim() || session.duration < 0 || session.ttc < 0) {
				session.error = true
				count++
			} else if (session.error !== undefined) {
				session.error = false
			}
		}

		return count
	}

	private static hasAccessError(access: IAccess): boolean {
		return !access.name || !access.name.trim() || (!!access.description && Office.maxAccessLength < access.description.trim().length)
	}

	private static hasErrorAccesses(office: IOffice): number {
		let count: number = 0
		for(let access of office.accesses.currents) {
			if (Office.hasAccessError(access)) {
				access.error = true
				count++
			} else if (access.error !== undefined) {
				access.error = false
			}
		}

		return count
	}

	private static getNotificationList(office:IOffice, list: string, label: string, withNew: boolean = true, callback: any = undefined): string[] {
		let countUpdate: number = 0
		let countCreate: number = 0
		let countDeleted: number = office[list].deleted.filter(item => parseInt(item.id) !== -1).length

		for(let item of office[list].currents) {
			if (withNew && (!callback ? parseInt(item.id) === -1 : callback(item))) countCreate++
			else if (item['update']) countUpdate++
		}

		let result: string[] = []
		Common.addMessageNotification(result, Common.getMessageNotification('office', 'updated', label, countUpdate, false))
		Common.addMessageNotification(result, Common.getMessageNotification('office', 'added', label, countCreate, false))
		Common.addMessageNotification(result, Common.getMessageNotification('office', 'deleted', label, countDeleted, false))

		return result
	}

	public static checkUrl(office: IOffice, settings: ISettings, user: IUser): boolean {
		if (!settings) return false
		if (!office.coordinates.address) return false
		if (!office.coordinates.address.city) return false

		let url:string = `${office.coordinates.address.city}/${user.coordinates.first_name}-${user.coordinates.last_name}/`.toLowerCase().trim()
		url = url.replace(/\s+/g, '-')
		let urlNormalize: string = url.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
		return settings.url === urlNormalize
	}

	public static checkAddress(office: IOffice): boolean {
		return !!office.coordinates.address && !!office.coordinates.address.formatted && !!office.coordinates.address.formatted.trim()
	}

	public static checkPhones(office: IOffice): boolean {
		return Office.checkPhone(office.coordinates.phone1) || Office.checkPhone(office.coordinates.phone2)
	}

	public static checkActivate(settings: ISettings): boolean {
		return !!settings && settings.activate
	}

	public static checkMessage(settings: ISettings): boolean {
		return !!settings && !!settings.message && settings.message.trim() !== ''
	}

	public static checkDefaultRejection(settings: ISettings): boolean {
		return !!settings && !!settings.default_rejection && settings.default_rejection.trim() !== ''
	}

	public static checkSchedule(schedule: ISchedule): boolean {
		return !!schedule && parseInt(schedule.id) !== -1
	}

	public static checkAccesses(office: IOffice): boolean {
		return office.accesses.currents.length > 0
	}

	private static checkPhone(phone: IPhone): boolean {
		return !!phone && !!phone.number && !!phone.number.trim()
	}
}
