import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { Provider, Profile } from "@Enums/index"

@Module({
	name: 'coworking',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class CoWorkingModule extends VuexModule {
    step: number = 0
    profile: Profile = Profile.None
    provider: Provider = Provider.None
    email: string = null

	get withProvider(): boolean {
        return this.provider !== Provider.None
	}
	
	@Mutation
	clear() {
		this.step = 0
		this.profile = Profile.None
		this.provider = Provider.None
		this.email = null
	}

	@Mutation
	updateStep(step: number) {
		this.step = step
	}

	@Mutation
	updateProfile(profile: Profile) {
		this.profile = profile
	}

	@Mutation
	updateProvider(provider: Provider) {
		this.provider = provider
	}

	@Mutation
	updateEmail(email: string) {
		this.email = email
	}

	@Action
	nextStep(): void {
		this.updateStep(this.step + 1)
	}

	@Action
	previousStep(): void {
		this.updateStep(Math.max(this.step - 1, 0))
	}
}

export const coworking = getModule(CoWorkingModule)
// export const coworking = new CoWorkingModule({ store, name: "coworking" })