import Render from '@Views/root/patients/campaigns/campaign/steps/summary.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatientSearch, IPopin, IPopinAction, ICampaign} from '@Store/types'
import { Gender } from '@Enums/index'
import { campaign, popIn } from '@Store/modules'

@Render
@Component({})
export class Summary extends Vue {

	@Prop() campaign: ICampaign

	//#region Getters
	get isSended(): boolean {
		return campaign.isSended()
	}
	//#endregion

	search(number: string): void {
		let searchAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.campaign.summary.search.popin-action').toString(),
			title: this.$i18n.t('vm.root.patients.campaign.summary.search.title').toString(),
			callback: (data: {selectedId: string, patient: IPatientSearch}) => {
				let patient: IPatientSearch = data.patient
				let validData: boolean = !!patient.last_name && !!patient.first_name
				if (!validData) return Promise.reject()

				this.$router.push({name: 'patient-coordinates', params: {pat_id: patient.id}})
				return Promise.resolve()
			},
			condition: (data: {selectedId: string}) => {
				return !!data.selectedId
			}
		}
		let patient: IPatientSearch = {
			id: '-1',
			last_name: '',
			first_name: '',
			gender: Gender.Man,
			birth_date: new Date(),
			phone: number,
			email: ''
		}
		let search: IPopin = {
			title: this.$i18n.t('vm.root.patients.campaign.summary.search.popin-title').toString(),
			content: {component: 'popin-search-patient'},
			action: searchAction,
			options: {selectedId: null, patient: patient}
		}

		popIn.info(search)
	}
}
