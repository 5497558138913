import Vue from 'vue'
import Router from 'vue-router'
import * as Root from '@ViewModels/root'
import { Profile } from '@Enums/index'
import { StorageHelper } from '@Helpers/index'
import { PlanningRoot } from './planning'
import { AccountingRoot } from './accounting'
import { DocumentRoot } from './document'
import { PatientsRoot } from './patients'
import { ShopRoot } from './shop'
import { HelpRoot } from './help'
import { LettersRoot } from './letters'
import { StatisticsRoot } from './statistics'
import { ParameterRoot } from './parameter'
import { CoWorkingRoot } from './co-working'
import { HomeRoot } from './home'
import { UserRoot } from './user'
import { LoginRoot } from './login'
import { user } from '@Store/modules'
import config from "@Config/index"

Vue.use(Router)

export const router = new Router({
	mode: 'history',
	routes: [
		{ 	path: '/',
			component: Root.Main,
			children: [
				{
				 	path: 'restricted', 
					name:'restricted', 
					component: Root.Restricted,
					meta: { permission: false }
				},
				...HomeRoot,
				...UserRoot,
				CoWorkingRoot,
				...PlanningRoot,
				...AccountingRoot,
				...DocumentRoot,
				...PatientsRoot,
				...ShopRoot,
				...LettersRoot,
				...HelpRoot,
				...StatisticsRoot,
				...ParameterRoot
			]
		},
		LoginRoot,

		// otherwise redirect to home
		{ path: '*', redirect: '/home' }
	]
});

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	if (!to.meta.permission)
		return next()
	
	StorageHelper.set('path', {name: to.name, params: to.params, meta: to.meta})
	const authHeaders = router.app.$cookies.get('auth_headers')
	let loggedIn: boolean | string = false
	if (!!authHeaders) {
		loggedIn = authHeaders.expiry && authHeaders.expiry * 1000 > Date.now()
	} else if (config.use_storage) {
		loggedIn = StorageHelper.get('session_id_osteo2ls', false)
	}

	if (loggedIn) {
		let _user = user.user
		if (_user === null) {
			return next(false)
		}

		let permission: Profile[] = to.meta.permission
		if (permission.includes(_user.profile)) {
			next()
		} else {
			next({name: 'restricted'})
		}
	} else {
		next({name: 'login'})
	}
})