<div class="input-frame" :class="{ 'is-alert': errorTest }">
    <label :for="id" :data-text="label" class="label">
        <span>{{label}}</span>
    </label>
    
    <dropdown-editable :id="id" :value="value" @input="handleInput" :searcher="searcher" :items="items"
        :placeholder="placeholder" :title="title" :readonly="readonly" :disabled="disabled" 
        :no-options-label="noOptionsLabel" :multiple="multiple" :taggable="taggable"/>
    <div class="msg-alert" v-if="displayError" v-show="errorTest">
        <div class="msg-alert-inner">{{errorMsg}}</div>
    </div>
</div>