<div class="wrapper marg-v text-center">
	<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
		<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
	</svg>
	<p><strong class="text-xl text-primary">{{ $t('vm.root.shop.paying.thanks') }}</strong></p>
	<p>{{ $t('vm.root.shop.paying.title') }}</p>
	<div v-if="isCheck">
		<p>
			<i18n path="vm.root.shop.paying.check.intro1" tag="span">
				<strong>{{ constants.COMPANY }}</strong>
			</i18n>
			<br>
			<span class="text-bold text-lg">{{ $n(price, 'currency', 'fr') }}</span><br>
			{{ $t('vm.root.shop.paying.check.intro2') }}
		</p>
		<div class="check-container p-like text-md">
			<div>
				<strong>{{ constants.COMPANY }}</strong><br>
				{{ constants.CONTACT }}<br>
				{{ constants.STREET }}<br>
				{{ constants.CITY }}, {{ constants.COUNTRY }}
			</div>
		</div>
		<i18n v-if="isBulk" path="vm.root.shop.paying.check.delay.content" tag="p">
			<strong>{{ $t('vm.root.shop.paying.check.delay.strong') }}</strong>
		</i18n>
	</div>
	<div v-else-if="isTransfer">
		<p>
			{{ $t('vm.root.shop.paying.transfer.intro1') }}<br>
			<span class="text-bold text-lg">{{ $n(price, 'currency', 'fr') }}</span><br>
			<i18n path="vm.root.shop.paying.transfer.intro2.text" tag="span">
				<abbr :title="$t('vm.root.shop.paying.transfer.intro2.abbr-title')">
					{{ $t('vm.root.shop.paying.transfer.intro2.abbr-label') }}
				</abbr>
			</i18n>
		</p>
		<div class="transfer-container p-like text-md">
			<div>
				{{ $t('vm.root.shop.paying.transfer.iban') }} :
				<strong class="text-numeric">
					<span v-for="item in iban" :key="item">
						<span class="select">{{item}}</span>
						<span><!-- space--></span>
					</span>
				</strong><br>
				{{ $t('vm.root.shop.paying.transfer.bic') }} :
				<strong class="text-numeric select">{{ constants.BIC }}</strong>
			</div>
		</div>
		<i18n v-if="isBulk" path="vm.root.shop.paying.transfer.delay.content" tag="p">
			<strong>{{ $t('vm.root.shop.paying.transfer.delay.strong') }}</strong>
		</i18n>
	</div>
	<div v-else-if="isPaypal" class="marg-20">
		<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#credit-card"/>
		</svg>
		<p>
			<spinner class="svg-middle"></spinner>
			{{ $t('vm.root.shop.paying.paypal') }}
		</p>
	</div>
</div>
