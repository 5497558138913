import { BaseService } from '@Services/index'

export enum TagCategory {
	Profession = 'profession',
	Keyword = 'keyword',
	Reference = 'reference',
	Consultation = 'consultation', 
	Sphere = 'sphere',
	Pathology = 'pathology',
	Antecedent = 'antecedent'
}
export class TagService extends BaseService {
	
	public getTags(query: string, category: TagCategory, params?: any): Promise<any> {
		return super.get(`/tag/${category}/${query}`, params)
	}

	public getAllTags(category: TagCategory): Promise<any> {
		return super.get(`/all/tag/${category}`)
	}
}