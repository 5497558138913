<context>
	<div class="wrapper">
		<p>
			<strong>{{ $t('vm.root.calendar.planning.context.strong') }}</strong>
		</p>
		<i18n path="vm.root.calendar.planning.context.paragraph1.text" tag="p">
			<em>{{ $t('vm.root.calendar.planning.context.paragraph1.em1') }}</em>
			<em>{{ $t('vm.root.calendar.planning.context.paragraph1.em2') }}</em>
		</i18n>
		<p>{{ $t('vm.root.calendar.planning.context.paragraph2') }}</p>
		<div class="p-like">
			<iframe width="100%" src="https://www.youtube.com/embed/njKjXbpWk8w" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen/>
		</div>
	</div>
</context>
