<div class="collapsible-panel bordered-panel" :class="collapsibleClass">
	<div class="childs-space-between">
		<button type="button" class="btn--link-like collapsible-panel-trigger text-bold text-inherit"
			@click="toggle()" :class="{'is-active': isToggle()}">
			<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
			</svg>
			<slot name="title"/>
		</button>
		<slot name="right" class="is-pushed-right"/>
	</div>

	<slot name="subtitle"/>

	<div class="collapsible-panel-container" :class="{'is-active': isToggle()}">
		<div class="collapsible-panel-content">
			<slot/>
		</div>
	</div>
</div>
