export const newTicket = {
    'new-title': "Nouveau ticket",
    'help': {
        text: "Vous rencontrez un soucis ? Le logiciel ne fonctionne pas normalement ? Avez-vous pensé à consulter la {0} ci-contre ? Si malgré cela, vous n'avez pas la réponse à votre question, alors, le {1} est là pour vous. Nous y répondrons dans les plus brefs délais.",
        'abbr-title': "Foire Aux Questions",
        'abbr-label': "F.A.Q.",
        strong: "nouveau ticket"
    },
    title: {
        label: "Intitulé du ticket",
        placeholder: "ex : Comment modifier mes coordonnées ?",
        'error-msg': "Merci de renseigner l'intitulé du ticket",
        title: "Veuillez renseigner l'intitulé du ticket"
    },
    comment: {
        label: "Votre commentaire",
        placeholder: "ex : Mon problème est…",
        'error-msg': "Merci de décrire le plus clairement possible votre problème",
        title: "Veuillez décrire le plus clairement votre problème"
    },
    send: {
        title: "Envoyez votre commentaire",
        label: "envoyer"
    },
    'support-disabled': {
        paragraph1: {
            text: "Votre maintenance est expirée depuis le {0}. Pour pouvoir créer un nouveau ticket vous devez acquérir un {1}.",
            'link-title': "Acquérir un pack de maintenance", 
            'link-label': "pack maintenance"
        },
        paragraph2: {
            text: "Vous pouvez également rejoindre notre {0}. L'aide y est gratuite et les autres utilisateurs d'Osteo2ls pourront vous apporter leur aide.",
            'link-title': "Groupe d'entraide pour Osteo2ls", 
            'link-label': "groupe d'entraide sur Facebook"
        }
    }
}