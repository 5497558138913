export const rgpdPatient = {
    error: {
        'no-date': "Merci de renseigner la date de naissance de votre patient",
        'bad-date': "Ce n'est pas la date de naissance de ce patient"
    },
    text1: "Ce patient ne souhaite pas partager ses informations sans son consentement.",
    text2: {
        content: "Pour pouvoir accéder à son dossier, demandez-lui sa date de naissance, puis cliquez sur {0}.",
        strong: "Confirmer"
    },
    text3: {
        content: "Son dossier sera alors accessible {0}.",
        strong: "pendant 2 heures"
    }
}