export const detailsColleague = {
    'first-name': {
        label: "Prénom du praticien",
        placeholder: "ex : Michèle",
        'error-msg': "Merci de renseigner le prénom du praticien",
        title: "Veuillez renseigner le prénom du praticien"
    },
    'last-name': {
        label: "Nom du praticien",
        placeholder: "ex : Simesse",
        'error-msg': "Merci de renseigner le nom du praticien",
        title: "Veuillez renseigner le nom du praticien"
    },
    phone: {
        label: "Numéro de téléphone du praticien",
        'error-msg': "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le numéro de téléphone du praticien"
    },
    email: {
        label: "Adresse email du praticien",
        placeholder: "ex : serge@karamazov.com",
        title: "Veuillez renseigner l'adresse email du praticien"
    },
    address: "Adresse postale du praticien",
    profession: {
        label: "Profession du praticien",
        'no-options-label': "tapez pour rechercher une profession…",
        placeholder: "ex : Dentiste",
        title: "Veuillez renseigner la profession du praticien"
    }
}