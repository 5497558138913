export enum RecurringType {
	None 	= 0,
	Day		= 1,
	Week 	= 2,
	Month 	= 3,
	Year	=4
}

export enum DayOfMonthType {
	DayOfWeek		= 0,
	DayOfMonth		= 1,
	LastDayOfMonth	= 2
}