<button v-if="supportFullScreen()" type="button" @click="toggle"
	:title="fullScreenStatus() ? $t('vm.components.breadcrumb.fullscreen.full.title') : $t('vm.components.breadcrumb.fullscreen.normal.title')">
	<template v-if="responsive">
		<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true">
			<use v-if="!fullScreenStatus()" xlink:href="@Assets/svg/symbol-defs.svg#expand-arrows" />
			<use v-else xlink:href="@Assets/svg/symbol-defs.svg#expand" />
		</svg>
		<span v-if="!fullScreenStatus()" class="sr-only">{{$t('vm.components.breadcrumb.fullscreen.full.text')}}</span>
		<span v-else class="sr-only">{{$t('vm.components.breadcrumb.fullscreen.normal.text')}}</span>
	</template>
	<template v-else>
		<span class="btn-in-breadcrumb-icon">
			<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true">
				<use v-if="!fullScreenStatus()" xlink:href="@Assets/svg/symbol-defs.svg#expand-arrows" />
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#expand" />
			</svg>
		</span>
		<span v-if="!fullScreenStatus()" class="btn-in-breadcrumb-text text-nowrap rwd-over-large">{{$t('vm.components.breadcrumb.fullscreen.full.text')}}</span>
		<span v-else class="btn-in-breadcrumb-text text-nowrap rwd-over-large">{{$t('vm.components.breadcrumb.fullscreen.normal.text')}}</span>
	</template>
</button>