import Render from '@Views/root/patients/export/export.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import config from "@Config/index"
import {
	IBreadcrumbItem,
	INotification,
	IExportFilter,
	IPatientSearch,
	IExportResult,
	INotificationAction
} from '@Store/types'
import { Debouncer } from '@Models/index'
import { IDropdownActionItem } from '@Components/index'
import {breadcrumb, notif, patients, user} from '@Store/modules'
import {ContextExport} from "./context-export"

@Render
@Component({
	components: {
		'context-export': ContextExport
	}
})
export class Export extends Vue {

	private static PerPage: number = 250

	result: IPatientSearch[] = []
	resultSearch: IPatientSearch[] = []
	searched: boolean = false
	searching: boolean = false
	page: number = 0
	total: number = 0

	private debouncer: Debouncer = null
	private emails: string[] = []
	private phones: string[] = []

	//#region Getters
	get filter(): IExportFilter {
        return patients.filterExport
    }
	get pageMax(): number {
		return Math.ceil(this.total / Export.PerPage)
	}
	get displayPagination(): boolean {
		return this.page > 0 || this.total > 0
	}
	get startIndex(): number {
		return (this.page * Export.PerPage) + 1
	}
	get endIndex(): number {
		return Math.min(this.total, (this.page + 1) * Export.PerPage)
	}
	get actionItems(): IDropdownActionItem[] {
		return [
			{
				value: ExportAction.Export,
				action: this.export,
				title: this.$i18n.t('vm.root.patients.export.action.export.title').toString(),
				label: this.$i18n.t('vm.root.patients.export.action.export.label').toString(),
				icon: "cloud-download"
			},
			{
				value: ExportAction.CopyEmail,
				action: this.copyEmail,
				title: this.$i18n.t('vm.root.patients.export.action.copy-email.title').toString(),
				label: this.$i18n.t('vm.root.patients.export.action.copy-email.label').toString(),
				icon: "copy"
			},
			{
				value: ExportAction.CopyPhone,
				action: this.copyPhone,
				title: this.$i18n.t('vm.root.patients.export.action.copy-phone.title').toString(),
				label: this.$i18n.t('vm.root.patients.export.action.copy-phone.label').toString(),
				icon: "copy"
			}
		]
	}
	get canPrevious(): boolean {
		return !this.searching && this.page > 0
	}
	get canNext(): boolean {
		return !this.searching && this.resultSearch.length === Export.PerPage
	}
	//#endregion

	mounted() {
		this.filter.duplicate = false
		this.page = 0
		this.debouncer = new Debouncer(this.search, 500)
		this.debouncer.start()
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	beforeDestroy() {
		this.resultSearch.length = 0
		Debouncer.clear(this.debouncer)
	}

	@Watch('filter.last_name')
	@Watch('filter.first_name')
	@Watch('filter.ofi_id')
	@Watch('filter.blacklisted')
	@Watch('filter.deceased')
	updateFilter() {
		this.page = 0
		this.total = 0
		this.searched = false
		this.debouncer.start()
	}

	previous(): void {
		this.page--
		this.searching = true
		setTimeout(this.search, 1)
	}

	next(): void {
		this.page++
		this.searching = true
		setTimeout(this.search, 1)
	}

	private search(): void {
		this.searching = true
		this.resultSearch = []

		patients.searchExport({filter: this.filter, page: this.page, with_list: this.page === 0})
		.then((result: IExportResult) => {
			this.searched = true
			this.total = result.total
			if (this.page === 0) {
				this.phones = result.phones
				this.emails = result.emails
			}
			this.resultSearch.push(...result.list)
			this.searching = false
		})
		.catch(error => {
			this.searched = true
			this.searching = false
			this.emails = []
			this.phones = []
			console.error(error)
		})
	}

	private export(): void {
		let filter: Record<string, string> = this.getFilterList()
		let params: string = new URLSearchParams(filter).toString()
		let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/csv/patient.csv?${params}`

		window.open(url, '_blank').focus()
	}

	private getFilterList(): Record<string, string> {
		let filter: Record<string, string> = {}
		Object.keys(this.filter).map(k => filter[`${k}`] = this.filter[k])

		return filter;
	}

	private copyEmail(): void {
		let emails: string[] = this.emails.filter(email => { return !!email })

		if (emails.length === 0) {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.tc('vm.root.patients.export.action.copy-email.success', 0).toString(), actions:[] }
			notif.error(notification)
			return
		}

		let allEmails: string = ""
		emails.forEach(email => allEmails = `${allEmails}${email};`)
		allEmails = allEmails.trim()
		navigator.clipboard.writeText(allEmails).then(() => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.tc('vm.root.patients.export.action.copy-email.success', emails.length).toString(), actions:[] }
			notif.success(notification)
		}, () => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.t('vm.root.patients.export.action.copy-email.error').toString(), actions:[] }
			notif.error(notification)
		})
	}

	private copyPhone(): void {
		let phones: string[] = this.phones.filter(phone => { return !!phone })

		if (phones.length === 0) {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.tc('vm.root.patients.export.action.copy-phone.success', 0).toString(), actions:[] }
			notif.error(notification)
			return
		}

		let allPhones: string = ""
		phones.forEach(phone => allPhones = `${allPhones}${phone};`)
		allPhones = allPhones.trim()
		navigator.clipboard.writeText(allPhones).then(() => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.tc('vm.root.patients.export.action.copy-phone.success', phones.length).toString(), actions:[] }
			notif.success(notification)
		}, () => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.t('vm.root.patients.export.action.copy-phone.error').toString(), actions:[] }
			notif.error(notification)
		})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.export').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

export enum ExportAction {
	Export		= 1,
	CopyEmail	= 2,
	CopyPhone	= 3
}
