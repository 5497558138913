export class SmsHelper {
    public static countOccurence(str: string): number {
        let doubleCharacters:string[] = ['{', '}', '€', '[', ']', '~', '^', '¨', '|', '&', '$', '@', '\n', '\r', '\t']
        let totalCount: number = 0
        for (var i = 0; i < doubleCharacters.length; i++) {
            var doubleCharacter = doubleCharacters[i]
            totalCount += Math.max(0, str.split(doubleCharacter).length - 1)
        }
        return totalCount
    }
}
