<container :loading="!gift" :loading-title="$t('vm.root.user.gift.main.loading')">
	<aside-gift/>

	<master>
		<central>
			<div class="wrapper marg-v">
				<router-view :gift="gift" @restore="restoreGift"/>
			</div>
		</central>
	</master>

</container>
