import * as Planning from '@ViewModels/root/calendar'
import { Profile } from '@Enums/profile'

export const DocumentRoot = [
	{
		path: 'documents', 
		name:'documents', 
		component: Planning.Main,
		meta: { permission: [] }
		// meta: { permission: [Profile.Main, Profile.Demo] }
	}
]
