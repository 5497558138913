<a-side :sub-menu="true">
	<ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'receipt'}" :title="$t('vm.root.accounting.aside.receipt.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                {{ $t('vm.root.accounting.aside.receipt.label') }}
            </router-link>
        </li>
        <li>
            <router-link :to="{name: 'spend'}" :title="$t('vm.root.accounting.aside.spend.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                {{ $t('vm.root.accounting.aside.spend.label') }}
            </router-link>
        </li>
        <li>
            <router-link :to="{name: 'immobilization'}" :title="$t('vm.root.accounting.aside.immobilization.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                {{ $t('vm.root.accounting.aside.immobilization.label') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'remittances'}" :title="$t('vm.root.accounting.aside.remittances.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                {{ $t('vm.root.accounting.aside.remittances.label') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'recipe-book'}" :title="$t('vm.root.accounting.aside.book.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                {{ $t('vm.root.accounting.aside.book.label') }}
            </router-link>
        </li>
    </ul>
</a-side>
