<container :loading="!campaign" :loading-title="$t('vm.root.patients.campaign.loading')">

	<aside-campaign/>

	<master v-if="campaign">

		<central>
			<form @submit.prevent="submitSave">
				<!-- Prevent implicit submission of the form -->
				<button type="submit" disabled style="display: none" aria-hidden="true"/>

				<router-view :campaign="campaign"/>
			</form>
		</central>

	</master>

</container>
