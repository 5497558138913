<div>
	<div class="p-like">
		<router-link :to="{name: 'purchase', params: {pur_id: purchase.id}}" :title="$t('vm.root.shop.purchase-item.title')">{{ title }}</router-link>
	</div>

	<div class="p-like childs-space-between">

		<span v-if="isCompleted">{{ $tc('vm.root.shop.purchase-item.invoice', purchase.billing_count) }}</span>

		<span v-else>{{ $t('vm.root.shop.purchase-item.not-finalized') }}</span>

		<span v-if="!isCompleted" class="is-pushed-right">
			<delete-item class="btn btn--link-like text-danger" :can-delete="true"
				:title="$t('vm.root.shop.purchase-item.delete.title')"
				:delete-msg="$t('vm.root.shop.purchase-item.delete.delete-msg')"
				:delete-action="submitDelete">
				<spinner v-if="isDeleting"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('general.delete.text') }}
			</delete-item>
		</span>

		<span v-else-if="canUnsubscribe" class="is-pushed-right">
			<span v-if="isUnsubscribed" class="text-danger">{{ $t('vm.root.shop.purchase-item.reset.already') }}</span>
			<delete-item v-else class="btn btn--link-like text-danger" :can-delete="true"
				:title="$t('vm.root.shop.purchase-item.reset.title')"
				:delete-label="$t('vm.root.shop.purchase-item.reset.label')"
				:delete-msg="$t('vm.root.shop.purchase-item.reset.delete-msg')"
				:delete-action="submitUnsubscribe">
				<spinner v-if="isUnsubscribing"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.shop.purchase-item.reset.label') }}
			</delete-item>
		</span>

	</div>
</div>
