export * from './document'
// export * from './general'
// export * from './patient'
// export * from './planning'
// export * from './notification'
// export * from './accounting'
// export * from './reminder'
// export * from './follow-up'
// export * from './relaunch'
// export * from './forms'
// export * from './documents'
// export * from './test'