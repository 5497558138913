import Render from '@Views/components/popin/layout/popin-create-sphere.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ISphere } from '@Store/types'
import { Main } from '@ViewModels/root/patients/patient/sphere'

@Render
@Component({
	components: {
		'details-sphere': Main
	}
})
export class PopinCreateSphere extends Vue {
    
    @Prop() options: ISphere
	@Prop({default: false}) submitted: boolean
}