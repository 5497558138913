import {getModule, Module, VuexModule} from "vuex-module-decorators"
import {store} from '@Store/index'
import {
	IAntecedent,
	IAttachment,
	IConsultation,
	IMember,
	IPediatrics,
	ISphere,
	ITimeLineFilter,
	ITimelineItem,
} from "@Store/types"
import {
	AttachmentType,
	MimeType,
	TimelineItemType
} from "@Enums/index"
import {patient} from "@Store/modules"
import {DateHelper, EnumsHelper} from "@Helpers/index"
import {i18n} from "@I18n/index"

@Module({
	name: 'timeline',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class TimelineModule extends VuexModule {
	get timelineItems(): (filter: ITimeLineFilter) => ITimelineItem[] {
		return (filter: ITimeLineFilter) => {
			let birth_date:Date = patient.coordinates.birth_date
			let items: ITimelineItem[] = []
			if (filter.consultations) {
				items = [...items, ...patient.consultations.map((consultation: IConsultation) => (this.consultationItem(birth_date, consultation)))]
			}
			if (filter.antecedents) {
				let myAntecedents: IAntecedent[] = patient.antecedents.filter(a => { return !a.family })
				items = [...items, ...myAntecedents.map((antecedent: IAntecedent) => (this.antecedentItem(birth_date, antecedent)))]
			}
			if (filter.spheres) {
				items = [...items, ...patient.spheres.map((sphere: ISphere) => (this.sphereItem(birth_date, sphere)))]
			}
			if (filter.attachments) {
				items = [...items, ...patient.attachments.map((attachment: IAttachment) => (this.attachmentItem(birth_date, attachment)))]
			}
			if (filter.pediatrics) {
				items = [...items, this.pediatricsItem(birth_date, patient.pediatrics)]
			}

			return items
		}
	}
	get familyItems(): (filter: ITimeLineFilter) => ITimelineItem[] {
		return (filter: ITimeLineFilter) => {
			let birth_date:Date = patient.coordinates.birth_date
			let items: ITimelineItem[] = []
			if (filter.antecedents) {
				let familyAntecedents: IAntecedent[] = patient.antecedents.filter(a => { return !!a.family })
				items = [...items, ...familyAntecedents.map((antecedent: IAntecedent) => (this.antecedentItem(birth_date, antecedent)))]
			}
			return items
		}
	}
	get consultationItem(): (birth_date:Date, consultation: IConsultation) => ITimelineItem {
		return (birth_date:Date, consultation: IConsultation) => {
			let result: ITimelineItem = {
				id: parseInt(consultation.id) === -1 ? consultation.create_id : consultation.id,
				creator_id: consultation.creator_id,
				active: false,
				title: patient.reasonTitle(consultation),
				date: consultation.act_date,
				type: TimelineItemType.Consultation,
				examen: {
					evolution: consultation.examen.evolution,
					observation: consultation.examen.observation,
					diagnostic: consultation.examen.diagnostic,
					tests: consultation.examen.tests,
					treatment: consultation.examen.treatment
				},
				eva: {
					before: consultation.eva.before,
					after: consultation.eva.after
				},
				exclusion_tests: consultation.exclusion_tests,
				follow_up: consultation.follow_up,
				forms: consultation.forms,
				age: DateHelper.calculateAge(birth_date, consultation.act_date)
			}

			return result
		}
	}
	get antecedentItem(): (birth_date:Date, antecedent: IAntecedent) => ITimelineItem {
		return (birth_date:Date, antecedent: IAntecedent) => {
			let endDate: Date = !antecedent.end_date ? new Date() : antecedent.end_date
			let member: IMember = patient.member(antecedent.pat_id)
			let result: ITimelineItem = {
				id: parseInt(antecedent.id) === -1 ? antecedent.create_id : antecedent.id,
				creator_id: antecedent.creator_id,
				active: false,
				title: antecedent.type,
				date: antecedent.event_date,
				type: TimelineItemType.Antecedent,
				member: member,
				family: antecedent.family,
				important: antecedent.important,
				periodic: antecedent.periodic,
				running: antecedent.running,
				sub_title: antecedent.title,
				description: antecedent.description,
				end_date: antecedent.running ? new Date() : (antecedent.periodic ? endDate : antecedent.event_date),
				forms: antecedent.forms,
				age: ''
			}
			result.age = DateHelper.calculateAge(birth_date, result.date)

			return result
		}
	}
	get sphereItem(): (birth_date:Date, sphere: ISphere) => ITimelineItem {
		return (birth_date:Date, sphere: ISphere) => {
			let endDate: Date = !sphere.end_date ? new Date() : sphere.end_date
			let result: ITimelineItem = {
				id: parseInt(sphere.id) === -1 ? sphere.create_id : sphere.id,
				creator_id: sphere.creator_id,
				active: false,
				title: sphere.type,
				date: sphere.event_date,
				type: TimelineItemType.Sphere,
				important: sphere.important,
				periodic: sphere.periodic,
				running: sphere.running,
				sub_title: sphere.title,
				description: sphere.description,
				end_date: sphere.running ? new Date() : (sphere.periodic ? endDate : sphere.event_date),
				forms: sphere.forms,
				age: ''
			}
			result.age = DateHelper.calculateAge(birth_date, result.date)

			return result
		}
	}
	get attachmentItem(): (birth_date:Date, attachment: IAttachment) => ITimelineItem {
		return (birth_date:Date, attachment: IAttachment) => {
			let result: ITimelineItem = {
				id: parseInt(attachment.id) === -1 ? attachment.create_id : attachment.id,
				creator_id: attachment.creator_id,
				active: false,
				title: attachment.name,
				description: attachment.description,
				date: attachment.act_date,
				type: TimelineItemType.Attachment,
				attachment: attachment.url,
				is_link: attachment.type === AttachmentType.Link,
				is_file: attachment.type === AttachmentType.File,
				is_pdf: attachment.mime === MimeType.Pdf,
				end_date: attachment.act_date,
				age: DateHelper.calculateAge(birth_date, attachment.act_date)
			}

			return result
		}
	}
	get pediatricsItem(): (birth_date:Date, pediatrics: IPediatrics) => ITimelineItem {
		return (birth_date:Date, pediatrics: IPediatrics) => {
			let result: ITimelineItem = {
				id: 'pediatrics',
				creator_id: undefined,
				active: false,
				title: "Périnatalité",
				date: birth_date,
				age: DateHelper.calculateAge(birth_date, birth_date),
				type: TimelineItemType.Pediatrics,
				forms: pediatrics.forms,
				description1: "",
				description2: "",
				description3: "",
				description4: "",
				description5: ""
			}
			let numberOfChildren: string = pediatrics.number_of_children === -1 ?
				i18n.t('vm.root.patients.patient.coordinates.number-of-children.none').toString() :
				i18n.tc('vm.root.patients.patient.coordinates.number-of-children.item', pediatrics.number_of_children).toString()
			let description1: string = `Nombre d'enfants : ${numberOfChildren}`
			if (!!pediatrics.antecedents_parents) description1 += `, ${pediatrics.antecedents_parents}`
			if (!!pediatrics.remarks1) description1 += `, ${pediatrics.remarks1}`
			result.description1 = description1

			let description2: string = ""
			if (pediatrics.with_contraction || !!pediatrics.contraction) description2 += "Contractions, "
			if (pediatrics.with_immobilisation || !!pediatrics.immobilisation) description2 += "Immobilisation au lit, "
			if (pediatrics.with_strapping || !!pediatrics.strapping)  description2 += "Cerclage, "
			if (pediatrics.with_accident || !!pediatrics.accident)  description2 += "Chutes, accidents, "
			if (pediatrics.with_disease || !!pediatrics.disease)  description2 += "Maladies pendant la grossesse, "
			if (pediatrics.with_gained_weight || !!pediatrics.gained_weight)  description2 += "Prise de poids, ";
			if (pediatrics.with_tranquilizer || !!pediatrics.tranquilizer)  description2 += "Prise de tranquilisants"
			if (!!pediatrics.remarks2) description2 += `, ${pediatrics.remarks2}`
			result.description2 = !description2 ? "RAS" : description2

			let description3: string = ""
			if (pediatrics.with_anesthesia || !!pediatrics.anesthesia) description3 += "Anesthésie, "
			if (!!pediatrics.duration) description3 += `Durée du travail : ${pediatrics.duration} h, `
			if (!!pediatrics.eutocic) description3 += "Accouchement eutocique, "
			if (!!pediatrics.obstructed) description3 += "Accouchement dystocique, "
			if (!!pediatrics.episiotomy) description3 += "Épisiotomie, "
			if (!!pediatrics.forceps) description3 += "Forceps, "
			if (!!pediatrics.fundal) description3 += "Expression abdominale, "
			if (!!pediatrics.suction_cup) description3 += "Ventouse, "
			if (!!pediatrics.finger) description3 += "Doigt dans la bouche, "
			if (pediatrics.with_accelerators || !!pediatrics.accelerators) description3 += "Prise d'accélérateurs, "
			if (pediatrics.with_caesarean || !!pediatrics.caesarean) description3 += "Césarienne"
			if (!!pediatrics.presentation) description3 += `, ${pediatrics.presentation} `
			if (!!pediatrics.remarks3) description3 += `, ${pediatrics.remarks3}`
			result.description3 = !description3 ? "RAS" : description3

			let description4: string = ""
			if (pediatrics.with_premature || !!pediatrics.premature) description4 += "Prématuré, "
			if (!!pediatrics.weight) description4 += `Poids ${pediatrics.weight} kg, `
			if (!!pediatrics.size) description4 += `Taille ${pediatrics.size} cm, `
			if (pediatrics.with_apgar1 || pediatrics.with_apgar3 || pediatrics.with_apgar5 || pediatrics.with_apgar10) {
				description4 += "APGAR : "
				if (pediatrics.with_apgar1) description4 += `${pediatrics.apgar1}<sup>(1)</sup>, `
				if (pediatrics.with_apgar3) description4 += `${pediatrics.apgar3}<sup>(3)</sup>, `
				if (pediatrics.with_apgar5) description4 += `${pediatrics.apgar5}<sup>(5)</sup>, `
				if (pediatrics.with_apgar10) description4 += `${pediatrics.apgar10}<sup>(10)</sup>, `
				description4 = description4.substring(0, description4.length - 2)
			}
			if (!!pediatrics.cyanotic) description4 += "Cyanosé, "
			if (!!pediatrics.alimentation) description4 += `${EnumsHelper.alimentationToString(pediatrics.alimentation)}, `
			if (pediatrics.with_reanimation || !!pediatrics.reanimation) description4 += "Réanimation, "
			if (!pediatrics.with_scream || !!pediatrics.scream) description4 += "N'a pas crié immédiatement, "
			if (pediatrics.with_deformed_skull || !!pediatrics.deformed_skull) description4 += "Crâne déformé"
			if (!!pediatrics.remarks4) description4 += `, ${pediatrics.remarks4}`
			result.description4 = !description4 ? "RAS" : description4

			let description5: string = ""
			if (!!pediatrics.suckle) description5 += "Difficultés pour téter ou avaler, "
			if (pediatrics.with_reflux || !!pediatrics.reflux) description5 += "Reflux gastro-œsophagien, "
			if (pediatrics.with_allergies || !!pediatrics.allergies) description5 += "Allergies, "
			if (!!pediatrics.sleep) description5 += "Sommeil, "
			if (!!pediatrics.state) description5 += "État nerveux"
			if (!!pediatrics.remarks5) description5 += `, ${pediatrics.remarks5}`
			result.description5 = !description5 ? "RAS" : description5

			return result
		}
	}
}

export const timeline = getModule(TimelineModule)
// export const patient = new PatientModule({ store, name: "patient" })
