import Render from '@Views/components/button-to-top.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class ButtonToTop extends Vue {

	@Prop() target: string

	backToTop(): void {
		this.scrollToTop()
	}

	private scrollToTop(): void {
		let element: HTMLElement = document.getElementById(this.target)
		element.scrollTo(0, 0)
	}
}
