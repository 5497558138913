import Render from '@Views/root/shop/purchase/context-main.html'
import config from '@Config/index'
import {Component, Vue} from 'vue-property-decorator'
import {IPurchase} from '@Store/types'
import {purchase} from '@Store/modules'

@Render
@Component({})
export class ContextMain extends Vue {

	//#region Getters
	get purchase(): IPurchase {
		return purchase.purchase
	}
	get isLoading(): boolean {
		return purchase.isLoading
	}
	get payed(): boolean {
		return this.purchase.billings.length === 0 && parseInt(this.purchase.id) !== -1
	}
	//#endregion

	billingPath(bil_id: string): string {
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/billing/${bil_id}.pdf`
	}
}
