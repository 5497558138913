import Render from '@Views/root/statistics/colleagues/colleague/aside-colleague.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IColleague } from '@Store/types'
import { colleague } from '@Store/modules'

@Render
@Component({})
export class ASideColleague extends Vue {

    @Prop() colleague: IColleague

    // @State('colleague') stateColleague: ColleagueState
    // @Action('colleague/deleteColleague') deleteColleague: (col_id: string) => Promise<any>
    
    get isDeleting(): boolean {
        return colleague.isDeleting
    }

    submitDelete(): void {
        this.$emit('delete')
        colleague.deleteColleague(this.colleague.id)
        .then(() => { 
            this.$router.push({name: 'statistics-colleagues'})
        })
    }
}