<div class="wrapper marg-v">
    <collapsible-help v-if="isMain" id="share-office-main-information" tag="h1" :title="$t('vm.root.co-working.steps.step2.title', {profile: profileToString})">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step2.main-help1') }}
            </p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.main-help2') }}</p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.main-help3') }}</p>
        </div>
    </collapsible-help>
    <collapsible-help v-else-if="isSecretary" id="share-office-secretary-information" tag="h1" :title="$t('vm.root.co-working.steps.step2.title', {profile: profileToString})">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step2.secretary-help1') }}
            </p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.secretary-help2') }}</p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.secretary-help3') }}</p>
            <p class="text-left">
                {{ $t('vm.root.co-working.steps.step2.common-help1', {profile: shortProfile}) }}<br>
                {{ $t('vm.root.co-working.steps.step2.common-help2') }}
            </p>
        </div>
    </collapsible-help>
    <collapsible-help v-else-if="isSubstitute" id="share-office-substitute-information" tag="h1" :title="$t('vm.root.co-working.steps.step2.title', {profile: profileToString})">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step2.substitute-help1') }}
            </p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.substitute-help2') }}</p>
            <p class="text-left">
                {{ $t('vm.root.co-working.steps.step2.common-help1', {profile: shortProfile}) }}<br>
                {{ $t('vm.root.co-working.steps.step2.common-help2') }}
            </p>
        </div>
    </collapsible-help>
    <collapsible-help v-else-if="isAccounting" id="share-office-accounting-information" tag="h1" :title="$t('vm.root.co-working.steps.step2.title', {profile: profileToString})">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step2.accounting-help1') }}
            </p>
            <p class="text-left">{{ $t('vm.root.co-working.steps.step2.accounting-help2') }}</p>
            <p class="text-left">
                {{ $t('vm.root.co-working.steps.step2.common-help1', {profile: shortProfile}) }}<br>
                {{ $t('vm.root.co-working.steps.step2.common-help2') }}
            </p>
        </div>
    </collapsible-help>

    <div v-if="isVerifying && !manual" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.co-working.steps.step2.verifying') }}
		</div>
	</div>
    <form v-else @submit.prevent="submitNext">
        <input-frame type="email" id="email" v-model="email" :required="true" :auto-focus="true" :readonly="withProvider"
            :label="$t('vm.root.co-working.steps.step2.email.label', {profile: profileToString})"
            :error-test="hasEmailError" :error-msg="$t('vm.root.co-working.steps.step2.email.error-msg')"
            :placeholder="$t('vm.root.co-working.steps.step2.email.placeholder')"
            :title="$t('vm.root.co-working.steps.step2.email.title')"/>

        <dropdown-frame v-if="isSecretary" id="provider" :items="providerItems" v-model="provider"
            :default-label="$t('vm.root.co-working.steps.step2.provider.default-label')"
            :label="$t('vm.root.co-working.steps.step2.provider.label')"/>
        <p v-if="withProvider && isSecretary">
            {{ $t('vm.root.co-working.steps.step2.provider.description') }}
            <a :href="`//${website}`" target="_blank">{{ website }}</a>
        </p>

        <control-bar class="childs-space-between">
            <button-action btn-class="btn btn-default" @click.native="previous" :disabled="isVerifying" :title="$t('vm.root.co-working.steps.step2.previous-step.title')">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.co-working.steps.step2.previous-step.text') }}
            </button-action>

            <button-action type="submit" btn-class="btn btn-primary" :loading="isVerifying" :disabled="disabled" :title="$t('vm.root.co-working.steps.step2.next-step.title')">
                {{ $t('vm.root.co-working.steps.step2.next-step.text') }}
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
                </svg>
            </button-action>
        </control-bar>
    </form>

</div>
