<container>
	<aside-closed/>

	<master>

		<central>
			<router-view :closed="true"/>
		</central>

		<context-main/>

	</master>

</container>
