import Render from '@Views/root/patients/patient-item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPatientHistoric } from '@Store/types'
import { AnonymousHelper } from '@Helpers/index'
import { Profile } from '@Enums/index'
import { configuration, user } from '@Store/modules'

@Render
@Component({})
export class PatientItem extends Vue {

	@Prop() patient: IPatientHistoric

	//#region Getters
	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get title(): string {
		let title: string = this.$t('vm.root.patients.patient-item.title', {first_name: this.patient.first_name, last_name: this.patient.last_name}).toString()
		if (!this.isAnonymous) return title

		return AnonymousHelper.getAnonymousText(title)
	}
	get isForOtherUser(): boolean {
		return user.isSubstituteUser && !!this.patient.usr_id && parseInt(this.patient.usr_id) !== -1
	}
	get userName(): string {
		let _user: any = user.linkedUsers(Profile.Main).find(u => { return u.id === this.patient.usr_id })
		if (!_user) return this.$t("vm.root.patients.patient-item.colleague").toString()
		return `${_user.first_name} ${_user.last_name}`
	}
	//#endregion
}
