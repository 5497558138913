import Render from '@Views/components/frame/toggledate-frame.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { i18n } from '@I18n/index'
import { IToggleBoxItem } from '@Components/input/togglebox'
import { DateHelper, ITime } from '@Helpers/index'

const dateItems: IToggleBoxItem[] = [
    {value: 3, label: i18n.t('enums.recurringUnit.day').toString()},
    {value: 4, label: i18n.t('enums.recurringUnit.month').toString()},
    {value: 5, label: i18n.t('enums.recurringUnit.year').toString()}
]

@Render
@Component({})
export class ToggleDateFrame extends Vue {

    myType: number = null
    myIndex: number = null
    items: IToggleBoxItem[] = dateItems

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: () => new Date()}) to: Date
    @Prop() from: Date
    @Prop() minDate: Date
    @Prop() maxDate: Date
    @Prop() label: string
    @Prop() id: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() date: Date

    get type(): number {
        return this.myType
    }
    set type(value: number) {
        if (this.myType === value) return
        this.myType = value
        this.updateDate(value, this.myIndex)
    }
    get index(): number {
        return this.myIndex
    }
    set index(value: number) {
        if (this.myIndex === value) return
        this.myIndex = value
        this.updateDate(this.myType, value)
    }
    created() {
        this.initialize()
    }

    private updateDate(type: number, index: number) {
        if (!index || !type) return

        let date: Date = this.getPeriodDate(!!this.from ? this.getDateFrom() : this.getDateTo())
        this.$emit('input', date)
    }

    @Watch('date')
    private initialize() {
        if (!this.date) {
            this.myType = null
            this.myIndex = null
            return
        }

        let date: Date = this.getPeriodDate(this.date)
        let time: ITime = this.getTime(date)
        this.myType = Math.max(time.unitValue, dateItems[0].value)
        this.myIndex = time.time
    }

    private getTime(date: Date): ITime {
        if (!!this.from) {
            let value: number = Math.max(date.getTime(), this.from.getTime())
            return DateHelper.timeBetweenDate(this.from, new Date(value))
        }

        let value: number = Math.min(date.getTime(), this.to.getTime())
        return DateHelper.timeBetweenDate(new Date(value), this.to)
    }

    private getPeriodDate(date: Date): Date {
        let minDate: Date = !this.minDate ? date : this.minDate
        let maxDate: Date = !this.maxDate ? date : this.maxDate
        let value: number = Math.max(minDate.getTime(), date.getTime())
        value = Math.min(maxDate.getTime(), date.getTime())

        return new Date(value)
    }

    private getDateFrom(): Date {
        let date: Date = this.getOrginalDate(this.from)
        let index: number = parseInt((this.myIndex | 0).toString())

        if (this.myType === dateItems[0].value) {
            date.setDate(date.getDate() + index)
        } else if (this.myType === dateItems[1].value) {
            date.setMonth(date.getMonth() + index)
        } else if (this.myType === dateItems[2].value) {
            date.setFullYear(date.getFullYear() + index)
        }

        return date
    }

    private getDateTo(): Date {
        let date: Date = this.getOrginalDate(this.to)
        let index: number = parseInt((this.myIndex | 0).toString())

        if (this.myType === dateItems[0].value) {
            date.setDate(date.getDate() - index)
        } else if (this.myType === dateItems[1].value) {
            date.setMonth(date.getMonth() - index)
        } else if (this.myType === dateItems[2].value) {
            date.setFullYear(date.getFullYear() - index)
        }

        return date
    }

    private getOrginalDate(orginalDate: Date): Date {
        let date: Date = new Date()
        date.setTime(orginalDate.getTime())
        if (!this.date) return date

        date.setHours(this.date.getHours())
        date.setMinutes(this.date.getMinutes())
        date.setSeconds(this.date.getSeconds())
        date.setMilliseconds(this.date.getMilliseconds())

        return date
    }
}
