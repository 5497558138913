<div :class="defaultClass">
    <div class="radio">
        <input type="radio" :id="id" :name="name" :value="radioValue" :checked="checked"
            @change="update" :disabled="disabled" :readonly="readonly">
        <label :for="id" :title="title" :disabled="disabled">
            <span class="sr-only">{{title}}</span>
        </label>
    </div>
    <label :for="id"><slot/></label>
</div>
