import Render from '@Views/root/user/email.html'
import { Component } from 'vue-property-decorator'
import { INotification, INotificationAction, EmailPayload, IUser } from '@Store/types'
import { AutoSaver } from '@Components/auto-saver'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb, notif, user } from '@Store/modules'

@Render
@Component({
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Email extends AutoSaver {

	submitted: boolean = false
	email: {newEmail?: string, confirmEmail?: string} = {}

	constructor() {
		super('email', false)
	}

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('notification/success') success:(message: string | INotification) => void
	// @Action('user/changeEmail') changeEmail:(payload: EmailPayload) => any

	get user(): IUser {
		return user.user
	}
	get hasNewEmailError(): boolean {
		return this.submitted && !this.email.newEmail
	}
	get hasConfirmEmailError(): boolean {
		return this.submitted && this.email.confirmEmail !== this.email.newEmail
	}
	get isLoading(): boolean {
		return user.isEmailLoading
	}

    canSubmit(): boolean {
		return !!this.email.newEmail && !!this.email.confirmEmail && this.email.newEmail === this.email.confirmEmail
	}

	submit(event: any) {
		this.submitted = true
		this.save()
	}

	//#region IAutoSaver implementation
	restoreValue(value: any): void {
		this.email = {}
	}
	save(): void {
		if (!this.canSubmit()) return

        const { newEmail, confirmEmail } = this.email
		
		let action: INotificationAction = { label: this.$i18n.t('vm.root.user.email.save-notification.action').toString(), callback: this.save}
		let notification: INotification = {
			message: this.$i18n.t('vm.root.user.email.save-notification.message').toString(),
			actions: [action], delay: false, canClose: true
		}

		let payload: EmailPayload = { newEmail: newEmail, confirmEmail: confirmEmail }
		user.changeEmail(payload)
		.then(() => { notif.success(notification) })
		.then(() => { super.initializeValue({}) })
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'email')
		this.initializeBreadcrumb()
		super.initializeValue({})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.email').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}