import { BaseService } from '@Services/index'
import { IConfiguration } from '@Store/types'

export class ConfigurationService extends BaseService {
	
	public getConfiguration(): Promise<any> {
		return super.get(`/configuration/`)
	}

	public save(configuration: IConfiguration): Promise<any> {
		return super.put(`/configuration/`, configuration)
	}

}