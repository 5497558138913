<div>
	<h1>{{ $t('vm.root.statistics.consultations.main.title') }}</h1>

	<div v-if="isLoading" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.statistics.consultations.main.loading') }}
		</div>
	</div>

	<template v-else>
		<div class="grid-2">
			<div>
				<h2>{{ $t('vm.root.statistics.consultations.main.ratio-title', {total: totalRatio}) }}</h2>
				<apexchart type="donut" :options="ratioChartOptions" :series="ratioSeries"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legends" class="text-sm" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/>
					</li>
				</ul>
			</div>
			<div>
				<h2>{{ $t('vm.root.statistics.consultations.main.ratio-follow-up-title', {total: totalFollowUpRatio}) }}</h2>
				<apexchart type="donut" :options="ratioChartOptionsFollowUp" :series="ratioFollowUpSeries"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legendsFollowUp" class="text-sm" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/>
					</li>
				</ul>
			</div>
		</div>

		<hr>

		<div class="grid-2">
			<div>
				<h2>{{ $t('vm.root.statistics.consultations.main.evolution-title', {year: filter.year1}) }}</h2>
				<apexchart type="area" :options="evolutionChartOptions" :series="evolutionSeries1"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legends" class="text-xs" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/> ({{ getTotalSeries1(legend.label) }})
					</li>
				</ul>
			</div>

			<div>
				<h2>{{ $t('vm.root.statistics.consultations.main.evolution-title', {year: filter.year2}) }}</h2>
				<apexchart type="area" :options="evolutionChartOptions" :series="evolutionSeries2"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legends" class="text-xs" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/> ({{ getTotalSeries2(legend.label) }})
					</li>
				</ul>
			</div>
		</div>
		<hr>

<!--		<div class="grid-2">-->
			<div>
				<h2>{{ $t('vm.root.statistics.consultations.main.week-title', {week: filter.week}) }}</h2>
				<apexchart type="bar" :options="countWeekConsultationChartOptions" :series="countWeekConsultation"/>
			</div>

<!--			<div>-->
<!--				<h2>{{ $t('vm.root.statistics.consultations.main.week-title', {year: filter.year2, week: filter.week}) }}</h2>-->
<!--				<apexchart type="bar" :options="countWeekConsultationChartOptions" :series="countWeekConsultation2"/>-->
<!--			</div>-->
<!--		</div>-->
		<hr>

		<h2>Patients par nombre de consultations</h2>
		<apexchart type="bar" :options="countConsultationChartOptions" :series="countConsultation"/>
	</template>

</div>
