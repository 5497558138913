export const step3 = {
    title: "Partage de vos données",
    verifying: "Vérification en cours…",
    help1: "Activez le ou les cabinets auxquels ce compte {profile} pourra avoir accès.",
    help2: "Votre collègue n'a pas encore de compte sur Osteo2ls. Pas de soucis, le logiciel se chargera, après que vous ayez finalisé cette étape, de lui envoyer une invitation à rejoindre ce magnifique logiciel.",
    help3: "Le taux de rétrocession que vous indiquerez correspondra à celui que vous allez rétrocéder à votre remplaçant.",
    help4: "ex : pour une consultation d'un montant de 50 €, si vous indiquez un taux de 70 %, cela implique que vous reverserez 35 € à votre remplaçant.",
    'last-name': {
        label: "Nom",
        'error-msg': "Merci de renseigner le nom du titulaire du compte",
        placeholder: "ex : Dupont",
        title: "Veuillez renseigner le nom du titulaire du compte"
    },
    'first-name': {
        label: "Prénom",
        'error-msg': "Merci de renseigner le prénom du titulaire du compte",
        placeholder: "ex : Jean-luc",
        title:"Veuillez renseigner le prénom du titulaire du compte"
    },
    'access-to': "accès à : ",
    office:
    {
        title: "Pas de cabinet | Cabinet | Cabinets",
        retrocession: {
            label: "Rétrocession (%)",
            'error-msg': "Merci de le taux de la rétrocession effectuée",
            placeholder: "ex : 70 %",
            title: "Veuillez le taux de la rétrocession effectuée"
        },
        mode: {
            label: "Mode de paiement des rétrocessions" ,
            title: "Sélectionnez le mode de paiement par défaut de ces rétrocessions"
        },
        billing: {
            label: "Facturation au nom…",
            choice1: "…du remplaçant",
            choice2: "…de vous-même"
        }
    },
    planning:
    {
        help: "Vous pouvez partager vos plannings avec votre collègue. Vos plannings ne seront {0}. Cela implique qu'il ne sera {1} l'un de vos rendez-vous. Mais au moins, votre collègue pourra voir vos disponibilités.",
        strong1: "accessibles qu'en lecture seule",
        strong2: "pas possible d'ajouter, modifier ou encore supprimer",
        title: "Pas de planning | Planning | Plannings"
    },
    'next-step': {
        title: "Modifier les accès pour cet utilisateur",
        text: "finaliser"
    },
    'previous-step': {
        title: "Retourner à l'étape précédente",
        text: "étape 2"
    },
    'delete-all': {
        title: "Supprimer tous les accès pour cet utilisateur",
        text1: "supprimer",
        text2: "tous les accès"
    },
    success: {
        invitation: "Votre invitation à rejoindre vos cabinets a bien été envoyée.",
        update: "Vos modifications de partage ont bien été prises en compte.",
        delete: "Tous les accès à vos cabinets ont été supprimés."
    }
}
