<div>
	<section class="wrapper">

		<h2 class="marg-v">{{ $t('vm.root.statistics.colleagues.context.title') }}</h2>

		<div class="context-search marg-v">
			<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
			</svg>
			<input type="search" class="context-search-input" v-model="filter.query"
				:placeholder="$t('vm.root.statistics.colleagues.context.search.placeholder')"
				:title="$t('vm.root.statistics.colleagues.context.search.title')"/>
		</div>

	</section>
</div>
