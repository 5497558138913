import Render from '@Views/components/frame/input-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Common } from '@Models/index'

@Render
@Component({})
export class InputFrame extends Vue {

    private random: string = ''

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) autoFocus: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: false}) withHelp: boolean
    @Prop({default: "text"}) type: string
    @Prop() min: number
    @Prop() max: number
    @Prop() step: number
    @Prop() id: string
    @Prop() label: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() errorMsg: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }

    created() {
		this.random = Common.generateId()
    }
    
    handleInput(e: any) {
        if (this.type === 'number') {
            let numValue: number = parseFloat(e) as number
            if (isNaN(numValue)) {
                this.$emit('input', e)
            } else {
                this.$emit('input', numValue)
            }
        } else {
            this.$emit('input', e)
        }
    }

    focus() {
        (this.$refs[this.inputId] as any).focus()
    }
}