export const formations = {
    title: "Formation | Formation | Formations",
    'new-formation': {
        title: "Créer une nouvelle formation",
        label: "Nouvelle formation"
    },
    'none': {
        label: "Pas de formation",
        text: "Indiquez vos différentes {0}.",
        'button-label': "formations"
    },
    move: {
        title: "Déplacer cette formation",
        label: "Déplacer"
    },
    details: "Détails de cette formation",
    'default-name': "nouvelle formation"
}