import Render from '@Views/components/drawer.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InputColor } from './input/input-color'
import { ISchema, IItem } from '@Store/types'

@Render
@Component({
    components: {
        'input-color': InputColor
    }
})
export class Drawer extends Vue {

    public static defaultOptions: IDrawerOptions = {
		with_body: true,
		with_backbone: true,
		color: '#000000',
		size: 10
	}
    private interval: NodeJS.Timeout

    @Prop() id: 'face' | 'back' | 'left' | 'right'
    @Prop() history: IItem[]
    @Prop({default: Drawer.defaultOptions}) options: IDrawerOptions
    @Prop() schema: ISchema

    draw: Draw

    mounted() {
        this.draw = new Draw(this)
    }

    beforeDestroy() {
        if (!this.draw) return

        this.draw.destroy()
    }

    redraw(): void {
        this.draw.redraw()
    }

    clearAll() {
        this.history.splice(0, this.history.length)
        this.redraw()
    }
    
    mouseDown(): void{
        this.undo()
        this.interval = setInterval(this.undo, 100)
    }

    mouseUp(): void {
        clearInterval(this.interval)
    }

    mouseLeave(): void {
        clearInterval(this.interval)
    }

    undo() {
        this.history.splice(this.history.length-2, 1)
        this.redraw()
        if (this.history.length > 0) return

        clearInterval(this.interval)
    }
}

class Draw {
    private app: Drawer
    private mouseDowned: boolean = false
	private mouseX: number = 0
	private mouseY: number = 0
	private touchX: number = 0
    private touchY: number = 0
    private c: HTMLCanvasElement
    private ctx: CanvasRenderingContext2D

    get dummyItem(): IItem {

		let lastPoint = this.app.history.length === 0 ? {x: 0, y: 0} : this.app.history[this.app.history.length - 1]
		
		return {
			isDummy: true,
			x: lastPoint.x,
			y: lastPoint.y,
			c: null,
			r: null
		}
    }
    
	constructor(schema: Drawer) {
        this.app = schema
		this.c = document.getElementById(`${this.app.id}-canvas`) as HTMLCanvasElement
		this.ctx = this.c.getContext('2d')
		
		this.mouseDowned = false
		this.mouseX = 0
		this.mouseY = 0
		this.touchX = 0
		this.touchY = 0
		
		this.listen()
		this.redraw()
	}
    
    redraw() {
		this.ctx.clearRect(0, 0, this.c.width, this.c.height)
		// this.drawBgDots()
		
		if (this.app.history.length === 0) return
		
		this.app.history.forEach((item, i) => {
			this.draw(item, i)
		})
    }
    
	destroy() {
		this.c.removeEventListener('mousedown', (e: MouseEvent) => this.mouseDown(e))
		this.c.removeEventListener('mouseup', () => this.mouseLeave())
		this.c.removeEventListener('mouseleave', () => this.mouseLeave())
		this.c.removeEventListener('mousemove', (e: MouseEvent) => this.mouseMove(e))
		
		this.c.removeEventListener('touchstart', () => this.touch())
        this.c.removeEventListener('touchmove', (e: TouchEvent) => this.touch(e))
        this.c.removeEventListener('touchend', () => this.touchEnd())
    }

    private listen() {
		this.c.addEventListener('mousedown', (e: MouseEvent) => this.mouseDown(e))
		this.c.addEventListener('mouseup', () => this.mouseLeave())
		this.c.addEventListener('mouseleave', () => this.mouseLeave())
		this.c.addEventListener('mousemove', (e: MouseEvent) => this.mouseMove(e))
		
		this.c.addEventListener('touchstart', (e: TouchEvent) => this.touch(e, true))
        this.c.addEventListener('touchmove', (e: TouchEvent) => this.touch(e))
        this.c.addEventListener('touchend', () => this.touchEnd())
    }

    private mouseDown(e: MouseEvent) {
        this.mouseDowned = true
        this.mouseX = e.offsetX
        this.mouseY = e.offsetY
        this.setDummyPoint()

        let item: IItem = {
            isDummy: false,
            x: this.mouseX,
            y: this.mouseY,
            c: this.app.options.color,
            r: this.app.options.size
        }
        
        this.addItem(item)
    }

    private mouseLeave() {
        if (this.mouseDowned) {
            this.setDummyPoint()
        }

        this.mouseDowned = false
    }

    private mouseMove(e: MouseEvent) {
        if (!this.mouseDowned) return

        this.mouseX = e.offsetX
        this.mouseY = e.offsetY
                        
        let item: IItem = {
            isDummy: false,
            x: this.mouseX,
            y: this.mouseY,
            c: this.app.options.color,
            r: this.app.options.size
        }
        
        this.addItem(item)
    }

    private touch(e?: TouchEvent, start: boolean = false) {
        this.getTouchPos(e)

        if (start) {
            this.setDummyPoint()
        }
        
        // During a touchmove event, unlike a mousemove event, we don't need to check if the touch is engaged, 
        // since there will always be contact with the screen by definition.
        let item: IItem = {
            isDummy: false,
            x: this.touchX,
            y: this.touchY,
            c: this.app.options.color,
            r: this.app.options.size
        }

        this.addItem(item)

        // Prevent a scrolling action as a result of this touchmove triggering.
        if (!!e && !!e.preventDefault) e.preventDefault()
    }

    private touchEnd() {
        this.setDummyPoint()
    }
    
    private addItem(item: IItem) {
        this.app.history.push(item)
        this.draw(item, this.app.history.length)
    }

	private getTouchPos(e?: TouchEvent) {
        e = !!e ? e : event as TouchEvent

        if (!e.touches) return
        if (e.touches.length != 1) return // Only deal with one finger
        
        var rect = this.c.getBoundingClientRect()

        let touch = e.touches[0] // Get the information for finger #1
        this.touchX = touch.clientX - rect.left
        this.touchY = touch.clientY - rect.top
    }
	
	private setDummyPoint() {
        let item: IItem = this.dummyItem
        this.addItem(item)
	}
	
	// private drawBgDots() {
	// 	let gridSize: number = 50
	// 	this.ctx.fillStyle = 'rgba(0, 0, 0, .2)'
		
	// 	for(let i = 0; i * gridSize < this.c.width; i++) {
	// 		for(let j = 0; j * gridSize < this.c.height; j++) {
	// 			if (i <= 0 || j <= 0) continue

    //             this.ctx.beginPath()
    //             this.ctx.rect(i * gridSize, j * gridSize, 2, 2)
    //             this.ctx.fill()
    //             this.ctx.closePath()
	// 		}
	// 	}
	// }
	
	private draw(item: IItem, i: number) {
		this.ctx.lineCap = "round"
		this.ctx.lineJoin = "round"
        if (i < 2) return
        
		let prevItem:IItem = this.app.history[i - 2]
		if (!item.isDummy && !this.app.history[i - 1].isDummy && !prevItem.isDummy) {
			this.ctx.strokeStyle = item.c
			this.ctx.lineWidth = item.r
			
			this.ctx.beginPath()
			this.ctx.moveTo(prevItem.x, prevItem.y)
			this.ctx.lineTo(item.x, item.y)
			this.ctx.stroke()
			this.ctx.closePath()
		} else if (!item.isDummy) {			
			this.ctx.strokeStyle = item.c
			this.ctx.lineWidth = item.r
			
			this.ctx.beginPath()
			this.ctx.moveTo(item.x, item.y)
			this.ctx.lineTo(item.x, item.y)
			this.ctx.stroke()
			this.ctx.closePath()
		}
	}
}

export interface IDrawerOptions {
    with_body: boolean, 
    with_backbone: boolean, 
    color: string, 
    size: number
}