<div>
    <div v-if="isUserSubscribed">
		<p>Chers utilisateurs,</p>
		<p>J'espère que ce message vous trouve dans une forme éclatante et que vous profitez à fond de notre logiciel qui vous accompagne depuis si longtemps&nbsp;! Je tiens à vous informer d'une évolution importante concernant votre logiciel préféré et je vous invite à lire attentivement ce message jusqu'à la fin.</p>
		<p>Depuis <strong>plus de 20 ans</strong>, je développe avec passion votre logiciel, celui que vous avez choisi d'utiliser au quotidien pour votre activité (et je vous en suis <strong>extrêmement reconnaissant</strong>). Je suis très fier de cette aventure Osteo2ls qui <strong>rythme mes soirées</strong> et journées depuis la fin de mes études. Cependant, pour être honnête avec vous, jongler avec les mises à jour, les demandes de support et la vie personnelle devient un peu comme essayer de jongler avec des bananes, des oranges et des pastèques en même temps (et je peux vous dire que c'est certes hilarant mais il y a un risque non négligeable que tout ça finisse par terre en salade de fruit). Et quand plus de <strong>1 000 personnes</strong> dépendent de cet outil, on n'a pas spécialement envie que ça plante.</p>
		<p>Ce constat étant fait&hellip; Comment on réagit ?</p>
		<p>J'ai décidé de m'associer avec une PME locale pour étoffer l’équipe Osteo2ls. Cette association me permettra de <strong>déléguer certaines tâches</strong> et de trouver un <strong>équilibre entre ma vie professionnelle et personnelle</strong>, tout en assurant <strong>la continuité de notre service</strong>. Mais voilà, avec le modèle économique actuel, il est impossible d'engager une tierce personne (charges mensuelles importantes et inévitables etc.). C'est d'ailleurs la raison pour laquelle Osteo2ls n'a jamais été mon activité principale. Ma vision des choses, sûrement trop utopiste, m'imposait un achat avec paiement unique, mais cette vision était valable pour le jeune étudiant que j'étais il y a 20 ans, sans attache, sans contrainte. Elle l'est beaucoup moins aujourd'hui, et je ne peux plus "m'amuser" à travailler toute la nuit pour Osteo2ls et enchaîner le lendemain avec mon "vrai" travail. Il en va de <strong>ma propre santé</strong>. Alors, pour garder notre logiciel au top et moi-même en pleine forme, j'ai décidé de passer à une formule d'abonnement. Cela me permettra de m'entourer <strong>d'une équipe dynamique et passionnée</strong>, tout en m'assurant que je peux continuer à fournir un produit de qualité sans risquer de me retrouver à jongler avec des châtaignes (oui, c'est arrivé une fois et quand ça arrive, ça pique les doigts).</p>
		<p>Je sais que le changement peut parfois être un peu déconcertant, voire décevant, mais je vous promets que cette transition sera aussi douce que du chocolat fondant un jour de pluie. Vous, déjà clients, <strong>n'aurez rien à payer avant le 1er janvier 2025</strong>. Et parce que vous m'avez fait confiance, l'abonnement mensuel sera à <strong>moitié prix pour vous</strong>, soit à partir de 12 € TTC/mois pendant toute la durée de votre engagement. De plus, dans cet abonnement, <strong>les options de planning en ligne (19 € TTC/mois) et de support (29 € TTC/an) seront désormais incluses</strong>, ce qui en fait une offre très avantageuse, vous en conviendrez.</p>
		<p>Vous aurez accès à des fonctionnalités encore plus incroyables et à un support client aux petits oignons (oui, encore de la nourriture, mais cette fois-ci c'est pour l'image).</p>
		<p>Nous comprenons que ce changement puisse être brutal, c'est pourquoi vous pourrez choisir entre le 1er novembre et le 31 décembre 2024 ce que vous souhaitez faire&nbsp;: continuer l'aventure avec Osteo2ls avec cette réduction de 50 % incluant les deux options supplémentaires, ou quitter Osteo2ls. Dans ce dernier cas, vous pourrez continuer à utiliser Osteo2ls pendant un an (soit jusqu’au 31 décembre 2025), afin de vous laisser le temps nécessaire pour vous retourner.</p>
		<p>Je suis convaincu que cette association et ce nouveau modèle économique nous permettront de continuer à améliorer notre logiciel et de vous offrir un service de qualité. Sachez également que <strong>je ne quitte pas le navire, Osteo2ls reste mon "bébé"</strong> (mais ce bébé est devenu adolescent et comme beaucoup d'adolescents, il voudrait bien s'assumer tout seul, mais il n'en n'est pas capable 😉), je reste donc présent et décisionnaire sur les futures actions qui seront mises en place pour Osteo2ls. Je tiens à vous remercier du fond du cœur pour votre fidélité et votre soutien au fil des années. Vous êtes la raison pour laquelle je me lève chaque matin avec un sourire (même si parfois ce sourire ressemble un peu à un grincement après une longue nuit de codage). Bon, j'avoue, vous n'êtes pas la raison première, mais quand même, vous faites plaisir 😉</p>
		<p>N'hésitez pas à me contacter si vous avez des questions, des commentaires ou simplement envie de partager vos blagues préférées sur la nourriture.</p>
		<p>Merci encore, du plus profond de mon cœur.</p>
		<p>Avec toute ma gratitude et une tonne d'enthousiasme,</p>
		<p>
			Antoine<br>
			Le créateur (un peu fatigué mais toujours plein de vie) de Osteo2ls.
		</p>
		<p><em>P.S.&nbsp;:</em> :<br><strong>Pour les clients qui nous ont rejoints depuis moins d’un an&nbsp;:</strong></p>
		<ul>
			<li>Si vous avez acheté le pack tranquillité, l’accès à Osteo2ls et ses options planning en ligne et maintenance vous seront offerts pour une période complétant votre première année d’utilisation. Parce qu’un exemple vaut mille mots&nbsp;: si cela fait 8 mois que vous avez acheté Osteo2ls, vous bénéficierez de 4 mois offerts.</li>
			<li>Si vous avez acheté le pack tranquillité échelonné, vos mensualités baisseront considérablement pour s’aligner sur les nouveaux tarifs d’abonnement.</li>
		</ul>
	</div>
  <div v-else>
	  <p>Chers utilisateurs,</p>
	  <p>J'espère que ce message vous trouve dans une forme éclatante et que vous profitez à fond de votre espace d’essai ! Je tiens à vous informer d'une évolution importante concernant notre logiciel et je vous invite à lire attentivement ce message jusqu'à la fin.</p>
	  <p>Vous le savez peut-être déjà, Osteo2ls était jusqu’à maintenant développé et maintenu par une seule personne, moi-même. Depuis plus de 20 ans, j’ai développé ce logiciel, en passant par plusieurs versions majeures différentes, évoluant année après année.</p>
	  <p>Or, après 20 ans à m’occuper d’Osteo2ls, en parallèle d’un autre métier à plein temps, je ne peux plus en assumer la charge complète tout seul. L’équipe Osteo2ls va donc, pour le bien du logiciel et sa pérennité, <strong>s’étoffer et se renforcer</strong>.</p>
	  <p>Pour s’adapter à l’agrandissement de l’équipe, le modèle économique du logiciel va changer, pour <strong>passer du mode licence au mode abonnement</strong>.</p>
	  <p>Concrètement, qu’est-ce que cela change pour vous&nbsp? Plutôt que d’acheter une fois votre licence à 369 € TTC, vous allez pouvoir vous abonner au logiciel pour 24 € TTC par mois.</p>
	  <p>Pourquoi ce changement de modèle&nbsp?</p>
	  <p>Puisque notre équipe s’agrandit, nous allons pouvoir proposer plus de prestations à nos utilisateurs&nbsp;:</p>
	  <ul>
		  <li><strong>service d’assistance inclus&nbsp;:</strong> auparavant optionnel, il sera désormais accessible à tous dans le cadre de l’abonnement.</li>
		  <li><strong>mises à jour encore plus régulières&nbsp;:</strong> elles seront également définies selon une feuille de route que vous connaitrez.</li>
		  <li><strong>planning en ligne inclus&nbsp;:</strong> précédemment vendues à 19 € TTC par mois, il fera partie intégrante de notre nouvelle formule, afin que vous puissiez bénéficier de <strong>la totalité des fonctionnalités Osteo2ls en un seul abonnement</strong>.</li>
	  </ul>
	  <p>Pour vous aider à ce que vous soyez encore plus convaincus de notre produit, nous vous offrons <strong>deux mois d’utilisation gratuite</strong> jusqu’au 30 septembre 2024. Vous pourrez ainsi essayer le logiciel à son plein potentiel jusqu’à cette date, <strong>profitez-en&nbsp!</strong></p>
	  <p>N'hésitez pas à me contacter si vous avez des questions ou des commentaires.</p>
	  <p>Antoine, <br>Le créateur (un peu fatigué mais toujours plein de vie) de Osteo2ls.</p>
  </div>
  <br>
  <switch-frame id="switch" v-model="checked">{{ $t('vm.components.popin.communication.accept')}}</switch-frame>
</div>
