<div>
    <div v-if="isMultipleChoice">
        <label class="label" :for="question.id"><strong>{{question.title}}</strong></label>
        <checkbox-frame v-for="choice in question.choices" :key="choice.id" :id="choice.id" v-model="choice.selected">{{choice.label}}</checkbox-frame>
    </div>
	<div v-if="isSingleChoice">
		<label class="label" :for="question.id"><strong>{{question.title}}</strong></label>
		<radio-frame v-for="choice in question.choices" :key="choice.id" :id="choice.id" :name="`choice-${choice.id}`"
					 v-model="selectedChoice" :radio-value="choice.id">{{choice.label}}</radio-frame>
	</div>
    <input-frame v-if="isShortText" :label="question.title" :placeholder="question.title" :id="question.id" v-model="question.answer"/>
    <textarea-frame v-if="isLongText" :id="question.id" :label="question.title" :placeholder="question.title" v-model="question.answer"/>
</div>
