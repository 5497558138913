import Render from '@Views/root/user/office/main.html'
import { Component } from 'vue-property-decorator'
import { ASideOffice } from './aside-office'
import { ContextOffice } from './context-office'
import { ASideSchedule } from './schedule/aside-schedule'
import { AutoSaver } from '@Components/index'
import { AutoSaverState } from '@Enums/index'
import { Office } from '@Models/index'
import { IPopinAction, IPopin, IOffice, INotification } from '@Store/types'
import { breadcrumb, notif, office } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-schedule': ASideSchedule,
		'aside-office': ASideOffice,
		'context-office': ContextOffice
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	submitted: boolean = false
	item: string = ''

	constructor() {
		super('office', false)
	}

	//#region Getters
	get office(): IOffice {
		return office.office(this.$route.params.ofi_id)
	}
	get isSaving(): boolean {
		return office.isSaving
	}
	get displayAsideSchedule(): boolean {
		return this.item === 'schedule-office'
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IOffice): void {
		office.restoreOffice(value)
		office.restoreSessions()
		office.restoreSchedules()
		office.restoreSpecificSchedules()
		office.restoreSettings()
		if (!value || parseInt(value.id) !== -1) return

		office.removeOffice(value.id)
	}

	save(): void {
		if (!this.canSave()) return

		let _office:IOffice = Office.getOffice(this.office, this.oldValue)
		let notification:INotification = Office.getNotification(_office)
		const { create_id } = _office
		office.saveOffice({office: _office})
		.then((newOffice: IOffice) => {
			super.initializeValue()
			notif.success(notification)
			this.submitted = false

			let params = {ofi_id: newOffice.id}
			if (!create_id) return

			office.removeOffice(this.office.id)
			office.addOffice(newOffice)
			super.initializeValue(newOffice)

			let route = {name: this.$router.currentRoute.name, params: params}
			this.$router.replace(route)
		})
		.catch(() => {
			super.initializeValue()
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'office')
		super.initializeValue()
		if (!!this.office) return

		this.$router.push({name: 'offices'})
	}

	onNavigate(item: string): void {
		this.item = item
	}

	onDelete() {
		// Appelé quand on efface le dossier depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.oldValue = null
		this.state = AutoSaverState.None
		this.$router.push({name: 'offices'})
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.user.office.main.cancel.action').toString(),
			title: this.$i18n.t('vm.root.user.office.main.cancel.title').toString(),
			callback: () => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let office:IOffice = Office.getOffice(this.office, this.oldValue)
		let messages: string[] = Office.getUpdateList(office)
		let updates: string = messages.join("</li><li>")
		let message = !!updates ? this.$i18n.t('vm.root.user.office.main.cancel.message-details', {details: updates}).toString()
		 : this.$i18n.t('vm.root.user.office.main.cancel.message').toString()

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.user.office.main.cancel.title').toString(),
			content: message,
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	owner(): any {
		return this.office.links.find(u => { return u.id === this.office.creator_usr_id })
	}

	private cancelAction(): void {
		let ofiId = this.office.id
		super.cancel()
		office.restoreSessions()
		office.restoreSchedules()
		office.restoreSpecificSchedules()
		office.restoreSettings()
		this.submitted = false

		if (parseInt(ofiId) !== -1) return

		office.removeOffice(ofiId)
		this.$router.push({name: 'offices'})
	}

	private canSave(): boolean {
		if (!this.office) return false

		let notification: INotification | false = Office.getErrorNotification(this.office)
		if (!notification) return true

		notif.error(notification)
		return false
	}
}
