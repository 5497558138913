export const contextMain = {
    title: "Remplissage de vos informations",
    complete: "compléter",
    legends: {
        user: "Utilisateur",
        offices: "Cabinet | Cabinet | Cabinets"
    },
    'user-title': {
        title: "Titre",
        description: "Renseignez votre titre décrivant au mieux votre profession",
        'link-title': "Renseignez votre titre complet"
    },
    organizations: {
        title: "Associations",
        description: "Renseignez les différentes associations avec lesquelles vous avez pu travailler",
        'link-title': "Ajoutez vos différentes associations"
    },
    formations: {
        title: "Formations",
        description: "Indiquez les différentes formations que vous avez suivies",
        'link-title': "Ajoutez vos différentes formations"
    },
    avatar: {
        title: "Avatar",
        description: "Modifiez votre avatar pour personnaliser votre page",
        'link-title': "Modifiez votre avarar"
    }
}