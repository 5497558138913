import Render from '@Views/root/user/office/schedule/main.html'
import { isEqual } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ScheduleElement } from '@Components/index'
import { IBreadcrumbItem, IOffice, ISchedule, ILinkUser } from '@Store/types'
import { account, breadcrumb, office } from '@Store/modules'

@Render
@Component({
	components: {
		'schedule': ScheduleElement
	}
})
export class Main extends Vue {

	@Prop() office: IOffice

	//#region Getters
	get schedule(): ISchedule {
		return office.schedule(this.office.id)
	}
	get links(): ILinkUser[] {
		return account.activeAccount(this.office.id)
	}
	private get oldSchedule(): ISchedule {
		return office.oldSchedule(this.office.id)
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'schedule-office')
		this.initializeBreadcrumb()
	}

	@Watch('schedule', {deep: true})
	updateSchedule() {
		if (!this.schedule) return

		// On retire les horaires en cours si finalement ils sont restaurés
		this.office.schedules.currents = []

		if (this.oldSchedule.id !== this.schedule.id || isEqual(this.oldSchedule.days, this.schedule.days)) return

		this.schedule['update'] = true
		this.office.schedules.currents.push(this.schedule)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.schedule').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}
