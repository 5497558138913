<div class="form-group">
	<div class="with-icon">
		<svg width="1em" class="with-icon-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#key"/>
		</svg>
		<input :type="show ? 'text' : 'password'" class="form-group-input" :value="password" :id="inputId" :ref="inputId" :title="title"
			:placeholder="placeholder" :disabled="disabled" @input="onInput($event.target.value)" :required="required">
	</div>
	<button type="button" class="input-like form-group-input is-flex-fixed misc-trigger" :title="show ? 'Masquer le mot de passe' : 'Voir le mot de passe'" @click="show = !show">
		<svg v-if="show" width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 640 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#eye-slash"/>
		</svg>
		<svg v-else width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#eye"/>
		</svg>
	</button>
</div>
