export const aside = {
    back: {
        title: "Retournez à la liste de vos cabinets",
        label: "Retour aux cabinets"
    },
    coordinates: {
        title: "Mettez à jour les coordonnées de votre cabinet",
		label: "Coordonnées"
    },
    sessions: {
        title: "Modifiez les types de consultation dans votre cabinet",
        label: "Type de consultation | Type de consultation | Types de consultation"
    },
    schedule: {
        title: "Mettez à jour les horaires de votre cabinet",
        label: "Horaires"
    },
    accesses: {
        title: "Modifiez les différents moyens d'accès à votre cabinet",
		label: "Accès | Accès | Accès"
    },
    absence: {
        title: "Indiquez vos dates d'absence / vacances", 
        label: "Absence / Congés"
    },
    parameters: {
        title: "Mettez à jour les paramètres de votre planning en ligne",
        label: "Page web"
    },
    disabled: {
        'delete-msg': "Voulez-vous vraiment désactiver ce cabinet ?",
        'delete-label': "désactiver", 
        title: "Désactivation",
        label: "Désactiver ce cabinet"
    }
}