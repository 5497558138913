export const aside = {
    back: {
        title: "Retournez à vos plannings",
        label: "Retour aux plannings"
    },
	'duplicate': {
		failed: "Impossible de dupliquer ce rendez-vous.",
		succeeded: "Le rendez-vous dupliqué a bien été créé.",
		'action-label': "ouvrir ce nouveau rendez-vous",
		'title-popin': "Duplication de ce rendez-vous",
		title: "Dupliquer ce rendez-vous",
		label: "dupliquer",
		'label-aside': "Dupliquer ce RDV"
	},
	'rendez-vous': {
        title: "Modifiez les informations de votre rendez-vous",
        label: "Horaire et lieu"
    },
    recurring: {
        title: "Créez une récurrence pour votre rendez-vous",
        label: "Récurrence"
    },
    relaunch: {
        title: "Relancez votre patient à titre préventif",
        label: "Relance"
    },
    delete: {
        confirm: "Voulez-vous vraiment supprimer ce rendez-vous ?",
        label: "Supprimer ce RDV"
    },
    cancel: {
        'action-title': "Annulation",
        'action-label': "confirmer",
        confirm: "Cette annulation sera comptabilisée dans vos statistiques.<br>Confirmez-vous que le patient associé à ce rendez-vous souhaite l'annuler ?",
        label: "Annulation du patient"
    }
}
