import Render from '@Views/root/search/patient/item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { isEqual, cloneDeep } from 'lodash'
import {IPatient, IPopinAction, IPopin, IEvent} from '@Store/types'
import { Gender } from '@Enums/index'
import { event, notif, popIn, user } from '@Store/modules'

@Render
@Component({})
export class PatientItem extends Vue {

	@Prop() patient: IPatient
	@Prop() event: IEvent
	@Prop() readOnly: boolean
	@Prop() query: string
	@Prop({default: false}) popinToUpdate: boolean
	@Prop() pathToOpen: {name: string, params: any}
	@Prop() unselect: () => void
	@Prop({default: false}) withoutOpen: boolean
	@Prop({default: false}) withoutUnselect: boolean

	//#region Getters
	get isNewPatient(): boolean {
		return !this.patient || parseInt(this.patient.id) === -1 || this.patient.is_new
	}
	get last_name(): string {
		return (this.patient && this.patient.coordinates && this.patient.coordinates.last_name) || ''
	}
	get first_name(): string {
		return (this.patient && this.patient.coordinates && this.patient.coordinates.first_name) || ''
	}
	get gender(): Gender {
		return (this.patient && this.patient.coordinates && this.patient.coordinates.gender) || Gender.Man
	}
	get phone(): string {
		return (this.patient && this.patient.coordinates && this.patient.coordinates.phone1.number) || ''
	}
	get email(): string {
		return (this.patient && this.patient.coordinates && this.patient.coordinates.email) || ''
	}
	get blacklisted(): boolean {
		return this.patient && this.patient.blacklisted
	}
	get deceased(): boolean {
		return this.patient && this.patient.deceased
	}
	//#endregion

	open(): void {
		if (this.popinToUpdate) {
			this.createPatient()
		} else if (!!this.pathToOpen) {
			this.$router.push(this.pathToOpen)
		} else if (user.isMainUser) {
			this.$router.push({name: 'patient-coordinates', params: {pat_id: this.patient.id}})
		} else {
			this.createPatient()
		}
	}

	createPatient(): void {
		let addAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.patient.search.update-popin.label').toString(),
			title: this.$i18n.t('vm.root.search.patient.search.update-popin.title').toString(),
			callback: (data: {patient: IPatient}) => {
				let validData: boolean = !!data.patient.coordinates.last_name && !!data.patient.coordinates.first_name
				if (!validData) return Promise.reject()

				data.patient.coordinates['update'] = !isEqual(data.patient.coordinates, this.patient.coordinates)

				event.addPatient({patient: data.patient, event: this.event})
				notif.clear()

				return Promise.resolve()
			}
		}
		let patient: IPatient = cloneDeep(this.patient)
		let add: IPopin = {
			title: this.$i18n.t('vm.root.search.patient.search.update-popin.title').toString(),
			content: {component: 'popin-create-patient'},
			action: addAction,
			options: {patient: patient},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(add)
	}
}
