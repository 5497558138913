export const top10Main = {
    title: "Tops 10",
    'missed-title': "Rendez-vous manqués",
    'missed-column': "RDV manqués",
    'cancelled-title': "Rendez-vous annulés",
    'cancelled-column': "RDV annulés",
    'consultation-title': "Patients les plus consultés",
    'consultation-column': "Consultations",
    loading: "Chargement en cours…",
    'patient-column': "Patient",
    first: "Premier",
    second: "Deuxième",
    third: "Troisième",
}