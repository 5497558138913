import Render from '@Views/root/calendar/event/main.html'
import {Component, Watch} from 'vue-property-decorator'
import { GoogleService } from '@Services/index'
import { ContextEvent } from './context-event'
import { ASideEvent } from './aside-event'
import { AutoSaverState, Profile, PlanningType, ReminderType } from '@Enums/index'
import { AutoSaver } from '@Components/index'
import { Patient, Recurring, Relaunch, User } from '@Models/index'
import {DateHelper} from '@Helpers/index'
import { INotification, IPopinAction, IPopin, IPatient, IPlanning, IOffice, ISession, IUser, IEvent } from '@Store/types'
import {event, notif, office, patient, planning, user} from '@Store/modules'

@Render
@Component({
	components: {
		'aside-event': ASideEvent,
		'context-event': ContextEvent
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	},
	beforeRouteUpdate(to, from, next) {
		next()
		if (to.params.evt_id === from.params.evt_id) return

		this.initializeEvent()
	}
})
export class Main extends AutoSaver {

	saved: boolean = false
	errors: {name: string, inError: boolean}[] = []
	private service: GoogleService = null
	private authorized:boolean = false

	constructor() {
		super('event', false)
	}

	//#region Getters
	get event(): IEvent {
		return event.event
	}
	get patient(): IPatient {
		return this.event.patient || Patient.createNewPatient(super.configuration)
	}
	get isNewEvent(): boolean {
		return !this.event || parseInt(this.event.id) === -1
	}
	get prefix(): string {
		if (!!this.$route.params.date)
			return 'new-'
		if (!!this.$route.params.pla_id && !!this.$route.params.evt_id)
			return 'google-'

		return ''
	}
	get date(): string {
		return DateHelper.getDateString(!this.event ? undefined : new Date(this.event.start_date))
	}
	get isSaving(): boolean {
		return event.isSaving
	}
	get isDeleting(): boolean {
		return event.isDeleting
	}
	get selectedUser(): IUser {
		return user.selectedPlanningUser
	}
	get isRemind(): boolean {
		return this.reminderSms || this.reminderMail
	}
	get reminderSms(): boolean {
		return this.event.reminder_sms
	}
	get reminderMail(): boolean {
		return this.event.reminder_mail
	}
	get owner(): string {
		let owner: string = patient.owner(this.event)
		if (!owner) owner = this.$i18n.t('vm.root.calendar.event.main.yourself').toString()
		return this.$i18n.t('vm.root.calendar.event.main.by', {owner: owner}).toString()
	}
	get online(): boolean {
		return !!this.event && !!this.event.customer
	}
	private get user(): IUser {
		return user.user
	}
	private get selectedOffice(): IOffice {
		return office.selectedPlanningOffice
	}
	private get isGoogleEvent(): boolean {
		return !!this.$route.params.pla_id && !!this.$route.params.evt_id
	}
	private get currentType(): PlanningType {
		const { pla_id } = this.event
		return planning.planningType(pla_id)
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IEvent): void {
		if (this.isGoogleEvent) {
			event.eventGoogleSuccess(value)
		} else {
			event.eventSuccess(value)
		}
	}

	save(): void {
		if (!this.canSave()) return

		this.event.type = this.currentType
		let key: string = parseInt(this.event.id) === -1 ? 'vm.root.calendar.event.main.create' : 'vm.root.calendar.event.main.update'
		let message: string = this.$i18n.t(key, {day: this.$i18n.d(new Date(this.event.start_date), 'LL'), hour: this.$i18n.d(new Date(this.event.start_date), 'LT')}).toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
		event.saveEvent({event: this.event})
		.then(() => {
			super.initializeValue()

			notif.success(notification)
			this.$router.push({name: 'plannings', params: {date: this.date}})
		})
	}
	//#endregion

	mounted() {
		this.service = GoogleService.getInstance()
		this.authorized = this.service.authorized
		this.service.$on('authorized-updated', () => {
			this.authorized = this.service.authorized
		})

		if (this.isGoogleEvent) {
			this.initializeGoogleEvent()
		} else {
			this.initializeEvent()
		}
	}

	onDelete() {
		// Appelé quand on efface le rdv depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.event.main.cancel.action-label').toString(),
			title: this.$i18n.t('vm.root.calendar.event.main.cancel.action-title').toString(),
			callback: (data: any) => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.calendar.event.main.cancel.action-title').toString(),
			content: this.$i18n.t('vm.root.calendar.event.main.cancel.content').toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	cancelAction(): void {
		super.cancel()
		this.$router.push({name: 'plannings', params: {date: this.date}})
	}

	submitSave(): void {
		this.saved = true
		this.save()
	}

	@Watch('authorized')
	initializeGooglePlannings() {
		planning.initializeGooglePlannings(this.authorized)
	}

	//#region private methods
	private canSave(): boolean {
		let errors = this.errors.filter(error => !!error.inError)
		let inError = !!errors && errors.length > 0
		if (inError) {
			this.$router.push({name: errors[0].name, params: this.$route.params})
		}
		const { event } = this
		return !!event && !inError
	}

	private checkSelectedUser(): void {
		if (!user.isSecretaryUser || !!user.selectedPlanningUser) return

		if (!!this.event.usr_id) {
			let users = user.linkedUsers(Profile.Main)
			let _user = users.find(user => user.id === this.event.usr_id)
			if (!!_user) {
				planning.toggleVisibilityByUser(_user.id)
				user.selectLinkPlanningUser(User.linkUserToUser(_user))
				return
			}
		}

		this.$router.push({name: 'plannings', params:{date: this.date}})
	}

	private getPlannings(): IPlanning[] {
		if (!user.isSecretaryUser)
		return planning.editablePlannings

		if (!this.selectedUser) return []
		return planning.editablePlannings.filter(p => { return p.usr_id === this.selectedUser.id})
	}

	private createNewEvent(): IEvent {
		let reminderType: ReminderType = !!super.configuration.reminder_type ? super.configuration.reminder_type : ReminderType.Both
		let confirmationType: ReminderType = !!super.configuration.confirmation_type ? super.configuration.confirmation_type : ReminderType.Both
		let ofi_id: string = null
		if (!!this.selectedOffice && parseInt(this.selectedOffice.id) !== -1) {
			ofi_id = this.selectedOffice.id
		} else {
			let offices: IOffice[] = office.offices.filter(o => { return office.isActive(o) })
			let _office = (!!offices && offices.length > 0) ? offices[0] : null
			ofi_id = !!_office ? _office.id : null
		}

		let sessions:ISession[] = office.sessions(ofi_id)
		let session: ISession = (!!sessions && sessions.length > 0) ? sessions[0] : null

		let duration: number = !!session ? session.duration : 45
		let with_color: boolean = !!session ? session.with_color : false
		let color: string = !!session ? session.color : ''
		let date: string = this.$route.params.date
		let hour: string = this.$route.params.hour || '00:00'
		let allDay: boolean = this.$route.params.hour === undefined

		let dateValue: string[] = date === undefined ? [] : date.split('-') // ["2020", "10", "13"]
		let hourValue: string[] = hour.split(':') // ["15", "56"]

		let startDate: Date = date === undefined ? new Date() : new Date(parseInt(dateValue[0]), parseInt(dateValue[1]) - 1, parseInt(dateValue[2]), parseInt(hourValue[0]), parseInt(hourValue[1]), 0)
		let endDate: Date = new Date(startDate.getTime() + duration * 60000)

		let plannings: IPlanning[] = this.getPlannings()

		let planning = plannings.length > 0 ? plannings[0] : null
		let plaId = planning === null ? null : planning.id

		let event: IEvent = {
			id: '-1',
			label: "",
			missed: false,
			unvalid: false,
			color: color,
			with_color: with_color,
			start_date: startDate,
			end_date: endDate,
			all_day: allDay,
			ofi_id: !!ofi_id ? ofi_id : '-1',
			ses_id: !!session ? session.id : '-1',
			pla_id: plaId,
			old_pla_id: plaId,
			from_rec_id: null,
			confirmation_type: confirmationType,
			reminder_type: reminderType,
			reminder_mail: false,
			reminder_sms: false,
			relaunch: Relaunch.createNewRelaunch(super.configuration),
			recurring: Recurring.createNewRecurring(startDate),
			recurring_update: false,
			type: PlanningType.Classic,
			old_type: PlanningType.Classic,
			patient: null,
			usr_id: user.isSecretaryUser ? (!!this.selectedUser ? this.selectedUser.id : '-1') : this.user.id
		}

		return event
	}

	private initializeEvent(): void {
		let payload = {evt_id: this.$route.params.evt_id}
		if (payload.evt_id === undefined) {
			this.restoreValue(this.createNewEvent())
			this.initializeValue()
		} else if (!event.isLoaded || !event.event || event.event.id !== payload.evt_id) {
			event.loadEvent(payload)
			.then(() => {
				this.initializeValue()
			})
		} else {
			this.initializeValue()
		}
	}

	protected initializeValue() {
		super.initializeValue()
		this.checkSelectedUser()
	}

	private initializeGoogleEvent(): void {
		if (!this.service.authorized) return

		let payload = {evt_id: this.$route.params.evt_id, pla_id: this.$route.params.pla_id}
		if (payload.evt_id === undefined) return
		//ici ça plante après avoir modifié un event directement dans le planning par d'n'd
		if (event.isGoogleLoaded && !!event.event && event.event.id === payload.evt_id) {
			super.initializeValue()
			return
		}

		event.loadGoogleEvent(payload)
		.then(() => { super.initializeValue()})
	}
	//#endregion
}
