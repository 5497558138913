import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IPatient, IEvent, IColumnSlot, EventLoadPayload, EventActionPayload, EventSearchPayload, EventRefusePayload, IPlanning, FreeSlotPayload } from "@Store/types"
import { configuration, planning } from "@Store/modules"
import { PlanningType, RequestStatus } from "@Enums/index"
import { EventService, GoogleService } from "@Services/index"
import { EventHelper, GoogleEventHelper, ISearchEvent } from "@Helpers/index"

@Module({
	name: 'event',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class EventModule extends VuexModule {
	status: RequestStatus = RequestStatus.None
    statusSearching: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusDeleting: RequestStatus = RequestStatus.None
    statusCancelling: RequestStatus = RequestStatus.None
    statusGoogle: RequestStatus = RequestStatus.None
    statusLoadingSlot: RequestStatus = RequestStatus.None
    statusConfirming: RequestStatus = RequestStatus.None
    statusRefusing: RequestStatus = RequestStatus.None
    event: IEvent = null
	slots: IColumnSlot[][] = []

	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isCancelling(): boolean {
		return this.statusCancelling === RequestStatus.Loading
	}
	get isGoogleLoaded(): boolean {
		return this.statusGoogle === RequestStatus.Success
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isLoadingSlot(): boolean {
		return this.statusLoadingSlot === RequestStatus.Loading
	}
	get isRefusing(): boolean {
		return this.statusRefusing === RequestStatus.Loading
	}
	get isConfirming(): boolean {
		return this.statusConfirming === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSearching = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusCancelling = RequestStatus.None
		this.statusGoogle = RequestStatus.None
		this.statusLoadingSlot = RequestStatus.None
		this.statusConfirming = RequestStatus.None
		this.statusRefusing = RequestStatus.None
		this.event = null
		this.slots = []
	}

	@Mutation
  	eventRequest() {
		this.status = RequestStatus.Loading
		this.event = null
	}

	@Mutation
  	eventSuccess(event: IEvent) {
		this.status = RequestStatus.Success
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.event = event
	}

	@Mutation
  	eventFailure() {
		this.status = RequestStatus.Failed
		this.event = null
	}

	@Mutation
  	eventGoogleRequest() {
		this.statusGoogle = RequestStatus.Loading
		this.event = null
	}

	@Mutation
  	eventGoogleSuccess(event: IEvent) {
		this.statusGoogle = RequestStatus.Success
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.event = event
	}

	@Mutation
  	eventGoogleFailure() {
		this.statusGoogle = RequestStatus.Failed
		this.event = null
	}

	@Mutation
  	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
  	saveSuccess(event: IEvent) {
		this.statusSaving = RequestStatus.Success
		this.event = event
	}

	@Mutation
  	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
  	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
  	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
  	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
  	cancelRequest() {
		this.statusCancelling = RequestStatus.Loading
	}

	@Mutation
  	cancelSuccess() {
		this.statusCancelling = RequestStatus.Success
	}

	@Mutation
  	cancelFailure() {
		this.statusCancelling = RequestStatus.Failed
	}

	@Mutation
  	searchRequest() {
		this.statusSearching = RequestStatus.Loading
	}

	@Mutation
  	searchSuccess() {
		this.statusSearching = RequestStatus.Success
	}

	@Mutation
  	searchFailure() {
		this.statusSearching = RequestStatus.Failed
	}

	@Mutation
  	loadingSlotRequest() {
		this.statusLoadingSlot = RequestStatus.Loading
		this.slots = []
	}

	@Mutation
  	loadingSlotSuccess(slots) {
		this.statusLoadingSlot = RequestStatus.Success
		this.slots = slots
	}

	@Mutation
  	loadingSlotFailure() {
		this.statusLoadingSlot = RequestStatus.Failed
		this.slots = []
	}

	@Mutation
  	confirmRequest() {
		this.statusConfirming = RequestStatus.Loading
	}

	@Mutation
  	confirmSuccess() {
		this.statusConfirming = RequestStatus.Success
	}

	@Mutation
  	confirmFailure() {
		this.statusConfirming = RequestStatus.Failed
	}

	@Mutation
  	refuseRequest() {
		this.statusRefusing = RequestStatus.Loading
	}

	@Mutation
  	refuseSuccess() {
		this.statusRefusing = RequestStatus.Success
	}

	@Mutation
  	refuseFailure() {
		this.statusRefusing = RequestStatus.Failed
	}

	@Action
	addPatient(payload: {patient: IPatient, event?: IEvent}): void {
		if (!payload.patient) return

		payload.patient.coordinates.without_birth_date = !payload.patient.coordinates.birth_date
		if (!!payload.event) payload.event.patient = payload.patient
		else this.event.patient = payload.patient
	}

	@Action
	unselectPatient(): void {
		this.event.patient = undefined
	}

	@Action({rawError: true})
	async loadEvent(payload: EventLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.eventRequest()

		let service = new EventService()
		return service.getEvent(payload.evt_id)
		.then(response => {
			let event = EventHelper.initializeEvent(response, configuration.configuration)
			this.eventSuccess(event)
			return Promise.resolve(event)
		})
		.catch(error => {
			this.eventFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadGoogleEvent(payload: EventLoadPayload): Promise<any> {
		if (this.statusGoogle === RequestStatus.Loading) return

		const {evt_id, pla_id} = payload
		this.eventGoogleRequest()

		let service = GoogleService.getInstance()
		return service.getEvent(evt_id, pla_id)
		.then(response => {
			let event = EventHelper.convertGoogleItemToEvent(response, pla_id)
			this.eventGoogleSuccess(event)
			return Promise.resolve(event)
		})
		.catch(error => {
			this.eventGoogleFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveEvent(payload: EventActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		const {event} = payload
		this.saveRequest()

		let copy: IEvent = {...event}

		if (event.type != event.old_type) {
			await this.deleteEvent({event: event, withoutNotification: true})
			copy.id = '-1'
		}

		if (copy.type === PlanningType.Classic) {
			let service = new EventService()
			return service.save(copy)
			.then(newEvent => {
				newEvent = EventHelper.initializeEvent(newEvent, configuration.configuration)

				this.saveSuccess(newEvent)
				this.eventSuccess(newEvent)
				return Promise.resolve(newEvent)
			})
			.catch(error => {
				this.saveFailure()
				return Promise.reject(error)
			})
		} else {
			// on doit gérer la création du patient et l'envoi vers l'api osteo2ls
			let service = GoogleService.getInstance()
			return service.save(copy)
			.then(response => {
				let newEvent = EventHelper.convertGoogleItemToEvent(response.result, copy.pla_id)
				let eventService = new EventService()
				eventService.sendGoogleList([newEvent])
				this.saveSuccess(newEvent)
				this.eventGoogleSuccess(newEvent)
				return Promise.resolve(newEvent)
			})
			.catch(error => {
				this.saveFailure()
				return Promise.reject(error)
			})
		}
	}

	@Action({rawError: true})
	async deleteEvent(payload: EventActionPayload): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		const {event, withoutNotification} = payload
		if (!event.id || parseInt(event.id) === -1) return

		if (!withoutNotification) this.deleteRequest()

		if (event.old_type === PlanningType.Classic) {
			let service = new EventService()
			return service.delete(event.id)
			.then(
				() => {
					if (!withoutNotification) {
						this.deleteSuccess()
					}
					return Promise.resolve()
				}
			)
			.catch(error => {
				this.deleteFailure()
				return Promise.reject(error)
			})
		} else {
			let service = GoogleService.getInstance()
			return service.delete(event)
			.then(() => {
				if (!withoutNotification) {
					this.deleteSuccess()
				}
				let eventService = new EventService()
				eventService.deleteGoogle(event)

				return Promise.resolve()
			})
			.catch(error => {
				this.deleteFailure()
				return Promise.reject(error)
			})
		}
	}

	@Action({rawError: true})
	async cancelEvent(payload: EventActionPayload): Promise<any> {
		if (this.statusCancelling === RequestStatus.Loading) return

		this.cancelRequest()

		const {event} = payload
		if (!event.id || parseInt(event.id) === -1) return

		let service = new EventService()
		return service.cancel(event)
		.then(
			() => {
				this.cancelSuccess()
				return Promise.resolve()
			}
		)
		.catch(error => {
			this.cancelFailure()
			return Promise.reject(error)
		})
	}

	@Action
	async searchEvents(payload: EventSearchPayload): Promise<any> {
		if (this.statusSearching === RequestStatus.Loading) return

		const {query, filter} = payload
		this.searchRequest()

		let googlePlannings: IPlanning[] = planning.googlePlannings
		let queries = []

		let eventService = new EventService()
		queries.push(eventService.searchEvent(query, filter))
		for(let planning of googlePlannings) {
			if (!planning.visible || !planning.editable) continue
			queries.push(GoogleService.getInstance().searchEvent(query, planning.id))
		}

		return Promise.all(queries)
		.then((response_list) => {
			let results = []
			for (let list of response_list) {
				for (let event of list) {
					if (EventHelper.isGoogleEvent(event)) {
						let item = GoogleEventHelper.convertResultSearch(event)
						if (item === undefined) continue
						results.push(item)
					} else {
						results.push(event)
					}
				}
			}
			results = results.sort((a: ISearchEvent, b: ISearchEvent) => {
				return b.date.getTime() - a.date.getTime()
			})

			this.searchSuccess()
			return Promise.resolve(results)
		})
		.catch(error => {
			this.searchFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async confirmEvent(payload: EventActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		const {event} = payload
		this.confirmRequest()

		let service = new EventService()
		return service.confirm(event)
		.then(() => {
			this.confirmSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.confirmFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async refuseEvent(payload: EventRefusePayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		const {evt_id} = payload
		const {refuse} = payload
		this.refuseRequest()

		let service = new EventService()
		return service.refuse(evt_id, refuse)
		.then(() => {
			this.refuseSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.refuseFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadFreeSlot(payload: FreeSlotPayload): Promise<any> {
		if (this.statusLoadingSlot === RequestStatus.Loading) return

		this.loadingSlotRequest()

		let eventService = new EventService()

		return eventService.getFreeSlot(payload)
		.then(slots => {
			this.loadingSlotSuccess(slots)
			return Promise.resolve(slots)
		})
		.catch(error => {
			this.loadingSlotFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async nextFreeSlot(payload: FreeSlotPayload): Promise<any> {
		if (this.statusLoadingSlot === RequestStatus.Loading) return

		this.loadingSlotRequest()

		let eventService = new EventService()

		return eventService.getNextFreeSlot(payload)
		.then(result => {
			this.loadingSlotSuccess(result.slots)
			return Promise.resolve(result)
		})
		.catch(error => {
			this.loadingSlotFailure()
			return Promise.reject(error)
		})
	}
}

export const event = getModule(EventModule)
// export const event = new EventModule({ store, name: "event" })
