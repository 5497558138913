<div class="wrapper marg-v">
    <collapsible-help id="campaign-information-1" tag="h1"
		:title="$t('vm.root.patients.campaign.step-1.title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                <i18n path="vm.root.patients.campaign.step-1.help" tag="span">
                    <strong>{{ $t('vm.root.patients.campaign.step-1.strong') }}</strong>
                </i18n>
            </p>
		</div>
    </collapsible-help>

    <div class="notice-warning marg-v">
        <p class="text-left">
            <svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
            </svg>
            <i18n path="vm.root.patients.campaign.step-1.warning.text1" tag="span">
                <strong>{{ $t('vm.root.patients.campaign.step-1.warning.strong') }}</strong>
            </i18n>
        </p>
        <i18n path="vm.root.patients.campaign.step-1.warning.text2" tag="p" class="text-left">
            <abbr :title="$t('vm.root.patients.campaign.step-1.warning.abbr-title')">{{ $t('vm.root.patients.campaign.step-1.warning.abbr-label') }}</abbr>
            <strong>{{ $t('vm.root.patients.campaign.step-1.warning.strong1') }}</strong>
            <strong>{{ $t('vm.root.patients.campaign.step-1.warning.strong2') }}</strong>
        </i18n>
    </div>

    <div class="grid-3">
        <input-frame id="title" v-model="campaign.title"
            :label="$t('vm.root.patients.campaign.step-1.input.title.label')"
            :placeholder="$t('vm.root.patients.campaign.step-1.input.title.placeholder')"
            :error-test="hasTitleError" :error-msg="$t('vm.root.patients.campaign.step-1.input.title.error-msg')"
            :title="$t('vm.root.patients.campaign.step-1.input.title.title')"/>

        <input-date-frame id="sending-date" v-model="campaign.sending_date" :type="dateType" :invalid="invalidDate"
            :label="$t('vm.root.patients.campaign.step-1.input.date.label')"
            :title="$t('vm.root.patients.campaign.step-1.input.date.title')"
            :error-test="hasDateError" :error-msg="dateError"/>

        <input-frame id="sms-count" v-model="campaign.sms_count" :readonly="true"
            :label="$t('vm.root.patients.campaign.step-1.input.sms-count.label')"
            :title="$t('vm.root.patients.campaign.step-1.input.sms-count.title')"/>

        <textarea-frame id="message" class="grid-item-full" v-model="campaign.message" rows="5"
            :label="$tc('vm.root.patients.campaign.step-1.input.message.label', messageLength)"
            :error-test="hasMessageError" :error-msg="messageError"
            :placeholder="$t('vm.root.patients.campaign.step-1.input.message.placeholder')"
            :title="$t('vm.root.patients.campaign.step-1.input.message.title')"/>
    </div>

    <control-bar class="text-right">
        <button-action btn-class="btn btn-primary" @click.native="next" :title="$t('vm.root.patients.campaign.step-1.next.title')">
            {{ $t('vm.root.patients.campaign.step-1.next.label') }}
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
            </svg>
        </button-action>
    </control-bar>

</div>
