export const coordinates = {
    'read-only': {
        anonymous: "La modification des informations générales n'est pas possible en mode anonyme",
        'is-child': "est un petit garçon | est une petite fille | est un individu",
        'is-teenager': "est un adolescent | est une adolescente | est un individu",
        'is-gender': "est un homme | est une femme | est un individu",
        'is-none': "est de sexe masculin | est de sexe féminin | est ce qu'on veut",
        'born-the': "né le | née le | né le",
        'of': "de",
        'parent': "Il est le père | Elle est la mère | C'est le responsable ",
        'no-parent': "Il n'est le père d'aucun enfant | Elle n'est la mère d'aucun enfant | Ce n'est le responsable d'aucun enfant",
        'number-of-children': "d'aucun enfant | de {count} enfant | de {count} enfants",
        'his-nir': "Son numéro de sécurité sociale est le",
        'no-nir': "Son numéro de sécurité sociale n'est pas renseigné",
        'can-contact': "Vous pouvez le contacter par : | Vous pouvez la contacter par : | Vous pouvez le contacter par :",
        'by-phone': "téléphone, au",
        'by-email': "email, à l'adresse",
        call: "Appeler {name}",
        plan: "Voir le plan",
        'send-email': "Envoyer un email à {name}",
        'no-contact': "Aucun moyen de contact renseigné.",
        'address': "Son adresse postale est {0}.",
        'no-address': "Son adresse postale n'est pas renseignée.",
        'last-consultation': "Sa dernière consultation date du {0} soit {1}.",
        'reference': "Il vous a connu par le biais de | Elle vous a connu par le biais de | Il vous a connu par le biais de ",
        'reference-female': "Il vous a connue par le biais de | Elle vous a connue par le biais de | Il vous a connue par le biais de ",
        'rabbits': "Son dernier lapin {0} date d'{1}, c'était son {2}.",
        'rabbit-count': "er|er|ème",
        'unpaids': {
            'popin-title': "Factures impayées",
            'popin-action': "consulter",
            'text': "Toutes ses factures sont réglées. | Il reste {count} facture non réglée : | Il reste {count} factures non réglées : ",
            'title': "Les factures impayées de ce patient",
            'label': 'détail'
        },
        'follow-up': {
            'text': "| Il y a {count} suivi de consultation non lu : | Il y a {count} suivis de consultation non lus : ",
            'title': "Le dernier suivi de consultation non lu",
            'label': '|le consulter|consulter le dernier'
        },
        'stat-consultations': "consultation | consultation | consultations",
        'stat-important-antecedents': "antécédent important |antécédent important | antécédents importants",
        'stat-important-spheres': "sphère importante | sphère importante | sphères importantes",
    },
    'check-duplicate': {
        action: "ouvrir cet autre dossier",
        message: "Il existe déjà un dossier pour ce patient. Souhaitez-vous l'ouvrir ?"
    },
    title: "Informations générales",
    'first-name': {
        label: "Prénom du patient" ,
        placeholder: "ex : Serge",
        error: "Merci de renseigner le prénom de votre patient",
        title: "Veuillez renseigner le prénom de votre patient"
    },
    'last-name': {
        label: "Nom du patient" ,
        placeholder: "ex : Karamazov",
        error: "Merci de renseigner le nom de votre patient",
        title: "Veuillez renseigner le nom de votre patient"
    },
    'birth-date': {
        label: "Date de naissance",
        title: "Veuillez renseigner la date de naissance de votre patient",
        placeholder: "ex : Le 30 Février 2015",
        error: "Merci de renseigner la date de naissance de votre patient"
    },
    nir: {
        label: "Numéro de sécurité sociale" ,
        error: "Merci de renseigner un numéro de sécurité social valide",
        title: "Veuillez renseigner le numéro de sécurité sociale de votre patient"
    },
    phone1: {
        label: "Numéro de téléphone 1" ,
        error: "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le numéro de téléphone du patient"
    },
    phone2: {
        label: "Numéro de téléphone 2" ,
        error: "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le numéro de téléphone du patient"
    },
    gender: {
        label: "Sexe du patient",
        title: "Veuillez renseigner le genre du patient",
        man: "homme",
        woman: "femme",
        other: "autre"
    },
    email: {
        label: "Adresse email" ,
        placeholder: "ex : serge@karamazov.com",
        title: "Veuillez renseigner l'adresse email du patient"
    },
    address: {
        label: "Adresse du patient",
        title: "Veuillez renseigner l'adresse postale du patient"
    },
    profession: {
        label: "Profession du patient" ,
        'no-options-label': "tapez pour rechercher une profession…",
        placeholder: "ex : Agent de sécurité",
        title: "Veuillez renseigner la profession de votre patient"
    },
    reference: {
        label: "Comment ce patient vous a connu ?" ,
        'no-options-label': "tapez pour rechercher une référence…",
        placeholder: "ex : osteordv.fr",
        title: "Veuillez renseigner la manière dont votre patient vous a connu"
    },
    situation: {
        label: "Situation familiale" ,
        title: "Veuillez renseigner la situation familiale du patient"
    },
    'number-of-children': {
        none: "non renseigné",
        item: "pas d'enfant|{count} enfant|{count} enfants",
        label: "Nombre d'enfant" ,
        placeholder: "ex : 1",
        title: "Veuillez renseigner le nombre d'enfant de votre patient"
    },
    level: {
        label: "Niveau d'importance des notes",
        title: "Veuillez renseigner le niveau d'importance des notes"
    },
    notes: {
        label: "Notes" ,
        placeholder: "ex : patient sympathique" ,
        title: "Veuillez renseigner les notes concernant ce patient"
    },
    habitus: {
        label: "Habitudes & antécédents",
        placeholder: "ex : Ligaments croisés antérieurs droits",
        title: "Veuillez renseigner les habitudes et les antécédents du patient"
    },
    subtitle: "Références",
    rgpd: {
        text: "Depuis le 25 mai 2018, le {0} permet à votre patient d'avoir un droit de regard sur ses données. Vous devez donc {1} avec d'autres prestataires de santé de votre cabinet ou d'ailleurs. Si le partage n'est pas autorisé, et que vous êtes plusieurs à travailler dans ce cabinet, vous serez le seul à avoir accès à ce dossier. Pour vos collègues, il faudra répondre à une question concernant le patient, donc en sa présence.",
        abbr: {
            title: "Règlement Général sur la Protection des Données",
            label: "R.G.P.D.",
        },
        strong: "demander à votre patient s'il autorise ou non le partage de son dossier",
        'allow-access': {
            title: "Autoriser le partage du dossier",
            label: "Le patient autorise le partage de son dossier"
        }
    }
}
