export const main = {
    patient: 'patient | patient | patients',
    'blacklisted-patient': 'patient blacklisté | patient blacklisté | patients blacklistés',
    'new-patient-this-month': 'nouveau patient ce mois-ci | nouveau patient ce mois-ci | nouveaux patients ce mois-ci',
    'new-patient': {
        action: 'Nouveau patient',
        title: 'Créer un nouveau dossier patient',
    },
    'listing-export': {
        action: 'Listing / Exportation',
        title: 'Lister et exporter les coordonnées de vos patients',
    },
    'sms-campaign': {
        action: 'Campagne SMS',
        title: 'Créer une nouvelle campagne SMS',
    },
    'merge': {
        action: 'Fusion des doublons',
        title: 'Fusionner les dossiers doublons',
    },
    next: {
        title: 'Prochains rendez-vous',
        loading: 'Chargement de vos prochains rendez-vous…',
        none: {
            title: 'Aucun rendez-vous prévu.',
            description: {
                text: "Notez vos rendez-vous sur {0} pour voir apparaître vos prochains patients ici.",
                link: "votre planning",
                title: "Accéder à votre planning"
            },
        }
    },
    previous: {
        title: 'Dossiers récemment ouverts',
        loading: 'Chargement de vos dossiers patients récemment ouverts…',
        none: {
            title: 'Aucun dossier patient ouvert récemment.',
            description: {
                text: "Souhaitez-vous créer un {0} ?",
                link: "nouveau dossier",
                title: "Créer un nouveau dossier patient"
            },
        }
    },
    purchase: {
        label: 'acquérir',
        message: "Pour ajouter de nouveaux patients, vous devez acquérir un <strong>pack tranquillité</strong>. Voulez-vous l'acquérir ?"
    },
    license: {
        text: "Votre {0} est {1}atteinte. Pour lever cette limite, vous pouvez {2}.",
        strong: "limite de {license} patients",
        span: "bientôt ",
        link: "acquérir un pack tranquillité",
        title: "Acquérir un pack tranquillité"
    }
}
