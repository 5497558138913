export const timelineItem = {
    item: {
        link: "Voir les détails de {item}",
        text: "Détails"
    },
    'create-by': "créé par {owner}",
    concerns: "Concerne {first-name} {last-name}, {member}",
    consultation: {
        eva: {
            text: "EVA : {0} {1} {2}",
            before: "Avant {eva}",
            after: "Après {eva}"
        },
        evolution: "Évolution de l'état de santé",
        observation: "Observations",
        diagnostic: "Diagnostic",
        'examen-tests': "Tests ostéopathiques",
        treatment: "Traitement",
        tests: "Liste des tests d'exclusion positif",
    },
    'description1-title': "Antécédents parents",
    'description2-title': "Antécédents grossesse(s)",
    'description3-title': "Antécédents accouchement(s)",
    'description4-title': "Examen néonatal de l'enfant",
    'description5-title': "Comportement de l'enfant"
}
