export const specificSchedule = {
    title: "Horaires spécifiques du cabinet",
    subtitle: "Du {start} au {end}",
    delete: {
        title :"Suppression de l'horaire",
        'delete-msg': "Voulez-vous vraiment supprimer cet horaire ?",
        label: "supprimer cet horaire"
    },
    duplicate: {
        title :"Dupliquer l'horaire",
        label: "dupliquer cet horaire"
    },
    warning: "Attention, un autre horaire spécifique chevauche cette période, cela peut engendrer des erreurs.",
    start: {
        label: "À partir du",
        previous: {
            label: "Précédent",
            title: "Voir le lundi précédent"
        },
        next: {
            label: "Suivant",
            title: "Voir le prochain lundi"
        }
    },
    duration: {
        label: "Pendant {count} semaine|Pendant {count} semaine|Pendant {count} semaines",
        placeholder: "ex : 2 semaines",
        title: "Indiquez le nombre de semaine que vont couvrir ces horaires"
    },
    cycle: {
        label: "Toutes les semaines|Toutes les semaines|Toutes les {count} semaines",
        placeholder: "ex : 1 semaine sur 3",
        title: "Indiquez la fréquence de ces horaires"
    },
    message: {
        label:"Message spécifique à ces horaires",
        title:"Indiquez un message spécifique à cette période",
        placeholder:"ex : mon remplaçant prendra le relais pendant cette période…",
    },
    help1: {
        text: "{0}, et vous devez l'indiquer visuellement, soit à vos patients (pour la prise de rdv en ligne), soit au compte secrétaire qui travaille avec vous.",
        strong: "Vos horaires peuvent varier d'une semaine à l'autre"
    },
    help2: {
        text: "C'est le but de ces horaires spécifiques. Indiquez une période, éventuellement une fréquence, et, automatiquement, {0}.",
        strong: "ces nouveaux horaires viendront remplacer ceux par défaut",
    },
    help3: {
        text: "Vous avez également la possibilité d'indiquer {0}, pendant la période définie.",
        strong: "un message explicatif qui apparaîtra en temps voulu"
    },
    help4: {
        text: "{0} : Si vous avez défini {1}, alors cette dernière restera prioritaire par rapport à n'importe quel horaire.",
        strong: "Attention",
        link: {
            label: "une période d'absence / congés",
            title: "Indiquez vos absences / congés"
        }
    }
}
