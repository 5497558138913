<a-side :sub-menu="true" v-if="pediatrics">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.patient.aside-pediatrics.back-patient.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.patients.patient.aside-pediatrics.back-patient.text') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'patient-coordinates', params: $route.params}" :title="$t('vm.root.patients.patient.aside-pediatrics.back-main.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.patients.patient.aside-pediatrics.back-main.text') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'patient-pediatrics', params: $route.params}" :title="$t('vm.root.patients.patient.aside-pediatrics.antecedents.title')">{{ $t('vm.root.patients.patient.aside-pediatrics.antecedents.text') }}</router-link>
        </li>
        <li>
            <router-link :to="{name: 'patient-pediatrics-neonatal', params: $route.params}" :title="$t('vm.root.patients.patient.aside-pediatrics.neonatal.title')">{{ $t('vm.root.patients.patient.aside-pediatrics.neonatal.text') }}</router-link>
        </li>
        <li>
            <router-link class="with-badge" :to="{name: 'patient-pediatrics-forms', params: $route.params}" :title="$tc('vm.root.patients.patient.aside-pediatrics.forms.title', pediatrics.forms.length)">
                <span class="with-badge-label">{{ $tc('vm.root.patients.patient.aside-pediatrics.forms.text', pediatrics.forms.length) }}</span>
                <span class="with-badge-badge">{{pediatrics.forms.length}}</span>
            </router-link>
        </li>
    
    </ul>
</a-side>