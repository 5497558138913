import { BaseService } from '@Services/index'

export class HistoricService extends BaseService {
	
	public getNextPatient(): Promise<any> {
		return super.get(`/historic/next/`)
	}

	public getLastReadPatient(): Promise<any> {
		return super.get(`/historic/last-read/`)
	}

	public getCounts(): Promise<any> {
		return super.get(`/count/patients/`)
	}
}