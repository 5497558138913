import Render from '@Views/root/user/gift/bonus.html'
import { Dictionary } from "vue-router/types/router"
import { Vue, Component } from 'vue-property-decorator'
import {IBreadcrumbItem, IUser} from '@Store/types'
import {breadcrumb, user} from '@Store/modules'

@Render
@Component({})
export class Bonus extends Vue {

	private deploy: Dictionary<boolean> = {}

	get user(): IUser {
		return user.currentUser
	}

	mounted() {
		this.initializeBreadcrumb()
	}

	toggle(id: number): any {
		this.deploy[id] = !this.deploy[id]
		this.$forceUpdate()
	}

	isToggle(id: number): boolean {
		return this.deploy[id]
    }

	previous(): void {
        this.$router.push({name: 'user-gift3'})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift').toString(), link: 'user-gift' }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift1').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
