import Render from '@Views/root/patients/patient/consultation/aside-consultation.html'
import { Component, Prop } from 'vue-property-decorator'
import { INotification, IConsultation, IDisplay } from '@Store/types'
import { IInvoiceAction, InvoiceManager } from '@Managers/index'
import { configuration, notif, patient, user } from '@Store/modules'

@Render
@Component({})
export class ASideConsultation extends InvoiceManager {

  hasInvoice: boolean = false

  @Prop() readOnly: boolean
  @Prop() disabled: boolean

  constructor() {
		super()
  }

	//#region getter
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get consultation(): IConsultation {
		let consultation: IConsultation = patient.consultation(this.$route.params.item_id)
		this.updateHasInvoice(consultation)
		return consultation
	}
	get display(): IDisplay {
		return configuration.configuration && configuration.configuration.display
	}
	get hasFollowUpUnread(): boolean {
		return !!this.consultation.follow_up && !!this.consultation.follow_up.back && !this.consultation.follow_up.read
	}
	get invoiceNumber(): string {
		return this.getInvoiceNumber(this.consultation)
	}
	//#endregion

	mounted() {
		this.updateHasInvoice(this.consultation)
	}

	//#region InvoiceManager implementation
	updateConsultationId(conId: string): void {
		if (!!conId && parseInt(conId) === -1) {
		  this.hasInvoice = false
		} else {
		  this.updateHasInvoice(this.consultation)
		}
  	}
	//#endregion

	invoicePrinted(): boolean {
		return this.getInvoicePrinted(this.consultation)
	}

	invoiceSended(): boolean {
		return this.getInvoiceSended(this.consultation)
	}

	updateReceipt(): void {
		let action: IInvoiceAction = {
		  action: null,
		  title: this.$t('managers.invoice-manager.generate-invoice.action-title').toString(),
		  label: this.$t('managers.invoice-manager.generate-invoice.action-label').toString()
		}

		this.createInvoice(() => { super.updateReceipt(this.consultation) }, action)
	}

	createAndPrint(): void {
		let action: IInvoiceAction = {
		  action: () => { super.print(this.consultation).then(() => { this.$forceUpdate() }) },
		  title: this.$t('managers.invoice-manager.print-invoice.title').toString(),
		  label: this.$t('managers.invoice-manager.print-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	createAndSend(): void {
		let action: IInvoiceAction = {
		  action: () => { super.send(this.consultation).then(() => { this.$forceUpdate() }) },
		  title: this.$t('managers.invoice-manager.send-invoice.title').toString(),
		  label: this.$t('managers.invoice-manager.send-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	submitDelete() {
		patient.deleteConsultation(this.consultation)
		this.$router.push({name: "patient-consultations", params: this.$route.params})
	}

	private createInvoice(existAction: () => void, action: IInvoiceAction): void {
		if (this.disabled) {
			this.hasInvoice ? existAction() : super.createReceipt(this.consultation, action)
		} else {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.disabled').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
		}
	}

	private updateHasInvoice(consultation: IConsultation): void {
		let invId: string = this.getInvoiceId(consultation)
		this.hasInvoice = !!invId && parseInt(invId) !== -1
	}
}
