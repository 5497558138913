export const gift1 = {
    title: "Votre premier mois offert",
    subtitle: "Votre profil et votre cabinet|Votre profil et votre cabinet|Votre profil et vos cabinets",
    help: {
        text1: "Pour activer votre premier mois offert, assurez-vous de {0} au maximum. En effet, pour {1}, les moteurs de recherche (comme Google) ont besoin d'{2} d'une page web. Dans votre cas, votre page de prise de rdv en ligne doit {3}, vos horaires, votre manière de travailler, les différents types de consultations proposées ou encore comment se rendre à votre cabinet.",
        strong1: "faire grimper vos jauges",
        strong2: "un meilleur référencement naturel",
        strong3: "informations pour bien identifier le rôle",
        strong4: "clairement indiquer le lieu d'activité",
        text2: "Vous pouvez saisir toutes ces informations dans votre espace client ou en cliquant sur le {0} sous les légendes ci-dessous. Essayez de {1}, avertissez-nous dès qu'elles sont au maximum. Dès validation du bon état de votre page, nous vous {2} sur le planning en ligne.",
        em: "remplir",
        strong5: "compléter ces 2 jauges",
        strong6: "offrirons un mois gratuit",
        text3: "N'hésitez pas non plus à consulter notre {0} et à {1}. Ils sont applicables gratuitement et permettent d'améliorer votre visibilité.",
        'link-title': "Bonus supplémentaire",
        'link-label': 'bonus',
        strong7: "suivre les quelques conseils qui y sont prodigués"
    },
    waiting: {
        title: "Votre profil est à l'étude !",
        text: "Nous reviendrons vers vous très rapidement pour validation"
    },
    valided: {
        title: "Félicitations",
        text: "Vous avez obtenu votre premier mois !"
    },
    progression: {
        title: "Votre progression",
        'fill-out': "remplir",
        complete: "complet"
    },
    back: {
        label: "présentation",
        title: "Retourner à la présentation générale"
    },
    action: {
        label: "nous avertir",
        title: "Nous avertir pour validation de votre profil"
    },
    next: {
        label: "étape 2",
        title: "Aller à la prochaine étape"
    }
}