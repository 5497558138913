import Render from '@Views/root/accounting/remittances/aside-remittances.html'
import { Component, Vue } from 'vue-property-decorator'
import { IAccountingFilter, IRemittance } from '@Store/types'
import { accounting } from '@Store/modules'

@Render
@Component({})
export class ASideRemittances extends Vue {

	remittances: IRemittance[] = []

	// @State('accounting') stateAccounting: AccountingState
	// @Action('accounting/loadRemittances') loadRemittances: (mode: PaymentMode) => Promise<any>

	get filter(): IAccountingFilter {
		return accounting.filter
	}
	get isLoading(): boolean {
		return accounting.isLoading
	}

	mounted() {
		accounting.loadRemittances()
		.then(remittances => {
			this.remittances = remittances
		})
	}
}