import Render from '@Views/root/shop/bulk/main.html'
import {Component, Vue} from 'vue-property-decorator'
import {IBreadcrumbItem, IUser} from '@Store/types'
import {breadcrumb, bulk, shop, user} from '@Store/modules'
import { ContextMain } from './context-main'
import {ASideMain} from './aside-main'
import {Step1} from './steps/step1'
import {Step2} from './steps/step2'
import {Step3} from './steps/step3'
import {Step4} from './steps/step4'

@Render
@Component({
	components: {
		'aside-main': ASideMain,
		'context-main': ContextMain,
		'step1': Step1,
		'step2': Step2,
		'step3': Step3,
		'step4': Step4,
	}
})
export class Main extends Vue {

	//#region Getters
	get user(): IUser {
		return user.user;
	}
	get isLoading(): boolean {
		return bulk.isLoading
	}
	get canBuy(): boolean {
		return bulk.canBuy
	}
	get isStep1(): boolean {
		return bulk.isStep1
	}
	get isStep2(): boolean {
		return bulk.isStep2
	}
	get isStep3(): boolean {
		return bulk.isStep3
	}
	get title(): string {
		if (!this.canBuy)
			return "impossible"
		if (this.isStep1)
			return "réservation"
		if (this.isStep2)
			return "attente de fin de session"
		if (this.isStep3)
			return "finalisation"
		return "résumé"
	}
	//#endregion

	mounted() {
		this.canAccessOnBulk()
		this.initializeBulk()
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	private initializeBulk(): void {
		let bulkKey: string = !!this.user.bulk_key ? this.user.bulk_key : bulk.bulkKey(new Date())
		bulk.loadBulk(bulkKey)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.shop').toString(), link: "shop" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.bulk').toString() }
		breadcrumb.updateItems([item1, item2])
	}

	// Pour empecher l'acces au bulk
	private canAccessOnBulk(){
		if (!this.$route.path.startsWith('/bulk')) return
		this.$router.replace('/shop');
	}
}
