import Render from '@Views/root/accounting/immobilization/aside-immobilization.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IInvoice } from '@Store/types'
import { accounting } from '@Store/modules'

@Render
@Component({})
export class ASideImmobilization extends Vue {

  @Prop() immobilization: IInvoice

  // @State('accounting') stateAccounting: AccountingState
  // @Action('accounting/deleteInvoice') deleteInvoice: (inv_id: string) => Promise<any>
  
  get isDeleting(): boolean {
    return accounting.isDeleting
  }

  submitDelete(): void {
    const { immobilization } = this
    let route = {name: 'accounting'}

    this.$emit('delete')
    if (!!immobilization && !!immobilization.id && parseInt(immobilization.id) !== -1) {
      accounting.deleteInvoice(immobilization.id).then(() => {
        this.$router.push(route)
      })
    } else {
      this.$router.push(route)
    }
  }
}