export const followUp = {
    title: "Suivi de la consultation",
    help1: "Un mail/SMS sera envoyé à votre patient après le délai indiqué ci-dessous.",
    help2: "Ce dernier contiendra un lien unique qui redirigera votre patient vers un formulaire de suivi dans lequel il pourra indiquer l'évolution après cette consultation.",
    help3: {
        text: "À la validation de ce formulaire, le {0} sera complété et vous serez averti par mail.",
        strong: "retour du patient"
    },
    help4: {
        text: "Vous devez {0} dans le dossier du patient {1}, pour que le suivi lui soit envoyé.",
        strong: "nécessairement saisir au moins un email ou un numéro de téléphone portable",
        span: "(et avoir le nombre de crédit nécessaire pour les SMS)"
    },
    'follow-up-type': {
        label: "Type d'envoi du suivi de consultation",
        title: "Choisissez le type d'envoi du suivi de consultation"
    },
    'follow-up-delay': {
        label: "Demandez un suivi à votre patient dans {count} jour | Demandez un suivi à votre patient dans {count} jour | Demandez un suivi à votre patient dans {count} jours",
        placeholder: "ex : 6 jours",
        error: "Merci de renseigner un nombre de jour",
        title: "Veuillez renseigner le nombre de jour avant l'envoi de la demande de suivi"
    },
    level: {
        label: "Évolution de l'état du patient",
        title: "Indiquez, selon son retour, l'évolution de l'état du patient"
    },
    'follow-up-patient': {
        label: "Retour du patient",
        placeholder: "ex : Selon votre patient",
        title: "C'est votre patient qui remplira ce champ"
    },
    'follow-up-read': {
        label: "J'ai pris connaissance de ce retour",
        title: "Indiquer que vous avez bien pris connaissance du retour de vos patients"
    },
    none: {
        title: "Pas de suivi de consultation.",
        description: "Le patient n'a pas encore donné suite."
    },
    'not-readed': "Pas encore lu / évalué."
}
