import Render from '@Views/root/user/account/organizations.html'
import draggable from "vuedraggable"
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ICurriculum, IUser, IBreadcrumbItem } from '@Store/types'
import { Dictionary } from 'vue-router/types/router'
import * as Organization from './organization/index'
import { Common, User } from '@Models/index'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'draggable': draggable,
		'organization': Organization.Main
	}
})
export class Organizations extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() user: IUser

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get organizations(): ICurriculum[] {
		return this.user.organizations.currents
	}

	mounted() {
		this.$emit('navigate', 'organization-user')
		this.initializeBreadcrumb()

		this.moveToOrganization()
	}

	toggle(id: string, createId?: string): void {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	addOrganization(): void {
		let createId: string = Common.generateId()
		let organization: ICurriculum = User.createNewCurriculum(createId)
		this.toggle(organization.id, organization.create_id)
		this.user.organizations.currents.push(organization)
		setTimeout(() => { this.moveToOrganization(organization) }, 100)
	}

	getElementId(organization: ICurriculum): string {
		return parseInt(organization.id) === -1 ? organization.create_id : organization.id
	}

	@Watch('submitted')
	private moveToOrganization(organization?: ICurriculum) {
		organization = !organization ? this.organizations.find(a => a.error) : organization
		if (!organization) return

		if (!this.isToggle(organization.id, organization.create_id)) {
			this.toggle(organization.id, organization.create_id)
		}
		let ses_id: string = this.getElementId(organization)
		let element = this.$refs[ses_id] as any
		if (!element || element.length === 0) return

		let centralElement: Element = document.getElementsByClassName('l-central')[0]
		centralElement.scrollTo(0, element[0].offsetTop)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.organizations', this.organizations.length).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}