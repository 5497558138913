<div v-if="!!options">
	<collapsible-help id="merge-warning" tag="h2"
					  :hide-label="$t('vm.components.super-panel.merge.hide-label')"
					  :show-label="$t('vm.components.super-panel.merge.show-label')"
					  :title="$t('vm.components.super-panel.merge.title', {patient: patientName})">
		<spinner slot="main-icon" v-if="loading"/>
		<svg v-else slot="main-icon" width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#code-merge"/>
		</svg>

		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
		</svg>
		<div class="notice-warning">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				<i18n path="vm.components.super-panel.merge.help.content" tag="span">
					<strong>{{ $t('vm.components.super-panel.merge.help.strong1') }}</strong>
					<strong>{{ $t('vm.components.super-panel.merge.help.strong2') }}</strong>
					<strong>{{ $t('vm.components.super-panel.merge.help.strong3') }}</strong>
					<strong>{{ $t('vm.components.super-panel.merge.help.strong4') }}</strong>
				</i18n>
			</p>
		</div>
	</collapsible-help>

	<div class="childs-space-between is-nowrap">
		<p>{{ $t('vm.components.super-panel.merge.notice') }}</p>
		<button class="btn btn-default marg-l-1" :disabled="loading" @click="preSelect"
				:title="$t('vm.components.super-panel.merge.selection.title')">
			{{ $t('vm.components.super-panel.merge.selection.label') }}
		</button>
	</div>

	<div v-if="loading" class="merge-grid-container" ref="scrollParent">
		<div class="is-overflow scrollbar" ref="scrollContainer">
			<div class="merge-grid" :style="`--col-count:${idList.length}; --row-count:6;`">

				<div></div>
				<template v-for='id in 5'>
					<div class="merge-grid-title"><span class="merge-grid-loading" :style="`--factor:${id}`">&nbsp;</span></div>
				</template>

				<template v-for='(list, id1) in idList'>
					<template v-for='id2 in 6'>
						<div><div class="input-like merge-grid-loading" :style="`--factor:${id1+id2+1}`">&nbsp;</div></div>
					</template>
				</template>

				<!-- Test for horizontal scroll -->
				<div ref="butter" class="merge-grid-scroll-test"></div>
			</div>
		</div>
	</div>

	<div v-else class="merge-grid-container" ref="scrollParent">
		<div class="is-overflow scrollbar" ref="scrollContainer">
			<div class="merge-grid" :style="`--col-count:${idList.length}; --row-count:${rowCount + 1};`">

				<div></div>
				<div v-if="options.keys['main'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.main.label') }}</div>
				<div v-if="options.keys['birth_date'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.birth_date.label') }}</div>
				<div v-if="options.keys['phone1'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.phone1.label') }}</div>
				<div v-if="options.keys['phone2'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.phone2.label') }}</div>
				<div v-if="options.keys['address'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.address.label') }}</div>
				<div v-if="options.keys['email'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.email.label') }}</div>
				<div v-if="options.keys['nir'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.nir.label') }}</div>
				<div v-if="options.keys['gender'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.gender.label') }}</div>
				<div v-if="options.keys['notes'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.notes.label') }}</div>
				<div v-if="options.keys['number_of_children'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.number_of_children.label') }}</div>
				<div v-if="options.keys['situation'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.situation.label') }}</div>
				<div v-if="options.keys['professions'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.professions.label') }}</div>
				<div v-if="options.keys['reference'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.reference.label') }}</div>
				<div v-if="options.keys['habitus'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.habitus.label') }}</div>
				<div v-if="options.keys['family_antecedents'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.family_antecedents.label') }}</div>
				<div v-if="options.keys['form_coordinates'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.form_coordinates.label') }}</div>
				<div v-if="options.keys['pediatrics'].isVisible" class="merge-grid-title">{{ $t('vm.components.super-panel.merge.columns.pediatrics.label') }}</div>

				<template v-for='patient in patients'>
					<div>
						<i18n path="vm.components.super-panel.merge.create-date.content" tag="div" class="semi-pad-v text-center text-md">
							<span class="rwd-over-medium">{{ $t('vm.components.super-panel.merge.create-date.span') }}</span>
							<strong>{{ $d(patient.create_date, 'L') }}</strong>
							<strong>{{ $d(patient.create_date, 'LT') }}</strong>
						</i18n>
						<hr class="no-marg">
					</div>

					<button-merge property="main" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.main.title')">
						<span class="text-italic text-sm">
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
							</svg> {{ $t('vm.components.super-panel.merge.by', {owner: owner(patient)}) }}
						</span>
					</button-merge>
					<button-merge property="birth_date" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.birth_date.title')">
						<span v-if="patient.coordinates.without_birth_date">{{ $t('vm.components.super-panel.merge.columns.birth_date.no-data') }}</span>
						<span v-else>{{ $d(patient.coordinates.birth_date, 'll') }}</span>
					</button-merge>
					<button-merge property="phone1" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.phone1.title')">
						{{ patient.coordinates.phone1.number | formatPhone }}
					</button-merge>
					<button-merge property="phone2" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.phone2.title')">
						{{ patient.coordinates.phone2.number | formatPhone }}
					</button-merge>
					<button-merge property="address" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.address.title')">
						{{ patient.coordinates.address.formatted }}
					</button-merge>
					<button-merge property="email" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.email.title')">
						{{ patient.coordinates.email }}
					</button-merge>
					<button-merge property="nir" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.nir.title')">
						{{ patient.coordinates.nir.number | formatNir(patient.coordinates.nir.iso) }}
					</button-merge>
					<button-merge property="gender" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.gender.title')">
						{{ patient.coordinates.gender | gender }}
					</button-merge>
					<button-merge property="notes" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.notes.title')">
						({{ patient.coordinates.level | level}}) {{ patient.coordinates.notes }}
					</button-merge>
					<button-merge property="number_of_children" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.number_of_children.title')">
						<span v-if="patient.coordinates.number_of_children === -1">{{$t('vm.root.patients.patient.coordinates.number-of-children.none') }}</span>
						<span v-else>{{ $tc('vm.root.patients.patient.coordinates.number-of-children.item', patient.coordinates.number_of_children) }}</span>
					</button-merge>
					<button-merge property="situation" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.situation.title')">
						{{ patient.coordinates.situation | situation(patient.coordinates.gender) }}
					</button-merge>
					<button-merge property="professions" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.professions.title')">
						{{ patient.coordinates.professions.join(', ') }}
					</button-merge>
					<button-merge property="reference" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.reference.title')">
						{{ patient.coordinates.reference.join(', ') }}
					</button-merge>
					<button-merge property="habitus" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.habitus.title')">
						{{ patient.coordinates.habitus }}
					</button-merge>
					<button-merge property="family_antecedents" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.family_antecedents.title')">
						{{ patient.coordinates.family_antecedents }}
					</button-merge>
					<button-merge property="form_coordinates" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.form_coordinates.title')">
						{{ $t('vm.components.super-panel.merge.columns.form_coordinates.this-one') }}
					</button-merge>
					<button-merge property="pediatrics" :keys="options.keys" :patient="patient"
								  :title="$t('vm.components.super-panel.merge.columns.pediatrics.title')">
						{{ $t('vm.components.super-panel.merge.columns.pediatrics.this-one') }}
					</button-merge>
				</template>

				<!-- Test for horizontal scroll -->
				<div ref="butter" class="merge-grid-scroll-test"></div>
			</div>
		</div>
	</div>
</div>
