export const context = {
    title: "Recherchez",
    period: {
        title: "Période affichée",
        'start-date': "Date de début de la période filtrée",
        'end-date': "Date de fin de la période filtrée"
    },
    user: {
        label: "Lignes créées par",
        title: "Sélectionnez l'utilisateur que vous souhaitez",
        'filter-title': "Filtrez les utilisateurs pour en sélectionner un facilement",
        placeholder: "ex : Andrew Taylor Still",
    },
    office: {
        label: "Lignes associées au cabinet",
		title: "Sélectionnez le cabinet que vous souhaitez"
    },
    filter: {
        title: "Filtrez",
        reset: {
            title: "Remettre les filtres à zéro",
            label: "réinitialiser"
        },
        number: {
            label: "Numéro de facture" ,
            placeholder: "ex : 2000184",
            title: "Saisissez des chiffres pour filtrer les factures par leur numéro"
        },
        label: {
            label: "Libellé de facture" ,
            placeholder: "ex : Lady Bug",
            title: "Saisissez des caractères pour filtrer les facture par leur libellé"
        },
        type: "Filtrez par type de document",
        'receipt-type': "Filtrez par type de recette",
        mode: "Filtrez par mode de paiement",
        'all-modes': "Tous les modes",
        'no-mode': "Aucun mode",
        'all-types': "Tous les types",
        'no-type': "Aucun type",
        'all-receipts': "Toutes les recettes",
        'no-receipt': "Aucune recette"
    }
}