export const step1 = {
    title: "Choix du profil",
    verifying: "Vérification en cours…",
    help: "Sélectionnez le type de profil avec lequel vous souhaitez travailler. Les détails concernant ce profil seront alors affichés. Vous pouvez également modifier les paramètres d'un compte existant.",
    profile: {
        'default-label': "Sélectionnez un type de profil",
        'error-msg': "Veuillez sélectionner un type de profil",
        label: "Sélectionnez le type de profil"
    },
    'next-step': {
        title: "Aller à l'étape suivante",
        text: "étape 2"
    }
}
