import Render from '@Views/root/user/context-referral.html'
import { Component, Vue } from 'vue-property-decorator'
import { IUser } from '@Store/types'
import { referral, user } from '@Store/modules'

@Render
@Component({})
export class ContextReferral extends Vue {
	
	godchildList: IUser[] = []

	// @State('shop') stateShop: ShopState
	// @Getter('user/currentUser') currentUser: IUser
	// @Action('shop/loadGodchilds') loadGodchilds:(code: string) => Promise<any>

	get isLoading(): boolean {
		return referral.isGodchildsLoading
	}
	get code(): string {
		return user.currentUser.referral_code
	}

	mounted() {
		referral.loadGodchilds(this.code)
		.then(users => {
			this.godchildList = users
		})
	}
}
