export const aside = {
    receipt: {
        title: "Créez une nouvelle recette",
        label: "Nouvelle recette"
    },
    spend: {
        title: "Créez une nouvelle dépense",
        label: "Nouvelle dépense"
    },
    immobilization: {
        title: "Créez une nouvelle immobilisation",
        label: "Nouvelle immobilisation"
    },
    remittances: {
        title: "Gérez vos remises de chèques",
        label: "Remises de chèques"
    },
    book: {
        title: "Gérez votre journal de recette",
        label: "Journal des recettes"
    }
}