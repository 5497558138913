import { getModule, VuexModule, Module, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { INotification } from "@Store/types"
import { NotificationType } from "@Enums/index"
import { Notification } from "@Models/index"

@Module({
	name: 'notif',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
export class NotificationModule extends VuexModule {

	type: NotificationType = NotificationType.None
	notification: INotification = null

	@Mutation
	clear() {
		this.type = NotificationType.None
		this.notification = null
	}

	@Mutation
	success(notification: INotification | string) {
		this.type = NotificationType.Success
		this.notification = Notification.getNotification(notification)
	}

	@Mutation
	info(notification: INotification | string) {
		this.type = NotificationType.Info
		this.notification = Notification.getNotification(notification)
	}

	@Mutation
	warning(notification: INotification | string) {
		this.type = NotificationType.Warning
		this.notification = Notification.getNotification(notification)
	}

	@Mutation
	error(notification: INotification | string) {
		this.type = NotificationType.Error
		this.notification = Notification.getNotification(notification)
	}
	
	@Mutation
	notif(payload: {notification: INotification | string, type: NotificationType}) {
		this.type = payload.type
		this.notification = Notification.getNotification(payload.notification)
	}

	@Mutation
	progress(progress: number) {
		let message:string = progress >= 100 ? 'Envoi effectué. Traitement en cours…' : `Envoi en cours : ${progress}%`
		let notification: INotification = { message: message, actions: [], delay: false, canClose: false, progress: progress }
		this.type = NotificationType.Info
		this.notification = Notification.getNotification(notification)
	}

	@Mutation
	updateMessage(message: string) {
		if (!this.notification) return
		this.notification.message = message
	}
}

export const notif = getModule(NotificationModule)
// export const notif = new NotificationModule({ store, name: "notif" })