import Render from '@Views/components/anonymous.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AnonymousHelper } from '@Helpers/index'
import { configuration } from '@Store/modules'

@Render
@Component({})
export class Anonymous extends Vue {

	@Prop({default: "span"}) tag: string
	@Prop() query: string
	@Prop() text: string
	@Prop() title: string
	@Prop() email: boolean

	// @Getter('configuration/isAnonymous') isAnonymous: boolean

	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get anonymousText(): string {
		if (!this.isAnonymous) return this.text

		return AnonymousHelper.getAnonymousText(this.text, this.email)
	}
	get anonymousTitle(): string {
		if (!this.isAnonymous) return this.title

		return AnonymousHelper.getAnonymousText(this.title)
	}
}
