import Render from '@Views/root/calendar/valid-event/aside-valid-event.html'
import { Component, Vue } from 'vue-property-decorator'
import { IEvent } from '@Store/types'
import { DateHelper } from '@Helpers/index'
import { event } from '@Store/modules'

@Render
@Component({})
export class ASideValidEvent extends Vue {

//   @State('event') stateEvent: EventState
  
  get event(): IEvent {
		return event.event
	}
  get date(): string {
		return DateHelper.getDateString(!this.event ? undefined : new Date(this.event.start_date))
	}
}