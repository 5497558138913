export const createReceipt = {
    'your-name': "à votre nom",
    'other-name': "au nom de {last-name} {first-name}",
    'your-account': "votre compte",
    'other-account': "le compte de {last-name} {first-name}",
    text: {
        message: "Vous êtes sur le point de générer une facture. Cette dernière sera {0}. Une recette de rétrocession, au taux de {1} %, sera {2}.",
        strong1: "établie {user}",
        strong2: "automatiquement générée sur {account}"
    }
}