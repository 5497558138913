import Render from '@Views/root/letters/context-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import {IDropdownItem, IToggleBoxItem} from '@Components/index'
import { SearchPatient, PatientItem } from '@ViewModels/root/search/patient'
import {
	IOffice,
	ILetterFilter,
	IConfiguration,
	IDocument,
	IInvoice,
	IPatient,
	IConsultation,
	IPatientHistoric, IPatientSearch
} from '@Store/types'
import { DateHelper } from '@Helpers/index'
import { accounting, configuration, letter, office, patient, user } from '@Store/modules'
import {Backup} from "@Models/backup";
import {Patient} from "@Models/patient";
import {HistoricService} from "@Services/patient/historic.service"

@Render
@Component({
	components: {
		'patient-item': PatientItem,
		'search-patient': SearchPatient
	}
})
export class ContextMain extends Vue {

	loadingLastReadPatientList: boolean = false
	lastReadPatientList: IPatientSearch[] = []
	patient: IPatient = null
	mode: number = 0
	private consultations: IConsultation[] = []
	private invoices: IInvoice[] = []

	//#region Getters
	get models(): IDropdownItem[] {
		return this.documents.map(d => { return {value: d.id, label: d.title}})
	}
	get filter(): ILetterFilter {
		return letter.filter
	}
	get isConsultationLoading(): boolean {
		return patient.isLoading
	}
	get isInvoiceLoading(): boolean {
		return accounting.isListLoading
	}
	get needPatient(): boolean {
		return this.needConsultation || this.needInvoice || !!this.selectedDocument && this.selectedDocument.need_patient
	}
	get needConsultation(): boolean {
		return !!this.selectedDocument && this.selectedDocument.need_consultation
	}
	get needInvoice(): boolean {
		return !!this.selectedDocument && this.selectedDocument.need_invoice
	}
	get needOffice(): boolean {
		return !this.needConsultation && !this.needInvoice && !!this.selectedDocument && this.selectedDocument.need_office
	}
	get modeItems(): IToggleBoxItem[] {
		return [
			{value: 0, label: "recherche"},
			{value: 1, label: "recent"}
		]
	}
	get consultationItems(): IDropdownItem[] {
		let today: Date = new Date()
		let yesterday: Date = new Date()
		yesterday.setDate(yesterday.getDate() - 1)

		return this.consultations
		.filter(c => this.isManaged(c))
		.map(c => {
			let owner: string = patient.owner(c)
			let yourself: string = this.$i18n.t('vm.root.patients.patient.documents.consultation.items.yourself').toString()
			owner = this.$i18n.t('vm.root.patients.patient.documents.consultation.items.by', {owner: !!owner ? owner : yourself}).toString()

			let label: TranslateResult =
				DateHelper.sameDay(today, c.act_date) ?
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-day', {date: this.$i18n.t('general.today'), owner: owner}) :
				DateHelper.sameDay(yesterday, c.act_date) ?
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-day', {date: this.$i18n.t('general.yesterday'), owner: owner}) :
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-date', {date: this.$i18n.d(c.act_date, 'l'), owner: owner})
			return {
				value: c.id,
				label: label.toString()
			}
		})
	}
	get invoiceItems(): IDropdownItem[] {
		return this.invoices.map(i => { return { value: i.id, label: `#${i.number} - ${this.$i18n.d(i.settlement_date, 'l')}`} })
	}
	get officeItems(): IDropdownItem[] {
		return this.offices.map(o => { return { value: o.id, label: o.coordinates.name } })
	}
    private get offices(): IOffice[] {
        return office.offices.filter(o => { return office.isActive(o) })
    }
	private get selectedDocument(): IDocument {
		return this.documents.find(d => { return d.id === this.filter.doc_id })
	}
	private get documents(): IDocument[] {
		return !!this.configuration ? this.configuration.documents.currents : []
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	//#endregion

	mounted() {
		this.initializeLastRead()
	}

	setPatient(_patient: IPatient): void {
		this.patient = _patient
		let payload = {pat_id: _patient.id}

		patient.loadPatient(payload)
		.then((patient: IPatient) => {
			this.filter.patient = patient
			this.filter.pat_id = patient.id
			this.filter.con_id = '-1'
			this.$emit('filter-update', this.filter)
			this.consultations = patient.consultations.currents
		})

		accounting.loadInvoicesByPatId(payload.pat_id)
		.then((invoices: IInvoice[]) => {
			this.filter.invoices = invoices
			this.filter.inv_id = '-1'
			this.invoices = invoices
		})
	}

	private initializeLastRead(): void {
		this.loadingLastReadPatientList = true
		let service = new HistoricService()
		service.getLastReadPatient()
			.then(result => {
				let list: IPatientHistoric[] = result.sort((a: IPatientHistoric, b:IPatientHistoric) => b.time.getTime() - a.time.getTime())
				this.lastReadPatientList = []
				for (let historic of list) {
					this.lastReadPatientList.push(Patient.historicToPatientSearch(historic))
				}
				this.loadingLastReadPatientList = false
			})
			.catch(() => {
				let patients: IPatient[] = Backup.getInstance().patientList
				let list:IPatient[] = patients.slice(0, Math.min(6, patients.length))
				if (list.length === 0) {
					// keep old values
				} else {
					this.lastReadPatientList = list.map(patient => { return Patient.toPatientSearch(patient) })
				}
				this.loadingLastReadPatientList = false
			})
	}

	private isManaged(consultation: IConsultation): boolean {
		if (consultation.creator_id === user.currentUser.id) return true

		return user.currentUser.links.filter(u => { return u.id === consultation.creator_id }).length > 0
	}
}
