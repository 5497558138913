export const header = {
    main: {
        label: "Menu principal",
        title: "Ouvrir le menu principal"
    },
    search: {
        label: "Sous menu / recherche",
        title: "Ouvrir le panneau de sous-menu / recherche"
    },
    context: {
        label: "Panneau contextuel",
        title: "Ouvrir le panneau contextuel"
    }
}