export const quickEventManager = {
    title: "Rendez-vous rapide",
    label: "valider",
    'title-button': "Valider ce rendez-vous rapide",
    'create-planning': {
        label: "Créer un planning",
        message: "Vous devez ajouter au moins un planning pour créer un rendez-vous."
    },
    'create-office': {
        label: "Gérer les cabinets",
        message: "Vous devez avoir au moins un cabinet actif pour créer un rendez-vous."
    }
}
