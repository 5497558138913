import Render from '@Views/root/shop/bulk/steps/step4.html'
import {Component, Vue} from 'vue-property-decorator'
import {IBulkInfo, IUser} from '@Store/types'
import {bulk, user} from '@Store/modules'

@Render
@Component({})
export class Step4 extends Vue {

	//#region Getters
	get user(): IUser {
		return user.user
	}
	get bulk(): IBulkInfo {
		return bulk.bulk
	}

	get percent(): number {
		return bulk.bulk.percent
	}
	//#endregion
}
