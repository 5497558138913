<context>
	<div class="wrapper">

		<p class="text-center text-strong">{{ $t('vm.root.user.office.context.title') }}</p>
		<apexchart type="radialBar" height="250" :options="chartOptions" :series="series"/>

		<hr>

		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkActivate() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkActivate()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.page-enabled.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.page-enabled.description') }}</span>
			<span v-if="!checkActivate()">:
				<router-link :to="{name: 'office-parameters', params: $route.params}" :title="$t('vm.root.user.office.context.page-enabled.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkUrl() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkUrl()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.url.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.url.description') }}</span>
			<span v-if="!checkUrl()">: 
				<router-link :to="{name: 'office-parameters', params: $route.params}" :title="$t('vm.root.user.office.context.url.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkAddress() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkAddress()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.address.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.address.description') }}</span>
			<span v-if="!checkAddress()">:
				<router-link :to="{name: 'office-coordinates', params: $route.params}" :title="$t('vm.root.user.office.context.address.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkPhones() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkPhones()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.phone.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.phone.description') }}</span>
			<span v-if="!checkPhones()">:
				<router-link :to="{name: 'office-coordinates', params: $route.params}" :title="$t('vm.root.user.office.context.phone.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkMessage() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkMessage()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.presentation.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.presentation.description') }}</span>
			<span v-if="!checkMessage()">:
				<router-link :to="{name: 'office-parameters', params: $route.params}" :title="$t('vm.root.user.office.context.presentation.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>	
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkDefaultRejection() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkDefaultRejection()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.rejection.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.rejection.description') }}</span>
			<span v-if="!checkDefaultRejection()">:
				<router-link :to="{name: 'office-parameters', params: $route.params}" :title="$t('vm.root.user.office.context.rejection.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>	
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkSchedule() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkSchedule()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.schedule.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.schedule.description') }}</span>
			<span v-if="!checkSchedule()">:
				<router-link :to="{name: 'office-absence', params: $route.params}" :title="$t('vm.root.user.office.context.schedule.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkAccesses() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkAccesses()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong>{{ $t('vm.root.user.office.context.access.title') }}</strong><br>
			<span>{{ $t('vm.root.user.office.context.access.description') }}</span>
			<span v-if="!checkAccesses()">:
				<router-link :to="{name: 'office-accesses', params: $route.params}" :title="$t('vm.root.user.office.context.access.link-title')">
					{{ $t('vm.root.user.office.context.complete') }}
				</router-link>
			</span>
		</p>

	</div>
</context>
