import { IForm, IQuestion } from "@Store/types"

export class FormHelper {
    
    public static isEmpty(form: IForm) {
        for(let question of form.questions) {
            if (!FormHelper.isEmptyQuestion(question)) return false
        }
        return true
    }
    
    public static isEmptyQuestion(question: IQuestion): boolean {
        if (!!question.choices) {
            for(let choice of question.choices) {
                if (choice.selected) return false
            }
        }
        return !question.answer
    }
}