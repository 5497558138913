import * as Shop from '@ViewModels/root/shop'
import * as Bulk from '@ViewModels/root/shop/bulk'
import * as Purchase from '@ViewModels/root/shop/purchase'
import { Profile } from '@Enums/profile'

export const ShopRoot = [
	{
		path: 'shop',
		name:'shop',
		component: Shop.Main,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'purchase/:pur_id',
		name:'purchase',
		alias: 'purchase/:pur_id/ok',
		component: Purchase.Main,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'purchase/pack/:pack',
		name:'purchase-pack',
		component: Purchase.Main,
		meta: { permission: [Profile.Main, Profile.Demo] }
	},
	{
		path: 'bulk',
		name:'bulk-purchase',
		component: Bulk.Main,
		meta: { permission: [Profile.Main, Profile.Demo] }
	}
]

