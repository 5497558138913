import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { RequestStatus, PaymentMode } from '@Enums/index'
import { IInvoice, IRemittance, IBookFilter, IAccountingFilter, IAccountingSubFilter, RemittanceActionPayload, BankActionPayload, InvoiceLoadPayload, InvoiceActionPayload, CheckActionPayload, AccountingActionPayload } from '@Store/types'
import { Dictionary } from 'vue-router/types/router'
import {Accounting, Recurring} from '@Models/index'
import { AccountingService } from '@Services/index'
import { router } from '@Router/index'

@Module({
	name: 'accounting',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class AccountingModule extends VuexModule {
    status: RequestStatus = RequestStatus.None
    statusSaving: RequestStatus = RequestStatus.None
    statusPrinting: RequestStatus = RequestStatus.None
    statusSending: RequestStatus = RequestStatus.None
    statusDeleting: RequestStatus = RequestStatus.None
    statusList: RequestStatus = RequestStatus.None
    statusRemittanceList: RequestStatus = RequestStatus.None
    statusFilter: RequestStatus = RequestStatus.None
    invoice: IInvoice = null
    remittance: IRemittance = null
    lastRemittance: IRemittance = null
    bookFilter: IBookFilter = null
    filter: IAccountingFilter = null
    subFilter: IAccountingSubFilter = Accounting.createNewSubFilter()
    counter: Dictionary<number> = {}

	get isFiltering(): boolean {
		return this.statusFilter === RequestStatus.Loading
	}
	get isListLoading(): boolean {
		return this.statusList === RequestStatus.Loading
	}
	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isSending(): boolean {
		return this.statusSending === RequestStatus.Loading
	}
	get countByMode(): (mode: PaymentMode) => number {
		return (mode: PaymentMode) => {
			return !!this.counter ? this.counter[mode.toString()] : 0
		}
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusPrinting = RequestStatus.None
		this.statusSending = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusList = RequestStatus.None
		this.statusRemittanceList = RequestStatus.None
		this.statusFilter = RequestStatus.None
		this.invoice = null
		this.remittance = null
		this.lastRemittance = null
		this.bookFilter = null
		this.filter = null
		this.subFilter = null
	}

	@Mutation
	clearInvoice() {
		this.invoice = null
	}

	@Mutation
	resetBookFilter() {
		this.bookFilter = Accounting.createNewBookFilter()
	}

	@Mutation
	resetFilter(monthly: boolean) {
		this.filter = Accounting.createNewFilter(monthly)
	}

	@Mutation
	resetSubFilter() {
		this.subFilter = Accounting.createNewSubFilter()
	}

	@Mutation
	subFilterRequest() {
		this.statusFilter = RequestStatus.Loading
	}

	@Mutation
	subFilterSuccess() {
		this.statusFilter = RequestStatus.Success
	}

	@Mutation
	invoicesRequest() {
		this.statusList = RequestStatus.Loading
	}

	@Mutation
	initializeCounter() {
		let counter: Dictionary<number> = {}
		this.counter = counter
	}

	@Mutation
	invoicesSuccess(counter?: Dictionary<number>) {
		this.statusList = RequestStatus.Success
		this.counter = counter
	}

	@Mutation
	invoicesFailure() {
		this.statusList = RequestStatus.Failed
	}

	@Mutation
	remittanceRequest() {
		this.status = RequestStatus.Loading
		this.remittance = null
	}

	@Mutation
	remittanceSuccess(remittance: IRemittance) {
		this.status = RequestStatus.Success
		this.remittance = remittance
	}

	@Mutation
	remittanceFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	invoiceRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	invoiceSuccess(invoice: IInvoice) {
		this.status = RequestStatus.Success
		this.invoice = invoice
	}

	@Mutation
	invoiceFailure() {
		this.status = RequestStatus.Failed
		this.invoice = null
	}

	@Mutation
	remittancesRequest() {
		this.statusRemittanceList = RequestStatus.Loading
	}

	@Mutation
	remittancesSuccess(remittances: IRemittance[]) {
		this.statusRemittanceList = RequestStatus.Success
		if (!remittances || remittances.length === 0) return

		this.lastRemittance = remittances[0]
	}

	@Mutation
	remittancesFailure() {
		this.statusRemittanceList = RequestStatus.Failed
	}

	@Mutation
	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(invoice?: IInvoice) {
		this.statusSaving = RequestStatus.Success
		this.invoice = invoice
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	printRequest() {
		this.statusPrinting = RequestStatus.Loading
	}

	@Mutation
	printSuccess() {
		this.statusPrinting = RequestStatus.Success
	}

	@Mutation
	printFailure() {
		this.statusPrinting = RequestStatus.Failed
	}

	@Mutation
	saveRemittanceRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveRemittanceSuccess(remittance: IRemittance) {
		this.statusSaving = RequestStatus.Success
		this.remittance = remittance
	}

	@Mutation
	saveRemittanceFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	sendRequest() {
		this.statusSending = RequestStatus.Loading
	}

	@Mutation
	sendSuccess() {
		this.statusSending = RequestStatus.Success
	}

	@Mutation
	sendFailure() {
		this.statusSending = RequestStatus.Failed
	}

	@Action({rawError: true})
	async loadInvoice(payload: InvoiceLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.invoiceRequest()

		let service = new AccountingService()
		return service.getInvoice(payload.inv_id)
		.then(invoice => {
			invoice.recurring = !invoice.recurring ? Recurring.createNewRecurring(invoice.start_date) : invoice.recurring
			this.invoiceSuccess(invoice)
			return Promise.resolve(invoice)
		})
		.catch(error => {
			this.invoiceFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async sendInvoice(inv_id: string): Promise<any> {
		if (this.statusSending === RequestStatus.Loading) return

		this.sendRequest()

		let service = new AccountingService()
		return service.sendInvoice(inv_id)
		.then(() => {
			this.sendSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.sendFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveInvoice(payload: InvoiceActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		const {invoice} = payload
		this.saveRequest()

		let service = new AccountingService()
		return service.save(invoice)
		.then(newInvoice => {
			this.saveSuccess(newInvoice)
			return Promise.resolve(newInvoice)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteInvoice(inv_id: string): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		if (!inv_id || parseInt(inv_id) === -1) return

		this.deleteRequest()
		let service = new AccountingService()
		return service.deleteInvoice(inv_id)
		.then(() => {
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadInvoices(payload: AccountingActionPayload): Promise<any> {
		this.invoicesRequest()

		let service = new AccountingService()
		return service.getInvoiceList(payload.start, payload.end, payload.ofi_id, payload.usr_id, payload.without_remittance)
		.then(
			invoices => {
				for (let mode in PaymentMode) {
					let id: number = Number(mode)
					if (isNaN(id)) continue

					let count: number = this.counter[mode.toString()] || 0
					this.counter[mode.toString()] = count + (invoices || []).filter(i => { return i.mode === id }).length
				}
				this.invoicesSuccess(this.counter)

				return Promise.resolve(invoices)
			}
		)
		.catch(error => {
			this.invoicesFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadInvoicesByPatId(patId: string): Promise<any> {
		if (this.statusList === RequestStatus.Loading) return

		this.invoicesRequest()

		let service = new AccountingService()
		return service.getInvoiceListByPatient(patId)
		.then(
			invoices => {
				this.invoicesSuccess()
				return Promise.resolve(invoices)
			}
		)
		.catch(error => {
			this.invoicesFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async checkInvoices(payload: CheckActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new AccountingService()
		return service.checkInvoices(payload.list, payload.checked)
		.then(() => {
			this.saveSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async bankInvoices(payload: BankActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new AccountingService()
		return service.bankInvoices(payload.list, payload.banked)
		.then(() => {
			this.saveSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteInvoices(list: string[]): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new AccountingService()
		return service.deleteInvoices(list)
		.then(() => {
			this.saveSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async sendDocuments(filter: Record<string, string>): Promise<any> {
		if (this.statusPrinting === RequestStatus.Loading) return

		this.printRequest()

		let service = new AccountingService()
		return service.sendDocuments(filter)
			.then(() => {
				this.printSuccess()
				return Promise.resolve()
			})
			.catch(error => {
				this.printFailure()
				return Promise.reject(error)
			})
	}

	@Action({rawError: true})
	async loadRemittance(payload: RemittanceActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.remittanceRequest()

		let service = new AccountingService()
		return service.getRemittance(payload.rem_id)
		.then(
			remittance => {
				this.remittanceSuccess(remittance)
				return Promise.resolve(remittance)
			}
		)
		.catch(error => {
			if (error.status === 406) {
				router.push({ name: 'accounting' })
				return
			}
			this.remittanceFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadRemittances(): Promise<any> {
		if (this.statusRemittanceList === RequestStatus.Loading) return

		this.remittancesRequest()

		let service = new AccountingService()
		return service.getRemittanceList()
		.then(
			remittances => {
				this.remittancesSuccess(remittances)
				return Promise.resolve(remittances)
			}
		)
		.catch(error => {
			this.remittancesFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveRemittance(remittance: IRemittance): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRemittanceRequest()

		let service = new AccountingService()
		return service.saveRemittance(remittance)
		.then(newRemittance => {
			this.saveRemittanceSuccess(newRemittance)
			return Promise.resolve(newRemittance)
		})
		.catch(error => {
			this.saveRemittanceFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteRemittance(rem_id: string): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		if (!rem_id || parseInt(rem_id) === -1) return

		this.deleteRequest()
		let service = new AccountingService()
		return service.deleteRemittance(rem_id)
		.then(() => {
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}
}

export const accounting = getModule(AccountingModule)
// export const accounting = new AccountingModule({ store, name: "accounting" })
