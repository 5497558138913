<container>
	<aside-main/>

	<master>

		<central>
			<div class="wrapper marg-v">

				<router-view @navigate="onNavigate"/>

			</div>
		</central>

		<context v-if="canDisplayContext">
			<context-patients v-if="isPatientsView"/>
			<context-consultations v-if="isConsultationsView"/>
			<context-planning v-if="isPlanningView"/>
			<context-planning-online v-if="isPlanningOnlineView"/>
			<context-accounting v-if="isAccountingView"/>
			<context-colleagues v-if="isColleaguesView"/>
		</context>

	</master>

</container>
