<div v-if="!!coordinates">

	<read-only v-if="readOnly || isAnonymous" :coordinates="coordinates"/>
	<div v-else>
		<h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.coordinates.title') }}</h2>

		<div class="notice-warning">
			<p class="text-left" v-if="!coordinates.allow_access">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				<i18n path="vm.root.patients.patient.coordinates.rgpd.text" tag="span">
					<abbr :title="$t('vm.root.patients.patient.coordinates.rgpd.abbr.title')">
						{{ $t('vm.root.patients.patient.coordinates.rgpd.abbr.label') }}
					</abbr>
					<strong>{{ $t('vm.root.patients.patient.coordinates.rgpd.strong') }}</strong>
				</i18n>
			</p>
			<switch-frame id="rgpd" v-model="coordinates.allow_access" checked-icon="share"
				:title="$t('vm.root.patients.patient.coordinates.rgpd.allow-access.title')">
				{{ $t('vm.root.patients.patient.coordinates.rgpd.allow-access.label') }}
			</switch-frame>
		</div>

		<div class="grid-2">
			<input-frame :auto-focus="true" type="text" id="last-name" v-model="coordinates.last_name"
				:label="$t('vm.root.patients.patient.coordinates.last-name.label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.last-name.placeholder')"
				:error-test="hasLastNameError" :error-msg="$t('vm.root.patients.patient.coordinates.last-name.error')"
				:title="$t('vm.root.patients.patient.coordinates.last-name.title')"/>

			<input-frame type="text" id="first-name" v-model="coordinates.first_name"
				:label="$t('vm.root.patients.patient.coordinates.first-name.label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.first-name.placeholder')"
				:error-test="hasFirstNameError" :error-msg="$t('vm.root.patients.patient.coordinates.first-name.error')"
				:title="$t('vm.root.patients.patient.coordinates.first-name.title')"/>

			<input-date-frame v-model='coordinates.birth_date' id="birth-date" :type="birthDateType"
				:label="$t('vm.root.patients.patient.coordinates.birth-date.label')"
				:title="$t('vm.root.patients.patient.coordinates.birth-date.title')"
				:placeholder="$t('vm.root.patients.patient.coordinates.birth-date.placeholder')"
				:error-test="hasBirthDateError" :error-msg="$t('vm.root.patients.patient.coordinates.birth-date.error')"/>

			<input-nir-frame id="nir" :nir="coordinates.nir"
				:label="$t('vm.root.patients.patient.coordinates.nir.label')"
				:error-test="hasNirError" :error-msg="$t('vm.root.patients.patient.coordinates.nir.error')"
				:title="$t('vm.root.patients.patient.coordinates.nir.title')"/>

			<input-phone-frame id="phone1" :phone="coordinates.phone1"
				:label="$t('vm.root.patients.patient.coordinates.phone1.label')"
				:error-test="coordinates.phone1.error" :error-msg="$t('vm.root.patients.patient.coordinates.phone1.error')"
				:title="$t('vm.root.patients.patient.coordinates.phone1.title')"/>

			<input-phone-frame id="phone2" :phone="coordinates.phone2"
				:label="$t('vm.root.patients.patient.coordinates.phone2.label')"
				:error-test="coordinates.phone2.error" :error-msg="$t('vm.root.patients.patient.coordinates.phone2.error')"
				:title="$t('vm.root.patients.patient.coordinates.phone2.title')"/>

			<togglebox-frame id="gender" :items="genderItems" v-model="coordinates.gender"
				:label="$t('vm.root.patients.patient.coordinates.gender.label')"
				:title="$t('vm.root.patients.patient.coordinates.gender.title')"/>

			<input-frame type="email" id="email" v-model="coordinates.email"
				:label="$t('vm.root.patients.patient.coordinates.email.label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.email.placeholder')"
				:title="$t('vm.root.patients.patient.coordinates.email.title')"/>

			<input-address-frame id="address" :address="coordinates.address" class="grid-item-full"
				:label="$t('vm.root.patients.patient.coordinates.address.label')"
				:title="$t('vm.root.patients.patient.coordinates.address.title')"/>

			<dropdown-editable-frame id="profession" :searcher="getProfessionList" :multiple="true" :taggable="true" v-model="coordinates.professions"
				:label="$t('vm.root.patients.patient.coordinates.profession.label')"
				:no-options-label="$t('vm.root.patients.patient.coordinates.profession.no-options-label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.profession.placeholder')"
				:title="$t('vm.root.patients.patient.coordinates.profession.title')"/>

			<dropdown-editable-frame id="reference" :searcher="getReferenceList" :taggable="true" v-model="coordinates.reference"
				:label="$t('vm.root.patients.patient.coordinates.reference.label')"
				:no-options-label="$t('vm.root.patients.patient.coordinates.reference.no-options-label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.reference.placeholder')"
				:title="$t('vm.root.patients.patient.coordinates.reference.title')"/>

			<dropdown-frame id="situation" v-model="coordinates.situation" :items="situationItems"
				:label="$t('vm.root.patients.patient.coordinates.situation.label')"
				:title="$t('vm.root.patients.patient.coordinates.situation.title')"/>

			<dropdown-frame id="number-of-children" v-model="coordinates.number_of_children" :items="childrenItems"
				:label="$t('vm.root.patients.patient.coordinates.number-of-children.label')"
				:title="$t('vm.root.patients.patient.coordinates.number-of-children.title')"/>

			<togglebox-frame id="level" :items="levelItems" v-model="coordinates.level"
				:label="$t('vm.root.patients.patient.coordinates.level.label')"
				:title="$t('vm.root.patients.patient.coordinates.level.title')"/>

			<textarea-frame id="notes" v-model="coordinates.notes" rows="1"
				:label="$t('vm.root.patients.patient.coordinates.notes.label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.notes.placeholder')"
				:title="$t('vm.root.patients.patient.coordinates.notes.title')"/>

			<textarea-frame id="habitus" v-model="coordinates.habitus" class="grid-item-full"
				:label="$t('vm.root.patients.patient.coordinates.habitus.label')"
				:placeholder="$t('vm.root.patients.patient.coordinates.habitus.placeholder')"
				:title="$t('vm.root.patients.patient.coordinates.habitus.title')"/>
		</div>

		<hr>
		<h2>{{ $t('vm.root.patients.patient.coordinates.subtitle') }}</h2>

		<search-colleague/>

		<div v-if="coordinates.colleagues.currents.length > 0" class="grid-3 vertical-gap marg-v">
			<colleague-item :colleague="colleague" :can-unselect="!readOnly" v-for="colleague in coordinates.colleagues.currents"/>
		</div>

		<hr>
		<h2>{{ $tc('vm.root.patients.patient.forms.title', forms.length) }}</h2>
		<div class="notice-info" v-if="forms.length === 0">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				{{ $t('vm.root.patients.patient.forms.none.title') }}
				<i18n path="vm.root.patients.patient.forms.none.description.coordinates-text" tag="span">
					<router-link :to="{name: 'parameters-forms'}" :title="$t('vm.root.patients.patient.forms.none.description.title')">{{ $t("vm.root.patients.patient.forms.none.description.link") }}</router-link>
				</i18n>
			</p>
		</div>
		<custom-form v-else v-for="form in forms" :key="form.id" :form="form"/>
	</div>
</div>
