export const patientItem = {
    'open': "Ouvrir le dossier de {last-name} {first-name}",
    'no-phone': "Téléphone non renseigné",
    'no-email': "Email non renseigné",
    'no-profession': "Profession non renseignée",
    blacklisted: "blacklisté",
    deceased: "décédé",
    unselect: {
        label: "dissocier",
        title: "Dissocier ce dossier patient de ce rendez-vous"
    }
}