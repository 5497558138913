<div>
	<collapsible-help id="referral-information" :title="$t('vm.root.user.referral.title')" tag="h1">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<i18n path="vm.root.user.referral.help1.text" tag="span">
					<strong>{{ $t('vm.root.user.referral.help1.strong') }}</strong>
				</i18n>
			</p>
			<p class="text-left">{{ $t('vm.root.user.referral.help2') }}</p>
		</div>
	</collapsible-help>

	<template v-if="isGodfather">
		<div class="p-like">
			<div>{{ $t('vm.root.user.referral.referral-code') }}</div>

			<div class="input-like">
				<div class="text-lg text-center text-bold select">
					{{ code }}
				</div>
			</div>
		</div>

		<div class="p-like">
			<div class="childs-space-between">
				<div>{{ $t('vm.root.user.referral.referral-url') }}</div>
				<div class="is-pushed-right text-sm">
					{{ $t('vm.root.user.referral.share-by') }}
					<button class="btn btn--link-like" @click="shareByEmail"
						:title="$t('vm.root.user.referral.share-by-email.title')">{{ $t('vm.root.user.referral.share-by-email.label') }}</button>,
					<button class="btn btn--link-like" @click="shareBySocialNetwork('https://facebook.com/sharer/sharer.php?u=', 'quote')"
						:title="$t('vm.root.user.referral.share-by-facebook.label')">{{ $t('vm.root.user.referral.share-by-facebook.label') }}</button>,
					<button class="btn btn--link-like" @click="shareBySocialNetwork('https://twitter.com/intent/tweet/?via=osteo2ls&url=', 'text')"
						:title="$t('vm.root.user.referral.share-by-twitter.label')">{{ $t('vm.root.user.referral.share-by-twitter.label') }}</button>
				</div>
			</div>

			<div class="input-like">
				<div class="text-center text-bold text-break select">
					https://app.osteo2ls.com/login/register/{{ code }}
				</div>
			</div>
		</div>

		<div class="p-like">
			<div>{{ $t('vm.root.user.referral.current-total') }}</div>

			<div class="input-like">
				<div class="text-lg text-center text-bold">
					{{ $tc('vm.root.user.referral.point', point) }}
				</div>
			</div>
		</div>
	</template>

	<i18n v-else path="vm.root.user.referral.none.text" tag="p">
		<router-link :to="to" :title="$t('vm.root.user.referral.none.link-title')">{{ $t("vm.root.user.referral.none.link-label") }}</router-link>
	</i18n>

	<section>
		<h2>
            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
            </svg>
            {{ $t('vm.root.user.referral.boni') }}
        </h2>
		<table class="data-table">
			<thead>
				<tr>
					<th class="w80 text-left" scope="col">{{ $t('vm.root.user.referral.header.bonus') }}</th>
					<th class="w20 text-center" scope="col">{{ $t('vm.root.user.referral.header.get') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="gift in gifts" :key="`${gift.type}-${gift.value}`">
					<th class="text-left text-normal" scope="row">{{ gift.type | giftPack(gift.value) }}</th>
					<td>
						<button type="button" class="btn btn-primary is-full-width" :disabled="isApplying || point < gift.point" @click="apply(gift)"
                        :title="$t('vm.root.user.referral.get', {point: $tc('vm.root.user.referral.point', gift.point), gift: $options.filters.giftPack(gift.type, gift.value)})">
							<spinner v-if="isApplyingGift(gift)" class="text-primary svg-middle"/>
							<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
							</svg>
							{{ $tc('vm.root.user.referral.point', gift.point) }}
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</div>
