import Render from '@Views/components/frame/input-password-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Common } from '@Models/index'

@Render
@Component({})
export class InputPasswordFrame extends Vue {

    password: string = ""
    private random: string = ''

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) autoFocus: boolean
    @Prop({default: true}) randomId: boolean
    @Prop() id: string
    @Prop() label: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() errorMsg: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }

    mounted() {
        this.password = this.value
    }

    created() {
		this.random = Common.generateId()
    }
    
    handleInput(password: any) {
        this.password = password
        this.$emit('input', password)
    }

    focus() {
        (this.$refs[this.inputId] as any).focus()
    }
}