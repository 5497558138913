export const mainSchedule = {
    title: "Horaires du cabinet",
    help1: {
        text: "Définissez ci-dessous les horaires d'ouverture de ce cabinet en cochant les demi-journées ouvrées et en indiquant leurs heures de début et de fin. Pour {0}, indiquez les mêmes horaires pour le matin et l'après-midi ou une heure de fin de matinée égale à l'heure de début d'après-midi.",
        strong: "une journée en continue"
    },
    help2: {
        text: "Par exemple, pour un mardi en continue de 09:00 jusqu'à 19:00, cochez les cases {0} et {1} et indiquez {2} dans les 2 champs concernés. Ou saisissez {3} pour le matin et {4} pour l'après-midi.",
        strong1: "mardi matin",
        strong2: "mardi après-midi",
        strong3: "09:00 - 19:00",
        strong4: "09:00 - 13:00",
        strong5: "13:00 - 19:00"
    },
    help3: {
        text: "Dans le cadre d'une utilisation du planning en ligne ou d'une collaboration avec un télésecrétariat, et si {0}, vous pourrez également {1}. L'information sera alors disponible pour vos patients et/ou le télésecrétariat.",
        link: {
            label: "vous travaillez à plusieurs au sein de votre cabinet",
            title: "Gérez les accès aux données liées à votre cabinet"
        },
        strong: "indiquer qui travaille au cabinet pour telle ou telle ½ journée"
    },
    user: {
        label: "Praticien en charge de cette ½ journée",
        title: "Praticien qui recevra les patients lors de cette ½ journée"
    },
    morning: "Matin",
    afternoon: "Après-midi",
    closed: "Fermé"
}