import Render from '@Views/root/patients/patient/consultation/examens.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ReadOnly } from './read-only'
import { IUser, INotification, INotificationAction, IOffice, IDisplay, IBreadcrumbItem, IConsultation, IExamen, IEva, IMeasurement, ITodayInfos } from '@Store/types'
import { Tag, Debouncer } from '@Models/index'
import { InputDateType, IDropdownItem } from '@Components/index'
import { Patient, Sorter } from '@Models/index'
import { Profile } from '@Enums/index'
import { breadcrumb, configuration, notif, office, patient, user } from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnly
	}
})
export class Examens extends Vue {

	autocompleteItems: {text: string}[] = []
	imc: string = ''
	userItems: IDropdownItem[] = []
	private debouncer: Debouncer = null
	private tag: string = null
	private imcItem: {value: number, label: string, level: string} = null

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	//#region Getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get eva(): IEva {
		return this.consultation.eva
	}
	get measurement(): IMeasurement {
		return this.consultation.measurement
	}
	get examen(): IExamen {
		return this.consultation.examen
	}
	get display(): IDisplay {
		return configuration.configuration && configuration.configuration.display
	}
	get hasUserError(): boolean {
		return this.submitted && (!this.consultation.usr_id || parseInt(this.consultation.usr_id) === -1)
	}
	get hasOfficeError(): boolean {
		return this.submitted && (!this.consultation.ofi_id || parseInt(this.consultation.ofi_id) === -1)
	}
	get hasActDateError(): boolean {
		return this.submitted && !this.consultation.act_date
	}
	get hasReasonError(): boolean {
		return this.submitted && (!this.consultation.reason || this.consultation.reason.length === 0 || this.reasonLength > 255)
	}
	get reasonError(): string {
		let tag: string = 'vm.root.patients.patient.examens.reason.error'
		if (this.reasonLength > 255) tag = 'vm.root.patients.patient.examens.reason.too-long'

		return this.$i18n.t(tag).toString()
	}
	get actDateType(): InputDateType {
		return InputDateType.Calendar
	}
	get firstGrid(): string {
		let count: number = 0
		if (this.display.with_evolution_state) count++
		if (this.display.with_observations) count++

		return count >= 2 ? `grid-${count}`: ''
	}
	get secondGrid(): string {
		let count: number = 0
		if (this.display.with_eva_before) count++
		if (this.display.with_eva_after) count++

		return count >= 2 ? `grid-${count}`: ''
	}
	get thirdGrid(): string {
		let count: number = 0
		if (this.display.with_diagnostic) count++
		if (this.display.with_tests) count++
		if (this.display.with_treatment) count++

		return count >= 2 ? `grid-${count}`: ''
	}
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		let ofi_id: string = parseInt(this.consultation.ofi_id) === -1 ? undefined : this.consultation.ofi_id
		let offices: IOffice[] = office.offices
		for (let o of offices) {
			if (!office.isActive(o) && o.id !== ofi_id) continue

			result.push({value: o.id, label: o.coordinates.name})
		}

		if (result.length === 0) {
			let notification: INotification = this.getNotification()
			notif.info(notification)
		} else {
			notif.clear()
		}

		return result
	}
	get imcLevel(): string {
		if (!this.imcItem) return 'lightgrey'
		return this.imcItem.level
	}
	get imcTitle(): string {
		if (!this.imcItem) return ''
		return this.imcItem.label.toLowerCase()
	}
	private get imcList(): {value: number, label: string, level: string}[] {
		return [
			{value: 16.5, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-1').toString(), level: "1"},
			{value: 18.5, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-2').toString(), level: "2"},
			{value: 25, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-3').toString(), level: "3"},
			{value: 30, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-4').toString(), level: "4"},
			{value: 35, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-5').toString(), level: "5"},
			{value: 40, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-6').toString(), level: "6"},
			{value: 40, label: this.$i18n.t('vm.root.patients.patient.examens.imc.level-7').toString(), level: "7"},
		]
	}
	private get reasonLength(): number {
		return Patient.getConsultationReasonLength(this.consultation.reason)
	}
	private get user(): IUser {
		return user.user
	}
	//#endregion

	created() {
		if (parseInt(this.consultation.id) !== -1) {
			this.updateUserItems()
			return
		}

		let offices: IOffice[] = office.offices.filter(o => { return office.isActive(o) })
		if (offices.length === 0) return

		let ofiId: string = !!patient.todayInfos ? patient.todayInfos.ofi_id : '-1'
		if (!!patient.todayInfos && !!patient.todayInfos.ofi_id) {
			let office: IOffice = offices.find(o => { return o.id === ofiId })
			if (!!office) {
				this.consultation.ofi_id = ofiId
				this.updateUserItems()

				return
			}
		}

		this.consultation.ofi_id = offices[0].id
		this.updateUserItems()

		let sorter: Sorter = new Sorter()
		sorter.nearest(offices, this.user.id).then((nearest) => {
			this.consultation.ofi_id = nearest
			this.updateUserItems()
		})
	}

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
		this.updateImc()
		this.debouncer = new Debouncer(this.getReasonList, 600)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	@Watch('consultation.ofi_id')
	updateUserItems(): void {
		this.userItems = []
		if (this.user.profile === Profile.Main || this.user.profile === Profile.Demo) {
			if (parseInt(this.consultation.id) !== -1) return

			this.consultation.usr_id = this.user.id
			return
		}
		if (!this.consultation.ofi_id || parseInt(this.consultation.ofi_id) === -1) return

		let _office = office.offices.find(o => { return o.id === this.consultation.ofi_id})
		if (!_office) return

		this.userItems = _office.links
			.filter(user => { return user.profile === Profile.Main })
			.map(user => { return {value: user.id, label: `${user.coordinates.first_name} ${user.coordinates.last_name}`} })

		if (this.userItems.length === 0) return
		this.consultation.usr_id = this.userItems[0].value
	}

	private getNotification(): INotification {
		let action: INotificationAction = {
			label: this.$i18n.t('vm.root.patients.patient.examens.office-notification.action').toString(),
			callback: (data: any) => {
				this.$router.push({name: 'offices'})
				return true
			}
		}

		let message: string = this.$i18n.t('vm.root.patients.patient.examens.office-notification.label').toString()

		return { message: message, delay: false, actions: [action], canClose: false }
	}

	@Watch('measurement.size')
	@Watch('measurement.weight')
	private updateImc() {
		if (!this.measurement || !this.measurement.size  || !this.measurement.weight) {
			this.imc = ''
			return
		}

		let imc: number = 10000 * this.measurement.weight / (this.measurement.size * this.measurement.size)
		imc = parseFloat(Math.max(9, Math.min(45, imc)).toFixed(1))

		let imcItem: {value: number, label: string, level: string} = this.imcList[0]
		if (imc >= this.imcList[6].value) {
			imcItem = this.imcList[6];
		} else {
			for (let item of this.imcList) {
				if (imc < item.value) {
					imcItem = item
					break
				}
			}
		}

		this.imcItem = imcItem
		this.imc = `${imc} - ${imcItem.label}`
	}

	searchReason(tag: string) {
		this.tag = tag
		this.debouncer.start(!!this.tag && this.tag.length >= 2)
	}

	getReasonList(tag: string) {
		return Tag.getReason(tag)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}
