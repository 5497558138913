import {cloneDeep, isEqual} from 'lodash'
import {i18n} from '@I18n/index'
import {router} from '@Router/index'
import {
	IAntecedent,
	IAttachment,
	IConfiguration,
	IConsultation,
	ICranioSacral,
	IEva,
	IExamen,
	IExclusionTest,
	IExportFilter,
	IFollowUp,
	IMeasurement,
	IMember,
	INotification,
	INotificationAction,
	IPatient,
	IPatientHistoric,
	IPatientSearch,
	IPediatrics,
	IReason,
	ISchema,
	ISphere, IWalton
} from "@Store/types"
import {Phone} from './phone'
import {
	Alimentation,
	ArticulationMove,
	AttachmentType,
	DirectionHorizontal,
	DirectionVertical,
	FollowUpLevel,
	FormType,
	Gender,
	Level,
	MemberType,
	MimeType,
	PhoneType,
	Situation,
	StrainMove
} from '@Enums/index'
import {Common} from './common'
import {Address} from './address'
import {Nir} from './nir'
import {Form} from './form'
import {NirHelper} from '@Helpers/index'
import {Relaunch} from './relaunch'

export class Patient {
	public static toPatient(patient: IPatientSearch): IPatient {
		let result: IPatient = Patient.createNewPatient(null)
		result.is_new = false
		result.id = patient.id
		result.blacklisted = patient.blacklisted
		result.deceased = patient.deceased
		result.coordinates.last_name = patient.last_name
		result.coordinates.first_name = patient.first_name
		result.coordinates.gender = patient.gender
		result.coordinates.birth_date = patient.birth_date
		result.coordinates.email = patient.email
		result.coordinates.phone1.number = patient.phone

		return result
	}

	public static toPatientSearch(patient: IPatient): IPatientSearch {
		return {
			id: patient.is_new ? '-1' : patient.id,
			last_name: patient.coordinates.last_name,
			first_name: patient.coordinates.first_name,
			gender: patient.coordinates.gender,
			blacklisted: patient.blacklisted,
			birth_date: !!patient.coordinates.birth_date ? patient.coordinates.birth_date : null,
			deceased: patient.deceased,
			email: patient.coordinates.email,
			phone: !!patient.coordinates.phone1 ? patient.coordinates.phone1.number : '',
			profession: !!patient.coordinates.professions ? patient.coordinates.professions.join(',') : ''
		}
	}

	public static historicToPatientSearch(patient: IPatientHistoric): IPatientSearch {
		return {
			id: patient.pat_id,
			last_name: patient.last_name,
			first_name: patient.first_name,
			gender: Gender.Man,
			blacklisted: patient.blacklisted,
			birth_date: null,
			deceased: patient.deceased,
			email: patient.email,
			phone: !!patient.phone ? patient.phone : '',
			profession: ''
		}
	}

	public static toPatientHistoric(patient: IPatient): IPatientHistoric {
		return {
			pat_id: patient.id,
			first_name: patient.coordinates.first_name,
			last_name: patient.coordinates.last_name,
			email: patient.coordinates.email,
			phone: !!patient.coordinates.phone1 ? patient.coordinates.phone1.number : '',
			blacklisted: patient.blacklisted,
			deceased: patient.deceased,
			time: patient.read_date
		}
	}

	public static queryToSearch(str: string) : string {
		if (!str || !str.trim()) return ''
		if (/\s/.test(str)) return `"${str}"`
		return str
	}

	public static getDefaultExportFilter(): IExportFilter {
		return {
			first_name: '',
			last_name: '',
			ofi_id: '-1',
			blacklisted: true,
			deceased: true,
			duplicate: false
		}
	}

	public static createNewPatient(configuration: IConfiguration): IPatient {
		return {
			is_new: true,
			id: Common.generateId(),
			allow_sharing: true,
			read_date: new Date(),
			blacklisted: false,
			deceased: false,
			rabbits: [],
			unpaids: [],
			relaunch: Relaunch.createNewRelaunch(configuration),
			coordinates: {
				last_name: '',
				first_name: '',
				birth_date: null,
				without_birth_date: true,
				notes: '',
				level: Level.Low,
				gender: 0,
				nir: Nir.createDefaultNir(),
				habitus: '',
				family_antecedents: '',
				number_of_children: -1,
				situation: Situation.None,
				professions: [],
				reference: [],
				phone1: Phone.createDefaultPhone(),
				phone2: Phone.createDefaultPhone(PhoneType.Fix),
				address: Address.createDefaultAddress(),
				colleagues: {currents: [], deleted: []},
				email: '',
				forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Coordinates) : [],
			},
			pediatrics: Patient.createNewPediatrics(configuration),
			antecedents: {
				currents:[],
				deleted:[]
			},
			spheres: {
				currents:[],
				deleted:[]
			},
			consultations: {
				currents:[],
				deleted:[]
			},
			reasons: {
				currents:[],
				deleted:[]
			},
			attachments: {
				currents: [],
				deleted: []
			},
			family: {
				currents: [],
				deleted: []
			}
		}
	}

	public static createNewPediatrics(configuration: IConfiguration): IPediatrics {
		return {
			antecedents_parents: '',
			number_of_children: -1,
			remarks1: '',

			with_contraction: false,
			contraction: '',
			with_immobilisation: false,
			immobilisation: '',
			with_strapping: false,
			strapping: '',
			with_accident: false,
			accident: '',
			with_disease: false,
			disease: '',
			with_gained_weight: false,
			gained_weight: '',
			with_tranquilizer: false,
			tranquilizer: '',
			remarks2: '',

			with_anesthesia: false,
			anesthesia: '',
			duration: 0,
			eutocic: false,
			obstructed: false,
			episiotomy: false,
			forceps: false,
			fundal: false,
			suction_cup: false,
			finger: false,
			with_accelerators: false,
			accelerators: '',
			with_caesarean: false,
			caesarean: '',
			presentation: '',
			remarks3: '',

			with_premature: false,
			premature: '',
			weight: 0,
			size: 0,
			apgar1: 0,
			with_apgar1: true,
			apgar3: 0,
			with_apgar3: false,
			apgar5: 0,
			with_apgar5: true,
			apgar10: 0,
			with_apgar10: true,
			cyanotic: false,
			alimentation: Alimentation.Milk,
			with_reanimation: false,
			reanimation: '',
			with_scream: true,
			scream: '',
			with_deformed_skull: false,
			deformed_skull: '',
			remarks4: '',

			suckle: false,
			with_reflux: false,
			reflux: '',
			with_allergies: false,
			allergies: '',
			sleep: '',
			state: '',
			remarks5: '',

			forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Pediatrics) : [],
		}
	}

  	public static createNewAttachment(createId: string): IAttachment {
		return {
			id: '-1',
			creator_id: undefined,
			type: AttachmentType.File,
			name: '',
			description: '',
			file: '',
			url: '',
			mime: MimeType.None,
			act_date: new Date(),
			create_id: createId
		}
	}

  	public static createNewReason(createId: string, configuration: IConfiguration): IReason {
		return {
			id: '-1',
			title: i18n.t("models.patient.new-reason").toString(),
			topography: '',
			description: '',
			signs: '',
			chronology: '',
			factors: '',
			forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Reason) : [],
			create_id: createId
		}
	}

	public static createNewExclusionTest(createdId: string): IExclusionTest {
		return {
			id: '-1',
			edit: true,
			created_id: createdId,
			name: '',
			positive: true,
			comment: ''
		}
	}

	public static createNewSphere(createId: string, configuration: IConfiguration, type?: string): ISphere {
		let sphere: ISphere = {
			id: '-1',
			creator_id: undefined,
			type: !!type ? type : '',
			title: '',
			description: '',
			important: false,
			running: false,
			periodic: false,
			event_date: new Date(),
			end_date: new Date(),
			forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Sphere) : [],
			date_modif: new Date(),
			create_id: createId
		}
		sphere.old_type = sphere.type
		sphere.old_event_date = sphere.event_date
		return sphere
	}

	public static createNewAntecedent(createId: string, configuration: IConfiguration, type?: string): IAntecedent {
		let antecedent: IAntecedent = {
			id: '-1',
			creator_id: undefined,
			type: !!type ? type : '',
			title: '',
			description: '',
			important: false,
			running: false,
			periodic: false,
			event_date: new Date(),
			end_date: new Date(),
			forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Antecedent) : [],
			date_modif: new Date(),
			create_id: createId
		}
		antecedent.old_type = antecedent.type
		antecedent.old_event_date = antecedent.event_date
		return antecedent
	}

	public static createNewConsultation(createId: string, configuration: IConfiguration, measurement: IMeasurement): IConsultation {
		let start_schedule: Date = new Date()
		start_schedule.setMinutes(start_schedule.getMinutes() - (start_schedule.getMinutes() % 5))
		let end_schedule: Date = new Date(start_schedule)
		end_schedule.setMinutes(end_schedule.getMinutes() + 45)

		let newMeasurement: IMeasurement = Patient.createNewMeasurement()
		if (!!measurement) {
			newMeasurement.size = measurement.size
			newMeasurement.weight = measurement.weight
		}

		return {
			id: '-1',
			creator_id: undefined,
			usr_id: '-1',
			ofi_id: '-1',
			inv_id: '-1',
			reason: [],
			act_date: new Date(),
			start_schedule: start_schedule,
			end_schedule: end_schedule,
			rea_id: '-1',
			cranio: Patient.createNewCranio(),
			forms: !!configuration ? Form.convertConfigurationForms(configuration.forms.currents, FormType.Consultation) : [],
			exclusion_tests: {currents: [], deleted: []},
			examen: Patient.createNewExamen(),
			eva: Patient.createNewEva(),
			schema: Patient.createNewSchema(),
			measurement: newMeasurement,
			walton: Patient.createNewWalton(),
			follow_up: Patient.createNewFollowUp(configuration),
			create_id: createId
		}
	}

	private static createNewFollowUp(configuration: IConfiguration): IFollowUp {
		return {
			delay: configuration.follow_up_delay,
			type: configuration.follow_up_type,
			level: FollowUpLevel.None,
			back: '',
			read: false
		}
	}

	public static createNewCranio(): ICranioSacral {
		return {
			rhythm_mrp_cranial: 0,
			amplitude_mrp_cranial: '',
			force_mrp_cranial: '',
			rhythm_mrp_sacred: 0,
			amplitude_mrp_sacred: '',
			force_mrp_sacred: '',
			ssb: ArticulationMove.None,
			torsion: DirectionHorizontal.None,
			sbr: DirectionHorizontal.None,
			vertical_strain: DirectionVertical.None,
			cause_vertical_strain: StrainMove.None,
			side_strain: DirectionHorizontal.None,
			cause_side_strain: StrainMove.None,
			compression: false,
			condylar_shares: '',
			c0c1: '',
			eyes: '',
			roof: '',
			mandible: ''
		}
	}

	public static createNewExamen(): IExamen {
		return {
			evolution: '',
			observation: '',
			diagnostic: '',
			tests: '',
			treatment: ''
		}
	}

	public static getLastMeasurement(consultations: IConsultation[]): IMeasurement {
		if (!consultations || consultations.length === 0) return null

		return consultations[0].measurement
	}

	public static createNewMeasurement(): IMeasurement {
		return {
			size: undefined,
			weight: undefined
		}
	}

	public static createNewEva(): IEva {
		return {
			before: 0,
			after: 0
		}
	}

	public static createNewWalton(): IWalton {
		return {
			data_1: 0,
			data_2: 0,
			data_3: 0,
			data_4: 0,
			data_5: 0,
			data_6: 0
		}
	}

	public static createNewSchema(): ISchema {
		return {
			description: '',
			face: [],
			back: [],
			left: [],
			right: []
		}
	}

	public static createNewMember(patient: IPatientSearch): IMember {
		return {
			id: '-1',
			link_id: patient.id,
			gender: patient.gender,
			deceased: patient.deceased,
			type: MemberType.None,
			last_name: patient.last_name,
			first_name: patient.first_name,
			birth_date: patient.birth_date,
			email: patient.email,
		}
	}

	public static getConsultationReasonLength(reason: string[]): number {
		let list: string[] = reason || []
		return list.reduce((sum, str) => sum + str.length, list.length)
	}

	public static isValidConsultation(consultation: IConsultation): boolean {
		if (!consultation.reason || consultation.reason.length === 0 || !consultation.act_date) return false
		if (Patient.getConsultationReasonLength(consultation.reason) > 255) return false
		return parseInt(consultation.ofi_id) !== -1;
	}

	public static isEmptyEva(eva: IEva): boolean {
		return Patient.isEmpty(eva, Patient.createNewEva())
	}

	public static isEmptyExamen(examen: IExamen): boolean {
		return Patient.isEmpty(examen, Patient.createNewExamen())
	}

	public static isEmptyCranio(cranio: ICranioSacral): boolean {
		return Patient.isEmpty(cranio, Patient.createNewCranio())
	}

	public static getPatient(patient: IPatient, oldPatient: IPatient): IPatient {
		let newPatient = cloneDeep(patient)
		newPatient.coordinates['update'] = !isEqual(newPatient.coordinates, oldPatient.coordinates)
		newPatient.pediatrics['update'] = !isEqual(newPatient.pediatrics, oldPatient.pediatrics)
		newPatient.relaunch['update'] = !isEqual(newPatient.relaunch, oldPatient.relaunch)
		Common.updateItemList(newPatient.reasons.currents, oldPatient.reasons.currents)
		Common.updateItemList(newPatient.consultations.currents, oldPatient.consultations.currents)
		Common.updateItemList(newPatient.antecedents.currents, oldPatient.antecedents.currents)
		Common.updateItemList(newPatient.spheres.currents, oldPatient.spheres.currents)
		Common.updateItemList(newPatient.attachments.currents, oldPatient.attachments.currents)
		let testList: IExclusionTest[] = []
		for(let consultation of newPatient.consultations.currents) {
			testList = [...testList, ...consultation.exclusion_tests.currents]
		}
		let oldTestList: IExclusionTest[] = []
		for(let consultation of oldPatient.consultations.currents) {
			oldTestList = [...oldTestList, ...consultation.exclusion_tests.currents]
		}
		Common.updateItemList(testList, oldTestList)
		return newPatient
	}

	public static getUpdateList(patient:IPatient): string[] {
		let result: string[] = []
		if (patient.is_new) {

			result.push(`${i18n.t("models.patient.coordinates")} ${i18n.tc("general.created-female", 1)}`)
			if (patient.relaunch['update']) {
				result.push(i18n.t("models.patient.relaunch-activated").toString())
			}
		} else {
			if (patient.coordinates['update']) {
				result.push(`${i18n.t("models.patient.coordinates")} ${i18n.tc("general.updated-female", 1)}`)
			}
			if (patient.relaunch['update']) {
				result.push(i18n.t("models.patient.relaunch-updated").toString())
			}
		}
		if (patient.pediatrics['update']) {
			result.push(`${i18n.t("models.patient.pediatrics")} ${i18n.tc("general.updated-female", 2)}`)
		}

		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'reasons', "reason"))
		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'consultations', "consultation", true))
		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'antecedents', "antecedent"))
		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'spheres', "sphere", true))
		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'attachments', "attachment", true))
		Common.addMessagesNotification(result, Patient.getNotificationList(patient, 'family', "member"))

		return result
	}

	public static checkAttachmentLink(link:string): boolean {
		const regex = /(https|http):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
		return !link || regex.test(link)
	}

	public static getNotification(patient:IPatient): INotification {
		let message: string = i18n.t("models.patient.patient-saved").toString()
		let messages: string[] = Patient.getUpdateList(patient)
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		return {message: message, delay: 5000, actions: [], canClose: true}
	}

	public static getErrorNotification(patient: IPatient): INotification | false {
		let messages: string[] = []
		let action: INotificationAction = undefined
		if (Patient.hasErrorCoordinates(patient)) {
			messages.push(i18n.t("models.patient.coordinates-incomplete").toString())
			action = {label: i18n.t("models.patient.complete").toString(), callback: () => router.push({name: 'patient-coordinates', params: {pat_id: patient.id}})}
		}
		let consultations: string[] = Patient.hasErrorConsultations(patient)
		if (consultations.length > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('patient', 'incomplete', 'consultation', consultations.length, true))
			if (!action)
				action = {label: i18n.t("models.patient.complete").toString(), callback: () => router.push({name: 'patient-consultation', params: {pat_id: patient.id, item_id: consultations[0]}})}
		}
		let antecedentCount: number = Patient.hasErrorAntecedents(patient)
		if (antecedentCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('patient', 'incomplete', 'antecedent', antecedentCount, false))
			if (!action)
				action = {label: i18n.t("models.patient.complete").toString(), callback: () => router.push({name: 'patient-antecedents', params: {pat_id: patient.id}})}
		}
		let sphereCount: number = Patient.hasErrorSpheres(patient)
		if (sphereCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('patient', 'incomplete', 'sphere', sphereCount, true))
			if (!action)
				action = {label: i18n.t("models.patient.complete").toString(), callback: () => router.push({name: 'patient-spheres', params: {pat_id: patient.id}})}
		}
		let attachments: string[] = Patient.hasErrorAttachments(patient)
		if (attachments.length > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('patient', 'incomplete', 'attachment', attachments.length, true))
			if (!action)
				action = {label: i18n.t("models.patient.complete").toString(), callback: () => router.push({name: 'patient-attachment', params: {pat_id: patient.id, item_id: attachments[0]}})}
		}

		if (messages.length === 0) return false

		let message: string = i18n.t("models.patient.patient-incomplete").toString()
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		let actions: INotificationAction[] = !!action ? [action] : []
		return {message: message, delay: false, actions: actions, canClose: true}
	}

	private static getNotificationList(patient:IPatient, list: string, label: string, female: boolean = false): string[] {
		let countUpdate: number = 0
		let countCreate: number = 0
		let countDeleted: number = patient[list].deleted.filter(item => parseInt(item.id) !== -1).length

		for(let item of patient[list].currents) {
			if (item['update']) countUpdate++
			else if (parseInt(item.id) === -1) countCreate++
		}

		let result: string[] = []
		Common.addMessageNotification(result, Common.getMessageNotification('patient', 'updated', label, countUpdate, female))
		Common.addMessageNotification(result, Common.getMessageNotification('patient', 'added', label, countCreate, female))
		Common.addMessageNotification(result, Common.getMessageNotification('patient', 'deleted', label, countDeleted, female))

		return result
	}

	private static hasErrorCoordinates(patient: IPatient): boolean {
		if (!patient.coordinates.last_name || !patient.coordinates.first_name || !patient.coordinates.birth_date) return true
		if (!NirHelper.isValidNumber(patient.coordinates.nir.number, patient.coordinates.nir.iso)) return true

		return patient.coordinates.phone1.error || patient.coordinates.phone2.error
	}

	private static hasErrorAntecedents(patient: IPatient): number {
		let count: number = 0
		for(let antecedent of patient.antecedents.currents) {
			if (!antecedent.type || antecedent.type.length === 0 || !antecedent.title || !antecedent.event_date) {
				antecedent.error = true
				count++
			} else if (antecedent.error !== undefined) {
				antecedent.error = false
			}
		}

		return count
	}

	private static hasErrorSpheres(patient: IPatient): number {
		let count: number = 0
		for(let sphere of patient.spheres.currents) {
			if (!sphere.type || sphere.type.length === 0 || !sphere.title || !sphere.event_date) {
				sphere.error = true
				count++
			} else if (sphere.error !== undefined) {
				sphere.error = false
			}
		}

		return count
	}

	private static hasErrorConsultations(patient: IPatient): string[] {
		let idList: string[] = []
		for(let consultation of patient.consultations.currents) {
			let id: string = parseInt(consultation.id) === -1 ? consultation.create_id : consultation.id
			if (!Patient.isValidConsultation(consultation)) idList.push(id)
		}

		return idList
	}

	private static hasErrorAttachments(patient: IPatient): string[] {
		let idList: string[] = []
		for(let attachment of patient.attachments.currents) {
			let id: string = parseInt(attachment.id) === -1 ? attachment.create_id : attachment.id
			if (!attachment.name || !attachment.act_date) idList.push(id)
			else if (attachment.error) idList.push(id)
			else if (attachment.type === AttachmentType.Link && !Patient.checkAttachmentLink(attachment.description)) idList.push(id)
		}

		return idList
	}

	private static isEmpty(target: any, source: any) {
		if (!target) return true
		let keys: string[] = Object.keys(source)
		for (let key of keys) {
			if (!!target[key] && source[key] !== target[key]) return false
		}
		return true
	}
 }
