export const register = {
    title: "Inscription",
    description: {
        text: "Merci de renseigner vos coordonnées afin de créer votre compte principal sur l'application {0}.",
        strong: "Osteo2ls"
    },
    'last-name': {
        label: "Nom",
        error: "Merci de renseigner votre nom",
        placeholder: "ex : Kent",
        title: "Veuillez renseigner votre nom"
    },
    'first-name': {
        label: "Prénom",
        error: "Merci de renseigner votre prénom",
        placeholder: "ex : Clark",
        title: "Veuillez renseigner votre prénom"
    },
    email: {
        label: "Adresse email",
        error: "Merci de renseigner votre adresse email",
        placeholder: "ex : superm@n.com",
        title: "Veuillez renseigner votre adresse email"
    },
    'confirm-email': {
        label: "Confirmez votre adresse email",
        error: "Merci de confirmer votre adresse email",
        placeholder: "ex : superm@n.com",
        title: "Veuillez confirmer votre adresse email"
    },
    'godchild-code': {
        label: "Code parrain",
        placeholder: "ex : C0D3-P4RR1",
        title: "Si vous possédez un code parrain, vous pouvez le saisir dès maintenant"
    },
    cgv: {
        politeness: "Merci d'approuver les CGV et CGU",
        text: "Je reconnais avoir lu et accepté {0}.",
        link: "les CGV et les CGU",
        link_title: "Les conditions générales de vente et d'utilisation d'Osteo2ls"
    },
    previous: {
        title: "Retour à la page précédente",
        text: 'précédent'
    },
    register: {
        title: "Inscrivez-vous à Osteo2ls",
        text: 'inscription',
        success: "Votre compte vient d'être créé et votre mot de passe envoyé à <strong>{email}</strong>"
    }
}