export const session = {
    name: {
        label: "Nom du type de consultation",
		placeholder: "ex : Pour enfants (< 12 ans)",
        'error-msg': "Merci de renseigner le nom de ce type de consultation",
        title: "Veuillez renseigner le nom de ce type de consultation"
    },
    duration: {
        label: "Durée de la consultation",
        placeholder: "ex : 45 minutes",
        'error-msg': "Merci de renseigner la durée de ce type de consultation",
        title: "Veuillez renseigner la durée de ce type de consultation"
    },
    color: {
        label: "Couleur de la consultation",
        title: "Personnalisez la couleur de cette consultation",
        placeholder: "ex : Turquoise",
        checked: "Indiquez si la couleur est prise en compte"
    },
    ttc: {
        label: "Montant de la consultation",
        placeholder: "ex : 45 €",
        'error-msg': "Merci de renseigner le montant de ce type de consultation",
        title: "Veuillez renseigner le montant de ce type de consultation"
    },
    description: {
        label: "Description de la consultation",
		placeholder: "ex : Consultation légèrement plus courte pour les plus jeunes",
        title: "Veuillez renseigner la description de ce type de consultation"
    },
    visible: {
        label: "Visible depuis votre planning en ligne",
        title: "Veuillez indiquer si vos patients pourront sélectionner ce type de consultation lors d'une prise de rendez-vous en ligne"
    },
    delete: {
        msg: "Voulez-vous vraiment supprimer ce type de consultation ?",
        title: "Supprimer ce type de consultation"
    },
    'no-delete': "Type de consultation par défaut, ne peux pas être effacée et est forcément visible."
}
