<container>
	<aside-support/>

	<master>

		<central v-on:scroll.native="triggerHandleScroll" id="target-to-top">
			<div v-if="hasHolidays" class="wrapper">
				<div class="marg-v notice-warning">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
						</svg>
						{{ absentPeriod }}
					</p>
				</div>
			</div>

			<router-view/>
		</central>

		<context-main/>

	</master>

</container>
