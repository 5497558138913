import Render from '@Views/components/forms/custom-form.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Question } from './question'
import { IForm } from '@Store/types'

@Render
@Component({
    components: {
        'question': Question
    }
})
export class CustomForm extends Vue {

    @Prop() form: IForm
}