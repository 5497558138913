export const searchMember = {
    label: "Recherchez des membres de la famille",
    title: "Recherchez les membres de la famille de ce patient parmi vos dossiers",
    placeholder: "ex : Tata Yvonne",
    'no-result': "aucun résultat",
    'searching': "recherche en cours…",
    'radio-title': "Sélectionner",
    select: {
        label: "sélectionner",
        title: "Rechercher un membre de la famille"
    },
    type: {
        label: "Lien de parenté avec le patient",
        title: "Indiquez le lien de parenté avec votre patient"
    },
    exception: {
        already: "Ce membre fait déjà parti de la famille.",
        same: "… Bah non, c'est le patient. Voyons…"
    }
}
