<section class="login-frame">
	<h2 class="login-frame-title">{{ $t("vm.login.loader.title") }}</h2>
	<div class="login-frame-content">
		<i18n path="vm.login.loader.description.text" tag="p">
			<strong>{{ $t("vm.login.loader.description.strong") }}</strong>
		</i18n>

		<div class="login-loading marg-v">
			<spinner v-if="!failed" class="svg-block svg-center text-primary"/>
			<svg v-if="failed" width="1em" class="svg-block svg-center text-warning" viewBox="0 0 496 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#dizzy"/>
			</svg>
		</div>
		<p class="text-xs text-italic text-right">{{ $t(loaderMessage) }}</p>
	</div>
</section>
