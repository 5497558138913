import Render from '@Views/root/parameters/main.html'
import { Component } from 'vue-property-decorator'
import { ASideMain } from './aside-main'
import { ASideDocument } from './document/aside-document'
import { ContextDocument } from './document/context-document'
import { Document } from './document/document'
import { AutoSaver } from '@Components/index'
import { FontHelper, StorageHelper, LangHelper, SkinHelper } from '@Helpers/index'
import { IPopinAction, IPopin, IConfiguration, INotification } from '@Store/types'
import { AccountingPeriod } from '@Enums/index'
import { Parameters } from '@Models/index'
import { accounting, configuration, notif } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-main': ASideMain,
		'aside-document': ASideDocument,
		'context-document': ContextDocument,
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	saved: boolean = false
	submitted: boolean = false
	private item: string = null
	private oldDefaultPeriod: AccountingPeriod

	constructor() {
		super('configuration')
	}

	//#region getters
	get configuration(): IConfiguration {
		return configuration.configuration
	}
	get isSaving(): boolean {
		return configuration.isSaving
	}
	get isDocumentView(): boolean {
		return this.item === 'document'
	}
	//#endregion

	mounted() {
		this.oldDefaultPeriod = this.configuration.default_period
		this.initializeConfiguration()
	}

	//#region IAutoSaver implementation
	restoreValue(value: IConfiguration): void {
		FontHelper.updateFontSize(value.font_size, super.configuration.font_size)
		SkinHelper.updateSkin(value.theme, super.configuration.theme)
		LangHelper.updateLang(value.lang)
		configuration.configurationSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		let _configuration:IConfiguration = Parameters.getConfiguration(this.configuration, this.oldValue)
		let notification:INotification = Parameters.getNotification(_configuration)
		configuration.saveConfiguration(_configuration)
		.then((newConfiguration: IConfiguration) => {
			super.initializeValue()
			StorageHelper.set('lang', newConfiguration.lang)
			this.submitted = false

			notif.success(notification)
			if (this.oldDefaultPeriod === newConfiguration.default_period) return

			accounting.resetFilter(newConfiguration.default_period === AccountingPeriod.Monthly)
			this.oldDefaultPeriod = newConfiguration.default_period
		})
	}
	//#endregion

	submitSave(): void {
		this.submitted = true
		this.saved = true
		this.save()
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.parameters.main.cancel.action').toString(),
			title: this.$i18n.t('vm.root.parameters.main.cancel.title').toString(),
			callback: () => {
				this.cancelAction()
				return Promise.resolve()
			}
		}
		let configuration:IConfiguration = Parameters.getConfiguration(this.configuration, this.oldValue)
		let messages: string[] = Parameters.getUpdateList(configuration)
		let updates: string = messages.join("</li><li>")
		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.parameters.main.cancel.title').toString(),
			content: this.$i18n.t('vm.root.parameters.main.cancel.content', {updates: updates}).toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	onNavigate(item: string): void {
		this.item = item
	}

	onInsert(text: string): void {
		let document: Document = this.$refs.mainView as Document
		if (!document) return

		document.insertText(text)
	}

	private cancelAction(): void {
		this.submitted = false
		super.cancel()
	}

	private canSave(): boolean {
		const { configuration } = this

		let doc_id: string | false = this.hasErrorDocuments()
		if (!!doc_id) {
			if (this.submitted) {
				this.$router.push({name: 'parameters-document', params: {doc_id: doc_id}})
			}
			return false
		}

		return !!configuration
	}

	private hasErrorDocuments(): string | false {
		for(let document of this.configuration.documents.currents) {
			if (!!document.title) continue

			return document.id
		}

		return false
	}

	private initializeConfiguration(): void {
		if (!configuration.isLoaded) {
			configuration.loadConfiguration()
			.then(() => { super.initializeValue() })
		} else {
			super.initializeValue()
		}
	}
}
