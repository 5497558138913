<div v-if="!!options && !!event">
	<div :class="{'grid-2': !hasSessions(event.ofi_id), 'grid-3': hasSessions(event.ofi_id)}">
		<input-frame :auto-focus="true" type="text" id="label" class="grid-item-full" v-model="event.label"
			:label="$t('vm.components.super-panel.quick-event.label.label')"
			:placeholder="$t('vm.components.super-panel.quick-event.label.placeholder')"
			:title="$t('vm.components.super-panel.quick-event.label.title')"/>

		<div v-if="!!event.patient" class="vertical-gap marg-v grid-item-full">
			<div class="grid-2">
				<dropdown-frame id="confirm-type" v-model="event.confirmation_type" :items="confirmationsItems"
								:label="$t('vm.components.super-panel.quick-event.confirmation-type')"/>
				<dropdown-frame id="type" v-model="event.reminder_type" :items="remindersItems"
								:label="$t('vm.components.super-panel.quick-event.reminder-type')"/>
			</div>

			<patient-item :patient="event.patient" :event="event" :unselect="unselect" :popin-to-update="true"/>
		</div>
		<search-patient :action="addPatient" v-else class="grid-item-full"/>

		<dropdown-frame id="planning" v-model="event.pla_id" :items="planningItems"
			:label="$t('vm.components.super-panel.quick-event.planning.label')"
			:title="$t('vm.components.super-panel.quick-event.planning.title')"/>
		<dropdown-frame id="office" v-model="event.ofi_id" :items="officeItems"
			:label="$t('vm.components.super-panel.quick-event.office.label')"
			:title="$t('vm.components.super-panel.quick-event.office.title')"/>
		<dropdown-frame id="session" v-if="hasSessions(event.ofi_id)" v-model="event.ses_id" :items="sessionItems(event.ofi_id)"
			:label="$t('vm.components.super-panel.quick-event.session.label')"
			:title="$t('vm.components.super-panel.quick-event.session.title')"/>
	</div>

	<p class="text-xs" v-if="getSubstitutes().length > 0">
		{{ $t('vm.components.super-panel.quick-event.substitute') }}
		<span v-for="substitute in getSubstitutes()">
			&nbsp;<svg width="1em" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :style="`color:${substitute.color}`">
				<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
			</svg>
			{{ substitute.name }}
		</span>
	</p>

	<hr>

	<div class="childs-space-between marg-v">
		<button type="button" @click="previous" :disabled="!canPrevious() || isLoadingSlot" class="input-like is-sized"
			:title="$t('vm.components.super-panel.quick-event.previous.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
			</svg>
			<span class="rwd-over-medium">{{ $t('vm.components.super-panel.quick-event.previous.label') }}</span>
		</button>

		<span class="text-md">{{ $t('vm.components.super-panel.quick-event.choose-hour') }}</span>

		<button type="button" @click="next" :disabled="isLoadingSlot" class="input-like is-sized"
			:title="$t('vm.components.super-panel.quick-event.next.title')">
			<span class="rwd-over-medium">{{ $t('vm.components.super-panel.quick-event.next.label') }}</span>
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
			</svg>
		</button>
	</div>

	<div v-if="isLoadingSlot" class="timetable is-loading" :style="`--col-count:${countDay}`">
		<section v-for="(day, id1) in weekdays" :key="day.number" class="timetable-column text-center" aria-hidden="true">
			<h2 class="timetable-column-title h-reset" :class="{'is-today': day.today, 'is-selected': isSelectedDay(day.index)}">
				<div class="timetable-column-title-inner">
					<div class="text-sm">{{day.label}}</div>
					<div class="text-xl text-bold">{{day.number}}</div>
					<div class="text-sm">{{day.month}}</div>
				</div>
			</h2>
			<template v-for="(slot, id2) in skeleton[day.index]">
				<span class="timetable-slot input-like is-narrow-padding-h" :style="`--factor:${id1+id2}`">&nbsp;</span>
			</template>
		</section>
	</div>

	<div v-else class="timetable" :style="`--col-count:${countDay}`">

		<section v-for="day in weekdays" :key="day.number" class="timetable-column text-center">
			<h2 class="timetable-column-title h-reset" :class="{'is-today': day.today, 'is-selected': isSelectedDay(day.index)}">
				<div class="timetable-column-title-inner">
					<div class="text-sm">{{day.label}}</div>
					<div class="text-xl text-bold">{{day.number}}</div>
					<div class="text-sm">{{day.month}}</div>
				</div>
			</h2>
			<template v-for="slot in getColumn(day.index)">
				<button
					v-if="slot.with_value"
					@click="selectHour(slot)"
					class="timetable-slot input-like is-narrow-padding-h"
					:class="{'is-hidden': slot.hidden, 'is-selected': slot.selected}"
					:title="$t('vm.components.super-panel.quick-event.select-hour', {'day-label': day.label, 'day-number': day.number, 'day-month': day.month, hour: slot.hour})"
				>
					<span>
						<svg v-if="withUser(slot.user) && !!getSubstituteColor(slot.user)" width="1em" class="text-xs" viewBox="0 0 512 512" aria-hidden="true" focusable="false"
							:style="`color:${getSubstituteColor(slot.user)}`">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						{{slot.hour}}
					</span>
				</button>
				<span
					v-else
					class="timetable-slot is-disabled input-like is-narrow-padding-h"
					:class="{'is-hidden': slot.hidden}"
					aria-hidden="true"
				>
					-
				</span>
			</template>
		</section>

		<div v-if="noMoreSlot || isEmpty" class="timetable-is-empty">

			<span v-if="noMoreSlot" class="text-bold">{{ $t('vm.components.super-panel.quick-event.no-hour') }}</span>

			<button v-else-if="isEmpty" @click="nextFreeSlot" class="btn btn-default"
				:title="$t('vm.components.super-panel.quick-event.next-hour.title')">
				<span>{{ $t('vm.components.super-panel.quick-event.next-hour.label') }}</span>
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
				</svg>
			</button>

		</div>

	</div>

	<div class="text-center" v-if="!hideSeeMoreButton">
		<button
			@click="seeMore"
			:disabled="isLoadingSlot || isEmpty || noMoreSlot"
			class="btn btn-default"
			:title="$t('vm.components.super-panel.quick-event.more-hour.title')"
		>
		{{ $t('vm.components.super-panel.quick-event.more-hour.label') }}
		</button>
	</div>

</div>
