import sha256 from 'sha-256-js'
import { Profile } from '@Enums/profile'
import { BaseService } from '@Services/index'
import config from "@Config/index"
import { IRegister } from '@Store/types'
import { StorageHelper } from '@Helpers/index'

export class LoginService extends BaseService {

	private static storeUserId(user: any): void {
		if (!config.use_storage) return
		StorageHelper.set('session_id_osteo2ls', user.id)
	}

	public getAccounts(email: string): Promise<any> {
		return super.get( `/login/accounts?email=${encodeURIComponent(email)}`)
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur durant l'identification"
			switch(error.status) {
				case 412:
					message = "Vous devez saisir une adresse email valide"
					break;
			}
			return Promise.reject(message)
		})
	}

	public login(email: string, password: string, profile: Profile): Promise<any> {
		return super.post('/login/authenticate', { email: email, password: sha256(password), profile: profile }, LoginService.storeUserId, true)
	}

	public lostLogin(email: string, profile: Profile): Promise<any> {
		return super.post('/login/lost-login', { email: email, profile: profile })
	}

	public isLoggedIn(): Promise<any> {
		return super.get('/login/authenticate')
	}

	public logout(): Promise<any> {
		StorageHelper.remove('session_id_osteo2ls')
		return super.delete('/login/authenticate')
	}

	public async register(user: IRegister): Promise<any> {
		return super.post('/login/register', user, undefined, true)
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur durant l'inscription"
			switch(error.status) {
				case 417:
					message = "Vous devez accepter les CGV et les CGU"
					break;
				case 412:
					if (error.statusText === 'empty') {
						message = "Vous devez indiquer votre adresse email"
					} else if (error.statusText === 'same') {
						message = "Vous devez confirmer votre adresse email"
					} else if (error.statusText === 'invalid') {
						message = "Vous devez saisir une adresse email valide"
					} else {
						message = "Vous devez indiquer vos noms et prénoms"
					}
					break;
				case 409:
					message = "Cette adresse email est déjà utilisée par un ostéopathe"
					break;
			}
			return Promise.reject(message)
		})
	}
}
