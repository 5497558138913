export const subscription = {
    accept : "J'ai bien lu, ne plus afficher ce message.",
	subscription: {
		label: "Choix de l'abonnement",
		title: "Choix de l'abonnement",
	},
	'subscription-items': {
		'annually': 'Abonnement annuel 144 € / an (soit 12 € / mois)',
		'monthly': 'Abonnement mensuel 14,5 € / mois (sans engagement)'
	}
}
