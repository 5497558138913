import Render from '@Views/root/calendar/event/context-event.html'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { FullCalendar, IFullCalendarEvent } from '@Components/index'
import { GoogleService, EventService } from '@Services/index'
import { IOffice, ISchedule, ISettings, IEvent, IPlanning, IConfiguration } from '@Store/types'
import { DateHelper, FullCalendarEventHelper } from '@Helpers/index'
import { Office, Debouncer } from '@Models/index'
import { breadcrumb, configuration, office, planning, user } from '@Store/modules'

@Render
@Component({
	components: {
		'calendar': FullCalendar
	}
})
export class ContextEvent extends Vue {

	config: any = {}
	title: string = ''
	private calendar: FullCalendar
	private scheduleEvents: IFullCalendarEvent[] = []
	private events: IFullCalendarEvent[] = []
	private colors:Record<string, string> = {}
	private debouncer: Debouncer = null
	private debouncerSchedule: Debouncer = null

	@Prop({default: null}) event: IEvent

	//#region Getters
	get allEvents(): IFullCalendarEvent[] {
		let copyEvent = {...this.event}
		copyEvent.label = !!copyEvent.label ? copyEvent.label : this.$i18n.t('vm.root.calendar.main.new-event.label').toString()

		return [...this.events, FullCalendarEventHelper.convertEventToFullCalendarEvent(copyEvent, this.colors)]
	}
	get scrollTime(): string {
		return  `${this.date.getHours().toLocaleString('fr-FR', {minimumIntegerDigits: 2})}:00:00`
	}
	private get plannings(): IPlanning[] {
		return planning.plannings
	}
	private get googlePlannings(): IPlanning[] {
		return planning.googlePlannings
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get date(): Date {
		return !!this.event ? new Date(this.event.start_date) : new Date()
	}
	//#endregion

	@Watch('date', {deep: true})
	updateDate(newValue: Date): void {
		let currentDate = this.getDate()
		if (currentDate === newValue) return

		this.getEvents()
		this.gotoDate(newValue)
		this.debouncerSchedule.start()
	}

	@Watch('event.ofi_id')
	updateOffice() {
		this.debouncerSchedule.start()
	}

	@Watch('plannings', {deep: true})
	@Watch('googlePlannings', {deep: true})
	refetchEvents() {
		this.getEvents()
	}

	beforeMount() {
		this.initializeConfig()
		this.debouncer = new Debouncer(this.getEventList, 333)
		this.debouncerSchedule = new Debouncer(this.initializeSchedule, 333)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
		Debouncer.clear(this.debouncerSchedule)
	}

	mounted() {
		this.calendar = (this.$refs.calendar as FullCalendar)

		breadcrumb.updateLinked(true)
		this.gotoDate(this.date)
		this.getEvents()
		this.debouncerSchedule.start()
	}

	private getEvents() {
		this.debouncer.start()
	}

	private initializeConfig(): any {
		this.config = {}
		if (user.isSecretaryUser) return

		let slotDuration = !!this.configuration.slot ? `00:${this.configuration.slot}:00` : '00:30:00'
		var minTime = !!this.configuration.min_time ? this.configuration.min_time.toLocaleTimeString('fr-FR') : '06:00:00'
		var maxTime = !!this.configuration.max_time ? this.configuration.max_time.toLocaleTimeString('fr-FR') : '21:00:00'

		this.config.slotDuration = slotDuration
		this.config.minTime = minTime
		this.config.maxTime = maxTime
	}

	private initializeSchedule(): void {
		if (!this.calendar) return

		this.calendar.clearBackground()
		this.calendar.setBusinessHours(false)
		this.scheduleEvents = []
		let usr_id: string = user.currentUser.id || null

		if (!usr_id || !this.event.ofi_id) return

		let _office: IOffice = office.office(this.event.ofi_id)
		let links: any[] = !!_office ? _office.links : []
		let specificSchedule: ISchedule[] = !!_office ? office.specificSchedulesRange(this.event.ofi_id, usr_id, this.date) : []
		let schedule: ISchedule = specificSchedule.length > 0 ? specificSchedule[0] : office.schedule(this.event.ofi_id, usr_id)
		let settings: ISettings = office.setting(this.event.ofi_id, usr_id)
		this.calendar.setBusinessHours(Office.getBusinessHours(schedule, settings, this.date, this.getDateByIndex))
		let scheduleEvents: IFullCalendarEvent[] = Office.getScheduleEventList(schedule, links, usr_id, this.date, this.getDateByIndex)
		if (!scheduleEvents) return

		this.scheduleEvents = scheduleEvents
		this.calendar.renderEvents(this.scheduleEvents)
	}

	private getDateByIndex(date: Date, index: number) {
		if (index !== date.getDay()) return
		return date
	}

	private getEventList() {
		if (!DateHelper.isValidDate(this.date)) return

		this.calendar.clear()
		this.calendar.renderEvents(this.scheduleEvents)
		let start = new Date(this.date)
		start.setHours(0, 0, 0, 0)
		let end = new Date(start)
		end.setDate(start.getDate() + 1)

		let queries: Promise<any>[] = []
		this.colors = {}

		let classicQueries = this.fetchClassicEvents(start, end)
		let googleQueries = this.fetchGoogleEvents(start, end)
		queries = [...classicQueries, ...googleQueries]

		for(let query of queries) {
			query.then((list) => {
				this.addRange(list, this.colors)
			}).catch((error) => {console.error(error)})
		}
	}

	private addRange(list: any[], colors:Record<string, string>): void {
		if (!this.calendar) return

		let eventList: IFullCalendarEvent[] = []
		for (let event of list) {
			let convertedEvent = FullCalendarEventHelper.convertEventToFullCalendarEvent(event, colors)
			if (convertedEvent === undefined) continue
			if (convertedEvent.id === this.event['id']) continue

			eventList.push(convertedEvent)
		}
		if (eventList.length === 0) return

		this.calendar.renderEvents(eventList)
	}

	private gotoDate(date: Date) {
		if (!this.calendar) return
		if (!DateHelper.isValidDate(date)) return

		var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
		let dateFormatted = date.toLocaleDateString('fr-FR', options)
		let currentDate = this.calendar.getDate().toLocaleDateString('fr-FR', options)
		if (dateFormatted === currentDate) {
			this.title = this.calendar.title()
			return
		}

		this.calendar.gotoDate(date)
		this.title = this.calendar.title()
	}

	private getDate(): any {
		let calendar = (this.$refs.calendar as FullCalendar)
		if (!calendar) return

		let currentDate: Date = calendar.getDate()
		if (!currentDate) return

		return DateHelper.getDateString(currentDate)
	}

	private fetchClassicEvents(start: Date, end: Date) {
		let queries: Promise<any>[] = []
		let service = new EventService()
		for (let planning of this.plannings) {
			if (!planning.visible) continue

			this.colors[planning.id] = planning.color
			let promise = service.getEventList(start, end, planning)
			queries.push(promise)
		}
		return queries
	}

	private fetchGoogleEvents(start: Date, end: Date) {
		let queries: Promise<any>[] = []
		let plannings: IPlanning[] = planning.googlePlannings
		for (let _planning of plannings) {
			if (!_planning.visible) continue

			this.colors[_planning.id] = _planning.color
			let promise = GoogleService.getInstance().getEventList(start, end, _planning)
			queries.push(promise)
		}

		return queries
	}
	//#endregion
}
