import Render from '@Views/components/popin/layout/popin-create-colleague.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IColleague } from '@Store/types'
import { DetailsColleague } from '@ViewModels/root/statistics/colleagues/colleague/details'

@Render
@Component({
	components: {
		'details-colleague': DetailsColleague
	}
})
export class PopinCreateColleague extends Vue {
    
    @Prop() options: IColleague
    @Prop({default: false}) submitted: boolean
}