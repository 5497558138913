import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { ILetterFilter } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { DocumentService } from "@Services/index"
import { Document } from "@Models/index"

@Module({
	name: 'letter',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class LetterModule extends VuexModule {
    statusReseting: RequestStatus = RequestStatus.None
    filter: ILetterFilter = Document.createLetterFilter()

	get isReseting(): boolean {
		return this.statusReseting === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.statusReseting = RequestStatus.None
		this.filter = Document.createLetterFilter()
	}

	@Mutation
	resetFilter() {
		this.filter = Document.createLetterFilter()
	}

	@Mutation
	resetRequest() {
		this.statusReseting = RequestStatus.Loading
	}

	@Mutation
	resetSuccess() {
		this.statusReseting = RequestStatus.Success
	}

	@Mutation
	resetFailure() {
		this.statusReseting = RequestStatus.Failed
	}

	@Action({rawError: true})
	async reset(tpl_id: string): Promise<any> {
		if (this.isReseting) return

		this.resetRequest()

		let service = new DocumentService()
		return service.reset(tpl_id)
		.then(result => {
			this.resetSuccess()
			return Promise.resolve(result.template)
		})
		.catch(error => {
			this.resetFailure()
			return Promise.reject(error)
		})
	}
}

export const letter = getModule(LetterModule)
// export const letter = new LetterModule({ store, name: "letter" })