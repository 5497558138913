import Render from '@Views/components/layout/notification.html'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { NotificationType } from '@Enums/index'
import { Debouncer } from '@Models/index'
import { INotification, INotificationAction } from '@Store/types'
import { notif } from '@Store/modules'

@Render
@Component({})
export class Notification extends Vue {
	name:string = 'notification'

	private debouncer: Debouncer = null

	//#region getters
	get type(): NotificationType {
		return notif.type
	}
	get classObject(): any {
		return {
			'is-flash': this.isFlash,
			'is-with-progress': this.isProgress,
			'notification-info': this.type === NotificationType.Info,
			'notification-success': this.type === NotificationType.Success,
			'notification-warning': this.type === NotificationType.Warning,
			'notification-danger': this.type === NotificationType.Error
		}
	}
	get progressStyle(): string {
		return this.isProgress ? `--progress:${this.notification.progress}%;` : ''
	}
	get message(): string {
		return this.notification.message || ''
	}
	get canClose(): boolean {
		return this.notification && this.notification.canClose
	}
	get actions(): INotificationAction[] {
		return this.notification.actions || []
	}
	private get isFlash(): boolean {
		return this.type !== NotificationType.None && (!!this.notification.delay || !!this.notification.progress)
	}
	private get isProgress(): boolean {
		return this.type !== NotificationType.None && !!this.notification.progress
	}
	private get notification(): INotification {
		return notif.notification
	}
	//#endregion

	mounted() {
		this.debouncer = new Debouncer(this.close)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	display(): boolean {
		return this.type !== NotificationType.None && !!this.message
	}

	close(): void {
		notif.clear()
	}

	callback(callback: any): void {
		notif.clear()
		callback()
	}

	@Watch('type')
	initializeNotification(): void {
		this.debouncer.delay = this.notification && this.notification.delay as number
		this.debouncer.start(!!this.notification && this.isFlash && !this.isProgress)
	}
}
