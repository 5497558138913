<div v-if="consultation">
	<h3>{{ $t('vm.root.patients.patient.walton.title') }}</h3>
	<div v-if="!readOnly" class="grid-2 semi-marg-v">
		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-1')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_1 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_1" :min="0" :max="4" :step="1"></slider-frame>

		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-2')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_2 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_2" :min="0" :max="4" :step="1"></slider-frame>

		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-3')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_3 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_3" :min="0" :max="4" :step="1"></slider-frame>

		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-4')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_4 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_4" :min="0" :max="4" :step="1"></slider-frame>

		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-5')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_5 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_5" :min="0" :max="4" :step="1"></slider-frame>

		<slider-frame :label="$t('vm.root.patients.patient.walton.walton-6')"
					  :marks="marks"
					  :hide-label="true"
					  :process-style="walton.data_6 | walton"
					  :tooltip-formatter="tooltipFormatter"
					  v-model="walton.data_6" :min="0" :max="4" :step="1"></slider-frame>
	</div>

	<apexchart type="radar" :options="radarChartOptions" :series="radarSeries"></apexchart>
</div>
