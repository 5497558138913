<container>
	<aside-main v-if="!!user"/>

	<master>
		<central>
			<div class="wrapper marg-v">
				<router-view @navigate="onNavigate"/>
			</div>
		</central>

		<context-referral v-if="isReferral"/>
		<context-main v-else/>
	</master>

</container>
