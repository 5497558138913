import Render from '@Views/root/statistics/planning-online/context-planning.html'
import { Component, Vue } from 'vue-property-decorator'
import { IStatisticsFilter } from '@Store/types'
import { statistics } from '@Store/modules'

@Render
@Component({})
export class ContextPlanningOnline extends Vue {

    year1: number = 2019
    year2: number = 2020

    // @State('statistics') stateStatistics: StatisticsState

    get isLoading(): boolean {
        return statistics.isLoading
	}
    get filter(): IStatisticsFilter {
        return statistics.filter
    }
}