import { getModule, VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { store } from '@Store/index'
import {IBulk, IBulkInfo} from '@Store/types'
import { RequestStatus } from '@Enums/index'
import {BulkService} from '@Services/index'
import {user} from '@Store/modules'
import {i18n} from '@I18n/index'

@Module({
	name: 'bulk',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class BulkModule extends VuexModule {
	bulk: IBulkInfo = null
    private status: RequestStatus = RequestStatus.None
    private statusSaving: RequestStatus = RequestStatus.None

	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get canBuy(): boolean {
		let previousKey: string = this.previousBulkKey
		let currentKey: string = this.bulkKey(new Date())

		return (!user.user.bulk_key && user.user.license !== -1)
		|| (!!user.user.bulk_key && (user.user.bulk_key == currentKey || user.user.bulk_key == previousKey))
	}
	get isStep1(): boolean {
		return !user.user.bulk_key || user.user.license !== -1
	}
	get isStep2(): boolean {
		return !!user.user.bulk_key && user.user.bulk_key == this.bulkKey(new Date())
	}
	get isStep3(): boolean {
		return !!user.user.bulk_key && user.user.bulk_key == this.previousBulkKey && !!user.user.license_date
	}
	get bulkKey(): (date: Date) => string {
		return (date: Date) => {
			return `${i18n.d(date, 'yyyy')}-${i18n.d(date, 'mm')}`
		}
	}

	get previousBulkKey(): string {
		let today: Date = new Date()
		let previousEndSessionDate = new Date(today.getFullYear(), today.getMonth(), 1, 23, 59, 59)
		previousEndSessionDate.setDate(previousEndSessionDate.getDate() - 1)

		return this.bulkKey(previousEndSessionDate)
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.bulk = null
	}

	@Mutation
	bulkRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	bulkSuccess(bulk: IBulkInfo) {
		this.status = RequestStatus.Success
		this.bulk = bulk
	}

	@Mutation
	bulkFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(bulk: IBulk) {
		this.statusSaving = RequestStatus.Success
		this.bulk.bulk = bulk
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Action({rawError: true})
	async loadBulk(key: string): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.bulkRequest()

		let service = new BulkService()
		return service.getInfos(key)
		.then(bulk => {
			this.bulkSuccess(bulk)
			return Promise.resolve(bulk)
		})
		.catch(error => {
			this.bulkFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveBulk(bulk: IBulk): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new BulkService()
		return service.save(bulk)
			.then(newBulk => {
				this.saveSuccess(newBulk)
				return Promise.resolve(newBulk)
			})
			.catch(error => {
				this.saveFailure()
				return Promise.reject(error)
			})
	}
}

export const bulk = getModule(BulkModule)
