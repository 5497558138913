import * as Login from '@ViewModels/login'

export const LoginRoot = {
	path: '/login', 
	component: Login.Main,
		children: [
		{
			path:'',
			name:'login',
			component: Login.Login,
			meta: { permission: false }
		},
		{
			path:'authentication',
			name:'authentication',
			component: Login.Authenticate,
			meta: { permission: false }
		},
		{
			path:'loader',
			name:'loader',
			component: Login.Loader,
			meta: { permission: false }
		},
		{
			path:'register/:code?', 
			name:'register',
			component: Login.Register,
			meta: { permission: false }
		}
	]
}