import { BaseService } from '@Services/index'
import { IPlanning } from '@Store/types'
import { StorageHelper } from '@Helpers/index'

export class PlanningService extends BaseService {
	
	public getPlanningList(): Promise<any> {
		return super.get('/plannings')
	}

	public update(planning: any): Promise<any> {
		return super.put(`/planning/${planning.id}`, planning)
	}

	public save(planning: IPlanning): Promise<any> {
		if (parseInt(planning.id) === -1)
			return super.post('/planning', planning)

		return super.put(`/planning/${planning.id}`, planning)
	}

	public async delete(id: string): Promise<any> {
		return super.delete(`/planning/${id}`)
		.then(() => { return Promise.resolve() })
		.catch((error: any) => {
			let message = "Erreur durant la suppression."
			if (!error || !error.response) return Promise.reject(message)

			switch(error.response.status) {
				case 409:
					message = "Ce planning ne peut-être effacé, car lié à votre planning en ligne."
					break
				case 401:
					message = "Vous avez été déconnecté !"
					StorageHelper.set('error', message)
					break
			} 
			return Promise.reject(message)
		})
	}
}