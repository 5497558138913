<section class="login-frame">
	<h2 class="login-frame-title">{{ $t("vm.login.login.title") }}</h2>
	<form @submit.prevent="submit" class="login-frame-content">

		<p v-if="isDemo" v-html="$t('vm.login.login.demo')"/>
		<i18n v-else path="vm.login.login.description.text" tag="p">
			<router-link :to="{name: 'register'}" :title="$t('vm.login.login.description.link_title')">{{ $t("vm.login.login.description.link") }}</router-link>
			<strong>{{ $t("vm.login.login.description.strong") }}</strong>
		</i18n>

		<input-frame :auto-focus="true" type="email" id="email" :label="$t('vm.login.login.email.label')" v-model="email"
			:error-test="hasEmailError" :error-msg="$t('vm.login.login.email.error')" :random-id="false"
			:placeholder="$t('vm.login.login.email.placeholder')" :title="$t('vm.login.login.email.title')"/>

		<div class="p-like text-right">
			<button-action type="submit" btn-class="btn-primary" :loading="isLoading" :title="$t('vm.login.login.login.title')">
				{{ $t("vm.login.login.login.text") }}
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
				</svg>
			</button-action>
		</div>

	</form>
</section>
