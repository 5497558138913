export const main = {
    previous: {
        text: "précédente",
        title: "Consultation précédente"
    },
    next: {
        text: "suivante",
        title: "Consultation suivante"
    },
	yourself: "vous-même",
}
