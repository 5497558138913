export const notifications = {
    title: "Paramètres des notifications",
    'follow-up': {
        title: "Activez les notifications pour les suivis de consultation non lus",
        label: "Suivi de consultation non lu"
    },
    event: {
        title: "Activez les notifications pour les rendez-vous à valider",
        label: "Rendez-vous à valider"
    },
    help: {
		title: "Activez les notifications pour les réponses à vos tickets",
        label: "Réponse à vos tickets de support"
    },
    informations: "Les notifications sont de petites fenêtres, propres à chaque navigateur, qui apparaissent généralement en haut à droite de votre écran. Ici, par exemple, elles permettront de vous notifier quand un nouveau rendez-vous sera à valider ou quand une réponse à l'un de vos tickets aura été apportée."
}
