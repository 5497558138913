export const widget = {
    expand: {
        title: "Étendre ce panneau",
        label: "étendre"
    },
    collapse: {
        title: "Réduire ce panneau",
        label: "réduire"
    },
    birthday: {
        title: "Voir le dossier patient de {first-name} {last-name}",
        previous: {
            label: "Précédent",
            title: "Voir le jour précédent"
        },
        next: {
            label: "Suivant",
            title: "Voir le jour suivant",
        },
        none: "Aucun anniversaire à fêter ce jour."
    },
    'follow-up': {
        title: "Prenez connaissance du suivi de consultation de {first-name} {last-name}",
        new: "a indiqué un suivi à sa consultation",
        none: "Aucun suivi de consultation pour le moment."
    },
    unpaid: {
        title: "Consultez la facture non payée de {first-name} {last-name}",
        new: "a une facture impayée de",
        none: "Aucune facture impayée 👌"
    },
    'valid-event': {
        title: "Validez ce rendez-vous",
        'valid-planning': "Planning en ligne activé jusqu'au {date}",
        'unvalid-planning': {
            text: "Planning en ligne désactivé ({0})",
            link: "12 mois offerts",
            title: "Bénéficiez du planning en ligne gratuitement pendant 12 mois",
        },
        new: "Rendez-vous à valider le",
        none: "Aucun rendez-vous à valider pour le moment."
    },
    sms: {
        send: "Envoyé ce mois-ci | Envoyé ce mois-ci | Envoyés ce mois-ci",
        total: "Votre solde",
    },
    'new-sticky-note': {
        title: "Ajoutez une nouvelle note",
        label: "nouvelle note"
    },
    loading: "Chargement en cours…"
}
