<div v-if="!!organizations">
	<div class="childs-space-between marg-v">
		<h1 class="text-uppercase-first-letter no-marg">{{ $tc('vm.root.user.organizations.title', organizations.length) }}</h1>
		<button type="button" class="btn--link-like is-pushed-right" @click="addOrganization" :title="$t('vm.root.user.organizations.new-organization.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.user.organizations.new-organization.label') }}</span>
		</button>
	</div>

	<div v-if="organizations.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 640 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#users-class"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.user.organizations.none.label') }}</span><br>
			<i18n path="vm.root.user.organizations.none.text" tag="span">
				<button type="button" class="btn--link-like" @click="addOrganization" :title="$t('vm.root.user.organizations.new-organization.title')">
					{{ $t('vm.root.user.organizations.none.button-label') }}
				</button>
			</i18n>
		</p>
	</div>

	<draggable :forceFallback="true" tag="ul" :list="organizations" class="ul-reset" v-else handle=".draggable-handler">
		<li v-for="organization in organizations" class="draggable" :ref="getElementId(organization)" :key="getElementId(organization)">

			<div class="collapsible-panel bordered-panel marg-v draggable-frame is-with-border">
				<button type="button" class="btn--reset draggable-handler" :title="$t('vm.root.user.organizations.move.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.user.organizations.move.label') }}</span>
				</button>
				<button type="button" class="btn--link-like collapsible-panel-trigger text-bold"
					:title="$t('vm.root.user.organizations.details')"
					@click="toggle(organization.id, organization.create_id)"
					:class="{'is-active': isToggle(organization.id, organization.create_id)}">
					<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
					</svg>
					<span class="text-uppercase-first-letter">{{ organization.name || $t('vm.root.user.organizations.default-name') }}</span>
				</button>
				<div class="collapsible-panel-container"  :class="{'is-active': isToggle(organization.id, organization.create_id)}">
					<div class="collapsible-panel-content">
						<organization :organization="organization" :submitted="submitted"/>
					</div>
				</div>
			</div>

		</li>
	</draggable>

</div>
