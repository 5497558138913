export const contextNews = {
    title: "Actualités",
    'news-title': "En savoir plus…",
    share: {
        on: "Partager sur",
        facebook: "Facebook",
        twitter: "Twitter",
        'facebook-title': "Partager sur Facebook",
        'twitter-title': "Partager sur Twitter"
    },
    'more-news': "plus d'actualités"
}