export const patient = {
    title: "Paramètres du dossier patient",
    anonymous: {
        title: "Mode anonyme",
        help1: {
            content: "Le mode anonyme permet de {0}. Si vous devez, lors d'une formation, une conférence ou tout autre évènement, argumenter en montrant un dossier patient, vous pouvez le faire en {1}. Cette option ne rendra anonyme {2}, les autres sections resteront identifiables.",
            strong1: "rendre anonyme un dossier patient",
            strong2: "cachant l'identité de votre patient",
            strong3: "que la section patient du logiciel"
        },
        help2: {
            content: "Notez que quand le mode anonyme est activé, la {0}.",
            strong: "modification des dossiers n'est pas possible"
        },
        switch: {
            title: "Activez le mode anonyme et cacher l'identité de vos patients",
            label: "Activer le mode anonyme"
        }
    },
    color: {
        title: "Couleurs",
        consultation: {
            title: "Veuillez indiquer la couleur associée aux consultations",
            label: "Couleur associée aux consultations"
        },
        antecedent: {
            title: "Veuillez indiquer la couleur associée aux antécédents",
            label: "Couleur associée aux antécédents"
        },
        sphere: {
            title: "Veuillez indiquer la couleur associée aux sphères",
            label: "Couleur associée aux sphères"
        },
        attachment: {
            title: "Veuillez indiquer la couleur associée aux pièces jointes",
            label: "Couleur associée aux pièces jointes"
        }
    },
    display: {
        title: "Affichage consultation",
        help: {
            content: "Vous avez la possibilité de {0}. Si les questionnaires par défaut ne vous conviennent pas, vous pouvez les {1}. Il suffit pour cela de cocher/décocher les cases associées aux champs que vous souhaitez afficher/masquer. Les {2} déjà saisies {3}, juste cachées.",
            strong1: "modifier l'apparence des questionnaires d'une consultation",
            strong2: "cacher totalement ou partiellement",
            strong3: "données",
            strong4: "ne seront pas effacées"
        },
        'examens-title': "Examens",
        measurements: {
            label: "Taille (cm) / Poids (kg)",
            size: {
                label: "Taille (cm)",
                placeholder: "ex : 176",
                title: "Veuillez renseigner la taille du patient lors de cette consultation"
            },
            weight: {
                label: "Poids (kg)",
                placeholder: "ex : 69.6",
                title: "Veuillez renseigner le poids du patient lors de cette consultation"
            },
            imc: {
                label: "I.M.C.",
                placeholder: "ex : 22.6",
                title: "L'IMC du patient sera indiqué dans ce champ"
            }
        },
        'evolution-state': {
            label: "Anamnèse du jour",
            title: "Affichez l'anamnèse du jour",
            placeholder: "ex : Va mieux depuis 2 jours"
        },
        observations: {
            label: "Observations",
            title: "Affichez les observations",
            placeholder: "ex : Lors de l'examen, j'ai pu constater…"
        },
        'eva-before': {
            label: "E.V.A. avant",
            title: "Affichez l'EVA avant",
        },
        'eva-after': {
            label: "E.V.A. après",
            title: "Affichez l'EVA après",
        },
        diagnostic: {
            label: "Évolution du diagnostic",
            title: "Affichez l'évolution du diagnostic",
            placeholder: "ex : Va mieux depuis 2 jours…"
        },
        tests: {
            label: "Tests ostéopathiques",
            title: "Affichez les tests ostéopathiques",
            placeholder: "ex : Zone de blocage dorsales…"
        },
        treatment: {
            label: "Traitement",
            title: "Affichez le traitement",
            placeholder: "ex : Compression crânienne…"
        },
        'exclusion-tests': {
            label: "Tests d'exclusion",
            title: "Affichez les tests d'exclusion"
        },
        'cranio-sacral': {
            label: "Cranio sacré",
            title: "Affichez le cranio sacré"
        },
        walton: {
            label: "Diagramme de Walton",
            title: "Affichez le diagramme de Walton"
        },
        schema: {
            label: "Schéma corporel",
            title: "Affichez le schéma corporel"
        }
    }
}
