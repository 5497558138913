import * as Statistics from '@ViewModels/root/statistics'
import { Profile } from '@Enums/profile'

export const StatisticsRoot = [
	{
		path: 'colleague/:col_id', 
		name: 'colleague',
		component: Statistics.Colleague,
		meta: { permission: [Profile.Main, Profile.Demo] },
	},
	{
		path: 'statistics', 
		redirect: 'statistics-patients',
		component: Statistics.Main,
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: [
			{
				path: '', 
				name: 'statistics-patients',
				component: Statistics.Patients,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'consultations', 
				name: 'statistics-consultations',
				component: Statistics.Consultations,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'planning', 
				name: 'statistics-planning',
				component: Statistics.Planning,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'planning-online', 
				name: 'statistics-planning-online',
				component: Statistics.PlanningOnline,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'accounting', 
				name: 'statistics-accounting',
				component: Statistics.Accounting,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'references', 
				name: 'statistics-references',
				component: Statistics.Reference,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'colleagues', 
				name: 'statistics-colleagues',
				component: Statistics.Colleagues,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'top10', 
				name: 'statistics-top10',
				component: Statistics.Top10,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
		]
	}
]