<dropdown
    :id="dropdownId"
    class="form-group inline-flex"
    :trigger="triggerClass"
    caret="svg-block"
    menu-stuck="right"
    :disabled="isLoading || disabled"
    :menu-on-top="menuOnTop"
    :display-selected-item="false"
    v-model="currentAction"
    :items="actions">
    <button-action slot="inner-element" :btn-class="btnClass" @click.native="callAction"
        :loading="isLoading" :title="actionTitle" :disabled="isLoading || disabled">
        <svg v-if="!!actionIcon" width="1em" class="svg-middle" :class="svgClass" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${actionIcon}`"/>
        </svg>
        {{ actionLabel }}
    </button-action>
</dropdown>
