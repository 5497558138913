export const office = {

	'all-office': "Tous les cabinets",
	session: 'type de consultation | type de consultation | types de consultation',
	access: 'accès | accès | accès',
	added: 'un {item} ajouté | {n} {item} ajoutés',
	updated: 'un {item} modifié | {n} {item} modifés',
	deleted: 'un {item} effacé | {n} {item} effacés',
	incomplete: 'un {item} incomplet | {n} {item} incomplets',
	'added-female': 'une {item} ajoutée | {n} {item} ajoutées',
	'updated-female': 'une {item} modifiée | {n} {item} modifées',
	'deleted-female': 'une {item} effacée | {n} {item} effacées',
	'incomplete-female': 'une {item} incomplète | {n} {item} incomplètes',
	'office-saved': "Informations du cabinet sauvegardées",
	'office-incomplete': "Informations du cabinet incomplète",
	'coordinates-created': "coordonnées générales créées",
	'coordinates-updated': "coordonnées générales modifiées",
	'coordinates-incomplete': "coordonnées générales incomplètes",
	'schedule-updated': "horaires modifiés",
	'settings-updated': "paramètres modifiés",
	'default-session': {
		name: "Classique",
		description: "Type de consultation par défaut"
	},
	complete: "compléter"
}
