export const email = {
    'save-notification': {
        action: "Renvoyer le courriel",
		message: "Un courriel de confirmation vient de vous être envoyé à votre nouvelle adresse. Vérifiez vos spams."
    },
    title: "Modifiez votre adresse email",
    'current-email': "Votre adresse email actuelle est {0}.",
    'waiting-email': "Cette adresse est en attente de validation : {0}.",
    'new-email': {
        label: "Nouvelle adresse email",
        'error-msg': "Merci de renseigner votre nouvelle adresse email",
        placeholder: "ex : andrew-taylor-still@lycos.com",
        title: "Veuillez renseigner votre nouvelle adresse email"
    },
    'confirm-email': {
        label: "Confirmation de votre adresse email",
        'error-msg': "Merci de confirmer votre nouvelle adresse email",
        placeholder:"ex : andrew-taylor-still@lycos.com",
        title:"Veuillez confirmer votre nouvelle adresse email"
    },
    update: {
        'short-label': "modifier",
        'long-label': "votre email",
        title: "Mettez à jour votre email"
    }
}