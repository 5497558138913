<div v-if="antecedents">
	<h2>{{ $t('vm.root.patients.patient.antecedents.title') }}</h2>

	<div class="grid-2">
		<div class="input-frame">
			<label class="label" for="filter" :data-text="$t('vm.root.patients.patient.antecedents.filter.label')">
				<span>{{ $t('vm.root.patients.patient.antecedents.filter.label') }}</span>
			</label>
			<div class="with-icon">
				<svg width="1em" class="with-icon-icon is-shown" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" id="filter" v-model="filter"
					:placeholder="$t('vm.root.patients.patient.antecedents.filter.placeholder')"
					:title="$t('vm.root.patients.patient.antecedents.filter.title')">
			</div>
		</div>
		<div class="input-frame-like">
			<button type="button" class="btn btn-primary btn--input-shape" @click="addAntecedent($event)"
				:title="$t('vm.root.patients.patient.antecedents.new-antecedent.title', {type: ``})">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.patients.patient.antecedents.new-antecedent.text') }}
			</button>
		</div>
	</div>

	<hr>

	<div v-for="(antecedents, type) in antecedentsGrouped" :key="type">
		<div class="childs-space-between marg-v">
			<h3 class="text-uppercase-first-letter no-marg" :inner-html.prop="type | mark(filter)"/>
			<button type="button" class="btn--link-like is-pushed-right" @click="addAntecedent($event, type)"
				:title="$t('vm.root.patients.patient.antecedents.new-antecedent.title', {type: type})">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.patients.patient.antecedents.new-antecedent.text') | capitalize }}
			</button>
		</div>

		<ul class="ul-reset">
			<li v-for="antecedent in antecedents" :key="getElementId(antecedent)">

				<antecedent v-if="antecedent.edit && !readOnly" :ref="getElementId(antecedent)" :antecedent="antecedent" :read-only="readOnly" :submitted="submitted"/>

				<collapsible-panel v-else collapsible-class="marg-v">
					<template slot="title">
						<svg v-if="antecedent.important" width="1em" class="svg-middle text-md" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle" />
						</svg>
						<span class="text-uppercase-first-letter" :inner-html.prop="antecedent.title | mark(filter)"/>
					</template>
					<div v-if="owner(antecedent)" slot="subtitle" class="text-italic text-sm marg-l-1">
						<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
						</svg>
						{{ $t('vm.root.patients.patient.antecedents.create-by', {owner: owner(antecedent)}) }}
					</div>
					<span slot="right" :class="{'text-danger': antecedent.important}">
						{{ $t('general.at') }} {{coordinates.birth_date | age(antecedent.event_date)}},

						<em v-if="antecedent.periodic && antecedent.running">
							<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
							</svg> {{ $t('general.running') }}, {{antecedent.event_date | since}}
						</em>
						<span v-else-if="antecedent.periodic">{{antecedent.event_date | timeAgo}}, {{ $t('general.during') }} {{antecedent.event_date | age(antecedent.end_date)}}</span>
						<span v-else>{{antecedent.event_date | timeAgo}}</span>
					</span>

					<p class="text-pre-line no-marg" :inner-html.prop="antecedent.description | mark(filter)"/>

					<form-summary v-for="form in antecedent.forms" :key="form.id" :form="form"/>

					<div class="text-right">
						<button type="button" @click="editAntecedent(antecedent)"class="btn--link-like" :title="$t('general.edit.title')">{{ $t('general.edit.text') }}</button>
						<span v-if="!readOnly && isMainUser">&bull;
							<delete-item class="btn btn--link-like text-danger" :can-delete="true"
								:delete-action="() => deleteAntecedent(antecedent)">
								{{ $t('general.delete.text') }}
							</delete-item>
						</span>
					</div>
				</collapsible-panel>
			</li>
		</ul>
	</div>
	<div v-if="Object.keys(antecedentsGrouped).length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.antecedents.none.title') }}</span><br>
			<i18n path="vm.root.patients.patient.antecedents.none.description.text" tag="span">
				<button type="button" class="btn--link-like" @click="addAntecedent($event)" :title="$t('vm.root.patients.patient.antecedents.none.description.title')">{{ $t("vm.root.patients.patient.antecedents.none.description.link") }}</button>
			</i18n>
		</p>
	</div>

	<collapsible-help id="antecedents-information" tag="h3"
		:title="$t('vm.root.patients.patient.antecedents.family.label')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.patients.patient.antecedents.help1') }}
			</p>
			<i18n path="vm.root.patients.patient.antecedents.help2.text" tag="p" class="text-left">
				<strong>{{ $t('vm.root.patients.patient.antecedents.help2.strong1') }}</strong>
				<strong>{{ $t('vm.root.patients.patient.antecedents.help2.strong2') }}</strong>
			</i18n>
		</div>
	</collapsible-help>

	<div v-if="readOnly">
		<p class="text-pre-line">
			<span v-if="!!coordinates.family_antecedents">{{coordinates.family_antecedents}}</span>
			<em v-else-if="familyAntecedents.length === 0">{{ $t('vm.root.patients.patient.antecedents.not-specified') }}</em>
		</p>
	</div>
	<div v-else>
		<textarea-frame id="family" v-model="coordinates.family_antecedents"
			:label="$t('vm.root.patients.patient.antecedents.family.label')"
			:placeholder="$t('vm.root.patients.patient.antecedents.family.placeholder')"
			:title="$t('vm.root.patients.patient.antecedents.family.title')"/>
	</div>

	<ul class="ul-reset" v-if="familyAntecedents.length > 0">
		<li v-for="antecedent in familyAntecedents" :key="antecedent.id">
			<family-antecedent :antecedent="antecedent" :filter="filter"/>
		</li>
	</ul>

</div>
