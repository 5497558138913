<a-side :sub-menu="true" v-if="!!planning">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'plannings'}" :title="$t('vm.root.calendar.planning.aside.back.label')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.calendar.planning.aside.back.label') }}
            </router-link>
        </li>
    </ul>
    <ul class="aside-navigation is-pushed-down">
        <li class="hr" aria-hidden="true"/>
        <li>
            <delete-item :delete-msg="$t('vm.root.calendar.planning.aside.delete.confirm')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
                :delete-action="submitDelete" :can-delete="true" :disabled="isDeleting">
                <spinner v-if="isDeleting"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
                </svg>
                {{ $t('vm.root.calendar.planning.aside.delete.label') }}
            </delete-item>
        </li>
    </ul>
</a-side>