export const neonatal = {
    title: "Néonatal",
    subtitle1: "Examen néonatal de l'enfant",
    premature: {
        'with-label': "Enfant prématuré",
        label: "Détails",
        placeholder: "ex : 37 semaines d'aménorrhée",
        title: "Veuillez indiquer le nombre de semaines du fœtus"
    },
    weight: {
        label: "Poids à la naissance (en Kg)",
        placeholder: "ex : 3,150",
        title: "Veuillez renseigner le poids du nourrisson à la naissance"
    },
    size: {
        label: "Taille à la naissance (en cm)",
        placeholder: "ex : 52",
        title: "Veuillez renseigner la taille du nourrisson à la naissance"
    },
    cyanotic: "Cyanosé",
    apgar1: {
        label: "APGAR 1" ,
        placeholder: "ex : 7",
        title: "Veuillez renseigner l'APGAR 1",
    }, 
    apgar3: {
        label: "APGAR 3",
        placeholder: "ex : 7",
        title: "Veuillez renseigner l'APGAR 3"
    },
    apgar5: {
        label: "APGAR 5",
        placeholder: "ex : 7",
        title: "Veuillez renseigner l'APGAR 5"
    },
    apgar10: {
        label: "APGAR 10",
        placeholder: "ex : 7",
        title: "Veuillez renseigner l'APGAR 10"
    },
    alimentation: "Type d'alimentation",
    reanimation: {
        'with-label': "Réanimation",
        label: "Détails de la réanimation",
        placeholder: "ex : Ventilé pendant 3 minutes",
        title: "Veuillez décrire la réanimation"
    },
    scream: {
        'with-label': "A crié immédiatement",
        label: "Détails",
        placeholder: "ex : Une minute apres",
        title: "Veuillez décrire la situation"
    },
    'deformed-skull': {
        'with-label': "Crâne déformé",
        label: "Forme du crâne",
        placeholder: "ex : Œdème sur le pariétal gauche",
        title: "Veuillez décrire la déformation du crâne"
    },
    remarks4: {
        label: "Remarques complémentaires",
        placeholder: "ex : beau bébé ", 
        title: "Veuillez renseigner des remarques complémentaires que vous ne pourriez saisir ailleurs"
    },

    subtitle2: "Comportement de l'enfant",
    suckle: "Difficultés pour téter ou avaler",
    reflux: {
        'with-label': "Reflux gastro-œsophagien",
        label: "Type de reflux",
        placeholder: "ex : Vomissements en jets",
        title: "Veuillez décrire le type de reflux"
    },
    allergies: {
        'with-label': "Allergies",
        label: "Décription des allergies",
        placeholder: "ex : Terrain maternel",
        title: "Veuillez décrire les allergies"
    },
    sleep: {
        label: "Sommeil",
        placeholder: "ex : Variable, plutôt éveillé",
        title: "Veuillez décrire le type de sommeil"
    },
    state: {
        label: "État nerveux",
        placeholder: "ex : Plutôt excité",
        title: "Veuillez décrire l'état nerveux"
    },
    remarks5: {
        label: "Remarques complémentaires",
        placeholder: "ex : beau bébé ", 
        title: "Veuillez renseigner des remarques complémentaires que vous ne pourriez saisir ailleurs"
    }
}