import Render from '@Views/components/super-panel/layout/super-panel-merge.html'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {IMergeData, IPatient} from "@Store/types/patient"
import {patient} from "@Store/modules/patient"
import {Dictionary} from "vue-router/types/router"
import {ButtonMerge} from "@Components/super-panel/layout/merge/button-merge"

@Render
@Component({
	components: {
		'button-merge': ButtonMerge
	}
})
export class SuperPanelMerge extends Vue {

	private listEndObserver: IntersectionObserver

	patients: IPatient[] = []
    @Prop() options: {idList: string[], keys: Dictionary<IMergeData>, loaded: boolean, allSelected: boolean}
	@Prop({default: false}) submitted: boolean

	//#region Getters
	get idList(): string[] {
		return this.options.idList
	}
	get loading(): boolean {
		return !this.options.loaded
	}
	get rowCount(): number {
		let count: number = 0
		let keys: string[] = Object.keys(this.options.keys)
		for(let key of keys) {
			count += this.options.keys[key].isVisible ? 1 : 0
		}
		return count
	}
	get patientName(): string {
		if (this.loading) return '…'

		return `${this.patients[0].coordinates.first_name} ${this.patients[0].coordinates.last_name}`
	}
	private get allSelected(): boolean {
		let keys: string[] = Object.keys(this.options.keys)
		for (let key of keys) {
			if (!this.options.keys[key].isVisible || this.options.keys[key].id) continue

			return false
		}

		return true
	}
	//#endregion

	mounted() {
		this.initializeObserver()
		this.clearMergeData()

		this.loadPatient(0)
	}

	beforeDestroy() {
		if (!this.listEndObserver) return

		this.listEndObserver.disconnect()
	}

	owner(p: IPatient): string {
		let owner: string = patient.owner({id:p.id, creator_id: !!p.creator_id ? p.creator_id : '-1'})
		return !!owner ? owner : "vous-même"
	}

	isSelected(patId: string): boolean {
		let data: IMergeData = this.options.keys['main']
		if (!data) return false

		return data.id === patId
	}

	preSelect(): void {
		this.clearMergeData()
		for(let patient of this.patients) {
			this.updateVisibilityData(patient)
			this.updateMergeData(patient)
		}
	}

	initializeObserver() {
		if (this.listEndObserver) return

		this.$nextTick().then(() => {
			this.setUpInterSectionObserver()
		})
	}

	@Watch('options', {deep: true})
	updateData() {
		this.options.allSelected = this.allSelected
	}

	private loadPatient(index: number): void {
		if (index > this.idList.length - 1) {
			this.options.loaded = true
			return
		}

		patient.loadPatient({pat_id: this.idList[index]})
			.then((result: IPatient) => {
				this.patients.push(result)
				this.updateVisibilityData(result)
				this.loadPatient(++index)
			})
	}

	private clearMergeData(): void {
		this.options.keys = {
			'main': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'birth_date': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'nir': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'phone1': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'phone2': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'address': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'email': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'gender': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'notes': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'situation': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'number_of_children': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'professions': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'reference': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'habitus': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'family_antecedents': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'form_coordinates': {id: undefined, date: undefined, hasValue: false, isVisible: false},
			'pediatrics': {id: undefined, date: undefined, hasValue: false, isVisible: false},
		}
	}

	private updateVisibilityData(patient: IPatient): void {
		this.options.keys['main'] = SuperPanelMerge.getVisibilityValue(this.options.keys['main'], patient.id)
		this.options.keys['birth_date'] = SuperPanelMerge.getVisibilityValue(this.options.keys['birth_date'], patient.coordinates.without_birth_date ? '' : this.$i18n.d(patient.coordinates.birth_date, 'll'))
		this.options.keys['phone1'] = SuperPanelMerge.getVisibilityValue(this.options.keys['phone1'], patient.coordinates.phone1.number)
		this.options.keys['phone2'] = SuperPanelMerge.getVisibilityValue(this.options.keys['phone2'], patient.coordinates.phone2.number)
		this.options.keys['email'] = SuperPanelMerge.getVisibilityValue(this.options.keys['email'], patient.coordinates.email)
		this.options.keys['nir'] = SuperPanelMerge.getVisibilityValue(this.options.keys['nir'], patient.coordinates.nir.number)
		this.options.keys['address'] = SuperPanelMerge.getVisibilityValue(this.options.keys['address'], patient.coordinates.address.formatted)
		this.options.keys['notes'] = SuperPanelMerge.getVisibilityValue(this.options.keys['notes'], patient.coordinates.notes)
		this.options.keys['gender'] = SuperPanelMerge.getVisibilityValue(this.options.keys['gender'], `${patient.coordinates.gender}`)
		this.options.keys['situation'] = SuperPanelMerge.getVisibilityValue(this.options.keys['situation'], `${patient.coordinates.situation}`)
		this.options.keys['number_of_children'] = SuperPanelMerge.getVisibilityValue(this.options.keys['number_of_children'], `${patient.coordinates.number_of_children}`)
		this.options.keys['professions'] = SuperPanelMerge.getVisibilityValue(this.options.keys['professions'], patient.coordinates.professions.join())
		this.options.keys['reference'] = SuperPanelMerge.getVisibilityValue(this.options.keys['reference'], patient.coordinates.reference.join())
		this.options.keys['habitus'] = SuperPanelMerge.getVisibilityValue(this.options.keys['habitus'], patient.coordinates.habitus)
		this.options.keys['family_antecedents'] = SuperPanelMerge.getVisibilityValue(this.options.keys['family_antecedents'], patient.coordinates.family_antecedents)
		this.options.keys['form_coordinates'] = SuperPanelMerge.getVisibilityValue(this.options.keys['form_coordinates'], "value")
		this.options.keys['pediatrics'] = SuperPanelMerge.getVisibilityValue(this.options.keys['pediatrics'], "value")
	}

	private updateMergeData(patient: IPatient): void {
		this.options.keys['main'] = SuperPanelMerge.getRecentValue(this.options.keys['main'], patient, patient.id, false)
		this.options.keys['birth_date'] = SuperPanelMerge.getRecentValue(this.options.keys['birth_date'], patient, patient.coordinates.without_birth_date ? '' : this.$i18n.d(patient.coordinates.birth_date, 'll'))
		this.options.keys['phone1'] = SuperPanelMerge.getRecentValue(this.options.keys['phone1'], patient, patient.coordinates.phone1.number)
		this.options.keys['phone2'] = SuperPanelMerge.getRecentValue(this.options.keys['phone2'], patient, patient.coordinates.phone2.number)
		this.options.keys['email'] = SuperPanelMerge.getRecentValue(this.options.keys['email'], patient, patient.coordinates.email)
		this.options.keys['nir'] = SuperPanelMerge.getRecentValue(this.options.keys['nir'], patient, patient.coordinates.nir.number)
		this.options.keys['address'] = SuperPanelMerge.getRecentValue(this.options.keys['address'], patient, patient.coordinates.address.formatted)
		this.options.keys['notes'] = SuperPanelMerge.getRecentValue(this.options.keys['notes'], patient, patient.coordinates.notes)
		this.options.keys['gender'] = SuperPanelMerge.getRecentValue(this.options.keys['gender'], patient, `${patient.coordinates.gender}`)
		this.options.keys['situation'] = SuperPanelMerge.getRecentValue(this.options.keys['situation'], patient, `${patient.coordinates.situation}`)
		this.options.keys['number_of_children'] = SuperPanelMerge.getRecentValue(this.options.keys['number_of_children'], patient, `${patient.coordinates.number_of_children}`)
		this.options.keys['professions'] = SuperPanelMerge.getRecentValue(this.options.keys['professions'], patient, patient.coordinates.professions.join())
		this.options.keys['reference'] = SuperPanelMerge.getRecentValue(this.options.keys['reference'], patient, patient.coordinates.reference.join())
		this.options.keys['habitus'] = SuperPanelMerge.getRecentValue(this.options.keys['habitus'], patient, patient.coordinates.habitus)
		this.options.keys['family_antecedents'] = SuperPanelMerge.getRecentValue(this.options.keys['family_antecedents'], patient, patient.coordinates.family_antecedents)
		this.options.keys['form_coordinates'] = SuperPanelMerge.getRecentValue(this.options.keys['form_coordinates'], patient, "value")
		this.options.keys['pediatrics'] = SuperPanelMerge.getRecentValue(this.options.keys['pediatrics'], patient, "value")
	}

	private static getVisibilityValue(data: IMergeData, value?: string): IMergeData {
		return {id: data.id, date: data.date, hasValue: data.hasValue, isVisible: data.isVisible || !!value}
	}

	private static getRecentValue(data: IMergeData, patient: IPatient, value?: string, moreRecent: boolean = true): IMergeData {
		if (!data.id)
			return {id: patient.id, date: patient.create_date, hasValue: !!value, isVisible: data.isVisible}

		if (!data.hasValue && !!value)
			return {id: patient.id, date: patient.create_date, hasValue: !!value, isVisible: data.isVisible}

		if (!!value && ((moreRecent && data.date < patient.create_date) || (!moreRecent && data.date > patient.create_date)))
			return {id: patient.id, date: patient.create_date, hasValue: !!value, isVisible: data.isVisible}

		return data
	}

	private setUpInterSectionObserver(): void {
		let options: IntersectionObserverInit = {
			root: this.$refs["scrollContainer"] as Element,
			// rootMargin: "20px"
		}
		this.listEndObserver = new IntersectionObserver(
			(x: any) => { this.handleIntersection(x, this) },
			options
		)

		let sentinel: Element = this.$refs['butter'] as Element
		this.listEndObserver.observe(sentinel)
	}

	private handleIntersection([entry], vm) {
		let scrollerParent: Element = this.$refs["scrollParent"] as Element
		if (!scrollerParent) return

		if (entry.isIntersecting) scrollerParent.classList.remove("is-overflowing")
		else scrollerParent.classList.add("is-overflowing")
	}
}
