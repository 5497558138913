<div>
	<h1>{{ $t('vm.root.user.gift.gift2.title') }}</h1>
	<form @submit.prevent="submitGift">
		<div v-if="!canSubmit" class="wrapper marg-v text-center">
			<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
			</svg>
			<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift2.valided.title') }}</strong></p>
			<p>{{ $t('vm.root.user.gift.gift2.valided.text') }}</p>
		</div>
		<template v-else>
			<section>
				<h2>{{ $t('vm.root.user.gift.gift2.subtitle') }}</h2>
				<i18n path="vm.root.user.gift.gift2.help.text1" tag="p">
					<strong>{{ $t('vm.root.user.gift.gift2.help.strong1') }}</strong>
					<strong>{{ $t('vm.root.user.gift.gift2.help.strong2') }}</strong>
				</i18n>
				<p>
					{{ $t('vm.root.user.gift.gift2.help.text2') }}
				</p>
			</section>
			<section>
				<h2>{{ $t('vm.root.user.gift.gift2.cities.main-title') }}</h2>
				<div class="grid-2">
					<input-frame v-for="(city, index) in cities" type="text" :ref="`city-${index}`" :id="`city-${index}`" v-model="cities[index]"
						:label="$tc('vm.root.user.gift.gift2.cities.label', index + 1)"
						:error-test="hasCityError" :error-msg="$t('vm.root.user.gift.gift2.cities.error-msg')"
						:placeholder="$t('vm.root.user.gift.gift2.cities.placeholder')"
						:title="$tc('vm.root.user.gift.gift2.cities.title', index + 1)"/>
				</div>
			</section>
			<section>
				<h2>{{ $t('vm.root.user.gift.gift2.domains.main-title') }}</h2>
				<div class="grid-2">
					<input-frame v-for="(target, index) in targets" type="text" :ref="`pref-${index}`" :id="`pref-${index}`" v-model="targets[index]"
						:label="$tc('vm.root.user.gift.gift2.domains.label', index + 1)"
						:placeholder="$t('vm.root.user.gift.gift2.domains.placeholder')"
						:title="$tc('vm.root.user.gift.gift2.domains.title', index + 1)"/>
				</div>
			</section>
		</template>
		<control-bar class="childs-space-between">
			<button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.user.gift.gift2.back.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.gift.gift2.back.label') }}
			</button-action>

			<span>
				<button-action type="submit" btn-class="btn btn-primary" :disabled="!canSubmit" :loading="isSaving" :title="$t('vm.root.user.gift.gift2.action.title')">
					{{ $t('vm.root.user.gift.gift2.action.label') }}
				</button-action>

				<button-action btn-class="btn btn-default" @click.native="next" :title="$t('vm.root.user.gift.gift2.next.title')">
					{{ $t('vm.root.user.gift.gift2.next.label') }}
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</button-action>
			</span>
		</control-bar>
	</form>


</div>
