import Render from '@Views/root/patients/merge/context-merge.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IDropdownItem } from '@Components/index'
import { StorageHelper } from '@Helpers/index'
import { IOffice, IExportFilter } from '@Store/types'
import { office, patients } from '@Store/modules'

@Render
@Component({})
export class ContextMerge extends Vue {

    get filter(): IExportFilter {
        return patients.filterExport
    }
    get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		result.push({value: '-1', label: this.$i18n.t('general.all-offices').toString()})
		let offices: IOffice[] = office.offices
		for (let o of offices) {
			if (!office.isActive(o)) continue

			result.push({value: o.id, label: o.coordinates.name})
		}

		return result
    }

    @Watch('filter', {deep: true})
	saveFilter() {
		StorageHelper.set('export-filter', this.filter)
	}
}
