import { Vue, Component } from 'vue-property-decorator'
import config from "@Config/index"
import Render from '@Views/login/main.html'
import { login } from '@Store/modules'

@Render
@Component({})
export class Main extends Vue {

	private dates: {date: Date, classes: string[]}[] = [
		{date: new Date('2020-12-17'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-18'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-19'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-20'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-21'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-22'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-23'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-24'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-25'), classes: ['bg-xmas-1', 'bg-xmas-2', 'bg-xmas-3']},
		{date: new Date('2020-12-31'), classes: ['bg-new-year']},
		{date: new Date('2020-01-01'), classes: ['bg-new-year']},
		{date: new Date('2020-10-31'), classes: ['bg-halloween']},
		{date: new Date('2020-05-04'), classes: ['bg-starwars']},
		{date: new Date('2020-06-18'), classes: ['bg-appel']},
	]

	get bgClass(): string {
		let now: Date = new Date()
		for(let item of this.dates) {
			if (this.$i18n.d(now, 'short_ll') !== this.$i18n.d(item.date, 'short_ll')) continue
		
			let index: number = Math.floor(Math.random() * Math.floor(item.classes.length))
			return item.classes[index]
		}

		let index = Math.round((Math.random()*6)+1)
		return `bg-${index}`
	}

	get version() {
		return config.version
	}

	created() {
		let route = this.$router.currentRoute
		if (!!route && route.name === 'loader') return
		if (!!route && route.name === 'register') return

		login.logout()
		.then(() => {
			if (this.$router.currentRoute.name === 'login') return
			this.$router.push({ name: 'login' })
		})
	}
}
