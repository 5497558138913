<div class="wrapper">
	<section class="marg-v">
		<h2>{{ $t('vm.root.patients.export.context.title') }}</h2>

		<input-frame :auto-focus="true" type="text" id="last-name" v-model="filter.last_name"
			:label="$t('vm.root.patients.export.context.last-name.label')"
			:placeholder="$t('vm.root.patients.export.context.last-name.placeholder')"
			:title="$t('vm.root.patients.export.context.last-name.title')"/>

		<input-frame type="text" id="first-name" v-model="filter.first_name"
			:label="$t('vm.root.patients.export.context.first-name.label')"
			:placeholder="$t('vm.root.patients.export.context.first-name.placeholder')"
			:title="$t('vm.root.patients.export.context.first-name.title')"/>

		<dropdown-frame id="office" v-model="filter.ofi_id" :items="officeItems"
			:label="$t('vm.root.patients.export.context.office.label')"
			:title="$t('vm.root.patients.export.context.office.title')"/>

		<div class="label">{{ $t('vm.root.patients.context.export.more-filter') }}</div>
		<checkbox-frame id="blacklisted" v-model="filter.blacklisted">{{ $t('vm.root.patients.context.export.blacklisted') }}</checkbox-frame>
		<checkbox-frame id="deceased" v-model="filter.deceased">{{ $t('vm.root.patients.context.export.deceased') }}</checkbox-frame>
	</section>
</div>
