import Render from '@Views/root/user/gift/gift3.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IGift, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { GiftState } from '@Enums/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class Gift3 extends Vue {

	index: number = -1
	answer: string = ""
	submitted: boolean = false

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('user/submitGift3') submitGift3:(payload: Gift3Payload) => Promise<any>
	
	@Prop() gift: IGift

	get isSaving(): boolean {
		return user.isGiftSaving
	}
	get answers(): string[] {
		return this.gift.answers
	}
	get questions(): string[] {
		return this.gift.questions
	}
	get questionItems(): IDropdownItem[] {
		let result: IDropdownItem[] = this.gift.questions.map((question, index) => {
			let icon: string = "question-circle"
			if (this.isValidatedQuestion(index)) icon = "check-circle"
			else if (this.isValidatingQuestion(index)) icon = "hourglass-half"
			return {value: index, label: question, icon: icon}
		})
		result.unshift({value: -1, label: this.$i18n.t('vm.root.user.gift.gift3.question.none').toString(), disabled: true})
		return result
	}
	get isFull(): boolean {
		return this.gift.state_questions.filter(state => { return state === GiftState.Validated }).length >= 10
	}
	get isWaiting(): boolean {
		return this.gift.state_questions.filter(state => { return state !== GiftState.Running }).length >= 10
	}
	get isRunning(): boolean {
		return this.gift.state_questions[this.index] === GiftState.Running
	}
	get isValidated(): boolean {
		return this.isValidatedQuestion(this.index)
	}
	get isValidating(): boolean {
		return this.isValidatingQuestion(this.index)
	}
	get hasAnswerError(): boolean {
		return this.submitted && this.wordCount < 150
	}
	get canSubmit(): boolean {
		return !this.isFull && !this.isWaiting && this.index >= 0 && this.gift.state_questions[this.index] === GiftState.Running && this.wordCount >= 150
	}
	get wordCount(): number {
		if (this.index < 0) return 0
		let text: string = this.answers[this.index]
		text = text.replace(/[^a-zA-Z0-9]/g, ' ').trim()
		if (text === '') return 0
		var regex = /\s+/gi
		return text.replace(regex, ' ').split(' ').length
	}
	  
	private isValidatedQuestion(index: number): boolean {
		return this.gift.state_questions[index] === GiftState.Validated
	}
	private isValidatingQuestion(index: number): boolean {
		return this.gift.state_questions[index] === GiftState.Validating
	}

	mounted() {
		// this.$emit('navigate', 'referral')
		this.initializeBreadcrumb()
	}

	previous(): void {
        this.$router.push({name: 'user-gift2'})
	}
	
	next(): void {
        this.$router.push({name: 'user-gift-bonus'})
	}

	submitGift(): void {
		if (!this.canSubmit) return
		if (this.submitted) return

		this.submitted = true

		user.submitGift3({question_index: this.index, answer: this.answers[this.index]})
		.then((gift: IGift) => {
			this.index = -1
			this.submitted = false
			this.$emit('restore', gift)
		})
		.catch((error) => { this.submitted = false })
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift').toString(), link: 'user-gift' }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift3').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}