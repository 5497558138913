<div v-if="!!spend" class="grid-2">

    <input-frame :auto-focus="true" type="text" id="label" v-model="spend.label"
        :label="$t('vm.root.accounting.spend.details.label.label')"
        :placeholder="$t('vm.root.accounting.spend.details.label.placeholder')"
        :error-test="hasLabelError" :error-msg="$t('vm.root.accounting.spend.details.label.error-msg')"
        :title="$t('vm.root.accounting.spend.details.label.title')"/>
    <input-date-frame v-model='spend.settlement_date' id="settlement-date" :type="dateType"
        :label="$t('vm.root.accounting.spend.details.date.label')"
        :placeholder="$t('vm.root.accounting.spend.details.date.placeholder')"
        :title="$t('vm.root.accounting.spend.details.date.title')"/>

    <dropdown-frame id="office" v-model="spend.ofi_id" :items="officeItems" class="grid-item-full"
        :label="$t('vm.root.accounting.spend.details.office.label')"
        :title="$t('vm.root.accounting.spend.details.office.title')"/>

    <input-frame type="text" id="number" v-model="spend.number" :readonly="!spend.can_modify"
        :label="$t('vm.root.accounting.spend.details.number.label')"
        :placeholder="$t('vm.root.accounting.spend.details.number.placeholder')"
        :title="$t('vm.root.accounting.spend.details.number.title')"/>
    <div class="input-frame-like">
        <switch-frame id="modify-number" :title="$t('vm.root.accounting.spend.details.modify-number.title')" v-model="spend.can_modify">
            {{ $t('vm.root.accounting.spend.details.modify-number.label') }}
        </switch-frame>
    </div>    

    <dropdown-frame id="spend-type" v-model="spend.spend_type" :items="spendTypeItems" 
        :label="$t('vm.root.accounting.spend.details.type.label')"
        :title="$t('vm.root.accounting.spend.details.type.title')"/>
    <dropdown-frame id="payment-mode" v-model="spend.mode" :items="paymentModeItems" 
        :label="$t('vm.root.accounting.spend.details.mode.label')"
        :title="$t('vm.root.accounting.spend.details.mode.title')"/>

    <input-frame type="number" id="amount" v-model="spend.ttc" min="0" step="0.01"
        :label="$t('vm.root.accounting.spend.details.amount.label')"
        :placeholder="$t('vm.root.accounting.spend.details.amount.placeholder')"
        :title="$t('vm.root.accounting.spend.details.amount.title')"/>
    <input-frame type="number" id="tva" v-model="spend.tva" min="0" max="100" step="0.1"
        :label="$t('vm.root.accounting.spend.details.tva.label')"
        :placeholder="$t('vm.root.accounting.spend.details.tva.placeholder')"
        :title="$t('vm.root.accounting.spend.details.tva.title')"/>
</div>
    