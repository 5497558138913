import Render from '@Views/root/user/office/coordinates.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOffice, IOfficeCoordinates, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { PaymentMode, Timezone, Currency } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb } from '@Store/modules'

@Render
@Component({})
export class Coordinates extends Vue {

	@Prop() office: IOffice
	@Prop() submitted: boolean

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get coordinates(): IOfficeCoordinates {
		return this.office.coordinates
	}
	get hasNameError(): boolean {
		return this.submitted && !this.coordinates.name
	}
	get timezoneItems(): IDropdownItem[] {
		return [
			{value: Timezone.UtcPlus1, label: EnumsHelper.timezoneToString(Timezone.UtcPlus1)},
			{value: Timezone.UtcMinus3, label: EnumsHelper.timezoneToString(Timezone.UtcMinus3)},
			{value: Timezone.UtcMinus4, label: EnumsHelper.timezoneToString(Timezone.UtcMinus4)},
			{value: Timezone.UtcMinus5, label: EnumsHelper.timezoneToString(Timezone.UtcMinus5)},
			{value: Timezone.UtcPlus4, label: EnumsHelper.timezoneToString(Timezone.UtcPlus4)},
			{value: Timezone.UtcPlus11, label: EnumsHelper.timezoneToString(Timezone.UtcPlus11)}
		]
	}
	get currencyItems(): IDropdownItem[] {
		return [
			{value: Currency.Euro, label: EnumsHelper.currencyToString(Currency.Euro, false)},
			{value: Currency.Dollar, label: EnumsHelper.currencyToString(Currency.Dollar, false)}
		]
    }
	get modeItems(): IDropdownItem[] {
		return [
			{value: PaymentMode.Check, label: EnumsHelper.paymentToString(PaymentMode.Check)},
			{value: PaymentMode.CreditCard, label: EnumsHelper.paymentToString(PaymentMode.CreditCard)},
			{value: PaymentMode.FreeAct, label: EnumsHelper.paymentToString(PaymentMode.FreeAct)},
			{value: PaymentMode.Moneo, label: EnumsHelper.paymentToString(PaymentMode.Moneo)},
			{value: PaymentMode.Cash, label: EnumsHelper.paymentToString(PaymentMode.Cash)},
			{value: PaymentMode.Transfer, label: EnumsHelper.paymentToString(PaymentMode.Transfer)},
			{value: PaymentMode.Other, label: EnumsHelper.paymentToString(PaymentMode.Other)},
			{value: PaymentMode.Waiting, label: EnumsHelper.paymentToString(PaymentMode.Waiting)}
		]
    }

	mounted() {
		this.$emit('navigate', 'coordinates-office')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
