import Render from '@Views/root/help/context-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { Dictionary } from "vue-router/types/router"
import { PackType } from '@Enums/index'
import { IFaq } from '@Store/types'
import { support, user } from '@Store/modules'

@Render
@Component({})
export class ContextMain extends Vue {

    query: string = ''
    private deploy: Dictionary<boolean> = {}

    // @State('user') stateUser: UserState
    // @Getter('support/faq') faq: IFaq[]

    get supportDate(): Date {
        return user.user.support_date || new Date()
    }
    get hasUnlimitedSupport(): boolean {
        return !!user.user.unlimited_support
    }
    get hasValidSupport(): boolean {
        return this.hasUnlimitedSupport || this.supportDate >= new Date()
    }
    get to(): {name: string, params: {pack: PackType}} {
        return {name: 'purchase-pack', params: {pack: PackType.Support}}
    }
    get items(): IFaq[] {
		return support.faq.filter(i => this.filterItem(i))
	}

    toggle(id: number): any {
		this.deploy[id] = !this.deploy[id]
		this.$forceUpdate()
	}
	isToggle(id: number): boolean {
		return this.deploy[id]
    }
    
    private filterItem(item: IFaq): boolean {
        if (!this.query || this.query.trim() === '') return true

        let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.query.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!item.title && item.title.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.content && item.content.toLowerCase().includes(filter)) {
                countResult++
            }
		}
		return countFilter === countResult
	}
}