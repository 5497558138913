import Render from '@Views/root/co-working/context-share.html'
import { Component, Vue } from 'vue-property-decorator'
import { IOffice, ILinkUser } from '@Store/types'
import { LinkItem } from './link-item'
import { account, breadcrumb, office } from '@Store/modules'

@Render
@Component({
	components: {
		'link-item': LinkItem
	}
})
export class ContextShare extends Vue {

	//#region Getters
	get offices(): IOffice[] {
		return office.offices
	}
	get isLoading(): boolean {
		return office.isLoading
	}
	//#endregion

	links(ofi_id: string): ILinkUser[] {
		return account.activeAccount(ofi_id)
	}

	mounted() {
		breadcrumb.updateLinked(true)
	}
}
