import Render from '@Views/root/patients/patient/read-only-coordinates.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ICoordinates, IPopinAction, IPopin, IPatient, IConsultation, IForm } from '@Store/types'
import { ReadOnlyForms } from '@Components/forms/read-only-forms'
import { Situation, MemberType, AgeBracket, Gender, Level } from '@Enums/index'
import { ColleagueItem } from '@ViewModels/root/search/colleague'
import { configuration, patient, popIn, user } from '@Store/modules'

@Render
@Component({
	components: {
		'colleague-item': ColleagueItem,
		'read-only': ReadOnlyForms
	}
})
export class ReadOnlyCoordinates extends Vue {

	@Prop() coordinates: ICoordinates

	//#region Getters
	get userIsMan(): boolean {
		return user.currentUser.coordinates.gender === Gender.Man
	}
	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get consultations(): IConsultation[] {
		return patient.consultations
	}
	get antecedentCount(): number {
		return patient.antecedents.filter(a => a.important).length
	}
	get sphereCount(): number {
		return patient.spheres.filter(s => s.important).length
	}
	get followUpCount(): number {
		return this.consultations.filter(c => !!c.follow_up && !c.follow_up.read && !!c.follow_up.back).length
	}
	get lastConsultation(): IConsultation {
		if (this.consultations.length > 0) return this.consultations[0]
		return null
	}
	get rabbits(): Date[] {
		return this.patient.rabbits || []
	}
	get forms(): IForm[] {
		return this.coordinates.forms
	}
	get numberOfChildren(): number {
		if (this.coordinates.number_of_children > 0) return this.coordinates.number_of_children

		let length: number = patient.family.filter(m => { return m.type === MemberType.Son_Daughter }).length
		// number_of_children est soit === 0 soit -1. donc si la famille ne donne rien,
		// on renvoie la vraie info qui peut être "non renseignée"
		return length > 0 ? length : this.coordinates.number_of_children
	}
	get lastFollowUp(): string {
		let list = this.consultations.filter(c => !!c.follow_up && !c.follow_up.read && !!c.follow_up.back)
		if (!list || list.length === 0) return '-1'

		return list[0].id
	}
	get patient(): IPatient {
		return patient.patient
	}
	get isMediumLevel(): boolean {
		return this.coordinates.level === Level.Medium
	}
	get isHighLevel(): boolean {
		return this.coordinates.level === Level.High
	}
	get isLowLevel(): boolean {
		return this.coordinates.level === Level.Low
	}
	get isChild(): boolean {
		return this.ageBracket === AgeBracket.Child
	}
	get isTeenager(): boolean {
		return this.ageBracket === AgeBracket.Teenager
	}
	get isAdult(): boolean {
		return this.ageBracket === AgeBracket.Adult
	}
	get isSituationNone(): boolean {
		return this.coordinates.situation === Situation.None
	}
	private get ageBracket() :AgeBracket {
		if (!this.patient.coordinates.birth_date) return AgeBracket.None
		let ageNumber: number = (new Date().getTime() - this.patient.coordinates.birth_date.getTime()) / 31557600000

		if (ageNumber < 10) return AgeBracket.Child
		if (ageNumber < 18) return AgeBracket.Teenager
		return AgeBracket.Adult
	}
	//#endregion

	showUnpaid(): void {
		let unpaidAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.coordinates.read-only.unpaids.popin-action').toString(),
			title: this.$i18n.t('vm.root.patients.patient.coordinates.read-only.unpaids.popin-title').toString(),
			callback: (data: {id: string}) => {
				if (!data.id || parseInt(data.id) === -1) return Promise.reject()

				this.$router.push({name: 'receipt', params: {inv_id: data.id}})
				return Promise.resolve()
			},
			condition: (data: {id: string}) => {
				return !!data.id
			}
		}
		let unpaid: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.coordinates.read-only.unpaids.popin-title').toString(),
			content: {component: 'popin-unpaid-invoice'},
			action: unpaidAction,
			options: {id: null, list: this.patient.unpaids}
		}

		popIn.warning(unpaid)
	}
}
