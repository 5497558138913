export const general = {
    title: "Paramètres généraux",
    'behavior-title': "Comportement",
    lang: {
        label: "Langue", 
        title: "Sélectionnez la langue de votre logiciel" 
    },
    'default-page': {
        label: "Page par défaut à la connexion", 
        title: "Sélectionnez la page sur laquelle vous souhaitez arriver après vous êtes connecté" 
    },
    'font-size': {
        label: "Taille de la police", 
        title: "Ajustez au mieux la taille de la police pour une meilleure lisibilité" 
    },
    'auto-save': {
        label: "Sauvegarde automatique", 
        title: "Sélectionnez le délai après lequel, sans action de votre part, la sauvegarde des modifications en cours se fera" 
    },
    'theme-title': "Thème",
    theme: "Sélectionnez cette apparence pour votre logiciel"
}