<a-side>
	<div class="wrapper aside-search-container">
		<div class="aside-search-sticky-container">
			<div class="aside-search">
				<svg width="1em" class="aside-search-icon" :class="{'is-shown': !isSearching}" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<span class="aside-search-icon" :class="{'is-shown': isSearching}">
					<spinner/>
				</span>
				<input :type="isAnonymous ? 'password' : 'search'" autocomplete="off" class="aside-search-input" id="patients-search"
					v-model="query" :placeholder="$t('vm.root.patients.aside.search.placeholder')" :title="$t('vm.root.patients.aside.search.title')">
			</div>

			<div id="patients-filter" class="search-filter">
				<div class="text-right">
<!--					<button class="btn&#45;&#45;link-like text-sm" @click="reinitialize"-->
<!--							style="position:absolute;bottom:0;left:0;"-->
<!--						:title="$t('vm.root.patients.aside.reinitialize.title')">-->
<!--						<spinner class="svg-middle" v-if="isReinitializing"/>-->
<!--						<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">-->
<!--							<use xlink:href="@Assets/svg/symbol-defs.svg#sync-alt"/>-->
<!--						</svg>-->
<!--						{{ $t('vm.root.patients.aside.reinitialize.button-label') }}-->
<!--					</button>-->
					<button type="button" class="btn--link-like text-sm" v-toggle.main="{className: 'is-active', selector: '#patients-filter'}">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#filter"/>
						</svg>
						{{ $tc("vm.root.patients.aside.activated-filter", filterCount) }}
					</button>
				</div>
				<div class="search-filter-content text-sm">
					<ul class="search-filter-list">
						<li class="search-filter-list-item">
							<input id="last-name-filter" type="checkbox" v-model="filter.by_last_name">
							<label for="last-name-filter">{{ $t("vm.root.patients.aside.filter.last-name") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="first-name-filter" type="checkbox" v-model="filter.by_first_name">
							<label for="first-name-filter">{{ $t("vm.root.patients.aside.filter.first-name") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="profession-filter" type="checkbox" v-model="filter.by_profession">
							<label for="profession-filter">{{ $t("vm.root.patients.aside.filter.profession") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="email-filter" type="checkbox" v-model="filter.by_email">
							<label for="email-filter">{{ $t("vm.root.patients.aside.filter.email") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="phone-filter" type="checkbox" v-model="filter.by_phone">
							<label for="phone-filter">{{ $t("vm.root.patients.aside.filter.phone") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="address-filter" type="checkbox" v-model="filter.by_address">
							<label for="address-filter">{{ $t("vm.root.patients.aside.filter.address") }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="birth-date-filter" type="checkbox" v-model="filter.by_birth_date">
							<label for="birth-date-filter">{{ $t("vm.root.patients.aside.filter.birth-date") }}</label>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-if="!resultSearch || resultSearch.length == 0" class="patients-search-container patients-search-no-result" :class="{'is-white-space': !searched || !query || isSearching}">
			<label for="patients-search">
				<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
			</label>
			<div class="text-md text-bold text-center marg-v">
				<span v-if="isSearching">{{ $t("vm.root.patients.aside.searching") }}</span>
				<span v-else-if="searched && query">{{ $tc("vm.root.patients.aside.result", 0) }}</span>
			</div>
		</div>

		<div v-else class="patients-search-container">
			<div class="text-md text-bold">{{ $tc("vm.root.patients.aside.result", !!resultSearch ? resultSearch.length : 0) }}</div>
			<patient-search-item v-for="patient in resultSearch" :key="patient.id" :patient="patient" :filter="filter" :query="query"/>
		</div>

		<div class="aside-search-sticky-container is-bottom">
			<button class="btn--link-like text-sm" @click="reinitialize"
					:title="$t('vm.root.patients.aside.reinitialize.title')">
				<spinner class="svg-middle" v-if="isReinitializing"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#sync-alt"/>
				</svg>
				{{ $t('vm.root.patients.aside.reinitialize.button-label') }}
			</button>
		</div>
	</div>
</a-side>
