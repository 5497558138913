import { Vue } from 'vue-property-decorator'
import { PopinType } from '@Enums/index'
import { IEvent, IPopin, IPopinAction, INotificationAction, INotification } from '@Store/types'
import { office, event, planning, user, notif, superPanel } from '@Store/modules'

export abstract class QuickEventManager extends Vue {

	//#region Getters
    get isMainUser(): boolean {
		return user.isMainUser
	}
	get isSubstituteUser(): boolean {
		return user.isSubstituteUser
	}
	get isSecretaryUser(): boolean {
		return user.isSecretaryUser
	}
	get isAccountingUser(): boolean {
		return user.isAccountingUser
	}
	get hasPlanning(): boolean {
		return planning.hasPlanning
	}
	get hasOffice(): boolean {
		return office.offices.filter(o => { return office.isActive(o) }).length > 0
	}
	//#endregion

	abstract createPlanning(): void

	abstract createOffice(): void

	quickEvent(): void {
		if (!this.hasPlanning) {
			this.noPlanning()
		} else if (!this.hasOffice) {
			this.noOffice()
		} else {
			let saveEvent: IPopinAction = {
				label: this.$i18n.t('managers.quick-event-manager.label').toString(),
				title: this.$i18n.t('managers.quick-event-manager.title-button').toString(),
				callback: (data: {event: IEvent}) => {

					let _event: IEvent = data.event
					let message: string = this.$i18n.t('vm.root.calendar.event.main.create', {day: this.$i18n.d(new Date(_event.start_date), 'LL'), hour: this.$i18n.d(new Date(_event.start_date), 'LT')}).toString()
					let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
					event.saveEvent({event: _event})
					.then(() => {
						planning.updateRefresh(true)
						notif.success(notification)
					})
					return Promise.resolve()
				},
				condition: (data: {event: IEvent}) => {
					return !!data.event && !!data.event.start_date
				}
			}

			let quickEventPopin: IPopin = {
				title: this.$i18n.t('managers.quick-event-manager.title').toString(),
				content: {component: 'super-panel-quick-event'},
				options: {event: null},
				action: saveEvent
			}

			superPanel.show({popin: quickEventPopin, type: PopinType.Info})
		}
    }

	private noPlanning(): void {
		if (this.isMainUser) {
			let action: INotificationAction = {label: this.$i18n.t('managers.quick-event-manager.create-planning.label').toString(), callback: this.createPlanning}
			let notification: INotification = {message: this.$i18n.t('managers.quick-event-manager.create-planning.message').toString(), actions:[action], delay: false, canClose: true}
			notif.info(notification)
		} else if (this.isSecretaryUser) {
			let action: INotificationAction = {label: this.$i18n.t('managers.quick-event-manager.create-planning.label').toString(), callback: this.createPlanning}
			let notification: INotification = {message: this.$i18n.t('managers.quick-event-manager.create-planning.message').toString(), actions:[action], delay: false, canClose: true}
			notif.info(notification)
		}
	}

	private noOffice(): void {
		if (this.isMainUser) {
			let action: INotificationAction = {label: this.$i18n.t('managers.quick-event-manager.create-office.label').toString(), callback: this.createOffice}
			let notification: INotification = {message: this.$i18n.t('managers.quick-event-manager.create-office.message').toString(), actions:[action], delay: false, canClose: true}
			notif.info(notification)
		} else if (this.isSecretaryUser) {
			let action: INotificationAction = {label: this.$i18n.t('managers.quick-event-manager.create-office.label').toString(), callback: this.createOffice}
			let notification: INotification = {message: this.$i18n.t('managers.quick-event-manager.create-office.message').toString(), actions:[action], delay: false, canClose: true}
			notif.info(notification)
		}
	}
}
