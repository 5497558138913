<div v-if="!!coordinates">

	<div class="stat-list marg-v">
		<div class="text-center">
			<div class="stat-list-value">{{ consultations.length }}</div>
			<div class="text-bold text-md">{{ $tc('vm.root.patients.patient.coordinates.read-only.stat-consultations', consultations.length) }}</div>
		</div>
		<div class="text-center">
			<div class="stat-list-value">{{ antecedentCount }}</div>
			<div class="text-bold text-md">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle-strong" />
				</svg>
				{{ $tc('vm.root.patients.patient.coordinates.read-only.stat-important-antecedents', antecedentCount) }}
			</div>
		</div>
		<div class="text-center">
			<div class="stat-list-value">{{ sphereCount }}</div>
			<div class="text-bold text-md">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle-strong" />
				</svg>
				{{ $tc('vm.root.patients.patient.coordinates.read-only.stat-important-spheres', sphereCount) }}
			</div>
		</div>
	</div>

	<hr v-if="!!lastConsultation || rabbits.length > 0 || patient.unpaids.length > 0 || followUpCount > 0">

	<p class="text-center" v-if="!!lastConsultation || rabbits.length > 0 || patient.unpaids.length > 0 || followUpCount > 0">
		<i18n v-if="!!lastConsultation" path="vm.root.patients.patient.coordinates.read-only.last-consultation" tag="span">
			<strong>{{ $d(lastConsultation.act_date, 'll') }}</strong>
			<span>{{ lastConsultation.act_date | timeAgo }}</span>
		</i18n>
		<i18n v-if="rabbits.length > 0" path="vm.root.patients.patient.coordinates.read-only.rabbits" tag="span">
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#rabbit" />
			</svg>
			<strong>{{ rabbits[0] | timeAgo }}</strong>
			<strong>{{rabbits.length}}<sup>{{ $tc('vm.root.patients.patient.coordinates.read-only.rabbit-count', rabbits.length) }}</sup></strong>
		</i18n>
		<span v-if="patient.unpaids.length > 0">
			{{ $tc('vm.root.patients.patient.coordinates.read-only.unpaids.text', patient.unpaids.length) }}
			<button type="button" @click="showUnpaid" class="btn--link-like" :title="$t('vm.root.patients.patient.coordinates.read-only.follow-up.title')"><!--
				-->{{ $t('vm.root.patients.patient.coordinates.read-only.unpaids.label') }}<!--
			--></button>.
	</span>
		<span v-if="followUpCount > 0">
			{{ $tc('vm.root.patients.patient.coordinates.read-only.follow-up.text', followUpCount) }}
			<router-link :to="{name: 'patient-consultation-follow-up', params: {pat_id: patient.id, item_id: lastFollowUp}}" :title="$t('vm.root.patients.patient.coordinates.read-only.follow-up.title')"><!--
				-->{{ $tc('vm.root.patients.patient.coordinates.read-only.follow-up.label', followUpCount) }}<!--
			--></router-link>.
		</span>
	</p>

	<hr>

	<div v-if="isAnonymous" class="notice-warning">
		<p class="text-left">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#user-secret"/>
			</svg>
			{{ $t('vm.root.patients.patient.coordinates.read-only.anonymous') }}
		</p>
	</div>

	<h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.coordinates.title') }}</h2>

	<div v-if="!!coordinates.notes" :class="{'notice-warning': isMediumLevel, 'notice-danger': isHighLevel, 'notice-info': isLowLevel}">
		<p class="text-left">
			<svg v-if="isHighLevel" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
			</svg>
			<span class="text-pre-line">{{ $t('vm.root.patients.patient.coordinates.notes.label') }} : {{ coordinates.notes }}</span>
		</p>
	</div>

	<p>
		<anonymous class="text-capitalize" :text="coordinates.first_name"/> <anonymous class="text-capitalize" :text="coordinates.last_name"/>
		<span v-if="isChild">{{ $tc('vm.root.patients.patient.coordinates.read-only.is-child', coordinates.gender) }}</span>
		<span v-else-if="isTeenager">{{ $tc('vm.root.patients.patient.coordinates.read-only.is-teenager', coordinates.gender) }}</span>
		<span v-else-if="isAdult">{{ $tc('vm.root.patients.patient.coordinates.read-only.is-gender', coordinates.gender) }}</span>
		<span v-else>{{ $tc('vm.root.patients.patient.coordinates.read-only.is-none', coordinates.gender) }}</span>
		<span v-if="isAdult && !isSituationNone">{{ coordinates.situation | situation(coordinates.gender) }}</span>
		<span v-if="coordinates.birth_date">{{ $t('vm.root.patients.patient.coordinates.read-only.of') }} <strong>{{coordinates.birth_date | age}}</strong>
		{{ $tc('vm.root.patients.patient.coordinates.read-only.born-the', coordinates.gender) }}
		{{ $d(coordinates.birth_date, 'll') }}</span>.

		<span v-if="numberOfChildren > 0 && isAdult">
			{{ $tc('vm.root.patients.patient.coordinates.read-only.parent', coordinates.gender) }}
			<strong>{{ $tc('vm.root.patients.patient.coordinates.read-only.number-of-children', numberOfChildren) }}</strong>.
		</span>
		<span v-else-if="numberOfChildren === 0 && isAdult">
			{{ $tc('vm.root.patients.patient.coordinates.read-only.no-parent', coordinates.gender) }}.
		</span>
	</p>

	<p v-if="coordinates.nir.number">
		{{ $t('vm.root.patients.patient.coordinates.read-only.his-nir') }}
		<anonymous tag="strong" class="text-numeric select" :text="$options.filters.formatNir(coordinates.nir.number, coordinates.nir.iso)"/>
		({{ coordinates.nir.iso }}).
	</p>
	<p v-else>{{ $t('vm.root.patients.patient.coordinates.read-only.no-nir') }}.</p>

	<p v-if="coordinates.phone1.number || coordinates.phone2.number || coordinates.email">
		{{ $tc('vm.root.patients.patient.coordinates.read-only.can-contact', coordinates.gender) }}
		<ul class="ul-reset">
			<li v-if="coordinates.phone1.number">
				&bull; {{ $t('vm.root.patients.patient.coordinates.read-only.by-phone') }}
				<anonymous tag="a" :href="`tel:${coordinates.phone1.number}`"
					:text="$options.filters.formatPhone(coordinates.phone1.number)"
					:title="$t('vm.root.patients.patient.coordinates.read-only.call', {name: coordinates.phone1.number})" />
			</li>
			<li v-if="coordinates.phone2.number">
				&bull; {{ $t('vm.root.patients.patient.coordinates.read-only.by-phone') }}
				<anonymous tag="a" :href="`tel:${coordinates.phone2.number}`"
					:text="$options.filters.formatPhone(coordinates.phone2.number)"
					:title="$t('vm.root.patients.patient.coordinates.read-only.call', {name: coordinates.phone2.number})" />
			</li>
			<li v-if="coordinates.email">
				&bull; {{ $t('vm.root.patients.patient.coordinates.read-only.by-email') }}
				<anonymous tag="a" :href="`mailto:${coordinates.email}`" :email="true"
					:text="coordinates.email"
					:title="$t('vm.root.patients.patient.coordinates.read-only.send-email', {name: coordinates.email})" />
			</li>
		</ul>
	</p>
	<p v-else>{{ $t('vm.root.patients.patient.coordinates.read-only.no-contact') }}</p>

	<i18n v-if="!!coordinates.address.formatted" path="vm.root.patients.patient.coordinates.read-only.address" tag="p">
		<anonymous tag="a" :href="`https://www.google.com/maps/place/${coordinates.address.formatted}`" target="_blank"
			:text="coordinates.address.formatted"
			:title="$t('vm.root.patients.patient.coordinates.read-only.plan')" />
	</i18n>
	<p v-else>{{ $t('vm.root.patients.patient.coordinates.read-only.no-address') }}</p>

	<p v-if="coordinates.reference && coordinates.reference.length > 0">
		<span v-if="userIsMan">{{ $tc('vm.root.patients.patient.coordinates.read-only.reference', coordinates.gender) }}</span>
		<span v-else>{{ $tc('vm.root.patients.patient.coordinates.read-only.reference-female', coordinates.gender) }}</span>
		<strong>{{coordinates.reference[0]}}</strong>.
	</p>

	<div v-if="!!coordinates.habitus">
		<h3 class="no-marg">{{ $t('vm.root.patients.patient.coordinates.habitus.label') }}</h3>
		<p class="text-pre-line">
			<span>{{coordinates.habitus}}</span>
		</p>
	</div>

	<div v-if="coordinates.colleagues.currents.length > 0">
		<h3 class="no-marg">{{ $t('vm.root.patients.patient.coordinates.subtitle') }}</h3>
		<div class="grid-3 vertical-gap marg-v">
			<colleague-item :colleague="colleague" :can-unselect="false" :key="colleague.id" v-for="colleague in coordinates.colleagues.currents"/>
		</div>
	</div>

	<read-only v-if="forms.length > 0" :forms="forms" :with-white-space="false"/>
</div>
