import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { user } from '@Store/modules'
import { IUser, IReferralGift, SendReferralPayload } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { ReferralService } from "@Services/index"
import { shop } from "./shop"

@Module({
	name: 'referral',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class ReferralModule extends VuexModule {
    godchilds: IUser[] = []
    private statusApplying: RequestStatus = RequestStatus.None
    private statusSending: RequestStatus = RequestStatus.None
    private statusGodchildList: RequestStatus = RequestStatus.None

	get isGodchildsLoading(): boolean {
		return this.statusGodchildList === RequestStatus.Loading
	}
	get isApplying(): boolean {
		return this.statusApplying === RequestStatus.Loading
	}
	get gifts(): IReferralGift[] {
		return shop.shop.referral
	}

	@Mutation
	clear() {
		this.statusApplying = RequestStatus.None
		this.statusSending = RequestStatus.None
		this.statusGodchildList = RequestStatus.None
		this.godchilds = []
	}

	@Mutation
	godchildsRequest() {
		this.statusGodchildList = RequestStatus.Loading
	}

	@Mutation
	godchildsSuccess(users: IUser[]) {
		this.statusGodchildList = RequestStatus.Success
		this.godchilds = users
	}

	@Mutation
	godchildsFailure() {
		this.statusGodchildList = RequestStatus.Failed
	}

	@Mutation
	giftRequest() {
		this.statusApplying = RequestStatus.Loading
	}

	@Mutation
	giftSuccess() {
		this.statusApplying = RequestStatus.Success
	}

	@Mutation
	giftFailure() {
		this.statusApplying = RequestStatus.Failed
	}

	@Mutation
	sendRequest() {
		this.statusSending = RequestStatus.Loading
	}

	@Mutation
	sendSuccess() {
		this.statusSending = RequestStatus.Success
	}

	@Mutation
	sendFailure() {
		this.statusSending = RequestStatus.Failed
	}

	@Action({rawError: true})
	async applyGift(gift: IReferralGift): Promise<any> {
		if (this.statusApplying === RequestStatus.Loading) return

		this.giftRequest()

		let service = new ReferralService()
		return service.applyGift(gift)
		.then(_user => {
			this.giftSuccess()
			user.initialize(_user)
			return Promise.resolve(_user)
		})
		.catch(error => {
			this.giftFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async sendReferral(payload: SendReferralPayload): Promise<any> {
		if (this.statusSending === RequestStatus.Loading) return

		this.sendRequest()

		let service = new ReferralService()
		return service.sendReferral(payload)
		.then(() => {
			this.sendSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.sendFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadGodchilds(code: string): Promise<any> {
		if (this.statusGodchildList === RequestStatus.Loading) return

		this.godchildsRequest()

		let service = new ReferralService()
		return service.getGodchildList(code)
		.then(
			users => {
				this.godchildsSuccess(users)
				return Promise.resolve(users)
			}
		)
		.catch(error => {
			this.godchildsFailure()
			return Promise.reject(error)
		})
	}
}

export const referral = getModule(ReferralModule)
// export const shop = new ShopModule({ store, name: "shop" })