<div class="wrapper accounting-context">

	<section class="marg-v" v-if="!!filter">
		<h2>{{ $t('vm.root.accounting.context.title') }}</h2>

		<div class="input-frame">
			<label class="label" for="startRange" data-text="Période">
				<span>{{ $t('vm.root.accounting.context.period.title') }}</span>
			</label>
			<input-range class="accounting-search" v-model="range" :type="rangeType">
				<input type="text" class="accounting-search-input" id="startRange" :title="$t('vm.root.accounting.context.period.start-date')">
				<input type="text" class="accounting-search-input" id="endRange" :title="$t('vm.root.accounting.context.period.end-date')">
			</input-range>
		</div>

		<div class="input-frame">
			<label class="label" for="user-search" :data-text="$t('vm.root.accounting.context.user.label')">
				<span>{{ $t('vm.root.accounting.context.user.label') }}</span>
			</label>
			<div class="context-search">
				<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" class="context-search-input" id="user-search" v-model="query"
					:placeholder="$t('vm.root.accounting.context.user.placeholder')"
					:title="$t('vm.root.accounting.context.user.filter-title')">
			</div>
		</div>

		<ul class="ul-reset osteo-list">
			<li v-for="user in users" :key="user.id" class="semi-marg-v">
				<misc-item :id="user.id" v-model="filter.usr_id" :radio-value="user.id" name="user">
					<avatar slot="icon" :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
					<span :inner-html.prop="user.coordinates.first_name | mark(query)"/> <span :inner-html.prop="user.coordinates.last_name | mark(query)"/>
				</misc-item>
			</li>
		</ul>

		<dropdown-frame id="office" v-model="filter.ofi_id" :items="officeItems"
			:label="$t('vm.root.accounting.context.office.label')"
			:title="$t('vm.root.accounting.context.office.title')"/>
	</section>

	<hr>

	<section class="marg-v" v-if="!!subFilter">
		<div class="childs-space-between p-like">
			<h2 class="no-marg">{{ $t('vm.root.accounting.context.filter.title') }}</h2>
			<div class="text-right">
				<spinner v-if="isFiltering" class="svg-middle"/>
				<button v-else type="button" class="btn--link-like" @click="reset" :title="$t('vm.root.accounting.context.filter.reset.title')">{{ $t('vm.root.accounting.context.filter.reset.label') }}</button>
			</div>
		</div>

		<input-frame id="filter-number" v-model="subFilter.number"
			:label="$t('vm.root.accounting.context.filter.number.label')"
			:placeholder="$t('vm.root.accounting.context.filter.number.placeholder')"
			:title="$t('vm.root.accounting.context.filter.number.title')"/>

		<input-frame id="filter-label" v-model="subFilter.label"
			:label="$t('vm.root.accounting.context.filter.label.label')"
			:placeholder="$t('vm.root.accounting.context.filter.label.placeholder')"
			:title="$t('vm.root.accounting.context.filter.label.title')"/>

		<div class="input-frame">
			<label class="label" :data-text="$t('vm.root.accounting.context.filter.type')">
				<span>{{ $t('vm.root.accounting.context.filter.type') }}</span>
			</label>
			<collapsible-panel>
				<span slot="title">{{ labelInvoiceTypeFilter }}</span>
				<div v-for="type in subFilter.types" :key="`invoice-type-${type.id}`">
					<checkbox-frame :id="`type-${type.id}`" v-model="type.checked">{{ type.label }}</checkbox-frame>
				</div>
			</collapsible-panel>
		</div>

		<div class="input-frame" v-if="hasReceipt">
			<label class="label" :data-text="$t('vm.root.accounting.context.filter.receipt-type')">
				<span>{{ $t('vm.root.accounting.context.filter.receipt-type') }}</span>
			</label>
			<collapsible-panel>
				<span slot="title">{{ labelReceiptTypeFilter }}</span>
				<div v-for="type in subFilter.receiptTypes" :key="`receipt-type-${type.id}`">
					<checkbox-frame :id="`receipt-type-${type.id}`" v-model="type.checked">
						<svg v-if="!!type.icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${type.icon}`"/>
						</svg>
						<span>{{ type.label }}</span>
					</checkbox-frame>
				</div>
			</collapsible-panel>
		</div>

		<div class="input-frame">
			<label class="label" :data-text="$t('vm.root.accounting.context.filter.mode')">
				<span>{{ $t('vm.root.accounting.context.filter.mode') }}</span>
			</label>
			<collapsible-panel>
				<span slot="title">{{ labelPaymentModeFilter }}</span>
				<div v-for="mode in subFilter.modes" :key="`payment-mode-${mode.id}`">
					<checkbox-frame :id="`mode-${mode.id}`" v-model="mode.checked">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIcon(mode.id)}`"/>
						</svg>
						<span>{{ mode.label }} ({{ countByMode(mode.id) }})</span>
					</checkbox-frame>
				</div>
			</collapsible-panel>
		</div>

	</section>

</div>
