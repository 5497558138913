import Vue from 'vue'
import Vuex  from 'vuex'
import { IState } from './types'

Vue.use(Vuex)

export const store = new Vuex.Store<IState>({
    state: {
        version: '1.0.0' // a simple property
    },
    actions: {
        reset({ commit }) {
            commit("coworking/clear")
            commit("breadcrumb/clear")
            commit("popin/clear")
            commit("super-panel/clear")
            commit("statistics/clear")
            commit("home/clear")
            commit("accounting/clear")
            commit("tag/clear")
            commit("support/clear")
            commit("bulk/clear")
            commit("shop/clear")
            commit("purchase/clear")
            commit("referral/clear")
            commit("office/clear")
            commit("planning/clear")
            commit("colleague/clear")
            commit("event/clear")
            commit("account/clear")
            commit("configuration/clear")
            commit("campaign/clear")
            commit("letter/clear")
            commit("patient/clear")
            commit("patients/clear")
            commit("user/clear")
        }
    }
})
