import Render from '@Views/components/frame/textarea-frame.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Render
@Component({})
export class TextareaFrame extends Vue {

    resized: boolean = false
    private oldHeight: string = ''
    private heightOffset: number = 0

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) autoFocus: boolean
    @Prop({default: "text"}) type: string
    @Prop({default: 2}) rows: number
    @Prop() id: string
    @Prop() label: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() errorMsg: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    
    mounted() {
        let textarea: HTMLTextAreaElement = this.$refs[this.id] as HTMLTextAreaElement
        let style: CSSStyleDeclaration = window.getComputedStyle(textarea, null)

		if (style.boxSizing === 'content-box') {
			this.heightOffset = -(parseFloat(style.paddingTop) + parseFloat(style.paddingBottom))
		} else {
			this.heightOffset = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)
		}
		// Fix when a textarea is not on document body and heightOffset is Not a Number
        this.heightOffset = isNaN(this.heightOffset) ? 0 : this.heightOffset
        this.oldHeight = textarea.style.height
    }

    handleInput(e: any) {
        this.$emit('input', e)
    }

    focus() {
        (this.$refs[this.id] as HTMLElement).focus()
    }

    @Watch('resized')
    resize() {
        let textarea: HTMLTextAreaElement = this.$refs[this.id] as HTMLTextAreaElement
        textarea.style.height = this.resized ? `${textarea.scrollHeight + this.heightOffset}px` : this.oldHeight
    }
}