import Render from '@Views/root/patients/patient/attachments.html'
import { AttachmentItem } from './attachment/attachment-item'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {IAttachment, IBreadcrumbItem} from '@Store/types'
import {breadcrumb, patient} from '@Store/modules'
// import {StorageItem} from "./attachment/storage-item"

@Render
@Component({
	components: {
		// 'storage-item': StorageItem,
		'attachment-item': AttachmentItem
	}
})
export class Attachments extends Vue {

	config: any

	@Prop() readOnly: boolean

	//#region Getters
	get attachments(): IAttachment[] {
		return patient.attachments
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'attachments')
		this.initializeBreadcrumb()
	}

	onEdit(): void {
		this.$emit('edit')
	}

	getElementId(attachment: IAttachment): string {
		return parseInt(attachment.id) === -1 ? attachment.create_id : attachment.id
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.attachment', this.attachments.length).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
