export const paying = {
	thanks: "Merci !",
	title: "Votre achat a bien été pris en compte.",
	check: {
		intro1: "Pour le finaliser, merci d'envoyer un chèque, à l'ordre de {0}, d'un montant de",
		intro2: "à l'adresse suivante :",
		delay: {
			content: "{0} Votre paiement doit nous parvenir avant la fin de session. L'envoi d'un chèque par la poste peut prendre plusieurs jours. Seuls les paiements par Paypal sont immédiats.",
			strong: "Attention :"
		}
	},
	transfer: {
		intro1: "Pour le finaliser, merci d'effectuer un virement de",
		intro2: {
			text: "vers le compte identifié par le {0} suivant :",
			'abbr-title': "Relevé d'Identité Bancaire",
			'abbr-label': "RIB"
		},
		delay: {
			content: "{0} Votre paiement doit nous parvenir avant la fin de session. Un virement peut prendre un certain temps. Seuls les paiements par Paypal sont immédiats.",
			strong: "Attention :"
		},
		iban: "IBAN",
		bic: "BIC",
	},
	paypal: "Vous allez être redirigé dans quelques instants…"
}
