<container :loading="!immobilization" :loading-title="$t('vm.root.accounting.immobilization.main.title')">
	<aside-immobilization :immobilization="immobilization" @delete="onDelete"/>

	<master v-if="!!immobilization">
		<central>

			<div class="wrapper marg-v">
				<h1>{{ $t('vm.root.accounting.immobilization.main.title') }}</h1>
			</div>

			<div class="wrapper marg-v">
				<form @submit.prevent="submitSave">
					<router-view :immobilization="immobilization" :submitted="submitted"/>

					<control-bar class="childs-space-between">
						<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || isDeleting || disabled" :title="$t('general.cancel.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
							</svg>
							{{ $t('general.cancel.text') }}
						</button-action>

						<button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled || isDeleting" :title="$t('general.save.title')">
							{{ $t('general.save.text') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							</svg>
						</button-action>
					</control-bar>
				</form>
			</div>

		</central>

	</master>

</container>
