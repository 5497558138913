export const schema = {
    title: "Schéma corporel",
    description: {
        label: "Description complémentaire",
        placeholder: "ex : ce que vous auriez voulu noter",
        title: "Description complémentaire à vos schémas"
    },
    color: "Couleur du trait",
    body: {
        hide: "Masquer le corps",
        show: "Afficher le corps",
        label: "Corps"
    },
    backbone: {
        hide: "Masquer le squelette",
        show: "Afficher le squelette",
        label: "Squelette"
    },
    clear: {
        title: "Effacer toutes vos annotations",
        'delete-msg': "Vous êtes sur le point d'effacer toutes vos annotations. Souhaitez-vous continuer ?",
        'delete-label': "effacer",
        label: "tout effacer"
    },
    'mobile-disabled': "Le dessin sur schéma corporel est inutilisable avec cette résolution d'écran, nous l'avons donc désactivé. Essayez en faisant pivoter votre écran, mais il est préférable de le faire depuis un écran offrant une plus grande résolution.",
    reason: {
        label: "Motif de la consultation",
        'no-options-label': "tapez pour rechercher un motif…",
        placeholder: "ex : Entorse, Cheville, Droite",
        error: "Merci de renseigner le motif de la consultation",
        'too-long': "Le motif de consultation ne doit pas dépasser 255 caractères",
        title: "Veuillez renseigner le motif de la consultation"
    },
    "act-date": {
        label: "Date de la consultation",
        title: "Veuillez renseigner la date de la consultation",
        placeholder: "ex : Le 30 Février 2015",
        error: "Merci de renseigner la date de la consultation"
    },
    size: {
        label: "Taille (cm)",
        placeholder: "ex : 176",
        title: "Veuillez renseigner la taille du patient lors de cette consultation"
    },
    weight: {
        label: "Poids (kg)",
        placeholder: "ex : 69.6",
        title: "Veuillez renseigner le poids du patient lors de cette consultation"
    },
    imc: {
        label:"I.M.C.",
        title: ""
    },
    "evolution-state": {
        label:"Anamnèse du jour",
        placeholder: "ex : Va mieux depuis 2 jours",
        title: "Veuillez renseigner l'évolution de l'état de santé du patient"
    },
    observations: {
        label: "Observations",
        placeholder: "ex : Lors de l'examen, j'ai pu constater…",
        title: "Veuillez renseigner vos observations lors de cette consultation"
    },
    "eva": {
        before: "E.V.A. avant",
        after: "E.V.A. après"
    },
    diagnostic: {
        label: "Évolution du diagnostic",
        placeholder: "ex : Malaise au cours de la consultation…",
        title: "Veuillez renseigner l'évolution du diagnostic au cours de la consultation"
    },
    tests: {
        label: "Tests ostéopathiques",
        placeholder: "ex : Zone de blocage dorsales",
        title: "Veuillez renseigner les tests effectués lors de cette consultation"
    },
    treatment: {
        label: "Traitement",
        placeholder: "ex : Compression crânienne",
        title: "Veuillez renseigner le traitement effectué lors de cette consultation"
    },
    'office-notification': {
        label: "Vous devez avoir au moins un cabinet pour compléter vos consultations.",
        action: "créer un cabinet"
    }
}
