import Render from '@Views/components/popin/layout/popin-refuse-event.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class PopinRefuseEvent extends Vue {
    
    @Prop() options: {cst_id: string, text: string}
	@Prop({default: false}) submitted: boolean

}