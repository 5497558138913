import Render from '@Views/root/user/office/schedule/aside-schedule.html'
import { cloneDeep } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOffice, ISchedule } from '@Store/types'
import { Common } from '@Models/index'
import { DateHelper } from '@Helpers/index'
import { office } from '@Store/modules'

@Render
@Component({})
export class ASideSchedule extends Vue {

  @Prop() office: IOffice

	//#region Getters
	get schedules(): ISchedule[] {
		return this.office.specific_schedules.currents
	}
	//#endregion

	getScheduleId(schedule: ISchedule): string {
		return parseInt(schedule.id) === -1 ? schedule.create_id : schedule.id
	}

	getScheduleName(schedule: ISchedule): string {
		let startWeek: number = this.getWeekNumber(schedule.start)
		let endWeek: number = this.getWeekNumber(schedule.end)
		if (startWeek === endWeek) return this.$i18n.t('vm.root.user.office.aside-schedule.specific-schedule.simple-label', {start: startWeek}).toString()
		return this.$i18n.t('vm.root.user.office.aside-schedule.specific-schedule.multi-label', {start: startWeek, end: endWeek}).toString()
	}

	addSchedule(): void {
		let schedule: ISchedule = office.duplicateSchedule(office.schedule(this.office.id))
		office.addSpecificSchedule(schedule)

		this.$router.push({name: 'office-schedule-plus', params: {ofi_id: this.office.id, sch_id: schedule.create_id}})
	}

	private getWeekNumber(date: Date): number {
		let begin = DateHelper.getMonday(date)
		let firstJanuary = new Date(begin.getFullYear(),0,1);
		return Math.round((begin.getTime() - firstJanuary.getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1
	}
}
