export const main = {
    max_size: "Votre image ne doit pas dépasser <strong>{max_size} méga octets (Mo)</strong>. Elle fait actuellement <strong>{size} Mo</strong>. Réduisez la taille de votre image.",
    wrong_format: "Seules les images au format <strong>jpeg</strong> ou <strong>png</strong> sont autorisées. Votre fichier est au format <strong>{type}</strong>",
	header: {
        new: "nouvelle",
        'new-long': "pièce jointe",
        title: "Créer une nouvelle pièce jointe"
    },
	duplicate: {
		text: "dupliquer",
		'text-long': "cette pièce jointe",
		title: "Dupliquer cette pièce jointe"
	},
	previous: {
		text: "précédente",
		title: "Pièce jointe précédente"
	},
	next: {
		text: "suivante",
		title: "Pièce jointe suivante"
	},
	none: {
        title: "Aucune information sur cette pièce jointe.",
        text: "{0}{1}Non, non, vraiment rien…"
    },
    item: {
        title: "le {date}",
        image_description: "Ouvrir l'image dans une nouvelle page",
        link_description: "Ouvrir le lien dans une nouvelle page"
    },
    name: {
        label: "Intitulé de la pièce jointe",
        placeholder: "ex : Radio de la hanche",
        error: "Merci de renseigner l'intitulé de la pièce jointe",
        title: "Veuillez renseigner l'intitulé de la pièce jointe"
    },
    date: {
        label: "Date de liée à la pièce jointe",
        placeholder: "ex : Le 30 Février 2015",
        error: "Merci de renseigner la date liée à cette pièce jointe",
        title: "Veuillez renseigner la date liée à cette pièce jointe"
    },
    type: {
        label: "Type de pièce jointe",
        title: "Veuillez renseigner le type de cette pièce jointe"
    },
    description: {
        label: "Description de la pièce jointe",
        placeholder: "ex : Radio effectuée suite à une chute",
        title:"Veuillez renseigner la description de la pièce jointe"
    },
    link: {
        label: "Url de la pièce jointe",
        placeholder: "ex : https://dropbox.com/identifiant-de-la-pj-sur-dropbox",
        title: "Veuillez renseigner l'url de la pièce jointe",
        error: "Merci de renseigner une url valide pour cette pièce jointe"
    },
	purchase: {
		label: "augmenter",
		warning: "Votre espace de stockage est presque plein ({storage} / {max} Mo). Vous pouvez <strong>augmenter votre espace de stockage</strong> dès maintenant. Voulez-vous augmenter sa capacité ?",
		message: "Votre espace de stockage est plein ({storage} / {max} Mo). Pour ajouter une nouvelle pièce jointe à vos dossiers patients, vous devez <strong>augmenter votre espace de stockage</strong>. Voulez-vous augmenter sa capacité ?"
	},
	file: {
        label: "L'image associée",
        title: "Veuillez renseigner l'image qui sera associée à la pièce jointe",
        error: "Merci de renseigner une image au bon format et à la bonne taille"
    }
}
