import Render from '@Views/components/popin/layout/popin-search-member.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IMember, IPatientFilter, IPatientSearch } from '@Store/types'
import { MemberType, Gender } from '@Enums/index'
import { IDropdownItem } from '@Components/input/dropdown'
import { EnumsHelper } from '@Helpers/index'
import { Debouncer, Common, Patient } from '@Models/index'
import { configuration, patients } from '@Store/modules'

@Render
@Component({})
export class PopinSearchMember extends Vue {

	private members: MemberType[] = [
		MemberType.None,
		MemberType.Father_Mother,
		MemberType.Brother_Sister,
		MemberType.Son_Daughter,
		MemberType.Nephew_Niece,
		MemberType.Uncle_Aunt,
		MemberType.Husband_Wife,
		MemberType.GrandFather_GrandMother,
		MemberType.GrandSon_GrandDaughter,
		MemberType.GreatGrandFather_GreatGrandMother,
		MemberType.GreatGrandSon_GreatGrandDaughter
	]

	searched: boolean = false
	searching: boolean = false
	random: string = null
    resultSearch: IMember[] = []
	query: string = ''
	private debouncer: Debouncer = null

    @Prop() options: {members: IMember[], contains: string[], query: string}
    @Prop({default: false}) submitted: boolean

	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	private get memberManItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.members.forEach (type => result.push({value: type, label: EnumsHelper.memberToString(type, Gender.Man)}))
	    return result
	}
	private get memberWomanItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.members.forEach (type => result.push({value: type, label: EnumsHelper.memberToString(type, Gender.Woman)}))
	    return result
	}

    mounted() {
		this.random = Common.generateId()
        this.debouncer = new Debouncer(this.search, 500)
        let input: HTMLElement = this.$refs['popin-search-member'] as HTMLElement
		input.focus()

		if (!this.options.query) return
		this.query = this.options.query
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	memberItems(gender: Gender): IDropdownItem[] {
		return gender === Gender.Woman ? this.memberWomanItems : this.memberManItems
	}

	updateType(type: MemberType, member: IMember): void {
		member.type = type
		this.options.members = this.options.members.filter(m => { return m.link_id !== member.link_id})
		if (type === MemberType.None) return

		this.options.members.push(member)
	}

    @Watch('query')
	createDebounce(): void {
		if (!this.query) {
			this.searched = false
			this.resultSearch = []
		}

		this.debouncer.start(!!this.query)
	}

	private search(): void {
		this.searching = true
		this.resultSearch = []
		let filter: IPatientFilter = { by_first_name: true, by_last_name: true }
		patients.searchPatients({query: this.query, filter: filter})
		.then(results => {
			this.searched = true
			this.searching = false
			let members: IMember[] = results.map(patient => { return this.createNewMember(patient)})
			this.resultSearch = members.filter(m => { return !this.options.contains.includes(m.link_id)})
		})
		.catch(error => {
			this.searched = true
			this.searching = false
		})
	}

	private createNewMember(patient: IPatientSearch): IMember {
		let member: IMember = Patient.createNewMember(patient)
		let contain: IMember = this.options.members.find(m => { return m.link_id === member.link_id})
		if (!!contain) {
			member.type = contain.type
		}

		return member
	}
}
