<div>
	<div v-if="hasDocumentSelected">
		<div class="childs-space-between marg-v">
			<h1 class="no-marg">{{ document.title || $t('vm.root.parameters.document.main.new-document') }}</h1>

			<div v-if="canReset">
				<spinner v-if="isReseting" class="svg-middle"/>
				<button v-if="canReset" type="button" :disabled="isReseting" class="btn--link-like accounting-filter-button is-pushed-right" @click="reset()"
					:title="$t('vm.root.parameters.document.main.reset.title')">
					<span>{{ $t('vm.root.parameters.document.main.reset.label') }}</span>
				</button>
			</div>
			<div v-else-if="canDelete">
				<delete-item class="btn btn--link-like text-danger" :can-delete="true" :delete-action="submitDelete"
					:title="$t('vm.root.parameters.document.main.delete.title')"
					:delete-msg="$t('vm.root.parameters.document.main.delete.delete-msg')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
					</svg>
					<span>{{ $t('vm.root.parameters.document.main.delete.label') }}</span>
				</delete-item>
			</div>
		</div>

		<form @submit.prevent="submitSave">
			<input-frame type="text" id="name" v-model="document.title"
				:label="$t('vm.root.parameters.document.main.name.label')"
				:placeholder="$t('vm.root.parameters.document.main.name.placeholder')"
				:error-test="hasTitleError" :error-msg="$t('vm.root.parameters.document.main.name.error-msg')"
				:title="$t('vm.root.parameters.document.main.name.title')"/>

			<input-document class="marg-v" :is-page="true" ref="inputDocument" v-model="document.render"
				:title="$t('vm.root.parameters.document.main.render.title')"
				:placeholder="$t('vm.root.parameters.document.main.render.placeholder')"/>

		</form>
	</div>

	<div v-else class="marg-20 text-center">
		<div>
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#file-invoice"/>
			</svg>
			<i18n path="vm.root.parameters.document.main.none.text" tag="p">
				<button type="button" class="btn--link-like" @click="addDocument" :title="$t('vm.root.parameters.document.main.none.button-title')">
					<span>{{ $t('vm.root.parameters.document.main.none.button-label') }}</span>
				</button>
			</i18n>
		</div>
	</div>


</div>
