import Render from '@Views/root/statistics/colleagues/colleagues.html'
import { Component, Vue } from 'vue-property-decorator'
import { IColleague, IStatisticsFilter, IBreadcrumbItem } from '@Store/types'
import { ColleagueItem } from '@ViewModels/root/search/colleague'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({
	components: {
		'colleague-item': ColleagueItem
	}
})
export class Colleagues extends Vue {

	private stats: IColleague[] = []

	// @State('statistics') stateStatistics: StatisticsState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Action('statistics/loadColleagueStatistics') loadColleagueStatistics: () => Promise<any>

	//#region Getters
	get filter(): IStatisticsFilter {
        return statistics.filter
	}
	get isLoading(): boolean {
        return statistics.isLoading
	}
	get colleagues(): IColleague[] {
		return this.stats.filter(this.filterColleague)
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'colleagues')
		this.initializeBreadcrumb()

		statistics.loadColleagueStatistics()
		.then((stats) => { this.stats = stats })
	}
	
	private filterColleague(colleague: IColleague): boolean {
		let last_name:string = (colleague.last_name || '').toLowerCase()
		let first_name:string = (colleague.first_name || '').toLowerCase()
		return last_name.includes(this.filter.query) || first_name.includes(this.filter.query)
	}
	
	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.colleagues', 2).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}