import Render from '@Views/root/shop/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { IBreadcrumbItem, IShop, IPack } from '@Store/types'
import { PackItem } from './pack-item'
import { PackType } from '@Enums/index'
import { breadcrumb, shop } from '@Store/modules'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'pack-item': PackItem
	}
})
export class Main extends Vue {

	//#region Getters
	get shop(): IShop {
		return shop.shop
	}
	get mainPacks(): IPack[] {
		return this.shop.packs.filter(p => { return p.id === PackType.Unlimited})
	}
	get subPacks(): IPack[] {
		let packs: IPack[] = this.shop.packs.filter(p => {
			return p.id !== PackType.Unlimited
				&& p.id !== PackType.Subscription
				&& p.id !== PackType.Bulk1
				&& p.id !== PackType.Bulk2
				&& p.id !== PackType.Planning
				&& p.id !== PackType.Support
		})
		packs.push({
			id: PackType.Site,
			recurrent: false,
			values: []
		})
		return packs;
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		breadcrumb.updateAside(false)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.shop').toString() }
		breadcrumb.updateItems([item])
	}
}
