export const bonus = {
    title: "Un petit bonus",
    subtitle: "Des réponses à vos questions",
    help: "Vous êtes venu avec vos questions, nous sommes venus avec nos réponses. Voire un peu plus, car on évoque également une piste pour avoir encore plus que vos 12 mois offerts.",
    'question-title': "Voir la réponse à cette question",
    'question-10': {
        title: "Comment bien configurer ma page pour un meilleur référencement ?",
        text1: "La qualité d'un bon référencement naturel dépend des mots clés de votre page et de leur mise en valeur. Il convient donc de bien configurer votre page pour que celui-ci soit optimisé.",
        text2: "Mais si vous avez suivi nos conseils et validé le premier mois offert, alors, votre profil est théoriquement complet. Pour rappel, il est important de bien saisir votre adresse postale, pour qu'un moteur de recherche puisse associer votre page à votre ville. En effet, vos hypothétiques nouveaux futurs patients rechercheront d'abord un ostéopathe dans leur ville ou à proximité de leur emplacement. C'est le rôle des moteurs de recherche de proposer des résultats pertinents en fonction d'une localisation. Donc si vous n'êtes pas localisé, vos patients ne pourront pas vous trouver, en tout cas pas simplement.",
        text3: "De notre côté, si votre adresse postale est bien saisie, nous mettons tout en œuvre pour faciliter le travail des moteurs de recherche et ainsi leur permettre bien comprendre votre position.",
        text4: "Pour tout le reste, plus vous donnerez de détails pertinents, originaux, utiles, plus les moteurs de recherche apprécieront votre page. Cela ne garantit pas le résultat, mais ce qui est sûr c'est que sans, on est sûr que ça ne fonctionne pas 😊.",
        text5: "En espérant que ces quelques conseils vous soient utiles.",
    },
    'question-20': {
        title: "Malgré une page complète, mon référencement reste médiocre, comment l'améliorer ?",
        text1: "Pour que votre référencement soit encore meilleur, il faut augmenter le trafic sur votre page. Si malgré tous vos efforts pour avoir une page de prise de rdv complète et unique, la concurrence est trop forte pour vous hisser aux premières places des recherches des ostéopathes dans votre ville, il existe un autre levier sur lequel vous pouvez avoir un impact.",
        text2: {
            content: "En effet, autre critère apprécié par un moteur de recherche, c'est le {0} d'une page. Ce dernier est généralement quantifié par le trafic généré par cette page. Pour avoir un meilleur trafic, il faut parler de votre page. En parler à l'oral, c'est un premier point, mais surtout à {1}, sur internet.",
            em1: "succès",
            em2: "l'écrit"
        },
        text3: {
            content: "Pour cela, vous pouvez, dans un premier temps, vous inscrire sur des annuaires comme {0} ou {1} et indiquer l'adresse ({2}) de votre page de prise de rdv.",
            a1: "votreOsteo.com",
            a2: "osteopathe.eu",
            'abbr-title': "Uniform Resource Locator",
            'abbr-label': "URL"
        },
        text4: "Vous pouvez également indiquer votre URL sur n'importe quel autre annuaire qui le permet. Par exemple, si vous êtes référencé malgré vous sur le très célèbre Doctolib, profitez de cela pour demander à avoir accès à vos informations et ainsi indiquer aux utilisateurs, qui tomberaient sur cette page par erreur, comment prendre rdv avec vous.",
        text5: "Autre astuce, vous pouvez également laisser votre adresse sur différents forums. Si cela ne cible pas directement vos patients, ce n'est pas grave car cela pourra quand même générer un trafic non négligeable vers votre page.",
        text6: {
            content: "Par exemple, vous pouvez vous inscrire sur {0}, puis vous rendre sur {1} et cliquer sur le bouton {2}. Indiquez ensuite comme sujet : {3}{4}. Ensuite, saisissez, par exemple, un message comme celui-ci :",
            a1: "ce celèbre forum",
            a2: "cette page",
            strong1: "Nouveau",
            strong2: "Ostéopathe à [VOTRE VILLE]",
            sup: "(*)"
        },
        text7: "Bonjour, je suis {last-name} {first-name}, j'ai mis en place une page pour présenter mon activité d'ostéopathe, mais aussi pour :arrow: [url=https://osteo2ls.com/rdv/cabinets/{url}]prendre rendez-vous avec ostéopathe de [VOTRE VILLE][/url].",
        text8: "Ceci est un exemple que vous pouvez évidemment adapter à votre convenance.",
        text9: {
            content: "{0} en remplaçant {1} par votre ville d'exercice",
            sup: "(*)",
            strong: "[VOTRE VILLE]"
        }
    },
    'question-30': {
        title: "Comment mes patients peuvent avoir connaissance de ma page de prise de rdv ?",
        text1: "Effectivement, vouloir toucher de nouveaux patients est une chose, mais vos fidèles patients peuvent être intéressés par une prise de rdv en ligne. Voici donc quelques astuces pour faciliter la vie à vos patients, et la vôtre par la même occasion :",
        li1: {
            content: "Vous pouvez, dans un premier temps, indiquer le site {0} (ce lien redirige vers osteo2ls.com/rdv/) sur {1}, en précisant à vos patients qu'ils peuvent vous y retrouver et prendre rdv directement avec vous, en recherchant votre nom.",
            a: "osteordv.fr",
            strong: "le message de votre répondeur"
        },
        li2: {
            content: "Vous pouvez ensuite {0} sur {1} ou sur {2}, dans lequel vous indiquez le lien complet vers votre page {3}. Ainsi, quand le numéro, qui s'affiche sur votre téléphone, est un numéro de portable, vous pouvez refuser l'appel en envoyant ce message pré-enregistré.",
            strong: "créer un SMS de réponse automatique",
            a1: "Android",
            a2: "iPhone",
            a3: "osteo2ls.com/rdv/cabinets/{url}"
        },
        li3: {
            content: "La {0} après un certain délai sans visite à votre cabinet. C'est une {1} associée à votre planning en ligne qui permet d'envoyer un mail et/ou un SMS à votre patient, pour lui suggérer de prendre un nouveau rendez-vous à titre préventif. Ce message contiendra un lien vers votre page.",
            strong: "relance de vos patients",
            'link-title': "Relancez vos patients",
            'link-label': "fonctionnalité"
        },
        li4: {
            content: "Si vous avez un site, vous pouvez bien sûr y mettre un lien vers votre page, pour que vos patients soient directement redirigés vers votre planning en ligne. Si vous n'avez pas de site, profitez des tarifs négociés avec notre partenaire {0}. Il vous offre, entre autre, {1}, et vous aide à créer votre site facilement.",
            'a-title': "créez votre site simplement",
            'a-label': "Simplébo",
            strong: "les frais de création (800 € HT)"
        }
    },
    'question-40': {
        title: "Comment configurer ma page Facebook pour faciliter la prise de rendez-vous en ligne ?",
        text: {
            content: "Si vous avez une {0} ou un {1}, vous pouvez, voire vous devez, partager votre page sur ces réseaux sociaux. Pour les pages Facebook, vous pouvez ajouter un bouton sur ces dernières. Pour cela, suivez ces 5 étapes :",
            a1: "page FB",
            a2: "compte twitter"
        },
        li1: {
            text: "Cliquez sur le bouton {0}",
            strong: "Ajouter un bouton"
        },
        li2: {
            text: "Cliquez sur {0} puis sur {1}",
            strong1: "Réservez chez vous",
            strong2: "Suivant"
        },
        li3: {
            text: "Saisissez le lien de votre page, puis cliquez sur {0}",
            strong: "Suivant"
        },
        li4: {
            text: "Cliquez sur {0}",
            strong: "Terminer"
        },
        li5: "Et le tour est joué"
    },
    'question-50': {
        title: "Comment inciter de potentiels nouveaux patients à utiliser ce service ?",
        text1: {
            content: "Quand nous cherchons un professionnel (de santé ou autre), nous passons généralement par {0}. Vous devez donc {1} en faisant en sorte que leurs recherches aboutissent sur {2}. L'idée est de faire en sorte, qu'avec {3}, {4} de osteo2ls.com/rdv/ {5}.",
            strong1: "les moteurs de recherche",
            strong2: "faciliter la vie de vos futurs patients",
            a1: "votre page de prise de rdv",
            a2: "une recherche avec nom et prénom",
            strong3: "une de vos pages",
            strong4: "apparaisse dans les premières positions"
        },
        text2: {
            content: "Pour cela, vous pouvez déjà contacter les organismes que vous n'avez pas sollicités et qui font apparaître vos coordonnées. Soit pour modifier vos coordonées et faire apparaître votre page de prise de rdv sur leur site, soit pour leur demander de ne plus être référencé chez eux. Car si vous ne souscrivez pas à leur service, la page en question pourrait bien indiquer vos coordonnées mais elle pourrait proposer également d'autres praticiens qui permettent la prise de rdv en ligne avec cet autre service. Donc potentiellement, votre {0} {1} {2} cet autre service.",
            em: "nouveau patient",
            strong: "pourrait s'orienter vers un autre praticien",
            i: "via"
        },
        text3: {
            content: "Vous pouvez essayer de créer votre profil {0}. C'est très simple et cela vous permet de renseigner l'ensemble de vos coordonnées et de renseigner une URL (l'adresse d'une page internet). Dans votre cas, vous pourrez renseigner {1} et ainsi bénéficier de la mise en avant de Google lors d'une {2}.",
            strong1: "Google My Business",
            a: "votre page",
            strong2: "recherche sur votre nom ou votre ville"
        },
        text4: "En espérant que ces quelques conseils vous soient utiles."
    },
    'question-60': {
        title: "Que va m'apporter ce service ?",
        text: {
            content: "Un {0}, et surtout, vous ne serez plus dérangé pendant vos consultations. Vous pourrez vous consacrer entièrement à vos patients. Avec {1}, vos patients n'oublieront plus leur rdv et ne vous rappelleront pas pour vous demander l'horaire exact.",
            strong1: "gain de temps indéniable",
            strong2: "le rappel de rdv"
        }
    },
    'question-70': {
        title: "Douze mois gratuits c'est bien, mais y a moyen d'avoir plus ?",
        text: {
            content: "Eh bien curieusement, oui, c'est possible. Nous avons mis en place un système de {0}. Avec ce système, vous pouvez parrainer des collègues qui découvrent Osteo2ls, et pour chaque collègue parrainé, vous obtenez 1 point. Un point équivaut à 1 mois de planning en ligne gratuit, mais 10 points valent 1 an. Ce parrainage permet à vos collègues de bénéficier de 10 % de réduction sur l'achat d'un pack tranquillité. Donc tout le monde est gagnant, vous, nous, eux.",
            'link-title': "Parrainer pour obtenir le planning en ligne gratuit",
            'link-label': "parrainage"
        }
    },
    back: {
        label: "étape 3",
        title: "Retourner à l'étape précédente"
    },

}