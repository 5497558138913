import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IUser, IGift, ILinkUser, PasswordPayload, EmailPayload, Gift2Payload, Gift3Payload } from '@Store/types'
import { RequestStatus, Profile } from '@Enums/index'
import { UserService } from '@Services/index'
import {notif} from '@Store/modules/notification'

@Module({
	name: 'user',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
export class UserModule extends VuexModule {
	//TODO renommer en connectedUser
	user: IUser = null
    selectedPlanningUser: IUser = null // for secretary
    gift: IGift = null
    private status: RequestStatus = RequestStatus.None
	private statusPassword: RequestStatus = RequestStatus.None
    private statusEmail: RequestStatus = RequestStatus.None
    private statusGiftLoading: RequestStatus = RequestStatus.None
    private statusStorage: RequestStatus = RequestStatus.None
	private statusGift: RequestStatus = RequestStatus.None

	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isEmailLoading(): boolean {
		return this.statusEmail === RequestStatus.Loading
	}
	get isPasswordLoading(): boolean {
		return this.statusPassword === RequestStatus.Loading
	}
	get isGiftSaving(): boolean {
		return this.statusGift === RequestStatus.Loading
	}
	get isStorageLoading(): boolean {
		return this.statusStorage === RequestStatus.Loading
	}
	get fullName(): string {
        const { user: user } = this
        const firstName = (user && user.coordinates.first_name) || ''
        const lastName = (user && user.coordinates.last_name) || ''
        return `${firstName} ${lastName}`
	}
    get canUsePlanning(): boolean {
        const { user: user } = this
        return user && (user.profile === Profile.Demo || (!user.no_planning && user.planning_date > new Date()))
    }
    get isMainUser(): boolean {
        const { user: user } = this
        return user && (user.profile === Profile.Main || user.profile === Profile.Demo)
    }
    get isSecretaryUser(): boolean {
        const { user: user } = this
        return user && user.profile === Profile.Secretary
    }
    get isSubstituteUser(): boolean {
        const { user: user } = this
        return user && user.profile === Profile.Substitute
    }
    get isAccountingUser(): boolean {
        const { user: user } = this
        return user && user.profile === Profile.Accounting
    }
    get currentUser(): IUser {
        if (this.isSecretaryUser) return this.selectedPlanningUser
        return this.user
	}
	get linkedUsers(): (profile: Profile) => ILinkUser[] {
		return (profile: Profile) => {
			const { user: user } = this
			const linked = (user && user.links) || []
			if (profile === Profile.None)
				return linked

			return linked.filter(u => { return u.profile === profile || (profile === Profile.Main && u.profile === Profile.Demo) })
		}
	}
	get canAddAttachment(): boolean {
		return true
		// if (!this.isMainUser) return true
		// return this.user.storage < this.user.max_storage
	}
	get hasWarningAttachment(): boolean {
		return false
		// if (!this.isMainUser) return false
		// return this.user.storage + 20 >= this.user.max_storage
	}

	@Mutation
	updateRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	updateSuccess(user: IUser) {
		this.status = RequestStatus.Success
		this.user = user
	}

	@Mutation
	updateFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	storageRequest() {
		this.statusStorage = RequestStatus.Loading
	}

	@Mutation
	storageSuccess(storage: number) {
		this.statusStorage = RequestStatus.Success
		this.user.storage = storage
	}

	@Mutation
	storageFailure() {
		this.statusStorage = RequestStatus.Failed
	}

	@Mutation
	giftRequest() {
		this.statusGiftLoading = RequestStatus.Loading
		this.gift = null
	}

	@Mutation
	giftSuccess(gift: IGift) {
		this.statusGiftLoading = RequestStatus.Success
		this.gift = gift
	}

	@Mutation
	giftFailure() {
		this.statusGiftLoading = RequestStatus.Failed
	}

	@Mutation
	updateGiftRequest() {
		this.statusGift = RequestStatus.Loading
	}

	@Mutation
	updateGiftSuccess(gift: IGift) {
		this.statusGift = RequestStatus.Success
		this.gift = gift
	}

	@Mutation
	updateGiftFailure() {
		this.statusGift = RequestStatus.Failed
	}

	@Mutation
	passwordRequest() {
		this.statusPassword = RequestStatus.Loading
	}

	@Mutation
	passwordSuccess() {
		this.statusPassword = RequestStatus.Success
	}

	@Mutation
	passwordFailure() {
		this.statusPassword = RequestStatus.Failed
	}

	@Mutation
	emailRequest() {
		this.statusEmail = RequestStatus.Loading
	}

	@Mutation
	emailSuccess(newEmail: string) {
		this.statusEmail = RequestStatus.Success
		this.user.email_tmp = newEmail
	}

	@Mutation
	emailFailure() {
		this.statusEmail = RequestStatus.Failed
	}

	@Mutation
	initialize(user: IUser) {
		this.user = user
	}

	@Mutation
	selectLinkPlanningUser(user: IUser) {
		this.selectedPlanningUser = user
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusGiftLoading = RequestStatus.None
		this.statusEmail = RequestStatus.None
		this.statusPassword = RequestStatus.None
		this.selectedPlanningUser = null
		this.user = null
		this.gift = null
	}

	@Action({rawError: true})
	async update(user: IUser): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.updateRequest()

		let service = new UserService()
        return service.update(user)
        .then(user => {
			this.updateSuccess(user)
			return Promise.resolve(user)
		})
		.catch(error => {
			this.updateFailure()

			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async changePassword(payload: PasswordPayload): Promise<any> {
		if (this.statusPassword === RequestStatus.Loading) return

		this.passwordRequest()
		const { currentPassword, newPassword, confirmPassword } = payload
		let service = new UserService()
		return service.password(currentPassword, newPassword, confirmPassword)
		.then(() => {
			this.passwordSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.passwordFailure()
			notif.error(error)
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async changeEmail(payload: EmailPayload): Promise<any> {
		if (this.statusEmail === RequestStatus.Loading) return

		this.emailRequest()
		const { newEmail, confirmEmail } = payload
		let service = new UserService()
		return service.email(newEmail, confirmEmail)
		.then(() => {
			this.emailSuccess(newEmail)
			return Promise.resolve()
		})
		.catch(error => {
			this.emailFailure()
			notif.error(error)
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async exportData(): Promise<any> {
		let service = new UserService()
		return service.export()
		.then(() => {
			return Promise.resolve()
		})
		.catch(error => {
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadStorage(): Promise<any> {
		if (this.statusStorage === RequestStatus.Loading) return

		this.storageRequest()

		let service = new UserService()
		return service.getStorage()
			.then(
				(result: {storage: number}) => {
					this.storageSuccess(result.storage)
					return Promise.resolve(result.storage)
				}
			)
			.catch(error => {
				this.storageFailure()
				return Promise.reject(error)
			})
	}
	@Action({rawError: true})
	async loadGift(): Promise<any> {
		if (this.statusGiftLoading === RequestStatus.Loading) return

		this.giftRequest()

		let service = new UserService()
		return service.getGift()
		.then(
			gift => {
				this.giftSuccess(gift)
				return Promise.resolve(gift)
			}
		)
		.catch(error => {
			this.giftFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async submitGift3(payload: Gift3Payload): Promise<any> {
		if (this.statusGift === RequestStatus.Loading) return

		this.updateGiftRequest()
		let service = new UserService()
		return service.updateGift3(payload)
		.then((gift) => {
			this.updateGiftSuccess(gift)
			return Promise.resolve(gift)
		})
		.catch(error => {
			this.updateGiftFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async submitGift2(payload: Gift2Payload): Promise<any> {
		if (this.statusGift === RequestStatus.Loading) return

		this.updateGiftRequest()
		let service = new UserService()
		return service.updateGift2(payload)
		.then((gift) => {
			this.updateGiftSuccess(gift)
			return Promise.resolve(gift)
		})
		.catch(error => {
			this.updateGiftFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async submitGift1(): Promise<any> {
		if (this.statusGift === RequestStatus.Loading) return

		this.updateGiftRequest()
		let service = new UserService()
		return service.updateGift1()
		.then((gift) => {
			this.updateGiftSuccess(gift)
			return Promise.resolve(gift)
		})
		.catch(error => {
			this.updateGiftFailure()
			return Promise.reject(error)
		})
	}
}

export const user = getModule(UserModule)
// export const user = new UserModule({ store, name: "user" })
