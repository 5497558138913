import Render from '@Views/root/parameters/document/document.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from "vue-router/types/router"
import { IPopinAction, IPopin, IConfiguration, IDocument, IBreadcrumbItem } from '@Store/types'
import { Common } from '@Models/index'
import * as DocumentModel from '@Models/document'
import { InputDocument } from '@Components/index'
import { breadcrumb, letter, popIn } from '@Store/modules'

@Render
@Component({})
export class Document extends Vue {

	private template: string

	@Prop() configuration: IConfiguration
	@Prop() submitted: boolean

	//#region getters
	get doc_id(): string {
		return this.$route.params.doc_id
	}
	get isReseting(): boolean {
		return letter.isReseting
	}
	get document(): IDocument {
		return this.configuration.documents.currents.find(d => { return d.id === this.doc_id || (!!d.createId && d.createId === this.doc_id) })
	}
	get hasTitleError(): boolean {
		return this.submitted && !this.document.title
	}
	get hasDocumentSelected() : boolean {
		return !!this.document
	}
	get canReset(): boolean {
		return !!this.document.tpl_id && parseInt(this.document.tpl_id) !== -1
	}
	get canDelete(): boolean {
		return parseInt(this.document.id) === -1 || parseInt(this.document.tpl_id) === -1
	}
	private get keywords(): Dictionary<string[]> {
		return DocumentModel.Document.getKeywords()
	}
	private get needPatient(): boolean {
		if (!this.hasDocumentSelected) return false
		return !!this.keywords.patient.find(k => { return this.document.render.includes(k) })
	}
	private get needConsultation(): boolean {
		if (!this.hasDocumentSelected) return false
		return !!this.keywords.consultation.find(k => { return this.document.render.includes(k) })
	}
	private get needInvoice(): boolean {
		if (!this.hasDocumentSelected) return false
		return !!this.keywords.invoice.find(k => { return this.document.render.includes(k) })
	}
	private get needOffice(): boolean {
		if (!this.hasDocumentSelected) return false
		return !!this.keywords.office.find(k => { return this.document.render.includes(k) })
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'document')
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	addDocument(): void {
		let createId: string = Common.generateId()
		let document: IDocument = DocumentModel.Document.createNewDocument(createId)

		this.configuration.documents.currents.push(document)

		this.$router.push({name: 'parameters-document', params: {doc_id: document.createId}})
	}

	insertText(text: string) {
		let editor: InputDocument = this.$refs.inputDocument as InputDocument
		editor.insertText(text)
	}

	submitDelete() {
		const { document } = this
		this.configuration.documents.currents = this.configuration.documents.currents.filter(d => { return d !== document })
		this.configuration.documents.deleted.push(document)

		this.$router.push({name: 'parameters-document'})
	}

	@Watch('document.render')
	updateRender() {
		if (!this.hasDocumentSelected) return

		this.document.reset = this.template === this.document.render
		this.updateNeeded()
	}

	reset() {
		if (!this.canReset) return

		let resetAction: IPopinAction = {
			label: this.$i18n.t('vm.root.parameters.document.main.reset.action.label').toString(),
			title: this.$i18n.t('vm.root.parameters.document.main.reset.action.title').toString(),
			callback: (data: any) => {
				letter.reset(this.document.tpl_id)
				.then((template: string) => {
					this.template = template
					this.document.render = template
					setTimeout(() => {
						//hack to avoid quill behavior
						this.document.reset = true
					}, 100)
				})
				return Promise.resolve()
			}
		}

		let resetPopin: IPopin = {
			title: this.$i18n.t('vm.root.parameters.document.main.reset.action.title').toString(),
			content: this.$i18n.t('vm.root.parameters.document.main.reset.action.content').toString(),
			action: resetAction
		}
		popIn.warning(resetPopin)
	}

	private updateNeeded() {
		this.document.need_consultation = this.needConsultation
		this.document.need_invoice = this.needInvoice
		this.document.need_patient = this.document.need_consultation || this.document.need_invoice || this.needPatient
		this.document.need_office = this.needOffice
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.template').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
