import Render from '@Views/root/parameters/notifications.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IBreadcrumbItem } from '@Store/types'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class Notifications extends Vue {

	@Prop() configuration: IConfiguration

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Getter('user/isMainUser') isMainUser: boolean

	get isMainUser(): boolean {
		return user.isMainUser
	}
	
	mounted() {
		this.$emit('navigate', 'notification')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.notifications').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
