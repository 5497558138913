import Render from '@Views/root/statistics/colleagues/colleague/context-colleague.html'
import { Component, Vue } from 'vue-property-decorator'
import { IPatient } from '@Store/types'
import { PatientItem } from '@ViewModels/root/search/patient'
import { colleague } from '@Store/modules'

@Render
@Component({
	components: {
		'patient-item': PatientItem
	}
})
export class ContextColleague extends Vue {

    query: string = ''

    // @State('colleague') stateColleague: ColleagueState

    get patients(): IPatient[] {
        let patients: IPatient[] = !!colleague.colleague ? colleague.colleague.patients || [] : []
        return patients.filter(this.filterPatient)
    }
    
    private filterPatient(patient: IPatient): boolean {
        if (!patient || !patient.coordinates) return false
		let last_name:string = (patient.coordinates.last_name || '').toLowerCase()
		let first_name:string = (patient.coordinates.first_name || '').toLowerCase()
		return last_name.includes(this.query) || first_name.includes(this.query)
	}
}