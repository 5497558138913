import Render from '@Views/components/frame/switch-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class SwitchFrame extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: "check"}) checkedIcon: string
    @Prop({default: "0 0 512 512"}) checkedViewBox: string
    @Prop() title: string
    @Prop() id: string
    @Prop() switchClass: string
    @Prop() value: boolean

    get checked(): boolean {
        return this.value
    }
    set checked(value: boolean) {
        this.$emit('input', value)
    }
}
