import Render from '@Views/root/parameters/forms.html'
import draggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
	IPopinAction,
	IPopin,
	IConfiguration,
	IBreadcrumbItem,
	IConfigForm,
	IConfigQuestion,
	IConfigChoice,
	IForm
} from '@Store/types'
import { Dictionary } from "vue-router/types/router"
import { FormType, QuestionType } from '@Enums/index'
import { IDropdownItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { Common } from '@Models/index'
import { breadcrumb, configuration, popIn } from '@Store/modules'

@Render
@Component({
	components: {
		draggable: draggable
	}
})
export class Forms extends Vue {

	dragging = false
	private deploy: Dictionary<boolean> = {}
	@Prop() configuration: IConfiguration

	//#region getters
	get oldForms(): IConfigForm[] {
		return configuration.forms
	}
	get forms(): {currents: IConfigForm[], deleted: IConfigForm[]} {
		return this.configuration.forms
	}
	get formTypes(): IDropdownItem[] {
		return [
			{value: FormType.Coordinates, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Coordinates)}` },
			{value: FormType.Antecedent, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Antecedent)}` },
			{value: FormType.Sphere, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Sphere)}` },
			{value: FormType.Consultation, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Consultation)}` },
			{value: FormType.Pediatrics, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Pediatrics)}` },
			{value: FormType.Reason, label: `${this.$i18n.t('general.for')} ${EnumsHelper.formTypeToString(FormType.Reason)}` }
		]
	}
	get questionTypes(): IDropdownItem[] {
		return [
			{value: QuestionType.Long_Text, label: EnumsHelper.questionTypeToString(QuestionType.Long_Text)},
			{value: QuestionType.Short_Text, label: EnumsHelper.questionTypeToString(QuestionType.Short_Text)},
			{value: QuestionType.Single_Choice, label: EnumsHelper.questionTypeToString(QuestionType.Single_Choice)},
			{value: QuestionType.Multiple_Choice, label: EnumsHelper.questionTypeToString(QuestionType.Multiple_Choice)}
		]
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'forms')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	isNewForm(form: IForm): boolean {
		return parseInt(form.id) === -1
	}
	hasChoice(question: IConfigQuestion): boolean {
		return question.type === QuestionType.Multiple_Choice || question.type === QuestionType.Single_Choice
	}
	getKey(item: {id: string, createdId?: string}): string {
		return parseInt(item.id) === -1 ? item.createdId : item.id
	}
	onChangedChoice(evt: any, question: IConfigQuestion) {
		let moved: any = evt.moved
		if (!moved) return

		for(let i = 0; i < question.choices.currents.length; i++) {
			let choice: IConfigChoice = question.choices.currents[i]
			let oldOrder: number = choice.order
			choice.order = i
			choice.updated = choice.updated || choice.order !== oldOrder
		}
	}
	onChangedQuestion(evt: any, form: IConfigForm) {
		let moved: any = evt.moved
		if (!moved) return

		for(let i = 0; i < form.questions.currents.length; i++) {
			let question: IConfigQuestion = form.questions.currents[i]
			let oldOrder: number = question.order
			question.order = i
			question.updated = question.updated || question.order !== oldOrder
		}
	}
	onChangedForm(evt: any) {
		let moved: any = evt.moved
		if (!moved) return

		for(let i = 0; i < this.forms.currents.length; i++) {
			let form: IConfigForm = this.forms.currents[i]
			let oldOrder: number = form.order
			form.order = i
			form.updated = form.updated || form.order !== oldOrder
		}
	}
	addForm() {
		let form: IConfigForm = {
			id: '-1',
			createId: Common.generateId(),
			name: this.$i18n.t('vm.root.parameters.forms.new-form', {index: this.forms.currents.length + 1}).toString(),
			order: this.forms.currents.length,
			updated: false,
			type: FormType.Coordinates,
			questions: {currents: [], deleted: []}
		}
		this.forms.currents.unshift(form)
		this.toggle(form.id, form.createId)
	}
	confirmDeleteForm(form: IConfigForm) {
		this.delete(() => { this.deleteForm(form) })
	}
	confirmDeleteQuestion(form: IConfigForm, question: IConfigQuestion) {
		this.delete(() => { this.deleteQuestion(form, question) })
	}
	confirmDeleteChoice(question: IConfigQuestion, choice: IConfigChoice, index: number) {
		this.delete(() => { this.deleteChoice(question, choice, index) })
	}
	addQuestion(form: IConfigForm) {
		let question: IConfigQuestion = {
			id: '-1',
			createId: Common.generateId(),
			title: this.$i18n.t('vm.root.parameters.forms.new-question', {index: form.questions.currents.length + 1}).toString(),
			order: form.questions.currents.length,
			updated: false,
			type: QuestionType.Long_Text,
			choices: {currents: [], deleted: []}
		}
		form.questions.currents.unshift(question)
		this.toggle(question.id, question.createId)
	}
	addChoice(question: IConfigQuestion) {
		let choice: IConfigChoice = {
			id: '-1',
			createId: Common.generateId(),
			label: this.$i18n.t('vm.root.parameters.forms.new-choice', {index: question.choices.currents.length + 1}).toString(),
			order: question.choices.currents.length,
			updated: false
		}
		question.choices.currents.push(choice)
	}
	updateForm(form: IConfigForm) {
		if (parseInt(form.id) === -1) return
		let oldForm: IConfigForm = this.oldForms.find(f => f.id === form.id)

		form.updated = oldForm.name !== form.name || oldForm.type !== form.type
	}
	updateQuestion(form: IConfigForm, question: IConfigQuestion) {
		if (parseInt(form.id) === -1) return
		if (parseInt(question.id) === -1) return
		let oldForm: IConfigForm = this.oldForms.find(f => f.id === form.id)
		let oldQuestion: IConfigQuestion = oldForm.questions.currents.find(q => q.id === question.id)

		question.updated = oldQuestion.title !== question.title || oldQuestion.type !== question.type
	}
	updateChoice(form: IConfigForm, question: IConfigQuestion, choice: IConfigChoice) {
		if (parseInt(form.id) === -1) return
		if (parseInt(question.id) === -1) return
		if (parseInt(choice.id) === -1) return
		let oldForm: IConfigForm = this.oldForms.find(f => f.id === form.id)
		let oldQuestion: IConfigQuestion = oldForm.questions.currents.find(q => q.id === question.id)
		let oldChoice: IConfigChoice = oldQuestion.choices.currents.find(c => c.id === choice.id)

		choice.updated = oldChoice.label !== choice.label
	}
	toggle(id: string, createId?: string): any {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}
	private deleteForm(form: IConfigForm) {
		let index: number = this.forms.currents.indexOf(form)
		if (index < 0) return

		this.forms.currents.splice(index, 1)
		if (parseInt(form.id) === -1) return

		this.forms.deleted.unshift(form)
	}
	private deleteQuestion(form: IConfigForm, question: IConfigQuestion) {
		let index: number = form.questions.currents.indexOf(question)
		if (index < 0) return

		form.questions.currents.splice(index, 1)
		if (parseInt(question.id) === -1) return

		form.questions.deleted.unshift(question)
	}

	private deleteChoice(question: IConfigQuestion, choice: IConfigChoice, index: number) {
		question.choices.currents.splice(index, 1)
		if (parseInt(choice.id) === -1) return

		question.choices.deleted.unshift(choice)
	}
	private delete(callback: any): void {
		let deleteAction: IPopinAction = {
			label: this.$i18n.t('vm.root.parameters.forms.delete.label').toString(),
			title: this.$i18n.t('vm.root.parameters.forms.delete.title').toString(),
			callback: () => {
				callback()
				return Promise.resolve()
			}
		}
		let deletePopin: IPopin = {
			title: this.$i18n.t('vm.root.parameters.forms.delete.title').toString(),
			content: this.$i18n.t('vm.root.parameters.forms.delete.content').toString(),
			action: deleteAction
		}

		popIn.warning(deletePopin)
	}
	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.forms', 2).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
