import Render from '@Views/root/patients/patient/consultation/reason.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ReadOnlyReason } from './read-only-reason'
import { IConfiguration, IConsultation, IReason, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { Common, Patient } from '@Models/index'
import { CustomForm } from '@Components/forms/custom-form'
import { breadcrumb, configuration, patient, user } from '@Store/modules'

@Render
@Component({
	components: {
		'custom-form': CustomForm,
		'read-only': ReadOnlyReason
	}
})
export class Reason extends Vue {

	rea_id: string = null
	@Prop() consultation: IConsultation
	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	//#region Getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get reason(): IReason {
		if (!this.rea_id) return null
		return patient.reason(this.rea_id)
	}
	get reasonItems(): IDropdownItem[] {
		return patient.reasons.map(reason => ({value: this.getId(reason), label: reason.title}))
	}
	get hasTitleError(): boolean {
		return this.submitted && !this.reason.title
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
		this.updateConsultation()
	}

	addReason() {
		let createId = Common.generateId()
		let reason: IReason = Patient.createNewReason(createId, this.configuration)
		patient.reasons.push(reason)
		let rea_id = createId

		this.consultation.rea_id = rea_id
		this.$emit('edit')
		this.$forceUpdate()
	}

	unselectReason() {
		this.rea_id = null
	}

	deleteSelectedReason(reason: IReason) {
		patient.deleteReason(reason)
		this.rea_id = null
	}

	@Watch('rea_id')
	updateReason() {
		let rea_id: string = this.rea_id
		if (this.consultation.rea_id === rea_id) return

		this.consultation.rea_id = rea_id
	}

	@Watch('consultation.rea_id')
	private updateConsultation() {
		let rea_id: string = (this.consultation && !!this.consultation.rea_id && parseInt(this.consultation.rea_id) !== -1) ? this.consultation.rea_id : null
		let reason:IReason = patient.reasons.find(reason => { return reason.id === rea_id || reason.create_id === rea_id })
		if (!!reason && this.rea_id === reason.id) return

		this.rea_id = !reason ? undefined : rea_id
	}

	private getId(reason: IReason): string {
		return parseInt(reason.id) === -1 ? reason.create_id : reason.id
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.reason').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}
