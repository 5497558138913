import Render from '@Views/login/authenticate.html'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Profile } from '@Enums/index'
import { login, notif, user } from '@Store/modules'
import { INotification, IUser } from '@Store/types'

@Render
@Component({})
export class Authenticate extends Vue {

	private loginSubmitted: boolean = false
	private lostLoginSubmitted: boolean = false
	profile: Profile = Profile.None
	password: string = ''

	//#region getters
	get isLoading(): boolean {
		return login.isAuthenticating
	}
	get isSending(): boolean {
		return login.isSending
	}
	get email(): string {
		return login.email
	}
	get accounts(): number[] {
		return login.accounts
	}
	get hasErrorProfile(): boolean {
		return (this.loginSubmitted || this.lostLoginSubmitted) && this.profile === Profile.None
	}
	get hasErrorPassword(): boolean {
		return this.loginSubmitted && !this.password
	}
	get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	//#endregion

	submitLogin() {
		this.loginSubmitted = true
		if (!this.canLogin()) return

		login.login({email: this.email, password: this.password, profile: this.profile})
		.then((_user: IUser) => {
			user.initialize(_user)
			this.$router.push({name: 'loader'})
		})
		.catch(error => {
			let message: string = (!!error && error.status === 406) ? 
				this.$i18n.t('vm.login.authenticate.login.failed-406').toString() :
				this.$i18n.t('vm.login.authenticate.login.failed').toString()

			let notification:INotification = { message: message, actions: [], delay: 5000 }
			notif.error(notification)
		})
	}

	submitLostLogin(event: any) {
		this.lostLoginSubmitted = true
		if (!this.canLostLogin()) return

		login.lostLogin({email: this.email, profile: this.profile})
		.then(() => {
			let notification:INotification = { message: this.$i18n.t('vm.login.authenticate.lost-login.success', {email: this.email}).toString(), actions: [], delay: 5000 }
			notif.success(notification)
			this.$router.push({name: 'authentication'})
		})
		.catch(() => {
			let notification:INotification = { message: this.$i18n.t('vm.login.authenticate.lost-login.failed').toString(), actions: [], delay: 5000 }
			notif.error(notification)
		})
	}

	mounted() {
		if (this.accounts.length > 1) return

		this.setFocus()
		this.profile = this.accounts[0]
	}

	previous() {
		this.$router.push({name: 'login'})
	}

	//#region private methods
	@Watch('profile')
	@Watch('accounts')
	private setFocus() {
		let password = this.$refs.password as any
		if (password === undefined) return
		password.focus()
	}

	private canLogin(): boolean {
		return !!this.password && this.profile !== Profile.None && !!this.email
	}

	private canLostLogin(): boolean {
		return this.profile !== Profile.None && !!this.email
	}
	//#endregion
}
