<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link v-if="currentStep > 0" :to="{name: 'share-step-one'}">1 &bull; {{ $t('vm.root.co-working.aside.step1') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 0, 'is-future-step': currentStep < 0}">1 &bull; {{ $t('vm.root.co-working.aside.step1') }}</span>
		</li>
		<li>
			<router-link v-if="currentStep > 1" :to="{name: 'share-step-two'}">2 &bull; {{ $t('vm.root.co-working.aside.step2') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 1, 'is-future-step': currentStep < 1}">2 &bull; {{ $t('vm.root.co-working.aside.step2') }}</span>
		</li>
		<li>
			<router-link v-if="currentStep > 2" :to="{name: 'share-step-three'}">3 &bull; {{ $t('vm.root.co-working.aside.step3') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 2, 'is-future-step': currentStep < 2}">3 &bull; {{ $t('vm.root.co-working.aside.step3') }}</span>
		</li>
	</ul>
</a-side>
