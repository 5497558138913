import Render from '@Views/components/popin/layout/popin-create-patient.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPhone } from '@Components/index'
import { IUser, IPatient, ICoordinates, PatientDuplicatePayload, INotification, INotificationAction, IPopinAction } from '@Store/types'
import { InputDateType } from '@Components/frame/input-date-frame'
import { IToggleBoxItem } from '@Components/input/togglebox'
import { Debouncer } from '@Models/index'
import { IDropdownItem } from '@Components/input/dropdown'
import { MemberType } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { notif, patient, popIn, user } from '@Store/modules'

@Render
@Component({})
export class PopinCreatePatient extends Vue {

	phone: IPhone
	private debouncer: Debouncer
	private members: MemberType[] = [
		MemberType.None, MemberType.Father_Mother, MemberType.Brother_Sister, MemberType.Son_Daughter, MemberType.Nephew_Niece,
		MemberType.Uncle_Aunt, MemberType.Husband_Wife, MemberType.GrandFather_GrandMother, MemberType.GrandSon_GrandDaughter,
		MemberType.GreatGrandFather_GreatGrandMother, MemberType.GreatGrandSon_GreatGrandDaughter]

    @Prop() options: {patient: IPatient, member: MemberType}
	@Prop({default: false}) submitted: boolean

	get coordinates(): ICoordinates {
		return this.options.patient.coordinates
	}
	get hasLastNameError(): boolean {
		return this.submitted && !this.coordinates.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.coordinates.first_name
	}
	get birthDateType(): InputDateType {
		return InputDateType.Numpad
	}
	get genderItems(): IToggleBoxItem[] {
		return [
			{value: 0, label: this.$i18n.t('vm.components.popin.create-patient.gender.man').toString(), icon:"mars"},
			{value: 1, label: this.$i18n.t('vm.components.popin.create-patient.gender.woman').toString(), icon:"venus"}
		]
	}
	get isDuplicate(): boolean {
		return this.options.member !== undefined && this.options.member !== null
	}
	get memberItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.members.forEach (type => result.push({value: type, label: EnumsHelper.membersToString(type, 1)}))
	    return result
	}
	private get action(): IPopinAction {
		return popIn.popin.action
	}
	private get user(): IUser {
		return user.currentUser
	}

	mounted() {
		this.debouncer = new Debouncer(this.checkingDuplicate, 500)
		this.canCheckDuplicate()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	@Watch('coordinates.last_name')
	@Watch('coordinates.first_name')
	@Watch('coordinates.birth_date')
	private canCheckDuplicate() {
		if (!this.options || !this.options.patient || !this.options.patient.is_new) return
		if (!this.coordinates.last_name) return
		if (!this.coordinates.first_name) return
		if (!this.coordinates.birth_date) return

		this.debouncer.start()
	}

	private checkingDuplicate() {
		let payload: PatientDuplicatePayload = {
			usr_id: this.user.id,
			profile: this.user.profile,
			last_name: this.coordinates.last_name,
			first_name: this.coordinates.first_name,
			birth_date: this.coordinates.birth_date
		}

		patient.checkDuplicate(payload)
		.then(result => {
			if (!result.id) notif.clear()
			else notif.info(this.getNotification(result.id))
		})
	}

	private getNotification(id: string): INotification {
		let openAction: INotificationAction = {
            label: this.$i18n.t('vm.components.popin.create-patient.duplicate.label').toString(),
            callback: () => { this.searchDuplicatePatient(id) }
        }
        let notification: INotification = {
            message: this.$i18n.t('vm.components.popin.create-patient.duplicate.message').toString(),
            actions: [openAction],
            canClose: true,
            delay: false
		}

		return notification
	}

	private searchDuplicatePatient(pat_id: string) {
		patient.getDuplicate({pat_id: pat_id})
		.then(result => {
			if (!this.action.callback({patient: result})) return

			popIn.clear()
		})
	}
}
