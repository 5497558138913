import { i18n } from '@I18n/index'
import { INotification } from "@Store/types"
import { NotificationType } from "@Enums/index"

export class Connection {
    private static instance: Connection = null

    private _state: boolean = true
    private listeners: ((notification: INotification, type: NotificationType) => any)[] = []

    get state(): boolean {
        return this._state
    }
    set state(value: boolean) {
        this._state = value
        let message:string = value ? i18n.t("models.connection.connection-alive").toString() : i18n.t("models.connection.no-connection").toString()
        let notification:INotification = {message: message, actions: [], delay: false, canClose: true}
        for(let callback of this.listeners) {
            callback(notification, value ? NotificationType.Info : NotificationType.Warning)
        }
    }

    public static getInstance(): Connection {
        this.instance = !this.instance ? new Connection() : this.instance
        return this.instance
    }

    private constructor() {
        this._state = true
        this.listeners = []
    }

    public addListener(callback: (notification: INotification, type: NotificationType) => any): void {
        this.listeners.push(callback)
    }

    public removeListener(callback: (notification: INotification, type: NotificationType) => any): void {
        this.listeners.splice(this.listeners.indexOf(callback), 1)
    }
}
