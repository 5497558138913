import Render from '@Views/root/user/office/schedule/schedule.html'
import {cloneDeep, isEqual} from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ILinkUser, IOffice, ISchedule, IBreadcrumbItem } from '@Store/types'
import { ScheduleElement } from '@Components/index'
import { DateHelper } from '@Helpers/index'
import { account, breadcrumb, office, user } from '@Store/modules'
import {Common} from "@Models/common";

@Render
@Component({
	components: {
		'schedule': ScheduleElement
	},
	beforeRouteUpdate(to, from, next) {
		next()
		if (!this.schedule) return

		this.week = this.computeWeek()
	}
})
export class Schedule extends Vue {

	week: number = 1

	@Prop() office: IOffice

	//#region Getters
	get sch_id(): string {
		return this.$route.params.sch_id
	}
	get schedule(): ISchedule {
		return this.schedules.find(s => { return s.id === this.sch_id || (!!s.create_id && s.create_id === this.sch_id) })
	}
	get samePeriod(): boolean {
		let usr_id: string = user.user.id
		let schedules: ISchedule[] = office.specificSchedulesRange(this.office.id, usr_id, this.schedule.start, this.schedule.end)
		return schedules.filter(s => { return s.id !== this.schedule.id }).length > 0
	}
	get links(): ILinkUser[] {
		return account.activeAccount(this.office.id)
	}
	get canPrevious(): boolean {
		let date: Date = new Date(this.schedule.start)
		date.setDate(date.getDate() - 7)

		return date >= DateHelper.getMonday(new Date())
	}
	private get oldSchedule(): ISchedule {
		return office.oldSpecificSchedule(this.scheduleId)
	}
	private get schedules(): ISchedule[] {
		return this.office.specific_schedules.currents
	}
	private get scheduleId(): string {
		return parseInt(this.schedule.id) === -1 ? this.schedule.create_id : this.schedule.id
 	}
	 //#endregion

	mounted() {
		if (!this.schedule) {
			this.$router.push({name: 'office-schedule', params: this.$route.params})
			return
		}

		this.$emit('navigate', 'schedule-office')
		this.initializeBreadcrumb()
		this.week = this.computeWeek()
	}

	submitDelete() {
		office.deleteSpecificSchedule(this.schedule)

		this.$router.push({name: 'office-schedule', params: this.$route.params})
	}

	@Watch('schedule', {deep: true})
	updateSchedule() {
		if (!this.schedule) return
		if (!this.oldSchedule) return

		let updated: boolean = !isEqual(this.oldSchedule.days, this.schedule.days)
			|| !DateHelper.sameDay(this.oldSchedule.start, this.schedule.start)
			|| !DateHelper.sameDay(this.oldSchedule.end, this.schedule.end)
			|| this.oldSchedule.message !== this.schedule.message
			|| this.oldSchedule.cycle !== this.schedule.cycle

		this.schedule.update = updated
	}

	@Watch('schedules', {deep: true})
	redirect(): void {
		if (!!this.schedule) return
		this.$router.push({name: 'office-schedule', params: this.$route.params})
	}

	duplicate(): void {
		let schedule: ISchedule = office.duplicateSchedule(this.schedule)
		office.addSpecificSchedule(schedule)

		this.$router.push({name: 'office-schedule-plus', params: {ofi_id: this.office.id, sch_id: schedule.create_id}})
	}

	@Watch('week')
	private updateEnd(): void {
		let date: Date = new Date(this.schedule.start)
		date.setDate(date.getDate() - 1 + this.week * 7)

		this.schedule.end = date
	}

	next(): void {
		let start: Date = new Date(this.schedule.start)
		start.setDate(start.getDate() + 7)

		this.schedule.start = start
		this.updateEnd()
	}

	previous(): void {
		let start: Date = new Date(this.schedule.start)
		start.setDate(start.getDate() - 7)
		if (start < DateHelper.getMonday(new Date())) return

		this.schedule.start = start
		this.updateEnd()
	}

	private computeWeek(): number {
		return Math.round((this.schedule.end.getTime() - this.schedule.start.getTime()) / (7 * 24 * 60 * 60 * 1000))
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.specific-schedule').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}
