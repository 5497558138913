import Render from '@Views/root/patients/patient/consultation/forms.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConsultation, IForm, IBreadcrumbItem } from '@Store/types'
import { CustomForm } from '@Components/forms/custom-form'
import { ReadOnlyForms } from '@Components/forms/read-only-forms'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'custom-form': CustomForm,
		'read-only': ReadOnlyForms
	}
})
export class Forms extends Vue {

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get forms(): IForm[] {
		return this.consultation.forms
	}

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.forms', this.forms.length).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}