import Render from '@Views/root/user/aside-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { IUser, INotification, IOffice, IPopin, IPopinAction } from '@Store/types'
import { notif, office, popIn, user } from '@Store/modules'

@Render
@Component({})
export class ASideMain extends Vue {

	//#region Getters
    get user(): IUser {
        return user.user
    }
    get isMainUser(): boolean {
        return user.isMainUser
    }
    get offices(): IOffice[] {
        return office.offices.filter(o => { return office.isActive(o) })
    }
	//#endregion

    confirmExport(): void {
        let exportAction: IPopinAction = {
			label: this.$i18n.t('vm.root.user.aside.export.action-label').toString(),
			title: this.$i18n.t('vm.root.user.aside.export.action-title').toString(),
			callback: () => {
                user.exportData()
                .then(() => {
                    let notification: INotification = {message: this.$i18n.t('vm.root.user.aside.export.succeeded', {email: this.user.email}).toString(), actions:[], delay: 5000, canClose: true}
                    notif.success(notification)
                })
                .catch(() => {
                    let notification: INotification = {message: this.$i18n.t('vm.root.user.aside.export.failed').toString(), actions:[], delay: 5000, canClose: true}
                    notif.error(notification)
                })
                return Promise.resolve()
			}
		}

		let exportPopin: IPopin = {
			title: this.$i18n.t('vm.root.user.aside.export.action-title').toString(),
			content: this.$i18n.t('vm.root.user.aside.export.action-content').toString(),
			action: exportAction,
			options: {}
		}

		popIn.info(exportPopin)
    }
}
