import Render from '@Views/components/frame/togglebox-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IToggleBoxItem } from '@Components/input/togglebox'

@Render
@Component({})
export class ToggleBoxFrame extends Vue {

    _value: any

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop() items: IToggleBoxItem[]
    @Prop() label: string
    @Prop() id: string
    @Prop() value: any

    
    created() {
        this._value = this.value
    }

    handleInput(e: any) {
        this.$emit('input', e)
    }
}