import Render from '@Views/root/patients/patient/antecedent/family-antecedent.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IAntecedent, IMember } from '@Store/types'
import { patient } from '@Store/modules'

@Render
@Component({})
export class FamilyAntecedent extends Vue {

	member: IMember = null
	owner: string = ''

	@Prop() antecedent: IAntecedent
	@Prop() filter: string

	mounted() {
		this.owner = patient.owner(this.antecedent)
		this.member = patient.member(this.antecedent.pat_id)
	}
}