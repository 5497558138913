<div class="wrapper">
	<section class="marg-v">
		<h2>{{ $t('vm.root.patients.merge.context.title') }}</h2>

		<input-frame :auto-focus="true" type="text" id="last-name" v-model="filter.last_name"
			:label="$t('vm.root.patients.merge.context.last-name.label')"
			:placeholder="$t('vm.root.patients.merge.context.last-name.placeholder')"
			:title="$t('vm.root.patients.merge.context.last-name.title')"/>

		<input-frame type="text" id="first-name" v-model="filter.first_name"
			:label="$t('vm.root.patients.merge.context.first-name.label')"
			:placeholder="$t('vm.root.patients.merge.context.first-name.placeholder')"
			:title="$t('vm.root.patients.merge.context.first-name.title')"/>
	</section>

	<hr>

	<section class="marg-v">
		<div class="label">{{ $t('vm.root.patients.merge.context.duplicates.label') }}</div>
		<checkbox-frame id="same-date" v-model="filter.same_date" checked-icon="code-merge">
			{{ $t('vm.root.patients.merge.context.duplicates.same-date') }}
		</checkbox-frame>
		<checkbox-frame id="same-email" v-model="filter.same_email" checked-icon="code-merge">
			{{ $t('vm.root.patients.merge.context.duplicates.same-email') }}
		</checkbox-frame>
		<checkbox-frame id="same-phone" v-model="filter.same_phone" checked-icon="code-merge">
			{{ $t('vm.root.patients.merge.context.duplicates.same-phone') }}
		</checkbox-frame>
	</section>
</div>
