export const asideRemittances = {
    back: {
        title: "Retourner au tableau des finances",
        label: "Retour au tableau"
    },
    new: {
        title: "Créez une nouvelle remise de chèque",
        label: "Nouvelle remise"
    },
    old: {
        title: "Consulter cette remise de chèque",
        date: "déposée le {date}"
    }
}
