import Render from '@Views/root/statistics/colleagues/colleague/colleague.html'
import { Component } from 'vue-property-decorator'
import { ASideColleague } from './aside-colleague'
import { DetailsColleague } from './details'
import { ContextColleague } from './context-colleague'
import { IColleague, IPopinAction, IPopin, INotification, IBreadcrumbItem } from '@Store/types'
import { AutoSaver } from '@Components/index'
import { AutoSaverState } from '@Enums/index'
import { breadcrumb, colleague, notif } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-colleague': ASideColleague,
		'details-colleague': DetailsColleague,
		'context-colleague': ContextColleague
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
		notif.clear()
	}
})
export class Colleague extends AutoSaver {

	submitted: boolean = false

	constructor() {
		super('colleague')
	}

	//#region Getters
	get colleague(): IColleague {
        return colleague.colleague
	}
	get isLoading(): boolean {
        return colleague.isLoading
	}
	get isDeleting(): boolean {
        return colleague.isDeleting
    }
	get isSaving(): boolean {
        return colleague.isSaving
    }
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IColleague): void {
		if (!value) return

		colleague.colleagueSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		colleague.saveColleague({colleague: this.colleague})
		.then((newColleague: IColleague) => {
			super.initializeValue()
			let notification: INotification = {message: this.$i18n.t('vm.root.statistics.colleagues.main-colleague.saved').toString(), delay: 5000, actions: [], canClose: true }
			notif.success(notification)
			this.submitted = false
		})
		.catch(() => {
			super.initializeValue()
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'colleague')
		this.initializeBreadcrumb()

		this.initializeColleague()
	}

	onDelete() {
		// Appelé quand on efface le rdv depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	submitCancel(): void {
		if (this.disabled) return

		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.statistics.colleagues.main-colleague.cancel.label').toString(),
			title: this.$i18n.t('vm.root.statistics.colleagues.main-colleague.cancel.title').toString(),
			callback: () => {
				this.cancelAction()
				return Promise.resolve()
			}
		}
		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.statistics.colleagues.main-colleague.cancel.title').toString(),
			content: this.$i18n.t('vm.root.statistics.colleagues.main-colleague.cancel.content').toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	private cancelAction(): void {
		super.cancel()
		this.submitted = false
	}

	private canSave(): boolean {
		if (!this.colleague) return false
		if (!this.colleague.last_name || !this.colleague.first_name) return false
		if (!!this.colleague.phone.error) return false

		return true
	}

	private initializeColleague(): void {
		let payload = {col_id: this.$route.params.col_id}
		if (!colleague.isLoaded || !colleague.colleague || colleague.colleague.id !== payload.col_id) {
			colleague.loadColleague(payload)
			.then(() => {
				this.initializeValue()
			})
			.catch((error) => {})
		} else {
			this.initializeValue()
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.colleagues', 2).toString(), link: "statistics-colleagues" }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.colleagues', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
