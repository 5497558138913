<a-side :sub-menu="true" v-if="office">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'offices'}" :title="$t('vm.root.user.office.aside.back.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.office.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'office-coordinates', params: $route.params}" :title="$t('vm.root.user.office.aside.coordinates.title')">
				{{ $t('vm.root.user.office.aside.coordinates.label') }}
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'office-sessions', params: $route.params}" :title="$t('vm.root.user.office.aside.sessions.title')">
				<span class="with-badge-label">{{ $tc('vm.root.user.office.aside.sessions.label', office.sessions.currents.length) }}</span>
				<span class="with-badge-badge">{{office.sessions.currents.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'office-schedule', params: $route.params}" :title="$t('vm.root.user.office.aside.schedule.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ $t('vm.root.user.office.aside.schedule.label') }}
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'office-accesses', params: $route.params}" :title="$t('vm.root.user.office.aside.accesses.title')">
				<span class="with-badge-label">{{ $tc('vm.root.user.office.aside.accesses.label', office.accesses.currents.length) }}</span>
				<span class="with-badge-badge">{{office.accesses.currents.length}}</span>
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'office-absence', params: $route.params}" :title="$t('vm.root.user.office.aside.absence.title')">
				{{ $t('vm.root.user.office.aside.absence.label') }}
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'office-parameters', params: $route.params}" :title="$t('vm.root.user.office.aside.parameters.title')">
				{{ $t('vm.root.user.office.aside.parameters.label') }}
			</router-link>
		</li>
	</ul>
	<ul v-if="!office.create_id" class="aside-navigation is-pushed-down">
		<li class="hr" aria-hidden="true"/>
		<li>
			<delete-item :delete-action="submitDisable" :can-delete="true"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
				:delete-msg="$t('vm.root.user.office.aside.disabled.delete-msg')" 
				:delete-label="$t('vm.root.user.office.aside.disabled.delete-label')" 
				:title="$t('vm.root.user.office.aside.disabled.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.user.office.aside.disabled.label') }}
			</delete-item>
		</li>
	</ul>

</a-side>
