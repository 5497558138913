import Render from '@Views/root/patients/patient-search-item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPatientFilter, IPatientSearch } from '@Store/types'

@Render
@Component({})
export class PatientSearchItem extends Vue {

	@Prop() patient: IPatientSearch
	@Prop() filter: IPatientFilter
	@Prop() query: string
}