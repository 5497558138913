<div v-if="consultations">
	<div class="childs-space-between marg-v">
		<h2 class="text-uppercase-first-letter no-marg">{{ $t('vm.root.patients.patient.consultations.title') }}</h2>
		<router-link :to="{name:'patient-consultation', params:{pat_id: $route.params.pat_id}}"
					 class="is-pushed-right" :title="$t('vm.root.patients.patient.consultations.new-consultation.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.patients.patient.consultations.new-consultation.text') }}</span>
		</router-link>
	</div>
	<div v-for="(consultations, year) in groupedConsultations" :key="`list-${year}`" :id="`list-${year}`">
		<h3>
			<button type="button" @click="toggle(year)" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(year)}">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				<span class="text-lowercase">{{year}} - {{consultations.length}} {{ $tc("models.patient.consultation", consultations.length) }}</span>
			</button>
		</h3>
		<div class="misc-collapsible" :class="{'is-active': isToggle(year)}">
			<ul class="ul-reset misc-collapsible-content">
				<li v-for="consultation in consultations" :key="getId(consultation)">
					<collapsible-panel collapsible-class="marg-v" :collapsed="firstConsultationId !== consultation.id">
						<span slot="title" class="text-uppercase-first-letter">
							{{getReasonTitle(consultation)}}
						</span>
						<div v-if="owner(consultation)" slot="subtitle" class="text-italic text-sm">
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
								<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
							</svg>
							{{ $t('vm.root.patients.patient.consultations.create-by', {owner: owner(consultation)}) }}
						</div>
						<span slot="right">{{ $t('general.the') }} {{$d(consultation.act_date, 'll')}}</span>
						<read-only :consultation="consultation"/>
						<div class="text-right">
							<span>
								<button type="button" @click="updateConsultation(consultation)"class="btn--link-like" :title="$t('general.edit.title')">{{ $t('general.edit.text') }}</button>
								<span v-if="!readOnly && isMainUser">&bull;
									<delete-item class="btn btn--link-like text-danger" :can-delete="true"
										:delete-action="() => deleteConsultation(consultation)">
										{{ $t('general.delete.text') }}
									</delete-item>
								</span>
							</span>
						</div>
					</collapsible-panel>
				</li>
			</ul>
		</div>
	</div>
	<div v-if="Object.keys(groupedConsultations).length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.consultations.none.title') }}</span><br>
			<i18n path="vm.root.patients.patient.consultations.none.description.text" tag="span">
				<router-link :to="{name:'patient-consultation', params:{pat_id: $route.params.pat_id}}"
							 :title="$t('vm.root.patients.patient.consultations.none.description.title')">
					{{ $t('vm.root.patients.patient.consultations.none.description.link') }}
				</router-link>
			</i18n>
		</p>
	</div>

</div>
