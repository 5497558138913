import Render from '@Views/root/patients/patient/consultations.html'
import { groupBy } from 'lodash'
import { ReadOnly } from './consultation/read-only'
import { Dictionary } from "vue-router/types/router"
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConfiguration, IConsultation, IBreadcrumbItem } from '@Store/types'
import { Patient } from '@Models/index'
import { Common } from '@Models/index'
import { breadcrumb, configuration, patient, user } from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnly
	}
})
export class Consultations extends Vue {

	firstConsultationId: string = ''
	private deploy: Dictionary<boolean> = {}

	@Prop() readOnly: boolean

	//#region Getter
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get consultations(): IConsultation[] {
		return patient.consultations
	}
	get configuration(): IConfiguration {
		return configuration.configuration
	}
	get groupedConsultations(): Dictionary<IConsultation[]> {
		return groupBy(this.consultations, consultation => ` ${consultation.act_date.getFullYear()}`)
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'consultations')
		this.initializeBreadcrumb()
	}

	created() {
		let keys: string[] = Object.keys(this.groupedConsultations)
		if (keys.length === 0) return

		this.toggle(keys[0])
		if (this.groupedConsultations[keys[0]].length === 0) return

		this.firstConsultationId = this.groupedConsultations[keys[0]][0].id
	}

	owner(item: {id: string, creator_id: string}): string {
		return patient.owner(item)
	}

	getReasonTitle(consultation: IConsultation): string {
		return patient.reasonTitle(consultation)
	}

	deleteConsultation(consultation: IConsultation): void {
		patient.deleteConsultation(consultation)
	}

	updateConsultation(consultation: IConsultation): void{
		this.$emit('edit')
		this.$router.push({name:'patient-consultation', params:{pat_id:this.$route.params.pat_id, item_id: this.getRouteId(consultation)}})
	}

	// addConsultation(): void {
	// 	this.$emit('edit')
	// 	let createId: string = Common.generateId()
	// 	this.consultations.push(Patient.createNewConsultation(createId, this.configuration, Patient.getLastMeasurement(this.consultations)))
	//
	// 	this.$router.push({name:'patient-consultation', params:{pat_id: this.$route.params.pat_id, item_id: createId}})
	// }

	toggle(year: string): any {
		this.deploy[year] = !this.deploy[year]
		this.$forceUpdate()
	}
	isToggle(year: string): boolean {
		return this.deploy[year]
	}
	getId(consultation: IConsultation): string {
		return parseInt(consultation.id) === -1 ? consultation.create_id : consultation.id
	}

	private getRouteId(consultation: IConsultation): string {
		return parseInt(consultation.id) === -1 ? consultation.create_id : consultation.id
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', this.consultations.length).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
