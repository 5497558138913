import Render from '@Views/components/layout/navigation.html'
import { Component, Watch } from 'vue-property-decorator'
import { IPopin, IPopinAction, IConfiguration, IUser } from '@Store/types'
import { Favico } from '@Models/favico'
import { GoogleService } from '@Services/index'
import config from "@Config/index"
import { QuickEventManager } from '@Managers/quick-event-manager'
import {configuration, home, notif, planning, popIn, support, user} from '@Store/modules'
import {ReminderType} from "@Enums/reminder"

@Render
@Component({})
export class Navigation extends QuickEventManager {
	name:string = 'navigation'

	private _isPatientView: boolean = false
	private interval: NodeJS.Timeout
	private interval2: NodeJS.Timeout

	//#region Getters
	get user(): IUser {
		return user.user
	}
	get isPatientView(): boolean {
		return this._isPatientView
	}
	get version() {
		return config.version
	}
	get changeLog() {
		return support.changeLog
	}
	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get hasUnreadTicket(): boolean {
		return support.hasUnreadTicket
	}
	get hasUnvalidEvent(): boolean {
		return planning.hasUnvalidEvent
	}
	get hasUnreadFollowUp(): boolean {
		return home.hasUnreadFollowUp
	}
	get unreadClosedCommentCount(): number {
		return support.unreadClosedCommentCount
	}
	get unreadCommentCount(): number {
		return support.unreadCommentCount
	}
	get unvalidEventCount(): number {
		return planning.unvalidEventCount
	}
	get unreadFollowUpCount(): number {
		return home.unreadFollowUpCount
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get notificationCount(): number {
		return this.unreadClosedCommentCount + this.unreadCommentCount + planning.unvalidEventCount + home.unreadFollowUpCount
	}
	//#endregion

	mounted() {
		this.checkNotifications()
		this.updateUnreadFollowUp()
		this.interval = setInterval(this.checkNotifications, 300000) // 5 minutes
		this.interval2 = setInterval(this.updateUnreadFollowUp, 1800000) // 30 minutes

		this.updateIsPatientView()
		this.$router.afterEach(() => { this.updateIsPatientView() })
	}

	beforeDestroy() {
		clearInterval(this.interval)
		clearInterval(this.interval2)
	}

	displayChangeLog(): void {
		if (!this.changeLog) return

		let changeLogPopin: IPopin = {
			title: `Application version ${this.version}`,
			content: {component: 'popin-change-log'},
			options: {changeLog: this.changeLog}
		}

		popIn.info(changeLogPopin)
	}

	createNewEvent(): void {
		super.quickEvent()
	}

	createPlanning(): void {
		this.$router.push({name: 'planning'})
	}

	createOffice(): void {
		this.$router.push({name: 'offices'})
	}

	logout(): void {
		let logoutAction: IPopinAction = {
			label: this.$i18n.t('vm.components.layout.navigation.logout.action-label').toString(),
			title: this.$i18n.t('vm.components.layout.navigation.logout.title').toString(),
			callback: () => {
				notif.clear()
				this.$router.push({name: 'login'})
				if (!this.configuration.connect_google) {
					GoogleService.getInstance().revokeToken()
				}
				return Promise.resolve()
			}
		}
		// avec un simple texte
		let logout: IPopin = {
			title: this.$i18n.t('vm.components.layout.navigation.logout.title').toString(),
			content: this.$i18n.t('vm.components.layout.navigation.logout.action-text').toString(),
			action: logoutAction
		}

		// avec un composant plus complexe
		// let logout: IPopin = { title: "Déconnexion", content: {component: 'popin-component'}, action: logoutAction }

		// avec un composant plus complexe, mais surtout sans action
		//let logout: IPopin = { title: "Déconnexion", content: {component: 'popin-component'}, action: logoutAction }

		popIn.error(logout)
	}

	private updateIsPatientView() {
		this._isPatientView = !!this.$router.currentRoute && !!this.$router.currentRoute.name ? this.$router.currentRoute.name.startsWith('patient-') : false
	}

	@Watch('notificationCount')
	updateFavico() {
		Favico.getInstance().badge(this.notificationCount)
	}

	@Watch('unreadClosedCommentCount')
	@Watch('unreadCommentCount')
	notify() {
		if (!this.configuration.notification_help) return

		let count: number = this.unreadClosedCommentCount + this.unreadCommentCount
		if (count === 0) return

		if (Notification.permission !== "granted") {
			Notification.requestPermission()
		} else {
			new Notification(this.$i18n.t('vm.components.layout.navigation.notification.support.title').toString(), {
				body: this.$i18n.tc('vm.components.layout.navigation.notification.support.body', count).toString(),
				icon: 'assets/img/notification-icons/support.png'
			})
	 	}
	}

	@Watch('unvalidEventCount')
	notifyValidEvent() {
		if (!this.configuration.notification_event) return
		if (this.unvalidEventCount === 0) return

		if (Notification.permission !== "granted") {
			Notification.requestPermission()
		} else {
			new Notification(this.$i18n.t('vm.components.layout.navigation.notification.event.title').toString(), {
				body: this.$i18n.tc('vm.components.layout.navigation.notification.event.body', this.unvalidEventCount).toString(),
				icon: 'assets/img/notification-icons/calendar.png'
			})
		}
	}

	@Watch('unreadFollowUpCount')
	notifyUnreadFollowUp() {
		if (!this.configuration.notification_follow_up) return
		if (this.unreadFollowUpCount === 0) return

		if (Notification.permission !== "granted") {
			Notification.requestPermission()
		} else {
			new Notification(this.$i18n.t('vm.components.layout.navigation.notification.follow-up.title').toString(), {
				body: this.$i18n.tc('vm.components.layout.navigation.notification.follow-up.body', this.unreadFollowUpCount).toString(),
				icon: 'assets/img/notification-icons/patient.png'
			})
		}
	}

	private updateUnreadTickets() {
		if (!this.isMainUser) return

		support.loadCount()
		.then(result => { support.unreadTickets(result) })
	}

	private updateUnvalidEvents() {
		if (!this.isMainUser) return
		if (!user.canUsePlanning) return

		planning.checkUnvalidEvents()
		.then(count => { planning.unvalidEvents(count) })
	}

	private updateUnreadFollowUp() {
		if (!this.isMainUser) return
		if (this.configuration.follow_up_type === ReminderType.None) return

		home.checkUnreadFollowUp()
		.then(count => { home.unreadFollowUp(count) })
	}

	private checkNotifications() {
		this.updateUnreadTickets()
		this.updateUnvalidEvents()
	}
}
