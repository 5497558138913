import Render from '@Views/root/help/closed-tickets.html'
import { Vue, Component } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { ASideClosed } from './aside-closed'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb, support } from '@Store/modules'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'aside-closed': ASideClosed
	}
})
export class ClosedTickets extends Vue {

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('support/loadClosedTickets') loadClosedTickets: () => Promise<any>

	mounted() {
		support.loadClosedTickets()
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.help').toString(), link: "help" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.closed-tickets').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}