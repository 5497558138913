import Render from '@Views/components/layout/aside.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class Aside extends Vue {

	@Prop({default: false}) subMenu: boolean

	className(): any {
		return {
			'l-aside-nav': this.subMenu,
			'l-aside-search': !this.subMenu
		}
	}

	mounted() {
		if (this.subMenu) {
			document.querySelector('.l-navigation').classList.add('is-with-sub-nav')
			document.querySelectorAll('[data-toggle-aside]')
			.forEach( element => { element.classList.add('is-with-sub-nav') })
		} else {
			document.querySelector('.l-navigation').classList.remove('is-with-sub-nav')
			document.querySelectorAll('[data-toggle-aside]')
			.forEach( element => { element.classList.remove('is-with-sub-nav') })
		}
	}
}
