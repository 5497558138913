export const main = {
    loading: "chargement du rendez-vous",
    'help-warning': {
        title: "Validation du rendez-vous",
        text: "Prenez bien soin de {0}. Sans cela, votre futur patient ne recevra pas de courriel de confirmation (ou de refus), ce qui pourrait entraîner une confusion pour ce créneau.",
        strong: "confirmer ou de refuser ce rendez-vous"
    },
	'update-warning': {
		text: "{0} : les coordonnées de ce dossier ont évolué. Vous pouvez choisir de les mettre à jour ou non dans votre dossier patient.",
		strong: "Attention",
		'phone-update': {
			title: "Mettre à jour le numéro de téléphone",
			label: "Modifier le numéro de téléphone par celui-ci : {0}",
		},
		'email-update': {
			title: "Mettre à jour l'adresse email",
			label: "Modifier l'adresse email par celle-ci : {0}",
		}
	},
	notification: "<strong>Attention :</strong> Pour rappel, ce rendez-vous a été pris pour <strong>{patient}</strong>",
    'informations': {
        'main-title': "Informations générales",
        office: "Rendez-vous pris au cabinet :",
        session: "Type de consultation :",
        'session-none': "inconnue",
        'duration-unit': "min.",
        customer: "Rendez-vous pris par :",
        'for-patient': "Rendez-vous pris pour ",
        himself: "lui-même|elle-même|lui-même",
    },
    'help-information': {
        title: "Dossier patient associé",
        text: "L'utilisateur qui a pris ce rendez-vous n'est pas rattaché à l'un de vos patients ? Faites une recherche parmis vos patients ou créez un nouveau dossier pour que, lors de ses prochaines prises de rendez-vous en ligne, cet utilisateur soit lié à un dossier patient. Vous pouvez également ignorer cette étape, votre rendez-vous ne sera pas associé à un de vos dossiers patients."
    },
    'patient-linked': "Dossier patient déjà rattaché à cet utilisateur | Dossier patient déjà rattaché à cet utilisateur | Dossiers patient déjà rattachés à cet utilisateur",
    select: {
        title: "Sélectionner ce patient pour l'associer à ce rdv",
        label: "sélectionner ce patient"
    },
    blacklisted: "blacklisté",
    deceased: "décédé",
    refuse: {
        title: "Refuser ce rdv et avertir le patient",
        label: "refuser",
        'label-popin': "refuser",
        'title-popin': "Refus"
    },
    confirm: {
        title: "Confirmer ce rdv et avertir le patient",
        label: "confirmer",
        'label-popin': "confirmer",
        'title-popin': "Confirmation",
        'content-popin': "Vous êtes sur le point de confirmer ce rdv et d'avertir votre patient de votre choix.<br>Souhaitez-vous vraiment confirmer ?",
    }
}
