<section class="login-frame">
	<h2 class="login-frame-title">{{ $t("vm.login.register.title") }}</h2>
	<form @submit.prevent="submit" class="login-frame-content">

		<i18n path="vm.login.register.description.text" tag="p">
			<strong>{{ $t("vm.login.register.description.strong") }}</strong>
		</i18n>

		<input-frame type="text" id="last-name" ref="last-name" :label="$t('vm.login.register.last-name.label')" v-model="last_name"
			:error-test="hasLastNameError"
			:error-msg="$t('vm.login.register.last-name.error')"
			:placeholder="$t('vm.login.register.last-name.placeholder')"
			:title="$t('vm.login.register.last-name.title')"/>

		<input-frame type="text" id="first-name" :label="$t('vm.login.register.first-name.label')" v-model="first_name"
			:error-test="hasFirstNameError"
			:error-msg="$t('vm.login.register.first-name.error')"
			:placeholder="$t('vm.login.register.first-name.placeholder')"
			:title="$t('vm.login.register.first-name.title')"/>

		<input-frame type="email" id="email" :label="$t('vm.login.register.email.label')" v-model="email"
			:error-test="hasEmailError"
			:error-msg="$t('vm.login.register.email.error')"
			:placeholder="$t('vm.login.register.email.placeholder')"
			:title="$t('vm.login.register.email.title')"/>

		<input-frame type="email" id="confirm-email" :label="$t('vm.login.register.confirm-email.label')" v-model="confirm_email"
			:error-test="hasConfirmEmailError"
			:error-msg="$t('vm.login.register.confirm-email.error')"
			:placeholder="$t('vm.login.register.confirm-email.placeholder')"
			:title="$t('vm.login.register.confirm-email.title')"/>

		<input-frame type="text" id="godchild-code" :label="$t('vm.login.register.godchild-code.label')" v-model="code"
			:placeholder="$t('vm.login.register.godchild-code.placeholder')"
			:title="$t('vm.login.register.godchild-code.title')"/>

		<div class="p-like">
			<div v-if="hasCgvError" class="notice-danger">{{ $t("vm.login.register.cgv.politeness") }}</div>
			<i18n path="vm.login.register.cgv.text" tag="checkbox-frame" id="cgv" v-model="cgv">
				<a href="//osteo2ls.com/logiciel/assets/mentions-legales.pdf" :title="$t('vm.login.register.cgv.link_title')">{{ $t("vm.login.register.cgv.link") }}</a>
			</i18n>
		</div>

		<div class="p-like childs-space-between">
			<button-action class="btn btn-default" @click.native="previous" :disabled="isRegistering" :title="$t('vm.login.register.previous.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t("vm.login.register.previous.text") }}
			</button-action>

			<button-action type="submit" btn-class="btn-primary" :loading="isRegistering" :title="$t('vm.login.register.register.title')">
				{{ $t("vm.login.register.register.text") }}
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				</svg>
			</button-action>
		</div>

	</form>
</section>
