export enum DefaultPage {
    None        = 0,
    Dashboard   = 1,
    Patients    = 2,
    Planning    = 3,
    Accounting  = 4,
    Letters     = 5,
    CoWorking   = 6,
    Shop        = 7,
    Help        = 8,
    Statistics  = 9,
    Parameters  = 10,
    User        = 11,
}