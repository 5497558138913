import Render from '@Views/components/button-action.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class ButtonAction extends Vue {
    @Prop({default: false}) loading: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: "button"}) type: string
    @Prop({default: "btn-default"}) btnClass: string
    @Prop({default: "btn"}) defaultClass: string
    @Prop({default: true}) loader: boolean
}
