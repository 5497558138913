export enum FormType {
	Consultation = 0,
	Antecedent = 1,
	Sphere = 2,
	Coordinates = 4,
	Pediatrics = 5,
	Reason = 6
}
export enum QuestionType {
	Multiple_Choice = 1,
	Single_Choice = 2,
	Short_Text = 3,
	Long_Text = 4
}