<container>

	<aside-calendar/>

	<master>

		<central>
			<div class="wrapper-full calendar calendar-frame">
				<div v-if="isAbsent" class="marg-v notice-warning">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
						</svg>
						{{ absentPeriod }}
					</p>
				</div>
				<div v-if="!!schedule && !!schedule.message" class="marg-v notice-warning">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
						</svg>
						{{ $tc('vm.root.user.office.schedule.cycle.label', schedule.cycle) }},
						{{ $t('general.from') }} {{ $d(schedule.start, 'weekdayMin') }} {{ $d(schedule.start, 'LL') }}
						{{ $t('general.to') }} {{ $d(schedule.end, 'weekdayMin') }} {{ $d(schedule.end, 'LL') }}<br>
						{{ schedule.message }}
					</p>
				</div>

				<div class="calendar-header toolbar childs-space-between">
					<h1 class="h-reset calendar-header-title">
						<div class="text-lg text-primary">{{ title }}</div>
					</h1>
					<togglebox class="toolbar-control" v-model="view" :items="viewItems" id="id"/>
					<div class="is-pushed-right">
						<div class="form-group toolbar-control">
							<button type="button" class="input-like form-group-input is-flex-fixed"
								:title="$t('vm.root.calendar.main.today.title')" @click="today">
								<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-day"/>
								</svg>
								<span class="rwd-over-xlarge">{{ $t('vm.root.calendar.main.today.label') }}</span>
							</button>
							<button type="button" class="input-like form-group-input is-flex-fixed"
								:title="$t('vm.root.calendar.main.previous.title')" @click="previous">
								<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
								</svg>
								<span class="sr-only">{{ $t('vm.root.calendar.main.previous.label') }}</span>
							</button>
							<button type="button" class="input-like form-group-input is-flex-fixed"
								:title="$t('vm.root.calendar.main.next.title')" @click="next">
								<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
								</svg>
								<span class="sr-only">{{ $t('vm.root.calendar.main.next.label') }}</span>
							</button>
						</div>
					</div>
				</div>

				<div class="calendar-container">
					<calendar 	ref="calendar"
								:config="config"
								@event-selected="eventClick"
								@date-click="dateClick"
								@event-drop="eventDrop"
								@event-resize="eventResize"
								@view-render="refresh"/>
				</div>

				<control-bar class="calendar-legend childs-space-between">
					<div>
						<span class="calendar-legend-item">
							<span class="calendar-legend-item-color is-unvalid"></span>
							<span class="text-middle">{{ $t('vm.root.calendar.main.legend.to-valid') }}</span>
						</span>
						<span class="calendar-legend-item">
							<span class="calendar-legend-item-color is-missed"></span>
							<span class="text-middle">{{ $t('vm.root.calendar.main.legend.missed') }}</span>
						</span>
						<span class="calendar-legend-item">
							<span class="calendar-legend-item-color is-unavailable"></span>
							<span class="text-middle">{{ $t('vm.root.calendar.main.legend.unavailable') }}</span>
						</span>
						<span class="calendar-legend-item">
							<span class="calendar-legend-item-color is-substitute"></span>
							<span class="text-middle">Remplaçant</span>
						</span>
					</div>
					<div class="is-pushed-right">
						<button type="button" @click="addNewEvent" class="btn btn-primary"
							:title="$t('vm.root.calendar.main.new-event.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-plus"/>
							</svg>
							<span class="rwd-over-xlarge">{{ $t('vm.root.calendar.main.new-event.label') }}</span>
						</button>
						<button type="button" :disabled="loading" @click="refetchEvents" class="btn btn-default" :title="$t('vm.root.calendar.main.refresh.title')">
							<spinner class="svg-middle" v-if="loading"/>
							<svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#sync-alt"/>
							</svg>
							<span class="sr-only">{{ $t('vm.root.calendar.main.refresh.label') }}</span>
						</button>

					</div>
				</control-bar>
			</div>
		</central>

		<context>
			<context-user v-if="isMainUser"/>
			<context-secretary v-if="isSecretaryUser"/>
		</context>

	</master>

</container>
