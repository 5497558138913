export const asidePurchase = {
    back: {
        title: "Retournez vers la boutique",
        label: "Retour à la boutique"
    },
    delete: {
        'delete-msg': "Voulez-vous vraiment supprimer cet achat ?",
        title: "Supprimer cet achat",
        label: "Supprimer cet achat"
    }
}