export enum ReminderType {
	None 		= 1,
	OnlyMail	= 2,
	OnlySms 	= 3,
	Both 		= 4
}

export enum FollowUpLevel {
	NotSatisfied 	= 1,
	None			= 2,
	Satisfied 		= 3
}