export const enumsValues = {
    'font-size': {
        tiny: "Rikiki",
        small: "Petite",
        normal: "Normale",
        large: "Large",
        huge: "Énooooooorme"
    },
    'default-page': {
        none: "La dernière page visitée",
        dashboard: "Dashboard",
        patients: "Patients",
        planning: "Planning",
        accounting: "Finances",
        letters: "Courriers",
        coworking: "Co-travail",
        shop: "Boutique",
        help: "Support",
        statistics: "Statistiques",
        parameters: "Paramètres",
        user: "Compte utilisateur",
    },
    skin: {
        default: "Fjord",
        dark: "Nocturne",
        light: "Clair"
    },
    'purchase-mode': {
        check: "Chèque",
        transfer: "Virement",
        paypal: "Paypal / CB"
    },
    timezone: {
        'utc+1': "UTC+1:00 (France, Belgique, Suisse, etc.)",
        'utc-3': "UTC-3:00 (Guyanne)",
        'utc-4': "UTC-4:00 (Guadeloupe, Martinique, St-Barthélemy, St-Martin)",
        'utc-5': "UTC-5:00 (Toronto, New-York, Bogota)",
        'utc+4': "UTC+4:00 (Dubaï, Émirats Arabes Unis)",
        'utc+11': "UTC+11:00 (Nouvelle Calédonie)"
    },
    lang: {
        french: "Français",
        english: "English"
    },
    'auto-save-delay': {
        'five-minutes': "après 5 minutes d'inactivité",
        'ten-minutes': "après 10 minutes d'inactivité",
        'thirty-minutes': "après 30 minutes d'inactivité",
        never: "jamais"
    },
    'accounting-period': {
        monthly: "le mois en cours",
        yearly: "l'année en cours"
    },
    widget: {
        'birth-days': "Aucun anniversaire | Un anniversaire | {count} anniversaires",
        'unpaid': "Aucune facture impayée | Une seule facture impayée | {count} factures impayées",
        'sms': "SMS | SMS | SMS",
        'follow-up': "Aucun suivi de consultation | Un suivi de consultation | {count} suivis de consultation",
        'valid-event': "Aucun rendez-vous à valider | Un rendez-vous à valider | {count} rendez-vous à valider"
    },
    pack: {
        sms: "pack de SMS",
		subscription: "pack tranquillité échelonné",
		support: "pack de maintenance",
		unlimited: "pack tranquillité",
		import: "pack d'importation",
		planning: "pack planning en ligne",
		card: "pack carte de visite",
		bulk1: "réservation achats groupés",
		bulk2: "finalisation achats groupés",
		site: "pack site internet"
    },
    'gift-pack': {
        sms: "{count} SMS|{count} SMS|{count} SMS",
		subscription: "",
		support: "{count} mois de maintenance|{count} mois de maintenance|{count} mois de maintenance",
		unlimited: "",
		import: "",
		planning: "{count} mois de planning en ligne|{count} mois de planning en ligne|{count} mois de planning en ligne",
		card: "",
		site: ""
    },
    provider: {
        none: "Aucun",
		permatel: "Permatel",
    },
    'invoice-type': {
        receipt: "Recette",
		spend: "Dépense",
		immobilization: "Immobilisation"
    },
    'damping-type': {
        linear: "Linéaire",
		degressive: "Dégressif",
		exceptional: "Exceptionnel"
    },
    'receipt-filter-type': {
		checked: "Réglée",
		'not-checked': "Non réglée",
        banked: "Déposée",
		'not-banked': "Non déposée",
		retrocession: "Rétrocession",
		'not-retrocession': "Non rétrocession"
    },
    'receipt-type': {
        receipt1: "Recette encaissée",
		receipt2: "À déduire, débours payés",
		receipt3: "À déduire, honoraires rétrocédés",
		receipt5: "Produits financiers",
		receipt6: "Gains divers",
		'receipt-other': "Autre"
    },
    'spend-type': {
        spend0: "Achats",
        spend1: "Frais de personnel, Salaires nets et avantages en nature",
        spend2: "Frais de personnel, Charges sociales sur salaires",
        spend3: "Impôts et taxes, Taxe sur la valeur ajoutée",
        spend4: "Impôts et taxes, Contribution économique territorialle",
        spend5: "Impôts et taxes, Autres impôts",
        spend6: "Contribution sociale généralisée déductible",
        spend7: "Loyer et charges locatives",
        spend8: "Location de matérial et de mobilier",
        spend9: "Entretien et réparations",
        spend10: "Personnel intérimaire",
        spend11: "Petil outillage",
        spend12: "Chauffage, eau, gaz, électricité",
        spend13: "Honoraires ne constituant pas des rétrocessions",
        spend14: "Primes d'assurances",
        spend15: "Frais de véhicules",
        spend16: "Autres frais de déplacements (voyages…)",
        spend17: "Charges sociales personnelles obligatoires",
        spend18: "Charges sociales personnelles facultatives",
        spend19: "Frais de réception, de représentation et de congrès",
        spend20: "Fournitures de bureau, frais de documentation…",
        spend21: "Frais d'actes et de contentieux",
        spend22: "Cotisations syndicales et professionnelles",
        spend23: "Autres frais divers de gestion",
        spend24: "Frais financiers",
        spend25: "Pertes diverses",
        spend26: "Autre",
    },
    access: {
        other: "Autre",
        rer: "RER",
        metro: "Métro",
        tramway: "Tramway"
    },
    currency: {
        dollar: "$ | Dollar ($) | Dollar ($)",
        euro: "€ | Euro (€) | Euro (€)"
    },
    gender: {
        man: "Homme",
        woman: "Femme",
        other: "Homme ou Femme"
    },
    'gender-child': {
        man: "Petit garçon",
        woman: "Petite fille",
        other: "Petit être"
    },
    'gender-teenager': {
        man: "Adolescent",
        woman: "Adolescente",
        other: "Adolescent"
    },
    'gender-none': {
        man: "Âgé",
        woman: "Âgée",
        other: "Âgé"
    },
    alimentation: {
        both: "les 2",
        breast: "sein",
        milk: "lait maternisé"
    },
    date_state: {
        there_is: "il y a",
        at_the_age: "à l'âge de",
        date: "précisément le",
        at_year: "en"
    },
    form: {
        antecedent: "un antécedent",
        consultation: "une consultation",
        coordinates: "les coordonnées",
        pediatrics: "la périnatalité",
        reason: "un historique",
        sphere: "une sphère"
    },
    question: {
        long_text: "texte long",
        multiple_choice: "choix multiple",
        short_text: "texte court",
        single_choice: "choix simple",
    },
    situation: {
        none: "non renseignée | non renseignée | non renseignée",
        single: "célibataire | célibataire | célibataire",
        married: "marié | mariée | marié",
        divorced: "divorcé | divorcée | divorcé",
        widowed: "veuf | veuve | veuf",
        freeUnion: "en union libre | en union libre | en union libre",
        pacs: "pacsé | pacsée | pacsé"
    },
    'follow-up-level': {
        bad: "aggravation",
        none: "stagnation",
        good: "évolution"
    },
    level: {
        low: "bas",
        medium: "moyen",
        high: "haut"
    },
    members: {
        none: "Aucun | Aucun | Aucun",
        father_mother: "Mère / Père | Mère / Père | Mères / Pères",
        brother_sister: "Soeur / Frère | Soeur / Frère | Soeurs / Frères",
        son_daughter: "Fille / Fils | Fille / Fils | Filles / Fils",
        nephew_niece: "Nièce / Neveu | Nièce / Neveu | Nièces / Neveux",
        uncle_aunt: "Tante / Oncle | Tante / Oncle | Tantes / Oncles",
        husband_wife: "Femme / Mari | Femme / Mari | Femmes / Maris",
        grand_father_grand_mother: "Grand-mère / Grand-père | Grand-mère / Grand-père | Grand-mères / Grand-pères",
        grand_son_grand_daughter: "Petite-fille / Petit-fil | Petite-fille / Petit-fils | Petites-filles / Petits-fils",
        great_grand_father_great_grand_mother: "Arrière grand-mère / Arrière grand-père | Arrière grand-mère / Arrière grand-père | Arrières grand-mères / Arrières grand-pères",
        great_grand_son_great_grand_daughter: "Arrière petite-fille / Arrière petit-fils | Arrière petite-fille / Arrière petit-fils | Arrières petites-filles / Arrières petits-fils"
    },
    member: {
        none: "Aucun | Aucun | Aucun",
        father_mother: "Père | Mère | Parent",
        brother_sister: "Frère | Soeur | Frère",
        son_daughter: "Fils | Fille | Enfant",
        nephew_niece: "Neveu | Nièce | Neveu",
        uncle_aunt: "Oncle | Tante | Oncle",
        husband_wife: "Mari | Femme | Mari",
        grand_father_grand_mother: "Grand-père | Grand-mère | Grand-parent",
        grand_son_grand_daughter: "Petit-fils | Petite-fille | Petit-enfant",
        great_grand_father_great_grand_mother: "Arrière grand-père | Arrière grand-mère | Arrière grand-parent",
        great_grand_son_great_grand_daughter: "Arrière petit-fils | Arrière petite-fille | Arrière petit-enfant"
    },
    articulationMove: {
        flexion: "flexion",
        extension: "extension"
    },
    horizontalDirection: {
        left: "gauche",
        right: "droite"
    },
    verticalDirection: {
        top: "haut",
        bottom: "bas"
    },
    strainMove: {
        rolling: "par roulement",
        sliding: "par glissement"
    },
    recurring: {
        none: "Pas de récurrence",
        day: "Tous les",
        week: "Toutes les",
        month: "Tous les",
        year: "Tous les",
    },
    recurringUnit: {
        day: "jours",
        month: "mois",
        week: "semaines",
        year: "ans",
    },
    profile: {
        main: "compte principal",
        substitute: "compte remplaçant",
        accounting: "compte comptable",
        secretary: "compte secrétaire",
        demo: "compte de démonstration",
        'main-short': "principal",
        'substitute-short': "remplaçant",
        'accounting-short': "comptable",
        'secretary-short': "secrétaire",
        'demo-short': "démonstration"
    },
    reminder: {
        none: "Aucun",
        both: "Rappel par SMS et par mail",
        onlySms: "Rappel uniquement par SMS",
        onlyMail: "Rappel uniquement par mail"
    },
    confirmation: {
        none: "Aucun",
        both: "Confirmation par SMS et par mail",
        onlySms: "Confirmation uniquement par SMS",
        onlyMail: "Confirmation uniquement par mail"
    },
    attachment: {
        file: "Fichier",
        link: "Lien"
    },
    payment: {
        check: "Chèque",
        'credit-card': "Carte bleue",
        'free-act': "Acte gratuit",
        moneo: "Monéo",
        cash: "Espèces",
        transfer: "Virement",
        other: "Autre",
        waiting: "En attente"
    },
    news: {
        health: "santé",
        osteopathy: "ostéopathie",
        software: "logiciel"
    },
    phone: {
        fix: "Fixe",
        mobil: "Portable",
        professional: "Professionnel",
        fax: "Fax"
    },
    'free-space-mode': {
        classic: "Classique",
        optimized: "Optimisé",
        'semi-optimized': "Semi-optimisé"
    }
}
