<div v-if="attachments">

	<div class="childs-space-between marg-v">
		<h2 class="text-uppercase-first-letter no-marg">{{ $t('vm.root.patients.patient.attachments.title') }}</h2>
		<router-link :to="{name:'patient-attachment', params:{pat_id: $route.params.pat_id}}"
					 class="is-pushed-right" :title="$t('vm.root.patients.patient.attachments.new-attachment.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.patients.patient.attachments.new-attachment.text') }}</span>
		</router-link>
	</div>

	<div class="masonry">
<!--		<storage-item/>-->
		<attachment-item v-for="attachment in attachments" :key="getElementId(attachment)" :attachment="attachment" :read-only="readOnly" @edit="onEdit"/>
	</div>
	<div v-if="attachments.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#image"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.attachments.none.title') }}</span><br>
			<i18n path="vm.root.patients.patient.attachments.none.description.text" tag="span">
				<router-link :to="{name:'patient-attachment', params:{pat_id: $route.params.pat_id}}"
							 :title="$t('vm.root.patients.patient.attachments.none.description.title')">
					{{ $t("vm.root.patients.patient.attachments.none.description.link") }}
				</router-link>
			</i18n>
		</p>
	</div>

</div>
