import Render from '@Views/root/co-working/link-item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Profile, Provider } from '@Enums/index'
import { ILink } from '@Store/types'
import { account, coworking, notif, user } from '@Store/modules'

@Render
@Component({})
export class LinkItem extends Vue {

	private static LastStep: number = 2

	@Prop({}) link: ILink

	// @State('coworking') stateCoWorking: CoWorkingState
	// @State('account') stateAccount: AccountState
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Mutation('coworking/updateStep') updateStep: (step: number) => void
	// @Mutation('coworking/updateEmail') updateEmail: (email: string) => void
	// @Mutation('coworking/updateProfile') updateProfile: (profile: Profile) => void
	// @Mutation('notification/error') error: (notification: string | INotification) => void
	// @Getter('user/currentUser') currentUser: IUser
	// @Action('account/verify') verify: (context: {email: string, profile: Profile, provider: Provider}) => any

	get isVerifying(): boolean {
		if (!account.isVerifying) return false
		if (!account.verifyLink) return false
		if (account.verifyLink.email === this.link.email && account.verifyLink.profile === this.link.profile) return true
		return false
	}
	get canUpdate(): boolean {
		return user.currentUser.email !== this.link.email || user.currentUser.profile !== this.link.profile
	}
	private get step(): Profile {
		return coworking.step
    }
	
	update(): void {
		if (!!account.verifyLink && account.verifyLink.email === this.link.email && account.verifyLink.profile === this.link.profile) return

		account.verify({email: this.link.email, profile: this.link.profile, provider: Provider.None})
		.then(() => {	
			coworking.updateProfile(this.link.profile)
			coworking.updateEmail(this.link.email)
			if (this.step === LinkItem.LastStep) return

            coworking.updateStep(LinkItem.LastStep)
			this.$router.push({name: 'share-step-three'})
        })
		.catch(error => { notif.error(error) })
	}
}