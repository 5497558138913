import Render from '@Views/root/user/account/informations.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IBreadcrumbItem, IUser } from '@Store/types'
import { IToggleBoxItem } from '@Components/index'
import { User } from '@Models/index'
import { breadcrumb, user } from '@Store/modules'
import {Gender} from "@Enums/gender";

@Render
@Component({})
export class Informations extends Vue {

	@Prop() submitted: boolean
	@Prop() user: IUser

	//#region Getters
	get hasLastNameError(): boolean {
		return this.submitted && !this.user.coordinates.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.user.coordinates.first_name
	}
	get hasSirenError(): boolean {
		return this.submitted && !this.hasValidSiren
	}
	get isLoading(): boolean {
		return user.isLoading
	}
	get genderItems(): IToggleBoxItem[] {
		return [
			{value: Gender.Man, label: this.$i18n.t('vm.root.user.informations.gender.man').toString(), icon:"mars"},
			{value: Gender.Woman, label: this.$i18n.t('vm.root.user.informations.gender.woman').toString(), icon:"venus"},
			{value: Gender.Other, label: this.$i18n.t('vm.root.user.informations.gender.other').toString()}
		]
	}
	private get hasValidSiren(): boolean {
		return !this.user.coordinates.siren || User.isLuhnNum(this.user.coordinates.siren)
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'account')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString() }
		breadcrumb.updateItems([item])
	}
}
