import Render from '@Views/root/patients/patient/consultation/cranio.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReadOnly } from './read-only'
import { IConsultation, ICranioSacral, IBreadcrumbItem, IDisplay } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { ArticulationMove, StrainMove, DirectionHorizontal, DirectionVertical } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb, configuration } from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnly
	}
})
export class Cranio extends Vue {

	private horizontalDirections: DirectionHorizontal[] = [DirectionHorizontal.None, DirectionHorizontal.Left, DirectionHorizontal.Right]
	private verticalDirections: DirectionVertical[] = [DirectionVertical.None, DirectionVertical.Top, DirectionVertical.Bottom]
	private articulationMoves: ArticulationMove[] = [ArticulationMove.None, ArticulationMove.Extension, ArticulationMove.Flexion]
	private strainMoves: StrainMove[] = [StrainMove.None, StrainMove.Rolling, StrainMove.Sliding]

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	// @State('configuration') stateConfiguration: ConfigurationState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get cranio(): ICranioSacral {
		return this.consultation.cranio
	}
	get horizontalDirectionItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
    	this.horizontalDirections.forEach(direction => result.push({value: direction, label: EnumsHelper.horizontalDirectionToString(direction)}))
	    return result
    }
	get articulationMoveItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
    	this.articulationMoves.forEach(move => result.push({value: move, label: EnumsHelper.articulationMoveToString(move)}))
	    return result
	}
	get strainMoveItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
    	this.strainMoves.forEach(move => result.push({value: move, label: EnumsHelper.strainMoveToString(move)}))
	    return result
	}
	get verticalDirectionItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
    	this.verticalDirections.forEach(direction => result.push({value: direction, label: EnumsHelper.verticalDirectionToString(direction)}))
	    return result
	}
	get display(): IDisplay {
		return configuration.configuration && configuration.configuration.display
	}

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.cranio').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}