export const main = {
    'new-document': "Nouveau document",
    reset: {
        title: "Restaurez ce document à l'initial",
        label: "restaurer le document",
        action: {
            label: "restaurer",
            title: "Restauration du document",
            content: "Vous êtes sur le point de restaurer ce document à l'initial. Vous confirmez ?"
        }
    },
    delete: {
        label: "supprimer ce document",
        title: "Suppression du document",
		'delete-msg': "Vous êtes sur le point de supprimer ce document. Souhaitez-vous continuer ?"
    },
    name: {
        label: "Titre du courrier",
        placeholder: "ex : Courrier de relance",
        'error-msg': "Merci d'indiquer le titre de votre courrier",
        title: "Personnalisez le titre de votre courrier"
    },
    none: {
        text: "Sélectionnez un document existant ou {0}.",
        'button-title': "Créer un nouveau document",
        'button-label': "créez-en un nouveau"
    },
    render: {
        title: "Mettez en page votre document",
        placeholder: "Bla bla…"
    }
}