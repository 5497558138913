<div v-if="options">
	<p>{{ $t('vm.components.popin.rgpd-patient.text1') }}</p>
	<i18n path="vm.components.popin.rgpd-patient.text2.content" tag="p">
		<strong>{{ $t('vm.components.popin.rgpd-patient.text2.strong') }}</strong>
	</i18n>
	<i18n path="vm.components.popin.rgpd-patient.text3.content" tag="p">
		<strong>{{ $t('vm.components.popin.rgpd-patient.text3.strong') }}</strong>
	</i18n>

	<input-date-frame v-model='options.date' id="birth-date" :type="birthDateType"
		:label="$t('vm.root.patients.patient.coordinates.birth-date.label')"
		:title="$t('vm.root.patients.patient.coordinates.birth-date.title')"
		:placeholder="$t('vm.root.patients.patient.coordinates.birth-date.placeholder')"
		:error-test="hasBirthDateError" :error-msg="errorMessage"/>

</div>
