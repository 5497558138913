import { BaseService } from '@Services/index'
import {WidgetType} from "@Enums/widget";

export class WidgetService extends BaseService {

	public getWidget(type: WidgetType): Promise<any> {
		return super.get(`/widget/${type}/`)
	}
	public getBirthdayList(date: Date): Promise<any> {
		return super.post('/widgets/', {birthday: date})
	}
	public getNotificationFollowUp(): Promise<any> {
		return super.get(`/notification/follow-up/`)
	}
}
