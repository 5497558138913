import Render from '@Views/root/accounting/context-accounting.html'
import { Component } from 'vue-property-decorator'
import { Profile } from '@Enums/index'
import { AbstractContext } from './context-base'
import { ILinkUser } from '@Store/types'
import { user } from '@Store/modules'

@Render
@Component({})
export class ContextAccounting extends AbstractContext {

	mounted() {
		super.reset()
		let users: ILinkUser[] = this.getUserList()
		if (!users || users.length === 0) return

		this.filter.usr_id = users[0].id
	}

	reset(): void {
		super.reset()
	}

	getUserList(): ILinkUser[] {
		return user.linkedUsers(Profile.Main)
	}
}