export const navigation = {
    reduce: {
        label: "Réduire",
        title: "Réduire / agrandir le menu de navigation"
    },
    dashboard: {
        label: "Dashboard",
        title: "Visualiser mon activité",
		tooltip: "{count} suivi non lu | {count} suivi non lu | {count} suivis non lus"
    },
    planning: {
        label: "Planning",
        title: "Prendre mes rendez-vous",
        tooltip: "{count} rendez-vous à valider | {count} rendez-vous à valider | {count} rendez-vous à valider"
    },
    accounting: {
        label: "Finances",
        title: "Tenir mes comptes"
    },
    letters: {
        label: "Courriers",
        title: "Écrires mes courriers"
    },
    'co-working': {
        label: "Co-Travail",
        title: "Partager mon travail"
    },
    patients: {
        label: "Patients",
        title: "Consulter mes dossiers patients"
    },
    shop: {
        label: "Boutique",
        title: "Acheter ma licence et mes SMS"
    },
    help: {
        label: "Support",
        title: "Demander de l'aide"
    },
    statistics: {
        label: "Statistiques",
        title: "Consulter mes statistiques"
    },
    parameters: {
        label: "Paramètres",
        title: "Paramétrer mon application"
    },
    'new-event': {
        label: "Rendez-vous",
        title: "Créer rapidement un nouveau rendez-vous"
    },
    account: {
        title: "Accéder à mon profil",
        anonymous: "compte anonyme"
    },
    logout: {
        label: "Déconnexion",
        title: "Me déconnecter d'Osteo2ls",
        'action-label': "déconnecter",
        'action-text': "Êtes-vous sûr de vouloir vous déconnecter d'Osteo2ls ?"
    },
    notification: {
        'follow-up': {
            title: "Suivi de consultation",
            body: "Vous n'avez aucun suivi de consultation non lu | Vous avez {count} suivi de consultation non lu | Vous avez {count} suivis de consultation non lus"
        },
        support: {
            title: "Réponse support",
            body: "Vous n'avez aucune réponse à vos tickets | Vous avez {count} réponse à vos tickets | Vous avez {count} réponses à vos tickets"
        },
        event: {
            title: "Rendez-vous à valider",
			body: "Vous avez {count} rendez-vous à valider",
        }
    }
}
