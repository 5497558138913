<div v-if="options" class="popin-person-search">

	<div v-if="invoices.length === 0" class="popin-person-search-no-result is-white-space">
		<div>
			<label for="popin-search-patient">
				<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 496 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-invoice"/>
				</svg>
			</label>
			<div class="text-md text-bold text-center marg-v">{{ $t('vm.components.popin.unpaid-invoice.no-unpaid') }}</div>
		</div>
	</div>

	<div v-else class="marg-v">
		<misc-item v-for="invoice in invoices" :key="invoice.id" :id="invoice.id" v-model="options.id" :radio-value="invoice.id">
			{{ invoice.number }} {{ $d(invoice.settlement_date, 'll') }} &bull; {{ invoice.ttc }} {{ invoice.currency | currency }}
		</misc-item>
	</div>

</div>
