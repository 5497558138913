<context>
	<div class="wrapper">

		<p class="text-center text-strong">{{ $t('vm.root.user.context-main.title') }}</p>
		<apexchart type="radialBar" height="250" :options="chartOptions" :series="series"/>
		<ul class="ul-reset childs-space-around">
			<li v-for="legend in legends" :key="legend.label">
				<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
				</svg>
				{{ legend.label }}
			</li>
		</ul>

		<hr>

		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkTitle() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkTitle()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong class="text-middle">{{ $t('vm.root.user.context-main.user-title.title') }}</strong><br>
			<span>{{ $t('vm.root.user.context-main.user-title.description') }}</span>
			<span v-if="!checkTitle()">:
				<router-link :to="{name: 'user-account', params: $route.params}" :title="$t('vm.root.user.context-main.user-title.link-title')">
					{{ $t('vm.root.user.context-main.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkOrganization() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkOrganization()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong class="text-middle">{{ $t('vm.root.user.context-main.organizations.title') }}</strong><br>
			<span>{{ $t('vm.root.user.context-main.organizations.description') }}</span>
			<span v-if="!checkOrganization()">:
				<router-link :to="{name: 'user-organizations', params: $route.params}" :title="$t('vm.root.user.context-main.organizations.link-title')">
					{{ $t('vm.root.user.context-main.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(checkEducation() ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="checkEducation()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong class="text-middle">{{ $t('vm.root.user.context-main.formations.title') }}</strong><br>
			<span>{{ $t('vm.root.user.context-main.formations.description') }}</span>
			<span v-if="!checkEducation()">:
				<router-link :to="{name: 'user-formations', params: $route.params}" :title="$t('vm.root.user.context-main.formations.link-title')">
					{{ $t('vm.root.user.context-main.complete') }}
				</router-link>
			</span>
		</p>
		<p>
			<svg width="1em" class="text-middle text-lg" :class="(hasAvatar ? 'text-success' : 'text-danger')" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="hasAvatar" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<strong class="text-middle">{{ $t('vm.root.user.context-main.avatar.title') }}</strong><br>
			<span>{{ $t('vm.root.user.context-main.avatar.description') }}</span>
			<span v-if="!hasAvatar">:
				<router-link :to="{name: 'user-account', params: $route.params}" :title="$t('vm.root.user.context-main.avatar.link-title')">
					{{ $t('vm.root.user.context-main.complete') }}
				</router-link>
			</span>
		</p>

	</div>
</context>
