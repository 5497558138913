export const password = {
    notification: "Votre mot de passe vient d'être modifié avec succès.",
    title: "Modifiez votre mot de passe",
    'current-email': "Votre adresse email actuelle est {0}.",
    'waiting-email': "Cette adresse est en attente de validation : {0}.",
    'current-password': {
        label: "Mot de passe actuel",
        'error-msg': "Merci de renseigner votre mot de passe actuel",
        placeholder: "ex : m07_d3_p4553!",
        title: "Veuillez renseigner votre mot de passe actuel"
    },
    'new-password': {
        label: "Nouveau mot de passe",
        'error-msg': "Merci de renseigner votre nouveau mot de passe",
        placeholder: "ex : N00vO_m07_d3_p4553!",
        title: "Veuillez renseigner votre nouveau mot de passe"
    },
    'confirm-password': {
        label: "Confirmation du mot de passe",
        'error-msg': "Merci de confirmer votre nouveau mot de passe",
        placeholder: "ex : N00vO_m07_d3_p4553!",
        title: "Veuillez confirmer votre nouveau mot de passe"
    },
    update: {
        'short-label': "modifier",
        'long-label': "votre mot de passe",
        title: "Mettez à jour votre mot de passe"
    }
}