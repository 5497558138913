import Render from '@Views/root/home/sticky-note.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Debouncer } from '@Models/index'
import { ILinkUser, IStickyNote, IPopin, IPopinAction } from '@Store/types'
import { home, popIn, user } from '@Store/modules'

@Render
@Component({})
export class StickyNote extends Vue {

	isSaving: boolean = false
	isDeleting: boolean = false
	private debouncer: Debouncer

	@Prop() stickyNote: IStickyNote

	get isSharing(): boolean {
		return home.isSharing
	}
	get canShare(): boolean {
		return parseInt(this.stickyNote.id) !== -1 && user.user.links.length > 0
	}
	get computedSize(): string {
		if (!this.stickyNote.notes) return this.sizes[0].size

		let length: number = this.stickyNote.notes.length
		for(let item of this.sizes) {
			if (length < item.length) return item.size
		}
		return this.sizes[this.sizes.length - 1].size
	}
	private get isNew(): boolean {
		return !this.stickyNote || parseInt(this.stickyNote.id) === -1
	}
	private	get users(): ILinkUser[] {
		return user.user.links
	}
	private get sizes(): ISize[] {
		return [
			{length: 15, size: "3em"},
			{length: 30, size: "2.5em"},
			{length: 45, size: "2em"},
			{length: 60, size: "1.75em"},
			{length: 85, size: "1.5em"},
			{length: 120, size: "1.375em"},
			{length: 160, size: "1.25em"},
			{length: 210, size: "1.125em"},
			{length: 270, size: "1em"}
		]
	}

	mounted() {
		this.debouncer = new Debouncer(this.save, 666)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	@Watch('stickyNote.notes')
	@Watch('stickyNote.label')
	@Watch('stickyNote.color')
	update() {
		this.debouncer.start()
	}

	share() {
		let shareAction: IPopinAction = {
			label: this.$i18n.t('vm.root.home.sticky-note.share-action.label').toString(),
			title: this.$i18n.t('vm.root.home.sticky-note.share-action.title').toString(),
			callback: (data: {shareList: any}) => {
				if (!data.shareList) return Promise.reject()

				this.stickyNote.share_list = data.shareList
				home.shareStickyNote({id: this.stickyNote.id, shareList: data.shareList})
				return Promise.resolve()
			}
		}

		let shareList = {}
		this.users.forEach(u => {
			shareList[u.id] = !!this.stickyNote.share_list[u.id] ? this.stickyNote.share_list[u.id] : false
		})

		let share: IPopin = {
			title: this.$i18n.t('vm.root.home.sticky-note.share-action.title').toString(),
			content: {component: 'popin-sticky-note-share'},
			action: shareAction,
			isWide: true,
			options: {shareList: shareList}
		}

		popIn.info(share)
	}

	submitDelete() {
		if (this.isNew) {
			this.$emit('delete', this.stickyNote)
			return
		}

		this.isDeleting = true
		home.deleteStickyNote(this.stickyNote)
		.then(() => {
			this.isDeleting = false
			this.$emit('delete', this.stickyNote)
		})
		.catch(() => { this.isDeleting = false })
	}

	private save() {
		this.isSaving = true
		home.saveStickyNote(this.stickyNote)
		.then((newPostIt: IStickyNote) => {
			this.isSaving = false
			if (parseInt(this.stickyNote.id) !== -1) return

			this.stickyNote.id = newPostIt.id
		})
		.catch(() => { this.isSaving = false })
	}
}

interface ISize {
	length: number,
	size: string
}
