import Render from '@Views/root/shop/purchase-item.html'
import {Component, Vue, Prop} from 'vue-property-decorator'
import {IPack, IPurchase, IPackValue} from '@Store/types'
import {purchase, user} from '@Store/modules'

@Render
@Component({})
export class PurchaseItem extends Vue {

	@Prop() purchase: IPurchase

	//#region Getters
	get isDeleting(): boolean {
		return purchase.isDeleting(this.purchase.id)
	}
	get isUnsubscribing(): boolean {
		return purchase.isUnsubscribing(this.purchase.id)
	}
	get isCompleted(): boolean {
		return purchase.isCompleted(this.purchase.state)
	}
	get isRecurrent(): boolean {
		return this.pack.recurrent
	}
	get title(): string {
		if (!this.pack) return ''

		let packValue: IPackValue = this.pack.values.find(v => {
			return v.value === this.purchase.value
		})
		return packValue.name
	}
	get canUnsubscribe(): boolean {
		return this.isRecurrent && (!!user.currentUser.license_date || user.currentUser.planning_date > new Date())
	}
	get isUnsubscribed(): boolean {
		return parseInt(this.purchase.profile_id) === -1
	}
	private get pack(): IPack {
		return purchase.pack(this.purchase.type)
	}
	//#endregion

	submitDelete(): void {
		if (!this.purchase || !this.purchase.id || parseInt(this.purchase.id) === -1) return
		purchase.deletePurchase(this.purchase.id)
	}

	submitUnsubscribe(): void {
		let route = {name: 'shop'}

		if (this.canUnsubscribe) {
			purchase.unsubscribePurchase(this.purchase.id).then(() => {
				this.$router.push(route)
			})
		} else {
			this.$router.push(route)
		}
	}
}
