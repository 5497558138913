export const searchPatient = {
    'new-patient': {
        text: "créer un {0} ?",
        button: "nouveau dossier",
        title: "Créer un nouveau dossier"
    },
    search: {
        searching: "recherche en cours…",
        'no-result': "aucun résultat"
    },
    blacklisted: "blacklisté",
    deceased: "décédé"
}