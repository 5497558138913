export const accountingContext = {
    title: "Filtre",
    office: {
        label: "Sélectionnez un cabinet",
		title: "Veuillez sélectionner un cabinet"
    },
    year1: {
		label: "Année 1",
		placeholder: "ex : 2016",
		title: "Indiquez l'année pour l'évolution 1"
    },
    year2: {
        label: "Année 2",
        placeholder: "ex : 2017",
        title: "Indiquez l'année pour l'évolution 2"
    },
    months: "Filtrer les mois à comparer"
}