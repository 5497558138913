<section class="person-item">
	<button v-if="!isNewPatient && !withoutOpen" type="button" class="btn--link-like person-item-header text-capitalize text-inherit"
		@click="open()" :title="$t('vm.root.search.patient.item.open', {'last-name': last_name, 'first-name': first_name})">
		<figure class="person-item-avatar">
			<avatar :first-name="first_name" :last-name="last_name" :email="email" :gender="gender"/>
		</figure>
		<h3 class="h-reset person-item-name">
			<span :inner-html.prop="first_name | mark(query)"/><br>
			<b :inner-html.prop="last_name | mark(query)"/>
		</h3>
	</button>
	<div v-else class="person-item-header text-capitalize text-inherit">
		<figure class="person-item-avatar">
			<avatar :first-name="first_name" :last-name="last_name" :email="email" :gender="gender"/>
		</figure>
		<h3 class="h-reset person-item-name">
			<span :inner-html.prop="first_name | mark(query)"/><br>
			<b :inner-html.prop="last_name | mark(query)"/>
		</h3>
	</div>
	<div class="person-item-border">
		<div v-if="phone" class="select"><a :href="`tel:${phone}`">{{phone | formatPhone}}</a></div>
		<div v-else><i>{{ $t('vm.root.search.patient.item.no-phone') }}</i></div>
		<div v-if="email" class="marquee select">
			<div class="marquee-inner">
				<div class="marquee-content"><a :href="`mailto:${email}`">{{email}}</a></div>
			</div>
		</div>
		<div v-else><i>{{ $t('vm.root.search.patient.item.no-email') }}</i></div>
	</div>
	<div v-if="blacklisted || deceased" class="person-item-border">
		<span v-if="blacklisted" class="patient-blacklisted">{{ $t('vm.root.search.patient.item.blacklisted') }}</span>
		<span v-if="deceased" class="patient-deceased">{{ $t('vm.root.search.patient.item.deceased') }}</span>
	</div>
	<div class="person-item-border text-right" v-if="!withoutUnselect">
		<button type="button" class="btn btn--link-like text-danger" @click="unselect"
			:title="$t('vm.root.search.patient.item.unselect.title')">{{ $t('vm.root.search.patient.item.unselect.label') }}</button>
	</div>
</section>
