<a-side :sub-menu="true">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'parameters-main'}" :title="$t('vm.root.parameters.document.aside.back.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.parameters.document.aside.back.label') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
		<li>
            <button type="button" class="btn--link-like" @click="addDocument" :title="$t('vm.root.parameters.document.aside.create-document.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				{{ $t('vm.root.parameters.document.aside.create-document.label') }}
			</button>
        </li>
        <li class="hr" aria-hidden="true"/>
		<li v-for="document in documents" :key="getDocumentId(document)">
            <router-link :to="{name: 'parameters-document', params:{doc_id: getDocumentId(document)}}" :title="$t('vm.root.parameters.document.aside.link-title', {title: document.title})">
                {{ document.title || $t('vm.root.parameters.document.aside.new-document') }}
			</router-link>
        </li>
    </ul>
</a-side>
