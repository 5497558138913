<div class="input-frame" :class="{ 'is-alert': errorTest }">
	<label class="label" :for="inputId" :data-text="label">
		<span>{{label}}</span>
	</label>

	<div class="input-phone form-group" :class="{ 'is-disabled': disabled }">

		<dropdown-button class="is-flex-fixed" :id="`${inputId}Type`" ddbClass="input-phone-type" :title="$t('vm.components.frame.input-phone-frame.title.type')" :items="phoneItems"
			:readonly="readonly" :disabled="disabled" :get-icon="getTypeIcon" v-model="phone.type"/>

		<dropdown-button class="is-flex-fixed" :id="`${inputId}Flag`" ddbClass="input-phone-flag" :title="$t('vm.components.frame.input-phone-frame.title.flag')" v-model="iso2"
			:flag="true" :preferred-items="preferredItems" :items="countryItems" :readonly="readonly" :disabled="disabled" :get-icon="getFlagIcon"/>

		<input class="input-phone-number form-group-input" ref="input" v-model="number" type="tel" :id="inputId" :title="title" :placeholder="placeholder"
			:disabled="disabled" @blur="onBlur" @input="onInput" :required="required">

	</div>

	<div class="msg-alert" v-if="displayError" v-show="errorTest">
		<div class="msg-alert-inner">{{errorMsg}}</div>
	</div>
</div>
