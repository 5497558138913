<div v-if="session">
	<div :class="isDefaultSession ? 'grid-3' : 'grid-2'">
		<input-frame :id="`title-${elementId}`" v-model="session.name"
			:label="$t('vm.root.user.office.session.name.label')"
			:placeholder="$t('vm.root.user.office.session.name.placeholder')"
			:error-test="hasNameError" :error-msg="$t('vm.root.user.office.session.name.error-msg')"
			:title="$t('vm.root.user.office.session.name.title')"/>

		<div class="input-frame" v-if="!isDefaultSession">
			<label class="label" :for="`color-${elementId}`" :data-text="$t('vm.root.user.office.session.color.label')">
				<span>{{ $t('vm.root.user.office.session.color.label') }}</span>
			</label>
			<div class="form-group">
				<checkbox-frame :id="`with-color-${elementId}`" tag="label" default-class="input-like form-group-input is-flex-fixed"
					v-model="session.with_color"
					:title="$t('vm.root.user.office.session.color.checked')"/>
				<input-color :id="`color-${elementId}`" class="form-group-input" v-model="session.color" :disabled="!session.with_color"
					:placeholder="$t('vm.root.user.office.session.color.placeholder')"
					:title="$t('vm.root.user.office.session.color.title')"/>
			</div>
		</div>

		<input-frame type="number" step="5" min="0" :id="`duration-${elementId}`" v-model="session.duration"
			:label="$t('vm.root.user.office.session.duration.label')"
			:placeholder="$t('vm.root.user.office.session.duration.placeholder')"
			:error-test="hasDurationError" :error-msg="$t('vm.root.user.office.session.duration.error-msg')"
			:title="$t('vm.root.user.office.session.duration.title')"/>

		<input-frame type="number" step="0.01" min="0" :id="`ttc-${elementId}`" v-model="session.ttc"
			:label="$t('vm.root.user.office.session.ttc.label')"
			:placeholder="$t('vm.root.user.office.session.ttc.placeholder')"
			:error-test="hasTtcError" :error-msg="$t('vm.root.user.office.session.ttc.error-msg')"
			:title="$t('vm.root.user.office.session.ttc.title')"/>
	</div>
	<textarea-frame :id="`description-${elementId}`" v-model="session.description" rows="2"
		:label="$t('vm.root.user.office.session.description.label')"
		:placeholder="$t('vm.root.user.office.session.description.placeholder')"
		:title="$t('vm.root.user.office.session.description.title')"/>

	<switch-frame v-if="!isDefaultSession" :id="`visible-${elementId}`" v-model="session.visible" checked-icon="eye"
				  :title="$t('vm.root.user.office.session.visible.title')">
		{{ $t('vm.root.user.office.session.visible.label') }}
	</switch-frame>


	<div class="text-right">
		<em v-if="isDefaultSession">{{ $t('vm.root.user.office.session.no-delete') }}</em>
		<delete-item v-else class="btn--link-like text-danger" :can-delete="true"
			:title="$t('vm.root.user.office.session.delete.title')"
			:delete-msg="$t('vm.root.user.office.session.delete.msg')"
			:delete-action="submitDelete">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
			</svg>
			{{ $t('general.delete.text') }}
		</delete-item>
	</div>

</div>
