import Render from '@Views/root/shop/bulk/steps/step2.html'
import {Component, Vue} from 'vue-property-decorator'
import {IUser} from '@Store/types'
import {user} from '@Store/modules'

@Render
@Component({})
export class Step2 extends Vue {

	//#region Getters
	get user(): IUser {
		return user.user
	}
	//#endregion
}
