<div class="input-frame" :class="{ 'is-alert': errorTest }">
	<collapsible-help v-if="withHelp" id="`help-${id}-information`" :title="label" 
		tag="label" tag-class="label" :tag-for="id">
		<slot slot="icon" name="icon"/>
		<slot slot/>
	</collapsible-help>
	<label v-else class="label" :for="id" :data-text="label">
		<span>{{label}}</span>
	</label>
	<dropdown :id="id" v-model="selectedValue" :items="items" :title="title" :readonly="readonly" :disabled="disabled"
		:default-label="defaultLabel" :default-value="defaultValue" :manage-missing="manageMissing"/>
	<div class="msg-alert" v-if="displayError" v-show="errorTest">
		<div class="msg-alert-inner">{{errorMsg}}</div>
	</div>
	
</div>
