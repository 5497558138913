export const contextMain = {
    office: {
        'main-title': "Cabinet | Cabinet | Cabinets",
        help: {
            text: "Si vous avez renseigné les {0} de vos cabinets, en sélectionnant un cabinet, vous pourrez les voir apparaître en arrière plan de votre planning.",
            link: {
                title: "Renseignez les horaires de votre cabinet",
                text: "horaires d'ouverture"
            }
        },
        none: "Pas de cabinet pour cet utilisateur"
    },
    'display-planning': "Afficher le planning : {name}",
    planning: {
        'main-title': "Planning Osteo2ls | Planning Osteo2ls | Plannings Osteo2ls",
        none: "Avant toute chose, vous devez créer un planning pour y associer vos rendez-vous",
        create: {
            title: "Créer un nouveau planning pour votre calendrier",
            label: "Créer un nouveau planning"
        },
        suppress: {
            title: "Suppression",
            message: "Voulez-vous vraiment supprimer de planning ?"
        }
    },
    'planning-google': {
        'main-title': "Planning Google | Planning Google | Plannings Google",
        connect: {
            title: "Connectez-vous à votre compte Google calendar",
            label: "connectez-vous"
        },
        refresh: {
            title: "Rafraîchir l'accès aux données Google calendar",
            label: "Rafraîchir"
        },
        disconnect: {
            title: "Déconnectez-vous à votre compte Google calendar",
            label: "Déconnexion"
        }
    }
}
