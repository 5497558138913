import * as Home from '@ViewModels/root/home'
import { Profile } from '@Enums/profile'

export const HomeRoot = [
	{
		path:'home',
		name:'home',
		component: Home.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] }
	}
]