export const contextSecretary = {
    osteopath: {
        'main-title': "Ostéopathe | Ostéopathe | Ostéopathes",
        filter: {
            placeholder: "ex : Andrew Taylor Still",
            title: "Veuillez renseigner un filtre pour rechercher un ostéopathe"
        }
    },
    'office-title': "Cabinet | Cabinet | Cabinets",
    'planning-title': "Planning | Planning | Plannings",
    'display-planning': "Afficher le planning : {name}"
}