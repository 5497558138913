export const cranio = {
    title: "Cranio-sacré",
    "sub-title-1": "M.R.P. crânien",
    "sub-title-2": "M.R.P. sacré",
    "mrp-cranial": {
        rhythm: {
            label: "Rythme (cycle/min)",
            placeholder: "ex : 6",
            title: "Veuillez renseigner le rythme M.R.P. crânien"
        },
        amplitude: {
            label: "Amplitude (+/-)",
            placeholder: "ex : ++",
            title: "Veuillez renseigner l'amplitude M.R.P. crânien"
        },
        force: {
            label: "Force (+/-)",
            placeholder: "ex : ---",
            title: "Veuillez renseigner la force M.R.P. crânien"
        }
    },
    "mrp-sacred": {
        rhythm: {
            label: "Rythme (cycle/min)",
            placeholder: "ex : 6",
            title: "Veuillez renseigner le rythme M.R.P. sacré"
        },
        amplitude: {
            label: "Amplitude (+/-)",
            placeholder: "ex : ++",
            title: "Veuillez renseigner l'amplitude M.R.P. sacré"
        },
        force: {
            label: "Force (+/-)",
            placeholder: "ex : ---",
            title: "Veuillez renseigner la force M.R.P. sacré"
        }
    },
    "ssb-label": "S.S.B.",
    "torsion-label": "Torsion",
    "sbr-label": "S.B.R.",
    "vertical-strain": {
        label: "Contrainte verticale",
        cause: "Mouvement"
    },
    "side-strain": {
        label: "Contrainte latérale",
        cause: "Mouvement"
    },
    "compression-label": "Compression",
    "condylar-shares": {
        label: "Parts condylaires",
        placeholder: "ex : Whiplash",
        title:"Veuillez renseigner les parts condylaires"
    },
    c0c1: {
        label: "C0 / C1",
        placeholder: "ex : Décompression C0 /C1",
        title: "Veuillez renseigner le C0 / C1"
    },
    eyes: {
        label: "Yeux",
        placeholder: "ex : Ptérion gauche", 
        title:"Veuillez renseigner un éventuel trouble des yeux"
    },
    roof: {
        label:"Palais",
        placeholder: "ex : Bonne succion",
        title: "Veuillez renseigner d'éventuelles malformation du palais"
    },
    mandible: {
        label: "Mandibule",
        placeholder: "ex : Appareil dentaire", 
        title: "Veuillez renseigner l'état des mandibules"
    }
}
