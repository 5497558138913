import Render from '@Views/root/letters/main.html'
import config from '@Config/index'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { ILetterFilter, IOffice, IConsultation, IInvoice, IPatient, IUser, IBreadcrumbItem, IDocument, IConfiguration } from '@Store/types'
import { IPhone, IAddress } from '@Components/index'
import { breadcrumb, configuration, letter, office, user } from '@Store/modules'

@Render
@Component({
	components: {
		'context-main': ContextMain
	}
})
export class Main extends Vue {

	private content: string

	//#region Getters
	get document(): IDocument {
		return this.models.find(d => { return d.id === this.filter.doc_id})
	}
	get filter(): ILetterFilter {
		return letter.filter
	}
	private get models(): IDocument[] {
		return !!this.configuration ? this.configuration.documents.currents : []
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get invoice(): IInvoice {
		return !!this.filter.invoices ? this.filter.invoices.find(i => { return i.id === this.filter.inv_id }) : undefined
	}
	private get consultation(): IConsultation {
		return !!this.filter.patient ? this.filter.patient.consultations.currents.find(c => { return c.id === this.filter.con_id }) : undefined
	}
	private get office(): IOffice {
		if (!!this.consultation)
			return office.office(this.consultation.ofi_id)

		if (!!this.invoice)
			return office.office(this.invoice.ofi_id)

		return !this.filter.ofi_id ? undefined : office.office(this.filter.ofi_id)
	}
	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	//#endregion

	mounted() {
		letter.resetFilter()
		breadcrumb.updateLinked(true)
		breadcrumb.updateAside(false)
		this.initializeBreadcrumb()
	}

	getContent(): string {
		return this.content
	}

	printDocument(): void {
		if (!this.filter.doc_id || parseInt(this.filter.doc_id) === -1) return

		let filter: Record<string, string> = {}
		if (!!this.filter.pat_id && parseInt(this.filter.pat_id) !== -1) {
			filter['pat_id'] = this.filter.pat_id
		}
		if (!!this.filter.inv_id && parseInt(this.filter.inv_id) !== -1) {
			filter['inv_id'] = this.filter.inv_id
		}
		if (!!this.filter.con_id && parseInt(this.filter.con_id) !== -1) {
			filter['con_id'] = this.filter.con_id
		}
		if (!!this.filter.ofi_id && parseInt(this.filter.ofi_id) !== -1) {
			filter['ofi_id'] = this.filter.ofi_id
		}
		if (this.isDemo) filter['demo'] = "true"

		let params:string = new URLSearchParams(filter).toString()

		let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/print/document/${this.filter.doc_id}.pdf?${params}`
		window.open(url, '_blank').focus()
	}

	@Watch('filter.doc_id')
	@Watch('filter.pat_id')
	@Watch('filter.con_id')
	@Watch('filter.inv_id')
	@Watch('filter.ofi_id')
	updateContent() {
		this.$forceUpdate()
		this.content = !this.document ? '' : this.replaceKeys(this.document.render)
	}

	private replaceContent(content: string, regex: string, value: string): string {
		return content.replace(new RegExp('{'+regex+'}', 'g'), !value ? '' : value)
	}

	private replaceAddressKeys(property: string, address: IAddress, content: string): string {
		if (!content || !address || !property) return content

		let street = `${!address.number ? '' : `${address.number} `}${!address.street ? '' : address.street.trim()}`
		content = this.replaceContent(content, `${property}.adresse`, street)
		content = this.replaceContent(content, `${property}.cp`, address.zipcode)
		content = this.replaceContent(content, `${property}.ville`, address.city)

		return content
	}

	private replacePhoneKeys(property: string, phone: IPhone, content: string): string {
		if (!content || !phone || !property) return content

		content = this.replaceContent(content, `${property}.telephone`, phone.number)

		return content
	}

	private replaceUserKeys(user: IUser, content: string): string {
		if (!content || !user) return content

		content = this.replaceContent(content, 'mon.prenom', this.capitalize(user.coordinates.first_name))
		content = this.replaceContent(content, 'mon.nom', this.capitalize(user.coordinates.last_name))
		content = this.replaceContent(content, 'mon.siren', user.coordinates.siren)
		content = this.replaceContent(content, 'mon.logo', `<img src="${user.img}" width="50" height="50" alt="un logo">`)
		content = this.replaceContent(content, 'mon.adeli', user.coordinates.adeli)
		content = this.replaceContent(content, 'mon.titre', user.coordinates.title)
		// content = this.replaceContent(content, 'mes.notes', user.coordinates.notes);

		return content
	}

	private replaceInvoiceKeys(invoice: IInvoice, content: string): string {
		if (!content || !invoice) return content

		content = this.replaceContent(content, 'facture.numero', invoice.number)
		content = this.replaceContent(content, 'facture.date', this.$i18n.d(invoice.settlement_date, 'l'))
		content = this.replaceContent(content, 'facture.montant', this.$i18n.n(invoice.ttc, 'currency', invoice.currency_i18n))
		content = this.replaceContent(content, 'facture.taux_tva', `${invoice.tva} %`)
		content = this.replaceContent(content, 'facture.montant_tva', this.$i18n.n(invoice.ttc - invoice.ht, 'currency', invoice.currency_i18n))
		content = this.replaceContent(content, 'facture.ht', this.$i18n.n(invoice.ht, 'currency', invoice.currency_i18n))
		content = this.replaceContent(content, 'facture.reglee', invoice.checked ? this.$i18n.t('vm.root.letters.main.state.checked').toString() : this.$i18n.t('vm.root.letters.main.state.unchecked').toString())

		return content
	}

	private replaceConsultationKeys(consultation: IConsultation, content: string) {
		if (!content || !consultation) return content

		content = this.replaceContent(content, 'consultation.date', this.$i18n.d(consultation.act_date, 'l'))
		content = this.replaceContent(content, 'consultation.fin', this.$i18n.d(consultation.end_schedule, 'LT'))
		content = this.replaceContent(content, 'consultation.debut', this.$i18n.d(consultation.start_schedule, 'LT'))

		content = this.replaceContent(content, 'consultation.motif', consultation.reason.join(' '))
		content = this.replaceContent(content, 'consultation.evolution', consultation.examen.evolution)
		content = this.replaceContent(content, 'consultation.observation', consultation.examen.observation)
		content = this.replaceContent(content, 'consultation.diagnostic', consultation.examen.diagnostic)
		content = this.replaceContent(content, 'consultation.tests', consultation.examen.tests)
		content = this.replaceContent(content, 'consultation.traitement', consultation.examen.treatment)
		content = this.replaceContent(content, 'consultation.eva1', (consultation.eva.before / 10).toString())
		content = this.replaceContent(content, 'consultation.eva2', (consultation.eva.after / 10).toString())
		content = this.replaceContent(content, 'consultation.poids', consultation.measurement.size.toString())
		content = this.replaceContent(content, 'consultation.taille', consultation.measurement.weight.toString())

		return content
	}

	private replacePatientKeys(patient: IPatient, content: string): string {
		if (!content || !patient) return content

		content = this.replaceContent(content, 'patient.prenom', this.capitalize(patient.coordinates.first_name))
		content = this.replaceContent(content, 'patient.nom', this.capitalize(patient.coordinates.last_name))
		content = this.replaceContent(content, 'patient.secu', !!patient.coordinates.nir ? patient.coordinates.nir.number : '')
		content = this.replaceContent(content, 'patient.naissance', this.$i18n.d(patient.coordinates.birth_date, 'l'))
		content = this.replaceAddressKeys('patient', patient.coordinates.address, content)

		return content
	}

	private replaceOfficeKeys(office: IOffice, content: string): string {
		if (!content || !office || !office.coordinates) return content

		content = this.replaceAddressKeys('cabinet', office.coordinates.address, content)
		// var currency = ArrayFactory.getPropertyValue(InvoiceFactory.currencies, 'label', office.currency);
		// content = this.replaceContent(content, 'cabinet.devise', currency);
		content = this.replacePhoneKeys('cabinet', office.coordinates.phone1, content)
		content = this.replacePhoneKeys('cabinet', office.coordinates.phone2, content)

		return content
	}

	private replaceGeneralKeys(content: string): string {
		content = this.replaceContent(content, 'date.jour', this.$i18n.d(new Date(), 'l'))

		return content
	}

	private replaceKeys(content: string): string {
		if (!content) return

		content = this.replaceUserKeys(user.currentUser, content)

		content = this.replacePatientKeys(this.filter.patient, content)
		content = this.replaceInvoiceKeys(this.invoice, content)
		content = this.replaceOfficeKeys(this.office, content)
		content = this.replaceConsultationKeys(this.consultation, content)
		content = this.replaceGeneralKeys(content)

		return content
	}

	private capitalize(str: string): string {
		return !!str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.letters').toString() }
		breadcrumb.updateItems([item])
	}
}
