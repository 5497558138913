import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { ICountSupport, ITicket, IFaq, IHolidays, IChangeLog, TicketLoadPayload, CommentSentPayload } from "@Store/types"
import { RequestStatus, TicketState } from "@Enums/index"
import { SupportService } from "@Services/index"

@Module({
	name: 'support',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class SupportModule extends VuexModule {
	count: ICountSupport = {
        unreadComments: 0,
        unreadClosedComments: 0,
        closed_count: 0
    }
    status: RequestStatus = RequestStatus.None
    statusSending: RequestStatus = RequestStatus.None
    statusCreating: RequestStatus = RequestStatus.None
    statusFaq: RequestStatus = RequestStatus.None
    statusChangeLog: RequestStatus = RequestStatus.None
    statusOpened: RequestStatus = RequestStatus.None
    statusClosed: RequestStatus = RequestStatus.None
    ticket: ITicket = null
    faq: IFaq[] = []
    holidays: IHolidays = null
    changeLog: IChangeLog = {version: '', log: ''}
    ticketsOpened: ITicket[] = []
	ticketsClosed: ITicket[] = []

	get isClosedLoading(): boolean {
		return this.statusClosed === RequestStatus.Loading
	}
	get isOpenedLoading(): boolean {
		return this.statusOpened === RequestStatus.Loading
	}
	get isTicketClosed(): boolean {
		return !!this.ticket && this.ticket.state === TicketState.Closed
	}
	get isPosting() {
		return this.statusSending === RequestStatus.Loading
	}
	get isLoading() {
		return this.status === RequestStatus.Loading
	}
	get hasUnreadTicket(): boolean {
        return this.unreadCommentCount > 0 || this.unreadClosedCommentCount > 0
    }
    get unreadCommentCount(): number {
        return this.count.unreadComments || 0
    }
    get unreadClosedCommentCount(): number {
        return this.count.unreadClosedComments || 0
    }
    get ticketsOpenedCount(): number {
        return this.ticketsOpened.length
	}

	@Mutation
	clear() {
		this.count = {
			unreadComments: 0,
			unreadClosedComments: 0,
			closed_count: 0
		},
		this.status = RequestStatus.None
		this.statusCreating = RequestStatus.None
		this.statusSending = RequestStatus.None
		this.statusFaq = RequestStatus.None
		this.statusOpened = RequestStatus.None
		this.statusClosed = RequestStatus.None
		this.ticket = null
		this.faq = []
		this.holidays = null
		this.changeLog = {version: '', log: ''}
		this.ticketsOpened = []
		this.ticketsClosed = []
	}

	@Mutation
	unreadTickets(result:{unread_count: number, closed_unread_count: number, closed_count: number}) {
		this.count.unreadComments = result.unread_count
		this.count.unreadClosedComments = result.closed_unread_count
		this.count.closed_count = result.closed_count
	}

	@Mutation
	ticketRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	ticketSuccess(ticket: ITicket) {
		this.status = RequestStatus.Success
		this.ticket = ticket
	}

	@Mutation
	ticketFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	createRequest() {
		this.statusCreating = RequestStatus.Loading
	}

	@Mutation
	createSuccess(ticket: ITicket) {
		this.statusCreating = RequestStatus.Success
		this.ticketsOpened.push(ticket)
		this.ticket = ticket
	}

	@Mutation
	createFailure() {
		this.statusCreating = RequestStatus.Failed
	}

	@Mutation
	sendRequest() {
		this.statusSending = RequestStatus.Loading
	}

	@Mutation
	sendSuccess(comment: string) {
		this.statusSending = RequestStatus.Success
		this.ticket.comments.push({content: comment.replace(/\n/g, '<br>'), admin: false, date_create: new Date()})
	}

	@Mutation
	sendFailure() {
		this.statusSending = RequestStatus.Failed
	}

	@Mutation
	changeLogRequest() {
		this.statusChangeLog = RequestStatus.Loading
	}

	@Mutation
	changeLogSuccess(changeLog: IChangeLog) {
		this.statusChangeLog = RequestStatus.Success
		this.changeLog = changeLog
	}

	@Mutation
	changeLogFailure() {
		this.statusChangeLog = RequestStatus.Failed
	}

	@Mutation
	faqRequest() {
		this.statusFaq = RequestStatus.Loading
	}

	@Mutation
	faqSuccess(faq: IFaq[]) {
		this.statusFaq = RequestStatus.Success
		this.faq = faq
	}

	@Mutation
	faqFailure() {
		this.statusFaq = RequestStatus.Failed
		this.faq = []
	}

	@Mutation
	holidaysSuccess(holidays: IHolidays | false) {
		this.statusFaq = RequestStatus.Success
		this.holidays = !!holidays ? holidays : null
	}

	@Mutation
	ticketsOpenedRequest() {
		this.statusOpened = RequestStatus.Loading
	}

	@Mutation
	ticketsOpenedSuccess(tickets: ITicket[]) {
		this.statusOpened = RequestStatus.Success
		this.ticketsOpened = tickets
	}

	@Mutation
	ticketsOpenedFailure() {
		this.statusOpened = RequestStatus.Failed
		this.ticketsOpened = []
	}

	@Mutation
	ticketsClosedRequest() {
		this.statusClosed = RequestStatus.Loading
	}

	@Mutation
	ticketsClosedSuccess(tickets: ITicket[]) {
		this.statusClosed = RequestStatus.Success
		this.ticketsClosed = tickets
	}

	@Mutation
	ticketsClosedFailure() {
		this.statusClosed = RequestStatus.Failed
		this.ticketsClosed = []
	}

	@Action({rawError: true})
	async loadCount(): Promise<any> {
		let service = new SupportService()
		return service.getNotificationSupport()
		.then(result => { return Promise.resolve(result) })
		.catch(error => { return Promise.reject(error) })
	}

	@Action({rawError: true})
	async loadChangeLog(): Promise<any> {
		if (this.statusChangeLog === RequestStatus.Loading) return

		this.changeLogRequest()

		let service = new SupportService()
		return service.getChangeLog()
		.then(
			changeLog => {
				this.changeLogSuccess(changeLog)
				return Promise.resolve(changeLog)
			}
		)
		.catch(error => {
			this.changeLogFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadFaq(): Promise<any> {
		if (this.statusFaq === RequestStatus.Loading) return

		this.faqRequest()

		let service = new SupportService()
		return service.getFaqList()
		.then(
			result => {
				this.faqSuccess(result.faq)
				this.holidaysSuccess(result.holidays)
				return Promise.resolve(result.faq)
			}
		)
		.catch(error => {
			this.faqFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadOpenedTickets(): Promise<any> {
		if (this.statusOpened === RequestStatus.Loading) return

		this.ticketsOpenedRequest()

		let service = new SupportService()
		return service.getOpenedTicketList()
		.then(
			tickets => {
				this.ticketsOpenedSuccess(tickets)
				return Promise.resolve(tickets)
			}
		)
		.catch(error => {
			this.ticketsOpenedFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadClosedTickets(): Promise<any> {
		if (this.statusClosed === RequestStatus.Loading) return

		this.ticketsClosedRequest()

		let service = new SupportService()
		return service.getClosedTicketList()
		.then(
			tickets => {
				this.ticketsClosedSuccess(tickets)
				return Promise.resolve(tickets)
			}
		)
		.catch(error => {
			this.ticketsClosedFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadTicket(payload: TicketLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.ticketRequest()

		let service = new SupportService()
		return service.getTicket(payload.sup_id)
		.then(
			ticket => {
				this.ticketSuccess(ticket)
				return Promise.resolve(ticket)
			}
		)
		.catch(error => {
			this.ticketFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async sendComment(payload: CommentSentPayload): Promise<any> {
		if (this.statusSending === RequestStatus.Loading) return

		this.sendRequest()

		let service = new SupportService()
		return service.sendComment(payload.sup_id, payload.comment)
		.then(
			() => {
				this.sendSuccess(payload.comment)
				return Promise.resolve()
			}
		)
		.catch(error => {
			this.sendFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async createTicket(ticket: ITicket): Promise<any> {
		if (this.statusCreating === RequestStatus.Loading) return

		this.createRequest()

		let service = new SupportService()
		return service.createTicket(ticket)
		.then(
			newTicket => {
				this.createSuccess(newTicket)
				return Promise.resolve(newTicket)
			}
		)
		.catch(error => {
			this.createFailure()
			return Promise.reject(error)
		})
	}
}

export const support = getModule(SupportModule)
// export const support = new SupportModule({ store, name: "support" })