import { cloneDeep } from 'lodash'
import base64 from 'base-64'
import utf8 from 'utf8'
import { PlanningType } from "@Enums/index"
import { FullCalendarEventHelper } from "./fullcalendar-event"
import { DateHelper } from "./date"
import { IEvent, IPatient } from "@Store/types"

export class GoogleEventHelper {
    public static convertEventToGoogleItem(event: IEvent): any {
        if (event === undefined) return

        let item = {
            id: event.id,
            summary: event.label,
            status: 'confirmed',
            description: `osteo2ls:${GoogleEventHelper.base64Encode({
                ofi_id: event.ofi_id,
                pla_id: event.pla_id,
                ses_id: event.ses_id,
                missed: event.missed,
                confirmation_type: event.confirmation_type,
                reminder_type: event.reminder_type,
                reminder_mail: event.reminder_mail,
                reminder_sms: event.reminder_sms,
            })}`,
            attendees: new Array<IGoogleAttendee>(),
            start: null,
            end: null,
        }

        if (!!event.patient) {
            let patientEvent: IPatient = GoogleEventHelper.cleanPatient(event.patient)

            let patient = {
                id: patientEvent.id,
                email: patientEvent.coordinates.email || "undefined@osteo2ls-patient.com",
                displayName: `${patientEvent.coordinates.last_name} ${patientEvent.coordinates.first_name}`,
                comment: `osteo2ls:${GoogleEventHelper.base64Encode(patientEvent)}`
            }
            item.attendees.push(patient)
            // item.description += `;patient_${patient.comment}`
        }

        if (event.all_day) {
            let endDate = new Date(event.end_date || event.start_date)
            endDate.setDate(endDate.getDate() + 1)

            item.start = {
                date: DateHelper.getDateString(new Date(event.start_date)),
                dateTime: null,
                timeZone: "UTC"
            }
            item.end = {
                date: DateHelper.getDateString(endDate),
                dateTime: null,
                timeZone: "UTC"
            }
        } else {
            item.start = {
                date: null,
                dateTime: `${new Date(event.start_date).toISOString().split('.')[0]}Z`,
                timeZone: "UTC"
            }
            item.end = {
                date: null,
                dateTime: `${new Date(event.end_date).toISOString().split('.')[0]}Z`,
                timeZone: "UTC"
            }
        }

        return item
    }

    public static convertResultSearch(event: IGoogleEvent): ISearchEvent {
        let entry = FullCalendarEventHelper.convertEventToFullCalendarEvent(event)
        if (!entry || !entry.patient) return

        return {
            'id': entry.id,
            'last_name': entry.patient.coordinates.last_name,
            'first_name': entry.patient.coordinates.first_name,
            'email': entry.patient.coordinates.email,
            'date': new Date(entry.start),
            'pla_id': entry.pla_id,
            'type': PlanningType.Google,
            'enabled': true
        }
    }

    private static cleanPatient(patient: IPatient): IPatient {
        let cleanPatient: IPatient = cloneDeep(patient)
        cleanPatient.coordinates.birth_date = undefined
        cleanPatient.coordinates.notes = undefined
        cleanPatient.coordinates.phone2 = undefined
        cleanPatient.coordinates.address = undefined
        cleanPatient.coordinates.level = undefined
        cleanPatient.coordinates.number_of_children = undefined
        cleanPatient.coordinates.situation = undefined
        cleanPatient.coordinates.professions = undefined
        cleanPatient.coordinates.reference = undefined
        cleanPatient.coordinates.habitus = undefined
        cleanPatient.coordinates.family_antecedents = undefined
        cleanPatient.coordinates.nir = undefined
        cleanPatient.coordinates.colleagues = undefined
        cleanPatient.coordinates.forms = undefined
        cleanPatient.reasons = undefined
        cleanPatient.consultations = undefined
        cleanPatient.attachments = undefined
        cleanPatient.antecedents = undefined
        cleanPatient.spheres = undefined
        cleanPatient.pediatrics = undefined
        cleanPatient.rabbits = undefined
        cleanPatient.unpaids = undefined
        cleanPatient.relaunch = undefined
        cleanPatient.family = undefined
        cleanPatient.read_date = undefined

        return cleanPatient
    }

    private static base64Encode(value: any): string {
        let text = JSON.stringify(value)
        let bytes = utf8.encode(text)
        return base64.encode(bytes)
	}
}

export interface ISearchEvent {
    id: string,
    last_name: string,
    first_name: string,
    email: string,
    date: Date,
    pla_id: string,
    type: PlanningType,
    enabled: boolean
}

export interface IGoogleEvent {
    etag: string,
    id: string,
    status: string,
    calendar: any,
    summary: string,
    description: string,
    location: string,
    colorId: string,
    start: IGoogleDate,
    end: IGoogleDate,
    attendees: Array<IGoogleAttendee>,
    // kind: "calendar#event",
    // "htmlLink": string,
    // "created": datetime,
    // "updated": datetime,
    // "creator": {
    //   "id": string,
    //   "email": string,
    //   "displayName": string,
    //   "self": boolean
    // },
    // "organizer": {
    //   "id": string,
    //   "email": string,
    //   "displayName": string,
    //   "self": boolean
    // },
    // "endTimeUnspecified": boolean,
    // "recurrence": [
    //   string
    // ],
    // "recurringEventId": string,
    // "originalStartTime": {
    //   "date": date,
    //   "dateTime": datetime,
    //   "timeZone": string
    // },
    // "transparency": string,
    // "visibility": string,
    // "iCalUID": string,
    // "sequence": integer,
    // "attendeesOmitted": boolean,
    // "extendedProperties": {
    //   "private": {
    //     (key): string
    //   },
    //   "shared": {
    //     (key): string
    //   }
    // },
    // "hangoutLink": string,
    // "conferenceData": {
    //   "createRequest": {
    //     "requestId": string,
    //     "conferenceSolutionKey": {
    //       "type": string
    //     },
    //     "status": {
    //       "statusCode": string
    //     }
    //   },
    //   "entryPoints": [
    //     {
    //       "entryPointType": string,
    //       "uri": string,
    //       "label": string,
    //       "pin": string,
    //       "accessCode": string,
    //       "meetingCode": string,
    //       "passcode": string,
    //       "password": string
    //     }
    //   ],
    //   "conferenceSolution": {
    //     "key": {
    //       "type": string
    //     },
    //     "name": string,
    //     "iconUri": string
    //   },
    //   "conferenceId": string,
    //   "signature": string,
    //   "notes": string,
    //   "gadget": {
    //   "type": string,
    //   "title": string,
    //   "link": string,
    //   "iconLink": string,
    //   "width": integer,
    //   "height": integer,
    //   "display": string,
    //   "preferences": {
    //     (key): string
    //   }
    // },
    // "anyoneCanAddSelf": boolean,
    // "guestsCanInviteOthers": boolean,
    // "guestsCanModify": boolean,
    // "guestsCanSeeOtherGuests": boolean,
    // "privateCopy": boolean,
    // "locked": boolean,
    // "reminders": {
    //   "useDefault": boolean,
    //   "overrides": [
    //     {
    //       "method": string,
    //       "minutes": integer
    //     }
    //   ]
    // },
    // "source": {
    //   "url": string,
    //   "title": string
    // },
    // "attachments": [
    //   {
    //     "fileUrl": string,
    //     "title": string,
    //     "mimeType": string,
    //     "iconLink": string,
    //     "fileId": string
    //   }
    // ]
}

export interface IGoogleAttendee {
    id: string,
    email: string,
    displayName: string,
    comment: string,
    organizer?: boolean,
    self?: boolean,
    resource?: boolean,
    optional?: boolean,
    responseStatus?: string,
    additionalGuests?: number
}

export interface IGoogleDate {
    date?: string,
    dateTime?: string,
    timeZone?: string
}
