<section class="shop-option" :class="{'is-main': isUnlimited || isSubscription}">
	<h2 class="text-center text-uppercase-first-letter" :class="{'text-md': !isUnlimited && !isSubscription}">{{ pack.id | pack }}</h2>
	<template v-if="isSupport">
		<i18n path="vm.root.shop.packs.support.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.support.text1.strong') }}</strong>
		</i18n>
		<i18n path="vm.root.shop.packs.support.text2.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.support.text2.strong') }}</strong>
		</i18n>
	</template>
	<template v-else-if="isUnlimited">
		<i18n path="vm.root.shop.packs.unlimited.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.unlimited.text1.strong') }}</strong>
		</i18n>
		<p>{{ $t('vm.root.shop.packs.unlimited.text2') }}</p>
	</template>
	<template v-else-if="isSubscription">
		<i18n path="vm.root.shop.packs.subscription.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.subscription.text1.strong') }}</strong>
		</i18n>
		<p>{{ $t('vm.root.shop.packs.subscription.text2') }}</p>
	</template>
	<template v-else-if="isPlanning">
		<p>{{ $t('vm.root.shop.packs.planning.text1') }}</p>
		<p>{{ $t('vm.root.shop.packs.planning.text2') }}</p>
		<p><a href="https://osteo2ls.com/rdv/vous-etes-osteo.html" :title="$t('vm.root.shop.packs.planning.link-title')" target="_blank">
			{{ $t('vm.root.shop.packs.planning.link-label') }}
		</a></p>
	</template>
	<template v-else-if="isCard">
		<p>{{ $t('vm.root.shop.packs.card.text1') }}</p>
		<p>{{ $t('vm.root.shop.packs.card.text2') }}</p>
	</template>
	<template v-else-if="isSms">
		<i18n path="vm.root.shop.packs.sms.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.sms.text1.strong') }}</strong>
		</i18n>
		<i18n path="vm.root.shop.packs.sms.text2.content" tag="p">
			<router-link :to="{name: 'patients-campaigns'}" :title="$t('vm.root.shop.packs.sms.text2.link-title')">
				{{ $t('vm.root.shop.packs.sms.text2.link-label') }}
			</router-link>
		</i18n>
		<i18n path="vm.root.shop.packs.sms.text3.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.sms.text3.strong') }}</strong>
		</i18n>
	</template>
	<template v-else-if="isImport">
		<i18n path="vm.root.shop.packs.import.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.import.text1.strong') }}</strong>
		</i18n>
		<p>{{ $t('vm.root.shop.packs.import.text2') }}</p>
	</template>
	<template v-else-if="isSite">
		<i18n path="vm.root.shop.packs.site.text1.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.site.text1.strong') }}</strong>
		</i18n>
		<i18n path="vm.root.shop.packs.site.text2.content" tag="p">
			<strong>{{ $t('vm.root.shop.packs.site.text2.strong1') }}</strong>
			<strong>{{ $t('vm.root.shop.packs.site.text2.strong2') }}</strong>
		</i18n>
	</template>

	<footer class="text-center">
		<button v-if="!isSite" class="btn" :class="isUnlimited || isSubscription ? 'btn-primary' : 'btn-default'"
			@click="goTo(pack.id)" :title="$t('vm.root.shop.pack-item.go-to-title')">
			<span :class="{'text-md': isUnlimited || isSubscription}">
				<span v-if="manyPrice">{{ $t('vm.root.shop.pack-item.as-of') }}</span>
				{{ defaultPrice }}&nbsp;€
				<span v-if="pack.recurrent">/ {{ $t('vm.root.shop.pack-item.month') }}</span>
			</span>
		</button>
		<a v-else href="https://www.osteo2ls.simplebo.net/?sbmcn=OSTEO2LS_EP&utm_campaign=Osteo2ls+espace+prive&utm_source=Osteo2ls&utm_medium=Site+internet+public&job=f666c51d71c62ccf2e34ddaf" target="_blank" class="btn btn-default">
			{{ $t('vm.root.shop.pack-item.choice-offers') }}
		</a>
	</footer>
</section>
