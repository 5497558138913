import Render from '@Views/root/statistics/patients/patients.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, StatisticsActionPayload, IStatisticsFilter } from '@Store/types'
import { Statistics, Debouncer } from '@Models/index'
import { DateHelper } from '@Helpers/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class Patients extends Vue {

	private first: boolean = true
	private stats: IStatsPatients = null
	private debouncerEvolution1: Debouncer = null
	private debouncerEvolution2: Debouncer = null

	// @State('statistics') stateStatistics: StatisticsState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Action('statistics/loadPatientStatistics') loadPatientStatistics: (payload: StatisticsActionPayload) => Promise<any>

	//#region Getters
	get filter(): IStatisticsFilter {
        return statistics.filter
	}
	get isLoading(): boolean {
        return this.first && statistics.isLoading
	}
	get total(): number {
		return !!this.ratio && this.ratio.length > 0 ? Math.abs(this.ratio.reduce((prev, next) => prev + next)) : 0
	}
	get legends(): {label: string, color: string}[] {
		return [
			{label: this.$i18n.t('vm.root.statistics.patients.main.legends.first').toString(), color: '#008FFB'},
			{label: this.$i18n.t('vm.root.statistics.patients.main.legends.second').toString(), color: '#DB208F'},
			{label: this.$i18n.t('vm.root.statistics.patients.main.legends.third').toString(), color: '#20DB5B'}
		]
	}
	get evolutionSeries1(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.manEvolution1
		}, {
			name: this.legends[1].label,
			data: this.femaleEvolution1
		}, {
			name: this.legends[2].label,
			data: this.otherEvolution1
		}]
    }
	get evolutionSeries2(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.manEvolution2
		}, {
			name: this.legends[1].label,
			data: this.femaleEvolution2
		}, {
			name: this.legends[2].label,
			data: this.otherEvolution2
		}]
    }
    get evolutionChartOptions(): any {
		return {
            chart: {
                type: 'area',
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				categories: DateHelper.getMonthsName()
			},
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			}
        }
    }
	get pyramidSeries(): any[] {
		return [{
			name: this.legends[0].label,
			data: this.manAge
		}, {
			name: this.legends[1].label,
			data: this.femaleAge
		}, {
			name: this.legends[2].label,
			data: this.otherAge
		}]
	}
	get pyramidChartOptions(): any {
		return {
			chart: {
				type: 'bar',
				stacked: true
			},
			plotOptions: {
				bar: {
					horizontal: true,
				}
			},
			colors: this.legends.map(l => { return l.color }),
			dataLabels: {
				enabled: false
			},
			legend: {
				show: false
			},
			xaxis: {
				categories: [
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category1').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category2').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category3').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category4').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category5').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category6').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category7').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category8').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category9').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category10').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.age-chart.category11').toString()
				],
				labels: {
					formatter: (val: number) => { return Math.abs(Math.round(val)) }
				}
			},
			tooltip: {
				y: {
					formatter: (val: number) => { return Math.abs(Math.round(val)) }
				}
			}
		}
	}

	get pediatrySeries(): any[] {
		return [{
			name: this.legends[0].label,
			data: this.manPediatry
		}, {
			name: this.legends[1].label,
			data: this.femalePediatry
		}, {
			name: this.legends[2].label,
			data: this.otherPediatry
		}]
	}
	get pediatryChartOptions(): any {
		return {
			chart: {
				type: 'bar',
				stacked: true
			},
			plotOptions: {
				bar: {
					horizontal: true,
				}
			},
			dataLabels: {
				enabled: false
			},
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			},
			xaxis: {
				categories: [
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category1').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category2').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category3').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category4').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category5').toString(),
					this.$i18n.t('vm.root.statistics.patients.main.pediatry-chart.category6').toString()
				],
				labels: {
					formatter: (val: number) => { return Math.abs(Math.round(val)) }
				}
			}
		}
	}

	get ratioSeries(): number[] {
		return this.ratio
	}
	get ratioChartOptions(): any {
		return {
			chart: {
				type: 'donut'
			},
			dataLabels: {
				enabled: false
			},
			labels: this.legends.map(l => { return l.label }),
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			},
			tooltip: {
				y: {
					formatter: (val: number) => {
						let percent: number = Math.round(val * 100 / this.total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}

	private get manEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._0 ? [] : this.stats.evolution1._0
	}
	private get femaleEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._1 ? [] : this.stats.evolution1._1
	}
	private get otherEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._2 ? [] : this.stats.evolution1._2
	}
	private get manEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._0 ? [] : this.stats.evolution2._0
	}
	private get femaleEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._1 ? [] : this.stats.evolution2._1
	}
	private get otherEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._2 ? [] : this.stats.evolution2._2
	}
	private get manAge(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[0]._0 ? [] : this.stats.ages[0]._0
	}
	private get femaleAge(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[0]._1 ? [] : this.stats.ages[0]._1.map(v => { return -v })
	}
	private get otherAge(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[0]._2 ? [] : this.stats.ages[0]._2.map(v => { return -v })
	}
	private get manPediatry(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[1]._0 ? [] : this.stats.ages[1]._0
	}
	private get femalePediatry(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[1]._1 ? [] : this.stats.ages[1]._1.map(v => { return -v })
	}
	private get otherPediatry(): number[] {
		return !this.stats || !this.stats.ages || !this.stats.ages[1]._2 ? [] : this.stats.ages[1]._2.map(v => { return -v })
	}
	private get ratio(): number[] {
		return [
			!this.manAge || this.manAge.length === 0 ? 0 : Math.abs(this.manAge.reduce((prev, next) => prev + next)),
			!this.femaleAge || this.femaleAge.length === 0 ? 0 : Math.abs(this.femaleAge.reduce((prev, next) => prev + next)),
			!this.otherAge || this.otherAge.length === 0 ? 0 : Math.abs(this.otherAge.reduce((prev, next) => prev + next))
		]
	}
	//#endregion

	mounted() {
		this.debouncerEvolution1 = new Debouncer(this.updateEvolution1, 500)
		this.debouncerEvolution2 = new Debouncer(this.updateEvolution2, 500)
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'patients')
		this.initializeBreadcrumb()

		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			year1: this.filter.year1,
			year2: this.filter.year2,
			update: false
		}
		statistics.loadPatientStatistics(payload)
		.then((stats) => {
			this.stats = stats
			this.first = false
		})
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerEvolution1)
		Debouncer.clear(this.debouncerEvolution2)
	}

	getTotalSeries1(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries1.find(e => { return e.name === legend}))
	}

	getTotalSeries2(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries2.find(e => { return e.name === legend}))
	}

	@Watch('filter.usr_id')
	@Watch('filter.ofi_id')
	updateYears() {
		this.updateStatistics(this.filter.year1, this.filter.year2)
	}

	@Watch('filter.year1')
	updateYear1() {
		this.debouncerEvolution1.start()
	}

	@Watch('filter.year2')
	updateYear2() {
		this.debouncerEvolution2.start()
	}

	private updateStatistics(year1: number, year2: number) {
		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			year1: !!year1 ? year1 : -1,
			year2: !!year2 ? year2 : -1,
			update: true
		}
		statistics.loadPatientStatistics(payload)
		.then((stats) => {
			if (!!year1) this.stats.evolution1 = stats.evolution1
			if (!!year2) this.stats.evolution2 = stats.evolution2
		})
	}

	private updateEvolution1() {
		this.updateStatistics(this.filter.year1, undefined)
	}

	private updateEvolution2() {
		this.updateStatistics(undefined, this.filter.year2)
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString() }
		breadcrumb.updateItems([item])
	}
}

interface IStatsPatients {
	evolution1: {
		_0: number[],
		_1: number[],
		_2: number[],
	},
	evolution2: {
		_0: number[],
		_1: number[],
		_2: number[],
	},
	ages: {
		_0: number[],
		_1: number[],
		_2: number[],
	}[],
	references: {
		text: string,
		weight: number
	}[]
}
