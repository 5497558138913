<div v-if="consultation">

	<collapsible-help id="follow-up-consultation-information" :title="$t('vm.root.patients.patient.follow-up.title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.patients.patient.follow-up.help1') }}
			</p>
			<p class="text-left">
				{{ $t('vm.root.patients.patient.follow-up.help2') }}
			</p>
			<i18n path="vm.root.patients.patient.follow-up.help3.text" tag="p" class="text-left">
				<strong>{{ $t('vm.root.patients.patient.follow-up.help3.strong') }}</strong>
			</i18n>
			<i18n path="vm.root.patients.patient.follow-up.help4.text" tag="p" class="text-left">
				<strong>{{ $t('vm.root.patients.patient.follow-up.help4.strong') }}</strong>
				<span class="text-xs">{{ $t('vm.root.patients.patient.follow-up.help4.span') }}</span>
			</i18n>
		</div>
	</collapsible-help>

	<read-only v-if="readOnly" :follow-up="followUp"/>
	<div v-else>
		<div v-if="isSended" class="grid-2">
			<togglebox-frame id="level" :items="levelItems" v-model="followUp.level"
				:label="$t('vm.root.patients.patient.follow-up.level.label')"
				:title="$t('vm.root.patients.patient.follow-up.level.title')"/>

			<textarea-frame id="follow-up-patient" v-model="followUp.back" rows="1" :readonly="true"
				:label="$t('vm.root.patients.patient.follow-up.follow-up-patient.label')"
				:placeholder="$t('vm.root.patients.patient.follow-up.follow-up-patient.placeholder')"
				:title="$t('vm.root.patients.patient.follow-up.follow-up-patient.title')"/>

			<switch-frame id="follow-up-read" v-model="followUp.read" class="grid-item-full"
				:title="$t('vm.root.patients.patient.follow-up.follow-up-read.title')">
				{{ $t('vm.root.patients.patient.follow-up.follow-up-read.label') }}
			</switch-frame>
		</div>
		<div v-else>
			<dropdown-frame id="follow-up-type" v-model="followUp.type" :items="followUpItems"
				:label="$t('vm.root.patients.patient.follow-up.follow-up-type.label')"
				:title="$t('vm.root.patients.patient.follow-up.follow-up-type.title')" />

			<input-frame v-if="isFollowUpActive" type="number" id="follow-up-delay" v-model.number="followUp.delay"
				:disabled="disabled" class="grid-item-full" min="1" max="400"
				:label="$tc('vm.root.patients.patient.follow-up.follow-up-delay.label', followUp.delay)"
				:placeholder="$t('vm.root.patients.patient.follow-up.follow-up-delay.placeholder')"
				:error-test="hasDelayError" error-msg="$t('vm.root.patients.patient.follow-up.follow-up-delay.error')"
				:title="$t('vm.root.patients.patient.follow-up.follow-up-delay.title')"/>
		</div>
	</div>
</div>
