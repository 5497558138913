<div class="input-frame">
    <div class="switchbox" :class="switchClass">
        <input type="checkbox" :id="id" :title="title" v-model="checked" :disabled="disabled" :readonly="readonly">
        <label :for="id" :disabled="disabled">
            <svg width="1em" class="switch-yes" :viewBox="checkedViewBox" aria-hidden="true">
                <use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${checkedIcon}`"/>
            </svg>
            <svg width="1em" class="switch-no" viewBox="0 0 320 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
            </svg>
            <span class="sr-only">{{title}}</span>
        </label>
    </div>
    <label :for="id"><slot/></label>
</div>
