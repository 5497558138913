import Render from '@Views/root/co-working/aside-share.html'
import { Component, Vue } from 'vue-property-decorator'
import { Profile } from '@Enums/index'
import { coworking } from '@Store/modules'

@Render
@Component({})
export class ASideShare extends Vue {

	get currentStep(): number {
		return coworking.step
	}
	get profile(): Profile {
		return coworking.profile
	}
}