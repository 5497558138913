export const searchColleague = {
    'new-praticien': {
        text: "créer un {0} ?",
        button: "nouveau praticien",
        title: "Créer un praticien"
    },
    search: {
        searching: "recherche en cours…",
        'no-result': "aucun résultat"
    }
}