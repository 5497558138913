<div v-if="!!bulk">
	<i18n v-if="user.license === -1" path="vm.root.shop.bulk.main.step4.balance-received.content" tag="p">
		<template v-slot:percent>
			<span>{{ percent }}</span>
		</template>
		<template v-slot:strong>
			<strong>{{ $t('vm.root.shop.bulk.main.step4.balance-received.strong') }}</strong>
		</template>
		<template v-slot:link>
			<router-link :to="{name: 'shop'}" :title="$t('vm.root.shop.bulk.main.step4.balance-received.link.title')">
				{{ $t('vm.root.shop.bulk.main.step4.balance-received.link.label') }}
			</router-link>
		</template>
	</i18n>
	<i18n v-else path="vm.root.shop.bulk.main.step4.not-finalized" tag="p">
		<strong>{{ $t('vm.root.shop.bulk.main.step4.balance-received.strong') }}</strong>
		<router-link :to="{name: 'shop'}" :title="$t('vm.root.shop.bulk.main.step4.balance-received.link.title')">
			{{ $t('vm.root.shop.bulk.main.step4.balance-received.link.label') }}
		</router-link>
	</i18n>
</div>
