<div class="dropdown" :class="[{'is-active': open}, ddbClass]" v-click-outside="clickedOutside">
    <button type="button" class="select-like form-group-input" :class="{'is-active': open }"
        :title="title" :id="id" :readonly="readonly" :disabled="disabled"
        @keydown="keyboardNav" @keydown.esc="reset" @click="toggleDropdown"
        aria-haspopup="listbox" :aria-controls="`${id}Menu`" :aria-expanded="open">
        <svg width="1em" class="select-like-label svg-block" :class="{'svg-flag': flag}" viewBox="0 0 512 512" role="img">
            <use v-if="flag" :xlink:href="`${require('@Assets/svg/flag-defs.svg')}#${getIcon(activeItem)}`"/>
            <use v-else :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIcon(activeItem)}`"/>
        </svg>
        <svg width="1em" class="select-like-caret" viewBox="0 0 448 512" aria-hidden="true">
            <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
        </svg>
    </button>

    <div class="dropdown-menu is-stuck-left" :id="`${id}Menu`" :aria-labelledby="id" :aria-hidden="!open"
        @keydown="keyboardNav" @keydown.esc="reset">

		<button type="button" @click="toggleDropdown" class="dropdown-menu-backdrop" :title="$t('general.close.title')">
			<span class="sr-only">{{$t('general.close.text')}}</span>
		</button>

        <ul class="dropdown-menu-list" ref="list">
            <li v-for="(item, index) in items" :key="item.value" :class="getItemClass(index, item.value)">
                <button type="button" class="dropdown-menu-item" :title="item.label"
                    :class="{'is-selected': isSelected(item.value)}" @click="choose(item)">
                    <span class="dropdown-menu-item-icon">
                        <svg width="1em" class="svg-block" :class="{'svg-flag': flag}" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                            <use v-if="flag" :xlink:href="`${require('@Assets/svg/flag-defs.svg')}#${getIcon(item)}`"/>
                            <use v-else :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIcon(item)}`"/>
                        </svg>
                    </span>
					<span class="dropdown-menu-item-label">{{item.label}}</span>
					<span v-if="item.other" class="dropdown-menu-item-value">{{ item.other }}</span>
                </button>
            </li>
        </ul>
    </div>
</div>
