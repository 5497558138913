import Render from '@Views/root/user/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { ASideMain } from './aside-main'
import { ContextMain } from './context-main'
import { ContextReferral } from './context-referral'
import { IUser } from '@Store/types'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-main': ASideMain,
		'context-main': ContextMain,
		'context-referral': ContextReferral
	}
})
export class Main extends Vue {

	private item: string = ''
	get user(): IUser {
		return user.user
	}
	get isReferral(): boolean {
		return this.item === "referral"
	}

	mounted() {
		breadcrumb.updateLinked(true)
	}

	onNavigate(item: string): void {
		this.item = item
	}
}
