import Render from '@Views/root/statistics/main.html'
import { Component, Vue } from 'vue-property-decorator'
import { ASideMain } from './aside-main'
import { ContextPatients } from './patients/context-patients'
import { ContextConsultations } from './consultations/context-consultations'
import { ContextAccounting } from './accounting/context-accounting'
import { ContextColleagues } from './colleagues/context-colleagues'
import { ContextPlanning } from './planning/context-planning'
import { ContextPlanningOnline } from './planning-online/context-planning'

@Render
@Component({
	components: {
		'aside-main': ASideMain,
		'context-patients': ContextPatients,
		'context-consultations': ContextConsultations,
		'context-planning': ContextPlanning,
		'context-planning-online': ContextPlanningOnline,
		'context-colleagues': ContextColleagues,
		'context-accounting': ContextAccounting
	}
})
export class Main extends Vue {

	private item: string = null

	get isPatientsView(): boolean {
		return this.item === 'patients'
	}
	get isConsultationsView(): boolean {
		return this.item === 'consultations'
	}
	get isPlanningView(): boolean {
		return this.item === 'planning'
	}
	get isPlanningOnlineView(): boolean {
		return this.item === 'planning-online'
	}
	get isAccountingView(): boolean {
		return this.item === 'accounting'
	}
	get isColleaguesView(): boolean {
		return this.item === 'colleagues'
	}
	get canDisplayContext(): boolean {
		return this.item !== 'top10' && this.item !== 'reference'
	}

	onNavigate(item: string): void {
		this.item = item
	}
}
