import Render from '@Views/components/input/input-color.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Debouncer } from '@Models/index'

@Render
@Component({})
export class InputColor extends Vue {

    private debouncer: Debouncer
    private color: string

    @Prop() id: string
    @Prop() title: string
    @Prop() value: string
    @Prop() placeholder: string
    @Prop({default: false}) disabled: boolean

    mounted() {
        this.debouncer = new Debouncer(this.updateValue, 50)
    }

    beforeDestroy() {
        Debouncer.clear(this.debouncer)
    }

    handleInput(e: any) {
        this.color = e
        this.debouncer.start()    
    }

    updateValue() {
        if (!this.color) return
        
        this.$emit('input', this.color)
    }
}
