import Render from '@Views/root/user/gift/gift1.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOffice, IBreadcrumbItem, IUser, IGift, IRatialBar } from '@Store/types'
import { GravatarService } from '@Services/index'
import { Gravatar } from '@Models/index'
import { Office } from '@Models/index'
import { GiftState } from '@Enums/index'
import { breadcrumb, office, user } from '@Store/modules'

@Render
@Component({})
export class Gift1 extends Vue {

	hasAvatar: boolean = false
	private service: GravatarService

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('office/offices') allOffices: () => IOffice[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean
	// @Getter('office/settings') getSettings: (id: string) => ISettings
	// @Getter('office/schedule') getSchedule: (id: string) => ISchedule
	// @Action('user/submitGift1') submitGift1:() => Promise<any>
	
	@Prop() gift: IGift
	
	get isSaving(): boolean {
		return user.isGiftSaving
	}
	get canSubmit(): boolean {
		return this.gift.first_state === GiftState.Running && this.state >= 100 && this.stateOffice >=100
	}
	get isWaiting(): boolean {
		return this.gift.first_state === GiftState.Validating
	}
	get isValidated(): boolean {
		return this.gift.first_state === GiftState.Validated
	}
	get state(): number {
		if (!this.user) return 0

		let count: number = 0
		let result: number = 0

		count++
		result += this.checkTitle() ? 1 : 0
		count++
		result += this.checkOrganization() ? 1 : 0
		count++
		result += this.checkEducation() ? 1 : 0
		count++
		result += this.hasAvatar ? 1 : 0

		return Math.round(count === 0 ? 100 : (result * 100 / count))
	}
	get legends(): {label: string, color: string, link: string}[] {
		return [
			{label: this.$i18n.t('vm.root.user.context-main.legends.user').toString(), color: '#008FFB', link: "user-account"},
			{label: this.$i18n.tc('vm.root.user.context-main.legends.offices', this.offices.length).toString(), color: '#00C376', link: "offices"}
		]
	}
	get series(): number[] {
		return [this.state, this.stateOffice]
	}
	get chartOptions(): IRatialBar {
		return {
			chart: {
				type: 'radialBar',
				fontFamily: 'inherit'
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: `40%`
					},
					dataLabels: {
						name: {
							show: true,
						},
						value: {
							fontSize: '20px',
							show: true,
						}
					}
				},
			},
			labels: this.legends.map(l => { return l.label }),
			legend: {
				show: false
			},
			colors: this.legends.map(l => { return l.color }),
			stroke: {
				lineCap: 'round'
			}
		}
	}
	get offices() : IOffice[] {
		return office.offices.filter(o => { return office.isActive(o) })
	}
	private get gravatarId(): string {
		return Gravatar.getGravatarId(this.user.email)
	}
	private get stateOffice(): number {
		let count: number = 0
		let sum: number = 0
		for (let _office of this.offices) {
			count++
			sum += Office.getState(_office, office.setting(_office.id), office.schedule(_office.id), this.user)
		}
		return Math.round(count === 0 ? 0 : (sum / count))
	}
	private get user(): IUser {
		return user.user
	}

	mounted() {
		// this.$emit('navigate', 'referral')
		this.service = new GravatarService()
		this.initializeBreadcrumb()
		this.checkAvatar()
	}

	checkTitle(): boolean {
		return !!this.user && !!this.user.coordinates.title
	}
	checkOrganization(): boolean {
		return !!this.user && !!this.user.organizations && !!this.user.organizations.currents && this.user.organizations.currents.length > 0
	}
	checkEducation(): boolean {
		return !!this.user && !!this.user.formations && !!this.user.formations.currents && this.user.formations.currents.length > 0
	}

	previous(): void {
        this.$router.push({name: 'user-gift'})
	}

	next(): void {
        this.$router.push({name: 'user-gift2'})
	}

	submitGift(): void {
		if (!this.canSubmit) return

		user.submitGift1()
		.then((gift: IGift) => {
			this.$emit('restore', gift)
		})
		.catch((error) => {  })
	}

	private checkAvatar(): void {
		this.hasAvatar = false
		if (Gravatar.defaultGravatarId === this.gravatarId)
			return

		try {
			this.service.exists(this.gravatarId)
			.then(response => {
				if (response.status !== 200) return

				this.hasAvatar = response.data.entry[0].requestHash === this.gravatarId
			}, error => {})
			.catch(error => {})
		} catch(err) {}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift').toString(), link: 'user-gift' }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift1').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}