<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'user-account'}" :title="$t('vm.root.user.aside.informations.title')">{{ $t('vm.root.user.aside.informations.label') }}</router-link>
		</li>
		<li v-if="isMainUser">
			<router-link class="with-badge" :to="{name: 'user-formations', params: $route.params}" :title="$t('vm.root.user.aside.formations.title')">
				<span class="with-badge-label">{{ $tc('vm.root.user.aside.formations.label', user.formations.currents.length) }}</span>
				<span class="with-badge-badge">{{user.formations.currents.length}}</span>
			</router-link>
		</li>
		<li v-if="isMainUser">
			<router-link class="with-badge" :to="{name: 'user-organizations', params: $route.params}" :title="$t('vm.root.user.aside.organizations.title')">
				<span class="with-badge-label">{{ $tc('vm.root.user.aside.organizations.label', user.organizations.currents.length) }}</span>
				<span class="with-badge-badge">{{user.organizations.currents.length}}</span>
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"></li>
		<li>
			<router-link :to="{name: 'user-email'}" :title="$t('vm.root.user.aside.email.title')">
				{{ $t('vm.root.user.aside.email.label') }}
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'user-password'}" :title="$t('vm.root.user.aside.password.title')">
				{{ $tc('vm.root.user.aside.password.label') }}
			</router-link>
		</li>

		<li v-if="isMainUser" class="hr" aria-hidden="true"></li>
		<li v-if="isMainUser">
			<router-link class="with-badge" :to="{name: 'offices'}" :title="$t('vm.root.user.aside.offices.title')">
				<span class="with-badge-label">{{ $tc('vm.root.user.aside.offices.label', offices.length) }}</span>
				<span class="with-badge-badge">{{offices.length}}</span>
			</router-link>
		</li>

		<li v-if="isMainUser" class="hr" aria-hidden="true"/>
		<li v-if="isMainUser">
			<router-link :to="{name: 'user-referral'}" :title="$t('vm.root.user.aside.referral.title')">
				{{ $t('vm.root.user.aside.referral.label') }}
			</router-link>
		</li>
		<!--<li v-if="isMainUser">
			<router-link :to="{name: 'user-gift'}" :title="$t('vm.root.user.aside.gift.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ $t('vm.root.user.aside.gift.label') }}
			</router-link>
		</li> -->
	</ul>
	<ul v-if="isMainUser" class="aside-navigation is-pushed-down">
		<li class="hr" aria-hidden="true"/>
		<li>
			<button type="button" @click="confirmExport()" :title="$t('vm.root.user.aside.export.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#cloud-download"/>
				</svg>
				{{ $t('vm.root.user.aside.export.label') }}
			</button>
		</li>
	</ul>

</a-side>
