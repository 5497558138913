export const walton = {
    title: "Diagramme de Walton",
	'walton-1': "1 - Cognitive",
	'walton-2': "2 - Psycho socio-environnementale",
	'walton-3': "3 - Sensori-motrice",
	'walton-4': "4 - Nociceptive",
	'walton-5': "5 - Neuropathique",
	'walton-6': "6 - Nociplastique",
	level: "Niveau",
	'level-1': "Très bas",
	'level-2': "Bas",
	'level-3': "Modéré",
	'level-4': "Haut",
	'level-5': "Très haut",
}
