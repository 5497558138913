import Render from '@Views/root/parameters/follow-up.html'
import config from "@Config/index"
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IUser, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem, InputDocument } from '@Components/index'
import { PackType, ReminderType } from '@Enums/index'
import { SmsHelper, EnumsHelper } from '@Helpers/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class FollowUp extends Vue {

	private followUpTextWarning: boolean = false

	@Prop() configuration: IConfiguration

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void

	//#region getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get user(): IUser {
		return user.user
	}
	get canSendSms(): boolean {
		return this.configuration.follow_up_type === ReminderType.Both || this.configuration.follow_up_type === ReminderType.OnlySms
	}
	get canSendFollowUp(): boolean {
		return this.configuration.follow_up_type !== ReminderType.None
	}
	get types(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		this.followUps.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
		return result
	}
	get followUpTextLength(): string {
		let length: number = this.computeFollowUpTextLength()
		return this.$i18n.tc('vm.root.parameters.follow-up.remaining-characters', length).toString()
	}
	get followUpError(): boolean {
		return !this.configuration.follow_up_text.includes("LIEN") || this.followUpTextWarning
	}
	get followUpErrorMessage(): string {
		if (!this.configuration.follow_up_text.includes("LIEN"))
			return this.$i18n.t('vm.root.parameters.follow-up.error.no-link').toString()
		if (this.followUpTextWarning)
			return this.$i18n.t('vm.root.parameters.follow-up.error.too-long').toString()

		return ''
	}
	get to(): {name: string, params: {pack: PackType}} {
        return {name: 'purchase-pack', params: {pack: PackType.Sms}}
	}
	get lightOptions(): any {
        return InputDocument.lightOptions()
	}
	private get followUps(): ReminderType[] {
		if (this.isMainUser) return [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]
		return [ReminderType.None, ReminderType.OnlyMail]
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'follow-up')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	private computeFollowUpTextLength(): number {
		if (!this.configuration || !this.configuration.follow_up_text) return 149

		this.configuration.follow_up_text = this.configuration.follow_up_text.trim()

		var text = this.configuration.follow_up_text
		var count = SmsHelper.countOccurence(text)
		var result = 149 - text.length - count
		this.followUpTextWarning = result < 0
		return result
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.follow-up-parameters').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
