export const asideRemittance = {
    back: {
        title: "Retourner à la liste des remises",
        label: "Retour aux remises"
    },
    print: {
        title: "Imprimer la remise",
        label: "Imprimer"
    },
    delete: {
        title: "Suppression de cette remise",
        'delete-msg': "Voulez-vous vraiment supprimer cette remise ?",
        label: "Supprimer cette remise"
    }
}