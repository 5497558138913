export const dateTimeFormats = {
    weekdayMin: {weekday: 'short'},
    weekday: {weekday: 'long'},
    dayOfMonth: {day: 'numeric'},
    dd: {
        day: '2-digit'
    },
    mm: {
        month: '2-digit'
    },
    MM: {
        month: 'short'
    },
    MMM: {
        month: 'long'
    },
    hh: {
        hour: 'numeric'
    },
    ii: {
        minute: '2-digit'
    },
    yyyy: {
        year: 'numeric'
    },
    short_ll: {
        month: 'short', day: 'numeric'
    },
    short_LL: {
        month: 'long', day: 'numeric'
    },
    ll: {
        year: 'numeric', month: 'short', day: 'numeric'
    },
    l: {
        year: '2-digit', month: 'numeric', day: 'numeric'
    },
    L: {
        year: 'numeric', month: 'numeric', day: 'numeric'
    },
    LL: {
        year: 'numeric', month: 'long', day: 'numeric'
    },
    LT: {
        hour: 'numeric', minute: 'numeric'
    },
    long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
}
