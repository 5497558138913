<container :loading="!remittance || isLoading" :loading-title="$t('vm.root.accounting.remittance.main.loading')">

	<aside-remittance @delete="onDelete"/>

	<master v-if="!!remittance && !isLoading">

		<central>
			<div class="wrapper">
				<collapsible-help id="remittance-information" tag="h1" :title="remittance.label || $t('vm.root.accounting.remittance.main.title')">
					<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<div class="notice-info">
						<p class="text-left">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
							</svg>
							<i18n path="vm.root.accounting.remittance.main.help.content" tag="span">
								<strong>{{ $t('vm.root.accounting.remittance.main.help.strong') }}</strong>
							</i18n>
						</p>
					</div>
				</collapsible-help>

				<form @submit.prevent="submitSave" class="marg-v">

					<div class="grid-2">

						<input-frame :auto-focus="true" type="text" id="label" v-model="remittance.label"
							:label="$t('vm.root.accounting.remittance.main.label.label')"
							:placeholder="$t('vm.root.accounting.remittance.main.label.label')"
							:error-test="hasLabelError" :error-msg="$t('vm.root.accounting.remittance.main.label.error-msg')"
							:title="$t('vm.root.accounting.remittance.main.label.title')"/>

						<input-frame type="text" id="number" v-model="remittance.number"
							:label="$t('vm.root.accounting.remittance.main.number.label')"
							:placeholder="$t('vm.root.accounting.remittance.main.number.label')"
							:error-test="hasNumberError" :error-msg="$t('vm.root.accounting.remittance.main.number.error-msg')"
							:title="$t('vm.root.accounting.remittance.main.number.title')"/>

						<togglebox-frame id="deposit" :items="depositItems" v-model="remittance.closed"
							:label="$t('vm.root.accounting.remittance.main.banked.label')"
							:title="$t('vm.root.accounting.remittance.main.banked.title')"/>

						<input-date-frame id="deposit-date" v-model="remittance.deposit_date" :disabled="!remittance.closed"
							:label="$t('vm.root.accounting.remittance.main.date.label')"
							:title="$t('vm.root.accounting.remittance.main.date.title')"/>

						<div class="notice-warning grid-item-full" v-if="remittance.closed">
							<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
								<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
							</svg>
							{{ $t('vm.root.accounting.remittance.main.closed-warning') }}
						</div>
					</div>

					<hr>

					<div v-if="lines.length <= 0" class="marg-v" :class="{'is-white-space': !searched || searching}">
						<svg v-if="searching" width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
						</svg>
						<div v-if="searching" class="text-md text-bold text-center marg-v">
							{{ $t('vm.root.accounting.main.loading') }}
						</div>
						<div v-else-if="searched" class="text-md text-bold text-center marg-v">
							{{ $t('vm.root.accounting.remittance.main.none') }}
						</div>
					</div>

					<div v-else class="is-overflow scrollbar">
						<table class="data-table accounting-table">
							<thead>
								<tr>
									<th scope="col" class="text-left">
										<div class="checkbox text-xs">
											<input type="checkbox" id="chk00" v-model="selectAll" :title="$t('vm.root.accounting.main.select-all.title')">
											<label for="chk00" :title="$t('vm.root.accounting.main.select-all.label')">
												<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
												<span class="sr-only">{{ $t('vm.root.accounting.main.select-all.label') }}</span>
											</label>
										</div>
									</th>
									<th scope="col" class="text-left w30">{{ $t('vm.root.accounting.main.columns.label') }}</th>
									<th scope="col" class="text-left w30">{{ $t('vm.root.accounting.main.columns.bank') }}</th>
									<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.number') }}</th>
									<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.date') }}</th>
									<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.mode') }}</th>
									<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.receipt') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="line in lines" :key="line.id" :class="{'is-selected': line.selected}">
									<td class="text-left accounting-table-checkbox">
										<div class="checkbox text-xs">
											<input type="checkbox" :id="`chk${line.id}`" v-model="line.selected">
											<label :for="`chk${line.id}`" :title="$t('vm.root.accounting.main.select')">
												<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
												<span class="sr-only">{{ $t('vm.root.accounting.main.select') }}</span>
											</label>
										</div>
									</td>
									<td class="text-left accounting-table-label">
										<span :inner-html.prop="line.label | mark(subFilter.label)"/>
									</td>
									<td class="text-left text-ellipsis accounting-table-label">
										<span :inner-html.prop="line.bank | mark(subFilter.bank)"/>
									</td>
									<td class="text-right accounting-table-num">
										<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.number') }}</span>
										<span class="text-numeric text-nowrap accounting-table-num-value" :inner-html.prop="line.number | mark(subFilter.number)"/>
									</td>
									<td class="text-right accounting-table-num">
										<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.date') }}</span>
										<span class="text-numeric text-nowrap accounting-table-num-value">{{ $d(line.settlement_date, 'l') }}</span>
									</td>
									<td class="text-right accounting-table-num">
										<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.mode') }}</span>
										<button type="button" class="btn--reset accounting-table-num-value data-table-icon" tabindex="-1">
											<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIcon(line.mode)}`"/>
											</svg>
											<span class="data-table-icon-tooltip">{{ getModeLabel(line.mode) }}</span>
										</button>
									</td>
									<td class="text-right accounting-table-num" :class="{'rwd-over-small': !( line.ttc >= 0 )}">
										<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.receipt') }}</span>
										<span v-if="line.ttc >= 0" class="accounting-table-num-value">
											<span class="text-numeric text-nowrap">{{ $n(line.ttc, 'currency', line.currency_i18n) }}</span>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<control-bar v-if="lines.length > 0" class="accounting-control-bar">

						<div class="is-overflow scrollbar accounting-footer">
							<table class="data-table accounting-footer-table">
								<tbody>
									<tr>
										<th scope="row" class="text-left w60">{{ $t('vm.root.accounting.remittance.main.total') }}</th>
										<td class="text-right w20">
											<span class="text-nowrap">
												{{ $tc('vm.root.accounting.remittance.main.check', countSelectedLines) }}
											</span>
										</td>
										<td class="text-right w20">
											<span class="text-numeric text-nowrap">
												{{ $n(total, 'currency', defaultCurrency) }}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<hr class="accounting-footer-hr">

						<div class="text-right">
							<button-action type="submit" btn-class="btn-primary is-pushed-right" :loading="isSaving"
								:disabled="disabled || isDeleting"
								:title="$t('general.save.title')">
								{{ saveButtonLabel }}
								<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#university"/>
								</svg>
							</button-action>
						</div>

					</control-bar>

				</form>
			</div>
		</central>

		<context>
			<context-remittance/>
		</context>

	</master>

</container>
