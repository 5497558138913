import Render from '@Views/login/register.html'
import { Vue, Component } from 'vue-property-decorator'
import { login, notif } from '@Store/modules'
import { IRegister } from '@Store/types'

@Render
@Component({})
export class Register extends Vue {
	
	last_name: string = ''
	first_name: string = ''
	code: string = ''
	confirm_email: string = ''
	cgv: boolean = false
	submitted: boolean = false

	//#region getters
	get email(): string {
		return login.email
	}
	set email(value: string) {
		login.updateEmail(value)
	}
	get isRegistering() {
		return login.isRegistering
	}
	get hasEmailError(): boolean {
		return this.submitted && !this.email
	}
	get hasConfirmEmailError(): boolean {
		return this.submitted && (!this.confirm_email || this.confirm_email !== this.email)
	}
	get hasLastNameError(): boolean {
		return this.submitted && !this.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.first_name
	}
	get hasCgvError(): boolean {
		return this.submitted && !this.cgv
	}
	//#endregion

	canSubmit(): boolean {
		return !!this.email && !!this.confirm_email && this.email === this.confirm_email && !!this.last_name && !!this.first_name && this.cgv
	}

	submit(event: any) {
		this.submitted = true
		if (!this.canSubmit()) return

		let register: IRegister = { 
			email: this.email, 
			last_name: this.last_name, 
			first_name: this.first_name, 
			confirm_email: this.confirm_email, 
			godchild_code: this.code,
			cgv: this.cgv, 
			url_connection: "https://app.osteo2ls.com"
		}
		login.register(register)
		.then((user: IRegister) => {
			notif.success(this.$i18n.t('vm.login.register.register.success', {email: user.email}).toString())
			this.$router.push({ name: 'authentication' })
		})
		.catch(error => { notif.error(error) })
	}

	mounted() {
		this.code = this.$route.params.code || ''
		this.setFocus()
	}

	previous() {
		this.$router.push({name: 'login'})
	}

	private setFocus() {
		let last_name = this.$refs['last-name'] as any
		if (last_name === undefined) return

		last_name.focus()
	}
}