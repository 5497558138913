import Render from '@Views/root/user/account.html'
import { Component } from 'vue-property-decorator'
import { IPopin, IPopinAction, INotification, IUser } from '@Store/types'
import { AutoSaver } from '@Components/auto-saver'
import { User } from '@Models/index'
import { notif, user } from '@Store/modules'

@Render
@Component({
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Account extends AutoSaver {

	submitted: boolean = false

	constructor() {
		super('user')
	}

	//#region Getters
    get user(): IUser {
		return user.user
	}
	get isLoading(): boolean {
		return user.isLoading
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: any): void {
		if (!value) return

		user.updateSuccess(value)
	}

	save(): void {
		if (!this.canSubmit()) return

		let _user:IUser = User.getUser(this.user, this.oldValue)
		let notification: INotification = User.getNotification(_user)

		user.update(_user)
		.then(() => {
			super.initializeValue()

			notif.success(notification)
			this.submitted = false
		})
		.catch(error => {
			notif.error(error)
		})
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'account')
		super.initializeValue()
	}

	beforeDestroy() {
		notif.clear()
	}

	submitSave(event: any) {
		this.submitted = true
		this.save()
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.user.office.main.cancel.action').toString(),
			title: this.$i18n.t('vm.root.user.office.main.cancel.title').toString(),
			callback: (data: any) => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let user:IUser = User.getUser(this.user, this.oldValue)
		let messages: string[] = User.getUpdateList(user)
		let updates: string = messages.join("</li><li>")
		let message = !!updates ? this.$i18n.t('vm.root.user.office.main.cancel.message-details', {details: updates}).toString()
		 : this.$i18n.t('vm.root.user.office.main.cancel.message').toString()

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.user.office.main.cancel.title').toString(),
			content: message,
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	private canSubmit(): boolean {
		if (!this.user) return false

		let notification: INotification | false = User.getErrorNotification(this.user)
		if (!notification) return true

		notif.error(notification)
		return false
	}

	private cancelAction(): void {
		super.cancel()
		this.submitted = false
	}
}
