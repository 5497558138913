export const sessions = {
    title: "Type de consultation | Type de consultation | Types de consultation",
    'new-session': {
        title: "Créer un nouveau type de consultation pour ce cabinet",
        label: "Nouveau type de consultation"
    },
    move: {
        title: "Déplacer ce type de consultation",
        label: "Déplacer"
    },
    details: "Détails de cet accès",
    'default-name': "Consultation par défaut",
    'default-new-name': "nouveau type de consultation",
    summary: "{duration} minutes à {price}"
}