import Render from '@Views/components/input/input-password.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Common } from '@Models/index'

@Render
@Component({})
export class InputPassword extends Vue {

    show: boolean = false
    password: string = ""
    private random: string = ''

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) required: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: "ex : m07_d3_p4553!"}) placeholder: string
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string
    @Prop() value: string

    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }

    mounted() {
        this.password = this.value
    }

    created() {
		this.random = Common.generateId()
    }

    onInput(password: any) {
        this.password = password
        this.$emit('input', password)
    }

    focus() {
        (this.$refs[this.inputId] as any).focus()
    }
}