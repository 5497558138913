<div>

	<div class="input-address-field">
		<div class="form-group">
			<div class="with-icon">
				<svg width="1em" class="with-icon-icon" :class="{'is-shown': !searching}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#map-marker-alt"/>
				</svg>
				<span class="with-icon-icon" :class="{'is-shown': searching}">
					<spinner class="text-primary"/>
				</span>
				<input class="form-group-input" v-model="street" :id="id" :title="title" :placeholder="placeholder" :required="required" :readonly="readonly"
					:disabled="disabled" @blur="onBlur" @focus="onFocus" @input="onInput" @keydown="keyboardNav" @keydown.esc="reset">
			</div>
			<button type="button" class="input-like form-group-input is-flex-fixed misc-trigger" :class="{'is-active': display}" :title="display ? 'Masquer les détails' : 'Afficher les détails'" @click="display = !display">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				<span class="rwd-over-medium">détails</span>
			</button>
		</div>

		<ul ref="list" class="input-address-list dropdown-like" :class="{'is-active': displaySuggestions && suggestions.length > 0}">
			<li v-for="suggestion in suggestions">
				<button type="button" class="input-address-list-item dropdown-like-menu-item" @click="selectAddress(suggestion)" :inner-html.prop="suggestion.properties.label | mark(street)"/>
			</li>
		</ul>
	</div>

	<div class="misc-collapsible" :class="{'is-active': display}">
		<div class="misc-collapsible-content">
			<div class="grid-2">
				<input-frame type="text" id="number" :readonly="readonly" v-model="address.number"
					label="Numéro de la rue" 
					placeholder="ex : 7 bis"
					title="Veuillez renseigner numéro dans la rue"/>
				<input-frame type="text" id="street" :readonly="readonly" v-model="address.street"
					label="Nom de la rue" 
					placeholder="ex : rue Jean-Jaurès"
					title="Veuillez renseigner le nom de la rue"/>
			</div>
			<div class="grid-3">
				<input-frame type="text" id="zipcode" :readonly="readonly" v-model="address.zipcode"
					label="Code postal" 
					placeholder="ex : 91220"
					title="Veuillez renseigner le code postal"/>
				<input-frame type="text" id="number" :readonly="readonly" v-model="address.city"
					label="Ville" 
					placeholder="ex : Brétigny-sur-Orge"
					title="Veuillez renseigner la ville"/>
				<input-frame type="text" id="number" :readonly="readonly" v-model="address.country"
					label="Pays" 
					placeholder="ex : France"
					title="Veuillez renseigner le pays"/>
			</div>
			<hr>
		</div>
	</div>

</div>
