export const relaunch = {
    title: "Paramètres de la relance préventive",
    'remaining-characters': "{count} caractère restant|{count} caractère restant|{count} caractères restants",
    error: {
        // 'no-stop': 'La mention "STOP au XXXXX" est obligatoire.',
        'no-link': 'La mention "LIEN" doit être présente pour pouvoir indiquer le lien de votre page de prise de rdv en ligne.',
        'too-long': "Votre texte de relance préventive par SMS est trop long."
    },
    type: {
        title: "Sélectionnez le type d'envoi de vos relances préventives",
        label: "Type d'envoi, par défaut, de la relance préventive"
    },
    details: {
        title: "Détails du SMS de relance préventive du patient",
        text1: {
            content: "Vous pouvez modifier le texte ci-dessous, 160 caractères maximum. C'est celui-ci que vos patients recevront, par SMS, après le délai indiqué (ici {0}).",
            strong: "{count} mois|{count} mois|{count} mois"
        },
        text2: {
            content: "{0} si le message venait à dépasser les 160 caractères, il ne serait pas envoyé.",
            strong: "Attention :"
        },
        text3: {
            content: "{0} La mention {1} sera remplacée par le lien vers la page de vos cabinets sur {2}. Ce lien fait {3} et est obligatoire. Veillez à bien la saisir sous ce format.",
            em: "N.B. :",
            strong1: "LIEN",
            'link-title': "Votre plateforme de prise de rdv en ligne",
            'link-label': "le planning en ligne",
            strong2: "une quinzaine de caractères"
        }
    },
    sms: {
        balance: {
            content: "Votre solde actuel : {0}",
            strong: "{sms-count} SMS"
        },
        title: "Acquérir des SMS",
        label: "acquérir des SMS"
    },
    text: {
        label: "Texte du SMS de relance ({length})",
        placeholder: "ex : Souhaitez-vous prendre un nouveau rdv…",
        title: "Veuillez renseigner le texte de votre SMS de relance"
    },
    delay: {
        label: "Délai, par défaut, pour relancer vos patients après une longue absence (mois)",
        title: "Veuillez indiquer le délai après lequel le patient sera relancé",
        placeholder: "ex : dans 6 mois"
    },
}