<container :loading="!event" :loading-title="$t('vm.root.calendar.valid-event.main.loading')">
	<aside-valid-event/>

	<master v-if="!!event">
		<central>
			<div class="wrapper marg-v">
				<form @submit.prevent="submitConfirm">

					<collapsible-help id="valid-event-warning" :title="$t('vm.root.calendar.valid-event.main.help-warning.title')" tag="h1">
						<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
						</svg>
						<div class="notice-warning">
							<p class="text-left">
								<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
								</svg>
								<i18n path="vm.root.calendar.valid-event.main.help-warning.text" tag="span">
									<strong>{{ $t('vm.root.calendar.valid-event.main.help-warning.strong') }}</strong>
								</i18n>
							</p>
						</div>
					</collapsible-help>
					<div class="marg-v">
						<h2>{{ $t('vm.root.calendar.valid-event.main.informations.main-title') }}</h2>
						<div class="grid-2 vertical-gap">
							<section class="person-item">
								<h3 class="h-reset">
									<div>{{ $t('vm.root.calendar.valid-event.main.informations.office') }}</div>
									<div><b>{{office.name}}</b></div>
								</h3>
								<div class="person-item-border">
									<div>
										<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-check"/>
										</svg>
										{{ $t('general.the') }} {{ $d(event.start_date, 'long') }}
									</div>
									<div>{{ event.start_date | inTime }}</div>
								</div>
								<div class="person-item-border">
									{{ $t('vm.root.calendar.valid-event.main.informations.session') }}
									<span v-if="!!session">
										<b>{{session.name}}</b>
										({{session.duration}} {{ $t('vm.root.calendar.valid-event.main.informations.duration-unit') }})
									</span>
									<b v-else>{{ $t('vm.root.calendar.valid-event.main.informations.session-none') }}</b>
								</div>
							</section>

							<section v-if="!!customer" class="person-item">
								<h3 class="h-reset">
									<div>{{ $t('vm.root.calendar.valid-event.main.informations.customer') }}</div>
									<div>{{customer.first_name}} <b>{{customer.last_name}}</b></div>
								</h3>

								<div class="person-item-border">
									<div v-if="customer.phone" class="select"><a :href="`tel:${customer.phone}`">{{customer.phone | formatPhone}}</a></div>
									<div v-else><i>{{ $t('vm.root.search.patient.item.no-phone') }}</i></div>
									<div v-if="customer.email" class="marquee select">
										<div class="marquee-inner">
											<div class="marquee-content"><a :href="`mailto:${customer.email}`">{{customer.email}}</a></div>
										</div>
									</div>
									<div v-else><i>{{ $t('vm.root.search.patient.item.no-email') }}</i></div>
								</div>
								<div class="person-item-border">
									{{ $t('vm.root.calendar.valid-event.main.informations.for-patient') }}
									<strong>{{ !event.description ? $tc('vm.root.calendar.valid-event.main.informations.himself', customer.gender) : event.description }}</strong>
								</div>
							</section>
						</div>

						<hr>

						<div>
							<collapsible-help id="valid-event-information" :title="$t('vm.root.calendar.valid-event.main.help-information.title')" tag="h2">
								<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
								</svg>
								<div class="notice-info">
									<p class="text-left">
										<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
										</svg>
										{{ $t('vm.root.calendar.valid-event.main.help-information.text') }}
									</p>
								</div>
							</collapsible-help>

							<div class="marg-v">
								<patient-item :patient="patient" :unselect="unselect" v-if="!!patient"/>
								<div v-else>
									<label v-if="!!event.patients && event.patients.length > 0" class="label" data-text="label">
										<span>{{ $tc('vm.root.calendar.valid-event.main.patient-linked', event.patients.length) }}</span>
									</label>
									<div class="grid-2">
										<section class="person-item" :key="patient.id" v-for="patient in event.patients">
											<div class="person-item-header text-capitalize text-inherit">
												<figure class="person-item-avatar">
													<avatar :first-name="patient.coordinates.first_name" :last-name="patient.coordinates.last_name" :email="patient.coordinates.email" :gender="patient.coordinates.gender"/>
												</figure>
												<h3 class="h-reset person-item-name">{{patient.coordinates.first_name}}<br><b>{{patient.coordinates.last_name}}</b></h3>
											</div>
											<div class="person-item-border">
												<div v-if="!!patient.coordinates.phone1.number" class="select"><a :href="`tel:${patient.coordinates.phone1.number}`">{{patient.coordinates.phone1.number | formatPhone}}</a></div>
												<div v-else><i>{{ $t('vm.root.search.patient.item.no-phone') }}</i></div>
												<div v-if="patient.coordinates.email" class="marquee select">
													<div class="marquee-inner">
														<div class="marquee-content"><a :href="`mailto:${patient.coordinates.email}`">{{patient.coordinates.email}}</a></div>
													</div>
												</div>
												<div v-else><i>{{ $t('vm.root.search.patient.item.no-email') }}</i></div>
											</div>
											<div v-if="patient.blacklisted || patient.deceased" class="person-item-border">
												<span v-if="patient.blacklisted" class="patient-blacklisted">{{ $t('vm.root.calendar.valid-event.main.blacklisted') }}</span>
												<span v-if="patient.deceased" class="patient-deceased">{{ $t('vm.root.calendar.valid-event.main.deceased') }}</span>
											</div>
											<div class="person-item-border text-right">
												<button type="button" class="btn btn--link-like" @click="select(patient)"
													:title="$t('vm.root.calendar.valid-event.main.select.title')">
													{{ $t('vm.root.calendar.valid-event.main.select.label') }}
												</button>
											</div>
										</section>
									</div>
									<search-patient :action="select" :notification="notification" :customer="customer"/>
								</div>

								<div class="collapsible-panel-container" :class="{'is-active': isUpdated}">
									<div class="collapsible-panel-content">
										<div class="notice-warning">
											<p class="text-left">
												<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
													<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
												</svg>
												<i18n path="vm.root.calendar.valid-event.main.update-warning.text" tag="span">
													<strong>{{ $t('vm.root.calendar.valid-event.main.update-warning.strong') }}</strong>
												</i18n>
											</p>
											<p class="text-left">
												<switch-frame id="phone-update" v-model="phoneUpdate" v-if="isPhoneUpdated"
															  :title="$t('vm.root.calendar.valid-event.main.update-warning.phone-update.title')">
													<i18n path="vm.root.calendar.valid-event.main.update-warning.phone-update.label" tag="span">
														<strong>{{ customer.phone }}</strong>
													</i18n>
												</switch-frame>
												<switch-frame id="email-update" v-model="emailUpdate" v-if="isEmailUpdated"
															  :title="$t('vm.root.calendar.valid-event.main.update-warning.email-update.title')">
													<i18n path="vm.root.calendar.valid-event.main.update-warning.email-update.label" tag="span">
														<strong>{{ customer.email }}</strong>
													</i18n>
												</switch-frame>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<control-bar class="childs-space-between">
						<button-action btn-class="btn-danger" @click.native="submitRefuse" :loading="isRefusing" :disabled="isConfirming"
							:title="$t('vm.root.calendar.valid-event.main.refuse.title')">
							{{ $t('vm.root.calendar.valid-event.main.refuse.label') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
							</svg>
						</button-action>

						<button-action type="submit" btn-class="btn-success" :loading="isConfirming" :disabled="isRefusing"
							:title="$t('vm.root.calendar.valid-event.main.confirm.title')">
							{{ $t('vm.root.calendar.valid-event.main.confirm.label') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							</svg>
						</button-action>
					</control-bar>
				</form>
			</div>
		</central>

		<context>
			<context-event :event="event"/>
		</context>
	</master>

</container>
