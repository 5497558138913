<section class="person-item" v-if="patient.visible">
	<div class="person-item-header text-capitalize text-inherit">
		<figure class="person-item-avatar">
			<avatar :first-name="first_name" :last-name="last_name" :email="email" :gender="gender"/>
		</figure>
		<h3 class="h-reset person-item-name">
			<span :inner-html.prop="first_name | mark(query)"/><br>
			<b :inner-html.prop="last_name | mark(query)"/>
		</h3>
	</div>
	<div class="person-item-border">
		<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#code-merge"/>
		</svg>
		{{ count_duplicate }} doublons
	</div>
	<div class="person-item-border text-right">
		<button type="button" class="btn btn--link-like text-danger" @click="merge"
					   title="Fusionner les patients sélectionner">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#code-merge"/>
			</svg>
			fusionner
		</button>
	</div>
</section>
