<div v-if="!!schedule">
	<collapsible-help id="schedule-information" :title="$t('vm.root.user.office.main-schedule.title')" tag="h2">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<i18n path="vm.root.user.office.main-schedule.help1.text" tag="span">
					<strong>{{ $t('vm.root.user.office.main-schedule.help1.strong') }}</strong>
				</i18n>
			</p>
			<i18n path="vm.root.user.office.main-schedule.help2.text" class="text-left" tag="p">
				<strong>{{ $t('vm.root.user.office.main-schedule.help2.strong1') }}</strong>
				<strong>{{ $t('vm.root.user.office.main-schedule.help2.strong2') }}</strong>
				<strong>{{ $t('vm.root.user.office.main-schedule.help2.strong3') }}</strong>
				<strong>{{ $t('vm.root.user.office.main-schedule.help2.strong4') }}</strong>
				<strong>{{ $t('vm.root.user.office.main-schedule.help2.strong5') }}</strong>
			</i18n>
			<i18n path="vm.root.user.office.main-schedule.help3.text" class="text-left" tag="p">
				<router-link :to="{name: 'co-working'}" :title="$t('vm.root.user.office.main-schedule.help3.link.title')">
					{{ $t('vm.root.user.office.main-schedule.help3.link.label') }}
				</router-link>
				<strong>{{ $t('vm.root.user.office.main-schedule.help3.strong') }}</strong>
			</i18n>
		</div>
	</collapsible-help>

	<schedule :schedule="schedule" :links="links"/>
</div>
