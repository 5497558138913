import Render from '@Views/root/patients/patient/family.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { groupBy } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { IBreadcrumbItem, IMember, ICoordinates, IPatient } from '@Store/types'
import { MemberItem, SearchMember } from '@ViewModels/root/search/member'
import { breadcrumb, patient } from '@Store/modules'

@Render
@Component({
	components: {
        'search-member': SearchMember,
        'member-item': MemberItem
	}
})
export class Family extends Vue {

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	//#region Getters
	get patient(): IPatient {
		return patient.patient
	}
	get family(): IMember[] {
		return patient.family
	}
	get coordinates(): ICoordinates {
		return patient.coordinates
	}
    get membersGrouped(): Dictionary<IMember[]> {
        let result:Dictionary<IMember[]> = groupBy(this.family.sort(), s => s.type)
        return result
    }
    //#endregion

    mounted() {
		this.$emit('navigate', 'family')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
        let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.family').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
