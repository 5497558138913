<a-side :sub-menu="true" v-if="consultation">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.patient.aside-consultation.back-patient.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.patients.patient.aside-consultation.back-patient.text') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'patient-consultations', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.back-consultation.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.patients.patient.aside-consultation.back-consultation.text') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'patient-consultation', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.examens.text')">{{ $t('vm.root.patients.patient.aside-consultation.examens.text') }}</router-link>
		</li>
		<li v-if="display.with_exclusion_tests">
			<router-link class="with-badge" :to="{name: 'patient-consultation-exclusion', params: $route.params}" :title="$tc('vm.root.patients.patient.aside-consultation.exclusion-test.title', consultation.exclusion_tests.currents.length)">
				<span class="with-badge-label">{{ $tc('vm.root.patients.patient.aside-consultation.exclusion-test.text', consultation.exclusion_tests.currents.length) }}</span>
				<span class="with-badge-badge">{{consultation.exclusion_tests.currents.length}}</span>
			</router-link>
		</li>
		<li v-if="display.with_cranio_sacral">
			<router-link :to="{name: 'patient-consultation-cranio', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.cranio-sacral.text')">{{ $t('vm.root.patients.patient.aside-consultation.cranio-sacral.text') }}</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-consultation-forms', params: $route.params}" :title="$tc('vm.root.patients.patient.aside-consultation.forms.title', consultation.forms.length)">
				<span class="with-badge-label">{{ $tc('vm.root.patients.patient.aside-consultation.forms.text', consultation.forms.length) }}</span>
				<span class="with-badge-badge">{{consultation.forms.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'patient-consultation-reason', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.reason.text')">{{ $t('vm.root.patients.patient.aside-consultation.reason.text') }}</router-link>
		</li>
		<li v-if="display.with_walton">
			<router-link :to="{name: 'patient-consultation-walton', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.walton.text')">{{ $t('vm.root.patients.patient.aside-consultation.walton.text') }}</router-link>
		</li>
		<li v-if="display.with_schema">
			<router-link :to="{name: 'patient-consultation-schema', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.schema.text')">{{ $t('vm.root.patients.patient.aside-consultation.schema.text') }}</router-link>
		</li>
		<li>
			<router-link v-if="hasFollowUpUnread" class="with-badge" :to="{name: 'patient-consultation-follow-up', params: $route.params}" :title="$tc('vm.root.patients.patient.aside-consultation.follow-up.title', consultation.forms.length)">
				<span class="with-badge-label">{{ $t('vm.root.patients.patient.aside-consultation.follow-up.text') }}</span>
				<span class="with-badge-badge">1</span>
			</router-link>
			<router-link v-else :to="{name: 'patient-consultation-follow-up', params: $route.params}" :title="$t('vm.root.patients.patient.aside-consultation.follow-up.text')">{{ $t('vm.root.patients.patient.aside-consultation.follow-up.text') }}</router-link>
		</li>
	</ul>
	<ul class="aside-navigation is-pushed-down">
		<li class="hr" aria-hidden="true"/>

		<li v-if="hasInvoice">
			<span class="aside-link-like">
				{{ $t('managers.invoice-manager.invoice') }}
				<strong class="text-numeric">#<span class="select">{{ invoiceNumber }}</span></strong>
			</span>
		</li>
		<li>
			<button type="button" @click="createAndPrint" :title="$t('managers.invoice-manager.print-invoice.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': invoicePrinted()}">
					<use v-if="invoicePrinted()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					<use v-else xlink:href="@Assets/svg/symbol-defs.svg#print"/>
				</svg>
				{{ $t('managers.invoice-manager.print-invoice.label') }}
			</button>
		</li>
		<li>
			<button type="button" @click="createAndSend" :disabled="isInvoiceSending" :title="$t('managers.invoice-manager.send-invoice.title')">
				<spinner v-if="isInvoiceSending"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': invoiceSended()}">
                    <use v-if="invoiceSended()" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					<use v-else xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
				</svg>
				{{ $t('managers.invoice-manager.send-invoice.label') }}
            </button>
        </li>
		<li>
			<button type="button" @click="updateReceipt" :title="hasInvoice ? $t('managers.invoice-manager.update-invoice.title') : $t('managers.invoice-manager.generate-invoice.title')">
				<spinner v-if="isInvoiceLoading"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
                </svg>
				{{ hasInvoice ? $t('managers.invoice-manager.update-invoice.label') : $t('managers.invoice-manager.generate-invoice.label') }}
            </button>
        </li>
		<li v-if="!readOnly && isMainUser" class="hr" aria-hidden="true"/>
		<li v-if="!readOnly && isMainUser">
			<delete-item :delete-msg="$t('vm.root.patients.patient.aside-consultation.delete.message')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
				:delete-action="submitDelete" :can-delete="true" :title="$t('vm.root.patients.patient.aside-consultation.delete.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.patients.patient.aside-consultation.delete.text') }}
			</delete-item>
		</li>
	</ul>
</a-side>
