<a-side :sub-menu="true">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'help'}" :title="$t('vm.root.help.aside.back.title-closed')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.help.aside.back.label') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
		<li v-if="isLoading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</li>
		<li v-else v-for="ticket in tickets" :key="ticket.id">
            <router-link class="with-badge" :to="{name: 'closed-ticket', params:{sup_id: ticket.id}}" :title="$t('vm.root.help.aside.ticket', {title: ticket.title})">
                <span class="with-badge-label">{{ ticket.title }}</span>
                <span v-if="ticket.unread_count > 0" class="with-badge-badge">{{ ticket.unread_count }}</span>
			</router-link>
        </li>
    </ul>
</a-side>