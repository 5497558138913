export const login = {
    title: "Connexion",
    demo: "Vous êtes sur le point d'essayer <strong>Osteo2ls</strong>. Pour vous connecter à cette version de démonstration, saisissez l'email <strong>demo@osteo2ls.com</strong>, puis le mot de passe <strong>test</strong>.",
    description: {
        text: "Veuillez renseigner votre adresse email pour vous connecter ou {0} sur l'application {1}.",
        link: "créer un compte",
        link_title: "Inscrivez-vous sur Osteo2ls",
        strong: "Osteo2ls"
    },
    email: {
        label: "Adresse email",
        error: "Merci de renseigner votre adresse email",
        placeholder: "ex : obiwan@kenobi.com",
        title: "Veuillez renseigner votre adresse email"
    },
    login: {
        title: "Connectez-vous à Osteo2ls",
        text: 'connexion'
    }
}