import Render from '@Views/root/statistics/consultations/consultations.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, StatisticsActionPayload, IStatisticsFilter } from '@Store/types'
import { Statistics, Debouncer } from '@Models/index'
import { EnumsHelper, DateHelper } from '@Helpers/index'
import { FollowUpLevel } from '@Enums/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class Consultations extends Vue {

	totalRatio: number = 0
	private first: boolean = true
	private groupSum: number[] = [0,0,0,0,0,0,0,0,0,0]
	private ratios: number[] = [0,0]
	private stats: IStatsConsultations = null
	private debouncerEvolution1: Debouncer = null
	private debouncerEvolution2: Debouncer = null
	private debouncerEvolution3: Debouncer = null

	//#region Getters
	get filter(): IStatisticsFilter {
        return statistics.filter
	}
	get isLoading(): boolean {
        return this.first && statistics.isLoading
	}
	get legends(): {label: string, color: string}[] {
		return [
			{label: this.$i18n.t('vm.root.statistics.consultations.main.legends.first').toString(), color: '#008ffb'},
			{label: this.$i18n.t('vm.root.statistics.consultations.main.legends.second').toString(), color: '#00cb8f'},
			{label: this.$i18n.t('vm.root.statistics.consultations.main.legends.third').toString(), color: '#fea019'}
		]
	}
	get legendsFollowUp(): {label: string, color: string}[] {
		return [
			{label: EnumsHelper.followUpLevelToString(FollowUpLevel.NotSatisfied), color: '#f42'},
			{label: EnumsHelper.followUpLevelToString(FollowUpLevel.None), color: '#aaa'},
			{label: EnumsHelper.followUpLevelToString(FollowUpLevel.Satisfied), color: '#0d2'}
		]
	}
	get evolutionSeries1(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.firstEvolution1
		}, {
			name: this.legends[1].label,
			data: this.nextEvolution1
		}, {
			name: this.legends[2].label,
			data: this.totalEvolution1
		}]
    }
	get evolutionSeries2(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.firstEvolution2
		}, {
			name: this.legends[1].label,
			data: this.nextEvolution2
		}, {
			name: this.legends[2].label,
			data: this.totalEvolution2
		}]
	}
    get evolutionChartOptions(): any {
		return {
            chart: {
                type: 'area',
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				categories: DateHelper.getMonthsName()
			},
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			}
        }
    }
	get countConsultation(): any[] {
		return [{
			name: this.$i18n.t('vm.root.statistics.consultations.main.legend-chart.name').toString(),
			data: this.groupSum
		}]
	}
	get countConsultationChartOptions(): any {
		return {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '75%',
					endingShape: 'flat'
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			xaxis: {
				categories: [
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 1).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 2).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 3).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 4).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 5).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 6).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 7).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 8).toString(),
					this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 9).toString(),
					`+${this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.category', 10)}`.toString(),
				],
			},
			yaxis: {
				title: {
					text: this.$i18n.t('vm.root.statistics.consultations.main.legend-chart.title').toString()
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val: number) => { return this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.tooltip', val).toString() }
				}
			}
		}
	}
	get countWeekConsultation(): any[] {
		return [{
			name: this.$i18n.t('vm.root.statistics.consultations.main.legend-chart.name').toString(),
			data: [
				!this.stats || !this.stats.week1 || !this.stats.week1._0 ? 0 : this.stats.week1._0,
				!this.stats || !this.stats.week2 || !this.stats.week2._0 ? 0 : this.stats.week2._0,

				!this.stats || !this.stats.week1 || !this.stats.week1._1 ? 0 : this.stats.week1._1,
				!this.stats || !this.stats.week2 || !this.stats.week2._1 ? 0 : this.stats.week2._1,

				!this.stats || !this.stats.week1 ? 0 : (!this.stats.week1._1 ? 0 : this.stats.week1._1) + (!this.stats.week1._0 ? 0 : this.stats.week1._0),
				!this.stats || !this.stats.week2 ? 0 : (!this.stats.week2._1 ? 0 : this.stats.week2._1) + (!this.stats.week2._0 ? 0 : this.stats.week2._0),

				!this.stats || !this.stats.week1 || !this.stats.week1._2 ? 0 : this.stats.week1._2,
				!this.stats || !this.stats.week2 || !this.stats.week2._2 ? 0 : this.stats.week2._2,
			]
		}]
	}
	// get countWeekConsultation2(): any[] {
	// 	return [{
	// 		name: this.$i18n.t('vm.root.statistics.consultations.main.legend-chart.name').toString(),
	// 		data: [
	// 		]
	// 	}]
	// }
	get countWeekConsultationChartOptions(): any {
		return {
			chart: {
				type: 'bar',
				height: 350
			},
			// colors: this.stats.map(l => { return this.getColor(l.text) }),
			plotOptions: {
				bar: {
					distributed: true,
					columnWidth: '45%',
					endingShape: 'flat'
				},
			},
			dataLabels: {
				enabled: false
			},
			legend: {
				show: false
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			colors: [
				'#008ffb',
				'#008ffb',
				'#00cb8f',
				'#00cb8f',
				'#fea019',
				'#fea019',
				'#5e5e5e',
				'#5e5e5e',
			],
			xaxis: {
				categories: [
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.first', { year: this.filter.year1 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.first', { year: this.filter.year2 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.second', { year: this.filter.year1 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.second', { year: this.filter.year2 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.third', { year: this.filter.year1 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.third', { year: this.filter.year2 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.quarter', { year: this.filter.year1 }).toString(),
					this.$i18n.t('vm.root.statistics.consultations.main.week-legends.quarter', { year: this.filter.year2 }).toString(),
				],
			},
			yaxis: {
				title: {
					text: this.$i18n.t('vm.root.statistics.consultations.main.legend-chart.title').toString()
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val: number) => { return this.$i18n.tc('vm.root.statistics.consultations.main.legend-chart.tooltip', val).toString() }
				}
			}
		}
	}
	get ratioSeries(): number[] {
		return this.ratios
	}
	get totalFollowUpRatio(): number {
		return this.ratioFollowUpSeries.reduce((a, b) => a + b, 0)
	}
	get ratioFollowUpSeries(): number[] {
		return !!this.stats ? this.stats.ratio_follow_up : [0,0,0]
	}
	get ratioChartOptions(): any {
		return {
			chart: {
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			labels: this.legends.map(l => { return l.label }),
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			},
			tooltip: {
				y: {
					formatter: (val: number) => {
						let total: number = this.ratioSeries.reduce((a, b) => a + b, 0)
						let percent: number = Math.round(val * 100 / total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}
	get ratioChartOptionsFollowUp(): any {
		return {
			chart: {
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			labels: this.legendsFollowUp.map(l => { return l.label }),
			colors: this.legendsFollowUp.map(l => { return l.color }),
			legend: {
				show: false
			},
			tooltip: {
				y: {
					formatter: (val: number) => {
						let total: number = this.ratioFollowUpSeries.reduce((a, b) => a + b, 0)
						let percent: number = Math.round(val * 100 / total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}
	private get firstEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._0 ? [] : this.filterSeries(this.stats.evolution1._0)
	}
	private get nextEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._1 ? [] : this.filterSeries(this.stats.evolution1._1)
	}
	private get totalEvolution1(): number[] {
		let result: number[] = []
		for(let i: number = 0; i < Math.max(this.firstEvolution1.length, this.nextEvolution1.length); i++) {
			let first: number = this.firstEvolution1.length > i ? this.firstEvolution1[i] : 0
			let next: number = this.nextEvolution1.length > i ? this.nextEvolution1[i] : 0
			result.push(first + next)
		}
		return this.filterSeries(result)
	}
	private get firstEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._0 ? [] : this.filterSeries(this.stats.evolution2._0)
	}
	private get nextEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._1 ? [] : this.filterSeries(this.stats.evolution2._1)
	}
	private get totalEvolution2(): number[] {
		let result: number[] = []
		for(let i: number = 0; i < Math.max(this.firstEvolution2.length, this.nextEvolution2.length); i++) {
			let first: number = this.firstEvolution2.length > i ? this.firstEvolution2[i] : 0
			let next: number = this.nextEvolution2.length > i ? this.nextEvolution2[i] : 0
			result.push(first + next)
		}
		return this.filterSeries(result)
	}
	//#endregion

	mounted() {
		this.debouncerEvolution1 = new Debouncer(this.updateEvolution1, 500)
		this.debouncerEvolution2 = new Debouncer(this.updateEvolution2, 500)
		this.debouncerEvolution3 = new Debouncer(this.updateWeeks, 500)
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'consultations')
		this.initializeBreadcrumb()

		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			year1: this.filter.year1,
			year2: this.filter.year2,
			update: false
		}
		statistics.loadConsultationStatistics(payload)
		.then((stats) => {
			this.stats = stats
			this.computeData(this.stats.ratio)
			this.first = false
		})
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerEvolution1)
		Debouncer.clear(this.debouncerEvolution2)
		Debouncer.clear(this.debouncerEvolution3)
	}

	getTotalSeries1(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries1.find(e => { return e.name === legend}))
	}

	getTotalSeries2(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries2.find(e => { return e.name === legend}))
	}

	@Watch('filter.usr_id')
	@Watch('filter.ofi_id')
	updateYears() {
		this.updateStatistics(this.filter.year1, this.filter.year2, this.filter.week)
	}

	@Watch('filter.year1')
	updateYear1() {
		this.debouncerEvolution1.start()
	}

	@Watch('filter.year2')
	updateYear2() {
		this.debouncerEvolution2.start()
	}

	@Watch('filter.week')
	updateWeek() {
		this.debouncerEvolution3.start()
	}

	private updateStatistics(year1: number, year2: number, week: number) {
		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			year1: !!year1 ? year1 : -1,
			year2: !!year2 ? year2 : -1,
			week: !!week ? week : -1,
			update: true
		}
		statistics.loadConsultationStatistics(payload)
		.then((stats) => {
			console.log(payload, stats)
			if (!!year1) {
				this.stats.evolution1 = stats.evolution1
				this.stats.week1 = stats.week1
			}
			if (!!year2) {
				this.stats.evolution2 = stats.evolution2
				this.stats.week2 = stats.week2
			}
		})
	}

	private updateEvolution1() {
		this.updateStatistics(this.filter.year1, undefined, this.filter.week)
	}

	private updateEvolution2() {
		this.updateStatistics(undefined, this.filter.year2, this.filter.week)
	}

	private updateWeeks() {
		this.updateStatistics(this.filter.year1, this.filter.year2, this.filter.week)
	}

	private filterSeries(serie: number[]): number[] {
		if (!this.filter.months || !serie || serie.length < this.filter.months.length) return serie

		let result: number[] = []
		for(let i: number = 0; i < this.filter.months.length; i++) {
			result.push(this.filter.months[i] ? serie[i] : 0)
		}
		return result
	}

	private computeData(sumConsultation: number[]) {
		this.totalRatio = 0
		this.groupSum = [0,0,0,0,0,0,0,0,0,0]
		this.ratios = [0,0]
		if (!sumConsultation) return

		for(let value of sumConsultation) {
			if (value < 1) continue

			let index: number = Math.max(0, Math.min(value, 10) - 1)
			this.totalRatio += value
			this.groupSum[index] += 1
			this.ratios[0] += 1
			if (value === 1) continue

			this.ratios[1] += value - 1
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

interface IStatsConsultations {
	evolution1: {
		_0: number[],
		_1: number[],
	},
	evolution2: {
		_0: number[],
		_1: number[],
	},
	week1: {
		_0: number,
		_1: number,
		_2: number,
	},
	week2: {
		_0: number,
		_1: number,
		_2: number,
	},
	ratio: number[],
	ratio_follow_up: number[]
}
