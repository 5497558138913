<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.follow-up.title') }}</h1>
	<section>
		<dropdown-frame id="follow-up-type" v-model="configuration.follow_up_type" :items="types"
			:title="$t('vm.root.parameters.follow-up.type.title')"
			:label="$t('vm.root.parameters.follow-up.type.label')"/>

		<div v-if="canSendFollowUp">
			<input-frame type="number" min="1" max="400" id="follow-up-delay" v-model="configuration.follow_up_delay"
				:label="$t('vm.root.parameters.follow-up.delay.label')"
				:placeholder="$t('vm.root.parameters.follow-up.delay.placeholder')"
				:title="$t('vm.root.parameters.follow-up.delay.title')"/>

			<collapsible-help id="follow-up-presentation" :title="$t('vm.root.parameters.follow-up.page-presentation.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.parameters.follow-up.page-presentation.help" tag="span">
							<a :href="`//osteo2ls.com/followup/sample/${user.id}/`" target="_blank" :title="$t('vm.root.parameters.follow-up.page-presentation.link-title')">
								{{ $t('vm.root.parameters.follow-up.page-presentation.link-label') }}
							</a>
						</i18n>
					</p>
				</div>
			</collapsible-help>

			<input-document-frame id="follow-up-presentation" v-model="configuration.follow_up_presentation"
				:options="lightOptions"
				:label="$t('vm.root.parameters.follow-up.presentation.label')"
				:placeholder="$t('vm.root.parameters.follow-up.presentation.placeholder')"
				:title="$t('vm.root.parameters.follow-up.presentation.title')"/>

		</div>

		<div v-if="canSendSms">
			<collapsible-help id="follow-up-information" :title="$t('vm.root.parameters.follow-up.details.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.parameters.follow-up.details.text1.content" tag="span">
							<strong>{{ $tc('vm.root.parameters.follow-up.details.text1.strong', configuration.follow_up_delay) }}</strong>
						</i18n>
					</p>
					<i18n path="vm.root.parameters.follow-up.details.text2.content" tag="p" class="text-left">
						<strong>{{ $t('vm.root.parameters.follow-up.details.text2.strong') }}</strong>
					</i18n>

					<i18n path="vm.root.parameters.follow-up.details.text3.content" tag="p" class="text-left">
						<em>{{ $t('vm.root.parameters.follow-up.details.text3.em') }}</em>
						<strong>{{ $t('vm.root.parameters.follow-up.details.text3.strong1') }}</strong>
						<strong>{{ $t('vm.root.parameters.follow-up.details.text3.strong2') }}</strong>
					</i18n>
				</div>
			</collapsible-help>

			<div class="childs-space-between" :class="user.sms_count | smsClass">
				<i18n path="vm.root.parameters.follow-up.sms.balance.content" tag="span">
					<strong>{{ $t('vm.root.parameters.follow-up.sms.balance.strong', {'sms-count': user.sms_count}) }}</strong>
				</i18n>
				<router-link class="text-strong" :to="to" :title="$t('vm.root.parameters.follow-up.sms.title')">
					{{ $t('vm.root.parameters.follow-up.sms.label') }}
				</router-link>
			</div>

			<textarea-frame id="follow-up-text" v-model="configuration.follow_up_text"
				:label="$t('vm.root.parameters.follow-up.text.label', {length: followUpTextLength})"
				:error-test="followUpError" :error-msg="followUpErrorMessage"
				:placeholder="$t('vm.root.parameters.follow-up.text.placeholder')"
				:title="$t('vm.root.parameters.follow-up.text.title')"/>
		</div>

	</section>
</div>
