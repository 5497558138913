import Render from '@Views/root/co-working/steps/step-3.html'
import { Component, Vue } from 'vue-property-decorator'
import { Provider, PaymentMode, Profile } from '@Enums/index'
import { INotification, IAccount, IOfficeAccount, IPlanningAccount } from '@Store/types'
import { IToggleBoxItem, IDropdownItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { account, coworking, notif } from '@Store/modules'

@Render
@Component({})
export class StepThree extends Vue {

    submitted: boolean = false

	//#region Getters
    get profile(): Profile {
        return coworking.profile
    }
    get existsAccount(): boolean {
        return account.existsAccount
    }
    get shortProfile(): string {
		return EnumsHelper.profileToString(this.profile, true)
    }
    get withProvider(): boolean {
        return coworking.withProvider
    }
    get account(): IAccount {
        let defaultAccount: IAccount = {
            id: '-1',
            last_name: '',
            first_name: '',
            email: coworking.email,
            profile: Profile.Substitute,
            provider: Provider.None,
            offices:[],
            plannings:[]
        }
		return account.account || defaultAccount
    }
    get offices(): IOfficeAccount[] {
		return this.account.offices || []
	}
    get plannings(): IPlanningAccount[] {
		return this.account.plannings || []
	}
    get isMain(): boolean {
        return this.profile === Profile.Main
    }
    get isSubstitute(): boolean {
		return this.profile === Profile.Substitute
    }
    get hasLastNameError(): boolean {
		return this.submitted && (!this.account || !this.account.last_name)
	}
	get hasFirstNameError(): boolean {
		return this.submitted && (!this.account || !this.account.first_name)
	}
    get isSaving(): boolean {
        return account.isSaving
    }
    get isDeleting() {
		return account.isDeleting
    }
    get isVerifying(): boolean {
		return account.isVerifying
	}
    get billingItems() : IToggleBoxItem[] {
        return [
			{value: true, label: this.$i18n.t('vm.root.co-working.steps.step3.office.billing.choice1').toString()},
			{value: false, label: this.$i18n.t('vm.root.co-working.steps.step3.office.billing.choice2').toString()}
		]
    }
	get paymentModesItems(): IDropdownItem[] {
		return [
			{value: PaymentMode.Check, label: EnumsHelper.paymentToString(PaymentMode.Check)},
			{value: PaymentMode.CreditCard, label: EnumsHelper.paymentToString(PaymentMode.CreditCard)},
			{value: PaymentMode.FreeAct, label: EnumsHelper.paymentToString(PaymentMode.FreeAct)},
			{value: PaymentMode.Moneo, label: EnumsHelper.paymentToString(PaymentMode.Moneo)},
			{value: PaymentMode.Cash, label: EnumsHelper.paymentToString(PaymentMode.Cash)},
			{value: PaymentMode.Transfer, label: EnumsHelper.paymentToString(PaymentMode.Transfer)},
			{value: PaymentMode.Other, label: EnumsHelper.paymentToString(PaymentMode.Other)}
		]
    }
	//#endregion

    mounted() {
        if (coworking.step === 2) return

        this.$router.push({name: 'share-step-one'})
    }

    hasRetrocessionError(retrocession: number): boolean {
		return retrocession === undefined || !(retrocession >= 0 && retrocession <= 100)
    }

    submitFinalize(): void {
        this.submitted = true
        this.finalize()
    }

    submitSuppress(): void {
        this.submitted = true
        this.suppress()
    }

    previous(): void {
        coworking.previousStep()
        this.$router.push({name: 'share-step-two'})
    }

    canSuppress(): boolean {
		let a: IAccount = account.account
		return !!a && !!a.id && parseInt(a.id) !== -1
    }

    private suppress(): void {
		if (!this.canSuppress()) return

		account.delete({id: this.account.id, provider: this.account.provider}).then(() => {
            coworking.clear()
            this.suppressNotification()
            this.$router.push({name: 'share-step-one'})
        })
	}

    private finalize(): void {
        if (!this.canFinalize()) return

		const withInvitation: boolean = !this.existsAccount && this.isMain
        account.save(this.account)
        .then(() => {
            coworking.clear()
            this.finalizeNotification(withInvitation)
            this.$router.push({name: 'share-step-one'})
        })
    }

    private canFinalize(): boolean {
		const { last_name, first_name } = account.account
		return !!account.account && ((!!last_name && !!first_name) || this.isMain || this.withProvider)
    }

    private finalizeNotification(withInvitation: boolean = false) {
        let message: string = withInvitation ? this.$i18n.t('vm.root.co-working.steps.step3.success.invitation').toString() :
        this.$i18n.t('vm.root.co-working.steps.step3.success.update').toString()
        let notification: INotification = {
            message: message,
            actions: [],
            canClose: true,
            delay: 5000
        }
        notif.success(notification)
    }

    private suppressNotification() {
        let notification: INotification = {
            message: this.$i18n.t('vm.root.co-working.steps.step3.success.delete').toString(),
            actions: [],
            canClose: true,
            delay: 5000
        }
        notif.success(notification)
    }
}
