export const asideImmobilization = {
    back: {
        title: "Retourner au tableau des finances",
        label: "Retour au tableau"
    },
    delete: {
        title: "Suppression de cette immobilisation",
        'delete-msg': "Voulez-vous vraiment supprimer cette immobilisation ?",
        label: "Supprimer cette immobilisation"
    }
}
