export const contextBook = {
    title: "Recherchez",
    day: {
        label: "Date du journal des recettes",
        title: "Indiquez la date de votre journal des recettes",
        placeholder: "ex: 30 Février 2016"
    },
    next: {
        label: "jour suivant",
        title: "Jour suivant"
    },
    previous: {
        label: "jour précédent",
        title: "Jour précédent"
    }
}