<div class="input-frame" :class="{ 'is-alert': errorTest }">
    <label class="label" :for="inputId" :data-text="label">
        <span>{{label}}</span>
    </label>
    <input-password :id="inputId" :ref="inputId" v-focus="autoFocus"
        :value="password" @input="handleInput" :random-id="false" 
        :placeholder="placeholder" :title="title" :readonly="readonly" :disabled="disabled"/>
    <div class="msg-alert" v-if="displayError" v-show="errorTest">
        <div class="msg-alert-inner">{{errorMsg}}</div>
    </div>
</div>
