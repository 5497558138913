import Render from '@Views/root/patients/patient/context-main.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { groupBy } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { TimelineItem } from './timeline/timeline-item'
import { IPopin, IPopinAction, IConfiguration, IColor, ITimelineItem, ITimeLineFilter, IPatient, IConsultation, IAttachment, ISphere, IAntecedent, IPediatrics, ICoordinates } from '@Store/types'
import { StorageHelper } from '@Helpers/index'
import {Patient, Common, Debouncer} from '@Models/index'
import { Gender } from '@Enums/index'
import {configuration, patient, popIn, timeline} from '@Store/modules'

@Render
@Component({
	components: {
		'timeline-item': TimelineItem
	}
})
export class ContextMain extends Vue {

	open: boolean = false
	query: string = ''
    filter: ITimeLineFilter = {
        consultations: true,
        antecedents: true,
        spheres: true,
        attachments: true,
        pediatrics: true
	}
	private filteredItems: ITimelineItem[] = []
	private filteredFamilyItems: ITimelineItem[] = []
	private activateItems: string[] = []
	private activateFamilyItems: string[] = []
	private debouncer: Debouncer = null

	//#region Getters
	get patient(): IPatient {
		return patient.patient
	}
	get coordinates(): ICoordinates {
		return patient.coordinates
	}
	get pediatrics(): IPediatrics {
		return patient.pediatrics
	}
	get spheres(): ISphere[] {
		return patient.spheres
	}
	get antecedents(): IAntecedent[] {
		return patient.antecedents
	}
	get attachments(): IAttachment[] {
		return patient.attachments
	}
	get consultations(): IConsultation[] {
		return patient.consultations
	}
	get colorManager(): IColor {
		return configuration.configuration && configuration.configuration.colors
  	}
	get patientName() {
		let name = !!this.patient && `${this.patient.coordinates.first_name} ${this.patient.coordinates.last_name}`.trim()
		return !name ? 'Nouveau patient' : name
	}
	get gender(): Gender {
		return this.patient.coordinates.gender
	}
	get filterCount(): number {
		let count: number = 0
		for(let key of Object.keys(this.filter)) {
			if (key === 'tag') continue
			count = !this.filter[key] ? count + 1 : count
		}
		return count
	}
	get hasItems(): boolean {
		let expectedLength: number = !!this.patient.coordinates.birth_date ? 0 : 1
		return (!!this.items && Object.keys(this.items).length > expectedLength) || (!!this.familyItems && this.familyItems.length > 0)
	}
	get items(): Dictionary<ITimelineItem[]> {
		let items: ITimelineItem[] = this.filteredItems
		.filter(i => this.filterItem(i))
		.sort((a: ITimelineItem, b: ITimelineItem) => { return new Date(b.date).getTime() - new Date(a.date).getTime() })

		return groupBy(items, 'age')
	}
	get familyItems(): ITimelineItem[] {
		let items: ITimelineItem[] = this.filteredFamilyItems
		.filter(i => this.filterItem(i))
		.sort((a: ITimelineItem, b: ITimelineItem) => { return new Date(b.date).getTime() - new Date(a.date).getTime() })

		return items
	}
	get expanded(): boolean {
		return !!this.filteredItems.find(i => { return i.active }) || !!this.filteredFamilyItems.find(i => { return i.active })
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get isActivate(): (id: string, list: string[]) => boolean {
		return (id:string, list: string[]) => {
			if (!list) return false

			return list.filter(i => i === id).length > 0
		}
	}
	//#endregion

	mounted() {
		this.filter = StorageHelper.get('timeline-filter', this.filter)
		this.debouncer = new Debouncer(this.updateFilterItemsDebounce)
		this.updateFilteredItems()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	expandAll() {
		let expanded:boolean = this.expanded
		this.filteredItems.forEach(i => { i.active = !expanded })
		this.filteredFamilyItems.forEach(i => { i.active = !expanded })
	}

	addAntecedent(): void {
		let addAntecedentAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.context.new-item.add').toString(),
			title: this.$i18n.t('vm.root.patients.patient.context.new-item.title').toString(),
			callback: (data: IAntecedent) => {
				if (!this.canSaveAntecedent(data)) return Promise.reject()

				this.$emit('edit')
				data.edit = true
				this.antecedents.push(data)
				return Promise.resolve()
			}
		}

		let antecedent: IAntecedent = Patient.createNewAntecedent(Common.generateId(), this.configuration)

		let createAntecedent: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.context.new-item.new-antecedent').toString(),
			content: {component: 'popin-create-antecedent'},
			action: addAntecedentAction,
			options: antecedent,
			isWide: true
		}

		popIn.info(createAntecedent)
	}

	addSphere(): void {
		let addSphereAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.patient.context.new-item.add').toString(),
			title: this.$i18n.t('vm.root.patients.patient.context.new-item.title').toString(),
			callback: (data: ISphere) => {
				if (!this.canSaveSphere(data)) return Promise.reject()

				this.$emit('edit')
				data.edit = true
				this.spheres.push(data)
				return Promise.resolve()
			}
		}

		let sphere: ISphere = Patient.createNewSphere(Common.generateId(), this.configuration)

		let createSphere: IPopin = {
			title: this.$i18n.t('vm.root.patients.patient.context.new-item.new-sphere').toString(),
			content: {component: 'popin-create-sphere'},
			action: addSphereAction,
			options: sphere,
			isWide: true
		}

		popIn.info(createSphere)
	}

	@Watch('coordinates.birth_date')
	@Watch('consultations', {deep: true})
	@Watch('antecedents', {deep: true})
	@Watch('spheres', {deep: true})
	@Watch('attachments', {deep: true})
	@Watch('pediatrics', {deep: true})
	private updateFilteredItems() {
		this.debouncer.start()
	}

	@Watch('filter', {deep: true})
	private saveFilter() {
		this.updateFilteredItems()
		StorageHelper.set('timeline-filter', this.filter)
	}

	private canSaveAntecedent(antecedent: IAntecedent): boolean {
		return !(!antecedent.type || antecedent.type.length === 0 || !antecedent.title || !antecedent.event_date)
	}

	private canSaveSphere(sphere: ISphere): boolean {
		return !(!sphere.type || sphere.type.length === 0 || !sphere.title || !sphere.event_date)
	}

	private filterItem(item: ITimelineItem): boolean {
        if (!this.query || this.query.trim() === '') return true

        let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.query.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!item.title && item.title.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.sub_title && item.sub_title.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.description && item.description.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.examen && !!item.examen.evolution && item.examen.evolution.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.examen && !!item.examen.observation && item.examen.observation.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.examen && !!item.examen.diagnostic && item.examen.diagnostic.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.examen && !!item.examen.tests && item.examen.tests.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.examen && !!item.examen.treatment && item.examen.treatment.toLowerCase().includes(filter)) {
				countResult++
            }
		}
		return countFilter === countResult
	}

	private updateFilterItemsDebounce(): void {
		this.updateActivateItems()
		this.updateActivateFamilyItems()
		this.filteredItems = timeline.timelineItems(this.filter)
		this.filteredItems.forEach(i => {
			i.active = this.isActivate(i.id, this.activateItems)
		})
		this.filteredFamilyItems = timeline.familyItems(this.filter)
		this.filteredFamilyItems.forEach(i => {
			i.active = this.isActivate(i.id, this.activateFamilyItems)
		})
	}

	private updateActivateItems() {
		if (!this.filteredItems) return

		this.activateItems = this.filteredItems.filter(i => i.active).map(i => i.id)
	}

	private updateActivateFamilyItems() {
		if (!this.filteredFamilyItems) return

		this.activateFamilyItems = this.filteredFamilyItems.filter(i => i.active).map(i => i.id)
	}
}
