import { getModule, VuexModule, Module, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IBreadcrumbItem } from "@Store/types"

@Module({
	name: 'breadcrumb',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class BreadcrumbModule extends VuexModule {
	items: IBreadcrumbItem[] = []
    linkedToContext: boolean = true
	hasAside: boolean = true
		
	@Mutation
	clear() {
		this.items = []
		this.linkedToContext = true
		this.hasAside = true
	}

	@Mutation
	updateItems(items: IBreadcrumbItem[]) {
		this.items = items
		let title = ""
		for(let item of items) {
			title += `${item.label} > `
		}
		document.title = `${title}Osteo2ls`
	}

	@Mutation
	updateLinked(link: boolean) {
		this.hasAside = true
		this.linkedToContext = link
	}

	@Mutation
	updateAside(hasAside: boolean) {
		this.hasAside = hasAside
	}
}

export const breadcrumb = getModule(BreadcrumbModule)
// export const breadcrumb = new BreadcrumbModule({ store, name: "breadcrumb" })