<div v-if="consultation">
	<h3>{{ $t('vm.root.patients.patient.schema.title') }}</h3>
	<div v-if="readOnly">
		<p class="text-pre-line" v-if="!!schema.description">
			{{ schema.description }}
		</p>
	</div>
	<div v-else>
		<textarea-frame v-if="!readOnly" id="description" v-model="schema.description"
			:label="$t('vm.root.patients.patient.schema.description.label')"
			:placeholder="$t('vm.root.patients.patient.schema.description.placeholder')"
			:title="$t('vm.root.patients.patient.schema.description.title')"/>
	</div>

	<div class="drawer" :class="{'is-readonly': readOnly}">

		<div v-if="!readOnly" class="drawer-toolbar toolbar marg-t text-center">
			<div class="form-group toolbar-control">
				<input-color id="pencil-color" class="form-group-input" v-model="options.color" :label="$t('vm.root.patients.patient.schema.color')"/>
				<togglebox class="form-group-input" v-model="options.size" :items="sizeItems" id="size"/>
				<button
					type="button"
					class="input-like form-group-input"
					:class="{'is-selected': options.with_body}"
					@click="options.with_body = !options.with_body"
					:title="`${options.with_body ? $t('vm.root.patients.patient.schema.body.hide') : $t('vm.root.patients.patient.schema.body.show')}`"
				>
					<svg width="1em" class="svg-block" viewBox="0 0 256 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#male"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.patients.patient.schema.body.label') }}</span>
				</button>
				<button
					type="button"
					class="input-like form-group-input"
					:class="{'is-selected': options.with_backbone}"
					@click="options.with_backbone = !options.with_backbone"
					:title="`${options.with_backbone ? $t('vm.root.patients.patient.schema.backbone.hide') : $t('vm.root.patients.patient.schema.backbone.show')}`"
				>
					<svg width="1em" class="svg-block" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#skeleton"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.patients.patient.schema.backbone.label') }}</span>
				</button>
				<delete-item :can-delete="true" :disabled="!canDelete" btn-class="input-like form-group-input is-flex-fixed"
					:title="$t('vm.root.patients.patient.schema.clear.title')"
					:delete-msg="$t('vm.root.patients.patient.schema.clear.delete-msg')"
					:delete-label="$t('vm.root.patients.patient.schema.clear.delete-label')"
					:delete-action="removeAllHistory">
					<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
					</svg>
					<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.schema.clear.label') }}</span>
				</delete-item>
			</div>
		</div>

		<p v-if="!readOnly" class="notice-info drawer-notice">{{ $t('vm.root.patients.patient.schema.mobile-disabled') }}</p>

		<div class="childs-space-between is-overflow-x scrollbar">
			<drawer class="marg-auto marg-v" id="face" ref="face" :history="consultation.schema.face" :options="options"/>
			<drawer class="marg-auto marg-v" id="back" ref="back" :history="consultation.schema.back" :options="options"/>
			<drawer class="marg-auto marg-v" id="right" ref="right" :history="consultation.schema.right" :options="options"/>
			<drawer class="marg-auto marg-v" id="left" ref="left" :history="consultation.schema.left" :options="options"/>
		</div>
	</div>
</div>
