import Render from '@Views/root/accounting/receipt/details.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDropdownItem, IToggleBoxItem, InputDateType } from '@Components/index'
import { IConsultation, IInvoice, IOffice, ISession } from '@Store/types'
import { ReceiptType, PaymentMode } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { PatientItem } from '@ViewModels/root/search/patient'
import { office } from '@Store/modules'

@Render
@Component({
	components: {
		'patient-item': PatientItem
	}
})
export class Details extends Vue {

	ses_id: string = '-1'
	private oldTtc: number
	private sessions: ISession[] = []

	@Prop() receipt: IInvoice
	@Prop() submitted: boolean
	@Prop({default: false}) light: boolean
	@Prop({default: true}) withPatient: boolean
	@Prop({default: null}) consultation: IConsultation

	// @Getter('office/office') office: (ofi_id: string) => IOffice
	// @Getter('office/offices') offices: () => IOffice[]
	// @Getter('office/sessions') getSessions: (ofi_id: string, usr_id: string) => ISession[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean

	//#region Getters
	get hasLabelError(): boolean {
		return this.submitted && !this.receipt.label
	}
	get isFreeAct(): boolean {
		return this.receipt.mode === PaymentMode.FreeAct
	}
	get dateType(): InputDateType.Calendar {
		return InputDateType.Calendar
	}
	get isRetrocession(): boolean {
		return this.receipt.is_retrocession ||
			this.receipt.retrocession > 0 ||
			this.receipt.receipt_type === ReceiptType.Receipt3
	}
	get withSessions(): boolean {
		return !!this.consultation
	}
	get sessionItems(): IDropdownItem[] {
		if (!this.withSessions) return []

		this.sessions = office.sessions(this.consultation.ofi_id, this.consultation.usr_id)
		if (!this.sessions || this.sessions.length === 0) 
			return [{value: '-1', label: this.$i18n.t('vm.root.accounting.receipt.details.session.none').toString()}]

		return this.sessions
		.map(s => {
			let item: IDropdownItem = { value: s.id, label: s.name }
			if (s.with_color && !!s.color) {
				item.icon = 'circle'
				item.color = s.color
			}
			return item
		})
	}
	
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		let ofi_id: string = parseInt(this.receipt.ofi_id) === -1 ? undefined : this.receipt.ofi_id
		let offices: IOffice[] = office.offices
		for (let _office of offices) {
			if (!office.isActive(_office) && _office.id !== ofi_id) continue

			result.push({value: _office.id, label: _office.coordinates.name})
		}

		return result
	}
	get paymentModeItems(): IToggleBoxItem[] {
		return [
			{value: PaymentMode.Check, label: EnumsHelper.paymentToString(PaymentMode.Check)},
			{value: PaymentMode.Cash, label: EnumsHelper.paymentToString(PaymentMode.Cash)},
			{value: PaymentMode.CreditCard, label: EnumsHelper.paymentToString(PaymentMode.CreditCard)},
			{value: PaymentMode.FreeAct, label: EnumsHelper.paymentToString(PaymentMode.FreeAct)},
			{value: PaymentMode.Moneo, label: EnumsHelper.paymentToString(PaymentMode.Moneo)},
			{value: PaymentMode.Transfer, label: EnumsHelper.paymentToString(PaymentMode.Transfer)},
			{value: PaymentMode.Other, label: EnumsHelper.paymentToString(PaymentMode.Other)},
			{value: PaymentMode.Waiting, label: EnumsHelper.paymentToString(PaymentMode.Waiting)}
		]
	}
	get receiptTypeItems(): IToggleBoxItem[] {
		return [
			{value: ReceiptType.Receipt1, label: EnumsHelper.receiptTypeToString(ReceiptType.Receipt1)},
			{value: ReceiptType.Receipt2, label: EnumsHelper.receiptTypeToString(ReceiptType.Receipt2)},
			{value: ReceiptType.Receipt3, label: EnumsHelper.receiptTypeToString(ReceiptType.Receipt3)},
			{value: ReceiptType.Receipt5, label: EnumsHelper.receiptTypeToString(ReceiptType.Receipt5)},
			{value: ReceiptType.Receipt6, label: EnumsHelper.receiptTypeToString(ReceiptType.Receipt6)},
			{value: ReceiptType.ReceiptOther, label: EnumsHelper.receiptTypeToString(ReceiptType.ReceiptOther)},
		]
	}
	//#endregion

	mounted() {
		if (!this.receipt) return

		this.oldTtc = this.receipt.ttc
		this.ses_id = !!this.receipt.ses_id && parseInt(this.receipt.ses_id) !== -1 ? this.receipt.ses_id : '-1'
	}

	@Watch('receipt.mode')
	updateMode() {
		if (this.receipt.mode === PaymentMode.FreeAct) {
			let oldTtc: number = this.oldTtc
			this.receipt.ttc = 0
			setTimeout(() => { this.oldTtc = oldTtc }, 10)
		} else {
			this.receipt.ttc = this.oldTtc
		}
	}

	@Watch('receipt.ofi_id')
	updateOffice(): void {
		let _office: IOffice = office.office(this.receipt.ofi_id)
		this.receipt.mode = !!_office ? _office.coordinates.mode : PaymentMode.Check
	}

	@Watch('receipt.ttc')
	updateTtc(): void {
		this.oldTtc = this.receipt.ttc
	}

	@Watch('ses_id')
	updateAmount(): void {
		let _office: IOffice = office.office(this.receipt.ofi_id)
		let ttc: number = !!_office ? _office.coordinates.ttc : 0

		if (!!this.sessions) {
			let session: ISession = this.sessions.find(s => { return s.id === this.ses_id })
			if (!!session) {
				ttc = session.ttc
			}
		}
		
		this.receipt.ttc = ttc
	}
}