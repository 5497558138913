<div v-if="relaunch">

	<collapsible-help id="relaunch-patient-information" :title="$t('vm.root.patients.patient.relaunch.title')" tag="h2">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
		<div class="notice-info">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				{{ $t('vm.root.patients.patient.relaunch.help1') }}
			</p>
			<p class="text-left">{{ $t('vm.root.patients.patient.relaunch.help2') }}</p>
			<i18n path="vm.root.patients.patient.relaunch.help3.text" tag="p" class="text-left">
				<a href="//osteo2ls.com/rdv/" :title="$t('vm.root.patients.patient.relaunch.help3.title')" target="_blank">{{ $t('vm.root.patients.patient.relaunch.help3.link') }}</a>
			</i18n>
			<i18n path="vm.root.patients.patient.relaunch.help4.text" tag="p" class="text-left">
				<strong>{{ $t('vm.root.patients.patient.relaunch.help4.strong') }}</strong>
				<span class="text-xs">{{ $t('vm.root.patients.patient.relaunch.help4.span') }}</span>
			</i18n>
		</div>
	</collapsible-help>

	<div v-if="readOnly" class="marg-v">
		<p v-if="isRelaunchActive">
			{{ $t('vm.root.patients.patient.relaunch.read-only.summary', {delay: relaunch.delay}) }}

			<ul class="ul-reset">
				<li v-if="relaunchByEmail">
					&bull; {{ $t('vm.root.patients.patient.relaunch.read-only.by-email') }}
				</li>
				<li v-if="relaunchBySms">
					&bull; {{ $t('vm.root.patients.patient.relaunch.read-only.by-sms') }}
				</li>
			</ul>
		</p>
		<div v-else class="marg-v text-center">
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
			</svg>
			<p>
				<span class="text-lg">{{ $t('vm.root.patients.patient.relaunch.none') }}</span>
			</p>
		</div>
	</div>
	<div v-else>
		<div class="grid-2">
			<dropdown-frame id="type" v-model="relaunch.type" :items="relaunchItems" :disabled="disabled && !useActivePlanning"
				:label="$t('vm.root.patients.patient.relaunch.type')"/>

			<input-frame v-if="isRelaunchActive" type="number" id="delay"
				:label="$t('vm.root.patients.patient.relaunch.delay.label', {delay: relaunch.delay})"
				v-model="relaunch.delay" min="1" max="400" :disabled="disabled"
				:placeholder="$t('vm.root.patients.patient.relaunch.delay.placeholder')"
				:error-test="hasDelayError" :error-msg="$t('vm.root.patients.patient.relaunch.delay.error')"
				:title="$t('vm.root.patients.patient.relaunch.delay.title')"/>
		</div>
	</div>
</div>
