<div v-if="consultation">
	<div class="childs-space-between marg-v">
		<h3 class="text-uppercase-first-letter no-marg">{{ $t('vm.root.patients.patient.tests.title') }}</h3>
		<button type="button" class="btn--link-like is-pushed-right" @click="addExclusionTest" :title="$t('vm.root.patients.patient.tests.new-test.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			{{ $t('vm.root.patients.patient.tests.new-test.text') }}
		</button>
	</div>

	<ul v-for="test in consultation.exclusion_tests.currents" :key="getId(test)" class="ul-reset">
		<exclusion-test v-if="test.edit && !readOnly" :test="test" :consultation="consultation" :read-only="readOnly" :submitted="submitted"/>
		<li v-else>
			<div class="collapsible-panel bordered-panel marg-v">
				<div class="childs-space-between">
					<button type="button" class="btn--link-like collapsible-panel-trigger text-bold text-inherit"
						@click="toggle(getId(test))" :title="$t('vm.root.patients.patient.tests.detail-test.title')"
						:class="{'is-active': isToggle(getId(test))}">
						<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
							<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
						</svg>
						<span class="text-uppercase-first-letter">{{test.name}}</span>
					</button>
					<span class="is-pushed-right text-uppercase-first-letter">
							{{test.positive ? $t('general.positive') : $t('general.negative') }}
					</span>
				</div>

				<div class="collapsible-panel-container"  :class="{'is-active': isToggle(getId(test))}">
					<div class="collapsible-panel-content">
						<p class="no-marg">{{test.comment}}</p>
						<div class="text-right">
							<span>
								<button type="button" @click="editExclusionTest(test)" :title="$t('general.edit.title')" class="btn--link-like">{{ $t('general.edit.text') }}</button>
								<span v-if="!readOnly && isMainUser">&bull;
									<delete-item class="btn btn--link-like text-danger" :can-delete="true"
										:delete-action="() => deleteTest(test)">
										{{ $t('general.delete.text') }}
									</delete-item>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</li>
	</ul>

	<div v-if="consultation.exclusion_tests.currents.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.tests.none.title') }}</span><br>
			<i18n v-if="!readOnly" path="vm.root.patients.patient.tests.none.description.text" tag="span">
				<button type="button" class="btn--link-like" @click="addExclusionTest" :title="$t('vm.root.patients.patient.tests.none.description.title')">{{ $t("vm.root.patients.patient.tests.none.description.link") }}</button>
			</i18n>
		</p>
	</div>

</div>
