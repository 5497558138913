<context>
	<div class="wrapper">
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t("vm.root.patients.context.search.title") }}</strong></h2>
			<i18n path="vm.root.patients.context.search.paragraph1.text" tag="p">
				<strong>{{ $t("vm.root.patients.context.search.paragraph1.strong") }}
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#filter"/>
					</svg>
				</strong>
			</i18n>
			<i18n path="vm.root.patients.context.search.paragraph2.text" tag="p">
				<strong><code>{{ $t("vm.root.patients.context.search.paragraph2.sample1") }}</code></strong>
				<strong><code>{{ $t("vm.root.patients.context.search.paragraph2.sample2") }}</code></strong>
			</i18n>
			<i18n path="vm.root.patients.context.search.paragraph3.text" tag="p">
				<router-link :to="{name: 'patient-coordinates'}" :title="$t('vm.root.patients.context.search.paragraph3.title')">{{ $t("vm.root.patients.context.search.paragraph3.link") }}</router-link>
			</i18n>
		</section>
		<hr>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t("vm.root.patients.context.export.title") }}</strong></h2>
			<i18n path="vm.root.patients.context.export.paragraph.text" tag="p">
				<router-link :to="{name: 'patients-export'}" :title="$t('vm.root.patients.context.export.paragraph.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#clipboard-list-check"/>
					</svg>
					{{ $t("vm.root.patients.context.export.paragraph.link") }}
				</router-link>
				<abbr title="Comma-Separated Values" lang="en">{{ $t("vm.root.patients.context.export.paragraph.abbr") }}</abbr>
			</i18n>
		</section>
		<hr>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t("vm.root.patients.context.campaign.title") }}</strong></h2>
			<i18n path="vm.root.patients.context.campaign.paragraph.text" tag="p">
				<abbr title="Short Message Service" lang="en">{{ $t("vm.root.patients.context.campaign.paragraph.abbr") }}</abbr>
				<router-link :to="{name: 'patients-campaigns'}" :title="$t('vm.root.patients.context.campaign.paragraph.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#comment-alt-lines"/>
					</svg>
					{{ $t("vm.root.patients.context.campaign.paragraph.link") }}
				</router-link>
			</i18n>
		</section>
	</div>
</context>
