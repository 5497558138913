import Render from '@Views/root/patients/patient/pediatrics/forms.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IBreadcrumbItem, IForm, IPediatrics } from '@Store/types'
import { CustomForm } from '@Components/forms/custom-form'
import { ReadOnlyForms } from '@Components/forms/read-only-forms'
import { breadcrumb, patient } from '@Store/modules'

@Render
@Component({
	components: {
		'custom-form': CustomForm,
		'read-only': ReadOnlyForms
	}
})
export class Forms extends Vue {

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/pediatrics') pediatrics: IPediatrics

	get pediatrics(): IPediatrics {
		return patient.pediatrics
	}
	get forms(): IForm[] {
		return this.pediatrics.forms
	}
	
	mounted() {
		this.$emit('navigate', 'pediatrics')
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.pediatrics').toString(), link: {name: "patient-pediatrics", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.forms', this.forms.length).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}