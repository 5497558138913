import { BaseService } from '@Services/index'

export class StatisticsService extends BaseService {

	public getPatientStatistics(ofiId: string, accId: string, year1: number, year2: number, update: boolean): Promise<any> {
		return super.get(`/statistics/patient/`, {ofi_id: ofiId, acc_id: accId, year1: year1, year2: year2, update: update})
	}
	public getConsultationStatistics(ofiId: string, accId: string, year1: number, year2: number, week: number, update: boolean): Promise<any> {
		return super.get(`/statistics/consultation/`, {ofi_id: ofiId, acc_id: accId, year1: year1, year2: year2, week: week, update: update})
	}
	public getPlanningStatistics(ofiId: string, accId: string, plaId: string, year1: number, year2: number, update: boolean): Promise<any> {
		return super.get(`/statistics/planning/`, {ofi_id: ofiId, acc_id: accId, pla_id: plaId, year1: year1, year2: year2, update: update})
	}
	public getPlanningOnlineStatistics(year1: number, year2: number, update: boolean): Promise<any> {
		return super.get(`/statistics/planning-online/`, {year1: year1, year2: year2, update: update})
	}
	public getAccountingStatistics(ofiId: string, year1: number, year2: number, update: boolean): Promise<any> {
		return super.get(`/statistics/accounting/`, {ofi_id: ofiId, year1: year1, year2: year2, update: update})
	}
	public getTop10Statistics(ofiId: string): Promise<any> {
		return super.get(`/statistics/top10/`, {ofi_id: ofiId})
	}
	public getReferenceStatistics(): Promise<any> {
		return super.get(`/statistics/reference/`)
	}
	public getColleagueStatistics(): Promise<any> {
		return super.get(`/statistics/colleague/`)
	}
}
