<section v-if="!!link" class="semi-marg-v person-item">
    <span class="person-item-header text-capitalize text-inherit">
        <figure class="person-item-avatar">
            <avatar :first-name="link.coordinates.first_name" :last-name="link.coordinates.last_name" :email="link.email" :gender="link.coordinates.gender"/>
        </figure>
        <h3 class="h-reset person-item-name">{{ link.coordinates.first_name }}<br><b>{{ link.coordinates.last_name }}</b></h3>
    </span>
    <div class="person-item-border marquee">
        <span class="marquee-inner">
            <span class="marquee-content">{{ link.email }}</span>
        </span>
    </div>
    <div class="person-item-border text-primary">
        <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${$options.filters.profileIcon(link.profile)}`"/>
        </svg> {{ link.profile | profile }}
    </div>
    <div v-if="canUpdate" class="person-item-border text-right">
        <button type="button" class="btn btn--link-like" @click="update(link)" :title="$t('vm.root.co-working.context.update-title')">
            <spinner v-if="isVerifying"/>
            <svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
            </svg>
            {{ $t('general.edit.text') }}
        </button>
    </div>
</section>
