export class Debouncer {

    delay: number
    private readonly callback: any
    private debouncer: any = null

    constructor(callback: any, delay: number = 600) {
        this.callback = callback
        this.delay = delay
    }

    start(canStart: boolean = true): void {
        Debouncer.clear(this)
        if (!canStart || !this.callback) return

        this.debouncer = setTimeout(() => { this.callback() }, this.delay)
    }

    static clear(debouncer: Debouncer): void {
        if (!debouncer || !debouncer.debouncer) return

        clearTimeout(debouncer.debouncer)
    }
}
