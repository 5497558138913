export const consultations = {
    title: "Consultations",
    'create-by': "créé par {owner}",
    'new-consultation': {
        title: "Créer une nouvelle consultation",
        text: "Nouvelle consultation"
    },
    none: {
        title: "Pas de consultation.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer une nouvelle consultation",
            link: "une nouvelle consultation"
        }
    }
}