export const forms = {
    title: "Questionnaire personnalisé|Questionnaire personnalisé|Questionnaires personnalisés",
    delete: {
        label: "supprimer", 
        title: "Suppression", 
        content: "Voulez-vous vraiment supprimer cet élément ?"
    },
    'create-form': {
        title: "Créez un nouveau questionnaire personnalisé",
        label: "nouveau questionnaire"
    },
    'create-question': {
        title: "Ajoutez une nouvelle question à ce formulaire",
        label: "nouvelle question"
    },
    'create-choice': {
        title: "Ajoutez un nouveau choix à cette question",
        label: "nouveau choix"
    },
    'move-form': {
        title: "Déplacez ce questionnaire",
        label: "Déplacer",
        less: "Réduire ce questionnaire",
        more: "Développer ce questionnaire",
    },
    'move-question': {
        title: "Déplacez cette question",
        label: "Déplacer",
        less: "Réduire cette question",
        more: "Développer cette question",
    },
    'move-choice': {
        title: "Déplacez ce choix",
        label: "Déplacer"
    },
    'delete-form': {
        title: "Supprimer ce questionnaire",
        label: "supprimer"
    },
    'delete-question': {
        title: "Supprimer cette question",
        label: "supprimer"
    },
    'delete-choice': {
        title: "Supprimer ce choix",
        label: "supprimer"
    },
    'form-name': {
        label: "Nom du questionnaire personnalisé",
        title: "Veuillez indiquer le nom du questionnaire"
    },
    'form-target': {
        title: "Sélectionnez la destination de ce questionnaire personnalisé",
        label: "Destination du questionnaire personnalisé",
    },
    'question-title': {
        label: "Intitulé de la question",
        title: "Veuillez indiquer l'intitulé de la question"
    },
    'question-type': {
        label: "Type de la question",
        title: "Veuillez sélectionner le type de la question"
    },
    'new-form': "nouveau questionnaire {index}",
    'new-question': "nouvelle question {index}",
    'new-choice': "nouveau choix {index}",
    'question-list': "Liste des questions",
    'choice-list': "Liste des choix pour cette question",
    'choice-label': {
        label: "Libellé du choix {index}",
        placeholder: "ex : droitier",
		title: "Veuillez indiquer le libellé du choix"
    },
    'no-form': {
        content: "Aucun questionnaire personnalisé pour le moment.",
        'button-title': "Ajoutez un questionnaire personnalisé pour vos dossiers patients",
        'button-label': "créer un questionnaire"
    },
    'no-question': {
        content: "Aucune question pour le moment : {0}.",
        'button-title': "Ajoutez une question à votre formulaire",
        'button-label': "ajouter une question"
    },
    'no-choice': {
        content: "Aucun choix pour le moment : {0}.",
        'button-title': "Ajoutez un choix à votre question",
        'button-label': "ajouter un choix"
    }
}