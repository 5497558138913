<div class="input-frame">
    <label class="label" for="search-member" :data-text="$t('vm.root.search.member.search.label')">{{ $t('vm.root.search.member.search.label') }}</label>

    <div class="form-group">
        <div class="with-icon">
            <svg width="1em" class="with-icon-icon" :class="{'is-shown': !false}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
            </svg>
            <span class="with-icon-icon" :class="{'is-shown': false}">
                <spinner class="text-primary"/>
            </span>
            <input class="form-group-input" id="search-member" @click="searchMember"
                :title="$t('vm.root.search.member.search.title')"
                :placeholder="$t('vm.root.search.member.search.placeholder')">
        </div>
    </div>
</div>