<div v-if="antecedent" :ref="elementId">
	<div class="grid-2">
		<dropdown-editable-frame :id="`type-${elementId}`" :items="antecedentTags" :taggable="true" v-model="antecedent.type"
			:label="$t('vm.root.patients.patient.antecedent.type.label')"
			:no-options-label="$t('vm.root.patients.patient.antecedent.type.no-options-label')"
			:error-test="hasTypeError" :error-msg="$t('vm.root.patients.patient.antecedent.type.error')"
			:placeholder="$t('vm.root.patients.patient.antecedent.type.placeholder')"
			:title="$t('vm.root.patients.patient.antecedent.type.title')"/>


		<input-frame :id="`title-${elementId}`" v-model="antecedent.title"
			:label="$t('vm.root.patients.patient.antecedent.title.label')" 
			:placeholder="$t('vm.root.patients.patient.antecedent.title.placeholder')"
			:error-test="hasTitleError" :error-msg="$t('vm.root.patients.patient.antecedent.title.error')"
			:title="$t('vm.root.patients.patient.antecedent.title.title')"/>

		<togglebox-frame :id="`state-${elementId}`" :items="stateItems" v-model="state"
			:label="$t('vm.root.patients.patient.antecedent.state_date.label')"/>

		<toggledate-frame v-if="isThereIs" :id="`event-date-1-${elementId}`" :date="toDate" @input="updateTo" :min-date="birthDate"
			:placeholder="$t('vm.root.patients.patient.antecedent.there_is_placeholder')" :label="thereIsLabel"/>

		<toggledate-frame v-else-if="isAtTheAge" :id="`event-date-2-${elementId}`" :from="birthDate" :date="fromDate" @input="updateFrom" :min-date="birthDate"
			:placeholder="$t('vm.root.patients.patient.antecedent.at_the_age_placeholder')" :label="atTheAgeLabel"/>

		<input-date-frame v-else-if="isDate" v-model='eventDate' :id="`event-date-3-${elementId}`" :type="eventDateType" :label="dateLabel"
			:title="$t('vm.root.patients.patient.antecedent.date.title')" 
			:placeholder="$t('vm.root.patients.patient.antecedent.date.placeholder')"
			:error-test="hasEventDateError" :error-msg="$t('vm.root.patients.patient.antecedent.date.error')"/>

		<input-frame v-else type="number" :value='eventYear' @input="updateYear" :id="`event-date-4-${elementId}`" :label="atYearLabel"
			:min="birthDate.getFullYear()" :max="new Date().getFullYear()"
			:title="$t('vm.root.patients.patient.antecedent.at_year.title')" 
			:placeholder="$t('vm.root.patients.patient.antecedent.at_year.placeholder')"
			:error-test="hasEventDateError" :error-msg="$t('vm.root.patients.patient.antecedent.at_year.error')"/>

		<textarea-frame :id="`description-${elementId}`" v-model="antecedent.description" class="grid-item-full" rows="2" 
			:label="$t('vm.root.patients.patient.antecedent.description.label')" 
			:placeholder="$t('vm.root.patients.patient.antecedent.description.placeholder')" 
			:title="$t('vm.root.patients.patient.antecedent.description.placeholder')"/>

		<togglebox-frame :id="`important-${elementId}`" :items="importantItems" v-model="antecedent.important"
			:label="$t('vm.root.patients.patient.antecedent.important_label')"/>
		<togglebox-frame :id="`periodic-${elementId}`" :items="periodictyItems" v-model="antecedent.periodic"
			:label="$t('vm.root.patients.patient.antecedent.periodicity.label')"/>

		<togglebox-frame v-if="antecedent.periodic" :id="`running-${elementId}`" :items="runningItems" v-model="antecedent.running"
			:label="$t('vm.root.patients.patient.antecedent.periodic_label')"/>

		<toggledate-frame v-if="antecedent.periodic && !antecedent.running" :id="`end-date-${elementId}`" 
			:from="antecedent.event_date" :date='endDate' @input="updateEnd" :max-date="new Date()"
			:label="$t('vm.root.patients.patient.antecedent.end_date.label')"
			:placeholder="$t('vm.root.patients.patient.antecedent.end_date.placeholder')" />
	</div>

	<custom-form v-for="form in forms" :key="form.id" :form="form"/>

	<div class="text-right" v-if="isMainUser && canDelete">
		<delete-item class="btn btn--link-like text-danger" :can-delete="true"
			:delete-action="submitDelete">
			{{ $t('general.delete.text') }}
		</delete-item>
	</div>
</div>
