export const rendezVous = {
    label: {
        label: "Intitulé du rdv",
        placeholder: "ex : Mal au dos",
        title: "Veuillez renseigner l'intitulé du rdv"
    },
    'reminder-type': "Type de rappel du rendez-vous",
    'confirmation-type': "Type de confirmation du rendez-vous",
    office: {
        label: "Cabinet lié à ce rdv",
        title: "Indiquez le cabinet lié à ce rdv",
        none: "Aucun cabinet"
    },
    session: {
        label: "Type de consultation",
        title: "Indiquez le type de consultation de la future consultation"
    },
    planning: {
        label: "Planning lié à ce rdv",
        title: "Sélectionnez le planning lié à ce rdv"
    },
    color: {
        label: "Couleur du rdv",
        title: "Personnalisez la couleur de ce rendez-vous",
        placeholder: "ex : Turquoise",
        checked: "Indiquez si la couleur est prise en compte"
    },
    'all-day': {
        label: "Toute la journée",
        title: "Indiquez si le rdv concerne la journée entière"
    },
    missing: {
        label: "Rendez-vous manqué",
        title: "Indiquez si patient n'est pas venu au rendez-vous"
    },
    range: {
        start: {
            label: "Date de début du rendez-vous",
            title: "Veuillez renseigner la date de début du rendez-vous"
        },
        end: {
            label: "Date de fin du rendez-vous",
            title: "Veuillez renseigner la date de fin du rendez-vous"
        }
    },
    duration: {
        label: "Durée du rendez-vous (fin : {end-date})",
        title: "Veuillez renseigner la durée du rendez-vous"
    },
    create: {
        label: "créer un cabinet",
        message: "Vous devez avoir au moins un cabinet pour compléter vos rendez-vous."
    },
    unselect: {
        label: "dissocier",
        title: "Dissociation",
        content: "Êtes-vous sûr de vouloir dissocier ce dossier patient de ce rendez-vous ?"
    }
}
