<a-side :sub-menu="true" v-if="!!event">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'plannings', params: {date: date}}" :title="$t('vm.root.calendar.event.aside.back.label')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                </svg>
                {{ $t('vm.root.calendar.event.aside.back.label') }}
            </router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: `${prefix}event`, params: $route.params}" :title="$t('vm.root.calendar.event.aside.rendez-vous.title')">{{ $t('vm.root.calendar.event.aside.rendez-vous.label') }}</router-link>
        </li>
        <li>
            <router-link v-if="isClassic" :to="{name: `${prefix}event-recurrency`, params: $route.params}" :title="$t('vm.root.calendar.event.aside.recurring.title')">{{ $t('vm.root.calendar.event.aside.recurring.label') }}</router-link>
        </li>
        <li>
            <router-link v-if="isClassic" :to="{name: `${prefix}event-relaunch`, params: $route.params}" :title="$t('vm.root.calendar.event.aside.relaunch.title')">{{ $t('vm.root.calendar.event.aside.relaunch.label') }}</router-link>
        </li>
    </ul>
    <ul class="aside-navigation is-pushed-down">
        <li v-if="isClassic && event.id !== '-1'" class="hr" aria-hidden="true"/>
		<li v-if="isClassic && event.id !== '-1'">
			<button type="button" @click="duplicate" :title="$t('vm.root.calendar.event.aside.duplicate.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#copy"/>
				</svg>
				{{ $t('vm.root.calendar.event.aside.duplicate.label-aside') }}
			</button>
		</li>
		<li class="hr" aria-hidden="true"/>
        <li v-if="canCancel">
            <delete-item :delete-msg="$t('vm.root.calendar.event.aside.cancel.confirm')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
                :delete-label="$t('vm.root.calendar.event.aside.cancel.action-label')"
                :title="$t('vm.root.calendar.event.aside.cancel.action-title')"
                :delete-action="submitCancel" :can-delete="true" :disabled="isDeleting || isCancelling">
                <spinner v-if="isCancelling"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#ban"/>
                </svg>
                {{ $t('vm.root.calendar.event.aside.cancel.label') }}
            </delete-item>
        </li>
        <li>
            <delete-item :delete-msg="$t('vm.root.calendar.event.aside.delete.confirm')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
                :delete-action="submitDelete" :can-delete="true" :disabled="isDeleting || isCancelling">
                <spinner v-if="isDeleting"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
                </svg>
                {{ $t('vm.root.calendar.event.aside.delete.label') }}
            </delete-item>
        </li>
    </ul>
</a-side>
