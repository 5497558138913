import Render from '@Views/components/recurring.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { RecurringType, DayOfMonthType } from '@Enums/index'
import {DateHelper, EnumsHelper} from '@Helpers/index'
import { IRecurring } from '@Store/types'
import { IDropdownItem } from '@Components/input/dropdown'
import { IToggleBoxItem } from './input/togglebox'

@Render
@Component({})
export class Recurring extends Vue {

	weekdays: {index: number, min: string, normal: string}[] = DateHelper.getDaysName()
    private recurrings: RecurringType[] = [RecurringType.None, RecurringType.Day, RecurringType.Week, RecurringType.Month, RecurringType.Year]

    @Prop() value: IRecurring
    @Prop() startDate: Date
    @Prop({default: false}) disabled: boolean

    //#region getters
    get recurring(): IRecurring {
        return this.value
    }
    set recurring(value: IRecurring) {
        this.$emit('input', value)
    }
    get cycleLabel(): string {
        return `${this.$tc('vm.components.recurring.cycleLabel', this.isWeekRecurring ? 0 : 1)} (${this.unit})`
    }
    get isNoRecurring(): boolean {
        return this.recurring.type === RecurringType.None
    }
    get isWeekRecurring(): boolean {
        return this.recurring.type === RecurringType.Week
    }
    get isMonthRecurring(): boolean {
        return this.recurring.type === RecurringType.Month
    }
    get unit(): string {
        if (!this.recurring) return ""

        return EnumsHelper.recurringUnitToString(this.recurring.type)
    }
    get title(): string {
		if (this.recurring === undefined || this.recurring.type === RecurringType.None)
			return EnumsHelper.recurringToString(this.recurring.type)

        let cycle: string = this.recurring.cycle > 1 ? `${this.recurring.cycle} ` : ''
        let label = ""
        if (this.recurring.type === RecurringType.Day) {
            label = this.resumeLabelDay(cycle)
        } else if (this.recurring.type == RecurringType.Week) {
            label = this.resumeLabelWeek(cycle)
        } else if (this.recurring.type == RecurringType.Month && this.startDate !== undefined) {
            label = this.resumeLabelMonth(cycle)
        } else if (this.recurring.type == RecurringType.Year && this.startDate !== undefined) {
            label = this.resumeLabelYear(cycle)
        }

        if (!!label && this.recurring.with_end_date) {
            label = `${label}, ${this.$t('vm.components.recurring.until')} ${this.$d(this.recurring.end_date, 'll')}`
        }

        return label
    }
    get dayOfMonthItems() : IToggleBoxItem[] {
        return [
			{value: DayOfMonthType.DayOfMonth, label: this.$i18n.t('vm.components.recurring.dayOfMonth').toString()},
			{value: DayOfMonthType.DayOfWeek, label: this.$i18n.t('vm.components.recurring.dayOfWeek').toString()},
			{value: DayOfMonthType.LastDayOfMonth, label: this.$i18n.t('vm.components.recurring.lastDayOfMonth').toString()}
		]
    }
    get withEndDateItems() : IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.no').toString()},
			{value: true, label: this.$i18n.t('general.yes').toString()}
		]
	}
    get recurringItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.recurrings.forEach(type => result.push({value: type, label: EnumsHelper.recurringToString(type)}))
	    return result
    }
    //#endregion

    @Watch('recurring.with_end_date')
    updateWithEndDate(){
        if (!this.recurring.with_end_date || !!this.recurring.end_date) return

        let endDate = new Date(this.recurring.start_date)
        endDate.setDate(endDate.getDate() + 1)
        this.recurring.end_date = endDate
    }

    getSelectedDay(id: number): {id: number, checked: boolean} {
        return this.recurring.selected_days.find(day => day.id === id)
    }

    //#region private methods
	private resumeLabelDay(cycle: string): string {
		return EnumsHelper.recurringToString(this.recurring.type, cycle)
	}

	private resumeLabelWeek(cycle: string): string {
		let total: number = 0
		let label: string = ''
		if (this.recurring.selected_days !== undefined) {
			for(let i = 0; i < 7; i++) {
                let index = i === 6 ? 0 : i + 1
				let selected_day = this.recurring.selected_days.find(day => day.id === index)
				if (!selected_day.checked) continue

				total++
				label += `${this.weekdays[i].normal}, `
			}
		}

		if (total === 7) {
			label = `, ${this.$t('vm.components.recurring.everyday')}`
		} else if (total > 0) {
			label = `, ${this.$tc('general.the', total)} ${label.substring(0, label.length - 2)}`
		}

		return `${EnumsHelper.recurringToString(this.recurring.type, cycle)}${label}`
	}

	private resumeLabelMonth(cycle: string) {
        let label = `${EnumsHelper.recurringToString(this.recurring.type, cycle)}, ${this.$tc('general.the', 1)} `
		if (this.recurring.day_of_month === DayOfMonthType.DayOfWeek) {
			let rank = Math.ceil(this.startDate.getDate() / 7)

			return `${label}${rank}<sup>${this.$tc('vm.components.recurring.rank', rank)}</sup> ${this.$d(this.startDate, 'weekday')}`
		} else if (this.recurring.day_of_month === DayOfMonthType.DayOfMonth) {
			return `${label}${this.$d(this.startDate, 'dayOfMonth')}`
		} else if (this.recurring.day_of_month === DayOfMonthType.LastDayOfMonth) {
			return `${label}${this.$t('vm.components.recurring.lastDayOfMonth')}`
		}
	}

	private resumeLabelYear(cycle: string) {
		let label = `${this.$tc('general.the', 1)} ${this.$d(this.startDate, 'short_LL')}`
		label = label.endsWith(',') ? label.substring(0, label.length - 1) : label
		return `${EnumsHelper.recurringToString(this.recurring.type, cycle)}, ${label}`
    }
    //#endregion
}
