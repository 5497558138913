export const searchColleague = {
    label: "Quels praticiens contacter pour ce patient ?",
    title: "Recherchez les praticiens associés à ce dossier",
    placeholder: "ex : Michèle Simesse",
    create: {
        title: "Créer un nouveau praticien",
        text: "Créer un praticien"
    },
    select: {
        label: "sélectionner",
        title: "Rechercher un praticien"
    },
    'create-popin': {
        label: "ajouter",
        title: "Ajouter un nouveau praticien"
    }
}
