<div v-if="options">
	<div :class="{'grid-2': !!options.list}">
		<div class="popin-person-search">
			<div class="with-icon">
				<svg width="1em" class="with-icon-icon" :class="{'is-shown': !searching}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<span class="with-icon-icon" :class="{'is-shown': searching}">
					<spinner class="text-primary"/>
				</span>
				<input type="search" v-model="query" class="form-group-input" :id="`${random}-popin-search-patient`" ref="popin-search-patient"
					:title="$t('vm.root.search.patient.search.title')"
					:placeholder="$t('vm.root.search.patient.search.placeholder')">
			</div>

			<div v-if="!resultSearch || resultSearch.length <= 0" class="popin-person-search-no-result" :class="{'is-white-space': !searched || !query || searching}">
				<div>
					<label for="popin-search-patient">
						<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
						</svg>
					</label>
					<div class="text-md text-bold text-center marg-v">
						<span v-if="!!searching">{{ $t('vm.components.popin.search-patient.search.searching') }}</span>
						<span v-else>{{ $t('vm.components.popin.search-patient.search.no-result') }}</span>

						<span v-if="!!query && !!searched && !searching">
							<br>
							<i18n path="vm.components.popin.search-patient.new-patient.text" tag="span">
								<button type="button" class="btn--link-like" @click="createPatient()" :title="$t('vm.components.popin.search-patient.new-patient.title')">{{ $t('vm.components.popin.search-patient.new-patient.button') }}</button>
							</i18n>
						</span>
					</div>
				</div>
			</div>

			<div v-else class="marg-v">
				<misc-item v-for="patient in resultSearch" class="semi-marg-v" :key="patient.id" :id="patient.id" v-model="options.selectedId" :radio-value="patient.id">
					<avatar slot="icon" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
					<span class="text-capitalize">{{patient.first_name}} <b>{{patient.last_name}}</b></span>
					<span v-if="patient.blacklisted"> &bull; <span class="patient-blacklisted">{{ $t('vm.components.popin.search-patient.blacklisted') }}</span></span>
					<span v-if="patient.deceased"> &bull; <span class="patient-deceased">{{ $t('vm.components.popin.search-patient.deceased') }}</span></span>
					<br>
					<span v-if="patient.email !== '' || patient.phone !== ''" class="text-sm">
						{{patient.phone | formatPhone}}<span v-if="patient.email !== '' && patient.phone !== ''"> &bull; </span>{{patient.email}}
					</span>
				</misc-item>
			</div>
		</div>
		<div v-if="!!options.list">
			<label>Dossiers récemments ouverts</label>
			<misc-item v-for="patient in options.list" class="semi-marg-v" :key="`recent-${patient.id}`" :id="patient.id" v-model="options.selectedId" :radio-value="patient.id">
				<avatar slot="icon" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
				<span class="text-capitalize">{{patient.first_name}} {{patient.last_name}}</span>
				<span v-if="patient.blacklisted"> &bull; <span class="patient-blacklisted">{{ $t('vm.components.popin.search-patient.blacklisted') }}</span></span>
				<span v-if="patient.deceased"> &bull; <span class="patient-deceased">{{ $t('vm.components.popin.search-patient.deceased') }}</span></span>
				<br>
				<span v-if="patient.email !== '' || patient.phone !== ''" class="text-sm">
					{{patient.phone | formatPhone}}<span v-if="!!patient.email && !!patient.phone"> &bull; </span>{{patient.email}}
				</span>
			</misc-item>
		</div>
	</div>
</div>
