import Render from '@Views/root/parameters/reminder.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IBreadcrumbItem, IUser } from '@Store/types'
import { IDropdownItem, InputDocument } from '@Components/index'
import { PackType, ReminderType } from '@Enums/index'
import { SmsHelper, EnumsHelper } from '@Helpers/index'
import { breadcrumb, office, user } from '@Store/modules'

@Render
@Component({})
export class Reminder extends Vue {

	private reminderTextWarning: boolean = false
	private longestAddress: string = ''
	private longestZipcode: string = ''
	private longestCity: string = ''
	private reminders: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]

	@Prop() configuration: IConfiguration

	//#region getters
	get user(): IUser {
		return user.user
	}
	get canSendSms(): boolean {
		return this.configuration.reminder_type === ReminderType.Both || this.configuration.reminder_type === ReminderType.OnlySms
	}
	get canSendMail(): boolean {
		return this.configuration.reminder_type === ReminderType.Both || this.configuration.reminder_type === ReminderType.OnlyMail
	}
	get types(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		this.reminders.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
		return result
	}
	get reminderTextLength(): string {
		let length: number = this.computeReminderTextLength()
		return this.$i18n.tc('vm.root.parameters.reminder.remaining-characters', length).toString()
	}
	get reminderError(): boolean {
		return this.reminderTextWarning
	}
	get reminderErrorMessage(): string {
		if (this.reminderTextWarning)
			return this.$i18n.t('vm.root.parameters.reminder.error.too-long').toString()

		return ''
	}
	get to(): {name: string, params: {pack: PackType}} {
        return {name: 'purchase-pack', params: {pack: PackType.Sms}}
	}
	get lightOptions(): any {
        return InputDocument.superLightOptions()
    }
	//#endregion

	mounted() {
		this.$emit('navigate', 'reminder')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
		this.computeLongestLocalities()
	}

	private computeReminderTextLength(): number {
		if (!this.configuration || !this.configuration.reminder_text) return 160

		this.configuration.reminder_text = this.configuration.reminder_text.trim()

		let key_word: string = "{cabinet.adresse}"
		let text: string = this.configuration.reminder_text.replace(key_word, this.longestAddress)
		key_word = "{cabinet.code_postal}"
		text = text.replace(key_word, this.longestZipcode)
		key_word = "{cabinet.ville}"
		text = text.replace(key_word, this.longestCity)
		let count: number = SmsHelper.countOccurence(text)
		let result: number = 160 - text.length - count
		this.reminderTextWarning = result < 0
		return result
	}

	private computeLongestLocalities() {
		for(let o of office.offices) {
			if (!o.coordinates.address) continue

			this.longestAddress = this.computeLongestAddress(o.coordinates.address, 'formatted', this.longestAddress)
			this.longestZipcode = this.computeLongestAddress(o.coordinates.address, 'zipcode', this.longestZipcode)
			this.longestCity = this.computeLongestAddress(o.coordinates.address, 'city', this.longestCity)
		}
	}

	private computeLongestAddress(address: any, property: string, max: string): string {
		if (address[property] === undefined) return max
		if (address[property].length <= max.length) return max

		return address[property]
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.reminder').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
