<div v-if="options" class="popin-person-search">

	<div class="with-icon">
		<svg width="1em" class="with-icon-icon" :class="{'is-shown': !searching}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
		</svg>
		<span class="with-icon-icon" :class="{'is-shown': searching}">
			<spinner class="text-primary"/>
		</span>
		<input type="search" v-model="query" class="form-group-input" :id="`${random}-popin-search-colleague`" ref="popin-search-colleague"
			:title="$t('vm.root.search.colleague.search.title')"
			:placeholder="$t('vm.root.search.colleague.search.placeholder')">
	</div>

	<div v-if="resultSearch.length <= 0" class="popin-person-search-no-result" :class="{'is-white-space': !searched || !query || searching}">
		<div>
			<label for="popin-search-colleague">
				<svg width="1em" class="svg-block svg-center svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
			</label>
			<div class="text-md text-bold text-center marg-v">
				<span v-if="!!searching">{{ $t('vm.components.popin.search-colleague.search.searching') }}</span>
				<span v-else>{{ $t('vm.components.popin.search-colleague.search.no-result') }}</span>

				<span v-if="!!query && !!searched && !searching">
					<br>
					<i18n path="vm.components.popin.search-colleague.new-praticien.text" tag="span">
						<button type="button" class="btn--link-like" @click="createColleague()" :title="$t('vm.components.popin.search-colleague.new-praticien.title')">{{ $t('vm.components.popin.search-colleague.new-praticien.button') }}</button>
					</i18n>
				</span>
			</div>
		</div>
	</div>

	<div v-else class="marg-v">
		<misc-item v-for="colleague in resultSearch" class="semi-marg-v" :key="colleague.id" :id="colleague.id" v-model="options.selectedId" :radio-value="colleague.id">
			<avatar slot="icon" :first-name="colleague.first_name" :last-name="colleague.last_name" :email="colleague.email"/>
			<span class="text-capitalize">{{colleague.first_name}} <b>{{colleague.last_name}}</b></span><br>
			<span v-if="colleague.email !== '' || colleague.phone !== ''" class="text-sm">
				{{colleague.phone | formatPhone}}<span v-if="colleague.email !== '' && colleague.phone !== ''""> &bull; </span>{{colleague.email}}
			</span>
		</misc-item>
	</div>

</div>
