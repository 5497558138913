<div v-if="consultation">

	<div class="childs-space-between marg-v">
		<span>
			<h2 class="no-marg">{{reasonTitle}} <span class="text-xs">&bull; {{$d(consultation.act_date, 'll')}}</span></h2>
			<span class="text-italic text-sm">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
				</svg>
				{{ $t('vm.root.patients.patient.timeline-item.create-by', {owner: owner}) }}
				<span v-if="office"> &bull; {{ office }}</span>
			</span>
		</span>

		<div class="form-group is-pushed-right">
			<button type="button" @click="previous" class="btn btn-default form-group-input" :disabled="isFirst" :title="$t('vm.root.patients.patient.consultation.previous.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.consultation.previous.text') }}</span>
			</button>
			<button type="button" @click="next" class="btn btn-default form-group-input" :disabled="isLast" :title="$t('vm.root.patients.patient.consultation.next.title')">
					<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.consultation.next.text') }}</span>
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
				</svg>
			</button>
		</div>
	</div>

	<hr>

	<router-view :read-only="readOnly || mustBeReadonly(consultation)" :submitted="submitted" @edit="onEdit" @navigate="onNavigate" :consultation="consultation"/>
</div>
