<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'statistics-colleagues'}" :title="$t('vm.root.statistics.colleagues.aside-colleague.back.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.statistics.colleagues.aside-colleague.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
    </ul>

	<ul class="aside-navigation is-pushed-down">
		<li class="hr" aria-hidden="true"/>
		<li>
			<delete-item :can-delete="true" :delete-action="submitDelete"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
				:title="$t('vm.root.statistics.colleagues.aside-colleague.delete.title')" :disabled="isDeleting"
				:delete-msg="$t('vm.root.statistics.colleagues.aside-colleague.delete.delete-msg')">
				<spinner v-if="isDeleting"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.statistics.colleagues.aside-colleague.delete.label') }}
			</delete-item>
		</li>
	</ul>
</a-side>
