export const purchaseItem = {
    title: "Détails de l'achat",
    invoice: "aucune facture | Une facture | {count} factures",
    'not-finalized': "achat non finalisé",
    delete: {
        title: "Effacez cet achat non finalisé",
        'delete-msg': "Vous êtes sur le point de supprimer cet achat. Voulez-vous continuer ?"
    },
    reset: {
        title: "Résiliez cet abonnement",
        'delete-msg': "Vous êtes sur le point de résilier cet abonnement. Voulez-vous continuer ?",
        label: "résilier",
		already: "résilié"
    }
}
