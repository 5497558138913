import Render from '@Views/root/shop/bulk/steps/step3.html'
import {Component} from 'vue-property-decorator'
import {PackType} from '@Enums/purchase'
import {Paying} from '@ViewModels/root/shop/paying'
import {PayStep} from './pay-step'
import {IPack} from '@Store/types/shop'
import {shop, bulk} from '@Store/modules'
import {AutoSaverState} from "@Enums/auto-save";

@Render
@Component({
	components: {
		'paying': Paying,
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Step3 extends PayStep {
	constructor() {
		super('bulk', false)
	}

	//#region Getters
	get price(): number {
		let pack: IPack = shop.shop.packs.find(p => { return p.id === PackType.Unlimited })
		if (!pack) return 999

		let price: number = pack.values[0].price
		let percent: number = bulk.bulk.percent

		return price * (1 - percent / 100) - 150
	}
	//#endregion

	mounted() {
		super.initializeValue()
		this.state = !!this.bulk && parseInt(this.bulk.id) !== -1 ? AutoSaverState.Updated : this.state
	}

	//#region PayStep implementation
	getType(): PackType.Bulk2 {
		return PackType.Bulk2
	}
	//#endregion

	submitPay() {
		super.submit()
	}
}
