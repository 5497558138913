export const colleagueItem = {
    'no-phone': "Téléphone non renseigné",
    'no-email': "Email non renseigné",
    'no-profession': "Profession non renseignée",
    'associted-patient': "{count} patient associé | {count} patient associé | {count} patients associés",
    unselect: {
        label: "dissocier", 
        'title-button': "Dissocier ce praticien du dossier patient", 
        title: "Dissociation", 
        content: "Vous-êtes sûr de vouloir dissocier ce praticien de ce dossier ?"
    },
    update: {
        label: "modifier", 
        title: "Modifier ce praticien"
    },
}