import * as Help from '@ViewModels/root/help'
import { Profile } from '@Enums/profile'

export const TicketRoot = function(prefix: string = ''): any[] {
    return [
        {
            path: '',
            name: `${prefix}help`,
            component: Help.NoTicket,
            meta: { permission: [Profile.Main, Profile.Demo] },
        },
        {
            path:'ticket/:sup_id?', 
            name: `${prefix}ticket`,
            component: Help.Ticket,
            meta: { permission: [Profile.Main, Profile.Demo] },
        }
    ]
}
