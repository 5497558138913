import { Sound } from "@Enums/index"

export class Player {

    private audio: any
  
    public play(sound: Sound): void {
        let soundFile: any = this.file(sound)
        if (!soundFile) return

        this.audio = new Audio(soundFile)
        if (!this.audio) return

        this.audio.play()
    }

    public stop(): void {
        if (!this.audio) return

        this.audio.pause()
        this.audio.currentTime = 0   
    }

    private file(sound: Sound): any {
        switch(sound) {
            case Sound.Stress: 
                return require('@Assets/sound/stress.mp3')
            default:
                return null
        }
    }
}