import Render from '@Views/root/statistics/reference/reference.html'
import { Component, Vue } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'
import { ColorHelper } from '@Helpers/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class Reference extends Vue {

	private stats: IStatsReference[] = []
	private alphabetFirstLetter: number
	private alphabetCount: number

	get isLoading(): boolean {
        return statistics.isLoading
	}
	get countReference(): any[] {
		return [{
			name: this.$i18n.t('vm.root.statistics.reference.main.legend-chart.name').toString(),
			data: this.stats.map(l => { return l.weight })
		}]
	}
	get countReferenceChartOptions(): any {
		return {
			chart: {
				type: 'bar',
				height: 350
			},
			colors: this.stats.map(l => { return this.getColor(l.text) }),
			plotOptions: {
				bar: {
					distributed: true,
					columnWidth: '45%',
					endingShape: 'flat'
				},
			},
			dataLabels: {
				enabled: false
			},
			legend: {
				show: false
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			xaxis: {
				categories: this.stats.map(l => { return l.text })
			},
			yaxis: {
				title: {
					text: this.$i18n.t('vm.root.statistics.reference.main.legend-chart.title').toString(),
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: (val: number) => { return this.$i18n.tc('vm.root.statistics.reference.main.legend-chart.tooltip', val).toString() }
				}
			}
		}
	}

	//#endregion

	mounted() {
		this.alphabetFirstLetter = "a".charCodeAt(0),
		this.alphabetCount = "z".charCodeAt(0) - this.alphabetFirstLetter

		breadcrumb.updateLinked(false)
		this.$emit('navigate', 'reference')
		this.initializeBreadcrumb()

		statistics.loadReferenceStatistics()
		.then((stats) => { this.computeData(stats) })
	}

	getColor(text: string): string {

		let a: number = ((!text || text.length === 0) ? ' ' : text.substring(0, 1).toUpperCase()).charCodeAt(0)
		let b: number = ((!text || text.length === 0) ? ' ' : text.slice(-1).toLowerCase()).charCodeAt(0)
		let c: number = ((!text || text.length <= 1) ? ' ' : text.substring(1, 2).toUpperCase()).charCodeAt(0)
		let d: number = ((!text || text.length <= 1) ? ' ' : text.slice(text.length - 2, text.length - 1).toLowerCase()).charCodeAt(0)

		// With latin chars, we will have an alphabet from 0 to 25
		// Conditions for keeping possibilities for other chars
		if (a >= this.alphabetFirstLetter ) a -= this.alphabetFirstLetter
		if (c >= this.alphabetFirstLetter ) c -= this.alphabetFirstLetter

		a += text.length

		// Magic!
		let h: number = Math.round(((360 * a) / this.alphabetCount) + (b % 2 ? b : -b))
		let s: number = 85 + (b % 2 ? c / 2 : -c / 2)
		let l: number = 70 + (d % 2 ? c : -c)

		// Boundaries
		s = Math.min(Math.max(s, 0), 100)
		l = Math.min(Math.max(l, 50), 90)

		return ColorHelper.HSLToHex(`hsl(${h},${s}%,${l}%)`)
	}

	private computeData(data: IStatsReference[]) {
		let sorted: IStatsReference[] = !!data ? data.sort((a, b) => (a.weight < b.weight) ? 1 : -1) : []

		this.stats = []
		for(let value of sorted) {
			if (this.stats.length >= 10) break

			this.stats.push(value)
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.reference').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

interface IStatsReference {
	text: string,
	weight: number
}
