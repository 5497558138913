<context>
	<div class="wrapper">

		<div class="context-search-sticky-container">
			<div class="context-search">
				<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" class="context-search-input" v-model="query"
					:placeholder="$t('vm.root.patients.patient.context.search.placeholder')"
					:title="$t('vm.root.patients.patient.context.search.title')">
			</div>

			<div id="timeline-filter" class="search-filter">
				<div class="text-right">
					<button type="button" class="btn--link-like text-sm" v-toggle.main="{className: 'is-active', selector: '#timeline-filter'}">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#filter"/>
						</svg>
						{{ $tc('vm.root.patients.patient.context.search.activated-filter', filterCount) }}
					</button>
				</div>
				<div class="search-filter-content">
					<ul class="search-filter-list">
						<li class="search-filter-list-item">
							<input id="consultations-filter" type="checkbox" v-model="filter.consultations">
							<label for="consultations-filter">{{ $tc('vm.root.patients.patient.context.filter.consultations', 2) }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="antecedents-filter" type="checkbox" v-model="filter.antecedents">
							<label for="antecedents-filter">{{ $tc('vm.root.patients.patient.context.filter.antecedents', 2) }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="spheres-filter" type="checkbox" v-model="filter.spheres">
							<label for="spheres-filter">{{ $tc('vm.root.patients.patient.context.filter.spheres', 2) }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="attachments-filter" type="checkbox" v-model="filter.attachments">
							<label for="attachments-filter">{{ $tc('vm.root.patients.patient.context.filter.attachments', 2) }}</label>
						</li>
						<li aria-hidden="true">&bull;</li>
						<li class="search-filter-list-item">
							<input id="pediatrics-filter" type="checkbox" v-model="filter.pediatrics">
							<label for="pediatrics-filter">{{ $t('vm.root.patients.patient.context.filter.pediatrics') }}</label>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<section class="timeline">
			<h2 class="sr-only">{{ $t('vm.root.patients.patient.context.title', {name: patientName}) }}</h2>

			<section class="timeline-section">
				<div class="timeline-item" :class="[{ 'is-active': open }]">
					<div class="timeline-item-title">
						<div class="timeline-item-title-bullet">
							<strong>{{ $t('general.today') | capitalize }}</strong> : {{$d(new Date(), 'll')}}
						</div>
						<div>
							<button type="button" class="btn--link-like timeline-item-new-trigger" @click="open = !open"
								:title="$t('vm.root.patients.patient.context.new-item.title')">
								<svg width="1em" class="timeline-item-new-trigger-caret svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
								</svg>
								<span>{{ $t('vm.root.patients.patient.context.new-item.label') }}</span>
							</button>
						</div>
						<div class="timeline-item-content">
							<div class="timeline-item-content-inner">
								<ul class="ul-reset p-like">
									<li class="timeline-item-new">
										<button @click="addAntecedent" class="btn--link-like" :title="$t('vm.root.patients.patient.context.new-item.new-antecedent')">
											<svg width="1em" class="svg-middle" :style="`color:${colorManager.badge_antecedent};`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#plus-circle"/>
											</svg>
											<span class="text-uppercase-first-letter">{{ $tc('vm.root.patients.patient.context.filter.antecedents', 1) }}</span>
										</button>
									</li>
									<li class="timeline-item-new">
										<button @click="addSphere" class="btn--link-like" :title="$t('vm.root.patients.patient.context.new-item.new-sphere')">
											<svg width="1em" class="svg-middle" :style="`color:${colorManager.badge_sphere};`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#plus-circle"/>
											</svg>
											<span class="text-uppercase-first-letter">{{ $tc('vm.root.patients.patient.context.filter.spheres', 1) }}</span>
										</button>
									</li>
									<li class="timeline-item-new">
										<router-link :to="{name:'patient-consultation', params:{pat_id: $route.params.pat_id}}"
													 class="btn--link-like" :title="$t('vm.root.patients.patient.context.new-item.new-consultation')">
											<svg width="1em" class="svg-middle" :style="`color:${colorManager.badge_consultation};`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#plus-circle"/>
											</svg>
											<span class="text-uppercase-first-letter">{{ $tc('vm.root.patients.patient.context.filter.consultations', 1) }}</span>
										</router-link>
									</li>
									<li class="timeline-item-new">
										<router-link :to="{name:'patient-attachment', params:{pat_id: $route.params.pat_id}}"
													 class="btn--link-like" :title="$t('vm.root.patients.patient.context.new-item.new-attachment')">
											<svg width="1em" class="svg-middle" :style="`color:${colorManager.badge_attachment};`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#plus-circle"/>
											</svg>
											<span class="text-uppercase-first-letter">{{ $tc('vm.root.patients.patient.context.filter.attachments', 1) }}</span>
										</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section v-if="hasItems" class="timeline-section">
				<div class="timeline-item" :class="[{ 'is-active': expanded }]">
					<div class="timeline-item-title">
						<button type="button" class="btn--link-like timeline-item-title-trigger" @click="expandAll">
							<span class="timeline-item-title-trigger-caret timeline-item-title-trigger-caret-expand">
								<svg width="1em" class="svg-block" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
									<use v-if="expanded" xlink:href="@Assets/svg/symbol-defs.svg#minus"/>
									<use v-else xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
								</svg>
							</span>
							<span v-if="expanded">{{ $t('vm.root.patients.patient.context.collapse-all') }}</span>
							<span v-else>{{ $t('vm.root.patients.patient.context.expand-all') }}</span>
						</button>
					</div>
				</div>
			</section>

			<template v-for="(value, key) in items">
				<section v-if="!!key" class="timeline-section" :key="key">
					<h3 class="timeline-section-title">{{key}}</h3>
					<timeline-item :item="item" :query="query" v-for="item in value" :key="item.id" :gender="gender"/>
				</section>
			</template>

			<section class="timeline-section" v-if="!!familyItems && familyItems.length > 0">
				<h3 class="timeline-section-title">{{ $t('vm.root.patients.patient.context.family') }}</h3>
				<timeline-item :item="item" :query="query" v-for="item in familyItems" :key="item.id"/>
			</section>

			<section class="timeline-section" v-if="!!patient.coordinates.birth_date">
				<h3 class="timeline-section-title">{{ $t('vm.root.patients.patient.context.birth') }}</h3>
				<section class="timeline-item">
					<h4 class="timeline-item-title">
						<div class="timeline-item-title-bullet">{{$d(patient.coordinates.birth_date, 'll')}}</div>
					</h4>
					<span>{{patient.coordinates.birth_date | timeAgo}}</span>
				</section>
			</section>

			<div class="timeline-section" v-else>
				<div class="timeline-item">
					<div class="timeline-item-title">
						<div class="timeline-item-title-bullet">
							<div><i>{{ $t('vm.root.patients.patient.context.missing-birth-date') }}</i></div>
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</section>

	</div>
</context>
