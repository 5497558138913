export const mainBulk = {
    title: "Achat groupé",
	loading: "ouverture des achats groupés",
	'cant-buy': "Vous possédez déjà un pack tranquillité, l'achat groupé n'a donc aucun intérêt pour vous 😊.",
	address: {
		'error-msg': "Veuillez indiquer une adresse de facturation",
		label: "Adresse de facturation",
		title: "Veuillez indiquer l'adresse de facturation"
	},
	mode: {
		label: "Mode de paiement",
		title: "Sélectionnez le mode de paiement pour régler"
	},
	total: {
		'ttc-title': "Toutes Taxes Comprises",
		'ttc-label': "TTC"
	},
	step1: {
		'how-to-validate': "Pour valider votre réservation sur cette session d'achats groupés, vous devez verser un acompte de {price}. Une fois cet acompte versé, il ne vous restera plus qu'à patienter jusqu'à la fin de la session (fin du mois en cours). Libre à vous de parler autour de vous de cette session pour maximiser vos chances d'obtenir le plus grand pourcentage.",
		'down-payment': "Acompte à verser :",
		reserve: {
			title: "Réservez votre pack tranquillité",
			label: "réserver"
		}
	},
	step2: {
		'down-payment-received': {
			content:  "Votre acompte a bien été pris en compte et votre réservation validée. Vous pouvez télécharger dès maintenant votre facture d'acompte depuis la section {0} de {1}.",
			strong: "Achats",
			link: {
				title: "Boutique Osteo2ls",
				label: "votre boutique"
			}
		}
	},
	step3: {
		'how-to-finalize': "Pour finaliser votre achat sur cette session d'achats groupés, vous devez verser le solde restant, soit {price}.Une fois ce solde versé, votre compte sera débloqué.",
		'balance-to-pay': "Solde à verser :",
		finalize: {
			title: "Finalisez votre achat groupé",
			label: "finaliser"
		}
	},
	step4: {
		'balance-received': {
			content: "Votre achat a bien été finalisé avec {percent} % de réduction. Vous pouvez télécharger votre facture d'acompte et votre facture du solde depuis la section {strong} de {link}.",
			strong: "Achats",
			link: {
				title: "Boutique Osteo2ls",
				label: "votre boutique"
			}
		},
		'not-finalized': "Vous n'avez pas finalisé votre achat goupé. Vous pouvez toutefois télécharger votre facture d'acompte depuis la section {0} de {1}."
	}
}
