import Render from '@Views/root/co-working/steps/step-2.html'
import { Component, Vue } from 'vue-property-decorator'
import { Provider, Profile } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { IDropdownItem } from '@Components/index'
import { account, coworking, notif } from '@Store/modules'

@Render
@Component({})
export class StepTwo extends Vue {

    submitted: boolean = false
    manual: boolean = false

    // @State('coworking') stateCoWorking: CoWorkingState
    // @State('account') stateAccount: AccountState
    // @Mutation('coworking/updateProvider') updateProvider: (provider: Provider) => void
    // @Mutation('coworking/updateEmail') updateEmail: (email: string) => void
    // @Mutation('notification/error') error: (notification: string | INotification) => void
    // @Mutation('notification/clear') clear: () => void
    // @Action('coworking/previousStep') previousStep:() => void
    // @Action('coworking/nextStep') nextStep:() => void
    // @Action('account/verify') verify: (context: {email: string, profile: Profile, provider: Provider}) => any

    get profile(): Profile {
        return coworking.profile
    }
    get email(): string {
        return coworking.email
    }
    set email(value: string) {
        coworking.updateEmail(value)
    }
    get profileToString(): string {
		return EnumsHelper.profileToString(this.profile)
    }
    get shortProfile(): string {
		return EnumsHelper.profileToString(this.profile, true)
    }
    get provider(): Provider {
        return coworking.provider
    }
    set provider(value: Provider) {
        coworking.updateProvider(value)
    }
    get withProvider(): boolean {
        return coworking.withProvider
    }
    get providerItems() : IDropdownItem[] {
		return [
			{value: Provider.None, label: EnumsHelper.providerToString(Provider.None)},
			{value: Provider.Permatel, label: EnumsHelper.providerToString(Provider.Permatel)}
		]
    }
    get isVerifying(): boolean {
        return account.isVerifying
    }
    get isMain(): boolean {
        return this.profile === Profile.Main
    }
	get isAccounting(): boolean {
		return this.profile === Profile.Accounting
	}
	get isSubstitute(): boolean {
		return this.profile === Profile.Substitute
	}
    get isSecretary(): boolean {
        return this.profile === Profile.Secretary
    }
    get hasEmailError(): boolean {
        return this.submitted && this.disabled
    }
    get website(): string {
        switch (this.provider) {
            case Provider.Permatel:
                return "permatel.fr"
        }
    }
    get disabled(): boolean {
        return !this.withProvider && (!this.email || !this.email.trim())
    }

    mounted() {
        if (coworking.step === 1) return

        this.$router.push({name: 'share-step-one'})
    }

    beforeDestroy() {
        notif.clear()
    }
    
    submitNext(): void {
        this.submitted = true
        this.next()
    }
    
    next(): void {
        if (!this.canNext()) return

        this.manual = true
        account.verify({email: this.email, profile: this.profile, provider: this.provider})
		.then(() => {
            this.manual = false
            coworking.nextStep()
            this.$router.push({name: 'share-step-three'})
        })
        .catch(error => {
            this.manual = false
            notif.error(error)
        })
    }
    
    previous(): void {
        coworking.previousStep()
        this.$router.push({name: 'share-step-one'})
    }
    
    private canNext(): boolean {
        return !this.hasEmailError
    }
}