<div v-if="attachment">
	<div class="childs-space-between marg-v">
		<h2 class="text-uppercase-first-letter no-marg">{{attachment.name || $tc("models.patient.attachment", 1)}}</h2>
		<div class="form-group is-pushed-right">
			<button type="button" @click="previous" class="btn btn-default form-group-input" :disabled="isFirst" :title="$t('vm.root.patients.patient.attachment.previous.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.attachment.previous.text') }}</span>
			</button>
			<button type="button" @click="next" class="btn btn-default form-group-input" :disabled="isLast" :title="$t('vm.root.patients.patient.attachment.next.title')">
				<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.attachment.next.text') }}</span>
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
				</svg>
			</button>
		</div>
	</div>
	<div class="childs-space-between">
		<div>{{ $t("vm.root.patients.patient.attachment.item.title", {date: $d(attachment.act_date, 'll')}) }}</div>
		<div>
			<button type="button" @click="duplicate" class="btn btn--link-like"
					:title="$t('vm.root.patients.patient.attachment.duplicate.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#copy"/>
				</svg>
				<span>{{ $t("vm.root.patients.patient.attachment.duplicate.text") }}</span>
				<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.attachment.duplicate.text-long') }}</span>
			</button>
			•
			<router-link :to="{name:'patient-attachment', params:{pat_id: $route.params.pat_id}}"
						 class="is-pushed-right" :title="$t('vm.root.patients.patient.attachment.header.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
				</svg>
				<span>{{ $t("vm.root.patients.patient.attachment.header.new") }}</span>
				<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.attachment.header.new-long') }}</span>
			</router-link>
		</div>
	</div>
	<div v-if="readOnly">
		<div v-if="parseInt(attachment.id) === -1" class="marg-v text-center">
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#image"/>
			</svg>
			<i18n path="vm.root.patients.patient.attachment.none.text" tag="p">
				<span class="text-lg">{{ $t("vm.root.patients.patient.attachment.none.title") }}</span><br>
			</i18n>
		</div>
		<div v-else>
			<div v-if="isFile" class="text-center">
				<p class="text-pre-line">{{attachment.description}}</p>
				<iframe v-if="isPdf" :src="attachment.url" height="500px" width="100%"></iframe>
				<a v-else-if="attachment.url" :href="attachment.url" :title="$t('vm.root.patients.patient.attachment.item.image_description')" target="_blank">
					<img :src="attachment.url"/>
				</a><br>
			</div>
			<a v-else :href="attachment.description" :title="$t('vm.root.patients.patient.attachment.item.link_description')" target="_blank">
				<img :src="attachment.description" :alt="attachment.title">
			</a>
		</div>
	</div>
	<div v-else>
		<div class="grid-2">
			<input-frame :auto-focus="true" id="name" :label="$t('vm.root.patients.patient.attachment.name.label')" v-model="attachment.name"
				:placeholder="$t('vm.root.patients.patient.attachment.name.placeholder')" class="grid-item-full"
				:error-test="hasNameError" :error-msg="$t('vm.root.patients.patient.attachment.name.error')"
				:title="$t('vm.root.patients.patient.attachment.name.title')"/>

			<input-date-frame v-model='attachment.act_date' id="act-date" :type="actDateType" :label="$t('vm.root.patients.patient.attachment.date.label')"
				:placeholder="$t('vm.root.patients.patient.attachment.date.placeholder')"
				:error-test="hasActDateError" :error-msg="$t('vm.root.patients.patient.attachment.date.error')"
				:title="$t('vm.root.patients.patient.attachment.date.title')"/>

			<togglebox-frame id="attachment-type" v-model="attachment.type" :items="attachmentItems"
				:label="$t('vm.root.patients.patient.attachment.type.label')"
				:title="$t('vm.root.patients.patient.attachment.type.title')"/>

			<textarea-frame v-if="isFile" id="description" :label="$t('vm.root.patients.patient.attachment.description.label')" v-model="attachment.description"
				:placeholder="$t('vm.root.patients.patient.attachment.description.placeholder')" class="grid-item-full"
				:title="$t('vm.root.patients.patient.attachment.description.title')"/>

			<input-frame v-else id="link" :label="$t('vm.root.patients.patient.attachment.link.label')" v-model="attachment.description"
				:placeholder="$t('vm.root.patients.patient.attachment.link.placeholder')" class="grid-item-full"
				:title="$t('vm.root.patients.patient.attachment.link.title')"
				:error-test="hasLinkError" :error-msg="$t('vm.root.patients.patient.attachment.link.error')"/>
		</div>

		<div v-if="isFile" class="text-center">
			<iframe v-if="isPdf" :src="fileUrl" height="500px" width="100%"></iframe>
			<a v-else-if="!!attachment.url" :href="attachment.url" :title="$t('vm.root.patients.patient.attachment.item.image_description')" target="_blank">
				<img :src="fileUrl"/>
			</a>
			<img v-if="!!attachment.file" :src="fileUrl"/>
			<div v-else-if="!attachment.url" class="input-frame" :class="{ 'is-alert': hasFileError }">
				<label class="label text-left" for="file" :data-text="$t('vm.root.patients.patient.attachment.file.label')">
					<span>{{ $t('vm.root.patients.patient.attachment.file.label') }}</span>
				</label>
				<input type="file" accept="application/pdf, image/*" @change="onChange" :title="$t('vm.root.patients.patient.attachment.file.title')"/>
				<div class="msg-alert" v-show="hasFileError">
					<div class="msg-alert-inner">{{ $t('vm.root.patients.patient.attachment.file.error') }}</div>
				</div>
			</div>
		</div>
		<a v-else :href="attachment.description" :title="$t('vm.root.patients.patient.attachment.item.link_description')" target="_blank">
			<img :src="attachment.description" :alt="attachment.title">
		</a>

		<div v-if="isMainUser" class="text-right">
			<delete-item class="btn btn--link-like text-danger" :can-delete="true"
				:delete-action="deleteAttachment">
				{{ $t('general.delete.text') }}
			</delete-item>
		</div>

	</div>
</div>
