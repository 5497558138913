import Render from '@Views/components/frame/input-nir-frame.html'
import allCountries from '@Assets/js/all-countries'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CountryCode } from 'libphonenumber-js'
import { IDropdownItem } from '@Components/input/dropdown'
import { DropdownButton, IDropdownButtonItem } from '@Components/dropdown-button'
import { ICountry } from './input-phone-frame'
import { Common } from '@Models/index'
import { NirHelper } from '@Helpers/index'

@Render
@Component({
    components: {
        'dropdown-button': DropdownButton
    }
})
export class InputNirFrame extends Vue {
    name: string = 'input-nir'

    number: string = ''
    iso2: CountryCode = null
    private random: string = ''

    @Prop() nir: INir
    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) required: boolean
    @Prop({default: false}) disabledFetchingCountry: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: true}) enabledFlags: boolean
    @Prop({default: "ex : 1 78 12 78 345 087 17"}) placeholder: string
    @Prop({default: "defaultCountry"}) defaultCountry: string
    @Prop({default: InputNirFrame.preferredCountries}) preferredCountries: string[]
    @Prop({default: InputNirFrame.onlyCountries}) onlyCountries: string[]
    @Prop({default: () => []}) ignoredCountries: string[]
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string
    @Prop() errorMsg: string

    //#region getters
    get state(): boolean {
        return this.number === '' || NirHelper.isValidNumber(this.number, this.iso2)
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }
    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get countryItems(): IDropdownButtonItem[] {
        let items = [...this.preferredCountries, ...this.onlyCountries]
        let result: IDropdownButtonItem[] = this.getCountries(items)
        .map(country => ({
            value: country.iso2.toLowerCase(),
            label: country.name
        }))
        return result
    }
    get preferredItems(): IDropdownButtonItem[] {
        let result: IDropdownButtonItem[] = this.getCountries(this.preferredCountries)
        .map(country => ({
            value: country.iso2.toLowerCase(),
            label: country.name
        }))
        return result
    }
    //#endregion

    mounted() {
        this.$emit('onValidate', this.nir)
    }

    created() {
		this.random = Common.generateId()
        this.updateNir()
    }

    getFlagIcon(item: IDropdownItem): string {
        if (!item) return ''
        return item.value
    }

    onInput(): void {
        let input: HTMLSelectElement = this.$refs.input as HTMLSelectElement
        if (input !== undefined) {
            input.setCustomValidity(this.state ? '' : this.errorMsg)
        }
        if (!this.state) this.nir.error = true
        else delete this.nir.error

        this.$emit('onInput', this.nir)
    }

    onBlur(): void {
        this.$emit('onBlur')
    }

    @Watch('nir')
    private updateNir() {
        this.initializeCountry()
        if (!this.nir) return

        this.iso2 = this.nir.iso.toUpperCase() as CountryCode
        this.number = NirHelper.formattedResult(this.nir.number, this.iso2)
    }

    @Watch('iso2')
    updateCountry() {
        this.iso2 = this.iso2.toString().toUpperCase() as CountryCode
        this.nir.iso = this.iso2.toString().toLowerCase()
        let formattedNumber: string = NirHelper.formattedResult(this.number, this.iso2)
        this.nir.number = formattedNumber.replace(/ /g, '')
        this.number = formattedNumber

        if (!this.state) this.nir.error = true
        else delete this.nir.error
    }

    @Watch('number')
    updateNumber() {
        // PhoneHelper.selectCountryWithDialCode(this.number)

        this.nir.number = NirHelper.formattedResult(this.number, this.iso2).replace(/ /g, '')
    }

    @Watch('state')
    updateState(newValue: boolean) {
        if (newValue) {
            // If mode is 'prefix', keep the number as user typed,
            // Otherwise format it
            let formattedNumber: string = NirHelper.formattedResult(this.number, this.iso2)
            this.nir.number = formattedNumber.replace(/\W/g, '')
            this.number = formattedNumber
        }
        this.$emit('onValidate', this.nir)
    }

    //#region private methods
    private initializeCountry(): void {
        if (this.setDefaultCountry()) return

        let country = this.preferredItems[0]
        this.iso2 = country.value.toUpperCase() as CountryCode
    }

    private setDefaultCountry(): boolean {
        if (!this.defaultCountry) return false
        const country = this.findCountry(this.defaultCountry)

        if (!country) return false

        this.iso2 = country.iso2
        return true
    }

    private getCountries(list:string[] = []): ICountry[] {
        return list
            .map(countryCode => this.findCountry(countryCode))
            .filter(Boolean)
    }

    private findCountry(iso:string = ''): ICountry {
        return allCountries.find(country => country.iso2 === iso.toUpperCase())
    }

    // private formattedResult(nir:string, iso: string): string {
    //     nir = nir.replace(/\W/g, '')
    //     if (!InputNirFrame.isValidNumber(nir, this.iso2)) return nir

    //     switch (this.iso2) {
    //         case 'FR':
    //             return `${nir.substring(0, 1)} ${nir.substring(1, 3)} ${nir.substring(3, 5)} ${nir.substring(5, 7)} ${nir.substring(7, 10)} ${nir.substring(10, 13)} ${nir.substring(13)}`
    //         case 'LU':
    //             return nir
    //         case 'BE':
    //             return `${nir.substring(0, 2)} ${nir.substring(2, 4)} ${nir.substring(4, 6)} ${nir.substring(6, 9)}-${nir.substring(9)}`
    //         default:
    //             return nir
    //     }
    // }

    private static preferredCountries(): string[] {
        return ['fr', 'be', 'lu', 'nc']
    }

    private static onlyCountries(): string[] {
        return []
    }

    // private static isValidNumber(nir: string, iso: CountryCode): boolean {
    //     switch(iso) {
    //         case 'FR':
    //             return NirHelper.checkNirFr(nir)
    //         case 'LU':
    //             return NirHelper.checkNirLu(nir)
    //         case 'BE':
    //             return NirHelper.checkNirBe(nir)
    //         default:
    //             return false
    //     }
    // }
    //#endregion
}

export interface INir {
    id: string,
    number: string,
    iso: string,
    error?: boolean
}
