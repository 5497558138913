<context>

	<div>
		<section v-if="isCurrentSession" class="wrapper marg-v">
			<h2>{{ $t('vm.root.shop.bulk.context.running-session') }}</h2>
			<div v-if="isLoading" class="text-center">
				<spinner class="svg-xl text-primary"></spinner>
			</div>
			<div v-else>
				<p class="text-center text-strong">{{ $t('vm.root.shop.bulk.context.end-session') }}</p>
				<p class="transfer-container text-center">
					<strong class="text-numeric">
						{{ $tc('vm.root.shop.bulk.context.countdown.days', (countdown.getDate() - 1)) }}
						{{ $tc('vm.root.shop.bulk.context.countdown.hours', countdown.getHours()) }}
						{{ $tc('vm.root.shop.bulk.context.countdown.minutes', countdown.getMinutes()) }}
						{{ $tc('vm.root.shop.bulk.context.countdown.seconds', countdown.getSeconds()) }}
					</strong>
				</p>
				<p class="text-center text-strong">{{ $tc('vm.root.shop.bulk.context.count-reservation', reservation) }}</p>
				<i18n path="vm.root.shop.bulk.context.reduction" tag="p" class="transfer-container text-center">
					<strong class="text-numeric text-xl">{{percent }} %</strong><br>
				</i18n>

				<div v-if="nextPercent > 0">
					<p class="text-center text-strong">{{ $tc('vm.root.shop.bulk.context.missing-reservation', missingReservation) }}</p>

					<i18n path="vm.root.shop.bulk.context.reduction" tag="p" class="transfer-container text-center">
						<strong class="text-numeric text-xl">{{nextPercent }} %</strong><br>
					</i18n>
				</div>

				<slider-frame label=""
							  :disabled="true"
							  :marks="marks"
							  :tooltip-formatter="tooltipFormatter"
							  v-model="Math.min(reservation, 15)" :min="0" :max="15" :step="1"></slider-frame>
			</div>
		</section>
		<section v-else class="wrapper marg-v">
			<h2>{{ $t('vm.root.shop.bulk.context.your-session') }}</h2>
			<div v-if="isLoading" class="text-center">
				<spinner class="svg-xl text-primary"></spinner>
			</div>
			<div v-else>
				<p class="text-center text-strong">{{ $t('vm.root.shop.bulk.context.end-session') }}</p>
				<p class="transfer-container text-center">
					<strong class="text-numeric">{{ $t('vm.root.shop.bulk.context.session-terminated') }}</strong>
				</p>
				<p class="text-center text-strong">{{ $tc('vm.root.shop.bulk.context.reservation-obtained', reservation) }}</p>
				<i18n path="vm.root.shop.bulk.context.reduction" tag="p" class="transfer-container text-center">
					<strong class="text-numeric text-xl">{{percent }} %</strong><br>
				</i18n>
			</div>
		</section>
	</div>

</context>
