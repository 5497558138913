import Render from '@Views/root/shop/paying.html'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {shop} from '@Store/modules'
import {PurchaseMode} from '@Enums/purchase'
import {IBulk, IPurchase} from '@Store/types/shop'

@Render
@Component({})
export class Paying extends Vue {

	@Prop({default: 0}) price: number
	@Prop({default: false}) isBulk: boolean
	@Prop() payer: IBulk | IPurchase

	//#region Getters
	get isPaypal(): boolean {
		return this.payer.mode === PurchaseMode.Paypal
	}
	get isCheck(): boolean {
		return this.payer.mode === PurchaseMode.Check
	}
	get isTransfer(): boolean {
		return this.payer.mode === PurchaseMode.Transfer
	}
	get constants(): any {
		return shop.shop.constants
	}
	get iban(): string[] {
		return this.constants.IBAN.split(" ")
	}
	//#endregion
}
