export const access = {
    name: {
        label: "Nom de l'accès",
        placeholder: "ex : Métro Chatelet",
        'error-msg': "Merci de renseigner le nom de l'accès",
        title: "Veuillez renseigner le nom de l'accès"
    },
    type: {
        label:"Type d'accès",
        title:"Sélectionnez le type d'accès pour plus de précision",
    },
    line: {
        label:"Ligne concernée",
        title:"Sélectionnez la ligne concernée par votre accès",
    },
    description: {
        label: "Description de l'accès ({count} caractère restant) | Description de l'accès ({count} caractère restant) | Description de l'accès ({count} caractères restants)",
        placeholder: "ex : En face de la poste",
        title: "Veuillez renseigner la description de cet accès",
		'msg-error': "Votre description ne doit pas dépasser 250 caractères"
    },
    delete: {
        msg: "Voulez-vous vraiment supprimer cet accès ?",
        title: "Supprimer cet accès"
    }
}
