<container :loading="!patient" :loading-title="$t('vm.root.patients.patient.main.loading')">
	<aside-patient @delete="onDelete" v-if="!!patient && displayAsidePatient"/>
	<aside-consultation v-if="!!patient && displayAsideConsultation" :read-only="readonly" :disabled="disabled"/>
	<aside-pediatrics v-if="!!patient && displayAsidePediatrics"/>

	<master>

		<central v-on:scroll.native="triggerHandleScroll" id="target-to-top">
			<div v-if="!!patient">
				<header class="patient-header" id="header">
					<div :class="{'wrapper': !hasWrapperFull, 'wrapper-full': hasWrapperFull}">
						<div class="patient-header-wrapper">
							<div class="patient-header-flex">
								<figure class="patient-header-image">
									<avatar class="patient-header-avatar" :first-name="patient.coordinates.first_name" :gender="patient.coordinates.gender"
										:last-name="patient.coordinates.last_name" :email="patient.coordinates.email"/>
									<anonymous tag="figcaption" class="sr-only" :text="patientName"/>
								</figure>
								<div class="patient-header-infos">
									<h1 class="patient-header-name text-capitalize">
										<span v-if="blacklisted" class="patient-blacklisted">{{ $t('vm.root.patients.patient.main.blacklisted') }}</span>
										<span v-if="deceased" class="patient-deceased">{{ $t('vm.root.patients.patient.main.deceased') }}</span>
										<anonymous v-if="!patientName" :class="{'text-danger':blacklisted}" :text="$t('vm.root.patients.patient.main.new-patient')"/>
										<template v-else>
											<anonymous class="text-normal" :class="{'text-danger':blacklisted}" :text="patient.coordinates.first_name"/>
											<anonymous :class="{'text-danger':blacklisted}" :text="patient.coordinates.last_name"/>
										</template>
									</h1>
									<div>
										<strong v-if="isNoneBracket">{{ $t('vm.root.patients.patient.main.no-date') }}</strong>
										<strong v-else>{{ $t('vm.root.patients.patient.main.patient-description', {gender: gender, age: age }) }}</strong>,
										<span v-if="hasProfession && profession">{{profession}}</span><span class="text-italic text-sm" v-else>{{ $t('vm.root.patients.patient.main.no-profession') }}</span>
									</div>
									<div class="patient-header-links">
										<anonymous tag="a" v-if="hasPhone && phone" :href="`tel:${phone}`" :text="$options.filters.formatPhone(phone)"
											:title="$t('vm.root.patients.patient.main.call', {name: patientName})" />
										<span class="text-italic text-sm" v-else>{{ $t('vm.root.patients.patient.main.no-phone') }}</span> &ndash;
										<anonymous tag="a" v-if="patient.coordinates.email" :email="true" :href="`mailto:${patient.coordinates.email}`"
											:title="$t('vm.root.patients.patient.main.send-email', {name: patientName})" :text="patient.coordinates.email"/>
										<span class="text-italic text-sm" v-else>{{ $t('vm.root.patients.patient.main.no-email') }}</span>
										<div class="patient-header-controls text-sm">
											<button ref="active-notes" v-if="patient.coordinates.notes" type="button" class="btn--link-like patient-header-notes-trigger"
												:class="{'text-warning text-bold': isMediumLevel, 'text-danger text-bold': isHighLevel}"
												v-toggle.main.self="{className: 'is-active', selector: '.patient-header-notes', state:'notes'}">
												<svg width="1em" class="svg-middle patient-header-notes-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
													<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
												</svg><!--
												--><svg v-if="isHighLevel" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
													<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle-strong" />
												</svg>
												<span class="patient-header-notes-trigger-label-show">{{ $t('vm.root.patients.patient.main.notes.display') }}</span>
												<span class="patient-header-notes-trigger-label-hide">{{ $t('vm.root.patients.patient.main.notes.hide') }}</span>
											</button>
											<span class="text-italic" v-else>{{ $t('vm.root.patients.patient.main.notes.none') }}</span>
											•
											<button type="button" @click="print" class="btn--link-like"
												:title="$t('vm.root.patients.patient.main.print.title')">
												<span>{{ $t('vm.root.patients.patient.main.print.label') }}</span>
											</button>
											•
											<button type="button" @click="duplicate" class="btn--link-like"
												:title="$t('vm.root.patients.patient.main.duplicate.title')">
												<span>{{ $t('vm.root.patients.patient.main.duplicate.label') }}</span>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div v-if="patient.coordinates.notes" class="patient-header-notes">
								<div ref="notes" class="patient-header-notes-content" :class="{'notice-warning': isMediumLevel, 'notice-danger': isHighLevel, 'notice-info': isLowLevel}">
									<p class="text-left">
										<svg v-if="isHighLevel" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
										</svg>
										<span class="text-pre-line">{{ patient.coordinates.notes }}</span>
									</p>
								</div>

								<!-- <div ref="notes" class="patient-header-notes-content">{{patient.coordinates.notes}}</div> -->
							</div>
						</div>
					</div>
				</header>

				<div :class="{'wrapper': !hasWrapperFull, 'wrapper-full': hasWrapperFull}">
					<form @submit.prevent="submitSave">
						<!-- Prevent implicit submission of the form -->
						<button type="submit" disabled style="display: none" aria-hidden="true" focusable="false"/>

						<router-view :read-only="readonly" :submitted="submitted" :disabled="disabled" @edit="onEdit" @navigate="onNavigate"/>
						<control-bar class="text-right" v-if="readonly">
							<button-action btn-class="btn btn-primary" @click.native="readonly = false" :title="$t('vm.root.patients.patient.main.edit.title')">
								<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#pencil"/>
								</svg>
								{{ $t('vm.root.patients.patient.main.edit.label') }}
							</button-action>

							<button-to-top target="target-to-top"/>
						</control-bar>

						<control-bar class="childs-space-between" v-else>
							<div v-if="disabled">
								<button-action v-if="!patient.is_new" btn-class="btn-success" @click.native="submitCancel" :title="$t('vm.root.patients.patient.main.terminate.title-button')">
									<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
										<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
									</svg>
									{{ $t('vm.root.patients.patient.main.terminate.label') }}
									<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.main.terminate.long') }}</span>
								</button-action>
							</div>
							<div v-else>
								<button-action v-if="!patient.is_new" btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving"
									:title="$t('vm.root.patients.patient.main.cancel.title-button')">
									<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
										<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
									</svg>
									{{ $t('vm.root.patients.patient.main.cancel.label') }}
									<span class="rwd-over-xlarge">{{ $t('vm.root.patients.patient.main.cancel.long') }}</span>
								</button-action>
							</div>
							<div>
								<button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled"
									:title="$t('vm.root.patients.patient.main.save.title')">
									{{ $t('vm.root.patients.patient.main.save.label') }}
									<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
										<use xlink:href="@Assets/svg/symbol-defs.svg#folder-download"/>
									</svg>
								</button-action>

								<button-to-top target="target-to-top"/>
							</div>
						</control-bar>
					</form>
				</div>
			</div>
		</central>

		<context-main v-if="!!patient" @edit="onEdit"/>

	</master>
</container>
