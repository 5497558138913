import Render from '@Views/components/full-calendar.html'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { defaultsDeep } from 'lodash'
import { Calendar, EventInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { PlanningType, ReminderType } from '@Enums/index'
import { IPatient, IConfiguration } from '@Store/types'
import { configuration } from '@Store/modules'

@Render
@Component({})
export class FullCalendar extends Vue {
	private calendar: any = null
	private currentView: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth' = 'timeGridWeek'

	@Prop({default: () => []}) events: any[]
	@Prop({default: () => []}) eventSources: any[]
	@Prop({default: false}) scrollTime: string | false
	@Prop({default: true}) eventDurationEditable: boolean
	@Prop({default: true}) eventStartEditable: boolean
	@Prop({default: true}) editable: boolean
	@Prop({default: true}) eventLimit: boolean
	@Prop({default: true}) selectable: boolean
	@Prop({default: true}) selectHelper: boolean
	@Prop({default: () => FullCalendar.defaultHeader}) header: any
	@Prop({default: () => FullCalendar.defaultViews}) views: any
	@Prop({default: () => 'timeGridWeek'}) defaultView: () => string
	@Prop({default: () => false}) sync: () => boolean
	@Prop({default: () => FullCalendar.defaultConfig}) config: any
	@Prop({default: () => FullCalendar.defaultEventRender}) eventRender: (info: IEventRenderParameter) => void
	@Prop({default: () => FullCalendar.defaultViewRender}) viewRender: (info: IViewRenderParameter) => void

	@Watch('events')
	updateEvents(newEvents: any[], oldEvents: any[]) {
		// let events = this.calendar.getEvents()
		for(let fullEvent of oldEvents) {
			let event = this.calendar.getEventById(fullEvent.id)
			if (!event) continue
			event.remove()
		}
		this.calendar.addEventSource(newEvents)
	}

	@Watch('eventSources')
	updateEventSources(newEvents: any[], oldEvents: any[]) {
		this.$emit('rebuild-sources')
	}

	mounted() {
		let config = defaultsDeep({...this.config}, this.currentConfig())

		this.calendar = new Calendar(this.$el as HTMLElement, config)
		this.calendar.render()
	}

	renderEvents(events: any[]): void {
		let vm = this
		if (!this.calendar) return

		this.calendar.batchRendering(() => {
			for(let event of events) {
				vm.calendar.addEvent(event)
			}
		})
	}

	clear(): void {
		if (!this.calendar) return

		let events = this.calendar.getEvents()
		this.calendar.batchRendering(() => {
			for(let event of events) {
				event.remove()
			}
		})
	}

	clearBackground(): void {
		if (!this.calendar) return

		let events = this.calendar.getEvents()
		this.calendar.batchRendering(() => {
			for(let event of events) {
				if (event.rendering !== 'inverse-background' && event.rendering !== 'background') continue
				event.remove()
			}
		})
	}

	setBusinessHours(businessHours: any): void {
		if (!this.calendar) return

		this.calendar.setOption('businessHours', businessHours)
	}

	getDate(): Date {
		return this.calendar.getDate()
	}

	gotoDate(date: Date): void {
		this.calendar.gotoDate(date)
	}

	next(): void {
		this.calendar.next()
	}

	previous(): void {
		this.calendar.prev()
	}

	today(): void {
		this.calendar.today()
	}

	title(): string {
		return this.calendar.view.title
	}

	changeView(view: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth'): void {
		this.currentView = view
		this.calendar.changeView(view)
	}

	beforeDestroy() {
		this.calendar.destroy()
		this.calendar = null
	}

	public static getFullCalendarEvent(event: EventInput): IFullCalendarEvent {
		let fullEvent: IFullCalendarEvent = event.extendedProps as IFullCalendarEvent

		let end: Date = event.end as Date
		if (!end && !event.allDay) {
			end = new Date(event.start as Date)
			end.setHours(end.getHours() + 1)
		}
		return {
			id: event.id as string,
			title: event.title,
			editable: event.editable || event.startEditable,
			start: event.start as Date,
			end: end,
			allDay: event.allDay,
			...fullEvent
		}
	}

	//#region Private methods
	@Emit('event-render')
	private invokeEventRender(info: IEventRenderParameter): void {
		this.eventRender(info)
	}

	@Emit('view-render')
	private invokeViewRender(info: IViewRenderParameter): void {
		this.viewRender(info)
	}

	private currentConfig() {
		const self = this
		let date = new Date()
		let defaultScrollTime = `${date.getHours().toLocaleString('fr-FR', {minimumIntegerDigits: 2})}:00:00`
		return {
			plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin ],
			header: this.header,
			views: this.views,
			defaultView: this.defaultView,
			editable: this.editable,
			eventDurationEditable: this.eventDurationEditable,
			eventStartEditable: this.eventStartEditable,
			eventLimit: this.eventLimit,
			selectable: this.selectable,
			selectHelper: this.selectHelper,
			aspectRatio: 2,
			nowIndicator: true,
			weekNumbers: true,
			//weekNumbersWithinDays: true,
			weekNumberCalculation: "local",
			weekLabel: this.$t('vm.components.full-calendar.week-text'),
			weekNumberFormat: { week: 'narrow' },
			// weekLabel: '',
			eventTimeFormat: { // like '14:30:00'
				hour: 'numeric',
				minute: '2-digit',
				meridiem: false,
				hour12: false
			},
			scrollTime: this.scrollTime || defaultScrollTime,
			timeFormat: 'HH:mm',
			slotLabelFormat: {
				hour: '2-digit',
				minute: '2-digit',
				omitZeroMinute: false,
				meridiem: false
			},
			locale: configuration.configuration.lang,
			hiddenDays: configuration.configuration.hidden_days,
			height: 'parent',
			firstDay: 1,
			allDayText: this.$t('vm.components.full-calendar.all-day-text'),
			events: this.events,
			eventSources: this.eventSources,
			eventRender(info: IEventRenderParameter) {
				if (this.sync) {
					self.events = this.calendar.fullCalendar('clientEvents')
				}
				self.invokeEventRender(info)
			},
			columnHeaderHtml: this.columnHeaderHtml,
			datesRender(info: IViewRenderParameter) {
				if (this.sync) {
					self.events = this.calendar.fullCalendar('clientEvents')
				}
				self.invokeViewRender(info)
			},
			eventDestroy(info: IEventDestroyParameter) {
				if (this.sync) {
					self.events = this.calendar.fullCalendar('clientEvents')
				}
			},
			eventClick(info: IEventClickParameter) {
				self.$emit('event-selected', info)
			},
			eventDrop(info: IEventDropParameter) {
				self.$emit('event-drop', info)
			},
			eventReceive(info: IEventReceiveParameter) {
				self.$emit('event-receive', info)
			},
			eventResize(info: IEventResizeParameter) {
				self.$emit('event-resize', info)
			},
			dateClick(info: IDateClickParameter){
				self.$emit('date-click', info)
			},
			select(info: ISelectParameter) {
				self.$emit('event-created', info)
			}
		}
	}

	private columnHeaderHtml(date: Date): string {
		if (this.currentView === 'dayGridMonth') return this.$d(date, 'weekdayMin')
		if (this.currentView === 'timeGridWeek') {
			let title_text:string = `<span class='fc-widget-header-day'>${this.$d(date, 'weekdayMin')}</span>`
			title_text += `<span class='fc-widget-header-number'>${date.getDate()}</span>`

			return title_text
		}
		return ''
	}

	private static defaultConfig: any = {}

	private static defaultHeader: any = {
		left: '',
		center: '',
		right: ''
	}

	private static defaultViews: any = {
		month: {
			columnHeaderFormat: { weekday: 'short' }
		},
		week: {
			columnHeaderFormat: { day: 'numeric', weekday: 'short' }
		},
		day: {
			titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
			columnHeader: false
		},
	}

	private static defaultEventRender(info: IEventRenderParameter): void {
		let fullEvent: IFullCalendarEvent = info.event.extendedProps as IFullCalendarEvent
		if (!fullEvent) return

		if (fullEvent.unvalid) {
			info.el.classList.add('is-unvalid')
		}

		if (fullEvent.missed) {
			info.el.classList.add('is-missed')
		}

		if (!!info.event.end && new Date(info.event.end as Date) < new Date()) {
			info.el.classList.add('is-done')
		} else if (!!info.event.start && new Date(info.event.start as Date) < new Date()) {
			info.el.classList.add('is-done')
		}

		info.el.setAttribute("style", `--color:${info.event.backgroundColor};${info.el.getAttribute("style")}`)

		let titleElement: Element = FullCalendar.getTitleElement(info.el)
		let patient = fullEvent.patient
		if (!patient || !patient.coordinates) {
			info.el.setAttribute("title", !!titleElement.innerHTML ? titleElement.innerHTML : '')
			return
		}

		let title: string = !!titleElement.innerHTML ? ` &bull; ${titleElement.innerHTML}` : ''
		titleElement.innerHTML = `${patient.coordinates.first_name} ${patient.coordinates.last_name}${title}`
		info.el.setAttribute("title", `Rendez-vous avec ${patient.coordinates.first_name} ${patient.coordinates.last_name}`)
	}

	private static getTitleElement(element: HTMLElement): Element {
		let titles: HTMLCollectionOf<Element> = element.getElementsByClassName("fc-title")
		if (!!titles && titles.length > 0) return titles[0]

		let titleElement: Element = document.createElement("div")
		titleElement.classList.add('fc-title')
		element.append(titleElement)

		return titleElement
	}

	private static defaultViewRender(info: IViewRenderParameter): void {
		// if (info.view.type === 'dayGridMonth') return

		// let dayHeaders:HTMLCollectionOf<Element> = info.el.getElementsByClassName("fc-day-header")
		// for(let dayHeader of dayHeaders) {
		// 	let title:HTMLElement = dayHeader as HTMLElement
		// 	let title_text: string = title.innerText
		// 	let array_title: string[] = title_text.split(' ')

		// 	title_text = `<span class='fc-widget-header-day'>${array_title[0]}</span>`
		// 	title_text += `<span class='fc-widget-header-number'>${array_title[1]}</span>`
		// 	title.innerHTML = title_text
		// }
	}
	//#endregion
}

export interface IBusinessHour {
	daysOfWeek: number[],
	startTime: string,
	endTime: string
}

export interface IFullCalendarEvent {
	id?: string 					// Uniquely identifies the given event. Different instances of repeating events should all have the same id.
	groupId?: string
	title: string 					// The text on an event's element
	missed?: boolean
	unvalid?: boolean
	type: PlanningType
	confirmation_type?: ReminderType
	reminder_type?: ReminderType
	patient?: IPatient
	ofi_id?: string
	ses_id?: string
	usr_id?: string
	pla_id?: string
	allDay?: boolean				// Whether an event occurs at a specific time-of-day. This property affects whether an event's time is shown. Also, in the agenda views, determines if it is displayed in the "all-day" section. If this value is not explicitly specified, allDayDefault will be used if it is defined. If all else fails, FullCalendar will try to guess. If either the start or end value has a "T" as part of the ISO8601 date string, allDay will become false. Otherwise, it will be true. Don't include quotes around your true/false. This value is a boolean, not a string!
	start: string | Date			// The date/time an event begins. Required. A Moment-ish input, like an ISO8601 string. Throughout the API this will become a real Moment object.
	end?: string | Date				// The exclusive date/time an event ends. Optional. A Moment-ish input, like an ISO8601 string. Throughout the API this will become a real Moment object. It is the moment immediately after the event has ended. For example, if the last full day of an event is Thursday, the exclusive end of the event will be 00:00:00 on Friday!
	url?: string					// A URL that will be visited when this event is clicked by the user. For more information on controlling this behavior, see the eventClick callback.
	className?: string | string[] 	// A CSS class (or array of classes) that will be attached to this event's element.
	editable?: boolean				// Overrides the master editable option for this single event.
	startEditable?: boolean			// Overrides the master eventStartEditable option for this single event.
	durationEditable?: boolean		// Overrides the master eventDurationEditable option for this single event.
	resourceEditable?: boolean		// Overrides the master eventResourceEditable option for this single event.
	rendering?: any					// Allows alternate rendering of the event, like background events. Can be empty, "background", or "inverse-background"
	overlap?: boolean				// Overrides the master eventOverlap option for this single event. If false, prevents this event from being dragged/resized over other events. Also prevents other events from being dragged/resized over this event.
	constraint?: any				// an event ID, "businessHours", object. Optional. Overrides the master eventConstraint option for this single event.
	source?: IFullCalendarEvent		// Event Source Object. Automatically populated. A reference to the event source that this event came from.
	color?: string					// Sets an event's background and border color just like the calendar-wide eventColor option.
	backgroundColor?: string		// Sets an event's background color just like the calendar-wide eventBackgroundColor option.
	borderColor?: string			// Sets an event's border color just like the the calendar-wide eventBorderColor option.
	textColor?: string				// Sets an event's text color just like the calendar-wide eventTextColor option.
	display?: string
}

export interface IEventRenderParameter {
	event: EventInput,
	el: HTMLElement,
	view: any,
	isStart: boolean,
	isEnd: boolean,
	isMirror: boolean
}

export interface IViewRenderParameter {
	view: any,
	el: HTMLElement
}

export interface IEventDestroyParameter {
	title: string,
	el: HTMLElement,
	view: any
}

export interface IEventClickParameter {
	event: EventInput,
	el: HTMLElement,
	jsEvent: any,
	view: any
}

export interface IDateClickParameter {
	date: Date,
	dateStr: string,
	allDay: boolean,
	dayEl: HTMLElement,
	jsEvent: any
}

export interface IEventDropParameter {
	event: EventInput,
	prevEvent: any,
	delta: any,
	jsEvent: any
	revert: () => void
}
export interface IEventResizeParameter {
	event: EventInput,
	prevEvent: any,
	startDelta: any,
	endDelta: any,
	jsEvent: any
	revert: () => void
}

export interface IEventReceiveParameter {
	draggedEl: HTMLElement,
	event: EventInput,
	view: any
}

export interface ISelectParameter {
	start: Date,
	startStr: string,
	end: Date,
	endStr: string,
	allDay: boolean,
	jsEvent: any,
	view: any,
	resource: any
}

export interface IEventsParameter {
	start: Date,
	startStr: string,
	end: Date,
	endStr: string,
	timeZone: string
}
