import { isEqual } from 'lodash'
import { i18n } from '@I18n/index'

export class Common {
	public static updateItemList(list: any[], oldList: any[]) {
		for(let item of list) {
			let oldItem = oldList.find(i => i.id === item.id)
			if (!oldItem) continue
			item['update'] = !isEqual(item, oldItem)
		}
	}

	public static addMessageNotification(array: string[], str: string): void {
		if (!str) return
		array.push(str)
	}

	public static addMessagesNotification(array: string[], values: string[]): void {
		if (!values || values.length === 0) return
		for(let str of values) {
			Common.addMessageNotification(array, str)
		}
	}

	public static getMessageNotification(model: string, action: string, label: string, count: number, female: boolean): string {
		if (count <= 0) return ''
		let tag: string = `models.${model}.${action}${female ? '-female' : ''}`
		return i18n.tc(tag, count, {item: i18n.tc(`models.${model}.${label}`, count)})
	}

	public static generateId(): string {
		return `${Common.getSubId()}${Common.getSubId()}${Common.getSubId()}${Common.getSubId()}${Common.getSubId()}${Common.getSubId()}`
	}

	private static getSubId(): string {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
	}
}