<div class="l-app" :class="{'is-without-aside': !hasAside}" v-if="!!user">

	<headeur/>

	<div class="l-sub-app">
		<navigation/>
		<router-view/>
	</div>

</div>
