export const configuration = {
	accounting: "paramètres des finances",
	'follow-up': "paramètres du suivi de consultation",
	notification: "paramètres des notifications",
	general: "paramètres généraux",
	planning: "paramètres du planning",
	relaunch: "paramètres de la relance préventive",
	reminder: "paramètres du rappel de rendez-vous",

	anonymous: "mode anonyme du dossier patient",
	colors: "couleurs du dossier patient",
	display: "affichage du dossier patient",

	document: 'modèle de document | modèle de document | modèles de document',
	form: 'questionnaire personnalisé | questionnaire personnalisé | questionnaires personnalisés',

	added: 'un {item} ajouté | {n} {item} ajoutés',
	updated: 'un {item} modifié | {n} {item} modifés',
	deleted: 'un {item} effacé | {n} {item} effacés',
	'configuration-saved': "Paramètres sauvegardés"
}
