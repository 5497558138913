import Render from '@Views/root/calendar/event/aside-event.html'
import { Component, Vue } from 'vue-property-decorator'
import {
	IConfiguration,
	IEvent,
	INotification,
	INotificationAction,
	IPopin,
	IPopinAction
} from '@Store/types'
import {DateHelper} from '@Helpers/index'
import {PlanningType} from '@Enums/index'
import {event, notif, popIn, configuration} from '@Store/modules'
import {cloneDeep} from "lodash"

@Render
@Component({})
export class ASideEvent extends Vue {

	private deleting: boolean = false

	//#region Getters
	get event(): IEvent {
		return event.event
	}
	get date(): string {
		return DateHelper.getDateString(!this.event ? undefined : new Date(this.event.start_date))
	}
	get isClassic(): boolean {
		return this.event.type === PlanningType.Classic
	}
	get isDeleting(): boolean {
		return event.isDeleting
	}
	get isCancelling(): boolean {
		return event.isCancelling || this.deleting
	}
	get canCancel(): boolean {
		return parseInt(this.event.id) !== -1 && !!this.event.patient
	}
	get prefix(): string {
		if (!!this.$route.params.date)
			return 'new-'
		if (!!this.$route.params.pla_id && !!this.$route.params.evt_id)
			return 'google-'

		return ''
	}
	//#endregion

	submitDelete(): void {
		const { date } = this
		let route = {name: 'plannings', params: {date: date}}

		this.$emit('delete')
		if (!!this.event && !!this.event.id && parseInt(this.event.id) !== -1) {
		  event.deleteEvent({event: this.event}).then(() => {
			this.$router.push(route)
		  })
		} else {
		  this.$router.push(route)
		}
	}

	submitCancel(): void {
		const { date } = this
		this.deleting = false
		let route = {name: 'plannings', params: {date: date}}

		this.$emit('delete')
		event.cancelEvent({event: this.event}).then(() => {
		  this.deleting = true
		  event.deleteEvent({event: this.event, withoutNotification: true})
		  .then(() => {
			this.deleting = false
			this.$router.push(route)
		  })
		  .catch(() => { this.deleting = false })
		})
	}

	get configuration(): IConfiguration {
		return configuration.configuration
	}

	duplicate() {
		let currentEvent: IEvent = cloneDeep(this.event)
		let duplicateEvent: IEvent = cloneDeep(this.event)
		duplicateEvent.id = '-1'
		let failedNotification: INotification = { message: this.$i18n.t('vm.root.calendar.event.aside.duplicate.failed').toString(), actions: [], canClose: true, delay: 5000 }

		let duplicateAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.event.aside.duplicate.label').toString(),
			title: this.$i18n.t('vm.root.calendar.event.aside.duplicate.title').toString(),
			callback: (data: {event: IEvent}, updateLoading: any) => {
				updateLoading(true)

				return event.saveEvent({event: data.event})
					.then(newEvent => {
						updateLoading(false)
						let goToDuplicateAction: INotificationAction = {
							label: this.$i18n.t('vm.root.calendar.event.aside.duplicate.action-label').toString(),
							callback: () => { this.$router.push({name: 'event', params: {evt_id: newEvent.id}}) }
						}
						let notification: INotification = {
							message: this.$i18n.t('vm.root.calendar.event.aside.duplicate.succeeded').toString(),
							actions: [goToDuplicateAction],
							canClose: true,
							delay: 10000
						}

						event.saveSuccess(currentEvent)
						notif.success(notification)
						return Promise.resolve()
					}, () => {
						updateLoading(false)
						return Promise.reject()
					})
					.catch(() => {
						updateLoading(false)
						notif.error(failedNotification)
						return Promise.reject()
					})
			}
		}

		let duplicate: IPopin = {
			title: this.$i18n.t('vm.root.calendar.event.aside.duplicate.title-popin').toString(),
			content: {component: 'popin-duplicate-event'},
			action: duplicateAction,
			options: {event: duplicateEvent},
			cancelAction: notif.clear,
			isWide: true
		}

		popIn.info(duplicate)
	}
}
