<a-side :sub-menu="true" v-if="!!receipt">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'accounting'}" :title="$t('vm.root.accounting.receipt.aside.back.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.accounting.receipt.aside.back.label') }}
			</router-link>
        </li>
    </ul>
    <ul class="aside-navigation is-pushed-down">
        <li v-if="canPrint" class="hr" aria-hidden="true"/>
        <li v-if="canPrint">
            <button type="button" @click="print" :title="$t('vm.root.accounting.receipt.aside.print.title')">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': isPrinted}">
					<use v-if="isPrinted" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					<use v-else xlink:href="@Assets/svg/symbol-defs.svg#print"/>
				</svg>
                {{ $t('vm.root.accounting.receipt.aside.print.label') }}
            </button>
        </li>
        <li v-if="canPrint && !!receipt.patient">
            <button type="button" @click="send" :disabled="isSending" :title="$t('vm.root.accounting.receipt.aside.send.title')">
                <spinner v-if="isSending"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false" :class="{'text-success': isSended}">
                    <use v-if="isSended" xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					<use v-else xlink:href="@Assets/svg/symbol-defs.svg#paper-plane"/>
				</svg>
                {{ $t('vm.root.accounting.receipt.aside.send.label') }}
            </button>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <delete-item :can-delete="true" :disabled="isDeleting"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
                :title="$t('vm.root.accounting.receipt.aside.delete.title')"
                :delete-msg="$t('vm.root.accounting.receipt.aside.delete.delete-msg')"
                :delete-action="submitDelete">
                <spinner v-if="isDeleting"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
                </svg>
                {{ $t('vm.root.accounting.receipt.aside.delete.label') }}
            </delete-item>
        </li>
    </ul>
</a-side>
