<div class="wrapper marg-v">
	<h1>{{ $t('vm.root.patients.campaign.summary.title') }}</h1>
	<h2>{{ campaign.title }}</h2>

	<textarea-frame id="message" v-model="campaign.message" :readonly="true" rows="5"
		:label="$t('vm.root.patients.campaign.summary.sended', {date: $d(campaign.sending_date, 'll'), hour: $d(campaign.sending_date, 'LT')})"/>
	
	<div class="input-frame">
		<label class="label">{{ $t('vm.root.patients.campaign.summary.sms-count') }}</label>
		<div class="text-lg text-center text-bold semi-marg-v">
			{{ $tc('vm.root.patients.campaign.summary.sms', campaign.total_sms) }}
		</div>
	</div>

	<section>
		<collapsible-help id="stop-sms-information" tag="h2" :title="$t('vm.root.patients.campaign.summary.stop-sms.title')">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
			</svg>
			<div class="notice-info">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					{{ $t('vm.root.patients.campaign.summary.stop-sms.help') }}
				</p>
			</div>
		</collapsible-help>
		<div v-if="!campaign.sms_stop || campaign.sms_stop.length === 0" class="marg-v text-center">
			<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#smile-beam"/>
			</svg>
			<p>
				<span class="text-lg">{{ $t('vm.root.patients.campaign.summary.stop-sms.none-title') }}</span><br>
				{{ $t('vm.root.patients.campaign.summary.stop-sms.none-label') }}
			</p>
		</div>
		<table v-else class="data-table">
			<thead>
				<tr>
					<th class="w80 text-left" scope="col">{{ $t('vm.root.patients.campaign.summary.header.numbers') }}</th>
					<th class="w20 text-center" scope="col"><span class="sr-only">$t('vm.root.patients.campaign.summary.header.action') }}</span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(number, index) in campaign.sms_stop">
					<th class="text-left text-normal" scope="row">{{ index + 1 }}. <span class="select">{{ number }}</span></th>
					<td>
						<button type="button" class="btn--link-like is-full-width" @click="search(number)"
							:title="$t('vm.root.patients.campaign.summary.search.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
							</svg>
							{{ $t('vm.root.patients.campaign.summary.search.label') }}
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</div>