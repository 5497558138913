export const ticket = {
    title: "Ticket",
    loading: "Chargement en cours…",
    'message-count': "sans message | un message | {count} messages",
    'from-osteo2ls': "Osteo2ls",
    'from-you': "Vous",
    'new-message': "nouveau message",
    text: "Vous devez sélectionner un ticket existant{0}.",
    'create-new': " ou en créer un nouveau",
    'group-fb': "Vous pouvez également rejoindre notre {0}. L'aide y est gratuite et les autres utilisateurs d'Osteo2ls pourront vous apporter leur aide.",
    'link-title': "Groupe d'entraide pour Osteo2ls", 
    'link-label': "groupe d'entraide sur Facebook",
    notification: "Une <strong>réponse</strong> vient d'être apportée à votre ticket."
}