import Render from '@Views/root/accounting/remittances/remittance/context-remittance.html'
import { Component } from 'vue-property-decorator'
import { AbstractContext } from '../../context-base'
import { ILinkUser } from '@Store/types'
import { user } from '@Store/modules'

@Render
@Component({})
export class ContextRemittance extends AbstractContext {

	private oldUsrId: string = '-1'

	mounted() {
		super.reset()
		this.oldUsrId = super.filter.usr_id
		super.filter.usr_id = user.user.id
	}

	reset(): void {
		super.reset()
	}

	beforeDestroy() {
		super.filter.usr_id = this.oldUsrId
	}

	getUserList(): ILinkUser[] {
		return []
	}
}