import Render from '@Views/root/shop/bulk/aside-main.html'
import { Component, Vue } from 'vue-property-decorator'
import { PurchaseState } from '@Enums/purchase'
import { purchase } from '@Store/modules'
import { IPurchase } from '@Store/types'

@Render
@Component({})
export class ASideMain extends Vue {

    // @State('shop') stateShop: ShopState
    // @Getter('shop/purchase') purchase: IPurchase
    // @Action('shop/deletePurchase') deletePurchase: (pur_id: string) => Promise<any>

    get purchase(): IPurchase {
      return purchase.purchase
    }
    get isDeleting(): boolean {
      return purchase.isDeleting()
    }
    get isCompleted(): boolean {
		  return this.purchase.state === PurchaseState.Completed
    }

    submitDelete(): void {
        let route = {name: 'shop'}

        this.$emit('delete')
        if (!!this.purchase && !!this.purchase.id && parseInt(this.purchase.id) !== -1) {
            purchase.deletePurchase(this.purchase.id).then(() => {
                this.$router.push(route)
            })
        } else {
          this.$router.push(route)
        }
    }
}
