import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IRegister, IUser } from "@Store/types"
import { RequestStatus, Profile } from "@Enums/index"
import { LoginService } from "@Services/index"

@Module({
	name: 'login',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
export class LoginModule extends VuexModule {

	statusAccount: RequestStatus = RequestStatus.None
    statusLogin: RequestStatus = RequestStatus.None
    statusLostLogin: RequestStatus = RequestStatus.None
    statusRegistration: RequestStatus = RequestStatus.None
    email: string = null
    profile: Profile = Profile.None
    accounts: number[] = []

	get isLogging(): boolean {
		return this.statusAccount === RequestStatus.Loading
	}
	get isAuthenticating(): boolean {
		return this.statusLogin === RequestStatus.Loading
	}
	get isRegistering(): boolean {
		return this.statusRegistration === RequestStatus.Loading
	}
	get isSending(): boolean {
		return this.statusLostLogin === RequestStatus.Loading
	}

	@Mutation
	updateEmail(email: string) {
		this.email = email
	}

	@Mutation
	accountsRequest(email: string) {
		this.statusAccount = RequestStatus.Loading
		this.accounts = []
		this.email = email
	}

	@Mutation
	accountsSuccess(accounts: number[]) {
		this.statusAccount = RequestStatus.Success
		this.accounts = accounts
	}

	@Mutation
	accountsFailure() {
		this.statusAccount = RequestStatus.Failed
		this.accounts = []
		this.email = null
	}

	@Mutation
	loginRequest() {
		this.statusLogin = RequestStatus.Loading
	}

	@Mutation
	loginSuccess() {
		this.statusLogin = RequestStatus.Success
	}

	@Mutation
	loginFailure() {
		this.statusLogin = RequestStatus.Failed
	}
	
	@Mutation
	lostLoginRequest(profile: Profile) {
		this.statusLostLogin = RequestStatus.Loading
		this.profile = profile
	}

	@Mutation
	lostLoginSuccess() {
		this.statusLostLogin = RequestStatus.Success
	}

	@Mutation
	lostLoginFailure() {
		this.statusLostLogin = RequestStatus.Failed
		this.profile = Profile.None
	}

	@Mutation
	logoutRequest() {
		this.statusAccount = RequestStatus.None
		this.statusLogin = RequestStatus.None
		this.statusLostLogin = RequestStatus.None
	}

	@Mutation
	registerRequest() {
		this.statusRegistration = RequestStatus.Loading
	}

	@Mutation
	registerSuccess(user: IRegister) {
		this.statusRegistration = RequestStatus.Success
		this.email = user.email
		this.accounts = [Profile.Main]
	}

	@Mutation
	registerFailure() {
		this.statusRegistration = RequestStatus.Failed
	}

	@Action({rawError: true})
	async getAccounts(email: string): Promise<any> {
		this.accountsRequest(email)

		let service = new LoginService()
		return service.getAccounts(email)
		.then((accounts: number[]) => {
			this.accountsSuccess(accounts.sort())
			return Promise.resolve(this.accounts)
		})
		.catch(error => {
			this.accountsFailure()
			return Promise.reject(error)
		})
	}
	
	@Action({rawError: true})
	async login(payload:{email: string, password: string, profile: Profile}): Promise<any> {
		this.loginRequest()

		let service = new LoginService()
		return service.login(payload.email, payload.password, payload.profile)
		.then((user: IUser) => {
			user.email_tmp = !!user.email_tmp ? user.email_tmp : ''
			this.loginSuccess()
			return Promise.resolve(user)
		})
		.catch(error => {
			this.loginFailure()
			return Promise.reject(error)
		})
	}

	@Action
	async lostLogin(payload:{email: string, profile: Profile}): Promise<any> {
		this.lostLoginRequest(payload.profile)

		let service = new LoginService()
		return service.lostLogin(payload.email, payload.profile)
		.then(
			() => {
				this.lostLoginSuccess()
				return Promise.resolve()
			},
			() => {
				this.lostLoginFailure()
				return Promise.reject()
			}
		)
	}

	@Action({rawError: true})
	async isLoggedIn(): Promise<any> {
		let service = new LoginService()
		return service.isLoggedIn()
		.then(
			user => {
				this.loginSuccess()
				return Promise.resolve(user)
			},
			() => {
				this.loginFailure()
				return Promise.reject()
			}
		)
	}

	@Action
	async logout(): Promise<any> {
		let service = new LoginService()
		return service.logout()
		.then(() => {
			this.logoutRequest()
			store.dispatch('reset', null, { root: true })
			return Promise.resolve()
		})
	}
	
	@Action({rawError: true})
	async register(user: IRegister): Promise<any> {
		this.registerRequest()

		let service = new LoginService()
		return service.register(user)
		.then(() => {
			this.registerSuccess(user)
			return Promise.resolve(user)
		},
		error => {
			this.registerFailure()
			return Promise.reject(error)
		})
	}
}

export const login = getModule(LoginModule)
// export const login = new LoginModule({ store, name: "login" })
