<container>

	<aside-patients/>

	<master>

		<central>
			<div class="wrapper">
				<div class="stat-list marg-v">
					<div v-if="loadingCounts">
						<spinner class="svg-block svg-center text-primary stat-list-value"/>
					</div>
					<div v-else class="text-center">
						<div class="stat-list-value"><count-up v-model="countTotal"/></div>
						<div class="text-bold text-md">{{ $tc("vm.root.patients.main.patient", countTotal) }}</div>
					</div>

					<div v-if="loadingCounts">
						<spinner class="svg-block svg-center text-primary stat-list-value"/>
					</div>
					<div v-else class="text-center">
						<div class="stat-list-value"><count-up v-model="countBlacklist"/></div>
						<div class="text-bold text-md">{{ $tc("vm.root.patients.main.blacklisted-patient", countBlacklist) }}</div>
					</div>

					<div v-if="loadingCounts">
						<spinner class="svg-block svg-center text-primary stat-list-value"/>
					</div>
					<div v-else class="text-center">
						<div class="stat-list-value"><count-up v-model="countCurrentMonth"/></div>
						<div class="text-bold text-md">{{ $tc("vm.root.patients.main.new-patient-this-month", countCurrentMonth) }}</div>
					</div>
				</div>

				<hr>

				<div v-if="displayWarning" :class="{'notice-warning': canCreateNewPatient, 'notice-danger': !canCreateNewPatient}">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
						</svg>
						<i18n path="vm.root.patients.main.license.text" tag="span">
							<strong>{{ $t("vm.root.patients.main.license.strong", {license: user.license}) }}</strong>
							<span>{{ canCreateNewPatient ? $t("vm.root.patients.main.license.span") : '' }}</span>
							<router-link :to="to" :title="$t('vm.root.patients.main.license.title')">{{ $t("vm.root.patients.main.license.link") }}</router-link>
						</i18n>
					</p>
				</div>

				<div class="stat-list marg-v">
					<button @click="createNewPatient" :disabled="loadingCounts || isAnonymous" class="patient-list-link btn--link-like" :title="$t('vm.root.patients.main.new-patient.title')">
						<svg width="1em" class="svg-middle svg-block text-xl marg-auto semi-marg-b" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
						</svg>
						<strong>{{ $t("vm.root.patients.main.new-patient.action") }}</strong>
					</button>
					<router-link :to="{name: 'patients-export'}" class="patient-list-link" :title="$t('vm.root.patients.main.listing-export.title')">
						<svg width="1em" class="svg-middle svg-block text-xl marg-auto semi-marg-b" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#clipboard-list-check"/>
						</svg>
						<strong>{{ $t("vm.root.patients.main.listing-export.action") }}</strong>
					</router-link>
					<dropdown-actions
						id="action"
						:actions="actionItems"
						btn-class="patient-list-link form-group-input btn--link-like is-full-width no-marg text-bold"
						trigger-class="patient-list-link form-group-input is-flex-fixed is-last"
						svg-class="svg-block text-xl marg-auto semi-marg-b"
					/>
<!--					<router-link :to="{name: 'patients-campaigns'}" class="patient-list-link" :title="$t('vm.root.patients.main.sms-campaign.title')">-->
<!--						<svg width="1em" class="svg-middle text-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">-->
<!--							<use xlink:href="@Assets/svg/symbol-defs.svg#comment-alt-lines"/>-->
<!--						</svg><br>-->
<!--						<strong>{{ $t("vm.root.patients.main.sms-campaign.action") }}</strong>-->
<!--					</router-link>-->
				</div>

				<hr>

				<h2>{{ $t("vm.root.patients.main.next.title") }}</h2>
				<div v-if="loadingNextPatientList" class="marg-v text-center">
					<spinner class="text-primary text-xl"/>
					<p>{{ $t("vm.root.patients.main.next.loading") }}</p>
				</div>
				<div v-else-if="nextPatientList.length === 0" class="marg-v text-center">
					<svg width="1em" class="text-primary svg-xl" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-times"/>
					</svg>
					<p>
						<strong>{{ $t("vm.root.patients.main.next.none.title") }}</strong><br>
						<i18n path="vm.root.patients.main.next.none.description.text" tag="span">
							<router-link :to="{name: 'plannings'}" :title="$t('vm.root.patients.main.next.none.description.title')">{{ $t("vm.root.patients.main.next.none.description.link") }}</router-link>
						</i18n>
					</p>
				</div>
				<div v-else class="grid-3 vertical-gap marg-v">
					<patient-item :patient="patient" :key="patient.id" v-for="patient in nextPatientList">
						<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-check"/>
						</svg>
					</patient-item>
				</div>

				<hr>

				<h2>{{ $t("vm.root.patients.main.previous.title") }}</h2>
				<div v-if="loadingLastReadPatientList" class="marg-v text-center">
					<spinner class="text-primary text-xl"/>
					<p>{{ $t("vm.root.patients.main.previous.loading") }}</p>
				</div>
				<div v-else-if="lastReadPatientList.length === 0" class="marg-v text-center">
					<svg width="1em" class="text-primary svg-xl" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#folder-open"/>
					</svg>
					<p>
						<strong>{{ $t("vm.root.patients.main.previous.none.title") }}</strong><br>
						<i18n path="vm.root.patients.main.previous.none.description.text" tag="span">
							<router-link :to="{name: 'patient-coordinates'}" :title="$t('vm.root.patients.main.previous.none.description.title')">{{ $t("vm.root.patients.main.previous.none.description.link") }}</router-link>
						</i18n>
					</p>
				</div>
				<div v-else class="grid-3 vertical-gap marg-v">
					<patient-item :patient="patient" :key="patient.id" v-for="patient in lastReadPatientList">
						<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#eye"/>
						</svg>
					</patient-item>
				</div>
			</div>
		</central>

		<context-main/>

	</master>

</container>
