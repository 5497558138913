import Render from '@Views/root/calendar/planning/main.html'
import { Component } from 'vue-property-decorator'
import { ASidePlanning } from './aside-planning'
import { ContextMain } from './context-main'
import { AutoSaver } from '@Components/auto-saver'
import { IPlanning, IBreadcrumbItem, INotification } from '@Store/types'
import { breadcrumb, notif, planning } from '@Store/modules'
import { Planning } from '@Models/index'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'aside-planning': ASidePlanning
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	},
	beforeRouteUpdate(to, from, next) {
		this.beforeRouteLeave(to, from, next, true)
		this.submitted = false
		this.initializePlanning(to.params.pla_id)
	}
})
export class Main extends AutoSaver {

	private submitted: boolean = false

	// @State('planning') statePlanning: PlanningState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('planning/updatePlanning') updatePlanning:(planning: IPlanning) => void
	// @Mutation('planning/selectPlanning') selectPlanning:(planning: IPlanning) => void
	// @Mutation('notification/success') success: (message: string | INotification) => void
	// @Mutation('notification/error') error: (message: string | INotification) => void
	// @Getter('planning/planning') getPlanning:(id: string) => IPlanning
	// @Action('planning/savePlanning') savePlanning: (payload: PlanningActionPayload) => any

	//#region Getters
	get planning(): IPlanning {
		return planning.selectedPlanning || Planning.createNewPlanning()
	}
	get isSaving(): boolean {
		return planning.isSaving
	}
	get isDeleting(): boolean {
		return planning.isDeleting
	}
	get hasNameError(): boolean {
		return this.submitted && !this.planning.name
	}
	get isNewPlanning(): boolean {
		return !this.planning || parseInt(this.planning.id) === -1
	}
	//#endregion

	constructor() {
		super('planning')
	}

	//#region IAutoSaver implementation
	restoreValue(value: IPlanning): void {
		planning.selectPlanning(value)
	}

	save(): void {
		if (!this.canSave()) return

		planning.savePlanning({planning: this.planning})
		.then((newPlanning: IPlanning) => {
			super.initializeValue(newPlanning)

			let route = {name: 'planning', params: {pla_id: newPlanning.id}}
			this.$router.replace(route)
		})
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
		this.initializePlanning(this.$route.params.pla_id)
	}

	submitCancel(): void {
		super.cancel()
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	copy(): void {
		navigator.clipboard.writeText(this.planning.url).then(() => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.t('vm.root.calendar.planning.main.copy-url.copied').toString(), actions:[] }
			notif.success(notification)
		}, () => {
			let notification: INotification = { canClose: true, delay: 5000, message: this.$i18n.t('vm.root.calendar.planning.main.copy-url.no-copied').toString(), actions:[] }
			notif.error(notification)
		})
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.plannings-main').toString(), link: "plannings" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.planning').toString() }
		breadcrumb.updateItems([item1, item2])
	}

	private canSave(): boolean {
		return !!this.planning.name
	}

	private initializePlanning(pla_id: string): void {
		if (pla_id === undefined) {
			this.restoreValue(Planning.createNewPlanning())
			super.initializeValue()
		} else {
			this.restoreValue(planning.planning(pla_id))
			super.initializeValue()
		}
	}
}