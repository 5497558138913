<section class="login-frame">

	<div v-if="accounts.length === 0">
		<h2 class="login-frame-title">{{ $t("vm.login.no-account.title") }}</h2>
		<div class="login-frame-content">
			<i18n path="vm.login.no-account.description1" tag="p">
				<strong place="email">{{email}}</strong>
			</i18n>

			<p v-if="isDemo" v-html="$t('vm.login.no-account.demo')"/>
			<i18n v-else path="vm.login.no-account.description2.text" tag="p">
				<router-link :to="{name: 'register'}" :title="$t('vm.login.no-account.description2.title')">{{ $t("vm.login.no-account.description2.link") }}</router-link>
			</i18n>
			<div class="p-like childs-space-between">
				<router-link class="btn btn-default" :to="{name: 'login'}" :title="$t('vm.login.no-account.previous.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					{{ $t("vm.login.no-account.previous.text") }}
				</router-link>
				<router-link v-if="!isDemo" class="btn btn-primary is-pushed-right" :to="{name: 'register'}" :title="$t('vm.login.no-account.register.title')">
					{{ $t("vm.login.no-account.register.text") }}
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</router-link>
			</div>
		</div>
	</div>

	<div v-else>
		<h2 class="login-frame-title">{{ $t("vm.login.authenticate.title") }}</h2>
		<form class="login-frame-content" @submit.prevent="submitLogin">
			<p v-html="$tc('vm.login.authenticate.description1', accounts.length, {email: email})"/>

			<p v-if="accounts.length > 1">{{ $t("vm.login.authenticate.description2.text") }}</p>
			<div v-if="accounts.length > 1" v-show="hasErrorProfile" class="notice-danger p-like">{{ $t("vm.login.authenticate.description2.error") }}</div>

			<ul v-if="accounts.length > 1" class="account-list" :class="{'is-3-cols': accounts.length === 3}">
				<li v-for="account in accounts" :key="account">
					<misc-item :id="`type-${account}`" v-model="profile" :radio-value="account" name="type">
						<svg slot="icon" width="1em" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${$options.filters.profileIcon(account)}`"/>
						</svg>
						{{ account | profile }}
					</misc-item>
				</li>
			</ul>

			<div class="p-like">
				<input-password-frame ref="password" id="password" v-model="password"
					:label="$t('vm.login.authenticate.password.label')"
					:error-test="hasErrorPassword" :error-msg="$t('vm.login.authenticate.password.error')"
					:placeholder="$t('vm.login.authenticate.password.placeholder')" :title="$t('vm.login.authenticate.password.title')"/>
			</div>

			<div class="p-like childs-space-between">
				<button-action class="btn btn-default" @click.native="previous" :disabled="isLoading" :title="$t('vm.login.authenticate.previous.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					{{ $t("vm.login.authenticate.previous.text") }}
				</button-action>
				<button-action type="submit" btn-class="btn-primary is-pushed-right" :loading="isLoading" :title="$t('vm.login.authenticate.login.title')">
					{{ $t("vm.login.authenticate.login.text") }}
					<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					</svg>
				</button-action>
			</div>

			<hr>

			<p>
				{{ $t("vm.login.authenticate.lost-login.text") }}
				<button type="button" class="btn--link-like" @click="submitLostLogin()" :disabled="isSending" :title="$t('vm.login.authenticate.lost-login.title')">
					{{ $t("vm.login.authenticate.lost-login.new") }}
				</button>
				<spinner v-if="isSending" class="svg-middle"/>
			</p>

		</form>
	</div>

</section>
