export const consultationsContext = {
  title: "Filtre",
  user: {
    label: "Sélectionnez un utilisateur",
    title: "Veuillez sélectionner un utilisateur"
  },
  office: {
    label: "Sélectionnez un cabinet",
    title: "Veuillez sélectionner un cabinet"
  },
  year1: {
    label: "Année 1",
    placeholder: "ex : 2016",
    title: "Indiquez l'année pour l'évolution 1"
  },
  year2: {
    label: "Année 2",
    placeholder: "ex : 2017",
    title: "Indiquez l'année pour l'évolution 2"
  },
	weeks: "Semaine à détailler"
}
