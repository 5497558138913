export const exportation = {
    title: "Listing des coordonnées",
    searching: "Chargement en cours…",
    'no-result': "Aucun résultat.",
    'adjust-filter': "Vous devriez peut-être modifier vos filtres.",
    'name-column': "Nom",
    'birth-date-column': "Naissance",
    'email-column': "Email",
    'phone-column': "Téléphone",
    'name-title': "Ouvrir le dossier de {first_name} {last_name}",
    select: "Sélectionner / Désélectionner",
    blacklisted: "Blacklisté",
    deceased: "Décédé",
	previous: {
    	label: "précédente",
    	title: "Page précédente"
	},
	next: {
    	label: "suivante",
    	title: "Page suivante"
	},
	pagination: {
    	text: "{prefix} {start} à {end} sur {total}",
		prefix: "Résultats de"
	},
    action: {
        export: {
            label: "exporter le tableau",
            title: "Exporter le tableau",
		},
        'copy-email': {
            label: "copier les emails",
            title: "Copier uniquement les emails",
            success: "Aucun email à copier dans le presse-papier. | Un email a bien été copié dans le presse-papier. Vous pouvez maintenant le coller (Ctrl + V ou Cmd + V). | {count} emails ont bien été copiés dans le presse-papier. Vous pouvez maintenant les coller (Ctrl + V ou Cmd + V).",
            error: "Votre navigateur ne permet de copier dans le presse-papier. Essayez avec un autre navigateur."
        },
        'copy-phone': {
            label: "copier les numéros de téléphone",
            title: "Copier uniquement les numéros de téléphone",
            success: "Aucun numéro de téléphone à copier dans le presse-papier. | Un numéro de téléphone a bien été copié dans le presse-papier. Vous pouvez maintenant le coller (Ctrl + V ou Cmd + V). | {count} numéros de téléphone ont bien été copiés dans le presse-papier. Vous pouvez maintenant les coller (Ctrl + V ou Cmd + V).",
            error: "Votre navigateur ne permet de copier dans le presse-papier. Essayez avec un autre navigateur."
        }
    },
    context: {
        title: "Filtrer",
        'last-name': {
            label: "Filtre par nom",
            placeholder: "ex : Fleck",
            title: "Filtrer votre recherche par le nom",
        },
        'first-name': {
            label: "Filtre par prénom",
            placeholder: "ex : Arthur",
            title: "Filtrer votre recherche par le prénom",
        },
        office: {
            label: "Filtre par cabinet",
			title: "Filtrer votre recherche par le cabinet"
        }
    }
}
