import Render from '@Views/root/calendar/main.html'
import { Component, Watch } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { ContextSecretary } from './context-secretary'
import { ASideCalendar } from './aside-calendar'
import { FullCalendar, IFullCalendarEvent, IEventClickParameter, IDateClickParameter, IEventDropParameter, IEventResizeParameter, IViewRenderParameter, IToggleBoxItem } from '@Components/index'
import { EventService, GoogleService } from '@Services/index'
import { IPlanning, INotification, INotificationAction, IBreadcrumbItem, IOffice, ISchedule, ISettings, IUser, IConfiguration } from '@Store/types'
import { Office, Debouncer } from '@Models/index'
import { FullCalendarEventHelper, EventHelper, IGoogleEvent, DateHelper } from '@Helpers/index'
import { QuickEventManager } from '@Managers/index'
import { PlanningType, Profile } from '@Enums/index'
import { breadcrumb, configuration, event, notif, office, planning, user } from '@Store/modules'

@Render
@Component({
	components: {
		'calendar': FullCalendar,
		'aside-calendar': ASideCalendar,
		'context-user': ContextMain,
		'context-secretary': ContextSecretary
	}
})
export class Main extends QuickEventManager {

	loading: boolean = false
	config: any
	view: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth' = 'timeGridWeek'
	title: string = ''
	isAbsent: boolean = false
	absentPeriod: string = ''
	private countQueries: number = 0
	private calendar: FullCalendar
	private scheduleEvents: IFullCalendarEvent[] = []
	private searchArgs: {start: Date, end: Date} = null
	private debouncer: Debouncer = null
	private debouncerRefresh: Debouncer = null
	private debouncerSchedule: Debouncer = null

	//#region Getters
	get refreshing(): boolean {
		return planning.refresh
	}
	get currentUser(): IUser {
		return user.currentUser
	}
	get plannings(): IPlanning[] {
		return planning.plannings
	}
	get googlePlannings(): IPlanning[] {
		return planning.googlePlannings
	}
	get viewItems(): IToggleBoxItem[] {
		return [
			{value: 'timeGridDay', label: this.$i18n.t('vm.root.calendar.main.view.day').toString()},
			{value: 'timeGridWeek', label: this.$i18n.t('vm.root.calendar.main.view.week').toString()},
			{value: 'dayGridMonth', label: this.$i18n.t('vm.root.calendar.main.view.month').toString()}
		]
	}
	get schedule(): ISchedule {
		if (!this.searchArgs || !this.searchArgs.start) return

		let _user: IUser = this.isSecretaryUser ? user.selectedPlanningUser : user.currentUser
		if (!_user || !this.selectedOffice) return

		let specificSchedule: ISchedule[] = office.specificSchedulesRange(this.selectedOffice.id, _user.id, this.searchArgs.start)
		return specificSchedule.length > 0 ? specificSchedule[0] : office.schedule(this.selectedOffice.id, _user.id)
	}
	private get selectedOffice(): IOffice {
		return office.selectedPlanningOffice
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	//#endregion

	mounted() {
		planning.updateRefresh(false)
		this.calendar = (this.$refs.calendar as FullCalendar)
		this.title = this.calendar.title()
		this.initializeBreadcrumb()
	}

	createPlanning(): void {
		this.$router.push({name: 'planning'})
	}

	createOffice(): void {
		this.$router.push({name: 'offices'})
	}

	@Watch('countQueries')
	updateLoading() {
		this.loading = this.countQueries > 0
	}

	@Watch('refreshing')
	forceRefresh() {
		if (!planning.refresh) return

		planning.updateRefresh(false)
		this.createDebounce(this.searchArgs.start, this.searchArgs.end)
	}

	@Watch('plannings', {deep: true})
	@Watch('googlePlannings', {deep: true})
	@Watch('currentUser.id')
	@Watch('selectedOffice')
	refetchEvents() {
		this.createDebounce(this.searchArgs.start, this.searchArgs.end)
	}

	@Watch('view')
	changeView(): void {
		if (!this.calendar) return

		this.calendar.changeView(this.view)
		this.title = this.calendar.title()
	}

	next(): void {
		if (!this.calendar) return

		this.calendar.next()
		this.title = this.calendar.title()
	}

	previous(): void {
		if (!this.calendar) return

		this.calendar.previous()
		this.title = this.calendar.title()
	}

	today(): void {
		if (!this.calendar) return

		this.calendar.today()
		this.title = this.calendar.title()
	}

	refresh(info: IViewRenderParameter) {
		this.createDebounce(info.view.activeStart, info.view.activeEnd)
	}

	link(event:{id: string, pla_id: string, type: PlanningType}): {name: string, params?: any} {
		return EventHelper.getEventUrl(event)
	}

	eventClick(info: IEventClickParameter): void {
		let event: IFullCalendarEvent = FullCalendar.getFullCalendarEvent(info.event)
		if (event.editable) {
			this.$router.push(EventHelper.getEventUrl(event))
		} else {
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-editable').toString(), actions:[], delay: 5000, canClose: true}
			notif.warning(notification)
		}
	}

	dateClick(info: IDateClickParameter): void {
		if (!user.currentUser) {
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-user').toString(), actions:[], delay: 5000}
			notif.warning(notification)
			return
		}

		if (this.hasPlanning) {
			let params = {
				date: DateHelper.getDateString(info.date),
				hour: info.allDay ? undefined : DateHelper.getHourString(info.date)
			}
			this.$router.push({name: 'new-event', params: params})
		} else if (this.isMainUser) {
			let action: INotificationAction = {label: this.$i18n.t('vm.root.calendar.main.create.label').toString(), callback: this.createPlanning}
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.create.message').toString(), actions:[action], delay: false, canClose: true}
			notif.info(notification)
		} else {
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-planning').toString(), actions:[], delay: 5000}
			notif.warning(notification)
		}
	}

	eventDrop(info: IEventDropParameter): void {
		let event: IFullCalendarEvent = FullCalendar.getFullCalendarEvent(info.event)
		if (event.editable) {
			this.updateEvent(event)
		} else {
			info.revert()
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-editable').toString(), actions:[], delay: 5000, canClose: true}
			notif.warning(notification)
		}
	}

	eventResize(info: IEventResizeParameter): void {
		let event: IFullCalendarEvent = FullCalendar.getFullCalendarEvent(info.event)
		if (event.editable) {
			this.updateEvent(event)
		} else {
			info.revert()
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-editable').toString(), actions:[], delay: 5000, canClose: true}
			notif.warning(notification)
		}
	}

	beforeMount() {
		this.initializeConfig()
		this.debouncer = new Debouncer(this.getEvents, 333)
		this.debouncerSchedule = new Debouncer(this.initializeSchedule, 333)
		this.debouncerRefresh = new Debouncer(this.getEvents, this.configuration.refresh_calendar_delay)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
		Debouncer.clear(this.debouncerRefresh)
		Debouncer.clear(this.debouncerSchedule)
	}

	@Watch('currentUser')
	updateBreadCrumb() {
		this.initializeBreadcrumb()
		this.updateSchedule()
	}

	@Watch('selectedOffice')
	updateSchedule() {
		this.debouncerSchedule.start()
	}

	addNewEvent(): void {
		if (this.isMainUser) {
			let date = new Date()
			date.setMinutes(date.getMinutes() - date.getMinutes() % 15)
			this.$router.push({name: 'new-event', params: {date: DateHelper.getDateString(date), hour: DateHelper.getHourString(date)}})
		} else if (!user.currentUser) {
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.no-user').toString(), actions:[], delay: 5000}
			notif.warning(notification)
		} else {
			super.quickEvent()
		}
	}

	private initializeBreadcrumb(): void {
		let userName: string = !!user.currentUser ?
			`${user.currentUser.coordinates.first_name} ${user.currentUser.coordinates.last_name}` :
			this.$i18n.t('vm.root.breadcrumb.no-user').toString()
		let label = this.isSecretaryUser ?
			this.$i18n.t('vm.root.breadcrumb.plannings-secretary', {user: userName.trim()}).toString() :
			this.$i18n.t('vm.root.breadcrumb.plannings-main').toString()

		let item: IBreadcrumbItem = { label: label}
		breadcrumb.updateItems([item])
	}

	private initializeConfig(): any {
		this.config = {}
		let slotDuration:string = !!this.configuration.slot ? `00:${this.configuration.slot}:00` : '00:15:00'
		let minTime:string = !!this.configuration.min_time ? this.configuration.min_time.toLocaleTimeString('fr-FR') : '06:00:00'
		let maxTime:string = !!this.configuration.max_time ? this.configuration.max_time.toLocaleTimeString('fr-FR') : '21:00:00'

		this.config.slotDuration = slotDuration
		this.config.minTime = minTime
		this.config.maxTime = maxTime

		if (!this.$route.params.date) return

		this.config.defaultDate = this.$route.params.date
	}

	private initializeSchedule(): void {
		if (!this.calendar) return

		this.calendar.clearBackground()
		this.calendar.setBusinessHours(false)
		this.scheduleEvents = []
		let usr_id: string = !!user.currentUser ? user.currentUser.id : null || null

		if (!usr_id || !this.selectedOffice) return

		let schedule: ISchedule = this.schedule

		let settings: ISettings = office.setting(this.selectedOffice.id, usr_id)
		this.calendar.setBusinessHours(Office.getBusinessHours(schedule, settings, this.searchArgs.start, this.getDateByIndex))
		let links: any[] = this.isSecretaryUser ? user.linkedUsers(Profile.None) : this.selectedOffice.links
		let scheduleEvents: IFullCalendarEvent[] = Office.getScheduleEventList(schedule, links, usr_id, this.searchArgs.start, this.getDateByIndex)
		this.isAbsent = this.getIsAbsent(settings)
		if (!scheduleEvents) return

		this.scheduleEvents = scheduleEvents
		this.calendar.renderEvents(this.scheduleEvents)
	}

	private getIsAbsent(settings: ISettings): boolean {
		this.absentPeriod = ''
		if (!settings || !settings.absence) return false
		if (!settings.absence_period[0] || !settings.absence_period[1]) return false
		let start: Date = new Date(settings.absence_period[0])
		start.setDate(start.getDate() - 7)
		let end: Date = settings.absence_period[1]
		let now = new Date()

		let startString: string = this.$i18n.d(settings.absence_period[0], 'long').toString()
		let endString: string = this.$i18n.d(end, 'long').toString()

		this.absentPeriod = now >= settings.absence_period[0] ?
			this.$i18n.t('vm.root.calendar.main.current-holidays', {end: endString}).toString() :
			this.$i18n.t('vm.root.calendar.main.holidays', {start: startString, end: endString}).toString()

		return start <= now && now <= end
	}

	private updateEvent(_event: IFullCalendarEvent): void {
		let item = FullCalendarEventHelper.convertFullCalendarEventToItem(_event)
		item['from_calendar'] = true
		event.saveEvent({event: item}).then(() => {
			let notification: INotification = {message: this.$i18n.t('vm.root.calendar.main.event-updated').toString(), actions:[], delay: 5000, canClose: true}
			notif.success(notification)
			event.eventSuccess(null)
		})
	}

	private getEvents() {
		if (!this.calendar) return
		let usr_id: string = !!user.currentUser ? user.currentUser.id : null || null
		if (!usr_id) {
			this.countQueries = 0
			return
		}

		this.countQueries = 1
		this.calendar.clear()
		this.calendar.renderEvents(this.scheduleEvents)
		let queries: {promise: Promise<any>, editable: boolean}[] = []
		let colors:Record<string, string> = {}

		let classicQueries = this.fetchClassicEvents(this.searchArgs.start, this.searchArgs.end, colors)
		let googleQueries = this.fetchGoogleEvents(this.searchArgs.start, this.searchArgs.end, colors)
		queries = [...classicQueries, ...googleQueries]

		let route = {name: 'plannings', params: {date: DateHelper.getDateString(this.searchArgs.start)}}
		if (route.name !== this.$router.currentRoute.name || route.params.date !== this.$router.currentRoute.params.date) {
			this.$router.replace(route)
		}

		this.countQueries = queries.length
		for(let query of queries) {
			query.promise
			.then((list) => {
				this.addRange(list, colors, query.editable)
				this.countQueries--
			})
			.catch((error) => {
				console.error(error)
				this.countQueries--
			})
		}

		this.debouncerRefresh.start()
	}

	private fetchClassicEvents(start: any, end: any, colors: Record<string, string>): {promise: Promise<any>, editable: boolean}[] {
		let queries: {promise: Promise<any>, editable: boolean}[] = []
		let service = new EventService()
		let plannings: IPlanning[] = planning.plannings
		for (let _planning of plannings) {
			if (!_planning.visible) continue

			colors[_planning.id] = _planning.color
			let promise = service.getEventList(start, end, _planning)
			queries.push({promise: promise, editable: _planning.editable})
		}
		return queries
	}

	private fetchGoogleEvents(start: any, end: any, colors: Record<string, string>): {promise: Promise<any>, editable: boolean}[] {
		let queries: {promise: Promise<any>, editable: boolean}[] = []
		let plannings: IPlanning[] = planning.googlePlannings
		for (let _planning of plannings) {
			if (!_planning.visible) continue

			colors[_planning.id] = _planning.color
			let promise = GoogleService.getInstance().getEventList(start, end, _planning)
			queries.push({promise: promise, editable: undefined})
		}

		return queries
	}

	private addRange(list: any[] | IGoogleEvent[], colors:Record<string, string>, editable: boolean): void {
		if (!this.calendar) return

		let eventList: IFullCalendarEvent[] = []
		const selectedOfficeId = !!this.selectedOffice ? this.selectedOffice.id : '-1'
		for (let event of list) {
			let convertedEvent = FullCalendarEventHelper.convertEventToFullCalendarEvent(event, colors, editable)
			if (convertedEvent === undefined) continue
			if (!!convertedEvent.ofi_id
				&& parseInt(convertedEvent.ofi_id) !== -1
				&& parseInt(selectedOfficeId) !== -1
				&& convertedEvent.ofi_id !== selectedOfficeId) continue;

			eventList.push(convertedEvent)
		}
		if (eventList.length === 0) return

		this.calendar.renderEvents(eventList)
	}

	private getDateByIndex(firstDate: Date, index: number): Date {
		let d: Date = new Date(firstDate)
		d.setDate(d.getDate() - d.getDay() + (index === 0 ? 7 : index))
		return d;
	}

	private createDebounce(start: Date, end: Date): void {
		this.countQueries = 1
		this.searchArgs = { start: start, end: end }
		this.debouncer.start()
		this.debouncerSchedule.start()
	}
}
