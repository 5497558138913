export enum ArticulationMove {
	None		= 0,
	Flexion 	= 1,
	Extension 	= 2
}
export enum StrainMove {
	None	= 0,
	Rolling = 1,
	Sliding = 2
}
