export const context = {
    title: "Modèle de courrier",
    model: {
        'default-label': "sélectionnez un modèle",
        label: "Choix du modèle",
        title: "Choisissez le modèle de courrier que vous souhaitez remplir"
    },
    patient: "Choix du patient concerné",
    consultation: {
        'default-label': "sélectionnez la consultation",
        label: "Choix de la consultation",
        title: "Choisissez la consultation concernée par votre courrier",
        empty: "Aucune consultation associée"
    },
    invoice: {
        'default-label': "sélectionnez la facture",
        label: "Choix de la facture",
        title: "Choisissez la facture concernée par votre courrier",
        empty: "Aucune facture associée"
    },
    office: {
        'default-label': "sélectionnez le cabinet",
        label: "Choix du cabinet",
        title: "Choisissez le cabinet concerné par votre courrier",
        empty: "Aucun cabinet associé"
    }
}
