import { BaseService } from '@Services/index'
import { IStickyNote } from '@Store/types'

export class StickyNoteService extends BaseService {
	
	public getStickyNoteList(): Promise<any> {
		return super.get('/sticky-note/')
	}

	public deleteStickyNote(widId: string): Promise<any> {
		return super.delete(`/sticky-note/${widId}`)
	}

	public save(stickyNote: IStickyNote): Promise<any> {
		if (parseInt(stickyNote.id) === -1) 
			return super.post('/sticky-note', stickyNote)

		return super.put(`/sticky-note/${stickyNote.id}`, stickyNote)
	}

	public share(id: string, selectedIds: any): Promise<any> {
		if (parseInt(id) === -1) return Promise.reject()

		return super.post(`/share/sticky-note/${id}`, selectedIds)
	}
}