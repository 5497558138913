<section class="planning-list-item p-like" :class="{'is-readonly': !isOwner}">
    <header class="planning-list-item-header">
        <div class="planning-list-item-legend" :style="`--color:${planning.color}`"></div>
        <checkbox-frame default-class="planning-list-item-title" :id="planning.id" v-model="planning.visible" 
            :title="$t('vm.root.calendar.planning-item.display-planning', {name: planning.name})">
            <span class="text-bold text-capitalize">{{planning.name}}</span>
        </checkbox-frame>
    </header>
    <div class="toolbar" v-if="isOwner">
        <div class="form-group toolbar-control">
            <router-link :to="{name:'planning', params:{pla_id: planning.id}}" class="input-like form-group-input text-center"
                :title="$t('general.edit.title')" :disabled="isDeleting">
                {{ $t('general.edit.text') }}
            </router-link>
            <delete-item btn-class="input-like form-group-input text-center"
                :title="$t('vm.root.calendar.planning-item.suppress.title')"
                :delete-msg="$t('vm.root.calendar.planning-item.suppress.message')"
                :delete-action="submitDelete" :can-delete="true" :disabled="isDeleting">
                <spinner v-if="isDeleting"/>
                <span v-else>{{ $t('general.suppress.text') }}</span>
            </delete-item>
        </div>
    </div>
</section>