import Render from '@Views/components/popin/layout/popin-create-receipt.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Details } from '@ViewModels/root/accounting/receipt'
import { IConsultation, IInvoice } from '@Store/types'
import { office, user } from '@Store/modules'

@Render
@Component({
	components: {
		'details-receipt': Details
	}
})
export class PopinCreateReceipt extends Vue {

    @Prop() options: {receipt: IInvoice, consultation: IConsultation, light?: boolean}
	@Prop({default: false}) submitted: boolean

	//#region Getters
	get isSubstituteUser(): boolean {
		return user.isSubstituteUser
	}
	get userLinked(): any {
		if (!this.options.consultation) return
		let usersLinked: any[] = office.links(this.options.consultation.ofi_id)

		return usersLinked.find(u => u.id === this.options.consultation.usr_id)
	}
	//#endregion
}
