import * as User from '@ViewModels/root/user/index'
import * as Account from '@ViewModels/root/user/account/index'
import * as Office from '@ViewModels/root/user/office/index'
import * as Schedule from '@ViewModels/root/user/office/schedule/index'
import * as Gift from '@ViewModels/root/user/gift/index'
import { Profile } from '@Enums/profile'

export const UserRoot = [
	{
		path: 'user', 
		component: User.Main,
		redirect: { name: 'user-account' },
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] },
		children: [
			{
				path:'informations',
				name:'user-account',
				redirect: { name: 'user-informations' },
				component: User.Account,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] },
				children: [
					{
						path: '',
						name: 'user-informations',
						component: Account.Informations,
						meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] }
					},
					{
						path: 'formations',
						name: 'user-formations',
						component: Account.Formations,
						meta: { permission: [Profile.Main, Profile.Demo] }
					},
					{
						path: 'organizations',
						name: 'user-organizations',
						component: Account.Organizations,
						meta: { permission: [Profile.Main, Profile.Demo] }
					}
				]
			},
			{
				path:'password',
				name:'user-password',
				component: User.Password,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] }
			},
			{
				path:'email',
				name:'user-email',
				component: User.Email,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] }
			},
			{
				path:'referral',
				name:'user-referral',
				component: User.Referral,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path:'offices',
				name:'offices',
				component: User.Offices,
				meta: { permission: [Profile.Main, Profile.Demo] }
			}
		]
	},
	{
		path:'gift',
		component: Gift.Main,
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: [
			{
				path: '',
				name: 'user-gift',
				component: Gift.Home,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'gift1',
				name: 'user-gift1',
				component: Gift.Gift1,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'gift2',
				name: 'user-gift2',
				component: Gift.Gift2,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'gift3',
				name: 'user-gift3',
				component: Gift.Gift3,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'gift-bonus',
				name: 'user-gift-bonus',
				component: Gift.Bonus,
				meta: { permission: [Profile.Main, Profile.Demo] }
			}
		]
	},
	{
		path:'office/:ofi_id?',
		component: Office.Main,
		meta: { permission: [Profile.Main, Profile.Demo] },
		children: [
			{
				path: '',
				name: 'office-coordinates',
				component: Office.Coordinates,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'sessions',
				name: 'office-sessions',
				component: Office.Sessions,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'accesses',
				name: 'office-accesses',
				component: Office.Accesses,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'schedule',
				name: 'office-schedule',
				component: Schedule.Main,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'schedule-plus/:sch_id',
				name: 'office-schedule-plus',
				component: Schedule.Schedule,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'absence',
				name: 'office-absence',
				component: Office.Absence,
				meta: { permission: [Profile.Main, Profile.Demo] }
			},
			{
				path: 'parameters',
				name: 'office-parameters',
				component: Office.Parameters,
				meta: { permission: [Profile.Main, Profile.Demo] }
			}
		]
	}
]