<div>
	<div v-if="!reason || parseInt(reason.id) === -1" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.reason.none.title') }}</span>
		</p>
	</div>
	<div v-else class="grid-2">
		<h4 v-if="reason.title" class="grid-item-full text-center">{{reason.title}}</h4>
		<p class="text-pre-line" v-if="reason.topography"><strong>{{ $t('vm.root.patients.patient.reason.topography.label') }}</strong> :<br><span v-html="reason.topography"/></p>
		<p class="text-pre-line" v-if="reason.chronology"><strong>{{ $t('vm.root.patients.patient.reason.chronology.label') }}</strong> :<br><span v-html="reason.chronology"/></p>
		<p class="text-pre-line" v-if="reason.factors"><strong>{{ $t('vm.root.patients.patient.reason.factors.label') }}</strong> :<br><span v-html="reason.factors"/></p>
		<p class="text-pre-line" v-if="reason.signs"><strong>{{ $t('vm.root.patients.patient.reason.signs.label') }}</strong> :<br><span v-html="reason.signs"/></p>
		<p class="text-pre-line grid-item-full" v-if="reason.description"><strong>{{ $t('vm.root.patients.patient.reason.description.label') }}</strong> :<br><span v-html="reason.description"/></p>

		<read-only-forms class="grid-item-full" :forms="reason.forms" :with-white-space="false"/>
	</div>
</div>
