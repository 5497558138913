<div v-if="!!recurring">

	<h2 v-html="title"/>

	<div :class="{ 'grid-2 vertical-gap': !isNoRecurring }">
		<dropdown-frame id="type-recurring" v-model="recurring.type" :items="recurringItems" :label="$t('vm.components.recurring.label')" :disabled="disabled"/>
		<input-frame v-if="!isNoRecurring" type="number" id="cycle-recurring" :label="cycleLabel" v-model="recurring.cycle" min="1" max="31" :disabled="disabled"/>
	</div>

	<template v-if="!isNoRecurring">

		<hr>

		<div v-if="isWeekRecurring" class="input-frame">
			<label class="label" :data-text="$t('vm.components.recurring.repeatEach')">
				{{$t('vm.components.recurring.repeatEach')}}
			</label>
			<div class="recurring-weekdays">
				<checkbox-frame v-for="day in weekdays" :key="day.index" :id="day.index" v-model="getSelectedDay(day.index).checked" :disabled="disabled">
					{{day.normal}}
				</checkbox-frame>
			</div>
		</div>

		<togglebox-frame v-if="isMonthRecurring" id="day-of-month-recurring" :items="dayOfMonthItems" v-model="recurring.day_of_month" :label="$t('vm.components.recurring.repeatEach')" :disabled="disabled"/>

		<hr v-if="isWeekRecurring || isMonthRecurring">

		<div class="grid-3">
			<input-date-frame id="start-date-recurring" v-model="recurring.start_date" label="Date de début" :disabled="disabled"/>

			<togglebox-frame id="with-end-date-recurring" :items="withEndDateItems" v-model="recurring.with_end_date" label="Date de fin ?" :disabled="disabled"/>

			<input-date-frame id="end-date-recurring" label="Date de fin" v-model="recurring.end_date" :disabled="!recurring.with_end_date || disabled"/>
		</div>

	</template>

</div>
