<div v-if="!!accesses">
	<div class="childs-space-between marg-v">
		<h1 class="text-uppercase-first-letter no-marg">{{ $tc('vm.root.user.office.accesses.title', accesses.length) }}</h1>
		<button type="button" class="btn--link-like is-pushed-right" @click="addAccess" :title="$t('vm.root.user.office.accesses.new-access.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.user.office.accesses.new-access.label') }}</span>
		</button>
	</div>

	<div v-if="accesses.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 640 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#park"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.user.office.accesses.none.label') }}</span><br>
			<i18n path="vm.root.user.office.accesses.none.text" tag="span">
				<button type="button" class="btn--link-like" @click="addAccess" :title="$t('vm.root.user.office.accesses.new-access.title')">
					{{ $t('vm.root.user.office.accesses.none.button-label') }}
				</button>
			</i18n>
		</p>
	</div>

	<draggable :forceFallback="true" tag="ul" :list="accesses" class="ul-reset" v-else handle=".draggable-handler">
		<li v-for="access in accesses" class="draggable" :ref="getElementId(access)" :key="getElementId(access)">

			<div class="collapsible-panel bordered-panel marg-v draggable-frame is-with-border">
				<button type="button" class="btn--reset draggable-handler" :title="$t('vm.root.user.office.accesses.move.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.user.office.accesses.move.label') }}</span>
				</button>
				<button type="button" class="btn--link-like collapsible-panel-trigger text-bold"
					:title="$t('vm.root.user.office.accesses.details')"
					@click="toggle(access.id, access.create_id)"
					:class="{'is-active': isToggle(access.id, access.create_id)}">
					<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
					</svg>
					<span class="text-uppercase-first-letter">{{ access.name || $t('vm.root.user.office.accesses.default-name') }}</span>
				</button>
				<div class="collapsible-panel-container"  :class="{'is-active': isToggle(access.id, access.create_id)}">
					<div class="collapsible-panel-content">
						<access :office="office" :access="access" :submitted="submitted"/>
					</div>
				</div>
			</div>

		</li>
	</draggable>


</div>
