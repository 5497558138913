<a-side :sub-menu="true">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'statistics-patients'}" :title="$t('vm.root.statistics.aside.patients.title')">{{ $t('vm.root.statistics.aside.patients.label') }}</router-link>
        </li>
        <li>
            <router-link :to="{name: 'statistics-consultations'}" :title="$t('vm.root.statistics.aside.consultations.title')">{{ $t('vm.root.statistics.aside.consultations.label') }}</router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'statistics-accounting'}" :title="$t('vm.root.statistics.aside.accounting.title')">{{ $t('vm.root.statistics.aside.accounting.label') }}</router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'statistics-planning'}" :title="$t('vm.root.statistics.aside.planning.title')">{{ $t('vm.root.statistics.aside.planning.label') }}</router-link>
        </li>
        <li>
            <router-link :to="{name: 'statistics-planning-online'}" :title="$t('vm.root.statistics.aside.planning-online.title')">{{ $t('vm.root.statistics.aside.planning-online.label') }}</router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'statistics-references'}" :title="$t('vm.root.statistics.aside.references.title')">{{ $t('vm.root.statistics.aside.references.label') }}</router-link>
        </li>
        <li>
            <router-link :to="{name: 'statistics-colleagues'}" :title="$t('vm.root.statistics.aside.colleague.title')">{{ $t('vm.root.statistics.aside.colleague.label') }}</router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'statistics-top10'}" :title="$t('vm.root.statistics.aside.top10.title')">{{ $t('vm.root.statistics.aside.top10.label') }}</router-link>
        </li>
    </ul>
</a-side>