import Render from '@Views/root/home/main.html'
import {Component, Vue} from 'vue-property-decorator'
import {ContextNews} from './context-news'
import {StickyNote} from './sticky-note'
import {Widget} from './widget'
import {WidgetReferral} from './widget-referral'
import {breadcrumb, home, support, user} from '@Store/modules'
import {IBreadcrumbItem, IChangeLog, IStickyNote, IUser, IWidget} from '@Store/types'
import {Common} from '@Models/index'
import {WidgetType} from "@Enums/widget"


@Render
@Component({
	components: {
		'sticky-note': StickyNote,
		'widget': Widget,
		'widget-referral': WidgetReferral,
		'context-news': ContextNews
	}
})
export class Main extends Vue {

	expand: boolean = false
	widgets: IWidget[] = []
	stickyNotes: IStickyNote[] = []
	//#region Getters
	get isWidgetLoading(): boolean {
		return home.isWidgetLoading
	}
	get isStickyNoteLoading(): boolean {
		return home.isStickyNoteLoading
	}
	get changeLog(): IChangeLog {
		return support.changeLog
	}
	get helloMessage(): string {
		let messages: string[] = [];

		for (let i = 1; i <= 10; i++) {
			messages.push(this.$i18n.t(`vm.root.home.main.welcome.text-${i}`).toString());
		}

		return messages[Math.floor(Math.random() * messages.length)]
	}
	private get user(): IUser {
		return user.user
	}

	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		breadcrumb.updateAside(false)
		let widgetTypes: WidgetType[] = []
		if (user.isMainUser) widgetTypes = [WidgetType.FollowUp, WidgetType.ValidEvent, WidgetType.Sms, WidgetType.BirthDays, WidgetType.Unpaid]
		else if (user.isSecretaryUser) widgetTypes = [WidgetType.ValidEvent]
		else if (user.isSubstituteUser) widgetTypes = [WidgetType.FollowUp, WidgetType.BirthDays, WidgetType.Unpaid]

		this.initializeBreadcrumb()
       
		home.loadStickyNotes()
			.then((stickyNotes: IStickyNote[]) => {
				this.stickyNotes = stickyNotes
			})

		let countLoader = widgetTypes.length
		if (countLoader === 0) return

		home.widgetsRequest()
		for (let type of widgetTypes) {
			home.loadWidget(type)
				.then((widget: IWidget) => {
					this.widgets.push(widget)
					countLoader--
					if (countLoader > 0) return
					home.widgetsSuccess()
				})
				.catch(error => {
					countLoader--
					if (countLoader > 0) return
					home.widgetsFailure()
				})
		}
		
		
	}

	onDelete(stickyNode: IStickyNote): void {
		this.stickyNotes = this.stickyNotes.filter(p => { return p.id !== stickyNode.id || p.creator_id !== stickyNode.creator_id })
	}

	getStickyNoteId(note: IStickyNote): string {
		return note.creator_id || note.id
	}

	addStickyNote(): void {
		let stickyNote: IStickyNote = {
			id: '-1',
			creator_id: Common.generateId(),
			label: '',
			notes: '',
			color: '#ffff80',
			share_list: {}
		}

		this.stickyNotes.unshift(stickyNote)
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.home').toString()}
		breadcrumb.updateItems([item])
	}
}
