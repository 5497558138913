import { Vue } from 'vue-property-decorator'
import { Dictionary } from "vue-router/types/router"
import { PaymentMode } from '@Enums/index'
import { Accounting } from '@Models/index'
import { IOffice, ISession, IInvoice, INotification, IConsultation, ICoordinates, IPatient, IPopinAction, IPopin, ITodayInfos, IUser } from '@Store/types'
import { StorageHelper } from "@Helpers/index"
import config from "@Config/index"
import { accounting, notif, office, patient, popIn, user } from '@Store/modules'

export abstract class InvoiceManager extends Vue {

	private readonly lic: Dictionary<IInvoiceLight> = StorageHelper.get('osteo2ls-lic', {})

    //#region Getters
	get isInvoiceSending(): boolean {
		return accounting.isSending
	}
	get isInvoiceLoading(): boolean {
		return accounting.isLoading
	}
    get isDemo(): boolean {
        return !!location.hostname && location.hostname.startsWith('demo')
    }
	get currentUser(): IUser {
		return user.currentUser
	}
	get consultations(): IConsultation[] {
		return patient.consultations
	}
	get coordinates(): ICoordinates {
		return patient.coordinates
	}
    private get patient(): IPatient {
		return patient.patient
	}
    private get todayInfos(): ITodayInfos {
		return patient.todayInfos
	}
    //#endregion

    protected constructor() {
		super()
		this.lic = StorageHelper.get('osteo2ls-lic', {})
    }

    abstract updateConsultationId(conId: string): void

    getInvoicePath(invId: string): string {
		let param: string = this.isDemo ? "?demo=true" : ""
		let label: string = `${this.coordinates.last_name}-${this.coordinates.first_name}`.replace(/\s+|[,\/]/g, "-")
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/invoice/${invId}/${label}.pdf${param}`
    }

    getInvoiceId(consultation: IConsultation): string {
		if (!consultation || !consultation.inv_id || parseInt(consultation.inv_id) === -1) {
			let invoice: IInvoiceLight = this.lic[consultation.id]
			return !!invoice ? invoice.id : undefined
		}

		return consultation.inv_id
	}
	getInvoiceNumber(consultation: IConsultation): string {
		if (!consultation || !consultation.inv_number) {
			let invoice: IInvoiceLight = this.lic[consultation.id]
			return !!invoice ? invoice.number : undefined
		}

		return consultation.inv_number
    }
	getInvoicePrinted(consultation: IConsultation): boolean {
		return (!!consultation && consultation.inv_printed) || (!!this.lic[consultation.id] && !!this.lic[consultation.id].printed)
    }
	getInvoiceSended(consultation: IConsultation): boolean {
		return (!!consultation && consultation.inv_sended) || (!!this.lic[consultation.id] && !!this.lic[consultation.id].sended)
    }

    updateReceipt(consultation: IConsultation): void {
		let payload = {inv_id: this.getInvoiceId(consultation)}
		accounting.loadInvoice(payload)
		.then((receipt: IInvoice) => {
			let label: string = this.$i18n.t('managers.invoice-manager.update-invoice.action-label').toString()
			let title: string = this.$i18n.t('managers.invoice-manager.update-invoice.action-title').toString()
			let notification: INotification = {message: this.$i18n.t('managers.invoice-manager.update-invoice.succeeded', {email: this.coordinates.email}).toString(), actions:[], delay: 5000, canClose: true}
			this.openReceiptPopin(consultation, receipt, label, title, notification)
		})
		.catch(error => {
			if (error.status !== 406) return

			// cas d'une modification de la facture d'un autre utilisateur
			let label: string = this.$i18n.t('managers.invoice-manager.update-invoice.action-label').toString()
			let title: string = this.$i18n.t('managers.invoice-manager.update-invoice.action-title').toString()
			let notification: INotification = {message: this.$i18n.t('managers.invoice-manager.update-invoice.succeeded', {email: this.coordinates.email}).toString(), actions:[], delay: 5000, canClose: true}
			this.openReceiptPopin(consultation, error.data, label, title, notification, null, true)
		})
	}

	createReceipt(consultation: IConsultation, action?: IInvoiceAction): void {
		let sesId: string = !!this.todayInfos ? this.todayInfos.ses_id : undefined
		let _office: IOffice = office.offices.find(o => { return o.id === consultation.ofi_id})
		let defaultMode: PaymentMode = !!_office ? _office.coordinates.mode : PaymentMode.Check
		let receipt: IInvoice = Accounting.createNewReceipt(this.currentUser.id, consultation.ofi_id, consultation.id, this.patient.id)
		receipt.ttc = this.getTtc(consultation.ofi_id, consultation.usr_id, sesId)
		receipt.tva = this.getTva(consultation.ofi_id)
		receipt.ses_id = !!sesId ? sesId : '-1'
		receipt.label = `${this.capitalize(this.coordinates.first_name)} ${this.capitalize(this.coordinates.last_name)}`
		receipt.settlement_date = consultation.act_date
		receipt.mode = defaultMode

		let label: string = !!action && !!action.label ? action.label : this.$i18n.t('managers.invoice-manager.generate-invoice.action-label').toString()
		let title: string = !!action && !!action.title ? action.title : this.$i18n.t('managers.invoice-manager.generate-invoice.action-title').toString()
		let notification: INotification = {message: this.$i18n.t('managers.invoice-manager.generate-invoice.succeeded', {email: this.coordinates.email}).toString(), actions:[], delay: 5000, canClose: true}
		this.openReceiptPopin(consultation, receipt, label, title, notification, !!action ? action.action : null)
	}

	print(consultation: IConsultation): Promise<any> {
		let inv_id: string = this.getInvoiceId(consultation)
		let url: string = this.getInvoicePath(inv_id)

		window.open(url, '_blank').focus()

		let invoice: IInvoiceLight = this.lic[consultation.id]
		invoice = !invoice ? { id: inv_id } : invoice
		invoice.printed = true
		this.lic[consultation.id] = invoice
		StorageHelper.set('osteo2ls-lic', this.lic)

		return Promise.resolve()
	}

	send(consultation: IConsultation): Promise<any> {
		if (this.isInvoiceSending) return

		let inv_id: string = this.getInvoiceId(consultation)
		return accounting.sendInvoice(inv_id)
		.then(() => {
			let invoice: IInvoiceLight = this.lic[consultation.id]
			invoice = !invoice ? { id: inv_id } : invoice
			invoice.sended = true
			this.lic[consultation.id] = invoice
			StorageHelper.set('osteo2ls-lic', this.lic)
			let notification: INotification = {message: this.$i18n.t('managers.invoice-manager.send-invoice.succeeded', {email: this.coordinates.email}).toString(), actions:[], delay: 5000, canClose: true}
			notif.success(notification)

			return Promise.resolve()
		})
		.catch(() => {
			let notification: INotification = {message: this.$i18n.t('managers.invoice-manager.send-invoice.failed').toString(), actions:[], delay: 5000, canClose: true}
			notif.error(notification)

			return Promise.reject()
		})
	}

    private getTtc(ofiId: string, usrId: string, sesId: string): number {
		if (!ofiId) return 0

		let _office: IOffice = office.offices.find(o => { return o.id === ofiId})
		let sessions: ISession[] = office.sessions(ofiId, usrId)

		if (!sessions || sessions.length === 0)
			return !!_office ? _office.coordinates.ttc : 0

		let session: ISession = sessions.find(s => { return s.id === sesId})
		return !!session ? session.ttc : sessions[0].ttc
	}

	private getTva(ofiId: string): number {
		if (!ofiId) return 0

		let _office: IOffice = office.offices.find(o => { return o.id === ofiId})

		return !!_office ? _office.coordinates.tva : 0
	}

    private openReceiptPopin(consultation: IConsultation, receipt: IInvoice, label: string, title: string, notification: INotification, action?: () => void, light: boolean = false): void {
		let addAction: IPopinAction = {
			label: label,
			title: title,
			callback: (data: {receipt: IInvoice, consultation: IConsultation}, updateLoading: any) => {
				if (!this.canSave(data.receipt)) return Promise.reject()

				updateLoading(true)
				return accounting.saveInvoice({invoice: data.receipt})
				.then((receipt: IInvoice) => {
                    updateLoading(false)

                    this.updateConsultationId('-1')
					this.lic[data.consultation.id] = receipt
					StorageHelper.set('osteo2ls-lic', this.lic)

                    this.updateConsultationId(data.consultation.id)

					if (!!action) action()
					else notif.success(notification)

					return Promise.resolve()
				})
				.catch(() => {
					updateLoading(false)
					return Promise.reject()
				})
			}
		}

		let create: IPopin = {
			title: title,
			content: {component: 'popin-create-receipt'},
			action: addAction,
			options: {receipt: receipt, consultation: consultation, light: light},
			isWide: true
		}

		popIn.info(create)
    }

	private capitalize(str: string): string {
		if (!str || str === '') return str
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	private canSave(receipt: IInvoice): boolean {
		return true
	}
}

export interface IInvoiceAction {
	action: () => void
	label: string
	title: string
}

export interface IInvoiceLight {
	id: string,
	number?: string,
	printed?: boolean,
	sended?: boolean
}
