export enum ReceiptType {
    Receipt1 = 0, // Recette encaissée
    Receipt2 = 1, // À déduire, débours payés
    Receipt3 = 2, // À déduire, honoraires rétrocédés
    Receipt5 = 3, // Produits financiers
    Receipt6 = 4, // Gains divers
    ReceiptOther = 5 // Autre
}

export enum ReceiptFilterType {
    Checked = 0,
    NotChecked = 1,
    Banked = 2,
    NotBanked = 3,
    Retrocession = 4,
    NotRetrocession = 5
}