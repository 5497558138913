import Render from '@Views/root/statistics/colleagues/context-colleagues.html'
import { Component, Vue } from 'vue-property-decorator'
import { IStatisticsFilter } from '@Store/types'
import { statistics } from '@Store/modules'

@Render
@Component({})
export class ContextColleagues extends Vue {

    // @State('statistics') stateStatistics: StatisticsState

    get filter(): IStatisticsFilter {
        return statistics.filter
    }
}