export const campaigns = {
    'default-text': "[Votre message ici]\nSTOP au XXXXX",
    aside: {
        back: {
            title: "Retournez à votre liste de patient",
            label: "Retour aux patients"
        },
        'new-campaign': {
            title: "Créer une nouvelle campagne",
            label: "Créer une campagne SMS"
        },
        campaign: "Ouvrir la campagne {campaign}",
        sended: "envoyée le {date} à {hour}",
        'not-sended': "non envoyée"
    },
    context: {
        title: "Communiquer par SMS",
        text1: "Vous souhaitez envoyer un {0} à l'ensemble de vos patients, ou une partie de votre patientèle ?",
        'abbr-text': "SMS",
        'abbr-title': "Short Message Service",
        text2: "Alors la campagne de SMS est là pour vous. Ici, vous allez pouvoir définir le message que vous souhaitez envoyer, la date d'envoi de votre SMS et aussi la liste des patients concernés par ce message. Suivez les différentes étapes et laissez-vous guider pour commencer à définir ces différents paramètres.",
        strong: "Ainsi, vous pourrez très facilement indiquer un changement d'adresse, partager vos vœux ou tout autre type de message qu'il vous plaira d'envoyer 😊."
    },
    'white-space': {
        strong: "Campagne SMS",
        text: "Vous devez sélectionner une campagne existante ou en créer une nouvelle."
    }
}