<container :class="{'is-without-aside': isAccountingUser}">

	<aside-main v-if="!isAccountingUser"/>

	<master>

		<central>
			<div class="wrapper-full accounting">

				<div class="childs-space-between marg-v">
					<h1 class="no-marg">{{ $t('vm.root.accounting.main.title') }}</h1>
					<button type="button" class="btn--link-like accounting-filter-button is-pushed-right is-mobile-closed"
						data-toggle-context v-toggle="{className: 'is-open is-mobile-closed', selector: '[data-toggle-context]', state:'context'}"
						:title="$t('vm.root.accounting.main.filter.title')">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#filter"/>
						</svg>
						<span class="is-for-more">{{ $t('vm.root.accounting.main.filter.more') }}</span>
						<span class="is-for-less">{{ $t('vm.root.accounting.main.filter.less') }}</span>
					</button>
				</div>

				<div v-if="invoices.length <= 0" class="accounting-no-result">
					<div v-if="searching" class="text-center">
						<spinner class="svg-xl text-primary"/>
						<div class="text-md text-bold marg-v">
							{{ $t('vm.root.accounting.main.loading') }}
						</div>
					</div>
					<div v-else-if="searched" class="text-center">
						<svg width="1em" class="svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
						</svg>
						<div class="text-md text-bold marg-v">
							{{ $t('vm.root.accounting.main.none.title') }}<br>
							<i18n path="vm.root.accounting.main.none.text" tag="span">
								<button type="button" class="btn--link-like is-mobile-closed" :title="$t('vm.root.accounting.main.filter.title')"
									data-toggle-context v-toggle="{className: 'is-open is-mobile-closed', selector: '[data-toggle-context]', state:'context'}">
									<span>{{ $t('vm.root.accounting.main.none.button') }}</span>
								</button>
							</i18n>
						</div>
					</div>
				</div>

				<div v-else class="is-overflow scrollbar">
					<table class="data-table accounting-table">
						<thead>
							<tr>
								<th scope="col" class="text-left">
									<div class="checkbox text-xs">
										<input type="checkbox" id="chk00" v-model="selectAll" :title="$t('vm.root.accounting.main.select-all.title')">
										<label for="chk00" :title="$t('vm.root.accounting.main.select-all.label')">
											<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
											<span class="sr-only">{{ $t('vm.root.accounting.main.select-all.label') }}</span>
										</label>
									</div>
								</th>
								<th scope="col" class="text-left w40">{{ $t('vm.root.accounting.main.columns.label') }}</th>
								<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.number') }}</th>
								<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.date') }}</th>
								<th scope="col" class="text-right">{{ $t('vm.root.accounting.main.columns.mode') }}</th>
								<th scope="col" class="text-right">{{ $t('vm.root.accounting.main.columns.state') }}</th>
								<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.spend') }}</th>
								<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.receipt') }}</th>
								<th scope="col" class="text-right w10">{{ $t('vm.root.accounting.main.columns.tva') }}</th>
								<th scope="col"><!-- Imprimer --></th>
								<th scope="col"><!-- Supprimer --></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="invoice in invoices" :key="invoice.id" :class="{'is-selected': invoice.selected, 'is-spend': isSpend(invoice) || isImmobilization(invoice)}">
								<td class="text-left accounting-table-checkbox">
									<div class="checkbox text-xs">
										<input type="checkbox" :id="`chk${invoice.id}`" v-model="invoice.selected">
										<label :for="`chk${invoice.id}`" :title="$t('vm.root.accounting.main.select')">
											<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
											<span class="sr-only">{{ $t('vm.root.accounting.main.select') }}</span>
										</label>
									</div>
								</td>
								<td class="text-left text-ellipsis accounting-table-label">
									<router-link v-if="isReceipt(invoice)":to="{name: 'receipt', params: {inv_id: invoice.id}}" :title="$t('vm.root.accounting.main.link-title.receipt')"
										:inner-html.prop="invoice.label | mark(subFilter.label)">
									</router-link>
									<router-link v-if="isSpend(invoice)":to="{name: 'spend', params: {inv_id: invoice.id}}" :title="$t('vm.root.accounting.main.link-title.spend')"
										:inner-html.prop="invoice.label | mark(subFilter.label)">
									</router-link>
									<router-link v-if="isImmobilization(invoice)":to="{name: 'immobilization', params: {inv_id: invoice.id}}" :title="$t('vm.root.accounting.main.link-title.immobilization')"
										:inner-html.prop="invoice.label | mark(subFilter.label)">
									</router-link>
								</td>
								<td class="text-right accounting-table-num">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.number') }}</span>
									<span class="text-numeric text-nowrap accounting-table-num-value" :inner-html.prop="invoice.number | mark(subFilter.number)"/>
								</td>
								<td class="text-right accounting-table-num">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.date') }}</span>
									<span class="text-numeric text-nowrap accounting-table-num-value">{{ $d(invoice.settlement_date, 'l') }}</span>
								</td>
								<td class="text-right accounting-table-num">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.mode') }}</span>
									<button type="button" class="btn--reset accounting-table-num-value data-table-icon" tabindex="-1">
										<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
											<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIcon(invoice.mode)}`"/>
										</svg>
										<span class="data-table-icon-tooltip">{{ getModeLabel(invoice.mode) }}</span>
									</button>
								</td>
								<td class="text-right accounting-table-num" :class="{'rwd-over-small': !( isReceipt(invoice) )}">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.state') }}</span>
									<span class="accounting-table-num-value text-nowrap" v-if="isReceipt(invoice)">
										<button v-if="invoice.checked" type="button" class="btn--reset data-table-icon" tabindex="-1">
											<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#inbox-in"/>
											</svg>
											<span class="data-table-icon-tooltip">{{ $t('vm.root.accounting.main.state.checked') }}</span>
										</button>
										<button v-if="invoice.banked" type="button" class="btn--reset data-table-icon" tabindex="-1">
											<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#piggy-bank"/>
											</svg>
											<span class="data-table-icon-tooltip">{{ $t('vm.root.accounting.main.state.banked') }}</span>
										</button>
									</span>
									<span class="accounting-table-num-value text-nowrap" v-if="isSpend(invoice)">
										<button v-if="isRecurrency(invoice)" type="button" class="btn--reset data-table-icon" tabindex="-1">
											<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
												<use xlink:href="@Assets/svg/symbol-defs.svg#repeat"/>
											</svg>
											<span class="data-table-icon-tooltip">{{ $t('vm.root.accounting.main.state.recurrency') }}</span>
										</button>
									</span>
								</td>
								<td class="text-right accounting-table-num"
									:class="{'rwd-over-small': !( ((isSpend(invoice) || isImmobilization(invoice)) && invoice.ttc > 0) || (isReceipt(invoice) && invoice.retrocession > 0) )}">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.spend') }}</span>
									<span v-if="(isSpend(invoice) || isImmobilization(invoice)) && invoice.ttc > 0"
										class="accounting-table-num-value text-numeric text-nowrap">
										{{ $n(invoice.ttc, 'currency', invoice.currency) }}
									</span>
									<span v-else-if="isReceipt(invoice) && invoice.retrocession > 0"
										class="accounting-table-num-value text-numeric text-nowrap">
										{{ $n(invoice.retrocession, 'currency', invoice.currency) }}
									</span>
								</td>
								<td class="text-right accounting-table-num" :class="{'rwd-over-small': !( isReceipt(invoice) && invoice.ttc >= 0 )}">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.receipt') }}</span>
									<span v-if="isReceipt(invoice) && invoice.ttc >= 0"
										class="accounting-table-num-value text-numeric text-nowrap">
										{{ $n(invoice.ttc, 'currency', invoice.currency_i18n) }}
									</span>
								</td>
								<td class="text-right accounting-table-num" :class="{'rwd-over-small': !( invoice.tva > 0 )}">
									<span class="rwd-under-small">{{ $t('vm.root.accounting.main.columns.tva') }}</span>
									<span class="text-numeric text-nowrap accounting-table-num-value">
										<span v-if="invoice.tva > 0">{{ invoice.tva }} %</span>
									</span>
								</td>
								<td class="accounting-table-button" :class="{'rwd-over-small': !( isReceipt(invoice) && isReceiptType1(invoice) )}">
									<a v-if="isReceipt(invoice) && isReceiptType1(invoice)"
										class="accounting-table-action"
										:title="$t('vm.root.accounting.main.print-receipt')" :href="invoicePath(invoice)" target="_blank">
										<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#print"/>
										</svg>
										<span class="rwd-under-small">{{ $t('general.print.text') }}</span>
									</a>
								</td>
								<td class="accounting-table-button">
									<delete-item btn-class="btn--link-like text-danger accounting-table-action"
										:title="$t('vm.root.accounting.main.delete-line.title')"
										:delete-msg="$t('vm.root.accounting.main.delete-line.delete-msg')"
										:delete-action="() => submitDelete(invoice.id)" :can-delete="true" :disabled="isDeleting(invoice.id)">
										<spinner v-if="isDeleting(invoice.id)"/>
										<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
										</svg>
										<span class="rwd-under-small">{{ $t('vm.root.accounting.main.delete-line.label') }}</span>
									</delete-item>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<control-bar v-if="invoices.length > 0" class="accounting-control-bar">

					<div class="is-overflow scrollbar accounting-footer">
						<table class="data-table accounting-footer-table">
							<tbody>
								<tr>
									<th scope="row" class="text-left w60">{{ $t('vm.root.accounting.main.total.receipts') }}</th>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(totalReceiptHt, 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ht') }}</span>
									</td>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(totalReceipt, 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ttc') }}</span>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-left w60">{{ $t('vm.root.accounting.main.total.spends') }}</th>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(totalSpendHt, 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ht') }}</span>
									</td>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(totalSpend, 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ttc') }}</span>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-left w60">{{ $t('vm.root.accounting.main.total.balance') }}</th>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(totalReceiptHt - totalSpendHt, 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ht') }}</span>
									</td>
									<td class="w20">
										<span class="text-numeric text-nowrap">{{ $n(getTotal(), 'currency', defaultCurrency) }} {{ $t('vm.root.accounting.main.total.ttc') }}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<hr class="accounting-footer-hr">

					<div class="text-right">
						<dropdown-actions id="action" :actions="actionItems" :is-loading="isLoadingAction" :menu-on-top="invoices.length > 5"/>
					</div>

				</control-bar>

			</div>
		</central>

		<context>
			<context-main v-if="isMainUser"/>
			<context-accounting v-else-if="isAccountingUser"/>
			<context-substitute v-else-if="isSubstituteUser"/>
		</context>

	</master>

</container>
