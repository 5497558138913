export const absence = {
    title: "Absence / Congés",
    help: "En indiquant une période d'absence, les horaires disponibles de votre planning seront automatiquement mises à jour. De plus, si vous avez souscrit à l'offre de planning en ligne, la prise de rendez-vous en ligne par vos patients sera désactivée pendant cette période en affichant le message que vous renseignerez.",
    'is-absent': {
        title: "Êtes-vous absent prochainement ?",
        label: "Vous êtes absent ou en vacances ?"
    },
    period: {
        label: "Période d'absence",
        title: "Indiquez la période de votre absence",
        placeholder: "ex : Du 30 février au 32 mars"
    },
    message: {
        label: "Message affiché pendant une période d'absence",
        placeholder: "ex : L'ostéopathe que vous demandez est actuellement en congés, nanana nanère…",
        title: "Message d'absence affiché automatiquement pendant une période d'absence"
    }
}