export const aside = {
    back: {
        'title-closed': "Retournez vers vos tickets ouverts",
        label: "Retour"
    },
    'new-ticket': {
        title: "Créer un nouveau ticket",
        label: "Créer un ticket"
    },
    ticket: "Ouvrir le ticket {title}",
    'closed-ticket': {
        title: "Consultez vos tickets fermés",
        label: "Tickets fermés"
    },
    support: {
        label: "acquérir",
        title: "Support expiré",
        content: "Votre support est expiré. Pour pouvoir créer un nouveau ticket, vous devez <strong>acquérir un pack maintenance</strong>."
    }
}