<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.patient.aside.back.title')" 
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.patients.patient.aside.back.text') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'patient-coordinates', params: $route.params}" :title="$t('vm.root.patients.patient.aside.coordinates.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ $t('vm.root.patients.patient.aside.coordinates.text') }}
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-antecedents', params: $route.params}" :title="$t('vm.root.patients.patient.aside.antecedents.title')">
				<span class="with-badge-label">
					{{ $tc("vm.root.patients.patient.aside.antecedents.text", antecedents.length) }}<br>
					<span class="text-xs">{{antecedentRunningCount}} {{ $t('general.running') }} - {{ $tc("vm.root.patients.patient.aside.antecedents.family", antecedentFamiliyCount) }}</span>
				</span>
				<span class="with-badge-badge" :class="colorManager.badge_antecedent | foregroundClass" :style="`--color:${colorManager.badge_antecedent};`">{{antecedents.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-spheres', params: $route.params}" :title="$t('vm.root.patients.patient.aside.spheres.title')">
				<span class="with-badge-label">
					{{ $tc("vm.root.patients.patient.aside.spheres.text", spheres.length) }}<br>
					<span class="text-xs">{{sphereRunningCount}} {{ $t('general.running') }}</span>
				</span>
				<span class="with-badge-badge" :class="colorManager.badge_sphere | foregroundClass" :style="`--color:${colorManager.badge_sphere};`">{{spheres.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-consultations', params: $route.params}" :title="$t('vm.root.patients.patient.aside.consultations.title')">
				<span class="with-badge-label">{{ $tc("vm.root.patients.patient.aside.consultations.text", consultations.length) }}</span>
				<span class="with-badge-badge" :class="colorManager.badge_consultation | foregroundClass" :style="`--color:${colorManager.badge_consultation};`">{{consultations.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-attachments', params: $route.params}" :title="$t('vm.root.patients.patient.aside.attachments.title')">
				<span class="with-badge-label">{{ $tc("vm.root.patients.patient.aside.attachments.text", attachments.length) }}</span>
				<span class="with-badge-badge" :class="colorManager.badge_attachment | foregroundClass" :style="`--color:${colorManager.badge_attachment};`">{{attachments.length}}</span>
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'patient-pediatrics', params: $route.params}" :title="$t('vm.root.patients.patient.aside.pediatrics.title')">{{ $t('vm.root.patients.patient.aside.pediatrics.text') }}</router-link>
		</li>
		<li>
			<router-link class="with-badge" :to="{name: 'patient-family', params: $route.params}" :title="$t('vm.root.patients.patient.aside.family.title')">
				<span class="with-badge-label">{{ $t('vm.root.patients.patient.aside.family.text') }}</span>
				<span class="with-badge-badge">{{family.length}}</span>
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li v-if="isMainUser">
			<router-link :to="{name: 'patient-relaunch', params: $route.params}" :title="$t('vm.root.patients.patient.aside.relaunch.title')">{{ $t('vm.root.patients.patient.aside.relaunch.text') }}</router-link>
		</li>
		<li>
			<router-link :to="{name: 'patient-documents', params: $route.params}" :title="$t('vm.root.patients.patient.aside.documents.title')">{{ $t('vm.root.patients.patient.aside.documents.text') }}</router-link>
		</li>
	</ul>

	<ul class="aside-navigation is-pushed-down" v-if="!patient.is_new">
		<li class="hr" aria-hidden="true"/>
		<li>
			<button type="button" :disabled="isBlacklisting" :class="{'is-whitelisted': !blacklisted, 'is-blacklisted': blacklisted}" @click="blacklist(!blacklisted)">
				<spinner v-if="isBlacklisting"/>
				<svg v-if="blacklisted" width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-plus"/>
				</svg>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-times"/>
				</svg>
				<span v-if="blacklisted">{{ $t('vm.root.patients.patient.aside.whitelist.label') }}</span>
				<span v-else>{{ $t('vm.root.patients.patient.aside.blacklist.label') }}</span>
			</button>
		</li>
		<li>
			<button type="button" :disabled="isDeceasing" @click="dead(!deceased)">
				<spinner v-if="isDeceasing"/>
				<svg v-else-if="deceased" width="1em" class="svg-middle" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#smile-beam"/>
				</svg>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#dizzy"/>
				</svg>
				<span v-if="deceased">{{ $t('vm.root.patients.patient.aside.resurrected.label') }}</span>
				<span v-else>{{ $t('vm.root.patients.patient.aside.deceased.label') }}</span>
			</button>
		</li>
		<li v-if="isMainUser">
			<delete-item :can-delete="!patient.is_new" :delete-action="submitDelete"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
				:title="$t('vm.root.patients.patient.aside.delete.title')" :disabled="isDeleting"
				:delete-msg="$t('vm.root.patients.patient.aside.delete.message')">
				<spinner v-if="isDeleting"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.patients.patient.aside.delete.text') }}
			</delete-item>
		</li>
	</ul>
</a-side>
