import Render from '@Views/root/user/office/context-office.html'
import { Component, Vue } from 'vue-property-decorator'
import { IUser, IRatialBar, IOffice, ISettings, ISchedule } from '@Store/types'
import { Office } from '@Models/index'
import { office, user } from '@Store/modules'

@Render
@Component({})
export class ContextOffice extends Vue {

	// @State('user') stateUser: UserState
	// @State('office') stateOffice: OfficeState
	// @Getter('office/office') getOffice: (id: string) => IOffice
	// @Getter('office/settings') getSettings: (id: string) => ISettings
	// @Getter('office/schedule') getSchedule: (id: string) => ISchedule

	get user(): IUser {
		return user.user
	}
	get office(): IOffice {
		return office.office(this.$route.params.ofi_id)
	}
	get settings(): ISettings {
		return office.setting(this.office.id)
	}
	get series(): number[] {
		return [this.state]
	}
	get chartOptions(): IRatialBar {
		return {
			chart: {
				type: 'radialBar',
				fontFamily: 'inherit'
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: `55%`,
					},
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							fontSize: '40px',
							show: true
						}
					}
				}
			},
			stroke: {
				lineCap: 'round'
			}
		}
	}
	get state(): number {
		return Office.getState(this.office, this.settings, this.schedule, this.user)
	}
	private get schedule(): ISchedule {
		return office.schedule(this.office.id)
	}

	checkUrl(): boolean {
		return Office.checkUrl(this.office, this.settings, this.user)
	}

	checkAddress(): boolean {
		return Office.checkAddress(this.office)
	}

	checkPhones(): boolean {
		return Office.checkPhones(this.office)
	}

	checkActivate(): boolean {
		return Office.checkActivate(this.settings)
	}

	checkMessage(): boolean {
		return Office.checkMessage(this.settings)
	}

	checkDefaultRejection(): boolean {
		return Office.checkDefaultRejection(this.settings)
	}

	checkSchedule(): boolean {
		return Office.checkSchedule(this.schedule)
	}

	checkAccesses(): boolean {
		return Office.checkAccesses(this.office)
	}
}
