export const contextRemittances = {
    intro: "Lorsque vous recevez des paiements par chèque, vous devez les endosser puis remplir un bordereau de remise de chèques et enfin le remettre, accompagné du ou des chèques concernés, à votre établissement bancaire. Ce dernier se chargera de déposer la somme sur votre compte. On détaille :",
    title1: "Étape 1 : Endossez vos chèques",
    text1: "Cela signifie que vous devez indiquer au dos du chèque votre numéro de compte, puis inscrire votre signature. Répétez l'opération autant de fois que vous avez de chèques à déposer.",
    title2: "Étape 2 : remplir un bordereau de remise de chèque",
    text2: "Ce document pré-imprimé reprend la liste de tous les chèques déposés et atteste de la remise des chèques. Les informations à renseigner sont simples et ne concernent que le bénéficiaire (donc vous) et les chèques en eux-mêmes :",
    list2: {
        item1: "Vos prénom et nom.",
		item2: "Votre numéro de compte bancaire (celui sur lequel vous voulez déposer ces sommes).",
		item3: "La date de dépôt.",
		item4: "Le nombre de chèques, leurs montants, les noms des émetteurs ou des banques émettrices.",
		item5: "La somme totale de la remise de chèque.",
        item6: "Votre signature pour attester de votre dépôt."
    },
    title3: "Étape 3 : conserver le double du bordereau",
    text3: {
        content: "C'est ce 2{0} feuillet qui vous servira de preuve de dépôt. La plupart des établissements bancaires mettent à disposition de leurs clients des enveloppes prévues pour cet usage. Ensuite, selon votre choix, la procédure pour encaisser un chèque est simple :",
        sup: "ème"
    },
    list3: {
        item1: "Glissez le bordereau et les chèques dans l'urne réservée à cet effet ou dans la boîte aux lettres d'une agence de votre banque.",
		item2: "ou Déposez le bordereau et les chèques au conseiller présent au guichet d'une agence de votre banque. Dans ce cas, il vous remettra un reçu ou une attestation de remise de chèque en main propre.",
        item3: "ou Envoyez le bordereau et les chèques par courrier. Votre lettre de remise de cheque doit être adressée à l'intention de votre banque."
    },
    title4: "Étape 4 : la banque reçoit votre remise de chèque",
    text4: "Elle en assure alors le traitement en déposant la somme sur votre compte. Sachez que la banque crédite votre compte avec une date de valeur de 1 jour ouvré à partir du jour de la remise du chèque. Autrement dit, si vous déposez un chèque un mercredi 26 septembre, la date de valeur sera le jeudi 27 septembre. Ce point est important car c'est la date de valeur que votre banque prendra en compte dans le calcul de vos intérêts…",
    outro: {
        content: "Ici, les remises de chèque proposées par Osteo2ls vont permettre de {0}.",
        strong: "remplir l'étape 2"
    }
}