export const main = {
    type: {
        label: "Type de sphère",
        error: "Merci de renseigner le type de sphère",
        'no-options-label': "tapez pour rechercher un type de sphère…",
        placeholder: "ex : Système orl",
        title: "Veuillez renseigner le type de sphère"
    },
    title: {
        label: "Pathologie",
        placeholder: "ex : Conjonctivite",
        'no-options-label': "tapez pour rechercher une pathologie…",
        error: "Merci de renseigner la pathologie de cette sphère",
        title: "Veuillez renseigner la pathologie de cette sphère"
    },
    state_date: {
        label: "Date de la sphère"
    },
    there_is_placeholder: "ex : 15 jours",
    at_the_age_placeholder: "ex : 23 ans",
    date: {
        title: "Veuillez indiquer la date de à laquelle s'est produite la sphère",
        placeholder: "ex : Le 16 juin 1995",
        error: "Merci d'indiquer la date de à laquelle s'est produite la sphère"
    },
    at_year: {
        title: "Veuillez indiquer l'année à laquelle s'est produite la sphère",
        placeholder: "ex : 1995",
        error: "Merci d'indiquer l'année à laquelle s'est produite la sphère"
    },
    description: {
        label: "Description de la sphère",
        placeholder: "ex : Suite à une allergie",
        title: "Veuillez renseigner la description de cette sphère"
    },
    important_label: "Sphère importante",
    periodic_label: "Sphère en cours",
    periodicity: {
        label: "Périodicité",
        no: "ponctuel",
        yes: "périodique"
    },
    end_date: {
        label: "pendant…",
        placeholder: "ex : 6 mois"
    }
}
