import Render from '@Views/root/calendar/event/rendez-vous.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPopin, IPopinAction, IBreadcrumbItem, IEvent, IPatient } from '@Store/types'
import {IDropdownItem, IToggleBoxItem} from '@Components/index'
import { PatientItem, SearchPatient } from '@ViewModels/root/search/patient'
import {DayOfMonthType, ReminderType} from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb, event, popIn, user } from '@Store/modules'
import {TimePanel} from "@ViewModels/root/calendar/event/time-panel"

@Render
@Component({
	components: {
		'patient-item': PatientItem,
		'search-patient': SearchPatient,
		'time-panel': TimePanel
	}
})
export class RendezVous extends Vue {

	private reminders: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]

	@Prop() event: IEvent

	//#region Getters
	get isNewEvent(): boolean {
		return !this.event || parseInt(this.event.id) === -1
	}
	get remindersItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.reminders.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
	    return result
	}
	get confirmationsItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.reminders.forEach (type => result.push({value: type, label: EnumsHelper.confirmationToString(type)}))
	    return result
	}
	private get selectedUser(): any {
		return user.selectedPlanningUser || {}
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
	}

	addPatient(patient: IPatient): void {
		event.addPatient({patient: patient})
	}

	unselect(): void {
		let unselectAction: IPopinAction = {
			label: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.label').toString(),
			title: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.title').toString(),
			callback: (data: any) => {
				event.unselectPatient()
				return Promise.resolve()
			}
		}
		let unselectPopin: IPopin = {
			title: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.title').toString(),
			content: this.$i18n.t('vm.root.calendar.event.rendez-vous.unselect.content').toString(),
			action: unselectAction
		}

		popIn.warning(unselectPopin)
	}

	private initializeBreadcrumb(): void {
		let userName: string = !!this.selectedUser ?
			`${this.selectedUser.first_name} ${this.selectedUser.last_name}` :
			this.$i18n.t('vm.root.breadcrumb.no-user').toString()

        let label = user.isSecretaryUser ?
			this.$i18n.t('vm.root.breadcrumb.plannings-secretary', {user: userName.trim()}).toString() :
			this.$i18n.t('vm.root.breadcrumb.plannings-main').toString()

		let item1: IBreadcrumbItem = { label: label, link: "plannings"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.event').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
