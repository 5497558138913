<div v-if="!!receipt" class="grid-2">

    <patient-item v-if="receipt.patient && withPatient" :patient="receipt.patient" :without-unselect="true" class="grid-item-full"
        :path-to-open="{name: 'patient-documents', params: {pat_id: receipt.patient.id, con_id: receipt.con_id}}"/>

    <input-frame :auto-focus="true" type="text" id="label" v-model="receipt.label" :class="{'grid-item-full': !!light}"
        :label="$t('vm.root.accounting.receipt.details.label.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.label.placeholder')"
        :error-test="hasLabelError" :error-msg="$t('vm.root.accounting.receipt.details.label.error-msg')"
        :title="$t('vm.root.accounting.receipt.details.label.title')"/>

    <input-date-frame v-if="!light" v-model='receipt.settlement_date' id="settlement-date" :type="dateType"
        :label="$t('vm.root.accounting.receipt.details.date.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.date.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.date.title')"/>

    <dropdown-frame v-if="!light && withSessions" id="session" v-model="ses_id" :items="sessionItems" class="grid-item-full"
        :label="$t('vm.root.accounting.receipt.details.session.label')"
        :title="$t('vm.root.accounting.receipt.details.session.title')"/>
    <dropdown-frame v-else-if="!light" id="office" v-model="receipt.ofi_id" :items="officeItems" class="grid-item-full"
        :label="$t('vm.root.accounting.receipt.details.office.label')"
        :title="$t('vm.root.accounting.receipt.details.office.title')"/>

    <input-frame v-if="!light" type="text" id="number" v-model="receipt.number" :readonly="!receipt.can_modify"
        :label="$t('vm.root.accounting.receipt.details.number.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.number.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.number.title')"/>

	<div v-if="!light" class="input-frame-like">
        <switch-frame id="modify-number" v-model="receipt.can_modify"
            :title="$t('vm.root.accounting.receipt.details.modify-number.title')">
			{{ $t('vm.root.accounting.receipt.details.modify-number.label') }}
		</switch-frame>
	</div>

    <dropdown-frame id="receipt-type" v-model="receipt.receipt_type" :items="receiptTypeItems"
        :label="$t('vm.root.accounting.receipt.details.type.label')"
        :title="$t('vm.root.accounting.receipt.details.type.title')"/>

    <dropdown-frame id="payment-mode" v-model="receipt.mode" :items="paymentModeItems"
        :label="$t('vm.root.accounting.receipt.details.mode.label')"
        :title="$t('vm.root.accounting.receipt.details.mode.title')"/>

    <input-frame v-if="!light" type="number" id="amount" v-model="receipt.ttc" min="0" step="0.01" :readonly="isFreeAct"
        :label="$t('vm.root.accounting.receipt.details.amount.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.amount.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.amount.title')"/>

    <input-frame v-if="!light" type="number" id="tva" v-model="receipt.tva" min="0" max="100" step="0.1"
        :label="$t('vm.root.accounting.receipt.details.tva.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.tva.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.tva.title')"/>

    <switch-frame id="checked" v-model="receipt.checked" checked-icon="inbox-in"
        :title="$t('vm.root.accounting.receipt.details.checked.title')">
        {{ $t('vm.root.accounting.receipt.details.checked.label') }}
    </switch-frame>

    <switch-frame id="banked" v-model="receipt.banked" checked-icon="piggy-bank"
        :title="$t('vm.root.accounting.receipt.details.banked.title')">
        {{ $t('vm.root.accounting.receipt.details.banked.label') }}
    </switch-frame>

    <input-frame v-if="!light && isRetrocession && receipt.retrocession > 0"
        type="number" id="retrocession" v-model="receipt.retrocession" min="0" step="0.01"
        :label="$t('vm.root.accounting.receipt.details.retrocession.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.retrocession.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.retrocession.title')"/>

    <dropdown-frame v-if="!light && isRetrocession && receipt.retrocession > 0"
        id="payment-retrocession-mode" v-model="receipt.mode_retrocession" :items="paymentModeItems"
        :label="$t('vm.root.accounting.receipt.details.mode-retrocession.label')"
        :title="$t('vm.root.accounting.receipt.details.mode-retrocession.title')"/>

    <input-frame type="text" id="bank" v-model="receipt.bank" class="grid-item-full"
        :label="$t('vm.root.accounting.receipt.details.bank.label')"
        :placeholder="$t('vm.root.accounting.receipt.details.bank.placeholder')"
        :title="$t('vm.root.accounting.receipt.details.bank.title')"/>

</div>
