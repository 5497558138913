import Render from '@Views/root/user/office/aside-office.html'
import { Component, Vue } from 'vue-property-decorator'
import { IOffice, ISession } from '@Store/types'
import { office } from '@Store/modules'

@Render
@Component({})
export class ASideOffice extends Vue {

  // @State('office') stateOffice: OfficeState
  // @Getter('office/office') getOffice: (id: string) => IOffice
  // @Getter('office/sessions') getSessions: (ofiId: string) => ISession[]
  // @Action('office/deleteOffice') deleteOffice:(ofiId: string) => Promise<any>

	get office(): IOffice {
		return office.office(this.$route.params.ofi_id)
  }
  get sessions(): ISession[] {
		return office.sessions(this.office.id)
	}

  submitDisable(): void {
    office.deleteOffice(this.office.id)
    .then(() => { this.$emit('delete') })
	}
}