<container>
	<aside-share/>

	<master>
		<central>
			<router-view/>
		</central>

		<context-share/>
	</master>

</container>
