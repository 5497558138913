import Render from '@Views/root/patients/patient/exclusion-test/main.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IExclusionTest, IConsultation } from '@Store/types'
import { IToggleBoxItem } from '@Components/index'
import { patient, user } from '@Store/modules'

@Render
@Component({})
export class Main extends Vue {

	@Prop() test: IExclusionTest
	@Prop() consultation: IConsultation
	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @Getter('user/isMainUser') isMainUser: boolean
	// @Action('patient/deleteExclusionTest') deleteExclusionTest: (payload: ExclusionTestDeletePayload) => void
	
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get id(): string {
		return parseInt(this.test.id) === -1 ? this.test.created_id : this.test.id
	}
	get items(): IToggleBoxItem[] {
		return [
			{value: true, label: this.$i18n.t('general.positive').toString()}, 
			{value: false, label: this.$i18n.t('general.negative').toString()}
		]
	}
	get hasExclusionTestNameError(): boolean {
		return false
	}

	submitDelete(): void {
		patient.deleteExclusionTest({consultation: this.consultation, test: this.test})
	}
}