<section class="patients-search-item text-sm" :id="`search-${patient.id}`">
    <router-link class="patients-search-item-header text-capitalize text-inherit" :to="{name:'patient-coordinates', params: {pat_id: patient.id}}"
        :title="$t('vm.root.patients.patient-search-item.title', {first_name: patient.first_name, last_name: patient.last_name})"
        v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
        <figure class="patients-search-item-avatar">
            <avatar :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
        </figure>
        <h3 class="h-reset patients-search-item-name marquee">
            <span class="marquee-inner">
                <span class="marquee-content">
                    <anonymous :text="patient.first_name" :query="query"/>
                </span>
            </span><br>
            <span class="marquee-inner">
                <span class="marquee-content">
                    <anonymous tag="b" :text="patient.last_name" :query="query"/>
                </span>
            </span>
        </h3>
    </router-link>
    <div v-if="patient.blacklisted || patient.deceased" class="patients-search-item-border">
        <span v-if="patient.blacklisted" class="patient-blacklisted">{{ $t("vm.root.patients.patient-search-item.blacklisted") }}</span>
        <span v-if="patient.deceased" class="patient-deceased">{{ $t("vm.root.patients.patient-search-item.deceased") }}</span>
    </div>
    <div class="patients-search-item-border" v-if="filter.by_profession || filter.by_email || filter.by_phone || filter.by_address || filter.by_birth_date">
        <div v-if="filter.by_profession">
            <div v-if="patient.profession" :inner-html.prop="patient.profession | mark(query)"/>
            <div v-else><i>{{ $t("vm.root.patients.patient-search-item.no-profession") }}</i></div>
        </div>
        <div v-if="filter.by_email">
            <div v-if="patient.email" class="marquee select">
                <div class="marquee-inner">
                    <anonymous tag="div" :query="query" :text="patient.email" :email="true" class="marquee-content"/>
                </div>
            </div>
            <div v-else><i>{{ $t("vm.root.patients.patient-search-item.no-email") }}</i></div>
        </div>
        <div v-if="filter.by_phone">
            <anonymous tag="div" v-if="patient.phone" class="select" :text="$options.filters.formatPhone(patient.phone)"/>
            <div v-else><i>{{ $t("vm.root.patients.patient-search-item.no-phone") }}</i></div>
        </div>
        <div v-if="filter.by_address">
            <anonymous tag="div" v-if="patient.address" :text="patient.address"/>
            <div v-else><i>{{ $t("vm.root.patients.patient-search-item.no-address") }}</i></div>
        </div>
        <div v-if="filter.by_birth_date">
            <anonymous tag="div" v-if="patient.birth_date" :text="`${$tc('vm.root.patients.patient.coordinates.read-only.born-the', patient.gender)} ${$d(patient.birth_date, 'll')}`"/>
            <div v-else><i>{{ $t("vm.root.patients.patient-search-item.no-birth-date") }}</i></div>
        </div>
    </div>
</section>
