import Render from '@Views/root/statistics/planning/planning.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, StatisticsActionPayload, IStatisticsFilter } from '@Store/types'
import { Statistics, Debouncer } from '@Models/index'
import { DateHelper } from '@Helpers/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class Planning extends Vue {

	private first: boolean = true
	private ratios1: number[] = [0,0]
	private ratios2: number[] = [0,0]
	private stats: IStatsConsultations = null
	private debouncerEvolution1: Debouncer = null
	private debouncerEvolution2: Debouncer = null

	//#region Getters
	get filter(): IStatisticsFilter {
        return statistics.filter
	}
	get isLoading(): boolean {
        return this.first && statistics.isLoading
	}
	get legends(): {label: string, color: string}[] {
		return [
			{label: this.$i18n.t('vm.root.statistics.planning.main.legends.first').toString(), color: '#008ffb'},
			{label: this.$i18n.t('vm.root.statistics.planning.main.legends.second').toString(), color: '#00cb8f'},
			{label: this.$i18n.t('vm.root.statistics.planning.main.legends.third').toString(), color: '#fea019'}
		]
	}
	get evolutionSeries1(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.doEvolution1
		}, {
			name: this.legends[1].label,
			data: this.missedEvolution1
		}, {
			name: this.legends[2].label,
			data: this.totalEvolution1
		}]
    }
	get evolutionSeries2(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.doEvolution2
		}, {
			name: this.legends[1].label,
			data: this.missedEvolution2
		}, {
			name: this.legends[2].label,
			data: this.totalEvolution2
		}]
	}
    get evolutionChartOptions(): any {
		return {
            chart: {
                type: 'area',
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				categories: DateHelper.getMonthsName()
			},
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			}
        }
	}

	get ratioSeries1(): number[] {
		return this.ratios1
	}
	get ratioSeries2(): number[] {
		return this.ratios2
	}
	get ratioChartOptions1(): any {
		return {
			...this.ratioChartOptions,
			tooltip: {
				y: {
					formatter: (val: number) => {
						let total: number = this.ratioSeries1.reduce((a, b) => a + b, 0)
						let percent: number = Math.round(val * 100 / total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}
	get ratioChartOptions2(): any {
		return {
			...this.ratioChartOptions,
			tooltip: {
				y: {
					formatter: (val: number) => {
						let total: number = this.ratioSeries2.reduce((a, b) => a + b, 0)
						let percent: number = Math.round(val * 100 / total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}
	private get ratioChartOptions(): any {
		return {
			chart: {
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			labels: this.legends.map(l => { return l.label }),
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			}
		}
	}

	private get doEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._0 ? [] : this.stats.evolution1._0
	}
	private get missedEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._1 ? [] : this.stats.evolution1._1
	}
	private get totalEvolution1(): number[] {
		let result: number[] = []
		for(let i: number = 0; i < Math.max(this.doEvolution1.length, this.missedEvolution1.length); i++) {
			let first: number = this.doEvolution1.length > i ? this.doEvolution1[i] : 0
			let next: number = this.missedEvolution1.length > i ? this.missedEvolution1[i] : 0
			result.push(first + next)
		}
		return result
	}
	private get doEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._0 ? [] : this.stats.evolution2._0
	}
	private get missedEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._1 ? [] : this.stats.evolution2._1
	}
	private get totalEvolution2(): number[] {
		let result: number[] = []
		for(let i: number = 0; i < Math.max(this.doEvolution2.length, this.missedEvolution2.length); i++) {
			let first: number = this.doEvolution2.length > i ? this.doEvolution2[i] : 0
			let next: number = this.missedEvolution2.length > i ? this.missedEvolution2[i] : 0
			result.push(first + next)
		}
		return result
	}
	//#endregion

	mounted() {
		this.debouncerEvolution1 = new Debouncer(this.updateEvolution1, 500)
		this.debouncerEvolution2 = new Debouncer(this.updateEvolution2, 500)
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'planning')
		this.initializeBreadcrumb()

		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			pla_id: this.filter.pla_id,
			year1: this.filter.year1,
			year2: this.filter.year2,
			update: false
		}
		statistics.loadPlanningStatistics(payload)
		.then((stats) => {
			this.stats = stats
			this.ratios1 = [this.sum(this.doEvolution1), this.sum(this.missedEvolution1)]
			this.ratios2 = [this.sum(this.doEvolution2), this.sum(this.missedEvolution2)]
			this.first = false
		})
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerEvolution1)
		Debouncer.clear(this.debouncerEvolution2)
	}

	getTotalSeries1(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries1.find(e => { return e.name === legend}))
	}

	getTotalSeries2(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries2.find(e => { return e.name === legend}))
	}

	@Watch('filter.usr_id')
	@Watch('filter.ofi_id')
	@Watch('filter.pla_id')
	updateYears() {
		this.updateStatistics(this.filter.year1, this.filter.year2)
	}

	@Watch('filter.year1')
	updateYear1() {
		this.debouncerEvolution1.start()
	}

	@Watch('filter.year2')
	updateYear2() {
		this.debouncerEvolution2.start()
	}

	private updateStatistics(year1: number, year2: number) {
		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			acc_id: this.filter.usr_id,
			pla_id: this.filter.pla_id,
			year1: !!year1 ? year1 : -1,
			year2: !!year2 ? year2 : -1,
			update: true
		}
		statistics.loadPlanningStatistics(payload)
		.then((stats) => {
			if (!!year1) {
				this.stats.evolution1 = stats.evolution1
				this.ratios1 = [this.sum(this.doEvolution1), this.sum(this.missedEvolution1)]
			}
			if (!!year2) {
				this.stats.evolution2 = stats.evolution2
				this.ratios2 = [this.sum(this.doEvolution2), this.sum(this.missedEvolution2)]
			}
		})
	}

	private updateEvolution1() {
		this.updateStatistics(this.filter.year1, undefined)
	}

	private updateEvolution2() {
		this.updateStatistics(undefined, this.filter.year2)
	}

	private sum(array: number[]): number {
		return array.reduce((a, b) => a + b, 0)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.planning').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

interface IStatsConsultations {
	evolution1: {
		_0: number[],
		_1: number[],
	},
	evolution2: {
		_0: number[],
		_1: number[],
	},
	ratio: number[]
}
