import { TagService, TagCategory } from "@Services/index"

export class Tag {

    public static getProfession(query: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Profession)
    }

    public static getReference(query: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Reference)
    }

    public static getReason(query: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Consultation)
    }

    public static getAntecedentType(query: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Antecedent)
    }

    public static getSphereType(query: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Sphere)
    }

    public static getPathologyType(query: string, sphere: string): Promise<any> {
        let service = new TagService()
        return service.getTags(query, TagCategory.Pathology, {sphere: sphere})
    }
}