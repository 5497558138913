export const main = {
    name: {
        label: "Nom du test d'exclusion",
        placeholder: "ex : Tension artérielle",
        error: "Merci de renseigner le nom du test d'exclusion",
        title: "Veuillez renseigner le nom du test d'exclusion"
    },
    value: {
        label: "Valeur du test",
        title: "Résultat du test"
    },
    comment: {
        label: "Commentaire sur le test d'exclusion",
        placeholder: "ex : 13/8cmHg", 
        title: "Veuillez renseigner un commentaire à propos du test d'exclusion"
    }
}