export const mainPurchase = {
    title: "Achat",
    paying: {
        thanks: "Merci !",
        title: "Votre achat a bien été pris en compte.",
        check: {
            intro1: "Pour le finaliser, merci d'envoyer un chèque, à l'ordre de {0}, d'un montant de",
            intro2: "à l'adresse suivante :"
        },
        transfer: {
            intro1: "Pour le finaliser, merci d'effectuer un virement de",
            intro2: {
                text: "vers le compte identifié par le {0} suivant :",
                'abbr-title': "Relevé d'Identité Bancaire",
                'abbr-label': "RIB"
            },
            iban: "IBAN",
            bic: "BIC",
        },
        paypal: "Vous allez être redirigé dans quelques instants…"
    },
    payed: {
        thanks: "Merci !",
        text1: "Votre achat a bien été finalisé.",
        text2: "|Retrouvez la facture associée à ce dernier dans le contexte ci-contre.|Retrouvez les factures associées à ce dernier dans le contexte ci-contre."
    },
    poster: {
        alt: "Affiche d'Osteo2ls",
        title: "Affiche"
    },
    card: {
        model1: "Modèle 1",
        model2: "Modèle 2",
        'alt-recto': "Carte de visite recto d'Osteo2ls,",
        'title-recto': "Carte de visite, recto",
        'alt-verso': "Carte de visite verso d'Osteo2ls,",
        'title-verso': "Carte de visite, verso",
    },
    'unvalid-code': "Code non valable",
    cancel: {
		label: "perdre vos modifications",
        title: "Annulation",
        content: "Vous êtes sur le point d'annuler l'ensemble de vos modifications. Vous confirmez ?"
    },
    'cant-payed-on-demo': "On ne peut pas acheter depuis la plateforme de démonstration… voyons !",
    address: {
        'error-msg': "Veuillez indiquer une adresse de facturation",
        label: "Adresse de facturation",
        title: "Veuillez indiquer l'adresse de facturation"
    },
    code: {
        label: "Code promotionnel ou Code parrain",
        title: "Si vous possédez un code promotionnel ou un code parrain, n'hésitez pas à le saisir",
        placeholder: "ex : C0D3",
        apply: "appliquer",
        'apply-title': "Appliquez votre code promotionnel ou votre code parrain",
        help: "Le code parrain que vous avez saisi lors de votre inscription vous permet de bénéficier d'une réduction de 10 %. Il n'est pas cumulable avec un éventuel autre code promotionnel."
    },
    model: {
        label: "Modèle de carte",
        title: "Sélectionnez le modèle de carte de visite que vous souhaitez"
    },
    quantity: {
        label: "Quantité",
        title: "Nombre d'élément souhaité"
    },
    mode: {
        label: "Mode de paiement",
        title: "Sélectionnez le mode de paiement pour régler"
    },
    total: {
        text: "Total à payer :",
        'ttc-title': "Toutes Taxes Comprises",
        'ttc-label': "TTC",
        subscription: " / mois, pendant 12 mois",
        month: "mois",
        year: "an"
    },
    pay: {
        title: "Réglez votre achat",
        label: "régler"
    },
    subscribe: {
        title: "Abonnez vous à ce service",
        label: "s'abonner"
    },
    unsubscribe: {
        'delete-msg': "Vous êtes sur le point de résilier cet abonnement. Voulez-vous continuer ?",
        title: "Résiliez votre abonnement à ce service",
        label: "résilier"
    }
}
