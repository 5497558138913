export const asideReceipt = {
    back: {
        title: "Retourner au tableau des finances",
        label: "Retour au tableau"
    },
    print: {
        title: "Imprimer la facture",
        label: "Imprimer"
    },
    send: {
        title: "Envoyer la facture par mail",
        label: "Envoyer par mail",
        succeeded: "La facture a bien été envoyée à l'adresse <strong>{email}</strong>.",
        failed: "La facture n'a pu être envoyée à votre patient."
    },
    delete: {
        title: "Suppression de cette recette",
        'delete-msg': "Voulez-vous vraiment supprimer cette recette ?",
        label: "Supprimer cette recette"
    }
}
