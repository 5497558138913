import Render from '@Views/root/patients/patient/consultation/main.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {IConsultation, IOffice} from '@Store/types'
import { CustomForm } from '@Components/forms/custom-form'
import {configuration, patient, user, office} from '@Store/modules'
import {Common} from "@Models/common"
import {Patient} from "@Models/patient"

@Render
@Component({
	components: {
		'custom-form': CustomForm
	},
	beforeRouteUpdate(to, from, next) {
		next()
		if (!!to && !!to.params.item_id) return

		this.createConsultation()
	}
})
export class Main extends Vue {

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	//#region Getters
	get consultation(): IConsultation {
		return patient.consultation(this.$route.params.item_id)
	}
	get hasReasonError(): boolean {
		return this.submitted && (!this.consultation.reason || this.consultation.reason.length === 0)
	}
	get reasonTitle(): string {
		return patient.reasonTitle(this.consultation) || 'Consultation'
	}
	get index(): number {
		return patient.consultations.findIndex(c => c === this.consultation)
	}
	get isFirst(): boolean {
		return this.index === patient.consultations.length -1
	}
	get isLast(): boolean {
		return this.index === 0
	}
	get office(): string {
		let currenOffice: IOffice = office.office(this.consultation.ofi_id)
		if (!currenOffice) return ''
		return currenOffice.coordinates.name
	}
	get owner(): string {
		let owner: string = patient.owner(this.consultation)
		if (!owner) return this.$i18n.t('vm.root.patients.patient.consultation.yourself').toString()
		return owner
	}
	private get isSubstituteUser(): boolean {
		return user.isSubstituteUser
	}
	//#endregion

	created() {
		this.createConsultation()
	}

	mustBeReadonly(consultation: IConsultation): boolean {
		return this.isSubstituteUser && parseInt(consultation.id) !== -1 && consultation.creator_id !== user.currentUser.id
	}

	onEdit(): void {
		this.$emit('edit')
	}

	onNavigate(item: string): void {
		this.$emit('navigate', item)
	}

	next() {
		if (this.isLast) return

		this.moveTo(this.index - 1)
	}

	previous() {
		if (this.isFirst) return

		this.moveTo(this.index + 1)
	}

	private createConsultation() {
		if (!!this.$route.params.pat_id && !!patient.patient && patient.patient.id !== this.$route.params.pat_id) return
		if (!!this.$route.params.item_id && !!patient.consultation(this.$route.params.item_id)) return

		this.onEdit()
		let createId: string = Common.generateId()
		patient.consultations.push(Patient.createNewConsultation(createId, configuration.configuration, Patient.getLastMeasurement(patient.consultations)))

		this.$router.replace({
			name: 'patient-consultation',
			params: {pat_id: this.$route.params.pat_id, item_id: createId}
		})
	}

	private moveTo(index: number) {
		let consultation: IConsultation = patient.consultations[index]
		this.$router.push({name:this.$route.name, params:{pat_id:this.$route.params.pat_id, item_id: this.getRouteId(consultation)}})
	}

	private getRouteId(consultation: IConsultation): string {
		return parseInt(consultation.id) === -1 ? consultation.create_id : consultation.id
	}
}
