<container>

	<a-side :sub-menu="true">
		<ul class="aside-navigation">
			<li>
				<router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.aside.back.title')"
					v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					{{ $t('vm.root.patients.aside.back.text') }}
				</router-link>
			</li>
		</ul>
	</a-side>

	<master>

		<central>
			<div class="wrapper">
				<collapsible-help id="merge-information"
								  :title="$t('vm.root.patients.merge.title', {value: searching ? `${percent} %` : mergeCount})" tag="h1">
					<spinner slot="main-icon" v-if="searching"/>
					<svg v-else slot="main-icon" width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#code-merge"/>
					</svg>

					<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
					</svg>
					<div class="notice-info">
						<p class="text-left">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
							</svg>
							{{ $t('vm.root.patients.merge.help') }}
						</p>
					</div>
				</collapsible-help>

				<div v-if="resultSearch.length <= 0" class="marg-20">
					<div v-if="!searching && searched" class="text-center">
						<svg width="1em" class="svg-xl text-primary" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
						</svg>
						<p>
							{{ $t("vm.root.patients.merge.no-result") }}<br>
							{{ $t("vm.root.patients.merge.adjust-filter") }}
						</p>
					</div>
				</div>
				<div v-else class="is-overflow scrollbar">
					<div class="grid-3 vertical-gap marg-v">
						<merge-item :patient="patient" v-for="patient in resultSearch" :key="patient.id"/>
					</div>
				</div>
				<div v-if="searching" class="text-center" :class="{'marg-20': !resultSearch || resultSearch.length <= 0}">
					<spinner class="svg-xl text-primary"/>
					<div class="text-md text-bold marg-v">{{ $t('vm.root.patients.merge.searching') }}</div>
				</div>
			</div>
		</central>

		<context>
			<context-merge/>
		</context>

	</master>

</container>
