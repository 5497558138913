<div class="no-marg">
    <div v-if="isEmpty" class="marg-v text-center">
        <svg v-if="!timeline" width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
        </svg>
        <em v-if="timeline" class="p-like text-lg">{{ $t('vm.root.patients.patient.read-only.none') }}</em>
        <p v-else class="text-lg">{{ $t('vm.root.patients.patient.read-only.none') }}</p>
    </div>

    <div v-else>
        <div v-if="withExamens && (!isEmptyEva || !isEmptyExamen)">
            <p v-if="!isEmptyEva">
                <i18n path="vm.root.patients.patient.read-only.eva.text" tag="strong">
                    <span :style="eva.before | eva('color')">{{ $t('vm.root.patients.patient.read-only.eva.before', {eva: eva.before}) }}</span>
                    &bull;
                    <span :style="eva.after | eva('color')">{{ $t('vm.root.patients.patient.read-only.eva.after', {eva: eva.after}) }}</span>
                </i18n>
            </p>
            <p class="text-pre-line" v-if="examen.evolution"><strong>{{ $t('vm.root.patients.patient.read-only.examen.evolution') }}</strong> :<br><span v-html="examen.evolution"/></p>
            <p class="text-pre-line" v-if="examen.observation"><strong>{{ $t('vm.root.patients.patient.read-only.examen.observation') }}</strong> :<br><span v-html="examen.observation"/></p>
            <p class="text-pre-line" v-if="examen.diagnostic"><strong>{{ $t('vm.root.patients.patient.read-only.examen.diagnostic') }}</strong> :<br><span v-html="examen.diagnostic"/></p>
            <p class="text-pre-line" v-if="examen.tests"><strong>{{ $t('vm.root.patients.patient.read-only.examen.tests') }}</strong> :<br><span v-html="examen.tests"/></p>
            <p class="text-pre-line" v-if="examen.treatment"><strong>{{ $t('vm.root.patients.patient.read-only.examen.treatment') }}</strong> :<br><span v-html="examen.treatment"/></p>
        </div>
        <div v-if="withCranio && !isEmptyCranio">
            <h4>{{ $t('vm.root.patients.patient.cranio.sub-title-1') }}</h4>
            <div class="grid-3">
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-cranial.rhythm.label') }} :</strong> {{cranio.rhythm_mrp_cranial}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-cranial.amplitude.label') }} :</strong> {{cranio.amplitude_mrp_cranial}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-cranial.force.label') }} :</strong> {{cranio.force_mrp_cranial}}</p>
            </div>

            <h4>{{ $t('vm.root.patients.patient.cranio.sub-title-2') }}</h4>
            <div class="grid-3">
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-sacred.rhythm.label') }} :</strong> {{cranio.rhythm_mrp_sacred}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-sacred.amplitude.label') }} :</strong> {{cranio.amplitude_mrp_sacred}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.mrp-sacred.force.label') }} :</strong> {{cranio.force_mrp_sacred}}</p>
            </div>

            <div class="grid-3">
                <p><strong>{{ $t('vm.root.patients.patient.cranio.ssb-label') }} :</strong> {{cranio.ssb | articulationMove}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.torsion-label') }} :</strong> {{cranio.torsion | horizontalDirection}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.sbr-label') }} :</strong> {{cranio.sbr | horizontalDirection}}</p>
            </div>

            <div class="grid-2">
                <p><strong>{{ $t('vm.root.patients.patient.cranio.vertical-strain.label') }} :</strong> {{cranio.vertical_strain | verticalDirection}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.vertical-strain.cause') }} :</strong> {{cranio.cause_vertical_strain | strainMove}}</p>
            </div>

            <div class="grid-2">
                <p><strong>{{ $t('vm.root.patients.patient.cranio.side-strain.label') }} :</strong> {{cranio.side_strain | horizontalDirection}}</p>
                <p><strong>{{ $t('vm.root.patients.patient.cranio.side-strain.cause') }} :</strong> {{cranio.cause_side_strain | strainMove}}</p>
            </div>

            <p v-if="cranio.compression"><strong>{{ $t('vm.root.patients.patient.cranio.compression-label') }}</strong></p>

            <p class="text-pre-line"><strong>{{ $t('vm.root.patients.patient.cranio.condylar-shares.label') }} :</strong> {{cranio.condylar_shares}}</p>
            <p class="text-pre-line"><strong>{{ $t('vm.root.patients.patient.cranio.c0c1.label') }} :</strong> {{cranio.c0c1}}</p>
            <p class="text-pre-line"><strong>{{ $t('vm.root.patients.patient.cranio.eyes.label') }} :</strong> {{cranio.eyes}}</p>
            <p class="text-pre-line"><strong>{{ $t('vm.root.patients.patient.cranio.roof.label') }} :</strong> {{cranio.roof}}</p>
            <p class="text-pre-line"><strong>{{ $t('vm.root.patients.patient.cranio.mandible.label') }} :</strong> {{cranio.mandible}}</p>
        </div>

        <p v-if="withTests && tests"><strong>{{ $t('vm.root.patients.patient.read-only.tests') }}</strong> :<br>{{tests}}&hellip;</p>
        <form-summary v-if="withForms" v-for="form in consultation.forms" :key="form.id" :form="form"/>

    </div>

</div>
