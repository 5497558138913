<div v-if="!!event">
    <div :class="[hasSessions(event.ofi_id) && !event.all_day ? 'grid-2' : 'grid-3']">
        <dropdown-frame id="office" v-model="event.ofi_id" :items="officeItems"
            :label="$t('vm.root.calendar.event.rendez-vous.office.label')"
            :title="$t('vm.root.calendar.event.rendez-vous.office.title')"/>
        <dropdown-frame id="session" v-if="hasSessions(event.ofi_id) && !event.all_day" v-model="event.ses_id" :items="sessionItems(event.ofi_id)"
            :label="$t('vm.root.calendar.event.rendez-vous.session.label')"
            :title="$t('vm.root.calendar.event.rendez-vous.session.title')"/>
        <div class="input-frame">
            <label class="label" for="color" :data-text="$t('vm.root.calendar.event.rendez-vous.color.label')">
                <span>{{ $t('vm.root.calendar.event.rendez-vous.color.label') }}</span>
            </label>
            <div class="form-group">
                <checkbox-frame id="with-color" tag="label" default-class="input-like form-group-input is-flex-fixed"
                    v-model="event.with_color" :disabled="!isClassic"
                    :title="$t('vm.root.calendar.event.rendez-vous.color.checked')"/>
                <input-color class="form-group-input" id="color" v-model="event.color" :disabled="!event.with_color || !isClassic"
                    :placeholder="$t('vm.root.calendar.event.rendez-vous.color.placeholder')"
                    :title="$t('vm.root.calendar.event.rendez-vous.color.title')"/>
            </div>
        </div>

        <dropdown-frame id="planning" v-model="event.pla_id" :items="planningItems"
            :label="$t('vm.root.calendar.event.rendez-vous.planning.label')"
            :title="$t('vm.root.calendar.event.rendez-vous.planning.title')"/>
    </div>

    <div class="grid-2">
        <switch-frame id="all-day" :title="$t('vm.root.calendar.event.rendez-vous.all-day.title')" v-model="event.all_day">
            {{ $t('vm.root.calendar.event.rendez-vous.all-day.label') }}
        </switch-frame>
        <switch-frame id="missed" switch-class="is-danger" :title="$t('vm.root.calendar.event.rendez-vous.missing.title')" v-model="event.missed" checked-icon="rabbit">
            {{ $t('vm.root.calendar.event.rendez-vous.missing.label') }}
        </switch-frame>

        <input-date-frame v-if="!event.all_day" v-model='event.start_date' id="start-date" :type="dateType"
            :label="$t('vm.root.calendar.event.rendez-vous.range.start.label')"
            :title="$t('vm.root.calendar.event.rendez-vous.range.start.title')"/>

        <input-frame v-if="!event.all_day" type="number" id="duration" min="10" step="5" v-model="duration"
            :label="$t('vm.root.calendar.event.rendez-vous.duration.label', {'end-date': $d(new Date(event.end_date), 'long')})"
            :title="$t('vm.root.calendar.event.rendez-vous.duration.title')" />

        <input-range v-if="event.all_day" v-model='range' :type="rangeType" :duration="duration" class="grid-item-full grid-2">
            <input-frame id="startRange" :random-id="false"
                :label="$t('vm.root.calendar.event.rendez-vous.range.start.label')"
                :title="$t('vm.root.calendar.event.rendez-vous.range.start.title')"/>
            <input-frame id="endRange" :random-id="false"
                :label="$t('vm.root.calendar.event.rendez-vous.range.end.label')"
                :title="$t('vm.root.calendar.event.rendez-vous.range.end.title')"/>
        </input-range>

    </div>

</div>
