export * from './aside'
export * from './main'
export * from './examens'
export * from './exclusion-tests'
export * from './follow-up'
export * from './reason'
export * from './cranio'
export * from './schema'
export * from './walton'
export * from './read-only'
