import { BaseService } from '@Services/index'
import { ICampaign } from '@Store/types'

export class CampaignService extends BaseService {
	
	public getCampaign(cmp_id: string): Promise<any> {
		return super.get(`/campaign/${cmp_id}`)
	}

	public deleteCampaign(cmp_id: string): Promise<any> {
		return super.delete(`/campaign/${cmp_id}`)
	}

	public save(campaign: ICampaign): Promise<any> {
		if (parseInt(campaign.id) === -1) 
			return super.post('/campaign', campaign)

		return super.put(`/campaign/${campaign.id}`, campaign)
	}

	public getCampaignList(): Promise<any> {
		return super.get(`/campaigns/`)
	}
}