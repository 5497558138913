import Render from '@Views/components/input/togglebox.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class ToggleBox extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop() items: IToggleBoxItem[]
    @Prop() id: string
    @Prop() value: any

    checked(value: any): boolean {
        return this.value === value
    }

    update(value: any): void {
        this.$emit('input', value)
        this.$emit('change', value)
    }

    getId(item: IToggleBoxItem, index: number): string {
        let suffix: string = index === 0 ? '' : `-${index}`
        return `${this.id}${suffix}`
    }
}

export interface IToggleBoxItem {
    label: string,
    value: any,
    class?:string,
    icon?:string
}