<div v-if="!!sessions">
	<div class="childs-space-between marg-v">
		<h2 class="text-uppercase-first-letter no-marg">{{ $tc('vm.root.user.office.sessions.title', sessions.length) }}</h2>
		<button type="button" class="btn--link-like is-pushed-right" @click="addSession" :title="$t('vm.root.user.office.sessions.new-session.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.user.office.sessions.new-session.label') }}</span>
		</button>
	</div>

	<div class="collapsible-panel bordered-panel marg-v" :ref="getElementId(defaultSession)" slot="header">
		<div class="childs-space-between">
			<button type="button" class="btn--link-like collapsible-panel-trigger text-bold"
				:title="$t('vm.root.user.office.sessions.details')"
				@click="toggle(defaultSession.id, defaultSession.create_id)"
				:class="{'is-active': isToggle(defaultSession.id, defaultSession.create_id)}">
				<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				<span class="text-uppercase-first-letter">{{ defaultSession.name || $t('vm.root.user.office.sessions.default-name') }}</span>
			</button>
			<span class="text-italic is-pushed-right">
				{{ $t('vm.root.user.office.sessions.summary', {duration: defaultSession.duration, price: $n(defaultSession.ttc, 'currency', office.coordinates.currency)}) }}
			</span>
		</div>
		<div class="collapsible-panel-container"  :class="{'is-active': isToggle(defaultSession.id, defaultSession.create_id)}">
			<div class="collapsible-panel-content">
				<session :session="defaultSession" :office="office" :submitted="submitted"/>
			</div>
		</div>
	</div>

	<draggable :forceFallback="true" tag="ul" :list="sessions" class="ul-reset" handle=".draggable-handler">

		<template v-for="session in sessions">
			<li v-if="!isDefaultSession(session)" :key="getElementId(session)" class="draggable" :ref="getElementId(session)">
				<div class="collapsible-panel bordered-panel marg-v draggable-frame is-with-border">
					<div class="childs-space-between">
						<span>
							<button type="button" class="btn--reset text-default draggable-handler" :title="$t('vm.root.user.office.sessions.move.title')">
								<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#grip-vertical"/>
								</svg>
								<span class="sr-only">{{ $t('vm.root.user.office.sessions.move.label') }}</span>
							</button>
							<button type="button" class="btn--link-like collapsible-panel-trigger text-bold"
								:title="$t('vm.root.user.office.sessions.details')"
								@click="toggle(session.id, session.create_id)"
								:class="{'is-active': isToggle(session.id, session.create_id)}">
								<svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
									<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
								</svg>
								<span class="text-uppercase-first-letter">{{ session.name || $t('vm.root.user.office.sessions.default-new-name') }}</span>
							</button>
						</span>
						<span class="text-italic is-pushed-right">
							{{ $t('vm.root.user.office.sessions.summary', {duration: session.duration, price: $n(session.ttc, 'currency', office.coordinates.currency)}) }}
						</span>
					</div>
					<div class="collapsible-panel-container"  :class="{'is-active': isToggle(session.id, session.create_id)}">
						<div class="collapsible-panel-content">
							<session :session="session" :office="office" :submitted="submitted"/>
						</div>
					</div>
				</div>
			</li>
		</template>

	</draggable>

</div>
