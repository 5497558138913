<section class="person-item">
	<router-link class="person-item-header text-capitalize text-inherit" :to="{name:'colleague', params: {col_id: colleague.id}}"
		:title="$t('vm.root.search.colleague.item.update.title')">
		<figure class="person-item-avatar">
			<avatar :disable-anonymous="true" :first-name="colleague.first_name" :last-name="colleague.last_name" :email="colleague.email"/>
		</figure>
		
		<h3 class="h-reset person-item-name">
			<span :inner-html.prop="colleague.first_name | mark(query)"/><br>
			<b :inner-html.prop="colleague.last_name | mark(query)"/>
		</h3>
	</router-link>
	<div class="person-item-border text-primary">
		<span v-if="professions">{{professions}}</span>
		<i v-else>{{ $t('vm.root.search.colleague.item.no-profession') }}</i>
	</div>
	<div class="person-item-border">
		<div v-if="!colleague.phone || !colleague.phone.number"><i>{{ $t('vm.root.search.colleague.item.no-phone') }}</i></div>
		<div v-else class="select"><a :href="`tel:${colleague.phone.number}`">{{colleague.phone.number | formatPhone}}</a></div>
		<div v-if="colleague.email" class="marquee select">
			<div class="marquee-inner">
				<div class="marquee-content"><a :href="`mailto:${colleague.email}`">{{colleague.email}}</a></div>
			</div>
		</div>
		<div v-else><i>{{ $t('vm.root.search.colleague.item.no-email') }}</i></div>
	</div>
	<div v-if="colleague.patient_count > 0" class="person-item-border text-primary">
		<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
		</svg>
		{{ $tc('vm.root.search.colleague.item.associted-patient', colleague.patient_count) }}
	</div>
	<div v-if="canUnselect" class="person-item-border text-right">
		<button v-if="canUnselect" type="button" class="btn btn--link-like text-danger" @click="unselect"
			:title="$t('vm.root.search.colleague.item.unselect.title-button')">{{ $t('vm.root.search.colleague.item.unselect.label') }}</button>
	</div>
</section>
