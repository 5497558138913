import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { CampaignStatus, RequestStatus } from "@Enums/index"
import { ICampaign } from "@Store/types"
import { CampaignService } from "@Services/index"

@Module({
	name: 'campaign',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class CampaignModule extends VuexModule {
    step: number = 0
    campaign: ICampaign = null
    private status: RequestStatus = RequestStatus.None
    private statusSaving: RequestStatus = RequestStatus.None
    private statusDeleting: RequestStatus = RequestStatus.None
    private statusList: RequestStatus = RequestStatus.None

	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isLoading(): boolean {
		return this.statusList === RequestStatus.Loading
	}
	get isSended(): (campaign?: ICampaign) => boolean {
		return (campaign?: ICampaign) => {
			campaign = !!campaign ? campaign : this.campaign
			return !!campaign && campaign.status === CampaignStatus.Sended
		}
	}

	@Mutation
	clear() {
		this.step = 0
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusList = RequestStatus.None
		this.campaign = null
	}
	
	@Mutation
	updateStep(step: number) {
		this.step = step
	}
	
	@Mutation
	campaignsRequest() {
		this.statusList = RequestStatus.Loading
	}
	
	@Mutation
	campaignsSuccess() {
		this.statusList = RequestStatus.Success
	}
	
	@Mutation
	campaignsFailure() {
		this.statusList = RequestStatus.Failed
	}
	
	@Mutation
	campaignRequest() {
		this.status = RequestStatus.Loading
		this.campaign = null
	}
	
	@Mutation
	campaignSuccess(campaign) {
		this.status = RequestStatus.Success
		this.campaign = campaign
	}
	
	@Mutation
	campaignFailure() {
		this.status = RequestStatus.Failed
		this.campaign = null
	}
	
	@Mutation
	deleteRequest() {
		this.statusDeleting = RequestStatus.Loading
	}
	
	@Mutation
	deleteSuccess() {
		this.statusDeleting = RequestStatus.Success
	}
	
	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}
	
	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}
	
	@Mutation
	saveSuccess(campaign: ICampaign) {
		this.statusSaving = RequestStatus.Success
		this.campaign = campaign
	}
	
	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Action
	nextStep(): void {
		this.updateStep(this.step + 1)
	}

	@Action
	previousStep(): void {
		this.updateStep(Math.max(this.step - 1, 0))
	}

	@Action({rawError: true})
	async loadCampaign(cmp_id: string): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.campaignRequest()

		let service = new CampaignService()
		return service.getCampaign(cmp_id)
		.then(campaign => {
			this.campaignSuccess(campaign)
			return Promise.resolve(campaign)
		})
		.catch(error => {
			this.campaignFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveCampaign(campaign: ICampaign): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new CampaignService()
		return service.save(campaign)
		.then(newCampaign => {
			this.saveSuccess(newCampaign)
			return Promise.resolve(newCampaign)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteCampaign(cmp_id: string): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		if (!cmp_id || parseInt(cmp_id) === -1) return
		
		this.deleteRequest()
		let service = new CampaignService()
		return service.deleteCampaign(cmp_id)
		.then(() => {
			this.deleteSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadCampaigns(): Promise<any> {
		if (this.statusList === RequestStatus.Loading) return

		this.campaignsRequest()

		let service = new CampaignService()
		return service.getCampaignList()
		.then(
			invoices => {
				this.campaignsSuccess()
				return Promise.resolve(invoices)
			}
		)
		.catch(error => {
			this.campaignsFailure()
			return Promise.reject(error)
		})
	}
}

export const campaign = getModule(CampaignModule)
// export const campaign = new CampaignModule({ store, name: "campaign" })