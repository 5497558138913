<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.campaigns.aside.back.label')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.patients.campaigns.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>

		<li>
			<router-link :to="{name: 'patients-campaign', params: {cmp_id: '-1'}}" :title="$t('vm.root.patients.campaigns.aside.new-campaign.title')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ $t('vm.root.patients.campaigns.aside.new-campaign.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li v-if="isLoading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</li>
		<li v-else v-for="campaign in campaigns" :key="campaign.id">
			<router-link v-if="isSended(campaign)" :to="{name: 'campaign-summary', params:{cmp_id: campaign.id}}" :title="$t('vm.root.patients.campaigns.aside.campaign', {campaign: campaign.title})"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ campaign.title }}<br>
				<span class="text-xs">{{ $t('vm.root.patients.campaigns.aside.sended', {date: $d(campaign.sending_date, 'll'), hour: $d(campaign.sending_date, 'LT')}) }}</span>
			</router-link>
			<router-link v-else :to="{name: 'patients-campaign', params:{cmp_id: campaign.id}}" :title="$t('vm.root.patients.campaigns.aside.campaign', {campaign: campaign.title})"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				{{ campaign.title }}<br>
				<span class="text-xs">{{ $t('vm.root.patients.campaigns.aside.not-sended') }}</span>
			</router-link>
		</li>
	</ul>
</a-side>