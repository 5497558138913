import {cloneDeep, isEqual} from 'lodash'
import {i18n} from '@I18n/index'
import {IConfiguration, INotification} from '@Store/types'
import {Common} from './common'

export class Parameters {
	public static getNotification(configuration: IConfiguration): INotification {
		let message: string = i18n.t("models.configuration.configuration-saved").toString()
		let messages: string[] = Parameters.getUpdateList(configuration)
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		return {message: message, delay: 5000, actions: [], canClose: true}
	}

	public static getConfiguration(configuration: IConfiguration, oldConfiguration: IConfiguration): IConfiguration {
		let newConfiguration = cloneDeep(configuration)
		newConfiguration['update-accounting'] = this.isAccountingUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-follow-up'] = this.isFollowUpUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-notification'] = this.isNotificationUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-general'] = this.isGeneralUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-planning'] = this.isPlanningUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-relaunch'] = this.isRelaunchUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-reminder'] = this.isReminderUpdated(newConfiguration, oldConfiguration)
		newConfiguration['update-anonymous'] = newConfiguration.anonymous !== oldConfiguration.anonymous
		newConfiguration.colors['update'] = !isEqual(newConfiguration.colors, oldConfiguration.colors)
		newConfiguration.display['update'] = !isEqual(newConfiguration.display, oldConfiguration.display)

		Common.updateItemList(newConfiguration.documents.currents, oldConfiguration.documents.currents)
		Common.updateItemList(newConfiguration.forms.currents, oldConfiguration.forms.currents)

		return newConfiguration
	}

	public static getUpdateList(configuration:IConfiguration): string[] {
		let result: string[] = []
		if (configuration['update-general']) result.push(i18n.t("models.configuration.general").toString())
		if (configuration['update-anonymous']) result.push(i18n.t("models.configuration.anonymous").toString())
		if (configuration.colors['update']) result.push(i18n.t("models.configuration.colors").toString())
		if (configuration.display['update']) result.push(i18n.t("models.configuration.display").toString())
		if (configuration['update-planning']) result.push(i18n.t("models.configuration.planning").toString())
		if (configuration['update-accounting']) result.push(i18n.t("models.configuration.accounting").toString())
		if (configuration['update-reminder']) result.push(i18n.t("models.configuration.reminder").toString())
		if (configuration['update-follow-up']) result.push(i18n.t("models.configuration.follow-up").toString())
		if (configuration['update-relaunch']) result.push(i18n.t("models.configuration.relaunch").toString())
		Common.addMessagesNotification(result, Parameters.getNotificationList(configuration, 'documents', "document"))
		Common.addMessagesNotification(result, Parameters.getNotificationList(configuration, 'forms', "form"))
		if (configuration['update-notification']) result.push(i18n.t("models.configuration.notification").toString())

		return result
	}

	private static getNotificationList(configuration:IConfiguration, list: string, label: string): string[] {
		let countUpdate: number = 0
		let countCreate: number = 0
		let countDeleted: number = configuration[list].deleted.filter(item => parseInt(item.id) !== -1).length

		for(let item of configuration[list].currents) {
			if (item['update']) countUpdate++
			else if (parseInt(item.id) === -1) countCreate++
		}

		let result: string[] = []
		Common.addMessageNotification(result, Common.getMessageNotification('configuration', 'updated', label, countUpdate, false))
		Common.addMessageNotification(result, Common.getMessageNotification('configuration', 'added', label, countCreate, false))
		Common.addMessageNotification(result, Common.getMessageNotification('configuration', 'deleted', label, countDeleted, false))

		return result
	}

	private static isAccountingUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.default_period !== oldConfiguration.default_period
	}

	private static isReminderUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.reminder_type !== oldConfiguration.reminder_type
			|| newConfiguration.reminder_text !== oldConfiguration.reminder_text
	}

	private static isRelaunchUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.relaunch_type !== oldConfiguration.relaunch_type
			|| newConfiguration.relaunch_text !== oldConfiguration.relaunch_text
			|| newConfiguration.relaunch_delay !== oldConfiguration.relaunch_delay
	}

	private static isPlanningUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		let sameHiddenDays: boolean = newConfiguration.hidden_days.length === oldConfiguration.hidden_days.length
		if (sameHiddenDays) {
			for (let i = 0; i < newConfiguration.hidden_days.length; i++) {
				sameHiddenDays = newConfiguration.hidden_days[i] === oldConfiguration.hidden_days[i]
				if (!sameHiddenDays) break
			}
		}
		return !sameHiddenDays
			|| newConfiguration.slot !== oldConfiguration.slot
			|| newConfiguration.refresh_calendar_delay !== oldConfiguration.refresh_calendar_delay
			|| newConfiguration.min_time.getTime() !== oldConfiguration.min_time.getTime()
			|| newConfiguration.max_time.getTime() !== oldConfiguration.max_time.getTime()
			|| newConfiguration.connect_google !== oldConfiguration.connect_google
	}

	private static isNotificationUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.notification_event !== oldConfiguration.notification_event
			|| newConfiguration.notification_follow_up !== oldConfiguration.notification_follow_up
			|| newConfiguration.notification_help !== oldConfiguration.notification_help
	}

	private static isGeneralUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.lang !== oldConfiguration.lang
			|| newConfiguration.auto_save !== oldConfiguration.auto_save
			|| newConfiguration.font_size !== oldConfiguration.font_size
			|| newConfiguration.theme !== oldConfiguration.theme
	}

	private static isFollowUpUpdated(newConfiguration: IConfiguration, oldConfiguration: IConfiguration): boolean {
		return newConfiguration.follow_up_type !== oldConfiguration.follow_up_type
			|| newConfiguration.follow_up_text !== oldConfiguration.follow_up_text
			|| newConfiguration.follow_up_delay !== oldConfiguration.follow_up_delay
			|| newConfiguration.follow_up_presentation !== oldConfiguration.follow_up_presentation
	}
}
