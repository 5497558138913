import axios from 'axios'

export class AddressService {
	
	private options: any = {
		//https://adresse.data.gouv.fr/api
		// "https://api-adresse.data.gouv.fr/search/?q=8+bd+du+port&lat=48.789&lon=2.789"
		baseURL: `https://api-adresse.data.gouv.fr/`,
		withCredentials: false,
		headers: { 'Content-Type': 'application/json' },
		transformResponse: [
			(responseData: any, headers: any) => {
				if (headers['content-type'] !== 'application/json; charset=utf-8' || responseData === '') {
					return responseData
				}
				try {
					return JSON.parse(responseData)
				} catch (e) {
					console.error(responseData)
					return {}
				}
			}
		]
	}

	private provider: any = axios.create(this.options)

	public async get(location: string, latitude?: number, longitude?: number): Promise<any> {
		if (!location) return Promise.resolve({features:[]})

		let vm = this
		let params: any = {q: location, limit: 15}
		if (!!latitude && !!longitude) {
			params.lat = latitude
			params.lon = longitude
		}

		return this.provider.get('search/', {params: params})
		.then((response: any) => {
			return Promise.resolve(response.data)
		})
		.catch((error: any) => {
			return vm.manageError(error)
		})
	}

	private manageError(error: any): Promise<any> {
		// console.error('manageError', error)
		return Promise.reject()
	}

}