<div>
    <div v-if="!!followUp.back">
        <p>
            <strong>{{ $t('vm.root.patients.patient.follow-up.follow-up-patient.label') }}</strong> :
            <span v-if="followUp.read">
                <svg width="1em" class="svg-middle" :class="classLevel" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
                    <use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${iconLevel}`" />
                </svg>
                <span :class="classLevel">{{ followUp.level | followUpLevel }}</span>
            </span>
            <span v-else>{{ $t('vm.root.patients.patient.follow-up.not-readed') }}</span>
            <br>
            <span class="text-pre-line" v-html="followUp.back"/>
        </p>
    </div>
    <div v-else class="marg-v text-center">
        <svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
        </svg>
        <p>
            <span class="text-lg">{{ $t('vm.root.patients.patient.follow-up.none.title') }}</span><br>
            <span>{{ $t('vm.root.patients.patient.follow-up.none.description') }}</span>
        </p>
    </div>
</div>