export const organizations = {
    title: "Association | Association | Associations",
    'new-organization': {
        title: "Créer une nouvelle association",
        label: "Nouvelle association"
    },
    'none': {
        label: "Pas d'association'",
        text: "Indiquez vos différentes {0}.",
        'button-label': "associations"
    },
    move: {
        title: "Déplacer cette association",
        label: "Déplacer"
    },
    details: "Détails de cette association",
    'default-name': "nouvelle association"
}