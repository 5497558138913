import { DateHelper } from "@Helpers/date"

export const age = function (birthDay: Date, date?: Date) {
  return DateHelper.calculateAge(birthDay, date)
}

export const timeAgo = function (from: Date, to?: Date) {
  return DateHelper.timeAgo(from, to)
}

export const since = function (from: Date, to?: Date) {
  return DateHelper.since(from, to)
}

export const inTime = function (to: Date, from?: Date) {
  return DateHelper.inTime(to, from)
}