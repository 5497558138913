import Render from '@Views/root/accounting/main.html'
import {Component, Vue, Watch} from 'vue-property-decorator'
import {ContextMain} from './context-main'
import {ContextAccounting} from './context-accounting'
import {ContextSubstitute} from './context-substitute'
import {ASideMain} from './aside-main'
import {
	IAccountingFilter,
	IAccountingSubFilter,
	IBreadcrumbItem,
	ICheckFilter,
	IInvoice,
	INotification,
	INotificationAction,
	IPopin,
	IPopinAction
} from '@Store/types'
import {AccountingAction, InvoiceType, PaymentMode, ReceiptFilterType, ReceiptType} from '@Enums/index'
import {Debouncer} from '@Models/index'
import {EnumsHelper} from '@Helpers/index'
import {IDropdownActionItem} from '@Components/dropdown-actions'
import {accounting, breadcrumb, notif, popIn, user} from '@Store/modules'
import config from '@Config/index'

@Render
@Component({
	components: {
		'aside-main': ASideMain,
		'context-main': ContextMain,
		'context-substitute': ContextSubstitute,
		'context-accounting': ContextAccounting,
	}
})
export class Main extends Vue {

	private static LinesNumberToSend = 1900

	searched: boolean = false
	searching: boolean = false
	selectAll: boolean = false
	action: AccountingAction = AccountingAction.Print
	invoices: IInvoice[] = []
	totalReceipt: number = 0
	totalRetrocession: number = 0
	totalSpend: number = 0
	totalReceiptHt: number = 0
	totalRetrocessionHt: number = 0
	totalSpendHt: number = 0
	isLoadingAction: boolean = false
	private deleteId: string = null
	private allInvoices: IInvoice[] = []
	private debouncerFilter: Debouncer = null
	private countQueries: number = 0

	//#region getter and setter
	get filter(): IAccountingFilter {
		return accounting.filter
	}
	get subFilter(): IAccountingSubFilter {
		return accounting.subFilter
	}
	get isAccountingUser(): boolean {
		return user.isAccountingUser
	}
	get isSubstituteUser(): boolean {
		return user.isSubstituteUser
	}
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get actionItems(): IDropdownActionItem[] {
		let allReceipt = this.selectedLines.filter(l => { return l.type === InvoiceType.Receipt })
		let checkKey: string = allReceipt.length > 0 && allReceipt.filter(l => { return l.checked }).length === allReceipt.length ? "uncheck" : "check"
		let bankKey: string = allReceipt.length > 0 && allReceipt.filter(l => { return l.banked }).length === allReceipt.length ? "unbank" : "bank"
		return [
			{
				value: AccountingAction.Print,
				action: this.print,
				title: this.$i18n.t('vm.root.accounting.main.actions.print-title').toString(),
				label: this.$i18n.t('vm.root.accounting.main.actions.print-label').toString(),
				icon: "print"
			},
			{
				value: AccountingAction.Export,
				action: this.export,
				title: this.$i18n.t('vm.root.accounting.main.actions.export-title').toString(),
				label: this.$i18n.t('vm.root.accounting.main.actions.export-label').toString(),
				icon: "cloud-download"
			},
			{ value: -1, action: null, title: '', label: "", separator: true },
			{
				value: AccountingAction.Check,
				action: this.check,
				title: this.$i18n.t(`vm.root.accounting.main.actions.${checkKey}-title`).toString(),
				label: this.$i18n.tc(`vm.root.accounting.main.actions.${checkKey}-label`, this.countSelectedLines).toString(),
				icon: "inbox-in",
				disabled: this.countSelectedLines === 0
			},
			{
				value: AccountingAction.Bank,
				action: this.bank,
				title: this.$i18n.t(`vm.root.accounting.main.actions.${bankKey}-title`).toString(),
				label: this.$i18n.tc(`vm.root.accounting.main.actions.${bankKey}-label`, this.countSelectedLines).toString(),
				icon: "piggy-bank",
				disabled: this.countSelectedLines === 0
			},
			{
				value: AccountingAction.Delete,
				action: this.confirmDelete,
				title: this.$i18n.t('vm.root.accounting.main.actions.delete-title').toString(),
				label: this.$i18n.tc('vm.root.accounting.main.actions.delete-label', this.countSelectedLines).toString(),
				icon: "trash-alt",
				disabled: this.countSelectedLines === 0
			}
		]
	}
	get defaultCurrency(): string {
		return !!this.invoices && this.invoices.length > 0 ? this.invoices[0].currency_i18n : 'fr'
	}
	get countSelectedLines(): number {
		return this.selectedLines.length
	}
	private get selectedLines(): IInvoice[] {
		return this.invoices.filter(i => { return i.selected })
	}
	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}
	//#endregion

	mounted() {
		this.debouncerFilter = new Debouncer(this.applySubFilter, 333)
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
		this.initializeInvoices()
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerFilter)
	}

	@Watch('countQueries')
	updateLoading() {
		this.searching = this.countQueries > 0
		this.searched = this.countQueries === 0
		if (!this.searched) return

		this.applySubFilter()
	}

	getTotal(): number {
		let receipts = !this.invoices ? [] : this.invoices.filter(i => this.isReceipt(i))
		let retrocessions = !this.invoices ? [] : this.invoices.filter(i => this.isReceipt(i) && i.retrocession > 0)
		let spends = !this.invoices ? [] : this.invoices.filter(i => !this.isReceipt(i))

		this.totalReceipt = receipts.length === 0 ? 0 : receipts.map(i => i.ttc).reduce((prev, next) => prev + next)
		this.totalReceiptHt = receipts.length === 0 ? 0 : receipts.map(i => this.getValueHt(i, i.ttc)).reduce((prev, next) => prev + next)

		this.totalRetrocession = retrocessions.length === 0 ? 0 : retrocessions.map(i => i.retrocession).reduce((prev, next) => prev + next)
		this.totalRetrocessionHt = retrocessions.length === 0 ? 0 : retrocessions.map(i => this.getValueHt(i, i.retrocession)).reduce((prev, next) => prev + next)

		this.totalSpend = spends.length === 0 ? this.totalRetrocession : spends.map(i => i.ttc).reduce((prev, next) => prev + next) + this.totalRetrocession
		this.totalSpendHt = spends.length === 0 ? this.totalRetrocessionHt : spends.map(i => this.getValueHt(i, i.ttc)).reduce((prev, next) => prev + next) + this.totalRetrocessionHt

		return this.totalReceipt - this.totalSpend
	}

	getValueHt(invoice: IInvoice, value: number): number {
		if (!value) return 0
		if (!invoice.tva) return value

		return Math.round(value * 100 / (100 + invoice.tva))
	}

	getIcon(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode, true)
	}

	getModeLabel(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode)
	}

	isRecurrency(invoice: IInvoice): boolean {
		return !!invoice.from_rec_id && parseInt(invoice.from_rec_id) !== -1
	}

	isReceipt(invoice: IInvoice): boolean {
		return !!invoice && invoice.type === InvoiceType.Receipt
	}

	isReceiptType1(invoice: IInvoice): boolean {
		return !!invoice && invoice.receipt_type === ReceiptType.Receipt1
	}

	isSpend(invoice: IInvoice): boolean {
		return !!invoice && invoice.type === InvoiceType.Spend
	}

	isImmobilization(invoice: IInvoice): boolean {
		return !!invoice && invoice.type === InvoiceType.Immobilization
	}

	invoicePath(receipt: IInvoice): string {
		let param: string = this.isDemo ? "?demo=true" : ""
		let label: string = (receipt.label || `recette-${receipt.number}`).replace(/\s+|[,\/]/g, "-")
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/invoice/${receipt.id}/${label}.pdf${param}`
	}

	isDeleting(invId: string): boolean {
		return accounting.isDeleting && this.deleteId === invId
	}

	submitDelete(invId: string): void {
		this.deleteId = null
		if (!invId) return

		this.deleteId = invId
		accounting.deleteInvoice(invId).then(() => {
			this.allInvoices = this.allInvoices.filter(i => { return i.id !== invId })
			this.invoices = this.invoices.filter(i => { return i.id !== invId })
		})
	}

	@Watch('selectAll')
	updateSelectAll(): void {
		this.invoices.forEach(i => { i.selected = this.selectAll })
	}

	@Watch('subFilter', {deep: true})
	filterInvoices() {
		if (this.searching) return

		if (!this.allInvoices || this.allInvoices.length === 0) {
			this.searched = true
			return
		}

		this.debouncerFilter.start()
	}

	private print(): void {
		if (this.invoices.length >= Main.LinesNumberToSend) {
			this.confirmPrint()
		} else {
			let filter: Record<string, string> = this.getFilterList()
			let params:string = new URLSearchParams(filter).toString()
			let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/print/documents/list.pdf?${params}`

			window.open(url, '_blank').focus()
		}
	}

	private export(): void {
		let filter: Record<string, string> = this.getFilterList()
		let params:string = new URLSearchParams(filter).toString()
		let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/csv/invoice.csv?${params}`

		window.open(url, '_blank').focus()
	}

	private check(): void {
		this.isLoadingAction = true
		let allReceipt = this.selectedLines.filter(l => { return l.type === InvoiceType.Receipt })
		let checked: boolean = allReceipt.filter(l => { return l.checked }).length !== allReceipt.length

		let checkList: string[] = allReceipt.map(l => { return l.id })
		accounting.checkInvoices({list: checkList, checked: checked})
		.then(() => {
			this.selectedLines.forEach(l => { l.checked = checked })
			this.isLoadingAction = false
		})
		.catch(() => { this.isLoadingAction = false })
	}

	private bank(): void {
		this.isLoadingAction = true
		let allReceipt = this.selectedLines.filter(l => { return l.type === InvoiceType.Receipt })
		let banked: boolean = allReceipt.filter(l => { return l.banked }).length !== allReceipt.length

		let bankList: string[] = allReceipt.map(l => { return l.id })
		accounting.bankInvoices({list: bankList, banked: banked})
		.then(() => {
			this.selectedLines.forEach(l => { l.banked = banked })
			this.isLoadingAction = false
		})
		.catch(() => { this.isLoadingAction = false })
	}

	private delete(): void {
		this.isLoadingAction = true
		let deleteList: string[] = this.selectedLines.map(l => { return l.id })
		accounting.deleteInvoices(deleteList)
		.then(() => {
			this.invoices = this.invoices.filter(i => { return !this.selectedLines.map(l => { return l.id }).includes(i.id) })
			this.isLoadingAction = false
		})
		.catch(() => { this.isLoadingAction = false })
	}

	private confirmDelete(): void {
		let deleteAction: IPopinAction = {
			label: this.$i18n.t('vm.root.accounting.main.delete.label').toString(),
			title: this.$i18n.t('vm.root.accounting.main.delete.title').toString(),
			callback: () => {
				this.delete()
				return Promise.resolve()
			}
		}
		let deletePopin: IPopin = {
			title: this.$i18n.t('vm.root.accounting.main.delete.title').toString(),
			content: this.$i18n.tc('vm.root.accounting.main.delete.content', this.countSelectedLines).toString(),
			action: deleteAction
		}

		popIn.warning(deletePopin)
	}

	private confirmPrint(): void {
		let cancelAction: INotificationAction = {
			label: this.$i18n.t('general.cancel.text').toString(),
			callback: () => {
				notif.clear()
				return true
			}
		}

		let sendAction: INotificationAction = {
			label: this.$i18n.t('vm.root.accounting.main.print.label').toString(),
			callback: () => {
				let filter: Record<string, string> = this.getFilterList()
				accounting.sendDocuments(filter)
					.then(() => {
						let message: string = this.$i18n.t('vm.root.accounting.main.print.success', {email: user.currentUser.email}).toString()
						notif.success({message: message, delay: 10000, actions: [], canClose: true})
					})
					.catch(() => {
						let message: string = this.$i18n.t('vm.root.accounting.main.print.failed').toString()
						notif.error({ message: message, delay: 10000, actions: [], canClose: true })
					})

				return true
			}
		}

		let message: string = this.$i18n.t('vm.root.accounting.main.print.content').toString()

		let notification: INotification = { message: message, delay: 10000, actions: [sendAction, cancelAction], canClose: true }
		notif.warning(notification)
	}

	private getFilterList(): Record<string, string> {
		let filter: Record<string, string> = {}
		filter['checked'] = this.hasReceiptFilter(ReceiptFilterType.Checked).toString()
		filter['unchecked'] = this.hasReceiptFilter(ReceiptFilterType.NotChecked).toString()
		filter['banked'] = this.hasReceiptFilter(ReceiptFilterType.Banked).toString()
		filter['unbanked'] = this.hasReceiptFilter(ReceiptFilterType.NotBanked).toString()
		filter['is_retrocession'] = this.hasReceiptFilter(ReceiptFilterType.Retrocession).toString()
		filter['is_not_retrocession'] = this.hasReceiptFilter(ReceiptFilterType.NotRetrocession).toString()
		filter['ofi_id'] = this.filter.ofi_id
		filter['number'] = this.subFilter.number
		filter['label'] = this.subFilter.label
		filter['modes'] = this.getParamSubFilter("modes")
		filter['types'] = this.getParamSubFilter("types")
		filter['start_date'] = `${this.$d(this.filter.start, 'yyyy')}-${this.$d(this.filter.start, 'mm')}-${this.$d(this.filter.start, 'dd')}`
		filter['end_date'] = `${this.$d(this.filter.end, 'yyyy')}-${this.$d(this.filter.end, 'mm')}-${this.$d(this.filter.end, 'dd')}`
		filter['main_usr_id'] = user.isAccountingUser ? this.filter.usr_id : '-1'
		filter['creator_id'] = user.isAccountingUser ? '-1' : this.filter.usr_id
		if (this.isDemo) filter['demo'] = true.toString()

		return filter
	}

	@Watch('filter', {deep: true})
	private initializeInvoices() {
		if (this.searching) return
		if (accounting.isListLoading) return

		this.searching = true
		this.searched = false
		this.invoices = []
		this.allInvoices = []

		let queries: Promise<any>[] = []
		let start = new Date(this.filter.start.getFullYear(), this.filter.start.getMonth(), this.filter.start.getDate());
		let end = new Date(this.filter.end.getFullYear(), this.filter.end.getMonth(), this.filter.end.getDate());
		let lastDayOfMonth = new Date(start.getFullYear(), start.getMonth()+1, 0);
		let index = 1;

		accounting.initializeCounter()
		while (lastDayOfMonth < end) {
			queries.push(accounting.loadInvoices({start: start, end: lastDayOfMonth, ofi_id: this.filter.ofi_id, usr_id: this.filter.usr_id}))
			start = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 1);
			lastDayOfMonth = new Date(start.getFullYear(), start.getMonth()+1, 0);
			index++
		}
		queries.push(accounting.loadInvoices({start: start, end: end, ofi_id: this.filter.ofi_id, usr_id: this.filter.usr_id}))
		this.countQueries = queries.length
		for(let query of queries) {
			query.then((invoices) => {
				this.allInvoices.push(...invoices)
				this.countQueries--
			})
			.catch(() => {
				this.countQueries--
			})
		}
	}

	private applySubFilter(): void {
		if (!!this.allInvoices) {
			accounting.subFilterRequest()
			this.allInvoices.sort(function(invoice1: IInvoice, invoice2: IInvoice) {
				let result: number = invoice2.settlement_date.getFullYear() - invoice1.settlement_date.getFullYear()
				if (result === 0) {
					result = invoice2.settlement_date.getMonth() - invoice1.settlement_date.getMonth()
				}
				if (result === 0) {
					result = invoice2.settlement_date.getDate() - invoice1.settlement_date.getDate()
				}
				if (result === 0) {
					result = parseInt(invoice2.number) - parseInt(invoice1.number)
				}
				return result
			})
			this.invoices = this.allInvoices.filter(this.filterInvoice)
			setTimeout(() => { accounting.subFilterSuccess() }, 333)
		}

		this.searched = true
	}

	private filterInvoice(invoice: IInvoice): boolean {
		let result: boolean = true

		result = result && this.filterByLabel(invoice)
		result = result && this.filterByNumber(invoice)
		result = result && this.filterByMode(invoice)
		result = result && this.filterByType(invoice)
		result = result && this.filterByReceiptType(invoice)

		return result
	}

	private filterByNumber(invoice: IInvoice): boolean {
		let filter: string = this.subFilter.number.trim().toLowerCase()
		if (!filter) return true

		return !!invoice.number && invoice.number.toLowerCase().includes(filter)
	}

	private filterByLabel(invoice: IInvoice): boolean {
		let filter: string = this.subFilter.label.trim().toLowerCase()
		if (!filter) return true

		return !!invoice.label && invoice.label.toLowerCase().includes(filter)
	}

	private filterByMode(invoice: IInvoice): boolean {
		let filter: ICheckFilter[] = this.subFilter.modes
		if (!filter) return true

		return filter.filter(f => { return f.checked && (f.id as PaymentMode) === invoice.mode }).length > 0
	}

	private filterByType(invoice: IInvoice): boolean {
		let filter: ICheckFilter[] = this.subFilter.types
		if (!filter) return true

		return filter.filter(f => { return f.checked && (f.id as InvoiceType) === invoice.type }).length > 0
	}

	private filterByReceiptType(invoice: IInvoice): boolean {
		if (invoice.type !== InvoiceType.Receipt) return true

		let filter: ICheckFilter[] = this.subFilter.receiptTypes
		if (!filter) return true

		return 	(this.hasReceiptFilter(ReceiptFilterType.Checked) || !invoice.checked) &&
				(this.hasReceiptFilter(ReceiptFilterType.NotChecked) || !!invoice.checked) &&
				(this.hasReceiptFilter(ReceiptFilterType.Banked) || !invoice.banked) &&
				(this.hasReceiptFilter(ReceiptFilterType.NotBanked) || !!invoice.banked) &&
				(this.hasReceiptFilter(ReceiptFilterType.Retrocession) || !invoice.is_retrocession) &&
				(this.hasReceiptFilter(ReceiptFilterType.NotRetrocession) || !!invoice.is_retrocession)
	}

	private hasReceiptFilter(type: ReceiptFilterType) : boolean {
		return this.subFilter.receiptTypes.filter(f => { return f.checked && (f.id as ReceiptFilterType) === type }).length > 0
	}

	private getParamSubFilter(property: string): string {
		let result: string = ""
		let count: number = 0
		for (let filter of this.subFilter[property]) {
			if (!filter.checked) continue
			result = `${result}${filter.id},`
			count++
		}
		if (count === this.subFilter[property].length) return "all"

		return result
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString() }
		breadcrumb.updateItems([item])
	}
}
