<div>
	<h1>{{ $t('vm.root.statistics.colleagues.main.title') }}</h1>

	<div v-if="isLoading" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.statistics.colleagues.main.loading') }}
		</div>
	</div>

	<div v-else-if="colleagues.length > 0" class="grid-3 vertical-gap marg-v">
		<colleague-item :colleague="colleague" :query="filter.query" :can-unselect="false" v-for="colleague in colleagues" :key="colleague.id"/>
	</div>

	<div v-else class="marg-20 text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.statistics.colleagues.main.none') }}</span>
		</p>
	</div>

</div>
