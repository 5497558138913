export const aside = {
    informations: {
        title: "Mettez à jour vos informations",
        label: "Informations"
    },
    formations: {
        title: "Modifiez vos différentes formations",
        label: "Formation | Formation | Formations"
    },
    organizations: {
        title: "Modifiez vos différentes associations",
        label: "Association | Association | Associations"
    },
    email: {
        title: "Modifiez votre email",
        label: "Email"
    },
    password: {
        title: "Modifiez votre mot de passe",
        label: "Mot de passe"
    },
    referral: {
        title: "Parrainez vos collègues et cumulez des points",
        label: "Parrainage"
    },
    gift: {
        title: "Profitez d'un planning gratuit pendant 12 mois",
        label: "Mois offerts"
    },
    offices: {
        title: "Gérez vos cabinets",
        label: "Cabinet | Cabinet | Cabinets"
    },
    export: {
        title: "Exportez toutes vos données en CSV",
        label: "Exporter les données",
        'action-label': "exporter", 
        'action-title': "Export de vos données", 
        'action-content': "Cette opération peut prendre du temps, vous recevrez un mail avec lien pour télécharger votre export. Voulez-vous continuer ?",
        succeeded: "Votre demande d'exportation a bien été prise en compte. Vous recevrez un mail, dans quelques instants, à <strong>{email}</strong.",
        failed: "Votre demande d'exportation a échouée. Veuillez réessayer plus tard, ou nous contacter."
    }
}