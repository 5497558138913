import Render from '@Views/root/statistics/accounting/accounting.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, StatisticsActionPayload, IStatisticsFilter } from '@Store/types'
import { Statistics, Debouncer } from '@Models/index'
import { PaymentMode } from '@Enums/index'
import { DateHelper, EnumsHelper } from '@Helpers/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class Accounting extends Vue {

	private first: boolean = true
	private stats: IStatsAccounting = null
	private debouncerEvolution1: Debouncer = null
	private debouncerEvolution2: Debouncer = null
	//#region Getters
	get filter(): IStatisticsFilter {
		return statistics.filter
	}
	get isLoading(): boolean {
		return this.first && statistics.isLoading
	}
	get legends(): {label: string, color: string}[] {
		return [
			{label: 'recettes', color: '#00C376'},
			{label: 'dépenses', color: '#ff4560'},
			{label: 'immobilisations', color: '#fea019'}
		]
	}
	get modeLegends(): {label: string, color: string}[] {
		let legends: {label: string, color: string}[] = []
		for (let mode in PaymentMode) {
			let id: number = Number(mode)
			if (isNaN(id)) continue

			legends.push({label: EnumsHelper.paymentToString(id as PaymentMode), color: Accounting.paymentToColor(id as PaymentMode)})
		}
		return legends
	}
	get ratioSeries(): number[] {
		return !!this.stats && !!this.stats.ratio ? Object.keys(this.stats.ratio).map(id => { return this.stats.ratio[id] }) : []
	}
	get ratioChartOptions(): any {
		return {
			chart: {
				type: 'donut',
				fontFamily: 'inherit',
				foreColor: 'inherit'
			},
			dataLabels: {
				enabled: false
			},
			labels: this.modeLegends.map(l => { return l.label }),
			colors: this.modeLegends.map(l => { return l.color }),
			legend: {
				show: false
			},
			tooltip: {
				y: {
					formatter: (val: number) => {
						let total: number = this.ratioSeries.reduce((a, b) => a + b, 0)
						let percent: number = Math.round(val * 100 / total)
						return `${val} (${percent} %)`
					}
				}
			}
		}
	}

	get evolutionSeries1(): any[] {
		return [{
			name: this.legends[0].label,
			data: this.receiptEvolution1
		}, {
			name: this.legends[1].label,
			data: this.spendEvolution1
		}, {
			name: this.legends[2].label,
			data: this.immobilizationEvolution1
		}]
	}
	get evolutionSeries2(): any[] {
		return [{
			name: this.legends[0].label,
			data: this.receiptEvolution2
		}, {
			name: this.legends[1].label,
			data: this.spendEvolution2
		}, {
			name: this.legends[2].label,
			data: this.immobilizationEvolution2
		}]
	}
	get evolutionChartOptions(): any {
		return {
			chart: {
				type: 'area',
				zoom: {
					enabled: false
				}
			},
			colors: this.legends.map(l => { return l.color }),
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				categories: DateHelper.getMonthsName()
			},
			legend: {
				show: false
			}
		}
	}

	private get receiptEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._0 ? [] : this.filterSeries(this.stats.evolution1._0)
	}
	private get spendEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._1 ? [] : this.filterSeries(this.stats.evolution1._1)
	}
	private get immobilizationEvolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._2 ? [] : this.filterSeries(this.stats.evolution1._2)
	}

	private get receiptEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._0 ? [] : this.filterSeries(this.stats.evolution2._0)
	}
	private get spendEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._1 ? [] : this.filterSeries(this.stats.evolution2._1)
	}
	private get immobilizationEvolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._2 ? [] : this.filterSeries(this.stats.evolution2._2)
	}

	//#endregion

	mounted() {
		this.debouncerEvolution1 = new Debouncer(this.updateEvolution1, 500)
		this.debouncerEvolution2 = new Debouncer(this.updateEvolution2, 500)
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'accounting')
		this.initializeBreadcrumb()

		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			year1: this.filter.year1,
			year2: this.filter.year2,
			update: false
		}
		statistics.loadAccountingStatistics(payload)
		.then(stats => {
			this.stats = stats
			this.first = false
		})
	}

	getTotalSeries1(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries1.find(e => { return e.name === legend}))
	}

	getTotalSeries2(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries2.find(e => { return e.name === legend}))
	}

	public static paymentToColor(mode: PaymentMode): string {
		switch (mode) {
			case PaymentMode.Check:
				return "#08f"
			case PaymentMode.CreditCard:
				return "#0cd"
			case PaymentMode.FreeAct:
				return "#0d2"
			case PaymentMode.Moneo:
				return "#dd0"
			case PaymentMode.Cash:
				return "#d90"
			case PaymentMode.Transfer:
				return "#f42"
			case PaymentMode.Other:
				return "#e1a"
			case PaymentMode.Waiting:
				return "#91e"
			default:
				return ""
		}
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerEvolution1)
		Debouncer.clear(this.debouncerEvolution2)
	}

	@Watch('filter.ofi_id')
	updateYears() {
		this.updateStatistics(this.filter.year1, this.filter.year2)
	}

	@Watch('filter.year1')
	updateYear1() {
		this.debouncerEvolution1.start()
	}

	@Watch('filter.year2')
	updateYear2() {
		this.debouncerEvolution2.start()
	}

	private filterSeries(serie: number[]): number[] {
		if (!this.filter.months) return serie

		let result: number[] = []
		for(let i: number = 0; i < this.filter.months.length; i++) {
			result.push(this.filter.months[i] ? serie[i] : 0)
		}
		return result
	}

	private updateStatistics(year1: number, year2: number) {
		let payload: StatisticsActionPayload = {
			ofi_id: this.filter.ofi_id,
			year1: !!year1 ? year1 : -1,
			year2: !!year2 ? year2 : -1,
			update: true
		}
		statistics.loadAccountingStatistics(payload)
		.then((stats) => {
			if (!!year1) this.stats.evolution1 = stats.evolution1
			if (!!year2) this.stats.evolution2 = stats.evolution2
		})
	}

	private updateEvolution1() {
		this.updateStatistics(this.filter.year1, undefined)
	}

	private updateEvolution2() {
		this.updateStatistics(undefined, this.filter.year2)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

interface IStatsAccounting {
	evolution1: {
		_0: number[],
		_1: number[],
		_2: number[],
	},
	evolution2: {
		_0: number[],
		_1: number[],
		_2: number[],
	},
	ratio: {
		_0: number,
		_1: number,
		_2: number,
		_3: number,
		_4: number,
		_5: number,
		_6: number,
		_7: number
	}
}
