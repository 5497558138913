<context>
    <div class="wrapper marg-v">
        <section class="marg-v text-center" v-if="isLoading">
            <spinner class="svg-xl text-primary"/>
        </section>
        <collapsible-panel v-else :key="office.id" v-for="office in offices" collapsible-class="marg-v">
            <span slot="title" class="text-uppercase-first-letter">{{ office.name }}</span>
            <span slot="right" class="text-xs">{{ $tc('vm.root.co-working.context.users', links(office.id).length) }}</span>

            <link-item :link="link" :key="link.id" v-for="link in links(office.id)"/>

            <div v-if="links(office.id).length === 0" class="marg-v text-center">
                <svg width="1em" class="text-primary svg-xl" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
                </svg>
                <p>
                    <span class="text-lg">{{ $t('vm.root.co-working.context.no-account.title') }}</span><br>
                    {{ $t('vm.root.co-working.context.no-account.text') }}
                </p>
            </div>

        </collapsible-panel>

    </div>
</context>
