export enum MemberType {
    None = 0,
    Father_Mother = 1,
    Brother_Sister = 2,
    Son_Daughter = 3,
    Nephew_Niece = 4,
    Uncle_Aunt = 5,
    Husband_Wife = 6,
    GrandFather_GrandMother = 7,
    GrandSon_GrandDaughter = 8,
    GreatGrandFather_GreatGrandMother = 9,
    GreatGrandSon_GreatGrandDaughter = 10,
}

export enum MemberException {
    None = 0,
    Same = 1,
    Already = 2
}