import Render from '@Views/root/shop/bulk/steps/step1.html'
import {Component} from 'vue-property-decorator'
import {PackType} from '@Enums/purchase'
import {PayStep} from './pay-step'
import {AutoSaverState} from '@Enums/auto-save'
import {Paying} from '@ViewModels/root/shop/paying'

@Render
@Component({
	components: {
		'paying': Paying,
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Step1 extends PayStep {
	constructor() {
		super('bulk', false)
	}

	mounted() {
		super.initializeValue()
		this.state = !!this.bulk && parseInt(this.bulk.id) !== -1 ? AutoSaverState.Updated : this.state
	}

	//#region PayStep implementation
	getType(): PackType.Bulk1 {
		return PackType.Bulk1
	}
	//#endregion

	submitPay() {
		super.submit()
	}
}
