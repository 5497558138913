<container :loading="isLoading" :loading-title="$t('vm.root.shop.bulk.main.loading')">

	<aside-main></aside-main>

	<master>
		<central>

			<div class="wrapper marg-v">
				<h1>{{ $t('vm.root.shop.bulk.main.title') }} : {{ title }}</h1>
				<div v-if="!canBuy">{{ $t('vm.root.shop.bulk.main.cant-buy') }}</div>
				<step1 v-else-if="isStep1"></step1>
				<step2 v-else-if="isStep2"></step2>
				<step3 v-else-if="isStep3"></step3>
				<step4 v-else></step4>
			</div>
		</central>

		<context-main></context-main>

	</master>

</container>

