import {cloneDeep, isEqual} from 'lodash'
import {Common} from './common'
import {i18n} from '@I18n/index'
import {router} from '@Router/index'
import {ICurriculum, ILinkUser, INotification, INotificationAction, IUser} from '@Store/types'
import {Profile} from '@Enums/index'
import {UserModule} from '@Store/modules'

export class User {

	public static maxCurriculumLength: number = 250

	public static createNewUser(): IUser {
		return {
			id: '',
			coordinates: {
				last_name: '',
				first_name: '',
				siren: ''
			},
			email: '',
			links: [],
			profile: Profile.Substitute,
			planning_date: new Date(),
			formations: null,
			organizations: null
		}
	}

	public static linkUserToUser(user: ILinkUser): IUser {
		return {
			id: user.id,
			coordinates: {
				last_name: user.coordinates.last_name,
				first_name: user.coordinates.first_name,
				siren: ''
			},
			email: '',
			links: [],
			profile: user.profile,
			planning_date: new Date(),
			formations: null,
			organizations: null
		}
	}

	public static userToLinkUser(user: IUser): ILinkUser {
		return {
			id: user.id,
			activate: [],
			last_name: user.coordinates.last_name,
			first_name: user.coordinates.first_name,
			email: user.email,
			coordinates: {
				last_name: user.coordinates.last_name,
				first_name: user.coordinates.first_name
			},
			profile: user.profile
		}
	}

	public static getCurrentUserId(user: UserModule, usr_id?: string): string {
		if (!!usr_id) return usr_id

		return !!user.currentUser && user.currentUser.id
	}

	public static createNewCurriculum(createId: string): ICurriculum {
		return {
			id: '-1',
			create_id: createId,
			// is_new: true,
			name: '',
			period: '',
			description: ''
		}
	}

	public static isLuhnNum(str: string, expectedLength: number = 9) {
		//longueur de la chaine $num
		let length = str.length
		if (length === 0) return false
		if (length !== expectedLength) return false

		//resultat de l'addition de tous les chiffres
		let total = 0
		for (let i = length - 1; i >= 0; i--) {
			let digit = parseInt(str.charAt(i))
			if ((length - i) % 2 === 0) {
				digit = digit * 2
				digit = digit > 9 ? digit-9 : digit
			}

			total += digit
		}

		return total % 10 === 0
	}

	public static getUser(user: IUser, oldUser: IUser): IUser {
		let newUser = cloneDeep(user)
		newUser['update'] = !isEqual(newUser.coordinates, oldUser.coordinates)
		Common.updateItemList(newUser.formations.currents, oldUser.formations.currents)
		Common.updateItemList(newUser.organizations.currents, oldUser.organizations.currents)

		return newUser
	}

	public static getUpdateList(user:IUser): string[] {
		let result: string[] = []
		if (user['update']) {
			result.push(i18n.t("models.user.coordinates-saved").toString())
		}

		Common.addMessagesNotification(result, User.getNotificationList(user, 'formations', "formation", true))
		Common.addMessagesNotification(result, User.getNotificationList(user, 'organizations', "organization", true))

		return result
	}

	public static getNotification(user:IUser): INotification {
		let message: string = i18n.t("models.user.user-saved").toString()
		let messages: string[] = User.getUpdateList(user)
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		return {message: message, delay: 5000, actions: [], canClose: true}
	}

	public static getErrorNotification(user:IUser): INotification | false {
		let messages: string[] = []
		let action: INotificationAction = undefined
		if (User.hasErrorInformations(user)) {
			messages.push(i18n.t("models.user.coordinates-incomplete").toString())
			action = {label: i18n.t("models.user.complete").toString(), callback: () => router.push({name: 'user-informations'})}
		}
		let organizationCount: number = User.hasErrorOrganizations(user)
		if (organizationCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('user', 'incomplete', 'organization', organizationCount, true))
			if (!action)
				action = {label: i18n.t("models.user.complete").toString(), callback: () => router.push({name: 'user-organizations'})}
		}
		let formationCount: number = User.hasErrorFormations(user)
		if (formationCount > 0) {
			Common.addMessageNotification(messages, Common.getMessageNotification('user', 'incomplete', 'formation', formationCount, true))
			if (!action)
				action = {label: i18n.t("models.user.complete").toString(), callback: () => router.push({name: 'user-formations'})}
		}

		if (messages.length === 0) return false

		let message: string = i18n.t("models.user.user-incomplete").toString()
		for(let item of messages) {
			message = `${message} - ${item}`
		}

		let actions: INotificationAction[] = !!action ? [action] : []
		return {message: message, delay: false, actions: actions, canClose: true}
	}

	private static hasErrorInformations(user: IUser): boolean {
		return !user.coordinates.last_name || !user.coordinates.first_name || (!!user.coordinates.siren && !User.isLuhnNum(user.coordinates.siren))
	}

	private static hasCurriculumError(curriculum: ICurriculum): boolean {
		return !curriculum.name || !curriculum.name.trim() || (!!curriculum.description && User.maxCurriculumLength < curriculum.description.trim().length)
	}

	private static hasErrorOrganizations(user: IUser): number {
		let count: number = 0
		for(let organization of user.organizations.currents) {
			if (User.hasCurriculumError(organization)) {
				organization.error = true
				count++
			} else if (organization.error !== undefined) {
				organization.error = false
			}
		}

		return count
	}

	private static hasErrorFormations(user: IUser): number {
		let count: number = 0
		for(let formation of user.formations.currents) {
			if (User.hasCurriculumError(formation)) {
				formation.error = true
				count++
			} else if (formation.error !== undefined) {
				formation.error = false
			}
		}

		return count
	}

	private static getNotificationList(user:IUser, list: string, label: string, female: boolean = false): string[] {
		let countUpdate: number = 0
		let countCreate: number = 0
		let countDeleted: number = user[list].deleted.filter(item => parseInt(item.id) !== -1).length

		for(let item of user[list].currents) {
			if (item['update']) countUpdate++
			else if (parseInt(item.id) === -1) countCreate++
		}

		let result: string[] = []
		Common.addMessageNotification(result, Common.getMessageNotification('user', 'updated', label, countUpdate, female))
		Common.addMessageNotification(result, Common.getMessageNotification('user', 'added', label, countCreate, female))
		Common.addMessageNotification(result, Common.getMessageNotification('user', 'deleted', label, countDeleted, female))

		return result
	}
}
