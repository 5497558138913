import Render from '@Views/root/calendar/event/relaunch.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { INotificationAction, INotification, IBreadcrumbItem, IEvent, IRelaunch } from '@Store/types'
import { PlanningType, PackType, ReminderType } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { IDropdownItem } from '@Components/index'
import { breadcrumb, notif, user } from '@Store/modules'

@Render
@Component({})
export class Relaunch extends Vue {

    disabled: boolean = false
    private relaunchs: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]

    @Prop() event: IEvent
    @Prop() saved: boolean
    @Prop() prefix: string
    @Prop() errors: {name: string, inError: boolean}[]

    get relaunch(): IRelaunch {
        return this.event.relaunch
    }
    get isClassic(): boolean {
        return this.event.type === PlanningType.Classic
    }
    get useActivePlanning(): boolean {
        return !user.user.no_planning && !!user.user.planning_date && user.user.planning_date > new Date()
    }
    get isRelaunchActive(): boolean {
        return this.relaunch.type !== ReminderType.None
    }
    get relaunchItems(): IDropdownItem[] {
        let result: IDropdownItem[] = []
        this.relaunchs.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
	    return result
    }
    get hasDelayError(): boolean {
        return !!this.saved && !this.isValidDelay
    }
    private get isValidDelay(): boolean {
        return !this.isRelaunchActive || (!!this.relaunch.delay && parseInt(this.relaunch.delay.toString()) > 0)
    }

    mounted() {
        this.initializeBreadcrumb()
        this.notifications()
    }

    beforeDestroy() {
        notif.clear()
    }

    @Watch('isRelaunchActive')
    @Watch('relaunch.delay')
    updateError() {
        let result: boolean = !this.isValidDelay
        let name: string = this.$router.currentRoute.name
        let error = this.errors.find(error => error.name === name)
        if (!error) {
            this.errors.push({name: name, inError: result})
        } else {
            error.inError = result
        }
    }

    @Watch('event.patient')
    private updatePatient() {
        if (!this.event.patient) {
            this.patientNotification()
            return
        }

        this.disabled = false
        notif.clear()
    }

    private notifications() {
        if (!this.useActivePlanning) {
            this.purchaseNotification()
            return
        }

        this.updatePatient()
    }

    private purchaseNotification() {
        this.disabled = true
        let purchaseAction: INotificationAction = {
            label: this.$i18n.t('vm.root.calendar.event.relaunch.notification.purchase.label').toString(),
            callback: () => { this.$router.push({name: 'purchase-pack', params: {pack: PackType.Planning.toString()}}) }
        }
        let notification: INotification = {
            message: this.$i18n.t('vm.root.calendar.event.relaunch.notification.purchase.message').toString(),
            actions: [purchaseAction],
            canClose: true,
            delay: false
        }
        notif.info(notification)
    }

    private patientNotification() {
        this.disabled = true
        let currentRoute = this.$router.currentRoute
        let routeName: string = currentRoute.name.replace('-relaunch', '')

        let patientAction: INotificationAction = {
            label: this.$i18n.t('vm.root.calendar.event.relaunch.notification.patient.label').toString(),
            callback: () => { this.$router.push({name: routeName, params: this.$route.params}) }
        }
        let notification: INotification = {
            message: this.$i18n.t('vm.root.calendar.event.relaunch.notification.patient.message').toString(),
            actions: [patientAction],
            canClose: true,
            delay: false
        }
        notif.info(notification)
    }

    private initializeBreadcrumb(): void {
        let label = user.isSecretaryUser
            ? this.$i18n.t('vm.root.breadcrumb.plannings-secretary', {first_name: user.currentUser.coordinates.title, last_name: user.currentUser.coordinates.last_name}).toString()
            : this.$i18n.t('vm.root.breadcrumb.plannings-main').toString()

        let item1: IBreadcrumbItem = { label: label, link: "plannings"}
        let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.event').toString(), link: {name: `${this.prefix}event`, params: this.$route.params} }
        let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.event-relaunch').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
