import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IExportFilter, PatientSearchPayload, IPatientSearch } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import {PatientService} from "@Services/index"
import { StorageHelper } from "@Helpers/index"
import { Backup, Patient } from "@Models/index"

@Module({
	name: 'patients',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class PatientsModule extends VuexModule {
	statusSearch: RequestStatus = RequestStatus.None
	statusReinitialize: RequestStatus = RequestStatus.None
    filterExport: IExportFilter = StorageHelper.get('export-filter', Patient.getDefaultExportFilter())

	get isSearching(): boolean {
		return this.statusSearch === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.statusSearch = RequestStatus.None
		this.statusReinitialize = RequestStatus.None
		this.filterExport = StorageHelper.get('export-filter', Patient.getDefaultExportFilter())
	}

	@Mutation
	searchRequest() {
		this.statusSearch = RequestStatus.Loading
	}

	@Mutation
	searchSuccess() {
		this.statusSearch = RequestStatus.Success
	}

	@Mutation
	searchFailure() {
		this.statusSearch = RequestStatus.Failed
	}

	@Mutation
	reinitializeRequest() {
		this.statusReinitialize = RequestStatus.Loading
	}

	@Mutation
	reinitializeSuccess() {
		this.statusReinitialize = RequestStatus.Success
	}

	@Mutation
	reinitializeFailure() {
		this.statusReinitialize = RequestStatus.Success
	}

	@Action({rawError: true})
	async reinitialize(): Promise<any> {
		if (this.statusReinitialize === RequestStatus.Loading) return

		this.reinitializeRequest()

		let patientService = new PatientService()

		return patientService.reinitialize()
		.then(() => {
			this.reinitializeSuccess()
			return Promise.resolve()
		})
		.catch(error => {
			this.reinitializeFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async searchPatients(payload: PatientSearchPayload): Promise<any> {
		if (this.statusSearch === RequestStatus.Loading) return

		this.searchRequest()

		let patientService = new PatientService()

		return patientService.searchPatient(payload.query, payload.filter)
		.then(patients => {
			this.searchSuccess()
			return Promise.resolve(patients)
		})
		.catch(error => {
			let patients:IPatientSearch[]  = Backup.getInstance().search(payload.query, payload.filter)
			if (!!patients && patients.length > 0) {
				this.searchSuccess()
				return Promise.resolve(patients)
			} else {
				this.searchFailure()
				return Promise.reject(error)
			}
		})
	}

	@Action({rawError: true})
	async searchExport(payload: { filter: IExportFilter, page: number, with_list: boolean }): Promise<any> {
		if (this.statusSearch === RequestStatus.Loading) return

		this.searchRequest()

		let patientService = new PatientService()

		return patientService.searchExport(payload.filter, payload.page, payload.with_list)
		.then(patients => {
			this.searchSuccess()
			return Promise.resolve(patients)
		})
		.catch(error => {
			this.searchFailure()
			return Promise.reject(error)
		})
	}
}

export const patients = getModule(PatientsModule)
