import Render from '@Views/root/accounting/immobilization/details.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IToggleBoxItem, IDropdownItem, InputDateType } from '@Components/index'
import { IOffice, IBreadcrumbItem } from '@Store/types'
import { DampingType, PaymentMode } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb, office } from '@Store/modules'

@Render
@Component({})
export class Details extends Vue {

	@Prop() immobilization: any
	@Prop() submitted: boolean

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('office/offices') offices: () => IOffice[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean

	//#region Getters
	get hasLabelError(): boolean {
		return this.submitted && !this.immobilization.label
	}
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		let ofi_id: string = parseInt(this.immobilization.ofi_id) === -1 ? undefined : this.immobilization.ofi_id
		let offices: IOffice[] = office.offices
		for (let _office of offices) {
			if (!office.isActive(_office) && _office.id !== ofi_id) continue

			result.push({value: _office.id, label: _office.coordinates.name})
		}

		return result
	}
	get dateType(): InputDateType.Calendar {
		return InputDateType.Calendar
	}
	get paymentModeItems(): IToggleBoxItem[] {
		return [
			{value: PaymentMode.Check, label: EnumsHelper.paymentToString(PaymentMode.Check)},
			{value: PaymentMode.Cash, label: EnumsHelper.paymentToString(PaymentMode.Cash)},
			{value: PaymentMode.CreditCard, label: EnumsHelper.paymentToString(PaymentMode.CreditCard)},
			{value: PaymentMode.Moneo, label: EnumsHelper.paymentToString(PaymentMode.Moneo)},
			{value: PaymentMode.Transfer, label: EnumsHelper.paymentToString(PaymentMode.Transfer)},
			{value: PaymentMode.Other, label: EnumsHelper.paymentToString(PaymentMode.Other)}
		]
	}
	get dampingTypeItems(): IToggleBoxItem[] {
		return [
			{value: DampingType.Linear, label: EnumsHelper.dampingTypeToString(DampingType.Linear)},
			{value: DampingType.Degressive, label: EnumsHelper.dampingTypeToString(DampingType.Degressive)},
			{value: DampingType.Exceptional, label: EnumsHelper.dampingTypeToString(DampingType.Exceptional)}
		]
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.immobilization').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}