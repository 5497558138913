export const main = {
    holidays: "Le cabinet sera fermé du {start} au {end}.",
    'current-holidays': "Le cabinet est fermé jusqu'au {end}.",
    today: {
        title: "Aujourd'hui",
        label: "aujourd'hui"
    },
    next: {
        title: "Suivant",
        label: "suivant"
    },
    previous: {
        title: "Précédent",
        label: "précédent"
    },
    legend: {
        'to-valid': "À valider",
        missed: "Lapin",
        unavailable: "Indisponible"
    },
    'new-event': {
        title: "Créer un nouveau rendez-vous",
        label: "Nouveau rendez-vous"
    },
    'refresh': {
        title: "Actualisez vos calendriers",
        label: "Actualiser le planning"
    },
    view: {
        day: "Jour",
        week: "Semaine", 
        month: "Mois"
    },
    'no-editable': "Ce rendez-vous n'est pas modifiable.",
    'event-updated': "Votre rendez-vous a bien été modifié.",
    'no-planning': "Cet utilisateur n'a pas de planning. Impossible pour vous de lui ajouter un rendez-vous.",
    'no-user': "Vous devez sélectionner un utilisateur avant de pouvoir ajouter un rendez-vous.",
    create: {
        label: "Créer un planning",
        message: "Vous devez ajouter au moins un planning pour créer un rendez-vous."
    }
}