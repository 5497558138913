export enum AccountingPeriod {
	Monthly = 'month',
	Yearly = 'year'
}

export enum AccountingAction {
	Print	= 1,
	Export	= 2,
	Check	= 3,
	Bank	= 4,
	Delete	= 5
}
