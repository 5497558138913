<section class="person-item">
	<router-link class="person-item-header text-capitalize text-inherit" :to="{name:'patient-coordinates', params: {pat_id: member.link_id}}"
		:title="$t('vm.root.search.member.item.title', {'first-name': member.first_name, 'last-name': member.last_name})">
		<figure class="person-item-avatar">
			<avatar :first-name="member.first_name" :last-name="member.last_name" :email="member.email" :gender="member.gender"/>
		</figure>
		<h3 class="h-reset person-item-name"><anonymous :text="member.first_name"/><br><anonymous tag="b" :text="member.last_name"/></h3>
	</router-link>
	<div class="person-item-border text-primary childs-space-between">
		{{ parseInt(member.type) | member(member.gender) }}

		<span>
			<span v-if="!!member.birth_date">{{ member.birth_date | age }}</span>
			<i v-else>{{ $t('vm.root.search.member.item.no-date') }}</i><br>
			<span v-if="member.deceased" class="patient-deceased">{{ $t('vm.root.search.member.item.deceased') }}</span>
		</span>
	</div>
	<div v-if="!readOnly && isMainUser" class="person-item-border text-right">
		<button type="button" class="btn btn--link-like text-danger" @click="unselect"
			:title="$t('vm.root.search.member.item.unselect.title')">{{ $t('vm.root.search.member.item.unselect.label') }}</button>
	</div>
</section>
