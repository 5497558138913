export const main = {
    delete: {
        label: "supprimer",
        title: "Suppression des documents",
        content: "Vous êtes sur le point de ne rien supprimer supprimer. Voulez-vous continuer ? | Vous êtes sur le point de supprimer une ligne. Voulez-vous continuer ? | Vous êtes sur le point de supprimer {count} lignes. Voulez-vous continuer ?",
    },
    print: {
        label: "envoyer",
        content: "L'impression d'un grand nombre de lignes nécessite du temps. Ce document vous sera donc envoyé. Voulez-vous le recevoir ?",
        success: "Le document vous sera donc envoyé, par mail à <strong>{email}</strong>, dès qu'il sera disponible. Pensez à vérifier vos spams.",
        failed: "Votre demande n'a pu être prise en compte, réessayez dans quelques instants.",
    },
    actions: {
        'print-title': "Imprimer le tableau",
        'print-label': "imprimer le tableau",
        'export-title': "Exporter le tableau",
        'export-label': "exporter le tableau",
        'check-title': "Passer les lignes sélectionnées à réglées",
        'check-label': "régler {count} sélection | régler {count} sélection | régler {count} sélections",
        'uncheck-title': "Passer les lignes sélectionnées à non réglées",
        'uncheck-label': "dépointer {count} sélection | dépointer {count} sélection | dépointer {count} sélections",
        'bank-title': "Passer les lignes sélectionnées à déposées",
        'bank-label': "déposer {count} sélection | déposer {count} sélection | déposer {count} sélections",
        'unbank-title': "Passer les lignes sélectionnées à non déposées",
        'unbank-label': "reprendre {count} sélection | reprendre {count} sélection | reprendre {count} sélections",
        'delete-title': "Effacer les lignes sélectionnées",
        'delete-label': "supprimer {count} sélection | supprimer {count} sélection | supprimer {count} sélections"
    },
    title: "Finances",
    filter: {
        title: "Filtrez vos finances",
        more: "filtrer",
        less: "cacher les filtres"
    },
    loading: "Chargement en cours…",
    none: {
        title: "Aucun résultat.",
        text: "Vous devriez peut-être {0}.",
        button: "modifier votre filtre"
    },
    'select-all': {
        title:" Sélectionnez toutes les lignes",
        label: "Tout sélectionner / désélectionner"
    },
    columns: {
        label: "Libellé",
        number: "Numéro",
        date: "Date",
        mode: "Mode",
        state: "État",
        spend: "Dépense",
        receipt: "Recette",
        tva: "TVA",
        bank: "Information bancaire"
    },
    select: "Sélectionner / Désélectionner",
    'link-title': {
        receipt: "Voir le détail de cette recette",
        spend: "Voir le détail de cette dépense",
        immobilization: "Voir le détail de cette immobilisation",
    },
    state: {
        recurrency: "Récurrence",
        checked: "Réglée",
        banked: "Déposée"
    },
    'print-receipt': "Imprimer cette recette",
    'delete-line': {
        title: "Suppression de cette ligne",
        'delete-msg': "Voulez-vous vraiment supprimer cette ligne ?",
        label: "Supprimer"
    },
    total: {
        receipts: "Recettes",
        spends: "Dépenses",
        balance: "Balance",
        ht: "HT",
        ttc: "TTC"
    }
}
