import Render from '@Views/root/patients/patient/consultation/walton.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {IBreadcrumbItem, IConsultation, IWalton} from '@Store/types'
import { Drawer } from '@Components/index'
import { breadcrumb } from '@Store/modules'
import { i18n } from "@I18n/index"

@Render
@Component({
	components: {
		'drawer': Drawer
	}
})
export class Walton extends Vue {

	marks: number[] = [0, 1, 2, 3, 4]
	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	//#region Getters
	get walton(): IWalton {
		return this.consultation.walton
	}
	get radarSeries(): any[] {
		return [
			{
				name: this.$i18n.t('vm.root.patients.patient.walton.level').toString(),
				data: [
					this.walton.data_1,
					this.walton.data_2,
					this.walton.data_3,
					this.walton.data_4,
					this.walton.data_5,
					this.walton.data_6,
				]
			},
		]
	}
	get radarChartOptions(): any {
		return {
			plotOptions: {
				radar: {
					polygons: {
						strokeColor: 'var(--default-border-color)',
						fill: {
							colors: [
								'var(--walton-level-5)',
								'var(--walton-level-4)',
								'var(--walton-level-3)',
								'var(--walton-level-2)',
								'var(--walton-level-1)'
							]
						}
					}
				}
			},
			tooltip: {
				y: {
					formatter: function (value) {
						return i18n.t(`vm.root.patients.patient.walton.level-${value + 1}`).toString()
					}
				},
				x: {
					formatter: function(value, { dataPointIndex }) {
						return i18n.t(`vm.root.patients.patient.walton.walton-${dataPointIndex + 1}`).toString()
					}
				}
			},
			markers: {
				size: 5,
				hover: {
					size: 10
				}
			},
			xaxis: {
				categories: [
					this.$i18n.t('vm.root.patients.patient.walton.walton-1').toString(),
					this.$i18n.t('vm.root.patients.patient.walton.walton-2').toString(),
					this.$i18n.t('vm.root.patients.patient.walton.walton-3').toString(),
					this.$i18n.t('vm.root.patients.patient.walton.walton-4').toString(),
					this.$i18n.t('vm.root.patients.patient.walton.walton-5').toString(),
					this.$i18n.t('vm.root.patients.patient.walton.walton-6').toString(),
				],
				labels: {
					show: true,
					formatter: (value, data) => {
						return `${data.dataPointIndex + 1}`
					}
				},
			},
			yaxis: {
				min: 0,
				max: 4,
				labels: {
					show: false,
					formatter: () => {
						return ''
					}
				}
			},
		}
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
	}

	tooltipFormatter(v: number): string {

		switch (v) {
			case 1:
				return this.$i18n.t('vm.root.patients.patient.walton.level-2').toString()
			case 2:
				return this.$i18n.t('vm.root.patients.patient.walton.level-3').toString()
			case 3:
				return this.$i18n.t('vm.root.patients.patient.walton.level-4').toString()
			case 4:
				return this.$i18n.t('vm.root.patients.patient.walton.level-5').toString()
			case 0:
				return this.$i18n.t('vm.root.patients.patient.walton.level-1').toString()
			default:
				return ''
		}
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.walton').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}
