import * as login from './viewmodels/login/index'
import * as breadcrumb from './viewmodels/root/breadcrumb/index'
import * as coWorking from './viewmodels/root/co-working/index'
import * as patients from './viewmodels/root/patients/index'
import * as antecedent from './viewmodels/root/patients/patient/antecedent/index'
import * as attachment from './viewmodels/root/patients/patient/attachment/index'
import * as timeline from './viewmodels/root/patients/patient/timeline/index'
import * as consultation from './viewmodels/root/patients/patient/consultation/index'
import * as pediatrics from './viewmodels/root/patients/patient/pediatrics/index'
import * as exclusionTest from './viewmodels/root/patients/patient/exclusion-test/index'
import * as sphere from './viewmodels/root/patients/patient/sphere/index'
import * as patient from './viewmodels/root/patients/patient/index'
import * as search from './viewmodels/root/search/index'
import * as components from './viewmodels/components/index'
import * as models from './models/index'
import * as managers from './managers/index'
import * as event from './viewmodels/root/calendar/event/index'
import * as validEvent from './viewmodels/root/calendar/valid-event/index'
import * as planning from './viewmodels/root/calendar/planning/index'
import * as accounting from './viewmodels/root/accounting/index'
import * as calendar from './viewmodels/root/calendar/index'
import * as home from './viewmodels/root/home/index'
import * as help from './viewmodels/root/help/index'
import * as statistics from './viewmodels/root/statistics/index'
import * as parameters from './viewmodels/root/parameters/index'
import * as document from './viewmodels/root/parameters/document/index'
import * as letters from './viewmodels/root/letters/index'
import * as shop from './viewmodels/root/shop/index'
import * as user from './viewmodels/root/user/index'
import * as userOffice from './viewmodels/root/user/office/index'
import * as userGift from './viewmodels/root/user/gift/index'
import { general } from './general'
import { enumsValues } from './enums'
import { unitsValues } from './units'
import { dateTimeFormats } from './date'
import { restricted } from './viewmodels/root/restricted'
import { main } from './viewmodels/root/main'

export const fr = {
    messages: {
        models: {
            configuration: models.configuration,
            connection: models.connection,
            document: models.document,
            patient: models.patient,
            office: models.office,
            queue: models.queue,
            user: models.user
        },
        managers: {
            'invoice-manager': managers.invoiceManager,
            'quick-event-manager': managers.quickEventManager
        },
        vm: {
            components: {
                breadcrumb: {
                    breadcrumb: components.breadcrumb,
                    fullscreen: components.fullscreen,
                    'more-info': components.moreInfo
                },
                layout: {
                    aside: components.aside,
                    context: components.context,
                    header: components.header,
                    navigation: components.navigation,
                    notification: components.notification
                },
                popin: {
                    'create-colleague': components.createColleague,
                    'create-patient': components.createPatient,
                    'create-receipt': components.createReceipt,
                    'sticky-note-share': components.stickyNoteShare,
                    'refuse-event': components.refuseEvent,
                    'rgpd-patient': components.rgpdPatient,
                    'search-colleague': components.searchColleague,
                    'search-patient': components.searchPatient,
                    'unpaid-invoice': components.unpaidInvoice,
                    'print-patient': components.printPatient,
                    'share-referral': components.shareReferral,
                    communication: components.communication 
                },
                'super-panel': {
                    'merge': components.superPanelMerge,
                    'quick-event': components.superPanelQuickEvent
                },
                frame: {
                    'input-phone-frame': components.inputPhoneFrame,
                    'textarea-frame': components.textareaFrame,
                },
                input: {
                    'input-range': components.inputRange,
                },
                'full-calendar': components.fullCalendar,
                'auto-saver': components.autoSaver,
                drawer: components.drawer,
                'button-to-top': components.buttonToTop,
                recurring: components.recurring,
                schedule: components.schedule,
                'collapsible-help': components.collapsibleHelp,
            },
            login: {
                main: login.main,
                login: login.login,
                'no-account': login.noAccount,
                authenticate: login.authenticate,
                register: login.register,
                loader: login.loader
            },
            root: {
                breadcrumb: breadcrumb.main,
                accounting: {
                    book: {
                        aside: accounting.asideBook,
                        context: accounting.contextBook,
                        main: accounting.mainBook
                    },
                    remittances: {
                        aside: accounting.asideRemittances,
                        context: accounting.contextRemittances,
                        main: accounting.mainRemittances
                    },
                    remittance: {
                        aside: accounting.asideRemittance,
                        context: accounting.contextRemittance,
                        main: accounting.mainRemittance
                    },
                    immobilization: {
                        aside: accounting.asideImmobilization,
                        details: accounting.detailsImmobilization,
                        main: accounting.mainImmobilization
                    },
                    receipt: {
                        aside: accounting.asideReceipt,
                        details: accounting.detailsReceipt,
                        main: accounting.mainReceipt
                    },
                    spend: {
                        aside: accounting.asideSpend,
                        details: accounting.detailsSpend,
                        main: accounting.mainSpend
                    },
                    aside: accounting.aside,
                    context: accounting.context,
                    main: accounting.main
                },
                calendar: {
                    event: {
                        relaunch: event.relaunch,
                        aside: event.aside,
                        main: event.main,
                        recurrency: event.recurrency,
                        'rendez-vous': event.rendezVous,
                    },
                    planning: {
                        aside: planning.aside,
                        context: planning.context,
                        main: planning.main,
                    },
                    'valid-event': {
                        aside: validEvent.aside,
                        main: validEvent.main,
                    },
                    aside: calendar.aside,
                    'planning-item': calendar.planningItem,
                    'context-main': calendar.contextMain,
                    'context-secretary': calendar.contextSecretary,
                    main: calendar.main,
                },
                home: {
                    'context-news': home.contextNews,
                    main: home.main,
                    'sticky-note': home.stickyNote,
                    widget: home.widget,
                    'widget-referral': home.widgetReferral,
                    communication: home.communication 
                },
                help: {
                    aside: help.aside,
                    context: help.context,
                    new: help.newTicket,
                    no: help.noTicket,
                    ticket: help.ticket,
                    main: help.main,
                },
                letters: {
                    context: letters.context,
                    main: letters.main,
                },
                patients: {
                    main: patients.main,
                    export: patients.exportation,
                    merge: patients.merge,
                    campaigns: patients.campaigns,
                    campaign: patients.campaign,
                    aside: patients.aside,
                    context: patients.context,
                    'patient-search-item': patients.patientSearchItem,
                    'patient-item': patients.patientItem,
                    patient: {
                        context: patient.context,
                        aside: patient.aside,
                        'aside-consultation': consultation.aside,
                        'aside-pediatrics': pediatrics.aside,
                        antecedent: antecedent.main,
                        'family-antecedent': antecedent.familyAntecedent,
                        attachment: attachment.main,
                        'attachment-item': attachment.attachmentItem,
                        'timeline-item': timeline.timelineItem,
                        cranio: consultation.cranio,
                        examens: consultation.examens,
                        schema: consultation.schema,
                        walton: consultation.walton,
                        tests: consultation.tests,
                        'follow-up': consultation.followUp,
                        'read-only': consultation.readOnly,
                        forms: patient.forms,
                        consultation: consultation.main,
                        'test-item': exclusionTest.main,
                        reason: consultation.reason,
                        'pediatrics-main': pediatrics.antecedents,
                        neonatal: pediatrics.neonatal,
                        sphere: sphere.main,
                        family: patient.family,
                        antecedents: patient.antecedents,
                        attachments: patient.attachments,
                        consultations: patient.consultations,
                        coordinates: patient.coordinates,
                        documents: patient.documents,
                        main: patient.main,
                        relaunch: patient.relaunch,
                        spheres: patient.spheres
                    }
                },
                parameters: {
                    document: {
                        aside: document.aside,
                        context: document.context,
                        main: document.main
                    },
                    accounting: parameters.accounting,
                    aside: parameters.aside,
                    'follow-up': parameters.followUp,
                    forms: parameters.forms,
                    general: parameters.general,
                    main: parameters.main,
                    notifications: parameters.notifications,
                    patient: parameters.patient,
                    planning: parameters.planning,
                    relaunch: parameters.relaunch,
                    reminder: parameters.reminder,
                    confirmation: parameters.confirmation,
                },
                'co-working': {
                    context: coWorking.context,
                    aside: coWorking.aside,
                    steps: {
                        step1: coWorking.step1,
                        step2: coWorking.step2,
                        step3: coWorking.step3,
                    }
                },
                search: {
                    colleague: {
                        item: search.colleagueItem,
                        search: search.searchColleague,
                    },
                    member: {
                        item: search.memberItem,
                        search: search.searchMember,
                    },
                    patient: {
                        item: search.patientItem,
                        search: search.searchPatient,
                        create: search.createPatient,
                    },
                },
                shop: {
                    bulk: {
                        aside: shop.asideBulk,
                        context: shop.contextBulk,
                        main: shop.mainBulk
                    },
                    purchase: {
                        aside: shop.asidePurchase,
                        context: shop.contextPurchase,
                        main: shop.mainPurchase
                    },
                    context: shop.context,
                    main: shop.main,
                    paying: shop.paying,
                    'pack-item': shop.packItem,
                    'purchase-item': shop.purchaseItem,
                    packs: shop.packs
                },
                statistics: {
                    accounting: {
                        main: statistics.accountingMain,
                        context: statistics.accountingContext
                    },
                    patients: {
                        main: statistics.patientsMain,
                        context: statistics.patientsContext
                    },
                    reference: {
                        main: statistics.referenceMain
                    },
                    top10: {
                        main: statistics.top10Main
                    },
                    consultations: {
                        main: statistics.consultationsMain,
                        context: statistics.consultationsContext
                    },
                    planning: {
                        main: statistics.planningMain,
                        context: statistics.planningContext
                    },
                    'planning-online': {
                        main: statistics.planningOnlineMain,
                        context: statistics.planningOnlineContext
                    },
                    colleagues: {
                        main: statistics.colleaguesMain,
                        context: statistics.colleaguesContext,
                        'details-colleague': statistics.detailsColleague,
                        'aside-colleague': statistics.asideColleague,
                        'context-colleague': statistics.contextColleague,
                        'main-colleague': statistics.mainColleague
                    },
                    aside: statistics.aside
                },
                user: {
                    office: {
                        access: userOffice.access,
                        session: userOffice.session,
                        absence: userOffice.absence,
                        accesses: userOffice.accesses,
                        aside: userOffice.aside,
                        'aside-schedule': userOffice.asideSchedule,
                        coordinates: userOffice.coordinates,
                        context: userOffice.context,
                        main: userOffice.main,
                        parameters: userOffice.parameters,
                        'main-schedule': userOffice.mainSchedule,
                        schedule: userOffice.specificSchedule,
                        sessions: userOffice.sessions,
                    },
                    gift: {
                        gift1: userGift.gift1,
                        gift2: userGift.gift2,
                        gift3: userGift.gift3,
                        bonus: userGift.bonus,
                        home: userGift.home,
                        aside: userGift.aside,
                        main: userGift.main,
                    },
                    informations: user.informations,
                    formations: user.formations,
                    organizations: user.organizations,
                    formation: user.formation,
                    organization: user.organization,
                    account: user.account,
                    aside: user.aside,
                    'context-main': user.contextMain,
                    'context-referral': user.contextReferral,
                    email: user.email,
                    referral: user.referral,
                    password: user.password,
                    offices: user.offices,
                },
                main: main,
                restricted: restricted
            }
        },
        enums: enumsValues,
        units: unitsValues,
        general: general,
    },
    dateTimeFormats: dateTimeFormats
}
