import config from "@Config/index"

export class Favico {

    private static FAVICON_SIZE: number = 16

    private static instance: Favico = null

    private favicon: HTMLLinkElement = null
    private favicon2: HTMLLinkElement = null
    private _badge: number = 0

    public static getInstance(): Favico {
        this.instance = !this.instance ? new Favico() : this.instance
        return this.instance
    }

    private constructor() {
        this.favicon = document.getElementById('favicon') as HTMLLinkElement
        this.favicon2 = document.getElementById('favicon2') as HTMLLinkElement
    }

    public badge(value: number): void {
        this._badge = value
        this.initialize()
    }

    private initialize(): void {
        if (!this.favicon) return

        let canvas: HTMLCanvasElement = document.createElement('canvas')
        canvas.width = Favico.FAVICON_SIZE
        canvas.height = Favico.FAVICON_SIZE

        let context: CanvasRenderingContext2D = canvas.getContext('2d')
        let img: HTMLImageElement = document.createElement('img')
        img.src = `${config.use_storage ? '/src' : ''}/assets/favicons/favicon-32x32.png`
        img.onload = () => {
            this.onLoad(this.favicon, img, canvas, context)
            this.onLoad(this.favicon2, img, canvas, context)
        }
    }

    private drawBadge(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
        // Draw Notification Circle
        context.beginPath()
        context.arc(canvas.width - Favico.FAVICON_SIZE / 3 , canvas.height - Favico.FAVICON_SIZE / 3, Favico.FAVICON_SIZE / 3, 0, 2 * Math.PI)
        context.fillStyle = '#FF0000'
        context.fill()

        // Draw Notification Number
        context.font = 'bold 10px Arial'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        context.fillStyle = '#FFF'
        context.fillText(`${this._badge >= 10 ? '+' : this._badge}`, canvas.width - Favico.FAVICON_SIZE / 3, canvas.height - Favico.FAVICON_SIZE / 3 + 1)
    }

    private onLoad(favicon: HTMLLinkElement, img: HTMLImageElement, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D): void {
        // Draw Original Favicon as Background
        context.drawImage(img, 0, 0, Favico.FAVICON_SIZE, Favico.FAVICON_SIZE)

        if (this._badge > 0) {
            this.drawBadge(canvas, context)
        }

        // Replace favicon
        favicon.href = canvas.toDataURL('image/png')
    }
}
