import Render from '@Views/root/calendar/event/recurrency.html'
import { isEqual, cloneDeep } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Recurring } from '@Components/index'
import { IEvent, IRecurring, IBreadcrumbItem, INotification, INotificationAction } from '@Store/types'
import { PlanningType } from '@Enums/index'
import { breadcrumb, notif, planning, user } from '@Store/modules'

@Render
@Component({
    components: {
        'recurring': Recurring
    }
})
export class Recurrency extends Vue {

    private readonly: boolean = true
    private oldRecurring: IRecurring

    @Prop() event: IEvent
    @Prop() prefix: string

    get recurring(): IRecurring {
        return this.event.recurring
    }
    get disabled(): boolean {
        return this.readonly
    }
    get canAccessRecurrency(): boolean {
		return this.currentType === PlanningType.Classic
	}
	private get currentType(): PlanningType {
		const { pla_id } = this.event
		return planning.planningType(pla_id)
	}

    mounted() {
        this.initializeBreadcrumb()
        this.updateFromRecId()
    }

    beforeDestroy() {
        notif.clear()
    }

    private setOldRecurring(recurring: IRecurring): any {
        if (!recurring) return

        if (!!this.oldRecurring) return

        this.oldRecurring = cloneDeep(recurring)
    }

    @Watch('recurring', {deep: true})
    updateRecurring(value: IRecurring) {
        this.event.recurring = value
        if (isEqual(this.event.recurring, this.oldRecurring)) return

        this.event.recurring_update = true
    }

    @Watch('event.from_rec_id')
    updateFromRecId() {
        this.readonly = this.canAccessRecurrency && !!this.event.from_rec_id && parseInt(this.event.from_rec_id) !== -1
        if (!this.readonly) return

        this.displayWarning()
    }

    private displayWarning() {
        let continueAction: INotificationAction = {
            label: this.$i18n.t('general.recurrency-warning.continue').toString(),
            callback: () => {
                this.setOldRecurring(this.recurring)
                this.readonly = false
            }
        }
        let cancelAction: INotificationAction = {
            label: this.$i18n.t('general.recurrency-warning.cancel').toString(),
            callback: () => { this.$router.back() }
        }
        let notification: INotification = {
            message: this.$i18n.t('general.recurrency-warning.message').toString(),
            actions: [continueAction, cancelAction],
            canClose: false,
            delay: false
        }
        notif.warning(notification)
    }

    private initializeBreadcrumb(): void {
        let label = user.isSecretaryUser ?
            this.$i18n.t('vm.root.breadcrumb.plannings-secretary', {first_name: user.currentUser.coordinates.first_name, last_name: user.currentUser.coordinates.last_name}).toString() :
            this.$i18n.t('vm.root.breadcrumb.plannings-main').toString()

        let item1: IBreadcrumbItem = { label: label, link: "plannings"}
        let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.event').toString(), link: {name: `${this.prefix}event`, params: this.$route.params} }
        let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.recurrency').toString() }
        breadcrumb.updateItems([item1, item2, item3])
	}
}
