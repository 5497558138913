import { IFullCalendarEvent } from '@Components/full-calendar'
import { IGoogleEvent } from './google-event'
import { EventHelper } from './event'
import { PlanningType } from '@Enums/index'
import { IEvent } from '@Store/types'
import { DateHelper } from './date'
import { ColorHelper } from './color'

const google_colors = [
	{ code: '-1', hex: '#9A9CFF' },
	{ code: '0', hex: '#5484ed' },
	{ code: '1', hex: '#a4bdfc' },
	{ code: '2', hex: '#46d6db' },
	{ code: '3', hex: '#7ae7bf' },
	{ code: '4', hex: '#51b749' },
	{ code: '5', hex: '#fbd75b' },
	{ code: '6', hex: '#ffb878' },
	{ code: '7', hex: '#ff887c' },
	{ code: '8', hex: '#dc2127' },
	{ code: '9', hex: '#dbadff' },
	{ code: '10', hex: '#e1e1e1' }
]

export class FullCalendarEventHelper {
	public static convertEventToFullCalendarEvent(entry: any | IGoogleEvent, colors?: Record<string, string>, editable?: boolean):  IFullCalendarEvent {
		if (entry === undefined) return undefined
		if (EventHelper.isGoogleEvent(entry)) {
			return FullCalendarEventHelper.convertGoogleItemToFullCalendarEvent(entry)
		}
		return FullCalendarEventHelper.convertClassicItemToFullCalendarEvent(entry, colors, editable);
	}

	public static convertFullCalendarEventToItem(entry: IFullCalendarEvent): IEvent {
		if (entry === undefined) return undefined
		const start: Date = new Date(entry.start)
		const end: Date = new Date(!entry.end && entry.allDay ? entry.start : entry.end)

		return {
			id: entry.id,
			missed: entry.missed,
			unvalid: entry.unvalid,
			label: entry.title,
			start_date: new Date(entry.allDay ? DateHelper.getDateString(start) : start.toISOString().split('.')[0] + 'Z'),
			end_date: new Date(entry.allDay ? DateHelper.getDateString(end) : end.toISOString().split('.')[0] + 'Z'),
			all_day: entry.allDay,
			type: entry.type,
			old_type: entry.type,
			patient: entry.patient,
			ofi_id: entry.ofi_id,
			ses_id: entry.ses_id,
			usr_id: entry.usr_id,
			pla_id: entry.pla_id,
			confirmation_type: entry.confirmation_type,
			reminder_type: entry.reminder_type,
			reminder_mail: null,
			reminder_sms: null,
			recurring_update: false
		}
	}

	private static convertClassicItemToFullCalendarEvent(entry: IEvent, colors: Record<string, string>, editable?: boolean): IFullCalendarEvent {
		const color = entry.with_color && !!entry.color ? entry.color : colors[entry.pla_id]
		const start: Date = new Date(entry.start_date)
		const end: Date = new Date(entry.end_date)

		return {
			id: entry.id,
			missed: entry.missed === undefined ? false : entry.missed,
			unvalid: entry.unvalid === undefined ? false : entry.unvalid,
			reminder_type: entry.reminder_type,
			confirmation_type: entry.confirmation_type,
			title: entry.label,
			start: entry.all_day ? DateHelper.getDateString(start) : start.toISOString().split('.')[0] + 'Z',
			end: entry.all_day ? DateHelper.getDateString(end) : end.toISOString().split('.')[0] + 'Z',
			allDay: entry.all_day === undefined ? false : entry.all_day,
			editable: editable,
			color: color,
			type: PlanningType.Classic,
			textColor: ColorHelper.isDark(color) ? 'white' : 'black',
			patient: entry.patient,
			ses_id: entry.ses_id,
			ofi_id: entry.ofi_id,
			usr_id: entry.usr_id,
			pla_id: entry.pla_id
		}
	}

	private static convertGoogleItemToFullCalendarEvent(entry: IGoogleEvent): IFullCalendarEvent {
		if (entry === undefined) return undefined
		if (entry.status === 'cancelled') return undefined

		const event = {
			id: entry.id,
			missed: EventHelper.isMissed(entry),
			title: entry.summary,
			color: FullCalendarEventHelper.getCalendarColor(entry),
			textColor: entry.calendar.textColor,
			color_id: entry.colorId,
			editable: entry.calendar === undefined ? true : entry.calendar.editable,
			location: entry.location,
			description: entry.description,
			type: PlanningType.Google,
			unvalid: false,
			pla_id: entry.calendar.id,
			ses_id: EventHelper.getSesId(entry),
			ofi_id: EventHelper.getOfiId(entry),
			reminder_mail: EventHelper.getReminderMail(entry),
			reminder_sms: EventHelper.getReminderSms(entry),
			reminder_type: EventHelper.getReminderType(entry),
			confirmation_type: EventHelper.getConfirmationType(entry),
			patient: undefined,
			start: undefined
		}

		if (!EventHelper.updateDateFields(event, entry, 'start', 'end', 'allDay')) return event

		event.patient = EventHelper.getPatient(entry)

		return event
	}

	private static getCalendarColor = function(entry: any): string {
		const color = entry.calendar === undefined || entry.calendar.color === undefined ? google_colors[0].hex : entry.calendar.color

		if (entry.colorId === undefined) return color

		for (let i = 0; i < google_colors.length; i++) {
			if (entry.colorId !== google_colors[i].code) continue
			return google_colors[i].hex
		}

		return color
	}
}
