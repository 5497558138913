<div>
	<h1>{{ $t('vm.root.statistics.reference.main.title') }}</h1>

	<div v-if="isLoading" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.statistics.reference.main.loading') }}
		</div>
	</div>

	<div v-else>
		<h2>{{ $t('vm.root.statistics.reference.main.chart-title') }}</h2>
		<apexchart type="bar" :options="countReferenceChartOptions" :series="countReference"/>
	</div>

</div>
