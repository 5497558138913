export const noAccount = {
    title: "Adresse email inconnue",
	description1: "Nous n'avons trouvé aucun type de compte attaché à l'adresse {email}.",
	description2: {
        text: "Assurez-vous d'avoir bien saisi votre adresse email. Si vous n'avez pas encore de compte, {0} gratuitement.",
        link: "inscrivez-vous",
        title: "Inscrivez-vous sur Osteo2ls"
    },
    demo: "Mais on a aussi indiqué, la page d'avant que, pour cette version de démonstration, il fallait s'identifier avec l'adresse mail <strong>demo@osteo2ls.com</strong>, donc bon, on est cohérent ;)",
	previous: {
        title: "Retour à la page précédente",
        text: 'précédent'
    },
    register: {
        title: "Inscrivez-vous sur Osteo2ls",
        text: 'inscription'
    }
}

export const authenticate = {
    title: "Sélection d'un profil",
    description1: "Nous avons trouvé un compte correspondant à l'adresse <strong>{email}</strong>. | Nous avons trouvé {n} comptes correspondant à l'adresse <strong>{email}</strong>.",
    description2: {
        text: "Sélectionnez le profil avec lequel vous connecter :",
        error: "Merci de sélectionner un profil"
    },
    password: {
        label: "Mot de passe",
        error: "Merci de renseigner votre mot de passe",
        placeholder: "ex : m07_d3_p4553!",
        title: "Veuillez renseigner votre mot de passe"
    },
    previous: {
        title: "Retour à la page précédente",
        text: 'précédent'
    },
    login: {
        title: "Connectez-vous à Osteo2ls",
        text: 'connexion',
        'failed-406': "Ce compte n'est rattaché à aucun cabinet, vous ne pouvez pas vous y connecter.",
        failed: "Accès refusé ! Votre mot de passe est erroné."
    },
    'lost-login': {
        text: "Mot de passe oublié ?",
        new: "En recevoir un nouveau",
        title: "Recevoir un nouveau mot de passe",
        success: "Votre nouveau mot de passe vient de vous être envoyé à l'adresse <strong>{email}</strong>. Pensez à vérifier dans vos spams.",
        failed: "Votre mot de passe n'a pas pu être réinitialisé."
    }
}