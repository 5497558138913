import Render from '@Views/components/forms/read-only-forms.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IForm } from '@Store/types'
import { FormHelper } from '@Helpers/index'
import { FormSummary } from './form-summary'

@Render
@Component({
	components: {
		'form-summary': FormSummary
	}
})
export class ReadOnlyForms extends Vue {

    @Prop() forms: IForm[]
    @Prop({default: true}) withWhiteSpace: boolean

    get isEmpty(): boolean {
		for(let form of this.forms) {
			if (!FormHelper.isEmpty(form)) return false
		}
		return true
	}
}