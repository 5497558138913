import Render from '@Views/root/parameters/planning.html'
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { IConfiguration, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem, InputRangeType } from '@Components/index'
import { breadcrumb, user } from '@Store/modules'
import {DateHelper} from '@Helpers/date'

@Render
@Component({})
export class Planning extends Vue {

	weekdays: {index: number, min: string, normal: string}[] = DateHelper.getDaysName()
	displayDays: number[] = [1, 2, 3, 4, 5, 6, 0]

	@Prop() configuration: IConfiguration

	//#region getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get rangeType(): InputRangeType.RangeTime {
		return InputRangeType.RangeTime
	}
	get range(): Date[] {
		return [new Date(this.configuration.min_time), new Date(this.configuration.max_time)]
	}
	set range(range: Date[]) {
		this.configuration.min_time = range[0]
		this.configuration.max_time = range[1]
	}
	get slots(): IDropdownItem[] {
		return [
			{value: 10, label: this.$i18n.tc('vm.root.parameters.planning.minute', 10).toString()},
			{value: 15, label: this.$i18n.tc('vm.root.parameters.planning.minute', 15).toString()},
			{value: 30, label: this.$i18n.tc('vm.root.parameters.planning.minute', 30).toString()}
		]
	}
	get delays(): IDropdownItem[] {
		return [
			{label:this.$i18n.tc('vm.root.parameters.planning.each-minute', 15).toString(), value:900000},
			{label:this.$i18n.tc('vm.root.parameters.planning.each-minute', 30).toString(), value:1800000},
			{label:this.$i18n.tc('vm.root.parameters.planning.each-minute', 45).toString(), value:2700000},
			{label:this.$i18n.tc('vm.root.parameters.planning.each-minute', 60).toString(), value:3600000}
		]
	}
	//#endregion

	created() {
		this.displayDays = this.displayDays.filter(day => { return !this.configuration.hidden_days.includes(day) })
	}

	mounted() {
		this.$emit('navigate', 'planning')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	@Watch('displayDays')
	updateHiddenDays() {
		let hiddenDays: number[] = [1, 2, 3, 4, 5, 6, 0]
		this.configuration.hidden_days = hiddenDays.filter(day => { return !this.displayDays.includes(day) })
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.planning').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
