import Render from '@Views/components/input/input-address.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AddressService } from '@Services/index'
import { Common, Debouncer } from '@Models/index'

@Render
@Component({})
export class InputAddress extends Vue {
    
    display: boolean = false
    canDisplaySuggestions: boolean = false
    suggestions: any[] = []
    street: string = ''
    selectedItem: any = {value: null, label: ''}
    searching: boolean = false
    private selectedIndex: number = null
    private selecting: boolean = false
    private updating: boolean = false
    private changing: boolean = false
    private debouncer: Debouncer
    private addressService: AddressService
    private latitude: number
    private longitude: number
    private random: string = ''
    
    @Prop() address: IAddress
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) required: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: "ex : 221b Baker Street, London NW1 6XE"}) placeholder: string
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string

    get displaySuggestions(): boolean {
        return this.canDisplaySuggestions && this.changing
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }

    mounted() {
        this.addressService = new AddressService()

        this.debouncer = new Debouncer(this.searchAddress, 333)
        this.$emit('onValidate', this.address)
    }

    created() {
		this.random = Common.generateId()
        this.updateAddress()
    }

    beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

    onInput(): void {}

    onFocus(): void {
        this.canDisplaySuggestions = true
        this.geolocation()
    }

    onBlur(): void {
        setTimeout(() => {
            this.canDisplaySuggestions = false
            this.changing = false
        }, 100)
    }

    keyboardNav(e: any): void {
        if (!this.suggestions || this.suggestions.length === 0) return

        let list: HTMLElement = this.$refs.list as HTMLElement
        if (list === undefined) return

        switch(e.key) {
            case "Down":
            case "ArrowDown":
                e.preventDefault()
                this.keyDown(list)
                break
            case "Up":
            case "ArrowUp":
                e.preventDefault()
                this.keyUp(list)
                break
            case "Enter":
                e.preventDefault()
                this.keyEnter()
                break
            case "End":
                e.preventDefault()
                this.keyEnd(list, this.suggestions.length -1)
                break
            case "Home":
                e.preventDefault()
                this.keyHome(list, 0)
                break
            default:
                break
        }
    }

    reset(): void {
        let index = this.suggestions.indexOf(this.selectedItem)
        this.selectedIndex = index === -1 ? null : index
        this.canDisplaySuggestions = false
    }

    selectAddress(suggestion: any): void {
        if (!suggestion || !suggestion.properties) return

        this.selecting = true
        this.address.number = suggestion.properties.housenumber
        this.address.street = suggestion.properties.street || suggestion.properties.name
        this.address.zipcode = suggestion.properties.postcode
        this.address.city = suggestion.properties.city
        this.address.country = 'France'

        this.address.formatted = this.getFormatAddress()
        this.street = this.address.formatted
        setTimeout(() => {this.selecting = false}, 100)
        
        this.selectedItem = suggestion
        this.canDisplaySuggestions = false
    }

    @Watch('address.formatted')
    updateFormattedAddress() {
        if (!!this.address.formatted) return

        this.address.city = ''
        this.address.country = ''
        this.address.zipcode = ''
        this.address.street = ''
        this.address.number = ''
    }

    @Watch('address.city')
    @Watch('address.country')
    @Watch('address.zipcode')
    @Watch('address.street')
    @Watch('address.number')
    private updateAddress() {
        // this.initializeCountry()
        if (!this.address) return

        this.updating = true
        this.street = this.getFormatAddress()
        setTimeout(() => {this.updating = false}, 100)
    }

    @Watch('street')
    updateStreet() {
        this.changing = true
        this.address.formatted = this.street
        if (this.selecting || this.updating) return

        this.suggestions = []
        this.selectedIndex = null
        this.debouncer.start()
    }

    private searchAddress(): void {
        this.searching = true
        this.addressService.get(this.street, this.latitude, this.longitude).then(response => {
            this.suggestions = response.features
            this.searching = false
        })
    }

    private getFormatAddress(): string {
        let formatted: string = this.address.number ? `${this.address.number} ` : ''
        formatted += this.address.street ? this.address.street : ''
        formatted += this.address.zipcode ? `, ${this.address.zipcode} ` : ''
        formatted += this.address.city ? this.address.city : ''
        formatted += this.address.country ? ', '+this.address.country : ''

        return formatted.trim()
    }

    private geolocation() {
		navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError)
    }
    
	private geolocationSuccess(position) {
		this.latitude = position.coords.latitude
		this.longitude = position.coords.longitude
    }
    
	private geolocationError(error) {
		this.latitude = undefined
		this.longitude = undefined
    }
    
    private keyEnter() {
        if (this.selectedIndex === null) return
        this.selectAddress(this.suggestions[this.selectedIndex])
    }

    private keyUp(list: HTMLElement) {
        let index = !!this.selectedIndex ? Math.max(0, this.selectedIndex - 1) : this.suggestions.length - 1

        this.keyEnd(list, index)
    }

    private keyDown(list: HTMLElement) {
        let index = this.selectedIndex === null ? -1 : this.selectedIndex
        index = index === this.suggestions.length - 1 ? 0 : Math.min(this.suggestions.length - 1, index + 1)

        this.keyHome(list, index)
    }

    private keyHome(list: HTMLElement, index: number) {
        this.removeFocus(list)
        this.canDisplaySuggestions = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.addFocus(selectedElement)
        if (selectedElement.offsetTop === 0) {
            list.scrollTop = 0
            return
        }
        if (selectedElement.offsetTop + selectedElement.clientHeight <= list.scrollTop + list.clientHeight) return

        list.scrollTop = selectedElement.offsetTop - list.clientHeight + selectedElement.clientHeight
    }

    private keyEnd(list: HTMLElement, index: number) {
        this.removeFocus(list)
        let oldIndex = this.selectedIndex
        this.canDisplaySuggestions = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.addFocus(selectedElement)
        if (!oldIndex && index === list.children.length - 1) {
            list.scrollTop = list.clientHeight + 2 * selectedElement.clientHeight
            return
        }
        if (selectedElement.offsetTop >= list.scrollTop) return

        list.scrollTop = selectedElement.offsetTop
    }

    private addFocus(element: HTMLElement) {
        if (!element.children || element.children.length === 0) return
        (element.children[0] as HTMLElement).classList.add('is-focus')
    }

    private removeFocus(list: HTMLElement) {
        if (this.selectedIndex === null || this.selectedIndex === -1) return
        let element: HTMLElement = list.children[this.selectedIndex] as HTMLElement

        if (!element.children || element.children.length === 0) return
        (element.children[0] as HTMLElement).classList.remove('is-focus')
    }
}

export interface IAddress {
    id: string,
    number: string,
    street: string,
    zipcode: string,
    city: string,
    country: string,
    formatted: string,
    error?: boolean
}