<div class="l-loading text-center">
	<div class="wrapper">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question"/>
		</svg>
		<p>
			<strong class="text-lg text-primary">{{ $t('vm.root.help.no.title') }}</strong>
		</p>
		<i18n path="vm.root.help.no.text" tag="p">
			<span v-if="!closed">{{ $t('vm.root.help.no.create-new') }}</span>
		</i18n>
		<i18n path="vm.root.help.no.group-fb" tag="p">
			<a href="//facebook.com/groups/198195551042934/" target="_blank" :title="$t('vm.root.help.no.link-title')">
				{{ $t('vm.root.help.no.link-label') }}
			</a>
		</i18n>
	</div>
</div>
