import Render from '@Views/root/accounting/spend/details.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IDropdownItem, IToggleBoxItem, InputDateType } from '@Components/index'
import { IOffice, IBreadcrumbItem, IInvoice } from '@Store/types'
import { PaymentMode, SpendType } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb, office } from '@Store/modules'

@Render
@Component({})
export class Details extends Vue {

	@Prop() spend: IInvoice
	@Prop() submitted: boolean

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('office/offices') offices: () => IOffice[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean

	//#region Getters
	get hasLabelError(): boolean {
		return this.submitted && !this.spend.label
	}
	get dateType(): InputDateType.Calendar {
		return InputDateType.Calendar
	}
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		let ofi_id: string = parseInt(this.spend.ofi_id) === -1 ? undefined : this.spend.ofi_id
		let offices: IOffice[] = office.offices
		for (let _office of offices) {
			if (!office.isActive(_office) && _office.id !== ofi_id) continue

			result.push({value: _office.id, label: _office.coordinates.name})
		}

		return result
	}
	get paymentModeItems(): IToggleBoxItem[] {
		return [
			{value: PaymentMode.Check, label: EnumsHelper.paymentToString(PaymentMode.Check)},
			{value: PaymentMode.Cash, label: EnumsHelper.paymentToString(PaymentMode.Cash)},
			{value: PaymentMode.CreditCard, label: EnumsHelper.paymentToString(PaymentMode.CreditCard)},
			{value: PaymentMode.Moneo, label: EnumsHelper.paymentToString(PaymentMode.Moneo)},
			{value: PaymentMode.Transfer, label: EnumsHelper.paymentToString(PaymentMode.Transfer)},
			{value: PaymentMode.Other, label: EnumsHelper.paymentToString(PaymentMode.Other)}
		]
	}
	get spendTypeItems(): IToggleBoxItem[] {
		return [
			{value: SpendType.SpendType0, label: EnumsHelper.spendTypeToString(SpendType.SpendType0)},
			{value: SpendType.SpendType1, label: EnumsHelper.spendTypeToString(SpendType.SpendType1)},
			{value: SpendType.SpendType2, label: EnumsHelper.spendTypeToString(SpendType.SpendType2)},
			{value: SpendType.SpendType3, label: EnumsHelper.spendTypeToString(SpendType.SpendType3)},
			{value: SpendType.SpendType4, label: EnumsHelper.spendTypeToString(SpendType.SpendType4)},
			{value: SpendType.SpendType5, label: EnumsHelper.spendTypeToString(SpendType.SpendType5)},
			{value: SpendType.SpendType6, label: EnumsHelper.spendTypeToString(SpendType.SpendType6)},
			{value: SpendType.SpendType7, label: EnumsHelper.spendTypeToString(SpendType.SpendType7)},
			{value: SpendType.SpendType8, label: EnumsHelper.spendTypeToString(SpendType.SpendType8)},
			{value: SpendType.SpendType9, label: EnumsHelper.spendTypeToString(SpendType.SpendType9)},
			{value: SpendType.SpendType10, label: EnumsHelper.spendTypeToString(SpendType.SpendType10)},
			{value: SpendType.SpendType11, label: EnumsHelper.spendTypeToString(SpendType.SpendType11)},
			{value: SpendType.SpendType12, label: EnumsHelper.spendTypeToString(SpendType.SpendType12)},
			{value: SpendType.SpendType13, label: EnumsHelper.spendTypeToString(SpendType.SpendType13)},
			{value: SpendType.SpendType14, label: EnumsHelper.spendTypeToString(SpendType.SpendType14)},
			{value: SpendType.SpendType15, label: EnumsHelper.spendTypeToString(SpendType.SpendType15)},
			{value: SpendType.SpendType16, label: EnumsHelper.spendTypeToString(SpendType.SpendType16)},
			{value: SpendType.SpendType17, label: EnumsHelper.spendTypeToString(SpendType.SpendType17)},
			{value: SpendType.SpendType18, label: EnumsHelper.spendTypeToString(SpendType.SpendType18)},
			{value: SpendType.SpendType19, label: EnumsHelper.spendTypeToString(SpendType.SpendType19)},
			{value: SpendType.SpendType20, label: EnumsHelper.spendTypeToString(SpendType.SpendType20)},
			{value: SpendType.SpendType21, label: EnumsHelper.spendTypeToString(SpendType.SpendType21)},
			{value: SpendType.SpendType22, label: EnumsHelper.spendTypeToString(SpendType.SpendType22)},
			{value: SpendType.SpendType23, label: EnumsHelper.spendTypeToString(SpendType.SpendType23)},
			{value: SpendType.SpendType24, label: EnumsHelper.spendTypeToString(SpendType.SpendType24)},
			{value: SpendType.SpendType25, label: EnumsHelper.spendTypeToString(SpendType.SpendType25)},
			{value: SpendType.SpendType26, label: EnumsHelper.spendTypeToString(SpendType.SpendType26)},
		]
	}
	//#endregion

	mounted() {
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.spend').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}