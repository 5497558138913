import Render from '@Views/root/accounting/spend/aside-spend.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IInvoice } from '@Store/types'
import { accounting } from '@Store/modules'

@Render
@Component({})
export class ASideSpend extends Vue {

  @Prop() spend: IInvoice

  // @State('accounting') stateAccounting: AccountingState
  // @Action('accounting/deleteInvoice') deleteInvoice: (inv_id: string) => Promise<any>
  
  get isDeleting(): boolean {
		return accounting.isDeleting
	}

  submitDelete(): void {
    const { spend } = this
    let route = {name: 'accounting'}

    this.$emit('delete')
    if (!!spend && !!spend.id && parseInt(spend.id) !== -1) {
      accounting.deleteInvoice(spend.id).then(() => {
        this.$router.push(route)
      })
    } else {
      this.$router.push(route)
    }
  }
}