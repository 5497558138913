import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IShop, IPurchase } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { PurchaseService } from "@Services/index"

@Module({
	name: 'shop',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class ShopModule extends VuexModule {
	shop: IShop = null
    purchases: IPurchase[] = []
    private status: RequestStatus = RequestStatus.None
    private statusShop: RequestStatus = RequestStatus.None
    private statusList: RequestStatus = RequestStatus.None

	get isPurchasesLoading(): boolean {
		return this.statusList === RequestStatus.Loading
	}
	
	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusShop = RequestStatus.None
		this.statusList = RequestStatus.None
		this.shop = null
		this.purchases = []
	}

	@Mutation
	clearPurchases() {
		this.statusList = RequestStatus.None
		this.purchases = []
	}

	@Mutation
	shopRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	shopSuccess(shop: IShop) {
		this.status = RequestStatus.Success
		this.shop = shop
	}

	@Mutation
	shopFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	purchasesRequest() {
		this.statusList = RequestStatus.Loading
	}

	@Mutation
	purchasesSuccess(purchases: IPurchase[]) {
		this.statusList = RequestStatus.Success
		this.purchases = [...this.purchases, ...purchases]
	}

	@Mutation
	purchasesFailure() {
		this.statusList = RequestStatus.Failed
	}

	@Mutation
	deletePurchaseSuccess(pur_id: string) {
		this.purchases = this.purchases.filter(p => { return p.id !== pur_id })
	}

	@Action({rawError: true})
	async loadShop(): Promise<any> {
		if (this.statusShop === RequestStatus.Loading) return

		this.shopRequest()

		let service = new PurchaseService()
		return service.getShop()
		.then(shop => {
			this.shopSuccess(shop)
			return Promise.resolve(shop)
		})
		.catch(error => {
			this.shopFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadPurchases(page: number): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.purchasesRequest()

		let service = new PurchaseService()
		return service.getPurchaseList(page)
		.then(
			purchases => {
				this.purchasesSuccess(purchases)
				return Promise.resolve(purchases)
			}
		)
		.catch(error => {
			this.purchasesFailure()
			return Promise.reject(error)
		})
	}
}

export const shop = getModule(ShopModule)
// export const shop = new ShopModule({ store, name: "shop" })