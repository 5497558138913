import Render from '@Views/root/user/gift/aside-gift.html'
import { Component, Vue } from 'vue-property-decorator'
import { IOffice } from '@Store/types'
import { office } from '@Store/modules'

@Render
@Component({})
export class ASideGift extends Vue {

    // @Getter('office/offices') allOffices: () => IOffice[]
    // @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean
    
    get offices() : IOffice[] {
		return office.offices.filter(o => { return office.isActive(o) })
	}
}