export const context = {
    faq: {
        text: "Voici une magnifique {0}. Elle peut, peut-être, déjà répondre à certaines de vos questions.",
        'abbr-label': "FAQ",
        'abbr-title': "Foire Aux Questions"
    },
    'support-unlimited': "Votre support est actif",
    'support-enabled': "Votre support est actif jusq'au {0}",
    'support-disabled': {
        text: "Votre support est expiré depuis le {0}. Pour créer un nouveau ticket, vous devez {1}.",
        'link-title': "Prolongez votre pack maintenance",
        'link-label': "acquérir un pack maintenance"
    },
    filter: {
        placeholder: "Filtrez la FAQ",
        title: "Filtrez la FAQ pour trouver facilement votre question"
    },
    'open-question': "Voir la réponse à cette question"
}