import { BaseService } from '../base.service'
import { IOffice } from '@Store/types'

export class OfficeService extends BaseService {
	
	public getOfficeList(): Promise<any> {
		return super.get('/offices')
	}

	public save(office: IOffice): Promise<any> {
		if (parseInt(office.id) === -1)
			return super.post('/office', office)

		return super.put(`/office/${office.id}`, office)
	}

	public delete(ofi_id: string): Promise<any> {
		return super.delete(`/office/${ofi_id}`)
	}

	public activate(ofi_id: string): Promise<any> {
		return super.put(`/office/activate/${ofi_id}`)
	}

	public getSettingList(): Promise<any> {
		return super.get('/settings')
	}
	public generateUrl(stt_id: string, ofi_id: string): Promise<any> {
		return super.post(`/settings/url/${stt_id}`, {ofi_id: ofi_id})
	}

	public getScheduleList(): Promise<any> {
		return super.get('/schedules')
	}

	public getSessionList(): Promise<any> {
		return super.get('/sessions')
	}
}
