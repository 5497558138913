<div class="input-frame">
    <label class="label" for="search-patient" :data-text="label">{{ label }}</label>

    <div class="form-group">
        <div class="with-icon">
            <svg width="1em" class="with-icon-icon" :class="{'is-shown': !false}" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
            </svg>
            <span class="with-icon-icon" :class="{'is-shown': false}">
                <spinner class="text-primary"/>
            </span>
            <input class="form-group-input" id="search-patient" @click="searchPatient"
                :title="$t('vm.root.search.patient.search.title')"
                :placeholder="$t('vm.root.search.patient.search.placeholder')">
        </div>
        <button v-if="canCreate" type="button" class="input-like form-group-input is-flex-fixed" @click="createPatient"
            :title="$t('vm.root.search.patient.search.create.title')">
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
            </svg>
            <span class="rwd-over-xlarge">{{ $t('vm.root.search.patient.search.create.text') }}</span>
        </button>
    </div>
</div>