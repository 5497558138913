import Render from '@Views/components/collapsible-panel.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class CollapsiblePanel extends Vue {

	private _collapsed: boolean

	@Prop({default: true}) collapsed: boolean
	@Prop() collapsibleClass: string

	created() {
		this._collapsed = this.collapsed
	}
    toggle(): void {
		this._collapsed = !this._collapsed
		this.$forceUpdate()
	}
	isToggle(): boolean {
		return !this._collapsed
	}
}
