import Render from '@Views/login/login.html'
import { Vue, Component } from 'vue-property-decorator'
import { FontSize, Skin } from '@Enums/index'
import { EnumsHelper, StorageHelper } from '@Helpers/index'
import { Backup, Favico } from '@Models/index'
import { login, notif, popIn } from '@Store/modules'

@Render
@Component({})
export class Login extends Vue {

	email: string = ''
	submitted: boolean = false

	get isLoading(): boolean {
		return login.isLogging
	}
	get hasEmailError(): boolean {
		return this.submitted && !this.email
	}
	get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}

	canSubmit(): boolean {
		return !!this.email
	}

	submit() {
		this.submitted = true
		if (!this.canSubmit()) return

		login.getAccounts(this.email)
		.then(() => { this.$router.push({ name: 'authentication' }) })
	}

	created() {
		document.documentElement.classList.remove(Skin.Light)
		document.documentElement.classList.remove(Skin.Dark)
		document.documentElement.classList.add(Skin.Default)

		document.documentElement.classList.remove(EnumsHelper.fontSizeToClass(FontSize.Tiny))
		document.documentElement.classList.remove(EnumsHelper.fontSizeToClass(FontSize.Small))
		document.documentElement.classList.remove(EnumsHelper.fontSizeToClass(FontSize.Large))
		document.documentElement.classList.remove(EnumsHelper.fontSizeToClass(FontSize.Huge))

		document.documentElement.classList.add(EnumsHelper.fontSizeToClass(FontSize.Normal))

		var message = StorageHelper.get('error', null)
		if (!message) return

		notif.error({message: message, actions: [], delay: 5000})
		StorageHelper.remove('error')
	}

	mounted() {
		this.email = login.email
		document.title = 'Osteo2ls'
		Favico.getInstance().badge(0)
		Backup.getInstance().clear()
		popIn.clear()
	}
}
