import Render from '@Views/root/patients/aside-patients.html'
import {Component, Vue, Watch} from 'vue-property-decorator'
import {PatientSearchItem} from './patient-search-item'
import {IPatientFilter, IPatientSearch, IPopin, IPopinAction} from '@Store/types'
import {Debouncer} from '@Models/index'
import {StorageHelper} from '@Helpers/index'
import {configuration, notif, patients, popIn} from '@Store/modules'
import {RequestStatus} from "@Enums/request"

@Render
@Component({
	components: {
		'patient-search-item': PatientSearchItem
	}
})
export class ASidePatients extends Vue {

	private debouncer: Debouncer = null
	searched: boolean = false
	displayFilter: boolean = false
	resultSearch: IPatientSearch[] = []
	query: string = ''
	filter: IPatientFilter = {
		by_first_name: true,
		by_last_name: true,
		by_profession: false,
		by_email: false,
		by_phone: false,
		by_address: false,
		by_birth_date: false
	}

	//#region Getters
	get isSearching(): boolean {
		return patients.statusSearch === RequestStatus.Loading
	}
	get isReinitializing(): boolean {
		return patients.statusReinitialize === RequestStatus.Loading
	}
	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get filterCount(): number {
		let count: number = 0
		for(let key of Object.keys(this.filter)) {
			count = !this.filter[key] ? count + 1 : count
		}
		return count
	}
	//#endregion

	mounted() {
		this.filter = StorageHelper.get('patients-filter', this.filter)
		this.debouncer = new Debouncer(this.search, 500)
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	search(): void {
		this.resultSearch = []
		patients.searchPatients({query: this.query, filter: this.filter})
		.then(results => {
			this.searched = true
			this.resultSearch = results
		})
		.catch(error => {
			this.searched = true
			console.error(error)
		})
	}

	reinitialize(): void {
		let reinitializeAction: IPopinAction = {
			label: this.$i18n.t('vm.root.patients.aside.reinitialize.label').toString(),
			title: this.$i18n.t('vm.root.patients.aside.reinitialize.title').toString(),
			callback: () => {
				patients.reinitialize()
				.then(() => {
					notif.success({
						canClose: true,
						delay: 5000,
						message: this.$i18n.t('vm.root.patients.aside.reinitialize.success').toString(),
						actions: []
					})
				})
				.catch(error => {
					notif.error({
						canClose: true,
						delay: 5000,
						message: this.$i18n.t('vm.root.patients.aside.reinitialize.failed').toString(),
						actions: []
					})
				})

				return Promise.resolve()
			}
		}
		let reinitialize: IPopin = {
			title: this.$i18n.t('vm.root.patients.aside.reinitialize.title').toString(),
			content: this.$i18n.t('vm.root.patients.aside.reinitialize.content').toString(),
			action: reinitializeAction,
		}

		popIn.info(reinitialize)
	}

	@Watch('filter', {deep: true})
	saveFilter() {
		StorageHelper.set('patients-filter', this.filter)
	}

	@Watch('query')
	createDebounce(): void {
		if (!this.query) {
			this.searched = false
			this.resultSearch = []
		}

		this.debouncer.start(!!this.query)
	}
}
