import Render from '@Views/root/accounting/immobilization/main.html'
import { Component } from 'vue-property-decorator'
import { AutoSaver } from '@Components/index'
import { Accounting } from '@Models/index'
import { AutoSaverState } from '@Enums/index'
import { ASideImmobilization } from './aside-immobilization'
import { INotification, IPopinAction, IPopin, IOffice, IInvoice, IAccountingFilter } from '@Store/types'
import { accounting, notif, breadcrumb, office, user } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-immobilization': ASideImmobilization
	},
	beforeRouteLeave(to, from, next) {
		this.beforeRouteLeave(to, from, next)
	}
})
export class Main extends AutoSaver {

	submitted: boolean = false
	errors: {name: string, inError: boolean}[] = []

	constructor() {
		super('immobilization', false)
	}

	//#region Getters
	get immobilization(): IInvoice {
		return accounting.invoice
	}
	get isSaving(): boolean {
		return accounting.isSaving
	}
	get isDeleting(): boolean {
		return accounting.isDeleting
	}
	private get selectedOfficeId(): string {
		return !!this.filter ? this.filter.ofi_id : '-1'
	}
	private get filter(): IAccountingFilter {
		return accounting.filter
	}
	//#endregion

	//#region IAutoSaver implementation
	restoreValue(value: IInvoice): void {
		accounting.invoiceSuccess(value)
	}

	save(): void {
		if (!this.canSave()) return

		const { immobilization } = this
		accounting.saveInvoice({invoice: immobilization})
		.then((newImmobilization: IInvoice) => {
			super.initializeValue()
			let message: string = this.$i18n.t('vm.root.accounting.immobilization.main.create', {number: newImmobilization.number}).toString()
			let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }
			notif.success(notification)

			this.submitted = false

			this.$router.push({name: 'accounting'})
		})
		.catch(() => {
			this.submitted = false
		})
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(false)
		this.initializeImmobilization()
	}

	beforeDestroy() {
		accounting.clearInvoice()
	}

	onDelete() {
		// Appelé quand on efface le rdv depuis l'aside
		// Pour ne pas avoir la popin de confirmation quand on quitte la page
		this.state = AutoSaverState.None
	}

	submitCancel(): void {
		let cancelAction: IPopinAction = {
			label: this.$i18n.t('vm.root.accounting.immobilization.main.cancel.label').toString(),
			title: this.$i18n.t('vm.root.accounting.immobilization.main.cancel.title').toString(),
			callback: (data: any) => {
				this.cancelAction()
				return Promise.resolve()
			}
		}

		let cancelPopin: IPopin = {
			title: this.$i18n.t('vm.root.accounting.immobilization.main.cancel.title').toString(),
			content: this.$i18n.t('vm.root.accounting.immobilization.main.cancel.content').toString(),
			action: cancelAction
		}
		this.warning(cancelPopin)
	}

	cancelAction(): void {
		super.cancel()
		this.submitted = false
		this.$router.push({name: 'accounting'})
	}

	submitSave(): void {
		this.submitted = true
		this.save()
	}

	//#region private methods
	private canSave(): boolean {
		const { immobilization } = this
		return !!immobilization && !!immobilization.label
	}

	private createNewImmobilization(): IInvoice {
		let ofiId: string = null
		if (!!this.selectedOfficeId && parseInt(this.selectedOfficeId) !== -1) {
			ofiId = this.selectedOfficeId
		} else {
			let offices: IOffice[] = office.offices.filter(o => { return office.isActive(o) })
			let _office = (!!offices && offices.length > 0) ? offices[0] : null
			ofiId = !!_office ? _office.id : null
		}

		return Accounting.createNewImmobilization(user.currentUser.id, ofiId)
	}

	private initializeImmobilization(): void {
		let payload = {inv_id: this.$route.params.inv_id}
		if (payload.inv_id === undefined) {
			this.restoreValue(this.createNewImmobilization())
			super.initializeValue()
		} else {
			accounting.loadInvoice(payload)
			.then(() => { super.initializeValue() })
			.catch(error => {
				if (error.status !== 406) return

				this.$router.push({ name: 'accounting' })
			})
		}
	}
	//#endregion
}
