import Render from '@Views/components/popin/layout/popin-rgpd-patient.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InputDateType } from '@Components/frame/input-date-frame'
import { patient } from '@Store/modules'

@Render
@Component({})
export class PopinRgpdPatient extends Vue {
    
    @Prop() options: {pat_id: string, date: Date}
	@Prop({default: false}) submitted: boolean
	
	// @State('patient') statePatient: PatientState

	get hasBirthDateError(): boolean {
		return this.submitted && (!this.options.date || patient.isShareFailed)
	}
	get birthDateType(): InputDateType {
		return InputDateType.Numpad
	}
	get errorMessage(): string {
		if (!this.options.date)
			return this.$i18n.t('vm.components.popin.rgpd-patient.error.no-date').toString()
		if (patient.isShareFailed) 
			return this.$i18n.t('vm.components.popin.rgpd-patient.error.bad-date').toString()
	}
	get displayFailedMessage(): boolean {
		return this.submitted && patient.isShareFailed
	}
}