import Render from '@Views/root/search/member/search.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IMember, IPatient, IPopinAction, IPopin } from '@Store/types'
import { Patient } from '@Models/index'
import { patient, popIn } from '@Store/modules'

@Render
@Component({})
export class SearchMember extends Vue {

	@Prop() patient: IPatient
	@Prop() family: IMember[]

	searchMember(): void {
		let searchAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.member.search.select.label').toString(),
			title: this.$i18n.t('vm.root.search.member.search.select.title').toString(),
			callback: (data: {members: IMember[]}) => {
				if (!data.members) return Promise.reject()

				for (let member of data.members) {
					patient.addMember(member)
				}
				return Promise.resolve()
			},
			condition: (data: {members: IMember[]}) => {
				return !!data.members && data.members.length > 0
			}
		}
		let query: string = Patient.queryToSearch(this.patient.coordinates.last_name)
		let contains: string[] = [...(!!this.patient ? [this.patient.id] : []), ...(this.family || []).map(m => { return m.link_id })]
		let search: IPopin = {
			title: this.$i18n.t('vm.root.search.member.search.select.title').toString(),
			content: {component: 'popin-search-member'},
			action: searchAction,
			isWide: true,
			options: {members: [], contains: contains, query: query}
		}

		popIn.info(search)
	}
}
