import Render from '@Views/components/popin/layout/popin-share-referral.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class PopinShareReferral extends Vue {
    
    @Prop() options: {text: string, email: string}
	@Prop({default: false}) submitted: boolean

	get hasEmailError(): boolean {
		return this.submitted && !this.options.email
	}
	get hasShareTextError(): boolean {
		return this.submitted && !this.options.text
	}
}