<container class="is-without-aside">

	<master>

		<central>
			<div class="wrapper marg-v">

				<h1 class="text-center">{{ $t('vm.root.shop.main.title') }}</h1>

				<div class="vertical-gap shop-grid">
					<!-- TODO: paragraphe a retirer après le 1er novembre 2024 -->
					<p>
						Vous voulez acheter Osteo2ls mais vous ne trouvez pas comment faire&nbsp;? C’est normal,
						Osteo2ls va changer de formule et n’est plus achetable jusqu’au 31 octobre 2024. En attendant,
						vous pouvez utiliser l’application gratuitement et en illimité&nbsp;!
						<br>
						Alors profitez-en, et on se revoit sur cette page le 1er novembre 2024&nbsp;😉
					</p>
<!--					<pack-item v-for="pack in mainPacks" :key="pack.id" :pack="pack"></pack-item>-->
				</div>
<!--
				<div class="vertical-gap shop-grid">
					<section class="shop-option is-main">
						<h2 class="text-center text-uppercase-first-letter">{{ $t('vm.root.shop.main.bulk.title') }}</h2>
						<i18n path="vm.root.shop.main.bulk.text1.content" tag="p">
							<strong>{{ $t('vm.root.shop.main.bulk.text1.strong') }}</strong>
						</i18n>
						<i18n path="vm.root.shop.main.bulk.text2.content" tag="p">
							<a href="//osteo2ls.com/blog/news-l-achat-groupe-dans-osteo2ls.html" :title="$t('vm.root.shop.main.bulk.text2.link1.title')">{{ $t('vm.root.shop.main.bulk.text2.link1.label') }}</a>
							<a href="//logiciel-osteo.com" :title="$t('vm.root.shop.main.bulk.text2.link2.title')">{{ $t('vm.root.shop.main.bulk.text2.link2.label') }}</a>
						</i18n>

						<footer class="text-center">
							<router-link :to="{name: 'bulk-purchase'}" class="btn btn-primary" :title="$t('vm.root.shop.main.bulk.action.title')">
								<span class="text-md">
									{{ $t('vm.root.shop.main.bulk.action.label') }}
								</span>
							</router-link>
						</footer>
					</section>
				</div> -->

				<div class="grid-2 vertical-gap shop-grid">
					<pack-item v-for="pack in subPacks" :key="pack.id" :pack="pack"></pack-item>
				</div>
			</div>
		</central>

		<context-main></context-main>

	</master>

</container>
