<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'patients-campaigns'}" :title="$t('vm.root.patients.campaign.aside.back.label')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.patients.campaign.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li v-if="!isSended">
			<router-link v-if="currentStep > 0" :to="{name: 'campaign-step-one'}" :title="$t('vm.root.patients.campaign.aside.step-1.title')">1 &bull; {{ $t('vm.root.patients.campaign.aside.step-1.label') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 0, 'is-future-step': currentStep < 0}">1 &bull; {{ $t('vm.root.patients.campaign.aside.step-1.label') }}</span>
		</li>
		<li v-if="!isSended">
			<router-link v-if="currentStep > 1" :to="{name: 'campaign-step-two'}" :title="$t('vm.root.patients.campaign.aside.step-2.title')">2 &bull; {{ $t('vm.root.patients.campaign.aside.step-2.label') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 1, 'is-future-step': currentStep < 1}">2 &bull; {{ $t('vm.root.patients.campaign.aside.step-2.label') }}</span>
		</li>
		<li v-if="!isSended">
			<router-link v-if="currentStep > 2" :to="{name: 'campaign-step-three'}" :title="$t('vm.root.patients.campaign.aside.step-3.title')">3 &bull; {{ $t('vm.root.patients.campaign.aside.step-3.label') }}</router-link>
			<span v-else class="aside-link-like" :class="{'is-current-step': currentStep === 2, 'is-future-step': currentStep < 2}">3 &bull; {{ $t('vm.root.patients.campaign.aside.step-3.label') }}</span>
		</li>
	</ul>
	<ul class="aside-navigation is-pushed-down" v-if="!isNew && !isSended">
		<li class="hr" aria-hidden="true"/>
		<li>
			<delete-item :can-delete="!isNew && !isSended" :delete-action="submitDelete"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
				:title="$t('vm.root.patients.campaign.aside.delete.title')" :disabled="isDeleting"
				:delete-msg="$t('vm.root.patients.campaign.aside.delete.message')">
				<spinner v-if="isDeleting"/>
				<svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
				</svg>
				{{ $t('vm.root.patients.campaign.aside.delete.text') }}
			</delete-item>
		</li>
	</ul>
</a-side>