<div v-if="options" class="popin-person-search">

	<p>{{ $t('vm.components.popin.sticky-note-share.text') }}</p>
	<div class="vertical-gap marg-v" v-for="user in users" :key="user.id">
		<div class="popin-person-search-item">
			<checkbox-frame :id="user.id" v-model="options.shareList[user.id]" :title="$t('vm.components.popin.sticky-note-share.title', {'first-name': user.coordinates.first_name, 'last-name': user.coordinates.last_name})"/>
			<div class="misc-item">
				<label class="misc-item-content" :for="user.id">
					<span class="misc-item-icon">
						<avatar :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
					</span>
					<span class="misc-item-label">
						<span class="text-capitalize">{{user.coordinates.first_name}} <b>{{user.coordinates.last_name}}</b> &bull; {{user.profile | profile}}</span>
					</span>
				</label>
			</div>
		</div>
	</div>

</div>
