import { getModule, VuexModule, Module, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IPopin } from "@Store/types"
import { PopinType } from "@Enums/index"

@Module({
	name: 'popin',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
export class PopinModule extends VuexModule {

	popin: IPopin = null
    type: PopinType = PopinType.None

	get isOpen(): boolean {
		return this.type !== PopinType.None
	}
	get options(): any {
		return (this.popin && this.popin.options) || {}
	}
	
	@Mutation
	clear() {
		this.type = PopinType.None
		this.popin = null
	}
	  
	@Mutation
	success(popin: IPopin) {
		this.type = PopinType.Success
		this.popin = popin
	}

	@Mutation
	info(popin: IPopin) {
		this.type = PopinType.Info
		this.popin = popin
	}

	@Mutation
	warning(popin: IPopin) {
		this.type = PopinType.Warning
		this.popin = popin
	}

	@Mutation
	error(popin: IPopin) {
		this.type = PopinType.Error
		this.popin = popin
	}
}

export const popIn = getModule(PopinModule)
// export const popIn = new PopinModule({ store, name: "popin" })