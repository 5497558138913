import Render from '@Views/components/misc-item.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class MiscItem extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop() name: string
    @Prop({default: "radio"}) type: "radio" | "checkbox"
    @Prop() title: string
    @Prop() id: string
    @Prop() label: string
    @Prop() radioValue: any
    @Prop() value: any

    get checked(): boolean {
        return this.value as boolean
    }

    get selected(): boolean {
        return this.value === this.radioValue
    }
    set selected(value: boolean) {
        this.$emit('input', value)
    }

    update(): void {
        this.$emit('input', this.radioValue)
    }
}