<button type="button" data-toggle-context class="btn-in-breadcrumb is-for-context"
    v-toggle="{className: 'is-open', selector: '[data-toggle-context]', state:'context'}"
    :title="$t('vm.components.breadcrumb.more-info.title')">
    <span class="btn-in-breadcrumb-icon">
        <svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-left"/>
        </svg>
    </span>
    <span class="btn-in-breadcrumb-text text-nowrap rwd-over-large">
		<span class="is-for-more">{{$t('vm.components.breadcrumb.more-info.more')}}</span>
		<span class="is-for-less">{{$t('vm.components.breadcrumb.more-info.less')}}</span>
    </span>
</button>
