<div :class="{'marg-v': tagClass == 'no-marg'}">

	<div class="childs-space-between">
		<component :is="tag" :for="tagFor" :class="tagClass" :data-text="title">
			<slot name="main-icon"/>
			<span v-if="tag === 'label'">{{title}}</span>
			<template v-else>{{title}}</template>
		</component>
		<button type="button" class="btn--link-like collapsible-panel-trigger is-pushed-right" :class="{'text-sm': tag === 'label', 'is-active': !hide}" @click="toggle()"><!--
			--><slot name="icon"/><!--
			--><span class="rwd-over-small" v-if="hide">&nbsp;{{showLabel}}&nbsp;</span><!--
			--><span class="rwd-over-small" v-else>&nbsp;{{hideLabel}}&nbsp;</span><!--
			--><svg width="1em" class="svg-middle collapsible-panel-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
			</svg>
		</button>
	</div>

	<div class="collapsible-panel-container" :class="{'is-active': !hide}">
		<div class="collapsible-panel-content">
			<slot/>
		</div>
	</div>

</div>
