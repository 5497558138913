<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.confirmation.title') }}</h1>
	<section>
		<dropdown-frame id="confirmation-type" v-model="configuration.confirmation_type" :items="types"
			:title="$t('vm.root.parameters.confirmation.type.title')"
			:label="$t('vm.root.parameters.confirmation.type.label')"/>

		<div v-if="canSendSms">
			<collapsible-help id="confirmation-information" :title="$t('vm.root.parameters.confirmation.details-sms.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<span>{{ $t('vm.root.parameters.confirmation.details-sms.text1') }}</span>
					</p>
					<i18n path="vm.root.parameters.confirmation.details-sms.text2.content" tag="p" class="text-left">
						<strong>{{ $t('vm.root.parameters.confirmation.details-sms.text2.strong1') }}</strong>
						<strong>{{ $t('vm.root.parameters.confirmation.details-sms.text2.strong2') }}</strong>
					</i18n>
					<p class="text-left">
						{{ $t('vm.root.parameters.confirmation.details-sms.text3') }}
						<ul>
							<i18n path="vm.root.parameters.confirmation.details-sms.list.item1.content" tag="li">
								<strong>{{ $t('vm.root.parameters.confirmation.details-sms.list.item1.strong') }}</strong>
							</i18n>
							<i18n path="vm.root.parameters.confirmation.details-sms.list.item2.content" tag="li">
								<strong>{{ $t('vm.root.parameters.confirmation.details-sms.list.item2.strong') }}</strong>
							</i18n>
							<i18n path="vm.root.parameters.confirmation.details-sms.list.item3.content" tag="li">
								<strong>{{ $t('vm.root.parameters.confirmation.details-sms.list.item3.strong') }}</strong>
							</i18n>
						</ul>
						<i18n path="vm.root.parameters.confirmation.details-sms.text4.content" tag="span">
							<strong>{{ $t('vm.root.parameters.confirmation.details-sms.text4.strong') }}</strong>
						</i18n>
					</p>
				</div>
			</collapsible-help>

			<div class="childs-space-between" :class="user.sms_count | smsClass">
				<i18n path="vm.root.parameters.confirmation.sms.balance.content" tag="span">
					<strong>{{ $t('vm.root.parameters.confirmation.sms.balance.strong', {'sms-count': user.sms_count}) }}</strong>
				</i18n>
				<router-link class="text-strong" :to="to" :title="$t('vm.root.parameters.confirmation.sms.title')">
					{{ $t('vm.root.parameters.confirmation.sms.label') }}
				</router-link>
			</div>

			<textarea-frame id="confirmation-text" v-model="configuration.confirmation_text"
				:label="$t('vm.root.parameters.confirmation.text-sms.label', {length: confirmationTextLength})"
				:error-test="confirmationError" :error-msg="confirmationErrorMessage"
				:placeholder="$t('vm.root.parameters.confirmation.text-sms.placeholder')"
				:title="$t('vm.root.parameters.confirmation.text-sms.title')"/>

		</div>
	</section>
</div>
