export const home = {
    title: "Activez vos mois offerts",
    'free-month': "{total} / {count} mois offert | {total} / {count} mois offert | {total} / {count} mois offerts",
    steps: {
        title:"Vos étapes",
        step1: "Votre profil et celui de vos cabinets",
        step2: "Votre zone de chalandise",
        step3: "Votre profession et ses bienfaits en 10 questions"
    },
    header: {
        step: "Étapes",
        get: "Obtenir"
    },
    help: {
        text1: "Nous vous proposons d'essayer notre planning en ligne, {0}, sans aucun engagement de votre part. Le planning en ligne vous permet de créer une page Internet, visible de tous. Vos patients peuvent ainsi prendre rendez-vous avec vous, à n'importe quel moment du jour ou de la nuit, sans vous déranger. Vous gardez la main sur votre planning en validant vous même ces rendez-vous ou en les refusant.",
        strong1: "gratuitement pendant 1 an",
        text2: "Prenez le temps de lire tous les paragraphes dédiés à chaque étape, 3 en tout. Nous pouvons vous {0} et surtout sans engagement ensuite de votre part. Ça vaut le coup de se poser pour bien comprendre ce qu'il faut faire 😊.",
        strong2: "offrir jusqu'à 1 an de planning en ligne gratuitement"
    }
}
