<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'accounting'}" :title="$t('vm.root.accounting.book.aside.back.title')"
				sv-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.accounting.book.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
	</ul>
</a-side>
