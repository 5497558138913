import Render from '@Views/root/home/widget.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {FollowUpLevel, WidgetType} from '@Enums/index'
import { Debouncer } from '@Models/index'
import { user, home } from '@Store/modules'
import { IUser, IWidget } from '@Store/types'

@Render
@Component({})
export class Widget extends Vue {

	date: Date = new Date()
	expand: boolean = false
	private debouncer: Debouncer

	@Prop() widget: IWidget

	//#region Getters
	get datas(): any {
		return this.widget.datas
	}
	get user(): IUser {
		return user.user
	}
	get canUsePlanning(): boolean {
		return user.canUsePlanning
	}
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get isBirthDays(): boolean {
		return this.widget.type === WidgetType.BirthDays
	}
	get isFollowUp(): boolean {
		return this.widget.type === WidgetType.FollowUp
	}
	get isSms(): boolean {
		return this.widget.type === WidgetType.Sms
	}
	get isUnpaid(): boolean {
		return this.widget.type === WidgetType.Unpaid
	}
	get isValidEvent(): boolean {
		return this.widget.type === WidgetType.ValidEvent
	}
	// get canDisplay(): boolean {
	// 	return user.isMainUser ||
	// 		(user.isSecretaryUser && this.isValidEvent) ||
	// 		(user.isSubstituteUser && (this.isBirthDays || this.isUnpaid || this.isFollowUp))
	// }
	get isLoadingBirthday(): boolean {
		return this.isBirthDays && home.isLoadingBirthday
	}
	//#endregion

	mounted() {
		if (!this.isBirthDays) return

		this.date = new Date()
		this.debouncer = new Debouncer(this.getBirthdayList, 333)
	}

	beforeDestroy() {
		if (!this.isBirthDays) return

		Debouncer.clear(this.debouncer)
	}

	next() {
		if (!this.isBirthDays) return

		this.date.setDate(this.date.getDate() + 1)
		this.date = new Date(this.date)
		this.debouncer.start()
	}

	previous() {
		if (!this.isBirthDays) return

		this.date.setDate(this.date.getDate() - 1)
		this.date = new Date(this.date)
		this.debouncer.start()
	}

	getClassLevel(level: FollowUpLevel): any {
		return {
			'text-danger': level === FollowUpLevel.NotSatisfied,
			'text-success': level === FollowUpLevel.Satisfied
		}
	}
	getIconLevel(level: FollowUpLevel): string {
		return level === FollowUpLevel.NotSatisfied ? 'frown' : (level === FollowUpLevel.Satisfied ? 'smile-beam' : 'meh')
	}

	private getBirthdayList() {
		home.birthdays(this.date)
		.then((widget: IWidget) => {
			this.widget.datas = widget.datas
		})
	}
}
