<container>

	<a-side :sub-menu="true">
		<ul class="aside-navigation">
			<li>
				<router-link :to="{name: 'patients'}" :title="$t('vm.root.patients.aside.back.title')"
							 v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					{{ $t('vm.root.patients.aside.back.text') }}
				</router-link>
			</li>
		</ul>
	</a-side>

	<master>

		<central>
			<div class="wrapper">
				<h1>
					<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#clipboard-list-check"/>
					</svg>
					{{ $t("vm.root.patients.export.title") }}
				</h1>

				<div v-if="resultSearch.length <= 0">
					<table v-if="searching" class="data-table">
						<thead>
						<tr>
							<th scope="col"><!-- Avatar --></th>
							<th scope="col" class="w40 text-left">{{ $t("vm.root.patients.export.name-column") }}</th>
							<th scope="col" class="w15 text-center">{{ $t("vm.root.patients.export.birth-date-column") }}</th>
							<th scope="col" class="w30 text-left">{{ $t("vm.root.patients.export.email-column") }}</th>
							<th scope="col" class="w15 text-left">{{ $t("vm.root.patients.export.phone-column") }}</th>
						</tr>
						</thead>
						<tbody>
							<tr v-for="n in 10">
								<td><avatar class="text-md"/></td>
								<td class="text-ellipsis"><span class="skeleton-loader" style="--size: 10"></span></td>
								<td class="text-center text-numeric"><span class="skeleton-loader" style="--size: 6"></span></td>
								<td class="text-ellipsis"><span class="skeleton-loader" style="--size: 12"></span></td>
								<td class="text-nowrap"><span class="skeleton-loader" style="--size: 8"></span></td>
							</tr>
						</tbody>
					</table>
					<div v-else-if="!searching && searched" class="marg-20 text-center">
						<svg width="1em" class="svg-xl text-primary" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
						</svg>
						<p>
							{{ $t("vm.root.patients.export.no-result") }}<br>
							{{ $t("vm.root.patients.export.adjust-filter") }}
						</p>
					</div>
				</div>
				<div v-else class="is-overflow scrollbar">
					<table class="data-table">
						<thead>
						<tr>
							<th scope="col"><!-- Avatar --></th>
							<th scope="col" class="w40 text-left">{{ $t("vm.root.patients.export.name-column") }}</th>
							<th scope="col" class="w15 text-center">{{ $t("vm.root.patients.export.birth-date-column") }}</th>
							<th scope="col" class="w30 text-left">{{ $t("vm.root.patients.export.email-column") }}</th>
							<th scope="col" class="w15 text-left">{{ $t("vm.root.patients.export.phone-column") }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="patient in resultSearch" :key="patient.id">
							<td>
								<avatar class="text-md" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
							</td>
							<td class="text-ellipsis">
								<router-link :to="{name:'patient-coordinates', params: {pat_id: patient.id}}" :title="$t('vm.root.patients.export.name-title', {last_name: patient.last_name, first_name: patient.first_name})">
									<anonymous :text="patient.last_name" /> <anonymous :text="patient.first_name" />
								</router-link>
								<span v-if="patient.blacklisted" class="patient-blacklisted">{{ $t('vm.root.patients.export.blacklisted') }}</span>
								<span v-if="patient.deceased" class="patient-deceased">{{ $t('vm.root.patients.export.deceased') }}</span>
							</td>
							<td class="text-center text-numeric">
								{{ $d(patient.birth_date, 'L') }}
							</td>
							<td class="text-ellipsis">
								<anonymous :email="true" :text="patient.email" />
							</td>
							<td class="text-nowrap">
								<anonymous :text="$options.filters.formatPhone(patient.phone)" />
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<control-bar v-if="resultSearch.length > 0" class="childs-space-between">
					<div>
						<button-action btn-class="btn btn-default" @click.native="previous" :disabled="!canPrevious" :title="$t('vm.root.patients.export.previous.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
							</svg>
							<span class="rwd-over-xlarge">{{ $t('vm.root.patients.export.previous.label') }}</span>
						</button-action>

						<button-action btn-class="btn btn-default" @click.native="next" :disabled="!canNext" :title="$t('vm.root.patients.export.next.title')">
							<span class="rwd-over-xlarge">{{ $t('vm.root.patients.export.next.label') }}</span>
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
							</svg>
						</button-action>
						<i18n path="vm.root.patients.export.pagination.text" tag="span" v-if="displayPagination">
							<template v-slot:prefix>
								<span class="rwd-over-medium">{{ $t('vm.root.patients.export.pagination.prefix') }}</span>
							</template>
							<template v-slot:start>{{ startIndex }}</template>
							<template v-slot:end>{{ endIndex }}</template>
							<template v-slot:total>{{ total }}</template>
						</i18n>
					</div>
					<div class="is-pushed-right">
						<dropdown-actions id="action" :actions="actionItems" :menu-on-top="resultSearch.length > 5" :disabled="resultSearch.length === 0"/>
					</div>
				</control-bar>

			</div>

		</central>

		<context>
			<context-export/>
		</context>

	</master>

</container>
