import Render from '@Views/root/accounting/receipt/aside-receipt.html'
import {Component, Vue, Prop} from 'vue-property-decorator'
import {ICoordinates, IInvoice, INotification} from '@Store/types'
import config from '@Config/index'
import {accounting, notif} from '@Store/modules'

@Render
@Component({})
export class ASideReceipt extends Vue {

	@Prop() receipt: IInvoice

	private printed: boolean = false
	private sended: boolean = false

	get coordinates(): ICoordinates {
		return this.receipt.patient.coordinates
	}

	get isDeleting(): boolean {
		return accounting.isDeleting
	}

	get isSending(): boolean {
		return accounting.isSending
	}

	get canPrint(): boolean {
		return !!this.receipt && parseInt(this.receipt.id) !== -1 && !this.receipt.is_the_retrocession
	}

	get isPrinted(): boolean {
		return this.receipt.printed || this.printed
	}

	get isSended(): boolean {
		return this.receipt.sended || this.sended
	}

	private get invoicePath(): string {
		let param: string = this.isDemo ? "?demo=true" : ""
		let label: string = (this.receipt.label || `recette-${this.receipt.number}`).replace(/\s+|[,\/]/g, "-")
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/invoice/${this.receipt.id}/${label}.pdf${param}`
	}

	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}

	print() {
		window.open(this.invoicePath, '_blank').focus()
		this.printed = true
	}

	send(): void {
		if (this.isSending) return

		accounting.sendInvoice(this.receipt.id)
			.then(() => {
				this.sended = true
				let notification: INotification = {
					message: this.$i18n.t('vm.root.accounting.receipt.aside.send.succeeded', {email: this.coordinates.email}).toString(),
					actions: [],
					delay: 5000,
					canClose: true
				}
				notif.success(notification)
			})
			.catch(() => {
				let notification: INotification = {
					message: this.$i18n.t('vm.root.accounting.receipt.aside.send.failed').toString(),
					actions: [],
					delay: 5000,
					canClose: true
				}
				notif.error(notification)
			})
	}

	submitDelete(): void {
		const {receipt} = this
		let route = {name: 'accounting'}

		this.$emit('delete')
		if (!!receipt && !!receipt.id && parseInt(receipt.id) !== -1) {
			accounting.deleteInvoice(receipt.id).then(() => {
				this.$router.push(route)
			})
		} else {
			this.$router.push(route)
		}
	}
}
