<context>
	<div class="wrapper">
		<p>{{ $t('vm.root.accounting.remittances.context.intro') }}</p>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t('vm.root.accounting.remittances.context.title1') }}</strong></h2>
			<p>{{ $t('vm.root.accounting.remittances.context.text1') }}</p>
		</section>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t('vm.root.accounting.remittances.context.title2') }}</strong></h2>
			<p>{{ $t('vm.root.accounting.remittances.context.text2') }}</p>
			<ul>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item1') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item2') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item3') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item4') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item5') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list2.item6') }}</li>
			</ul>
		</section>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t('vm.root.accounting.remittances.context.title3') }}</strong></h2>
			<i18n path="vm.root.accounting.remittances.context.text3.content" tag="p">
				<sup>{{ $t('vm.root.accounting.remittances.context.text3.sup') }}</sup>
			</i18n>
			<ul>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list3.item1') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list3.item2') }}</li>
				<li class="semi-marg-v">{{ $t('vm.root.accounting.remittances.context.list3.item3') }}</li>
			</ul>
		</section>
		<section class="marg-v">
			<h2 class="h-reset"><strong>{{ $t('vm.root.accounting.remittances.context.title4') }}</strong></h2>
			<p>{{ $t('vm.root.accounting.remittances.context.text4') }}</p>
		</section>

		<i18n path="vm.root.accounting.remittances.context.outro.content" tag="p">
			<strong>{{ $t('vm.root.accounting.remittances.context.outro.strong') }}</strong>
		</i18n>
	</div>
</context>
