import { ReminderType } from "@Enums/index"
import { IRelaunch, IConfiguration } from "@Store/types"

export class Relaunch {

    public static createNewRelaunch(configuration: IConfiguration): IRelaunch {
		return {
            delay: !!configuration ? configuration.relaunch_delay : 3, 
            type: !!configuration ? configuration.relaunch_type : ReminderType.None
        }
	}

}