<div class="wrapper marg-v">
    <collapsible-help id="share-office-detail-information" tag="h1" :title="$t('vm.root.co-working.steps.step3.title')">
        <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
        </svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                <span v-if="existsAccount || !isMain">
                    {{ $t('vm.root.co-working.steps.step3.help1', {profile: shortProfile}) }}
                </span>
                <span v-else>
                    {{ $t('vm.root.co-working.steps.step3.help2') }}
                </span>
            </p>
            <p class="text-left" v-if="isSubstitute">
                {{ $t('vm.root.co-working.steps.step3.help3') }}<br>
                <em>{{ $t('vm.root.co-working.steps.step3.help4') }}</em>
            </p>
        </div>
    </collapsible-help>
    <div v-if="isVerifying" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.co-working.steps.step3.verifying') }}
		</div>
	</div>
    <form v-else @submit.prevent="submitFinalize">
        <div>
            <div class="grid-2" v-if="(existsAccount || !isMain) && !withProvider">
                <input-frame type="text" ref="last_name" id="last_name" v-model="account.last_name" :readonly="existsAccount"
                    :label="$t('vm.root.co-working.steps.step3.last-name.label')"
                    :error-test="hasLastNameError" :error-msg="$t('vm.root.co-working.steps.step3.last-name.error-msg')"
                    :placeholder="$t('vm.root.co-working.steps.step3.last-name.placeholder')"
                    :title="$t('vm.root.co-working.steps.step3.last-name.title')"/>

                <input-frame type="text" ref="first_name" id="first_name" v-model="account.first_name" :readonly="existsAccount"
                    :label="$t('vm.root.co-working.steps.step3.first-name.label')"
                    :error-test="hasFirstNameError" :error-msg="$t('vm.root.co-working.steps.step3.first-name.error-msg')"
                    :placeholder="$t('vm.root.co-working.steps.step3.first-name.placeholder')"
                    :title="$t('vm.root.co-working.steps.step3.first-name.title')"/>
            </div>

            <hr v-if="(existsAccount || !isMain) && !withProvider">
            <h3>{{ $tc('vm.root.co-working.steps.step3.office.title', offices.length) }}</h3>
            <div :class="{'grid-2': !isSubstitute}" v-for="office in offices" :key="office.id">
                <switch-frame :id="office.id" v-model="office.selected">
                    {{ $t('vm.root.co-working.steps.step3.access-to') }}<em>{{office.name}}</em>
                </switch-frame>
                <div v-if="isSubstitute && office.selected" class="grid-2">
                    <input-frame type="number" :id="`retrocession-${office.id}`" min="0" max="100" step="0.01" v-model="office.retrocession"
                        :label="$t('vm.root.co-working.steps.step3.office.retrocession.label')"
                        :error-test="hasRetrocessionError(office.retrocession)" :error-msg="$t('vm.root.co-working.steps.step3.office.retrocession.error-msg')"
                        :placeholder="$t('vm.root.co-working.steps.step3.office.retrocession.placeholder')"
                        :title="$t('vm.root.co-working.steps.step3.office.retrocession.title')"/>

                    <dropdown-frame :id="`mode-retrocession-${office.id}`" v-model="office.mode_retrocession" :items="paymentModesItems"
                        :label="$t('vm.root.co-working.steps.step3.office.mode.label')"
                        :title="$t('vm.root.co-working.steps.step3.office.mode.title')"/>

                    <togglebox-frame class="grid-item-full" :id="`by-${office.id}`" :items="billingItems" v-model="office.by_substitute"
                        :label="$t('vm.root.co-working.steps.step3.office.billing.label')"/>

                    <hr class="grid-item-full">
                </div>
            </div>

            <div v-if="isMain && plannings.length > 0">
                <hr>
                <collapsible-help id="share-planning-information" tag="h3" :title="$tc('vm.root.co-working.steps.step3.planning.title', plannings.length)">
                    <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                        <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                    </svg>
                    <div class="notice-info">
                        <p class="text-left">
                            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                                <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                            </svg>
                            <i18n path="vm.root.co-working.steps.step3.planning.help" tag="span">
                                <strong>{{ $t('vm.root.co-working.steps.step3.planning.strong1') }}</strong>
                                <strong>{{ $t('vm.root.co-working.steps.step3.planning.strong2') }}</strong>
                            </i18n>
                        </p>
                    </div>
                </collapsible-help>
                <div :class="{'grid-2': !isSubstitute}" v-for="planning in plannings" :key="planning.id">
                    <switch-frame :id="planning.id" v-model="planning.selected">
                        {{ $t('vm.root.co-working.steps.step3.access-to') }}<em>{{planning.name}}</em>
                    </switch-frame>
                </div>
            </div>
        </div>

        <control-bar class="childs-space-between">
            <div>
                <button-action btn-class="btn btn-default" @click.native="previous" :disabled="isSaving || isDeleting" :title="$t('vm.root.co-working.steps.step3.previous-step.title')">
                    <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                        <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
                    </svg>
                    {{ $t('vm.root.co-working.steps.step3.previous-step.text') }}
                </button-action>

                <button-action v-if="canSuppress()" btn-class="btn btn-danger" @click.native="submitSuppress" :loading="isDeleting" :disabled="isSaving" :title="$t('vm.root.co-working.steps.step3.delete-all.title')">
                    <svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
                        <use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
                    </svg>
                    {{ $t('vm.root.co-working.steps.step3.delete-all.text1') }}
                    <span class="rwd-over-xlarge">{{ $t('vm.root.co-working.steps.step3.delete-all.text2') }}</span>
                </button-action>
            </div>

            <button-action type="submit" btn-class="btn btn-primary" :loading="isSaving" :disabled="isDeleting" :title="$t('vm.root.co-working.steps.step3.next-step.title')">
                {{ $t('vm.root.co-working.steps.step3.next-step.text') }}
                <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
                </svg>
            </button-action>
        </control-bar>
    </form>

</div>
