<div class="wrapper marg-v">
    <collapsible-help id="campaign-information-3" tag="h1"
        :title="$t('vm.root.patients.campaign.step-3.title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.patients.campaign.step-3.help') }}
            </p>
        </div>
    </collapsible-help>

    <div class="notice-warning marg-v">
        <p class="text-left">
            <svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
            </svg>
            <i18n path="vm.root.patients.campaign.step-3.warning.text" tag="span">
                <strong>{{ $t('vm.root.patients.campaign.step-3.warning.strong') }}</strong>
            </i18n>
        </p>
    </div>

    <div class="grid-2 marg-v">
        <input-frame type="text" id="estimation-count" v-model="campaign.estimation_count" :readonly="true"
            :label="$t('vm.root.patients.campaign.step-3.estimation-count.label')"
            :title="$t('vm.root.patients.campaign.step-3.estimation-count.title')"/>

        <input-frame type="text" id="total-sms" v-model="campaign.total_sms" :readonly="true"
            :label="$t('vm.root.patients.campaign.step-3.total-sms.label')"
            :title="$t('vm.root.patients.campaign.step-3.total-sms.title')"/>
    </div>

	<div class="marg-v">
		<switch-frame id="notif-campaign" :title="$t('vm.root.patients.campaign.step-3.notification.title')" v-model="campaign.notification">
			{{ $t('vm.root.patients.campaign.step-3.notification.label') }}
		</switch-frame>
	</div>


	<control-bar class="childs-space-between">
        <button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.patients.campaign.step-3.previous.title')">
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
            </svg>
            {{ $t('vm.root.patients.campaign.step-3.previous.label') }}
        </button-action>

        <button-action type="submit" btn-class="btn btn-primary" :loading="isSaving" :title="$t('vm.root.patients.campaign.step-3.save.title')"
            :disabled="isSended">
            {{ $t('vm.root.patients.campaign.step-3.save.label') }}
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
            </svg>
        </button-action>
    </control-bar>
</div>
