import * as Calendar from '@ViewModels/root/calendar'
import * as Planning from '@ViewModels/root/calendar/planning'
import * as Event from '@ViewModels/root/calendar/event'
import * as ValidEvent from '@ViewModels/root/calendar/valid-event'
import { Profile } from '@Enums/profile'
import { EventRoot } from './event';

export const PlanningRoot = [
	{
		path: 'plannings/:date?', 
		name:'plannings', 
		component: Calendar.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
	},
	{
		path: 'planning/:pla_id?',
		name:'planning', 
		component: Planning.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
	},
	{
		path: 'new-event/:date/:hour?', 
		component: Event.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
		children: EventRoot('new-')
	},
	{
		path: 'event/:evt_id', 
		component: Event.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
		children: EventRoot()
	},
	{
		path: 'google-event/:pla_id/:evt_id', 
		component: Event.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
		children: EventRoot('google-')
	},
	{
		path: 'valid-event/:evt_id', 
		name: 'valid-event', 
		component: ValidEvent.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
	}
]
