export const detailsSpend = {
    label: {
        label: "Libellé de la dépense",
        placeholder: "ex : Restaurant",
        'error-msg': "Merci de renseigner un libellé à votre dépense",
        title: "Indiquez un libellé pour identifier cette dépense"
    },
    date: {
        label: "Date d'émission de la dépense",
        title: "Indiquez la date de création de cette dépense",
        placeholder: "ex: 30 Février 2016"
    },
    office: {
        label: "Cabinet lié à la dépense",
        title: "Sélectionnez le cabinet associé à cette dépense"
    },
    number: {
        label: "Numéro de la dépense",
        placeholder: "ex : 01000123",
        title: "Indiquez un numéro unique, idéalement consécutif au précédent"
    },
    'modify-number': {
        title: "Activez ce switch si vous souhaitez modifier manuellement le numéro de la dépense",
        label: "Modifier le numéro de la dépense"
    },
    type: {
        label: "Type de dépense",
        title: "Sélectionnez le type de dépense"
    },
    mode: {
        label: "Mode de paiement pour cette dépense",
        title: "Sélectionnez le mode de paiement utilisé pour régler cette dépense"
    },
    amount: {
        label: "Montant de la dépense (en euros)",
        placeholder: "ex : 55 €",
        title: "Indiquez le montant de la dépense"
    },
    tva: {
        label: "Taux de TVA associé à la dépense (en %)",
        placeholder: "ex : 20 %",
        title: "Indiquez le taux de TVA associé à la dépense"
    }
}