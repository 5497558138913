import Render from '@Views/root/patients/patient/consultation/exclusion-tests.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConsultation, IDisplay, IBreadcrumbItem, IExclusionTest } from '@Store/types'
import { Patient } from '@Models/index'
import * as ExclusionTest from '@ViewModels/root/patients/patient/exclusion-test/index'
import { Common } from '@Models/index'
import { Dictionary } from "vue-router/types/router"
import { patient, configuration, user, breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'exclusion-test': ExclusionTest.Main
	}
})
export class ExclusionTests extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	// @State('configuration') stateConfiguration: ConfigurationState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('user/isMainUser') isMainUser: boolean
	// @Action('patient/deleteExclusionTest') deleteExclusionTest: (payload: ExclusionTestDeletePayload) => void

	get isMainUser(): boolean {
		return user.isMainUser
	}
	get tests(): IExclusionTest[] {
		return this.consultation.exclusion_tests.currents || []
	}
	get display(): IDisplay {
		return configuration.configuration && configuration.configuration.display
	}

	mounted() {
		this.$emit('navigate', 'consultation')
		this.initializeBreadcrumb()
	}

	editExclusionTest(test: IExclusionTest): void {
		for (let t of this.tests) {
			if (!t.edit) continue
			t.edit = false
		}

		test.edit = true
		this.$emit('edit')
		this.$forceUpdate()
	}

	addExclusionTest(): void {
		for (let t of this.tests) {
			if (!t.edit) continue
			t.edit = false
		}

		let createId: string = Common.generateId()
		this.consultation.exclusion_tests.currents.unshift(Patient.createNewExclusionTest(createId))
		this.$emit('edit')
		this.$forceUpdate()
	}

	deleteTest(test: IExclusionTest): void {
		patient.deleteExclusionTest({consultation: this.consultation, test: test})
	}

	toggle(year: string): any {
		this.deploy[year] = !this.deploy[year]
		this.$forceUpdate()
	}
	isToggle(year: string): boolean {
		return this.deploy[year]
	}
	getId(test: IExclusionTest): string {
		return parseInt(test.id) === -1 ? test.created_id : test.id
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.exclusion-test', this.tests.length).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}