<infinite-scroll class="wrapper marg-v" :initialized="initialized" :load-more="search" :is-loading="searching" :can-load-more="canLoadMore">
	<collapsible-help id="campaign-information-2" tag="h1"
        :title="$t('vm.root.patients.campaign.step-2.title')">
		<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
		</svg>
        <div class="notice-info">
            <p class="text-left">
                <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                </svg>
                {{ $t('vm.root.patients.campaign.step-2.help1') }}
            </p>
            <p class="text-left">{{ $t('vm.root.patients.campaign.step-2.help2') }}</p>
        </div>
	</collapsible-help>

    <togglebox-frame id="choice" v-model="campaign.choice" :items="choiceItems"
        :label="$t('vm.root.patients.campaign.step-2.choice-label')"/>

    <div v-if="isList">
        <textarea-frame id="list" v-model.trim="campaign.list" rows="5"
            :label="$t('vm.root.patients.campaign.step-2.list.label')"
            :placeholder="$t('vm.root.patients.campaign.step-2.list.placeholder')"
            :title="$t('vm.root.patients.campaign.step-2.list.title')"/>

        <h2>{{ $tc('vm.root.patients.campaign.step-2.list.result', countList) }}</h2>
    </div>
    <div v-else-if="isPatient">
        <div class="grid-3 marg-v">
            <input-frame type="text" id="last-name" v-model="campaign.filter.last_name"
                :label="$t('vm.root.patients.export.context.last-name.label')"
                :placeholder="$t('vm.root.patients.export.context.last-name.placeholder')"
                :title="$t('vm.root.patients.export.context.last-name.title')"/>

            <input-frame type="text" id="first-name" v-model="campaign.filter.first_name"
                :label="$t('vm.root.patients.export.context.first-name.label')"
                :placeholder="$t('vm.root.patients.export.context.first-name.placeholder')"
                :title="$t('vm.root.patients.export.context.first-name.title')"/>

            <dropdown-frame id="office" v-model="campaign.filter.ofi_id" :items="officeItems"
                :label="$t('vm.root.patients.export.context.office.label')"
                :title="$t('vm.root.patients.export.context.office.title')"/>

        </div>

        <div v-if="!resultSearch || resultSearch.length <= 0" class="accounting-no-result">
			<div v-if="!searching && searched" class="text-center">
                <svg width="1em" class="svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
                </svg>
                <div class="text-md text-bold marg-v">
                    {{ $t('vm.root.patients.campaign.step-2.no-result.title') }}<br>
                    {{ $t('vm.root.patients.campaign.step-2.no-result.text') }}
                </div>
            </div>
        </div>
        <div v-else class="is-overflow scrollbar">
            <h2>{{ $tc('vm.root.patients.campaign.step-2.patient-list', total) }}</h2>
            <table class="data-table">
                <thead>
                    <tr>
                        <th scope="col"><!-- Avatar --></th>
                        <th scope="col" class="w40 text-left">{{ $t("vm.root.patients.export.name-column") }}</th>
                        <th scope="col" class="w40 text-left">{{ $t("vm.root.patients.export.email-column") }}</th>
                        <th scope="col" class="w20 text-left">{{ $t("vm.root.patients.export.phone-column") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="patient in resultSearch" :key="patient.id">
                        <td>
                            <avatar class="text-md" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
                        </td>
                        <td class="text-ellipsis">
                            <router-link :to="{name:'patient-coordinates', params: {pat_id: patient.id}}" :title="$t('vm.root.patients.export.name-title', {last_name: patient.last_name, first_name: patient.first_name})">
                                {{ patient.last_name }} {{ patient.first_name }}
                            </router-link>
                        </td>
                        <td class="text-ellipsis">
                            {{ patient.email }}
                        </td>
                        <td class="text-nowrap">
                            {{ patient.phone | formatPhone }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
	<div slot="loading" class="text-center" :class="{'accounting-no-result': !resultSearch || resultSearch.length <= 0}">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">{{ $t('vm.root.patients.campaign.step-2.loading') }}</div>
	</div>

    <control-bar slot="footer" class="childs-space-between">
        <button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.patients.campaign.step-2.previous.title')">
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
            </svg>
            {{ $t('vm.root.patients.campaign.step-2.previous.label') }}
        </button-action>

        <button-action btn-class="btn btn-primary" @click.native="next" :loading="searching" :disabled="disabled" :title="$t('vm.root.patients.campaign.step-2.next.title')">
            {{ $t('vm.root.patients.campaign.step-2.next.label') }}
            <svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
            </svg>
        </button-action>
    </control-bar>
</infinite-scroll>
