<div class="input-frame" :class="{ 'is-alert': errorTest }">
	<label class="label" :for="id" :data-text="label">
		<span>{{label}}</span>
	</label>

	<input-document :id="id" :is-light="isLight" :value="value" @input="handleInput" :options="options"
		:placeholder="placeholder" :title="title" :read-only="readOnly" :disabled="disabled"/>

	<div class="msg-alert" v-if="displayError" v-show="errorTest">
		<div class="msg-alert-inner">{{errorMsg}}</div>
	</div>
</div>
