import Render from '@Views/components/frame/checkbox-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class CheckboxFrame extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: "input-frame"}) defaultClass: string
    @Prop({default: "div"}) tag: string
    @Prop() title: string
    @Prop() id: string
    @Prop() label: string
    @Prop() value: boolean

    get checked(): boolean {
        return this.value
    }
    set checked(value: boolean) {
        this.$emit('input', value)
    }
}