export const superPanelMerge = {
	title: "Fusion des dossiers {patient}",
	'hide-label' : "masquer l'avertissement",
	'show-label' : "afficher l'avertissement",
	help: {
		content: "{0} Avant tout autre chose, {1}, prenez bien soin de vérifier les informations saises avant de valider votre fusion. {2}. Tous les éléments des dossiers doublons (antécédents, sphères, consultations, pièces jointes, etc.) seront transférés dans le dossier final. Vous pouvez sélectionner les informations principales en fonction du dossier pour finaliser au mieux le dossier final après fusion. La {3} sélectionnera les informations les plus récentes parmi celles proposées par les différents dossiers.",
		strong1: "Avertissement :",
		strong2: "une fusion de dossiers est irréversible",
		strong3: "Les dossiers devenus inutiles après la fusion seront supprimés",
		strong4: "pré-sélection intelligente"
	},
	notice: "Cliquez sur la coordonnées que vous souhaitez conserver, parmi celles disponibles ci-dessous. Vous pouvez également choisir la préselection intelligente.",
	selection: {
		title: "Laissez le logiciel faire une pré-sélection intelligente des différents éléments",
		label: "Pré-sélection intelligente"
	},
	by: 'par {owner}',
	'create-date': {
		content: "{0} {1} à {2}",
		span: "Crée le "
	},
	'button-title': "Choisir {title} du patient créé le {date}",
	columns: {
		'main': {
			label: "Créé par",
			title: "l'origine"
		},
		'birth_date': {
			label: "Date de naissance",
			title: "la date de naissance",
			'no-data': "Non renseignée"
		},
		'phone1': {
			label: "Téléphone 1",
			title: "le numéro de téléphone 1"
		},
		'phone2': {
			label: "Téléphone 2",
			title: "le numéro de téléphone 2"
		},
		'address': {
			label: "Adresse",
			title: "l'adresse postale"
		},
		'email': {
			label: "Email",
			title: "l'email"
		},
		'nir': {
			label: "Numéro de sécurité sociale",
			title: "le numéro de sécurité sociale"
		},
		'gender': {
			label: "Sexe",
			title: "le sexe"
		},
		'notes': {
			label: "Notes",
			title: "les notes"
		},
		'number_of_children': {
			label: "Nombre d'enfant",
			title: "le nombre d'enfant"
		},
		'situation': {
			label: "Situation",
			title: "la situation"
		},
		'professions': {
			label: "Professions",
			title: "les professions"
		},
		'reference': {
			label: "Références",
			title: "les références"
		},
		'habitus': {
			label: "Habitudes & antécédents",
			title: "les habitudes et antécédents"
		},
		'family_antecedents': {
			label: "Antécédents familiaux",
			title: "les antécédents familiaux"
		},
		'form_coordinates': {
			label: "Questionnaire coordonnées",
			title: "le questionnaire personnalisé des coordonées",
			'this-one': "celui-ci"
		},
		'pediatrics': {
			label: "Périnatalité",
			title: "la périnatalité",
			'this-one': "celle-ci"
		}
	}
}
