export const aside = {
    back: {
        title: "Retour aux paramètres",
        label: "Retour"
    },
    'create-document': {
        title: "Créer un nouveau document",
        label: "Créer un document"
    },
    'link-title': "Modifier le document {title}",
    'new-document': "Nouveau document"
}