import * as Parameters from '@ViewModels/root/parameters'
import * as Document from '@ViewModels/root/parameters/document'
import { Profile } from '@Enums/profile'

export const ParameterRoot = [
	{
		path: 'parameters',
		redirect: 'parameters-main',
		component: Parameters.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary, Profile.Accounting, Profile.Substitute] },
		children: [
			{
				path: '',
				name: 'parameters-main',
				component: Parameters.General,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Secretary, Profile.Accounting] },
			},
			{
				path: 'patient',
				name: 'parameters-patient',
				component: Parameters.Patient,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'planning',
				name: 'parameters-planning',
				component: Parameters.Planning,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
			},
			{
				path: 'accounting',
				name: 'parameters-accounting',
				component: Parameters.Accounting,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute, Profile.Accounting] },
			},
			{
				path: 'confirmation',
				name: 'parameters-confirmation',
				component: Parameters.Confirmation,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
			},
			{
				path: 'reminder',
				name: 'parameters-reminder',
				component: Parameters.Reminder,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
			},
			{
				path: 'follow-up',
				name: 'parameters-follow-up',
				component: Parameters.FollowUp,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'notifications',
				name: 'parameters-notifications',
				component: Parameters.Notifications,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] },
			},
			{
				path: 'relaunch',
				name: 'parameters-relaunch',
				component: Parameters.Relaunch,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'forms',
				name: 'parameters-forms',
				component: Parameters.Forms,
				meta: { permission: [Profile.Main, Profile.Demo] },
			},
			{
				path: 'document/:doc_id?',
				name: 'parameters-document',
				component: Document.Document,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
			},
			{
				path: 'test',
				name: 'parameters-test',
				component: Parameters.Test,
				meta: { permission: [Profile.Main, Profile.Demo] },
			}
		]
	}
]
