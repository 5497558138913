import Render from '@Views/components/frame/slider-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'

@Render
@Component({
	components: {
		'slider': VueSlider
	}
})
export class SliderFrame extends Vue {

    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) hideLabel: boolean
    @Prop() formatter: any
    @Prop() processStyle: any
    @Prop() min: number
    @Prop() max: number
    @Prop() step: number
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string
    @Prop() marks: any
    @Prop() tooltipFormatter: any
    @Prop() data: any
    @Prop() value: any

    handleInput(e: any) {
        this.$emit('input', e);
    }
}
