import Render from '@Views/root/patients/patient/consultation/schema.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, IConsultation, ISchema } from '@Store/types'
import { Drawer, IToggleBoxItem, IDrawerOptions } from '@Components/index'
import { StorageHelper } from '@Helpers/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({
	components: {
		'drawer': Drawer
	}
})
export class Schema extends Vue {

	options: IDrawerOptions = Drawer.defaultOptions

	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop() consultation: IConsultation

	//#region Getters
	get schema(): ISchema {
		return this.consultation.schema
	}
	get sizeItems(): IToggleBoxItem[] {
        return [
            {value: 2,  label: "", icon: 'drawer-circle-xs'},
            {value: 4,  label: "", icon: 'drawer-circle-sm'},
            {value: 10, label: "", icon: 'drawer-circle-md'},
            {value: 15, label: "", icon: 'drawer-circle-lg'},
            {value: 24, label: "", icon: 'drawer-circle-xl'}
        ]
	}
	get canDelete(): boolean {
		return this.schema.face.length > 0 || this.schema.back.length > 0 || this.schema.right.length > 0 || this.schema.left.length > 0
	}
	//#endregion

	mounted() {
		this.options = StorageHelper.get(`schema-options-${user.currentUser.id}`, Drawer.defaultOptions)
		this.$emit('navigate', 'schema')
		this.initializeBreadcrumb()
	}

	removeAllHistory() {
		let face: Drawer = this.$refs['face'] as Drawer
		let back: Drawer = this.$refs['back'] as Drawer
		let left: Drawer = this.$refs['left'] as Drawer
		let right: Drawer = this.$refs['right'] as Drawer

		face.clearAll()
		back.clearAll()
		left.clearAll()
		right.clearAll()
	}

	@Watch('readOnly')
	cancel() {
		if (!this.readOnly) return
		this.updateSchema()
	}

	@Watch('consultation.id')
	updateSchema() {
		let face: Drawer = this.$refs['face'] as Drawer
		let back: Drawer = this.$refs['back'] as Drawer
		let left: Drawer = this.$refs['left'] as Drawer
		let right: Drawer = this.$refs['right'] as Drawer

		setTimeout(() => {
			face.redraw()
			back.redraw()
			left.redraw()
			right.redraw()
		}, 100)
	}

	@Watch('options', {deep: true})
	updateSize() {
		StorageHelper.set(`schema-options-${user.currentUser.id}`, this.options)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 2).toString(), link: {name: "patient-consultations", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.consultation', 1).toString(), link: {name: "patient-consultation", params: this.$route.params} }
		let item5: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.schema').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4, item5])
	}
}
