import Render from '@Views/root/statistics/planning-online/planning.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IBreadcrumbItem, StatisticsActionPayload, IStatisticsFilter } from '@Store/types'
import { Statistics, Debouncer } from '@Models/index'
import { DateHelper } from '@Helpers/index'
import { breadcrumb, statistics } from '@Store/modules'

@Render
@Component({})
export class PlanningOnline extends Vue {

	private first: boolean = true
	private stats: IStatsConsultations = null
	private debouncerEvolution1: Debouncer = null
	private debouncerEvolution2: Debouncer = null

	// @State('statistics') stateStatistics: StatisticsState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('breadcrumb/updateLinked') updateLinked: (link: boolean) => void
	// @Action('statistics/loadPlanningOnlineStatistics') loadPlanningOnlineStatistics: (payload: StatisticsActionPayload) => Promise<any>

	//#region Getters
	get filter(): IStatisticsFilter {
        return statistics.filter
	}
	get isLoading(): boolean {
        return this.first && statistics.isLoading
	}
	get legends(): {label: string, color: string}[] {
		return [
			{label: this.$i18n.t('vm.root.statistics.planning-online.main.legends.first').toString(), color: '#008ffb'}
		]
	}
	get evolutionSeries1(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.evolution1
		}]
    }
	get evolutionSeries2(): any[] {
        return [{
			name: this.legends[0].label,
			data: this.evolution2
		}]
	}
    get evolutionChartOptions(): any {
		return {
            chart: {
                type: 'area',
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				categories: DateHelper.getMonthsName()
			},
			colors: this.legends.map(l => { return l.color }),
			legend: {
				show: false
			}
        }
	}
	
	private get evolution1(): number[] {
		return !this.stats || !this.stats.evolution1 || !this.stats.evolution1._0 ? [] : this.stats.evolution1._0
	}
	private get evolution2(): number[] {
		return !this.stats || !this.stats.evolution2 || !this.stats.evolution2._0 ? [] : this.stats.evolution2._0
	}
	//#endregion

	mounted() {
		this.debouncerEvolution1 = new Debouncer(this.updateEvolution1, 500)
		this.debouncerEvolution2 = new Debouncer(this.updateEvolution2, 500)
		breadcrumb.updateLinked(true)
		this.$emit('navigate', 'planning-online')
		this.initializeBreadcrumb()

		let payload: StatisticsActionPayload = {
			year1: this.filter.year1,
			year2: this.filter.year2,
			update: false
		}
		statistics.loadPlanningOnlineStatistics(payload)
		.then((stats) => {
			this.stats = stats
			this.first = false
		})
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncerEvolution1)
		Debouncer.clear(this.debouncerEvolution2)
	}

	getTotalSeries1(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries1.find(e => { return e.name === legend}))
	}

	getTotalSeries2(legend: string): number {
		return Statistics.getTotalSeries(legend, this.evolutionSeries2.find(e => { return e.name === legend}))
	}
	
	@Watch('filter.year1')
	updateYear1() {
		this.debouncerEvolution1.start()
	}

	@Watch('filter.year2')
	updateYear2() {
		this.debouncerEvolution2.start()
	}

	private updateStatistics(year1: number, year2: number) {
		let payload: StatisticsActionPayload = {
			year1: !!year1 ? year1 : -1,
			year2: !!year2 ? year2 : -1,
			update: true
		}
		statistics.loadPlanningOnlineStatistics(payload)
		.then((stats) => {
			if (!!year1) this.stats.evolution1 = stats.evolution1
			if (!!year2) this.stats.evolution2 = stats.evolution2
		})
	}

	private updateEvolution1() {
		this.updateStatistics(this.filter.year1, undefined)
	}

	private updateEvolution2() {
		this.updateStatistics(undefined, this.filter.year2)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.statistics').toString(), link: "statistics-patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.planning').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}

interface IStatsConsultations {
	evolution1: {
		_0: number[],
		_1: number[],
	},
	evolution2: {
		_0: number[],
		_1: number[],
	},
	ratio: number[]
}
