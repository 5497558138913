export const account = {
    cancel: {
        label: "annuler",
        title: "Annulez les modifications sur votre compte"
    },
    update: {
        'short-label': "modifier",
        'long-label': "votre compte",
        title: "Mettez à jour votre compte utilisateur"
    }
}