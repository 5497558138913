import Render from '@Views/components/forms/question.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuestionType } from '@Enums/index'
import { IQuestion } from '@Store/types'

@Render
@Component({})
export class Question extends Vue {

    @Prop() question: IQuestion

	//#region Getter
    get isSingleChoice(): boolean {
        return this.question.type === QuestionType.Single_Choice
    }
    get isMultipleChoice(): boolean {
        return this.question.type === QuestionType.Multiple_Choice
    }
    get isShortText(): boolean {
        return this.question.type === QuestionType.Short_Text
    }
    get isLongText(): boolean {
        return this.question.type === QuestionType.Long_Text
    }
	get selectedChoice(): string {
		let choice = this.question.choices.find(choice => choice.selected)
		this.question['selected_choice'] = choice ? choice.id : null
		return this.question['selected_choice']
	}
	set selectedChoice(id: string) {
		this.question.choices.forEach(choice => choice.selected = choice.id === id)
		this.question['selected_choice'] = id
	}
	//#endregion
}
