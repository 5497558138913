<container>

	<aside-remittances/>

	<master>

		<central>

			<div class="l-loading text-center">
				<div>
					<svg width="1em" class="text-primary svg-xl" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#university"/>
					</svg>
					<p><strong class="text-lg text-primary">{{ $t('vm.root.accounting.remittances.main.title') }}</strong></p>
					<p>{{ $t('vm.root.accounting.remittances.main.text') }}</p>
				</div>
			</div>

		</central>
		
		<context-remittances/>

	</master>

</container>
