import Render from '@Views/root/patients/patient/pediatrics/main.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IConsultation } from '@Store/types'
import { CustomForm } from '@Components/forms/custom-form'
import { patient } from '@Store/modules'

@Render
@Component({
	components: {
		'custom-form': CustomForm
	}
})
export class Main extends Vue {

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @Getter('patient/consultation') getConsultation: (id: string) => IConsultation
	// @Getter('patient/reasonTitle') getReasonTitle: (consultation: IConsultation) => string

	get consultation(): IConsultation {
		return patient.consultation(this.$route.params.item_id)
	}
	get hasReasonError(): boolean {
		return this.submitted && (!this.consultation.reason || this.consultation.reason.length === 0)
	}
	get reasonTitle(): string {
		return patient.reasonTitle(this.consultation)
	}

	mounted() {
		this.$emit('navigate', 'pediatrics')
	}
}