<div :class="{'disabled': disabled}">
    <div class="notice-warning" v-if="!isClassic">
        <svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
            <use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
        </svg>
        {{ $t('vm.root.calendar.event.relaunch.google') }}
    </div>

    <div v-else>
        <collapsible-help id="relaunch-event-information" :title="$t('vm.root.calendar.event.relaunch.title')">
            <svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
            </svg>
            <div class="notice-info">
                <p class="text-left">
                    <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                        <use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
                    </svg>
                    {{ $t('vm.root.calendar.event.relaunch.help1') }}
                </p>
                <p class="text-left">{{ $t('vm.root.calendar.event.relaunch.help2') }}</p>
                <i18n path="vm.root.calendar.event.relaunch.help3.text" tag="p" class="text-left">
                    <a href="//osteo2ls.com/rdv/" :title="$t('vm.root.calendar.event.relaunch.help3.title')" target="_blank">{{ $t('vm.root.calendar.event.relaunch.help3.link') }}</a>
                </i18n>
                <i18n path="vm.root.calendar.event.relaunch.help4.text" tag="p" class="text-left">
                    <strong>{{ $t('vm.root.calendar.event.relaunch.help4.strong') }}</strong>
                    <span class="text-xs">{{ $t('vm.root.calendar.event.relaunch.help4.span') }}</span>
                </i18n>
            </div>
        </collapsible-help>

        <div class="grid-2">
            <dropdown-frame id="type" v-model="relaunch.type" :items="relaunchItems" :disabled="disabled && !useActivePlanning"
                :label="$t('vm.root.calendar.event.relaunch.type')"/>

            <input-frame v-if="isRelaunchActive" type="number" id="delay"
                :label="$t('vm.root.calendar.event.relaunch.delay.label', {delay: relaunch.delay})"
                v-model="relaunch.delay" min="1" max="400" :disabled="disabled"
                :placeholder="$t('vm.root.calendar.event.relaunch.delay.placeholder')"
                :error-test="hasDelayError" :error-msg="$t('vm.root.calendar.event.relaunch.delay.error')"
                :title="$t('vm.root.calendar.event.relaunch.delay.title')">
            </input-frame>
        </div>
    </div>
</div>
