import Render from '@Views/components/breadcrumb/fullscreen.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class FullScreen extends Vue {

    private _isFullScreen: boolean = false

    @Prop({default: false}) responsive: boolean

    get isFullScreen(): boolean {
        return this._isFullScreen
    }
    set isFullScreen(value: boolean) {
        this._isFullScreen = value
        this.$forceUpdate()
    }

    mounted() {
        this.onFullScreenEvent(this.fullScreenCallback)
    }

    beforeDestroy() {
        this.offFullScreenEvent(this.fullScreenCallback)
    }

    toggle() {
        if (!this.fullScreenStatus()) {
            this.requestFullscreen(document.documentElement)
        } else {
            this.exitFullscreen(document)
        }
    }

    supportFullScreen(): boolean {
        let doc: any = document.documentElement
      
        return  ('requestFullscreen' in doc) || ('mozRequestFullScreen' in doc) ||
                ('msRequestFullscreen' in doc) || ('webkitRequestFullScreen' in doc)
    }

    fullScreenStatus(): boolean {
        let doc: any = document as any
        return (doc.fullscreen || doc.mozFullScreen || doc.fullscreenElement || 
                doc.msFullscreenElement || doc.webkitIsFullScreen)
    }
      
    private requestFullscreen(element: any): void {
        if (!this.supportFullScreen()) return

        if (element.requestFullscreen) {
            element.requestFullscreen()
            this.isFullScreen = true
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen()
            this.isFullScreen = true
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen()
            this.isFullScreen = true
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen()
            this.isFullScreen = true
        } else {
            console.log('Fullscreen API is not supported.')
        }
    }
      
    private exitFullscreen(element: any): void {
        if (!this.supportFullScreen()) return

        if (element.exitFullscreen) {
            element.exitFullscreen()
            this.isFullScreen = false
        } else if (element.webkitExitFullscreen) {
            element.webkitExitFullscreen()
            this.isFullScreen = false
        } else if (element.mozCancelFullScreen) {
            element.mozCancelFullScreen()
            this.isFullScreen = false
        } else if (element.msExitFullscreen) {
            element.msExitFullscreen()
            this.isFullScreen = false
        } else {
            console.log('Fullscreen API is not supported.')
        }
    }

    private fullScreenCallback(): any {
        this.isFullScreen = this.fullScreenStatus()
        
        this.$emit('change', this._isFullScreen)
        this.$emit('update:fullscreen', this._isFullScreen)
    }

    private onFullScreenEvent(callback: any): void {
        document.addEventListener('fullscreenchange', callback)
        document.addEventListener('mozfullscreenchange', callback)
        document.addEventListener('MSFullscreenChange', callback)
        document.addEventListener('webkitfullscreenchange', callback)
    }

    private offFullScreenEvent(callback: any): void {
        document.removeEventListener('fullscreenchange', callback)
        document.removeEventListener('mozfullscreenchange', callback)
        document.removeEventListener('MSFullscreenChange', callback)
        document.removeEventListener('webkitfullscreenchange', callback)
    }
}