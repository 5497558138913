<div class="wrapper">

	<section class="marg-v">
		<h2 class="h-reset text-bold">{{ $tc('vm.root.calendar.context-secretary.osteopath.main-title', users.length) }}</h2>
		<div class="marg-v">
			<div class="context-search">
				<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
				</svg>
				<input type="search" class="context-search-input" v-model="query"
					:placeholder="$t('vm.root.calendar.context-secretary.osteopath.filter.placeholder')"
					:title="$t('vm.root.calendar.context-secretary.osteopath.filter.title')">
			</div>
		</div>
		<ul class="ul-reset osteo-list">
			<li v-for="user in users" :key="user.id" class="semi-marg-v">
				<misc-item :id="user.id" v-model="selected" :radio-value="user.id" name="user">
					<avatar slot="icon" :first-name="user.coordinates.first_name" :last-name="user.coordinates.last_name" :email="user.email" :gender="user.coordinates.gender"/>
					<span :inner-html.prop="user.coordinates.first_name | mark(query)"/> <span :inner-html.prop="user.coordinates.last_name | mark(query)"/>
				</misc-item>
			</li>
		</ul>
	</section>

	<hr v-if="hasPlanning">

	<section v-if="hasPlanning">
		<h2 class="h-reset text-bold">{{ $tc('vm.root.calendar.context-secretary.planning-title', plannings.length) }}</h2>
		<planning-item v-for="planning in plannings" :key="planning.id" :planning="planning"/>
	</section>

	<hr v-if="officesByUser.length > 1">

	<section v-if="officesByUser.length > 1" class="marg-v">
		<h2 class="h-reset text-bold">{{ $tc('vm.root.calendar.context-secretary.office-title', offices.length) }}</h2>
		<radio-frame :id="`office-${office.id}`" :key="`office-${office.id}`" v-model="selectedOffice" v-for="office in offices"
			default-class="p-like" :name="`office-${office.id}`" :title="office.coordinates.name"
			:radio-value="office.id">{{office.coordinates.name}}</radio-frame>
	</section>

</div>
