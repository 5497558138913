<div v-if="pediatrics">
    <read-only v-if="readOnly" :pediatrics="pediatrics"/>
    <div v-else>
        <h2>{{ $t('vm.root.patients.patient.pediatrics-main.title') }}</h2>

        <misc-collapsible-panel>
            <span slot="title">{{ $t('vm.root.patients.patient.pediatrics-main.subtitle1') }}</span>
            <textarea-frame id="antecedents-parents" v-model="pediatrics.antecedents_parents" rows="1"
                :label="$t('vm.root.patients.patient.pediatrics-main.antecedents-parents.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.antecedents-parents.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.antecedents-parents.title')"/>

            <dropdown-frame id="number-of-children" v-model="pediatrics.number_of_children" :items="childrenItems"
				:label="$t('vm.root.patients.patient.pediatrics-main.number-of-children.label')"
				:title="$t('vm.root.patients.patient.pediatrics-main.number-of-children.title')"/>

            <textarea-frame id="remarks1" v-model="pediatrics.remarks1" rows="2"
                :label="$t('vm.root.patients.patient.pediatrics-main.remarks1.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.remarks1.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.remarks1.title')"/>
            <hr>
        </misc-collapsible-panel>

        <misc-collapsible-panel>
            <span slot="title">{{ $tc('vm.root.patients.patient.pediatrics-main.subtitle2', pediatrics.number_of_children) }}</span>
            <div class="grid-2">
                <togglebox-frame id="with-contraction" v-model="pediatrics.with_contraction" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.contraction.with-label')" />
                <textarea-frame id="contraction" v-show="pediatrics.with_contraction" v-model="pediatrics.contraction" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.contraction.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.contraction.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.contraction.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-immobilisation" v-model="pediatrics.with_immobilisation" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.immobilisation.with-label')"/>
                <textarea-frame id="immobilisation" v-show="pediatrics.with_immobilisation" v-model="pediatrics.immobilisation" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.immobilisation.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.immobilisation.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.immobilisation.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-strapping" v-model="pediatrics.with_strapping" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.strapping.with-label')"/>
                <textarea-frame id="strapping" v-show="pediatrics.with_strapping" v-model="pediatrics.strapping" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.strapping.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.strapping.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.strapping.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-accident" v-model="pediatrics.with_accident" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.accident.with-label')"/>
                <textarea-frame id="accident" v-show="pediatrics.with_accident" v-model="pediatrics.accident" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.accident.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.accident.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.accident.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-disease" v-model="pediatrics.with_disease" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.disease.with-label')"/>
                <textarea-frame id="disease" v-show="pediatrics.with_disease" v-model="pediatrics.disease" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.disease.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.disease.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.disease.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-gained-weight" v-model="pediatrics.with_gained_weight" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.gained-weight.with-label')"/>
                <textarea-frame id="gained-weight" v-show="pediatrics.with_gained_weight" v-model="pediatrics.gained_weight" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.gained-weight.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.gained-weight.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.gained-weight.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-tranquilizer" v-model="pediatrics.with_tranquilizer" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.tranquilizer.with-label')"/>
                <textarea-frame id="tranquilizer" v-show="pediatrics.with_tranquilizer" v-model="pediatrics.tranquilizer" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.tranquilizer.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.tranquilizer.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.tranquilizer.title')"/>
            </div>

            <textarea-frame id="remarks2" v-model="pediatrics.remarks2" rows="2"
                :label="$t('vm.root.patients.patient.pediatrics-main.remarks2.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.remarks2.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.remarks2.title')"/>

            <hr>
        </misc-collapsible-panel>

        <misc-collapsible-panel>
            <span slot="title">{{ $tc('vm.root.patients.patient.pediatrics-main.subtitle3', pediatrics.number_of_children) }}</span>
            <div class="grid-2">
                <togglebox-frame id="with-anesthesia" v-model="pediatrics.with_anesthesia" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.anesthesia.with-label')"/>
                <textarea-frame id="anesthesia" v-show="pediatrics.with_anesthesia" v-model="pediatrics.anesthesia" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.anesthesia.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.anesthesia.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.anesthesia.title')"/>
            </div>

            <input-frame type="number" id="duration" v-model="pediatrics.duration"
                :label="$t('vm.root.patients.patient.pediatrics-main.duration.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.duration.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.duration.title')"/>

                <div class="grid-3">
                <checkbox-frame id="eutocic" v-model="pediatrics.eutocic">{{ $t('vm.root.patients.patient.pediatrics-main.eutocic') }}</checkbox-frame>
                <checkbox-frame id="obstructed" v-model="pediatrics.obstructed">{{ $t('vm.root.patients.patient.pediatrics-main.obstructed') }}</checkbox-frame>
                <checkbox-frame id="episiotomy" v-model="pediatrics.episiotomy">{{ $t('vm.root.patients.patient.pediatrics-main.episiotomy') }}</checkbox-frame>
                <checkbox-frame id="forceps" v-model="pediatrics.forceps">{{ $t('vm.root.patients.patient.pediatrics-main.forceps') }}</checkbox-frame>
                <checkbox-frame id="fundal" v-model="pediatrics.fundal">{{ $t('vm.root.patients.patient.pediatrics-main.fundal') }}</checkbox-frame>
                <checkbox-frame id="suction-cup" v-model="pediatrics.suction_cup">{{ $t('vm.root.patients.patient.pediatrics-main.suction-cup') }}</checkbox-frame>
                <checkbox-frame id="finger" v-model="pediatrics.finger">{{ $t('vm.root.patients.patient.pediatrics-main.finger') }}</checkbox-frame>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-accelerators" v-model="pediatrics.with_accelerators" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.accelerators.with-label')"/>
                <textarea-frame id="accelerators" v-show="pediatrics.with_accelerators" v-model="pediatrics.accelerators" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.accelerators.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.accelerators.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.accelerators.title')"/>
            </div>

            <div class="grid-2">
                <togglebox-frame id="with-caesarean" v-model="pediatrics.with_caesarean" :items="withItems"
                    :label="$t('vm.root.patients.patient.pediatrics-main.caesarean.with-label')"/>
                <textarea-frame id="caesarean" v-show="pediatrics.with_caesarean" v-model="pediatrics.caesarean" rows="1"
                    :label="$t('vm.root.patients.patient.pediatrics-main.caesarean.label')"
                    :placeholder="$t('vm.root.patients.patient.pediatrics-main.caesarean.placeholder')"
                    :title="$t('vm.root.patients.patient.pediatrics-main.caesarean.title')"/>
            </div>

            <textarea-frame id="presentation" v-model="pediatrics.presentation" rows="1"
                :label="$t('vm.root.patients.patient.pediatrics-main.presentation.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.presentation.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.presentation.title')"/>

            <textarea-frame id="remarks3" v-model="pediatrics.remarks3" rows="2"
                :label="$t('vm.root.patients.patient.pediatrics-main.remarks3.label')"
                :placeholder="$t('vm.root.patients.patient.pediatrics-main.remarks3.placeholder')"
                :title="$t('vm.root.patients.patient.pediatrics-main.remarks3.title')"/>

            <hr>
        </misc-collapsible-panel>
    </div>
</div>
