export const documents = {
    title: "Documents",
    consultation: {
		label: "Choix de la consultation",
        'default-label': "Sélectionnez une consultation",
        items: {
            by: " (par {owner})",
            yourself: "vous-même",
            'label-day': "consultation d'{date}{owner}",
            'label-date': "consultation du {date}{owner}",
        }
    },
    help1: {
        title: "Documents",
        text: "Imprimez ou exportez en PDF les documents liés aux consultations de ce patient. Si votre patient n'a pas encore de consultation, n'hésitez pas à en rajouter une au dossier."
    },
    help2: {
        title: "Facture",
        text1: "Vous pouvez générer un document PDF de la facture liée à cette consultation. Ce PDF pourra être imprimé pour être remis en main propre à votre patient, ou vous pourrez l'envoyer par vos soins, par mail, ou encore le stocker sur votre disque dur.",
        text2: "Vous pouvez également envoyer la facture directement par mail à votre patient. Il faut cependant que l'adresse mail de ce dernier soit bien renseignée dans ses coordonnées.",
        text3: "Si la facture n'a pas encore été créée, vous devez la générer avant toute autre chose. Une sauvegarde du dossier patient sera nécessaire avant cela."
    },
    help3: {
        title: "Attestation",
		text: "Vous pourrez également imprimer l'attestation liée à cette consultation. Rappellons qu'une attestion sert principalement à justifier l'absence du patient, à son employeur, pendant ses horaires de travail."
    },
    'none-selected': {
        title: "Pas de consultation sélectionnée.",
        description: "Sélectionnez une consultation pour gérer la facture et l'attestation en lien."
    },
    disabled: {
        title: "Dossier non sauvegardé.",
        description: "Pour gérer vos factures et attestations, le dossier du patient doit être sauvegardé."
    },
    none: {
        title: "Pas de consultation.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer une nouvelle consultation",
            link: "une nouvelle consultation"
        }
    },
    'print-attestation': "Imprimer l'attestation",
    range: {
        start: {
            label: "Heure de début de la consultation",
            title: "Veuillez renseigner l'heure de début de la consultation"
        }, 
        end: {
            label: "Heure de fin de la consultation",
            title: "Veuillez renseigner l'heure de fin de la consultation"
        }
    }
}