import Render from '@Views/root/patients/patient/pediatrics/antecedents.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from "vue-router/types/router"
import { IPatient, IPediatrics, IBreadcrumbItem } from '@Store/types'
import { IToggleBoxItem } from '@Components/index'
import { ReadOnlyAntecedents } from './read-only-antecedents'
import { breadcrumb, patient } from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnlyAntecedents
	}
})
export class Antecedents extends Vue {

	private deploy: Dictionary<boolean> = {}
	
	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	
	// @State('patient') statePatient: PatientState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/pediatrics') pediatrics: IPediatrics
	
	get pediatrics(): IPediatrics {
		return patient.pediatrics
	}
	get withItems(): IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.without').toString()}, 
			{value: true, label: this.$i18n.t('general.with').toString()}
		]
	}
	get childrenItems(): IToggleBoxItem[] {
		let result: IToggleBoxItem[] = [{value: -1, label: this.$i18n.t('vm.root.patients.patient.coordinates.number-of-children.none').toString()}]
		for(let i:number = 0; i <= 15; i++) {
			result.push({value: i, label: this.$i18n.tc('vm.root.patients.patient.coordinates.number-of-children.item', i).toString()})
		}
		return result
	}
	private get patient(): IPatient {
		return patient.patient
	}

	mounted() {
		this.$emit('navigate', 'pediatrics')
		this.initializeBreadcrumb()
	}

	@Watch('pediatrics.number_of_children')
	updateCoordinates() {
		if (!this.patient) return
		this.patient.coordinates.number_of_children = this.pediatrics.number_of_children
	}

	toggle(year: string): any {
		this.deploy[year] = !this.deploy[year]
		this.$forceUpdate()
	}
	isToggle(year: string): boolean {
		return this.deploy[year]
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.pediatrics').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}