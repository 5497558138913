export const main = {
    'default-name': "Votre planning",
    name: {
        label: "Nom du planning",
        error: "Merci de renseigner le nom du planning",
        placeholder: "ex : Planning vie privée",
        title: "Veuillez renseigner le nom du planning"
    },
    color: {
        title: "Veuillez sélectionner la couleur de votre planning", 
        label: "Couleur du planning"
    },
    url: {
        label: "Url du planning",
        title: "Url du planning à renseigner dans votre logiciel de calendrier"
    },
    'copy-url': {
        title: "Copier l'url dans le presse-papier",
        label: "copier l'url",
        copied: "L'url a bien été copiée dans le presse-papier.",
        'no-copied': "L'url n'a pu être copiée dans le presse-papier."
    }
}