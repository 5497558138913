export const main = {
    title: "Courriers",
    print: {
        title: "Imprimer ce courrier",
        label: "imprimer"
    },
    update: {
        title: "Modifier le courrier {title}",
        label: "modifier"
    },
	state: {
    	'checked': "Réglée",
    	'unchecked': "Non réglée"
	},
    none: {
        text: "Aucun courrier sélectionné",
        or: "ou",
        'link-title': "Sélectionnez un de vos courriers",
        'link-label': "Sélectionnez un courrier",
        'new-title': "Créez un nouveau courrier",
        'new-label': "créez-en un nouveau"
    }
}
