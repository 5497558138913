import Render from '@Views/components/frame/input-phone-frame.html'
import allCountries from '@Assets/js/all-countries'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { formatNumber, isValidNumber, CountryCode } from 'libphonenumber-js'
import { PhoneType } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { IDropdownItem } from '@Components/input/dropdown'
import { DropdownButton, IDropdownButtonItem } from '@Components/dropdown-button'
import { Common } from '@Models/index'

@Render
@Component({
    components: {
        'dropdown-button': DropdownButton
    }
})
export class InputPhoneFrame extends Vue {
    name: string = 'input-phone'

    number: string = ''
    iso2: CountryCode = null
    private random: string = ''

    @Prop() phone: IPhone
    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) required: boolean
    @Prop({default: false}) disabledFetchingCountry: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: true}) enabledFlags: boolean
    @Prop({default: "ex : 06 11 22 33 44"}) placeholder: string
    @Prop({default: "defaultCountry"}) defaultCountry: string
    @Prop({default: InputPhoneFrame.phoneTypes}) phoneTypes: PhoneType[]
    @Prop({default: InputPhoneFrame.preferredCountries}) preferredCountries: string[]
    @Prop({default: InputPhoneFrame.onlyCountries}) onlyCountries: string[]
    @Prop({default: () => []}) ignoredCountries: string[]
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string
    @Prop() errorMsg: string

    //#region getters
    get state(): boolean {
        return this.number === '' || isValidNumber(this.formattedResult(this.number), this.iso2)
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }
    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get phoneItems(): IDropdownButtonItem[] {
        let result: IDropdownButtonItem[] = []
        this.phoneTypes.forEach(type => result.push({value: type, label: EnumsHelper.phoneTypeToString(type)}))
	    return result
    }
    get countryItems(): IDropdownButtonItem[] {
        let items = [...this.preferredCountries, ...this.onlyCountries]
        let result: IDropdownButtonItem[] = this.getCountries(items)
        .map(country => ({
            value: country.iso2.toLowerCase(),
            label: country.name,
            other: `+${country.dialCode}`
        }))
        return result
    }
    get preferredItems(): IDropdownButtonItem[] {
        let result: IDropdownButtonItem[] = this.getCountries(this.preferredCountries)
        .map(country => ({
            value: country.iso2.toLowerCase(),
            label: country.name,
            other: `+${country.dialCode}`
        }))
        return result
    }
    private formattedResult(phone:string, format: 'National' | 'International' = 'International'): string {
        return formatNumber(phone, this.iso2, format)
    }
    //#endregion

    mounted() {
        this.$emit('onValidate', this.phone)
    }

    created() {
		this.random = Common.generateId()
        this.updatePhone()
    }

    getPhoneName(type: PhoneType): string {
        return EnumsHelper.phoneTypeToString(type)
    }

    getTypeIcon(item: IDropdownItem): string {
        if (!item) return ''
        return `phone-type-${item.value}`
    }

    getFlagIcon(item: IDropdownItem): string {
        if (!item) return ''
        return item.value
    }

    onInput(): void {
        let input: HTMLSelectElement = this.$refs.input as HTMLSelectElement
        if (input !== undefined) {
            input.setCustomValidity(this.state ? '' : this.errorMsg)
        }
        if (!this.state) this.phone.error = true
        else delete this.phone.error

        this.$emit('onInput', this.phone)
    }

    onBlur(): void {
        this.$emit('onBlur')
    }

    @Watch('phone')
    private updatePhone() {
        this.initializeCountry()
        if (!this.phone) return

        this.iso2 = this.phone.iso.toUpperCase() as CountryCode
        this.number = this.formattedResult(this.phone.number, 'National')
    }

    @Watch('iso2')
    updateCountry() {
        this.iso2 = this.iso2.toString().toUpperCase() as CountryCode
        this.phone.iso = this.iso2.toString().toLowerCase()
        this.phone.number = this.formattedResult(this.number).replace(/ /g, '')
        this.number = this.formattedResult(this.number, 'National')

        if (!this.state) this.phone.error = true
        else delete this.phone.error
    }

    @Watch('number')
    updateNumber() {
        // this.iso2 = PhoneHelper.selectCountryWithDialCode(this.number)

        this.phone.number = this.formattedResult(this.number).replace(/ /g, '')
    }

    @Watch('state')
    updateState(newValue: boolean) {
        if (newValue) {
            // If mode is 'prefix', keep the number as user typed,
            // Otherwise format it
            this.phone.number = this.formattedResult(this.number).replace(/ /g, '')
            this.number = this.formattedResult(this.number, 'National')
        }
        this.$emit('onValidate', this.phone)
    }

    //#region private methods
    private initializeCountry(): void {
        if (this.setDefaultCountry()) return

        let country = this.preferredItems[0]
        this.iso2 = country.value.toUpperCase() as CountryCode
    }

    private setDefaultCountry(): boolean {
        if (!this.defaultCountry) return false
        const country = this.findCountry(this.defaultCountry)

        if (!country) return false

        this.iso2 = country.iso2
        return true
    }

    private getCountries(list:string[] = []): ICountry[] {
        return list
            .map(countryCode => this.findCountry(countryCode))
            .filter(Boolean)
    }

    private findCountry(iso:string = ''): ICountry {
        return allCountries.find(country => country.iso2 === iso.toUpperCase())
    }

    private static phoneTypes(): PhoneType[] {
        return [PhoneType.Mobil, PhoneType.Fix]
    }

    private static preferredCountries(): string[] {
        return ['fr', 'be', 'ch', 'lu', 'gb', 'ca']
    }

    private static onlyCountries(): string[] {
        return ["ad", "gf", "pf", "de", "gi", "gp", "is", "ie", "it", "mq", "yt", "mc", "nc", "pt", "re", "bl", "mf", "pm", "es", "wf", "ga"]
    }
    //#endregion
}

export interface IPhone {
    id: string,
    number: string,
    iso: string,
    type: PhoneType,
    error?: boolean
}

export interface ICountry {
    name: string,
    iso2: CountryCode,
    dialCode: string,
    priority?: number,
    areaCodes?: string[]
}
