import Render from '@Views/root/user/gift/home.html'
import { Vue, Component } from 'vue-property-decorator'
import { IBreadcrumbItem, IGift } from '@Store/types'
import { GiftState } from '@Enums/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class Home extends Vue {

	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get gift(): IGift {
		return user.gift
	}
	get isApplying(): boolean {
		return false
	}
	get isFirstStateApplied(): boolean {
		return this.gift.first_state === GiftState.Validated
	}
	get isSecondStateApplied(): boolean {
		return this.gift.second_state === GiftState.Validated
	}
	get isThirdStateApplied(): boolean {
		return this.thirdStateAppliedCount >= 10
	}
	get thirdStateAppliedCount(): number {
		return this.gift.state_questions.filter(state => { return state === GiftState.Validated }).length
	}
	
	mounted() {
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.gift').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}