<container v-if="!!office">
    <aside-schedule v-if="displayAsideSchedule" :office="office"/>
    <aside-office v-else @delete="onDelete"/>

    <master>
        <central>
            <div class="event-header">
				<div class="wrapper">

					<div class="event-header-wrapper">
						<div class="event-header-flex">
							<h1 class="h-reset">
                                <div class="text-lg text-capitalize">
                                    <strong class="text-primary">{{office.name}}</strong>
								</div>
                                <div class="text-sm text-bold">{{ $t('vm.root.user.office.main.create-by') }} {{owner().coordinates.first_name}} <strong>{{owner().coordinates.last_name}}</strong></div>
							</h1>
						</div>
					</div>
				</div>
			</div>

            <form @submit.prevent="submitSave" class="wrapper">
                <router-view :office="office" :submitted="submitted" @navigate="onNavigate"/>

                <control-bar>
                    <div class="childs-space-between">
                        <button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || disabled" :title="$t('general.cancel.title')">
                            <svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
                                <use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
                            </svg>
                            {{ $t('general.cancel.text') }}
                        </button-action>

                        <button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled" :title="$t('general.save.title')">
                            {{ $t('general.save.text') }}
                            <svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
                                <use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
                            </svg>
                        </button-action>
                    </div>
                </control-bar>
            </form>
        </central>

        <context-office/>
    </master>

</container>
