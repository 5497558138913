import Render from '@Views/root/parameters/relaunch.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IUser, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { PackType, ReminderType } from '@Enums/index'
import { SmsHelper, EnumsHelper } from '@Helpers/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class Relaunch extends Vue {

	private relaunchTextWarning: boolean = false
	private relaunchs: ReminderType[] = [ReminderType.None, ReminderType.OnlyMail, ReminderType.OnlySms, ReminderType.Both]

	@Prop() configuration: IConfiguration

	//#region getters
	get user(): IUser {
		return user.user
	}
	get cannotSend(): boolean {
		return this.configuration.relaunch_type === ReminderType.None
	}
	get canSendSms(): boolean {
		return this.configuration.relaunch_type === ReminderType.Both || this.configuration.relaunch_type === ReminderType.OnlySms
	}
	get types(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		this.relaunchs.forEach (type => result.push({value: type, label: EnumsHelper.reminderToString(type)}))
		return result
	}
	get relaunchTextLength(): string {
		let length: number = this.computeRelaunchTextLength()
		return this.$i18n.tc('vm.root.parameters.relaunch.remaining-characters', length).toString()
	}
	get relaunchError(): boolean {
		return !this.configuration.relaunch_text.includes("LIEN") || this.relaunchTextWarning
	}
	get relaunchErrorMessage(): string {
		if (!this.configuration.relaunch_text.includes("LIEN"))
			return this.$i18n.t('vm.root.parameters.relaunch.error.no-link').toString()
		if (this.relaunchTextWarning)
			return this.$i18n.t('vm.root.parameters.relaunch.error.too-long').toString()

		return ''
	}
	get to(): {name: string, params: {pack: PackType}} {
        return {name: 'purchase-pack', params: {pack: PackType.Sms}}
    }
	//#endregion

	mounted() {
		this.$emit('navigate', 'relaunch')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	private computeRelaunchTextLength(): number {
		if (!this.configuration || !this.configuration.relaunch_text) return 149

		this.configuration.relaunch_text = this.configuration.relaunch_text.trim()

		let text: string = this.configuration.relaunch_text
		let count: number = SmsHelper.countOccurence(text)
		let result: number = 149 - text.length - count
		this.relaunchTextWarning = result < 0
		return result
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.relaunch').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
