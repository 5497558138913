<div v-if="!!event">
    <input-frame :auto-focus="true" type="text" id="label" v-model="event.label"
        :label="$t('vm.root.calendar.event.rendez-vous.label.label')"
        :placeholder="$t('vm.root.calendar.event.rendez-vous.label.placeholder')"
        :title="$t('vm.root.calendar.event.rendez-vous.label.title')"/>

    <hr>
    <div v-if="!!event.patient" class="vertical-gap marg-v">
		<div class="grid-2">
			<dropdown-frame id="confirm-type" v-model="event.confirmation_type" :items="confirmationsItems" :label="$t('vm.root.calendar.event.rendez-vous.confirmation-type')"/>
			<dropdown-frame id="type" v-model="event.reminder_type" :items="remindersItems" :label="$t('vm.root.calendar.event.rendez-vous.reminder-type')"/>
		</div>

        <patient-item :patient="event.patient" :unselect="unselect" :popin-to-update="isNewEvent"/>
    </div>
    <search-patient :action="addPatient" v-else/>
    <hr>

	<time-panel :event="event"></time-panel>

</div>
