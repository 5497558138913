import { FontSize } from "@Enums/font-size"
import { EnumsHelper } from "./enums"

export class FontHelper {
    public static updateFontSize(newSize: FontSize, oldSize: FontSize = FontSize.Normal) {
		if (newSize === oldSize) return
		
		document.documentElement.classList.remove(EnumsHelper.fontSizeToClass(oldSize))
		document.documentElement.classList.add(EnumsHelper.fontSizeToClass(newSize))
	}
}