import Render from '@Views/root/shop/bulk/context-main.html'
import {Component, Vue} from 'vue-property-decorator'
import {bulk, user} from '@Store/modules'

@Render
@Component({})
export class ContextMain extends Vue {

	marks: any = {
		'0': '',
		'2': '4 %',
		'5': '10 %',
		'10': '20 %',
		'15': '30 %',
	}
	countdown: Date = new Date()
	private interval: NodeJS.Timeout

	//#region Getters
	get reservation(): number {
		return !!bulk.bulk ? bulk.bulk.count : 0
	}
	get percent(): number {
		return !!bulk.bulk ? bulk.bulk.percent : 0
	}
	get nextPercent(): number {
		switch (this.percent) {
			case 30:
				return -1
			case 20:
				return 30
			case 10:
				return 20
			case 4:
				return 10
			default:
				return 4
		}
	}
	get missingReservation(): number {
		if (this.reservation >= 15)
			return 0

		if (this.reservation >= 10)
			return 15 - this.reservation

		if (this.reservation >= 5)
			return 10 - this.reservation

		if (this.reservation >= 2)
			return 5 - this.reservation

		return 2 - this.reservation
	}
	get isCurrentSession(): boolean {
		return !user.user.bulk_key || user.user.bulk_key === bulk.bulkKey(new Date())
	}
	get isLoading(): boolean {
		return bulk.isLoading
	}
	//#endregion

	mounted() {
		let today: Date = new Date()
		let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1, 23, 59, 59)
		lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1)
		this.interval = setInterval(() => {
			let countdownValue = lastDayOfMonth.getTime() - new Date().getTime()
			this.countdown = new Date(countdownValue)
		}, 1000)
	}

	beforeDestroy() {
		clearInterval(this.interval)
	}

	tooltipFormatter(v: number): string {
		switch (v) {
			case 0:
			case 1:
				return "Aucune réduction"
			case 2:
			case 3:
			case 4:
				return "4 % de réduction"
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
				return "10 % de réduction"
			case 10:
			case 11:
			case 12:
			case 13:
			case 14:
				return "20 % de réduction"
			case 15:
				return "30 % de réduction"
			default:
				return ''
		}
	}
}
