import Render from '@Views/components/popin/layout/popin-duplicate-event.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IEvent } from '@Store/types'
import {TimePanel} from "@ViewModels/root/calendar/event/time-panel"

@Render
@Component({
	components: {
		'time-panel': TimePanel
	}
})
export class PopinDuplicateEvent extends Vue {
    @Prop() options: {event: IEvent}
	@Prop({default: false}) submitted: boolean
}
