<div>
	<section class="wrapper marg-v">

		<div class="childs-space-between marg-b">
			<h2 class="no-marg">{{ $t('vm.root.statistics.planning-online.context.title') }}</h2>
			<div class="is-pushed-right">
				<spinner v-if="isLoading" class="text-primary text-lg svg-block"/>
			</div>
		</div>

		<input-frame type="number" id="year1" v-model="filter.year1"
			:label="$t('vm.root.statistics.planning-online.context.year1.label')"
			:placeholder="$t('vm.root.statistics.planning-online.context.year1.placeholder')"
			:title="$t('vm.root.statistics.planning-online.context.year1.title')"/>

		<input-frame type="number" id="year2" v-model="filter.year2"
			:label="$t('vm.root.statistics.planning-online.context.year2.label')"
			:placeholder="$t('vm.root.statistics.planning-online.context.year2.placeholder')"
			:title="$t('vm.root.statistics.planning-online.context.year2.title')"/>

	</section>
</div>
