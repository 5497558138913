export const main = {
    title: "Boutique Osteo2ls",
	bulk: {
		title: "Achat groupé (jusqu'à -30 %)",
		text1: {
			content: "Regroupez-vous pour acheter Osteo2ls à plusieurs et profiter d'{0}.",
			strong: "une réduction pouvant aller jusqu'à 30 %"
		},
		text2: {
			content: "Plus d'informations concernant l'achat groupé dans {0} et sur {1}.",
			link1: {
				label: "cet article",
				title: "Explications et détails de l'achat groupé dans Osteo2ls"
			},
			link2: {
				label: "logiciel-osteo.com",
				title: "Plateforme d'achats groupés d'Osteo2ls"
			}
		},
		action: {
			label: "finaliser un achat groupé",
			title: "Finaliser un achat groupé"
		}
	}
}
