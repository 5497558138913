<div v-if="isVisible">
	<button class="input-like marquee"
			:class="{'is-selected': isSelected}"
			:title="$t('vm.components.super-panel.merge.button-title', {title: title, date: $d(patient.create_date, 'L')})"
			@click="select">
			<span class="marquee-inner">
				<span class="marquee-content">
					<slot/>
				</span>
			</span>
	</button>
</div>
