import Render from '@Views/components/count-up.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ICountUp from 'vue-countup-v2'

@Render
@Component({
	components: {
		'count-up': ICountUp
	}
})
export class CountUp extends Vue {

	@Prop() value: number
	@Prop({default: 0}) delay: number
}
