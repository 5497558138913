<section class="dashboard-widget" v-if="!!code">
	<div class="dashboard-widget-header childs-space-between">
		<h2 class="text-md no-marg">
			<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#gift"/>
			</svg>
			{{ $t('vm.root.home.widget-referral.title') }}
			
		</h2>
	</div>

	<div class="dashboard-widget-content scrollbar">
		<div class="semi-marg-v text-center">
			{{ $t('vm.root.home.widget-referral.code') }} <span class="text-bold select">{{ code }}</span>
		</div>
		<hr class="no-marg">
		<div class="dashboard-widget-full-height">
			<div class="dashboard-widget-summary">
				<div>
					<div class="text-bold">{{ $tc('vm.root.home.widget-referral.godchild', godchildCount) }}</div>
					<div class="dashboard-widget-summary-number">{{ godchildCount }}</div>
				</div>
				<div>
					<div class="text-bold">{{ $t('vm.root.home.widget-referral.points') }}</div>
					<div class="dashboard-widget-summary-number">{{ point }}</div>
				</div>
			</div>
		</div>
		<hr class="no-marg">
		<div class="semi-marg-v text-center">
			<router-link :to="{name: 'user-referral'}" :title="$t('vm.root.home.widget-referral.more-informations.title')">
				{{ $t('vm.root.home.widget-referral.more-informations.label') }}
			</router-link>
		</div>
	</div>
</section>
