import { BaseService } from '@Services/index'
import { IReferralGift, SendReferralPayload } from '@Store/types'

export class ReferralService extends BaseService {
	public applyGift(gift: IReferralGift): Promise<any> {
	return super.post('/referral/', gift)
	}

	public sendReferral(payload: SendReferralPayload): Promise<any> {
		return super.put('/referral/', payload)
	}

	public getGodchildList(code: string): Promise<any> {
		return super.get(`/referrals/${code}/`)
	}
}