export const printPatient = {
    text: "Sélectionnez les éléments du dossier que vous souhaitez imprimer :",
    'all-elements': "Aucun élément | Le seul élément | Tous les {count} éléments",
    'consultations-title': "Consultation sélectionnée : {count}|Consultation sélectionnée : {count}|Consultations sélectionnées : {count}",
    'all-consultations': "Aucune consultation | La seule consultation | Toutes les {count} consultations",
    'consultation-title': "Consultation du {date} : {reason}",
    'antecedents-title': "Antécédent sélectionné : {count}|Antécédent sélectionné : {count}|Antécédents sélectionnés : {count}",
    'all-antecedents': "Aucun antécédent | Le seul antécédent | Tous les {count} antécédent",
    'antecedent-title': "Antécédent du {date} : {type} / {title}",
    'spheres-title': "Sphère sélectionnée : {count}|Sphère sélectionnée : {count}|Sphères sélectionnées : {count}",
    'all-spheres': "Aucune sphère | La seule sphère | Toutes les {count} sphères",
    'sphere-title': "Sphère du {date} : {type} / {title}",
    'reasons-title': "Historique sélectionné : {count}|Historique sélectionné : {count}|Historiques sélectionnés : {count}",
    'all-reasons': "Aucun historique | Le seul historique | Tous les {count} historiques",
    'reason-title': "Historique : {title}",
    'attachments-title': "Pièce jointe sélectionnée : {count}|Pièce jointe sélectionnée : {count}|Pièces jointes sélectionnées : {count}",
    'all-attachments': "Aucune pièce jointe | La seule pièce jointe | Toutes les {count} pièces jointes",
    'attachment-title': "Pièce jointe du {date} : {name}",
    pediatry: "Périnatalité"
}
