export const reason = {
    title: "Historique de ce motif de consultation",
    help: "Un historique de motif de consultation permet de faire le lien entre plusieurs consultations. Un patient peut venir vous consulter plusieurs fois pour le même trouble. C'est là que l'historique entre en jeu. Vous pourrez saisir les informations globales au problème et avoir une vue d'ensemble de l'évolution des symptômes.",
    reason: {
        label: "Choix d'un historique existant",
        'default-label': "Sélectionnez un historique",
        title: "Sélectionnez un historique commun à plusieurs consultations."
    },
    'new-reason': {
        label: "nouvel historique",
        title: "Créer un nouvel historique"
    },
    name: {
        label: "Titre de l'historique",
        placeholder: "ex : Cervicalgie urgence",
        error: "Merci de renseigner le titre de cet historique",
        title: "Veuillez renseigner le titre de cet historique"
    },
    topography: {
        label: "Topographie",
        placeholder: "ex : Charnière cervico-dorsale",
        title: "Veuillez renseigner la topographie de cet historique"
    },
    chronology: {
        label: "Chronologie",
        placeholder: "ex : Début courant avril 2015",
        title: "Veuillez renseigner la chronologie de cet historique"
    },
    factors: {
        label: "Facteurs déclenchants",
        placeholder: "ex : Surtout en dormant",
        title: "Veuillez renseigner les facteurs déclenchants de cet historique"
    },
    signs: {
        label: "Signes associés",
        placeholder: "ex : Tiraillement en flexion de tête",
        title: "Veuillez renseigner les signes associés de cet historique"
    },
    description: {
        label: "Histoire de la maladie",
        placeholder: "ex : Entorse de la cheville droite",
        title: "Veuillez renseigner l'histoire de la maladie liée à cet historique"
    },
    delete: {
        title :"Suppression de l'historique",
        'delete-msg': "Voulez-vous vraiment supprimer cet historique ?",
    },
    dissociate: {
        label :"dissocier",
        title :"Dissociation de l'historique",
        'dissociate-msg': "Voulez-vous vraiment dissocier cet historique de cette consultation ?",
    },
    none: {
        title: "Pas d'historique.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer un nouvel historique",
            link: "un nouvel historique"
        }
    }
}
