import Render from '@Views/components/frame/input-document-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class InputDocumentFrame extends Vue {

    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readOnly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) isLight: boolean
    @Prop() options: any
    @Prop() id: string
    @Prop() label: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() errorMsg: string
    @Prop() value: any

    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    
    handleInput(e: any) {
        this.$emit('input', e)
    }
}