export const coordinates = {
    title: {
        label: "Nom du cabinet",
        'error-msg': "Merci d'indiquer un nom à votre cabinet",
        placeholder: "ex : Cabinet de Bordeaux",
        title: "Veuillez renseigner le nom de votre cabinet"
    },
    address: "Adresse du cabinet",
    'phone-1': {
        label: "Numéro de téléphone 1",
        'error-msg': "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le premier numéro de téléphone du cabinet"
    },
    'phone-2': {
        label: "Numéro de téléphone 2",
        'error-msg': "Merci de renseigner un numéro de téléphone valide",
        title: "Veuillez renseigner le second numéro de téléphone du cabinet"
    },
    currency: {
        title: "Veuillez sélectionner la devise du cabinet",
        label: "Devise du cabinet"
    },
    tva: {
        label: "Taux de T.V.A. (en %)",
        placeholder: "ex : 20 %", 
        title: "Veuillez renseigner la T.V.A. liée à ce cabinet"
    },
    mode: {
        title: "Veuillez sélectionner le mode de paiement par défaut pour ce cabinet",
        label: "Mode de paiement par défaut"
    },
    timezone: {
        title: "Veuillez sélectionner le fuseau horaire de ce cabinet",
        label: "Fuseau horaire"
    }
}