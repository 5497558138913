import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IStatisticsFilter, StatisticsActionPayload } from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { StatisticsService } from "@Services/index"
import { Statistics } from "@Models/index"

@Module({
	name: 'statistics',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class StatisticsModule extends VuexModule {
	status: RequestStatus = RequestStatus.None
	filter: IStatisticsFilter = Statistics.createNewFilter()

	get isLoading(): boolean {
        return this.status === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.filter = Statistics.createNewFilter()
	}

	@Mutation
	statisticsRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	statisticsSuccess() {
		this.status = RequestStatus.Success
	}

	@Mutation
	statisticsFailure() {
		this.status = RequestStatus.Failed
	}

	@Action({rawError: true})
	async loadPatientStatistics(payload: StatisticsActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getPatientStatistics(payload.ofi_id, payload.acc_id, payload.year1, payload.year2, payload.update)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadConsultationStatistics(payload: StatisticsActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getConsultationStatistics(payload.ofi_id, payload.acc_id, payload.year1, payload.year2, payload.week, payload.update)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadPlanningStatistics(payload: StatisticsActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getPlanningStatistics(payload.ofi_id, payload.acc_id, payload.pla_id, payload.year1, payload.year2, payload.update)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadPlanningOnlineStatistics(payload: StatisticsActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getPlanningOnlineStatistics(payload.year1, payload.year2, payload.update)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadAccountingStatistics(payload: StatisticsActionPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getAccountingStatistics(payload.ofi_id, payload.year1, payload.year2, payload.update)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadReferenceStatistics(): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getReferenceStatistics()
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadColleagueStatistics(): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getColleagueStatistics()
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadTop10Statistics(ofi_id: string): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.statisticsRequest()

		let service = new StatisticsService()
		return service.getTop10Statistics(ofi_id)
		.then(stats => {
			this.statisticsSuccess()
			return Promise.resolve(stats)
		})
		.catch(error => {
			this.statisticsFailure()
			return Promise.reject(error)
		})
	}
}

export const statistics = getModule(StatisticsModule)
// export const statistics = new StatisticsModule({ store, name: "statistics" })
