import Render from '@Views/root/statistics/patients/context-patients.html'
import { Component, Vue } from 'vue-property-decorator'
import { IOffice, IStatisticsFilter } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { Profile } from '@Enums/index'
import { office, statistics, user } from '@Store/modules'

@Render
@Component({})
export class ContextPatients extends Vue {

    year1: number = 2019
    year2: number = 2020

    // @State('user') stateUser: UserState
    // @State('statistics') stateStatistics: StatisticsState
    // @Getter('office/offices') offices: () => IOffice[]
	// @Getter('office/isActive') isActive: (office: IOffice, usr_id?: string) => boolean
	// @Getter('user/linkedUsers') linkedUsers: (profile: Profile) => any[]

    get isLoading(): boolean {
        return statistics.isLoading
	}
	get userItems(): IDropdownItem[] {
        let users: {id: string, coordinates: {last_name: string, first_name: string}}[] = [user.user, ...user.linkedUsers(Profile.Substitute)]
        let result: IDropdownItem[] = users.map(user => { return {value: user.id, label: `${user.coordinates.first_name} ${user.coordinates.last_name}` } })
        result.unshift({value: '-1', label: this.$i18n.t('general.all-users').toString()})
        return result
    }
    get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = [{value: '-1', label: this.$i18n.t('general.all-offices').toString()}]
		let offices: IOffice[] = office.offices
		for (let _office of offices) {
			if (!office.isActive(_office)) continue

			result.push({value: _office.id, label: _office.coordinates.name})
		}

		return result
    }
    get filter(): IStatisticsFilter {
        return statistics.filter
    }
}