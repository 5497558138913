import Render from '@Views/root/calendar/context-secretary.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { PlanningItem } from './planning-item'
import {Profile} from '@Enums/index'
import {IPlanning} from '@Store/types'
import { breadcrumb, office, planning, user } from '@Store/modules'
import {OfficeHelper} from "@Helpers/office"
import {clone} from "lodash"

@Render
@Component({
	components: {
		'planning-item': PlanningItem
	}
})
export class ContextSecretary extends Vue {

	query: string = ""
	selected: string = null
	selectedOffice: string = null

	//#region getters
	get users(): any[] {
		return user.linkedUsers(Profile.Main).filter(i => (!!i.activate && i.activate.length === 0) &&  this.filterItem(i))
	}
	get offices(): any[] {
		let offices = clone(this.officesByUser)
		offices.unshift(OfficeHelper.createAllOfficeItem())

		return offices
	}
	get hasOffice() {
		return this.officesByUser.length > 0
	}
	get officesByUser(): any[] {
		if (!this.selected) return []
		return office.officesByUser(this.selected)
	}
	get plannings(): IPlanning[] {
		if (!this.selected) return []
		return planning.plannings.filter(p => { return p.usr_id === this.selected})
	}
	get hasPlanning(): boolean {
		return this.plannings.length > 0
	}
	private get mainLinkedUsers(): any[] {
		return user.linkedUsers(Profile.Main)
	}
	//#endregion

	mounted() {
		// par défaut, pour les comptes secrétaires, on ne sélectionne aucun utilisateur
		// cf. commentaires dans selectFirstUser
		this.selected = null
		user.selectLinkPlanningUser(null)

		breadcrumb.updateLinked(true)
		this.selectFirstUser()
	}

	@Watch('selected')
	updateSelected(): void {
		let user = this.mainLinkedUsers.find(user => user.id === this.selected)
		this.selectUser(user)
	}

	@Watch('selectedOffice')
	updateSelectedOffice(): void {
		let _office = this.offices.find(office => office.id === this.selectedOffice)
		office.selectedPlanningLinkOffice(_office)
	}

	private filterItem(item: any): boolean {
        if (!this.query || this.query.trim() === '') return true

        let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.query.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!item.first_name && item.first_name.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.last_name && item.last_name.toLowerCase().includes(filter)) {
				countResult++
            }
		}
		return countFilter === countResult
	}

	private selectUser(_user: any): void {
		if (!_user) return

		planning.toggleVisibilityByUser(_user.id)
		user.selectLinkPlanningUser(_user)
		this.selected = _user.id
		if (!this.hasOffice) {
			this.selectedOffice = null
		} else if (this.officesByUser.length > 1) {
			this.selectedOffice = this.offices[0].id
		} else {
			this.selectedOffice = this.officesByUser[0].id
		}
	}

	private selectFirstUser() {
		// si ce compte secrétaire ne gère qu'un seul compte, alors on le sélectionne par défaut
		// sinon, on n'en sélectionne aucun

		let selectedUser = user.selectedPlanningUser
		if (!selectedUser) {
			if (this.users.length !== 1) return

			selectedUser = this.users[0]
		}

		this.selectUser(selectedUser)
	}
}
