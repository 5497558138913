export const aside = {
    'back-patient': {
        text: "Retour aux patients",
        title: "Retournez à votre liste de patient"
    },
    'back-main': {
        text: "Retour au dossier",
        title: "Retournez aux informations générales"
    },
    antecedents: {
        text: "Antécédents pédiatriques",
        title: "Antécédents pédiatriques de ce patient"
    },
    neonatal: {
        text: "Néonatal",
        title: "Néonatal lié au dossier"
    },
    forms: {
        text: "Questionnaire personnalisé | Questionnaire personnalisé | Questionnaires personnalisés",
        title:"Questionnaire personnalisé de la périnatalité | Questionnaire personnalisé de la périnatalité | Questionnaires personnalisés de la périnatalité"
    }
}