import * as Event from '@ViewModels/root/calendar/event'
import { Profile } from '@Enums/profile'

export const EventRoot = function(prefix: string = ''): any[] {
    return [
        {
            path: '',
            name: `${prefix}event`,
            component: Event.RendezVous,
            meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
        },
        {
            path: 'recurrency',
            name: `${prefix}event-recurrency`,
            component: Event.Recurrency,
            meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
        },
        {
            path: 'relaunch',
            name: `${prefix}event-relaunch`,
            component: Event.Relaunch,
            meta: { permission: [Profile.Main, Profile.Demo, Profile.Secretary] }
        }
    ]
}
