<div class="l-header">

	<a href="#" role="button" data-toggle-navigation class="btn-in-header is-for-nav"
		v-toggle="{className: 'is-open', selector: '[data-toggle-navigation]'}"
		:title="$t('vm.components.layout.header.main.title')">
		<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true">
			<use xlink:href="@Assets/svg/symbol-defs.svg#bars" />
		</svg>
		<span class="sr-only">{{$t('vm.components.layout.header.main.label')}}</span>
	</a>

	<a href="#" role="button" data-toggle-aside class="btn-in-header is-for-aside"
		v-toggle="{className: 'is-open', selector: '[data-toggle-aside]'}"
		:title="$t('vm.components.layout.header.search.title')">
		<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-right" />
		</svg>
		<span class="sr-only">{{$t('vm.components.layout.header.search.label')}}</span>
	</a>

	<svg width="1em" class="logo svg-center" viewBox="0 0 761 100" role="img">
		<use xlink:href="@Assets/svg/symbol-defs.svg#logo" />
	</svg>

	<!-- This is for keeping a button space when no context button, to keep logo in place -->
	<span v-if="!linkedToContext" class="btn-in-header is-white-space" aria-hidden="true">
		<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true"></svg>
	</span>

	<fullscreen class="btn-in-header is-for-fullscreen" :responsive="true" />

	<button v-if="linkedToContext" type="button" data-toggle-context class="btn-in-header is-for-context is-mobile-closed"
		v-toggle="{className: 'is-mobile-closed', selector: '[data-toggle-context]', state:'context'}"
		:title="$t('vm.components.layout.header.context.title')">
		<svg width="1em" class="svg-block" viewBox="0 0 448 512" aria-hidden="true">
			<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-right" />
		</svg>
		<span class="sr-only">{{$t('vm.components.layout.header.context.label')}}</span>
	</button>

</div>
