import Render from '@Views/root/patients/merge/merge.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
	IExportFilter,
	IPatientSearch,
	IExportResult,
	IBreadcrumbItem, IPatientMerge
} from '@Store/types'
import { Debouncer } from '@Models/index'
import { InfiniteScroll } from '@Components/index'
import {breadcrumb, patients} from '@Store/modules'
import {ContextMerge} from "./context-merge"
import {MergeItem} from "@ViewModels/root/patients/merge/item"
import {Dictionary} from "vue-router/types/router"

@Render
@Component({
	components: {
		'infinite-scroll': InfiniteScroll,
		'context-merge': ContextMerge,
		'merge-item': MergeItem,
	}
})
export class Merge extends Vue {

	resultSearch: IPatientMerge[] = []
	searched: boolean = false
	searching: boolean = false
	percent: number = 0

	private mergeList: IPatientMerge[] = []
	private canLoadMore: boolean = true
	private page: number = 0
	private total: number = 0
	private debouncer: Debouncer = null

	//#region Getters
	get filter(): IExportFilter {
        return patients.filterExport
    }
	get mergeCount(): number {
		return this.resultSearch.filter(p => { return !!p.visible }).length
	}
	//#endregion

	mounted() {
		this.page = 0
		this.search()
		this.debouncer = new Debouncer(this.doFilter, 500)
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
	}

	beforeDestroy() {
		this.mergeList.length = 0
		this.resultSearch.length = 0
		Debouncer.clear(this.debouncer)
	}

	@Watch('filter.last_name')
	@Watch('filter.first_name')
	@Watch('filter.ofi_id')
	@Watch('filter.blacklisted')
	@Watch('filter.deceased')
	@Watch('filter.same_date')
	@Watch('filter.same_email')
	@Watch('filter.same_phone')
	updateFilter() {
		this.searching = true
		this.resultSearch = []
		this.debouncer.start()
	}

	private doFilter(): void {
		if (!this.searched) return

		this.resultSearch = this.filterDuplicate(this.mergeList)
	}

	private search(): void {
		this.searching = true
		this.searched = false

		let filter: IExportFilter = {
			first_name: "",
			last_name: "",
			ofi_id: "-1",
			duplicate: true
		}
		patients.searchExport({filter: filter, page: this.page, with_list: false})
		.then((result: IExportResult) => {
			if (!result) {
				setTimeout(this.search, 333)
				return
			}

			this.canLoadMore = this.total < result.total
			this.total += result.list.length
			this.percent = Math.round(this.total * 100 / result.total)
			let mergeList: IPatientMerge[] = this.filterPatient(result.list)
			this.mergeList.push(...mergeList)

			this.page++
			if (this.canLoadMore) {
				setTimeout(this.search, 50)
			} else {
				this.searched = true
				this.searching = false
				this.resultSearch = this.filterDuplicate(this.mergeList)
			}
		})
		.catch(error => {
			this.searched = true
			this.searching = false
			console.error(error)
		})
	}

	private filterPatient(list: IPatientSearch[]): IPatientMerge[] {
		let subResult: Dictionary<IPatientMerge> = {}
		for (let patient of list) {
			let key: string = this.filterKey(patient, true)
			let mergeData = {
				count: 0,
				visible: true,
				id_list: [],
				phones: [],
				emails: [],
				dates: [],
			}
			let merge: IPatientMerge = subResult[key] === undefined ? {...patient, ...mergeData} : subResult[key]
			merge.count++
			merge.id_list.push(patient.id)
			merge.phones.push(patient.phone)
			merge.emails.push(patient.email)
			merge.dates.push(patient.birth_date.toDateString())

			subResult[key] = merge
		}

		let result: IPatientMerge[] = []
		let keys = Object.keys(subResult)
		for(let key of keys) {
			if (subResult[key].count <= 1) continue
			result.push(subResult[key])
		}

		return result
	}

	private filterDuplicate(list: IPatientMerge[]): IPatientMerge[] {
		let result: IPatientMerge[] = []
		for (let patient of list) {
			if (!!this.filter.last_name && !patient.last_name.startsWith(this.filter.last_name.toLowerCase())) continue
			if (!!this.filter.first_name && !patient.first_name.startsWith(this.filter.first_name.toLowerCase())) continue
			if (!!this.filter.same_date && !Merge.sameArray(patient.dates)) continue
			if (!!this.filter.same_phone && !Merge.sameArray(patient.phones)) continue
			if (!!this.filter.same_email && !Merge.sameArray(patient.emails)) continue

			result.push(patient)
		}
		this.searching = false

		return result
	}

	private static sameArray(array: string[]): boolean {
		let base: string = undefined
		for(let value of array) {
			if (base === undefined) base = value
			else if (value !== base) return false
		}
		return true
	}

	private filterKey(patient: IPatientSearch, light: boolean): string {
		let key: string = `${patient.last_name}_${patient.first_name}`
		if (light) return key

		if (this.filter.same_date) key = `${key}_${patient.birth_date}`
		if (this.filter.same_email) key = `${key}_${patient.email}`
		if (this.filter.same_phone) key = `${key}_${patient.phone}`

		return key
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.merge').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
