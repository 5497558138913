import Render from '@Views/root/help/aside-closed.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ITicket } from '@Store/types'
import { support } from '@Store/modules'

@Render
@Component({})
export class ASideClosed extends Vue {

	private withLoading: boolean = false

	// @State('support') supportState: SupportState
	// @Getter('support/ticketsClosed') tickets: ITicket[]
	// @Getter('support/unreadClosedCommentCount') unreadClosedCommentCount: number
	// @Getter('support/unreadCommentCount') unreadCommentCount: number
	// @Action('support/loadClosedTickets') loadClosedTickets: () => Promise<any>

	get isLoading(): boolean {
		return this.withLoading && support.isClosedLoading
	}
	get unreadCommentCount(): number {
		return support.unreadCommentCount
	}
	get unreadClosedCommentCount(): number {
		return support.unreadClosedCommentCount
	}
	get tickets(): ITicket[] {
		return support.ticketsClosed
	}

	mounted() {
		this.updateTickets(true)
	}

	@Watch('unreadClosedCommentCount')
	private updateTickets(withLoading: boolean = false) {
		this.withLoading = withLoading
		support.loadClosedTickets()
	}
}