import Render from '@Views/root/calendar/planning-item.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IPlanning, IUser } from '@Store/types'
import { planning, user } from '@Store/modules'

@Render
@Component({})
export class PlanningItem extends Vue {

	@Prop() planning: IPlanning

	// @State('user') stateUser: UserState
	// @State('planning') statePlanning: PlanningState
	// @Mutation('popin/warning') warning: (popin: IPopin) => void
	// @Getter('user/isMainUser') isMainUser: boolean
	// @Action('planning/deletePlanning') deletePlanning: (payload: PlanningActionPayload) => Promise<any>
	// @Action('planning/toggleVisibilityAndSave') toggleVisibilityAndSave:(planning: IPlanning) => void

	get isDeleting(): boolean {
		return planning.isDeleting && planning.planningDeleting === this.planning.id
	}
	get isOwner(): boolean {
		return this.user.id === this.planning.usr_id
	}
	get user(): IUser {
		return user.user
	}

	@Watch('planning.visible')
	updateVisible() {
		if (!user.isMainUser) return

		planning.toggleVisibilityAndSave(this.planning)
	}

	submitDelete(): void {
		if (!this.canDelete()) return

		planning.deletePlanning({planning: this.planning})
		// .then(() => {
		// 	let pla_id = this.$route.params.pla_id
		// 	if (this.$route.name !== 'planning' || planning.id !== pla_id) return

		// 	this.$router.push({name: 'plannings'})
		// }
	}

	private canDelete(): boolean {
		return !!this.planning.id && parseInt(this.planning.id) !== -1
	}

	// private delete(): void {
	// 	let deleteAction: IPopinAction = { 
	// 		label: this.$i18n.t('general.suppress').toString(), 
	// 		callback: (data: any) => {
	// 			const {planning} = this
	// 			this.deletePlanning({planning: planning})
	// 			.then(() => {
	// 				let pla_id = this.$route.params.pla_id
	// 				if (this.$route.name !== 'planning' || planning.id !== pla_id) return
		
	// 				this.$router.push({name: 'plannings'})
	// 			})
	// 			return Promise.resolve()
	// 		}
	// 	} 
	// 	let deletePopin: IPopin = {
	// 		title: this.$i18n.t('vm.root.calendar.planning-item.suppress.title').toString(), 
	// 		content: this.$i18n.t('vm.root.calendar.planning-item.suppress.message').toString(), 
	// 		action: deleteAction
	// 	}
		
	// 	this.warning(deletePopin)
	// }
}