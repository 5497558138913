import { BaseService } from '@Services/index'
import {
	IPatient,
	PatientDuplicatePayload,
	PrintPatientPayload,
	IPatientFilter,
	IExportFilter,
	PatientMergePayload
} from '@Store/types'

export class PatientService extends BaseService {

	public reinitialize(): Promise<any> {
		return super.post(`/search/reinitialize/patients/`)
	}

	public searchPatient(query: string, filter: IPatientFilter): Promise<any> {
		return super.post(`/search/patients/${query}`, {filter: filter})
	}

	public searchExport(filter: IExportFilter, page: number, with_list: boolean): Promise<any> {
		return super.post(`/export/patient`, {filter: filter, page: page, with_list: with_list})
	}

	public getPatient(pat_id: string): Promise<any> {
		return super.get(`/patient/${pat_id}`)
	}

	public deletePatient(pat_id: string): Promise<any> {
		return super.delete(`/patient/${pat_id}`)
	}

	public print(payload: PrintPatientPayload): Promise<any> {
		return super.specificPost(`/print/patient/${payload.pat_id}/patient.pdf`, payload.params)
	}
	public save(patient: IPatient, callbackProgress?: (progress: number) => void): Promise<any> {
		if (patient.is_new)
			return super.post('/patient', patient, undefined, undefined, callbackProgress)

		return super.put(`/patient/${patient.id}`, patient, undefined, callbackProgress)
	}

	public merge(payload:PatientMergePayload): Promise<any> {
		return super.post(`/patients/merge`, payload)
	}

	public confirm(id: string, date: Date): Promise<any> {
		return super.put(`/patient/${id}/share`, {birth_date: date})
	}

	public blacklist(id: string, blacklisted: boolean): Promise<any> {
		return super.put(`/patient/${id}/blacklist`, {blacklisted: blacklisted})
	}

	public dead(id: string, deceased: boolean): Promise<any> {
		return super.put(`/patient/${id}/dead`, {deceased: deceased})
	}

	public checkDuplicate(payload: PatientDuplicatePayload): Promise<any> {
		return super.post('/patient/duplicate/', payload)
	}

	public getDuplicate(pat_id: string): Promise<any> {
		return super.get(`/patient/duplicate/${pat_id}`)
	}

	public getBackup(): Promise<any> {
		return super.get(`/backup/patients`)
	}

}
