<container class="is-without-aside">
	<master>

		<central>
			<div class="wrapper-full marg-v dashboard">
				<div class="childs-space-between marg-v">
					<h1 class="no-marg">
						{{ helloMessage }}
						<spinner v-if="isWidgetLoading" />
					</h1>
					<button class="btn--link-like is-pushed-right" @click="addStickyNote" :title="$t('vm.root.home.main.new-sticky-note.title')">
						<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#sticky-note"/>
						</svg>
						{{ $t('vm.root.home.main.new-sticky-note.label') }}
					</button>
				</div>

				<div v-if="isStickyNoteLoading" class="marg-v">
					<div class="sticky-note">
						<div class="sticky-note-loading">
							<spinner class="svg-xl text-primary"/>
						</div>
					</div>
				</div>

				<transition-group v-else name="appear" tag="div" class="sticky-note-container marg-v">
					<sticky-note :key="getStickyNoteId(stickyNote)" v-for="stickyNote in stickyNotes" :sticky-note="stickyNote" @delete="onDelete"/>
				</transition-group>

				<div class="grid-3 vertical-gap">
					<widget-referral/>

					<widget v-for="widget in widgets" :key="widget.type" :widget="widget"/>

					<section class="dashboard-widget" :class="{'is-expanded': expand}">
						<div class="dashboard-widget-header childs-space-between">
							<h2 class="text-md no-marg">{{ $t('vm.root.home.main.change-log') }}</h2>
							<button class="dashboard-widget-expand-btn btn--link-like is-pushed-right" :class="{'is-expanded': expand}"
								type="button"
								@click="expand = !expand"
								:title="expand ? $t('vm.root.home.widget.collapse.title') : $t('vm.root.home.widget.expand.title')">
								<span v-if="!expand" class="rwd-over-large">{{ $t('vm.root.home.widget.expand.label') }}</span>
								<span v-else class="rwd-over-large">{{ $t('vm.root.home.widget.collapse.label') }}</span>
								<svg width="1em" class="dashboard-widget-expand-icon svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-bottom"/>
								</svg>
							</button>
						</div>

						<div class="dashboard-widget-content scrollbar">
							<div class="dashboard-widget-with-padding" v-html="changeLog.log"/>
						</div>
					</section>

				</div>
			</div>
		</central>

		<context-news/>

	</master>
</container>
