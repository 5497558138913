<div class="l-context is-mobile-closed" data-toggle-context>
	<div class="l-context-inner">
		<button type="button" v-toggle.main="{className: 'is-open is-mobile-closed', selector: '[data-toggle-context]', state:'context'}" class="l-context-btn-close rwd-over-small rwd-under-medium" :title="$t('vm.components.layout.context.close.title')">
			<svg width="1em" class="svg-block" viewBox="0 0 320 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<span class="sr-only">{{$t('vm.components.layout.context.close.label')}}</span>
		</button>
		<div class="l-context-inner-scroll" ref="scrollContainer">
    		<slot/>
    	</div>
    </div>
</div>
