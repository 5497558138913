import {IOffice} from "@Store/types"

export class Sorter {

    private offices: IOffice[]
	private ofi_id: string

    public nearest(offices: IOffice[], usrId: string): Promise<any> {
		if (!offices || offices.length === 0) return undefined

		this.offices = offices
        return this.geolocation(usrId)
    }

    private geolocation(usrId: string): Promise<any> {
		let vm = this
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					resolve(vm.geolocationSuccess(usrId, position))
				},
				() => {
					reject(vm.geolocationError())
				})
		})
    }

	private geolocationSuccess(usrId: string, position: {coords: {latitude: number, longitude: number}}) {
		if (!position || !position.coords) {
			this.ofi_id = this.offices[0].id
			return
		}

		let coordinates: {lat: number, lng: number} = {lat: position.coords.latitude, lng: position.coords.longitude}
		let sortedOffices = this.offices.sort((a: IOffice, b: IOffice) => {
			if (coordinates.lat === undefined || coordinates.lng === undefined) return 0

			let distance1: number = Sorter.distance(coordinates, {lat: a.coordinates.latitude, lng: a.coordinates.longitude})
			let distance2: number = Sorter.distance(coordinates, {lat: b.coordinates.latitude, lng: b.coordinates.longitude})
			if (distance1 < distance2) return -1
			if (distance2 < distance1) return 1
			if (a.creator_usr_id === usrId) return -1
			if (b.creator_usr_id === usrId) return 1
			return 0
		})
		return sortedOffices[0].id
	}

	private geolocationError() {
		return this.offices[0].id
    }

	private static distance(a:{lat: number, lng: number}, b:{lat: number, lng: number}): number {
		return Math.round(Math.acos(Sorter.positionToVector(a).scale(Sorter.positionToVector(b))) * 20000 / Math.PI)
	}

	private static positionToVector(point:{lat: number, lng: number}): Vector {
		let lt: number = point.lat * Math.PI/180
		let ln: number = point.lng * Math.PI/180
		return new Vector(Math.cos(lt) * Math.cos(ln), Math.cos(lt) * Math.sin(ln), Math.sin(lt))
	}
}

class Vector {
	private x: number
	private y: number
	private z: number

	constructor(x: number, y: number, z: number)  {
		this.x = x
		this.y = y
		this.z = z
	}

	clone(): Vector {
		return new Vector(this.x,this.y,this.z)
	}

	multiple(k:number): Vector {
		return new Vector(k * this.x, k * this.y, k * this.z)
	}
	add(): Vector {
		let a = arguments, x = this.x, y = this.y, z = this.z
		for (let i = 0; i < a.length; i++) {
			x += a[i].x
			y += a[i].y
			z += a[i].z
		}
		return new Vector(x,y,z)
	}
	scale(v: Vector): number {
		return this.x * v.x + this.y * v.y + this.z * v.z
	}
	prd(v: Vector): Vector {
		return new Vector(this.y * v.z - v.y * this.z, this.z * v.x - v.z * this.x, this.x * v.y - v.x * this.y)
	}
	normalize(): Vector {
		let t: number = Math.sqrt(this.scale(this))
		return new Vector(this.x / t, this.y / t, this.z / t)
	}
	toString(): string { return JSON.stringify(this) }
}

