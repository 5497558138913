<div>
	<section class="wrapper marg-v">

		<div class="childs-space-between marg-b">
			<h2 class="no-marg">{{ $t('vm.root.statistics.consultations.context.title') }}</h2>
			<div class="is-pushed-right">
				<spinner v-if="isLoading" class="text-primary text-lg svg-block"/>
			</div>
		</div>

		<dropdown-frame id="user" v-model="filter.usr_id" :items="userItems"
			:label="$t('vm.root.statistics.consultations.context.user.label')"
			:title="$t('vm.root.statistics.consultations.context.user.title')"/>

		<dropdown-frame id="office" v-model="filter.ofi_id" :items="officeItems"
			:label="$t('vm.root.statistics.consultations.context.office.label')"
			:title="$t('vm.root.statistics.consultations.context.office.title')"/>

		<input-frame type="number" id="year1" v-model="filter.year1"
			:label="$t('vm.root.statistics.consultations.context.year1.label')"
			:placeholder="$t('vm.root.statistics.consultations.context.year1.placeholder')"
			:title="$t('vm.root.statistics.consultations.context.year1.title')"/>

		<input-frame type="number" id="year2" v-model="filter.year2"
			:label="$t('vm.root.statistics.consultations.context.year2.label')"
			:placeholder="$t('vm.root.statistics.consultations.context.year2.placeholder')"
			:title="$t('vm.root.statistics.consultations.context.year2.title')"/>

		<div class="marg-b">
			<collapsible-panel>
				<span slot="title">{{ $t('vm.root.statistics.accounting.context.months') }}</span>
				<checkbox-frame v-for="(month, index) in months" :key="index" :id="index" v-model="filter.months[index]">{{ month }}</checkbox-frame>
			</collapsible-panel>
		</div>

		<collapsible-panel>
			<span slot="title">{{ $t('vm.root.statistics.consultations.context.weeks') }}</span>
			<input-frame type="number" id="week" v-model="filter.week"
						 :min="1"
						 :max="53"
						 :label="$t('vm.root.statistics.consultations.context.weeks')"
						 :title="$t('vm.root.statistics.consultations.context.weeks')"/>
		</collapsible-panel>

	</section>
</div>
