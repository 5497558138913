export const contextBulk = {
	'running-session': "Session en cours",
	'your-session': "Votre session",
	'end-session': "Fin de la session dans :",
	'session-terminated': "session terminée",
	'count-reservation': "Aucune réservation, soit :|Déjà une réservation, soit :|Déjà {count} réservations, soit :",
	'missing-reservation': "Vous êtes au max|Encore une réservation pour bénéficier de :|Encore {count} réservations pour bénéficier de :",
	'reservation-obtained': "Aucune réservation obtenue, soit :|Une réservation obtenue, soit :|{count} réservations obtenues, soit :",
	reduction: "{0}{1}de réduction",
	countdown: {
		days: "0 jr | 1 jr | {count} jrs",
		hours: "0 h | 1 h | {count} h",
		minutes: "0 min | 1 min | {count} min",
		seconds: "0 sec | 1 sec | {count} sec",
	}
}
