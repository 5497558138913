<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.planning.title') }}</h1>
	<section class="grid-2">
		<dropdown-frame id="slot" v-model="configuration.slot" :items="slots"
			:title="$t('vm.root.parameters.planning.slot.title')"
			:label="$t('vm.root.parameters.planning.slot.label')"/>
		<dropdown-frame id="refresh-delay" v-model="configuration.refresh_calendar_delay" :items="delays"
			:title="$t('vm.root.parameters.planning.refresh-delay.title')"
			:label="$t('vm.root.parameters.planning.refresh-delay.label')"/>

		<input-range v-model='range' :type="rangeType" class="grid-item-full grid-2" :steps="60">
			<input-frame id="startRange" :random-id="false"
				:label="$t('vm.root.parameters.planning.range.start.label')"
				:title="$t('vm.root.parameters.planning.range.start.title')"/>
			<input-frame id="endRange" :random-id="false"
				:label="$t('vm.root.parameters.planning.range.end.label')"
				:title="$t('vm.root.parameters.planning.range.end.title')"/>
		</input-range>

		<div class="input-frame grid-item-full">
			<label class="label" :data-text="$t('vm.root.parameters.planning.displayed-days')">
				{{$t('vm.root.parameters.planning.displayed-days')}}
			</label>
			<div class="recurring-weekdays">
				<div v-for="day in weekdays" class="input-frame">
					<div class="checkbox">
						<input type="checkbox" :key="day.index" :id="day.index" v-model="displayDays" :value="day.index">
						<label :for="day.index" :title="day.normal">
							<svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
							<span class="sr-only">{{day.normal}}</span>
						</label>
					</div>
					<label :for="day.index">{{day.normal}}</label>
				</div>
			</div>
		</div>

		<checkbox-frame v-if="isMainUser" id="connect-google" class="grid-item-full" v-model="configuration.connect_google"
			:title="$t('vm.root.parameters.planning.google.title')">
			{{ $t('vm.root.parameters.planning.google.label') }}
		</checkbox-frame>
	</section>
</div>
