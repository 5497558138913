import Render from '@Views/root/patients/patient/attachment/main.html'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {IAttachment, IBreadcrumbItem, INotification} from '@Store/types'
import {InputDateType, IToggleBoxItem} from '@Components/index'
import {AttachmentType, MimeType} from '@Enums/index'
import {EnumsHelper} from '@Helpers/index'
import {Common, Patient} from '@Models/index'
import {breadcrumb, notif, patient, user} from '@Store/modules'
import Compressor from 'compressorjs'

@Render
@Component({
	beforeRouteUpdate(to, from, next) {
		next()
		Main.createAttachment(to.params.item_id, this)
	}
})
export class Main extends Vue {

	private static readonly maxSize: number = 3
	private oldDescription: string = null
	private allowedTypes:string[] = ['image/png', 'image/jpeg', 'application/pdf']

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	//#region Getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get attachment(): IAttachment {
		return patient.attachment(this.$route.params.item_id)
	}
	get hasNameError(): boolean {
		return this.submitted && !this.attachment.name
	}
	get hasActDateError(): boolean {
		return this.submitted && !this.attachment.act_date
	}
	get hasLinkError(): boolean {
		let validUrl: boolean = this.isFile || Patient.checkAttachmentLink(this.attachment.description)
		return this.submitted && !validUrl
	}
	get hasFileError(): boolean {
		return this.submitted && this.attachment.error
	}
	get isFile(): boolean {
		return this.attachment.type === AttachmentType.File
	}
	get isPdf(): boolean {
		return this.attachment.mime === MimeType.Pdf
	}
	get isFirst(): boolean {
		return this.index === patient.attachments.length -1
	}
	get isLast(): boolean {
		return this.index === 0
	}
	get index(): number {
		return patient.attachments.findIndex(c => c === this.attachment)
	}
	get attachmentItems(): IToggleBoxItem[] {
		return [
			{value: AttachmentType.File, label: EnumsHelper.attachmentToString(AttachmentType.File), icon: 'image'},
			{value: AttachmentType.Link, label: EnumsHelper.attachmentToString(AttachmentType.Link), icon: 'link'}
		]
	}
	get actDateType(): InputDateType {
		return InputDateType.Calendar
	}
	get fileUrl(): string {
		if (!!this.attachment.url) return this.attachment.url
		return this.attachment.file
	}
	//#endregion

	private static createAttachment(item_id: string, vm: Main = undefined, duplicate: IAttachment = undefined) {
		if (!!item_id && !!patient.attachment(item_id)) return

		let createId: string = Common.generateId()
		let attachment: IAttachment = Patient.createNewAttachment(createId)
		if (!!duplicate) {
			attachment.name = duplicate.name
			attachment.act_date = duplicate.act_date
			attachment.type = duplicate.type
		}
		patient.attachments.push(attachment)
		if (!vm) return

		vm.$emit('edit')
		vm.$router.replace({name:'patient-attachment', params:{pat_id: vm.$route.params.pat_id, item_id: createId}})
	}

	created() {
		Main.createAttachment(this.$route.params.item_id, this)
	}

	mounted() {
		this.$emit('navigate', 'attachment')
		this.initializeBreadcrumb()
	}

	duplicate() {
		Main.createAttachment(undefined, this, this.attachment)
	}

	@Watch('readOnly')
	mustBackOnList() {
		if (!this.readOnly || parseInt(this.attachment.id) !== -1) return

		this.deleteAttachment()
	}

	@Watch('isImage')
	updateType() {
		if (this.oldDescription === null) {
			this.oldDescription = this.attachment.description
			this.attachment.description = ''
		} else {
			this.attachment.description = this.oldDescription
			this.oldDescription = null
		}
	}

	deleteAttachment(): void {
		patient.deleteAttachment(this.attachment)
		this.$router.push({name:'patient-attachments', params:{pat_id: this.$route.params.pat_id}})
	}

	onChange(event) {
		let selectedFile = event.target.files[0]
		this.compressFile(selectedFile)
	}

	openLink(url: string): void {
		window.open(url, '_blank').focus()
	}

	next() {
		if (this.isLast) return

		this.moveTo(this.index - 1)
	}

	previous() {
		if (this.isFirst) return

		this.moveTo(this.index + 1)
	}

	private moveTo(index: number) {
		let attachment: IAttachment = patient.attachments[index]
		this.$router.push({name:this.$route.name, params:{pat_id:this.$route.params.pat_id, item_id: this.getRouteId(attachment)}})
	}

	private getRouteId(attachment: IAttachment): string {
		return parseInt(attachment.id) === -1 ? attachment.create_id : attachment.id
	}

	private compressFile(file: File) {
		let vm = this
		if (file.type === "application/pdf") {
			if (!vm.checkFile(file)) return

			const reader = new FileReader()
			reader.readAsBinaryString(file)
			reader.onload = () => {
				let binaryData = reader.result as string
				vm.attachment.file = `data:application/pdf;base64,${window.btoa(binaryData)}`
				vm.attachment.mime = MimeType.Pdf
			}
		} else {
			new Compressor(file, {
				quality: 0.8,
				convertSize: 2000000,
				mimeType: "image/jpeg",
				maxWidth: 1400,
				maxHeight: 1400,
				success(imageBlob) {
					if (!vm.checkFile(imageBlob)) return

					const reader = new FileReader()
					reader.readAsDataURL(imageBlob)
					reader.onload = () => {
						vm.attachment.file = reader.result as string
						vm.attachment.mime = MimeType.Jpeg
					}
				},
				error(err) {
					let notification: INotification = {message: err.message, actions:[], delay: 5000, canClose: true}
					notif.error(notification)
				},
			})
		}
	}

	private checkFile(selectedFile: File | Blob): boolean {
		let size:number = Main.formatBytes(selectedFile.size, 3)
		let message: string = null

		if (size > Main.maxSize) {
			message = this.$i18n.t('vm.root.patients.patient.attachment.max_size', {max_size: Main.maxSize, size: size}).toString()
			this.attachment.error = true
		}
		if (!this.allowedTypes.find(type => type === selectedFile.type)) {
			message = this.$i18n.t('vm.root.patients.patient.attachment.wrong_format', {type: selectedFile.type}).toString()
			this.attachment.error = true
		}

		if (!message) {
			this.attachment.error = false
			return true
		}

		let notification: INotification = {message: message, actions:[], delay: 5000, canClose: true}
		notif.error(notification)
		return false
	}

	private static formatBytes(bytes: number, decimals: number): number {
		if (bytes === 0) return 0

		let k: number = 1024
		let dm: number = decimals <= 0 ? 0 : decimals || 2

		return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm))
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.attachment', 2).toString(), link: {name: "patient-attachments", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.attachment', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}
