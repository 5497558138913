import Render from '@Views/root/accounting/book/main.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ASideBook } from './aside-book'
import { ContextBook } from './context-book'
import { IInvoice, IBookFilter, IBreadcrumbItem, AccountingActionPayload } from '@Store/types'
import { InvoiceType, PaymentMode } from '@Enums/index'
import config from "@Config/index"
import { Debouncer } from '@Models/index'
import { EnumsHelper } from '@Helpers/index'
import { accounting, breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'aside-book': ASideBook,
		'context-book': ContextBook
	}
})
export class Main extends Vue {

	searched: boolean = false
	searching: boolean = false
	selectAll: boolean = false
	action: number = 1
	invoices: IInvoice[] = []
	totalReceipt: number = 0
	totalRetrocession: number = 0
	totalSpend: number = 0
	totalReceiptHt: number = 0
	totalRetrocessionHt: number = 0
	totalSpendHt: number = 0
	private debouncerFilter: Debouncer = null
	private allInvoices: IInvoice[] = []

	get filter(): IBookFilter {
		return accounting.bookFilter
	}
	get defaultCurrency(): string {
		return !!this.invoices && this.invoices.length > 0 ? this.invoices[0].currency_i18n : 'fr'
	}
	get countSelectedLines(): number {
		return this.selectedLines.length
	}
	get bookPath(): string {
		let filter: Record<string, string> = this.getFilterList()
		let params:string = new URLSearchParams(filter).toString()
		return `${config.api_url}/${config.api_version}/${config.api_key}/print/books/list.pdf?${params}`
	}
	private get selectedLines(): IInvoice[] {
		return this.invoices.filter(i => { return i.selected })
	}
	private get isDemo(): boolean {
		return !!location.hostname && location.hostname.startsWith('demo')
	}

	mounted() {
		this.debouncerFilter = new Debouncer(this.applyFilter, 333)
		if (!this.filter) {
			accounting.resetBookFilter()
		}
		breadcrumb.updateLinked(true)
		this.initializeBreadcrumb()
		this.initializeInvoices()
	}

	getTotal(): number {
		let receipts = !this.invoices ? [] : this.invoices.filter(i => this.isReceipt(i))
		let retrocessions = !this.invoices ? [] : this.invoices.filter(i => this.isReceipt(i) && i.retrocession > 0)
		let spends = !this.invoices ? [] : this.invoices.filter(i => !this.isReceipt(i))

		this.totalReceipt = receipts.length === 0 ? 0 : receipts.map(i => i.ttc).reduce((prev, next) => prev + next)
		this.totalReceiptHt = receipts.length === 0 ? 0 : receipts.map(i => this.getValueHt(i, i.ttc)).reduce((prev, next) => prev + next)

		this.totalRetrocession = retrocessions.length === 0 ? 0 : retrocessions.map(i => i.retrocession).reduce((prev, next) => prev + next)
		this.totalRetrocessionHt = retrocessions.length === 0 ? 0 : retrocessions.map(i => this.getValueHt(i, i.retrocession)).reduce((prev, next) => prev + next)

		this.totalSpend = spends.length === 0 ? 0 : spends.map(i => i.ttc).reduce((prev, next) => prev + next) + this.totalRetrocession
		this.totalSpendHt = spends.length === 0 ? 0 : spends.map(i => this.getValueHt(i, i.ttc)).reduce((prev, next) => prev + next) + this.totalRetrocessionHt

		return this.totalReceipt - this.totalSpend
	}
	getValueHt(invoice: IInvoice, value: number): number {
		if (!value) return 0
		if (!invoice.tva) return value

		return Math.round(value * 100 / (100 + invoice.tva))
	}
	getIcon(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode, true)
	}

	getModeLabel(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode)
	}

	isReceipt(invoice: IInvoice): boolean {
		return !!invoice && invoice.type === InvoiceType.Receipt
	}

	@Watch('selectAll')
	updateSelectAll(): void {
		this.invoices.forEach(i => { i.selected = this.selectAll })
	}

	//#region private methods
	private getFilterList(): Record<string, string> {
		let filter: Record<string, string> = {}
		filter['ofi_id'] = this.filter.ofi_id
		filter['start_date'] = `${this.$d(this.filter.day_date, 'yyyy')}-${this.$d(this.filter.day_date, 'mm')}-${this.$d(this.filter.day_date, 'dd')}`
		if (this.isDemo) filter['demo'] = true.toString()

		return filter
	}

	@Watch('filter', {deep: true})
	private initializeInvoices() {
		this.debouncerFilter.start()
	}

	private applyFilter() {
		this.searching = true
		this.searched = false
		this.invoices = []

		let payload: AccountingActionPayload = {
			usr_id: '-1',
			ofi_id: this.filter.ofi_id,
			start: this.filter.day_date,
			end: this.filter.day_date
		}
		accounting.loadInvoices(payload)
		.then((invoices: IInvoice[]) => {
			this.searching = false
			this.allInvoices = invoices
			this.filterInvoices()
		})
		.catch(() => {
			this.searching = false
			this.searched = true
		})
	}

	private filterInvoices(): void {
		if (!!this.allInvoices && this.allInvoices.length > 0) {
			this.invoices = this.allInvoices.filter(this.filterInvoice)
		}

		this.searched = true
	}

	private filterInvoice(invoice: IInvoice): boolean {
		let result: boolean = true

		result = result && this.filterByType(invoice)

		return result
	}

	private filterByType(invoice: IInvoice): boolean {
		return invoice.type === InvoiceType.Receipt
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.accounting').toString(), link: "accounting" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.books').toString() }
		breadcrumb.updateItems([item1, item2])
	}
	//#endregion
}
