<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.relaunch.title') }}</h1>
	<section>
		<dropdown-frame id="relaunch-type" v-model="configuration.relaunch_type" :items="types"
			:title="$t('vm.root.parameters.relaunch.type.title')"
			:label="$t('vm.root.parameters.relaunch.type.label')"/>

		<input-frame v-if="!cannotSend" type="number" min="1" max="400" id="relaunch-delay" v-model="configuration.relaunch_delay"
					 :label="$t('vm.root.parameters.relaunch.delay.label')"
					 :placeholder="$t('vm.root.parameters.relaunch.delay.placeholder')"
					 :title="$t('vm.root.parameters.relaunch.delay.title')"/>

		<div v-if="canSendSms">
			<collapsible-help id="relaunch-information" :title="$t('vm.root.parameters.relaunch.details.title')">
				<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
				</svg>
				<div class="notice-info">
					<p class="text-left">
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
						</svg>
						<i18n path="vm.root.parameters.relaunch.details.text1.content" tag="span">
							<strong>{{ $tc('vm.root.parameters.relaunch.details.text1.strong', configuration.relaunch_delay) }}</strong>
						</i18n>
					</p>
					<i18n path="vm.root.parameters.relaunch.details.text2.content" tag="p" class="text-left">
						<strong>{{ $t('vm.root.parameters.relaunch.details.text2.strong') }}</strong>
					</i18n>
					<i18n path="vm.root.parameters.relaunch.details.text3.content" tag="p" class="text-left">
						<em>{{ $t('vm.root.parameters.relaunch.details.text3.em') }}</em>
						<strong>{{ $t('vm.root.parameters.relaunch.details.text3.strong1') }}</strong>
						<a href="https://osteo2ls.com/rdv/" target="_blank" :title="$t('vm.root.parameters.relaunch.details.text3.link-title')">
							{{ $t('vm.root.parameters.relaunch.details.text3.link-label') }}
						</a>
						<strong>{{ $t('vm.root.parameters.relaunch.details.text3.strong2') }}</strong>
					</i18n>
				</div>
			</collapsible-help>

			<div class="childs-space-between" :class="user.sms_count | smsClass">
				<i18n path="vm.root.parameters.relaunch.sms.balance.content" tag="span">
					<strong>{{ $t('vm.root.parameters.relaunch.sms.balance.strong', {'sms-count': user.sms_count}) }}</strong>
				</i18n>
				<router-link class="text-strong" :to="to" :title="$t('vm.root.parameters.relaunch.sms.title')">
					{{ $t('vm.root.parameters.relaunch.sms.label') }}
				</router-link>
			</div>

			<textarea-frame id="relaunch-text" v-model="configuration.relaunch_text"
				:label="$t('vm.root.parameters.relaunch.text.label', {length: relaunchTextLength})"
				:error-test="relaunchError" :error-msg="relaunchErrorMessage"
				:placeholder="$t('vm.root.parameters.relaunch.text.placeholder')"
				:title="$t('vm.root.parameters.relaunch.text.title')"/>
		</div>
	</section>
</div>
