<div v-if="!!stickyNote" class="sticky-note" :class="stickyNote.color | foregroundClass" :style="`--bg: ${stickyNote.color}; --font-size: ${computedSize}`">

	<label class="btn--link-like sticky-note-control sticky-note-color" :title="$t('vm.root.home.sticky-note.color.title')">
		<svg width="1em" class="svg-block" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#paint-roller"/>
		</svg>
		<input type="color" class="hide-input" :id="`post-it-color-${stickyNote.id}`" v-model="stickyNote.color">
		<span class="sr-only">{{ $t('vm.root.home.sticky-note.color.label') }}</span>
	</label>

	<button v-if="canShare" class="btn--link-like sticky-note-control sticky-note-share" :title="$t('vm.root.home.sticky-note.share.title')" @click="share">
		<spinner v-if="isSharing"/>
		<svg v-else width="1em" class="svg-block" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#share"/>
		</svg>
		<span class="sr-only">{{ $t('vm.root.home.sticky-note.share.label') }}</span>
	</button>

	<delete-item class="btn--link-like sticky-note-control sticky-note-delete" :can-delete="true" :delete-action="submitDelete"
		:title="$t('vm.root.home.sticky-note.delete.title')" :disabled="isDeleting"
		:delete-msg="$t('vm.root.home.sticky-note.delete.confirm')">
		<spinner v-if="isDeleting"/>
		<svg v-else width="1em" class="svg-block" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
		</svg>
		<span class="sr-only">{{ $t('vm.root.home.sticky-note.delete.label') }}</span>
	</delete-item>

	<spinner v-if="isSaving" class="sticky-note-spinner" :title="$t('vm.root.home.sticky-note.saving')" />

	<textarea class="sticky-note-input text-handwriting scrollbar" v-model="stickyNote.notes" placeholder="…" autocomplete="off" autocorrect="off"/>

	<span class="sticky-note-shadow" aria-hidden="true"/>

</div>
