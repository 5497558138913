export const shareReferral = {
    text1: "Veuillez indiquer quelques informations concernant l'email que vous souhaitez envoyer à votre futur filleul.",
    email: {
        label: "Email de la personne à parrainer",
        placeholder: "ex : filleul@parrain.com",
        error: "Merci de renseigner l'email de votre futur filleul",
        title: "Indiquez l'email de votre futur filleul"
    },
    'share-text': {
        label: "Contenu de l'email",
        placeholder: "ex : je souhaite te parrainer…",
        error: "Merci de renseigner un contenu à l'email",
        title: "Indiquez un contenu pour l'email que vous souhaitez envoyer à votre futur filleul"
    }
}