import Render from '@Views/root/help/ticket.html'
import { groupBy } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { INotification, IBreadcrumbItem, IComment, IUser, ITicket } from '@Store/types'
import { breadcrumb, notif, support, user } from '@Store/modules'

@Render
@Component({})
export class Ticket extends Vue {
	
	submitted: boolean = false
	comment: string = ''
	private interval: NodeJS.Timeout

	@Prop({default: false}) closed: boolean

	// @State('support') supportState: SupportState
	// @State('user') stateUser: UserState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Mutation('notification/info') info: (message: string | INotification) => void
	// @Getter('support/ticket') ticket: ITicket
	// @Action('support/loadTicket') loadTicket: (payload: TicketLoadPayload) => Promise<any>
	// @Action('support/loadCount') loadCount: () => Promise<any>
	// @Action('support/sendComment') sendComment: (payload: CommentSentPayload) => Promise<any>
	// @Action('support/createTicket') createTicket: (ticket: ITicket) => Promise<any>

	get user(): IUser {
		return user.user
	}
	get isClosed(): boolean {
		return this.closed || support.isTicketClosed
	}
	get ticket(): ITicket {
		return support.ticket
	}
	get isPosting() {
		return support.isPosting
	}
	get isLoading() {
		return support.isLoading
	}
	get hasCommentError(): boolean {
		return this.submitted && !this.comment
	}
	get commentsGrouped(): Dictionary<IComment[]> {
		if (!this.ticket) return {}

		this.ticket.comments.sort((a: IComment, b: IComment) => { return a.date_create.getTime() - b.date_create.getTime() })
		let comments: Dictionary<IComment[]> = groupBy(this.ticket.comments, comment => this.getKey(comment))
		let keys: string[] = Object.keys(comments)
		let result: Dictionary<IComment[]> = {}
		let alreadyUnread = false
		for(let key of keys) {
			let list: IComment[] = this.regroupComments(comments[key], keys.lastIndexOf(key) === keys.length - 1, alreadyUnread)
			result[key] = list

			alreadyUnread = list.filter(comment => comment.unread).length > 0
		}
		return result
	}
	//For Watch
	get supId(): string {
		return this.$route.params.sup_id
	}

	mounted() {
		this.initializeBreadcrumb()
		this.updateTicket()
		this.interval = setInterval(this.initializeTicket, 300000)
	}

	beforeDestroy() {
		clearInterval(this.interval)
	}

	submitPost(): void {
		this.submitted = true
		this.postComment()
	}

	mainDate(date: Date): string {
		if (!date) return ''
		return date.toISOString().split('T')[0]
	}

	@Watch('supId')
	private updateTicket() {
		this.initializeTicket(true)
	}

	private postComment() {
		if (!this.canPost()) return

		support.sendComment({sup_id: this.ticket.id, comment: this.comment.trim()})
		.then(() => setTimeout(() => {
			this.moveToLastComment(true)
			this.commentPosted()
		}))
	}

	private commentPosted(): void {
		this.comment = ''
		this.submitted = false
	}

	private canPost(): boolean {
		return !!this.comment
	}

	private getKey(comment: IComment): string {
		let key: string = this.$d(comment.date_create, 'll')
		let today: Date = new Date()
		if (key === this.$d(today, 'll')) 
			return this.$i18n.t('general.today').toString()
		
		today.setDate(today.getDate() - 1)
		if (key === this.$d(today, 'll')) 
		return this.$i18n.t('general.yesterday').toString()

		return key
	}

	private regroupComments(comments: IComment[], lastGroup: boolean, alreadyUnread: boolean): IComment[] {
		if (!comments || comments.length === 0) return []

		let result: IComment[] = []
		let comment: IComment
		for(let c of comments) {
			if (!comment) {
				comment = {...c}
				continue
			}

			if (comment.admin === c.admin && this.$d(comment.date_create, 'LT') === this.$d(c.date_create, 'LT')) {
				comment.content = `${comment.content}<br>${c.content}`.trim()
				comment.unread = (comment.unread || c.unread) && !alreadyUnread
			} else {
				result.push(comment)
				alreadyUnread = comment.unread
				c.unread = c.unread && !alreadyUnread
				comment = {...c}
			}
		}
		comment.isLast = lastGroup
		result.push(comment)

		return result
	}

	private moveToLastComment(move: boolean): void {
		if (!move) return

		let last:HTMLElement = document.querySelector('#last') as HTMLElement
		if (!last) return
				
		let bar:HTMLElement = document.querySelector('#control-bar') as HTMLElement
		if (!bar) {
			last.scrollIntoView(false)
			return
		}

		let centralElement:HTMLElement = document.getElementsByClassName('l-central')[0] as HTMLElement
		if (!centralElement) return

		centralElement.scrollTop = last.offsetTop - bar.offsetHeight - last.offsetHeight
	}

	private initializeTicket(move: boolean = false) {
		support.loadTicket({sup_id: this.$route.params.sup_id || '-1'})
		.then(ticket => {
			let hasNewComment = !!ticket && !!ticket.comments && ticket.comments.filter(comment => { return !!comment.unread }).length > 0
			this.moveToLastComment(move || hasNewComment)
			support.loadCount()
			.then(result => { support.unreadTickets(result) })
			
			if (!hasNewComment) return
			this.displayNotification()
		})
	}

	private displayNotification() {
		let message: string = this.$i18n.t('vm.root.help.ticket.notification').toString()
		let notification: INotification = { message: message, delay: 5000, actions: [], canClose: true }

		notif.info(notification)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.help').toString(), link: "help" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.ticket').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}