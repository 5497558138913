<div v-if="pediatrics">
	<read-only v-if="readOnly" :forms="forms"/>
	<div v-else>
		<h2>{{ $tc('vm.root.patients.patient.forms.title', forms.length) }}</h2>
	
		<div class="notice-info" v-if="forms.length === 0">
			<p class="text-left">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
				</svg>
				{{ $t('vm.root.patients.patient.forms.none.title') }}
				<i18n path="vm.root.patients.patient.forms.none.description.pediatrics-text" tag="span">
					<router-link :to="{name: 'parameters-forms'}" :title="$t('vm.root.patients.patient.forms.none.description.title')">{{ $t("vm.root.patients.patient.forms.none.description.link") }}</router-link>
				</i18n>
			</p>
		</div>
	
		<custom-form v-else v-for="form in forms" :key="form.id" :form="form"/>
	</div>

</div>
