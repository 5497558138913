import Render from '@Views/components/breadcrumb/breadcrumb.html'
import { Vue, Component } from 'vue-property-decorator'
import { FullScreen } from './fullscreen'
import MoreInfo from './more-info'
import { BreadcrumbItem } from './breadcrumb-item'
import { breadcrumb } from '@Store/modules'
import { IBreadcrumbItem } from '@Store/types'

@Render
@Component({
    components: {
        'fullscreen': FullScreen,
        'more-info': MoreInfo,
        'breadcrumb-item': BreadcrumbItem
    }
})
export class Breadcrumb extends Vue {

    get linkedToContext(): boolean {
        return breadcrumb.linkedToContext
    }
    get items(): IBreadcrumbItem[] {
        return breadcrumb.items
    }
}