import Render from '@Views/root/search/colleague/search.html'
import { Component, Vue } from 'vue-property-decorator'
import { IColleague, IPopinAction, IPopin } from '@Store/types'
import { Colleague } from '@Models/index'
import { patient, popIn } from '@Store/modules'

@Render
@Component({})
export class SearchColleague extends Vue {

	searchColleague(): void {
		let searchAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.colleague.search.select.label').toString(),
			title: this.$i18n.t('vm.root.search.colleague.search.select.title').toString(),
			callback: (options: {selectedId: string, colleague: IColleague}) => {
				let colleague: IColleague = options.colleague
				let validData: boolean = !!colleague.last_name && !!colleague.first_name
				if (!validData) return Promise.reject()

				patient.addColleague(colleague)
				return Promise.resolve()
			},
			condition: (options: {selectedId: string}) => {
				return !!options.selectedId
			}
		}
		let search: IPopin = {
			title: this.$i18n.t('vm.root.search.colleague.search.select.title').toString(),
			content: {component: 'popin-search-colleague'},
			action: searchAction,
			options: {selectedId: null, colleague: null}
		}

		popIn.info(search)
	}

	createColleague(): void {
		let addAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.colleague.search.create-popin.label').toString(),
			title: this.$i18n.t('vm.root.search.colleague.search.create-popin.title').toString(),
			callback: (options: IColleague) => {
				let validData: boolean = !!options.last_name && !!options.first_name
				if (!validData) return Promise.reject()

				patient.addColleague(options)
				return Promise.resolve()
			}
		}
		let colleague: IColleague = Colleague.createNewColleague()
		let add: IPopin = {
			title: this.$i18n.t('vm.root.search.colleague.search.create-popin.title').toString(),
			content: {component: 'popin-create-colleague'},
			action: addAction,
			options: colleague,
			isWide: true
		}

		popIn.info(add)
	}
}
