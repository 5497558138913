export const stickyNote = {
    'share-action': {
        label: "partager",
        title: "Partager ce post-it"
    },
    color: {
        title: "Choisissez une couleur de fond",
        label: "Choisissez une couleur"
    },
    share: {
        title: "Partagez cette note avec vos collaborateurs",
        label: "Partagez cette note"
    },
    delete: {
        title: "Supprimez cette note",
        confirm: "Voulez-vous vraiment supprimer cette note ?",
        label: "Supprimez cette note"
    },
    saving: "Sauvegarde en cours…"
}