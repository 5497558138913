import * as Accounting from '@ViewModels/root/accounting'
import * as Receipt from '@ViewModels/root/accounting/receipt'
import * as Spend from '@ViewModels/root/accounting/spend'
import * as Immobilization from '@ViewModels/root/accounting/immobilization'
import * as Remittances from '@ViewModels/root/accounting/remittances'
import * as Book from '@ViewModels/root/accounting/book'
import * as Remittance from '@ViewModels/root/accounting/remittances/remittance'
import { Profile } from '@Enums/profile'

export const AccountingRoot = [
	{
		path: 'accounting', 
		name:'accounting', 
		component: Accounting.Main,
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Accounting, Profile.Substitute] }
	},
	{
		path: 'receipt/:inv_id?', 
		component: Receipt.Main,
		redirect: 'receipt',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
		children: [
			{
				path: '', 
				name: 'receipt',
				component: Receipt.Details,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
			}
		]
	},
	{
		path: 'spend/:inv_id?', 
		component: Spend.Main,
		redirect: 'spend',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
		children: [
			{
				path: '', 
				name: 'spend',
				component: Spend.Details,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
			},
			{
				path: 'recurrency', 
				name: 'spend-recurrency',
				component: Spend.Recurrency,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
			}
		]
	},
	{
		path: 'immobilization/:inv_id?', 
		component: Immobilization.Main,
		redirect: 'immobilization',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] },
		children: [
			{
				path: '', 
				name: 'immobilization',
				component: Immobilization.Details,
				meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
			}
		]
	},
	{
		path: 'remittances', 
		component: Remittances.Main,
		name: 'remittances',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
	},
	{
		path: 'remittance/:rem_id?', 
		component: Remittance.Main,
		name: 'remittance',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Substitute] }
	},
	{
		path: 'recipe-book', 
		component: Book.Main,
		name: 'recipe-book',
		meta: { permission: [Profile.Main, Profile.Demo, Profile.Accounting, Profile.Substitute] }
	}
]
