<div v-if="options">
	<div v-if="!!options.light" class="notice-warning">
		<p class="text-left">
			<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
                <use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
            </svg>
			{{ $t('managers.invoice-manager.update-invoice.failed') }}
		</p>
	</div>
	<div v-else-if="isSubstituteUser" class="notice-info">
		<i18n path="vm.components.popin.create-receipt.text.message" tag="p" class="text-left">
			<strong>{{ $t('vm.components.popin.create-receipt.text.strong1', { user: userLinked.by_substitute ? $t('vm.components.popin.create-receipt.your-name') : $t('vm.components.popin.create-receipt.other-name', {'last-name': userLinked.coordinates.last_name, 'first-name': userLinked.coordinates.first_name}) }) }}</strong>
			<span>{{ (userLinked.by_substitute ? 100 - userLinked.retrocession : userLinked.retrocession).toFixed(2) }}</span>
			<strong>{{ $t('vm.components.popin.create-receipt.text.strong2', { account: userLinked.by_substitute ? $t('vm.components.popin.create-receipt.other-account', {'last-name': userLinked.coordinates.last_name, 'first-name': userLinked.coordinates.first_name}) : $t('vm.components.popin.create-receipt.your-account') }) }}</strong>
		</i18n>
	</div>

	<details-receipt :receipt="options.receipt" :consultation="options.consultation" :with-patient="false" :light="options.light"/>
</div>
