import Render from '@Views/root/patients/patient/spheres.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as Sphere from './sphere/index'
import { groupBy } from 'lodash'
import { Dictionary } from 'vue-router/types/router'
import { Patient, Common } from '@Models/index'
import { ISphere, ICoordinates, IBreadcrumbItem, IConfiguration } from '@Store/types'
import { FormSummary } from '@Components/forms/form-summary'
import { breadcrumb, configuration, patient, user } from '@Store/modules'

@Render
@Component({
	components: {
		'sphere': Sphere.Main,
		'form-summary': FormSummary
	}
})
export class Spheres extends Vue {

	filter: string = ''
	private deploy: Dictionary<boolean> = {}
	
	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @State('configuration') stateConfiguration: ConfigurationState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/spheres') spheres: ISphere[]
	// @Getter('patient/coordinates') coordinates: ICoordinates
	// @Getter('user/isMainUser') isMainUser: boolean
	// @Getter('patient/owner') owner: (item: {id: string, creator_id: string}) => string
	// @Action('patient/deleteSphere') deleteSphere: (sphere: ISphere) => void

	get coordinates(): ICoordinates {
		return patient.coordinates
	}
	get spheres(): ISphere[] {
		return patient.spheres
	}
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get spheresGrouped(): Dictionary<ISphere[]> {
		let result:Dictionary<ISphere[]> = groupBy(this.spheres.filter(s => this.filterSphere(s)), s => s.old_type)
		const ordered:Dictionary<ISphere[]> = {}
		Object.keys(result).sort().forEach(key => {
			ordered[key] = result[key].sort((a: ISphere, b: ISphere) => {
				if (parseInt(a.id) === -1) return -1
				if (parseInt(b.id) === -1) return 1
				return b.old_event_date.getTime() - a.old_event_date.getTime()
			}) 
		})
		return ordered
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}

	mounted() {
		this.$emit('navigate', 'spheres')
		this.initializeBreadcrumb()

		this.moveToSphere()
		this.updateFilter()
	}

	owner(item: {id: string, creator_id: string}): string {
		return patient.owner(item)
	}

	deleteSphere(sphere: ISphere): void {
		patient.deleteSphere(sphere)
	}

	toggle(id: string, createId?: string): any {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	editSphere(sphere: ISphere): void {
		for (let s of this.spheres) {
			if (!s.edit) continue
			s.edit = false
		}

		sphere.edit = true
		this.$emit('edit')
		this.$forceUpdate()
	}

	addSphere($event, type?: string): void {
		for (let s of this.spheres) {
			if (!s.edit) continue
			s.edit = false
		}

		this.$emit('edit')
		this.filter = ''
		let createId: string = Common.generateId()
		let sphere: ISphere = Patient.createNewSphere(createId, this.configuration, type)
		sphere.edit = true
		this.spheres.push(sphere)
	}

	getElementId(sphere: ISphere): string {
		return parseInt(sphere.id) === -1 ? sphere.create_id : sphere.id
	}

	@Watch('$route.query.filter')
	private updateFilter() {
		this.filter = this.$route.query.filter as string
		let element = this.$refs['filter'] as any
		if (!element || !element.$el) return

		element.$el.scrollIntoView(true)
	}

	@Watch('submitted')
	private moveToSphere() {
		let sphere: ISphere = this.spheres.find(a => a.error)
		if (!sphere) return

		sphere.edit = true
		setTimeout(() => {
			let sph_id: string = this.getElementId(sphere)
			let element = this.$refs[sph_id] as any
			if (!element || element.length === 0) return

			let centralElement: Element = document.getElementsByClassName('l-central')[0]
			let header:HTMLElement = document.getElementById("header")
	
			centralElement.scrollTo(0, element[0].$el.offsetTop - header.offsetHeight)
		}, 100)
	}

	private filterSphere(sphere: ISphere): boolean {
		if (!this.filter || this.filter.trim() === '') return true

		let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.filter.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!sphere.type && sphere.type.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!sphere.title && sphere.title.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!sphere.description && sphere.description.toLowerCase().includes(filter)) {
				countResult++
			}
		}
		return countFilter === countResult
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.sphere', this.spheres.length).toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}