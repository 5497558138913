<collapsible-panel v-if="!!antecedent" collapsible-class="marg-v">
	<template slot="title">
		<svg v-if="antecedent.important" width="1em" class="svg-middle text-md" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle" />
		</svg>
		<span class="text-uppercase-first-letter" :inner-html.prop="antecedent.type | mark(filter)"/> :
		<span class="text-uppercase-first-letter" :inner-html.prop="antecedent.title | mark(filter)"/>
	</template>
	<div slot="subtitle" class="text-italic text-sm marg-l-1">
		<div v-if="!!member">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
			</svg>
			{{ $t('vm.root.patients.patient.family-antecedent.concerns') }}
			<strong class="text-capitalize"><anonymous :text="member.first_name"/> <anonymous :text="member.last_name"/></strong>,
			{{member.type | member(member.gender)}}
			<span v-if="member.deceased" class="patient-deceased">{{ $t('vm.root.search.member.item.deceased') }}</span>
		</div>
		<div v-if="!!owner">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
			</svg>
			{{ $t('vm.root.patients.patient.antecedents.create-by', {owner: owner}) }}
		</div>
	</div>
	<span slot="right" :class="{'text-danger': antecedent.important}">
		<em v-if="antecedent.periodic && antecedent.running">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
			</svg> {{ $t('general.running') }}, {{antecedent.event_date | since}}
		</em>
		<span v-else-if="antecedent.periodic">{{antecedent.event_date | timeAgo}}, {{ $t('general.during') }} {{antecedent.event_date | age(antecedent.end_date)}}</span>
		<span v-else>{{antecedent.event_date | timeAgo}}</span>
	</span>

	<p class="no-marg" :inner-html.prop="antecedent.description | mark(filter)"/>

	<div class="text-right text-xs">{{ $t('vm.root.patients.patient.family-antecedent.restriction') }}</div>
</collapsible-panel>
