<div class="login-view" :class="bgClass">

	<nav class="login-view-nav">
		<a href="//osteo2ls.com" title="Home">
			<svg width="1em" class="logo text-xl" viewBox="0 0 761 100" role="img">
				<use xlink:href="@Assets/svg/symbol-defs.svg#logo"/>
			</svg>
		</a>
		<ul class="ul-reset is-pushed-right">
			<li class="login-view-nav-item rwd-over-medium">
				<a class="text-lg" href="//osteo2ls.com" :title="$t('vm.login.main.home.title')">{{ $t("vm.login.main.home.link") }}</a>
			</li>
			<li class="login-view-nav-item rwd-over-medium">&bull;</li>
			<li class="login-view-nav-item">
				<a class="text-lg" href="//osteo2ls.com/logiciel/nous-contacter.html" :title="$t('vm.login.main.contact.title')">{{ $t("vm.login.main.contact.link") }}</a>
			</li>
		</ul>
	</nav>
	<main class="login-view-main">
		<div class="login-view-main-inner">
			<transition name="fade">
				<router-view/>
			</transition>
		</div>
	</main>
	<div class="version text-xs">v{{version}}</div>
</div>
