<div v-if="!!immobilization" class="grid-2">
    <input-frame :auto-focus="true" type="text" id="label" v-model="immobilization.label"
        :label="$t('vm.root.accounting.immobilization.details.label.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.label.placeholder')"
        :error-test="hasLabelError" :error-msg="$t('vm.root.accounting.immobilization.details.label.error-msg')"
        :title="$t('vm.root.accounting.immobilization.details.label.title')"/>
    <input-date-frame v-model='immobilization.settlement_date' id="settlement-date" :type="dateType"
        :label="$t('vm.root.accounting.immobilization.details.date.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.date.placeholder')"
        :title="$t('vm.root.accounting.immobilization.details.date.title')"/>

    <dropdown-frame id="office" v-model="immobilization.ofi_id" :items="officeItems"
        :label="$t('vm.root.accounting.immobilization.details.office.label')"
        :title="$t('vm.root.accounting.immobilization.details.office.title')"/>
    <dropdown-frame id="damping-type" v-model="immobilization.damping_type" :items="dampingTypeItems" 
        :label="$t('vm.root.accounting.immobilization.details.type.label')"
        :title="$t('vm.root.accounting.immobilization.details.type.title')"/>

    <input-frame type="text" id="number" v-model="immobilization.number" :readonly="!immobilization.can_modify"
        :label="$t('vm.root.accounting.immobilization.details.number.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.number.placeholder')"
        :title="$t('vm.root.accounting.immobilization.details.number.title')"/>
    <div class="input-frame-like">
        <switch-frame id="modify-number" v-model="immobilization.can_modify"
            :title="$t('vm.root.accounting.immobilization.details.modify-number.title')">
            {{ $t('vm.root.accounting.immobilization.details.modify-number.label') }}
        </switch-frame>
    </div>

    <dropdown-frame id="payment-mode" v-model="immobilization.mode" :items="paymentModeItems" 
        :label="$t('vm.root.accounting.immobilization.details.mode.label')"
        :title="$t('vm.root.accounting.immobilization.details.mode.title')"/>

    <input-frame type="number" id="amount" v-model="immobilization.ttc" min="0" step="0.01"
        :label="$t('vm.root.accounting.immobilization.details.amount.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.amount.placeholder')"
        :title="$t('vm.root.accounting.immobilization.details.amount.title')"/>
    <input-frame type="number" id="rate" v-model="immobilization.damping_rate" min="0" step="1"
        :label="$t('vm.root.accounting.immobilization.details.rate.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.rate.placeholder')"
        :title="$t('vm.root.accounting.immobilization.details.rate.title')"/>

    <input-frame type="number" id="tva" v-model="immobilization.tva" min="0" max="100" step="0.1"
        :label="$t('vm.root.accounting.immobilization.details.tva.label')"
        :placeholder="$t('vm.root.accounting.immobilization.details.tva.placeholder')"
        :title="$t('vm.root.accounting.immobilization.details.tva.title')"/>

</div>
