export const user = {

	formation: 'formation | formation | formations',
	organization: 'association | association | associations',
	added: 'un {item} ajouté | {n} {item} ajoutés',
	updated: 'un {item} modifié | {n} {item} modifés',
	deleted: 'un {item} effacé | {n} {item} effacés',
	incomplete: 'un {item} incomplet | {n} {item} incomplets',
	'added-female': 'une {item} ajoutée | {n} {item} ajoutées',
	'updated-female': 'une {item} modifiée | {n} {item} modifées',
	'deleted-female': 'une {item} effacée | {n} {item} effacées',
	'incomplete-female': 'une {item} incomplète | {n} {item} incomplètes',
	'user-saved': "Utilisateur sauvegardé",
	'user-incomplete': "Utilisateur incomplet",
	'coordinates-saved': "informations modifiées",
	'coordinates-incomplete': "informations incomplètes",
	complete: "compléter"
}
