<div class="togglebox form-group">
	<div v-for="(item, index) in items" :key="getId(item, index)">
		<input type="radio" :name="id" :id="getId(item, index)" :value="item.value" :checked="checked(item.value)"
			@change="update(item.value)" :disabled="disabled" :readonly="readonly">
		<label :for="getId(item, index)" class="togglebox-item form-group-input" :title="item.label" :class="item.class" :disabled="disabled">
			<svg v-if="item.icon" width="1em" class="togglebox-item-icon svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${item.icon}`"/>
			</svg>
			<span v-if="item.label" class="togglebox-item-text">{{item.label}}</span>
		</label>
	</div>
</div>
