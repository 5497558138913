export const fullCalendar = {
    'all-day-text': 'Jour',
    'week-text': 'sem.',
    'button-text': {
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour'
    }
}
