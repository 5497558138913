export const planning = {
    title: "Paramètres du planning",
    minute: "{count} minute|{count} minute|{count} minutes",
    'each-minute': "|toutes les minutes|toutes les {count} minutes",
    slot: {
        label: "Durée des cases vides du planning",
        title: "Sélectionnez la durée des cases vides de votre planning"
    },
    'refresh-delay': {
        label: "Délai de rafraîchissement des calendriers",
        title: "Sélectionnez le délai après lequel votre planning se mettra à jour automatiquement"
    },
    range: {
        start: {
            label: "Début des créneaux horaires affichés",
            title: "Veuillez renseigner l'heure de début des créneaux horaires affichés"
        },
        end: {
            label: "Fin des créneaux horaires affichés",
            title: "Veuillez renseigner l'heure de fin des créneaux horaires affichés"
        }
    },
	'displayed-days': "Jours affichés dans votre planning",
    google: {
        title: "Lors de votre déconnexion, vous serez déconnecté de Google Calendar",
        label: "Rester connecter à Google Calendar après déconnexion"
    }
}
