import Render from '@Views/root/patients/patient/pediatrics/neonatal.html'
import { ReadOnlyNeonatal } from './read-only-neonatal'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Dictionary } from "vue-router/types/router"
import { IBreadcrumbItem, IPediatrics } from '@Store/types'
import { IToggleBoxItem } from '@Components/index'
import { Alimentation } from '@Enums/index'
import { EnumsHelper } from '@Helpers/index'
import { breadcrumb, patient } from '@Store/modules'

@Render
@Component({
	components: {
		'read-only': ReadOnlyNeonatal
	}
})
export class Neonatal extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() readOnly: boolean

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/pediatrics') pediatrics: IPediatrics

	get pediatrics(): IPediatrics {
		return patient.pediatrics
	}
	get yesNoItems(): IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.no').toString()}, 
			{value: true, label: this.$i18n.t('general.yes').toString()}
		]
	}
	get withItems(): IToggleBoxItem[] {
		return [
			{value: false, label: this.$i18n.t('general.without').toString()}, 
			{value: true, label: this.$i18n.t('general.with').toString()}
		]
	}
	get alimentationItems(): IToggleBoxItem[] {
		return [
			{value: Alimentation.Breast, label: EnumsHelper.alimentationToString(Alimentation.Breast)}, 
			{value: Alimentation.Milk, label: EnumsHelper.alimentationToString(Alimentation.Milk)}, 
			{value: Alimentation.Both, label: EnumsHelper.alimentationToString(Alimentation.Both)}
		]
	}

	mounted() {
		this.$emit('navigate', 'pediatrics')
		this.initializeBreadcrumb()
	}

	toggle(year: string): any {
		this.deploy[year] = !this.deploy[year]
		this.$forceUpdate()
	}
	isToggle(year: string): boolean {
		return this.deploy[year]
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.pediatrics').toString(), link: {name: "patient-pediatrics", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.neonatal').toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}