<div v-if="pediatrics">
    <h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.pediatrics-main.title') }}</h2>

    <misc-collapsible-panel>
        <span slot="title">{{ $t('vm.root.patients.patient.pediatrics-main.subtitle1') }}</span>
        <div class="no-marg">
            <p class="text-pre-line" v-if="pediatrics.antecedents_parents"><strong>{{ $t('vm.root.patients.patient.pediatrics-main.antecedents-parents.label') }}</strong> :<br><span v-html="pediatrics.antecedents_parents"></span></p>
            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.number-of-children.label') }}</strong> :
				<span v-if="pediatrics.number_of_children === -1">{{ $t('vm.root.patients.patient.coordinates.number-of-children.none') }}</span>
				<span v-else>{{ $tc('vm.root.patients.patient.coordinates.number-of-children.item', pediatrics.number_of_children) }}</span>
			</p>
            <p class="text-pre-line" v-if="pediatrics.remarks1"><strong>{{ $t('vm.root.patients.patient.pediatrics-main.remarks1.label') }}</strong> :<br><span v-html="pediatrics.remarks1"></span></p>
        </div>
        <hr>
    </misc-collapsible-panel>

    <misc-collapsible-panel>
        <span slot="title">{{ $tc('vm.root.patients.patient.pediatrics-main.subtitle2', pediatrics.number_of_children) }}</span>
        <div class="no-marg">
            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.contraction.label') }}</strong> : {{ pediatrics.with_contraction ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_contraction && !!pediatrics.contraction" class="text-pre-line"><span v-html="pediatrics.contraction" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.immobilisation.label') }}</strong> : {{ pediatrics.with_immobilisation ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_immobilisation && !!pediatrics.immobilisation" class="text-pre-line"><span v-html="pediatrics.immobilisation" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.strapping.label') }}</strong> : {{ pediatrics.with_strapping ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_strapping && !!pediatrics.strapping" class="text-pre-line"><span v-html="pediatrics.strapping" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.accident.label') }}</strong> : {{ pediatrics.with_accident ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_accident && !!pediatrics.accident" class="text-pre-line"><span v-html="pediatrics.accident" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.disease.label') }}</strong> : {{ pediatrics.with_disease ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_disease && !!pediatrics.disease" class="text-pre-line"><span v-html="pediatrics.disease" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.gained-weight.label') }}</strong> : {{ pediatrics.with_gained_weight ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_gained_weight && !!pediatrics.gained_weight" class="text-pre-line"><span v-html="pediatrics.gained_weight" /></p>

            <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.tranquilizer.label') }}</strong> : {{ pediatrics.with_tranquilizer ? $t('general.with') : $t('general.without') }}</p>
            <p v-if="pediatrics.with_tranquilizer && !!pediatrics.tranquilizer" class="text-pre-line"><span v-html="pediatrics.tranquilizer" /></p>

            <p class="text-pre-line" v-if="pediatrics.remarks2"><strong>{{ $t('vm.root.patients.patient.pediatrics-main.remarks2.label') }}</strong> :<br><span v-html="pediatrics.remarks2"></span></p>
        </div>
        <hr>
    </misc-collapsible-panel>

    <misc-collapsible-panel>
        <span slot="title">{{ $tc('vm.root.patients.patient.pediatrics-main.subtitle3', pediatrics.number_of_children) }}</span>
        <div class="no-marg">
            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.pediatrics-main.anesthesia.label') }}</strong> : {{ pediatrics.with_anesthesia ? $t('general.with') : $t('general.without') }}<br>
                <span v-if="pediatrics.with_anesthesia && !!pediatrics.anesthesia" v-html="pediatrics.anesthesia" />
            </p>

            <div class="grid-2">
                <p><strong>{{ $t('vm.root.patients.patient.pediatrics-main.duration.label') }}</strong> : {{ pediatrics.duration }}</p>
                <strong v-if="pediatrics.eutocic">{{ $t('vm.root.patients.patient.pediatrics-main.eutocic') }}</strong>
                <strong v-if="pediatrics.obstructed">{{ $t('vm.root.patients.patient.pediatrics-main.obstructed') }}</strong>
                <strong v-if="pediatrics.episiotomy">{{ $t('vm.root.patients.patient.pediatrics-main.episiotomy') }}</strong>
                <strong v-if="pediatrics.forceps">{{ $t('vm.root.patients.patient.pediatrics-main.forceps') }}</strong>
                <strong v-if="pediatrics.fundal">{{ $t('vm.root.patients.patient.pediatrics-main.fundal') }}</strong>
                <strong v-if="pediatrics.suction_cup">{{ $t('vm.root.patients.patient.pediatrics-main.suction-cup') }}</strong>
                <strong v-if="pediatrics.finger">{{ $t('vm.root.patients.patient.pediatrics-main.finger') }}</strong>
            </div>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.pediatrics-main.accelerators.label') }}</strong> : {{ pediatrics.with_accelerators ? $t('general.with') : $t('general.without') }}<br>
                <span v-if="pediatrics.with_accelerators && !!pediatrics.accelerators" v-html="pediatrics.accelerators" />
            </p>

            <p class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.pediatrics-main.caesarean.label') }}</strong> : {{ pediatrics.with_caesarean ? $t('general.with') : $t('general.without') }}<br>
                <span v-if="pediatrics.with_caesarean && !!pediatrics.caesarean" v-html="pediatrics.caesarean" />
            </p>

            <p v-if="!!pediatrics.presentation" class="text-pre-line">
                <strong>{{ $t('vm.root.patients.patient.pediatrics-main.presentation.label') }}</strong> :<br>
                <span v-html="pediatrics.presentation" />
            </p>

            <p class="text-pre-line" v-if="pediatrics.remarks3"><strong>{{ $t('vm.root.patients.patient.pediatrics-main.remarks3.label') }}</strong> :<br><span v-html="pediatrics.remarks3"></span></p>
        </div>
        <hr>
    </misc-collapsible-panel>
</div>
