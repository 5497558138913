import Render from '@Views/root/patients/campaigns/campaign/steps/step-1.html'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { ICampaign } from '@Store/types'
import { InputDateType } from '@Components/frame/input-date-frame'
import { campaign } from '@Store/modules'

@Render
@Component({})
export class StepOne extends Vue {

	private static maxLength: number = 480

	messageLength: number = StepOne.maxLength
	private submitted: boolean = false
	private messageWarning: boolean = false

	@Prop() campaign: ICampaign

	//#region getter and setter
	get dateType(): InputDateType {
		return InputDateType.CalendarTime
	}
	get invalidDate(): any[] {
		return [
			'w6', 'w7',
			{ start: '00:00', end: '07:59' },
			{ start: '20:00', end: '23:55' }
		]
    }
	get hasTitleError(): boolean {
		return this.submitted && !this.campaign.title
	}
	get hasMessageError(): boolean {
		return this.submitted && (!this.campaign.message || this.messageWarning)
	}
	get hasDateError(): boolean {
		return this.submitted && (!this.campaign.sending_date || !this.validDate || this.pastDate)
	}
	get messageError(): string {
		if (!this.campaign.message)
			return this.$i18n.t('vm.root.patients.campaign.step-1.input.message.error-msg-1').toString()
		if (this.messageWarning)
			return this.$i18n.t('vm.root.patients.campaign.step-1.input.message.error-msg-2').toString()

		return ""
	}
	get dateError(): string {
		if (!this.validDate)
			return this.$i18n.t('vm.root.patients.campaign.step-1.input.date.error-msg-1').toString()
		if (this.pastDate)
			return this.$i18n.t('vm.root.patients.campaign.step-1.input.date.error-msg-2').toString()

		return ""
	}
	get disabled(): boolean {
		return !this.campaign.title || !this.campaign.message || this.messageWarning || !this.campaign.sending_date || !this.validDate || this.pastDate
	}
	private get canNext(): boolean {
		return !this.hasTitleError && !this.hasMessageError && !this.hasDateError
	}
	private get validDate(): boolean {
		return 8 <= this.campaign.sending_date.getHours() && this.campaign.sending_date.getHours() < 20
	}
	private get pastDate(): boolean {
		return this.campaign.sending_date <= new Date()
	}
	//#endregion

	mounted() {
		this.submitted = false
		campaign.updateStep(0)
		this.updateMessage()
	}

	next(): void {
		this.submitted = true
		if (!this.canNext) return

		this.computeSmsCount()
		campaign.nextStep()
		this.submitted = false
		this.$router.push({name: 'campaign-step-two'})
	}

	@Watch('campaign.message')
	updateMessage() {
		this.campaign.sms_count = 1
		this.campaign.estimation_count = 0
		if (this.hasMessageError) {
			this.messageLength = StepOne.maxLength
			return
		}
		this.computeSmsCount()
	}

	private computeSmsCount() {
		let text: string = this.campaign.message.trim()
		let count = this.countOccurence(text)
		let result = StepOne.maxLength - text.length - count
		this.campaign.sms_count = Math.ceil((StepOne.maxLength - result) / 160)
		this.campaign.estimation_count = this.campaign.sms_count
		this.messageWarning = result < 0
		this.messageLength = Math.max(0, result)
	}

	countOccurence(str: string): number {
		let doubleArray: string[] = ['{', '}', '€', '[', ']', '~', '^', '¨', '|', '&', '$', '@', '\n', '\r', '\t']
		let totalCount = 0
		for (let i: number = 0; i < doubleArray.length; i++) {
			let doubleCharacter = doubleArray[i]
			totalCount += Math.max(0, str.split(doubleCharacter).length - 1)
		}
		return totalCount
	}
}
