import Render from '@Views/components/super-panel/layout/merge/button-merge.html'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Dictionary} from "vue-router/types/router"
import {IMergeData, IPatient} from "@Store/types/patient"

@Render
@Component({})
export class ButtonMerge extends Vue {

    @Prop() keys: Dictionary<IMergeData>
    @Prop() patient: IPatient
    @Prop() title: string
    @Prop() label: string
    @Prop() property: string

	get isVisible(): boolean {
		let data: IMergeData = this.keys[this.property]
		if (!data) return false

		return data.isVisible
	}
	get isSelected(): boolean {
		let data: IMergeData = this.keys[this.property]
		if (!data) return false

		return data.id === this.patient.id
	}

	select(): void {
		let data: IMergeData = this.keys[this.property]
		if (!data) return

		data.id = this.patient.id
	}
}
