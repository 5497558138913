<div class="drawer-frame">

	<div class="drawer-controls form-group">
		<button type="button" class="form-group-input input-like is-narrow-padding-h is-narrow-padding-v is-sized"
			@mousedown="mouseDown" @mouseup="mouseUp" @mouseleave="mouseLeave" @touchstart="undo"
			:title="$t('vm.components.drawer.undo.title')" :disabled="!history.length">
			<svg width="1em" class="svg-block text-md" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#eraser"/>
			</svg>
			<span class="sr-only">{{ $t('vm.components.drawer.undo.label') }}</span>
		</button>
		<delete-item :can-delete="true" :disabled="!history.length" btn-class="form-group-input input-like is-narrow-padding-h is-narrow-padding-v is-sized"
			:title="$t('vm.components.drawer.clear.title')"
			:delete-msg="$t('vm.components.drawer.clear.delete-msg')"
			:delete-label="$t('vm.components.drawer.clear.delete-label')"
			:delete-action="clearAll">
			<svg width="1em" class="svg-block text-md" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
			</svg>
			<span class="sr-only">{{ $t('vm.components.drawer.clear.label') }}</span>
		</delete-item>
	</div>

	<canvas :id="`${id}-canvas`" class="drawer-canvas" width="363" height="717" />

	<div class="drawer-illustration">
		<svg v-if="options.with_body" viewBox="0 0 365 721" class="drawer-illustration-svg" role="img" focusable="false">
			<use :xlink:href="`${require('@Assets/svg/schema-defs.svg')}#${id}`"/>
		</svg>
	</div>

	<div class="drawer-illustration drawer-illustration-backbone">
		<svg v-if="options.with_backbone" viewBox="0 0 365 721" class="drawer-illustration-svg" role="img" focusable="false">
			<use :xlink:href="`${require('@Assets/svg/backbone-defs.svg')}#${id}`"/>
		</svg>
	</div>

</div>
