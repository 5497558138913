<div v-if="family">
	<h2 class="text-uppercase-first-letter">{{ $t('vm.root.patients.patient.family.title') }}</h2>

	<search-member v-if="!readOnly" :family="family" :patient="patient"/>

	<div v-for="(members, type) in membersGrouped" :key="type">
		<h3>{{ parseInt(type) | members(members.length) }}</h3>

		<div class="grid-3 vertical-gap marg-v">
			<member-item :member="member" :read-only="readOnly" :key="member.link_id" v-for="member in members"/>
		</div>
	</div>
	<div v-if="Object.keys(membersGrouped).length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#frown"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.patients.patient.family.none.title') }}</span><br>
			{{ $tc('vm.root.patients.patient.family.none.description', coordinates.gender) }}
		</p>
	</div>

</div>
