import Render from '@Views/root/user/office/accesses.html'
import draggable from "vuedraggable"
import * as Access from './access/index'
import { Dictionary } from 'vue-router/types/router'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IOffice, IAccess, IBreadcrumbItem } from '@Store/types'
import { Office, Common } from '@Models/index'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'draggable': draggable,
		'access': Access.Main
	}
})
export class Accesses extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() office: IOffice

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get accesses(): IAccess[] {
		return this.office.accesses.currents
	}

	mounted() {
		this.$emit('navigate', 'access-office')
		this.initializeBreadcrumb()

		this.moveToAccess()
	}

	toggle(id: string, createId?: string): void {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	addAccess(): void {
		let createId: string = Common.generateId()
		let access: IAccess = Office.createNewAccess(createId)
		this.toggle(access.id, access.create_id)
		this.office.accesses.currents.push(access)
		setTimeout(() => { this.moveToAccess(access) }, 100)
	}
	
	getElementId(access: IAccess): string {
		return parseInt(access.id) === -1 ? access.create_id : access.id
	}

	@Watch('submitted')
	private moveToAccess(access?: IAccess) {
		access = !access ? this.accesses.find(a => a.error) : access
		if (!access) return

		if (!this.isToggle(access.id, access.create_id)) {
			this.toggle(access.id, access.create_id)
		}
		let ses_id: string = this.getElementId(access)
		let element = this.$refs[ses_id] as any
		if (!element || element.length === 0) return

		let centralElement: Element = document.getElementsByClassName('l-central')[0]
		centralElement.scrollTo(0, element[0].offsetTop)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.accesses', 1).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}
}