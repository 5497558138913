import Render from '@Views/root/parameters/general.html'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { IConfiguration, IBreadcrumbItem } from '@Store/types'
import { IDropdownItem } from '@Components/index'
import { AutoSaveDelay, DefaultPage, FontSize, Lang, Skin } from '@Enums/index'
import { EnumsHelper, FontHelper, LangHelper, SkinHelper } from '@Helpers/index'
import { breadcrumb, user } from '@Store/modules'

@Render
@Component({})
export class General extends Vue {

	private oldSize: FontSize
	@Prop() configuration: IConfiguration

	//#region getters
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get skin(): Skin {
		return this.configuration.theme
	}
	set skin(value: Skin) {
		this.configuration.theme = value
	}
	get skins(): IDropdownItem[] {
		return [
			{ value: Skin.Default, label: EnumsHelper.skinToString(Skin.Default) },
			{ value: Skin.Dark, label: EnumsHelper.skinToString(Skin.Dark) },
			{ value: Skin.Light, label: EnumsHelper.skinToString(Skin.Light) }
		]
	}
	get fontSize(): FontSize {
		return this.configuration.font_size
	}
	set fontSize(value: FontSize) {
		this.oldSize = this.configuration.font_size
		this.configuration.font_size = value
		FontHelper.updateFontSize(this.configuration.font_size, this.oldSize)
	}
	get langItems(): IDropdownItem[] {
		return [
			{ value: Lang.French, label: EnumsHelper.langToString(Lang.French) },
			// { value: Lang.English, label: EnumsHelper.langToString(Lang.English) }
		]
	}
	get pageItems(): IDropdownItem[] {
		return [
			{ value: DefaultPage.None, label: EnumsHelper.defaultPageToString(DefaultPage.None) },
			{ value: DefaultPage.Dashboard, label: EnumsHelper.defaultPageToString(DefaultPage.Dashboard) },
			{ value: DefaultPage.Patients, label: EnumsHelper.defaultPageToString(DefaultPage.Patients) },
			{ value: DefaultPage.Planning, label: EnumsHelper.defaultPageToString(DefaultPage.Planning) },
			{ value: DefaultPage.Accounting, label: EnumsHelper.defaultPageToString(DefaultPage.Accounting) },
			{ value: DefaultPage.Letters, label: EnumsHelper.defaultPageToString(DefaultPage.Letters) },
			{ value: DefaultPage.CoWorking, label: EnumsHelper.defaultPageToString(DefaultPage.CoWorking) },
			{ value: DefaultPage.Shop, label: EnumsHelper.defaultPageToString(DefaultPage.Shop) },
			{ value: DefaultPage.Help, label: EnumsHelper.defaultPageToString(DefaultPage.Help) },
			{ value: DefaultPage.Statistics, label: EnumsHelper.defaultPageToString(DefaultPage.Statistics) },
			{ value: DefaultPage.Parameters, label: EnumsHelper.defaultPageToString(DefaultPage.Parameters) },
			{ value: DefaultPage.User, label: EnumsHelper.defaultPageToString(DefaultPage.User) },
		]
	}
	get sizeItems(): IDropdownItem[] {
		return [
			{ value: FontSize.Tiny, label: EnumsHelper.fontSizeToString(FontSize.Tiny) },
			{ value: FontSize.Small, label: EnumsHelper.fontSizeToString(FontSize.Small) },
			{ value: FontSize.Normal, label: EnumsHelper.fontSizeToString(FontSize.Normal) },
			{ value: FontSize.Large, label: EnumsHelper.fontSizeToString(FontSize.Large) },
			{ value: FontSize.Huge, label: EnumsHelper.fontSizeToString(FontSize.Huge) }
		]
	}
	get delayItems(): IDropdownItem[] {
		return [
			{ value: AutoSaveDelay.FiveMinutes, label: EnumsHelper.autoSaveDelayToString(AutoSaveDelay.FiveMinutes) },
			{ value: AutoSaveDelay.TenMinutes, label: EnumsHelper.autoSaveDelayToString(AutoSaveDelay.TenMinutes) },
			{ value: AutoSaveDelay.ThirtyMinutes, label: EnumsHelper.autoSaveDelayToString(AutoSaveDelay.ThirtyMinutes) },
			{ value: AutoSaveDelay.Never, label: EnumsHelper.autoSaveDelayToString(AutoSaveDelay.Never) }
		]
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'general')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	isSelectedSkin(skin: Skin): boolean {
		return this.skin === skin
	}

	selectSkin(skin: Skin): void {
		let oldSkin: Skin = this.skin
		this.skin = skin
		SkinHelper.updateSkin(this.skin, oldSkin)
	}

	@Watch('configuration.lang')
	updateLocale() {
		LangHelper.updateLang(this.configuration.lang)
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString() }
		breadcrumb.updateItems([item])
	}
}
