<a-side :sub-menu="true" v-if="!!spend">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'accounting'}" :title="$t('vm.root.accounting.spend.aside.back.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.accounting.spend.aside.back.label') }}
			</router-link>
        </li>
        <li class="hr" aria-hidden="true"/>
        <li>
            <router-link :to="{name: 'spend', params: $route.params}" :title="$t('vm.root.accounting.spend.aside.details.title')">
                {{ $t('vm.root.accounting.spend.aside.details.label') }}
            </router-link>
        </li>
        <li>
            <router-link :to="{name: 'spend-recurrency', params: $route.params}" :title="$t('vm.root.accounting.spend.aside.recurrency.title')">
                {{ $t('vm.root.accounting.spend.aside.recurrency.label') }}
            </router-link>
        </li>
    </ul>
    <ul class="aside-navigation is-pushed-down">
        <li class="hr" aria-hidden="true"/>
        <li>
            <delete-item :can-delete="true" :disabled="isDeleting"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}"
                :title="$t('vm.root.accounting.spend.aside.delete.title')"
                :delete-msg="$t('vm.root.accounting.spend.aside.delete.delete-msg')"
                :delete-action="submitDelete">
                <spinner v-if="isDeleting"/>
                <svg v-else width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
                    <use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
                </svg>
                {{ $t('vm.root.accounting.spend.aside.delete.label') }}
            </delete-item>
        </li>
    </ul>
</a-side>
