import Render from '@Views/components/popin/layout/popin-create-antecedent.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAntecedent } from '@Store/types'
import { Main } from '@ViewModels/root/patients/patient/antecedent'

@Render
@Component({
	components: {
		'details-antecedent': Main
	}
})
export class PopinCreateAntecedent extends Vue {
    
    @Prop() options: IAntecedent
	@Prop({default: false}) submitted: boolean
}