<div>
	<section v-if="!!schedule" v-for="day in schedule.days" :key="day.index" class="marg-v">
		<h3 class="no-marg text-uppercase-first-letter">{{getDayName(day.index)}}</h3>
		<div class="grid-2">
			<div>
				<checkbox-frame :id="`opened-morning-${day.index}`" v-model="day.opened_morning">{{ $t('vm.components.schedule.morning') }}</checkbox-frame>
				<input-range v-model='day.morning' :type="rangeType" :disabled="!day.opened_morning" :default-start="getDefaultDate(8)"
					:default-end="getDefaultDate(12)" :disabled-label="$t('vm.components.schedule.closed')" time-format="HH:ii" :single-input="true"/>
				<dropdown-frame :id="`user-morning-${day.index}`" v-model="day.user_morning" :items="userItems"  :disabled="!day.opened_morning"
					:label="$t('vm.components.schedule.user.label')" :title="$t('vm.components.schedule.user.title')"/>
			</div>
			<div>
				<checkbox-frame :id="`opened-afternoon-${day.index}`" v-model="day.opened_afternoon">{{ $t('vm.components.schedule.afternoon') }}</checkbox-frame>
				<input-range v-model='day.afternoon' :type="rangeType" :disabled="!day.opened_afternoon" :default-start="getDefaultDate(14)"
					:default-end="getDefaultDate(19)":disabled-label="$t('vm.components.schedule.closed')" time-format="HH:ii" :single-input="true"/>
				<dropdown-frame :id="`user-afternoon-${day.index}`" v-model="day.user_afternoon" :items="userItems" :disabled="!day.opened_afternoon"
					:label="$t('vm.components.schedule.user.label')" :title="$t('vm.components.schedule.user.title')"/>
			</div>
		</div>
	</section>
</div>