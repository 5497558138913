import { StorageHelper } from '@Helpers/storage'
import { IUser } from '@Store/types'
import Render from '@Views/components/popin/layout/popin-communication.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'

@Render
@Component({})
export class PopinCommunication extends Vue {

    @Prop() options: {user: IUser,storageKey: String}
	@Prop({default: false}) submitted: boolean

	checked:boolean = false;

	get isUserSubscribed(): boolean {
		return this.options.user.license === -1 || this.options.user.license === 99999;
	}

    @Watch('checked')
    updateStorage() {
        StorageHelper.set(this.options.storageKey.toString(), this.checked)
        if (!this.checked) return
        if (!this.isUserSubscribed) return

        const email = this.options.user.email
        const baseURL = 'https://confirm.matiscode.fr'
        const provider: any = axios.create({baseURL})

        provider.get('/client/confirm-read', {params: {email, emailModel: 'client'}})
    }

}
