<section class="dashboard-widget" :class="{'is-expanded': expand}" v-if="!!widget">
	<div class="dashboard-widget-header childs-space-between">
		<h2 class="text-md no-marg">
			<svg v-if="isBirthDays" width="1em" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#birthday-cake"/>
			</svg>
			{{ widget.type | widget(!datas ? 0 : datas.length) }}
		</h2>
		<button v-if="!isSms && datas && datas.length > 0"
			type="button"
			class="dashboard-widget-expand-btn btn--link-like is-pushed-right"
			:class="{'is-expanded': expand}"
			@click="expand = !expand"
			:title="expand ? $t('vm.root.home.widget.collapse.title') : $t('vm.root.home.widget.expand.title')">
			<span v-if="!expand" class="rwd-over-large">{{ $t('vm.root.home.widget.expand.label') }}</span>
			<span v-else class="rwd-over-large">{{ $t('vm.root.home.widget.collapse.label') }}</span>
			<svg width="1em" class="dashboard-widget-expand-icon svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-to-bottom"/>
			</svg>
		</button>
	</div>

	<div class="dashboard-widget-content scrollbar">
		<template v-if="isBirthDays">
			<div class="dashboard-widget-subheader semi-pad-v is-sticky-top childs-space-between">
				<button type="button" class="btn--link-like" @click="previous" :disabled="isLoadingBirthday" :title="$t('vm.root.home.widget.birthday.previous.title')">
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
					</svg>
					<span class="sr-only">{{ $t('vm.root.home.widget.birthday.previous.label') }}</span>
				</button>
				<span class="text-bold select">{{ $d(date, 'LL') }}</span>
				<button type="button" class="btn--link-like" @click="next" :disabled="isLoadingBirthday" :title="$t('vm.root.home.widget.birthday.next.title')">
					<span class="sr-only">{{ $t('vm.root.home.widget.birthday.next.label') }}</span>
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</button>
			</div>
			<div v-if="isLoadingBirthday" class="text-center marg-v">
				<spinner class="text-primary text-lg"/>
			</div>
			<ul v-else-if="datas && datas.length > 0" class="ul-reset dashboard-widget-list">
				<li v-for="patient in datas" :key="patient.id">
					<router-link class="dashboard-widget-link" :to="{name: 'patient-coordinates', params: {pat_id: patient.id}}"
						:title="$t('vm.root.home.widget.birthday.title', {'last-name': patient.last_name, 'first-name': patient.first_name})">
						<span class="dashboard-widget-link-avatar">
							<avatar class="text-lg" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
						</span>
						<span>
							<span class="text-capitalize">{{ patient.first_name }}</span>
							<span class="text-capitalize">{{ patient.last_name }}</span>
							{{ $t('general.have') }} {{ patient.birth_date | age}} {{ $t('general.today') }}
						</span>
					</router-link>
				</li>
			</ul>
			<div v-else class="dashboard-widget-full-height">{{ $t('vm.root.home.widget.birthday.none') }}</div>
		</template>

		<template v-else-if="isFollowUp">
			<ul v-if="datas && datas.length > 0" class="ul-reset dashboard-widget-list">
				<li v-for="patient in datas" :key="patient.con_id">
					<router-link class="dashboard-widget-link" :to="{name: 'patient-consultation-follow-up', params: {pat_id: patient.id, item_id: patient.con_id}}"
						:title="$t('vm.root.home.widget.follow-up.title', {'last-name': patient.last_name, 'first-name': patient.first_name})">
						<span class="dashboard-widget-link-avatar">
							<avatar class="text-lg" :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
						</span>
						<span>
							<svg width="1em" class="svg-middle" :class="getClassLevel(patient.level)" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
								<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${getIconLevel(patient.level)}`" />
							</svg>
							<span class="text-capitalize">{{ patient.first_name }}</span>
							<span class="text-capitalize">{{ patient.last_name }}</span>
							{{ $t('vm.root.home.widget.follow-up.new') }}
						</span>
					</router-link>
				</li>
			</ul>
			<div v-else class="dashboard-widget-full-height">{{ $t('vm.root.home.widget.follow-up.none') }}</div>
		</template>

		<template v-else-if="isUnpaid">
			<ul v-if="datas && datas.length > 0" class="ul-reset dashboard-widget-list">
				<li v-for="data in datas" :key="data.id">
					<router-link class="dashboard-widget-link" :to="{name: 'receipt', params: {inv_id: data.id}}"
						:title="$t('vm.root.home.widget.unpaid.title', {'last-name': data.last_name, 'first-name': data.first_name})">
						<span class="dashboard-widget-link-avatar">
							<avatar class="text-lg" :first-name="data.first_name" :last-name="data.last_name" :email="data.email" :gender="data.gender"/>
						</span>
						<span>
							<span class="text-capitalize">{{ data.first_name }}</span>
							<span class="text-capitalize">{{ data.last_name }}</span>
							{{ $t('vm.root.home.widget.unpaid.new') }} {{ $n(data.amount, 'currency', data.currency) }}
						</span>
					</router-link>
				</li>
			</ul>
			<div v-else class="dashboard-widget-full-height">{{ $t('vm.root.home.widget.unpaid.none') }}</div>
		</template>

		<template v-else-if="isValidEvent">
			<div v-if="isMainUser" class="dashboard-widget-subheader semi-pad-v is-sticky-top childs-space-between">
				<span class="text-bold" v-if="canUsePlanning">
					{{ $t('vm.root.home.widget.valid-event.valid-planning', {date: $d(user.planning_date, 'll')}) }}
				</span>
				<i18n v-else class="text-bold" path="vm.root.home.widget.valid-event.unvalid-planning.text" tag="span">
					<router-link :to="{name: 'user-gift'}" :title="$t('vm.root.home.widget.valid-event.unvalid-planning.title')">
						{{ $t('vm.root.home.widget.valid-event.unvalid-planning.link') }}
					</router-link>
				</i18n>
			</div>
			<ul v-if="datas && datas.length > 0" class="ul-reset dashboard-widget-list">
				<li v-for="data in datas" :key="data.id">
					<router-link class="dashboard-widget-link" :to="{name: 'valid-event', params: {evt_id: data.id}}"
						:title="$t('vm.root.home.widget.valid-event.title')">
						<span class="dashboard-widget-link-avatar">
							<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#calendar-plus"/>
							</svg>
						</span>
						<span>
							{{ $t('vm.root.home.widget.valid-event.new') }} {{ $d(data.date, 'long') }}, {{ data.date | inTime }}
						</span>
					</router-link>
				</li>
			</ul>
			<div v-else class="dashboard-widget-full-height">{{ $t('vm.root.home.widget.valid-event.none') }}</div>
		</template>

		<template v-else-if="isSms">
			<div class="dashboard-widget-full-height">
				<div class="dashboard-widget-summary">
					<div>
						<div class="text-bold">{{ $tc('vm.root.home.widget.sms.send', datas.sms_send) }}</div>
						<div class="dashboard-widget-summary-number">{{ datas.sms_send }}</div>
					</div>
					<div>
						<div class="text-bold">{{ $t('vm.root.home.widget.sms.total') }}</div>
						<div class="dashboard-widget-summary-number">{{ datas.sms_count }}</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</section>
