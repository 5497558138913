import Render from '@Views/components/frame/input-address-frame.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InputAddress, IAddress } from '@Components/input/input-address'
import { Common } from '@Models/index'

@Render
@Component({
    components: {
        'input-address': InputAddress
    }
})
export class InputAddressFrame extends Vue {

    private random: string = ''

    @Prop() address: IAddress
    @Prop({default: false}) errorTest: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) required: boolean
    @Prop({default: true}) randomId: boolean
    @Prop({default: "ex : 221b Baker Street, London NW1 6XE"}) placeholder: string
    @Prop() id: string
    @Prop() label: string
    @Prop() title: string
    @Prop() errorMsg: string

    //#region getters
    get displayError(): boolean {
        return this.errorTest && !!this.errorMsg
    }
    get inputId() {
        return this.randomId ? `${this.random}-${this.id}`: this.id
    }
    //#endregion

    created() {
		this.random = Common.generateId()
    }
}