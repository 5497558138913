<div>
	<section class="wrapper">

		<h2 class="marg-v">{{ $tc('vm.root.statistics.colleagues.context-colleague.title', patients.length) }}</h2>

		<div class="context-search marg-v">
			<svg width="1em" class="context-search-icon is-shown" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#search"/>
			</svg>
			<input type="search" class="context-search-input" v-model="query"
				:placeholder="$t('vm.root.statistics.colleagues.context-colleague.search.placeholder')"
				:title="$t('vm.root.statistics.colleagues.context-colleague.search.title')"/>
		</div>

		<div v-if="patients.length > 0">
			<patient-item class="marg-v" :query="query" :patient="patient" :without-unselect="true" v-for="patient in patients" :key="patient.id"/>
		</div>

	</section>
</div>
