import Render from '@Views/root/home/widget-referral.html'
import { Vue, Component } from 'vue-property-decorator'
import { referral, user } from '@Store/modules'

@Render
@Component({})
export class WidgetReferral extends Vue {

	godchildCount: number = 0

	get code(): string {
		return !!user.currentUser ? user.currentUser.referral_code : ''
	}
	get point(): number {
		return user.currentUser.point_count || 0
	}

	mounted() {
		if (!this.code) return
		
		referral.loadGodchilds(this.code)
		.then(list => { this.godchildCount = list.length })
	}
}
