export const step2 = {
    title: "Vérification du {profile}",
    verifying: "Vérification en cours…",
    'main-help1': "Un compte principal aura, comme vous, accès à l'ensemble des fonctionnalités d'Osteo2ls. En revanche, le titulaire de ce compte, votre collègue, devra posséder sa propre licence.",
    'main-help2': "Vous pourrez bien sûr supprimer le partage de vos cabinets et de vos plannings avec ce compte à n'importe quel moment.",
    'main-help3': "Veuillez indiquer l'adresse mail de votre collègue. Si son compte n'existe pas encore, il recevra une invitation à s'inscrire. Lors de la finalisation de son inscription, Osteo2ls lui proposera de rejoindre votre cabinet.",
    'secretary-help1': "Un compte secrétaire n'aura accès qu'à vos plannings et aux coordonnées de vos patients, pour pouvoir les modifier le cas échéant, lors de la prise de rdv. Les accès aux autres parties du logiciel lui seront refusés. De part ces restrictions, ce type de compte est gratuit.",
    'secretary-help2': "Vous pouvez sélectionner le ou les cabinets pour lesquels votre secrétaire pourra prendre vos rendez-vous. Enfin, vous pourrez supprimer le partage de votre cabinet avec ce compte à n'importe quel moment.",
    'secretary-help3': "Si vous travaillez avec un télésecrétariat et que ce dernier est listé ci-dessous, sélectionnez-le puis passez à l'étape suivante. Osteo2ls se chargera d'associer l'ensembles des comptes de ce prestataire à votre compte.",
    'substitute-help1': "Un remplaçant n'aura accès qu'aux patients liés à ce cabinet et à ses finances. Les accès aux autres parties du logiciel lui seront refusés. De part ces restrictions, ce type de compte est gratuit. Vous pourrez paramétrer quelques informations liées à votre remplacement, comme le taux de rétrocession ou encore le mode de paiement par défaut pour cette rétrocession.",
    'substitute-help2': "Enfin, vous pourrez supprimer le partage de votre cabinet avec ce compte à n'importe quel moment.",
    'accounting-help1': "Un compte comptable n'aura accès qu'à vos finances en lecture seule. Le but de ce compte n'étant pas de saisir vos informations comptables, mais d'avoir un accès à ces données et au module d'exportation des données pour pouvoir les manipuler ensuite dans un logiciel comptable dédié à cela. Les accès aux autres parties du logiciel lui seront refusés. De part ces restrictions, ce type de compte est gratuit.",
    'accounting-help2': "Vous pouvez sélectionner le ou les cabinets pour lesquels votre comptable aura accès aux données. Enfin, vous pourrez supprimer le partage de votre cabinet avec ce compte à n'importe quel moment.",
    'common-help1': "Veuillez indiquer l'adresse mail de votre {profile}. Si son compte n'existe pas encore, il sera automatiquement créé. Un mail lui sera envoyé, avec ses identifiants de connexion, lui indiquant que vous lui donnez accès à votre cabinet par le biais d'Osteo2ls.",
    'common-help2': "Ce compte sera ensuite automatiquement rattaché à votre cabinet et il pourra commencer à travailler dès sa prochaine connexion.",
    email: {
        placeholder: "ex : meilleur-collegue@world.com",
        'error-msg': "Veuillez indiquer une adresse mail valide…",
        label: "L'email du {profile}",
        title: "Veuillez indiquer l'adresse mail de la personne concernée par ce compte"
    },
    provider: {
        'default-label': "Ou sélectionnez votre télésecrétariat",
        label: "…ou sélectionnez votre télésecrétariat",
        description: "Pour en savoir sur ce télésecrétariat, n'hésitez pas à visiter son site : "
    },
    'next-step': {
        title: "Aller à l'étape suivante",
        text: "étape 3"
    },
    'previous-step': {
        title: "Retourner à l'étape précédente",
        text: "étape 1"
    }
}
