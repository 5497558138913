<div class="input-frame" :class="{ 'is-alert': errorTest }">
    <collapsible-help v-if="withHelp" id="`help-${inputId}-information`" :title="label"
        tag="label" tag-class="label" :tag-for="inputId">
        <slot slot="icon" name="icon"/>
        <slot slot/>
    </collapsible-help>
    <label v-else class="label" :for="inputId" :data-text="label">
        <span>{{label}}</span>
    </label>
    <input :type="type" :id="inputId" :ref="inputId" v-focus="autoFocus"
            :min="min" :max="max" :step="step"
            :value="value" @input="handleInput($event.target.value)"
            :placeholder="placeholder" :title="title" :readonly="readonly" :disabled="disabled"/>
    <div class="msg-alert" v-if="displayError" v-show="errorTest">
        <div class="msg-alert-inner">{{errorMsg}}</div>
    </div>
</div>
