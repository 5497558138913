import Render from '@Views/components/breadcrumb/breadcrumb-item.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'

@Render
@Component({})
export class BreadcrumbItem extends Vue {

    @Prop() item: IBreadcrumbItem

    get tag(): string {
        return !!this.item.link ? 'router-link' : 'strong'
    }

    get link(): {name: string, params?:any}|string {
        if (typeof this.item.link === 'string')
            return {name: this.item.link}

        return this.item.link

    }

    mounted() {
        if (!!this.item.link) return

        this.$el.removeAttribute("to")
        this.$el.setAttribute("aria-current", "page")
    }
}