<section class="person-item">
	<router-link class="person-item-header text-capitalize text-inherit" :title="title"
		:to="{name:'patient-coordinates', params: {pat_id: patient.pat_id}}">
		<figure class="person-item-avatar">
			<avatar :first-name="patient.first_name" :last-name="patient.last_name" :email="patient.email" :gender="patient.gender"/>
		</figure>
		<h3 class="h-reset person-item-name"><anonymous :text="patient.first_name"/><br><anonymous tag="b" :text="patient.last_name"/></h3>
	</router-link>
	<div v-if="isForOtherUser" class="person-item-border text-primary">
		<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#retweet"/>
		</svg>
		{{ $t('general.for')}} {{ userName }}
	</div>
	<div class="person-item-border text-primary">
		<slot/>
		<span v-if="patient.time > new Date()">{{patient.time | inTime}}</span><span v-else>{{patient.time | timeAgo}}</span>
	</div>
	<div v-if="patient.blacklisted || patient.deceased" class="person-item-border">
		<span v-if="patient.blacklisted" class="patient-blacklisted">{{ $t("vm.root.patients.patient-item.blacklisted") }}</span>
		<span v-if="patient.deceased" class="patient-deceased">{{ $t("vm.root.patients.patient-item.deceased") }}</span>
	</div>
	<div class="person-item-border">
		<anonymous tag="div" v-if="patient.phone" class="select" :text="$options.filters.formatPhone(patient.phone)"/>
		<div v-if="patient.email" class="marquee select">
			<div class="marquee-inner">
				<anonymous tag="div" class="marquee-content" :text="patient.email" :email="true"/>
			</div>
		</div>
		<div v-if="!patient.phone && !patient.email"><i>{{ $t("vm.root.patients.patient-item.no_contact") }}</i></div>
	</div>
</section>
