<misc-collapsible-panel v-if="!isEmpty">
	<span slot="title">{{form.name}}</span>
	<template v-for="question in form.questions">
		<p class="text-pre-line" v-if="!isEmptyQuestion(question)" :key="question.id">
			<strong>{{ question.title }}</strong> :<br>
			<ul class="ul-reset" v-if="isChoiceQuestion(question)">
				<template v-for="(choice, index) in question.choices">
					<li v-if="choice.selected" :key="choice.id">&bull; {{choice.label}}</li>
				</template>
			</ul>
			<span v-else>{{question.answer}}</span>
		</p>
	</template>
</misc-collapsible-panel>
