import Render from '@Views/root/help/no-ticket.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IBreadcrumbItem } from '@Store/types'
import { breadcrumb } from '@Store/modules'

@Render
@Component({})
export class NoTicket extends Vue {

	@Prop({default: false}) closed: boolean
	
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	
	mounted() {
		this.initializeBreadcrumb()
		this.scrollToTop()
	}

	scrollToTop(): void {
		let element: HTMLElement = document.getElementById('target-to-top')
		if (!element) return

		element.scrollTop = 0
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.help').toString() }
		breadcrumb.updateItems([item])
	}

}