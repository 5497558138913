<div>
	<h1>{{ $t('vm.root.user.gift.bonus.title') }}</h1>

	<h2>{{ $t('vm.root.user.gift.bonus.subtitle') }}</h2>
	<p class="text-left">
		{{ $t('vm.root.user.gift.bonus.help') }}
	</p>

	<section>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(10)}"
				@click="toggle(10)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-10.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(10)}">
				<div class="misc-collapsible-content input-like">
					<p>{{ $t('vm.root.user.gift.bonus.question-10.text1') }}</p>
					<p>{{ $t('vm.root.user.gift.bonus.question-10.text2') }}</p>
					<p>{{ $t('vm.root.user.gift.bonus.question-10.text3') }}</p>
					<p>{{ $t('vm.root.user.gift.bonus.question-10.text4') }}</p>
					<p>{{ $t('vm.root.user.gift.bonus.question-10.text5') }}</p>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(20)}"
				@click="toggle(20)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-20.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(20)}">
				<div class="misc-collapsible-content input-like">
					<p>{{ $t('vm.root.user.gift.bonus.question-20.text1') }}</p>
					<i18n path="vm.root.user.gift.bonus.question-20.text2.content" tag="p">
						<em>{{ $t('vm.root.user.gift.bonus.question-20.text2.em1') }}</em>
						<em>{{ $t('vm.root.user.gift.bonus.question-20.text2.em2') }}</em>
					</i18n>
					<i18n path="vm.root.user.gift.bonus.question-20.text3.content" tag="p">
						<a href="//votreOsteo.com" target="_blank">{{ $t('vm.root.user.gift.bonus.question-20.text3.a1') }}</a>
						<a href="//osteopathe.eu" target="_blank">{{ $t('vm.root.user.gift.bonus.question-20.text3.a2') }}</a>
						<abbr :title="$t('vm.root.user.gift.bonus.question-20.text3.abbr-title')">{{ $t('vm.root.user.gift.bonus.question-20.text3.abbr-label') }}</abbr>
					</i18n>
					<p>{{ $t('vm.root.user.gift.bonus.question-20.text4') }}</p>
					<p>{{ $t('vm.root.user.gift.bonus.question-20.text5') }}</p>
					<i18n path="vm.root.user.gift.bonus.question-20.text6.content" tag="p">
						<a href="http://forum.osteopathe.com/" target="_blank">{{ $t('vm.root.user.gift.bonus.question-20.text6.a1') }}</a>
						<a href="http://forum.osteopathe.com/annoncez-votre-site-en-rapport-avec-losteopathie-le-monde-de-la-sante-lanatomie-vf13.html" target="_blank">{{ $t('vm.root.user.gift.bonus.question-20.text6.a2') }}</a>
						<strong>{{ $t('vm.root.user.gift.bonus.question-20.text6.strong1') }}</strong>
						<strong>{{ $t('vm.root.user.gift.bonus.question-20.text6.strong2') }}</strong>
						<sup>{{ $t('vm.root.user.gift.bonus.question-20.text6.sup') }}</sup>
					</i18n>
					<code>{{ $t('vm.root.user.gift.bonus.question-20.text7', {'last-name': user.coordinates.last_name, 'first-name': user.coordinates.first_name, url: user.url}) }}</code>
					<p>{{ $t('vm.root.user.gift.bonus.question-20.text8') }}</p>
					<i18n path="vm.root.user.gift.bonus.question-20.text9.content" tag="p" class="text-xs">
						<sup>{{ $t('vm.root.user.gift.bonus.question-20.text9.sup') }}</sup>
						<strong>{{ $t('vm.root.user.gift.bonus.question-20.text9.strong') }}</strong>
					</i18n>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(30)}"
				@click="toggle(30)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-30.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(30)}">
				<div class="misc-collapsible-content input-like">
					<p>{{ $t('vm.root.user.gift.bonus.question-30.text1') }}</p>
					<ol>
						<i18n path="vm.root.user.gift.bonus.question-30.li1.content" tag="li">
							<a href="//osteordv.fr" target="_blank">{{ $t('vm.root.user.gift.bonus.question-30.li1.a') }}</a>
							<strong>{{ $t('vm.root.user.gift.bonus.question-30.li1.strong') }}</strong>
						</i18n>
						<i18n path="vm.root.user.gift.bonus.question-30.li2.content" tag="li">
							<strong>{{ $t('vm.root.user.gift.bonus.question-30.li2.strong') }}</strong>
							<a href="//google.com/search?q=creer+un+SMS+de+reponse+automatique+sur+android" target="_blank">{{ $t('vm.root.user.gift.bonus.question-30.li2.a1') }}</a>
							<a href="//google.com/search?q=creer+un+SMS+de+reponse+automatique+sur+iPhone" target="_blank">{{ $t('vm.root.user.gift.bonus.question-30.li2.a2') }}</a>
							<a :href="`//osteo2ls.com/rdv/cabinets/${user.url}`" target="_blank">{{ $t('vm.root.user.gift.bonus.question-30.li2.a3', {url: user.url}) }}</a>
						</i18n>
						<i18n path="vm.root.user.gift.bonus.question-30.li3.content" tag="li">
							<strong>{{ $t('vm.root.user.gift.bonus.question-30.li3.strong') }}</strong>
							<router-link :to="{name: 'parameters-relaunch'}" :title="$t('vm.root.user.gift.bonus.question-30.li3.link-title')">
								{{ $t('vm.root.user.gift.bonus.question-30.li3.link-label') }}
							</router-link>
						</i18n>
						<i18n path="vm.root.user.gift.bonus.question-30.li4.content" tag="li">
							<a href="https://www.osteo2ls.simplebo.net/?sbmcn=OSTEO2LS_EP&utm_campaign=Osteo2ls+espace+prive&utm_source=Osteo2ls&utm_medium=Site+internet+public&job=f666c51d71c62ccf2e34ddaf" target='_blank' :title="$t('vm.root.user.gift.bonus.question-30.li4.a-title')">{{ $t('vm.root.user.gift.bonus.question-30.li4.a-label') }}</a>
							<strong>{{ $t('vm.root.user.gift.bonus.question-30.li4.strong') }}</strong>
						</i18n>
					</ol>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(40)}"
				@click="toggle(40)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-40.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(40)}">
				<div class="misc-collapsible-content input-like">
					<i18n path="vm.root.user.gift.bonus.question-40.text.content" tag="p">
						<a href="//facebook.com/osteo2ls" target="_blank">{{ $t('vm.root.user.gift.bonus.question-40.text.a1') }}</a>
						<a href="//twitter.com/osteo2ls" target="_blank">{{ $t('vm.root.user.gift.bonus.question-40.text.a2') }}</a>
					</i18n>
					<ol>
						<li>
							<i18n path="vm.root.user.gift.bonus.question-40.li1.text" tag="a" href='//osteo2ls.com/rdv/assets/img/help-facebook/step1.png' target='_blank'>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li1.strong') }}</strong>
							</i18n>
						</li>
						<li>
							<i18n path="vm.root.user.gift.bonus.question-40.li2.text" tag="a" href='//osteo2ls.com/rdv/assets/img/help-facebook/step2.png' target='_blank'>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li2.strong1') }}</strong>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li2.strong2') }}</strong>
							</i18n>
						</li>
						<li>
							<i18n path="vm.root.user.gift.bonus.question-40.li3.text" tag="a" href='//osteo2ls.com/rdv/assets/img/help-facebook/step3.png' target='_blank'>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li3.strong') }}</strong>
							</i18n>
						</li>
						<li>
							<i18n path="vm.root.user.gift.bonus.question-40.li4.text" tag="a" href='//osteo2ls.com/rdv/assets/img/help-facebook/step4.png' target='_blank'>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li4.strong') }}</strong>
							</i18n>
						</li>
						<li>
							<a href='//osteo2ls.com/rdv/assets/img/help-facebook/step5.png' target='_blank'>
								<strong>{{ $t('vm.root.user.gift.bonus.question-40.li5') }}</strong>
							</a>
						</li>
					</ol>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(50)}"
				@click="toggle(50)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-50.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(50)}">
				<div class="misc-collapsible-content input-like">
					<i18n path="vm.root.user.gift.bonus.question-50.text1.content" tag="p">
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text1.strong1') }}</strong>
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text1.strong2') }}</strong>
						<a :href="`//osteo2ls.com/rdv/cabinets/${user.url}`" target='_blank'>{{ $t('vm.root.user.gift.bonus.question-50.text1.a1') }}</a>
						<a :href="`//google.fr/search?q=${user.coordinates.first_name} ${user.coordinates.last_name} ostéopathe`" target='_blank'>{{ $t('vm.root.user.gift.bonus.question-50.text1.a2') }}</a>
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text1.strong3') }}</strong>
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text1.strong4') }}</strong>
					</i18n>
					<i18n path="vm.root.user.gift.bonus.question-50.text2.content" tag="p">
						<em>{{ $t('vm.root.user.gift.bonus.question-50.text2.em') }}</em>
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text2.strong') }}</strong>
						<i>{{ $t('vm.root.user.gift.bonus.question-50.text2.i') }}</i>
					</i18n>
					<i18n path="vm.root.user.gift.bonus.question-50.text3.content" tag="p">
						<strong><a href='//www.google.fr/business' target='_blank'>{{ $t('vm.root.user.gift.bonus.question-50.text3.strong1') }}</a></strong>
						<a :href="`//osteo2ls.com/rdv/cabinets/${user.url}`" target='_blank'>{{ $t('vm.root.user.gift.bonus.question-50.text3.a') }}</a>
						<strong>{{ $t('vm.root.user.gift.bonus.question-50.text3.strong2') }}</strong>
					</i18n>
					<p>{{ $t('vm.root.user.gift.bonus.question-50.text4') }}</p>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(60)}"
				@click="toggle(60)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-60.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(60)}">
				<div class="misc-collapsible-content input-like">
					<i18n path="vm.root.user.gift.bonus.question-60.text.content" tag="p">
						<strong>{{ $t('vm.root.user.gift.bonus.question-60.text.strong1') }}</strong>
						<strong>{{ $t('vm.root.user.gift.bonus.question-60.text.strong2') }}</strong>
					</i18n>
				</div>
			</div>
		</div>
		<div class="marg-v">
			<button type="button" class="misc-trigger btn--link-like" :class="{'is-active': isToggle(70)}"
				@click="toggle(70)" :title="$t('vm.root.user.gift.bonus.question-title')">
				<svg width="1em" class="svg-middle misc-trigger-arrow" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-down"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.question-70.title') }}
			</button>
			<div class="misc-collapsible" :class="{'is-active': isToggle(70)}">
				<div class="misc-collapsible-content input-like">
					<i18n path="vm.root.user.gift.bonus.question-70.text.content" tag="p">
						<router-link :to="{name: 'user-referral'}" :title="$t('vm.root.user.gift.bonus.question-70.text.link-title')">{{ $t('vm.root.user.gift.bonus.question-70.text.link-label') }}</router-link>
					</i18n>
				</div>
			</div>
		</div>
		
		<control-bar class="childs-space-between">
			<button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.user.gift.bonus.back.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.gift.bonus.back.label') }}
			</button-action>
		</control-bar>
	</section>	
</div>
