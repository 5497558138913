export const drawer = {
    undo: {
        label: "annuler",
        title: "Annuler"
    },
    clear: {
        title: "Effacer ces annotations",
        'delete-msg': "Vous êtes sur le point d'effacer les annotations de ce schéma. Souhaitez-vous continuer ?",
        'delete-label': "effacer",
        label: "tout effacer"
    }
}