import Render from '@Views/root/parameters/patient.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IConfiguration, IColor, IDisplay, IBreadcrumbItem } from '@Store/types'
import { breadcrumb } from '@Store/modules'

@Render
@Component({})
export class Patient extends Vue {

	eva_before: number = 0
	eva_after: number = 10

	@Prop() configuration: IConfiguration

	//#region getters
	get colors(): IColor {
		return this.configuration.colors
	}
	get display(): IDisplay {
		return this.configuration.display
	}
	//#endregion

	mounted() {
		this.$emit('navigate', 'patient')
		breadcrumb.updateLinked(false)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.parameters').toString(), link: "parameters-main"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
