<a-side :sub-menu="true">
    <ul class="aside-navigation">
        <li>
            <router-link :to="{name: 'parameters-main'}" :title="$t('vm.root.parameters.aside.general.title')">
                {{ $t('vm.root.parameters.aside.general.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSubstituteUser">
            <router-link :to="{name: 'parameters-patient'}" :title="$t('vm.root.parameters.aside.patient.title')">
                {{ $t('vm.root.parameters.aside.patient.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSecretaryUser">
            <router-link :to="{name: 'parameters-planning'}" :title="$t('vm.root.parameters.aside.planning.title')">
                {{ $t('vm.root.parameters.aside.planning.label') }}
            </router-link>
        </li>
        <li v-if="!isSecretaryUser">
            <router-link :to="{name: 'parameters-accounting'}" :title="$t('vm.root.parameters.aside.accounting.title')">
                {{ $t('vm.root.parameters.aside.accounting.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSubstituteUser || isSecretaryUser" class="hr" aria-hidden="true"/>
        <li v-if="isMainUser || isSecretaryUser">
            <router-link :to="{name: 'parameters-confirmation'}" :title="$t('vm.root.parameters.aside.confirmation.title')">
                {{ $t('vm.root.parameters.aside.confirmation.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSecretaryUser">
            <router-link :to="{name: 'parameters-reminder'}" :title="$t('vm.root.parameters.aside.reminder.title')">
                {{ $t('vm.root.parameters.aside.reminder.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSubstituteUser">
            <router-link :to="{name: 'parameters-follow-up'}" :title="$t('vm.root.parameters.aside.follow-up.title')">
                {{ $t('vm.root.parameters.aside.follow-up.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser">
            <router-link :to="{name: 'parameters-relaunch'}" :title="$t('vm.root.parameters.aside.relaunch.title')">
                {{ $t('vm.root.parameters.aside.relaunch.label') }}
            </router-link>
        </li>
        <li v-if="isMainUser || isSubstituteUser || isSecretaryUser" class="hr" aria-hidden="true"/>
        <li v-if="isMainUser">
            <router-link :to="{name: 'parameters-forms'}" class="with-badge" :title="$t('vm.root.parameters.aside.forms.title')">
                <span class="with-badge-label">{{ $tc('vm.root.parameters.aside.forms.label', formsCount) }}</span>
                <span class="with-badge-badge">{{formsCount}}</span>
            </router-link>
        </li>
        <li v-if="isMainUser || isSubstituteUser">
            <router-link :to="{name: 'parameters-document'}" class="with-badge" :title="$t('vm.root.parameters.aside.letters.title')"
                v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
                <span class="with-badge-label">{{ $tc('vm.root.parameters.aside.letters.label', documentsCount) }}</span>
                <span class="with-badge-badge">{{documentsCount}}</span>
            </router-link>
        </li>
        <li v-if="isMainUser || isSecretaryUser" class="hr" aria-hidden="true"/>
        <li v-if="isMainUser || isSecretaryUser">
            <router-link :to="{name: 'parameters-notifications'}" :title="$t('vm.root.parameters.aside.notifications.title')">
                {{ $t('vm.root.parameters.aside.notifications.label') }}
            </router-link>
        </li>
        <li v-if="isLocalhost" class="hr" aria-hidden="true"/>
        <li v-if="isLocalhost">
            <router-link :to="{name: 'parameters-test'}" title="Page test">Page test</router-link>
        </li>
    </ul>
</a-side>
