export const detailsReceipt = {
    label: {
        label: "Libellé de la recette",
        placeholder: "ex : Consultation de Mme. Odile Deray",
        'error-msg': "Merci de renseigner un libellé à votre recette",
        title: "Indiquez un libellé pour identifier cette recette"
    },
    date: {
        label: "Date d'émission de la recette",
        title: "Indiquez la date de création de cette recette",
        placeholder: "ex: 30 Février 2016"
    },
    session: {
        label: "Type de consultation lié à la recette",
        title: "Sélectionnez le type de recette associé à cette recette",
        none: "Aucun type de consultation"
    },
    office: {
        label: "Cabinet lié à la recette",
        title: "Sélectionnez le cabinet associé à cette recette"
    },
    number: {
        label: "Numéro de la recette",
        placeholder: "ex : 20000123",
        title: "Indiquez un numéro unique, idéalement consécutif au précédent"
    },
    'modify-number': {
        title: "Activez ce switch si vous souhaitez modifier manuellement le numéro de la recette",
        label: "Modifier le numéro de la recette"
    },
    type: {
        label: "Type de recette",
        title: "Sélectionnez le type de recette"
    },
    mode: {
        label: "Mode de paiement pour cette recette",
        title: "Sélectionnez le mode de paiement utilisé pour régler cette recette"
    },
    amount: {
        label: "Montant de la recette (en euros)",
        placeholder: "ex : 55 €",
        title: "Indiquez le montant de la recette"
    },
    tva: {
        label: "Taux de TVA associé à la recette (en %)",
        placeholder: "ex : 20 %",
        title: "Indiquez le taux de TVA associé à la recette"
    },
    checked: {
        title: "Indique que la recette a bien été réglée",
        label: "Recette réglée"
    },
    banked: {
        title: "Indique que le montant de la recette a bien été déposé en banque",
        label: "Déposé en banque"
    },
    retrocession: {
        label: "Montant lié à la rétrocession (en euros)",
        placeholder: "ex : 20 €",
        title: "Indiquez le montant de rétrocédé pour cette recette"
    },
    'mode-retrocession': {
        label: "Mode de paiement pour la rétrocession",
        title: "Sélectionnez le mode de paiement utilisé pour régler la rétrocession"
    },
    bank: {
        label: "Information bancaire",
        placeholder: "ex : numéro de chèque ou de transaction",
        title: "Indiquez l'information bancaire liée au paiement de cette recette"
    }
}