<container :loading="!event" :loading-title="$t('vm.root.calendar.event.main.loading')">
	<aside-event @delete="onDelete"/>

	<master v-if="!!event">
		<central>

			<div class="event-header">
				<div class="wrapper">
					<div v-if="!!event.patient" class="event-header-wrapper">
						<div class="event-header-flex">
							<div class="event-header-avatar">
								<avatar :first-name="patient.coordinates.first_name" :last-name="patient.coordinates.last_name" :email="patient.coordinates.email" :gender="patient.coordinates.gender"/>
							</div>
							<h1 class="h-reset">
								<div class="childs-space-between text-sm">
									<div>
										<span v-if="online" class="text-bold">{{ $t('vm.root.calendar.event.main.rdv-online-with', {date: `${$d(event.date_create, 'l')}`, hour: $d(event.date_create, 'LT'), owner: owner }) }}</span>
										<span v-else class="text-bold">{{ $t('vm.root.calendar.event.main.rdv-with', {date: `${$d(event.date_create, 'l')}`, hour: $d(event.date_create, 'LT'), owner: owner }) }}</span>
									</div>
									<div v-if="isRemind">
										<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
											<use xlink:href="@Assets/svg/symbol-defs.svg#alarm-exclamation"/>
										</svg>
										{{ $t('vm.root.calendar.event.main.reminder.text') }}
										<strong v-if="reminderSms">{{ $t('vm.root.calendar.event.main.reminder.sms') }}</strong>
										<span v-if="reminderSms && reminderMail">{{ $t('vm.root.calendar.event.main.reminder.and') }}</span>
										<strong v-if="reminderMail">{{ $t('vm.root.calendar.event.main.reminder.mail') }}</strong>
									</div>
								</div>
								<div class="event-header-title h-like text-capitalize">
									<router-link :to="{name:'patient-coordinates', params: {pat_id: patient.id}}"
										:title="$t('vm.root.calendar.event.main.link-title', {first_name: patient.coordinates.first_name, last_name: patient.coordinates.last_name})">
										<span>{{patient.coordinates.first_name}} {{patient.coordinates.last_name}}</span>
									</router-link><span v-if="!!event.label">&nbsp;:</span>
									<span v-if="!!event.label" class="text-default">{{event.label}}</span>
								</div>
							</h1>
						</div>
					</div>

					<div v-else class="event-header-wrapper">
						<h1 class="h-reset text-xl text-bold text-primary">{{ event.label || $t('vm.root.calendar.event.main.new-event') }}</h1>
					</div>

				</div>
			</div>

			<div class="wrapper marg-v">
				<form @submit.prevent="submitSave">
					<router-view :event="event" :saved="saved" :errors="errors" :prefix="prefix"/>

					<control-bar class="childs-space-between">
						<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="isSaving || isDeleting || disabled" :title="$t('vm.root.calendar.event.main.cancel.title')">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
							</svg>
							{{ $t('vm.root.calendar.event.main.cancel.label') }}
							<span class="rwd-over-xlarge">{{ $t('vm.root.calendar.event.main.cancel.long') }}</span>
						</button-action>

						<button-action type="submit" btn-class="btn-primary" :loading="isSaving" :disabled="disabled || isDeleting" :title="$t('general.save.title')">
							{{ $t('general.save.text') }}
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
							</svg>
						</button-action>
					</control-bar>
				</form>
			</div>

		</central>

		<context>
			<context-event :event="event"/>
		</context>
	</master>

</container>
