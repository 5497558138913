import { PhoneType } from '@Enums/index'
import { IColleague } from '@Store/types'

export class Colleague {
    public static createNewColleague(): IColleague {
        return {
            is_new: true,
            id: '-1',
            last_name: '', 
            first_name: '', 
            patient_count: 1,
            phone: {id: '-1', number: '', type: PhoneType.Mobil, iso: 'FR'},
            professions: [],
            email: '',
            address: {
                id: '-1',
                number: '',
                street: '',
                zipcode: '',
                city: '',
                country: '',
                formatted: ''
            }
        }
    }
 }
