import { IForm, IQuestion, IChoice, IConfigForm, IConfigQuestion, IConfigChoice } from '@Store/types'
import { FormType } from '@Enums/index'

export class Form {

	public static convertConfigurationForms(sourceForms: IConfigForm[], type: FormType): IForm[] {

		let forms: IConfigForm[] = sourceForms.filter(f => { return f.type === type })
		if (!forms) forms = []

		return forms.map(f => Form.convertConfigurationForm(f))
	}

	private static convertConfigurationForm(sourceForm: IConfigForm): IForm {
		let form: IForm = {
			id: sourceForm.id,
			name: sourceForm.name,
			questions: sourceForm.questions.currents.map(q => Form.convertConfigurationQuestion(q))
		}
		return form
	}

	private  static convertConfigurationQuestion(sourceQuestion: IConfigQuestion): IQuestion {
		let question: IQuestion = {
			id: sourceQuestion.id,
			type: sourceQuestion.type,
			title: sourceQuestion.title,
			answer: '',
			choices: sourceQuestion.choices.currents.map(c => Form.convertConfigurationChoice(c))
		}
		return question
	}
	
	private  static convertConfigurationChoice(sourceChoice: IConfigChoice): IChoice {
		let choice: IChoice = {
			id: sourceChoice.id,
			label: sourceChoice.label,
			selected: false
		}
		return choice
	}
}