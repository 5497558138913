<div v-if="colleague" class=" grid-2">
    <input-frame :auto-focus="true" id="last-name" v-model="colleague.last_name"
        :label="$t('vm.root.statistics.colleagues.details-colleague.last-name.label')" 
        :placeholder="$t('vm.root.statistics.colleagues.details-colleague.last-name.placeholder')"
        :error-test="hasLastNameError" :error-msg="$t('vm.root.statistics.colleagues.details-colleague.last-name.error-msg')"
        :title="$t('vm.root.statistics.colleagues.details-colleague.last-name.title')"/>

    <input-frame id="first-name" v-model="colleague.first_name"
        :label="$t('vm.root.statistics.colleagues.details-colleague.first-name.label')" 
        :placeholder="$t('vm.root.statistics.colleagues.details-colleague.first-name.placeholder')"
        :error-test="hasFirstNameError" :error-msg="$t('vm.root.statistics.colleagues.details-colleague.first-name.error-msg')"
        :title="$t('vm.root.statistics.colleagues.details-colleague.first-name.title')"/>

    <input-phone-frame id="phone" :phone="colleague.phone"
        :label="$t('vm.root.statistics.colleagues.details-colleague.phone.label')" 
        :error-test="hasLastNameError" :error-msg="$t('vm.root.statistics.colleagues.details-colleague.phone.error-msg')"
        :title="$t('vm.root.statistics.colleagues.details-colleague.phone.title')"/>

    <input-frame type="email" id="email" v-model="colleague.email"
        :label="$t('vm.root.statistics.colleagues.details-colleague.email.label')" 
        :placeholder="$t('vm.root.statistics.colleagues.details-colleague.email.placeholder')"
        :title="$t('vm.root.statistics.colleagues.details-colleague.email.title')"/>

    <input-address-frame id="address" class="grid-item-full" :label="$t('vm.root.statistics.colleagues.details-colleague.address')" :address="colleague.address"/>

    <dropdown-editable-frame class="grid-item-full" id="profession" :searcher="getProfessionList" :multiple="true" :taggable="true" v-model="colleague.professions"
        :label="$t('vm.root.statistics.colleagues.details-colleague.profession.label')" 
        :no-options-label="$t('vm.root.statistics.colleagues.details-colleague.profession.no-options-label')" 
        :placeholder="$t('vm.root.statistics.colleagues.details-colleague.profession.placeholder')"
        :title="$t('vm.root.statistics.colleagues.details-colleague.profession.title')"/>

</div>
