export const memberItem = {
    'no-date': "âge non déterminé",
    unselect: {
        label: "dissocier",
        title: "Dissociation",
        content: "Vous-êtes sûr de vouloir dissocier ce membre de la famille de ce dossier ?"
    },
	deceased: "décédé",
    title: "Accéder au dossier patient de {first-name} {last-name}",
}
