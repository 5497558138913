<div>
	<h1>{{ $t('vm.root.statistics.patients.main.title') }}</h1>

	<div v-if="isLoading" class="marg-20 text-center">
		<spinner class="svg-xl text-primary"/>
		<div class="text-md text-bold marg-v">
			{{ $t('vm.root.statistics.patients.main.loading') }}
		</div>
	</div>

	<template v-else>
		<div class="grid-2">
			<div>
				<h2>{{ $t('vm.root.statistics.patients.main.evolution-title', {year: filter.year1}) }}</h2>
				<apexchart type="area" :options="evolutionChartOptions" :series="evolutionSeries1"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legends" class="text-xs" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/> ({{ getTotalSeries1(legend.label) }})
					</li>
				</ul>
			</div>

			<div>
				<h2>{{ $t('vm.root.statistics.patients.main.evolution-title', {year: filter.year2}) }}</h2>
				<apexchart type="area" :options="evolutionChartOptions" :series="evolutionSeries2"/>
				<ul class="ul-reset childs-space-around">
					<li v-for="legend in legends" class="text-xs" :key="legend.label">
						<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
						</svg>
						<span v-html="legend.label"/> ({{ getTotalSeries2(legend.label) }})
					</li>
				</ul>
			</div>
		</div>

		<hr>

		<h2>{{ $t('vm.root.statistics.patients.main.ratio-title', {total: total}) }}</h2>
		<apexchart type="donut" :options="ratioChartOptions" :series="ratioSeries"/>
		<hr>
		<ul class="ul-reset childs-space-around">
			<li v-for="legend in legends" :key="legend.label">
				<svg width="1em" class="svg-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
				</svg>
				{{ legend.label }}
			</li>
		</ul>
		<hr>

		<h2>{{ $t('vm.root.statistics.patients.main.chart1-title') }}</h2>
		<apexchart type="bar" :options="pyramidChartOptions" :series="pyramidSeries"/>
		<hr>

		<h2>{{ $t('vm.root.statistics.patients.main.chart2-title') }}</h2>
		<apexchart type="bar" :options="pediatryChartOptions" :series="pediatrySeries"/>
	</template>

</div>
