import Render from '@Views/login/loader.html'
import config from "@Config/index"
import { Vue, Component, Watch } from 'vue-property-decorator'
import { FontHelper, StorageHelper, LangHelper, SkinHelper } from '@Helpers/index'
import { DefaultPage, FontSize, Skin } from '@Enums/index'
import {user, shop, notif, tag, support, account, configuration, office, planning} from '@Store/modules'
import { IConfiguration, INotification, INotificationAction, IChangeLog } from '@Store/types'

@Render
@Component({})
export class Loader extends Vue {

	// private service: GoogleService = null
	private loaded: boolean = false
	private failed: boolean = false
	private queue: {label: string, loader: ILoader}[] = []
	loaderMessage: string = ''

	private get configuration(): IConfiguration {
		return configuration.configuration
	}
	private get changeLog(): IChangeLog {
		return support.changeLog
	}
	private get version() {
		return config.version
	}

	mounted() {
		StorageHelper.remove('osteo2ls-lic')
		// this.service = GoogleService.getInstance()
		// this.service.$on('authorized-updated', () => {
		// 	planning.authorizedGoogle(this.service.authorized)
		// })

		this.initializeQueue()
		this.initialize()
	}

	beforeDestroy() {
		// this.service.$off('authorized-updated')
	}

	@Watch('loaded')
	callRedirect() {
		if (!this.loaded) return

		this.redirect()
	}

	@Watch('failed')
	loadFailed() {
		if (!this.failed) return

		let retry: INotificationAction = { label: this.$i18n.t('vm.login.loader.failed.retry').toString(), callback: this.initialize }
		let cancel: INotificationAction = { label: this.$i18n.t('vm.login.loader.failed.cancel').toString(), callback: this.cancel }
		let notification: INotification = { message: this.$i18n.t('vm.login.loader.failed.message').toString(), actions: [retry, cancel], delay: false, canClose: false }
		notif.error(notification)
	}

	private initialize(): void {
		notif.clear()
		this.loaded = false
		this.failed = false

		this.dequeue()
	}

	private initializeQueue(): void {
		this.queue.push({ label: 'vm.login.loader.loading.change-log', loader: {callback: support.loadChangeLog} })
		this.queue.push({ label: 'vm.login.loader.loading.check-version', loader: {callback: this.checkVersion} })
		this.queue.push({ label: 'vm.login.loader.loading.configuration', loader: {callback: configuration.loadConfiguration} })
		this.queue.push({ label: 'vm.login.loader.loading.accounts', loader: {callback: account.loadAccounts} })
		this.queue.push({ label: 'vm.login.loader.loading.offices', loader: {callback: office.loadOffices} })
		this.queue.push({ label: 'vm.login.loader.loading.schedules', loader: {callback: office.loadSchedules} })
		this.queue.push({ label: 'vm.login.loader.loading.sessions', loader: {callback: office.loadSessions} })
		this.queue.push({ label: 'vm.login.loader.loading.plannings', loader: {callback: this.initializePlannings} })
		this.queue.push({ label: 'vm.login.loader.loading.settings', loader: {callback: office.loadSettings} })
		this.queue.push({ label: 'vm.login.loader.loading.sphere-tags', loader: {callback: tag.loadSphereTags} })
		this.queue.push({ label: 'vm.login.loader.loading.antecedent-tags', loader: {callback: tag.loadAntecedentTags} })
		this.queue.push({ label: 'vm.login.loader.loading.faq', loader: {callback: support.loadFaq} })
		this.queue.push({ label: 'vm.login.loader.loading.shop', loader: {callback: shop.loadShop} })
		// this.queue.push({ label: 'vm.login.loader.loading.plannings-google', loader: {callback: this.initializeGooglePlannings} })
	}

	private dequeue(): void {
		if (this.queue.length === 0) {
			this.loaded = true
		} else {
			let item: {label: string, loader: ILoader} = this.queue.shift()
			this.loaderMessage = item.label
			let self = this
			setTimeout(function() {
				if (!!item.loader.callback) {
					item.loader.callback()
					.then(() => {
						if (!!item.loader.postProcess) {
							item.loader.postProcess()
						}
						self.dequeue()
					})
					.catch(() => { self.failed = true })
				} else {
					self.dequeue()
				}
			}, 333)
		}
	}

	private checkVersion(): Promise<any> {
		let retry: boolean = StorageHelper.get('retry', '') !== ''
		if (!retry && this.needReload(this.version, this.changeLog.version)) {
			StorageHelper.set('retry', this.changeLog.version)
			location.reload()
			return
		}
		StorageHelper.remove('retry')
		return Promise.resolve()
	}

	private needReload(currentVersion: string, serverVersion: string): boolean {
		if (!currentVersion || !serverVersion) return false

		let version1: string[] = currentVersion.split(".")
		let version2: string[] = serverVersion.split(".")

		return version1.length === 3 && version2.length === 3 &&
		(parseInt(version2[0]) > parseInt(version1[0])
		|| (parseInt(version2[0]) === parseInt(version1[0]) && parseInt(version2[1]) > parseInt(version1[1]))
		|| (parseInt(version2[0]) === parseInt(version1[0]) && parseInt(version2[1]) === parseInt(version1[1]) && parseInt(version2[2]) > parseInt(version1[2])))
	}

	private cancel(): void {
		notif.clear()
		this.$router.push({name: 'login'})
	}

	private redirect(): void {
		let route:any = StorageHelper.get('path', null)
		let defaultPage: any = this.getDefaultPage(!!route ? route : {name: 'home'})

		StorageHelper.remove('patients-filter')
		this.$router.push(defaultPage)
		this.applyConfiguration()
		if (Notification.permission === "granted") return

		Notification.requestPermission()
	}

	private applyConfiguration() {
		if (!(<any>Object).values(Skin).includes(this.configuration.theme)) {
			this.configuration.theme = Skin.Default
		}

		SkinHelper.updateSkin(this.configuration.theme, Skin.Default)
		FontHelper.updateFontSize(this.configuration.font_size, FontSize.Normal)

		LangHelper.updateLang(this.configuration.lang)
	}

	private getDefaultPage(none: any): any {
		switch (this.configuration.default_page) {
			case DefaultPage.Dashboard:
				return {name: 'home'}
			case DefaultPage.Patients:
				return {name: 'patients'}
			case DefaultPage.Planning:
				return {name: 'plannings'}
			case DefaultPage.Accounting:
				return {name: 'accounting'}
			case DefaultPage.Letters:
				return {name: 'letters'}
			case DefaultPage.CoWorking:
				return {name: 'co-working'}
			case DefaultPage.Shop:
				return {name: 'shop'}
			case DefaultPage.Help:
				return {name: 'help'}
			case DefaultPage.Statistics:
				return {name: 'statistics-patients'}
			case DefaultPage.Parameters:
				return {name: 'parameters-main'}
			case DefaultPage.User:
				return {name: 'user-account'}
			default:
				return none
		}
	}

	private initializePlannings(): any {
		if (!user.user) return
		return planning.loadPlannings({profile: user.user.profile})
	}

	// private initializeGooglePlannings(): Promise<any> {
	// // 	Vue.use(LoaderPlugin, {
	// // 		client_id: config.googleClientId
	// // 	})
	// // 	return Vue.GoogleAuth
	// 	// 	.then(auth2 => {
	// 	// 	return Promise.resolve()
	// 	// 	console.log(auth2.isSignedIn.get());
	// 	// 	console.log(auth2.currentUser.get())
	// 	// })
	//
	//
	// 	if (this.service.initializing) {
	// 		this.queue.push({ label: "vm.login.loader.loading.plannings-google", loader: {callback: this.initializeGooglePlannings} })
	// 		return Promise.resolve()
	// 	}
	//
	// 	// if (this.service.authorized) {
	// 	// 	return planning.loadGooglePlannings()
	// 	// } else if (!this.service.initializing) {
	// 	// 	return Promise.resolve()
	// 	// }
	// }
}

interface ILoader {
	callback: () => any,
	postProcess?: () => any
}
