export const context = {
    search: {
        placeholder: "ex : chir lig",
        title: "Veuillez renseigner un filtre pour rechercher un élément précis",
        'activated-filter': "Aucun filtre | {count} filtre activé | {count} filtres activés"
    },
    'expand-all': "Déplier tout",
    'collapse-all': "Replier tout",
    filter: {
        consultations: "consultation|consultation|consultations",
        antecedents: "antécédent|antécédent|antécédents",
        spheres: "sphère|sphère|sphères",
        attachments: "pièce jointe|pièce jointe|pièces jointes",
        pediatrics: "périnatalité"
    },
    'new-item': {
        label: "Quoi de neuf ?",
        add: "ajouter",
        title: "Ajouter un nouvel élément à ce dossier patient",
        'new-antecedent': "Ajouter un nouvel antécedent",
        'new-sphere': "Ajouter une nouvelle sphère",
        'new-consultation': "Ajouter une nouvelle consultation",
        'new-attachment': "Ajouter une nouvelle pièce jointe",
    },
    title: "Timeline de {name}",
    family: "Famille",
    birth: "Naissance",
    'missing-birth-date': "Date de naissance manquante",
    'new-consultation': {
        title: "Créer une nouvelle consultation",
        text: "Nouvelle consultation"
    },
    none: {
        title: "Pas de consultation.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer une nouvelle consultation",
            link: "une nouvelle consultation"
        }
    }
}