<div v-if="offices">

	<div class="childs-space-between marg-v">
		<h1 class="no-marg">{{ $tc('vm.root.user.offices.title', offices.length) }}</h1>
		<button type="button" class="btn--link-like is-pushed-right" @click="addOffice" :title="$t('vm.root.user.offices.new-office.title')">
			<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#plus"/>
			</svg>
			<span>{{ $t('vm.root.user.offices.new-office.label') }}</span>
		</button>
	</div>
	<div v-if="offices.length === 0" class="marg-v text-center">
		<svg width="1em" class="text-primary svg-xl" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#chair-office"/>
		</svg>
		<p>
			<span class="text-lg">{{ $t('vm.root.user.offices.no-active.label') }}</span><br>
			<i18n path="vm.root.user.offices.no-active.text" tag="span">
				<button type="button" class="btn--link-like" @click="addOffice" :title="$t('vm.root.user.offices.no-active.button-title')">
					{{ $t('vm.root.user.offices.no-active.button-label') }}
				</button>
			</i18n>
		</p>
	</div>

	<ul v-else class="ul-reset marg-v">
		<li v-for="office in offices" :key="office.id">
			<section class="bordered-panel marg-v">
				<div class="childs-space-between">
					<h2 class="text-uppercase-first-letter text-md no-marg">{{office.coordinates.name}}</h2>
					<span class="tag-success is-pushed-right">
						{{ $t('vm.root.user.offices.enabled') }}
					</span>
				</div>
				<i18n v-if="!!owner(office)" path="vm.root.user.offices.details-enabled.owner" tag="p">
					<span>{{owner(office).coordinates.first_name}}</span>
					<strong>{{owner(office).coordinates.last_name}}</strong>
				</i18n>
				<em v-else>{{ $t('vm.root.user.offices.details-enabled.no-owner') }}</em>
				<div class="text-right">
					<router-link tag="button" :to="{name: 'office-coordinates', params: {ofi_id: office.id}}" type="button" class="btn--link-like"
						:title="$t('vm.root.user.offices.details-enabled.update.title')">
						<span>{{ $t('vm.root.user.offices.details-enabled.update.label') }}</span>
					</router-link>
					&bull;
					<delete-item class="btn--link-like text-danger"
						:delete-msg="$t('vm.root.user.offices.details-enabled.delete.msg')"
						:delete-label="$t('vm.root.user.offices.details-enabled.delete.label')"
						:title="$t('vm.root.user.offices.details-enabled.delete.title')"
						:delete-action="() => submitDeleteOffice(office)" :can-delete="true">
						<span>{{ $t('vm.root.user.offices.details-enabled.delete.label') }}</span>
					</delete-item>
				</div>
			</section>
		</li>
	</ul>

	<template v-if="disableOffices.length > 0">

		<hr>

		<collapsible-help class="marg-v" id="disable-office-warning" :title="$tc('vm.root.user.offices.details-disabled.title', disableOffices.length)" tag="h2">
			<svg slot="icon" width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
			</svg>
			<div class="notice-warning">
				<p class="text-left">
					<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true">
						<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle"/>
					</svg>
					{{ $t('vm.root.user.offices.details-disabled.warning') }}
				</p>
			</div>
		</collapsible-help>

		<ul class="ul-reset marg-v">
			<li v-for="office in disableOffices" :key="office.id">
				<div class="collapsible-panel bordered-panel marg-v">
					<div class="childs-space-between">
						<strong class="text-uppercase-first-letter">{{office.coordinates.name}}</strong>
						<span class="tag-warning is-pushed-right">
							{{ $t('vm.root.user.offices.disabled') }}
						</span>
					</div>

					<div class="collapsible-panel-container is-active">
						<div class="collapsible-panel-content">
							<div>{{ $t('vm.root.user.offices.details-disabled.allow-access') }}</div>
							<div class="text-right" v-if="isOwner(office)" >
								<button type="button" class="btn--link-like" @click="submitActivateOffice(office)"
									:title="$t('vm.root.user.offices.details-disabled.enabled.title')">
									{{ $t('vm.root.user.offices.details-disabled.enabled.label') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>

	</template>
</div>
