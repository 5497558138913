<div>
	<h1>{{ $t('vm.root.user.gift.gift1.title') }}</h1>
	<form @submit.prevent="submitGift">
		<div v-if="isWaiting" class="wrapper marg-v text-center">
			<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
			</svg>
			<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift1.waiting.title') }}</strong></p>
			<p>{{ $t('vm.root.user.gift.gift1.waiting.text') }}</p>
		</div>
		<div v-else-if="isValidated" class="wrapper marg-v text-center">
			<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
			</svg>
			<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift1.valided.title') }}</strong></p>
			<p>{{ $t('vm.root.user.gift.gift1.valided.text') }}</p>
		</div>
		<section v-else>
			<h2>{{ $tc('vm.root.user.gift.gift1.subtitle', offices.length) }}</h2>

			<i18n path="vm.root.user.gift.gift1.help.text1" tag="p">
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong1') }}</strong>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong2') }}</strong>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong3') }}</strong>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong4') }}</strong>
			</i18n>

			<i18n path="vm.root.user.gift.gift1.help.text2" tag="p">
				<em>{{ $t('vm.root.user.gift.gift1.help.em') }}</em>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong5') }}</strong>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong6') }}</strong>
			</i18n>

			<i18n path="vm.root.user.gift.gift1.help.text3" tag="p">
				<router-link :to="{name: 'user-gift-bonus'}" :title="$t('vm.root.user.gift.gift1.help.link-title')">
					{{ $t('vm.root.user.gift.gift1.help.link-label') }}
				</router-link>
				<strong>{{ $t('vm.root.user.gift.gift1.help.strong7') }}</strong>
			</i18n>

			<section>
				<h3>{{ $t('vm.root.user.gift.gift1.progression.title') }}</h3>
				<div class="grid-2">
					<apexchart type="radialBar" height="150" :options="chartOptions" :series="series" class="illustration" />
					<ul class="ul-reset">
						<li v-for="(legend, index) in legends" :key="legend.label" class="marg-v">
							<svg width="1em" class="text-middle" :style="`color:${legend.color}`" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#circle"/>
							</svg>
							{{ legend.label }} :&nbsp;
							<span v-if="series[index] >= 100">
								<svg width="1em" class="text-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
								</svg>
								{{ $t('vm.root.user.gift.gift1.progression.complete') }}
							</span>
							<router-link :to="{name: legend.link}" v-else>
								{{ $t('vm.root.user.gift.gift1.progression.fill-out') }}
								<svg width="1em" class="text-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
									<use xlink:href="@Assets/svg/symbol-defs.svg#angle-right"/>
								</svg>
							</router-link>
						</li>
					</ul>
				</div>
			</section>
		</section>
		<control-bar class="childs-space-between">
			<button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.user.gift.gift1.back.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.gift.gift1.back.label') }}
			</button-action>

			<span>
				<button-action type="submit" btn-class="btn btn-primary" :disabled="!canSubmit" :loading="isSaving" :title="$t('vm.root.user.gift.gift1.action.title')">
					{{ $t('vm.root.user.gift.gift1.action.label') }}
				</button-action>

				<button-action btn-class="btn btn-default" @click.native="next" :title="$t('vm.root.user.gift.gift1.next.title')">
					{{ $t('vm.root.user.gift.gift1.next.label') }}
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</button-action>
			</span>
		</control-bar>
	</form>

</div>
