<a-side :sub-menu="true">
	<ul class="aside-navigation">
		<li>
			<router-link :to="{name: 'user-account'}" :title="$t('vm.root.user.gift.aside.back.label')"
				v-toggle="{className: 'is-open', reducer: '[data-toggle-aside]'}">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.gift.aside.back.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		
		<li>
			<router-link :to="{name: 'user-gift'}" :title="$t('vm.root.user.gift.aside.home.title')">
				{{ $t('vm.root.user.gift.aside.home.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'user-gift1'}" :title="$t('vm.root.user.gift.aside.gift1.title')">
				{{ $tc('vm.root.user.gift.aside.gift1.label', offices.length) }}
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'user-gift2'}" :title="$t('vm.root.user.gift.aside.gift2.title')">
				{{ $t('vm.root.user.gift.aside.gift2.label') }}
			</router-link>
		</li>
		<li>
			<router-link :to="{name: 'user-gift3'}" :title="$t('vm.root.user.gift.aside.gift3.title')">
				{{ $t('vm.root.user.gift.aside.gift3.label') }}
			</router-link>
		</li>
		<li class="hr" aria-hidden="true"/>
		<li>
			<router-link :to="{name: 'user-gift-bonus'}" :title="$t('vm.root.user.gift.aside.gift-bonus.title')">
				{{ $t('vm.root.user.gift.aside.gift-bonus.label') }}
			</router-link>
		</li>
	</ul>
</a-side>