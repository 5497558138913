<div v-if="formation" :ref="elementId">
	<div class="grid-2">
		<input-frame :id="`name-${elementId}`" v-model="formation.name"
			:label="$t('vm.root.user.formation.name.label')"
			:placeholder="$t('vm.root.user.formation.name.placeholder')"
			:error-test="hasNameError" :error-msg="$t('vm.root.user.formation.name.error-msg')"
			:title="$t('vm.root.user.formation.name.title')"/>

		<input-frame :id="`period-${elementId}`" v-model="formation.period"
			:label="$t('vm.root.user.formation.period.label')"
			:placeholder="$t('vm.root.user.formation.period.placeholder')"
			:title="$t('vm.root.user.formation.period.title')"/>

		<textarea-frame :id="`description-${elementId}`" class="grid-item-full" v-model="formation.description" rows="2"
			:label="$tc('vm.root.user.formation.description.label', messageLength)"
			:error-test="messageWarning" :error-msg="$t('vm.root.user.formation.description.msg-error')"
			:placeholder="$t('vm.root.user.formation.description.placeholder')"
			:title="$t('vm.root.user.formation.description.title')"/>
	</div>

	<div class="text-right">
		<delete-item class="btn--link-like text-danger" :can-delete="true"
			:title="$t('vm.root.user.formation.delete.title')"
			:delete-msg="$t('vm.root.user.formation.delete.msg')"
			:delete-action="deleteFormation">
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#trash-alt"/>
			</svg>
			{{ $t('general.delete.text') }}
		</delete-item>
	</div>
</div>
