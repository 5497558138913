import { Vue, Watch } from 'vue-property-decorator'
import { InputRangeType, IDropdownItem } from '@Components/index'
import { EnumsHelper } from '@Helpers/index'
import { PaymentMode, InvoiceType, AccountingPeriod, ReceiptFilterType } from '@Enums/index'
import { IConfiguration, IOffice, ILinkUser, IAccountingFilter, IAccountingSubFilter } from '@Store/types'
import { accounting, configuration, office, user } from '@Store/modules'

export abstract class AbstractContext extends Vue {

	query: string = ''
	labelPaymentModeFilter: string = ""
	labelInvoiceTypeFilter: string = ""
	labelReceiptTypeFilter: string = ""

	get filter(): IAccountingFilter {
		return accounting.filter
	}
	get subFilter(): IAccountingSubFilter {
		return accounting.subFilter
	}
	get isFiltering(): boolean {
		return accounting.isFiltering
	}
	get hasReceipt(): boolean {
		return this.subFilter.types.filter(f => { return (f.id as InvoiceType) === InvoiceType.Receipt && f.checked}).length > 0
	}
	get users(): ILinkUser[] {
		return this.getUserList().filter(i => this.filterItem(i))
	}
	get userItems(): IDropdownItem[] {
		return this.users.map(user => { return {value: user.id, label: `${user.coordinates.first_name} ${user.coordinates.last_name}` } })
	}
	get officeItems(): IDropdownItem[] {
		if (!this.filter.usr_id) return []

		let allOfficesItem: IDropdownItem = {value: '-1', label: this.$i18n.t('general.all-offices').toString()}
		if (user.isSubstituteUser)
			return [allOfficesItem, ...office.offices.map(o => { return {value: o.id, label: o.coordinates.name}})]

		let usr_id: string = parseInt(this.filter.usr_id) === -1 ? undefined : this.filter.usr_id
		let offices: IOffice[] = office.officesByUser(usr_id)

		return [allOfficesItem, ...offices.map(o => { return {value: o.id, label: o.coordinates.name}})]
	}
	get rangeType(): InputRangeType.RangeDate {
		return InputRangeType.RangeDate
	}
	get range(): Date[] {
		return [this.filter.start, this.filter.end]
	}
	set range(range: Date[]) {
		this.filter.start = range[0]
		this.filter.end = range[1]
	}
	private get configuration(): IConfiguration {
		return configuration.configuration
	}

	reset(): void {
		if (!this.filter) {
			accounting.resetFilter(this.configuration.default_period === AccountingPeriod.Monthly)
		}
		accounting.resetSubFilter()
		this.initializeFilter()
	}

	countByMode(mode: PaymentMode): number {
		return accounting.countByMode(mode)
	}

	@Watch('filter.usr_id')
	updateUser() {
		this.filter.ofi_id = '-1'
	}

	abstract getUserList(): ILinkUser[]

	getIcon(mode: PaymentMode): string {
		return EnumsHelper.paymentToString(mode, true)
	}

	private initializeFilter() {
		this.initializePaymentModeFilter()
		this.initializeTypeFilter()
		this.initializeReceiptTypeFilter()
	}

	private initializePaymentModeFilter() {
		if (!!this.subFilter.modes && this.subFilter.modes.length > 0) {
			this.updatePaymentModeFilter()
			return
		}

		this.subFilter.modes = []
		for (let mode in PaymentMode) {
			let id: number = Number(mode)
			if (isNaN(id)) continue

			this.subFilter.modes.push({ id: id, label: EnumsHelper.paymentToString(id as PaymentMode), checked: true })
		}
	}

	private initializeTypeFilter() {
		if (!!this.subFilter.types && this.subFilter.types.length > 0) {
			this.updateTypeFilter()
			return
		}

		this.subFilter.types = []
		for (let type in InvoiceType) {
			let id: number = Number(type)
			if (isNaN(id)) continue

			this.subFilter.types.push({ id: id, label: EnumsHelper.invoiceTypeToString(id as InvoiceType), checked: true })
		}
	}

	private initializeReceiptTypeFilter() {
		if (!!this.subFilter.receiptTypes && this.subFilter.receiptTypes.length > 0) {
			this.updateReceiptTypeFilter()
			return
		}

		this.subFilter.receiptTypes = []
		for (let type in ReceiptFilterType) {
			let id: number = Number(type)
			if (isNaN(id)) continue

			let icon: string = null
			if (id === ReceiptFilterType.Checked) {
				icon = "inbox-in"
			} else if (id === ReceiptFilterType.Banked) {
				icon = "piggy-bank"
			}
			this.subFilter.receiptTypes.push({ id: id, label: EnumsHelper.receiptFilterTypeToString(id as ReceiptFilterType), checked: true, icon: icon })
		}
	}

	@Watch('subFilter.modes', {deep: true})
	private updatePaymentModeFilter() {
		this.labelPaymentModeFilter = ""
		let count: number = 0
		for (let filter of this.subFilter.modes) {
			if (!filter.checked) continue
			this.labelPaymentModeFilter = `${this.labelPaymentModeFilter}${filter.label}, `
			count++
		}
		if (count === this.subFilter.modes.length) {
			this.labelPaymentModeFilter = this.$i18n.t('vm.root.accounting.context.filter.all-modes').toString()
			return
		}
		if (count > 0) {
			this.labelPaymentModeFilter = this.removeLastComa(this.labelPaymentModeFilter)
			return
		}

		this.labelPaymentModeFilter = this.$i18n.t('vm.root.accounting.context.filter.no-mode').toString()
	}

	@Watch('subFilter.types', {deep: true})
	private updateTypeFilter() {
		this.labelInvoiceTypeFilter = ""
		let count: number = 0
		for (let filter of this.subFilter.types) {
			if (!filter.checked) continue
			this.labelInvoiceTypeFilter = `${this.labelInvoiceTypeFilter}${filter.label}, `
			count++
		}
		if (count === this.subFilter.types.length) {
			this.labelInvoiceTypeFilter = this.$i18n.t('vm.root.accounting.context.filter.all-types').toString()
			return
		}
		if (count > 0) {
			this.labelInvoiceTypeFilter = this.removeLastComa(this.labelInvoiceTypeFilter)
			return
		}

		this.labelInvoiceTypeFilter = this.$i18n.t('vm.root.accounting.context.filter.no-type').toString()
	}

	@Watch('subFilter.receiptTypes', {deep: true})
	private updateReceiptTypeFilter() {
		this.labelReceiptTypeFilter = ""
		let count: number = 0
		for (let filter of this.subFilter.receiptTypes) {
			if (!filter.checked) continue
			this.labelReceiptTypeFilter = `${this.labelReceiptTypeFilter}${filter.label}, `
			count++
		}
		if (count === this.subFilter.receiptTypes.length) {
			this.labelReceiptTypeFilter = this.$i18n.t('vm.root.accounting.context.filter.all-receipts').toString()
			return
		}
		if (count > 0) {
			this.labelReceiptTypeFilter = this.removeLastComa(this.labelReceiptTypeFilter)
			return
		}

		this.labelReceiptTypeFilter = this.$i18n.t('vm.root.accounting.context.filter.no-receipt').toString()
	}

	private removeLastComa(str: string): string {
		return str.substring(0, str.length - 2)
	}
	private filterItem(item: any): boolean {
        if (!this.query || this.query.trim() === '') return true

        let countFilter:number = 0
		let countResult:number = 0
		let filters: string[] = this.query.split(' ')
		for(let filter of filters) {
			filter = filter.trim().toLowerCase()
			if (filter === '') continue
			countFilter++
			if (!!item.first_name && item.first_name.toLowerCase().includes(filter)) {
				countResult++
			} else if (!!item.last_name && item.last_name.toLowerCase().includes(filter)) {
				countResult++
            }
		}
		return countFilter === countResult
	}
}
