import Render from '@Views/root/search/colleague/item.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IColleague, IPopinAction, IPopin } from '@Store/types'
import { patient, popIn } from '@Store/modules'

@Render
@Component({})
export class ColleagueItem extends Vue {

	@Prop() colleague: IColleague
	@Prop() query: string
	@Prop() canUnselect: boolean

	get professions(): string {
		return !!this.colleague.professions && this.colleague.professions.length > 0 ? this.colleague.professions.join('-') : ''
	}

	unselect(): void {
        let unselectAction: IPopinAction = {
			label: this.$i18n.t('vm.root.search.colleague.item.unselect.label').toString(),
			title: this.$i18n.t('vm.root.search.colleague.item.unselect.title').toString(),
			callback: () => {
				patient.deleteColleague(this.colleague)
				return Promise.resolve()
			}
		}
		let unselectPopin: IPopin = {
			title: this.$i18n.t('vm.root.search.colleague.item.unselect.title').toString(),
			content: this.$i18n.t('vm.root.search.colleague.item.unselect.content').toString(),
			action: unselectAction
		}

        popIn.warning(unselectPopin)
	}
}
