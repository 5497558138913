<div>
	<h1>{{ $t('vm.root.user.gift.gift3.title') }}</h1>
	<form @submit.prevent="submitGift">
		<div v-if="isFull" class="wrapper marg-v text-center">
			<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
			</svg>
			<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift3.valided.title') }}</strong></p>
			<p>{{ $t('vm.root.user.gift.gift3.valided.text') }}</p>
		</div>
		<div v-else-if="isWaiting" class="wrapper marg-v text-center">
			<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
			</svg>
			<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift3.waiting.title') }}</strong></p>
			<p>{{ $t('vm.root.user.gift.gift3.waiting.text') }}</p>
		</div>
		<div v-else>
			<h2>{{ $t('vm.root.user.gift.gift3.subtitle') }}</h2>
			<p>{{ $t('vm.root.user.gift.gift3.help.text1') }}</p>
			<p>{{ $t('vm.root.user.gift.gift3.help.text2') }}</p>
			<p>{{ $t('vm.root.user.gift.gift3.help.text3') }}</p>
			<p>{{ $t('vm.root.user.gift.gift3.help.text4') }}</p>

			<dropdown-frame id="question" v-model="index" :items="questionItems"
				:label="$t('vm.root.user.gift.gift3.question.label')"
				:title="$t('vm.root.user.gift.gift3.question.title')"/>

			<div v-if="index >= 0">
				<div v-if="isRunning">
					<div class="notice-info">
						<p class="text-left">
							<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
								<use xlink:href="@Assets/svg/symbol-defs.svg#question-circle"/>
							</svg>
							{{ $t('vm.root.user.gift.gift3.question.help1') }}
							<ul>
								<li>{{ $t('vm.root.user.gift.gift3.question.li1') }}</li>
								<li>{{ $t('vm.root.user.gift.gift3.question.li2') }}</li>
								<li>{{ $t('vm.root.user.gift.gift3.question.li3') }}</li>
								<li>{{ $t('vm.root.user.gift.gift3.question.li4') }}</li>
							</ul>
							{{ $t('vm.root.user.gift.gift3.question.help2') }}
						</p>

					</div>
					<textarea-frame id="answer" v-model="answers[index]" rows="6"
						:label="questions[index]"
						:error-msg="$t('vm.root.user.gift.gift3.answer.error-msg')"
						:error-test="hasAnswerError"
						:title="$t('vm.root.user.gift.gift3.answer.title')"
						:placeholder="$t('vm.root.user.gift.gift3.answer.placeholder')"/>

					<i18n path="vm.root.user.gift.gift3.counter.label" tag="label" class="label">
						<span>{{ $t('vm.root.user.gift.gift3.counter.span', {counter: wordCount}) }}</span>
						<strong>{{ $t('vm.root.user.gift.gift3.counter.strong') }}</strong>
					</i18n>
				</div>
				<div v-else-if="isValidated" class="wrapper marg-v text-center">
					<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#check-circle"/>
					</svg>
					<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift3.question.validated.title') }}</strong></p>
					<p>{{ $t('vm.root.user.gift.gift3.question.validated.text') }}</p>
				</div>
				<div v-else-if="isValidating" class="wrapper marg-v text-center">
					<svg width="1em" class="text-primary shop-success-icon" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
					</svg>
					<p><strong class="text-xl text-primary">{{ $t('vm.root.user.gift.gift3.question.waiting.title') }}</strong></p>
					<p>{{ $t('vm.root.user.gift.gift3.question.waiting.text') }}</p>
				</div>
			</div>
		</div>

		<control-bar class="childs-space-between">
			<button-action btn-class="btn btn-default" @click.native="previous" :title="$t('vm.root.user.gift.gift3.back.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-left"/>
				</svg>
				{{ $t('vm.root.user.gift.gift3.back.label') }}
			</button-action>

			<span>
				<button-action type="submit" btn-class="btn btn-primary" :disabled="!canSubmit" :loading="isSaving" :title="$t('vm.root.user.gift.gift3.action.title')">
					{{ $t('vm.root.user.gift.gift3.action.label') }}
					<span class="rwd-over-xlarge">{{ $t('vm.root.user.gift.gift3.action.short-label') }}</span>
				</button-action>

				<button-action btn-class="btn btn-default" @click.native="next" :title="$t('vm.root.user.gift.gift3.next.title')">
					{{ $t('vm.root.user.gift.gift3.next.label') }}
					<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-right"/>
					</svg>
				</button-action>
			</span>
		</control-bar>
	</form>

</div>
