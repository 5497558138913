import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import {store} from '@Store/index'
import {INotification, IPlanning, IUser, PlanningActionPayload, PlanningLoadPayload} from "@Store/types"
import {PlanningType, Profile, RequestStatus} from "@Enums/index"
import {EventService, GoogleService, PlanningService} from "@Services/index"
import {notif, user} from "@Store/modules"
import {PlanningHelper, StorageHelper} from "@Helpers/index"

@Module({
	name: 'planning',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class PlanningModule extends VuexModule {
    _unvalidEvents: number =  0
    status: RequestStatus =  RequestStatus.None
    statusSaving: RequestStatus =  RequestStatus.None
    statusDeleting: RequestStatus =  RequestStatus.None
    statusGoogle: RequestStatus =  RequestStatus.None
    _authorizedGoogle: boolean =  false
    _plannings: IPlanning[] =  []
    _googlePlannings: IPlanning[] =  []
    selectedPlanning: IPlanning =  null
    planningDeleting: string =  null
    refresh: boolean =  false

	get hasUnvalidEvent(): boolean {
		return this._unvalidEvents > 0
	}
	get unvalidEventCount(): number {
		return this._unvalidEvents
	}
	get isDeleting(): boolean {
		return this.statusDeleting === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isGoogleLoading(): boolean {
		return this.statusGoogle === RequestStatus.Loading
	}
	get planning(): (id: string) => IPlanning {
		return (id: string) => {
			return this.plannings.find(planning => { return planning.id === id })
		}
	}
	get planningType(): (id: string) => PlanningType {
		return (id: string) => {
			if (id === null) return PlanningType.Classic

			let planning = this.editablePlannings.find(planning => { return planning.id === id })
			return planning === undefined ? PlanningType.Classic : planning.type
		}
	}
	get planningsByUser(): (usr_id: string) => IPlanning[] {
		return (usr_id: string) => {
			if (!usr_id) return this._plannings

			return this._plannings.filter(planning => { return planning.usr_id === usr_id })
		}
	}
	get plannings(): IPlanning[] {
		if (user.isMainUser) return this._plannings

		let selectedUser: IUser = user.selectedPlanningUser
		let usr_id = !!selectedUser ? selectedUser.id : null
		return this.planningsByUser(usr_id)
	}
	get hasPlanning(): boolean {
		return this.plannings.length > 0 || this.googlePlannings.length > 0
	}
	get googlePlannings(): IPlanning[] {
		return this._authorizedGoogle && user.isMainUser ? this._googlePlannings : []
	}
	get editablePlannings(): IPlanning[] {
		return [
			...this.plannings.filter(planning => { return planning.editable }),
			...this.googlePlannings.filter(planning => { return planning.editable })
		]
	}

	@Mutation
	clear() {
		this._unvalidEvents = 0
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusGoogle = RequestStatus.None
		this._authorizedGoogle = false
		this._plannings = []
		this._googlePlannings = []
		this.selectedPlanning = null
		this.planningDeleting = null
		this.refresh = false
	}

	@Mutation
	updateRefresh(refresh: boolean) {
		this.refresh = refresh
	}

	@Mutation
	unvalidEvents(count: number) {
		this._unvalidEvents = count
	}

	@Mutation
	authorizedGoogle(authorized: boolean) {
		this._authorizedGoogle = authorized
	}

	@Mutation
	planningsRequest() {
		this.status = RequestStatus.Loading
		this._plannings = []
	}

	@Mutation
	planningsSuccess(plannings: IPlanning[]) {
		this.status = RequestStatus.Success
		this._plannings = plannings
	}

	@Mutation
	planningsFailure() {
		this.status = RequestStatus.Success
		this._plannings = []
	}

	@Mutation
  	googlePlanningsRequest() {
		this.statusGoogle = RequestStatus.Loading
		this._googlePlannings = []
	}

	@Mutation
	googlePlanningsSuccess(plannings: IPlanning[]) {
		this.statusGoogle = RequestStatus.Success
		this._googlePlannings = plannings
	}

	@Mutation
	googlePlanningsFailure() {
		this.statusGoogle = RequestStatus.Failed
		this._googlePlannings = []
	}

	@Mutation
	selectPlanning(planning: IPlanning) {
		this.selectedPlanning = {...planning}
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess() {
		this.statusSaving = RequestStatus.Success
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	deleteRequest(id: string) {
		this.planningDeleting = id
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess(id: string) {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusDeleting = RequestStatus.Success
		this._plannings = this._plannings.filter(planning => planning.id !== id)
	}

	@Mutation
	deleteFailure() {
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
	toggleVisibilityByUser(id: string) {
		for (let planning of this._plannings) {
			planning.visible = planning.usr_id === id
		}
	}

	@Action
	updatePlanning(planning: IPlanning) {
		if (parseInt(planning.id) === -1) return

		let plannings: IPlanning[] = this.plannings
		for (let p of plannings) {
			if (p.id !== planning.id) continue
			p.name = planning.name
			p.color = planning.color
			return
		}

		this._plannings.push(planning)
	}

	@Action({rawError: true})
	async checkUnvalidEvents(): Promise<any> {
		let service = new EventService()
		return service.getNotificationEvent()
		.then(result => { return Promise.resolve(result.count) })
		.catch(error => { return Promise.reject(error) })
	}

	@Action({rawError: true})
  	async loadPlannings(payload: PlanningLoadPayload): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		let profile = user.user.profile
		this.planningsRequest()

		let service = new PlanningService()
		return service.getPlanningList()
		.then(
			plannings => {
				for (let planning of plannings) {
					planning.type = PlanningType.Classic
					planning.visible = profile !== Profile.Main || !!planning.visible
					planning.editable = profile === Profile.Secretary || planning.usr_id === user.user.id
				}
				this.planningsSuccess(plannings)
				return Promise.resolve(plannings)
			}
		)
		.catch(error => {
			this.planningsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	initializeGooglePlannings(authorized: boolean): void {
		this.authorizedGoogle(authorized)
		if (!authorized) return

		planning.loadGooglePlannings()
			.then((plannings: IPlanning[]) => {
				for(let planning of plannings) {
					let visible: boolean = StorageHelper.get(`planning-${planning.id}-visible`, true)
					planning.visible = visible
				}
			})
	}

	@Action({rawError: true})
	async loadGooglePlannings(): Promise<any> {
		if (this.statusGoogle === RequestStatus.Loading) return

		this.googlePlanningsRequest()

		let display = StorageHelper.get('display_calendars', {})
		let displayByUser = display[user.user.id] || []

		let service = GoogleService.getInstance()
		let promise = service.getPlanningList()
		return promise
		.then(response => {
			let plannings: IPlanning[] = []
			for (let planning of response.items) {
				planning.type = PlanningType.Google
				plannings.push(PlanningHelper.convertGoogleCalendarToCalendar(planning, displayByUser))
			}
			this.googlePlanningsSuccess(plannings)
			return Promise.resolve(plannings)
		})
		.catch(error => {
			this.googlePlanningsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async savePlanning(payload: PlanningActionPayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		const {planning} = payload
		this.saveRequest()

		let profile = user.user.profile
		let service = new PlanningService()
		return service.save(planning)
		.then(newPlanning => {
			newPlanning.editable = profile === Profile.Secretary || newPlanning.usr_id === user.user.id
			this.saveSuccess()
			this.updatePlanning(newPlanning)
			return Promise.resolve(newPlanning)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deletePlanning(payload: PlanningActionPayload): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		const {planning} = payload
		if (!planning.id || parseInt(planning.id) === -1) return

		this.deleteRequest(planning.id)

		let service = new PlanningService()
		return service.delete(planning.id)
		.then(() => {
			this.deleteSuccess(planning.id)
			return Promise.resolve()
		})
		.catch(error => {
			let notification: INotification = {message: error, actions:[], delay: 5000}
			this.deleteFailure()
			notif.error(notification)
			return Promise.reject(error)
		})
	}

	@Action
	toggleVisibilityAndSave(planning: IPlanning): any {
		let service = new PlanningService()
		return service.update(planning)
	}
}

export const planning = getModule(PlanningModule)
// export const planning = new PlanningModule({ store, name: "planning" })
