export class NirHelper {
	public static formattedResult(nir:string, iso: string): string {
        nir = nir.replace(/\W/g, '')
        if (!NirHelper.isValidNumber(nir, iso)) return nir
		if (!nir) return ''

        switch (iso.toUpperCase()) {
            case 'FR':
                return `${nir.substring(0, 1)} ${nir.substring(1, 3)} ${nir.substring(3, 5)} ${nir.substring(5, 7)} ${nir.substring(7, 10)} ${nir.substring(10, 13)} ${nir.substring(13)}`
            case 'LU':
                return `${nir.substring(0, 4)} ${nir.substring(4, 6)} ${nir.substring(6, 8)} ${nir.substring(8, 11)} ${nir.substring(11, 12)} ${nir.substring(12)}`
            case 'BE':
                return `${nir.substring(0, 2)} ${nir.substring(2, 4)} ${nir.substring(4, 6)} ${nir.substring(6, 9)}-${nir.substring(9)}`
            case 'NC':
                return nir
            default:
                return nir
        }
	}

	public static isValidNumber(nir: string, iso: string): boolean {
        switch(iso.toUpperCase()) {
            case 'FR':
                return NirHelper.checkNirFr(nir)
            case 'LU':
                return NirHelper.checkNirLu(nir)
            case 'BE':
                return NirHelper.checkNirBe(nir)
            case 'NC':
                return NirHelper.checkNirNc(nir)
            default:
                return true
        }
    }


    private static checkNirFr(nir: string): boolean {
		if (!nir) return true

		let regexp = /^([1-37-8])([0-9]{2})(0[0-9]|[2-35-9][0-9]|[14][0-2])((0[1-9]|[1-8][0-9]|9[0-69]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90))(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/
		nir = nir.replace(/\W/g, '')
		let result: boolean =  regexp.test(nir)
		if (!result) return false

		nir = nir.replace(/2A/, '19')
		nir = nir.replace(/2B/, '18')

		let nirValue: number = parseInt(nir.substring(0, nir.length - 2))
		let modulo = nirValue % 97

		let result2: number = parseInt(nir.substring(nir.length - 2, nir.length))
		let test: boolean = result2 === 97 - modulo
		return test
	}

	//lux : 1989063055620
	//belge : 19960408-180-72
	//https://fr.wikipedia.org/wiki/Num%C3%A9ro_de_registre_national
	private static checkNirLu(nir: string): boolean {
		if (!nir) return true

		let regexp = /^(19[0-9]{2}|20[0-9]{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([0-9]{3})([0-9])([0-9])$/
		nir = nir.replace(/\W/g, '')
		let result: boolean =  regexp.test(nir)
		if (!result) return false

		let luhnCheckValue: string = nir.substring(0, 12)
		if (!NirHelper.luhnCheck(luhnCheckValue)) return false
		let verhoeffValue = NirHelper.generateVerhoeff(nir.substring(0, 11))
		if (verhoeffValue !== parseInt(nir.substring(12))) return false

		return true
	}

	private static checkNirBe(nir: string): boolean {
		if (!nir) return true

		let regexp = /^([0-9]{2})([0-9]{2})([0-9]{2})(00[1-9]|[1-9]0[0-9]|[0-8][1-9][0-9]|9[0-8][0-9]|99[0-7])([0-9]{2})$/
		nir = nir.replace(/\W/g, '')
		let result: boolean =  regexp.test(nir)
		if (!result) return false

		let nirValue: number = parseInt(nir.substring(0, nir.length - 2))
		let modulo = nirValue % 97

		return parseInt(nir.substring(nir.length - 2, nir.length)) === 97 - modulo
	}

	private static checkNirNc(nir: string): boolean {
		if (!nir) return true

		let regexp = /^([0-9]{1,7})$/
		nir = nir.replace(/\W/g, '')
		return regexp.test(nir)
	}

	// multiplication table d
	private static d: number[][] = [
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		[1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
		[2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
		[3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
		[4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
		[5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
		[6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
		[7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
		[8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
		[9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
	]

	// permutation table p
	private static p: number[][] = [
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		[1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
		[5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
		[8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
		[9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
		[4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
		[2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
		[7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
	]

	// inverse table inv
	private static inv:number[] = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9]

	// generates checksum
	private static generateVerhoeff(number: string){

		let c: number = 0;
		let invertedArray:string[] = Array.from(number).reverse()

		for (let i = 0; i < invertedArray.length; i++){
			c = NirHelper.d[c][NirHelper.p[((i + 1) % 8)][parseInt(invertedArray[i])]];
		}

		return NirHelper.inv[c];
	}

	// // validates checksum
	private static validateVerhoeff(number: string) {

		let c: number = 0
		let invertedArray:string[] = Array.from(number).reverse()

		for (let i = 0; i < invertedArray.length; i++){
			c = NirHelper.d[c][NirHelper.p[(i % 8)][invertedArray[i]]]
		}

		return c === 0
	}

	private static luhnCheck(num: string): boolean {
		let arr = num.split('').reverse().map(x => parseInt(x))
		let lastDigit = arr.splice(0, 1)[0]
		let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0)
		sum += lastDigit
		return sum % 10 === 0
	}
}
