export const attachments = {
    title: "Pièces jointes",
    'new-attachment': {
        title: "Créer une nouvelle pièce jointe",
        text: "Nouvelle pièce jointe"
    },
    none: {
        title: "Aucune pièce jointe.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer une nouvelle pièce jointe",
            link: "une nouvelle pièce jointe"
        }
    }
}