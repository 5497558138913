import Render from '@Views/components/input/input-range.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import mobiscroll, { MbscRangeOptions } from '@mobiscroll/javascript'
import '@mobiscroll/javascript/dist/css/mobiscroll.javascript.min.css'
import { i18n } from "@I18n/index"
import { DateHelper } from '@Helpers/index'
import { configuration } from '@Store/modules'

export enum InputRangeType {
    RangeDate,
    RangeTime,
    RangeDateTime
}

@Render
@Component({})
export class InputRange extends Vue {

    private instance: any = null
    @Prop() id: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() value: Date[]
    @Prop() disabledLabel: string
    @Prop({default: 0}) duration: number
    @Prop({default: 5}) steps: number
    @Prop({default: false}) disabled: boolean
    @Prop({default: InputRangeType.RangeDate}) type: InputRangeType
    @Prop({default: false}) singleInput: boolean
    @Prop({default: '#startRange'}) startId: string
    @Prop({default: '#endRange'}) endId: string
    @Prop({default: () => new Date()}) defaultStart: Date
    @Prop({default: () => new Date()}) defaultEnd: Date
    @Prop({default: InputRange.dateFormat}) dateFormat: string
    @Prop({default: InputRange.timeFormat}) timeFormat: string

    private get controls(): ("calendar" | "time")[] {
        if (this.type === InputRangeType.RangeDate)
            return ['calendar']
        if (this.type === InputRangeType.RangeDateTime)
            return ['calendar', 'time']

        return ['time']
    }

    @Watch('duration')
    updateDuration() {
        this.instance.settings.disabled = this.getDuration()
    }

    @Watch('value', {deep: true})
    updateValue() {
        if (!this.value || this.value.length !== 2) return
        if (!DateHelper.isValidDate(this.value[0])) return
        if (!DateHelper.isValidDate(this.value[1])) return
        if (this.instance === null) return

        this.instance.setVal(this.value, true)
    }

    @Watch('disabled')
    updateDisabled() {
        this.instance.settings.disabled = this.disabled
        if (!this.singleInput) return

        if (this.disabled) {
            let element: any = this.singleInput ? this.$refs[`single-input-${this.id}`] : this.$el
            element.value = this.disabledLabel
        } else {
            if (!!this.value) {
                this.instance.setVal(this.value, true)
            } else {
                this.instance.setVal([this.defaultStart, this.defaultEnd], true)
                this.$emit('input', this.instance.getVal())
            }
        }
    }

    @Watch('type')
    updateType() {
        let element: any = this.singleInput ? this.$refs[`single-input-${this.id}`] : this.$el
        this.instance = mobiscroll.range(element, this.getConfigRange(this.type === InputRangeType.RangeTime))
        this.instance.setVal(this.value, true)
    }

    mounted() {
        this.updateType()

        if (!this.disabled) return
        this.updateDisabled()
    }

    private getDuration(): number {
        return (!this.duration ? 0 : this.duration * 60000) + 1
    }

    private getConfigRange(withTime?: boolean): MbscRangeOptions {
        let minTime = !!configuration.configuration.min_time ? configuration.configuration.min_time.toLocaleTimeString('fr-FR') : '06:00:00'
		let maxTime = !!configuration.configuration.max_time ? configuration.configuration.max_time.toLocaleTimeString('fr-FR') : '21:00:00'

        let invalid = []
        if (!withTime) {
            invalid = [
                { start: '00:00', end: minTime },
                { start: maxTime, end: '23:55' }
            ]
        }
        let self = this
        return {
            lang: configuration.configuration.lang,
            theme: 'ios',
            minRange: self.getDuration(),
            disabled: self.disabled,
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat,
            showOnFocus: true,
            steps: { minute: this.steps },
            responsive: {
                small: { display: 'center' },
                medium: { display: 'center' },
                large: { display: 'center' }
            },
            controls: this.controls,
            startInput: this.singleInput ? undefined : this.startId,
            endInput: this.singleInput ? undefined : this.endId,
            calendarScroll: 'vertical',
            months: 'auto',
            invalid: invalid,
            onSet: function (event, inst) {
                self.$emit('input', inst.getVal())
            }
        }
    }

    public static dateFormat(): string {
        return `${i18n.t('vm.components.input.input-range.the')} d MM yy`
    }

    public static timeFormat(): string {
        return `${i18n.t('vm.components.input.input-range.at')} HH:ii`
    }
}
