export const patient = {
	coordinates: 'informations générales',
	'coordinates-incomplete': 'informations générales incomplètes',
	pediatrics: 'informations pédiatriques',
	reason: 'historique | historique | historiques',
	consultation: 'consultation | consultation | consultations',
	antecedent: 'antécédent | antécédent | antécédents',
	sphere: 'sphère | sphère | sphères',
	attachment: 'pièce jointe | pièce jointe | pièces jointes',
	member: 'membre de la famille | membre de la famille | membres de la famille',
	'relaunch-updated': 'relance modifiée',
	'relaunch-activated': 'relance activée',
	added: 'un {item} ajouté | {n} {item} ajoutés',
	updated: 'un {item} modifié | {n} {item} modifés',
	deleted: 'un {item} effacé | {n} {item} effacés',
	incomplete: 'un {item} incomplet | {n} {item} incomplets',
	'added-female': 'une {item} ajoutée | {n} {item} ajoutées',
	'updated-female': 'une {item} modifiée | {n} {item} modifées',
	'deleted-female': 'une {item} effacée | {n} {item} effacées',
	'new-reason': "nouvel historique",
	'incomplete-female': 'une {item} incomplète | {n} {item} incomplètes',
	'patient-saved': "Dossier sauvegardé",
	'patient-incomplete': "Dossier incomplet",
	complete: "compléter"
}
