import Render from '@Views/root/user/account/formation/main.html'
import { isEqual, cloneDeep } from 'lodash'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {IUser, ICurriculum} from '@Store/types'
import { user } from '@Store/modules'
import {User} from "@Models/user"

@Render
@Component({})
export class Main extends Vue {

	messageLength: number = User.maxCurriculumLength
	private messageWarning: boolean = false
	private oldFormation: ICurriculum = null

	@Prop() submitted: boolean
	@Prop() formation: ICurriculum

	//#region getter
	get hasNameError(): boolean {
		return this.submitted && !this.formation.name
	}
	get elementId(): string {
		return parseInt(this.formation.id) === -1 ? this.formation.create_id : this.formation.id
	}
	private get user(): IUser {
		return user.user
	}
	//#endregion

	mounted() {
		this.oldFormation = cloneDeep(this.formation)
		this.updateMessage()
	}

	deleteFormation(): void {
		this.user.formations.deleted.push(this.formation)
		this.user.formations.currents = this.user.formations.currents.filter(f => { return f.id !== this.formation.id || f.create_id !== this.formation.create_id } )
	}

	@Watch('formation', {deep: true})
	updateFormation() {
		// On retire la session en cours pour ne pas l'écrire 2 fois
		if (this.oldFormation.id !== this.formation.id || isEqual(this.oldFormation, this.formation)) return

		this.formation['update'] = true
	}

	@Watch('formation.description')
	private updateMessage() {
		let result = User.maxCurriculumLength
		if (!!this.formation && !!this.formation.description) {
			let text: string = this.formation.description.trim()
			result = User.maxCurriculumLength - text.length
		}
		this.messageWarning = result < 0
		this.messageLength = Math.max(0, result)
	}
}
