<div v-if="!!configuration">
	<h1>{{ $t('vm.root.parameters.general.title') }}</h1>
	<hr>
	<section>
		<h2>{{ $t('vm.root.parameters.general.behavior-title') }}</h2>
		<div :class="{'grid-2': isMainUser, 'grid-3': !isMainUser}">
			<dropdown-frame id="lang" v-model="configuration.lang" :items="langItems"
				:title="$t('vm.root.parameters.general.lang.title')"
				:label="$t('vm.root.parameters.general.lang.label')"/>
			<dropdown-frame id="auto-save-delay" v-model="configuration.auto_save" :items="delayItems"
				:title="$t('vm.root.parameters.general.auto-save.title')"
				:label="$t('vm.root.parameters.general.auto-save.label')"/>
			<dropdown-frame v-if="isMainUser" id="default-page" v-model="configuration.default_page" :items="pageItems"
				:title="$t('vm.root.parameters.general.default-page.title')"
				:label="$t('vm.root.parameters.general.default-page.label')"/>
			<dropdown-frame id="font-size" v-model="fontSize" :items="sizeItems"
				:title="$t('vm.root.parameters.general.font-size.title')"
				:label="$t('vm.root.parameters.general.font-size.label')"/>
		</div>
	</section>
	<hr>
	<section>
		<h2>{{ $t('vm.root.parameters.general.theme-title') }}</h2>
		<div class="grid-3 vertical-gap">
			<button type="button" v-for="skin in skins" :key="skin.value" :id="skin.value" @click="selectSkin(skin.value)"
				:title="$t('vm.root.parameters.general.theme')"
				class="input-like skin-item" :class="{'is-selected': isSelectedSkin(skin.value)}">
				<div class="skin-item-img-container">
					<svg class="svg-block skin-item-img" viewBox="0 0 10 4" role="img" focusable="false">
						<rect class="skin-item-color-3" x="0" y="0" width="10" height="4" />
						<rect class="skin-item-color-2" x="0" y="0" width="6" height="4" />
						<rect class="skin-item-color-1" x="0" y="0" width="3" height="4" />
					</svg>
					<svg width="1em" class="skin-item-check text-primary" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
						<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
					</svg>
				</div>
				<div class="text-center skin-item-label">{{ skin.label }}</div>
			</button>
		</div>
	</section>
</div>
