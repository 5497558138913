export const spheres = {
    title: "Sphères",
    filter: {
        label: "Filtrer les sphères",
        placeholder:"ex : conj",
        title:"Veuillez renseigner un filtre pour rechercher un élément précis"
    },
    'create-by': "créé par {owner}",
    'new-sphere': {
        title: "Créer une nouvelle sphère {type}",
        text: "nouvelle sphère"
    },
    none: {
        title: "Pas de sphère.",
        description: {
            text: "Ajoutez {0} à ce dossier.",
            title: "Créer une nouvelle sphère",
            link: "une nouvelle sphère"
        }
    }
}