<component :is="tag" :class="defaultClass" :disabled="disabled">
    <div class="checkbox">
        <input type="checkbox" :id="id" v-model="checked" :value="value" :disabled="disabled" :readonly="readonly">
        <label :for="id" :title="title" :disabled="disabled">
            <svg width="1em" class="mark" viewBox="0 0 8 6" aria-hidden="true"><path d="M1,3l2,2l4,-4"/></svg>
            <span class="sr-only">{{title}}</span>
        </label>
    </div>
    <label :for="id"><slot/></label>
</component>
