import Render from '@Views/root/user/office/sessions.html'
import draggable from "vuedraggable"
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'
import { IOffice, ISession, IBreadcrumbItem } from '@Store/types'
import * as Session from './session/index'
import { Common, Office } from '@Models/index'
import { breadcrumb } from '@Store/modules'

@Render
@Component({
	components: {
		'draggable': draggable,
		'session': Session.Main
	}
})
export class Sessions extends Vue {

	private deploy: Dictionary<boolean> = {}

	@Prop() submitted: boolean
	@Prop() office: IOffice

	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void

	get sessions(): ISession[] {
		return this.office.sessions.currents
	}
	get defaultSession(): ISession {
		return this.office.sessions.currents.find(s => { return this.isDefaultSession(s) })
	}

	mounted() {
		this.$emit('navigate', 'sessions-office')
		this.initializeBreadcrumb()

		this.moveToSession()
	}

	toggle(id: string, createId?: string): any {
		let selectedId: string = !!createId ? createId : id
		this.deploy[selectedId] = !this.deploy[selectedId]
		this.$forceUpdate()
	}
	
	isToggle(id: string, createId?: string): boolean {
		let selectedId: string = !!createId ? createId : id
		return this.deploy[selectedId]
	}

	slotName(session: ISession): string {
		return this.isDefaultSession(session) ? 'header' : 'default'
	}

	isDefaultSession(session:ISession): boolean {
		return parseInt(session.id) === -1 && !session.create_id
	}
 
	addSession(): void {
		let createId: string = Common.generateId()
		let session: ISession = Office.createNewSession(createId, this.getId())
		this.toggle(session.id, session.create_id)
		this.office.sessions.currents.push(session)
		setTimeout(() => { this.moveToSession(session) }, 100)
	}

	getElementId(session: ISession): string {
		return parseInt(session.id) === -1 ? session.create_id : session.id
	}

	@Watch('submitted')
	private moveToSession(session?: ISession) {
		session = !session ? this.sessions.find(a => a.error) : session
		if (!session) return

		if (!this.isToggle(session.id, session.create_id)) {
			this.toggle(session.id, session.create_id)
		}
		let ses_id: string = this.getElementId(session)
		let element = this.$refs[ses_id] as any
		if (!element || element.length === 0) return

		let centralElement: Element = document.getElementsByClassName('l-central')[0]
		centralElement.scrollTo(0, element[0].offsetTop)
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString(), link:'user-account' }
		let item2: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 2).toString(), link:'offices' }
		let item3: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.offices', 1).toString(), link: {name: "office-coordinates", params: this.$route.params} }
		let item4: IBreadcrumbItem = { label: this.$i18n.tc('vm.root.breadcrumb.sessions', this.sessions.length).toString() }
		breadcrumb.updateItems([item1, item2, item3, item4])
	}

	private getId(): string {
		return parseInt(this.office.id) === -1 ? this.office.create_id : this.office.id
	}
}