export const examens = {
    title: "Examens de consultation",
    office: {
        label: "Cabinet lié à la consultation",
        title: "Cabinet dans lequel se déroule la consultation",
        error: "Merci de sélectionner le cabinet dans lequel se déroule la consultation"
    },
    user: {
        label: "Ostéopathe remplacé",
        title: "Ostéopathe remplacé pour cette consultation",
        error: "Merci de sélectionner l'ostéopathe remplacé pour cette consultation"
    },
    reason: {
        label: "Motif de la consultation",
        'no-options-label': "tapez pour rechercher un motif…",
        placeholder: "ex : Entorse, Cheville, Droite",
        error: "Merci de renseigner le motif de la consultation",
        'too-long': "Le motif de consultation ne doit pas dépasser 255 caractères",
        title: "Veuillez renseigner le motif de la consultation"
    },
    "act-date": {
        label: "Date de la consultation",
        title: "Veuillez renseigner la date de la consultation",
        placeholder: "ex : Le 30 Février 2015",
        error: "Merci de renseigner la date de la consultation"
    },
    size: {
        label: "Taille (cm)",
        placeholder: "ex : 176",
        title: "Veuillez renseigner la taille du patient lors de cette consultation"
    },
    weight: {
        label: "Poids (kg)",
        placeholder: "ex : 69.6",
        title: "Veuillez renseigner le poids du patient lors de cette consultation"
    },
    imc: {
        label:"I.M.C.",
        placeholder: "ex : 20,6 - Corpulence normale",
        title: "",
        'level-1': 'Dénutrition',
        'level-2': 'Maigreur',
        'level-3': 'Corpulence normale',
        'level-4': 'Surpoids',
        'level-5': 'Obésité modérée',
        'level-6': 'Obésité sévère',
        'level-7': 'Obésité morbide ou massive'
    },
    "evolution-state": {
        label:"Anamnèse du jour",
        placeholder: "ex : Va mieux depuis 2 jours",
        title: "Veuillez renseigner l'évolution de l'état de santé du patient"
    },
    observations: {
        label: "Observations",
        placeholder: "ex : Lors de l'examen, j'ai pu constater…",
        title: "Veuillez renseigner vos observations lors de cette consultation"
    },
    "eva": {
        before: "E.V.A. avant",
        after: "E.V.A. après"
    },
    diagnostic: {
        label: "Évolution du diagnostic",
        placeholder: "ex : Malaise au cours de la consultation…",
        title: "Veuillez renseigner l'évolution du diagnostic au cours de la consultation"
    },
    tests: {
        label: "Tests ostéopathiques",
        placeholder: "ex : Zone de blocage dorsales",
        title: "Veuillez renseigner les tests effectués lors de cette consultation"
    },
    treatment: {
        label: "Traitement",
        placeholder: "ex : Compression crânienne",
        title: "Veuillez renseigner le traitement effectué lors de cette consultation"
    },
    'office-notification': {
        label: "Vous devez avoir au moins un cabinet pour compléter vos consultations.",
        action: "créer un cabinet"
    }
}
