export enum AutoSaveDelay {
    FiveMinutes     = 300000,
    TenMinutes      = 600000,
    ThirtyMinutes   = 1800000,
    Never           = -1
}

export enum AutoSaverState {
	None 		= 1,
	Initialized	= 2,
	Updated 	= 3
}