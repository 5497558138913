import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { IOffice, ISettings, ISchedule, ISession, IAccount, IScheduleUser, ILinkUser, OfficeSavePayload, INotification } from "@Store/types"
import { RequestStatus, Profile } from "@Enums/index"
import { OfficeService } from "@Services/index"
import { Dictionary } from "vue-router/types/router"
import { user, notif } from "@Store/modules"
import { cloneDeep } from "lodash"
import {Common, Office, User} from "@Models/index"
import {DateHelper} from "@Helpers/date";

@Module({
	name: 'office',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class OfficeModule extends VuexModule {
    private status: RequestStatus = RequestStatus.None
    private statusSaving: RequestStatus = RequestStatus.None
    private statusSettings: RequestStatus = RequestStatus.None
    private statusSessions: RequestStatus = RequestStatus.None
    private statusSchedules: RequestStatus = RequestStatus.None
    private statusUrl: RequestStatus = RequestStatus.None
    private _offices: IOffice[] = []
    private _restoreSettings: ISettings[] = []
    private _restoreSchedules: ISchedule[] = []
    private _specificSchedules: ISchedule[] = []
    private _restoreSpecificSchedules: ISchedule[] = []
    private _sessions: Dictionary<Dictionary<ISession[]>> = {}
    private _restoreSessions: Dictionary<Dictionary<ISession[]>> = {}
    settings: ISettings[] = []
    schedules: ISchedule[] = []
    deletedSessions: ISession[] = []
    selectedPlanningOffice: IOffice = null

	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isGenerating(): boolean {
		return this.statusUrl === RequestStatus.Loading
	}
	get office(): (id: string) => IOffice {
        return (id) => { return this._offices.find(office => { return office.id === id || office.create_id === id }) }
	}
	get isActive(): (office: IOffice, usr_id?: string) => boolean {
		return (office: IOffice, usr_id?: string) => {
			if (!office) return false

			//on considère que si c'est un secrétaire ou comptable, le serveur n'a remonté que les cabinets liés à ce compte et actifs
			if (user.isSecretaryUser || user.isAccountingUser) return true
			let usrId: string = !usr_id ? user.user.id : usr_id

			return !!office.links && office.links.find(user => { return user.id === usrId })
		}
	}
	get setting(): (ofi_id: string, usr_id?: string) => ISettings {
		return (ofi_id: string, usr_id?: string) => {
			let usrId: string = User.getCurrentUserId(user, usr_id)
			if (!usrId) return

			return this.settings.find(settings => { return settings.ofi_id === ofi_id && settings.usr_id === usrId })
		}
	}
	get oldSettings(): (ofi_id: string, usr_id?: string) => ISettings {
		return (ofi_id: string, usr_id?: string) => {
			let usrId: string = User.getCurrentUserId(user, usr_id)
			if (!usrId) return

			return this._restoreSettings.find(settings => { return settings.ofi_id === ofi_id && settings.usr_id === usrId })
		}
	}
	get schedule(): (ofi_id: string, usr_id?: string) => ISchedule {
		return (ofi_id: string, usr_id?: string) => {
			let usrId: string = User.getCurrentUserId(user, usr_id)
			if (!usrId) return

			return this.schedules.find(schedule => { return schedule.ofi_id === ofi_id && schedule.usr_id === usrId })
		}
	}
	get scheduleUsers(): (ofi_id: string, usr_id: string, start: Date, end: Date) => IScheduleUser[] {
		return (ofi_id: string, usr_id: string, start: Date, end: Date) => {
			let schedule: ISchedule = this.schedule(ofi_id, usr_id)
			let links: ILinkUser[] = user.isSecretaryUser ? user.linkedUsers(Profile.None) : this.links(ofi_id)

			let users: IScheduleUser[] = Office.getScheduleUsers(schedule, links, usr_id)
			let specificsSchedules:ISchedule[] = this.specificSchedulesRange(ofi_id, usr_id, start, end)
			specificsSchedules.forEach(schedule => {
				let specificsUsers: IScheduleUser[] = Office.getScheduleUsers(schedule, links, usr_id, users)
				// specificsUsers = specificsUsers.filter(specificUser => { return !users.find(u => { return u.id === specificUser.id })})
				users = [...specificsUsers]
			})

			return users
		}
	}
	get oldSchedule(): (ofi_id: string, usr_id?: string) => ISchedule {
		return (ofi_id: string, usr_id?: string) => {
			let usrId: string = User.getCurrentUserId(user, usr_id)
			if (!usrId) return

			return this._restoreSchedules.find(schedule => { return schedule.ofi_id === ofi_id && schedule.usr_id === usrId })
		}
	}
	get officesByUser(): (usr_id: string, ofi_id?: string) => IOffice[] {
		return (usr_id: string, ofi_id?: string) => {
			if (!usr_id) return this._offices

			return this._offices
				.filter(office => {
					if (!!ofi_id && office.id === ofi_id)
						return true
					if (!office.links)
						return false

					let user = office.links.find(user => { return user.id === usr_id })
					return !!user
				})
		}
	}
	get offices(): IOffice[] {
        if (user.isMainUser || user.isSubstituteUser) return this._offices

        let usr_id: string = !!user.currentUser ? user.currentUser.id : null
        return this.officesByUser(usr_id)
	}
	get links(): (id: string) => ILinkUser[] {
		return (id: string) => {
			let office: IOffice = this.office(id)
			if (!!office) return office.links

			return []
		}
	}
	get linksLength(): (id: string) => number {
		return (id: string) => {
			return this.links(id).length
		}
	}
	get specificSchedules(): (ofi_id: string, usr_id?: string) => ISchedule[] {
		return (ofi_id: string, usr_id?: string) => {
			let usrId: string = User.getCurrentUserId(user, usr_id)
			if (!usrId) return []

			let office: IOffice = this.office(ofi_id)
			if (!office) return []
			let result: ISchedule[] = office.specific_schedules.currents.filter(schedule => { return schedule.ofi_id === ofi_id && schedule.usr_id === usrId })
			if (!!result && result.length > 0) return result

			return this._specificSchedules.filter(schedule => { return schedule.ofi_id === ofi_id && schedule.usr_id === usrId })
		}
	}
	get specificSchedulesRange(): (ofi_id: string, usr_id: string, start: Date, end?: Date) => ISchedule[] {
		return (ofi_id: string, usr_id: string, start: Date, end?: Date) => {
			let schedules: ISchedule[] = this.specificSchedules(ofi_id, usr_id)
			return schedules.filter(s => {
				if ((s.start <= start && start <= s.end) || (s.start <= end && end <= s.end)) {
					let week: number = Math.round((start.getTime() - s.start.getTime()) / (7 * 24 * 60 * 60 * 1000))
					return week % s.cycle === 0
				} else {
					return false
				}
			})
		}
	}
	get oldSpecificSchedule(): (sch_id: string) => ISchedule {
		return (sch_id: string) => {
			return this._restoreSpecificSchedules.find(s => { return s.id === sch_id || s.create_id === sch_id })
		}
	}
	get sessions(): (ofi_id: string, usr2_id?: string) => ISession[] {
		return (ofi_id: string, usr_id?: string) => {
			let usrId = !!usr_id ? usr_id : (!!user.currentUser ? user.currentUser.id : undefined)
			if (!usrId || !this._sessions[usrId]) return []

			let result: ISession[] = this._sessions[usrId][ofi_id]
			if (!result || result.length === 0) return []

			return result.filter(session => { return !this.deletedSessions.find(s => s.id === session.id) })
		}
	}
	get hasSessions(): (id: string) => boolean {
		return (id: string) => {
			if (!id || parseInt(id) === -1) return false

			let sessions: ISession[] = this.sessions(id)
			return sessions && sessions.length > 0
		}
    }

	get duplicateSchedule(): (schedule: ISchedule) => ISchedule {
		return (schedule: ISchedule) => {
			let cloneSchedule: ISchedule = cloneDeep(schedule)
			cloneSchedule.id = '-1'
			cloneSchedule.create_id = Common.generateId()
			cloneSchedule.start = DateHelper.getMonday(new Date())
			cloneSchedule.end = DateHelper.getSunday(new Date())

			return cloneSchedule
		}
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusSettings = RequestStatus.None
		this.statusSessions = RequestStatus.None
		this.statusSchedules = RequestStatus.None
		this.statusUrl = RequestStatus.None
		this._offices = []
		this.settings = []
		this.schedules = []
		this._specificSchedules = []
		this._sessions = {}
		this._restoreSessions = {}
		this._restoreSchedules = []
		this._restoreSpecificSchedules = []
		this._restoreSettings = []
		this.deletedSessions = []
		this.selectedPlanningOffice = null
	}

	@Mutation
	urlRequest() {
		this.statusUrl = RequestStatus.Loading
	}

	@Mutation
	urlSuccess() {
		this.statusUrl = RequestStatus.Success
	}

	@Mutation
	urlFailure() {
		this.statusUrl = RequestStatus.Success
	}

	@Mutation
	officesRequest() {
		this.status = RequestStatus.Loading
		this._offices = []
	}

	@Mutation
	officesSuccess(offices: IOffice[]) {
		this.status = RequestStatus.Success
		this._offices = offices
	}

	@Mutation
	officesFailure() {
		this.status = RequestStatus.Success
		this._offices = []
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess() {
		this.statusSaving = RequestStatus.Success
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Success
	}

	@Mutation
	restoreOffice(office: IOffice) {
		this.status = RequestStatus.Success
		this.deletedSessions = []
		if (!office) return

		let index: number = this._offices
			.findIndex(o => { return (parseInt(o.id) === -1 ? o.create_id : o.id) === (!!office.create_id ? office.create_id : office.id)})
		if (index < 0) return

		this._offices.splice(index, 1, office)
	}

	@Mutation
	settingsRequest() {
		this.statusSettings = RequestStatus.Loading
		this.settings = []
	}

	@Mutation
	settingsSuccess(settings: ISettings[]) {
		this.statusSettings = RequestStatus.Success
		this.settings = settings
		this._restoreSettings = cloneDeep(settings)
	}

	@Mutation
	settingsFailure() {
		this.statusSettings = RequestStatus.Success
		this.settings = []
	}

	@Mutation
	sessionsRequest() {
		this.statusSessions = RequestStatus.Loading
		this._sessions = {}
	}

	@Mutation
	sessionsSuccess(sessions: Dictionary<Dictionary<ISession[]>>) {
		this.statusSessions = RequestStatus.Success
		this._sessions = sessions
		this._restoreSessions = cloneDeep(sessions)
	}

	@Mutation
	sessionsFailure() {
		this.statusSessions = RequestStatus.Success
		this._sessions = {}
	}

	@Mutation
	schedulesRequest() {
		this.statusSchedules = RequestStatus.Loading
		this.schedules = []
	}

	@Mutation
	schedulesSuccess(result: {schedules: ISchedule[], specific_schedules: ISchedule[]}) {
		this.statusSchedules = RequestStatus.Success
		this.schedules = result.schedules
		this._restoreSchedules = cloneDeep(result.schedules)
		this._specificSchedules = result.specific_schedules
		this._restoreSpecificSchedules = cloneDeep(result.specific_schedules)
	}

	@Mutation
	schedulesFailure() {
		this.statusSchedules = RequestStatus.Success
		this.schedules = []
	}

	@Mutation
	updateLinks(account: IAccount) {
		for(let office of account.offices) {
			let stateOffice = this._offices.find(o => { return o.id === office.id})
			if (stateOffice === undefined) continue

			if (office.selected) {
				let stateAccount = stateOffice.links.find(a => { return a.id === account.id})
				if (stateAccount !== undefined) continue

				stateOffice.links.push(account)
			} else {
				stateOffice.links = stateOffice.links.filter(a => { return a.id !== account.id})
			}
		}
	}

	@Mutation
	removeLinks(id: string) {
		for(let office of this._offices) {
			office.links = office.links.filter(account => { return account.id !== id})
		}
	}

	@Mutation
	selectedPlanningLinkOffice(office: IOffice) {
		this.selectedPlanningOffice = office
	}

	@Mutation
	restoreSessions() {
		this._sessions = cloneDeep(this._restoreSessions)
	}

	@Mutation
	restoreSchedules() {
		this.schedules = cloneDeep(this._restoreSchedules)
	}

	@Mutation
	restoreSpecificSchedules() {
		this._specificSchedules = cloneDeep(this._restoreSpecificSchedules)
	}

	@Mutation
	setSpecificSchedules(schedules: ISchedule[]) {
		this._specificSchedules = schedules
	}

	@Mutation
	restoreSettings() {
		this.settings = cloneDeep(this._restoreSettings)
	}

	@Mutation
	removeOffice(ofiId: string): void {
		let office: IOffice = this._offices.find(o => o.id === ofiId)
		this._offices = this._offices.filter(o => o.id !== ofiId)
		if (parseInt(ofiId) !== -1 || !office) return

		this.settings = this.settings.filter(s => s.ofi_id !== ofiId)
		this._restoreSettings = this._restoreSettings.filter(s => s.ofi_id !== ofiId)
		this.schedules = this.schedules.filter(s => s.ofi_id !== ofiId)
		this._restoreSchedules = this._restoreSchedules.filter(s => s.ofi_id !== ofiId)
		this._specificSchedules = this._specificSchedules.filter(s => s.ofi_id !== ofiId)
		this._restoreSpecificSchedules = this._restoreSpecificSchedules.filter(s => s.ofi_id !== ofiId)
		this._sessions[office.usr_id][ofiId] = this._sessions[office.usr_id][ofiId].filter(s => s.ofi_id !== ofiId)
		this._restoreSessions[office.usr_id][ofiId] = this._restoreSessions[office.usr_id][ofiId].filter(s => s.ofi_id !== ofiId)
	}

	@Action
	addOffice(office: IOffice): void {
		if (!office) return

		this._offices.push(office)
		if (parseInt(office.id) !== -1) return

		let settings: ISettings = {...office.settings.currents[0]}
		this.settings.push(settings)
		this._restoreSettings.push(settings)
		let schedule: ISchedule = {...office.schedules.currents[0]}
		this.schedules.push(schedule)
		this._restoreSchedules.push(schedule)
		let session: ISession = {...office.sessions.currents[0]}
		this._sessions[office.usr_id][office.id] = []
		this._restoreSessions[office.usr_id][office.id] = []
		this._sessions[office.usr_id][office.id].push(session)
		this._restoreSessions[office.usr_id][office.id].push(session)
	}

	@Action
	deleteSpecificSchedule(schedule: ISchedule): void {
		if (!schedule) return

		let office: IOffice = this.office(schedule.ofi_id)
		if (parseInt(schedule.id) === -1) {
			office.specific_schedules.currents = office.specific_schedules.currents.filter(s => s !== schedule)
			return
		}

		office.specific_schedules.deleted.push(schedule)
		office.specific_schedules.currents = office.specific_schedules.currents.filter(s => s.id !== schedule.id)
		this.setSpecificSchedules(this._specificSchedules.filter(s => s.id !== schedule.id))
	}

	@Action
	addSpecificSchedule(schedule: ISchedule): void {
		if (!schedule) return

		let office: IOffice = this.office(schedule.ofi_id)
		if (!office) return

		office.specific_schedules.currents.push(schedule)
		this._specificSchedules.push(schedule)
	}

	@Action({rawError: true})
	async loadOffices(): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.officesRequest()

		let service = new OfficeService()
		return service.getOfficeList()
		.then(offices => {
			this.officesSuccess(offices)
			return Promise.resolve(offices)
		})
		.catch(error => {
			this.officesFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async saveOffice(payload: OfficeSavePayload): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new OfficeService()
		return service.save(payload.office)
		.then(newOffice => {
			this.loadSessions()
			this.loadSchedules()
			this.loadSettings()
			this.restoreSessions()
			this.restoreSchedules()
			this.restoreSpecificSchedules()
			this.restoreSettings()
			this.saveSuccess()
			this.restoreOffice(newOffice)

			return Promise.resolve(newOffice)
		})
		.catch(error => {
			let notification:INotification = { message: "La sauvegarde a échoué. Veuillez rééssayer plus tard.", actions: [], delay: 5000 }
			notif.warning(notification)
			this.saveFailure()
			this.restoreOffice(payload.office)
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deleteOffice(ofiId: string): Promise<any> {
		this.saveRequest()

		let service = new OfficeService()
		return service.delete(ofiId)
		.then(result => {
			this.saveSuccess()
			this.restoreOffice(result.data)

			return Promise.resolve(result.data)
		})
		.catch(error => {
			return Promise.reject(error)
		})
    }

	@Action({rawError: true})
	async activateOffice(ofiId: string): Promise<any> {
		this.saveRequest()

		let service = new OfficeService()
		return service.activate(ofiId)
		.then(newOffice => {
			this.saveSuccess()
			this.restoreOffice(newOffice)

			return Promise.resolve(newOffice)
		})
		.catch(error => {
			return Promise.reject(error)
		})
    }

	@Action({rawError: true})
	async loadSettings(): Promise<any> {
		if (this.statusSettings === RequestStatus.Loading) return

		this.settingsRequest()

		let service = new OfficeService()
		return service.getSettingList()
		.then(settings => {
			this.settingsSuccess(settings)
			return Promise.resolve(settings)
		})
		.catch(error => {
			this.settingsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadSchedules(): Promise<any> {
		if (this.statusSchedules === RequestStatus.Loading) return

		this.schedulesRequest()

		let service = new OfficeService()
		return service.getScheduleList()
		.then(result => {
			this.schedulesSuccess(result)
			return Promise.resolve(result)
		})
		.catch(error => {
			this.schedulesFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadSessions(): Promise<any> {
		if (this.statusSessions === RequestStatus.Loading) return

		this.sessionsRequest()

		let service = new OfficeService()
		return service.getSessionList()
		.then(sessions => {
			this.sessionsSuccess(sessions)
			return Promise.resolve(sessions)
		})
		.catch(error => {
			this.sessionsFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async generateUrl(payload:{ofiId: string, sttId: string}): Promise<any> {
		if (this.statusUrl === RequestStatus.Loading) return

		this.urlRequest()

		let service = new OfficeService()
		return service.generateUrl(payload.sttId, payload.ofiId)
		.then(result => {
			this.urlSuccess()
			return Promise.resolve(result)
		})
		.catch(error => {
			this.urlFailure()
			return Promise.reject(error)
		})
	}

	// @Mutation
	// clear() {
	// 	this.statusReseting = RequestStatus.None
	// 	this._filter = this.getClearFilter()
	// }

	// @Mutation
	// resetFilter() {
	// 	this._filter = this.getClearFilter()
	// }

	// @Mutation
	// resetRequest() {
	// 	this.statusReseting = RequestStatus.Loading
	// }

	// @Mutation
	// resetSuccess() {
	// 	this.statusReseting = RequestStatus.Success
	// }

	// @Mutation
	// resetFailure() {
	// 	this.statusReseting = RequestStatus.Failed
	// }

	// @Action
	// async reset(tpl_id: string): Promise<any> {
	// 	if (this.isReseting) return

	// 	this.resetRequest()

	// 	let service = new DocumentService()
	// 	return service.reset(tpl_id)
	// 	.then(result => {
	// 		this.resetSuccess()
	// 		return Promise.resolve(result.template)
	// 	})
	// 	.catch(error => {
	// 		this.resetFailure()
	// 		return Promise.reject(error)
	// 	})
	// }
}

export const office = getModule(OfficeModule)
// export const office = new OfficeModule({ store, name: "office" })
