import Render from '@Views/components/popin/layout/popin-unpaid-invoice.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class PopinUnpaidInvoice extends Vue {
    
    @Prop() options: {id: string, list: any[]}
	@Prop({default: false}) submitted: boolean
	
	get invoices(): any[] {
		return (this.options && this.options.list) || []
	}
}