<div class="input-frame" :class="{ 'is-alert': errorTest }">
    <label class="label" :for="inputId" :data-text="label">
        <span>{{label}}</span>
    </label>

    <input-address :randomId="false" :address="address" :id="inputId" :title="title" :placeholder="placeholder" :disabled="disabled" :required="required" :readonly="readonly"/>
    <div class="msg-alert" v-if="displayError" v-show="errorTest">
        <div class="msg-alert-inner">{{errorMsg}}</div>
    </div>
</div>
