<section class="timeline-item" :id="`timeline-item-${item.id}`" :style="style" :class="{'is-active': item.active}" :key="`timeline-item-${item.id}`">
	<h4 class="timeline-item-title">
		<button type="button" class="btn--link-like timeline-item-title-trigger" @click="toggle">
			<span class="timeline-item-title-trigger-caret" :class="color | foregroundClass">
				<svg width="1em" class="svg-block" viewBox="0 0 192 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#caret-right"/>
				</svg>
			</span>
			<span :inner-html.prop="item.title | mark(query)"/><span v-if="!!item.sub_title"> : </span><span :inner-html.prop="item.sub_title | mark(query)"/></span>
		</button>
	</h4>
	<div v-if="item.family" :title="$t('vm.root.patients.patient.timeline-item.concerns', {'first-name': item.member.first_name, 'last-name': item.member.last_name, member: getMember()})">
		<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-injured"/>
		</svg>
		<strong class="text-capitalize"><anonymous :text="item.member.first_name"/> <anonymous :text="item.member.last_name"/></strong>, {{ getMember() }}
	</div>
	<div>
		<strong>
			<svg v-if="isAttachmentItem && item.is_file" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use v-if="item.is_pdf" xlink:href="@Assets/svg/symbol-defs.svg#file-pdf"/>
				<use v-else xlink:href="@Assets/svg/symbol-defs.svg#image"/>
			</svg>
			<svg v-if="isAttachmentItem && item.is_link" width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#link"/>
			</svg>
			<svg v-if="item.important" width="1em" class="svg-middle text-danger" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#exclamation-triangle" />
			</svg>
			<em v-if="item.periodic && item.running">
				<svg width="1em" class="svg-middle" viewBox="0 0 384 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#hourglass-half" />
				</svg>
				{{ $t('general.running') }}, {{item.date | since}}
			</em>
			<span v-else-if="item.periodic">{{item.date | timeAgo}}, {{ $t('general.during') }} {{item.date | age(item.end_date)}}</span>
			<span v-else>{{item.date | timeAgo}} &bull; {{ $t('general.the') }} {{ $d(item.date, 'l') }}</span>
			<span v-if="hasFollowUp"> &bull;
				<svg width="1em" class="svg-middle" :class="classLevel" viewBox="0 0 496 512" aria-hidden="true" focusable="false">
					<use :xlink:href="`${require('@Assets/svg/symbol-defs.svg')}#${iconLevel}`" />
				</svg>
				<span :class="classLevel">{{item.follow_up.level | followUpLevel}}</span>
			</span>
		</strong>
	</div>
	<div v-if="owner" class="text-italic text-sm">
		<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
			<use xlink:href="@Assets/svg/symbol-defs.svg#user-md"/>
		</svg> {{ $t('vm.root.patients.patient.timeline-item.create-by', {owner: owner}) }}
	</div>
	<div class="timeline-item-content">
		<div class="timeline-item-content-inner" v-if="isAntecedentItem">
			<p>{{item.description}}</p>
			<form-summary v-for="form in item.forms" :key="form.id" :form="form"/>
			<div class="text-right"><router-link :to="{name: 'patient-antecedents', params: {pat_id:$route.params.pat_id}, query:{filter: `${item.title} ${item.sub_title}`}}"
				:title="$t('vm.root.patients.patient.timeline-item.item.link', {item: item.title})">{{ $t('vm.root.patients.patient.timeline-item.item.text') }}</router-link></div>
		</div>
		<div class="timeline-item-content-inner" v-else-if="isAttachmentItem">
			<p v-if="item.is_file">{{item.description}}</p>
			<figure v-if="item.is_file && !!item.attachment" class="text-center">
				<svg v-if="item.is_pdf" width="1em" class="svg-xl marg-v" viewBox="0 0 384 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#file-pdf"/>
				</svg>
				<img v-else :src="item.attachment" :alt="item.title">
				<figcaption class="sr-only">{{item.title}}</figcaption>
			</figure>
			<a v-if="item.is_link && !!item.attachment" :href="item.attachment" :title="item.title">{{item.title}}</a>
			<div class="text-right"><router-link :to="{name: 'patient-attachment', params: {pat_id:$route.params.pat_id, item_id: item.id}}"
				:title="$t('vm.root.patients.patient.timeline-item.item.link', {item: item.title})">{{ $t('vm.root.patients.patient.timeline-item.item.text') }}</router-link></div>
		</div>
		<div class="timeline-item-content-inner" v-else-if="isConsultationItem">
			<read-only :consultation="item" :timeline="true"/>
			<div class="text-right"><router-link :to="{name: 'patient-consultation', params: {pat_id:$route.params.pat_id, item_id: item.id}}"
				:title="$t('vm.root.patients.patient.timeline-item.item.link', {item: item.title})">{{ $t('vm.root.patients.patient.timeline-item.item.text') }}</router-link></div>
		</div>
		<div class="timeline-item-content-inner" v-else-if="isSphereItem">
			<p>{{item.description}}</p>
			<form-summary v-for="form in item.forms" :key="form.id" :form="form"/>
			<div class="text-right"><router-link :to="{name: 'patient-spheres', params: {pat_id:$route.params.pat_id}, query:{filter: `${item.title} ${item.sub_title}`}}"
				:title="$t('vm.root.patients.patient.timeline-item.item.link', {item: item.title})">{{ $t('vm.root.patients.patient.timeline-item.item.text') }}</router-link></div>
		</div>
		<div class="timeline-item-content-inner" v-else>
			<p v-if="!!item.description1">
				<strong>{{ $t('vm.root.patients.patient.timeline-item.description1-title') }}</strong><br>
				<span v-html="item.description1"/>
			</p>
			<p v-if="!!item.description2">
				<strong>{{ $t('vm.root.patients.patient.timeline-item.description2-title') }}</strong><br>
				<span v-html="item.description2"/>
			</p>
			<p v-if="!!item.description3">
				<strong>{{ $t('vm.root.patients.patient.timeline-item.description3-title') }}</strong><br>
				<span v-html="item.description3"/>
			</p>
			<p v-if="!!item.description4">
				<strong>{{ $t('vm.root.patients.patient.timeline-item.description4-title') }}</strong><br>
				<span v-html="item.description4"/>
			</p>
			<p v-if="!!item.description5">
				<strong>{{ $t('vm.root.patients.patient.timeline-item.description5-title') }}</strong><br>
				<span v-html="item.description5"/>
			</p>
			<form-summary v-for="form in item.forms" :key="form.id" :form="form"/>
			<div class="text-right"><router-link :to="{name: 'patient-pediatrics', params: {pat_id:$route.params.pat_id}}"
				:title="$t('vm.root.patients.patient.timeline-item.item.link', {item: item.title})">{{ $t('vm.root.patients.patient.timeline-item.item.text') }}</router-link></div>
		</div>
	</div>
</section>
