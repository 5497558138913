export const main = {
    'new-patient': "Nouveau patient",
    'no-date': "âge non déterminé",
    loading: "ouverture du dossier…",
    cancel: {
        label:"annuler",
        long: "les modifications",
        'title-button': "Annuler les modifications en cours sur ce dossier",
        action: "perdre vos modifications",
        title: "Annulation",
        content: "Vous êtes sur le point d'annuler l'ensemble de vos modifications :<ul><li>{updates}</li></ul>Vous confirmez ?"
    },
    terminate: {
        label:"terminer",
        long: "l'édition",
        'title-button': "Terminer le mode édition pour ce dossier",
    },
    print: {
        'title-popin': "Sélection des éléments à imprimer",
        title: "Imprimer le dossier de ce patient",
        label: "imprimer"
    },
    duplicate: {
        failed: "Impossible de dupliquer ce dossier patient.",
        succeeded: "Le dossier patient dupliqué a bien été créé.",
        'action-label': "ouvrir ce nouveau dossier",
        'title-popin': "Duplication de ce patient",
        title: "Dupliquer les coordonnées de ce patient vers un nouveau dossier",
        label: "dupliquer"
    },
    'child-description': "enfant de {age}",
    'teenager-description': "enfant de {age}",
    'patient-description': "{gender} de {age}",
    blacklisted: "Blacklisté",
    deceased: "Décédé",
    'no-profession': "profession non renseignée",
    'no-phone': "numéro de téléphone non renseigné",
    'no-email': "adresse mail non renseignée",
    call: "Appeler {name}",
    'send-email': "Envoyer un email à {name}",
    notes: {
        display: "Afficher les notes",
        hide: "Masquer les notes",
        none: "pas de notes"
    },
    edit: {
        title: "Modifier le dossier",
        label: "modifier le dossier"
    },
    save: {
        label: "sauvegarder",
        title: "Sauvegarder les modifications du dossier",
        failed: "La sauvegarde a échoué. Continuez, on se charge de <strong>réessayer</strong> dans 5 minutes. <strong>Mais restez connecté, sinon, on ne pourra rien faire</strong>."
    },
    title: "Les membres de la famille",
    already: "Ce membre fait déjà parti de la famille.",
    same: "… Bah non, c'est le patient. Voyons…",
    unselect: {
        label: "Dissocier",
        title: "Dissociation",
        content: "Vous-êtes sûr de vouloir dissocier ce dossier patient de ce rendez-vous ?"
    },
    member: {
        title: "Accéder au dossier patient de {first-name} {last-name}",
        unselect: {
            label: "dissocier",
            title: "Dissociation",
        }
    },
    none: {
        title: "Pas de famille.",
        description: "Oui, mais il doit avoir des amis. J'espère."
    },
    rgpd: {
        label: "confirmer", 
        title: "RGPD"
    },
    'buy-license': {
        label: "acquérir", 
        content: "Votre licence ne vous autorise à accéder qu'à vos <strong>{license}</strong> premiers dossiers. Pour accéder à l'ensemble de vos dossiers, vous devez <strong>acquérir un pack tranquillité</strong>.",
        title: "Licence limitée"
    }
}
