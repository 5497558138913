import Render from '@Views/components/dropdown-button.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDropdownItem } from '@Components/input/dropdown'

@Render
@Component({})
export class DropdownButton extends Vue {
    name: string = 'dropdown-button'

    activeItem: IDropdownButtonItem = null
    open: boolean = false
    selectedIndex: number = null

    @Prop({default: []}) items: IDropdownButtonItem[]
    @Prop({default: () => []}) preferredItems: IDropdownButtonItem[]
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) flag: boolean
    @Prop() getIcon: (item: IDropdownButtonItem) => string
    @Prop() ddbClass: string
    @Prop() property: string
    @Prop() id: string
    @Prop() title: string
    @Prop() value: string

    created() {
        if (this.setDefaultItem()) return

        this.activeItem = this.items[0]
    }

    @Watch('value')
    updateValue() {
        this.setDefaultItem()
    }

    isSelected(value: string): boolean {
        return !!this.activeItem && this.activeItem.value === value
    }

    clickedOutside(): void {
        this.open = false
    }

    toggleDropdown(): void {
        if (this.disabled) return

        this.open = !this.open
    }

    choose(item: IDropdownButtonItem): void {
        this.activeItem = item
        this.$emit('input', this.activeItem.value)
        this.open = false
    }

    reset(): void {
        this.open = false

        if (!this.activeItem) return
        this.selectedIndex = this.items.map(c => c.value).indexOf(this.activeItem.value)
    }

    getItemClass(index: number, value: string): {highlighted: boolean, 'last-preferred': boolean, preferred: boolean} {
        const highlighted = this.selectedIndex === index
        const lastPreferred = index === this.preferredItems.length - 1
        const preferred = this.preferredItems.find(item => item.value === value)
        return {
            highlighted,
            'last-preferred': lastPreferred,
            preferred: !!preferred,
        }
    }

    keyboardNav(e: any): void {
        let list: HTMLElement = this.$refs.list as HTMLElement
        
        if (!this.items || this.items.length === 0) return

        if (list === undefined) return

        switch(e.key) {
            case "Down":
            case "ArrowDown":
                e.preventDefault()
                this.keyDown(list)
                break
            case "Up":
            case "ArrowUp":
                e.preventDefault()
                this.keyUp(list)
                break
            case "Enter":
                e.preventDefault()
                this.keyEnter()
                break
            case "End":
                e.preventDefault()
                this.keyEnd(list, this.items.length -1)
                break
            case "Home":
                e.preventDefault()
                this.keyHome(list, 0)
                break
            default:
                break
        }
    }

    private keyEnter() {
        if (this.selectedIndex === null) return
        this.choose(this.items[this.selectedIndex])
    }

    private keyUp(list: HTMLElement) {
        let index = !!this.selectedIndex ? Math.max(0, this.selectedIndex - 1) : this.items.length - 1

        this.keyEnd(list, index)
    }

    private keyDown(list: HTMLElement) {
        let index = this.selectedIndex === null ? -1 : this.selectedIndex
        index = index === this.items.length - 1 ? 0 : Math.min(this.items.length - 1, index + 1)

        this.keyHome(list, index)
    }

    private keyHome(list: HTMLElement, index: number) {
        this.open = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.focus(selectedElement)
        if (selectedElement.offsetTop + selectedElement.clientHeight <= list.scrollTop + list.clientHeight) return

        list.scrollTop = selectedElement.offsetTop - list.clientHeight + selectedElement.clientHeight
    }

    private keyEnd(list: HTMLElement, index: number) {
        this.open = true
        this.selectedIndex = index

        let selectedElement: HTMLElement = list.children[this.selectedIndex] as HTMLElement
        this.focus(selectedElement)
        if (selectedElement.offsetTop >+ list.scrollTop) return

        list.scrollTop = selectedElement.offsetTop
    }

    private focus(element: HTMLElement) {
        if (!element.children || element.children.length === 0) return
        (element.children[0] as HTMLElement).focus()
    }

    private setDefaultItem(): boolean {
        if (!this.value) return false

        let item: IDropdownButtonItem = this.items.find(type => type.value.toString().toLowerCase() === this.value.toString().toLowerCase())
        if (!item) return false
        
        this.activeItem = item
        return true
    }
}

export interface IDropdownButtonItem extends IDropdownItem {
    other?: string
}