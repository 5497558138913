import Render from '@Views/root/patients/campaigns/campaign/aside-campaign.html'
import { Component, Vue } from 'vue-property-decorator'
import { ICampaign } from '@Store/types'
import { campaign } from '@Store/modules'

@Render
@Component({})
export class ASideCampaign extends Vue {

	// @State('campaign') stateCampaign: CampaignState
	// @Action('campaign/deleteCampaign') deleteCampaign: (cmp_id: string) => Promise<any>

	get currentStep(): number {
		return campaign.step
	}
	get campaign(): ICampaign {
		return campaign.campaign
	}
	get isDeleting(): boolean {
		return campaign.isDeleting
	}
	get isNew(): boolean {
		return !this.campaign || !this.campaign.id || parseInt(this.campaign.id) === -1
	}
	get isSended(): boolean {
		return campaign.isSended()
	}

	submitDelete(): void {
		let route = {name: 'patients-campaigns'}
	
		this.$emit('delete')
		if (!this.isNew) {
		  campaign.deleteCampaign(this.campaign.id).then(() => {
			this.$router.push(route)
		  })
		} else {
		  this.$router.push(route)
		}
	}
}
