import Render from '@Views/components/input/dropdown-editable.html'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import vSelect from 'vue-select'
import { OpenIndicator, Deselect } from './dropdown-editable-indicator'
import { Debouncer } from '@Models/index'
import { ITagItem } from '@Store/types'

@Render
@Component({
    components: {
        'vue-select': vSelect
    }
})
export class DropdownEditable extends Vue {

    open: boolean = false
    options: ITagItem[] = []
    private debouncer:Debouncer
	private tag: string
	private loading: any

    @Prop({default: 'select-like'}) trigger: string
    @Prop({default: 'sélectionnez une valeur'}) defaultLabel: string
    @Prop({default: 'tapez pour rechercher...'}) noOptionsLabel: string
    @Prop({default: ''}) defaultValue: any
    @Prop({default: false}) multiple: boolean
    @Prop({default: false}) taggable: boolean
    @Prop({default: false}) readonly: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) manageMissing: boolean
    @Prop() searcher: (search: string) => any
    @Prop() title: string
    @Prop() placeholder: string
    @Prop() id: string
    @Prop() value: any
    @Prop() items: ITagItem[]

    get OpenIndicator() {
        return OpenIndicator
    }
    get Deselect() {
        return Deselect
    }

    onCreated() {
    }

    onSearch(search: string, loading: any): void {
        if (!this.searcher) return
        this.tag = search
        this.loading = loading
        this.loading(true)

        this.debouncer.start()
    }

    handleInput(e: any) {
        this.$emit('input', e)
    }

    mounted() {
        this.updateItems()
        this.debouncer = new Debouncer(this.searching, 350)
    }

    beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}
    
    @Watch('items')
    private updateItems() {
        this.options = this.items || []
    }

    private searching(): void {
        if (!this.searcher) return
        this.searcher(this.tag)
        .then(result => {
            this.options = result
			this.loading(false)
		})
		.catch(error => {
			console.error(error)
			this.loading(false)
		})

	}
}