import Render from '@Views/root/patients/patient/consultation/read-only-follow-up.html'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IFollowUp } from '@Store/types'
import {FollowUpLevel} from "@Enums/reminder"

@Render
@Component({})
export class ReadOnlyFollowUp extends Vue {

	@Prop() followUp: IFollowUp

	get classLevel(): any {
		return {
			'text-danger': this.followUp.level === FollowUpLevel.NotSatisfied,
			'text-success': this.followUp.level === FollowUpLevel.Satisfied
		}
	}
	get iconLevel(): string {
		return this.followUp.level === FollowUpLevel.NotSatisfied ? 'frown' : (this.followUp.level === FollowUpLevel.Satisfied ? 'smile-beam' : 'meh')
	}
}
