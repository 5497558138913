import Render from '@Views/root/accounting/context-main.html'
import { Component } from 'vue-property-decorator'
import { AbstractContext } from './context-base'
import { user } from '@Store/modules'
import { ILinkUser } from '@Store/types'

@Render
@Component({})
export class ContextSubstitute extends AbstractContext {

	mounted() {
		super.reset()
		super.filter.usr_id = user.user.id
	}
	
	reset(): void {
		super.reset()
	}
	
	getUserList(): ILinkUser[] {
		return []
	}
}