export const context = {
    title: "Mots clés",
    'help-label': "Aide" ,
    text1: "Vous pouvez ajouter des mots clés à votre document en cliquant dans la liste ci-dessous.",
    text2: "Le mot clé choisi sera inséré dans votre document à l'emplacement de votre curseur. Chaque mot clé inséré sera remplacé automatiquement par la donnée correspondante lors de l'utilisation de ce document.",
    'button-title': "Ajouter le mot-clé {keyword}",
    tips: {
        title: "Trucs et astuces",
        tips1: {
            text: "Vous pouvez créer un nouveau paragraphe en appuyant simplement sur {0}, ou faire un retour à la ligne en appuyant sur {1} et {0}",
            kbd1: "Entrer",
            kbd2: "Shift"
        },
        tips2: "Vous pouvez insérer votre signature/tampon, préalablement scanné, en cliquant sur ce bouton {0}. Vous pourrez ensuite ajuster sa taille directement dans le document.",
    }
}
