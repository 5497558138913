import Render from '@Views/root/patients/campaigns/campaign/steps/step-2.html'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { IOffice, IPatientSearch, IExportFilter, ICampaign } from '@Store/types'
import { Debouncer } from '@Models/index'
import { CampaignChoice } from '@Enums/index'
import {IDropdownItem, InfiniteScroll, IToggleBoxItem} from '@Components/index'
import { campaign, office, patients } from '@Store/modules'

@Render
@Component({
	components: {
		'infinite-scroll': InfiniteScroll
	}
})
export class StepTwo extends Vue {

	countList: number = 0
	resultSearch: IPatientSearch[] = []
	total: number = 0
	searched: boolean = false
	searching: boolean = false
	initialized: boolean = true
	canLoadMore: boolean = true
	page: number = 0
	private searchTotal: number = 0
	private debouncer: Debouncer = null

	@Prop() campaign: ICampaign

	//#region Getters
	get filter(): IExportFilter {
        return this.campaign.filter
	}
	get disabled(): boolean {
		return (this.isPatient && (this.searching || !this.resultSearch || this.resultSearch.length === 0)) || (this.isList && this.countList === 0)
	}
	get choiceItems(): IToggleBoxItem[] {
		return [
			{value: CampaignChoice.List, label: "par liste de numéros"},
			{value: CampaignChoice.Patient, label: "par liste de patients"},
		]
	}
	get isList(): boolean {
		return this.campaign.choice === CampaignChoice.List
	}
	get isPatient(): boolean {
		return this.campaign.choice === CampaignChoice.Patient
	}
	get officeItems(): IDropdownItem[] {
		let result: IDropdownItem[] = []
		result.push({value: '-1', label: this.$i18n.t('general.all-offices').toString()})
		let offices: IOffice[] = office.offices
		for (let o of offices) {
			if (!office.isActive(o)) continue

			result.push({value: o.id, label: o.coordinates.name})
		}

		return result
    }
	//#endregion

	mounted() {
		this.debouncer = new Debouncer(this.search, 500)

		this.updateChoice()
		this.updateCountList()

		if (campaign.step === 1) return

		this.$router.push({name: 'campaign-step-one'})
	}

	beforeDestroy() {
		this.resultSearch.length = 0
		this.searchTotal = 0
		Debouncer.clear(this.debouncer)
	}

	next(): void {
		if (!this.canNext()) return

		if (this.isList) {
			this.campaign.estimation_count = this.campaign.sms_count * this.countList
		} else if (this.isPatient) {
			this.campaign.estimation_count = this.campaign.sms_count * this.total
		}

		campaign.nextStep()
		this.$router.push({name: 'campaign-step-three'})
	}

	previous(): void {
        campaign.previousStep()
        this.$router.push({name: 'campaign-step-one'})
	}

	@Watch('campaign.choice')
	updateChoice() {
		this.page = 0
		if (this.isPatient) this.debouncer.start()
		else Debouncer.clear(this.debouncer)
	}

	@Watch('campaign.list')
	updateCountList() {
		if (!this.isList) return

		if (!this.campaign.list) this.countList = 0
		else {
			let list: string[] = this.campaign.list.split(/,|;| /).filter(i => { return !!i })
			this.countList = list.length
		}
	}

	@Watch('filter', {deep: true})
	updateFilter() {
		if (!this.isPatient) return

		this.page = 0
		this.debouncer.start()
	}

	search(): void {
		if (!this.isPatient) return

		let total: number = this.total
		this.searching = true
		this.total = 0
		if (this.page === 0) {
			this.resultSearch.length = 0
			this.searchTotal = 0
		}
		patients.searchExport({filter: this.filter, page: this.page, with_list: false})
		.then((result: {total: number, list: any[]}) => {
			this.searched = true
			this.searching = false
			this.page++
			this.searchTotal += result.list.length
			let filteredList: IPatientSearch[] = result.list.filter(this.hasPhone)

			total = total === 0 ? result.total : total
			this.total = total - result.list.length + filteredList.length
			this.resultSearch.push(...filteredList)
			this.canLoadMore = this.searchTotal !== this.total
			this.initialized = false
		})
		.catch(error => {
			this.searched = true
			this.searching = false
			this.canLoadMore = false
			console.error(error)
		})
	}

	private hasPhone(patient: IPatientSearch): boolean {
		return !!patient.phone
	}

	private canNext(): boolean {
		return (this.isPatient && this.resultSearch.length > 0) || (this.isList && this.countList > 0)
	}
}
